import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

// Apollo
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_LOGGEDIN_FB_PROFILE } from 'src/apollo/queries';
import { CONFIGURE_FACEBOOK } from 'src/apollo/mutations';

// Components
import { FlexBox, Loader } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { initFacebookSdk } from 'src/helpers/InitFacebookSdk';
import { login } from 'src/services/facebook.service';
import useTitle from 'src/utils/hooks/useTitle';

// Icons
import { GreenCheckedIcon, BackRoundedIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables, ROUTES } from 'src/constants';

// Styles
import './_salesChannels.scss';

const SalesChannels: FC = () => {
  useTitle('Settings - Sales channels');
  const history = useHistory();
  const [isFacebookConnected, setIsFacebookConnected] = useState(false);
  const [accountName, setAccountName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const returnBack = () => {
    history.push(ROUTES.settings.main);
  };

  useEffect(() => {
    initFacebookSdk();
    setPageLoading(true);
    getLoggedInFBProfile();
  }, []);

  const [getLoggedInFBProfile] = useLazyQuery(GET_LOGGEDIN_FB_PROFILE, {
    onCompleted: (res) => {
      if (res.getLoggedInFBProfile?.name) {
        setPageLoading(false);
        setIsLoading(false);
        setIsFacebookConnected(true);
        setAccountName(res.getLoggedInFBProfile.name);
      }
    },
    onError: (res) => {
      setIsLoading(false);
      setIsFacebookConnected(false);
      setAccountName('');
      setPageLoading(false);
    }
  });

  const [configureFacebook] = useMutation(CONFIGURE_FACEBOOK, {
    onCompleted: (res) => {
      if (res.configureFacebook.success) {
        getLoggedInFBProfile();
      }
    },
    onError: (res) => {
      setIsLoading(false);
      setIsFacebookConnected(false);
      setPageLoading(false);
    }
  });

  const handleConnectFacebook = () => {
    setIsLoading(true);
    login().then((res) => {
      configureFacebook({
        variables: {
          input: {
            userAccessToken: res.authResponse.accessToken
          }
        }
      });
    });
  };

  return (
    <div className="sales-channels">
      <div className="width-animation w-100 p-40">
        <div className="sales-channels-container">
          <FlexBox className="align-items-center m-y-40">
            <img src={BackRoundedIcon} alt={BackRoundedIcon} onClick={returnBack} className="cursor-pointer" />
            <h2 className="page-title px-4 mb-0">{constVariables.salesChannels.pageName}</h2>
          </FlexBox>

          <div className="card w-100">
            {pageLoading ? (
              <Loader type="page" className="h-200px" />
            ) : (
              <>
                <div className="facebook-logo">facebook</div>
                {isFacebookConnected ? (
                  <FlexBox className="mt-5 facebook-connected-account align-items-center">
                    <img src={GreenCheckedIcon} alt="" />
                    <span className="ms-2">{accountName} Account is Connected.</span>
                  </FlexBox>
                ) : (
                  <>
                    <p>
                      Please click the connect button below to connect your Facebook account and link it to your Buy Social store.
                    </p>
                    <button className="btn mt-5 btn-primary" onClick={handleConnectFacebook}>
                      {isLoading ? 'Connecting' : 'Connect'}
                      {isLoading && <div className="spinner-border text-primary auth-flow"></div>}
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesChannels;
