/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';

// Components
import { FlexBox, Img } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';

// Icons
import { DefaultImage } from 'src/assets/icons';

// Styles
import './vendorList.scss';

export type VendorRowDetailType = {
  id: number;
  name: string;
  address: string;
  state: string;
  country: string;
  websiteUrl: string;
  profileUrl: string;
  category: Array<string>;
  subCategory: Array<string>;
  vendorRep: Array<{
    id: number;
    title?: string;
    name: string;
    email: string;
    phoneNo: string;
  }>;
  extraInfo: Array<{
    id: number;
    type: string;
    title: string;
    name: string;
  }>;
  totalSpending: number;
};

export type VendorRowDetailProps = {
  details: VendorRowDetailType;
};

const VendorRowDetail: FC<VendorRowDetailProps> = ({ details }) => {
  return (
    <tr className="detailRow">
      <td colSpan={2}>
        <FlexBox className="flex-row">
          <div className="flex-center me-5 img-row-div bg-white border">
            <Img
              className="min-w-100px bg-white border"
              src={setImageSrc(details?.profileUrl)}
              placeholderImg={DefaultImage}
              errorImg={DefaultImage}
            />
          </div>
          <FlexBox className="justify-content-start flex-column">
            <div className="text-primary mb-2">{details?.websiteUrl}</div>
            <div className="text-muted mb-2">
              <strong>Category:</strong>{' '}
              {details?.category?.map((item: any, index: number) => {
                return (
                  <span>
                    {item}
                    {index === details?.category?.length - 1 ? '' : ', '}
                  </span>
                );
              })}
            </div>
            <div className="text-muted">
              <div className="text-muted mb-2">
                <strong>Sub category:</strong>
                {details?.subCategory?.map((sub, index) => {
                  return <span className={`badge me-3 collection-badge`}>{sub}</span>;
                })}
              </div>
            </div>
          </FlexBox>
        </FlexBox>
      </td>
      <td colSpan={4}>
        <FlexBox className="flex-row">
          <FlexBox className="me-20">
            <FlexBox className="justify-content-start flex-column">
              <div className="mb-2 opacity-80">
                <strong>Customer rep</strong>
              </div>
              <div className="text-muted mb-2">{details?.vendorRep[0]?.name}</div>
              <div className="text-primary mb-2">{details?.vendorRep[0]?.email}</div>
              <div className="text-muted mb-2">{details?.vendorRep[0]?.phoneNo}</div>
            </FlexBox>
          </FlexBox>
          <FlexBox className="me-20">
            <FlexBox className="justify-content-start flex-column">
              <div className="mb-2 opacity-80">
                <strong>Sale information</strong>
              </div>
              {details?.extraInfo?.map((info, index) => {
                return info.type === 'SALE_INFO' ? <div className="text-muted mb-2">{info.title}</div> : null;
              })}
            </FlexBox>
          </FlexBox>
          <FlexBox className="flex-row">
            <div className="me-10">
              <div className="mb-2 opacity-80">
                <strong>Address</strong>
              </div>
              {details?.address && (
                <div className="text-muted mb-4">
                  {details?.address}, {details?.state}, {details?.country}
                </div>
              )}
              <div className="mb-2 opacity-80">
                <strong>At market location</strong>
              </div>
              {details?.address && (
                <div className="text-muted">
                  {details?.address}, {details?.state}, {details?.country}
                </div>
              )}
            </div>
            <div>
              <div className="mb-2 opacity-80">
                <strong>Other</strong>
              </div>
              {details?.extraInfo?.map((info, index) => {
                return info.type === 'OTHERS' ? <div className="text-muted mb-2">{info.title}</div> : null;
              })}
            </div>
          </FlexBox>
        </FlexBox>
      </td>
    </tr>
  );
};

export default VendorRowDetail;
