import { gql } from '@apollo/client';

export const MODIFY_WAREHOUSE = gql`
  mutation ModifyWarehouse($input: ModifyWarehouseInput) @api(name: "productsAPI") {
    modifyWarehouse(input: $input) {
      id
      name
    }
  }
`;
