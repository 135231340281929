import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

// Apollo
import { useQuery, useSubscription } from '@apollo/client';
import { GET_NEXT_SCHEDULED_LIVE_SHOW, GET_STORE_STREAM_CONFIGURATION } from 'src/apollo/queries';
import { ON_IVS_STATE_CHANGE } from 'src/apollo/subscription';

// Components
import LiveShowManagerPresentational from './LiveshowManager.presentational';

// Hooks && Utils && Helpers
import useTitle from 'src/utils/hooks/useTitle';

// Types
import { ILiveStreamDetails, StoreStreamConfiguratorType } from './LiveshowManager.types';

const LiveShowManager: FC = () => {
  const history = useHistory();

  useTitle('Liveshow manager');
  const [eventDetails, setEventDetails] = useState();
  const [liveStreamData, setLiveStreamData] = useState<ILiveStreamDetails>({
    id: '',
    products: [],
    startingAt: '',
    endingAt: '',
    thumbnailUrl: '',
    title: ''
  });

  useEffect(() => {
    if (history?.location?.state) {
      const { eventDetails } = history?.location?.state;
      setEventDetails(eventDetails);
    }
  }, []);

  const [showLiveShowModal, setShowLiveShowModal] = useState<boolean>(false);

  const [storeStreamConfigurator, setStoreStreamConfigurator] = useState<StoreStreamConfiguratorType>({
    liveVideo: {
      arn: '',
      ingestServer: '',
      isStreaming: false,
      playbackUrl: '',
      streamKey: ''
    },
    runningStreamSource: null,
    message: '',
    storeId: '',
    runningLiveId: '',
    medialiveChannelState: null,
    previewVideo: {
      arn: '',
      ingestServer: '',
      isStreaming: false,
      playbackUrl: '',
      streamKey: ''
    },
    isLiveOnApp: false,
    isLiveOnFB: false,
    liveStartedAt: '',
    nextShowBlockedTill: null
  });

  const { refetch } = useQuery(GET_NEXT_SCHEDULED_LIVE_SHOW, {
    onCompleted: (data) => {
      setLiveStreamData(data?.getNextScheduledLiveShow);
    },
    onError: (error) => {
      console.error('Get Live Stream Error', error);
    },
    fetchPolicy: 'cache-and-network'
  });

  useQuery(GET_STORE_STREAM_CONFIGURATION, {
    onCompleted: (d) => {
      if (d?.getStoreStreamConfiguration) {
        setStoreStreamConfigurator(d?.getStoreStreamConfiguration);
      }
    },
    onError: (e) => {
      console.error('Get Live Stream Error', e);
    },
    fetchPolicy: 'network-only'
  });

  useSubscription(ON_IVS_STATE_CHANGE, {
    variables: { input: localStorage.getItem('storeId') },
    onSubscriptionData: (d) => {
      if (d?.subscriptionData?.data?.onIVSStateChange) {
        setStoreStreamConfigurator(d.subscriptionData?.data?.onIVSStateChange);
      }
    }
  });

  return (
    <LiveShowManagerPresentational
      eventDetails={eventDetails}
      nextLiveStream={liveStreamData}
      showLiveShowModal={showLiveShowModal}
      setShowLiveShowModal={setShowLiveShowModal}
      storeStreamConfigurator={storeStreamConfigurator}
      setStoreStreamConfigurator={setStoreStreamConfigurator}
      handleGetNextScheduledLiveShow={refetch}
    />
  );
};

export default LiveShowManager;
