import { FC, useEffect, useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_PARCEL_TEMPLATES } from 'src/apollo/queries';

// Components
import { DropdownSelector, FlexBox } from 'src/components/atoms';
import { Dropdown, Checkbox, InputBox, Tooltip } from 'src/components/molecules';
import { WeightCodes } from 'src/components/oraganisms/AddProductShipping/tempData';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { convertWeight } from 'src/utils/weightConverter';

// Icons
import { DownArrowIcon, InfoIcon, NoPackageOptionImage, UpArrowIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { PackageAndShippingProps } from './PackageAndShippingFulfillmentCenter.types';

// Styles
import './_packageAndShippingFulfillmentCenter.scss';

const PackageAndShippingFulfillmentCenter: FC<PackageAndShippingProps> = ({
  addShipmentInsurance,
  addSignatureConfirmation,
  setAddShipmentInsurance,
  setAddSignatureConfirmation,
  totalWeight,
  pkgWeightValue,
  setPkgWeightValue,
  pkgUnitValue,
  setPkgUnitValue,
  packageData,
  setPackageData,
  totalOrderValue,
  setTotalOrderValue,
  pkgContentDescription,
  setPkgContentDescription,
  showPackageData,
  useWeightCheck,
  setUseWeightCheck,
  orderShipment,
  orderId
}) => {
  const [selectPkgWeight, setSelectPkgWeight] = useState(false);
  const [packageDropdown, togglePackageDropdown] = useState(false);
  const [hideShipment, setHideShipment] = useState(false);
  const [isDisablePackage, setIsDisablePackage] = useState(false);
  const [dropdownList, setDropdownList] = useState([
    {
      id: -1,
      name: ''
    }
  ]);

  const { data: parcelData } = useQuery(GET_PARCEL_TEMPLATES);

  const changeWeightCheck = () => {
    setUseWeightCheck(!useWeightCheck);
  };

  const changePkgWeight = () => {
    setSelectPkgWeight(!selectPkgWeight);
  };
  const changePkgWeightValue = (data) => {
    // eslint-disable-next-line
    if (!useWeightCheck) setPkgWeightValue(Math.round((parseInt(data) + Number.EPSILON) * 100) / 100);
  };

  const scrollEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    scrollEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (addShipmentInsurance) {
      scrollToBottom();
    }
  }, [addShipmentInsurance]);

  useEffect(() => {
    if (parcelData?.getParcelTemplates?.length > 0) {
      const arr: { id: number; name: string }[] = [];
      parcelData?.getParcelTemplates?.map((parcel, index) => {
        const obj = {
          name: parcel.name,
          id: index
        };
        arr.push(obj);
        return null;
      });
      setDropdownList([...arr]);
    }
  }, [parcelData]);

  useEffect(() => {
    const refundedOrderId = localStorage.getItem('refundedOrderId');
    if (refundedOrderId && orderId === parseInt(refundedOrderId, 10)) {
      setIsDisablePackage(true);
    }
  }, []);

  return (
    <div className={`h-100 w-100 d-flex  flex-column`} id="scroll-component">
      <div className="poppins-regular fulfill-title p-4">{constVariables.FulfillmentCenter.package}</div>
      <div className="h-100 overflow-scroll pb-4 ps-4 pe-2">
        {showPackageData || orderShipment.id ? (
          <>
            {/* <div className="helper-text fs-8 mt-2 mb-6">{constVariables.FulfillmentCenter.packageDesc}</div> */}
            <div className="mb-5">
              <div className="fulfill-input-label">{constVariables.FulfillmentCenter.package}</div>
              <div className="position-relative d-flex outside-click-parent-div mb-2">
                <OutsideClickHandler
                  onOutsideClick={() => {
                    togglePackageDropdown(false);
                  }}
                >
                  <DropdownSelector
                    selectedValue={packageData.name}
                    text="Select package dimensions"
                    type="dimensionSelector"
                    onClick={() => {
                      if (!orderShipment.id) togglePackageDropdown(!packageDropdown);
                    }}
                    className={`px-3 form-control form-control-lg d-flex align-items-center justify-space-between ${
                      isDisablePackage ? 'disable-background disable-hover' : ''
                    } ${orderShipment.id ? 'disable-background disable-hover' : 'dropdown-box'} ${
                      packageDropdown ? 'dropdown-box-active' : ''
                    }`}
                  />
                  <Dropdown
                    className="full-width-dropdown mt-1"
                    data={dropdownList}
                    selected={packageDropdown}
                    value={packageData.name}
                    listClass={'taxwrap'}
                    onSelect={(data: any) => {
                      const packageObj = parcelData?.getParcelTemplates?.find(({ name }) => name === data.name);
                      setPackageData(packageObj);
                      if (!useWeightCheck) {
                        setPkgWeightValue(Math.round((packageObj?.weight ? packageObj.weight : 0.0 + Number.EPSILON) * 100) / 100);
                        setPkgUnitValue(packageObj?.massUnit ? packageObj?.massUnit : 'lb');
                      }
                      togglePackageDropdown(false);
                    }}
                    closeDropdown={() => togglePackageDropdown(false)}
                  />
                </OutsideClickHandler>
              </div>
              {packageData && packageData?.name && (
                <p className="order-dimensions-text poppins-regular">
                  {/* <span className="opacity-80">{constVariables.FulfillmentCenter.dimensions}</span> */}
                  {packageData?.length} x {packageData.width} x {packageData.height} {packageData.distanceUnit}
                </p>
              )}
            </div>
            <div className="fulfill-input-label">{constVariables.FulfillmentCenter.packageWeight}</div>
            <div className={`d-flex position-relative ${useWeightCheck ? 'disable-background' : ''}`}>
              <InputBox
                mainclass={`col ${useWeightCheck ? 'disable-background' : ''}`}
                inputClass={`right-border-flat form-control form-control-lg ${useWeightCheck ? 'disable-background' : ''}`}
                type="number"
                disable={useWeightCheck ? true : false}
                value={pkgWeightValue}
                onChangeText={changePkgWeightValue}
                name={constVariables.FulfillmentCenter.pkgWeightPlaceholder.trim()}
                placeholder={constVariables.FulfillmentCenter.pkgWeightPlaceholder}
                charLimit={99999999}
              />
              <div className="position-relative">
                <DropdownSelector
                  selectedValue={pkgUnitValue}
                  text="Select"
                  onClick={() => {
                    if (!useWeightCheck) changePkgWeight();
                  }}
                  className={` left-border-flat  form-control form-control-lg justify-content-between align-items-center cursor-pointer ${
                    selectPkgWeight ? 'dropdown-box-active' : ''
                  } ${useWeightCheck ? 'disable-background' : 'dropdown-box'}`}
                />
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setSelectPkgWeight(false);
                  }}
                >
                  <Dropdown
                    className="full-width-dropdown"
                    data={WeightCodes}
                    selected={selectPkgWeight}
                    value={pkgUnitValue}
                    onSelect={(data) => {
                      const converted = convertWeight(pkgWeightValue, pkgUnitValue.toUpperCase(), data.name.toUpperCase());
                      setPkgWeightValue(converted);
                      setPkgUnitValue(data.name);
                      setSelectPkgWeight(false);
                    }}
                    closeDropdown={() => setSelectPkgWeight(false)}
                  />
                </OutsideClickHandler>
              </div>
            </div>
            <Checkbox
              tagclassname="poppins-medium final-scale-checkbox"
              labelclassname="pb-3 mt-2"
              name={constVariables.FulfillmentCenter.useWeightOfItems}
              value={useWeightCheck}
              onChangeValue={changeWeightCheck}
              disable={orderShipment.id ? true : false}
            />
            <FlexBox className="mt-4 mb-2 w-100 justify-content-between align-items-center">
              <div className="shipment-options-text">{constVariables.FulfillmentCenter.shipmentOptions}</div>
              <KTSVG
                path={hideShipment ? UpArrowIcon : DownArrowIcon}
                className="cursor-pointer"
                onClick={() => {
                  setHideShipment(!hideShipment);
                }}
              />
            </FlexBox>
            <div className={`${hideShipment ? 'd-none' : ''}`}>
              <Checkbox
                tagclassname="poppins-medium  final-scale-checkbox"
                labelclassname="pb-3"
                name={constVariables.FulfillmentCenter.signatureConfirmation}
                disable={orderShipment.id ? true : false}
                value={addSignatureConfirmation}
                onChangeValue={() => {
                  setAddSignatureConfirmation(!addSignatureConfirmation);
                }}
              />
              <FlexBox className="align-items-center">
                <Checkbox
                  tagclassname="poppins-medium final-scale-checkbox"
                  labelclassname="pb-3 me-0"
                  name={constVariables.FulfillmentCenter.shipmentInsurance}
                  disable={orderShipment.id ? true : false}
                  defaultValue={addShipmentInsurance}
                  onChangeValue={() => {
                    setAddShipmentInsurance(!addShipmentInsurance);
                  }}
                />
                <img src={InfoIcon} className="px-3 cursor-pointer" data-tip data-for="shippingFulfillment" />
                <Tooltip
                  tooltipId="shippingFulfillment"
                  className="toolTipPay"
                  place="top"
                  content={() => {
                    return (
                      <div>
                        {constVariables.FulfillmentCenter.insuranceMessage}
                        <a className="text-primary">{constVariables.FulfillmentCenter.goHere}</a>
                      </div>
                    );
                  }}
                />
              </FlexBox>
              {addShipmentInsurance && (
                <>
                  <div className="mt-5 fulfill-input-label">{constVariables.FulfillmentCenter.totalValueOfOrder}</div>
                  <InputBox
                    mainclass="col"
                    inputClass="form-control-sm"
                    type="number"
                    disable={orderShipment.id ? true : false}
                    value={totalOrderValue ? totalOrderValue : ''}
                    onChangeText={(data: string) => {
                      // eslint-disable-next-line
                      setTotalOrderValue(parseInt(data));
                    }}
                    name={constVariables.FulfillmentCenter.totalValuePlaceholder.trim()}
                    placeholder={constVariables.FulfillmentCenter.totalValuePlaceholder}
                    charLimit={99999999}
                  />
                  <div className="mt-5 fulfill-input-label">{constVariables.FulfillmentCenter.packageContentForInsurance}</div>
                  <InputBox
                    mainclass="col"
                    inputClass="form-control-sm"
                    disable={orderShipment.id ? true : false}
                    value={pkgContentDescription}
                    onChangeText={(data: string) => {
                      setPkgContentDescription(data);
                    }}
                    name={constVariables.FulfillmentCenter.packageContentPlaceholder.trim()}
                    placeholder={constVariables.FulfillmentCenter.packageContentPlaceholder}
                    charLimit={99999999}
                  />
                </>
              )}
              <div ref={scrollEndRef} />
            </div>
          </>
        ) : (
          <img className="package-placeholder-img" src={NoPackageOptionImage} />
        )}
      </div>
    </div>
  );
};

export default PackageAndShippingFulfillmentCenter;
