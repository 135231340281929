import { gql } from '@apollo/client';

export const GET_PRODUCT_SUB_CATEGORIES = gql`
  query GetProductSubCategories($input: GetProductSubCategoriesInput) @api(name: "productsAPI") {
    getProductSubCategories(input: $input) {
      id
      name
    }
  }
`;
