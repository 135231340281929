import { gql } from '@apollo/client';

export const GET_VENDORS_PRODUCTS = gql`
  query getVendorProducts($input: GetVendorProductInput!) @api(name: "productsAPI") {
    getVendorProducts(input: $input) {
      products {
        id
        variantId
        name
        brand
        description
        brandStyle
        categoryName
        sku
        cost
        quantity
        medias
        isSelected
        createdAt
        updatedAt
      }
      total
    }
  }
`;
export const SET_PRODUCTS_STATUS = gql`
  mutation addOrRemoveProductFromSelected($input: SelectedProduct!) @api(name: "productsAPI") {
    addOrRemoveProductFromSelected(input: $input) {
      id
      success
      message
    }
  }
`;
