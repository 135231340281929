import { FC, useEffect, useCallback } from 'react';
import clsx from 'clsx';

// Components
import { Img } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { setImageSrc } from 'src/utils/setImageSrc';

// Types
import { DropdownProps } from './Dropdown.types';

// Styles
import './_dropdownStyle.scss';

const Dropdown: FC<DropdownProps> = ({
  data,
  showImage,
  selected,
  onSelect,
  value,
  className,
  shiftLeft,
  lastElement,
  toTop,
  onBlur,
  shiftLeftValue = '-150px',
  listClass,
  noDisableEffect,
  searchDropDown,
  noScroll = false,
  closeDropdown
}) => {
  const toRight = () => {
    if (!shiftLeft) {
      return { right: '0px' };
    }
    return null;
  };
  const toLeft = () => {
    if (shiftLeft) {
      return { left: shiftLeftValue };
    }
    return null;
  };
  const lastEle = () => {
    if (lastElement) {
      return { top: toTop };
    }
    return null;
  };

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      closeDropdown();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction);

    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction]);

  return (
    <div
      className={clsx(
        `${!noScroll ? '' : 'no-scroll-dropdown'} dropdown-menu z-index-dropdown custom-dropdown ${
          lastElement ? `lastDropdownElement ` : ''
        }`,
        { show: selected },
        className
      )}
      style={{ ...toRight(), ...toLeft(), ...lastEle() }}
      onBlur={onBlur}
      aria-labelledby="dropdownMenuButton"
    >
      {data && data.length === 0 ? (
        <div className={`d-flex justfy-content-center align-items-center dropdown-item dropdown-height ${listClass} `}>
          No items found
        </div>
      ) : (
        data &&
        data?.map((item, index) => {
          return (
            <div
              title={item.name}
              onClick={(e) => {
                if (!item.disabled) {
                  onSelect(item, e);
                }
              }}
              className={`d-flex justfy-content-center align-items-center dropdown-item dropdown-height ${listClass} ${
                searchDropDown ? 'show-effects' : ''
              } ${item.disabled ? 'opacity-50' : 'cursor-pointer'} `}
              key={`dropdown-${index}`}
            >
              {showImage && (
                <Img
                  src={setImageSrc(item?.thumbnailUrl ? item?.thumbnailUrl : '')}
                  alt={item.name}
                  className="h-40px w-40px rounded-2"
                />
              )}
              {item?.icon && (
                <KTSVG
                  svgColor={item.name.toUpperCase() === 'DELETE' || item.name.toUpperCase() === 'CANCEL ORDER' ? '#FF0000' : ''}
                  path={item?.icon}
                  className="me-4"
                />
              )}
              <div key={index} aria-labelledby="dropdownMenuButton" className="dropdown-item-option">
                <span
                  className={`option-text poppins-medium ${
                    !noDisableEffect && value?.toLowerCase() === item?.name?.toLowerCase() ? '' : ''
                  }${
                    item.name.toUpperCase() === 'DELETE' ||
                    item.name.toUpperCase() === 'CANCEL ORDER' ||
                    item.name.toUpperCase() === 'DELETE CUSTOMER' ||
                    item.name.toUpperCase() === 'DELETE ADDRESS' ||
                    item.name.toUpperCase() === 'DELETE COLLECTION'
                      ? 'dropdown-item-red '
                      : ''
                  } ${item.className}`}
                >
                  {item.name}
                </span>
                {/* {item?.extraData && <span className="dropdown-item text-black-50">{item?.extraData}</span>} */}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default Dropdown;
