import React, { useState, createRef } from 'react';
import { CellProps } from 'react-datasheet-grid';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useMutation } from '@apollo/client';
import { CREATE_COLLECTION_OR_TAG_OR_CATALOGUE } from 'src/apollo/mutations';

// Components
import { Portal, TagAndCollectionDropdown } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { DownArrowIcon } from 'src/assets/icons';

// ConstVariables
import { TagColorsExtra } from 'src/components/oraganisms/OrdersList/tagColors';

const TagCell = React.memo(({ rowData, setRowData, active, focus, columnData }: CellProps) => {
  const tagCellRef = createRef<any>();

  const [showMoreDropdown, setShowMoreDropdown] = useState<boolean>(false);
  const [showAllDropdown, setShowAllDropdown] = useState<boolean>(false);
  const [moreDropDownPositions, setMoreDropDownPositions] = useState({
    top: 0,
    left: 0
  });
  const [allDropDownPositions, setAllDropDownPositions] = useState({
    top: 0,
    left: 0
  });

  const [addToTag] = useMutation(CREATE_COLLECTION_OR_TAG_OR_CATALOGUE, {
    onCompleted: (res) => {
      if (res?.createCollectionOrCatalogueOrTag) {
        const newTag = res?.createCollectionOrCatalogueOrTag;
        const obj = {
          id: newTag?.id,
          name: newTag?.name,
          textColorCode: newTag?.textColorCode,
          backgroundColorCode: newTag?.backgroundColorCode
        };
        columnData.updateTagsList(obj);
      }
    },
    onError: () => {
      console.error('');
    }
  });

  const handleOpenMoreDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowMoreDropdown(true);

    if (tagCellRef.current) {
      const position = tagCellRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - position.bottom;
      setMoreDropDownPositions({
        top: spaceBelow < 180 ? window.scrollY + position.top - 160 : window.scrollY + position.top + 71,
        left: position.left + 180
      });
    }
  };

  const closeMoreDropdown = () => {
    setShowMoreDropdown(false);
  };

  const handleOpenAllDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (focus) {
      setShowAllDropdown(true);

      if (tagCellRef.current) {
        const position = tagCellRef.current.getBoundingClientRect();
        const spaceBelow = window.innerHeight - position.bottom;
        setAllDropDownPositions({
          top: spaceBelow < 400 ? window.scrollY + position.top - 363 : window.scrollY + position.top + position.height,
          left: position.left + 180
        });
      }
    }
  };

  const closeAllDropdown = () => {
    setShowAllDropdown(false);
  };

  const handleRowDataTags = (item: any, type: string, action: string) => {
    const arr: any = [...rowData?.productTagsJson];
    if (action === 'remove' || arr.indexOf(item) !== -1) {
      const i = arr.indexOf(item);
      arr.splice(i, 1);
    } else if (arr.indexOf(item) === -1 && action !== 'remove') {
      arr.push(item);
    }
    const updatedRowData = { ...rowData, productTagsJson: arr };
    setRowData(updatedRowData);
  };

  return (
    <>
      <div className="d-flex align-items-center w-100 h-100 px-2" ref={tagCellRef} onClick={handleOpenAllDropdown}>
        {rowData?.productTagsJson?.length > 0 ? (
          <div className="d-flex flex-wrap w-100 position-relative align-items-center">
            {rowData?.productTagsJson?.slice(0, 1).map((item, index) => {
              return (
                <div
                  className={`text-nowrap tag-item`}
                  style={{
                    color: item?.textColorCode,
                    backgroundColor: item?.backgroundColorCode,
                    borderRadius: '8px'
                  }}
                  key={index}
                >
                  {item?.name}
                </div>
              );
            })}
            {rowData?.productTagsJson?.length > 1 && (
              <div
                className="d-flex align-items-center rounded tag-item limited-tag"
                style={{ backgroundColor: TagColorsExtra.background, color: TagColorsExtra.color }}
                onClick={handleOpenMoreDropdown}
              >
                <div>+{rowData?.productTagsJson?.length - 1}</div>
                <KTSVG path={DownArrowIcon} />
              </div>
            )}
          </div>
        ) : (
          <span>-</span>
        )}
      </div>

      {/* Show the rest tags on the Dropdown */}
      {showMoreDropdown && (
        <Portal id="kt_body">
          <OutsideClickHandler
            onOutsideClick={() => {
              if (showMoreDropdown) closeMoreDropdown();
            }}
          >
            <div className="position-absolute" style={{ top: moreDropDownPositions.top, left: moreDropDownPositions.left }}>
              <TagAndCollectionDropdown
                type="Tag"
                onlyAppliedTags={true}
                allItems={columnData?.tagsList}
                appliedItems={rowData?.productTagsJson?.slice(1, rowData?.productTagsJson?.length) || []}
                selected={showMoreDropdown}
                isBulkEditorModal={true}
                isCreatable
                isEditAndDeletable={true}
                closeDropdown={closeMoreDropdown}
                className="bulk-editor-dropdown"
              />
            </div>
          </OutsideClickHandler>
        </Portal>
      )}

      {/* Show the all tags on the Dropdown */}
      {showAllDropdown && (
        <Portal id="kt_body">
          <OutsideClickHandler
            onOutsideClick={() => {
              if (showAllDropdown) closeAllDropdown();
            }}
          >
            <div className="position-absolute" style={{ top: allDropDownPositions.top, left: allDropDownPositions.left }}>
              <TagAndCollectionDropdown
                type="Tag"
                allItems={columnData?.tagsList}
                appliedItems={rowData?.productTagsJson}
                changeAppliedItems={handleRowDataTags}
                selected={showAllDropdown}
                addToTag={addToTag}
                isBulkEditorModal={true}
                isCreatable
                isEditAndDeletable={true}
                closeDropdown={closeAllDropdown}
                className="bulk-editor-dropdown"
              />
            </div>
          </OutsideClickHandler>
        </Portal>
      )}
    </>
  );
});

export default TagCell;
