import { gql } from '@apollo/client';

export const GET_COLLECTIONS = gql`
  query getCollections($input: GetCollectionsInput) @api(name: "productsAPI") {
    getCollections(input: $input) {
      totalRecords
      collections {
        id
        name
        status
        orderNo
        productCount
        type
        description
        channels
        thumbnailUrl
        userCreatedThis
        userUpdatedThis
        parent
        droppable
        productIds
        text
        rulesMatch
        productAssignmentRules
        productAssignmentRulesLayout
        externalCollectionProvider
        externalCollectionHandle
        externalCollectionId
        externalProductIds
      }
    }
  }
`;

export const GET_COLLECTION_NAMES = gql`
  query getCollectionNames @api(name: "productsAPI") {
    getCollectionNames {
      names
    }
  }
`;

export const COLLECTIONS_TOTAL = gql`
  query getCollectionTotal @api(name: "productsAPI") {
    getCollectionTotal
  }
`;

export const UPDATE_COLLECTION_NAME = gql`
  mutation updateCollectionByName($input: UpdateCollectionByNameInput) @api(name: "productsAPI") {
    updateCollectionByName(input: $input) {
      __typename
      ... on GenericResponse {
        success
        message
      }
    }
  }
`;

export const DELETE_COLLECTION = gql`
  mutation deleteCollection($id: Int!) @api(name: "productsAPI") {
    deleteCollection(id: $id) {
      success
      message
    }
  }
`;

export const CREATE_COLLECTION_BY_NAME = gql`
  mutation createCollectionByName($input: CreateCollectionByNameInput!) @api(name: "productsAPI") {
    createCollectionByName(input: $input) {
      id
      name
      droppable
      text
      status
      type
      productCount
    }
  }
`;

export const DND_UPDATE_COLLECTION = gql`
  mutation dndUpdateCollection($input: [DndUpdateCollectionInput]!) @api(name: "productsAPI") {
    dndUpdateCollection(input: $input) {
      __typename
      ... on GenericResponse {
        id
        success
        message
      }
    }
  }
`;
