import { FC } from 'react';

// Components
import { RadioButton } from 'src/components/atoms';
import { Divider } from 'src/components/molecules';
import RulesEngineConditions from '../RulesEngine/RulesEngineConditions';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { TypeSectionTypes } from './TypeSection.typs';
import { productAssignmentMethod } from '../../pages/collections/CreateCollection/CreateCollection.types';

const TypeSection: FC<TypeSectionTypes> = ({
  title,
  showSubtitle = true,
  selectedProductAssignmentMethod,
  selectProductAssignmentMethod,
  rulesEngineConditions,
  setRulesEngineConditions,
  fieldDropdown,
  conditionDropdownArray,
  setConditionDropdownArray,
  allConditionMatch,
  setAllConditionMatch,
  setFormChanges,
  setRulesChanges,
  saveModuleRules,
  rulesChanges,
  typeDescription
}) => {
  return (
    <div className="card w-100">
      {title && <h4 className="section-title m-b-24">{title}</h4>}
      {showSubtitle && (
        <h6 className="section-subtitle m-b-24">{constVariables.ProductCategoryMenu.collections.collectionTypeMsg}</h6>
      )}
      <div className="d-flex align-items-center w-100">
        <RadioButton
          id="manualProductAssignment"
          name="productsAssignment"
          isChecked={selectedProductAssignmentMethod === productAssignmentMethod.MANUAL}
          handleChange={(e) => {
            setFormChanges(true);
            selectProductAssignmentMethod(productAssignmentMethod.MANUAL);
          }}
          noMargin
        />
        <label className="mb-0 ms-2 opacity-80 thicccboi-bold">
          {constVariables.ProductCategoryMenu.collections.collectionTypeList[0].title}
        </label>
      </div>

      <div className="d-flex align-items-center ml-3 w-100">
        <p className="ms-5 opacity-50 mb-0">
          Add products to {typeDescription} one by one by manually selecting {typeDescription} during product creation or update.
        </p>
      </div>

      <Divider classname="divider-status m-y-24" />

      <div className="d-flex align-items-center w-100">
        <RadioButton
          id="automaticProductAssignment"
          name="productsAssignment"
          isChecked={selectedProductAssignmentMethod === productAssignmentMethod.AUTOMATIC}
          handleChange={(e) => {
            setFormChanges(true);
            selectProductAssignmentMethod(productAssignmentMethod.AUTOMATIC);
          }}
          noMargin
        />
        <label className="mb-0 ms-2 opacity-80 thicccboi-bold">
          {constVariables.ProductCategoryMenu.collections.collectionTypeList[1].title}
        </label>
      </div>

      <div className="d-flex align-items-center ml-3 w-100">
        <p className="ms-5 opacity-50 mb-0">
          Products matched with the following conditions will be automatically assigned to {typeDescription}.
        </p>
      </div>

      <div className={`${selectedProductAssignmentMethod === productAssignmentMethod.AUTOMATIC ? '' : 'd-none'}`}>
        <RulesEngineConditions
          rulesEngineConditions={rulesEngineConditions}
          setRulesEngineConditions={setRulesEngineConditions}
          fieldDropdown={fieldDropdown}
          conditionDropdownArray={conditionDropdownArray}
          setConditionDropdownArray={setConditionDropdownArray}
          allConditionMatch={allConditionMatch}
          setAllConditionMatch={setAllConditionMatch}
          setFormChanges={setFormChanges}
          setRulesChanges={setRulesChanges}
          rulesChanges={rulesChanges}
          saveModuleRules={saveModuleRules}
        />
      </div>
    </div>
  );
};

export default TypeSection;
