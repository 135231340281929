import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Apollo
import { useQuery, useMutation } from '@apollo/client';
import { GET_STORE_USERS, GET_ROLES } from 'src/apollo/queries';
import { DELETE_BACKSTORE_USER, UPDATE_INVITE_DETAILS } from 'src/apollo/mutations';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import { SearchBar, FixedHeaderTable } from 'src/components/molecules';
import { CustomModal, DeleteConfirmModal } from 'src/components/oraganisms';
import InviteModal from './InviteModal';
import MoreActions from './MoreActions';
import UserRole from './UserRole';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';
import useTitle from 'src/utils/hooks/useTitle';

// Icons
import { BackRoundedIcon, DefaultImage } from 'src/assets/icons';

// ConstVariables
import { constVariables, ROUTES } from 'src/constants';

// Types
import { TablesProps } from 'src/components/molecules/Table/Table.types';
import { StoreUser, RoleItem, UserRoles } from './UserPermissions.type';

// Styles
import './_userPermissions.scss';

const UserPermissions: FC = () => {
  const history = useHistory();
  useTitle('Settings - Users and Permissions');
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [users, setUsers] = useState<StoreUser[]>([]);
  const [roles, setRoles] = useState<RoleItem[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<StoreUser>();
  const [sortBy, setSortby] = useState({
    column: 'NAME',
    order: 'ASC'
  });
  const [inviteMode, setInviteMode] = useState<string>('invite');

  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  useEffect(() => {
    if (role === UserRoles.ADMIN) {
      const updatedRoles = roles.filter((roleItem) => roleItem.role !== 'ROLE_OWNER');
      setRoles(updatedRoles);
    }
  }, [role]);

  const ChangeSortBy = (field: string) => {
    if (sortBy.column === field) {
      if (sortBy.order === 'DESC') {
        setSortby({ ...sortBy, order: 'ASC' });
      } else {
        setSortby({ ...sortBy, order: 'DESC' });
      }
    } else {
      setSortby({ column: field, order: 'DESC' });
    }
  };

  const { loading, refetch } = useQuery(GET_STORE_USERS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        filters: {
          searchText
        },
        sortBy: {
          fieldID: sortBy.column,
          type: sortBy.order
        }
      }
    },
    onCompleted: (res) => {
      setUsers(res?.getBackStoreUsers?.users);
    },
    onError: (err) => {
      console.error('Get all users ', err);
    }
  });

  useQuery(GET_ROLES, {
    onCompleted: (res) => {
      setRoles(
        res?.getRoles?.roles.map((role) => {
          return { ...role, id: parseInt(role.id, 10) };
        })
      );
    }
  });

  const [deleteBackStoreUser, { loading: isDeleteLoading }] = useMutation(DELETE_BACKSTORE_USER, {
    onCompleted: (data) => {
      setSelectedUser(undefined);
      const updatedUsers = users.filter((user) => user.storeUserId !== parseInt(data.deleteBackstoreUser.userId, 10));
      setUsers(updatedUsers);
      closeDeleteConfirmModal();
    },
    onError: (e) => {
      console.error(e);
      setSelectedUser(undefined);
      closeDeleteConfirmModal();
    }
  });

  const [updateInviteDetails] = useMutation(UPDATE_INVITE_DETAILS, {
    onCompleted: (data) => {
      setSelectedUser(undefined);
      refetch();
      // const updatedUsers = users.filter((user) => user.storeUserId !== parseInt(data.deleteBackstoreUser.userId, 10));
      // setUsers(updatedUsers);
      // closeDeleteConfirmModal();
    },
    onError: (e) => {
      setSelectedUser(undefined);
      console.error(e);
    }
  });

  const returnBack = () => {
    history.push(ROUTES.settings.main);
  };

  const handleUserAction = (id: number, name: string, user: StoreUser) => {
    setSelectedUser(user);
    if (name === 'Delete') {
      setShowDeleteConfirmModal(true);
    } else {
      setInviteMode('reinvite');
      setShowInviteModal(true);
    }
  };

  const handleChangeUserRole = (id: number, name: string, userId: number) => {
    updateInviteDetails({
      variables: {
        input: {
          role: id,
          storeUserId: userId
        }
      }
    });
  };

  const renderStatus = (status: string) => {
    switch (status) {
      case 'PENDING':
        return 'Pending';
      default:
        return '';
    }
  };

  let tableData: TablesProps = {};
  tableData = {
    headerClassName: 'position-relative ',
    isDragDisabled: true,
    headers: [
      {
        id: 1,
        className: 'py-0 ps-4 pe-0 align-middle table-header-background w-350px',
        colName: 'Name',
        sorting: true,
        applySort: () => {
          ChangeSortBy('NAME');
        }
      },
      {
        id: 2,
        className: 'py-0 align-middle table-header-background w-200px',
        colName: 'Roles',
        sorting: true,
        applySort: () => {
          ChangeSortBy('ROLE');
        }
      },
      {
        id: 3,
        className: 'px-0 py-0 align-middle table-header-background w-100px',
        colName: 'Last login',
        sorting: true,
        applySort: () => {
          ChangeSortBy('LAST_LOGGED_IN');
        }
      },
      {
        id: 4,
        className: 'py-0 align-middle table-header-background w-50px',
        colName: ''
      }
    ],
    rows: users?.map((user, index) => ({
      id: user.storeUserId,
      isDragDisabled: true,
      className: 'position-relative border-top-0 dontScrollBulkEditorTop',
      cells: [
        {
          className: `w-350px align-middle ps-4 pe-0 py-0 ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: (
            <FlexBox className="align-items-center">
              <div className="symbol me-5">
                <Img
                  className="object-fit-scale-down bg-white border border-light border-2"
                  src={setImageSrc(user?.profileUrl ? generateCompressedImageURL(user?.profileUrl, '1024') : DefaultImage)}
                  errorImg={DefaultImage}
                  placeholderImg={DefaultImage}
                  height={50}
                  width={50}
                />
              </div>
              <div>
                <div className="main-cell no-decoration">
                  <span className="d-block">
                    {user?.firstName} {user?.lastName}
                  </span>
                </div>
                <div>{user.email}</div>
              </div>
            </FlexBox>
          )
        },
        {
          className: `align-middle text-muted py-0 align-middle w-200px ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: <UserRole role={user.role} roles={roles} userId={user.storeUserId} runChangeUserRole={handleChangeUserRole} />
        },
        {
          className: `align-middle text-muted px-0 py-0 w-100px ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: <div>{user.lastLoggedinDate ? user.lastLoggedinDate : <span>{renderStatus(user.invitationStatus)}</span>}</div>
        },
        {
          className: `align-middle text-muted w-50px ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: <MoreActions user={user} userAction={handleUserAction} />
        }
      ]
    }))
  };

  const closeInviteModal = () => {
    document.body.style.overflow = 'unset';
    setShowInviteModal(false);
  };

  const closeDeleteConfirmModal = () => {
    document.body.style.overflow = 'unset';
    setShowDeleteConfirmModal(false);
  };

  const handleDeleteUser = () => {
    deleteBackStoreUser({
      variables: {
        input: {
          email: selectedUser?.email
        }
      }
    });
  };

  const handleChangeSearchText = (value) => {
    setSearchText(value);
  };

  return (
    <>
      <div className="user-permissions-page p-40">
        <div className="user-permissions-page-container">
          <FlexBox className="align-items-center justify-content-between m-y-40">
            <FlexBox className="align-items-center">
              <img src={BackRoundedIcon} alt={BackRoundedIcon} onClick={returnBack} className="cursor-pointer" />
              <h2 className="page-title px-4 mb-0">{constVariables.userPermissions.pageName}</h2>
            </FlexBox>
            <button className="btn btn-primary btn-sm m-0" onClick={() => setShowInviteModal(true)}>
              Invite User
            </button>
          </FlexBox>
          <div className="card m-0">
            <div className="w-50 m-b-20">
              <SearchBar placeholder="Search for users" onChange={handleChangeSearchText} />
            </div>
            <FixedHeaderTable
              headers={tableData.headers}
              rows={tableData.rows}
              loadingPosition="center"
              containerHeight={700}
              type="users"
              isLoading={loading}
            />
          </div>
        </div>
      </div>
      {showInviteModal && (
        <CustomModal
          show={showInviteModal}
          closeModal={closeInviteModal}
          bodyClassname="d-flex justify-content-center w-90 w-md-150"
        >
          <InviteModal
            roles={roles}
            closeModal={closeInviteModal}
            refetchUsers={refetch}
            inviteMode={inviteMode}
            user={selectedUser}
          />
        </CustomModal>
      )}
      {showDeleteConfirmModal && (
        <CustomModal
          show={showDeleteConfirmModal}
          closeModal={closeDeleteConfirmModal}
          bodyClassname="d-flex justify-content-center w-90 w-md-150"
        >
          <DeleteConfirmModal
            title="Delete User"
            message="Are you sure you want to delete user?"
            cancelBtnTitle="Cancel"
            actionBtnTitle="Yes, Delete"
            loadingBtnTitle="Deleting"
            cancelBtnHandler={closeDeleteConfirmModal}
            actionBtnHandler={handleDeleteUser}
            isDeleteLoading={isDeleteLoading}
          />
        </CustomModal>
      )}
    </>
  );
};

export default UserPermissions;
