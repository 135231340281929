import { gql } from '@apollo/client';

export const GET_USER_LIVE_CART = gql`
  query getUserLiveCart($input: GetUserCartInput!) @api(name: "liveStreamingAPI") {
    getUserLiveCart(input: $input) {
      id
      productName
      quantity
      price
      imageUrl
    }
  }
`;
