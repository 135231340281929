import { gql } from '@apollo/client';

export const CREATED_SHIPMENT_DETAILS = gql`
  query GetCreatedShipmentDetails($input: GetCreatedOrderShipmentInput!) @api(name: "ordersAPI") {
    getCreatedShipmentDetails(input: $input) {
      id
      orderId
      orderCode
      orderDate
      trackingNumber
      shipmentStatus
      shipmentDate
      customerName
      customerImage
      items {
        id
        image
        name
        variant
        quantity
        price
        warehouseLocation {
          warehouseId
          inventoryPosition
        }
      }
    }
  }
`;
