/* eslint-disable dot-notation */
import { refreshToken } from 'src/helpers/InitFirebase';
import firebase from 'firebase/app';
import 'firebase/app';
import { getAccountDetailsFromToken } from 'src/utils/authentication/decodeToken';

export const getNewAccessToken = async (): Promise<boolean> => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<boolean>(async (resolve) => {
    const response: any = await refreshToken();
    if (response) {
      localStorage.setItem('token', response);
      resolve(true);
    } else {
      resolve(false);
    }
  });
};

firebase.auth().onAuthStateChanged(async function (user) {
  // console.log('user...');
});

export const getNewFirebaseToken = async (): Promise<string | null> => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<string | null>(async (resolve) => {
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser?.getIdToken()
        .then((token) => {
          const utcTimeStamp = Math.floor(new Date().getTime());

          const decodedToken = getAccountDetailsFromToken(token);

          let difference = utcTimeStamp - decodedToken.auth_time * 1000;

          const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
          difference -= daysDifference * 1000 * 60 * 60 * 24;

          const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
          difference -= hoursDifference * 1000 * 60 * 60;

          const minutesDifference = Math.floor(difference / 1000 / 60);
          difference -= minutesDifference * 1000 * 60;
          const secondsDifference = Math.floor(difference / 1000);
          if (daysDifference >= 30) {
            localStorage.removeItem('token');
            localStorage.removeItem('storeId');

            resolve(null);
          } else {
            localStorage.setItem('token', token);
            resolve(token);
          }
        });
    } else {
      resolve(null);
    }
  });
};
