import { gql } from '@apollo/client';

export const GET_PRODUCTS_V3 = gql`
  query GetAllProductV3($input: AllProductsInputV3) @api(name: "productsAPI") {
    getAllProductsV3(input: $input) {
      totalProducts
      products {
        __typename
        ... on AllProductsStoreOwnerOutputV3 {
          productId
          productName
          productDescription
          productTags
          collectionIds
          vendorId
          vendorName
          # productMediaIds
          productCategoryId
          productCategoryName
          productTypeId
          productTypeName
          productAvailableFrom
          isProductScheduled
          salePrice
          perItemCost
          profit
          shippingPrice
          productSku
          productBarcode
          productStoreId
          productQty
          committedQuantity
          alertThreshold
          usersQuantity
          sensitive
          sellOnOutOfStock
          addSignatureConfirmation
          addShipmentInsurance
          isFinalSale
          dimensions
          taxCode
          retailPrice
          showRetailPrice
          productStatus
          visibility
          attachImageTo
          brand
          brandStyle
          productCreatedAt
          productUpdatedAt
          sellOnOutOfStock
          inventory
          productHasVariants
          margin
          productLocation {
            warehouseId
            productLocationId
            productLocationName
            productLocationQuantity
          }
          productTagsJson {
            backgroundColorCode
            id
            name
            textColorCode
          }
          productCollectionJson {
            id
            name
            type
            description
          }
          productMediasJson {
            productMediaId
            productMediaUrl
            productMediaPosition
            productMediaType
          }
          recordings {
            id
            url
            isPrimary
            thumbnailUrl
            duration
            title
            createdAt
            position
          }
          primaryVideoId
          autoAssignPrimaryVideo
          externalProductId
          externalProductHandle
          externalProductProvider
          tiktokSync
          tiktokId
          variantLocationDetails {
            productVariantId
            productVariantSku
            productVariantBarcode
            productVariantQty
            productVariantPrice
            variantUsersQuantity
            variantAlertThreshold
            variantStatus
            variantWeight
            variantCommittedQty
            variantMediaId
            variantMediaUrl
            variantMediaType
            variantTypes {
              variantTypeId
              variantTypeName
              variantTypeValue
            }
            variantLocation {
              warehouseId
              productVariantLocationId
              productVariantLocationName
              productVariantLocationQuantity
            }
          }
        }
      }
    }
  }
`;
