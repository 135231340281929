import { FC } from 'react';

// Styles
import './emptyList.scss';

type EmptyListProps = {
  title?: string;
  description?: string;
  addButtonText?: string;
  status?: string;
  datatype?: string;
  onAddButton?: () => void;
  icon: string;
  isPage?: boolean;
};

const EmptyList: FC<EmptyListProps> = ({ title, description, status, datatype, addButtonText, onAddButton, icon, isPage }) => {
  const renderStatus = (status: string) => {
    return status === 'archive' ? `${status}d` : status;
  };

  return (
    <div
      className={`d-flex align-items-center justify-content-center flex-column bg-white empty-data-section ${
        isPage ? 'page-empty' : 'section-empty'
      } w-100`}
    >
      <img src={icon} alt="" />
      {title && <h5>{title}</h5>}
      {description && <p className={isPage ? '' : 'mt-5'}>{description}</p>}
      {status && datatype && (
        <p className={isPage ? '' : 'mt-5'}>
          No <strong>{renderStatus(status.toLowerCase())}</strong> {datatype} found
        </p>
      )}
      {addButtonText && onAddButton && (
        <button className="btn btn-primary btn-md" onClick={onAddButton}>
          {addButtonText}
        </button>
      )}
    </div>
  );
};

export default EmptyList;
