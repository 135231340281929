import React, { FC, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { FlexBox } from 'src/components/atoms';
import { Dropdown, Portal } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers/components/KTSVG';

// Icons
import { DownArrowIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { UserRoles } from 'src/components/pages/settings/UserPermissions/UserPermissions.type';

interface ProductStatusProps {
  product: any;
  runChangeProductStatus: (data: any, productId: any) => void;
}

const ProductStatus: FC<ProductStatusProps> = ({ product, runChangeProductStatus }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const statusRef = React.createRef<any>();
  const [dropdownPositions, setDropdownPositions] = useState({
    top: 0,
    left: 0
  });

  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  const selectProductStatusClass = (productStatus: string | undefined) => {
    switch (productStatus) {
      case constVariables.ProductsTab.active.toLowerCase():
        return 'active';
      case constVariables.ProductsTab.draft.toLowerCase():
        return 'draft';
      default:
        return 'archive';
    }
  };

  const handleStatus = () => {
    setShowDropdown(true);

    if (statusRef.current) {
      const position = statusRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - position.bottom;
      setDropdownPositions({
        top: spaceBelow < 180 ? window.scrollY + position.top - 220 : window.scrollY + position.top - 20,
        left: position.left - 30
      });
    }
  };

  const renderProductStatus = (productStatus) => {
    return constVariables.statusDropdownData.find((status) => {
      if (productStatus === 'ARCHIVED') {
        return status.name.toLowerCase() === 'archive';
      }
      return status.name.toLowerCase() === productStatus?.toLowerCase();
    })?.name;
  };

  return (
    <>
      {product?.productStatus?.toLowerCase() === 'active' && new Date(product?.productAvailableFrom) > new Date() ? (
        <div className="d-flex p-2 poppins-medium justify-content-center scheduled-status-box">Scheduled</div>
      ) : (
        <div>
          <FlexBox
            className={`p-2 poppins-medium cursor-pointer justify-content-between ${selectProductStatusClass(
              product?.productStatus?.toLowerCase()
            )}-status-box ${role === UserRoles.FULFILMENT_ASSOCIATE ? 'disable-background' : ''}`}
            onClick={handleStatus}
          >
            <div className="d-flex justify-content-between" ref={statusRef}>
              <span>{renderProductStatus(product?.productStatus)}</span>
              <KTSVG
                path={DownArrowIcon}
                className={`m-auto-0 ${selectProductStatusClass(product?.productStatus?.toLocaleLowerCase())}-arrow`}
              />
            </div>
          </FlexBox>
          {showDropdown && (
            <Portal id="kt_body">
              <OutsideClickHandler
                onOutsideClick={() => {
                  if (showDropdown) setShowDropdown(false);
                }}
              >
                <div className="position-absolute" style={{ top: dropdownPositions.top, left: dropdownPositions.left }}>
                  <Dropdown
                    data={constVariables.statusDropdownData}
                    selected={showDropdown}
                    value={product.productStatus}
                    className="top-55"
                    onSelect={(value) => {
                      runChangeProductStatus(value, product.productId);
                      setShowDropdown(false);
                    }}
                    closeDropdown={() => setShowDropdown(false)}
                  />
                </div>
              </OutsideClickHandler>
            </Portal>
          )}
        </div>
      )}
    </>
  );
};

export default ProductStatus;
