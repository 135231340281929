import { gql } from '@apollo/client';

export const GET_VENDOR_NOTE = gql`
  query GetVendorNote($input: VendorNotes!) @api(name: "productsAPI") {
    getVendorNote(input: $input) {
      userName
      imageUrl
      note
      updatedAt
      id
    }
  }
`;
export const ADD_VENDOR_NOTES = gql`
  mutation AddVendorNote($input: VendorInputInput!) @api(name: "productsAPI") {
    addVendorNote(input: $input) {
      __typename
      ... on GenericResponse {
        success
        message
      }
    }
  }
`;
export const DELETE_VENDOR_NOTES = gql`
  mutation deleteVendorNote($input: EditOrDeleteVendorNotes!) @api(name: "productsAPI") {
    deleteVendorNote(input: $input) {
      __typename
      ... on GenericResponse {
        success
        message
      }
    }
  }
`;
export const EDIT_VENDOR_NOTES = gql`
  mutation editVendorNote($input: EditOrDeleteVendorNotes!) @api(name: "productsAPI") {
    editVendorNote(input: $input) {
      __typename
      ... on GenericResponse {
        success
        message
      }
    }
  }
`;
export const EDIT_VENDOR_STATUS = gql`
  mutation changeVendorStatus($input: VendorStatusChange!) @api(name: "productsAPI") {
    changeVendorStatus(input: $input) {
      __typename
      ... on GenericResponse {
        success
        message
      }
    }
  }
`;
