import { FC } from 'react';
import clsx from 'clsx';

// Components
import { Loader } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { CloseIcon } from 'src/assets/icons';

// Types
import { DiscardModalProps } from './Modal.types';

// Styles
import './modalStyles.scss';

const DiscardModal: FC<DiscardModalProps> = ({
  actionButtonClassName,
  deleteProductMedia,
  actionBtnHandler,
  cancelBtnHandler,
  cancelBtnTitle,
  actionBtnTitle,
  message,
  title,
  isPrimaryBtn,
  isLoading
}) => {
  const handleCancelAction = () => {
    document.body.style.overflow = 'unset';
    cancelBtnHandler && cancelBtnHandler();
  };

  const handleAction = () => {
    document.body.style.overflow = 'unset';
    actionBtnHandler && actionBtnHandler();
  };

  return (
    <div
      className={clsx('modal-content default-modal', {
        'modal-large-content': deleteProductMedia === true
      })}
    >
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <div className="btn btn-xs btn-active-light-primary p-0 m-0 border-none" onClick={handleCancelAction}>
          <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
        </div>
      </div>
      <div
        className={clsx('modal-body p-x-30 p-y-36', {
          mediaGreyText: deleteProductMedia === true
        })}
      >
        <div>{message}</div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-outlined-secondary btn-md" onClick={handleCancelAction} data-bs-dismiss="modal" type="button">
          {cancelBtnTitle}
        </button>
        <button
          className={`btn btn-md ${isPrimaryBtn ? 'btn-primary' : 'btn-danger'} ${actionButtonClassName}`}
          onClick={handleAction}
          type="button"
          disabled={isLoading}
        >
          {actionBtnTitle}
          {isLoading && <Loader type="button" className="h-10px w-10px" />}
        </button>
      </div>
    </div>
  );
};

export default DiscardModal;
