import { gql } from '@apollo/client';

export const VERIFY_USER_EMAIL = gql`
  mutation verifyUserMail($input: VerifyUserMailInput!) @api(name: "authAPI") {
    verifyUserMail(input: $input) {
      success
      message
      userId
    }
  }
`;
