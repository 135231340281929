import { gql } from '@apollo/client';

export const GET_APP_THEME = gql`
  query getAppTheme @api(name: "authAPI") {
    getAppTheme {
      primaryColor
      secondaryColor
      bgColor
      appLogo
      status
    }
  }
`;
