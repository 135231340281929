import { DefaultTheme } from 'styled-components';
import light from './light';
import dark from './dark';

export type Themes = {
  dark: DefaultTheme;
  light: DefaultTheme;
};

const themes: Themes = {
  dark,
  light
};

export default themes;
