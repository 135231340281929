import { FC } from 'react';

// Types
import { RadioButtonProps } from './RadioButton.types';

const RadioButton: FC<RadioButtonProps> = ({ id, name, isChecked, handleChange, noMargin }) => {
  return (
    <input
      type="radio"
      id={id}
      name={name}
      className={`mt-0  cursor-pointer ${noMargin ? '' : 'ms-3'}`}
      checked={isChecked}
      onChange={handleChange}
    />
  );
};

export default RadioButton;
