import React, { FC } from 'react';
import clsx from 'clsx';

// Components
import OptionItem from './OptionItem';

// Types
import { SelectDropdownMenuProps } from './SearchSelectDropdown.types';

// Styles
import './_searchSelectDropdown.scss';

const SelectDropdownMenu: FC<SelectDropdownMenuProps> = ({
  type,
  isVisibleDropdown,
  options,
  setOptionItemId,
  closeDropdown,
  handleDeleteOption,
  searchText,
  setSearchText,
  handleUpdateOption,
  isControllable,
  handleAddItemLink,
  setSelectedOptionName,
  isUseOutsideConfirmModal,
  handleShowDeleteConfirmModal,
  setSelectedOptionItem,
  className
}) => {
  const data: Array<{ id: number; name: string }> = options || [];

  const lowercasedFilter = searchText.toLowerCase();
  function isFiltered(element: { id: number; name: string }, index: number, array: Array<{ id: number; name: string }>) {
    return element.name.toLowerCase().includes(lowercasedFilter);
  }

  const filteredData = (data.length > 0 && data.filter(isFiltered)) || data;

  const handleSelectOption = (option) => {
    setOptionItemId && setOptionItemId(option.id);
    setSelectedOptionName && setSelectedOptionName(option.name);
    setSearchText('');
    closeDropdown();
  };

  return (
    <div
      className={clsx(
        'search-select-dropdown menu-background menu-sub-dropdown menu-column menu-rounded menu-gray-600 custom-select-dropdown',
        { show: isVisibleDropdown },
        className
      )}
      data-kt-menu="true"
    >
      {filteredData.length > 0 ? (
        filteredData.map((item, index) => (
          <OptionItem
            type={type}
            item={item}
            key={index}
            handleSelectOption={handleSelectOption}
            data={data}
            isVisibleDropdown={isVisibleDropdown}
            handleDeleteOption={handleDeleteOption}
            handleUpdateOption={handleUpdateOption}
            isControllable={isControllable}
            closeDropdown={closeDropdown}
            isUseOutsideConfirmModal={isUseOutsideConfirmModal}
            handleShowDeleteConfirmModal={handleShowDeleteConfirmModal}
            setSelectedOptionItem={setSelectedOptionItem}
            setOptionItemId={setOptionItemId}
            containerClassName={className}
          />
        ))
      ) : (
        <>
          {isControllable ? (
            <div className="option-item poppins-regular no-option text-center p-0">Press Enter to create new {type}</div>
          ) : (
            <>
              <div className="option-item poppins-regular no-option text-center text-capitalize">{type} not found</div>
              <div className="option-item p-0 poppins-regular text-primary text-center cursor-pointer" onClick={handleAddItemLink}>
                Add new {type}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(SelectDropdownMenu);
