// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getPosition = (width: number, dropDownTopPosition: number, dropDownLeftPosition: string, dropDownWidth: number) => {
  let topPosition;
  let leftPosition;
  if (width <= 1920) {
    topPosition = dropDownTopPosition / 14;
    leftPosition = parseInt(dropDownLeftPosition, 10) / 14 - dropDownWidth;
  }
  if (width > 1920 && width <= 2150) {
    topPosition = dropDownTopPosition / 18;
    leftPosition = parseInt(dropDownLeftPosition, 10) / 18 - dropDownWidth;
  }
  if (width > 2150 && width <= 2560) {
    topPosition = dropDownTopPosition / 20;
    leftPosition = parseInt(dropDownLeftPosition, 10) / 20 - dropDownWidth;
  }

  return { topPosition, leftPosition };
};
