export const configVariables = {
  breakPoints: {
    sm: 700,
    mobile: {
      minHeight: 508,
      maxWidth: 640
    },
    tabletPortrait: {
      minWidth: 641,
      maxWidth: 960
    },
    tabletLandscape: {
      minWidth: 961,
      maxWidth: 1299
    },
    web: {
      minWidth: 1300
    }
  }
};
