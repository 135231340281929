import { FC, useCallback, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';

interface IVSPlayer {
  playbackUrl: string;
  showControls: boolean;
  currentSlide?: number;
}

const IvsPlayer: FC<IVSPlayer> = ({ playbackUrl, showControls, currentSlide }) => {
  const player = useRef<any>(null);
  const video = useRef<HTMLVideoElement>(null);
  const pid = useRef(`(${uuid().slice(-3)})`);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { isPlayerSupported, create: createMediaPlayer, PlayerEventType } = window.IVSPlayer;
  const { ERROR } = PlayerEventType;

  // Generic PlayerEventType event listener
  const onError = useCallback((err) => {
    console.warn(`Player ${pid.current} Event - ERROR:`, err, player.current);
  }, []);

  const destroy = useCallback(() => {
    if (!player.current) return;

    // remove event listeners
    player.current.removeEventListener(PlayerEventType.ERROR, onError);

    // delete and nullify player
    player.current.pause();
    player.current.delete();
    player.current = null;
    video.current?.removeAttribute('src'); // remove possible stale src
  }, [onError]);

  const create = useCallback(() => {
    if (!isPlayerSupported) return;

    // If a player instnace already exists, destroy it before creating a new one
    if (player.current) destroy();

    player.current = createMediaPlayer({});
    player.current.attachHTMLVideoElement(video.current);

    player.current.addEventListener(ERROR, onError);
  }, [destroy, onError]);

  useEffect(() => {
    if (playbackUrl && isPlayerSupported) {
      if (!player.current) create();
      player.current.load(playbackUrl);
      player.current.play();
    }

    return () => {
      destroy();
    };
  }, [playbackUrl, isPlayerSupported]);

  useEffect(() => {
    if (player.current && showControls) {
      player.current.pause();
    }
  }, [currentSlide]);

  if (!isPlayerSupported) {
    return <div>Player not supported</div>;
  }

  return <video id="video-player" controls={showControls ? true : false} ref={video} crossOrigin="anonymous" playsInline />;
};

export default IvsPlayer;
