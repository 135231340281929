import { useState, useEffect, FC, memo } from 'react';
// import { useHistory } from 'react-router';
import clsx from 'clsx';

// Components
import { FlexBox } from 'src/components/atoms';
import { Pagination } from 'src/components/molecules';
import { OrderShippingPreview, OrdersList, EmptyList, OrderPreview, ScreenHeader } from 'src/components/oraganisms';

// Icons
import { NoOrderIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { AllOrdersPagePresentationalProps } from './AllOrdersPage.type';
import { orderDetailsData } from 'src/components/oraganisms/OrdersList/OrdersList.types';
import { ColumnType } from 'src/components/molecules/Table/Table.types';

// Styles
import './AllOrdersPage.scss';

const initialOrderTableColumns: ColumnType[] = [
  { name: 'Date', status: true },
  { name: 'Status', status: true },
  { name: 'Customer', status: true },
  { name: 'Total', status: true },
  { name: 'Items', status: true },
  { name: 'Tags', status: true }
];

const AllOrdersPagePresentational: FC<AllOrdersPagePresentationalProps> = (props) => {
  const {
    HeadingExtenstion,
    changeOrderType,
    customDateSelector,
    setOrdersPageInfo,
    setSelectedTab,
    changingDate,
    setSortBy,
    onSearch,
    searchText,
    refresh,
    selectedOrderType,
    ordersPageInfo,
    ordersList,
    setOrdersList,
    selectedDates,
    selectedTab,
    countList,
    sortBy,
    isLoading,
    totalCount
  } = props;

  const [showOrderModal, setShowOrderModal] = useState(false);
  const [isOpenOrderShipmentPreview, setIsOpenOrderShipmentPreview] = useState<boolean>(false);
  const [isOpenOrderPreview, setIsOpenOrderPreview] = useState<boolean>(false);
  const [shipmentId, setShipmentId] = useState<number>();
  const [shippingAddress, setShippingAddress] = useState<string>();
  const [shippingIndex, setShippingIndex] = useState<number>();
  const [orderDetails, setOrderDetails] = useState<orderDetailsData>({
    id: 0,
    orderCode: '',
    orderStatus: '',
    customer: '',
    customerEmail: '',
    customerPhoneNo: '',
    customerOrders: 0,
    isEmailVerified: false,
    isPhoneNoVerified: false,
    subTotal: 0.0,
    thumbnailUrl: '',
    totalWeight: 0.0,
    isPartiallyShipped: false,
    orderDetails: [
      {
        availableQuantity: -1,
        id: 0,
        name: '',
        sku: '',
        code: '',
        quantity: 0,
        productId: 0,
        variantId: 0,
        price: [{ price: 0, quantity: 0 }],
        orderItemStatus: '',
        imageUrl: '',
        location: '',
        dimensions: '',
        items: '',
        variantTypes: [
          {
            id: 0,
            name: '',
            value: ''
          }
        ]
      }
    ],
    total: 0.0,
    availableItemsQuantity: 0,
    items: 0,
    discountAmount: 0.0,
    shippingAmount: 0.0,
    taxAmount: 0.0,
    createdAt: '',
    updatedAt: '',
    isCombinedOrder: false,
    childOrders: [],
    isCombOrdrParent: false,
    noOfCombChildOrds: 0
  });

  const [showCombinedTab, setShowCombinedTab] = useState<boolean>(true);

  const [openEditColumnDropdown, setOpenEditColumnDropdown] = useState(false);
  const [editColumnData, setEditColumnData] = useState<ColumnType[]>([]);

  useEffect(() => {
    const tableColumns = localStorage.getItem('orderTable');
    if (tableColumns) {
      setEditColumnData(JSON.parse(tableColumns));
    } else {
      setEditColumnData(initialOrderTableColumns);
    }
  }, []);

  useEffect(() => {
    if (selectedTab === 'PENDING' || selectedTab === 'TO PROCESS') {
      setShowCombinedTab(false);
    } else {
      setShowCombinedTab(true);
    }
  }, [selectedTab]);

  const openOrderShippingPreview = () => {
    setIsOpenOrderShipmentPreview(true);
  };

  const openShipmentDetails = (id: number) => {
    setShipmentId(id);
  };

  const sendDate = (date: string, index: number) => {
    setShippingAddress(date);
    setShippingIndex(index);
  };

  const currentStatus = (selectedTab: string | undefined) => {
    switch (selectedTab) {
      case 'PENDING':
        return 'pending';
      case 'TO PROCESS':
        return 'process';
      case 'TO SHIP':
        return 'ship';
      case 'TO FULFILL':
        return 'fulfill';
      case 'SHIPPED':
        return 'shipped';
      default:
        return '';
    }
  };

  const toggleOrderPreviewSlide = () => {
    document.body.style.overflow = 'unset';
    setIsOpenOrderPreview(!isOpenOrderPreview);
  };

  const closeOrderPreviewSlide = () => {
    document.body.style.overflow = 'unset';
    setIsOpenOrderPreview(false);
  };

  return (
    <>
      <FlexBox className={`allOrdersPage p-40 ${showOrderModal ? 'overflow-hidden check' : ''}`} id="tablePage">
        <div className={`width-animation d-flex w-100 flex-column hide-scrollbar ${showOrderModal ? 'vh-100' : 'min-h-100'}`}>
          <ScreenHeader
            headerTitle={constVariables.AllOrdersMenu.allOrders}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            tabList={constVariables.TabForOrders}
            removeExportImport={true}
            HeadingExtenstion={HeadingExtenstion}
            showCountsOnTab={true}
            tabCountValue={countList}
            orderToggle={showCombinedTab}
            changeOrderType={changeOrderType}
            selectedOrderType={selectedOrderType}
            editColumnText={constVariables.orderEditColumns.title}
            editColumnData={editColumnData}
            setEditColumnData={setEditColumnData}
            openEditColumnDropdown={openEditColumnDropdown}
            setOpenEditColumnDropdown={setOpenEditColumnDropdown}
            isAllSwitchableColumns={true}
          />
          <div className="card w-100 mb-0 p-0">
            <div className="p-x-32 p-t-32">
              {!isLoading && totalCount === 0 && !searchText && !selectedDates?.from && (
                <>
                  {selectedTab === constVariables.OrdersTab.all.toUpperCase() ? (
                    <EmptyList description={constVariables.emptyDataList.order} icon={NoOrderIcon} />
                  ) : (
                    <EmptyList datatype="order" status={currentStatus(selectedTab)} icon={NoOrderIcon} />
                  )}
                </>
              )}

              <OrdersList
                selectedOrderType={selectedOrderType}
                openOrderShippingPreview={openOrderShippingPreview}
                openShipmentDetails={openShipmentDetails}
                sendDate={sendDate}
                ordersList={ordersList}
                setOrdersList={setOrdersList}
                orderDetails={orderDetails}
                setOrderDetails={setOrderDetails}
                setSortBy={setSortBy}
                sortBy={sortBy}
                rapidActionOpen={false}
                refresh={refresh}
                changingDate={changingDate}
                customDateSelector={customDateSelector}
                selectedDates={selectedDates}
                selectedTab={selectedTab}
                searchForOrders={onSearch}
                isLoading={isLoading}
                searchText={searchText}
                totalCount={totalCount}
                editColumnData={editColumnData}
                setIsOpenOrderPreview={setIsOpenOrderPreview}
                countList={countList}
              />
            </div>
            <div className={clsx('w-100', { 'display-none': totalCount === 0 && !searchText })}>
              <Pagination
                skipCount={ordersPageInfo?.skipCount}
                limitCount={ordersPageInfo?.limitCount}
                setSkipCount={(num: number) =>
                  setOrdersPageInfo((prev) => ({
                    ...prev,
                    skipCount: num
                  }))
                }
                total={totalCount}
              />
            </div>
          </div>
        </div>
      </FlexBox>
      <OrderShippingPreview
        isVisible={isOpenOrderShipmentPreview}
        closeSlide={() => setIsOpenOrderShipmentPreview(false)}
        shipmentId={shipmentId}
        shippingAddress={shippingAddress}
        shippingIndex={shippingIndex}
      />
      <OrderPreview
        isVisible={isOpenOrderPreview}
        setShowOrderModal={setShowOrderModal}
        orderDetails={orderDetails}
        refresh={refresh}
        toggleSlide={toggleOrderPreviewSlide}
        closeSlide={closeOrderPreviewSlide}
        selectedOrderType={selectedOrderType}
      />
    </>
  );
};

export default memo(AllOrdersPagePresentational);
