import { gql } from '@apollo/client';

export const GET_ORDER_NOTE = gql`
  query GetOrderNote($orderId: Int!) @api(name: "ordersAPI") {
    getOrderNotes(orderId: $orderId) {
      id
      isCreatedByYou
      note
      username
      thumbnailUrl
      userId
      createdAt
      updatedAt
    }
  }
`;
