import { FC, useEffect, useState, createRef } from 'react';
import moment from 'moment';
import { format } from 'date-fns';

// Components
import RenderImageList from '../RenderImageList';
import FullEventContentCard from '../FullEventContentCard/FullEventContentCard';

// Types
import { RenderEventInfoType } from 'src/components/molecules/LiveshowCalendarComponent/LiveshowCalendarComponent.types';

interface cardPositions {
  top: number;
  left?: number;
  width: number;
  minHeight: number;
}

const MidEventContent: FC<RenderEventInfoType> = (eventInfo) => {
  const { event, hidden, showEventDetail, eventList } = eventInfo;
  const eventCardRef = createRef<any>();
  const [eventStatus, setEventStatus] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [diff, setDiff] = useState(0);
  const [cardPositions, setCardPositions] = useState<cardPositions>({
    top: 0,
    left: 0,
    width: 0,
    minHeight: 0
  });

  useEffect(() => {
    if (event) {
      const timeDiff = Math.round((event.end?.getTime() - event.start?.getTime()) / 60000);
      setDiff(timeDiff);

      const currentEvent = eventList.find((eventItem) => eventItem.id.toString() === event.id);

      const givenStartTime = moment(event.start);
      const givenEndTime = moment(event.end);
      const currentTime = moment(new Date());
      if (givenEndTime.isBefore(currentTime)) {
        setEventStatus('past');
      } else if (currentTime.isBefore(givenStartTime)) {
        setEventStatus('upcoming');
      } else {
        if (currentEvent?.isLive) {
          setEventStatus('live');
        } else {
          setEventStatus('upcoming');
        }
      }
    }
  }, [event]);

  const renderLeftPosition = () => {
    const weekDay = new Date(event.end).getDay();
    switch (weekDay) {
      case 1:
        return document.querySelector('.fc-timegrid-col.fc-day-mon')?.getBoundingClientRect().left;
      case 2:
        return document.querySelector('.fc-timegrid-col.fc-day-tue')?.getBoundingClientRect().left;
      case 3:
        return document.querySelector('.fc-timegrid-col.fc-day-wed')?.getBoundingClientRect().left;
      case 4:
        return document.querySelector('.fc-timegrid-col.fc-day-thu')?.getBoundingClientRect().left;
      case 5:
        return document.querySelector('.fc-timegrid-col.fc-day-fri')?.getBoundingClientRect().left;
      case 6:
        return document.querySelector('.fc-timegrid-col.fc-day-sat')?.getBoundingClientRect().left;
      default:
        return document.querySelector('.fc-timegrid-col.fc-day-sun')?.getBoundingClientRect().left;
    }
  };

  const handleLiveshowHover = () => {
    setIsHovered(true);

    if (eventCardRef.current) {
      const position = eventCardRef.current.getBoundingClientRect();
      const tableCell = document.querySelector('.fc-timegrid-col.fc-day');
      setCardPositions({
        top: window.scrollY + position.top - 2,
        left: renderLeftPosition() !== undefined ? renderLeftPosition() : 0,
        width: tableCell?.clientWidth ? tableCell?.clientWidth : 0,
        minHeight: position.height + 3
      });
    }
  };

  return (
    <>
      <div className="cursor-pointer h-100 w-100 ">
        <div
          onMouseEnter={handleLiveshowHover}
          className={`poppins-medium overflow-hidden h-100 w-100 px-2 ${hidden ? 'opacity-0' : ''} eventContainer`}
          ref={eventCardRef}
        >
          {diff >= 60 && (
            <div>
              <RenderImageList nextLiveStream={event.extendedProps} />
              <div className="titleTextCal text-capitalize">{event.title}</div>

              <p className="m-0 descTextCal" style={{ color: '#4A516C' }}>
                {format(event.start, 'h:mm aaa')} - {format(event.end, 'h:mm aaa')}
              </p>
              {event.extendedProps?.products && (
                <p className="m-0 descTextCal" style={{ color: '#4A516C' }}>
                  {event.extendedProps?.products?.length} Products Live
                </p>
              )}
            </div>
          )}
          {diff >= 30 && diff < 60 && (
            <div>
              <RenderImageList nextLiveStream={event.extendedProps} />
              <div className="titleTextCal text-capitalize singleLine">{event.title}</div>

              <p className="m-0 descTextCal" style={{ color: '#4A516C' }}>
                {format(event.start, 'h:mm aaa')} - {format(event.end, 'h:mm aaa')}
              </p>
            </div>
          )}
          {diff < 30 && (
            <div>
              <div className="overviewText text-capitalize singleLine">{event.title}</div>
            </div>
          )}
        </div>
      </div>
      {isHovered && (
        <FullEventContentCard
          eventList={eventList}
          event={event}
          cardPositions={cardPositions}
          isHovered={isHovered}
          setIsHovered={setIsHovered}
          showEventDetail={showEventDetail}
          hidden={false}
          eventStatus={eventStatus}
        />
      )}
    </>
  );
};

export default MidEventContent;
