import { gql } from '@apollo/client';

export const ON_IVS_STATE_CHANGE = gql`
  subscription GET_STORE_STREAM_CONFIGURATION($input: String!) {
    onIVSStateChange(storeId: $input) {
      medialiveChannelState
      liveVideo {
        isStreaming
        arn
        streamKey
        ingestServer
        playbackUrl
      }
      previewVideo {
        isStreaming
        arn
        streamKey
        ingestServer
        playbackUrl
      }
      runningLiveId
      runningStreamSource
      storeId
      message
      isLiveOnApp
      isLiveOnFB
      liveStartedAt
      nextShowBlockedTill
    }
  }
`;
