import { gql } from '@apollo/client/core';

export const GET_LIVE_STATS = gql`
  query GET_LIVE_STATS($id: String!) @api(name: "liveStreamingAPI") {
    getLiveShowStats(id: $id) {
      views {
        facebook
        mobile
      }
      likes
      totalSales
      targetGoal
      paidOrders
      profit
      unpaidOrders
      pendingRevenue
      likes
    }
  }
`;
