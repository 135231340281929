import { FC } from 'react';
import { useHistory } from 'react-router';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { FlexBox, DropdownSelector, Loader } from 'src/components/atoms';
import { Dropdown, Tooltip } from 'src/components/molecules';
import { CustomModal, DiscardModal, ErrorModal, SaveHeader, AvalaraLogs } from 'src/components/oraganisms';

// Icons
import { BackRoundedIcon, InfoIcon, AvalaraLogo, ShowIcon, HideIcon, FilledCheckCircle } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { TaxesProps } from './Taxes.types';

// Styles
import './_taxes.scss';

const TaxesPresentational: FC<TaxesProps> = ({
  accountId,
  setAccountId,
  licenseKey,
  setLicenseKey,
  isSandbox,
  setIsSandbox,
  onSelectCompany,
  shippingCode,
  setShippingCode,
  productCode,
  setProductCode,
  showPassword,
  setShowPassword,
  showCompanyDropdown,
  setShowCompanyDropdown,
  connectAvalaraAccount,
  modal,
  setModal,
  onDiscardHandler,
  leavePageHandler,
  showErrorModal,
  setShowErrorModal,
  formChanges,
  discard,
  errorMessageArray,
  addErrorMessage,
  saveAvalaraSettings,
  accountError,
  isLoading,
  disableSaveButton,
  isConnectingAvalara,
  avalaraAccount,
  isAvalaraEnabled,
  isRecordingEnabled,
  setIsAvalaraEnabled,
  setIsRecordingEnabled,
  selectedCompany
}) => {
  const history = useHistory();

  const closeDiscardModal = () => {
    document.body.style.overflow = 'unset';
    setModal((prev) => ({ ...prev, discardModal: false }));
  };

  const closeErrorModal = () => {
    document.body.style.overflow = 'unset';
    setShowErrorModal(false);
  };

  return (
    <>
      {modal.discardModal && (
        <CustomModal bodyClassname="w-90 w-md-50" show={modal.discardModal} closeModal={closeDiscardModal}>
          <DiscardModal
            title={constVariables.common.discardForm.title}
            message={constVariables.common.discardForm.message}
            actionBtnTitle={constVariables.taxes.discardModal.action}
            cancelBtnTitle={constVariables.taxes.discardModal.cancel}
            actionBtnHandler={onDiscardHandler.bind(this, 'discard')}
            cancelBtnHandler={onDiscardHandler.bind(this, 'close')}
            actionButtonClassName={'tax-discard-button'}
          />
        </CustomModal>
      )}
      {modal.leaveModal && (
        <CustomModal
          bodyClassname="w-90 w-md-50"
          show={modal.leaveModal}
          closeModal={() => {
            document.body.style.overflow = 'unset';
            setModal((prev) => ({ ...prev, leaveModal: false }));
          }}
        >
          <DiscardModal
            title={constVariables.common.LeaveForm.title}
            message={constVariables.common.LeaveForm.message}
            actionBtnTitle={constVariables.common.LeaveForm.action}
            cancelBtnTitle={constVariables.common.LeaveForm.cancel}
            actionBtnHandler={leavePageHandler}
            cancelBtnHandler={onDiscardHandler.bind(this, 'leave')}
            actionButtonClassName={'tax-discard-button'}
          />
        </CustomModal>
      )}
      {showErrorModal && (
        <CustomModal bodyClassname="w-90 w-md-50" show={showErrorModal} closeModal={closeErrorModal}>
          <ErrorModal
            message={constVariables.common.onSaveError.message}
            actionTitle={constVariables.common.onSaveError.action}
            actionHandler={closeErrorModal}
            errors={errorMessageArray}
          />
        </CustomModal>
      )}
      {formChanges && (
        <SaveHeader
          saveDisabled={disableSaveButton}
          onSave={() => {
            if (!selectedCompany?.id || !productCode || !shippingCode) {
              addErrorMessage();
              setShowErrorModal(true);
            } else {
              saveAvalaraSettings();
            }
          }}
          onDiscard={discard}
        />
      )}
      <div className={`taxes`}>
        <div className={`width-animation w-100`}>
          <div className="details-page">
            <div className="details-page-container">
              <FlexBox className="align-items-center m-y-40">
                <img
                  src={BackRoundedIcon}
                  alt={BackRoundedIcon}
                  className="cursor-pointer"
                  onClick={() => {
                    history.push('/settings');
                  }}
                />
                <h2 className="page-title px-4 mb-0">{constVariables.taxes.key}</h2>
              </FlexBox>
              <div className="main-detail-layout">
                <div className="left-pane">
                  <div>
                    <div className="card w-100">
                      <div className="mb-6">
                        <img src={AvalaraLogo} />
                        <img src={InfoIcon} className="ps-3 cursor-pointer" data-tip data-for="taxInfoIcon" />
                        <Tooltip
                          tooltipId="taxInfoIcon"
                          className="toolTipShip"
                          place="right"
                          content={() => {
                            return (
                              <div>
                                {constVariables.taxes.hoverMessage}
                                <a className="text-primary" href="https://avalara.com" target={'_blank'} rel="noreferrer">
                                  {constVariables.taxes.clickHere}
                                </a>
                                {constVariables.taxes.moreInfo}
                              </div>
                            );
                          }}
                        />
                        <p className={avalaraAccount?.companyCountry ? 'mt-5 secondary-text' : 'd-none'}>
                          You are configured to collect tax in:
                          <span className="ms-2 text-dark">
                            <b>{avalaraAccount?.companyCountry}</b>
                          </span>
                        </p>
                        {avalaraAccount?.isConnected ? (
                          <div className="mt-10">
                            <img src={FilledCheckCircle} />
                            <span className="ms-2 text-success">{constVariables.taxes.accountConnectedMessage}</span>
                          </div>
                        ) : (
                          <div>
                            {isLoading ? (
                              <Loader type="page" />
                            ) : (
                              <>
                                <div className="tax-heading-container mt-5 mb-10">
                                  <p className={`poppins-semibold fs-16px mt-5`}>{constVariables.taxes.setApiKeyMessage}</p>
                                  <div className="sandbox-container">
                                    <p className={`poppins-regular fs-16px  mt-5  mr-10px`}>{constVariables.taxes.sandbox}</p>
                                    <div className="form-check form-switch form-switch-sm form-check-custom">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={isSandbox}
                                        name="rapidaction"
                                        onChange={(e) => {
                                          setIsSandbox(e.target.checked);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <FlexBox className="align-items-end w-100">
                                  <div className="w-42">
                                    <p>{constVariables.taxes.accountId}</p>
                                    <input
                                      autoComplete="nope"
                                      className={`form-control h-40px w-95`}
                                      id="username"
                                      name="username"
                                      type="text"
                                      value={accountId}
                                      onChange={(e) => {
                                        setAccountId(e.target.value);
                                      }}
                                      placeholder={constVariables.taxes.accountId}
                                    />
                                  </div>
                                  <div className="w-42">
                                    <p>{constVariables.taxes.licenseKey}</p>
                                    <div className={`input-group w-95`}>
                                      <input
                                        autoComplete="nope"
                                        className="form-control h-40px input-no-border"
                                        id="password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={licenseKey}
                                        onChange={(e) => {
                                          setLicenseKey(e.target.value);
                                        }}
                                        placeholder={constVariables.taxes.licenseKey}
                                      />
                                      <div className="input-group-addon">
                                        <img
                                          src={showPassword ? ShowIcon : HideIcon}
                                          className="cursor-pointer"
                                          onClick={() => {
                                            setShowPassword(!showPassword);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {isConnectingAvalara ? (
                                    <Loader type="button" className="w-10 con-load-container" />
                                  ) : (
                                    <button
                                      className="btn btn-primary mt-5"
                                      onClick={() => {
                                        connectAvalaraAccount();
                                      }}
                                    >
                                      {constVariables.taxes.connectBtn}
                                    </button>
                                  )}
                                </FlexBox>
                                <p className={accountError ? 'text-danger fs-8 mt-2' : 'd-none'}>
                                  {constVariables.taxes.accountErrorSettings}
                                </p>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {avalaraAccount?.isConnected && (
                    <div>
                      <div className="card w-100">
                        <div>
                          <p className="poppins-semibold fs-16px">{constVariables.taxes.setCompanyMessage}</p>
                          <FlexBox className="align-items-end w-100">
                            <div className="w-100">
                              <p>{constVariables.taxes.company}</p>
                              <div className="position-relative">
                                <DropdownSelector
                                  className={`form-control m-auto p-0 justify-content-between cursor-pointer dropdown-box h-40px`}
                                  onClick={() => {
                                    setShowCompanyDropdown(!showCompanyDropdown);
                                  }}
                                  selectedValue={avalaraAccount?.companyName}
                                  text="Select a company"
                                />
                                <OutsideClickHandler
                                  onOutsideClick={() => {
                                    setShowCompanyDropdown(false);
                                  }}
                                >
                                  <Dropdown
                                    className="dropdown-custom-width"
                                    data={
                                      avalaraAccount
                                        ? avalaraAccount?.availableCompanies?.map(({ companyCode, name }) => ({
                                            id: companyCode,
                                            name
                                          }))
                                        : []
                                    }
                                    selected={showCompanyDropdown}
                                    onSelect={(dropdownData) => {
                                      onSelectCompany(dropdownData);
                                    }}
                                    closeDropdown={() => setShowCompanyDropdown(false)}
                                  />
                                </OutsideClickHandler>
                              </div>
                            </div>
                          </FlexBox>
                        </div>
                      </div>
                    </div>
                  )}
                  {avalaraAccount?.isConnected && (
                    <div>
                      <div className="card w-100">
                        <div>
                          <p className="poppins-semibold fs-16px">{constVariables.taxes.setShippingCodeTitle}</p>
                          <FlexBox className="align-items-end w-100">
                            <div className="w-100">
                              <p>
                                {constVariables.taxes.taxCode}
                                <a
                                  className="text-primary ms-3 cursor-pointer"
                                  href="https://taxcode.avatax.avalara.com/search?category=Freight"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <u>{constVariables.taxes.searchTaxCode}</u>
                                </a>
                              </p>
                              <input
                                autoComplete="nope"
                                className={`form-control h-40px w-95`}
                                id="taxCode"
                                name="taxCode"
                                type="text"
                                placeholder="Tax code"
                                value={shippingCode}
                                onChange={(e) => {
                                  setShippingCode(e.target.value);
                                }}
                              />
                            </div>
                          </FlexBox>
                        </div>
                      </div>
                    </div>
                  )}
                  {avalaraAccount?.isConnected && (
                    <div>
                      <div className="card w-100">
                        <div>
                          <p className="poppins-semibold fs-16px">{constVariables.taxes.setProductCodeTitle}</p>
                          <FlexBox className="align-items-end w-100">
                            <div className="w-100">
                              <p>
                                {constVariables.taxes.taxCode}{' '}
                                <a
                                  className="text-primary ms-3 cursor-pointer"
                                  href="https://taxcode.avatax.avalara.com/"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <u>{constVariables.taxes.searchTaxCode}</u>
                                </a>
                              </p>
                              <input
                                autoComplete="nope"
                                className={`form-control h-40px w-95`}
                                id="taxCode"
                                name="taxCode"
                                type="text"
                                placeholder="Tax code"
                                value={productCode}
                                onChange={(e) => {
                                  setProductCode(e.target.value);
                                }}
                              />
                            </div>
                          </FlexBox>
                        </div>
                      </div>
                    </div>
                  )}
                  {avalaraAccount?.isConnected && <AvalaraLogs />}
                </div>
                <div className="right-pane">
                  {avalaraAccount?.isConnected && (
                    <div className="card w-100">
                      <div className="mb-6 poppins-regular">
                        <h2 className="poppins-regular mb-5 fs-3 m-auto-0 text-nowrap dark-color">
                          {constVariables.taxes.settings}
                        </h2>
                        <div className="setting-container">
                          <p className={`poppins-regular fs-16px mt-5`}>{constVariables.taxes.Enabled}</p>
                          <div className="form-check form-switch form-switch-sm form-check-custom">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={isAvalaraEnabled}
                              name="rapidaction"
                              onChange={(e) => setIsAvalaraEnabled(e?.target?.checked)}
                            />
                          </div>
                        </div>
                        <div className="setting-container">
                          <p className={`poppins-regular fs-16px mt-5`}>{constVariables.taxes.allowRecording}</p>
                          <div className="form-check form-switch   form-switch-sm form-check-custom">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={isRecordingEnabled}
                              name="rapidaction"
                              onChange={(e) => setIsRecordingEnabled(e?.target?.checked)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="card w-100">
                    <div className="mb-6 poppins-regular">
                      <h2 className="poppins-regular fs-3 m-auto-0 text-nowrap dark-color">{constVariables.taxes.steps.title}</h2>
                      <p className="mt-5 mediaGreyText">{constVariables.taxes.steps.stepTitle}</p>
                      <div className="mx-2">
                        <p className="mt-5 mediaGreyText">
                          {constVariables.taxes.steps.step1}
                          <a
                            className="text-primary mediaGreyText"
                            href="https://home.avalara.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {constVariables.taxes.steps.signUp}
                          </a>
                        </p>
                        <p className="mediaGreyText">{constVariables.taxes.steps.step2}</p>
                        <p className="mediaGreyText">{constVariables.taxes.steps.step3}</p>
                        <p className="mediaGreyText">{constVariables.taxes.steps.step4}</p>
                        <p className="mediaGreyText">{constVariables.taxes.steps.step5}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaxesPresentational;
