import { gql } from '@apollo/client';

export const UPDATE_PROFILE_INFO = gql`
  mutation UpdateProfileInfo($input: UpdateProfileInfoInput) @api(name: "productsAPI") {
    updateProfileInfo(input: $input) {
      success
      message
    }
  }
`;
