import React, { FC } from 'react';

// Components
import { RadioButton } from 'src/components/atoms';
import RulesEngineConditionItem from './RuleEngineConditionItem';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { PlusColorBlueIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { CollectionConditionPresentationProps } from './RulesEngineConditions.types';

// Styles
import './_rulesEngineCondition.scss';

const RulesEngineConditionsPresentational: FC<CollectionConditionPresentationProps> = ({
  rulesEngineConditions,
  fieldDropdown,
  conditionDropdownArray,
  allConditionMatch,
  setRulesEngineConditions,
  setConditionDropdownArray,
  setAllConditionMatch,
  setActiveIndex,
  getTagsList,
  getCollections,
  getProductTypeData,
  getVendorData,
  changeAppliedItems,
  openDropdown,
  openTagCollectionTypeDropdown,
  setFormChanges,
  setRulesChanges,
  rulesChanges,
  saveModuleRules
}) => {
  return (
    <div className="w-100 collection-management m-t-32">
      <h3 className="m-b-32">{constVariables.common.collectionConditions}</h3>
      <div className="d-flex align-items-center m-b-24 w-100">
        <div className="text-nowrap me-2 poppins-semibold">Products must match:</div>
        <RadioButton
          id="allConditions"
          name="productsMatch"
          isChecked={allConditionMatch}
          handleChange={(e) => {
            if (e.target.checked) {
              setAllConditionMatch(true);
              if (setRulesChanges) {
                setRulesChanges(true);
              }
            }
          }}
        />
        <label htmlFor="onTheVendor" className="mb-0 mx-2 opacity-80 fw-normal">
          {constVariables.common.allConditions}
        </label>
        <RadioButton
          id="anyConditions"
          name="productsMatch"
          isChecked={!allConditionMatch}
          handleChange={(e) => {
            if (e.target.checked) {
              setAllConditionMatch(false);
              if (setRulesChanges) {
                setRulesChanges(true);
              }
            }
          }}
        />
        <label htmlFor="onTheBoutique" className="mb-0 mx-2 opacity-80 fw-normal">
          {constVariables.common.anyConditions}
        </label>
      </div>
      {rulesEngineConditions?.map((condition, index) => (
        <RulesEngineConditionItem
          condition={condition}
          key={index}
          index={index}
          openDropdown={openDropdown}
          fieldDropdown={fieldDropdown}
          rulesEngineConditions={rulesEngineConditions}
          setRulesEngineConditions={setRulesEngineConditions}
          setActiveIndex={setActiveIndex}
          openTagCollectionTypeDropdown={openTagCollectionTypeDropdown}
          getTagsList={getTagsList}
          getCollections={getCollections}
          getProductTypeData={getProductTypeData}
          getVendorData={getVendorData}
          setRulesChanges={setRulesChanges}
          conditionDropdownArray={conditionDropdownArray}
          setConditionDropdownArray={setConditionDropdownArray}
          noMargin={index === rulesEngineConditions?.length - 1}
        />
      ))}
      <div className="d-flex align-items-center m-t-32">
        <button
          className="d-flex align-items-center justify-content-center btn btn-secondary ms-0 me-2 btn-md"
          onClick={() => {
            const arr = Array.from(rulesEngineConditions);
            const dropdownArr = Array.from(conditionDropdownArray);
            arr.push({
              field: { id: 0 },
              category: '',
              condition: '',
              openFieldDropdown: false,
              openCategoryDropdown: false,
              openConditionDropdown: false
            });
            dropdownArr.push({
              categoryDropdown: []
            });
            setRulesEngineConditions([...arr]);
            setConditionDropdownArray([...dropdownArr]);
          }}
        >
          <KTSVG path={PlusColorBlueIcon} className="svg-icon-8" />
          <span className="fs-6">{rulesEngineConditions.length ? 'Add another condition' : 'Add condition'}</span>
        </button>
        {rulesEngineConditions.length && rulesChanges ? (
          <button
            type="button"
            className="btn btn-primary btn-md"
            onClick={() => {
              if (saveModuleRules) {
                saveModuleRules();
              }
            }}
          >
            Done
          </button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default React.memo(RulesEngineConditionsPresentational);
