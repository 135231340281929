import { FC, useEffect, useState, createRef } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_UESR_TAGS } from 'src/apollo/queries';

// Components
import { TagAndCollectionDropdown, CustomerTags, Portal } from 'src/components/molecules';

// Types
import { CustomerSegment, CustomerTagsProps } from 'src/components/pages/coupons/CreateCoupon/CreateCoupon.types';

const CustomerTagsSection: FC<CustomerTagsProps> = ({ customerEligibility, setCustomerEligibility, errorMessages }) => {
  const containerRef = createRef<any>();
  const [showDropdown, setShowDropdown] = useState(false);
  const [segments, setSegments] = useState<CustomerSegment[]>([]);
  const [dropdownPositions, setDropdownPositions] = useState({
    top: 0,
    left: 0,
    width: 0
  });

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  const { data } = useQuery(GET_UESR_TAGS, {
    variables: {
      input: {
        userId: null
      }
    },
    onError: (err) => {
      console.error('Get user tags', err);
    }
  });

  const handleOpenDropdown = (e) => {
    e.stopPropagation();
    setShowDropdown(true);

    if (containerRef.current) {
      const position = containerRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - position.bottom;
      setDropdownPositions({
        top: spaceBelow < 400 ? window.scrollY + position.top - 363 : window.scrollY + position.top + position.height,
        left: position.left,
        width: position.width
      });
    }
  };

  useEffect(() => {
    if (data?.getUserTags) {
      setSegments(
        data?.getUserTags?.map((segment) => {
          return {
            id: segment.id,
            name: segment.tag,
            textColorCode: segment.color,
            backgroundColorCode: segment.bgColor
          };
        })
      );
    }
  }, [data]);

  const changeAppliedSegments = (item: any, type: string, action: string) => {
    const arr: any = customerEligibility?.segments && Array.from(customerEligibility?.segments);

    if (action === 'remove') {
      const i = arr.indexOf(item);
      arr.splice(i, 1);
    } else {
      arr.push(item);
    }

    setCustomerEligibility((customerEligibility) => ({
      ...customerEligibility,
      segments: [...arr]
    }));
  };

  return (
    <div className="section-content customer-segments-section">
      <div className="position-relative" ref={containerRef}>
        <div
          onClick={handleOpenDropdown}
          className="flex-nowrap d-flex align-items-center cursor-pointer h-40px w-100 rounded tags-list-container"
        >
          {customerEligibility?.segments && customerEligibility?.segments?.length > 0 && (
            <CustomerTags isProduct={true} userTags={customerEligibility?.segments} />
          )}
        </div>
      </div>
      {errorMessages?.specificSegmentsError && (
        <div className="warning-text p-0 fs-7 mt-1">{errorMessages?.specificSegmentsError}</div>
      )}
      {showDropdown && (
        <Portal id="kt_body">
          <OutsideClickHandler onOutsideClick={closeDropdown}>
            <div
              className="position-absolute"
              style={{ top: dropdownPositions.top, left: dropdownPositions.left, width: dropdownPositions.width }}
            >
              <TagAndCollectionDropdown
                type="Segment"
                allItems={segments}
                appliedItems={customerEligibility?.segments}
                changeAppliedItems={changeAppliedSegments}
                selected={showDropdown}
                isCreatable={false}
                isEditAndDeletable={false}
                closeDropdown={closeDropdown}
              />
            </div>
          </OutsideClickHandler>
        </Portal>
      )}
    </div>
  );
};

export default CustomerTagsSection;
