import { FC } from 'react';

// Components
import { Loader } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { CloseIcon } from 'src/assets/icons';

interface SaveOrDiscardAppThemeSettingsModalProps {
  isCustomizeLoading: boolean;
  handleDiscard: () => void;
  saveTheme: () => void;
}

const SaveOrDiscardAppThemeSettingsModal: FC<SaveOrDiscardAppThemeSettingsModalProps> = ({
  handleDiscard,
  saveTheme,
  isCustomizeLoading
}) => {
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Save or discard unsaved changes</h5>
        <div className="btn btn-xs btn-active-light-primary p-0 m-0 border-none" onClick={handleDiscard}>
          <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
        </div>
      </div>
      <div className="modal-body">
        <div className="my-3">
          Do you wish to save changes as draft for later, or discard them? If you discard changes, you’ll delete any edits you made
          since you last saved.
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-outlined-secondary btn-md" onClick={handleDiscard} data-bs-dismiss="modal" type="button">
          Discard
        </button>
        <button className="btn btn-primary btn-md" type="button" onClick={saveTheme} disabled={isCustomizeLoading}>
          {isCustomizeLoading ? 'Saving' : 'Save'}
          {isCustomizeLoading && <Loader type="button" className="h-10px w-10px" />}
        </button>
      </div>
    </div>
  );
};

export default SaveOrDiscardAppThemeSettingsModal;
