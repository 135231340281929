import React, { FC, useEffect, useState } from 'react';

// Components
import { FlexBox } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { LeftArrowIcon, RightArrowIcon } from 'src/assets/icons';

// Types
import { PaginationProps } from './Pagination.types';

// Styles
import './_paginations.scss';

const Pagination: FC<PaginationProps> = ({ skipCount, limitCount, setSkipCount, total }) => {
  const [pageNo, setPageNo] = useState<number>(1);
  const [visiblePages, setVisiblePages] = useState<number[]>([]);
  const [isNoRest, setIsNoRest] = useState<boolean>(false);

  const filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter((page) => page <= totalPages);
  };

  const getVisiblePages = (page, total) => {
    if (total < 5) {
      return filterPages([1, 2, 3, 4], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, total];
      }
    }
  };

  useEffect(() => {
    setPageNo(Math.floor(skipCount / limitCount) + 1);
  }, [skipCount, limitCount]);

  useEffect(() => {
    const rest = total % limitCount;
    const tempPageCount = rest > 0 ? Math.floor(total / limitCount) + 1 : Math.floor(total / limitCount);
    setVisiblePages(getVisiblePages(pageNo, tempPageCount));
  }, [total, limitCount, pageNo]);

  useEffect(() => {
    const rest = total % limitCount;
    setIsNoRest(rest === 0 ? true : false);
  }, [total, limitCount]);

  const handleDotsPagination = (visiblePages, index) => {
    const currentIndex = visiblePages.findIndex((item) => item === pageNo);
    if (currentIndex < index) {
      if (Math.floor(total / limitCount) + 1 > pageNo + 4) {
        setPageNo(pageNo === 1 ? pageNo + 4 : pageNo + 5);
        setSkipCount((pageNo === 1 ? pageNo + 3 : pageNo + 4) * limitCount);
      } else {
        setPageNo(Math.floor(total / limitCount) + 1);
        setSkipCount(Math.floor(total / limitCount) * limitCount);
      }
    } else {
      if (pageNo - 6 > 0) {
        setPageNo(pageNo - 6);
        setSkipCount((pageNo - 6) * limitCount);
      } else {
        setPageNo(1);
        setSkipCount(0);
      }
    }
  };

  return (
    <div className="table-top-border pagination-section p-x-32">
      <FlexBox className="justify-content-between">
        <FlexBox className="flex-center">
          <span className="f-14px text-muted">
            Showing {total === 0 ? skipCount : skipCount + 1} to {skipCount + limitCount > total ? total : skipCount + limitCount}{' '}
            of {total} records
          </span>
        </FlexBox>
        <FlexBox className="flex-center">
          <div className={`flex-center carousel-arrow-div ${pageNo > 1 ? '' : 'disable-carousel-arrow-div'}`}>
            <KTSVG
              path={LeftArrowIcon}
              className={`smallicon carousel-arrow text-center ${pageNo > 1 ? 'cursor-pointer' : 'opacity-50'}`}
              svgClassName={'w-10px h-10px'}
              onClick={() => {
                if (pageNo > 1) {
                  setPageNo(pageNo - 1);
                  setSkipCount((pageNo - 2) * limitCount);
                }
              }}
            />
          </div>
          {visiblePages.map((page, index, array) => {
            return (
              <React.Fragment key={index}>
                {array[index - 1] + 1 < page ? (
                  <div
                    className="d-flex align-items-center justify-content-center f-14 mx-2 h-30px w-30px cursor-pointer"
                    onClick={() => handleDotsPagination(array, index)}
                  >
                    <div className="w-2px h-2px bg-muted me-1"></div>
                    <div className="w-2px h-2px bg-muted me-1"></div>
                    <div className="w-2px h-2px bg-muted"></div>
                  </div>
                ) : null}
                <div
                  className={`flex-center h-30px w-30px me-1 rounded cursor-pointer ${
                    page === pageNo ? 'bg-primary text-white' : 'text-muted'
                  }`}
                  onClick={() => {
                    setPageNo(page);
                    setSkipCount((page - 1) * limitCount);
                  }}
                >
                  {page}
                </div>
              </React.Fragment>
            );
          })}
          <div
            className={`flex-center carousel-arrow-div ${
              (!isNoRest ? Math.floor(total / limitCount) + 1 : Math.floor(total / limitCount)) > pageNo
                ? ''
                : 'disable-carousel-arrow-div'
            }`}
          >
            <KTSVG
              path={RightArrowIcon}
              className={`smallicon carousel-arrow text-center ${
                (!isNoRest ? Math.floor(total / limitCount) + 1 : Math.floor(total / limitCount)) > pageNo
                  ? 'cursor-pointer'
                  : 'opacity-50'
              }`}
              svgClassName={'w-10px h-10px'}
              onClick={() => {
                if ((!isNoRest ? Math.floor(total / limitCount) + 1 : Math.floor(total / limitCount)) > pageNo) {
                  setPageNo(pageNo + 1);
                  setSkipCount(pageNo * limitCount);
                }
              }}
            />
          </div>
        </FlexBox>
      </FlexBox>
    </div>
  );
};

export default Pagination;
