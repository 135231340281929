import { gql } from '@apollo/client';

export const MARK_USER_ADDRESS_VERIFIED = gql`
  mutation MarkUserAddressVerified($input: UserAddessIds!) @api(name: "productsAPI") {
    markUserAddressVerified(input: $input) {
      id
      success
      message
    }
  }
`;
