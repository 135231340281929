import { gql } from '@apollo/client';

export const GET_FACEBOOK_CHANNELS = gql`
  query getFacebookChannels @api(name: "liveStreamingAPI") {
    getFacebookChannels {
      id
      name
      type
    }
  }
`;
