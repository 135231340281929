import { gql } from '@apollo/client';

export const CONFIGURE_STRIPE = gql`
  mutation connectStripe($secureCode: String!) @api(name: "authAPI") {
    connectStripe(secureCode: $secureCode) {
      __typename
      isConnected
    }
  }
`;
