import { FC } from 'react';

// Components
import { Img } from 'src/components/atoms';

// Icons
import { TickIcon } from 'src/assets/icons';

// Types
import { RadioSelectorType } from 'src/components/pages/coupons/CreateCoupon/CreateCoupon.types';

const RadioSelector: FC<RadioSelectorType> = ({
  isCheck,
  changeCheck,
  name,
  isCheckBox,
  isMessageText,
  isMessage,
  noMarginBottom
}) => {
  const checkBoxRender = () => {
    if (isCheck) {
      return (
        <div className="createCouponCheckBoxOuterSelected">
          <Img src={TickIcon} className="createCouponCheckBoxInnerSelected" />
        </div>
      );
    }
    return <div className="createCouponCheckBoxOuter" />;
  };
  const radioButtonRender = () => {
    if (isCheck) {
      return (
        <div className="createCouponRadioButtonOuterSelected">
          <div className="createCouponRadioButtonInnerSelected" />
        </div>
      );
    }
    return <div className="createCouponRadioButtonOuter" />;
  };

  const changeCheckPass = () => {
    if (!isMessage) {
      changeCheck();
    }
  };

  return (
    <div className={noMarginBottom ? '' : 'm-b-24'}>
      <div onClick={changeCheckPass} className="cursor-pointer d-flex align-items-center">
        <div>{isCheckBox ? checkBoxRender() : radioButtonRender()}</div>
        <div className="ms-4 createcouponRadioText">{name}</div>
      </div>
    </div>
  );
};
export default RadioSelector;
