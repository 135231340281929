import { gql } from '@apollo/client';

export const PRINT_SHIPPING_LABEL = gql`
  mutation PrintShippingLabel($input: PrintShippingLabelInput!) @api(name: "ordersAPI") {
    printShippingLabel(input: $input) {
      labelId
      labelURL
      labelStatus
      labelCreatedAt
      labelUpdatedAt
      labelOwner
      isTest
      rateId
      shipementId
      trackingNumber
      trackingStatus
      carrier
      carrierImage75
      carrierImage200
      trackingURLOfCarrier
      trackingHistory
      messages
      eta
      metaData
    }
  }
`;
