import { gql } from '@apollo/client';

export const SAVE_VENDOR_INFO = gql`
  mutation SaveVendorInfo($input: AddVendorInput) @api(name: "productsAPI") {
    saveVendorInfo(input: $input) {
      id
      name
      websiteURL
      profileURL
      accountNumber
      vendorManager {
        profileURL
        lastName
        email
      }
      contactInfo {
        id
        title
        name
        email
        phoneNo
      }
      isShopPayForShipping
      extraInfo {
        id
        type
        title
        name
      }
      address
      country
      state
      createdAt
      updatedAt
    }
  }
`;
