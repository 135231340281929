import { constVariables } from 'src/constants';

export const CouponTypes = {
  amountOffOrder: {
    title: constVariables.Coupons.AddCoupon.type.amountOffOrder,
    key: constVariables.Coupons.AddCoupon.type.amountOffOrderKey
  },
  amountOffProducts: {
    title: constVariables.Coupons.AddCoupon.type.amountOffProducts,
    key: constVariables.Coupons.AddCoupon.type.amountOffProductsKey
  },
  freeShipping: {
    title: constVariables.Coupons.AddCoupon.type.freeShipping,
    key: constVariables.Coupons.AddCoupon.type.freeShippingKey
  }
  // buyXGetY: {
  //   title: constVariables.Coupons.AddCoupon.type.buyXGetY,
  //   key: constVariables.Coupons.AddCoupon.type.buyXGetYKey
  // },
  // buyXGetX: {
  //   title: constVariables.Coupons.AddCoupon.type.buyXGetX,
  //   key: constVariables.Coupons.AddCoupon.type.buyXGetXKey
  // }
};

export const CouponChannels = {
  online: {
    title: constVariables.Coupons.channels.channel1Title,
    key: constVariables.Coupons.channels.channel1Key
  },
  mobile: {
    title: constVariables.Coupons.channels.channel2Title,
    key: constVariables.Coupons.channels.channel2Key
  },
  facebook: {
    title: constVariables.Coupons.channels.channel3Title,
    key: constVariables.Coupons.channels.channel3Key
  }
};

export const UsageLimits = {
  oncePerCustomer: {
    title: constVariables.Coupons.usageLimits.option1Title,
    key: constVariables.Coupons.usageLimits.option1Key
  },
  firstPurchaseChannel: {
    title: constVariables.Coupons.usageLimits.option2Title,
    key: constVariables.Coupons.usageLimits.option2Key
  },
  newUser: {
    title: constVariables.Coupons.usageLimits.option3Title,
    key: constVariables.Coupons.usageLimits.option3Key
  },
  maxUsed: {
    title: constVariables.Coupons.usageLimits.option4Title,
    key: constVariables.Coupons.usageLimits.option4Key
  }
};

export interface CustomerItem {
  id: number;
  name: string;
  email: string;
  profilePicture: string;
}

export interface CustomerSegment {
  id: number;
  name: string;
  color?: string;
  bgColor?: string;
}

export interface CustomerTagsProps {
  customerEligibility: CustomerEligibilityType;
  setCustomerEligibility: React.Dispatch<React.SetStateAction<CustomerEligibilityType>>;
  errorMessages: ErrorsInfo;
}

export interface GeneralInfoCreateCouponType {
  updatedAt?: string;
  value: string;
  name: string;
  description: string;
  title?: string;
  placeHolder?: string;
  helperText?: string;
  helperTextDescription?: string;
  automaticDiscount: boolean;
  externalProvider?: string;
}

export interface ErrorsInfo {
  generalInfoTitleError: string;
  fixedAmountError: string;
  percentageAmountError: string;
  minPurchaseAmountError: string;
  minQuantityAmountError: string;
  specifiCustomersError: string;
  specificSegmentsError: string;
  usageLimitsError: string;
  freeShippingAmountThresholdError: string;
}

export interface CouponTimeError {
  isStartTimeError: boolean;
  startTimeErrorMessage: string;
  isEndTimeError: boolean;
  endTimeErrorMessage: string;
}
export interface CouponCollectionsType {
  value: number[];
}
export interface CouponTypeType {
  value: 'AMOUNT_OF_ORDER' | 'AMOUNT_OF_PRODUCT' | 'FREE_SHIPPING' | 'BUY_X_GET_Y' | undefined;
}
export interface MinRequirementType {
  value: 'NONE' | 'PURCHASE_AMOUNT' | 'QAUANTITY_OF_ITEMS' | undefined;
  minPurchaseAmount: string;
  minQuantOfItems: string;
}
export interface CustomerEligibilityType {
  value: 'EVERYONE' | 'SPECIFIC_CUSTOMER_SEGMENTS' | 'SPECIFIC_CUSTOMERS' | undefined;
  customers: CustomerItem[];
  segments: CustomerSegment[];
}

export interface CustomerBlockType {
  isChecked: boolean;
  customer: CustomerItem;
  onChange: (customer: CustomerItem) => void;
}

export interface CustomerBuyGetType {
  isMinQuantity: boolean;
  isMinPurchase: boolean;
  buyQuantity: string;
  buyItem: string;
  getQuantity: string;
  getItem: string;
}
export interface CountriesCCType {
  isAllCountries: boolean;
  isSelectedCountries: boolean;
  isShippingRates: boolean;
  shippingRateValue: string;
}
export interface ValueCCType {
  amountPercentage: string;
  amountFixed: string;
  isAutomatic: boolean;
  isManual: boolean;
  optionSelected: 'FLAT' | 'PERCENTAGE';
}
export interface UsageLimitsType {
  selectedItems: string[];
  value: string;
}
export interface CouponChannelsType {
  value: string[];
}
export interface SummaryType {
  value: string[];
}

export interface CatalogProductListType {
  autoMaticProductListIds: number[];
  manualProductListIds: number[];
  selectedProductsIds: number[];
}

export interface RadioSelectorType {
  isCheck: boolean;
  changeCheck: () => void;
  name: string;
  isCheckBox?: boolean;
  isMessage?: boolean;
  isMessageText?: string;
  noMarginBottom?: boolean;
}
export interface StatusType {
  statusValue: string;
  startDate: Date | undefined;
  startTime?: string;
  // startTimeAmPm?: string;
  // endTimeAmPm?: string;
  endDate: Date | undefined;
  endTime?: string;
  isSetEndDate: boolean;
}
export interface CouponCollectionsSection {
  couponCollections: CouponCollectionsType;
  setCouponCollections: React.Dispatch<React.SetStateAction<CouponCollectionsType>>;
}
export interface GeneralInfoSection {
  generalInfo: GeneralInfoCreateCouponType;
  setGeneralInfo: React.Dispatch<React.SetStateAction<GeneralInfoCreateCouponType>>;
}
export interface CouponTypeSection {
  couponType: CouponTypeType;
  setCouponType: React.Dispatch<React.SetStateAction<CouponTypeType>>;
}
export interface MinRequirementTypeSection {
  minRequirement: MinRequirementType;
  setMinRequirement: React.Dispatch<React.SetStateAction<MinRequirementType>>;
  errorMessages: ErrorsInfo;
  setErrorMessages: React.Dispatch<React.SetStateAction<ErrorsInfo>>;
  validateInput: (value: string, fieldName: string) => void;
}
export interface CustomerEligibilityTypeSection {
  customerEligibility: CustomerEligibilityType;
  setCustomerEligibility: React.Dispatch<React.SetStateAction<CustomerEligibilityType>>;
  errorMessages: ErrorsInfo;
}
export interface UsageLimitsTypeSection {
  usageLimits: UsageLimitsType;
  setUsageLimits: React.Dispatch<React.SetStateAction<UsageLimitsType>>;
  errorMessages: ErrorsInfo;
  validateInput: (value: string, fieldName: string) => void;
}
export interface CustomerBuyGetTypeSection {
  customerBuyGet: CustomerBuyGetType;
  setCustomerBuyGet: React.Dispatch<React.SetStateAction<CustomerBuyGetType>>;
}
export interface ValueCCTypeSection {
  value: ValueCCType;
  setValue: React.Dispatch<React.SetStateAction<ValueCCType>>;
  valueType: CouponTypeType;
  errorMessages: ErrorsInfo;
  validateInput: (value: string, fieldName: string) => void;
}
export interface CountriesCCTypeSection {
  countries: CountriesCCType;
  setCountries: React.Dispatch<React.SetStateAction<CountriesCCType>>;
  countryList: CountryItem[];
  errorMessages: ErrorsInfo;
  validateInput: (value: string, fieldName: string) => void;
}
export interface CouponChannelsTypeSection {
  couponChannel: CouponChannelsType;
  setCouponChannel: React.Dispatch<React.SetStateAction<CouponChannelsType>>;
}
export interface SummaryTypeSection {
  summary: SummaryType;
}
export interface StatusTypeSection {
  status: StatusType;
  setStatus: React.Dispatch<React.SetStateAction<StatusType>>;
  setFormChanges: React.Dispatch<React.SetStateAction<boolean>>;
  couponTimesError: CouponTimeError;
  setCouponTimesError: React.Dispatch<React.SetStateAction<CouponTimeError>>;
}

export interface CatalogProductListTypeSection {
  productListData: CatalogProductListType;
  setProductListData: React.Dispatch<React.SetStateAction<CatalogProductListType>>;
}

export interface CountryItem {
  id: string;
  name: string;
}

export interface CreateCouponPresentationalProps
  extends CouponTypeSection,
    MinRequirementTypeSection,
    CustomerEligibilityTypeSection,
    UsageLimitsTypeSection,
    CouponChannelsTypeSection,
    SummaryTypeSection,
    CatalogProductListTypeSection,
    StatusTypeSection,
    ValueCCTypeSection,
    CustomerBuyGetTypeSection,
    CountriesCCTypeSection,
    CouponCollectionsSection,
    GeneralInfoSection {
  goBackToCouponListScreen: () => void;
  couponId: string;
  errorMessages: ErrorsInfo;
  setErrorMessages: React.Dispatch<React.SetStateAction<ErrorsInfo>>;
  countryList: CountryItem[];
  validateInput: (value: string, fieldName: string) => void;

  selectedProductAssignmentMethod?: productAssignmentMethod;
  allConditionMatch: boolean;
  setAllConditionMatch: React.Dispatch<React.SetStateAction<boolean>>;
  selectProductAssignmentMethod: (productAssignmentType: productAssignmentMethod) => void;
  couponTimesError: CouponTimeError;
  setCouponTimesError: React.Dispatch<React.SetStateAction<CouponTimeError>>;
  formChanges: boolean;

  // Rules engine
  rulesChanges: boolean;
  rulesEngineConditions: {
    field: { id: number; name?: string };
    condition: string;
    category: string | { name: string; id: number }[];
    errorMessage?: string;
    openFieldDropdown?: boolean;
    openCategoryDropdown?: boolean;
    openConditionDropdown?: boolean;
  }[];
  setRulesEngineConditions: React.Dispatch<
    React.SetStateAction<
      {
        field: { id: number; name?: string };
        condition: string;
        category: string | { name: string; id: number }[];
        errorMessage?: string;
        openFieldDropdown?: boolean;
        openCategoryDropdown?: boolean;
        openConditionDropdown?: boolean;
      }[]
    >
  >;
  fieldDropdown: {
    id: number;
    name: string;
    operations: { name: string }[];
  }[];
  conditionDropdownArray: {
    categoryDropdown: { id: number; name: string }[];
  }[];
  setConditionDropdownArray: React.Dispatch<
    React.SetStateAction<
      {
        categoryDropdown: {
          name: string;
          id: number;
        }[];
      }[]
    >
  >;

  setFormChanges: React.Dispatch<React.SetStateAction<boolean>>;
  setRulesChanges: React.Dispatch<React.SetStateAction<boolean>>;
  saveModuleRules: () => void;
  setColApiCall: any;
  // Rules engine
}

export enum productAssignmentMethod {
  MANUAL = 'MANUAL',
  AUTOMATIC = 'AUTOMATIC'
}

export type ConditionType = {
  field: {
    id: number;
    name?: string;
  };
  condition: string;
  category: string | { name: string; id: number }[];
  errorMessage?: string;
  openFieldDropdown?: boolean;
  openConditionDropdown?: boolean;
  openCategoryDropdown?: boolean;
};

export type FieldConditionType = {
  id: number;
  name: string;
  operations: { name: string }[];
};
