import { FC, useState, Fragment } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { FlexBox } from 'src/components/atoms';
import { Dropdown } from 'src/components/molecules';

// Icons
import { RightArrowIcon } from 'src/assets/icons';

// Types
import { UserRoles } from '../../settings/UserPermissions/UserPermissions.type';

interface OrderDetailVariantsCellProps {
  summaryProduct: any;
  getDropDownData: (summaryProduct: any) => { name: string; id: number; productId: number; itemId: number }[];
  selectVariant: (data: { id: number; name: string; productId: number; itemId: number }) => void;
  dropdownData: any[];
}

const OrderDetailVariantsCell: FC<OrderDetailVariantsCellProps> = ({
  summaryProduct,
  getDropDownData,
  selectVariant,
  dropdownData
}) => {
  const [isShowDropdown, setIsShowDropdown] = useState(false);

  const closeDropdown = () => {
    setIsShowDropdown(false);
  };

  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  return (
    <div className="position-relative">
      {summaryProduct.variantId ? (
        <OutsideClickHandler
          onOutsideClick={() => {
            if (isShowDropdown) closeDropdown();
          }}
        >
          <FlexBox
            className={`form-control form-control-lg fs-14 px-2 justify-content-between align-items-center cursor-pointer  dropdown-box ${
              isShowDropdown ? 'dropdown-box-active' : ''
            } ${role === UserRoles.ADMIN_ASSOCIATE || role === UserRoles.WAREHOUSE_ASSOCIATE ? 'disable-background' : ''}`}
            onClick={() => setIsShowDropdown(true)}
          >
            <FlexBox className="align-items-center w-100">
              <div>
                {dropdownData?.map((variant, i) => {
                  return (
                    <Fragment key={i}>
                      {summaryProduct.id === variant.orderItemId && (
                        <>
                          {summaryProduct.variantId === variant.selectedVariantId && (
                            <div className="osVariantName" key={i}>{`${variant.variants.name}`}</div>
                          )}
                        </>
                      )}
                    </Fragment>
                  );
                })}
              </div>
            </FlexBox>

            <div className="ms-2 rightToDown">
              <img src={RightArrowIcon} className="h-10px w-10px" />
            </div>
          </FlexBox>
          <Dropdown
            data={getDropDownData(summaryProduct)}
            selected={isShowDropdown}
            onSelect={selectVariant}
            closeDropdown={closeDropdown}
          />
        </OutsideClickHandler>
      ) : null}
    </div>
  );
};

export default OrderDetailVariantsCell;
