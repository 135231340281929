export const SortIds = [
  {
    id: 21,
    name: 'Products'
  },
  {
    id: 82,
    name: 'SKU'
  },
  {
    id: 100714,
    name: 'Incoming'
  },
  {
    id: 84,
    name: 'Commited'
  },
  {
    id: 85,
    name: 'Low stock alert'
  },
  {
    id: 86,
    name: 'Stock level'
  }
];
