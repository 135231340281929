export const setStatusColor = (status: string, isPartial?: boolean) => {
  if (status === 'awaiting_fulfillment') {
    return 'to-fulfil-order-status-box';
  } else if (status === 'pending') {
    return 'pending-order-status-box';
  } else if (status === 'awaiting_payment' || status === 'awaiting_processing') {
    return 'to-process-order-status-box';
  } else if (status === 'awaiting_shipment' || status === 'awiting_pickup' || status === 'partially_shipped') {
    return 'to-ship-order-status-box';
  } else if ((status === 'completed' || status === 'shipped') && isPartial) {
    return 'pending-order-status-box';
  } else if (status === 'completed' || status === 'shipped') {
    return 'shipped-order-status-box';
  } else if (status === 'partially_refund' || status === 'cancelled' || status === 'declined' || status === 'refunded') {
    return 'error-order-status-box';
  } else if (status === 'disputed' || status === 'manual_verification_required') {
    return 'disputed-order-status-box';
  }
  return '';
};

export const setStatusText = (status: string, isPartial?: boolean) => {
  if (status === 'pending') {
    return 'Pending';
  } else if (status === 'awaiting_payment' || status === 'awaiting_processing') {
    return 'To process';
  } else if (status === 'awaiting_fulfillment') {
    return 'To Fulfill';
  } else if (status === 'awaiting_shipment' || status === 'awiting_pickup' || status === 'partially_shipped') {
    return 'To ship';
  } else if ((status === 'completed' || status === 'shipped') && isPartial) {
    return 'Pending';
  } else if (status === 'completed') {
    return 'Delivered';
  } else if (status === 'shipped') {
    return 'Shipped';
  } else if (status === 'manual_verification_required') {
    return 'Manual verification required';
  } else if (status === 'partially_refund') {
    return 'Partially refund';
  }
  return status;
};
