import { FC, useMemo, useState } from 'react';
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_SHOPIFY_ADMIN_BASE_URL } from 'src/apollo/queries';

// Components
import { FlexBox } from 'src/components/atoms';
import { InputBox, ShopifySyncTooltip } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { LinkIcon, ShopifyIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { GeneralSection } from './GeneralInfoSection.types';

// Styles
import './_generalInfoSection.scss';

const GeneralInfoSectionPresentational: FC<GeneralSection> = ({
  type,
  maxLength,
  generalInfo,
  errorMessages,
  setGeneralInfo,
  setFormChanges,
  setColApiCall,
  isDisableInput
}) => {
  const [copied, setCopied] = useState<string | undefined>('');

  const { data: { shopifyAdminBaseUrl } = { shopifyAdminBaseUrl: null } } = useQuery(GET_SHOPIFY_ADMIN_BASE_URL);

  const copyLink = (text) => {
    navigator?.clipboard?.writeText(text);
    setCopied(text);
    setTimeout(() => {
      setCopied('');
    }, 3000);
  };

  const changeName = (e: string) => {
    if (setColApiCall && setFormChanges) {
      setColApiCall(true);
      setFormChanges(true);
    }
    setGeneralInfo((prev) => ({ ...prev, value: e.trimStart() }));
  };

  const goToShopifyPage = (shopifyLinkId) => {
    if (shopifyAdminBaseUrl) {
      if (type === 'Product') {
        window.open(`${shopifyAdminBaseUrl}products/${shopifyLinkId}`, '_blank');
      }
      if (type === 'Collection') {
        window.open(`${shopifyAdminBaseUrl}collections/${shopifyLinkId}`, '_blank');
      }
    }
  };

  const tooltipText = useMemo(() => {
    return `${type === 'Product' ? 'Product' : 'Collection'} from Shopify`;
  }, [type]);

  return (
    <div className="card w-100">
      <div className="m-b-32">
        <FlexBox className={`align-items-center ${generalInfo.updatedAt ? 'm-b-8' : 'mb-0'}`}>
          <h4 className="section-title mb-0">{constVariables.common.general.key}</h4>
          {generalInfo?.externalProvider === 'SHOPIFY' && (
            <>
              <img
                src={ShopifyIcon}
                className="cursor-pointer m-l-8"
                data-tip
                data-for={type === 'Product' ? 'productShopifyIcon' : 'collectionShopifyIcon'}
                onClick={() => goToShopifyPage(generalInfo?.externalId)}
              />
              <ShopifySyncTooltip
                tooltipText={tooltipText}
                tooltipId={type === 'Product' ? 'productShopifyIcon' : 'collectionShopifyIcon'}
              />
            </>
          )}
        </FlexBox>
        {generalInfo.updatedAt ? (
          <p className="helper-text-one fs-8 mb-0">
            {`Last update ${moment(generalInfo.updatedAt).format('D MMMM YYYY')} at ${moment(generalInfo.updatedAt).format(
              'h:mm a'
            )} by`}{' '}
            <span className="text-primary cursor-pointer ">you</span>
          </p>
        ) : null}
      </div>
      <InputBox
        isCatalog={generalInfo.isCatalog}
        inputClass="form-control-lg"
        mainclass="m-b-24"
        title={generalInfo.title}
        imageRight={<span className="text-danger fs-4 px-1">*</span>}
        value={generalInfo.value}
        onChangeText={changeName}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        name={generalInfo.name!}
        placeholder={generalInfo.placeHolder}
        helperText={generalInfo.helperText}
        warningText={errorMessages?.generalInfoTitleError}
        maxLength={maxLength}
        disable={isDisableInput}
      />
      {generalInfo.isCatalog ? (
        <>
          <div className="poppins-semibold fs-6">{constVariables.Catalogs.addCatalog.catalogUrlTitle}</div>
          <FlexBox className="align-items-center m-t-8">
            <div className="poppins-regular fs-7 curser-pointer">
              <span className="text-muted">{generalInfo.link}</span>
            </div>
            <div className="ms-4 d-flex align-items-center justify-content-start">
              <KTSVG path={LinkIcon} />
              <span className="ms-2 text-primary cursor-pointer " onClick={() => copyLink(generalInfo.link)}>
                {copied && copied?.length > 0 ? 'Copied !' : 'Copy Link'}
              </span>
            </div>
          </FlexBox>
        </>
      ) : (
        <div style={{ minHeight: '20vh' }}>
          <div className="text-nowrap input-title">{constVariables.common.general.description}</div>
          <div className={`${isDisableInput ? 'disable-background' : ''}`}>
            <ReactQuill
              placeholder="Type your text here..."
              value={generalInfo.description}
              onChange={(e) => {
                setGeneralInfo((prev) => ({
                  ...prev,
                  description: e
                }));
              }}
              theme="snow"
            />
          </div>
        </div>
      )}
      <span className="helper-text-bottom mt-1">{generalInfo.helperTextDescription}</span>
    </div>
  );
};

export default GeneralInfoSectionPresentational;
