import { useEffect, useState, FC, memo } from 'react';

// Components
import { VendorList, AddVendor, ScreenHeader } from 'src/components/oraganisms';

// ConstVariables
import { constVariables } from 'src/constants';

interface VendorManagementPresentationalProps {
  vendorDetailsOpen: boolean;
  selectedIntTab?: string;
  addVendorModal: boolean;
  refetchList: boolean;
  openEditColumnDropdown: boolean;
  editColumnData: {
    name: string;
    status: boolean;
  }[];
  handleVendorDetails(e: React.MouseEvent, vendor: any): void;
  addVendor: () => void;
  setOpenEditColumnDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  setEditColumnData: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
        status: boolean;
      }[]
    >
  >;
}
const VendorManagementPresentational: FC<VendorManagementPresentationalProps> = (props) => {
  const {
    selectedIntTab,
    addVendorModal,
    refetchList,
    openEditColumnDropdown,
    editColumnData,
    handleVendorDetails,
    addVendor,
    setOpenEditColumnDropdown,
    setEditColumnData
  } = props;

  const [selectedTab, setSelectedTab] = useState(constVariables.Tabs.allVendors.toUpperCase());
  useEffect(() => {
    if (selectedIntTab) {
      setSelectedTab(selectedIntTab);
    }
  }, [selectedIntTab]);

  const breadCrumbDetailsData = [
    {
      title: constVariables.VendorManagementMenu.vendorManagement,
      path: '/vendorManagement/vendors',
      isActive: false
    },
    {
      title: constVariables.VendorManagementMenu.vendors,
      path: '/vendorManagement/vendors',
      isActive: true
    }
  ];
  const breadCrumbProductData = [
    {
      title: constVariables.VendorManagementMenu.vendorManagement,
      path: '/vendorManagement/vendors',
      isActive: false
    },
    {
      title: constVariables.VendorManagementMenu.vendors,
      path: '/vendorManagement/vendors',
      isActive: false
    },
    {
      title: constVariables.ProductsTab.allProducts,
      path: '/vendorManagement',
      isActive: true
    }
  ];
  return (
    <>
      <div className="p-40">
        <div className={`width-animation w-100 hide-scrollbar ${addVendorModal ? 'vh-100' : 'min-h-100'}`}>
          <ScreenHeader
            breadCrumbsList={selectedTab === constVariables.VendorsTab.allVendors ? breadCrumbProductData : breadCrumbDetailsData}
            headerTitle={constVariables.VendorManagementMenu.vendors}
            headerButtonText={constVariables.VendorManagementMenu.addVendor}
            headerButtonClick={() => addVendor()}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            tabList={constVariables.TabForVendors}
            setOpenEditColumnDropdown={setOpenEditColumnDropdown}
            editColumnText={constVariables.VendorDetails.allProduct.editColumn}
            openEditColumnDropdown={openEditColumnDropdown}
            editColumnData={editColumnData}
            setEditColumnData={setEditColumnData}
            removeExportImport={false}
          />
          <div className="card w-100 mb-0 p-0">
            <VendorList
              handleVendorDetails={handleVendorDetails}
              selectedTab={selectedTab}
              refetchList={refetchList}
              editColumnData={editColumnData}
              addVendor={addVendor}
            />
          </div>
        </div>
      </div>
      <AddVendor show={addVendorModal} resetState={addVendor} data={null} />
    </>
  );
};

export default memo(VendorManagementPresentational);
