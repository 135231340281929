import { gql } from '@apollo/client';

export const ADD_PRODUCT_TO_CATALOGUE = gql`
  mutation AddProductToCatalogue($input: CatalogueMutationInput!) @api(name: "productsAPI") {
    addProductToCatalogue(input: $input) {
      success
      message
    }
  }
`;
