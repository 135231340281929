import { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import WorldData from 'country-state-city-plus';
import validator from 'validator';

// Apollo
import { useQuery, useMutation } from '@apollo/client';
import { GET_CUSTOMER_DETAILS } from 'src/apollo/queries';
import {
  ADD_USER_NOTE,
  BLOCK_USER_FROM_LIVE,
  CLEAR_USER_CART,
  CLEAR_USER_WISHLIST,
  DELETE_USER,
  DELETE_USER_NOTE,
  DELETE_USER_TAG,
  UPDATE_PROFILE_INFO,
  UPDATE_STORE_CREDIT,
  UPDATE_CUSTOMER_TAGS
} from 'src/apollo/mutations';

// Components
import { AddCustomerAddress, SaveHeader } from 'src/components/oraganisms';
import CustomerDetailsPresentational from './CustomerDetails.presentational';

// Hooks && Utils && Helpers
import { useToast } from 'src/utils/hooks/useToast';

// ConstVariables
import { constVariables, ROUTES } from 'src/constants';

// Types
import {
  AddressCDType,
  ContactInfoDataCDType,
  GeneralDataCDType,
  NotesCDType,
  OverviewDataCDType,
  StoreCreditDataCDType,
  CustomerTag
} from './CustomerDetails.types';
import { CustomerAddressDropdownProps } from 'src/components/oraganisms/AddCustomerAddress/AddCustomerAddress.types';

// Styles
import './_customerDetails.scss';

const CustomerDetails: FC = () => {
  const history = useHistory();
  const { showToast } = useToast();
  const [addCustomerAddressModal, setAddCustomerAddressModal] = useState(false);
  const [disableBlockButton, setDisableBlockButton] = useState(false);
  const [phoneList, setPhoneList] = useState<CustomerAddressDropdownProps[]>();
  const [editContactInfo, setEditContactInfo] = useState(false);
  const { customerId } = useParams<{ customerId: string }>();
  const [formChanges, setFormChanges] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  const [generalDataCustomerDetails, setGeneralDataCustomerDetails] = useState<GeneralDataCDType>({
    name: '',
    joinedOn: '',
    isBlockedForLive: false,
    email: '',
    profilePic: '',
    isVIPCustomer: false,
    isFirstTimeCustomer: false,
    shopifyId: undefined,
    shopifyUrl: undefined
  });

  const [overviewDataCustomerDetails, setOverviewDataCustomerDetails] = useState<OverviewDataCDType>({
    averageOrderValue: undefined,
    totalAmountSpentValue: undefined,
    numberOfProducts: undefined,
    lastOrderDate: '',
    productsRefunded: undefined
  });

  const [storeCreditDataCustomerDetails, setStoreCreditDataCustomerDetails] = useState<StoreCreditDataCDType>({
    storeCreditValue: undefined,
    isBlocked: false
  });

  const [contactInfoDataCustomerDetails, setContactInfoDataCustomerDetails] = useState<ContactInfoDataCDType>({
    email: '',
    isEmailVerified: false,
    phone: '',
    isPhoneVerified: false,
    editingPhone: '',
    editingEmail: '',
    lastEmail: '',
    lastPhone: '',
    selectedPhone: '+1',
    selectPhoneDropdown: false,
    saveButtonDisabled: false,
    canEdit: false
  });

  const [addressesCustomerDetails, setAddressesCustomerDetails] = useState<AddressCDType>({
    userAddress: [],
    freeShipping: false
  });
  const [notesCustomerDetails, setNotesCustomerDetails] = useState<NotesCDType>({
    userNotes: []
  });
  const [customerTags, setCustomerTags] = useState<CustomerTag[]>([]);
  const [selectedTags, setSelectedTags] = useState<CustomerTag[]>([]);

  const [addUserNote] = useMutation(ADD_USER_NOTE, {
    onCompleted: () => {
      refetchCustomerDetails();
    },
    onError: (error) => {
      console.error('Add User Note error', error);
    }
  });
  const [updateProfileInfoEmail] = useMutation(UPDATE_PROFILE_INFO, {
    onCompleted: () => {
      if (
        contactInfoDataCustomerDetails.editingPhone &&
        contactInfoDataCustomerDetails.selectedPhone &&
        validator.isMobilePhone(contactInfoDataCustomerDetails.editingPhone)
      ) {
        setTimeout(() => {
          updateProfileInfoPhone({
            variables: {
              input: {
                userId: customerId,
                updateProfileInfoType: 'MOBILE_NUMBER',
                newValue: `${contactInfoDataCustomerDetails.selectedPhone}${contactInfoDataCustomerDetails.editingPhone}`
              }
            }
          });
        }, 1000);
      }
    },
    onError: (error) => {
      setEditContactInfo(false);
      console.error('Update profile info error', error);
    }
  });
  const [updateProfileInfoPhone] = useMutation(UPDATE_PROFILE_INFO, {
    onCompleted: () => {
      refetchCustomerDetails();
    },
    onError: (error) => {
      refetchCustomerDetails();
      console.error('Update profile info error', error);
    }
  });
  const [updateProfileInfoJustPhone] = useMutation(UPDATE_PROFILE_INFO, {
    onCompleted: () => {
      refetchCustomerDetails();
    },
    onError: (error) => {
      setEditContactInfo(false);
      console.error('Update profile info error', error);
    }
  });
  const getCountryAndPhoneList = () => {
    const getAllCountries = WorldData.getAllCountries();
    let sortAllCountries: {
      id: string;
      sortname: string;
      name: string;
      phonecode: string;
    }[] = [];

    if (getAllCountries) {
      const temSported = getAllCountries
        .map((data) => parseInt(data.phonecode, 10))
        .sort(function (a, b) {
          return a - b;
        });
      const sorted = temSported.filter(function (item, pos) {
        return temSported.indexOf(item) === pos;
      });

      sorted.forEach((data) => {
        const currentIndex = getAllCountries.findIndex((indexData) => indexData.phonecode === data.toString());
        sortAllCountries = [...sortAllCountries, getAllCountries[currentIndex]];
      });

      const tempPhoneList = sortAllCountries.map((data, index) => {
        return { id: index, name: `+${data.phonecode.toString()}` };
      });
      setPhoneList(tempPhoneList);
    }
  };

  const [updateCustomerTags] = useMutation(UPDATE_CUSTOMER_TAGS, {
    onCompleted: (res) => {
      if (res?.updateCustomer?.success) {
        setFormChanges(false);
        setDisableSaveButton(false);
        showToast({ successText: 'Customer Tags saved successfully', message: `Customer info has been edited sucessfully` });
      }
    },
    onError: (error) => {
      setFormChanges(false);
      setDisableSaveButton(false);
    }
  });

  const [deleteUserNote] = useMutation(DELETE_USER_NOTE, {
    onCompleted: () => {
      refetchCustomerDetails();
    },
    onError: (error) => {
      console.error('Delete user Note error', error);
    }
  });

  const [addStoreCredit] = useMutation(UPDATE_STORE_CREDIT, {
    onCompleted: () => {
      refetchCustomerDetails();
    },
    onError: (error) => {
      console.error('Add Store Credit error', error);
    }
  });

  const [subStoreCredit] = useMutation(UPDATE_STORE_CREDIT, {
    onCompleted: () => {
      refetchCustomerDetails();
    },
    onError: (error) => {
      console.error('Add Store Credit error', error);
    }
  });
  const { refetch: refetchCustomerDetails, loading: isLoading } = useQuery(GET_CUSTOMER_DETAILS, {
    variables: {
      input: {
        userId: parseInt(customerId, 10)
      }
    },
    onCompleted: (customerData) => {
      if (customerData.getCustomerDetails) {
        document.title = `Customers - ${customerData?.getCustomerDetails?.name ? customerData?.getCustomerDetails?.name : ''}`;
        setGeneralDataCustomerDetails({
          name: customerData.getCustomerDetails.name,
          joinedOn: customerData.getCustomerDetails.joinedOn,
          isBlockedForLive: customerData.getCustomerDetails.isBlockedForLive,
          email: customerData.getCustomerDetails.email,
          profilePic: customerData.getCustomerDetails.profileUrl,
          isVIPCustomer: !customerData.getCustomerDetails.isVIPCustomer ? false : true,
          isFirstTimeCustomer: customerData.getCustomerDetails.totalAmountSpent === 0 ? true : false,
          shopifyId: customerData.getCustomerDetails.shopifyId,
          shopifyUrl: customerData.getCustomerDetails.shopifyUrl
        });
        setOverviewDataCustomerDetails({
          averageOrderValue: customerData.getCustomerDetails.totalAmountSpent / customerData.getCustomerDetails.totalNumberOfOrders,
          totalAmountSpentValue: customerData.getCustomerDetails.totalAmountSpent,
          numberOfProducts: customerData.getCustomerDetails.totalNumberOfOrders,
          lastOrderDate: customerData.getCustomerDetails.lastOrderDate,
          productsRefunded: customerData.getCustomerDetails.numberOfProductsRefunded
        });
        setStoreCreditDataCustomerDetails({
          storeCreditValue: customerData.getCustomerDetails.availableStoreCredit,
          isBlocked: customerData.getCustomerDetails.isBlockedForLive
        });

        setContactInfoDataCustomerDetails({
          email: customerData.getCustomerDetails.email,
          isEmailVerified: customerData.getCustomerDetails.isEmailVerified,
          phone: customerData.getCustomerDetails.phone,
          isPhoneVerified: customerData.getCustomerDetails.isPhoneNoVerified,
          editingPhone: customerData.getCustomerDetails.phone ? customerData.getCustomerDetails.phone : '',
          editingEmail: customerData.getCustomerDetails.email ? customerData.getCustomerDetails.email : '',
          lastEmail: customerData.getCustomerDetails.email ? customerData.getCustomerDetails.email : '',
          lastPhone: customerData.getCustomerDetails.phone ? customerData.getCustomerDetails.phone : '',
          selectedPhone: customerData.getCustomerDetails.phoneCountryCode
            ? `+${customerData.getCustomerDetails.phoneCountryCode}`
            : '+1',
          selectPhoneDropdown: false,
          saveButtonDisabled: false,
          canEdit: customerData.getCustomerDetails.signupMedium === 'EMAIL' ? true : false
        });
        setAddressesCustomerDetails({
          freeShipping: customerData.getCustomerDetails.isEligibleForFreeShipping,
          userAddress: customerData.getCustomerDetails.addresses
        });
        setNotesCustomerDetails({
          userNotes: customerData.getCustomerDetails.userNotes
        });
        setCustomerTags(
          customerData.getCustomerDetails.userTags?.map((tagItem) => {
            return {
              id: tagItem.id,
              name: tagItem.tag,
              textColorCode: tagItem.color,
              backgroundColorCode: tagItem.bgColor
            };
          })
        );
        setSelectedTags(
          customerData.getCustomerDetails.userTags?.map((tagItem) => {
            return {
              id: tagItem.id,
              name: tagItem.tag,
              textColorCode: tagItem.color,
              backgroundColorCode: tagItem.bgColor
            };
          })
        );
        setTimeout(() => {
          setEditContactInfo(false);
        }, 500);
      }
    },
    onError: (error) => {
      console.error('Customer Details error', error);
    },
    fetchPolicy: 'network-only'
  });

  const [blockUnblockUser] = useMutation(BLOCK_USER_FROM_LIVE, {
    onCompleted: (result) => {
      if (result.blockUserFromComments.success) {
        refetchCustomerDetails();
      }
      setDisableBlockButton(false);
    },
    onError: (error) => {
      console.error('Block unblock user error', error);
      setDisableBlockButton(false);
    }
  });

  const [clearUserCart] = useMutation(CLEAR_USER_CART, {
    onCompleted: (result) => {
      showToast({
        successText: result.clearUserCart.message,
        message: result.clearUserCart.message
      });
    },
    onError: (error) => {
      showToast({
        errorText: error.message,
        message: `Error occured while clearing cart: ${error.message}`
      });
    }
  });

  const [clearUserWishlist] = useMutation(CLEAR_USER_WISHLIST, {
    onCompleted: (result) => {
      showToast({
        successText: result.clearUserWishlist.message,
        message: result.clearUserWishlist.message
      });
    },
    onError: (error) => {
      showToast({
        errorText: error.message,
        message: `Error occured while clearing cart: ${error.message}`
      });
    }
  });

  const [deleteUser] = useMutation(DELETE_USER, {
    onCompleted: (result) => {
      if (result.deleteUser.success) {
        history.push(ROUTES.customers.allCustomers.main);
      }
    },
    onError: (error) => {
      console.error('Delete user error', error);
    }
  });
  const blockUnblockFunction = (id: string) => {
    if (id) {
      blockUnblockUser({
        variables: {
          input: { userId: id, action: generalDataCustomerDetails.isBlockedForLive ? 'UNBLOCK' : 'BLOCK' }
        }
      }).then((res) => {
        if (res?.data?.blockUserFromComments?.success) {
          showToast({
            successText: `<span style="color: #1e2749;">Customer is<span> ${
              generalDataCustomerDetails.isBlockedForLive
                ? `<span style="color:#f1416c;">blocked</span>`
                : `<span style="color:#50cd89;">unblocked</span>`
            }`,
            message: generalDataCustomerDetails.isBlockedForLive
              ? constVariables.customers.blockedMessage
              : constVariables.customers.unBlockedMessage
          });
        }
      });
    }
  };
  const deleteUserFunction = (id: string) => {
    if (id) {
      deleteUser({
        variables: {
          input: { userId: id }
        }
      });
    }
  };

  useEffect(() => {
    getCountryAndPhoneList();
  }, []);

  const addNewNote = (newNoteData: string) => {
    addUserNote({
      variables: {
        input: {
          note: newNoteData,
          userId: customerId
        }
      }
    });
  };
  const editOldNote = (oldNoteData: string, noteId: number) => {
    addUserNote({
      variables: {
        input: {
          userId: customerId,
          note: oldNoteData,
          id: noteId
        }
      }
    });
  };
  const deleteOldNote = (noteId: number) => {
    deleteUserNote({
      variables: {
        input: {
          noteId
        }
      }
    });
  };

  const saveEmailPhoneEdit = () => {
    setContactInfoDataCustomerDetails((prev) => ({
      ...prev,
      saveButtonDisabled: true
    }));
    let count = 0;

    if (
      contactInfoDataCustomerDetails.editingEmail &&
      validator.isEmail(contactInfoDataCustomerDetails.editingEmail) &&
      contactInfoDataCustomerDetails.editingEmail !== contactInfoDataCustomerDetails.lastEmail
    ) {
      count++;
      updateProfileInfoEmail({
        variables: {
          input: {
            userId: customerId,
            updateProfileInfoType: 'EMAIL',
            newValue: contactInfoDataCustomerDetails.editingEmail
          }
        }
      });
    } else if (
      contactInfoDataCustomerDetails.editingPhone &&
      contactInfoDataCustomerDetails.selectedPhone &&
      validator.isMobilePhone(contactInfoDataCustomerDetails.editingPhone) &&
      contactInfoDataCustomerDetails.editingPhone !== contactInfoDataCustomerDetails.lastPhone
    ) {
      count++;
      updateProfileInfoJustPhone({
        variables: {
          input: {
            userId: customerId,
            updateProfileInfoType: 'MOBILE_NUMBER',
            newValue: `${contactInfoDataCustomerDetails.selectedPhone}${contactInfoDataCustomerDetails.editingPhone}`
          }
        }
      });
    }

    if (count === 0) {
      setContactInfoDataCustomerDetails((prev) => ({
        ...prev,
        saveButtonDisabled: false
      }));
    }
  };
  const cancelEmailPhoneEdit = () => {
    setContactInfoDataCustomerDetails((prev) => ({
      ...prev,
      editingPhone: prev.phone ? prev.phone : '',
      editingEmail: prev.email ? prev.email : ''
    }));
    setEditContactInfo(false);
  };

  const addDataStoreCredit = (amount: number) => {
    addStoreCredit({
      variables: {
        input: {
          type: 'CREDITED',
          amount,
          customerIds: parseInt(customerId, 10)
        }
      }
    }).then((res) => {
      if (res?.data?.manageStoreCredits?.success) {
        showToast({
          successText: `<span style="color: #1e2749;">$${amount}<span> <span style="color:#50cd89;">added successfully</span> to customer’s store credit!`,
          message: ``
        });
      }
    });
  };

  const subtractStoreCredit = (amount: number) => {
    subStoreCredit({
      variables: {
        input: {
          type: 'DEBITED',
          amount,
          customerIds: parseInt(customerId, 10)
        }
      }
    }).then((res) => {
      if (res?.data?.manageStoreCredits?.success) {
        showToast({
          successText: `<span style="color: #1e2749;">$${amount}<span> <span style="color:#f1416c;">subtracted </span> from customer’s store credit.`,
          message: ``
        });
      }
    });
  };
  const addAddress = () => {
    setAddCustomerAddressModal(false);
    refetchCustomerDetails();
  };
  const closeAddressModal = () => {
    setAddCustomerAddressModal(false);
  };
  const openAddressModal = () => {
    setAddCustomerAddressModal(true);
  };
  const toggleEditContactInfo = () => {
    setEditContactInfo(!editContactInfo);
  };
  const changeEmailAddress = (data: string) => {
    setContactInfoDataCustomerDetails((prev) => ({
      ...prev,
      editingEmail: data
    }));
  };
  const changePhoneNumber = (data: string) => {
    setContactInfoDataCustomerDetails((prev) => ({
      ...prev,
      editingPhone: data
    }));
  };
  const togglePhoneDropdown = () => {
    setContactInfoDataCustomerDetails((prev) => ({
      ...prev,
      selectPhoneDropdown: !contactInfoDataCustomerDetails.selectPhoneDropdown
    }));
  };

  const closePhoneDropdown = () => {
    setContactInfoDataCustomerDetails((prev) => ({
      ...prev,
      selectPhoneDropdown: false
    }));
  };
  const onSelectPhone = (data: any) => {
    let tempNumber = data.name;
    if (data.name[0] !== '+') {
      tempNumber = `+${tempNumber}`;
    }
    setContactInfoDataCustomerDetails((prev) => ({
      ...prev,
      selectedPhone: tempNumber,
      selectPhoneDropdown: false
    }));
  };

  const handleDiscard = () => {
    console.log('call handleDiscard::');
  };

  const saveCustomerTags = () => {
    if (customerId) {
      setDisableSaveButton(true);
      updateCustomerTags({
        variables: {
          input: {
            userId: customerId,
            tags: selectedTags?.map((tagItem) => tagItem.id)
          }
        }
      });
    }
  };

  return (
    <>
      {formChanges && <SaveHeader onSave={saveCustomerTags} saveDisabled={disableSaveButton} onDiscard={handleDiscard} />}
      <div className="p-40">
        <div className="details-page-container">
          <div className="width-animation w-100 hide-scrollbar">
            <CustomerDetailsPresentational
              isLoading={isLoading}
              refetchCustomerDetails={refetchCustomerDetails}
              overViewData={overviewDataCustomerDetails}
              setOverViewData={setOverviewDataCustomerDetails}
              storeCreditData={storeCreditDataCustomerDetails}
              setStoreCreditData={setStoreCreditDataCustomerDetails}
              contactInfoData={contactInfoDataCustomerDetails}
              setContactInfoData={setContactInfoDataCustomerDetails}
              generalData={generalDataCustomerDetails}
              setGeneralDataData={setGeneralDataCustomerDetails}
              addressData={addressesCustomerDetails}
              setAddressData={setAddressesCustomerDetails}
              notesData={notesCustomerDetails}
              setNotesData={setNotesCustomerDetails}
              tagsData={customerTags}
              setTagsData={setCustomerTags}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
              blockUnblockFunction={blockUnblockFunction}
              deleteUserFunction={deleteUserFunction}
              customerId={customerId}
              clearUserCart={clearUserCart}
              clearUserWishlist={clearUserWishlist}
              disableBlockButton={disableBlockButton}
              setDisableBlockButton={setDisableBlockButton}
              addNewNote={addNewNote}
              editOldNote={editOldNote}
              deleteOldNote={deleteOldNote}
              openAddressModal={openAddressModal}
              changeEmailAddress={changeEmailAddress}
              changePhoneNumber={changePhoneNumber}
              toggleEditContactInfo={toggleEditContactInfo}
              togglePhoneDropdown={togglePhoneDropdown}
              closePhoneDropdown={closePhoneDropdown}
              phoneList={phoneList}
              editContactInfo={editContactInfo}
              onSelectPhone={onSelectPhone}
              saveEmailPhoneEdit={saveEmailPhoneEdit}
              cancelEmailPhoneEdit={cancelEmailPhoneEdit}
              addDataStoreCredit={addDataStoreCredit}
              subtractStoreCredit={subtractStoreCredit}
              setFormChanges={setFormChanges}
            />
          </div>
        </div>
        <div className={`width-animation w-100 hide-scrollbar`}>
          <AddCustomerAddress
            customerId={customerId}
            show={addCustomerAddressModal}
            resetState={addAddress}
            closeAddressModal={closeAddressModal}
          />
        </div>
      </div>
    </>
  );
};
export default CustomerDetails;
