import { useState, useEffect, FC } from 'react';
import clsx from 'clsx';

// Apollo
import { useQuery, useMutation } from '@apollo/client';
import { GET_VENDORS_PRODUCTS, SET_PRODUCTS_STATUS } from 'src/apollo/queries/getVendorProducts';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import VendorAllProductsPresentational from './VendorAllProducts.presentational';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';

// Icons
import { DefaultImage } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { RowObjectProps, TablesProps } from 'src/components/molecules/Table/Table.types';

// Styles
import './VendorAllProducts.scss';
interface VendorAllProductsProps {
  selectedTab: string;
  refetchList: boolean;
}

const tableData: TablesProps = {
  isDragDisabled: true,
  rows: []
};

const AllSortCategories = [
  {
    name: constVariables.sortCategories.name,
    id: 1,
    label: constVariables.sortLabels.name
  },
  {
    name: constVariables.sortCategories.availability,
    id: 2,
    label: constVariables.sortLabels.availability
  },
  {
    name: constVariables.sortCategories.type,
    id: 3,
    label: constVariables.sortLabels.type
  },
  {
    name: constVariables.sortCategories.brand,
    id: 4,
    label: constVariables.sortLabels.brand
  },
  {
    name: constVariables.sortCategories.price,
    id: 5,
    label: constVariables.sortLabels.price
  },
  {
    name: constVariables.sortCategories.status,
    id: 6,
    label: constVariables.sortLabels.status
  },
  {
    name: constVariables.sortCategories.createdAt,
    id: 7,
    label: constVariables.sortLabels.createdAt
  },
  {
    name: constVariables.sortCategories.updatedAt,
    id: 8,
    label: constVariables.sortLabels.updatedAt
  }
];

const VendorAllProducts: FC<VendorAllProductsProps> = (props) => {
  const { selectedTab, refetchList } = props;

  const [showVendorDetails] = useState(-1);
  const [skipCount, setSkipCount] = useState(0);
  const [totalVendors, setTotalVendors] = useState(0);
  const [vendorsProduct, setvendorsProduct] = useState([]);
  const [limitCount, setLimitCount] = useState(50);
  const [selectedVendorProducts, setSelectedVendorProducts] = useState<number[]>([]);
  const [sortBy, setSortBy] = useState<{
    catagory: string;
    id: number;
    type: string;
  }>({
    catagory: '',
    id: 0,
    type: ''
  });
  const [openSortDropdown, setOpenSortDropdown] = useState(false);
  const [changeProductStatus, { data: productStatusData }] = useMutation(SET_PRODUCTS_STATUS);
  const productsNotSelected = vendorsProduct?.filter((row: any) => row.isSelected === false);
  const onlyUnSelected = productsNotSelected?.filter((data: any) => selectedVendorProducts?.includes(data.id));

  const productsInput: {
    vendorId: number;
    pageInfo: {
      skipCount: number;
      limitCount: number;
    };
    type: string;
    sortInfo?: {
      key: string;
      order: string;
    };
  } = {
    vendorId: 28,
    pageInfo: {
      skipCount,
      limitCount
    },
    type: selectedTab === constVariables.Tabs.allProduct.toUpperCase() ? 'ALL' : 'SELECTED'
  };

  if (sortBy.catagory) {
    productsInput.sortInfo = {
      key: sortBy.catagory,
      order: sortBy.type
    };
  }

  const { data: vendorsProductsData, refetch: refetchVendors } = useQuery(GET_VENDORS_PRODUCTS, {
    variables: {
      input: productsInput
    },
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    if (productStatusData?.addOrRemoveProductFromSelected) {
      refetchVendors();
      setSelectedVendorProducts([]);
    }
  }, [productStatusData?.addOrRemoveProductFromSelected, refetchVendors]);

  useEffect(() => {
    if (selectedTab === constVariables.Tabs.allProduct && vendorsProductsData?.getVendorProducts?.products?.length > 0) {
      setvendorsProduct(vendorsProductsData?.getVendorProducts?.products);
    } else if (
      selectedTab === constVariables.Tabs.selectedProduct &&
      vendorsProductsData?.getVendorProducts?.products?.length >= 0
    ) {
      setvendorsProduct(vendorsProductsData?.getVendorProducts?.products?.filter((row: any) => row.isSelected === true));
    }
    if (vendorsProductsData?.getVendorProducts?.total) {
      setTotalVendors(vendorsProductsData?.getVendorProducts?.total);
    }
  }, [selectedTab, refetchList, vendorsProductsData]);

  const handleChangeProductStatus = (status: boolean) => {
    changeProductStatus({
      variables: {
        input: {
          ids: selectedVendorProducts,
          selected: status
        }
      }
    });
  };

  const handleSingleStatus = (vendorStatus: boolean, vendorId: number) => {
    changeProductStatus({
      variables: {
        input: {
          ids: [vendorId],
          selected: !vendorStatus
        }
      }
    });
  };

  const ChangeSortBy = (column: string, id: number) => {
    if (sortBy?.catagory === column) {
      if (sortBy.type === 'DESC') {
        setSortBy({
          ...sortBy,
          type: 'ASC'
        });
      } else {
        setSortBy({
          ...sortBy,
          type: 'DESC'
        });
      }
    } else {
      setSortBy({
        catagory: column === 'Default' ? '' : column,
        type: column === 'Default' ? '' : 'DESC',
        id: column === 'Default' ? 0 : id
      });
    }
  };

  const selectVendor = (e: any, vendor?: any) => {
    if (e === 'All') {
      const tempArr: number[] = vendorsProduct?.map((vendor: any) => vendor.id);
      setSelectedVendorProducts(tempArr);
    } else if (e.target.checked) {
      const arr = selectedVendorProducts.concat(vendor.id);
      setSelectedVendorProducts(arr);
    } else {
      const arr = selectedVendorProducts;

      arr.forEach((item, index) => {
        if (item === vendor.id) arr.splice(index, 1);
      });

      setSelectedVendorProducts([...arr]);
    }
  };
  const handleDeselectAll = () => {
    setSelectedVendorProducts([]);
  };

  tableData.sortByType = sortBy?.type;
  tableData.sortByCategory = sortBy?.catagory;

  tableData.headers = [
    {
      id: 1,
      className: 'w-45px align-middle border-bottom-0',
      colName: (
        <div className="form-check form-check-sm form-check-custom">
          <input
            className={clsx('me-5 form-check-input widget-9-check', {
              'bg-primary': vendorsProduct.length === selectedVendorProducts.length
            })}
            type="checkbox"
            onChange={() => {
              if (vendorsProduct.length === selectedVendorProducts.length) {
                setSelectedVendorProducts([]);
              } else {
                selectVendor('All');
              }
            }}
            checked={vendorsProduct.length === selectedVendorProducts.length}
          />
        </div>
      )
    },
    {
      id: 2,
      className: 'align-middle p-0 border-bottom-0 min-w-370px',
      colName: constVariables.VendorDetails.allProduct.tableHeader.product,
      sorting: true,
      applySort: () => {
        ChangeSortBy(constVariables.sortCategories.name, 1);
      },
      category: constVariables.sortCategories.name
    },
    {
      id: 3,
      className: 'align-middle p-0 border-bottom-0 min-w-150px',
      colName: constVariables.VendorDetails.allProduct.tableHeader.availability,
      sorting: true,
      applySort: () => {
        ChangeSortBy(constVariables.sortCategories.availability, 2);
      },
      category: constVariables.sortCategories.availability
    },
    {
      id: 4,
      className: 'align-middle p-0 border-bottom-0 min-w-130px',
      colName: constVariables.VendorDetails.allProduct.tableHeader.type,
      sorting: true,
      applySort: () => {
        ChangeSortBy(constVariables.sortCategories.type, 3);
      },
      category: constVariables.sortCategories.type
    },
    {
      id: 5,
      className: 'align-middle p-0 border-bottom-0 min-w-150px',
      colName: constVariables.VendorDetails.allProduct.tableHeader.brand,
      sorting: true,
      applySort: () => {
        ChangeSortBy(constVariables.sortCategories.brand, 4);
      },
      category: constVariables.sortCategories.brand
    },
    {
      id: 6,
      className: 'align-middle p-0 border-bottom-0 min-w-100px',
      colName: constVariables.VendorDetails.allProduct.tableHeader.price,
      sorting: true,
      applySort: () => {
        ChangeSortBy(constVariables.sortCategories.price, 5);
      },
      category: constVariables.sortCategories.price
    },
    {
      id: 7,
      className: 'align-middle p-0 border-bottom-0',
      colName: constVariables.VendorDetails.allProduct.tableHeader.status,
      sorting: true,
      applySort: () => {
        ChangeSortBy(constVariables.sortCategories.status, 6);
      },
      category: constVariables.sortCategories.status
    },
    {
      id: 9,
      className: 'border-bottom-0',
      colName: ''
    }
  ];

  const arr: Array<RowObjectProps> = [];

  vendorsProduct?.map((vendor: any) => {
    arr.push({
      id: vendor.id,
      className: 'position-relative',
      showVendorDetails,
      vendorDetails: { details: vendor },
      cells: [
        {
          className: 'align-middle table-top-border',
          value: (
            <div className="form-check form-check-sm form-check-custom">
              <input
                className="form-check-input widget-9-check"
                type="checkbox"
                checked={selectedVendorProducts.includes(vendor.id)}
                onClick={(e) => {
                  selectVendor(e, vendor);
                }}
              />
            </div>
          )
        },
        {
          className: 'align-middle ps-0 table-top-border',
          value: (
            <FlexBox className="align-items-center">
              <div className="symbol me-7">
                <Img
                  className="object-fit-scale-down border border-light border-2"
                  src={setImageSrc(vendor.medias[0])}
                  placeholderImg={DefaultImage}
                  errorImg={DefaultImage}
                />
              </div>
              <FlexBox className="justify-content-start flex-column cursor-pointer">
                <span className="poppins-semibold d-block">{vendor.name}</span>
              </FlexBox>
            </FlexBox>
          )
        },
        {
          className: 'align-middle ps-0 table-top-border text-muted fw-bold text-muted',
          value: (
            <FlexBox className="justify-content-start flex-column cursor-pointer">
              <span className="poppins-semibold d-block">
                {vendor.quantity}
                {constVariables.VendorDetails.details.stock}
              </span>
            </FlexBox>
          )
        },
        {
          className: 'align-middle ps-0 table-top-border text-muted fw-bold text-muted',
          value: (
            <FlexBox className="justify-content-start flex-column cursor-pointer">
              <span className="poppins-semibold d-block">{vendor.categoryName}</span>
            </FlexBox>
          )
        },
        {
          className: 'align-middle ps-0 table-top-border text-muted fw-bold text-muted',
          value: (
            <FlexBox className="justify-content-start flex-column cursor-pointer">
              <span className="poppins-semibold d-block">{vendor.brand}</span>
            </FlexBox>
          )
        },
        {
          className: 'align-middle ps-0 table-top-border text-muted fw-bold text-muted',
          value: (
            <FlexBox className="justify-content-start flex-column cursor-pointer">
              <span className="poppins-semibold d-block">$ {vendor.cost}</span>
            </FlexBox>
          )
        },
        {
          className: 'align-middle ps-0 table-top-border text-muted fw-bold text-muted',
          value: (
            <div className="btn-group cursor-pointer">
              <button
                type="button"
                className={clsx('btn dropdown-toggle px-5 d-flex flex-center poppins-regular', {
                  drpBtn: vendor.isSelected === true,
                  drpBtnColor: vendor.isSelected === false
                })}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {vendor.isSelected === true ? constVariables.AllProductsMenu.selected : constVariables.AllProductsMenu.unselected}
              </button>
              <div className="dropdown-menu">
                <div className="dropdown-item" onClick={(e) => handleSingleStatus(vendor.isSelected, vendor.id)}>
                  {vendor.isSelected === true ? constVariables.AllProductsMenu.unselected : constVariables.AllProductsMenu.selected}
                </div>
              </div>
            </div>
          )
        }
      ]
    });
    return null;
  });

  tableData.rows = arr;
  return (
    <VendorAllProductsPresentational
      tableData={tableData}
      skipCount={skipCount}
      onlyUnSelected={onlyUnSelected}
      selectedTab={selectedTab}
      selectedVendorProducts={selectedVendorProducts}
      limitCount={limitCount}
      totalVendors={totalVendors}
      setSkipCount={setSkipCount}
      handleDeselectAll={handleDeselectAll}
      handleChangeProductStatus={handleChangeProductStatus}
      allSortCategories={AllSortCategories}
      ChangeSortBy={ChangeSortBy}
      sortBy={sortBy}
      openSortDropdown={openSortDropdown}
      setOpenSortDropdown={setOpenSortDropdown}
    />
  );
};

export default VendorAllProducts;
