import { gql } from '@apollo/client';

export const EDIT_ORDER_ITEM = gql`
  mutation EditOrderItems($input: EditOrderItemsInput!) @api(name: "ordersAPI") {
    editOrderItems(input: $input) {
      id
      orderCode
      customerId
      orderStatus
      customer
      customerEmail
      customerPhoneNo
      isEmailVerified
      isPhoneNoVerified
      thumbnailUrl
      orderDetails {
        id
        availableQuantity
        name
        sku
        quantity
        price {
          price
          quantity
        }
        orderItemStatus
        productId
        variantId
        imageUrl
        location
        dimensions
        items
        variantTypes {
          id
          name
          value
        }
      }
      total
      totalWeight
      items
      discountAmount
      shippingAmount
      taxAmount
      subTotal
      createdAt
      updatedAt
      tags
    }
  }
`;
