import { gql } from '@apollo/client';

export const GET_FB_SSE_TOKEN = gql`
  query GET_SSE_TOKENS @api(name: "liveStreamingAPI") {
    getSSETokens {
      tokens {
        liveShowId
        pageId
        accessToken
      }
    }
  }
`;
