import { gql } from '@apollo/client';

export const CHANGE_PRODUCT_TIKTOK_CATEGORY = gql`
  mutation ChangeProductCategory($input: ChangeProductCategoryInput!) @api(name: "tiktokAPI") {
    changeProductCategory(input: $input) {
      id
      name
      imageUrl
      inventory
      category {
        id
        l1
        l2
        l3
      }
      status
      error
    }
  }
`;
