import { FC } from 'react';
import clsx from 'clsx';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { CloseIcon, RedWarningIcon, PrinterIcon } from 'src/assets/icons';

// Styles
import './modalStyles.scss';

interface ErrorModalProps {
  message: string;
  errors: string[];
  actionTitle: string;
  actionHandler: (...args: any) => void;
  cancelHandler?: (...args: any) => void;
  correctHandler?: (...args: any) => void;
  type?: string;
  title?: string;
  cancelTitle?: string;
  otherIcon?: any;
}
const ErrorModal: FC<ErrorModalProps> = ({
  message,
  errors,
  actionHandler,
  actionTitle,
  type,
  title,
  cancelTitle,
  cancelHandler,
  correctHandler,
  otherIcon
}) => {
  const handleModalAction = () => {
    document.body.style.overflow = 'unset';
    actionHandler();
  };

  const handleCorrectAction = () => {
    document.body.style.overflow = 'unset';
    correctHandler && correctHandler();
  };

  return (
    <div className="modal-content default-modal">
      <div className="modal-header p-x-30 p-t-20 pb-0 border-none justify-content-end">
        <div className="btn btn-xs btn-active-light-primary p-0 m-0" data-bs-dismiss="modal" aria-label="Close">
          <KTSVG path={CloseIcon} className="svg-icon svg-icon-2x m-0" onClick={handleModalAction} />
        </div>
      </div>
      <div className={clsx('modal-body p-x-30 p-t-24 p-b-36')}>
        <div className="d-flex flex-column align-items-center justify-content-center m-b-24">
          <img src={RedWarningIcon} className="h-40px m-b-8" />
          <h2 className={`error-modal-title text-danger poppins-medium mb-0`}>{title}</h2>
        </div>
        {message !== '' && <div>{message}</div>}
        <div className="error-content">
          <ul>{errors?.length > 0 && errors?.map((item: string) => item.length > 0 && <li key={item}>{item}</li>)}</ul>
        </div>
      </div>
      <div className="modal-footer">
        {type === 'errorHandling' ? (
          <>
            <button onClick={handleModalAction} className="btn btn-secondary btn-md printer-btn">
              <KTSVG path={PrinterIcon} className="me-2" />
              {actionTitle}
            </button>
            <button className="btn btn-danger btn-md" onClick={handleCorrectAction}>
              {cancelTitle}
            </button>
          </>
        ) : (
          <button className="btn btn-primary btn-md" onClick={handleModalAction}>
            {actionTitle}
          </button>
        )}
      </div>
    </div>
  );
};

export default ErrorModal;
