import { DefaultTheme } from 'styled-components';

const dark: DefaultTheme = {
  name: 'dark',
  colors: {
    body: '#0f0f0f',
    text: '#f7f7f7',
    link: '#1877f2',
    button: {
      text: 'white',
      backgroundColor: 'black',
      borderRadius: '10px',
      active: {
        text: 'black',
        backgroundColor: 'white'
      }
    }
  }
};

export default dark;
