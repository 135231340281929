import { gql } from '@apollo/client';

export const GET_LIVE_STREAMS = gql`
  query GetLiveStreams($input: GetLiveStreamsInput!) @api(name: "liveStreamingAPI") {
    getLiveStreams(input: $input) {
      id
      title
      startingAt
      endingAt
      startedAt
      endedAt
      thumbnailUrl
      isComplete
      isLive
      products {
        productId
        productReferencedId
        productName
        productPrice
        productImage
      }
    }
  }
`;
