import { FC } from 'react';
// import { FC, useEffect, useState } from 'react';
// import OutsideClickHandler from 'react-outside-click-handler';
// import clsx from 'clsx';

// Components
import { InputBox, Checkbox } from 'src/components/molecules';
// import { FlexBox } from 'src/components/atoms';
// import { RadioSelector, InputBox, Divider } from 'src/components/molecules';

// Hooks && Utils && Helpers
// import { KTSVG } from 'src/helpers';

// Icons
// import { DownArrowIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants/constVariables';

// Types
import { CountriesCCTypeSection } from 'src/components/pages/coupons/CreateCoupon/CreateCoupon.types';

const CountriesCreateCoupon: FC<CountriesCCTypeSection> = ({
  countries,
  setCountries,
  countryList,
  errorMessages,
  validateInput
}) => {
  // const [showCountryDropdown, setShowCountryDropdown] = useState(false);
  // const [searchCountryText, setSearchCountryText] = useState('');

  // const selectAllCountries = () => {
  //   setCountries((prev) => ({ ...prev, isAllCountries: true, isSelectedCountries: false }));
  // };

  // const selectSelectedCountries = () => {
  //   setCountries((prev) => ({ ...prev, isAllCountries: false, isSelectedCountries: true }));
  // };

  const changeValue = (shippingRateValue: any) => {
    if (shippingRateValue === '') {
      setCountries((prev) => ({ ...prev, shippingRateValue: '' }));
    } else if (!isNaN(shippingRateValue)) {
      setCountries((prev) => ({ ...prev, shippingRateValue }));
    }
  };

  const handleKeyInput = (event, fieldName) => {
    if (event.keyCode === 13) {
      validateInput(event.target.value, fieldName);
    }
  };

  const handleBlurInput = (event, fieldName) => {
    validateInput(event.target.value, fieldName);
  };

  // const handleChangeSearchCountry = (value) => {
  //   setSearchCountryText(value);
  // };

  // const handleToggleCountry = () => {
  //   console.log('call handleToggleCountry::');
  // };

  // const CountryItem = ({ country, onChange }) => {
  //   const handlChangeCheckbox = () => {
  //     onChange(country);
  //   };

  //   return (
  //     <>
  //       <div className="d-flex align-items-center mt-2">
  //         <div className="form-check form-check-sm form-check-custom me-3">
  //           <input className={`form-check-input`} name="CustomerBlock" type="checkbox" onChange={handlChangeCheckbox} />
  //         </div>
  //         <div className="">{country.name}</div>
  //       </div>
  //       <Divider classname="mt-2" />
  //     </>
  //   );
  // };

  return (
    <div>
      {/* <div className="card w-100">
        <div className="section-title m-b-12">{constVariables.Coupons.AddCoupon.countries.countries}</div>

        <RadioSelector
          isCheck={countries.isAllCountries}
          name={constVariables.Coupons.AddCoupon.countries.allCountries}
          changeCheck={selectAllCountries}
        />

        <RadioSelector
          isCheck={countries.isSelectedCountries}
          name={constVariables.Coupons.AddCoupon.countries.selectedCountries}
          changeCheck={selectSelectedCountries}
        />
        {countries.isSelectedCountries ? (
          <OutsideClickHandler onOutsideClick={() => setShowCountryDropdown(false)}>
            <div className="col-6 mt-6 position-relative">
              <FlexBox
                className={`form-control form-control-lg  px-5 justify-content-between align-items-center cursor-pointer dropdown-box `}
                onClick={() => setShowCountryDropdown(true)}
              >
                <InputBox
                  value={searchCountryText}
                  onChangeText={handleChangeSearchCountry}
                  name={constVariables.Coupons.AddCoupon.customerEligibility.searchForCustmoers}
                  placeholder={constVariables.Coupons.AddCoupon.customerEligibility.searchForCustmoers}
                  noBorders
                />
                <KTSVG path={DownArrowIcon} />
              </FlexBox>

              <div
                className={clsx(`p-5 dropdown-menu z-index-dropdown w-100 mt-2 overflow-scroll mh-300px`, {
                  show: showCountryDropdown
                })}
                aria-labelledby="dropdownMenuButton"
              >
                {countryList?.map((country) => (
                  <CountryItem key={country.id} country={country} onChange={handleToggleCountry} />
                ))}
                {countryList?.length > 0 && (
                  <FlexBox className="justify-content-end mt-3">
                    <button
                      onClick={() => setShowCountryDropdown(false)}
                      type="button"
                      className="btn btn-outlined-secondary me-3 btn-sm"
                    >
                      Cancel
                    </button>
                    <button onClick={() => setShowCountryDropdown(false)} type="button" className="btn btn-primary btn-sm">
                      Add
                    </button>
                  </FlexBox>
                )}
              </div>
            </div>
          </OutsideClickHandler>
        ) : null}
      </div> */}
      <div className="card w-100">
        <div className="section-title m-b-12">{constVariables.Coupons.AddCoupon.countries.shippingRates}</div>
        <Checkbox
          labelclassname="py-3"
          value={countries.isShippingRates}
          name={constVariables.Coupons.AddCoupon.countries.shippingRatesDesc}
          isReadOnly={true}
        />
        {countries.isShippingRates && (
          <div className="col-4 mt-3">
            <div className="d-flex align-items-center justify-content-start">
              <div className="d-flex align-items-center justify-content-between valueInput">
                <InputBox
                  dollarSign
                  inputClass="d-flex align-items-center form-control-lg"
                  value={countries.shippingRateValue}
                  onChangeText={changeValue}
                  name="CouponValue"
                  charLimit={10}
                  onKeyDown={(event) => handleKeyInput(event, 'freeShippingAmountThresholdError')}
                  onBlur={(event) => handleBlurInput(event, 'freeShippingAmountThresholdError')}
                  warningText={errorMessages?.freeShippingAmountThresholdError}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CountriesCreateCoupon;
