import React, { createRef, useState } from 'react';
import { CellProps } from 'react-datasheet-grid';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useMutation } from '@apollo/client';
import { CREATE_COLLECTION } from 'src/apollo/mutations';

// Components
import { TagAndCollectionDropdown, Portal } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { DownArrowIcon } from 'src/assets/icons';

const CollectionCell = React.memo(({ rowData, setRowData, active, focus, columnData }: CellProps) => {
  const collectionCellRef = createRef<any>();

  const [showMoreDropdown, setShowMoreDropdown] = useState<boolean>(false);
  const [showAllDropdown, setShowAllDropdown] = useState<boolean>(false);

  const [moreDropDownPositions, setMoreDropDownPositions] = useState({
    top: 0,
    left: 0
  });
  const [allDropDownPositions, setAllDropDownPositions] = useState({
    top: 0,
    left: 0
  });

  const [addToCollection] = useMutation(CREATE_COLLECTION, {
    onCompleted: (res) => {
      if (res?.createCollection) {
        const newCollection = JSON.parse(res?.createCollection?.additionalData);
        const obj = {
          id: parseInt(newCollection?.id, 10),
          name: newCollection?.name,
          type: newCollection?.type
        };
        columnData.updateCollectionsList(obj);
      }
    },
    onError: () => {
      console.error('');
    }
  });

  const handleOpenMoreDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowMoreDropdown(true);

    if (collectionCellRef.current) {
      const position = collectionCellRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - position.bottom;
      setMoreDropDownPositions({
        top: spaceBelow < 180 ? window.scrollY + position.top - 160 : window.scrollY + position.top + 71,
        left: position.left + 180
      });
    }
  };

  const closeMoreDropdown = () => {
    setShowMoreDropdown(false);
  };

  const handleOpenAllDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (focus) {
      setShowAllDropdown(true);

      if (collectionCellRef.current) {
        const position = collectionCellRef.current.getBoundingClientRect();
        const spaceBelow = window.innerHeight - position.bottom;
        setAllDropDownPositions({
          top: spaceBelow < 400 ? window.scrollY + position.top - 363 : window.scrollY + position.top + position.height,
          left: position.left + 180
        });
      }
    }
  };

  const closeAllDropdown = () => {
    setShowAllDropdown(false);
  };

  const handleAppliedCollections = (item: any, type: string, action: string) => {
    const arr: any = [...rowData?.collectionIds];
    if (action === 'remove' || arr.indexOf(item) !== -1) {
      const i = arr.indexOf(item.id);
      arr.splice(i, 1);
    } else if (arr.indexOf(item) === -1 && action !== 'remove') {
      arr.push(item.id);
    }

    const updatedRowData = { ...rowData, collectionIds: arr };
    setRowData(updatedRowData);
  };

  return (
    <>
      <div
        className="position-relative d-flex align-items-center w-100 h-100 px-2"
        onClick={handleOpenAllDropdown}
        ref={collectionCellRef}
      >
        <div className="d-flex align-items-center w-100">
          {rowData?.collectionIds?.length > 0 ? (
            <div className="d-flex flex-wrap w-100 position-relative align-items-center">
              {columnData?.collectionsList
                ?.filter((collection) => rowData?.collectionIds?.slice(0, 1)?.includes(collection?.id))
                .map((item, index) => {
                  return (
                    <div className={`text-nowrap tag-item none-color`} key={index}>
                      {item.name && item.name}
                    </div>
                  );
                })}
              {rowData?.collectionIds?.length > 1 && (
                <div className="d-flex align-items-center rounded tag-item none-color limited-tag" onClick={handleOpenMoreDropdown}>
                  <div>+{rowData?.collectionIds?.length - 1}</div>
                  <KTSVG path={DownArrowIcon} />
                </div>
              )}
            </div>
          ) : (
            <span>-</span>
          )}
        </div>
      </div>

      {/* Show the rest collections on the Dropdown */}
      {showMoreDropdown && (
        <Portal id="kt_body">
          <OutsideClickHandler
            onOutsideClick={() => {
              if (showMoreDropdown) closeMoreDropdown();
            }}
          >
            <div className="position-absolute" style={{ top: moreDropDownPositions.top, left: moreDropDownPositions.left }}>
              <TagAndCollectionDropdown
                type="Collection"
                onlyAppliedTags={true}
                allItems={columnData?.collectionsList?.map((item) => {
                  return { id: item?.id, name: item?.name, disabled: item?.type === 'AUTOMATIC' ? true : false };
                })}
                appliedItems={
                  columnData?.collectionsList?.filter((collection) =>
                    rowData?.collectionIds?.slice(1, rowData?.collectionIds?.length)?.includes(collection?.id)
                  ) || []
                }
                selected={showMoreDropdown}
                isBulkEditorModal={true}
                isCreatable
                isEditAndDeletable={false}
                closeDropdown={closeMoreDropdown}
                className="bulk-editor-dropdown"
              />
            </div>
          </OutsideClickHandler>
        </Portal>
      )}

      {/* Show the all collections on the Dropdown */}
      {showAllDropdown && (
        <Portal id="kt_body">
          <OutsideClickHandler
            onOutsideClick={() => {
              if (showAllDropdown) closeAllDropdown();
            }}
          >
            <div className="position-absolute" style={{ top: allDropDownPositions.top, left: allDropDownPositions.left }}>
              <TagAndCollectionDropdown
                type="Collection"
                allItems={columnData?.collectionsList?.map((item) => {
                  return { id: item?.id, name: item?.name, disabled: item?.type === 'AUTOMATIC' ? true : false };
                })}
                appliedItems={columnData?.collectionsList?.filter((collection) =>
                  rowData?.collectionIds?.includes(collection?.id?.toString())
                )}
                changeAppliedItems={handleAppliedCollections}
                selected={showAllDropdown}
                addToCollection={addToCollection}
                isBulkEditorModal={true}
                isCreatable
                isEditAndDeletable={false}
                closeDropdown={closeAllDropdown}
                className="bulk-editor-dropdown"
              />
            </div>
          </OutsideClickHandler>
        </Portal>
      )}
    </>
  );
});

export default CollectionCell;
