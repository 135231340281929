import { FC, useEffect, useState } from 'react';

// Components
import { FlexBox } from 'src/components/atoms';
import { InputBox } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { checkInvalidCharacter } from 'src/utils/validate';

// Icons
import { InfoItalicIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { PricingSection } from 'src/components/pages/products/AddProduct/AddProduct.types';

// Styles
import './_addProductPricing.scss';

const AddProductPricing: FC<PricingSection> = ({
  pricing,
  setPricing,
  errorMessages,
  setErrorMessages,
  shipping,
  retailPriceToggle,
  setRetailPriceToggle,
  isDisableInput
}) => {
  const [lastMarginValue, setLastMarginValue] = useState('');

  // useEffect(() => {
  //   if (shipping.shippingCost) {
  //     if (shipping.freeShippingCheck) {
  //       saleHandler((parseFloat(pricing.salePrice) + parseFloat(shipping.shippingCost)).toString());
  //     } else {
  //       saleHandler((parseFloat(pricing.salePrice) - parseFloat(shipping.shippingCost)).toString());
  //     }
  //   }
  // }, [shipping.freeShippingCheck]);
  useEffect(() => {
    setLastMarginValue(pricing.margin);
  }, [pricing.margin]);

  // handle changes in profit , sale price and change value in margin field
  const marginHandler = (unFilteredValue: any) => {
    let value = unFilteredValue;
    if (unFilteredValue.length > 1) {
      if (unFilteredValue[unFilteredValue.length - 2] === '%')
        value = unFilteredValue.slice(0, unFilteredValue.length - 2) + unFilteredValue.slice(unFilteredValue.length - 1);
      else {
        value = unFilteredValue.slice(0, unFilteredValue.length - 1) + unFilteredValue.slice(unFilteredValue.length);
      }
    } else if (unFilteredValue.length === 1) {
      if (lastMarginValue !== '') {
        value = '';
      }
    }
    if (value === '') {
      setPricing((prev) => ({
        ...prev,
        salePrice: pricing.costPerItem,
        margin: '',
        profit: ''
      }));
    } else if (!isNaN(value)) {
      setPricing((prev) => ({ ...prev, margin: value }));
      if (value !== '') {
        if (pricing.costPerItem) {
          let totalProfit = (parseFloat(value) * parseFloat(pricing.costPerItem)) / 100;
          if (totalProfit === Infinity || isNaN(totalProfit)) {
            totalProfit = 0;
          } else if (totalProfit % 1 !== 0) {
            totalProfit = Math.round(totalProfit * 100) / 100;
          }
          setPricing((prev) => ({
            ...prev,
            profit: value.length === 0 ? value : totalProfit.toString()
          }));
          salePriceHandler(value.length === 0 ? value : parseFloat(totalProfit.toString()).toString());
        }
      }
    }
  };
  // handle changes in margin , sale price and change value in profit field
  const profitHandler = (value: any) => {
    if (value === '') {
      setPricing((prev) => ({
        ...prev,
        margin: '',
        salePrice: pricing.costPerItem,
        profit: ''
      }));
    } else if (!isNaN(value)) {
      if (pricing.salePrice !== '' && pricing.costPerItem !== '') {
        setPricing((prev) => ({ ...prev, profit: value }));
        if (value !== '') {
          let totalMargin = (parseFloat(value) * 100) / parseFloat(pricing.costPerItem);
          if (totalMargin === Infinity || isNaN(totalMargin)) {
            totalMargin = 0;
          } else if (totalMargin % 1 !== 0) {
            totalMargin = Math.round(totalMargin * 100) / 100;
          }

          setPricing((prev) => ({
            ...prev,
            margin: !pricing.costPerItem ? '' : totalMargin.toString(),
            salePrice: value
          }));
          salePriceHandler(parseFloat(value).toString());
        }
      } else {
        setErrorMessages((prev) => ({
          ...prev,
          salePrice: pricing.salePrice === '' ? constVariables.common.errors.errSalePrice : '',
          costPerItem: pricing.costPerItem === '' ? constVariables.common.errors.errCostPerItem : ''
        }));
      }
    }
  };

  const taxCodeHandler = (value: any) => {
    setPricing((prev) => ({ ...prev, taxCode: value }));
  };

  const salePriceHandler = (value: any) => {
    if (!isNaN(value)) {
      let totalSalePrice = parseFloat(value) + parseFloat(pricing.costPerItem);
      if (totalSalePrice === Infinity || isNaN(totalSalePrice)) {
        totalSalePrice = 0;
      } else if (totalSalePrice % 1 !== 0) {
        totalSalePrice = Math.round(totalSalePrice * 100) / 100;
      }
      if (!pricing.costPerItem) {
        setPricing((prev) => ({
          ...prev,
          salePrice: ''
        }));
      } else {
        setPricing((prev) => ({
          ...prev,
          salePrice: value.length === 0 ? value : totalSalePrice.toString()
        }));
      }
    }
  };
  const retailPriceHandler = (value: any) => {
    if (!isNaN(value)) {
      setPricing((prev) => ({ ...prev, retailPrice: value }));
    }
  };

  const saleHandler = (value: any) => {
    if (value === '') {
      setPricing((prev) => ({
        ...prev,
        salePrice: '',
        margin: '',
        profit: ''
      }));
    } else if (!isNaN(value)) {
      setPricing((prev) => ({
        ...prev,
        salePrice: value
      }));
      if (value !== '') {
        let profit = !pricing.costPerItem ? 0 : parseFloat(value) - parseFloat(pricing.costPerItem);
        if (profit === Infinity || isNaN(profit)) {
          profit = 0;
        } else if (profit % 1 !== 0) {
          profit = Math.round(profit * 100) / 100;
        }
        let margin = !pricing.costPerItem ? 0 : (profit * 100) / parseFloat(pricing.costPerItem);
        if (margin === Infinity || isNaN(margin)) {
          margin = 0;
        } else if (margin % 1 !== 0) {
          margin = Math.round(margin * 100) / 100;
        }

        setPricing((prev) => ({
          ...prev,
          profit: profit <= 0 || isNaN(profit) ? '' : profit.toString(),
          margin: margin <= 0 || isNaN(margin) || value === '' ? '' : margin.toString()
        }));
      }
    }

    setErrorMessages((prev) => ({
      ...prev,
      salePrice: ''
    }));
  };

  const costPerItemHandler = (value: any) => {
    if (value === '') {
      setPricing((prev) => ({
        ...prev,
        costPerItem: '',
        margin: '',
        profit: ''
      }));
    } else if (!isNaN(value)) {
      setPricing((prev) => ({
        ...prev,
        costPerItem: value
      }));
      if (value !== '') {
        let profit = !pricing.salePrice ? 0 : parseFloat(pricing.salePrice) - parseFloat(value);
        let margin = !pricing.salePrice ? 0 : (profit * 100) / parseFloat(value);
        if (profit === Infinity || isNaN(profit)) {
          profit = 0;
        } else if (profit % 1 !== 0) {
          profit = Math.round(profit * 100) / 100;
        }
        if (margin === Infinity || isNaN(margin)) {
          margin = 0;
        } else if (margin % 1 !== 0) {
          margin = Math.round(margin * 100) / 100;
        }
        setPricing((prev) => ({
          ...prev,

          profit: profit <= 0 || isNaN(profit) ? '' : profit.toString(),
          margin: margin <= 0 || isNaN(margin) || value === '' ? '' : margin.toString()
        }));
      }
    }

    setErrorMessages((prev) => ({
      ...prev,
      costPerItem: ''
    }));
  };

  // updating error message for retail price
  useEffect(() => {
    if (
      retailPriceToggle &&
      !!pricing.salePrice &&
      !!pricing.retailPrice &&
      parseFloat(pricing.salePrice) > parseFloat(pricing.retailPrice)
    ) {
      setErrorMessages((prev) => ({
        ...prev,
        retailPrice: constVariables.common.errors.errRetailPrice
      }));
    } else {
      setErrorMessages((prev) => ({
        ...prev,
        retailPrice: ''
      }));
    }
  }, [retailPriceToggle, pricing.salePrice, pricing.margin, pricing.profit, pricing.retailPrice, setErrorMessages]);
  useEffect(() => {
    if (pricing.salePrice && pricing.costPerItem) {
      let profit = parseFloat(pricing.salePrice) - parseFloat(pricing.costPerItem);
      if (profit === Infinity || isNaN(profit)) {
        profit = 0;
      } else if (profit % 1 !== 0) {
        profit = Math.round(profit * 100) / 100;
      }
      let margin = (profit * 100) / parseFloat(pricing.costPerItem);
      if (margin === Infinity || isNaN(margin)) {
        margin = 0;
      } else if (margin % 1 !== 0) {
        margin = Math.round(margin * 100) / 100;
      }
      setPricing((prev) => ({
        ...prev,
        profit: profit <= 0 || isNaN(profit) ? '' : profit.toString(),
        margin: margin <= 0 || isNaN(margin) || pricing.costPerItem === '0' || pricing.salePrice === '0' ? '' : margin.toString()
      }));
    }
  }, [pricing.salePrice, pricing.costPerItem]);

  return (
    <div className="card w-100">
      <h4 className="section-title m-0">{constVariables.common.pricing.key}</h4>
      <div className="row m-t-32">
        <div className={`col-12 position-relative`}>
          <div className="row gx-3">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <InputBox
                onKeyDown={checkInvalidCharacter}
                dollarSign={pricing.salePrice.toString().length > 0}
                inputClass="form-control-lg"
                mainclass="m-b-24 sale-price-title"
                title={constVariables.common.pricing.salePrice}
                // imageRight={
                //   <div className="ms-1 d-flex justify-content-between align-item-center">
                //     <div className="text-danger">*</div>
                //     <KTSVG className="ms-1" path={InfoItalicIcon} />
                //   </div>
                // }
                value={pricing.salePrice}
                onChangeText={(e) => saleHandler(e)}
                name={constVariables.common.pricing.salePrice.trim()}
                placeholder={constVariables.common.pricing.pricePlaceholder}
                warningText={errorMessages?.salePrice}
                disable={isDisableInput}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <InputBox
                onKeyDown={checkInvalidCharacter}
                dollarSign={pricing.retailPrice.toString().length > 0}
                disable={isDisableInput || !pricing.showRetailPrice}
                inputClass={`form-control-lg ${!retailPriceToggle ? 'disabled-input' : ''}`}
                mainclass="m-b-24 sale-price-title"
                title={constVariables.common.pricing.retailPrice}
                imageRight={
                  <div className="ms-1 d-flex justify-content-between align-item-center">
                    {/* <KTSVG path={InfoItalicIcon} /> */}
                    <label className="form-check form-check-custom form-check-solid form-switch form-switch-sm toggle-absolute">
                      <input
                        style={{ outline: 'none' }}
                        className="form-check-input"
                        type="checkbox"
                        name="layout-builder[layout][header][fixed][desktop]"
                        checked={pricing.showRetailPrice}
                        onChange={() => {
                          setPricing((prev) => ({
                            ...prev,
                            showRetailPrice: !prev.showRetailPrice
                          }));
                          setRetailPriceToggle(!pricing.showRetailPrice);
                        }}
                      />
                    </label>
                  </div>
                }
                value={pricing.retailPrice}
                onChangeText={(e) => retailPriceHandler(e)}
                name={constVariables.common.pricing.retailPrice.trim()}
                placeholder={constVariables.common.pricing.pricePlaceholder}
                warningText={!retailPriceToggle ? '' : errorMessages?.retailPrice}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <InputBox
                onKeyDown={checkInvalidCharacter}
                inputClass="form-control-lg"
                mainclass="m-b-24"
                title={constVariables.common.pricing.costPerItem}
                value={pricing.costPerItem}
                onChangeText={costPerItemHandler}
                name={constVariables.common.pricing.costPerItem.trim()}
                placeholder={constVariables.common.pricing.pricePlaceholder}
                dollarSign={pricing.costPerItem?.toString().length > 0}
                warningText={errorMessages?.costPerItem}
                disable={isDisableInput}
              />
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row gx-3">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <InputBox
                onKeyDown={checkInvalidCharacter}
                inputClass="form-control-lg"
                mainclass="m-b-24"
                title={constVariables.common.pricing.margin}
                value={pricing.margin ? `${pricing.margin}%` : pricing.margin}
                onChangeText={(e) => marginHandler(e)}
                name={constVariables.common.pricing.margin.trim()}
                placeholder={constVariables.common.pricing.marginPlaceholder}
                disable={isDisableInput}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <InputBox
                dollarSign={pricing.profit.toString().length > 0}
                inputClass="form-control-lg"
                mainclass="m-b-24"
                title={constVariables.common.pricing.profit}
                value={pricing.profit}
                onChangeText={(e) => profitHandler(e)}
                name={constVariables.common.pricing.profit.trim()}
                placeholder={constVariables.common.pricing.pricePlaceholder}
                disable={isDisableInput}
              />
            </div>
            {/* <div className="col-lg-4 col-md-4 col-sm-12">
              <InputBox
                inputClass="form-control-lg"
                mainclass="m-b-24"
                title={constVariables.common.pricing.taxCode}
                value={pricing.taxCode}
                onChangeText={taxCodeHandler}
                name={constVariables.common.pricing.profit.trim()}
                placeholder={constVariables.common.pricing.taxCode}
              />
            </div> */}
          </div>
        </div>
        <div className="col-12">
          <div className="row gx-3">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <FlexBox className="align-items-center m-b-8">
                <div className="text-nowrap input-title mb-0">{constVariables.common.pricing.taxCode}</div>

                <a
                  href="https://taxcode.avatax.avalara.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary ms-3 cursor-pointer text-btn"
                >
                  {constVariables.taxes.searchTaxCode}
                </a>
              </FlexBox>
              <InputBox
                inputClass="form-control-lg"
                value={pricing.taxCode}
                onChangeText={taxCodeHandler}
                name={constVariables.common.pricing.profit.trim()}
                placeholder={constVariables.common.pricing.taxCode}
                disable={isDisableInput}
              />
            </div>
          </div>
        </div>

        {/* <div className="mt-8">
            <div className="poppins-regular input-title">{constVariables.common.pricing.finalScale}</div>
            <Checkbox
              tagclassname="poppins-medium ps-4 final-scale-checkbox"
              labelclassname="pb-3 "
              name={constVariables.common.pricing.finalScaleText}
              defaultValue={pricing.finalSale}
              onChangeValue={() =>
                setPricing((prev) => ({
                  ...prev,
                  finalSale: !prev.finalSale
                }))
              }
            />
          </div>
          <FlexBox className="mt-6 cursor-pointer">
            <div>
              <KTSVG path={RightBlueArrow} className="svg-icon-8 text-primary" />
            </div>
            <div className="helper-text-one fs-6 ms-2 text-primary">{'Show advanced pricing '}</div>
          </FlexBox> */}
      </div>
    </div>
  );
};

export default AddProductPricing;
