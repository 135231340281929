import { useState, createRef, FC } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { FlexBox } from 'src/components/atoms';
import { Dropdown, Portal } from 'src/components/molecules';

// Icons
import { HorizontalThreeDotIcon } from 'src/assets/icons';

// Types
import { UserRoles } from '../../settings/UserPermissions/UserPermissions.type';

interface OrderDetailActionCellProps {
  deleteProductHandler: () => void;
}

const OrderDetailActionCell: FC<OrderDetailActionCellProps> = ({ deleteProductHandler }) => {
  const { role } = useAppSelector((state) => state.userInfo.userInfo);
  const containerRef = createRef<any>();
  const [showActionMenu, setShowActionMenu] = useState(false);
  const [dropdownPositions, setDropdownPositions] = useState({
    top: 0,
    left: 0
  });

  const handleSelectOption = (option) => {
    if (option.name === 'Delete') {
      deleteProductHandler();
    }
  };

  const menuData = [
    { id: 1, name: 'Delete', disabled: role === UserRoles.ADMIN_ASSOCIATE || role === UserRoles.WAREHOUSE_ASSOCIATE ? true : false }
  ];

  const handleOpenDropdown = (e) => {
    e.stopPropagation();
    setShowActionMenu(true);

    if (containerRef.current) {
      const position = containerRef.current.getBoundingClientRect();
      setDropdownPositions({
        top: window.scrollY + position.top + 15,
        left: position.left + 140
      });
    }
  };

  const closeDropdown = () => {
    setShowActionMenu(false);
  };

  return (
    <div>
      <FlexBox className="align-items-center">
        <img
          src={HorizontalThreeDotIcon}
          className="px-2 h-30px w-30px cursor-pointer"
          alt=""
          onClick={handleOpenDropdown}
          ref={containerRef}
        />
      </FlexBox>
      {showActionMenu && (
        <Portal id="kt_body">
          <OutsideClickHandler onOutsideClick={closeDropdown}>
            <div className="position-absolute" style={{ top: dropdownPositions.top, left: dropdownPositions.left }}>
              <Dropdown
                data={menuData}
                onSelect={handleSelectOption}
                shiftLeft
                selected={showActionMenu}
                className="mt-6"
                closeDropdown={closeDropdown}
              />
            </div>
          </OutsideClickHandler>
        </Portal>
      )}
    </div>
  );
};

export default OrderDetailActionCell;
