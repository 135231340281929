import { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';

// Components
import { Img, FlexBox, Loader } from 'src/components/atoms';
import TableCell from '../components/TableCell';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import { RightArrowIcon, DefaultImage } from 'src/assets/icons';

// ConstVariables
import { ROUTES } from 'src/constants';

interface WaitListDetailsRowProps {
  waitListItem: any;
  columnOptions: any[];
  isLoading?: boolean;
  rowWidth: number;
}

const WaitListDetailsRow: FC<WaitListDetailsRowProps> = ({ waitListItem, isLoading, columnOptions, rowWidth }) => {
  const renderColumn = (variant, column) => {
    switch (column.dataKey) {
      case 'products':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <div className="d-flex align-items-center">
              <div className="opacity-0 form-check form-check-sm form-check-custom">
                <input className="me-5 form-check-input widget-9-check" type="checkbox" disabled />
              </div>
              <div className="opacity-0 h-20x w-20x p-2 d-flex align-items-center justify-content-center rounded me-4">
                <img src={RightArrowIcon} className="h-10px w-10px" />
              </div>
              <div className="align-items-center d-flex">
                <div className="symbol me-5">
                  <Img
                    src={setImageSrc(variant?.mediaUrl ? generateCompressedImageURL(variant?.mediaUrl, '50') : DefaultImage)}
                    placeholderImg={DefaultImage}
                    errorImg={DefaultImage}
                    className="object-fit-scale-down bg-white border border-light border-2"
                  />
                </div>
                <div className="text-left">
                  <Link
                    to={{
                      pathname: `/products/allProducts/edit/${waitListItem?.id}`,
                      state: { from: ROUTES.orders.allOrders.waitlists }
                    }}
                    className="main-cell"
                  >
                    {variant.variantTypes?.map((dataTwo, index) => (
                      <span key={index}>
                        {dataTwo?.variantTypeValue}
                        {index < variant.variantTypes.length - 1 && variant !== null ? '/' : null}
                      </span>
                    ))}
                  </Link>
                </div>
              </div>
            </div>
          </TableCell>
        );
      case 'commited':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <FlexBox className="align-items-center">{variant.committedQuantity ? variant.comittedQuantity : '-'}</FlexBox>
          </TableCell>
        );
      case 'waitlist':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <FlexBox className="align-items-center">{variant.waitlistQuantity ? variant.waitlistQuantity : '-'}</FlexBox>
          </TableCell>
        );
      case 'waitlistAuthorized':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <FlexBox className="align-items-center">{variant.waitlistAuthQuantity ? variant.waitlistAuthQuantity : '-'}</FlexBox>
          </TableCell>
        );
      case 'type':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <FlexBox className="align-items-center">
              <div className="d-flex align-items-center justify-content-center">-</div>
            </FlexBox>
          </TableCell>
        );
      case 'vendor':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <FlexBox className="align-items-center">
              <div className="d-flex align-items-center justify-content-center">-</div>
            </FlexBox>
          </TableCell>
        );
      case 'price':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <FlexBox className="align-items-center">{variant.price ? `$${variant.price.toFixed(2)}` : '-'}</FlexBox>
          </TableCell>
        );
      case 'restockStatus':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <FlexBox className="align-items-center">
              <div>-</div>
            </FlexBox>
          </TableCell>
        );
      case 'estimatedReorderProfit':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <FlexBox className="align-items-center">
              <div className="d-flex align-items-center justify-content-center">-</div>
            </FlexBox>
          </TableCell>
        );
      default:
        return;
    }
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            height: 70,
            display: 'flex',
            alignItems: 'center',
            width: rowWidth
          }}
          className="ReactVirtualized__Table__row"
        >
          <Loader type="page" />
        </div>
      ) : (
        <>
          {waitListItem?.variantLocationDetails?.map((variant, index) => (
            <div
              style={{
                height: 70,
                display: 'flex',
                alignItems: 'center',
                width: rowWidth
              }}
              key={index}
              className="ReactVirtualized__Table__row"
            >
              {columnOptions?.map((column, index) => {
                return <Fragment key={index}>{renderColumn(variant, column)}</Fragment>;
              })}
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default WaitListDetailsRow;
