// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const leavePageHandler = (locationState: string, history, targettedPath: string): void => {
  setTimeout(() => {
    window.location.reload();
  }, 500);

  if (targettedPath) {
    if (locationState) {
      history.push({
        pathname: targettedPath,
        state: locationState
      });
    } else {
      history.push(targettedPath);
    }
  }
};
