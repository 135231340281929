import { gql } from '@apollo/client';

export const CLEAR_USER_WISHLIST = gql`
  mutation ClearUserWishlist($userId: Int!) @api(name: "productsAPI") {
    clearUserWishlist(userId: $userId) {
      success
      message
    }
  }
`;
