import { gql } from '@apollo/client';

export const DELETE_ORDER_NOTE = gql`
  mutation DeleteOrderNote($id: Int!, $orderId: Int!) @api(name: "ordersAPI") {
    deleteOrderNote(id: $id, orderId: $orderId) {
      id
      success
      message
    }
  }
`;
