import CSVboxKey from './CSVboxLicenseKey.json';

export enum CSVboxTemplate {
  PRODUCTS = 'PRODUCTS'
}

enum ENV {
  DEV = 'DEV',
  QA = 'QA',
  LOCAL = 'LOCAL'
}

interface CSVboxLicenseKeys {
  dev: string;
  qa: string;
}

export const getCSVboxLicenseKey = (template: CSVboxTemplate): string => {
  switch (template) {
    case CSVboxTemplate.PRODUCTS:
      return getEnvSpecificLicenseKey(CSVboxKey.products);
    default:
      return getEnvSpecificLicenseKey(CSVboxKey.products);
  }
};

const getEnvSpecificLicenseKey = (keys: CSVboxLicenseKeys): string => {
  /* eslint-disable dot-notation */
  switch (window['app'].env.REACT_APP_ENV) {
    case ENV.DEV:
      return keys.dev;
    case ENV.QA:
      return keys.qa;
    default:
      return keys.dev;
  }
};
