/* eslint-disable no-nested-ternary */
import { FC } from 'react';
import { Link } from 'react-router-dom';

// Components
import { Img } from 'src/components/atoms';

// Icons
import { NoProductIcon } from 'src/assets/icons';

// Types
import { NotFoundProps } from './NotFound.types';

// Styles
import './_notFound.scss';

const NotFound: FC<NotFoundProps> = ({
  type,
  isCatalog,
  categoryInfo,
  isOrder,
  searchText,
  shouldShowAddProductButton,
  handleAddButton
}) => {
  return (
    <div className="p-y-40 d-flex flex-column align-items-center">
      <Img src={NoProductIcon} placeholderImg={NoProductIcon} errorImg={NoProductIcon} className="my-4 pb-1" />
      <div className="poppins-semibold mb-1">No {type} found</div>
      {searchText && <div className="text-muted">Please try again with a different query</div>}
      {(isCatalog || isOrder) &&
        !searchText &&
        (categoryInfo?.isAuto ? (
          <div className="text-muted">Please try again with different conditions</div>
        ) : shouldShowAddProductButton ? (
          <div className="m-t-16">
            <button className="btn btn-sm btn-primary" onClick={handleAddButton}>
              Add Products
            </button>
          </div>
        ) : (
          <div className="text-muted">
            Go to{' '}
            <Link to="/products/allProducts" target="_blank">
              <span className="text-primary cursor-pointer">Product List</span>
            </Link>
            <span> to assign items to {isOrder ? 'Order' : categoryInfo?.title} </span>
          </div>
        ))}
    </div>
  );
};

export default NotFound;
