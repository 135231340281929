import { useState, useEffect } from 'react';
import { configVariables } from '../configVariables';
import useWindowDimensions from '../UseWindowDimensions/useWindowDimensions';

interface PreferedDevice {
  isMobileLandscape: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isTabletLandscape: boolean;
  isWeb: boolean;
}

function getDeviceName(height, width) {
  const devicesBreakPoints = configVariables.breakPoints;
  const deviceName: PreferedDevice = {
    isMobileLandscape: false,
    isMobile: false,
    isTablet: false,
    isTabletLandscape: false,
    isWeb: false
  };
  if (width <= devicesBreakPoints.mobile.maxWidth) {
    return { ...deviceName, isMobile: true };
  }
  if (width >= devicesBreakPoints.tabletPortrait.minWidth && width <= devicesBreakPoints.tabletPortrait.maxWidth) {
    return { ...deviceName, isTablet: true };
  }
  if (width >= devicesBreakPoints.tabletLandscape.minWidth && width <= devicesBreakPoints.tabletLandscape.maxWidth) {
    return { ...deviceName, isTabletLandscape: true };
  }
  if (width >= devicesBreakPoints.web.minWidth) {
    return { ...deviceName, isWeb: true };
  }
  if (height <= devicesBreakPoints.mobile.minHeight) {
    return { ...deviceName, isMobileLandscape: true };
  }
  return deviceName;
}

export default function useDevice(): PreferedDevice {
  const { width, height } = useWindowDimensions();
  const [device, setDevice] = useState(getDeviceName(height, width));
  useEffect(() => {
    setDevice(getDeviceName(height, width));
  }, [width]);
  return device;
}
