import { FC, useState } from 'react';

// Apollo
import { useMutation } from '@apollo/client';
import { TOGGLE_COMBINE_ORDER } from 'src/apollo/mutations';

// Components
import { Checkbox } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { CloseIcon } from 'src/assets/icons';
import { modifyCacheForAutoCombineOrders } from 'src/apollo/cacheModifiers/autoCombine';

interface ConfirmationCombinedOrderModalProps {
  isAutomaticCombiningEnabled: boolean | undefined;
  closeModal: () => void;
  onNo: () => void;
  handleOrdersCombineAndPackingSlip: () => void;
}

const ConfirmationCombinedOrderModal: FC<ConfirmationCombinedOrderModalProps> = ({
  isAutomaticCombiningEnabled,
  closeModal,
  onNo,
  handleOrdersCombineAndPackingSlip
}) => {
  const [isAutomatic, setIsAutomatic] = useState(isAutomaticCombiningEnabled);
  const [toggleEnableCombineOrder] = useMutation(TOGGLE_COMBINE_ORDER, {
    onCompleted: (res) => {
      setIsAutomatic(res?.toggleCombineOrders);
    },
    onError: () => {
      setIsAutomatic(isAutomaticCombiningEnabled);
    },
    update(cache, { data: { toggleCombineOrders } }) {
      modifyCacheForAutoCombineOrders(cache, toggleCombineOrders);
    }
  });

  const handleEnableCombineOrders = (e) => {
    setIsAutomatic(e.target.checked);
    toggleEnableCombineOrder({
      variables: {
        allowAutomaticCombining: e.target.checked
      }
    });
  };

  return (
    <div className="modal-content modal-large-content combined-order-confirm-modal">
      <div className="modal-header">
        <h5 className="modal-title">Orders Combining</h5>
        <div className="btn btn-xs btn-active-light-primary p-0 m-0 border-none" onClick={closeModal}>
          <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
        </div>
      </div>
      <div className="modal-body">
        <p>Do you want to combine orders?</p>
        <Checkbox
          labelclassname="py-3"
          value={isAutomatic}
          name="Combine all orders in the future"
          onChangeValue={handleEnableCombineOrders}
        />
      </div>
      <div className="modal-footer">
        <button className="btn btn-outlined-secondary btn-md" onClick={onNo} type="button">
          No
        </button>
        <button className="btn btn-primary btn-md" onClick={handleOrdersCombineAndPackingSlip} type="button">
          Yes
        </button>
      </div>
    </div>
  );
};

export default ConfirmationCombinedOrderModal;
