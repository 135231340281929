import { gql } from '@apollo/client';

export const DELETE_USER_NOTE = gql`
  mutation DeleteUserNote($input: DeleteUserNoteInput!) @api(name: "productsAPI") {
    deleteUserNote(input: $input) {
      id
      success
      message
    }
  }
`;
