import { gql } from '@apollo/client';

export const GET_LOCATIONS = gql`
  query onGetLocations($input: GetLocationInput) @api(name: "productsAPI") {
    getLocations(input: $input) {
      id
      name
      warehouseId
      productVariantIds
      totalProducts
      totalProductVariants
      totalQuantity
    }
  }
`;
