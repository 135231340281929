import { FC } from 'react';

// Components
import { Loader } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { CloseIcon } from 'src/assets/icons';

// Types
import { DeleteConfirmModalProps } from './Modal.types';

const DeleteConfirmModal: FC<DeleteConfirmModalProps> = ({
  actionBtnHandler,
  cancelBtnHandler,
  cancelBtnTitle,
  actionBtnTitle,
  message,
  title,
  deleteIcon,
  noDeleteIcon = false,
  isDeleteLoading,
  loadingBtnTitle
}) => {
  const handleCancelBtnHandler = () => {
    document.body.style.overflow = 'unset';
    cancelBtnHandler && cancelBtnHandler();
  };

  const handleActionBtnHandler = () => {
    document.body.style.overflow = 'unset';
    actionBtnHandler && actionBtnHandler();
  };

  return (
    <div className="modal-content default-modal">
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <div className="btn btn-xs btn-active-light-primary p-0 m-0 border-none" onClick={handleCancelBtnHandler}>
          <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
        </div>
      </div>
      <div className="modal-body p-x-30 p-y-36">
        <div>{message !== '' ? message : 'Are you sure you want to delete this?'}</div>
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-outlined-secondary btn-md"
          onClick={handleCancelBtnHandler}
          data-bs-dismiss="modal"
          type="button"
        >
          {cancelBtnTitle}
        </button>
        <button className="btn btn-danger btn-md" disabled={isDeleteLoading} onClick={handleActionBtnHandler} type="button">
          {isDeleteLoading ? loadingBtnTitle : actionBtnTitle}
          {isDeleteLoading && <Loader type="button" className="h-15px w-15px" />}
        </button>
      </div>
    </div>
  );
};

export default DeleteConfirmModal;
