import React, { FC, useEffect, useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { debounce, uniq } from 'lodash';
import moment from 'moment';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_INVENTORY_HISTORY, GET_STORE_USERS } from 'src/apollo/queries';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import { EditColumn, FixedHeaderTable, DatesDropdownSelector } from 'src/components/molecules';
import FilterMenu from './InventoryHistoryFilterMenu';
import FilterMenuDropdown from './FilterMenuDropdown';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';
import { KTSVG } from 'src/helpers';
import getDeviceData from 'src/utils/UseDevice/useDevice';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// ConstVariables
import { constVariables } from 'src/constants';

// Icons
import { CloseIcon, FilterIcon, DefaultImage, CalendarIcon } from 'src/assets/icons';

// Types
import { RowObjectProps, TablesProps, ColumnType } from 'src/components/molecules/Table/Table.types';
import {
  InventoryHistoryModalProps,
  Filters,
  OptionItem,
  DateFilter,
  FilterOptionDateItem,
  IDateRange
} from './InventoryHistotryModal.types';
import { UserRoles } from 'src/components/pages/settings/UserPermissions/UserPermissions.type';

// Styles
import './_inventoryHistoryModal.scss';

const adjustmentTypes: OptionItem[] = [
  { id: 1, name: 'Received', value: 'STOCK_RECEIVED' },
  { id: 2, name: 'Returned', value: 'RESTOCK_RETURN' },
  { id: 3, name: 'Recounted', value: 'INVENTORY_RECOUNT' },
  { id: 4, name: 'Damaged', value: 'DAMAGE' },
  { id: 5, name: 'Theft', value: 'THEFT' },
  { id: 6, name: 'Lost', value: 'LOSS' },
  { id: 7, name: 'Given', value: 'GIVEAWAY' }
];

const initialInventoryHistoryTableColumns: ColumnType[] = [
  { name: 'Date', status: true },
  { name: 'Product', status: true },
  { name: 'Adjustment', status: true },
  { name: 'Location', status: true },
  { name: 'Owner', status: true },
  { name: 'Old Quantity', status: false },
  { name: 'New Quantity', status: false }
];

interface queryResult {
  getInventoryHistory: {
    totalRecords: number;
    logs: any;
  };
}

const InventoryHistoryModal: FC<InventoryHistoryModalProps> = ({ show, closeModal }) => {
  const getDateRange = useCallback((daySelector: DateFilter): IDateRange => {
    switch (daySelector) {
      case DateFilter.Today:
      default: {
        return {
          startTime: moment().startOf('day').toDate(),
          endTime: moment().endOf('day').toDate()
        };
      }
      case DateFilter.Yesterday: {
        return {
          startTime: moment().subtract(1, 'days').startOf('day').toDate(),
          endTime: moment().subtract(1, 'days').endOf('day').toDate()
        };
      }
      case DateFilter['Last week']: {
        return {
          startTime: moment().subtract(7, 'days').toDate(),
          endTime: moment().toDate()
        };
      }
      case DateFilter['Last month']: {
        return {
          startTime: moment().subtract(30, 'days').toDate(),
          endTime: moment().toDate()
        };
      }
      case DateFilter['Last year']: {
        return {
          startTime: moment().subtract(365, 'days').toDate(),
          endTime: moment().toDate()
        };
      }
    }
  }, []);

  const deviceData = getDeviceData();
  const [openEditColumnDropdown, setOpenEditColumnDropdown] = useState<boolean>(false);
  const [showFilterMenu, setShowFilterMenu] = useState<boolean>(false);
  const [adjustmentUsers, setAdjustmentUsers] = useState<OptionItem[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [textTypes, setTextTypes] = useState<(string | undefined)[]>([]);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [editColumnData, setEditColumnData] = useState<ColumnType[]>([]);
  const [activeColumns, setActiveColumns] = useState<ColumnType[]>([]);
  const [dateRange, setDateRange] = useState<IDateRange>(getDateRange(DateFilter.Today));
  const [filters, setFilters] = useState<Filters>({
    adjustmentOwner: [],
    adjustmentType: []
  });

  const adjustmentStatus = (status: string) => {
    switch (status) {
      case 'STOCK_RECEIVED':
        return 'received';
      case 'DAMAGE':
        return 'damaged';
      case 'INVENTORY_RECOUNT':
        return 'recounted';
      case 'THEFT':
        return 'theft';
      case 'LOSS':
        return 'lost';
      case 'RESTOCK_RETURN':
        return 'returned';
      case 'GIVEAWAY':
        return 'given';
      default:
        return '';
    }
  };

  const dateFilterList: FilterOptionDateItem[] = [
    { id: 1, name: DateFilter.Today },
    { id: 2, name: DateFilter.Yesterday },
    { id: 3, name: DateFilter['Last week'] },
    { id: 4, name: DateFilter['Last month'] },
    { id: 5, name: DateFilter['Last year'] },
    { id: 6, name: DateFilter.Custom }
  ];

  const [showDateDropdown, setShowDateDropdown] = useState(false);
  const [selectedDateFilter, setSelectedDateFilter] = useState(dateFilterList[0]);

  const [showCalendar, setShowCalendar] = useState(false);

  const handleSelectDateFilter = (selectedId: number) => {
    const selected = dateFilterList.filter((filterItem) => filterItem.id === selectedId)[0];
    setDateRange(getDateRange(selected.name));
    setSelectedDateFilter(selected);
    setShowDateDropdown(false);
    if (selectedId === 6) {
      setShowCalendar(true);
    }
  };

  const [getAllInventoryHistory, { loading: isLoading, fetchMore, data }] = useLazyQuery(GET_INVENTORY_HISTORY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        filters: {
          adjustmentType: textTypes !== undefined && textTypes.length > 0 ? textTypes : null,
          adjustmentOwner:
            filters.adjustmentOwner !== undefined && filters.adjustmentOwner.length > 0 ? filters.adjustmentOwner : null,
          fromDateTime: dateRange.startTime || '',
          toDateTime: dateRange.endTime || ''
        },
        pageInfo: {
          limitCount: 10,
          skipCount: 0
        }
      }
    },

    onCompleted: (response) => {
      if (response) {
        setTotalCount(response.getInventoryHistory.totalRecords);

        // const tempOwner = {
        //   id: response.getInventoryHistory.logs[0].ownerId,
        //   name: response.getInventoryHistory.logs[0].ownerName
        // };
        // setAdjustmentUsers([tempOwner]);
      }
    },

    onError: (error) => {
      console.error('err', error);
    }
  });

  useEffect(() => {
    getAllInventoryHistory();
  }, []);

  useQuery(GET_STORE_USERS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        filters: {},
        sortBy: null
      }
    },
    onCompleted: (res) => {
      // setUsers(res?.getBackStoreUsers?.users);
      const userArray = res?.getBackStoreUsers?.users
        .map((item) => {
          if (item.invitationStatus === 'ACCEPTED' || item.role === UserRoles.OWNER) {
            return {
              id: item.storeUserId,
              name: `${item.firstName}${item.lastName ? ` ${item.lastName}` : ''}`
            };
          }
          return null;
        })
        .filter(Boolean);
      setAdjustmentUsers(userArray);
    },
    onError: (err) => {
      console.error('Get all users ', err);
    }
  });

  useEffect(() => {
    const tableColumns = localStorage.getItem('inventoryHistoryTable');
    if (tableColumns) {
      setEditColumnData(JSON.parse(tableColumns));
    } else {
      setEditColumnData(initialInventoryHistoryTableColumns);
    }
  }, []);

  useEffect(() => {
    if (editColumnData?.length > 0) {
      const arr = Array.from(editColumnData);
      editColumnData.map((column, index) => {
        if (!column.status) {
          arr.splice(arr.indexOf(column), 1);
        }
        return null;
      });
      setActiveColumns([...arr]);
      localStorage.setItem('inventoryHistoryTable', JSON.stringify(editColumnData));
    }
  }, [editColumnData]);

  const tableData: TablesProps = {};

  const allHeaders = {
    [constVariables.inventoryHistoryEditColumns.date]: {
      id: 1,
      className: 'align-middle border-bottom-0 table-header-background px-4 py-0 minMidSize',
      colName: 'Date'
    },
    [constVariables.inventoryHistoryEditColumns.product]: {
      id: 2,
      className: 'align-middle border-bottom-0 table-header-background px-4 py-0 w-300px',
      colName: 'Product'
    },
    [constVariables.inventoryHistoryEditColumns.adjustment]: {
      id: 3,
      className: 'align-middle p-0 border-bottom-0 table-header-background px-4 py-0 minMidSize',
      colName: 'Adjustment'
    },
    [constVariables.inventoryHistoryEditColumns.location]: {
      id: 4,
      className: 'align-middle p-0 border-bottom-0 table-header-background px-4 py-0 minMidSize',
      colName: 'Location'
    },
    [constVariables.inventoryHistoryEditColumns.owner]: {
      id: 5,
      className: 'align-middle p-0 border-bottom-0 table-header-background px-4 py-0 minMidSize',
      colName: 'Owner'
    },
    [constVariables.inventoryHistoryEditColumns.oldQuantity]: {
      id: 6,
      className: 'align-middle p-0 border-bottom-0 table-header-background px-4 py-0 minMidSize',
      colName: 'Old Quantity'
    },
    [constVariables.inventoryHistoryEditColumns.newQuantity]: {
      id: 7,
      className: 'align-middle p-0 border-bottom-0 table-header-background px-4 py-0 minMidSize',
      colName: 'New Quantity'
    }
  };

  tableData.headers = [];

  activeColumns.map((column) => {
    if (allHeaders[column.name]) {
      tableData.headers?.push(allHeaders[column.name]);
    }
    return null;
  });

  const tableCells = {
    [constVariables.inventoryHistoryEditColumns.date](log, index: number) {
      return {
        className: `align-middle px-4 py-0 minMidSize ${index !== 0 ? 'table-top-border' : 'border-0'}`,
        value: <div className="adjustment-date default-font">{moment(log?.adjustmentCreatedOn).format('DD/MM/YYYY, hh:mm a')}</div>
      };
    },
    [constVariables.inventoryHistoryEditColumns.product](log, index: number) {
      return {
        className: `align-middle px-4 py-0 text-muted w-300px ${index !== 0 ? 'table-top-border' : 'border-0'}`,
        value: (
          <FlexBox className="product-varinat-info align-items-center">
            <div className="symbol me-5">
              <Img
                className="object-fit-scale-down bg-white border border-light border-2"
                src={setImageSrc(log.media[0] ? generateCompressedImageURL(log.media[0], '50') : DefaultImage)}
                placeholderImg={DefaultImage}
                errorImg={DefaultImage}
                height={50}
                width={50}
              />
            </div>
            <div>
              <div className="main-cell no-decoration inventory-history-cell">
                <Link to={`/products/allProducts/edit/${log.productId}`} className="main-cell" target="_blank">
                  <span className="d-block list-product-name">{log.productName}</span>
                </Link>
              </div>

              {/* <Link to={`/products/allProducts/edit/${log.productId}`} className="main-cell">
                <span className="d-block">{log.productName}</span>
              </Link> */}
              {uniq(log.variantValue).map((item, index) => {
                return item ? (
                  <span key={index}>
                    {item}
                    {index + 1 < uniq(log.variantValue).length ? '/' : ''}
                  </span>
                ) : (
                  ''
                );
              })}
            </div>
          </FlexBox>
        )
      };
    },
    [constVariables.inventoryHistoryEditColumns.adjustment](log, index: number) {
      return {
        className: `align-middle px-4 py-0 text-muted minMidSize ${index !== 0 ? 'table-top-border' : 'border-0'}`,
        value: (
          <FlexBox className="align-items-center">
            <span className="default-font">{log.adjustment}</span>
            <div className={`adjustment-status ${adjustmentStatus(log.adjustmentType)}`}>
              {adjustmentStatus(log.adjustmentType)}
            </div>
          </FlexBox>
        )
      };
    },
    [constVariables.inventoryHistoryEditColumns.location](log, index: number) {
      return {
        className: `align-middle px-4 py-0 text-muted minMidSize ${index !== 0 ? 'table-top-border' : 'border-0'}`,
        value: <div className="default-font">{log.locationName ? log.locationName : '-'}</div>
      };
    },
    [constVariables.inventoryHistoryEditColumns.owner](log, index: number) {
      return {
        className: `align-middle px-4 py-0 text-muted minMidSize ${index !== 0 ? 'table-top-border' : 'border-0'}`,
        value: <div className="default-font">{log.ownerName}</div>
      };
    },
    [constVariables.inventoryHistoryEditColumns.oldQuantity](log, index: number) {
      return {
        className: `align-middle px-4 py-0 text-muted minMidSize ${index !== 0 ? 'table-top-border' : 'border-0'}`,
        value: <div className="default-font">-</div>
      };
    },
    [constVariables.inventoryHistoryEditColumns.newQuantity](log, index: number) {
      return {
        className: `align-middle px-4 py-0 text-muted minMidSize ${index !== 0 ? 'table-top-border' : 'border-0'}`,
        value: <div className="default-font">-</div>
      };
    }
  };

  const arr: Array<RowObjectProps> = [];
  data?.getInventoryHistory?.logs?.map((log, index) => {
    const cells: { className: string; value: JSX.Element }[] = [];
    activeColumns.map((column) => {
      tableCells[column.name] && cells.push(tableCells[column.name](log, index));
      return null;
    });
    arr.push({
      className: `position-relative h-fit-content table-row log-row cursor-pointer`,
      id: log.id,
      cells
    });
    return null;
  });
  tableData.rows = arr;

  const handleTableScroll = async (event: React.UIEvent<HTMLDivElement>) => {
    debouncedScroll(event);
  };

  const debouncedScroll = debounce(async (event) => {
    const scrollPosition = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const skipCount = data?.getInventoryHistory?.logs?.length;
    // Table height is 500px and scrollbar Y-height is 13px: 500 -13 = 487px
    if (scrollHeight - scrollPosition === 400) {
      if (totalCount <= data?.getInventoryHistory?.logs?.length) {
        setIsLoadingMore(false);
        return;
      }
      setIsLoadingMore(true);
      await fetchMore({
        variables: {
          input: {
            filters: {
              adjustmentType: textTypes !== undefined && textTypes.length > 0 ? textTypes : null,
              adjustmentOwner:
                filters.adjustmentOwner !== undefined && filters.adjustmentOwner.length > 0 ? filters.adjustmentOwner : null
            },
            pageInfo: {
              limitCount: 10,
              skipCount
            }
          }
        },
        updateQuery: (previousQueryResult: queryResult, { fetchMoreResult }) => {
          if (fetchMoreResult?.getInventoryHistory.logs.length === 0) {
            return previousQueryResult;
          }

          return {
            getInventoryHistory: {
              totalRecords: fetchMoreResult?.getInventoryHistory.totalRecords || 0,
              logs: [...previousQueryResult.getInventoryHistory.logs, ...fetchMoreResult?.getInventoryHistory.logs]
            }
          };
        }
      });
    }
  }, 1000);

  useEffect(() => {
    if (totalCount === data?.getInventoryHistory?.logs?.length) {
      setIsLoadingMore(false);
    }
  }, [data]);

  const handleFilterInventoryHistory = () => {
    const filteredTextTypes = adjustmentTypes.filter((item) => filters.adjustmentType.includes(item.id)).map((item) => item.value);
    setTextTypes(filteredTextTypes);
    setShowFilterMenu(false);
  };

  const handleResetFilters = () => {
    setFilters({
      adjustmentType: [],
      adjustmentOwner: []
    });
    setTextTypes([]);
  };

  const handleCustomPicker = (data) => {
    setDateRange(() => {
      return { startTime: data.from, endTime: data.to };
    });
    setShowCalendar(false);
  };

  const selectedDateName = useMemo(() => {
    if (selectedDateFilter.name === DateFilter.Custom) {
      return showCalendar
        ? DateFilter.Custom
        : `${moment(dateRange.startTime).format('DD/MM/YYYY')} - ${moment(dateRange.endTime).format('DD/MM/YYYY')}`;
    } else {
      return selectedDateFilter.name;
    }
  }, [dateRange, selectedDateFilter.name]);

  return (
    <div
      className={clsx('modal fade opacity-100', {
        show
      })}
      aria-modal="true"
      tabIndex={-1}
      id="kt_modal_1"
    >
      <div
        className={`${
          deviceData.isWeb ? 'modal-dialog modal-dialog-centered flex-center' : 'w-90 w-md-75'
        } inventory-history-modal`}
      >
        <div className={`modal-content ${deviceData.isWeb ? 'w-1068px' : ''}`}>
          <FlexBox className="modal-header justify-content-between">
            <h5 className="modal-title">Inventory history</h5>
            <FlexBox className="d-flex align-items-center">
              <OutsideClickHandler
                onOutsideClick={() => {
                  setOpenEditColumnDropdown(false);
                }}
              >
                <div className="position-relative me-4">
                  <span
                    className="text-primary text-btn cursor-pointer"
                    onClick={() => setOpenEditColumnDropdown(!openEditColumnDropdown)}
                  >
                    {constVariables.inventoryHistoryEditColumns.title}
                  </span>
                  <EditColumn
                    show={openEditColumnDropdown}
                    columns={editColumnData}
                    setEditColumnData={setEditColumnData}
                    allSwitchable={true}
                  />
                </div>
              </OutsideClickHandler>
              <div className="btn btn-xs btn-active-light-primary p-0 m-0 border-none" onClick={closeModal}>
                <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
              </div>
            </FlexBox>
          </FlexBox>
          <div className="modal-body">
            <FlexBox className="mb-4 justify-content-end">
              <button
                className={`btn btn-sm btn-flex btn-icon-text align-items-center ms-0 m-r-16 ${
                  showDateDropdown ? 'btn-primary' : 'btn-secondary'
                }`}
                onClick={() => setShowDateDropdown(true)}
              >
                <KTSVG path={CalendarIcon} className="svg-icon-2 cursor-pointer" />
                <span className="poppins-regular">{selectedDateName}</span>
              </button>

              <FilterMenuDropdown
                isShowDropdown={showDateDropdown}
                data={dateFilterList}
                selectedValue={selectedDateFilter}
                onSelect={handleSelectDateFilter}
              />
              <div className="position-relative">
                <DatesDropdownSelector
                  onselect={(data) => handleCustomPicker(data)}
                  isOpen={showCalendar}
                  noRangePicker={false}
                  closeDropdown={() => setShowCalendar(false)}
                  extraClass="top-0 left-0"
                  isCentered
                />
              </div>

              <div className="position-relative">
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setShowFilterMenu(false);
                  }}
                >
                  <button
                    className={`btn btn-sm btn-flex btn-icon-text align-items-center ${showFilterMenu ? 'btn-primary' : ''}`}
                    onClick={() => setShowFilterMenu(true)}
                  >
                    <KTSVG path={FilterIcon} className="me-1" />
                    <span className="poppins-regular">Filter</span>
                  </button>
                  <FilterMenu
                    show={showFilterMenu}
                    filters={filters}
                    setFilters={setFilters}
                    adjustmentTypes={adjustmentTypes}
                    adjustmentUsers={adjustmentUsers}
                    handleFilter={handleFilterInventoryHistory}
                    resetFilters={handleResetFilters}
                  />
                </OutsideClickHandler>
              </div>
            </FlexBox>
            <div>
              <FixedHeaderTable
                headers={tableData.headers}
                rows={tableData.rows}
                isLoading={isLoading || isLoadingMore}
                loadingPosition={isLoadingMore ? 'bottom' : 'center'}
                containerHeight={400}
                onScroll={handleTableScroll}
                noMargin
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryHistoryModal;
