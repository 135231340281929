import React, { FC, useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { debounce } from 'lodash';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_MANIFESTS } from 'src/apollo/queries';

// Components
import { Img, FlexBox } from 'src/components/atoms';
import { FixedHeaderTable, Divider, PreviewTooltip } from 'src/components/molecules';
import { CustomModal } from 'src/components/oraganisms';
import ManifestPreview from './ManifestPreview';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { PreviewMagnifyingGlassIcon, FileDownLoadIcon, CloseIcon, ShippoLogoImage } from 'src/assets/icons';

// Types
import { ManifestsDropdownProps, ManifestItem } from './ManifestDropdown.types';
import { TablesProps } from '../Table/Table.types';

// Styles
import './_manifestDropdown.scss';

interface queryResult {
  getManifests: ManifestItem[];
}

const ManifestsDropdown: FC<ManifestsDropdownProps> = ({ isOpen, closeDropdown }) => {
  const [manifestList, setManifestList] = useState<ManifestItem[]>([]);
  const [selectedManifest, setSelectedManifest] = useState<ManifestItem>();
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [isLoadingMoreManifests, setIsLoadingMoreManifests] = useState(false);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      closeDropdown();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction);

    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction]);

  const { data, loading, fetchMore } = useQuery(GET_MANIFESTS, {
    variables: {
      input: {
        pageInfo: {
          skipCount: 0,
          limitCount: 10
        }
      }
    },
    onCompleted: (res) => {
      if (res) {
        setManifestList(res.getManifests);
      }
    },
    onError: (err) => {
      console.log('Get manifests error::', err);
    }
  });

  const openManifestPreview = (manifest: ManifestItem) => {
    setSelectedManifest(manifest);
    setShowPreviewModal(true);
  };

  let tableData: TablesProps = {};
  tableData = {
    headerClassName: 'position-relative ',
    isDragDisabled: true,
    bodyClass: 'w-100 no-top-border mh-300px',
    className: 'rounded-top w-100 scrollable-table-wrapper',
    headers: [
      {
        id: 1,
        className: 'py-0 ps-4 pe-0 align-middle table-header-background w-80px',
        colName: 'Carrier'
      },
      {
        id: 2,
        className: 'py-0 align-middle table-header-background w-120px',
        colName: 'Hand-off Date'
      },
      {
        id: 3,
        className: 'ps-3 py-0 align-middle table-header-background w-120px',
        colName: 'Status'
      },
      {
        id: 4,
        className: 'py-0 align-middle table-header-background w-100px',
        colName: 'Labels'
      },
      {
        id: 5,
        className: 'py-0 align-middle table-header-background w-120px',
        colName: ''
      }
    ],
    rows: manifestList?.map((item, index) => ({
      id: item.id,
      isDragDisabled: true,
      className: 'position-relative border-top-0 dontScrollBulkEditorTop',
      cells: [
        {
          className: `align-middle ps-4 pe-0 py-0 w-80px ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: (
            <div className="d-flex align-items-center">
              <div className="me-5">
                <img src={ShippoLogoImage} alt="" className="carrier-logo" />
              </div>
            </div>
          )
        },
        {
          className: `align-middle text-muted py-0 align-middle w-120px ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: moment(item.date).format('DD/MM/YYYY')
        },
        {
          className: `align-middle text-muted w-120px ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: (
            <div className="btn-group cursor-pointer">
              <button
                type="button"
                className={clsx('btn px-5 poppins-regular', {
                  'badge rounded-pill text-success badge-light-success': item.status === 'SUCCESS',
                  'badge rounded-pill text-muted badge-light-info': item.status === 'INACTIVE',
                  'badge rounded-pill text-warning badge-light-warning': item.status === 'SCHEDULED'
                })}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {item.status[0].toUpperCase() + item.status.substr(1).toLowerCase()}
              </button>
            </div>
          )
        },
        {
          className: `align-middle text-muted w-100px ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: (
            <FlexBox className="align-items-center">
              <span className="me-3">{item.numberOfLables}</span>
              <>
                <img
                  className="cursor-pointer"
                  src={PreviewMagnifyingGlassIcon}
                  alt="previewIcon"
                  data-tip
                  data-for="previewIcon"
                  onClick={() => {
                    openManifestPreview(item);
                  }}
                />
                <PreviewTooltip />
              </>
            </FlexBox>
          )
        },
        {
          className: `align-middle text-muted w-120px ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: (
            <a
              className="d-flex align-items-center text-primary cursor-pointer"
              href={item?.manifestUrl[0]}
              download
              target="_blank"
              rel="noreferrer"
            >
              <Img src={FileDownLoadIcon} className="me-2" />
              <span className="text-btn">Download</span>
            </a>
            // <div className="d-flex align-items-center text-primary cursor-pointer" onClick={() => handleDownLoadManifest(item?.manifestUrl[0])}>
            //   <Img src={FileDownLoadIcon} className="me-2" />
            //   <span className="text-btn">Download</span>
            // </div>
          )
        }
      ]
    }))
  };

  const closePreviewModal = () => {
    document.body.style.overflow = 'unset';
    setShowPreviewModal(false);
  };

  const handleGetMoreManifests = (event: React.UIEvent<HTMLDivElement>) => {
    debouncedScroll(event);
  };

  const debouncedScroll = debounce(async (event) => {
    const scrollPosition = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const skipCount = data?.getManifests?.length;

    if (scrollHeight - scrollPosition === 300) {
      setIsLoadingMoreManifests(true);
      await fetchMore({
        variables: {
          input: {
            pageInfo: {
              limitCount: 10,
              skipCount
            }
          }
        },
        updateQuery: (previousQueryResult: queryResult, { fetchMoreResult }) => {
          if (fetchMoreResult?.getManifests?.length === 0) {
            setIsLoadingMoreManifests(false);
            return previousQueryResult;
          }

          return {
            getManifests: fetchMoreResult?.getManifests
          };
        }
      });
    }
  }, 1000);

  return (
    <>
      <div
        className={clsx('mt-1 menu menu-sub menu-background menu-sub-dropdown position-absolute manifest-dropdown', {
          show: isOpen
        })}
        data-kt-menu="true"
        style={{ right: 0 }}
      >
        <FlexBox className="manifest-dropdown-header align-items-center justify-content-between">
          <h4 className="m-0 poppins-medium">Recent Manifests</h4>
          <KTSVG path={CloseIcon} className="cursor-pointer" onClick={closeDropdown} />
        </FlexBox>
        <Divider />
        <div className="manifest-dropdown-content d-flex align-items-center justify-content-center">
          {manifestList?.length > 0 ? (
            <FixedHeaderTable
              headers={tableData.headers}
              rows={tableData.rows}
              loadingPosition={isLoadingMoreManifests ? 'bottom' : 'center'}
              isLoading={loading}
              containerHeight={300}
              type="manifest"
              onScroll={handleGetMoreManifests}
            />
          ) : (
            <div className="text-center">Oops! No manifests found in the last 5 days.</div>
          )}
        </div>
      </div>
      {showPreviewModal && (
        <CustomModal
          show={showPreviewModal}
          closeModal={closePreviewModal}
          bodyClassname="d-flex justify-content-center w-90 w-md-150"
        >
          <ManifestPreview selectedManifest={selectedManifest} closeModal={closePreviewModal} />
        </CustomModal>
      )}
    </>
  );
};

export default ManifestsDropdown;
