import { FC, useState, useEffect } from 'react';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_FACEBOOK_CHANNELS } from 'src/apollo/queries';

// Components
import { FlexBox } from 'src/components/atoms';
import { Checkbox, EmojiInput } from 'src/components/molecules';
import { Thumbnail } from 'src/components/oraganisms';

// Icons
import { UploadThumbnailIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { CreateUnplannedLiveShowDetailStepPresentationalProps } from './CreateUnplannedLiveShowDetailStep.types';
import { UnplannedLiveShowDetailInfo } from 'src/components/templates/CreateUnplannedLiveshow/CreateUnplannedLiveshow.types';

// Styles
import './_createUnplannedLiveShowDetailStep.scss';

const CreateUnplannedLiveShowDetailStepPresentational: FC<CreateUnplannedLiveShowDetailStepPresentationalProps> = ({
  liveShowDetailInfo,
  liveShowInfoError,
  handleSendSuccessURL,
  updateLiveShowDetailInfo,
  updateLiveShowInfoErrors,
  removeThumbnail
}) => {
  const { facebookChannels } = liveShowDetailInfo;

  const [fbChannelsChecked, setFBChannelsChecked] = useState(false);
  const [showFBChannels, setShowFBChannels] = useState(true);

  useEffect(() => {
    if (facebookChannels?.length > 0) {
      setFBChannelsChecked(true);
    } else {
      setFBChannelsChecked(false);
    }
  }, [facebookChannels]);

  const { data } = useQuery(GET_FACEBOOK_CHANNELS);

  // Event handlers
  const changeLiveShowTitle = (text: string): void => {
    const updateData: UnplannedLiveShowDetailInfo = Object.assign({}, liveShowDetailInfo);
    updateData.title = text;
    updateData.notification.app.title = text;
    updateData.notification.messenger.description = text;
    updateData.notification.textMessage = text;
    updateLiveShowDetailInfo(updateData);
    if (text.length > 0) {
      updateLiveShowInfoErrors({ ...liveShowInfoError, titleError: '' });
    }
  };

  const allowUnSelectChannel = (e) => {
    if (e.target.checked) return true;

    if (
      e.target.name === constVariables.LiveShow.inputFields.facebookChannels &&
      !liveShowDetailInfo.streamOnApp &&
      !liveShowDetailInfo.streamOnWebsite
    )
      return false;

    let selectedLength = liveShowDetailInfo.facebookChannels.length;
    if (liveShowDetailInfo.streamOnApp) ++selectedLength;
    if (liveShowDetailInfo.streamOnWebsite) ++selectedLength;
    return selectedLength > 1;
  };

  const handleSelectChannel = (e, channel: string) => {
    if (!allowUnSelectChannel(e)) return;

    const updateData = Object.assign({}, liveShowDetailInfo);
    updateData[channel] = e.target.checked;
    updateLiveShowDetailInfo(updateData);
  };

  const handleAllFacebookChannels = (e) => {
    if (!allowUnSelectChannel(e)) return;

    const updateData: UnplannedLiveShowDetailInfo = Object.assign({}, liveShowDetailInfo);
    if (e.target.checked) {
      updateData.facebookChannels = data?.getFacebookChannels.map((channel) => {
        return { id: channel.id, type: channel.type };
      });
    } else {
      updateData.facebookChannels = [];
    }
    updateLiveShowDetailInfo(updateData);
  };

  const handleFacebookChannel = (e, channel) => {
    if (!allowUnSelectChannel(e)) return;

    const updateData: UnplannedLiveShowDetailInfo = Object.assign({}, liveShowDetailInfo);
    if (e.target.checked) {
      const updatedChannel = { id: channel.id, type: channel.type };
      updateData.facebookChannels = [...updateData.facebookChannels, updatedChannel];
    } else {
      updateData.facebookChannels = updateData.facebookChannels.filter((item) => item.id !== channel.id);
    }
    updateLiveShowDetailInfo(updateData);
  };

  return (
    <div className="create-live-show-detail-step-div">
      <FlexBox className="content">
        <div className="input-view">
          <div className="p-7 input-fields">
            <div className="w-100">
              <EmojiInput
                isError={liveShowInfoError.titleError !== ''}
                errorMessage={liveShowInfoError.titleError}
                title={constVariables.LiveShow.inputFields.liveShowTitle}
                placeholder={constVariables.LiveShow.inputFields.liveShowTitlePlaceholder}
                isMandatory={true}
                value={liveShowDetailInfo.title}
                showCount={true}
                max={constVariables.emojiInputCountLimit}
                onChangeText={changeLiveShowTitle}
              ></EmojiInput>
            </div>
          </div>
          <div className="w-100 p-7 channels-section">
            <div className={`poppins-semibold fs-6 mb-3`}>
              {constVariables.LiveShow.inputFields.streamChannel}
              <span className="text-danger fs-4 px-1">*</span>
            </div>
            <>
              {Object.keys(constVariables.LiveShow.inputFields.streamChannels).map((channel, index) => {
                return (
                  <div className="w-100" key={index}>
                    <Checkbox
                      tagclassname="poppins-medium final-scale-checkbox"
                      labelclassname="pb-3"
                      name={constVariables.LiveShow.inputFields.streamChannels[channel]}
                      value={liveShowDetailInfo[channel]}
                      onChangeValue={(e) => handleSelectChannel(e, channel)}
                      smaller
                      inputclassname="smallerInput"
                    />
                  </div>
                );
              })}
              <div>
                <div className="w-100">
                  <Checkbox
                    tagclassname="poppins-medium final-scale-checkbox"
                    labelclassname="pb-3"
                    name={constVariables.LiveShow.inputFields.facebookChannels}
                    value={fbChannelsChecked}
                    onChangeValue={(e) => {
                      handleAllFacebookChannels(e);
                    }}
                    smaller
                    downArrow
                    isRotateDownArrow={showFBChannels}
                    downArrowClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowFBChannels(!showFBChannels);
                    }}
                    inputclassname="smallerInput"
                  />
                </div>
                <div className="ps-6">
                  {showFBChannels &&
                    data?.getFacebookChannels?.map((channel, index) => (
                      <div className="w-100" key={index}>
                        <Checkbox
                          tagclassname="poppins-medium final-scale-checkbox"
                          labelclassname="pb-3"
                          name={channel.name}
                          value={facebookChannels.map((item) => item.id).includes(channel.id)}
                          onChangeValue={(e) => {
                            handleFacebookChannel(e, channel);
                          }}
                          smaller
                          inputclassname="smallerInput"
                        />
                      </div>
                    ))}
                </div>
              </div>
              <div className="ps-5">
                {liveShowInfoError.channelError && (
                  <div className={`text-danger poppins-regular fs-7 mb-3`}>{liveShowInfoError.channelError}</div>
                )}
              </div>
            </>
          </div>
        </div>
        <div className="thumbnail-view text-center">
          <Thumbnail
            title={liveShowDetailInfo.title}
            type="liveshow"
            thumbnailUrlData={liveShowDetailInfo.thumbnailUrl}
            hidePlaceholderText={true}
            classes={'thumbnail-title'}
            showDeleteIcon={true}
            placeholderImg={UploadThumbnailIcon}
            removeThumbnail={removeThumbnail}
            setThumbnailUrlData={handleSendSuccessURL}
            uploadeType="LIVE_STREAM_THUMBNAIL"
          />
        </div>
      </FlexBox>
    </div>
  );
};
export default CreateUnplannedLiveShowDetailStepPresentational;
