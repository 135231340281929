import { gql } from '@apollo/client';

export const GET_USER_FAVORITE_LIST = gql`
  query getUserFavouriteList($input: GetUserFavouriteList) @api(name: "productsAPI") {
    getUserFavouriteList(input: $input) {
      count {
        wishlist
        waitlist
        waitlistAuth
      }
      products {
        id
        name
        imageUrl
        shopifyId
      }
    }
  }
`;
