import { FC } from 'react';
import clsx from 'clsx';

interface Props {
  handleConfirm: () => void;
}

const ErrorOnDashboardModal: FC<Props> = (props) => {
  const { handleConfirm } = props;

  return (
    <div className={clsx('modal-content countdown-timer-div justify-content-center')}>
      <div className="countdown-header">
        <p>Sorry! Some Error occured</p>
      </div>
      <div className="modal-footer">
        <button className={clsx('btn btn-md delete-button')} onClick={handleConfirm} type="button">
          Go back!
        </button>
      </div>
    </div>
  );
};

export default ErrorOnDashboardModal;
