import { gql } from '@apollo/client';

export const ADD_PRODUCT_TO_COLLECTION = gql`
  mutation AddProductToCollection($input: CollectionMutationInput!) @api(name: "authAPI") {
    addProductToCollection(input: $input) {
      success
      message
    }
  }
`;
