import { constVariables } from 'src/constants';
export const TimeZoneFormat = [
  {
    id: 1,
    name: constVariables.LiveShow.liveShowTimeFormat.AM
  },
  {
    id: 2,
    name: constVariables.LiveShow.liveShowTimeFormat.PM
  }
];
