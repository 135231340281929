import React, { FC, useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { DropdownSelector } from 'src/components/atoms';
import { Dropdown } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { RemoveButtonIcon } from 'src/assets/icons';

// Types
import { RulesEngineConditionItemProps } from './RulesEngineConditions.types';

// Styles
import './_rulesEngineCondition.scss';

const RulesEngineConditionItem: FC<RulesEngineConditionItemProps> = ({
  condition,
  index,
  openDropdown,
  fieldDropdown,
  rulesEngineConditions,
  setRulesEngineConditions,
  setActiveIndex,
  openTagCollectionTypeDropdown,
  getTagsList,
  getCollections,
  getProductTypeData,
  getVendorData,
  setRulesChanges,
  conditionDropdownArray,
  setConditionDropdownArray,
  noMargin
}) => {
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [searchCategoryText, setSearchCategoryText] = useState('');
  const [filteredCategoryDropdownData, setFilteredCategoryDropdownData] = useState<{ id: number; name: string }[]>([]);

  useEffect(() => {
    setFilteredCategoryDropdownData(
      conditionDropdownArray[index]?.categoryDropdown?.length > 0 ? conditionDropdownArray[index]?.categoryDropdown : []
    );
  }, [conditionDropdownArray[index]?.categoryDropdown]);

  useEffect(() => {
    if (conditionDropdownArray[index]?.categoryDropdown?.length > 0) {
      const filteredData = conditionDropdownArray[index]?.categoryDropdown?.filter((item) =>
        item.name.toLowerCase().includes(searchCategoryText.toLowerCase())
      );
      setFilteredCategoryDropdownData(filteredData);
    } else {
      setFilteredCategoryDropdownData([]);
    }
  }, [searchCategoryText]);

  return (
    <div className={`w-100 ${noMargin ? 'mb-0' : 'm-b-24'}`}>
      <div className="d-flex align-items-center w-100">
        <div className="d-flex w-95">
          <div
            className={`position-relative me-3 w-33 input-group outside-click-parent-div px-3 h-40px ${
              typeof condition.openFieldDropdown === 'boolean' && condition.openFieldDropdown ? 'dropdown-box-active' : ''
            }`}
          >
            <OutsideClickHandler
              onOutsideClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (typeof condition.openFieldDropdown === 'boolean' && condition.openFieldDropdown) {
                  openDropdown(index, 'openFieldDropdown', false);
                }
              }}
            >
              <DropdownSelector
                onClick={() => {
                  openDropdown(index, 'openFieldDropdown');
                }}
                selectedValue={fieldDropdown.find(({ id }) => id === condition?.field?.id)?.name}
                text="Select an option"
              />
              <Dropdown
                data={fieldDropdown}
                selected={typeof condition.openFieldDropdown === 'boolean' && condition.openFieldDropdown}
                className="conditions-dropdown dropdown-custom-width"
                onSelect={(e) => {
                  let type = '';
                  if (e.name === 'Product tag') {
                    type = 'TAG';
                  } else if (e.name === 'Product type') {
                    type = 'PRODUCT_TYPE';
                  } else if (e.name === 'Collection Name') {
                    type = 'COLLECTION';
                  } else if (e.name === 'Vendor Name') {
                    type = 'VENDOR';
                  }

                  const arr = Array.from(rulesEngineConditions);
                  const obj = arr[index];
                  obj.field = e;
                  obj.condition = '';
                  obj.category = '';
                  obj.categoryName = '';
                  obj.openFieldDropdown = false;

                  arr[index] = obj;
                  setRulesEngineConditions([...arr]);
                  setActiveIndex(index);
                  openTagCollectionTypeDropdown(index);
                  if (type === 'TAG') {
                    getTagsList({
                      variables: {
                        input: {
                          filters: {
                            searchText: ''
                          }
                        }
                      }
                    });
                  } else if (type === 'COLLECTION') {
                    getCollections({
                      variables: {
                        input: {
                          setDroppableByStatus: false,
                          pageInfo: {
                            skipCount: 0,
                            limitCount: 1000
                          }
                        }
                      }
                    });
                  } else if (type === 'PRODUCT_TYPE') {
                    getProductTypeData({
                      variables: {
                        input: {
                          getStoreSpecificCategories: true
                        },
                        state: Math.random()
                      }
                    });
                  } else if (type === 'VENDOR') {
                    getVendorData({
                      variables: {
                        input: {
                          ids: [],
                          pagination: {
                            skipCount: 0,
                            limitCount: 1000
                          },
                          status: 'ACTIVE',
                          sort: {
                            column: 'NAME',
                            order: 'DESC'
                          }
                        },
                        state: Math.random()
                      }
                    });
                  }
                  if (setRulesChanges) {
                    setRulesChanges(true);
                  }
                }}
                value={fieldDropdown.find(({ id }) => id === condition?.field?.id)?.name}
                closeDropdown={() => openDropdown(index, 'openFieldDropdown', false)}
              />
            </OutsideClickHandler>
          </div>
          <div
            className={`d-flex position-relative me-3 w-33 input-group outside-click-parent-div px-3 h-40px  ${
              condition?.field?.id ? '' : 'disable-background'
            } ${
              typeof condition.openConditionDropdown === 'boolean' && condition.openConditionDropdown ? 'dropdown-box-active' : ''
            }`}
          >
            <OutsideClickHandler
              onOutsideClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (typeof condition.openConditionDropdown === 'boolean' && condition.openConditionDropdown) {
                  openDropdown(index, 'openConditionDropdown', false);
                }
              }}
            >
              <DropdownSelector
                onClick={() => {
                  openDropdown(index, 'openConditionDropdown');
                }}
                selectedValue={condition.condition}
                text="Select an option"
              />
              <Dropdown
                data={
                  fieldDropdown[
                    fieldDropdown.findIndex(
                      (element) => element.name === fieldDropdown.find(({ id }) => id === condition?.field?.id)?.name
                    )
                  ]?.operations
                }
                selected={typeof condition.openConditionDropdown === 'boolean' && condition.openConditionDropdown}
                className="conditions-dropdown dropdown-custom-width"
                onSelect={(e) => {
                  const arr = Array.from(rulesEngineConditions);
                  const obj = arr[index];
                  obj.condition = e.name;
                  obj.openConditionDropdown = false;
                  arr[index] = obj;
                  setRulesEngineConditions([...arr]);
                  if (setRulesChanges) {
                    setRulesChanges(true);
                  }
                }}
                value={condition?.condition}
                closeDropdown={() => openDropdown(index, 'openConditionDropdown', false)}
              />
            </OutsideClickHandler>
          </div>
          <div
            className={`position-relative w-33 outside-click-parent-div h-40px ${condition?.condition ? '' : 'disable-background'}`}
          >
            {!['Product tag', 'Collection Name', 'Product type', 'Vendor Name'].includes(
              fieldDropdown.find(({ id }) => id === condition?.field?.id)?.name || ''
            ) ? (
              <>
                <input
                  autoComplete="off"
                  className="form-control fs-13px h-100 h-40px"
                  type="text"
                  value={typeof condition?.category === 'string' ? condition?.category : ''}
                  onChange={(e) => {
                    if (setRulesChanges) {
                      setRulesChanges(true);
                    }
                    const arr = Array.from(rulesEngineConditions);
                    const obj = condition;
                    obj.category = e.target.value;
                    arr[index] = obj;
                    setRulesEngineConditions([...arr]);
                  }}
                />
              </>
            ) : (
              <div className="d-flex position-relative w-33 input-group outside-click-parent-div px-3 h-40px">
                <OutsideClickHandler
                  onOutsideClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (typeof condition.openCategoryDropdown === 'boolean' && condition.openCategoryDropdown) {
                      openDropdown(index, 'openCategoryDropdown', false);
                      setShowSearchInput(false);
                    }
                  }}
                >
                  <DropdownSelector
                    onClick={() => {
                      setShowSearchInput(true);
                      openDropdown(index, 'openCategoryDropdown');
                    }}
                    selectedValue={condition?.categoryName}
                    text="Select an option"
                  />
                  {showSearchInput ? (
                    <input
                      autoComplete="off"
                      className="form-control fs-13px h-100 h-40px rules-engine-select-search position-absolute"
                      autoFocus
                      type="text"
                      value={searchCategoryText}
                      onChange={(e) => setSearchCategoryText(e.target.value)}
                    />
                  ) : null}
                  <Dropdown
                    className="conditions-dropdown dropdown-custom-width"
                    data={filteredCategoryDropdownData}
                    selected={typeof condition.openCategoryDropdown === 'boolean' && condition.openCategoryDropdown}
                    onSelect={(e) => {
                      if (setRulesChanges) {
                        setRulesChanges(true);
                      }
                      const arr = Array.from(rulesEngineConditions);
                      const obj = condition;
                      obj.category = e.id;
                      obj.categoryName = e.name;
                      obj.categoryOptions = conditionDropdownArray[index]?.categoryDropdown;
                      arr[index] = obj;
                      obj.openCategoryDropdown = false;
                      setRulesEngineConditions([...arr]);
                      setShowSearchInput(false);
                      setSearchCategoryText('');
                      setFilteredCategoryDropdownData(conditionDropdownArray[index]?.categoryDropdown);
                    }}
                    closeDropdown={() => openDropdown(index, 'openCategoryDropdown', false)}
                  />
                </OutsideClickHandler>
              </div>
            )}
            <div className={`text-danger fs-8 errorbox`}>{condition?.errorMessage}</div>
          </div>
        </div>
        <div className="w-5 d-flex align-items-start justify-content-end ms-3">
          <button
            className="btn delete-icon-btn"
            onClick={() => {
              const arr = Array.from(rulesEngineConditions);
              const conditionArr = Array.from(conditionDropdownArray);
              arr.splice(index, 1);
              conditionArr.splice(index, 1);
              setRulesEngineConditions([...arr]);
              setConditionDropdownArray([...conditionArr]);
              if (setRulesChanges) {
                setRulesChanges(true);
              }
            }}
          >
            <KTSVG path={RemoveButtonIcon} className="m-0" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(RulesEngineConditionItem);
