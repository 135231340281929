import React, { FC, useEffect, useState, SetStateAction } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import validator from 'validator';

// Components
import { FlexBox, Loader } from 'src/components/atoms';
import { Dropdown, InputBox } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { DownArrowIcon, NotVerifiedIcon, SmallMailIcon, SmallPhoneIcon, VerifiedImage } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { CustomerAddressDropdownProps } from 'src/components/oraganisms/AddCustomerAddress/AddCustomerAddress.types';
import { ContactInfoDataCDType } from 'src/components/pages/customers/CustomerDetails/CustomerDetails.types';

interface ContactInfoType extends ContactInfoDataCDType {
  editContactInfo?: boolean;
  toggleEditContactInfo?: () => void;
  changeEmailAddress?: (data: string) => void;
  changePhoneNumber?: (data: string) => void;
  phoneList?: CustomerAddressDropdownProps[] | undefined;
  closePhoneDropdown?: () => void;
  togglePhoneDropdown?: () => void;
  onSelectPhone?: (data: any) => void;
  saveEmailPhoneEdit?: () => void;
  cancelEmailPhoneEdit?: () => void;
  canEdit?: boolean;
  setContactInfoData?: React.Dispatch<SetStateAction<ContactInfoDataCDType>>;
}
const ContactInfoDetails: FC<ContactInfoType> = ({
  email,
  phone,
  isEmailVerified,
  isPhoneVerified,
  editContactInfo,
  toggleEditContactInfo,
  editingEmail,
  editingPhone,
  changeEmailAddress,
  changePhoneNumber,
  phoneList,
  selectedPhone,
  selectPhoneDropdown,
  togglePhoneDropdown,
  closePhoneDropdown,
  onSelectPhone,
  cancelEmailPhoneEdit,
  saveEmailPhoneEdit,
  saveButtonDisabled,
  canEdit,
  setContactInfoData
}) => {
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  useEffect(() => {
    if (editingEmail) {
      if (validator.isEmail(editingEmail)) {
        setEmailError('');
      } else {
        setEmailError('Enter a valid email address.');
      }
    } else {
      setEmailError('Email address can’t be left empty.');
    }
  }, [editingEmail]);

  useEffect(() => {
    if (editingPhone) {
      if (validator.isMobilePhone(editingPhone)) {
        setPhoneError('');
      } else {
        setPhoneError('Enter a valid phone number.');
      }
    }
  }, [editingPhone]);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between ">
        <h4 className="section-title mb-0">{constVariables.customers.contactInformation}</h4>
        {!editContactInfo && canEdit ? (
          <div onClick={toggleEditContactInfo} className="fs-8 text-primary cursor-pointer">
            Edit
          </div>
        ) : null}
      </div>
      {editContactInfo ? (
        <div className="m-t-32">
          <div className="text-nowrap poppins-semibold fs-8 m-b-8">
            {constVariables.customerDetails.addCustomerAddress.emailAddress}
          </div>
          <InputBox
            inputClass="form-control-lg fs-8"
            value={editingEmail}
            onChangeText={changeEmailAddress}
            name={constVariables.customerDetails.addCustomerAddress.emailAddress}
            placeholder={constVariables.customerDetails.addCustomerAddress.emailAddressPH}
            warningText={emailError}
          />

          <div className="d-flex mt-3">
            <div className="text-nowrap poppins-semibold fs-8 m-b-8">Phone Number</div>
          </div>
          <OutsideClickHandler onOutsideClick={closePhoneDropdown ? closePhoneDropdown : () => null}>
            <div className="d-flex position-relative ">
              <div>
                <FlexBox
                  className={`right-border-flat form-control form-control-lg justify-content-between align-items-center cursor-pointer dropdown-box`}
                  onClick={togglePhoneDropdown}
                >
                  <div className="selected-option-text">
                    {selectedPhone ? selectedPhone : <span className="placeholder-text">Select</span>}
                  </div>
                  <div>
                    <KTSVG path={DownArrowIcon} />
                  </div>
                </FlexBox>
              </div>
              <InputBox
                mainclass="col"
                inputClass="form-control-lg fs-8 left-border-flat"
                value={editingPhone}
                onChangeText={changePhoneNumber}
                name={constVariables.customerDetails.addCustomerAddress.phone}
                placeholder={constVariables.customerDetails.addCustomerAddress.phonePH}
                charLimit={15}
                warningText={phoneError}
              />
              {phoneList && selectPhoneDropdown && phoneList.length > 0 && (
                <Dropdown
                  className="dropdown-custom-width"
                  data={phoneList}
                  selected={selectPhoneDropdown}
                  value={selectedPhone}
                  onSelect={onSelectPhone}
                  closeDropdown={() =>
                    setContactInfoData &&
                    setContactInfoData((contactData) => ({
                      ...contactData,
                      selectPhoneDropdown: false
                    }))
                  }
                />
              )}
            </div>
          </OutsideClickHandler>

          <div className="d-flex w-100 mt-5 align-items-center justify-content-end">
            {saveButtonDisabled ? (
              <Loader type="button" className="text-primary" />
            ) : (
              <>
                <button onClick={cancelEmailPhoneEdit} type="button" className="btn btn-outlined-secondary btn-xs me-1">
                  Cancel
                </button>
                <button
                  onClick={saveEmailPhoneEdit}
                  type="button"
                  className="btn btn-primary btn-xs ms-1"
                  disabled={emailError !== '' || phoneError !== ''}
                >
                  Save
                </button>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="m-t-32">
          {email ? (
            <div>
              <img src={SmallMailIcon} className="h-15px w-15px" />
              <span className="fs-7 mx-2 text-primary">{email}</span>
              <img src={isEmailVerified ? VerifiedImage : NotVerifiedIcon} className="h-15px w-15px" />
            </div>
          ) : null}
          {phone ? (
            <div className="mt-3">
              <img src={SmallPhoneIcon} className="h-15px w-15px" />
              <span className="fs-7 mx-2 ">{`${selectedPhone ? selectedPhone : ''} ${phone}`}</span>
              <img src={isPhoneVerified ? VerifiedImage : NotVerifiedIcon} className="h-15px w-15px" />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default ContactInfoDetails;
