export type orderDetailsData = {
  id?: number;
  orderCode: string;
  orderStatus: string;
  customer: string;
  customerId?: number;
  customerOrder?: number;
  customerEmail: string;
  customerPhoneNo: string;
  isEmailVerified: boolean;
  customerOrders: number;
  isPhoneNoVerified: boolean;
  subTotal: number;
  thumbnailUrl: string;
  totalWeight: number;
  orderDetails?: {
    id: number;
    name: string;
    sku: string;
    quantity: number;
    availableQuantity: number;
    addSignatureConfirmation?: boolean;
    addShipmentInsurance?: boolean;
    price: {
      price?: number;
      quantity?: number;
    }[];
    code: string;
    orderItemStatus: string;
    imageUrl: string;
    location: string;
    items: string;
    productId?: number;
    variantId?: number;
    dimensions: string;
    variantTypes: {
      id: number;
      name: string;
      value: string;
    }[];
    shopifyId?: string;
  }[];
  total?: number;
  availableItemsQuantity: number;
  items: number;
  isPartiallyShipped: boolean;
  shippingAddress?: {
    id?: number;
    receiverName?: string;
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    city?: string;
    state?: string;
    country?: string;
    pincode?: string;
    type?: string;
    isPrimary?: boolean;
    phoneNumber?: string;
    isVerified?: boolean;
  };
  isExpeditedShipping?: boolean;
  discountAmount: number;
  shippingAmount: number;
  taxAmount: number;
  createdAt: string;
  updatedAt: string;
  isCombinedOrder?: boolean;
  childOrders?: {
    id: number;
    code: number;
    paidAt: Date;
    status: OrderStatus;
    totalAmount: number;
    tags: string[];
    quantity: number;
    availableQuantity: number;
  }[];
  isCombOrdrParent: boolean;
  noOfCombChildOrds: number;
  shopifyId?: string;
};

export type OrdersListData = {
  id: number;
  orderCode: string;
  thumbnailUrl: string;
  createdAt: string;
  orderStatus: string;
  customer: string;
  customerOrders: number;
  isPartiallyShipped: boolean;
  total: number;
  items: number;
  availableItemsQuantity: number;
  isOrderAddressVerified: boolean;
  hasWeightErrors: boolean;
  mediaUrls: string;
  tags: any;
  customerId: number;
  isExpeditedShipping?: boolean;
  shippingAddress: {
    isVerified: boolean;
  };
  orderDetails: {
    id: number;
    name: string;
    sku: string;
    code: number;
    productId: number;
    quantity: number;
    availableQuantity: number;
    location: string;
    dimensions: string;
    orderItemStatus: string;
    imageUrl: string;
    items: any;
  }[];
  isFirstTimeCustomer: boolean;
  isVIPCustomer: boolean;
  isCombOrdrParent: boolean;
  isCombOrdrChild: boolean;
  noOfCombChildOrds: number;
  shopifyId: string;
};

export type OrdersListProps = {
  selectedOrderType?: 'INDIVIDUAL' | 'COMBINED';
  selectedDates: { to: string; from: string } | undefined;
  rapidActionOpen: boolean;
  openOrderShippingPreview?: () => void;
  openShipmentDetails?: (id: number) => void;
  sendDate?: (date: string, index: number) => void;
  selectedTab: string;
  changingDate: (data: string) => void;
  ordersList: Array<OrdersListData>;
  setOrdersList: React.Dispatch<React.SetStateAction<OrdersListData[]>>;
  setSortBy: any;
  sortBy: {
    catagory: string;
    id: number;
    type: string;
  };
  refresh: () => void;
  setIsOpenOrderPreview: React.Dispatch<React.SetStateAction<boolean>>;
  customDateSelector: (data: any) => void;
  orderDetails: orderDetailsData;
  setOrderDetails: React.Dispatch<React.SetStateAction<orderDetailsData>>;
  searchForOrders: (keyword: string) => void;
  isLoading: boolean;
  searchText: string;
  totalCount: number;
  editColumnData?: {
    name: string;
    status: boolean;
  }[];
  countList?: any;
};

export type DropdownOptions = {
  id: number;
  name: string;
};

export type CombinedOrderListExpandedProps = {
  setStatusColor?: (status: string, isPartial?: boolean) => string;
  setStatusText?: (status: string, isPartial?: boolean) => string;
  filterOrderMenu?: (orderStatus: string) => { id: number; name: string }[];
  handleOrderMenu?: (selectedOption: DropdownOptions, order: OrdersListData) => void;
  openOrderPreview?: (id: number, isCombinedOrder: boolean, order) => void;
  orderId?: number;
};
export type OrderListExpandedProps = {
  openOrderShippingPreview?: (type: string) => void;
  openShipmentDetails?: (id: number) => void;
  sendDate?: (date: string, index: number) => void;

  orderId?: number;
};
export type ListExpandedProps = {
  id: number;
  shipmentDate: string;
  status: string;
  totalAmount: number;
  numberOfItems: number;
};

export enum OrderStatus {
  CREATED = 'created',
  PENDING = 'pending',
  AWAITING_PAYMENT = 'awaiting_payment',
  AWAITING_FULFILLMENT = 'awaiting_fulfillment',
  AWAITING_PROCESSING = 'awaiting_processing',
  AWAITING_SHIPMENT = 'awaiting_shipment',
  AWITING_PICKUP = 'awiting_pickup',
  PARTIALLY_SHIPPED = 'partially_shipped',
  COMPLETED = 'completed',
  SHIPPED = 'shipped',
  CANCELLED = 'cancelled',
  DECLINED = 'declined',
  REFUNDED = 'refunded',
  DISPUTED = 'disputed',
  MANUAL_VERIFICATION_REQUIRED = 'manual_verification_required',
  PARTIALLY_REFUND = 'partially_refund'
}

export enum OrderRefundType {
  ORIGINAL_PAYMENT_METHOD = 'ORIGINAL_PAYMENT_METHOD',
  STORE_CREDIT = 'STORE_CREDIT'
}

export interface ChildOrderItem {
  id: number;
  code: number;
  availableQuantity: number;
  paidAt: string;
  quantity: number;
  status: string;
  tags: any;
  totalAmount: number;
}

export interface CombinedOrderDetail {
  id: number;
  code: number;
  orderCombinedOn: string;
  status: OrderStatus;
  isExpeditedShipping: boolean;
  shipmentIds: number[];
  labelUrls: string[];
  childOrders: {
    id: number;
    code: number;
    paidAt: Date;
    status: OrderStatus;
    totalAmount: number;
    tags: string[];
    quantity: number;
    availableQuantity: number;
  }[];
  shippingAddress: {
    id: number;
    type?: string;
    country?: string;
    state?: string;
    city?: string;
    pincode?: string;
    addressLine1?: string;
    receiverName?: string;
    phoneNumber?: string;
    shippoId?: string;
    isVerified?: boolean;
  };
  storeCredits: number;
  isPhoneNoVerified: boolean;
  thumbnailUrl: string;
  customer: string;
  customerId?: number;
  customerOrder?: number;
  customerEmail: string;
  customerPhoneNo: string;
  isEmailVerified: boolean;
}
