import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import clsx from 'clsx';

// Apollo
import { useMutation, useQuery } from '@apollo/client';
import { SEZZLE_CONNECTION_STATUS } from 'src/apollo/queries';
import { STRIPE_CONNECTION_STATUS } from 'src/apollo/queries/getStripeConnectionStatus';
import { TOGGLE_SEZZLE, SET_GET_SEZZLE_CONFIG } from 'src/apollo/mutations';

// Components
import { FlexBox, Loader } from 'src/components/atoms';
import { Tooltip } from 'src/components/molecules';
import StripeConnection from 'src/components/oraganisms/StripeConnection/StripeConnection';

// Hooks && Utils && Helpers
import { encrypt } from 'src/utils/encrypt/encrypt';
import useTitle from 'src/utils/hooks/useTitle';
import { useToast } from 'src/utils/hooks/useToast';

// Hooks && Utils && Helpers
import { BackRoundedIcon, FilledCheckCircle, GreenCheck, InfoIcon, SezzleIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables, ROUTES } from 'src/constants';

// Styles
import './Payments.scss';

const Payments: FC = () => {
  useTitle('Settings - Payments');
  const history = useHistory();
  const { showToast } = useToast();
  const [showModal] = useState(false);
  const [active, setActive] = useState(false);
  const [showError, setShowError] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [publicKey, setPublicKey] = useState<string>('');
  const [privateKey, setPrivateKey] = useState<string>('');
  const [firstTime, setFirstTime] = useState(true);
  const [isConnected, setIsConnected] = useState(false);

  const [setGetSezzleConfig] = useMutation(SET_GET_SEZZLE_CONFIG, {
    onCompleted: (data) => {
      if (data?.setGetSezzleConfig?.success) {
        setSuccessMessage(true);
        setShowError(false);
        showToast({
          successText: `<span style="color: #1e2749;">${constVariables.payments.sezzleIs}<span> <span style="color: #50cd89;">${constVariables.payments.enabled}</span>.`,
          message: constVariables.payments.modal.messageSuccess
        });
      }
    },
    onError: (e) => {
      console.error(e);
      setShowError(true);
    }
  });
  const [toggleSezzle] = useMutation(TOGGLE_SEZZLE, {
    onCompleted: (data) => {
      if (data?.toggleSezzle?.enable) {
        setActive(true);
        if (!firstTime) {
          showToast({
            successText: `<span style="color: #1e2749;">${constVariables.payments.sezzleIs}<span> <span style="color: #50cd89;">${constVariables.payments.enabled}</span>.`,
            message: constVariables.payments.modal.messageSuccess
          });
        }
      } else {
        setActive(false);
        if (!firstTime) {
          showToast({
            successText: `<span style="color: #1e2749;">Sezzle connection is<span> <span style="color: #f1416c;">failed</span>.`,
            message: constVariables.payments.modal.messageFailure
          });
        }
      }
      setFirstTime(false);
    },
    onError: (error) => {
      console.error(error);
    }
  });
  const { loading: loadingSezzle } = useQuery(SEZZLE_CONNECTION_STATUS, {
    onCompleted: (data) => {
      if (data?.getSezzleConnectionStatus?.connected) {
        setPrivateKey(data?.getSezzleConnectionStatus?.privateKey);
        setPublicKey(data?.getSezzleConnectionStatus?.publicKey);
        setIsConnected(data?.getSezzleConnectionStatus?.connected);
      }
    },
    onError: (error) => {
      console.error('SEZZLE_CONNECTION_STATUS error', error);
    }
  });
  const { loading: loadingStripe } = useQuery(STRIPE_CONNECTION_STATUS);
  const changePublicKey = (e) => {
    setPublicKey(e.target.value);
  };
  const changePrivateKey = (e) => {
    setPrivateKey(e.target.value);
  };
  const returnBack = () => {
    history.push(ROUTES.settings.main);
  };

  const toggle = () => {
    if (active) {
      toggleSezzle({
        variables: {
          input: {
            enable: false,
            operation: 'SET'
          }
        }
      });
    } else {
      toggleSezzle({
        variables: {
          input: {
            enable: true,
            operation: 'SET'
          }
        }
      });
    }
  };

  const sendKey = () => {
    setGetSezzleConfig({
      variables: {
        input: {
          operation: 'SET',
          publicKey: encrypt(publicKey),
          privateKey: encrypt(privateKey)
        }
      }
    });
  };

  useEffect(() => {
    toggleSezzle({
      variables: {
        input: {
          operation: 'GET',
          enable: true
        }
      }
    });
  }, []);

  return (
    <div className={`payments ${showModal ? 'overflow-hidden' : ''}`}>
      <div className="width-animation w-100">
        <div className="details-page">
          <div className="details-page-container">
            <FlexBox className="align-items-center m-y-40">
              <img src={BackRoundedIcon} alt={BackRoundedIcon} onClick={returnBack} className="cursor-pointer" />
              <h2 className="page-title px-4 mb-0">{constVariables.payments.key}</h2>
            </FlexBox>
            {loadingSezzle || loadingStripe ? (
              <Loader type="page" className="h-300px" />
            ) : (
              <div>
                <div className="main-detail-layout">
                  <div className="left-pane">
                    <StripeConnection />
                    <div className="card w-100 mb-0 mt-10">
                      <div>
                        <FlexBox className="align-items-center">
                          <img src={SezzleIcon} />
                          <img src={InfoIcon} className="px-3" data-tip data-for="paymentInfoIcon" />
                          <Tooltip
                            tooltipId="paymentInfoIcon"
                            className="toolTipPay"
                            place="top"
                            content={() => {
                              return (
                                <div>
                                  {constVariables.payments.hoverMessage}
                                  <a className="text-primary">{constVariables.payments.clickHere}</a>
                                  {constVariables.payments.moreInfo}
                                </div>
                              );
                            }}
                          />
                        </FlexBox>

                        <div>
                          {isConnected && (
                            <div className="my-5">
                              <img src={FilledCheckCircle} />
                              <span className="ms-2 text-success">{constVariables.payments.accountConnectedMessage}</span>
                            </div>
                          )}
                          <div className="form-check float-right form-switch form-switch-sm form-check-custom">
                            <input className="form-check-input" type="checkbox" checked={active} onChange={toggle} />
                          </div>
                          {successMessage ? (
                            <FlexBox className="poppins-medium fs-6 align-items-center mt-8">
                              <img className="h-18px w-18px" src={GreenCheck} alt="GreenCheck" />
                              <div className="text-success ms-2 ">{constVariables.payments.connectedMessage}</div>
                            </FlexBox>
                          ) : null}
                          {/* NOTE: commented Success message for Future */}
                          {/* <>
                                        <img src={Checked} />
                                        <span className="connected px-3">{constVariables.payments.connectedMessage}</span>
                                    </> */}
                          <p className="poppins-regular mt-5">{constVariables.payments.publicKey}</p>
                          <input
                            className="form-control"
                            disabled={!active}
                            value={publicKey}
                            onChange={changePublicKey}
                            aria-describedby="publicKey"
                            placeholder={constVariables.payments.enterPublicKey}
                          />
                          <p className="poppins-regular mt-5">{constVariables.payments.privateKey}</p>
                          <input
                            className="form-control"
                            disabled={!active}
                            value={privateKey}
                            onChange={changePrivateKey}
                            placeholder={constVariables.payments.enterPrivateKey}
                          />
                          {showError && (
                            <p className="poppins-regular mt-5 text-danger errorMsg">
                              {constVariables.payments.unableToConnectMessage}
                            </p>
                          )}
                          <button
                            className={clsx('btn mt-5', {
                              'btn-primary': active
                            })}
                            disabled={!active}
                            onClick={sendKey}
                          >
                            {constVariables.payments.updateBtn}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="right-pane">
                    <div className="card w-100 mb-0">
                      <h2 className="poppins-regular fs-3 m-auto-0 text-nowrap dark-color">
                        {constVariables.payments.sezzle.steps.title}
                      </h2>
                      <p className="mt-5 mediaGreyText">{constVariables.payments.stripe.steps.stepTitle}</p>
                      <div className="mx-2">
                        <p className="mediaGreyText">{constVariables.payments.stripe.steps.step1}</p>
                        <p className="mediaGreyText">{constVariables.payments.stripe.steps.step2}</p>
                      </div>

                      <p className="mt-5 mediaGreyText">{constVariables.payments.sezzle.steps.stepTitle}</p>
                      <div className="mx-2">
                        <p className="mt-5 mediaGreyText">
                          {constVariables.payments.sezzle.steps.step1}
                          <a
                            className="text-primary mediaGreyText"
                            href="https://dashboard.sezzle.com/merchant/signup"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {constVariables.payments.sezzle.steps.signUp}
                          </a>
                        </p>
                        <p className="mt-5 mediaGreyText">
                          {constVariables.payments.sezzle.steps.step2}
                          <a
                            className="text-primary mediaGreyText"
                            href="https://dashboard.sezzle.com/merchant/login/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {constVariables.payments.sezzeleDashboard}
                          </a>
                        </p>
                        <p className="mediaGreyText">{constVariables.payments.sezzle.steps.step3}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payments;
