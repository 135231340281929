import { gql } from '@apollo/client';

export const GET_LIVE_VIEWERS = gql`
  query GetViewerDetails($input: GetgetViewersDetailsInput!) @api(name: "liveStreamingAPI") {
    getViewersDetails(input: $input) {
      id
      firstName
      profileUrl
      amountSpent
      noOfCartItems
      isFirstTimeCustomer
      isVIPCustomer
      cartProgress
      isUserBlocked
      isLiveBlocked
      fetchedAt
    }
  }
`;
