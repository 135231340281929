import React, { FC } from 'react';

interface TableCellProps {
  children?: React.ReactNode;
  isStaticColumn?: boolean;
  columnWidth?: number;
}

const TableCell: FC<TableCellProps> = ({ children, isStaticColumn, columnWidth }) => {
  return (
    <div
      className={`ReactVirtualized__Table__rowColumn ${isStaticColumn ? 'fixed-width-column' : ''}`}
      style={{ overflow: 'hidden', flex: `0 1 ${columnWidth}px`, minWidth: `${columnWidth ? columnWidth : 150}px` }}
    >
      {children}
    </div>
  );
};

export default TableCell;
