import { FC } from 'react';
import clsx from 'clsx';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { SlideCloseIcon, CarouselPrevIcon, CarouselNextIcon } from 'src/assets/icons';

interface ImagePreviewSlideModalProps {
  show: boolean;
  currentSlide: number;
  totalProductImage: Array<any>;
  handleCloseModal: () => void;
}

const ImagePreviewSlideModal: FC<ImagePreviewSlideModalProps> = ({ show, currentSlide, totalProductImage, handleCloseModal }) => {
  return (
    <div
      className={clsx('modal fade opacity-100', {
        show
      })}
      aria-modal="true"
      tabIndex={-1}
      id="kt_modal_1"
    >
      <button className="closeIcon cursor-pointer" onClick={handleCloseModal}>
        <KTSVG path={SlideCloseIcon} />
      </button>
      <div className="image-preview-modal-content">
        <Carousel
          selectedItem={currentSlide}
          infiniteLoop
          showIndicators={false}
          showStatus={false}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <button type="button" className="carousel-arrow-icon prev-icon" onClick={onClickHandler}>
                <KTSVG path={CarouselPrevIcon} className="cursor-pointer" />
              </button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button type="button" className="carousel-arrow-icon next-icon" onClick={onClickHandler}>
                <KTSVG path={CarouselNextIcon} className="cursor-pointer" />
              </button>
            )
          }
        >
          {totalProductImage.map((item, index) => (
            <img src={item?.fileimage ? item?.fileimage : item.productMediaUrl} alt={`title-${index}`} key={index} />
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default ImagePreviewSlideModal;
