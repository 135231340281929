import { gql } from '@apollo/client';

export const EDIT_PRODUCT = gql`
  mutation EditProduct($input: EditProductInput!) @api(name: "productsAPI") {
    editProduct(input: $input) {
      id
      success
      message
    }
  }
`;
