import { FC, useState, createRef } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { FlexBox } from 'src/components/atoms';
import { Dropdown, Portal } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { DownArrowIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

interface StatusCellProps {
  id: number;
  status: string;
  updateStatus: (id: number, name: string) => void;
}

const StatusCell: FC<StatusCellProps> = ({ id, status, updateStatus }) => {
  const statusRef = createRef<any>();
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const [dropdownPositions, setDropdownPositions] = useState({
    top: 0,
    left: 0
  });

  const selectProductStatusClass = (catalogStatus: string | undefined) => {
    switch (catalogStatus) {
      case 'active':
        return 'active';
      case 'inactive':
        return 'inactive';
      default:
        return '';
    }
  };

  const toggleText = (data: string) => {
    if (data === 'ACTIVE') {
      return 'Active';
    } else if (data === 'INACTIVE') {
      return 'Inactive';
    } else if (data === 'SCHEDULED') {
      return 'Scheduled';
    }
    return '';
  };

  const handleShowDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsShowDropdown(true);

    if (statusRef.current) {
      const position = statusRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - position.bottom;
      setDropdownPositions({
        top: spaceBelow < 150 ? window.scrollY + position.top - 115 : window.scrollY + position.top + 35,
        left: position.left - 70
      });
    }
  };

  return (
    <>
      <FlexBox
        className={`p-2 poppins-medium cursor-pointer justify-content-between ${selectProductStatusClass(
          status?.toLowerCase()
        )}-status-box`}
        onClick={handleShowDropdown}
      >
        <div className="d-flex align-items-center justify-content-between w-100" ref={statusRef}>
          <span>{toggleText(status)}</span>
          <KTSVG path={DownArrowIcon} className={`m-auto-0 ${selectProductStatusClass(status?.toLocaleLowerCase())}-arrow`} />
        </div>
      </FlexBox>
      {isShowDropdown && (
        <Portal id="kt_body">
          <OutsideClickHandler
            onOutsideClick={() => {
              if (isShowDropdown) setIsShowDropdown(false);
            }}
          >
            <div className="position-absolute" style={{ top: dropdownPositions.top, left: dropdownPositions.left }}>
              <Dropdown
                data={constVariables.catalogStatusDropDownData}
                selected={isShowDropdown}
                value={toggleText(status)}
                onSelect={(data) => {
                  setIsShowDropdown(false);
                  updateStatus(id, data.name);
                }}
                closeDropdown={() => setIsShowDropdown(false)}
              />
            </div>
          </OutsideClickHandler>
        </Portal>
      )}
    </>
  );
};

export default StatusCell;
