import { gql } from '@apollo/client';

export const GET_PRODUCTS_FROM_COLLECTION = gql`
  query GetProductsFromCollection($input: CollectionGetInput!) @api(name: "authAPI") {
    getProductsFromCollection(input: $input) {
      enableCollection
      productIds
    }
  }
`;
