import React, { useEffect } from 'react';
import clsx from 'clsx';
import { CloseIcon } from 'src/assets/icons';
import { KTSVG } from 'src/helpers';

interface CountDownModalProps {
  handleCloseTimerModal: () => void;
  counter: number;
}

const CountDownModal: React.FC<CountDownModalProps> = ({ handleCloseTimerModal, counter }) => {
  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27 || e.keyCode === 88 || e.key === 'Escape') {
        handleCloseTimerModal();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  return (
    <div className={clsx('modal-content countdown-timer-div')}>
      <div className="discardbutton">
        <div className="btn btn-icon btn-sm" onClick={handleCloseTimerModal} data-bs-dismiss="modal" aria-label="Close">
          <KTSVG path={CloseIcon} className="svg-icon svg-icon-2x" />
        </div>
      </div>
      <div className="countdown-header">
        <p>Liveshow begins in</p>
      </div>
      <div className="countdown-timer">
        <p>{counter}</p>
      </div>
      <div className="countdown-detail">
        <p>Press ESC or ‘X’ to stop going live</p>
      </div>
    </div>
  );
};

export default CountDownModal;
