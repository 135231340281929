import { GeneralInfo } from 'src/components/templates/GeneralInfoSection/GeneralInfoSection.types';

export interface ErrorsInfo {
  generalInfoTitleError: string;
  statusError: string;
}
export interface CatalogTypeType {
  value: 'MANUAL' | 'AUTOMATIC' | undefined;
}

export interface ProductsCategoryType {
  value: 'MANUAL' | 'AUTOMATIC' | undefined;
}
export interface CatalogStatusType {
  value: 'ACTIVE' | 'INACTIVE' | 'SCHEDULED' | undefined;
}
export interface CatalogProductListType {
  autoMaticProductListIds: number[];
  manualProductListIds: number[];
  selectedProductsIds: number[];
}

export interface GeneralInfoSection {
  generalInfo: GeneralInfo;
  setGeneralInfo: React.Dispatch<React.SetStateAction<GeneralInfo>>;
}
export interface CatalogTypeSection {
  selectedProductAssignmentMethod?: productAssignmentMethod;
  selectProductAssignmentMethod: (productAssignmentType: productAssignmentMethod) => void;
}
export interface ProductsCategoryTypeSection {
  productCategory: ProductsCategoryType;
  setProductCategory: React.Dispatch<React.SetStateAction<ProductsCategoryType>>;
  productCategoryInput?: string;
  setProductCategoryInput?: React.Dispatch<React.SetStateAction<string>>;
  setFormChanges?: React.Dispatch<React.SetStateAction<boolean>>;
}
export interface CatalogStatusTypeSection {
  catalogStatus: CatalogStatusType;
  errorMessages: ErrorsInfo;
  setCatalogStatus: React.Dispatch<React.SetStateAction<CatalogStatusType>>;
  setFormChanges?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface CatalogProductListTypes {
  isAutoCatalog: boolean;
  hasConditions: boolean;
  productListData: CatalogProductListType;
  setProductListData: React.Dispatch<React.SetStateAction<CatalogProductListType>>;
}
export interface CatalogProductListTypeSection {
  productListData: CatalogProductListType;
  setProductListData: React.Dispatch<React.SetStateAction<CatalogProductListType>>;
}
export interface AddCatalogPresentationalProps
  extends CatalogStatusTypeSection,
    CatalogTypeSection,
    ProductsCategoryTypeSection,
    CatalogProductListTypeSection,
    GeneralInfoSection {
  formChanges: boolean;
  isPageLoading: boolean;
  errorMessages: ErrorsInfo;
  thumbnailUrlData: any;
  setThumbnailUrlData: React.Dispatch<React.SetStateAction<any>>;
  goBackToCatalogScreen: () => void;
  deleteCatalog: (id: string) => void;
  disabledDelBtn: boolean;
  catalogId: string | undefined;

  // Rules engine
  selectedProductAssignmentMethod?: productAssignmentMethod;
  allConditionMatch: boolean;
  setAllConditionMatch: React.Dispatch<React.SetStateAction<boolean>>;
  selectProductAssignmentMethod: (productAssignmentType: productAssignmentMethod) => void;
  rulesEngineConditions: {
    field: { id: number; name?: string };
    condition: string;
    category: string | { name: string; id: number }[];
    errorMessage?: string;
    openFieldDropdown?: boolean;
    openCategoryDropdown?: boolean;
    openConditionDropdown?: boolean;
  }[];
  setRulesEngineConditions: React.Dispatch<
    React.SetStateAction<
      {
        field: { id: number; name?: string };
        condition: string;
        category: string | { name: string; id: number }[];
        errorMessage?: string;
        openFieldDropdown?: boolean;
        openCategoryDropdown?: boolean;
        openConditionDropdown?: boolean;
      }[]
    >
  >;
  fieldDropdown: {
    id: number;
    name: string;
    operations: { name: string }[];
  }[];
  conditionDropdownArray: {
    categoryDropdown: { id: number; name: string }[];
  }[];
  setConditionDropdownArray: React.Dispatch<
    React.SetStateAction<
      {
        categoryDropdown: {
          name: string;
          id: number;
        }[];
      }[]
    >
  >;
  setFormChanges: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchText?: React.Dispatch<React.SetStateAction<string>>;
  rulesChanges: boolean;
  setRulesChanges: React.Dispatch<React.SetStateAction<boolean>>;
  saveModuleRules: () => void;
  // Rules engine
}

// Rules engine

export type FieldConditionType = {
  id: number;
  name: string;
  operations: { name: string }[];
};

export type ConditionType = {
  field: {
    id: number;
    name?: string;
  };
  condition: string;
  category: string | { name: string; id: number }[];
  errorMessage?: string;
  openFieldDropdown?: boolean;
  openConditionDropdown?: boolean;
  openCategoryDropdown?: boolean;
};

export type JoinConditionType = {
  conditionId?: number;
  operation?: string;
  value?: string;
  field?: {
    id: number;
    name?: string;
  };
  condition?: string;
  category?: string | { name: string; id: number }[];
  openFieldDropdown?: boolean;
  openConditionDropdown?: boolean;
  openCategoryDropdown?: boolean;
};

export enum CatalogueStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SCHEDULED = 'SCHEDULED'
}

export enum productAssignmentMethod {
  MANUAL = 'MANUAL',
  AUTOMATIC = 'AUTOMATIC'
}

// Rules engine
