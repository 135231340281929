import { gql } from '@apollo/client';

export const GET_CHAT_TOKEN = gql`
  query GET_CHAT_TOKEN @api(name: "liveStreamingAPI") {
    getChatToken {
      token
      sessionExpirationTime
      tokenExpirationTime
    }
  }
`;
