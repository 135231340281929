import { gql } from '@apollo/client';

export const GET_TAGS_OR_COLLECTIONS_OR_CATALOGS = gql`
  query GetTagsOrCollectionsOrCatalogs($input: TagsOrCollectionInput!) @api(name: "authAPI") {
    getTagsOrCollectionsOrCatalogs(input: $input) {
      __typename
      ... on TagsOrCollection {
        id
        name
        imageUrl
        productIds
        textColorCode
        backgroundColorCode
        productAssignmentType
        productIds
      }
    }
  }
`;
