import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { NodeModel, Tree } from '@minoru/react-dnd-treeview';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_COLLECTIONS } from 'src/apollo/queries';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { Loader } from 'src/components/atoms';
import { Checkbox, Tooltip } from 'src/components/molecules';

// Icons
import { arrowRight, arrowUp, folderBlue } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { CollectionProps, CustomData, CustomNodeProps } from './OrganizationSectionCollections.types';
import { UserRoles } from 'src/components/pages/settings/UserPermissions/UserPermissions.type';

// Styles
import './_organizationSectionCollections.scss';

const OrganizationSectionCollections: FC<CollectionProps> = ({
  addOrRemoveCollectionListItem,
  hideCheckboxes,
  collectionIdList,
  selectedOrganization,
  isCreateCollection
}) => {
  const [treeData, setTreeData] = useState<NodeModel[]>();
  const [allFoldersExpanded, setAllFoldersExpanded] = useState<boolean>(false);
  const treeRef = useRef<any>(null);

  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  const handleDrop = () => null;

  const isDisableCheckbox = useMemo(() => {
    if (!isCreateCollection && role === UserRoles.FULFILMENT_ASSOCIATE) return true;
    return false;
  }, [isCreateCollection]);

  // Get Collection Data
  const { loading: isLoading } = useQuery(GET_COLLECTIONS, {
    variables: {
      input: {
        setDroppableByStatus: false
      }
    },
    onCompleted: (data) => {
      if (data) {
        const TempCollections = data?.getCollections?.collections?.map((x: any) => {
          const children = data?.getCollections?.collections?.filter((item) => x.id === item.parent);
          if (children.length > 0) {
            return { ...x, hasChildren: true, text: x.name, droppable: true };
          }
          return { ...x, hasChildren: false, text: x.name, droppable: true };
        });
        setTreeData(TempCollections);
      }
    }
  });

  useEffect(() => {
    if (isCreateCollection) {
      if (treeRef && treeRef.current) {
        setAllFoldersExpanded(true);
        treeRef.current.openAll();
      }
    }
  }, [isCreateCollection, treeData]);

  const expandAll = () => {
    if (treeRef && treeRef.current) {
      if (allFoldersExpanded) {
        setAllFoldersExpanded(false);
        treeRef.current.closeAll();
      } else {
        setAllFoldersExpanded(true);
        treeRef.current.openAll();
      }
    }
  };

  const Folders: React.FC<CustomNodeProps> = (props) => {
    const { id, type, hasChildren } = props.node;
    const numericId: number = +id;

    const indent = props.depth * 24;

    const handleToggle = (e: React.MouseEvent) => {
      if (type === 'AUTOMATIC') return;
      e.stopPropagation();
      props.onToggle(props.node.id);
    };
    const handleCheckBox = () => {
      if (addOrRemoveCollectionListItem) {
        addOrRemoveCollectionListItem(numericId);
      }
    };

    const renderFolder = () => {
      return (
        <div
          className={`d-flex py-2 ${selectedOrganization === props.node.text ? 'selectedBackgroudOrg' : ''} tree-item ${
            !isCreateCollection && type === 'AUTOMATIC' ? 'auto-item' : 'manual-item'
          } `}
          style={{ paddingInlineStart: indent }}
        >
          <div className={`openFolderWrapper ${props.isOpen ? 'openFolderWrapperIsOpen' : ''}`}>
            {hasChildren && (
              <div onClick={handleToggle} className="d-flex align-items-center">
                <img className="h-12px w-12px " src={arrowRight} />
              </div>
            )}
          </div>

          {!hideCheckboxes && (
            <div className="d-flex align-items-center">
              <Checkbox
                value={collectionIdList?.includes(numericId)}
                onChangeValue={handleCheckBox}
                labelclassname="h-20px me-3"
                disable={type === 'AUTOMATIC' || isDisableCheckbox}
              />
            </div>
          )}

          <div className="d-flex align-items-center justify-content-center">
            <img className="h-22px w-22px " src={folderBlue} />
          </div>
          <div className={`d-flex align-items-center ${selectedOrganization === props.node.text ? 'text-primary' : ''} `}>
            <div className="poppins-regular node-text">{props.node.text}</div>
          </div>
        </div>
      );
    };

    return (
      <>
        {!isCreateCollection && type === 'AUTOMATIC' ? (
          <>
            <div data-tip data-for="automaticCollection">
              {renderFolder()}
            </div>
            <Tooltip
              tooltipId="automaticCollection"
              place="top"
              content={() => {
                return <>Automatic collection</>;
              }}
            />
          </>
        ) : (
          <>{renderFolder()}</>
        )}
      </>
    );
  };

  return (
    <div className="collections-section m-t-32">
      <div className="text-nowrap input-title">Collections</div>
      <div className="w-100 overflow-scroll collections-tree">
        {isLoading ? (
          <Loader type="page" className="h-100" />
        ) : (
          treeData && (
            <div>
              <div onClick={expandAll} className="expandAll cursor-pointer">
                <img src={allFoldersExpanded ? arrowUp : arrowRight} className="expandArrow" />
                <span className="poppins-regular">
                  {allFoldersExpanded ? constVariables.collections.collapseAll : constVariables.collections.expandAll}
                </span>
              </div>

              <Tree
                ref={treeRef}
                tree={treeData}
                rootId={0}
                render={(node: NodeModel<CustomData> | any, { depth, isOpen, onToggle }) => (
                  <Folders node={node} depth={depth} isOpen={isOpen} onToggle={onToggle} />
                )}
                onDrop={handleDrop}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default OrganizationSectionCollections;
