import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

// Apollo
import { useMutation, useQuery } from '@apollo/client';
import { GET_AVALARA_CONFIGURATION } from 'src/apollo/queries';
import { CONNECT_AVALARA_CONFIGURATION, UPDATE_AVALARA_ACCOUNT_SETTINGS } from 'src/apollo/mutations';

// Components
import TaxesPresentational from './Taxes.presentation';

// Hooks && Utils && Helpers
import { encrypt } from 'src/utils/encrypt/encrypt';
import useTitle from 'src/utils/hooks/useTitle';

// ConstVariables
import { ROUTES } from 'src/constants';

// Types
import { Modals } from '../../products/AddProduct/AddProduct.types';
import { IAvalaraCompany } from './Taxes.types';

const Taxes: FC = () => {
  useTitle('Settings - Taxes');
  const [accountId, setAccountId] = useState('');
  const [licenseKey, setLicenseKey] = useState('');
  const [isSandbox, setIsSandbox] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCompanyDropdown, setShowCompanyDropdown] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [accountError, setAccountError] = useState(false);
  const [errorMessageArray, setErrorMessage] = useState<string[]>(['']);
  const [targettedPath, setTargettedPath] = useState<string>(); // taking this to set clicked path
  const [modal, setModal] = useState<Modals>({
    discardModal: false,
    leaveModal: false,
    saveModal: false
  });
  const [formChanges, setFormChanges] = useState<boolean>(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  const [isAvalaraEnabled, setIsAvalaraEnabled] = useState<boolean>(false);
  const [isRecordingEnabled, setIsRecordingEnabled] = useState<boolean>(false);
  const [shippingCode, setShippingCode] = useState('');
  const [productCode, setProductCode] = useState('');
  const [selectedCompany, setSelectedCompany] = useState<IAvalaraCompany>();

  const { data, loading } = useQuery(GET_AVALARA_CONFIGURATION, {
    onCompleted({
      getAvalaraConfiguration: {
        isAvalaraEnabled,
        isConnected,
        allowDocumentRecording,
        defaultShippingTaxCode,
        defaultProductTaxCode,
        companyCode,
        companyName
      }
    }) {
      if (isConnected) {
        setIsAvalaraEnabled(isAvalaraEnabled);
        setIsRecordingEnabled(allowDocumentRecording);
        setShippingCode(defaultShippingTaxCode);
        setProductCode(defaultProductTaxCode);
        setSelectedCompany({
          id: companyCode,
          name: companyName
        });
      }
    }
  });

  const [connectAvalaraAccount, { loading: isConnectingAvalara }] = useMutation(CONNECT_AVALARA_CONFIGURATION, {
    onCompleted: () => {
      setAccountError(false);
    },
    onError: () => {
      setAccountError(true);
    }
  });

  const [saveAvalaraAccount, { loading: isSavingAvalaraSettings }] = useMutation(UPDATE_AVALARA_ACCOUNT_SETTINGS, {
    onCompleted: () => {
      setAccountError(false);
      setDisableSaveButton(false);
    },
    onError: () => {
      setAccountError(true);
      setDisableSaveButton(false);
    }
  });

  const onSelectCompany = (data) => {
    setSelectedCompany(data);
    setShowCompanyDropdown(false);
  };

  const saveAvalaraSettings = () => {
    saveAvalaraAccount({
      variables: {
        input: {
          isAvalaraEnabled,
          defaultProductTaxCode: productCode,
          defaultShippingTaxCode: shippingCode,
          allowDocumentRecording: isRecordingEnabled,
          companyCode: selectedCompany?.id
        }
      }
    });
    setDisableSaveButton(true);
  };

  const history = useHistory();

  const returnBack = () => {
    history.push(ROUTES.settings.main);
  };

  useEffect(() => {
    if (
      data?.getAvalaraConfiguration?.isConnected &&
      (productCode.trim() !== data?.getAvalaraConfiguration?.defaultProductTaxCode ||
        shippingCode.trim() !== data?.getAvalaraConfiguration?.defaultShippingTaxCode ||
        selectedCompany?.id !== data?.getAvalaraConfiguration?.companyCode ||
        isAvalaraEnabled !== data?.getAvalaraConfiguration?.isAvalaraEnabled ||
        isRecordingEnabled !== data?.getAvalaraConfiguration?.allowDocumentRecording)
    ) {
      setFormChanges(true);
    } else {
      setFormChanges(false);
    }
  }, [productCode, shippingCode, selectedCompany, isAvalaraEnabled, isRecordingEnabled]);

  useEffect(() => {
    const preventRoute = history.block((e: any) => {
      setTargettedPath(e.pathname);
      if (formChanges) {
        setModal((prev) => ({ ...prev, leaveModal: true }));
        return false;
      } else {
        return true;
      }
    });

    return () => {
      preventRoute();
    };
  }, [history, formChanges]);

  const onConnectAvalaraAccount = () => {
    if (accountId && licenseKey) {
      setAccountError(false);
      connectAvalaraAccount({
        variables: {
          input: {
            accountId,
            licenseKey: encrypt(licenseKey),
            isSandbox
          }
        }
      });
    } else {
      addUserNameErrorMessage();
      setShowErrorModal(true);
    }
  };

  const addErrorMessage = () => {
    const arr: string[] = [];
    if (!accountId && !data?.getAvalaraConfiguration?.isConnected) {
      arr.push('Please enter Username');
    }
    if (!licenseKey && !data?.getAvalaraConfiguration?.isConnected) {
      arr.push('Please enter password');
    }
    if (!selectedCompany) {
      arr.push('Please select company');
    }
    if (!productCode) {
      arr.push('Please enter Product Tax Code');
    }
    if (!shippingCode) {
      arr.push('Please enter Shipping tax code');
    }
    setErrorMessage([...arr]);
  };

  const addTaxErrorMessage = () => {
    const arr: string[] = [];
    if (!selectedCompany) {
      arr.push('Please select company');
    }
    if (!productCode) {
      arr.push('Please enter Product Tax Code');
    }
    setErrorMessage([...arr]);
  };

  const addShippingErrorMessage = () => {
    const arr: string[] = [];
    if (!selectedCompany) {
      arr.push('Please select company');
    }
    if (!shippingCode) {
      arr.push('Please enter Shipping Tax Code');
    }
    setErrorMessage([...arr]);
  };

  const addUserNameErrorMessage = () => {
    const arr: string[] = [];
    if (!accountId && !data?.getAvalaraConfiguration?.isConnected) {
      arr.push('Please enter Username');
    }
    if (!licenseKey && !data?.getAvalaraConfiguration?.isConnected) {
      arr.push('Please enter password');
    }
    setErrorMessage([...arr]);
  };

  const discard = () => {
    setModal((prev) => ({ ...prev, discardModal: true }));
  };

  const leavePageHandler = () => {
    setFormChanges(false);
    // NOTE: Push to targetted path or products, handled with timeout for now.
    setTimeout(() => {
      history.push(targettedPath ? targettedPath : '/settings/taxes');
    }, 100);
  };

  const onDiscardHandler = (state: string) => {
    if (state === 'discard') {
      setModal((prev) => ({ ...prev, discardModal: !modal.discardModal }));
      setFormChanges(false);
      setTimeout(() => {
        history.push('/settings');
      }, 100);
    }
    if (state === 'saveError') {
      setModal((prev) => ({ ...prev, saveModal: !modal.saveModal }));
    }
    if (state === 'leave') {
      setModal((prev) => ({ ...prev, leaveModal: !modal.leaveModal }));
    }
    if (state === 'close') {
      setModal({
        discardModal: false,
        leaveModal: false,
        saveModal: false
      });
    }
  };

  return (
    <TaxesPresentational
      accountId={accountId}
      setAccountId={setAccountId}
      licenseKey={licenseKey}
      setLicenseKey={setLicenseKey}
      showPassword={showPassword}
      setShowPassword={setShowPassword}
      isSandbox={isSandbox}
      setIsSandbox={setIsSandbox}
      shippingCode={shippingCode}
      setShippingCode={setShippingCode}
      productCode={productCode}
      setProductCode={setProductCode}
      showCompanyDropdown={showCompanyDropdown}
      setShowCompanyDropdown={setShowCompanyDropdown}
      onSelectCompany={onSelectCompany}
      connectAvalaraAccount={onConnectAvalaraAccount}
      showErrorModal={showErrorModal}
      setShowErrorModal={setShowErrorModal}
      discard={discard}
      leavePageHandler={leavePageHandler}
      modal={modal}
      setModal={setModal}
      onDiscardHandler={onDiscardHandler}
      returnBack={returnBack}
      formChanges={formChanges}
      errorMessageArray={errorMessageArray}
      setErrorMessageArray={setErrorMessage}
      addErrorMessage={addErrorMessage}
      saveAvalaraSettings={saveAvalaraSettings}
      accountError={accountError}
      avalaraAccount={data?.getAvalaraConfiguration}
      isLoading={loading}
      disableSaveButton={disableSaveButton}
      isConnectingAvalara={isConnectingAvalara}
      isAvalaraEnabled={isAvalaraEnabled}
      setIsAvalaraEnabled={setIsAvalaraEnabled}
      isRecordingEnabled={isRecordingEnabled}
      setIsRecordingEnabled={setIsRecordingEnabled}
      selectedCompany={selectedCompany}
    />
  );
};

export default Taxes;
