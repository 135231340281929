import { FC, useMemo, useState, createRef, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_TIKTOK_CATEGORIES } from 'src/apollo/queries';

// Components
import { DropdownSelector } from 'src/components/atoms';
import { Dropdown, Portal } from 'src/components/molecules';

// Types
import { TikTokProduct, TIKTOK_PRODUCT_STATUS, TiktokCategoryOptionItem } from '../TiktokIntegration.types';

interface CategoryCellProps {
  data: TikTokProduct;
  updateCategory: (productId: number, categoryId: string) => void;
}

const CategoryCell: FC<CategoryCellProps> = ({ data, updateCategory }) => {
  const dropdownRef = createRef<any>();
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [dropdownPositions, setDropdownPositions] = useState({
    top: 0,
    left: 0
  });
  const [categories, setCategories] = useState<TiktokCategoryOptionItem[]>([]);
  const [filteredCategories, setFilteredCategories] = useState<TiktokCategoryOptionItem[]>([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  useQuery(GET_TIKTOK_CATEGORIES, {
    onCompleted: (res) => {
      setCategories(
        res?.getCategories?.map((category) => {
          return { ...category, name: `${category.l2} - ${category.l3}` };
        })
      );
    },
    onError: (error) => {
      console.log('error::', error);
    }
  });

  useEffect(() => {
    if (!data?.category) {
      setSelectedCategory('');
    } else {
      // setSelectedCategory(`${data?.category?.l1} - ${data?.category?.l2} - ${data?.category?.l3}`);
      setSelectedCategory(`${data?.category?.l2} - ${data?.category?.l3}`);
    }
  }, [data?.category]);

  useEffect(() => {
    if (searchText !== '') {
      const newCategories = categories?.filter((category) => category?.name?.toLowerCase()?.includes(searchText.toLowerCase()));
      setFilteredCategories(newCategories);
    } else {
      setFilteredCategories(categories);
    }
  }, [searchText, categories]);

  const closeDropdown = () => {
    setShowDropdown(false);
    setShowSearchInput(false);
  };

  const disabledDropdown = useMemo(() => {
    if (data.status === TIKTOK_PRODUCT_STATUS.PROCESSING) return true;
    return false;
  }, [data.status]);

  const handleShowDropdown = () => {
    setShowSearchInput(true);
    setShowDropdown(true);

    if (dropdownRef.current) {
      const position = dropdownRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - position.bottom;
      setDropdownPositions({
        top: spaceBelow < 190 ? window.scrollY + position.top - 234 : window.scrollY + position.top - 5,
        left: position.left
      });
    }
  };

  const handleChangeCategory = (option) => {
    closeDropdown();
    setSearchText('');
    setSelectedCategory(`${option?.l2} - ${option?.l3}`);
    updateCategory(data?.id, option.id);
  };

  return (
    <>
      <div className="tiktok-category">
        {data.status === TIKTOK_PRODUCT_STATUS.PROCESSING ||
        data.status === TIKTOK_PRODUCT_STATUS.UPDATES_REQUIRED ||
        data.status === TIKTOK_PRODUCT_STATUS.DRAFT ? (
          <div
            className={`position-relative ${disabledDropdown ? 'disable-background' : ''} ${
              showDropdown ? 'dropdown-box-active' : ''
            } ${!selectedCategory && !disabledDropdown ? 'dropdown-box-error' : ''}`}
          >
            <div className="d-flex position-relative input-group px-3" ref={dropdownRef} onClick={handleShowDropdown}>
              <DropdownSelector selectedValue={selectedCategory} text="Please select a category" />
              {showSearchInput ? (
                <input
                  autoComplete="off"
                  className="h-100 position-absolute px-3 m-0"
                  autoFocus
                  type="text"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              ) : null}
            </div>
          </div>
        ) : (
          <div>{selectedCategory}</div>
        )}
      </div>
      {showDropdown && (
        <Portal id="kt_body">
          <OutsideClickHandler onOutsideClick={closeDropdown}>
            <div className="position-absolute" style={{ top: dropdownPositions.top, left: dropdownPositions.left, width: 180 }}>
              <Dropdown
                className="tiktok-category-dropdown"
                data={filteredCategories}
                selected={showDropdown}
                onSelect={handleChangeCategory}
                closeDropdown={closeDropdown}
              />
            </div>
          </OutsideClickHandler>
        </Portal>
      )}
    </>
  );
};

export default CategoryCell;
