import { gql } from '@apollo/client';

export const GET_USER_PROFILE = gql`
  query getUserProfile @api(name: "productsAPI") {
    getUserProfile {
      firstName
      lastName
      profileURL
      email
    }
  }
`;
export const GET_BS_USER_PROFILE = gql`
  query getBackstoreUserProfile($userId: Int) @api(name: "productsAPI") {
    getBackstoreUserProfile(userId: $userId) {
      firstName
      lastName
      profileURL
      email
    }
  }
`;
export const SAVE_USER_PROFILE = gql`
  mutation saveUserProfile($input: SaveUserProfileInput) @api(name: "productsAPI") {
    saveUserProfile(input: $input) {
      profileURL
      email
    }
  }
`;
