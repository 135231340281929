import { FC } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { Link } from 'react-router-dom';

// Components
import { FlexBox } from 'src/components/atoms';
import { FixedHeaderTable, Divider } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { CloseIcon } from 'src/assets/icons';

// Types
import { CombinedChildOrdersDropdownProps } from './OrderDetailsBlockFulfillmentCenter.types';
import { TablesProps } from '../Table/Table.types';

const CombinedChildOrdersDropdown: FC<CombinedChildOrdersDropdownProps> = ({ isOpen, closeDropdown, loading, childOrders }) => {
  let tableData: TablesProps = {};
  tableData = {
    headerClassName: 'position-relative ',
    isDragDisabled: true,
    bodyClass: 'w-100 no-top-border mh-300px',
    className: 'rounded-top w-100 scrollable-table-wrapper',
    headers: [
      {
        id: 1,
        className: 'py-0 ps-4 pe-0 align-middle table-header-background',
        colName: 'Order'
      },
      {
        id: 2,
        className: 'py-0 align-middle table-header-background',
        colName: 'Date'
      },
      {
        id: 3,
        className: 'ps-3 py-0 align-middle table-header-background',
        colName: 'Items'
      }
    ],
    rows: childOrders?.map((item, index) => ({
      id: item.id,
      isDragDisabled: true,
      className: 'position-relative border-top-0 dontScrollBulkEditorTop',
      cells: [
        {
          className: `align-middle ps-4 pe-0 py-0 ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: (
            <Link to={`/orders/orderDetails/${item.id}`} className="order-code" target="_blank">
              #{item.code}
            </Link>
          )
        },
        {
          className: `align-middle text-muted py-0 align-middle ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: moment(item.paidAt).format('D MMM YYYY, hh:mm')
        },
        {
          className: `align-middle text-muted ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: <div>{item.quantity}</div>
        }
      ]
    }))
  };

  return (
    <div
      className={clsx('mt-3 menu menu-sub menu-background menu-sub-dropdown position-absolute manifest-dropdown', {
        show: isOpen
      })}
      data-kt-menu="true"
      style={{ top: 20, left: 0 }}
    >
      <FlexBox className="align-items-center justify-content-between px-5 py-6">
        <h4 className="m-0 poppins-medium">Orders</h4>
        <KTSVG path={CloseIcon} className="cursor-pointer" onClick={closeDropdown} />
      </FlexBox>
      <Divider />
      <div className="d-flex align-items-center justify-content-center px-5 py-4">
        <FixedHeaderTable
          headers={tableData.headers}
          rows={tableData.rows}
          loadingPosition="center"
          isLoading={loading}
          containerHeight={300}
          type="order"
        />
      </div>
    </div>
  );
};

export default CombinedChildOrdersDropdown;
