import { gql } from '@apollo/client';

export const GET_COMBINED_ORDER_DETAIL = gql`
  query getCombinedOrderDetail($orderId: Int!) @api(name: "ordersAPI") {
    getCombineOrderDetail(orderId: $orderId) {
      id
      code
      orderCombinedOn
      status
      isExpeditedShipping
      shipmentIds
      labelUrls
      childOrders {
        id
        code
        paidAt
        status
        totalAmount
        tags
        quantity
        availableQuantity
      }
      shippingAddress {
        id
        receiverName
        addressLine1
        city
        state
        country
        pincode
        type
        isPrimary
        phoneNumber
        isVerified
      }
      customer
      customerId
      customerEmail
      storeCredits
      customerPhoneNo
      isEmailVerified
      isPhoneNoVerified
      thumbnailUrl
    }
  }
`;
