import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';

// Components
import { RadioButton } from 'src/components/atoms';
import { Divider } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// ConstVariables
import { constVariables } from 'src/constants';

// Icons
import { CloseIcon, InfoIcon } from 'src/assets/icons';

// Types
import { DeleteProductFromOrderProps } from 'src/components/oraganisms/CustomModal/Modal.types';
import { RefundType } from 'src/components/pages/orders/OrderDetails/OrderDetails.types';

// Styles
import 'src/components/oraganisms/CustomModal/modalStyles.scss';
import '../../pages/orders/OrderDetails/OrderDetails.scss';

const DeleteProductFromOrderModal: FC<DeleteProductFromOrderProps> = ({
  title,
  onlyOneItem,
  actionBtnTitleReplacement,
  cancelBtnTitle,
  actionBtnTitle,
  deleteHandler,
  replaceHandler,
  cancelBtnHandler,
  actionBtnTitleDelete,
  cancelOrder,
  disableRefundBtn,
  isOptions,
  show
}) => {
  const [replaceItemCheck, setReplaceItemCheck] = useState(false);
  const [deleteItemCheck, setDeleteItemCheck] = useState(false);
  const [refundOriginalCheck, setRefundOriginalCheck] = useState(false);
  const [refundStoreCheck, setRefundStoreCheck] = useState(false);

  useEffect(() => {
    refreshStates();
  }, [show]);

  const refreshStates = () => {
    setReplaceItemCheck(false);
    setDeleteItemCheck(false);
    setRefundOriginalCheck(false);
    setRefundStoreCheck(false);
  };

  const closeModalHandler = () => {
    refreshStates();
    cancelBtnHandler();
  };

  const toggleReplaceItemCheck = () => {
    if (replaceItemCheck) {
      setReplaceItemCheck(false);
    } else {
      setReplaceItemCheck(true);
      setDeleteItemCheck(false);
    }
  };
  const toggleDeleteItemCheck = () => {
    if (deleteItemCheck) {
      setDeleteItemCheck(false);
    } else {
      setDeleteItemCheck(true);
      setReplaceItemCheck(false);
      setRefundOriginalCheck(false);
      setRefundStoreCheck(false);
    }
  };

  const toggleRefundOriginalCheck = () => {
    setRefundOriginalCheck(!refundOriginalCheck);
    setRefundStoreCheck(false);
  };

  const toggleRefundStoreCreditCheck = () => {
    setRefundStoreCheck(!refundStoreCheck);
    setRefundOriginalCheck(false);
  };

  const actionBtnHandler = () => {
    if (replaceItemCheck) {
      replaceHandler && replaceHandler();
    } else if (refundStoreCheck) {
      deleteHandler && deleteHandler(RefundType.STORE_CREDIT);
    } else if (refundOriginalCheck) {
      deleteHandler && deleteHandler(RefundType.ORIGINAL_PAYMENT_METHOD);
    }
  };

  const ActionButtonTitleChange = () => {
    if (replaceItemCheck) {
      return actionBtnTitleReplacement;
    } else if (refundStoreCheck || refundOriginalCheck) {
      return actionBtnTitleDelete;
    } else {
      return actionBtnTitle;
    }
  };

  return (
    <div
      className={clsx('modal-content cancelOrder default-modal', {
        'modal-large-content': cancelOrder === true
      })}
    >
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <div className="btn btn-xs btn-active-light-primary p-0 m-0 border-none" onClick={closeModalHandler}>
          <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
        </div>
      </div>
      <div className={clsx('modal-body p-x-30 p-y-36')}>
        <div className="d-flex align-items-center w-100 m-b-8">
          <RadioButton
            id="replaceItemCheck"
            name="replaceItemCheck"
            isChecked={replaceItemCheck}
            handleChange={toggleReplaceItemCheck}
            noMargin
          />
          <label className="mb-0 ms-2">{constVariables.orderDetails.orderProductModal.option1.title}</label>
        </div>

        <div className="d-flex align-items-center ml-3 w-100">
          <p className="ms-5 opacity-50 mb-0">{constVariables.orderDetails.orderProductModal.option1.message}</p>
        </div>

        <Divider classname="divider-status m-y-24" />

        <div className="d-flex align-items-center w-100 m-b-8">
          <RadioButton
            id="deleteItemCheck"
            name="deleteItemCheck"
            isChecked={deleteItemCheck}
            handleChange={toggleDeleteItemCheck}
            noMargin
          />
          <label className="mb-0 ms-2">{constVariables.orderDetails.orderProductModal.option2.title}</label>
        </div>

        <div className="d-flex align-items-center ml-3 w-100">
          <p className="ms-5 opacity-50 mb-0">{constVariables.orderDetails.orderProductModal.option2.message}</p>
        </div>

        {deleteItemCheck && (
          <div className="m-t-16">
            <div className="form-check cursor-pointer ps-6 d-flex">
              <input
                type="radio"
                name="orderRefundOriginal"
                id="options"
                checked={refundOriginalCheck}
                onChange={toggleRefundOriginalCheck}
                className="m-0"
              />
              <span>
                <span className="px-2 poppins-semibold">
                  {constVariables.orderDetails.orderProductModal.option2.messageTwo}
                  <span>
                    <KTSVG path={InfoIcon} />
                  </span>
                </span>
              </span>
            </div>
            <div className="form-check cursor-pointer ps-6 d-flex">
              <input
                type="radio"
                name="orderRefundOriginal"
                id="options"
                checked={refundStoreCheck}
                onChange={toggleRefundStoreCreditCheck}
                className="m-0"
              />
              <span>
                <span className="px-2 poppins-semibold">
                  {constVariables.orderDetails.orderProductModal.option2.messageThree}
                  <span>
                    <KTSVG path={InfoIcon} />
                  </span>
                </span>
              </span>
            </div>
          </div>
        )}
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-outlined-secondary btn-md" data-bs-dismiss="modal" onClick={closeModalHandler}>
          {cancelBtnTitle}
        </button>
        <button
          type="button"
          className={`btn btn-md ${
            // eslint-disable-next-line no-nested-ternary
            replaceItemCheck ? 'btn-primary' : refundStoreCheck || refundOriginalCheck ? 'btn-danger' : ''
          }`}
          disabled={!replaceItemCheck && !refundStoreCheck && !refundOriginalCheck}
          onClick={actionBtnHandler}
        >
          {ActionButtonTitleChange()}
        </button>
      </div>
    </div>
  );
};

export default DeleteProductFromOrderModal;
