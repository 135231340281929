import { gql } from '@apollo/client';

export const EDIT_COUPON = gql`
  mutation EditCoupon($input: CouponInput!) @api(name: "productsAPI") {
    editCoupon(input: $input) {
      id
      success
      message
    }
  }
`;
