import React, { useCallback } from 'react';
import { CellProps } from 'react-datasheet-grid';

const PercentFormatCell = React.memo(({ rowData, setRowData, columnData, active, focus }: CellProps) => {
  const handleChange = (e) => {
    const value = e.target.value;
    const regex = /^\d*(\.\d{0,2})?$/;
    if (focus && (value === '' || regex.test(value))) {
      if (columnData.key === 'margin') {
        const margin = parseFloat(value);
        const profit = (margin * rowData?.perItemCost) / 100;
        const salePrice = profit + rowData?.perItemCost;
        const updatedRowdata = { ...rowData, [columnData.key]: value, profit, salePrice };
        setRowData(updatedRowdata);
      }
    }
  };

  const callbackRef = useCallback(
    (inputElement) => {
      if (inputElement && focus) {
        setTimeout(() => {
          inputElement.focus();
          inputElement.select();
        }, 100);
      }
    },
    [focus]
  );

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  };

  return (
    <input
      style={{ pointerEvents: focus ? 'auto' : 'none' }}
      autoFocus={focus ? true : false}
      tabIndex={-1}
      className="dsg-input"
      value={!focus ? `${rowData[columnData.key]}%` : rowData[columnData.key]}
      ref={callbackRef}
      onKeyDown={handleKeyPress}
      pattern="^\d*(\.\d{0,2})?$"
      type="text"
      onChange={(e) => {
        e.target.validity.valid && handleChange(e);
      }}
    />
  );
});

export default PercentFormatCell;
