import { FC, useState, createRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import { Dropdown, Portal } from 'src/components/molecules';
import { CustomModal, DeleteConfirmModal } from 'src/components/oraganisms';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { getPosition } from 'src/utils/getPosition';
import useWindowDimensions from 'src/utils/UseWindowDimensions/useWindowDimensions';
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import { DefaultImage, ThreeDotsIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

const LiveshowProductLineItem: FC<any> = ({
  product,
  selectedProduct,
  setSelectedProduct,
  handleDeleteProduct,
  visitedProductIds
}) => {
  const menuIcon = createRef<any>();
  const { width } = useWindowDimensions();
  const [showDropDown, setShowDropDown] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const [menuPositions, setMenuPositions] = useState({
    top: 0,
    left: 0
  });

  const closeDropDown = () => {
    setShowDropDown(false);
  };

  const openDropDown = (e) => {
    e.stopPropagation();
    setShowDropDown(true);

    if (menuIcon.current) {
      const position = menuIcon.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - position.bottom;
      const tempTopPosition = spaceBelow < 150 ? window.scrollY + position.top - 80 : window.scrollY + position.top + 30;
      const { topPosition, leftPosition } = getPosition(width, tempTopPosition, position.left, 10);
      setMenuPositions({
        top: topPosition,
        left: leftPosition
      });
    }
  };

  const selectingMenuItem = (data) => {
    if (data.name === 'Remove product') {
      setShowDeleteConfirm(true);
    }
    closeDropDown();
  };

  const getItemClassName = useMemo(() => {
    if (selectedProduct?.productId === product?.productId) {
      return 'active';
    }
    if (product?.isLowStockTriggred) {
      return 'low-stock';
    }
    if (visitedProductIds.includes(product?.productId)) {
      return 'visited';
    }
    return '';
  }, [product?.isComplete, product?.productId, product?.isLowStockTriggred, selectedProduct]);

  const handleRemoveProduct = () => {
    handleDeleteProduct(product.id.toString());
    closeConfirmModal();
  };

  const closeConfirmModal = () => {
    document.body.style.overflow = 'unset';
    setShowDeleteConfirm(false);
  };

  return (
    <>
      <div
        className={`product-list-product-detail display-flex align-items-center position-relative cursor-pointer ${getItemClassName}`}
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
      >
        <div
          className="d-flex align-items-center w-100"
          onClick={() => {
            setSelectedProduct(product);
          }}
        >
          <FlexBox className="left-section align-items-center">
            <div className="product-image-container">
              <Img
                src={setImageSrc(product?.imageUrl ? generateCompressedImageURL(product.imageUrl, '50') : DefaultImage)}
                placeholderImg={DefaultImage}
                errorImg={DefaultImage}
                width={44}
                height={44}
              />
            </div>
            <FlexBox className="product-name-price-container flex-column align-items-start justify-content-center">
              <Link to={`/products/allProducts/edit/${product.productId}`} target="_blank" onClick={(e) => e.stopPropagation()}>
                <span className="product-name poppins-medium">{product.name}</span>
              </Link>
              <FlexBox className="align-items-center">
                <span className="product-price">${product.salePrice}</span>
                <div className="product-reference-id">
                  <span>#{product.productReferenceId}</span>
                </div>
              </FlexBox>
            </FlexBox>
          </FlexBox>
          <FlexBox className="right-section align-items-center">
            <div className="value">
              <span>{product.quantity}</span>
            </div>
            <div className="value">
              <span>{product.noOfOrders}</span>
            </div>
            <div className="value">
              <span>{product.noOfOrdersPaid}</span>
            </div>
            <div className="value">
              <span>${product.revenue.toFixed(2)}</span>
            </div>
          </FlexBox>
        </div>
        <span ref={menuIcon} className="live-product-item-more position-absolute">
          <KTSVG onClick={openDropDown} className={`cursor-pointer ${visible ? '' : 'hidden'}`} path={ThreeDotsIcon} />
        </span>
        {showDropDown && (
          <Portal id="kt_body">
            <OutsideClickHandler onOutsideClick={closeDropDown}>
              <div className="position-absolute" style={{ top: `${menuPositions.top}rem`, left: `${menuPositions.left}rem` }}>
                <Dropdown
                  data={constVariables.liveShowDashboardProductListDropdownData}
                  onSelect={selectingMenuItem}
                  selected={showDropDown}
                  className="live-product-menu-dropdown"
                  noScroll={true}
                  closeDropdown={() => setShowDropDown(false)}
                />
              </div>
            </OutsideClickHandler>
          </Portal>
        )}
      </div>

      {showDeleteConfirm && (
        <CustomModal bodyClassname="w-90 w-md-150" show={showDeleteConfirm} closeModal={closeConfirmModal}>
          <DeleteConfirmModal
            title={constVariables.LiveShow.deleteProduct.title}
            message={constVariables.LiveShow.deleteProduct.message}
            actionBtnTitle="Yes, Delete"
            cancelBtnTitle="Cancel"
            cancelBtnHandler={closeConfirmModal}
            actionBtnHandler={handleRemoveProduct}
          ></DeleteConfirmModal>
        </CustomModal>
      )}
    </>
  );
};

export default LiveshowProductLineItem;
