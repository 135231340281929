import { FC, useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { DropdownSelector } from 'src/components/atoms';
import { Dropdown } from 'src/components/molecules';

// ConstVariables
import { constVariables } from 'src/constants/constVariables';

// Types
import { CatalogStatusTypeSection } from 'src/components/pages/catalogs/AddCatalog/AddCatalog.types';

const CatalogStatus: FC<CatalogStatusTypeSection> = ({ catalogStatus, setCatalogStatus, setFormChanges, errorMessages }) => {
  const [isCatalogDropdown, setIsCatalogDropdown] = useState(false);
  const [selectedValue, setSelectedValue] = useState('Select');
  useEffect(() => {
    if (catalogStatus.value === 'ACTIVE') {
      setSelectedValue('Active');
    } else if (catalogStatus.value === 'INACTIVE') {
      setSelectedValue('Inactive');
    } else if (catalogStatus.value === 'SCHEDULED') {
      setSelectedValue('Scheduled');
    } else {
      setSelectedValue('Select');
    }
    // if (setFormChanges) {
    //   setFormChanges(true);
    // }
  }, [catalogStatus]);

  const openStatusDropdown = () => {
    setIsCatalogDropdown(true);
  };
  const closeStatusDropdown = () => {
    setIsCatalogDropdown(false);
  };

  const selectStatus = (data: { id: number; name: string }) => {
    closeStatusDropdown();
    if (data.name.toUpperCase() === 'ACTIVE') {
      setCatalogStatus({ value: 'ACTIVE' });
    } else if (data.name.toUpperCase() === 'INACTIVE') {
      setCatalogStatus({ value: 'INACTIVE' });
    } else if (data.name.toUpperCase() === 'SCHEDULED') {
      setCatalogStatus({ value: 'SCHEDULED' });
    }
    if (setFormChanges) {
      setFormChanges(true);
    }
  };

  return (
    <div className="card w-100">
      <h4 className="section-title m-b-24">{constVariables.Catalogs.addCatalog.catalogStatusTitle}</h4>
      <div className="input-title">{constVariables.Catalogs.addCatalog.catalogStatusInputTitle}</div>
      <div className="position-relative">
        <DropdownSelector
          className={`form-control form-control-lg  px-5 justify-content-between align-items-center cursor-pointer dropdown-box ${
            isCatalogDropdown ? 'dropdown-box-active' : ''
          }`}
          onClick={openStatusDropdown}
          selectedValue={selectedValue}
          text="Select"
          isShowColor={true}
        />
        <OutsideClickHandler onOutsideClick={closeStatusDropdown}>
          <Dropdown
            className="dropdown-custom-width mt-1"
            data={constVariables.catalogStatusDropDownData}
            selected={isCatalogDropdown}
            value={selectedValue}
            onSelect={selectStatus}
            closeDropdown={() => setIsCatalogDropdown(false)}
          />
        </OutsideClickHandler>
      </div>
      {errorMessages?.statusError && <p className="warning-text p-0 fs-7 m-t-8 mb-0">{errorMessages?.statusError}</p>}
    </div>
  );
};

export default CatalogStatus;
