import { useState, useEffect, FC } from 'react';
import { useHistory } from 'react-router';

// Components
import { CatalogList, PageHeader } from 'src/components/oraganisms';

// ConstVariables
import { constVariables, ROUTES } from 'src/constants';

// Types
import { CatalogPresentationalProps } from './CatalogPage.type';
import { ColumnType } from 'src/components/molecules/Table/Table.types';

// Styles
import './_catalogpage.scss';

const initialCatalogTableColumns: ColumnType[] = [
  { name: 'Products', status: true },
  { name: 'Catalog Type', status: true },
  { name: 'Status', status: true },
  { name: 'Link', status: true }
];

const CatalogPagePresentational: FC<CatalogPresentationalProps> = ({
  selectedCatalogIDs,
  setSelectedCatalogIDs,
  catalogListData,
  onDeleteCatalog,
  onToggleCatalog,
  catalogsToDelete,
  setCatalogsToDelete,
  isLoading,
  pageInfo,
  setPageInfo,
  totalCount
}) => {
  const history = useHistory();
  const [openEditColumnDropdown, setOpenEditColumnDropdown] = useState(false);
  const [editColumnData, setEditColumnData] = useState<ColumnType[]>([]);

  useEffect(() => {
    const tableColumns = localStorage.getItem('catalogTable');
    if (tableColumns) {
      setEditColumnData(JSON.parse(tableColumns));
    } else {
      setEditColumnData(initialCatalogTableColumns);
    }
  }, []);

  const gotoAddCatalog = () => {
    history.push(ROUTES.marketing.catalogs.addCatalog);
  };

  return (
    <div className="catalogs allCatalogPage p-40" id="tablePage">
      <div className={`width-animation w-100 d-flex flex-column hide-scrollbar`}>
        <PageHeader
          pageTitle={constVariables.Catalogs.catalogs}
          addButton={constVariables.Catalogs.createCatalogs}
          onAddButton={gotoAddCatalog}
          removeExportImport
          isEditFields={true}
          editColumnData={editColumnData}
          setEditColumnData={setEditColumnData}
          openEditColumnDropdown={openEditColumnDropdown}
          setOpenEditColumnDropdown={setOpenEditColumnDropdown}
        />
      </div>
      <div className="card w-100 p-0 mb-0">
        <CatalogList
          selectedCatalogIDs={selectedCatalogIDs}
          setSelectedCatalogIDs={setSelectedCatalogIDs}
          catalogList={catalogListData}
          onDeleteCatalog={onDeleteCatalog}
          onToggleCatalog={onToggleCatalog}
          catalogsToDelete={catalogsToDelete}
          setCatalogsToDelete={setCatalogsToDelete}
          isLoading={isLoading}
          editColumnData={editColumnData}
          pageInfo={pageInfo}
          setPageInfo={setPageInfo}
          totalCount={totalCount}
        />
      </div>
      {/* <div className="pb-5">
        <FlexBox>                    
          <div className="col-sm-12 col-md-4 col-lg-4">
            <div className="card w-100 p-7">
              <div className="mb-6 poppins-regular">
                <h2 className="poppins-regular fs-3 m-auto-0 text-nowrap dark-color">
                  {constVariables.Catalogs.information.title}
                </h2>
                <p className="mt-5 mediaGreyText">{constVariables.Catalogs.information.steps}</p>
              </div>
            </div>
          </div>
        </FlexBox>
      </div> */}
    </div>
  );
};

export default CatalogPagePresentational;
