import { FC, useEffect, useState } from 'react';

// Components
import { RadioButton } from 'src/components/atoms';
import { Divider, InputBox } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { InfoIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants/constVariables';

// Types
import { ProductsCategoryTypeSection } from 'src/components/pages/catalogs/AddCatalog/AddCatalog.types';

// Styles
import './_catalogProductCategory.scss';

const ProductCategory: FC<ProductsCategoryTypeSection> = ({
  productCategory,
  setProductCategory,
  productCategoryInput,
  setProductCategoryInput
}) => {
  const [isAutomatic, setIsAutomatic] = useState(false);
  const [isManual, setIsManual] = useState(false);
  useEffect(() => {
    if (productCategory.value === 'MANUAL') {
      setIsManual(true);
      setIsAutomatic(false);
    } else if (productCategory.value === 'AUTOMATIC') {
      setIsAutomatic(true);
      setIsManual(false);
    }
  }, [productCategory]);

  const handelManualRadioButton = (data: any) => {
    if (data.target.checked) {
      setProductCategory({ value: 'MANUAL' });
    }
  };
  const handelAutomaticRadioButton = (data: any) => {
    if (data.target.checked) {
      setProductCategory({ value: 'AUTOMATIC' });
    }
  };

  const gotoCataloglink = () => {
    window.open('https://www.google.com/basepages/producttype/taxonomy-with-ids.en-US.txt');
  };

  return (
    <div className="card w-100">
      <h4 className="section-title m-b-32">{constVariables.Catalogs.addCatalog.productAssignmentMethodTitle}</h4>

      <div className="w-100">
        <div className="d-flex align-items-center">
          <RadioButton
            id="allConditions"
            name="productCatAutomatic"
            isChecked={isAutomatic}
            handleChange={handelAutomaticRadioButton}
            noMargin
          />
          <div className="ms-2 opacity-80 fw-normal">{constVariables.Catalogs.addCatalog.productAssignmentMethod.automatic}</div>
        </div>

        <div className="info_radiobutton  poppins-regular fs-8 ms-6 m-t-8">
          {constVariables.Catalogs.addCatalog.productCategoryAutomaticInfo}
        </div>
      </div>
      <Divider classname="divider-status m-y-24" />
      <div className="">
        <div className="d-flex align-items-center">
          <RadioButton
            id="anyConditions"
            name="productCatManual"
            isChecked={isManual}
            handleChange={handelManualRadioButton}
            noMargin
          />
          <div className="ms-2 opacity-80 fw-normal">
            {constVariables.Catalogs.addCatalog.productAssignmentMethod.isManual}

            <span className="ms-2">
              <KTSVG path={InfoIcon} />
            </span>
          </div>
        </div>

        <div className="info_radiobutton poppins-regular fs-8 ms-6 m-t-8">
          <span className="auto_link text-primary cursor-pointer" onClick={gotoCataloglink}>
            {constVariables.Catalogs.addCatalog.productCategoryManualInfoClick}
          </span>

          <span className="info_radiobutton"> {constVariables.Catalogs.addCatalog.productCategoryManualInfoClickMessage}</span>
        </div>
        {/* <div className="mt-6 w-100 ">
                    <input className="w-100 bg-transparent border py-2 px-4 fs-6" placeholder="Product category"></input>
                </div> */}
        {isManual ? (
          <InputBox
            inputClass="lightPlaceholderText form-control-lg"
            mainclass="my-4"
            value={productCategoryInput}
            onChangeText={(e) => setProductCategoryInput && setProductCategoryInput(e)}
            name={constVariables.Catalogs.addCatalog.productCategoryInputPlaceholder}
            placeholder={constVariables.Catalogs.addCatalog.productCategoryInputPlaceholder}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ProductCategory;
