import React, { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { debounce } from 'lodash';

// Apollo
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_USER_FAVORITE_LIST, GET_SHOPIFY_ADMIN_BASE_URL } from 'src/apollo/queries';

// Components
import { Tabs, SearchBar, FixedHeaderTable, ShopifySyncTooltip } from 'src/components/molecules';
import { FlexBox, Img } from 'src/components/atoms';
import { EmptyList } from 'src/components/oraganisms';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import { DefaultImage, NoProductIcon, ShopifyIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { TablesProps, RowObjectProps } from 'src/components/molecules/Table/Table.types';
import { FavoriteProductType } from './CustomerDetails.types';

interface queryResult {
  getUserFavouriteList: {
    count: {
      wishlist: number | undefined;
      waitlist: number | undefined;
      waitlistAuth: number | undefined;
    };
    products: any;
  };
}

const CustomerFavorites: FC = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const tableData: TablesProps = {
    isDragDisabled: true,
    className: 'overflow-visible',
    headerClassName: 'table-dark-strip'
  };

  const [selectedTab, setSelectedTab] = useState('WISHLIST');
  const [totalCount, setTotalCount] = useState(0);
  const [favoriteTypeCountList, setFavoriteTypeCountList] = useState<any>({
    wishlist: 0,
    waitlist: 0,
    waitlistAuth: 0
  });
  const [searchText, setSearchText] = useState<string>('');
  const [favoriteProducts, setFavoriteProducts] = useState<FavoriteProductType[]>([]);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  const { data: { shopifyAdminBaseUrl } = { shopifyAdminBaseUrl: null } } = useQuery(GET_SHOPIFY_ADMIN_BASE_URL);

  const [getUserFavoriteList, { loading: isLoading, data, fetchMore }] = useLazyQuery(GET_USER_FAVORITE_LIST, {
    variables: {
      input: {
        userId: parseInt(customerId, 10),
        searchString: searchText,
        type: selectedTab === constVariables.TabForFavorites[2].toUpperCase() ? 'WAITLIST_AUTH' : selectedTab.toUpperCase(),
        pageInfo: {
          skipCount: 0,
          limitCount: 6
        }
      }
    },
    onCompleted: (response) => {
      if (response?.getUserFavouriteList) {
        setFavoriteTypeCountList({
          [constVariables.TabForFavorites[0]]: response?.getUserFavouriteList?.count.wishlist
            ? response?.getUserFavouriteList?.count.wishlist
            : 0,
          [constVariables.TabForFavorites[1]]: response?.getUserFavouriteList?.count.waitlist
            ? response?.getUserFavouriteList?.count.waitlist
            : 0,
          [constVariables.TabForFavorites[2]]: response?.getUserFavouriteList?.count.waitlistAuth
            ? response?.getUserFavouriteList?.count.waitlistAuth
            : 0
        });
        setFavoriteProducts(response?.getUserFavouriteList?.products);
        if (selectedTab === constVariables.TabForFavorites[0].toUpperCase()) {
          setTotalCount(response?.getUserFavouriteList?.count.wishlist ? response?.getUserFavouriteList?.count.wishlist : 0);
        } else if (selectedTab === constVariables.TabForFavorites[1].toUpperCase()) {
          setTotalCount(response?.getUserFavouriteList?.count.waitlist ? response?.getUserFavouriteList?.count.waitlist : 0);
        } else {
          setTotalCount(
            response?.getUserFavouriteList?.count.waitlistAuth ? response?.getUserFavouriteList?.count.waitlistAuth : 0
          );
        }
      }
    }
  });

  useEffect(() => {
    if (totalCount === data?.getUserFavouriteList?.products?.length) {
      setIsLoadingMore(false);
    }
  }, [data]);

  useEffect(() => {
    getUserFavoriteList();
  }, [customerId, searchText, selectedTab]);

  const handleSearchFavoriteOrders = (value) => {
    setSearchText(value);
  };

  const goToShopifyPage = (shopifyProductId) => {
    if (shopifyAdminBaseUrl) window.open(`${shopifyAdminBaseUrl}products/${shopifyProductId}`, '_blank');
  };

  tableData.headers = [
    {
      id: 1,
      colName: 'Product',
      sorting: true,
      category: 'Product',
      className: 'align-middle ps-3 table-header-background'
    }
  ];

  const arr: Array<RowObjectProps> = [];
  favoriteProducts?.map((product, index) => {
    arr.push({
      className: `position-relative h-fit-content table-row cursor-pointer`,
      id: product.id,
      cells: [
        {
          className: `align-middle ps-2 text-muted ps-3 ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: (
            <FlexBox className="align-items-center">
              <div className="symbol me-5">
                <Img
                  src={setImageSrc(product?.imageUrl ? generateCompressedImageURL(product?.imageUrl, '50') : DefaultImage)}
                  placeholderImg={DefaultImage}
                  errorImg={DefaultImage}
                  className="object-fit-scale-down bg-white border border-light border-2"
                />
                {product?.shopifyId && (
                  <>
                    <img
                      src={ShopifyIcon}
                      className="cursor-pointer ms-1 shopify-icon position-absolute"
                      data-tip
                      data-for="shopifySyncIcon"
                      onClick={() => goToShopifyPage(product?.shopifyId)}
                    />
                    <ShopifySyncTooltip tooltipText="Product from Shopify" />
                  </>
                )}
              </div>
              <FlexBox className="justify-content-start flex-column">
                <Link
                  to={{
                    pathname: `/products/allProducts/edit/${product?.id}`,
                    state: { from: `/customers/customerDetails/${customerId}` }
                  }}
                  className="main-cell"
                >
                  <span className="d-block">{product.name}</span>
                </Link>
              </FlexBox>
            </FlexBox>
          )
        }
      ]
    });
    return null;
  });
  tableData.rows = arr;

  const currentType = (selectedTab: string | undefined) => {
    switch (selectedTab) {
      case 'WISHLIST':
        return 'wishlist';
      case 'WAITLIST':
        return 'waitlist';
      default:
        return 'waitlist authorized';
    }
  };

  const handleTableScroll = async (event: React.UIEvent<HTMLTableElement>) => {
    debouncedScroll(event);
  };

  const debouncedScroll = debounce(async (event) => {
    const scrollPosition = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const skipCount = data?.getUserFavouriteList?.products?.length;
    if (scrollHeight - scrollPosition === 489) {
      if (totalCount <= data?.getUserFavouriteList?.products?.length) {
        setIsLoadingMore(false);
        return;
      }
      setIsLoadingMore(true);
      await fetchMore({
        variables: {
          input: {
            userId: parseInt(customerId, 10),
            searchString: searchText,
            type: selectedTab === constVariables.TabForFavorites[2].toUpperCase() ? 'WAITLIST_AUTH' : selectedTab.toUpperCase(),
            pageInfo: {
              limitCount: 6,
              skipCount
            }
          }
        },
        updateQuery: (previousQueryResult: queryResult, { fetchMoreResult }) => {
          if (fetchMoreResult?.getUserFavouriteList.products.length === 0) {
            return previousQueryResult;
          }

          return {
            getUserFavouriteList: {
              count: fetchMoreResult?.getUserFavouriteList.count,
              products: [...previousQueryResult.getUserFavouriteList.products, ...fetchMoreResult?.getUserFavouriteList.products]
            }
          };
        }
      });
    }
  }, 1000);

  return (
    <div className="card w-100 mb-0">
      <h4 className="section-title mb-0">Favorites</h4>
      <div className="d-flex align-items-top w-100 justify-content-between m-t-24">
        <Tabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabs={constVariables.TabForFavorites}
          showCount={true}
          countValue={favoriteTypeCountList}
        />
      </div>
      <div className="w-400px m-t-24">
        <SearchBar placeholder={constVariables.AllOrdersMenu.searchForFavoriteOrders} onChange={handleSearchFavoriteOrders} />
      </div>
      {!isLoading && totalCount === 0 && !searchText && (
        <>
          <EmptyList datatype="product" status={currentType(selectedTab)} icon={NoProductIcon} isPage={false} />
        </>
      )}
      <div className={`m-t-24 table-wrapper ${totalCount === 0 && !searchText ? 'display-none' : ''}`}>
        <FixedHeaderTable
          headers={tableData.headers}
          sortByType={tableData.sortByType}
          sortByCategory={tableData.sortByCategory}
          rows={tableData.rows}
          type="Product"
          isLoading={isLoading || isLoadingMore}
          loadingPosition={isLoadingMore ? 'bottom' : 'center'}
          searchText={searchText}
          onScroll={handleTableScroll}
          containerHeight={500}
          noMargin
        />
      </div>
      {/* <div
        className={`mt-5 pt-3 h-500px ${deviceName.isWeb ? '' : 'overflow-scroll'} ${
          totalCount === 0 && !searchText ? 'display-none' : ''
        } `}
      >
        <Table
          rows={tableData.rows}
          headerClassName={tableData.headerClassName}
          headers={tableData.headers}
          sortByType={tableData.sortByType}
          sortByCategory={tableData.sortByCategory}
          isDragDisabled={tableData.isDragDisabled}
          type="Product"
          // className={`${!deviceName.isWeb && tableData?.rows?.length ? 'vw-87' : ''}`}
          isLoading={isLoading || isLoadingMore}
          loadingPosition={isLoadingMore ? 'bottom' : 'center'}
          hideHeader={totalCount === 0 && !searchText}
          searchText={searchText}
          onScroll={handleTableScroll}
        />
      </div> */}
    </div>
  );
};

export default CustomerFavorites;
