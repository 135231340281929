import { gql } from '@apollo/client';

export const GET_SHIPPING_RATE = gql`
  query GetShippingRates($input: GetShippingRatesInput!) @api(name: "ordersAPI") {
    getShippingRates(input: $input) {
      objectId
      amount
      amountLocal
      attributes
      currency
      currencyLocal
      provider
      providerImage75
      providerImage200
      estimatedDays
      arrivesBy
      durationTerms
      zone
    }
  }
`;
