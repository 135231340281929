import { gql } from '@apollo/client';

export const STRIPE_CONNECTION_STATUS = gql`
  query GetStripeStatus @api(name: "authAPI") {
    getStripeStatus {
      __typename
      isConnected
    }
  }
`;
