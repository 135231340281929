import { gql } from '@apollo/client';

export const ADD_PRODUCT = gql`
  mutation AddProduct($input: AddProductInput!) @api(name: "productsAPI") {
    addProduct(input: $input) {
      id
      success
      message
      additionalData
    }
  }
`;
