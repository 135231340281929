import React, { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router';
import clsx from 'clsx';
import { useLayout } from './core';
import { DrawerComponent } from '../assets/ts/components';
import { ROUTES } from 'src/constants/routes';

const Content: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const { classes } = useLayout();
  const location = useLocation();
  useEffect(() => {
    DrawerComponent.hideAll();
  }, [location]);

  return (
    <div
      id="kt_content_container"
      className={clsx(
        'override-content',
        'position-relative',
        classes.contentContainer.join(' ')
        // { 'pe-0': location?.pathname?.includes('/product') },
        // {
        //   'px-0':
        //     location?.pathname?.includes(ROUTES.products.allProducts.addProduct) ||
        //     location?.pathname?.includes(ROUTES.products.allProducts.editProduct) ||
        //     location?.pathname?.includes(ROUTES.products.productCategory.collections.createCollection) ||
        //     location?.pathname?.includes(ROUTES.settings.return.main) ||
        //     location?.pathname?.includes(ROUTES.products.productCategory.collections.createCollection) ||
        //     location?.pathname?.includes(ROUTES.products.productCategory.collections.editCollections) ||
        //     location?.pathname?.includes(ROUTES.orders.allOrders.orderDetails) ||
        //     location?.pathname?.includes(ROUTES.settings.taxes.main) ||
        //     location?.pathname?.includes(ROUTES.coupons.createCoupon.main) ||
        //     location?.pathname?.includes(ROUTES.settings.shopDetails.main) ||
        //     location?.pathname?.includes(ROUTES.marketing.catalogs.editCatalog) ||
        //     location?.pathname?.includes(ROUTES.settings.appCustomizer.main)
        // }
      )}
    >
      {children}
    </div>
  );
};

export { Content };
