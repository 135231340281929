import { gql } from '@apollo/client';

export const DUMP_CART = gql`
  mutation DumpCart($input: DumpCartInput!) @api(name: "liveStreamingAPI") {
    dumpCart(input: $input) {
      id
      success
      message
    }
  }
`;
