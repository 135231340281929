import { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Apollo
import { useQuery, useMutation } from '@apollo/client';
import { GET_SHOPIFY_ADMIN_BASE_URL } from 'src/apollo/queries';
import { DELETE_COLLECTION } from 'src/apollo/queries';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import { ShopifySyncTooltip, DataTable } from 'src/components/molecules';
import { CustomModal, DeleteConfirmModal } from 'src/components/oraganisms';
import StatusCell from './components/StatusCell';
import ActionCell from './components/ActionCell';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';
import toTree from 'src/utils/makeTreeData';

// Icons
import { DefaultImage, RightArrowIcon, ShopifyIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { NodeModel } from 'src/components/molecules/TableTree/types';

interface CollectionTableProps {
  collectionData: NodeModel[];
  isLoading: boolean;
  showAllDetails: boolean;
  searchText: string;
}

const CollectionTable: FC<CollectionTableProps> = ({ collectionData, isLoading, showAllDetails, searchText }) => {
  const [collections, setCollections] = useState<NodeModel[]>(collectionData);
  const [treeData, setTreeData] = useState<any[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [deletingCollectionId, setDeletingCollectionId] = useState<string | number>();
  const [deletingCollectionName, setDeletingCollectionName] = useState<string>();

  const { data: { shopifyAdminBaseUrl } = { shopifyAdminBaseUrl: null } } = useQuery(GET_SHOPIFY_ADMIN_BASE_URL);

  const getTreeData = (data) => {
    const treeData = toTree(
      data.map((collection) => {
        return {
          id: collection.id,
          parent: collection.parent,
          droppable: collection.droppable,
          text: collection.name,
          thumbnailUrl: collection.thumbnailUrl ? collection.thumbnailUrl : '',
          status: collection.status ? collection.status : '',
          type: collection.type,
          productCount: collection.productCount ? collection.productCount : 0,
          externalCollectionProvider: collection?.externalCollectionProvider ? collection?.externalCollectionProvider : '',
          externalCollectionId: collection?.externalCollectionId ? collection?.externalCollectionId : undefined
        };
      })
    );

    return treeData;
  };

  const [deleteCollection] = useMutation(DELETE_COLLECTION);

  useEffect(() => {
    if (collectionData) {
      setCollections(collectionData);
      const treeCollections = getTreeData(collectionData);
      setTreeData(treeCollections);
    }
  }, [collectionData]);

  const handleUpdateTreeData = (updatedCollection) => {
    const updatedCollections = collections?.map((collection) => {
      if (collection.id === updatedCollection.id) {
        return { ...collection, status: updatedCollection.status };
      } else {
        return collection;
      }
    });

    setCollections(updatedCollections);

    const updatedTreeData = getTreeData(updatedCollections);
    setTreeData(updatedTreeData);
  };

  const removeCollection = (collectionId) => {
    const updatedCollections = collections?.filter((collection) => collection?.id !== collectionId);
    setCollections(updatedCollections);

    const updatedTreeData = getTreeData(updatedCollections);
    setTreeData(updatedTreeData);
  };

  const expandCollection = (index: number) => {
    if (index === selectedIndex) {
      setSelectedIndex(-1);
    } else {
      setSelectedIndex(index);
    }
  };

  const goToShopifyPage = (shopifyCollectionId) => {
    if (shopifyAdminBaseUrl) window.open(`${shopifyAdminBaseUrl}collections/${shopifyCollectionId}`, '_blank');
  };

  const handleMenuOptions = (optionId: number, collectionName: string, collectionId: string | number) => {
    if (optionId === 2) {
      setDeletingCollectionName(collectionName);
      setDeletingCollectionId(collectionId);
      setShowConfirmModal(true);
    }
  };

  const columnOptions = [
    {
      dataKey: 'collection',
      name: 'Collection',
      width: 700,
      cellRenderer: ({ rowData, rowIndex }) => {
        return (
          <FlexBox className="align-items-center d-inline-block name-cell" style={{ marginLeft: `${rowData.level * 64}px` }}>
            <button
              className={`bg-transparent border border-transparent w-25px ${
                rowData.children && rowData.children.length > 0 ? '' : 'opacity-0'
              }`}
              onClick={() => expandCollection(rowIndex)}
            >
              <img src={RightArrowIcon} className={`h-10px w-10px ${selectedIndex === rowIndex ? 'rotatedArrowIcon' : ''}`} />
            </button>
            <div className="symbol me-5 ms-5">
              <Img
                src={setImageSrc(rowData.thumbnailUrl ? generateCompressedImageURL(rowData.thumbnailUrl, '260') : DefaultImage)}
                placeholderImg={DefaultImage}
                errorImg={DefaultImage}
                className="object-fit-scale-down bg-white border border-light border-2"
              />
              {rowData.externalCollectionProvider === 'SHOPIFY' && (
                <>
                  <img
                    src={ShopifyIcon}
                    className="cursor-pointer ms-1 shopify-icon position-absolute"
                    data-tip
                    data-for="shopifySyncIcon"
                    onClick={() => goToShopifyPage(rowData?.externalCollectionId)}
                  />
                  <ShopifySyncTooltip tooltipText="Collection from Shopify" />
                </>
              )}
            </div>
            <FlexBox className="justify-content-start flex-column d-inline-block collectionName">
              <Link to={`/products/collections/edit/${rowData.id}`} className="main-cell">
                <span className="d-block">{rowData.text}</span>
              </Link>
            </FlexBox>
          </FlexBox>
        );
      },
      staticColumn: true
    },
    {
      dataKey: 'products',
      name: 'Products',
      width: 120,
      cellRenderer: ({ rowData }) => {
        return <div>{rowData.productCount ? rowData.productCount : 0}</div>;
      }
    },
    {
      dataKey: 'collectionType',
      name: 'Collection Type',
      width: 150,
      cellRenderer: ({ rowData }) => {
        return <div>{rowData?.type?.toLowerCase()}</div>;
      }
    },
    {
      dataKey: 'status',
      name: 'Status',
      width: 150,
      cellRenderer: ({ rowData }) => {
        return <StatusCell id={rowData.id} status={rowData.status} updateTreeData={handleUpdateTreeData} />;
      }
    },
    {
      dataKey: 'action',
      name: '',
      width: 50,
      cellRenderer: ({ rowData }) => {
        return <ActionCell id={rowData.id} name={rowData.text} handleMenuOptions={handleMenuOptions} />;
      },
      staticColumn: true
    }
  ];

  const handleDeleteCollection = () => {
    deleteCollection({
      variables: {
        id: deletingCollectionId
      }
    })
      .then(() => {
        removeCollection(deletingCollectionId);
        setShowConfirmModal(false);
      })
      .catch((err) => {
        console.log('error::', err);
        setShowConfirmModal(false);
      });
  };

  const closeConfirmDeleteModal = () => {
    document.body.style.overflow = 'unset';
    setShowConfirmModal(false);
  };

  return (
    <>
      <div className="p-t-12">
        <DataTable
          page="collection"
          rowData={treeData}
          columnOptions={columnOptions}
          isLoading={isLoading}
          searchText={searchText}
          selectedIndex={selectedIndex}
          selectedIDs={[]}
          handleUpdateTreeData={handleUpdateTreeData}
          handleCollectionActionMenus={handleMenuOptions}
        />
      </div>
      {showConfirmModal && (
        <CustomModal bodyClassname="w-90 w-md-150" show={showConfirmModal} closeModal={closeConfirmDeleteModal}>
          <DeleteConfirmModal
            title={constVariables.collections.deleteConfirmTitle}
            actionBtnTitle="Yes, Delete"
            cancelBtnTitle="Cancel"
            message={`Are you sure you want delete “${deletingCollectionName}” from your list? This can’t be undone.`}
            cancelBtnHandler={closeConfirmDeleteModal}
            actionBtnHandler={handleDeleteCollection}
          ></DeleteConfirmModal>
        </CustomModal>
      )}
    </>
  );
};

export default CollectionTable;
