import { FC, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

// Components
import { Img } from 'src/components/atoms';
import { Divider } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';

// Icons
import { DefaultImage } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { OrderToFulfilListBlockFulfillmentCenterProps } from './OrderToFulfilListBlockFulfillmentCenter.types';

// Styles
import './_OrderToFulfillListBlockFulFillmentCenter.scss';

const OrderToFulfilListBlockFulfillmentCenter: FC<OrderToFulfilListBlockFulfillmentCenterProps> = ({
  data,
  setSelectedOrder,
  selectedOrder
}) => {
  const { customer, thumbnailUrl, orderCode, total, items, id, mediaUrls } = data;
  const productImages = JSON.parse(mediaUrls);

  const location = useLocation();
  const scrollEndRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    if (location?.state?.openId) {
      setTimeout(() => {
        if (scrollEndRef.current?.id === `#${selectedOrder}`) {
          scrollEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  }, [location]);

  return (
    <div
      className={`${selectedOrder === id ? 'selected-order' : ''} order-card rounded cursor-pointer `}
      onClick={() => setSelectedOrder(id)}
      id={`#${id}`}
      ref={scrollEndRef}
    >
      <div className="order-card-main">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center customer-profile">
            <Img
              src={setImageSrc(thumbnailUrl)}
              placeholderImg={DefaultImage}
              errorImg={DefaultImage}
              alt="Profile"
              className="fulfilmentPicture  rounded-circle me-2"
            />
            <div className="customer-name">{customer}</div>
          </div>
          {productImages?.length > 6 ? (
            <div className="d-flex order-product-images">
              {productImages.slice(0, 6).map((imageUrl, index) => (
                <Img
                  key={index}
                  src={setImageSrc(imageUrl)}
                  placeholderImg={DefaultImage}
                  errorImg={DefaultImage}
                  className="fulfilmentPicture border border-white roundedImagesOrderFulfilment fulfilmentShiftImage"
                />
              ))}
              <div className="border border-white roundedImagesOrderFulfilment fulfilmentShiftImage position-relative ">
                <div className="imageOverlayText fs-9 d-flex align-items-center justify-content-center roundedImagesOrderFulfilment">
                  {/* {data.orderDetails?.length - 6} */}+{productImages?.length - 6}
                </div>
                <Img
                  src={setImageSrc(mediaUrls[6])}
                  placeholderImg={DefaultImage}
                  errorImg={DefaultImage}
                  className="d-block w-100 fulfilmentPicture roundedImagesOrderFulfilment"
                />
              </div>
            </div>
          ) : (
            <div className="d-flex order-product-images">
              {productImages?.map((imageUrl, index) => (
                <Img
                  key={index}
                  src={setImageSrc(imageUrl)}
                  placeholderImg={DefaultImage}
                  errorImg={DefaultImage}
                  className="fulfilmentPicture border border-white roundedImagesOrderFulfilment fulfilmentShiftImage"
                />
              ))}
            </div>
          )}
        </div>
        <div className="order-description">
          <div>
            <span className="order-description-label poppins-regular">{constVariables.FulfillmentCenter.pOrders}</span>
            <span className="order-code"> #{orderCode}</span>
          </div>
          <div>
            <span className="order-description-label poppins-regular">
              {items} {items === 1 ? constVariables.FulfillmentCenter.item : constVariables.FulfillmentCenter.items}{' '}
            </span>
            <span>${total}</span>
          </div>
        </div>
      </div>
      <Divider />
      <div className="cursor-pointer d-flex align-items-center justify-content-center text-primary p-4">
        <div>{constVariables.FulfillmentCenter.fulfillOrder}</div>
      </div>
    </div>
  );
};

export default OrderToFulfilListBlockFulfillmentCenter;
