import React, { useCallback } from 'react';
import { CellProps } from 'react-datasheet-grid';

const PriceFormatCell = React.memo(({ rowData, setRowData, columnData, active, focus }: CellProps) => {
  const handleChange = (e) => {
    const value = e.target.value;
    // const regex = /^[0-9\b]+$/;
    const regex = /^\d*(\.\d{0,2})?$/;
    if (focus && (value === '' || regex.test(value))) {
      if (columnData.key === 'salePrice') {
        const salePrice = parseFloat(value);
        const profit = salePrice - rowData?.perItemCost;
        const margin = (profit / rowData?.perItemCost) * 100;
        const updatedRowdata = { ...rowData, [columnData.key]: value, profit, margin };
        setRowData(updatedRowdata);
      }
      if (columnData.key === 'perItemCost') {
        const perItemCost = parseFloat(value);
        const profit = rowData?.salePrice - perItemCost;
        const margin = (profit / perItemCost) * 100;
        const updatedRowdata = { ...rowData, [columnData.key]: value, profit, margin };
        setRowData(updatedRowdata);
      }
      if (columnData.key === 'profit') {
        const profit = parseFloat(value);
        const salePrice = rowData?.perItemCost + profit;
        const margin = (profit / rowData?.perItemCost) * 100;
        const updatedRowdata = { ...rowData, [columnData.key]: value, salePrice, margin };
        setRowData(updatedRowdata);
      }
      if (columnData.key === 'shippingPrice') {
        const shippingPrice = parseFloat(value);
        const updatedRowdata = { ...rowData, [columnData.key]: value, isFreeShipping: shippingPrice > 0 ? false : true };
        setRowData(updatedRowdata);
      }
    }
  };

  const callbackRef = useCallback(
    (inputElement) => {
      if (inputElement && focus) {
        setTimeout(() => {
          inputElement.focus();
          inputElement.select();
        }, 100);
      }
    },
    [focus]
  );

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  };

  return (
    <input
      style={{ pointerEvents: focus ? 'auto' : 'none' }}
      autoFocus={focus ? true : false}
      tabIndex={-1}
      className="dsg-input"
      value={!focus ? `$${rowData[columnData.key]}` : rowData[columnData.key]}
      ref={callbackRef}
      onKeyDown={handleKeyPress}
      onChange={(e) => {
        e.target.validity.valid && handleChange(e);
      }}
      pattern="^\d*(\.\d{0,2})?$"
      type="text"
    />
  );
});

export default PriceFormatCell;
