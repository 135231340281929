/* eslint-disable no-nested-ternary */
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Apollo
import { useLazyQuery } from '@apollo/client';
import { GET_LIVE_STREAMS } from 'src/apollo/queries';

// Components
import { Img } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { setImageSrc } from 'src/utils/setImageSrc';

// Icons
import { CloseIcon, SearchIcon } from 'src/assets/icons';

// Types
import { LiveshowSearch } from './LiveshowSearch.types';

// Styles
import './LiveshowSearch.scss';

const LiveshowSearchComponent: FC<LiveshowSearch> = ({ handleSelectLiveshow }) => {
  const { state } = useLocation();
  const [search, setSearch] = useState<string>(state?.search || '');
  const [searchResponse, setSearchResponse] = useState<any[]>([]);

  window.history.replaceState({}, document.title);

  const [getLiveStreams] = useLazyQuery(GET_LIVE_STREAMS, {
    variables: {
      input: {
        search
      }
    },
    onCompleted(data) {
      setSearchResponse(
        data.getLiveStreams?.map((item, index) => {
          const day = new Date(item.startingAt).toLocaleDateString('en-US', {
            weekday: 'long'
          });
          const date = new Date(item.startingAt).toLocaleDateString('en-US', {
            day: 'numeric'
          });
          const monthYear = new Date(item.startingAt).toLocaleDateString('en-US', {
            month: 'long',
            year: 'numeric'
          });
          const startTime = new Date(item.startingAt).toLocaleTimeString('en-US', { timeStyle: 'short' });
          const endTime = new Date(item.endingAt).toLocaleTimeString('en-US', { timeStyle: 'short' });
          const startDate = new Date(item.startingAt);
          const prevItemDate = new Date(data.getLiveStreams?.[index - 1]?.startingAt).toLocaleDateString('en-US', {
            month: 'long',
            year: 'numeric',
            day: 'numeric'
          });
          const nextItemDate = new Date(data.getLiveStreams?.[index + 1]?.startingAt).toLocaleDateString('en-US', {
            month: 'long',
            year: 'numeric',
            day: 'numeric'
          });
          const currentItemDate = new Date(item.startingAt).toLocaleDateString('en-US', {
            month: 'long',
            year: 'numeric',
            day: 'numeric'
          });

          return {
            id: item.id,
            name: item.title,
            thumbnailUrl: item.thumbnailUrl || '',
            timings: `${startTime} - ${endTime}`,
            upcoming: new Date(startDate) > new Date(),
            isLive: new Date() > new Date(startDate) && new Date() < new Date(item.endingAt),
            day,
            date,
            monthYear,
            showDate: prevItemDate !== currentItemDate,
            showBorder: nextItemDate !== currentItemDate,
            productImages: item.products?.map((product) => product.productImage),
            data: item
          };
        })
      );
    }
  });

  // Search for Livestreams
  const handleSearch = (searchString: string) => {
    if (searchString.length > 0) {
      setSearch(searchString);
      getLiveStreams({
        variables: {
          input: {
            search
          }
        }
      });
    } else {
      setSearch(searchString);
      setSearchResponse([]);
    }
  };

  useEffect(() => {
    if (search.length) {
      handleSearch(search);
    }
  }, []);

  return (
    <div
      className={`${
        searchResponse?.length > 8 ? 'h-auto' : 'h-700px'
      } mb-15 d-flex flex-column px-5 py-4 mt-1 shadow-sm bg-white rounded fw-bold`}
    >
      <div className="position-relative m-5 d-flex align-items-center justify-content-between">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="position-relative d-flex flex-row flex-nowrap align-items-center rounded-2"
        >
          <KTSVG svgClassName="h-30px w-30px px-2 py-1" path={SearchIcon} />
          <input
            className="w-400px bg-light border-0 px-2 py-1"
            type="text"
            placeholder="Search for liveshows"
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <KTSVG svgClassName="h-30px w-30px px-2 py-1" path={CloseIcon} onClick={() => handleSearch('')} />
        </form>
        <p className="text-black-50">{`Showing ${searchResponse?.length} results`}</p>
      </div>
      <table className="table-row-black-300 align-middle gs-0 gy-4">
        {searchResponse?.map((item, ind) => (
          <tr
            key={ind}
            className={`table-row ${item.showBorder ? 'border-bottom' : ''}`}
            onClick={() => handleSelectLiveshow(item.data)}
          >
            {item.showDate ? (
              <td>
                <div className="d-flex flex-grow">
                  <div className="dateText">{item.date}</div>
                  <div className="ms-2 monthYearText">{`${item.monthYear} • ${item.day}`}</div>
                </div>
              </td>
            ) : (
              <td className="h-65px"> </td>
            )}

            <td>
              <span className="timeText">{item.timings}</span>
            </td>
            <td className={`${!item.showBorder ? 'pb-5 border-bottom' : ''}`}>
              <div className={`my-3 d-flex flex-grow  flex-row flex-nowrap align-items-center`}>
                <Img className="h-40px w-40px rounded-2" alt={item.name} src={setImageSrc(item.thumbnailUrl)} />
                <div className="ms-3">{item.name}</div>
              </div>
            </td>
            <td className={`${!item.showBorder ? 'pb-5 border-bottom' : ''}`}>
              <div className="d-flex flex-grow">
                {item.isLive ? (
                  <div className="badge badge-light-danger cursor-pointer p-3 fw-normal rounded-pill">Live</div>
                ) : item.upcoming ? (
                  <div className="badge badge-light-primary cursor-pointer p-3 fw-normal rounded-pill">Upcoming</div>
                ) : (
                  <div className="badge badge-light p-3 cursor-pointer fw-normal rounded-pill">Completed</div>
                )}
              </div>
            </td>
            <td className={`${!item.showBorder ? 'pb-5 border-bottom' : ''} `}>
              <div className="d-flex flex-row flex-nowrap align-items-center ">
                {item.productImages &&
                  item.productImages
                    .slice(0, 10)
                    .map((productImage, ind) => (
                      <Img
                        key={ind}
                        className="h-30px w-30px rounded-circle overlappingImage"
                        alt={item.name}
                        src={setImageSrc(productImage)}
                      />
                    ))}
              </div>
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export default LiveshowSearchComponent;
