/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApolloClient, ApolloLink, createHttpLink } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from '@apollo/client/link/error';
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link';
import { signOut } from 'src/helpers/InitFirebase';
import { envUrls } from 'src/constants';
import { cache } from './cache';
import 'firebase/app';
import {
  UUIDOperationIdSubscriptionClient,
  asBase64EncodedJson,
  createAppSyncAuthorizedWebSocket,
  cacheWithAsyncRefresh,
  createAppSyncGraphQLOperationAdapter
} from './graphqlConfig';
import { getNewFirebaseToken } from 'src/utils/authentication/authConfig';
import { userLoginData } from './reactiveVariables';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { UrlInfo } from 'aws-appsync-subscription-link/lib/types';
import { setContext } from 'apollo-link-context';
import firebase from 'firebase/app';
const APPSYNC_MAX_CONNECTION_TIMEOUT_MILLISECONDS = 5 * 60 * 1000;

// get appSync authorization information
const getAppSyncAuthorizationInfo = () => {
  const headersObj = {
    host: envUrls.LiveStreamingAPIHost,
    Authorization: localStorage.getItem('token') ? localStorage.getItem('token') : ''
  };
  return headersObj;
};

let wsLink: any = null;
// WebSocketLink for manage subscriptions
wsLink = new WebSocketLink(
  new UUIDOperationIdSubscriptionClient(
    `${envUrls.realTimeLiveStreamingApiHost}?header=${asBase64EncodedJson(
      getAppSyncAuthorizationInfo()
    )}&payload=${asBase64EncodedJson({})}`,
    { timeout: APPSYNC_MAX_CONNECTION_TIMEOUT_MILLISECONDS, reconnect: true, lazy: true },
    createAppSyncAuthorizedWebSocket(cacheWithAsyncRefresh(getAppSyncAuthorizationInfo))
  ).use([createAppSyncGraphQLOperationAdapter(getAppSyncAuthorizationInfo)])
);
wsLink.subscriptionClient.maxConnectTimeGenerator.duration = () => wsLink.subscriptionClient.maxConnectTimeGenerator.max;

// Clean __typeName from input variables
// const cleanTypeName = new ApolloLink((operation, forward) => {
//   if (operation.variables) {
//     const omitTypename = (key, value) => (key === '__typename' ? undefined : value);
//     operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
//   }
//   return forward(operation).map((data) => {
//     return data;
//   });
// });

function getValidatedUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}

const authMiddleware = setContext(async (operation) => {
  const user = await getValidatedUser();
  return getNewFirebaseToken().then((token) => {
    const header = {
      authorization: token || null
    };
    if (
      operation.operationName === 'ResetPasswordMutation' ||
      operation.operationName === 'verifyUserMail' ||
      operation.operationName === 'onChangeEmailMutation'
    ) {
      // eslint-disable-next-line dot-notation
      header['token'] = operation.variables?.token;
      // eslint-disable-next-line dot-notation
      header['tenantid'] = operation.variables?.tenantid;
    } else if (operation.operationName === 'resendUserEmailVerify') {
      // eslint-disable-next-line dot-notation
      header['tenantid'] = operation.variables?.tenantid;
    }
    return {
      headers: header
    };
  });
});

// MultiAPILink is used to create differet endpoints as per module,
// and use it in API call syntax

const httpLink = new MultiAPILink({
  // defaultEndpoint: envUrls.productsApiUrl,
  endpoints: {
    productsAPI: envUrls.productsApiUrl,
    ordersAPI: envUrls.ordersApiUrl,
    authAPI: envUrls.authApiUrl,
    liveStreamingAPI: envUrls.liveStreamingUrl,
    shopifyAPI: envUrls.shopifyApiUrl,
    tiktokAPI: envUrls.tiktokApiUrl
  },
  httpSuffix: '',
  getContext: (endpoint, getCurrentContext) => {
    const headers = getCurrentContext().headers;
    if (!headers.tenantid) {
      headers.tenantid = localStorage.getItem('storeId') ? localStorage.getItem('storeId') : null;
    }

    if (endpoint === 'authAPI') {
      headers['x-api-key'] = envUrls.authXApiKey;

      return {
        headers
      };
    } else if (endpoint === 'ordersAPI') {
      headers['x-api-key'] = envUrls.orderXApiKey;

      return {
        headers
      };
    } else if (endpoint === 'productsAPI') {
      headers['x-api-key'] = envUrls.productxApiKey;

      return {
        headers
      };
    } else if (endpoint === 'shopifyAPI') {
      headers['x-api-key'] = envUrls.shopifyApiKey;

      return {
        headers
      };
    } else if (endpoint === 'tiktokAPI') {
      headers['x-api-key'] = envUrls.tiktokApiKey;

      return {
        headers
      };
    } else {
      headers['x-api-key'] = envUrls.livestreamApiKey;

      return {
        headers
      };
    }
  },
  createHttpLink: () => createHttpLink()
});

// choose link for subscription or http
// const splitLink = ApolloLink.split(
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
//   },
//   wsLink,
//   moduleLink
// );

// NOTE: If it returns network error with status code 401 or 403,
// it will remove localstorage data or token and will redirect to login screen
// for grapqhl errors, it will simply print down in console for debugging purpose.

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => {
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, ${path}`);
      return null;
    });
  if (networkError) {
    console.error('[Network error]: ', networkError);
    const errorMsg: string = networkError.message ? networkError.message : networkError.toString();
    if (errorMsg.includes('401') || errorMsg.includes('403')) {
      signOut();
      localStorage.clear();
      userLoginData({});
      window.location.reload();
      // This function will replace previous routes which are already in the history of react router
      window.history.replaceState(null, '/');
    }
  }
});

// const combinedLink = from([errorLink, splitLink]);

// export const client = new ApolloClient({
//   cache,
//   link: combinedLink
// });

const authoriser: UrlInfo['auth'] = {
  type: 'API_KEY',
  apiKey: envUrls.livestreamApiKey
};

// const combinedLink = ApolloLink.from([authLink, httpLink]);

const link = ApolloLink.from([
  errorLink,
  authMiddleware as unknown as ApolloLink,
  createSubscriptionHandshakeLink(
    {
      url: envUrls.liveStreamingUrl,
      auth: authoriser,
      region: envUrls.awsRegion
    },
    httpLink
  )
]);

export const client = new ApolloClient({
  cache,
  link
});
