import React, { useState, createRef, useEffect } from 'react';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { DownArrowIcon } from 'src/assets/icons';

// Types
import { TagColorsExtra } from 'src/components/oraganisms/OrdersList/tagColors';
import { CustomerTagsBlockProps } from './CustomerTagsBlock.types';

const CustomerTags: React.FC<CustomerTagsBlockProps> = ({ userTags, isProduct }) => {
  const tagsListRef = createRef<HTMLDivElement>();
  const [limitCount, setLimitCount] = useState(0);

  const tempUsertags = userTags;

  useEffect(() => {
    if (tagsListRef.current) {
      const tagsListWidth = tagsListRef.current.clientWidth;
      let tagsWidthSum = 0;
      for (let i = 0; i < tempUsertags.length; i++) {
        const element = document.getElementById(`tag-item-${tempUsertags[i]?.id}`);
        if (element) {
          const tagItemWidth = element?.clientWidth;
          tagsWidthSum += tagItemWidth + 6;
        }

        if (tagsListWidth - 55 < tagsWidthSum) {
          setLimitCount(i);
          tagsWidthSum = 0;
          return;
        }
      }
    }
  }, [tempUsertags]);

  return (
    <>
      <div
        className={`position-relative d-flex align-items-center flex-wrap overflow-hidden ${
          isProduct ? 'w-100 text-nowrap' : 'w-200px'
        } ${limitCount > 0 && tempUsertags.length > 1 && tempUsertags.length > limitCount ? 'justify-content-between' : ''}`}
        ref={tagsListRef}
      >
        <>
          <div className="d-flex align-items-center">
            {tempUsertags.slice(0, limitCount || undefined).map((data, index) => (
              <div
                key={index}
                style={{
                  backgroundColor: tempUsertags[index]?.bgColor
                    ? tempUsertags[index]?.bgColor
                    : tempUsertags[index]?.backgroundColorCode,
                  color: tempUsertags[index]?.color ? tempUsertags[index]?.color : tempUsertags[index]?.textColorCode
                }}
                className="text-nowrap tag-item"
                id={`tag-item-${data?.id}`}
              >
                {data?.tag ? data?.tag : data?.name}
              </div>
            ))}
          </div>
          {limitCount > 0 && tempUsertags.length > 1 && tempUsertags.length > limitCount && (
            <div
              style={{ backgroundColor: TagColorsExtra.background, color: TagColorsExtra.color }}
              className="align-items-center d-flex rounded tag-item limited-tag"
            >
              <div>{`+${tempUsertags.length - limitCount}`}</div>
              <KTSVG path={DownArrowIcon} />
            </div>
          )}
        </>
      </div>
    </>
  );
};

export default CustomerTags;
