import { FC, useState } from 'react';

// Components
import { FlexBox, Img, Loader } from 'src/components/atoms';

// Icons
import { DefaultImage, LeftArrowIcon, RightArrowIcon } from 'src/assets/icons';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Types
import { ProductDetailsProps } from 'src/components/oraganisms/ProductList/ProductList.types';

const ProductDetailsRow: FC<ProductDetailsProps> = ({ productDetails, isLoading, rowWidth }) => {
  const [slideNo, setSlideNo] = useState(0);

  return (
    <div
      className="ReactVirtualized__Table__row"
      style={{
        height: 230,
        display: 'flex',
        alignItems: 'center',
        width: rowWidth
      }}
    >
      {isLoading ? (
        <Loader type="page" />
      ) : (
        <>
          <div className="pe-0 detail-table-cell h-100">
            <FlexBox className="align-items-center">
              <div className="symbol me-5">
                <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                  <div className="carousel-inner">
                    <div className="carousel-item active w-160px h-160px">
                      {productDetails?.productMediasJson ? (
                        <>
                          {productDetails?.productMediasJson[slideNo]?.productMediaType === 'VIDEO' ? (
                            <video src={productDetails?.productMediasJson[slideNo]?.url} height={146} width={146} controls={true} />
                          ) : (
                            <Img
                              className="w-160px h-160px product-details-media"
                              src={setImageSrc(
                                productDetails?.productMediasJson
                                  ? generateCompressedImageURL(productDetails?.productMediasJson[slideNo]?.productMediaUrl, '200')
                                  : DefaultImage
                              )}
                              placeholderImg={DefaultImage}
                              errorImg={DefaultImage}
                            />
                          )}
                        </>
                      ) : (
                        <img src={DefaultImage} className="w-160px h-160px product-details-media" alt="" />
                      )}
                    </div>
                  </div>
                  {slideNo > 0 && (
                    <a
                      className="carousel-control-prev"
                      role="button"
                      onClick={() => {
                        setSlideNo(slideNo - 1);
                      }}
                    >
                      <span aria-hidden="true">
                        <KTSVG path={LeftArrowIcon} />
                      </span>
                      <span className="sr-only primary">Previous</span>
                    </a>
                  )}
                  {productDetails?.productMediasJson && productDetails?.productMediasJson?.length >= slideNo + 1 && (
                    <a
                      className="carousel-control-next"
                      role="button"
                      onClick={() => {
                        setSlideNo(slideNo + 1);
                      }}
                    >
                      <span aria-hidden="true">
                        <KTSVG path={RightArrowIcon} />
                      </span>
                      <span className="sr-only">Next</span>
                    </a>
                  )}
                </div>
              </div>
              <FlexBox className="justify-content-start flex-column detail-text-section">
                <FlexBox className="justify-content-start flex-column mb-10 detail-item">
                  <p className="detail-item-header">Collections</p>
                  <div className="text-muted">
                    {productDetails?.productCollectionJson?.map((item, index) => {
                      return (
                        <span key={index}>
                          {item.name}
                          {index === productDetails?.productCollectionJson?.length - 1 ? '.' : ', '}
                        </span>
                      );
                    })}
                  </div>
                </FlexBox>
                <FlexBox className="justify-content-start flex-column detail-item">
                  <p className="detail-item-header">Tags</p>
                  <div className="text-muted">
                    {productDetails?.productTagsJson?.map((tag, index) => {
                      return (
                        <span
                          className={`badge me-3 mt-3`}
                          style={{
                            color: tag.textColorCode,
                            backgroundColor: tag.backgroundColorCode,
                            borderRadius: '8px'
                          }}
                          key={index}
                        >
                          {tag.name}
                        </span>
                      );
                    })}
                  </div>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </div>
          <div className="pe-4 detail-table-cell h-100">
            <FlexBox className="detail-text-section">
              <FlexBox className="me-5 detail-text-section-block">
                <FlexBox className="ustify-content-start flex-column">
                  <FlexBox className="justify-content-start flex-column mb-10 detail-item">
                    <p className="detail-item-header">Waitlist</p>
                    <div className="poppins-regular text-muted">{productDetails?.waitlist || 10} orders</div>
                  </FlexBox>
                  <FlexBox className="justify-content-start flex-column detail-item">
                    <p className="detail-item-header">Waitlist auth</p>
                    <div className="poppins-regular text-muted">{productDetails?.waitlistAuth || 9} orders</div>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
              <FlexBox className="me-5 detail-text-section-block">
                <FlexBox className="justify-content-start flex-column">
                  <FlexBox className="justify-content-start flex-column mb-10 detail-item">
                    <p className="detail-item-header">Variants</p>
                    <div className="poppins-regular text-muted">{productDetails?.variantCount}</div>
                  </FlexBox>
                  <FlexBox className="justify-content-start flex-column detail-item">
                    <p className="detail-item-header">Incoming</p>
                    <div className="poppins-regular text-muted">{productDetails?.incoming || 10}</div>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
              <FlexBox className="me-5 detail-text-section-block">
                <FlexBox className="justify-content-start flex-column">
                  <FlexBox className="justify-content-start flex-column mb-10 detail-item">
                    <p className="detail-item-header">SKU</p>
                    <div className="poppins-regular text-muted">{productDetails?.productSku}</div>
                  </FlexBox>
                  <FlexBox className="justify-content-start flex-column detail-item">
                    <p className="detail-item-header">Sold</p>
                    <div className="poppins-regular text-muted">{productDetails?.sold || 10}</div>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
              <FlexBox className="me-5 detail-text-section-block">
                <FlexBox className="justify-content-start flex-column">
                  <FlexBox className="justify-content-start flex-column mb-10 detail-item">
                    <p className="detail-item-header">Cost</p>
                    <div className="poppins-regular text-muted">{productDetails?.salePrice} USD</div>
                  </FlexBox>
                  <FlexBox className="justify-content-start flex-column detail-item">
                    <p className="detail-item-header">FulFilled</p>
                    <div className="poppins-regular text-muted">{productDetails?.fulfilled || 8}</div>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </div>
        </>
      )}
    </div>
  );
};

export default ProductDetailsRow;
