import { FC } from 'react';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { Checkbox } from 'src/components/molecules';
import { OrganizationSectionCollections } from 'src/components/oraganisms';
import ProductTypes from './ProductTypes';
import Vendors from './Vendors';
import Tags from './Tags';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { InfoIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { OrganizationSectionTypes } from './OrganizationSection.types';
import { UserRoles } from 'src/components/pages/settings/UserPermissions/UserPermissions.type';

// Styles
import './_organizationSection.scss';

const OrganizationSection: FC<OrganizationSectionTypes> = ({
  organization,
  isCreateCollection = false,
  setOrganization,
  addOrRemoveCollectionListItem,
  collectionIdList,
  appliedTags,
  setAppliedTags,
  selectedOrganization,
  productVendorID,
  setProductVendorID,
  productTypeId,
  setProductTypeId
}) => {
  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  const sensitiveOrganizationCheckbox = () => {
    setOrganization((prev) => ({
      ...prev,
      sensitiveProduct: !prev.sensitiveProduct
    }));
  };

  return (
    <div>
      <h4 className="section-title m-0">{constVariables.common.addOrganization.key}</h4>

      {/* Collections Section */}
      <OrganizationSectionCollections
        addOrRemoveCollectionListItem={addOrRemoveCollectionListItem}
        collectionIdList={collectionIdList}
        setOrganization={setOrganization}
        collectionData={organization.collectionList}
        selectedOrganization={selectedOrganization}
        hideCheckboxes={isCreateCollection}
        isCreateCollection={isCreateCollection}
      />

      {/* Check if page is Product or Collection */}
      {!isCreateCollection && (
        <>
          <Vendors productVendorID={productVendorID} setProductVendorID={setProductVendorID} />
          <ProductTypes productTypeId={productTypeId} setProductTypeId={setProductTypeId} />
          <Tags appliedTags={appliedTags} setAppliedTags={setAppliedTags} isDisable={role === UserRoles.FULFILMENT_ASSOCIATE} />
          <div className="d-flex justify-content-between align-items-center m-t-24">
            <Checkbox
              tagclassname="poppins-medium ps-4 final-scale-checkbox"
              name={constVariables.common.addOrganization.sensitiveProduct}
              defaultValue={organization.sensitiveProduct}
              onChangeValue={sensitiveOrganizationCheckbox}
              noVerticalPadding
              disable={role === UserRoles.FULFILMENT_ASSOCIATE}
            />
            {/* <div>
              <KTSVG path={InfoIcon} />
            </div> */}
          </div>
        </>
      )}
    </div>
  );
};

export default OrganizationSection;
