import { configureStore } from '@reduxjs/toolkit';
import userInfoReducer from './reducer/userInfoReducer';
import notificationsReducer from './reducer/notificationReducer';

export const store = configureStore({
  reducer: {
    userInfo: userInfoReducer,
    notifications: notificationsReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
