import { createContext } from 'react';
import { ToastProp } from 'src/components/molecules/Toast/Toast.types';

export const defaultToast = {
  toastValue: {
    title: '',
    successText: '',
    errorText: '',
    message: ''
  },
  setToastValues: (obj: ToastProp): any => ({})
};

export const ToastHandler = createContext(defaultToast);
