import { gql } from '@apollo/client';

export const GET_LABEL_TEMPLATES = gql`
  query OngetLabelTemplates @api(name: "productsAPI") {
    getLabelTemplates {
      id
      originalDymension
      type
      paperType
    }
  }
`;
