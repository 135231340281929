import { FC, useState } from 'react';
import { useHistory } from 'react-router';
import OutsideClickHandler from 'react-outside-click-handler';
import moment from 'moment';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_SHOPIFY_ADMIN_BASE_URL } from 'src/apollo/queries';

// Components
import { FlexBox, Img, Loader } from 'src/components/atoms';
import {
  ContactInfoDetails,
  Dropdown,
  OverviewCustomerDetails,
  ShippingAddressDetails,
  StoreCreditCustomerDetails,
  VipCustomerTooltip,
  FirstTimeCustomerTooltip,
  ShopifySyncTooltip
} from 'src/components/molecules';
import { AddNotes, CustomerTaxcode } from 'src/components/oraganisms';
import CustomerOrders from './CustomerOrders';
import CustomerFavorites from './CustomerFavorites';
import CustomerTags from './CustomerTags';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';

// Icons
import { BackRoundedIcon, FirstTime, ThreeDotsIcon, VipCustomerIcon, DefaultImage, ShopifyIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { CustomerDetailsType } from './CustomerDetails.types';

const CustomerDetailsPresentational: FC<CustomerDetailsType> = ({
  isLoading,
  generalData,
  overViewData,
  storeCreditData,
  contactInfoData,
  setContactInfoData,
  tagsData,
  setTagsData,
  addressData,
  notesData,
  blockUnblockFunction,
  deleteUserFunction,
  clearUserCart,
  clearUserWishlist,
  customerId,
  disableBlockButton,
  setDisableBlockButton,
  editOldNote,
  deleteOldNote,
  addNewNote,
  addDataStoreCredit,
  subtractStoreCredit,
  openAddressModal,
  editContactInfo,
  toggleEditContactInfo,
  changeEmailAddress,
  changePhoneNumber,
  togglePhoneDropdown,
  closePhoneDropdown,
  phoneList,
  onSelectPhone,
  saveEmailPhoneEdit,
  cancelEmailPhoneEdit,
  refetchCustomerDetails,
  setFormChanges,
  selectedTags,
  setSelectedTags
}) => {
  const [openMenu, setOpenMenu] = useState(false);

  const history = useHistory();

  const { data: { shopifyAdminBaseUrl } = { shopifyAdminBaseUrl: null } } = useQuery(GET_SHOPIFY_ADMIN_BASE_URL);

  const backbtnHandler = () => {
    history.push('/customers/allCustomers');
  };

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };
  const toggleBlockUnblock = () => {
    setDisableBlockButton(true);
    blockUnblockFunction(customerId);
  };
  const closeMenu = () => {
    if (openMenu) {
      setOpenMenu(false);
    }
  };

  const selectingMenuItem = (data: any) => {
    closeMenu();
    if (data.name.toUpperCase() === 'BLOCK CUSTOMER' || data.name.toUpperCase() === 'UNBLOCK CUSTOMER') {
      setDisableBlockButton(true);
      blockUnblockFunction(customerId);
    } else if (data.name.toUpperCase() === 'DELETE CUSTOMER') {
      deleteUserFunction(customerId);
    }
  };

  const goToShopifyPage = (shopifyCustomerId) => {
    if (shopifyAdminBaseUrl) window.open(`${shopifyAdminBaseUrl}customers/${shopifyCustomerId}`, '_blank');
  };

  return (
    <>
      {isLoading ? (
        <Loader type="page" className="h-500px" />
      ) : (
        <>
          <FlexBox className="align-items-center m-b-40">
            <img src={BackRoundedIcon} alt="Back" className="me-5 h-30px w-30px cursor-pointer" onClick={backbtnHandler} />
            <Img
              src={setImageSrc(generalData?.profilePic ? generalData?.profilePic : '')}
              placeholderImg={DefaultImage}
              errorImg={DefaultImage}
              className="objectFit customerImageCustomerDetails me-5"
            />

            {generalData.name || generalData.email ? (
              <div>
                <FlexBox className="align-items-center">
                  <div className="poppins-semibold m-auto-0 text-nowrap dark-color fs-6">
                    {generalData.name ? generalData.name : generalData.email}
                  </div>
                  {generalData?.shopifyId && (
                    <>
                      <img
                        src={ShopifyIcon}
                        className="cursor-pointer ms-2"
                        data-tip
                        data-for="shopifySyncIcon"
                        onClick={() => goToShopifyPage(generalData?.shopifyId)}
                      />
                      <ShopifySyncTooltip tooltipText="Customer from Shopify" />
                    </>
                  )}
                </FlexBox>

                <FlexBox className="align-items-center">
                  {generalData?.isFirstTimeCustomer && (
                    <>
                      <img src={FirstTime} className="cursor-pointer me-2" data-tip data-for="firstTimeCustomerIcon" />
                      <FirstTimeCustomerTooltip />
                    </>
                  )}
                  {generalData?.isVIPCustomer && (
                    <>
                      <img src={VipCustomerIcon} className="cursor-pointer me-2" data-tip data-for="vipCustomerIcon" />
                      <VipCustomerTooltip />
                    </>
                  )}
                  {generalData.joinedOn ? (
                    <div className="text-muted mx-3 fs-6">{moment(generalData.joinedOn).format('D MMM YYYY, hh:mm a')}</div>
                  ) : null}
                  <button
                    onClick={toggleBlockUnblock}
                    className={`btn ${generalData.isBlockedForLive ? `btn-primary` : `btn-danger`} btn-sm `}
                    disabled={disableBlockButton}
                  >
                    {generalData.isBlockedForLive ? 'Unblock Customer' : 'Block Customer'}
                  </button>
                  {/* <button
                    onClick={() => clearUserCart({ variables: { userId: customerId } })}
                    className="ms-3 btn btn-primary btn-sm"
                  >
                    Clear Cart
                  </button>
                  <button
                    onClick={() => clearUserWishlist({ variables: { userId: customerId } })}
                    className="ms-3 btn btn-primary btn-sm"
                  >
                    Clear Wishlist
                  </button> */}
                  <OutsideClickHandler onOutsideClick={closeMenu}>
                    <span className="position-relative ms-4">
                      <img
                        src={ThreeDotsIcon}
                        className="dropdown-toggle cursor-pointer h-15px "
                        id="dropdownMenuButton"
                        onClick={toggleMenu}
                      />
                      <Dropdown
                        data={
                          generalData.isBlockedForLive
                            ? constVariables.customrDetailsOptionsBlockedDropdownData
                            : constVariables.customrDetailsOptionsUnblockedDropdownData
                        }
                        onSelect={selectingMenuItem}
                        shiftLeft
                        shiftLeftValue="-200px"
                        selected={openMenu}
                        className="mt-5"
                        closeDropdown={() => setOpenMenu(false)}
                      />
                    </span>
                  </OutsideClickHandler>
                </FlexBox>
              </div>
            ) : null}
          </FlexBox>
          <div className="main-detail-layout">
            <div className="left-pane">
              <div className="card w-100">
                <OverviewCustomerDetails
                  averageOrderValue={overViewData.averageOrderValue}
                  totalAmountSpentValue={overViewData.totalAmountSpentValue}
                  numberOfProducts={overViewData.numberOfProducts}
                  lastOrderDate={overViewData.lastOrderDate}
                  productsRefunded={overViewData.productsRefunded}
                />
              </div>
              <CustomerOrders />
              <CustomerFavorites />
            </div>
            {/* Side Column  */}
            <div className="right-pane custom-details-page">
              <div className="card">
                <StoreCreditCustomerDetails
                  addDataStoreCredit={addDataStoreCredit}
                  subtractStoreCredit={subtractStoreCredit}
                  isBlocked={generalData.isBlockedForLive}
                  storeCreditValue={storeCreditData.storeCreditValue}
                />
              </div>

              <div className="card">
                <ContactInfoDetails
                  email={contactInfoData.email}
                  isEmailVerified={contactInfoData.isEmailVerified}
                  phone={contactInfoData.phone}
                  isPhoneVerified={contactInfoData.isPhoneVerified}
                  editContactInfo={editContactInfo}
                  toggleEditContactInfo={toggleEditContactInfo}
                  editingEmail={contactInfoData.editingEmail}
                  editingPhone={contactInfoData.editingPhone}
                  changeEmailAddress={changeEmailAddress}
                  changePhoneNumber={changePhoneNumber}
                  togglePhoneDropdown={togglePhoneDropdown}
                  closePhoneDropdown={closePhoneDropdown}
                  phoneList={phoneList}
                  selectPhoneDropdown={contactInfoData.selectPhoneDropdown}
                  onSelectPhone={onSelectPhone}
                  selectedPhone={contactInfoData.selectedPhone}
                  saveEmailPhoneEdit={saveEmailPhoneEdit}
                  cancelEmailPhoneEdit={cancelEmailPhoneEdit}
                  saveButtonDisabled={contactInfoData.saveButtonDisabled}
                  canEdit={contactInfoData.canEdit}
                  setContactInfoData={setContactInfoData}
                />
              </div>

              <div className="card">
                <ShippingAddressDetails
                  openAddressModal={openAddressModal}
                  userAddress={addressData.userAddress}
                  freeShipping={addressData.freeShipping}
                  customerId={customerId}
                  refetchCustomerDetails={refetchCustomerDetails}
                />
              </div>
              <div className="card">
                <CustomerTaxcode customerId={customerId} />
              </div>

              <div className="card">
                <AddNotes
                  id={12}
                  editOldNote={editOldNote}
                  deleteOldNote={deleteOldNote}
                  addNewNote={addNewNote}
                  notesData={notesData.userNotes}
                  setNotesData={() => null}
                />
              </div>
              <div className="card">
                <CustomerTags
                  tags={tagsData}
                  setTags={setTagsData}
                  setFormChanges={setFormChanges}
                  selectedTags={selectedTags}
                  setSelectedTags={setSelectedTags}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CustomerDetailsPresentational;
