import { FC } from 'react';
import clsx from 'clsx';

// Components
import { FlexBox } from 'src/components/atoms';

// Types
import { FilterMenuDropdownProps } from './HomePage.type';

const FilterMenuDropdown: FC<FilterMenuDropdownProps> = ({ isShowDropdown, data, selectedValue, onSelect }) => {
  return (
    <div
      className={clsx(
        `menu menu-sub menu-background menu-sub-dropdown menu-column menu-rounded menu-gray-600 w-300px position-absolute p-0 sort-dropdown mt-3`,
        { show: isShowDropdown }
      )}
      data-kt-menu="true"
    >
      <div className="dropdown-content">
        {data?.map((item, index) => {
          return (
            <div className="menu-item" key={index}>
              <a
                className={`dropdown-item menu-link mb-0 ${selectedValue.id === item.id ? 'text-primary' : ''}`}
                onClick={() => {
                  onSelect(item.id);
                }}
              >
                <FlexBox className="w-100 justify-content-between align-items-center">
                  <div>{item.name}</div>
                </FlexBox>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FilterMenuDropdown;
