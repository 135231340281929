import { gql } from '@apollo/client';

export const DELETE_PRODUCT_IN_SHOW = gql`
  mutation deleteProductInShow($input: DeleteLiveShowProductInput!) @api(name: "liveStreamingAPI") {
    deleteProductFromLiveShow(input: $input) {
      id
      success
      message
    }
  }
`;
