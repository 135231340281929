import { useState, FC } from 'react';

// Components
import { FlexBox, Img } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import { DefaultImage, DeleteButtonIcon, DeleteHoverButtonIcon, DragIcon } from 'src/assets/icons';

// Types
import { LiveProductInfoProps } from './LiveProductInfoProps.types';

// Styles
import './_liveProductInfo.scss';

const LiveProductInfo: FC<LiveProductInfoProps> = ({ productInfo, indexNumber, deleteProduct }) => {
  // State params
  const [isHovered, setIsHovered] = useState<boolean>(false);
  // Event Handlers
  const showProductDetail = () => {
    window.open(`${window.location.origin}/products/allProducts/edit/${productInfo.productId}`, '_blank');
  };
  return (
    <div className="live-product-info-div">
      <div className="card d-flex align-items-center poppins-semibold product-row p-3 justify-content-between live-product-roww">
        <FlexBox className="align-items-center">
          <KTSVG path={DragIcon} className="me-2 d-flex align-items-center" />
          <div className="symbol me-2">
            <Img
              src={setImageSrc(productInfo?.productImg ? generateCompressedImageURL(productInfo?.productImg, '50') : DefaultImage)}
              placeholderImg={!productInfo.productImg && DefaultImage}
              errorImg={DefaultImage}
              className="object-fit-scale-down bg-white border border-light border-2"
            />
          </div>
          <div className="d-flex product-title-info align-items-start">
            <span className="product-name-class cursor-pointer" onClick={showProductDetail}>
              {productInfo.productName}
            </span>
            <span className="fs-8 poppins-regular">${productInfo.productPrice}</span>
          </div>
        </FlexBox>
        <FlexBox>
          <div className="d-flex product-id p-2 ps-4 pe-4 greyText">
            <span className="fs-8 poppins-regular fs-7">{indexNumber}</span>
          </div>
          <FlexBox className="align-items-center ms-3">
            <div
              className=" d-flex align-items-center justify-content-center cursor-pointer"
              onClick={() => deleteProduct(productInfo.productId)}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {isHovered ? (
                <img src={DeleteHoverButtonIcon} className="px-2 h-32px w-32px" />
              ) : (
                <img src={DeleteButtonIcon} className="px-2 h-32px w-32px" />
              )}
            </div>
          </FlexBox>
        </FlexBox>
      </div>
    </div>
  );
};

export default LiveProductInfo;
