/* eslint-disable id-blacklist */
import { FC, useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { Column, DynamicDataSheetGrid, keyColumn, textColumn, CellComponent } from 'react-datasheet-grid';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { GET_TAGS_LIST, GET_PRODUCT_TYPES, GET_COLLECTIONS, GET_STORE_DETAILS } from 'src/apollo/queries';
import { EDIT_PRODUCTS } from 'src/apollo/mutations';

// Components
import { Loader } from 'src/components/atoms';
import { EditColumn } from 'src/components/molecules';
import {
  ProductName,
  CollectionCell,
  ProductTypeCell,
  TagCell,
  StatusCell,
  PriceFormatCell,
  PercentFormatCell,
  DimensionInputCell,
  CheckboxCell
} from './components';
import { floatColumn, percentColumn, dimensionColumn } from './columns';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { CloseIcon, MinimiseBulkEditor, StretchIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { ColumnType } from 'src/components/molecules/Table/Table.types';
import {
  NewBulkEditorModalProps,
  ProductDetailType,
  productDataType,
  TagItemType,
  CollectionItemType
} from 'src/components/oraganisms/NewBulkEditorModal/NewBulkEditorModal.types';
import { DimensionOptions } from 'src/components/pages/products/AddProduct/AddProduct.types';

// Styles
import 'react-datasheet-grid/dist/style.css';
import './_newBulkEditorModal.scss';

const initialBulkEditorColumns: ColumnType[] = [
  { id: 'collections', name: 'Collection', status: true },
  { id: 'autoAssignPrimaryVideo', name: 'Set recent recording as primary', status: true },
  { id: 'productType', name: 'Type', status: true },
  { id: 'tags', name: 'Tag', status: true },
  { id: 'status', name: 'Status', status: true },
  { id: 'salePrice', name: 'Sale price', status: true },
  { id: 'retailPrice', name: 'Retail price', status: true },
  { id: 'margin', name: 'Margin', status: true },
  { id: 'profit', name: 'Profit', status: true },
  { id: 'perItemCost', name: 'Item cost', status: true },
  { id: 'taxCode', name: 'Tax code', status: true },
  { id: 'isFinalSale', name: 'Final sale', status: true },
  { id: 'shippingPrice', name: 'Shipping cost', status: true },
  { id: 'weight', name: 'Weight', status: true },
  { id: 'width', name: 'Width', status: true },
  { id: 'height', name: 'Height', status: true },
  { id: 'depth', name: 'Depth', status: true },
  { id: 'isFreeShipping', name: 'Free shipping', status: true },
  { id: 'addSignatureConfirmation', name: 'Add signature confirmation', status: true },
  { id: 'addShipmentInsurance', name: 'Add shipment insurance', status: true },
  { id: 'sensitive', name: 'Sensitive product', status: true }
];

const NewBulkEditorModal: FC<NewBulkEditorModalProps> = ({ show, data, closeModal, updateProductList }) => {
  const [expandedView, setExpandedView] = useState(false);
  const [openEditColumnDropdown, setOpenEditColumnDropdown] = useState(false);
  const [product, setProduct] = useState<ProductDetailType[]>(data);

  const [editColumnData, setEditColumnData] = useState<ColumnType[]>([]);
  const [activeColumns, setActiveColumns] = useState<ColumnType[]>([]);

  const [updatedRowIDs, setUpdatedRowIDs] = useState<number[]>([]);
  const [storeMeasurementUnit, setStoreMeasurementUnit] = useState<string>('');
  const [storeWeightUnit, setStoreWeightUnit] = useState<string>('');
  const [tagsList, setTagsList] = useState<TagItemType[]>([]);
  const [collectionsList, setCollectionsList] = useState<CollectionItemType[]>([]);

  const [getTagsList] = useLazyQuery(GET_TAGS_LIST, {
    variables: {
      input: {
        filters: {
          searchText: ''
        }
      }
    },
    onCompleted: (res) => {
      if (res?.getTagList) {
        setTagsList(res?.getTagList);
      }
    },
    onError: (err) => {
      console.log('error::', err);
    }
  });

  const [getCollectionsList] = useLazyQuery(GET_COLLECTIONS, {
    variables: {
      input: {
        setDroppableByStatus: false
      }
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (res) => {
      if (res?.getCollections?.collections) {
        setCollectionsList(res?.getCollections?.collections);
      }
    },
    onError: (err) => {
      console.log('error::', err);
    }
  });

  const { data: typeDropdownData } = useQuery(GET_PRODUCT_TYPES, {
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    const tableColumns = localStorage.getItem('bulkEditorTable');
    if (tableColumns) {
      setEditColumnData(JSON.parse(tableColumns));
    } else {
      setEditColumnData(initialBulkEditorColumns);
    }

    getTagsList();
    getCollectionsList();
  }, []);

  useEffect(() => {
    if (editColumnData?.length > 0) {
      const arr = Array.from(editColumnData);
      editColumnData.map((column, index) => {
        if (!column.status) {
          arr.splice(arr.indexOf(column), 1);
        }
        return null;
      });
      setActiveColumns([...arr]);
      localStorage.setItem('bulkEditorTable', JSON.stringify(editColumnData));
    }
  }, [editColumnData]);

  const [getStoreDetails] = useLazyQuery(GET_STORE_DETAILS, {
    onCompleted: (res) => {
      if (res?.getStoreDetails) {
        setStoreMeasurementUnit(res?.getStoreDetails?.unitOfMeasurement);
        setStoreWeightUnit(res?.getStoreDetails?.weightUnit);
      }
    }
  });

  useEffect(() => {
    getStoreDetails();
  }, []);

  const [callEditProducts, { loading: isBulkEditing }] = useMutation(EDIT_PRODUCTS, {
    onCompleted: (res) => {
      if (res) {
        document.body.classList.remove('modal-open');
        const updatedProducts = product.filter((productItem) => updatedRowIDs.includes(productItem.productId));
        updateProductList && updateProductList(updatedProducts);
        closeModal();
      }
    },
    onError: (err) => {
      console.log('err::', err);
    }
  });

  const isReadyData = useMemo(() => {
    if (tagsList && collectionsList && typeDropdownData?.getAllProductTypes) {
      return true;
    } else {
      return false;
    }
  }, [tagsList, collectionsList, typeDropdownData]);

  const handleUpdateTagsList = (newTag) => {
    setTagsList((tagsList) => {
      return [newTag, ...tagsList];
    });
  };

  const handleUpdateCollectionsList = (newCollection) => {
    setCollectionsList((collectionsList) => {
      return [newCollection, ...collectionsList];
    });
  };

  // Type your columns to get type checks in your IDE
  const initialColumns: Column[] = [
    {
      id: 'productName',
      title: 'Product',
      component: ProductName as CellComponent,
      minWidth: 300
    },
    {
      id: 'collections',
      title: 'Collection',
      component: CollectionCell as CellComponent,
      minWidth: 180,
      columnData: {
        collectionsList,
        updateCollectionsList: handleUpdateCollectionsList
      },
      copyValue: ({ rowData }) => {
        return JSON.stringify(rowData?.collectionIds);
      },
      pasteValue: ({ rowData, value }) => {
        return { ...rowData, collectionIds: JSON.parse(value) };
      }
    },
    {
      id: 'autoAssignPrimaryVideo',
      title: 'Set recent recording as primary',
      cellClassName: 'justify-content-center',
      headerClassName: 'text-center',
      columnData: {
        key: 'autoAssignPrimaryVideo'
      },
      component: CheckboxCell as CellComponent,
      copyValue: ({ rowData }) => {
        return rowData?.autoAssignPrimaryVideo;
      },
      pasteValue: ({ rowData, value }) => {
        const autoAssignPrimaryVideo = JSON.parse(value);
        return { ...rowData, autoAssignPrimaryVideo };
      }
    },
    {
      id: 'productType',
      title: 'Type',
      component: ProductTypeCell as CellComponent,
      minWidth: 120,
      columnData: typeDropdownData,
      copyValue: ({ rowData }) => {
        const productTypeObj = {
          productTypeId: rowData?.productTypeId,
          productTypeName: rowData?.productTypeName
        };
        return JSON.stringify(productTypeObj);
      },
      pasteValue: ({ rowData, value }) => {
        const productTypeObj = JSON.parse(value);
        return { ...rowData, productTypeId: productTypeObj?.productTypeId, productTypeName: productTypeObj?.productTypeName };
      }
    },
    {
      id: 'tags',
      title: 'Tags',
      component: TagCell as CellComponent,
      columnData: {
        tagsList,
        updateTagsList: handleUpdateTagsList
      },
      minWidth: 180,
      copyValue: ({ rowData }) => {
        return JSON.stringify(rowData?.productTagsJson?.map((tag) => tag.id));
      },
      pasteValue: ({ rowData, value }) => {
        const tagIDs = JSON.parse(value);
        const copiedTags = tagsList?.filter((tag) => tagIDs.includes(tag.id));
        return { ...rowData, productTagsJson: copiedTags };
      }
    },
    {
      id: 'status',
      title: 'Status',
      component: StatusCell as CellComponent,
      minWidth: 120,
      copyValue: ({ rowData }) => {
        return rowData?.productStatus;
      },
      pasteValue: ({ rowData, value }) => {
        return { ...rowData, productStatus: value };
      }
    },
    {
      ...keyColumn('salePrice', floatColumn),
      component: PriceFormatCell as CellComponent,
      title: 'Sale Price',
      copyValue: ({ rowData }) => {
        return rowData?.salePrice;
      },
      pasteValue: ({ rowData, value }) => {
        const salePrice = parseFloat(value);
        const profit = salePrice - rowData?.perItemCost;
        const margin = (profit / salePrice) * 100;
        return { ...rowData, salePrice, profit, margin };
      }
    },
    {
      ...keyColumn('retailPrice', floatColumn),
      title: 'Retail Price'
    },
    {
      ...keyColumn('margin', percentColumn),
      title: 'Margin',
      component: PercentFormatCell as CellComponent,
      copyValue: ({ rowData }) => {
        return rowData?.margin;
      },
      pasteValue: ({ rowData, value }) => {
        const margin = parseFloat(value);
        const salePrice = (100 * rowData?.perItemCost) / (100 - margin);
        const profit = salePrice - rowData?.perItemCost;
        return { ...rowData, salePrice, margin, profit };
      }
    },
    {
      ...keyColumn('profit', floatColumn),
      title: 'Profit',
      component: PriceFormatCell as CellComponent,
      copyValue: ({ rowData }) => {
        return rowData?.profit;
      },
      pasteValue: ({ rowData, value }) => {
        const profit = parseFloat(value);
        const salePrice = profit + rowData?.perItemCost;
        const margin = (profit / salePrice) * 100;
        return { ...rowData, salePrice, margin, profit };
      }
    },
    {
      ...keyColumn('perItemCost', floatColumn),
      component: PriceFormatCell as CellComponent,
      title: 'Item cost',
      copyValue: ({ rowData }) => {
        return rowData?.perItemCost;
      },
      pasteValue: ({ rowData, value }) => {
        const perItemCost = parseFloat(value);
        const profit = rowData?.salePrice - perItemCost;
        const margin = (profit / rowData?.salePrice) * 100;
        return { ...rowData, profit, margin, perItemCost };
      }
    },
    {
      ...keyColumn('taxCode', textColumn),
      title: 'Tax code'
    },
    {
      id: 'isFinalSale',
      title: 'Final Sale',
      cellClassName: 'justify-content-center',
      headerClassName: 'text-center',
      columnData: {
        key: 'isFinalSale'
      },
      component: CheckboxCell as CellComponent,
      copyValue: ({ rowData }) => {
        return rowData?.isFinalSale;
      },
      pasteValue: ({ rowData, value }) => {
        const isFinalSale = JSON.parse(value);
        return { ...rowData, isFinalSale };
      }
    },
    {
      ...keyColumn('shippingPrice', floatColumn),
      component: PriceFormatCell as CellComponent,
      title: 'Shipping cost',
      copyValue: ({ rowData }) => {
        return rowData?.shippingPrice;
      },
      pasteValue: ({ rowData, value }) => {
        const shippingPrice = parseFloat(value);
        return { ...rowData, shippingPrice, isFreeShipping: shippingPrice > 0 ? false : true };
      }
    },
    {
      title: 'Weight',
      ...keyColumn('weight', dimensionColumn),
      component: DimensionInputCell as CellComponent,
      minWidth: 130,
      columnData: {
        key: 'weight',
        unit: storeWeightUnit
      },
      copyValue: ({ rowData }) => {
        const dimensions = JSON.parse(rowData?.dimensions);
        return JSON.stringify(dimensions?.weight);
      },
      pasteValue: ({ rowData, value }) => {
        const dimensions = JSON.parse(rowData?.dimensions);
        const updatedDimensions = { ...dimensions, weight: JSON.parse(value) };
        return { ...rowData, dimensions: JSON.stringify(updatedDimensions) };
      }
    },
    {
      title: 'Width',
      ...keyColumn('width', dimensionColumn),
      component: DimensionInputCell as CellComponent,
      minWidth: 130,
      columnData: {
        key: 'width',
        unit: storeMeasurementUnit
      },
      copyValue: ({ rowData }) => {
        const dimensions = JSON.parse(rowData?.dimensions);
        return JSON.stringify(dimensions?.width);
      },
      pasteValue: ({ rowData, value }) => {
        const dimensions = JSON.parse(rowData?.dimensions);
        const updatedDimensions = { ...dimensions, width: JSON.parse(value) };
        return { ...rowData, dimensions: JSON.stringify(updatedDimensions) };
      }
    },
    {
      title: 'Height',
      ...keyColumn('height', dimensionColumn),
      component: DimensionInputCell as CellComponent,
      minWidth: 130,
      columnData: {
        key: 'height',
        unit: storeMeasurementUnit
      },
      copyValue: ({ rowData }) => {
        const dimensions = JSON.parse(rowData?.dimensions);
        return JSON.stringify(dimensions?.height);
      },
      pasteValue: ({ rowData, value }) => {
        const dimensions = JSON.parse(rowData?.dimensions);
        const updatedDimensions = { ...dimensions, height: JSON.parse(value) };
        return { ...rowData, dimensions: JSON.stringify(updatedDimensions) };
      }
    },
    {
      title: 'Depth',
      ...keyColumn('depth', dimensionColumn),
      component: DimensionInputCell as CellComponent,
      minWidth: 130,
      columnData: {
        key: 'depth',
        unit: storeMeasurementUnit
      },
      copyValue: ({ rowData }) => {
        const dimensions = JSON.parse(rowData?.dimensions);
        return JSON.stringify(dimensions?.depth);
      },
      pasteValue: ({ rowData, value }) => {
        const dimensions = JSON.parse(rowData?.dimensions);
        const updatedDimensions = { ...dimensions, depth: JSON.parse(value) };
        return { ...rowData, dimensions: JSON.stringify(updatedDimensions) };
      }
    },
    {
      id: 'isFreeShipping',
      title: 'Free shipping',
      cellClassName: 'justify-content-center',
      headerClassName: 'text-center',
      columnData: {
        key: 'isFreeShipping'
      },
      component: CheckboxCell as CellComponent,
      copyValue: ({ rowData }) => {
        return rowData?.isFreeShipping;
      },
      pasteValue: ({ rowData, value }) => {
        const isFreeShipping = JSON.parse(value);
        return { ...rowData, isFreeShipping, shippingPrice: isFreeShipping ? 0 : rowData.shippingPrice };
      }
    },
    {
      id: 'addSignatureConfirmation',
      title: 'Add signature confirmation',
      minWidth: 120,
      cellClassName: 'justify-content-center',
      headerClassName: 'text-center',
      columnData: {
        key: 'addSignatureConfirmation'
      },
      component: CheckboxCell as CellComponent,
      copyValue: ({ rowData }) => {
        return rowData?.addSignatureConfirmation;
      },
      pasteValue: ({ rowData, value }) => {
        const addSignatureConfirmation = JSON.parse(value);
        return { ...rowData, addSignatureConfirmation };
      }
    },
    {
      id: 'addShipmentInsurance',
      title: 'Add shipment insurance',
      cellClassName: 'justify-content-center',
      headerClassName: 'text-center',
      columnData: {
        key: 'addShipmentInsurance'
      },
      component: CheckboxCell as CellComponent,
      copyValue: ({ rowData }) => {
        return rowData?.addShipmentInsurance;
      },
      pasteValue: ({ rowData, value }) => {
        const addShipmentInsurance = JSON.parse(value);
        return { ...rowData, addShipmentInsurance };
      }
    },
    {
      id: 'sensitive',
      title: 'Sensitive product',
      cellClassName: 'justify-content-center',
      headerClassName: 'text-center',
      columnData: {
        key: 'sensitive'
      },
      component: CheckboxCell as CellComponent,
      copyValue: ({ rowData }) => {
        return rowData?.sensitive;
      },
      pasteValue: ({ rowData, value }) => {
        const sensitive = JSON.parse(value);
        return { ...rowData, sensitive };
      }
    }
  ];

  const columns: Column[] = useMemo(() => {
    const activeColumnIDs = activeColumns?.map((column) => column.id);
    // eslint-disable-next-line array-callback-return
    const filteredColumns = initialColumns
      ?.filter((column) => {
        if (column.id === 'productName' || activeColumnIDs?.includes(column?.id)) return true;
        return false;
      })
      .sort(function (a, b) {
        return activeColumnIDs.indexOf(a.id) - activeColumnIDs.indexOf(b.id);
      });
    return filteredColumns;
  }, [tagsList, collectionsList, typeDropdownData, activeColumns, storeMeasurementUnit, storeWeightUnit]);

  const handleChangeTable = (newValue, operations) => {
    for (const operation of operations) {
      console.log('newValue::', newValue, 'operations::', operations);
      if (operation.type === 'UPDATE') {
        newValue.slice(operation.fromRowIndex, operation.toRowIndex).forEach(({ productId }) => {
          setUpdatedRowIDs((updatedRowIDs) => {
            if (!updatedRowIDs.includes(productId)) {
              return [...updatedRowIDs, productId];
            }
            return updatedRowIDs;
          });
        });
      }
    }
    setProduct(newValue);
  };

  const handleSaveBulkProducts = () => {
    const arr: Array<productDataType> = product
      ?.filter((productItem) => updatedRowIDs.includes(productItem.productId))
      ?.map((product) => {
        const tags: Array<number> = [];
        product?.productTagsJson?.map((tag) => {
          tags.push(tag.id);
          return null;
        });
        const dimensionsData: DimensionOptions = {
          height: {
            value: '',
            unit: ''
          },
          width: {
            value: '',
            unit: ''
          },
          depth: {
            value: '',
            unit: ''
          },
          weight: {
            value: '',
            unit: ''
          }
        };
        const obj: productDataType = {
          productIds: [],
          status: '',
          tagIds: [],
          collectionIds: [],
          productTypeId: 0,
          salePrice: 0,
          retailPrice: 0,
          perItemCost: 0,
          name: '',
          taxCode: '',
          shippingPrice: 0,
          isFinalSale: false,
          addSignatureConfirmation: false,
          addShipmentInsurance: false,
          sensitive: false,
          dimensions: JSON.stringify(dimensionsData),
          autoAssignPrimaryVideo: false
        };

        obj.productIds[0] = product.productId;
        obj.name = product.productName;
        obj.status = product.productStatus;
        obj.tagIds = tags;
        obj.collectionIds = product?.collectionIds;
        obj.salePrice = product.salePrice ? product.salePrice : 0;
        obj.retailPrice = product.retailPrice ? product.retailPrice : 0;
        obj.perItemCost = product.perItemCost ? product.perItemCost : 0;
        obj.taxCode = product.taxCode ? product.taxCode : '';
        obj.shippingPrice = product.shippingPrice && !product.isFreeShipping ? product.shippingPrice : 0;
        obj.isFinalSale = product.isFinalSale ? product.isFinalSale : false;
        obj.addSignatureConfirmation = product.addSignatureConfirmation ? product.addSignatureConfirmation : false;
        obj.addShipmentInsurance = product.addShipmentInsurance ? product.addShipmentInsurance : false;
        obj.sensitive = product.sensitive ? product.sensitive : false;
        obj.dimensions = product.dimensions ? product.dimensions : JSON.stringify(dimensionsData);
        obj.productTypeId = product.productTypeId ? product.productTypeId : null;
        obj.autoAssignPrimaryVideo = product.autoAssignPrimaryVideo;

        return obj;
      });

    callEditProducts({
      variables: {
        input: arr
      }
    });
  };

  return (
    <div>
      <div
        className={clsx('modal fade opacity-100 bulk-editor-modal', {
          show
        })}
        aria-modal="true"
        tabIndex={-1}
        id="kt_modal_1"
      >
        <div className={`modal-dialog modal-dialog-centered flex-center ${expandedView ? 'm-0' : ''}`}>
          <div className={`modal-content ${expandedView ? 'vh-100 vw-100 rounded-0' : 'w-1068px'}`}>
            <div className="modal-header justify-content-between">
              <h5 className="modal-title">{constVariables.AllProductsMenu.bulkEditor}</h5>
              <div className="d-flex align-items-center">
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setOpenEditColumnDropdown(false);
                  }}
                >
                  <div className="position-relative">
                    <div
                      className="text-primary cursor-pointer text-btn"
                      onClick={() => setOpenEditColumnDropdown(!openEditColumnDropdown)}
                    >
                      {constVariables.AllProductsMenu.editColumns}
                    </div>
                    <EditColumn
                      show={openEditColumnDropdown ? openEditColumnDropdown : false}
                      columns={editColumnData}
                      setEditColumnData={setEditColumnData}
                      allSwitchable={true}
                    />
                  </div>
                </OutsideClickHandler>
                <img
                  src={expandedView ? MinimiseBulkEditor : StretchIcon}
                  className="ms-2 me-2 cursor-pointer h-15px w-15px"
                  onClick={() => {
                    setExpandedView(!expandedView);
                  }}
                />
                <div
                  className="btn btn-xs btn-active-light-primary p-0 m-0 border-none"
                  onClick={() => {
                    document.body.style.overflow = 'unset';
                    closeModal();
                  }}
                >
                  <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
                </div>
              </div>
            </div>

            <div>
              <div className={`bulk-editor-content ${expandedView ? 'expanded-bulk-editor' : ''}`}>
                {isReadyData ? (
                  <DynamicDataSheetGrid
                    value={product}
                    columns={columns}
                    rowHeight={70}
                    className="bulk-editor-table h-100"
                    // gutterColumn={{ title: 'Product', component: ProductName as CellComponent, basis: 300 }}
                    onChange={handleChangeTable}
                  />
                ) : (
                  <Loader type="page" className="h-100" />
                )}
              </div>
            </div>

            <div className="modal-footer">
              <button className="btn btn-outlined-secondary btn-md me-3" onClick={closeModal} data-bs-dismiss="modal" type="button">
                Cancel
              </button>
              <button
                className="btn btn-primary btn-md"
                type="button"
                disabled={updatedRowIDs?.length === 0 || isBulkEditing}
                onClick={handleSaveBulkProducts}
              >
                {isBulkEditing ? 'Saving' : 'Save'}
                {isBulkEditing && <Loader type="button" className="h-10px w-10px" />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewBulkEditorModal;
