import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';

// Components
import { SelectionCircle } from 'src/components/atoms';
import { Divider } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { MoveWarehouseIcon, CloseIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { DeleteAndMoveWarehouseModalProps } from './Modal.types';

// Styles
import './modalStyles.scss';

const DeleteAndMoveWarehouseModal: FC<DeleteAndMoveWarehouseModalProps> = ({
  cancelBtnHandler,
  actionBtnHandler,
  showDeleteModal
}) => {
  useEffect(() => {
    setSelectedMove(false);
    setSelectedDelete(false);
  }, [showDeleteModal]);

  const [selectedMove, setSelectedMove] = useState(false);
  const [selectedDelete, setSelectedDelete] = useState(false);

  const checkUnCheckMove = () => {
    if (selectedMove) {
      setSelectedMove(false);
    } else {
      setSelectedMove(true);
      setSelectedDelete(false);
    }
  };
  const checkUnCheckDelete = () => {
    if (selectedDelete) {
      setSelectedDelete(false);
    } else {
      setSelectedDelete(true);
      setSelectedMove(false);
    }
  };

  return (
    <div className={clsx('modal-content modal-large-content ')}>
      <div className="w-100 position-relative">
        <KTSVG
          path={CloseIcon}
          className="svg-icon cursor-pointer svg-icon-2x position-absolute top-10"
          onClick={cancelBtnHandler}
        />
        <div className="p-t-40 px-4 text-center ">
          <KTSVG path={MoveWarehouseIcon} className="svg-icon svg-icon-5x my-2" />
          <h5 className="modal-title my-2 fs-2 text-center">{constVariables.common.MoveWarehouse.title}</h5>
        </div>
        <Divider />
        <div className="m-6  ">
          <div className="d-flex align-items-center">
            <SelectionCircle className="me-3" onClick={checkUnCheckMove} isSelected={selectedMove} />
            <div>{constVariables.common.MoveWarehouse.allowMe}</div>
          </div>
          <div className="d-flex">
            <div className="me-3" />
            <div className="helper-text leftPaddingDelMoveModal  fs-8 mt-2">{constVariables.common.MoveWarehouse.allowMeText}</div>
          </div>
        </div>
        <Divider />
        <div className="m-6  ">
          <div className="d-flex align-items-center">
            <SelectionCircle className="me-3" onClick={checkUnCheckDelete} isSelected={selectedDelete} />
            <div>{constVariables.common.MoveWarehouse.deleteThem}</div>
          </div>
          <div className="d-flex">
            <div className="me-3" />
            <div className="helper-text leftPaddingDelMoveModal  fs-8 mt-2">
              {constVariables.common.MoveWarehouse.deleteThemText}
            </div>
          </div>
        </div>
        {selectedDelete ? (
          <div className="modal-footer">
            <button type="button" className="btn btn-outlined-secondary btn-md" data-bs-dismiss="modal" onClick={cancelBtnHandler}>
              Cancel
            </button>
            <button type="button" className="btn btn-danger btn-md" onClick={actionBtnHandler}>
              Delete It Permanently
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DeleteAndMoveWarehouseModal;
