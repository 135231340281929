/* eslint-disable no-nested-ternary */
import { FC } from 'react';

// Components
import { Img } from 'src/components/atoms';
import { RenderImageList } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { getFormattedDate } from 'src/utils/getFormattedDate';
import { getTimediff } from 'src/utils/getTimediff';
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import { Clock, DefaultImage, PencilSimpleLineColor } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

interface LiveSettingsProps {
  toggleLiveShowModal: () => void;
  liveShowPlannedInfo: any;
  liveShowInfo: any;
  nextLiveStream: any;
}

const LiveSettings: FC<LiveSettingsProps> = ({ toggleLiveShowModal, liveShowPlannedInfo, liveShowInfo, nextLiveStream }) => {
  return (
    <div className="overflow-auto hide-scrollbar liveshow-settings min-vh-100">
      <h2 className="poppins-regular heading">{constVariables.LiveShow.liveSettings}</h2>
      <div className="d-flex flex-column">
        <div
          className="d-flex flex-column justify-content-between liveshow-details cursor-pointer"
          onClick={() => toggleLiveShowModal()}
        >
          <img height={24} width={30} src={PencilSimpleLineColor} alt="Pencil" />
          <span className="poppins-regular">
            {liveShowPlannedInfo.title.length > 0
              ? constVariables.LiveShow.editLiveshowDetails
              : liveShowInfo.title.length > 0
              ? constVariables.LiveShow.editLiveshowDetails
              : constVariables.LiveShow.enterLiveshowDetails}
          </span>
        </div>
        {/* <div className="d-flex flex-column justify-content-between liveshow-goals cursor-pointer">
          <img height={24} width={30} src={RocketLaunchColor} alt="Goals" />
          <span className="poppins-regular">{constVariables.LiveShow.setLiveshowGoals}</span>
        </div> */}
      </div>
      {nextLiveStream?.title && (
        <div className="nextlive-container">
          <h2 className="poppins-regular heading">{constVariables.LiveShow.nextLive}</h2>
          <div>
            <div className="nextliveshow-container">
              <div className="image-container-next-show">
                <Img
                  src={setImageSrc(
                    nextLiveStream?.thumbnailUrl ? generateCompressedImageURL(nextLiveStream?.thumbnailUrl, '226') : DefaultImage
                  )}
                  placeholderImg={DefaultImage}
                  errorImg={DefaultImage}
                  height={98}
                  width={59}
                />
              </div>
              <div className="desc-container">
                <RenderImageList nextLiveStream={nextLiveStream} />
                <span>{nextLiveStream?.title}</span>
                <span className="date-time-text">{getFormattedDate(nextLiveStream.startingAt, nextLiveStream.endingAt)}</span>
              </div>
            </div>
            <div className="time-remaining">
              <img src={Clock} alt="clock" />
              <span>{getTimediff(nextLiveStream?.startingAt)}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LiveSettings;
