import { gql } from '@apollo/client';

export const ADD_LIVE_STORE_CREDIT = gql`
  mutation addLiveStoreCredit($input: AddLiveStoreCreditInput!) @api(name: "liveStreamingAPI") {
    addLiveStoreCredit(input: $input) {
      id
      success
      message
    }
  }
`;
