import { FC, useState, createRef } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useMutation } from '@apollo/client';
import { CHANGE_COLLECTION_STATUS } from 'src/apollo/mutations';

// Components
import { FlexBox } from 'src/components/atoms';
import { Dropdown, Portal } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { DownArrowIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { CollectionStatus } from '../../CreateCollection/CreateCollection.types';

interface StatusCellProps {
  id: string | number;
  status?: string;
  updateTreeData?: (updatedCollection) => void;
}

const StatusCell: FC<StatusCellProps> = ({ id, status, updateTreeData }) => {
  const statusRef = createRef<any>();
  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const [dropdownPositions, setDropdownPositions] = useState({
    top: 0,
    left: 0
  });

  const [toggleCollectionOrCatalog] = useMutation(CHANGE_COLLECTION_STATUS, {
    onCompleted: (response) => {
      const updatedCollection = JSON.parse(response.changeCollectionStatus.additionalData);
      updateTreeData && updateTreeData(updatedCollection);
    }
  });

  const handleShowDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (status === CollectionStatus.SCHEDULED) return;
    setIsOptionOpen(true);

    if (statusRef.current) {
      const position = statusRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - position.bottom;
      setDropdownPositions({
        top: spaceBelow < 150 ? window.scrollY + position.top - 115 : window.scrollY + position.top + 35,
        left: position.left - 70
      });
    }
  };

  const statusText = (title: string) => {
    if (title === CollectionStatus.ACTIVE) {
      return 'Active';
    }
    if (title === CollectionStatus.SCHEDULED) {
      return 'Scheduled';
    }
    return 'Inactive';
  };

  const toggleCollcetionStatus = (value, e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOptionOpen(false);

    toggleCollectionOrCatalog({
      variables: {
        input: {
          id,
          status: value.name.toUpperCase()
        }
      }
    });
  };

  const selectCollectionStatusClass = (collectionStatus: string | undefined) => {
    switch (collectionStatus) {
      case 'active':
        return 'active';
      case 'inactive':
        return 'inactive';
      default:
        return 'scheduled';
    }
  };

  return (
    <>
      <FlexBox
        className={`p-2 poppins-medium justify-content-between ${selectCollectionStatusClass(status?.toLowerCase())}-status-box ${
          status === CollectionStatus.ACTIVE || status === CollectionStatus.INACTIVE ? 'cursor-pointer' : ''
        }`}
        onClick={handleShowDropdown}
      >
        <div className="d-flex align-items-center justify-content-between w-100" ref={statusRef}>
          <span>{statusText(status ? status : '')}</span>
          {(status === CollectionStatus.ACTIVE || status === CollectionStatus.INACTIVE) && (
            <KTSVG path={DownArrowIcon} className={`m-auto-0 ${selectCollectionStatusClass(status?.toLocaleLowerCase())}-arrow`} />
          )}
        </div>
      </FlexBox>
      {isOptionOpen && (
        <Portal id="kt_body">
          <OutsideClickHandler
            onOutsideClick={() => {
              if (isOptionOpen) setIsOptionOpen(false);
            }}
          >
            <div className="position-absolute" style={{ top: dropdownPositions.top, left: dropdownPositions.left }}>
              <Dropdown
                data={constVariables.activeInactivestatusDropdownData}
                selected={isOptionOpen}
                value={status}
                onSelect={(value, e) => toggleCollcetionStatus(value, e)}
                closeDropdown={() => setIsOptionOpen(false)}
              />
            </div>
          </OutsideClickHandler>
        </Portal>
      )}
    </>
  );
};

export default StatusCell;
