/* eslint-disable no-nested-ternary */
import { FC, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

// Components
import { Img, FlexBox } from 'src/components/atoms';
import { FileInput, ColorPicker, Tooltip } from 'src/components/molecules';
import { CustomModal, ImageCropModal } from 'src/components/oraganisms';
import { SplashScreen, SigninScreen } from './pages';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';
import { KTSVG } from 'src/helpers';

// Icons
import {
  AddVariantImageSmallIcon,
  AcceptDraggedFile,
  DefaultImage,
  CrossIcon,
  AppArrowLeftIcon,
  AppArrowRightIcon
} from 'src/assets/icons';

// Types
import { ScreensProps, fadeAnimationHandler, AppCustomizationStatus } from '../AppCustomizer.types';

const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.svg)$/i;

const pages = [
  { id: 1, name: 'Splash Screen' },
  { id: 2, name: 'Sign In Screen' }
];

const IntroScreens: FC<ScreensProps> = ({ appTheme, updateTheme, generalError, setGeneralError }) => {
  const [isDragActive, setIsDragActive] = useState(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [showCropImageModal, setShowCropImageModal] = useState(false);
  const [tempLogo, setTempLogo] = useState<string | ArrayBuffer>('');
  const [currentPage, setCurrentPage] = useState(pages[0]);

  const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    handleSetFiles(files);
  };

  const handleSetFiles = async (files) => {
    if (files.length > 1) {
      setGeneralError && setGeneralError('You can only drop one image.');
      return;
    }

    for (let i = 0; i < files.length; i++) {
      if (!allowedExtensions.exec(files[i].name)) {
        setIsDragActive(false);
        setGeneralError &&
          setGeneralError(`${files[i].name} is not a supported file type. Upload files ending with: jpg, jpeg, png, svg.`);
        return;
      }
    }

    setGeneralError && setGeneralError('');

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      if (reader.result) {
        setTempLogo(reader.result);
      }
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
    setShowCropImageModal(true);
  };

  const handleUpdateColor = (color) => {
    updateTheme('bgColor', color);
  };

  const handleDeleteLogo = () => {
    setGeneralError && setGeneralError('');
    updateTheme('appLogo', { url: '', fileImage: '' });
  };

  const closeCropImageModal = () => {
    document.body.style.overflow = 'unset';
    setShowCropImageModal(false);
  };

  const handleUpdateLogo = (imageData) => {
    updateTheme('appLogo', imageData);
  };

  const handleChangeSlide = (index) => {
    const currentItem = pages.find((page) => page.id === index + 1);
    if (currentItem) {
      setCurrentPage(currentItem);
    }
  };

  const handleDiscardLogo = () => {
    if (appTheme.appLogo.fileImage === '' && appTheme.appLogo.url === '') {
      updateTheme('appLogo', { url: '', fileImage: '' });
    }
    closeCropImageModal();
  };

  const renderScreen = (pageName: string) => {
    switch (pageName) {
      case 'Splash Screen':
        return <SplashScreen bgColor={appTheme.bgColor} logo={appTheme.appLogo} />;
      case 'Sign In Screen':
        return (
          <SigninScreen logo={appTheme.appLogo} primaryColor={appTheme.primaryColor} secondaryColor={appTheme.secondaryColor} />
        );
      default:
        return <></>;
    }
  };

  const handleOpenImageCropModal = (logo) => {
    setTempLogo(logo);
    setShowCropImageModal(true);
  };

  return (
    <>
      <FlexBox className="app-customizer-layout align-items-center justify-content-between">
        <div className="left-pane">
          <div className="card intro-screens m-0">
            <h3>Intro Screens</h3>
            <p className="m-0 section-description">
              Once you save changes, your customers need to update their their app to see changes applied.
            </p>
            <div className="intro-screens-section">
              <h5 className="poppins-medium">Upload your logo</h5>
              {appTheme?.appLogo?.url || appTheme?.appLogo?.fileImage ? (
                <>
                  <button
                    className="logo-btn"
                    onMouseEnter={() => setShowDeleteIcon(true)}
                    onMouseLeave={() => setShowDeleteIcon(false)}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleOpenImageCropModal(
                        appTheme.appLogo.fileImage ? appTheme.appLogo.fileImage : appTheme.appLogo.url ? appTheme.appLogo.url : ''
                      );
                    }}
                    data-tip
                    data-for="inReviewStatusIndicator"
                    disabled={appTheme.status === AppCustomizationStatus.UNDER_REVIEW}
                  >
                    <Img
                      src={setImageSrc(
                        appTheme.appLogo.fileImage
                          ? appTheme.appLogo.fileImage
                          : appTheme.appLogo.url
                          ? appTheme.appLogo.url
                          : DefaultImage
                      )}
                      placeholderImg={DefaultImage}
                      errorImg={DefaultImage}
                      className="cursor-pointer logo-img img-preview"
                    />
                    {showDeleteIcon && (
                      <KTSVG
                        path={CrossIcon}
                        className="cursor-pointer svg-icon-8 svg-icon-gray-500 me-1 logo-remove-icon"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleDeleteLogo();
                        }}
                      />
                    )}
                  </button>
                  {appTheme.status === AppCustomizationStatus.UNDER_REVIEW && (
                    <Tooltip
                      tooltipId="inReviewStatusIndicator"
                      place="bottom"
                      className="app-customization-status-tooltip"
                      content={() => {
                        return (
                          <>
                            App changes are currently under review. You won't be able to make further edits until these changes are
                            published.
                          </>
                        );
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  <FileInput
                    className={`logo-uploader ${isDragActive ? 'active' : ''}`}
                    handleSetFiles={handleSetFiles}
                    isDragActive={isDragActive}
                    setIsDragActive={setIsDragActive}
                  >
                    <input
                      type="file"
                      id="appLogoFileInput"
                      className="file-upload-input custor-pointer"
                      onChange={handleLogoChange}
                      value={
                        appTheme.appLogo.fileImage ? appTheme.appLogo.fileImage : appTheme.appLogo.url ? appTheme.appLogo.url : ''
                      }
                    />
                    <img
                      src={!isDragActive ? AddVariantImageSmallIcon : AcceptDraggedFile}
                      className={`uploadIcon ${isDragActive ? 'accepted' : ''}`}
                    />
                    <p className="mt-4 text-primary">Upload Image</p>
                  </FileInput>
                  <div className="col-sm text-center greyText file-extension">
                    <p>File should be *.png,*.jpg, or *.svg</p>
                  </div>
                </>
              )}
              {generalError && <p className="mt-3 text-danger">{generalError}</p>}
            </div>
            <div className="intro-screens-section">
              <h5 className="poppins-medium">Select background color</h5>
              <ColorPicker
                color={appTheme.bgColor}
                updateColor={handleUpdateColor}
                disabled={appTheme.status === AppCustomizationStatus.UNDER_REVIEW}
                tooltipId="backgroundColorTooltip"
              />
            </div>
          </div>
        </div>
        <div className="right-pane">
          <div className="preview-mode-page">
            <div className="d-flex align-items-center justify-content-center position-relative">
              <Carousel
                infiniteLoop={false}
                showThumbs={false}
                showIndicators={false}
                showStatus={false}
                // emulateTouch
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <button
                      type="button"
                      className="carousel-arrow-icon prev-icon d-flex align-items-center justify-content-center cursor-pointer"
                      onClick={onClickHandler}
                    >
                      <KTSVG path={AppArrowLeftIcon} className="cursor-pointer" />
                    </button>
                  )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                  hasNext && (
                    <button
                      type="button"
                      className="carousel-arrow-icon next-icon d-flex align-items-center justify-content-center cursor-pointer"
                      onClick={onClickHandler}
                    >
                      <KTSVG path={AppArrowRightIcon} className="cursor-pointer" />
                    </button>
                  )
                }
                onChange={handleChangeSlide}
                animationHandler={fadeAnimationHandler}
              >
                {pages.map((page, index) => (
                  <div className="screen-item" key={index}>
                    {renderScreen(page.name)}
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
          <p className="m-0 mt-4 text-center">{currentPage?.name}</p>
        </div>
      </FlexBox>
      {showCropImageModal && (
        <CustomModal bodyClassname="w-90 w-md-150" show={showCropImageModal} closeModal={closeCropImageModal}>
          <ImageCropModal
            closeModal={closeCropImageModal}
            image={tempLogo}
            updateImage={handleUpdateLogo}
            setGeneralError={setGeneralError}
            discardCropImage={handleDiscardLogo}
          />
        </CustomModal>
      )}
    </>
  );
};

export default IntroScreens;
