import {
  LiveShowDetailInfo,
  LiveShowInfoError
} from 'src/components/oraganisms/CreateLiveShowDetailStep/CreateLiveShowDetailStep.types';
import { ProductItem } from 'src/components/molecules/LiveProductInfo/LiveProductInfoProps.types';

export interface CreateLiveShowProps {
  isEditing: boolean;
  resetValue: boolean;
  editLiveInfo: any;
  hideModal(): void;
  setIsUpdated(): void;
  defaultStartTime: string;
}

export interface CreateLiveShowPresentationalProps {
  isEditing: boolean;
  reachedStep: number;
  isDataChanged: boolean;
  liveShowInfo: LiveShowDetailInfo;
  liveShowInfoError: LiveShowInfoError;
  stepCount: number;
  loading: boolean;
  updateStepCount: (countNumber: number) => void;
  editEvent: () => void;
  updateLiveShowInfo: (data: any) => void;
  cancelBtnHandler?: (...args: any) => void;
  addSelectedProducts?: (data: any) => void;
  bucketList?: ProductItem[];
  updateBucketList?: (data: any) => void;
  updateProductList?: (productList: ProductItem[]) => void;
  updateLiveShowInfoErrors: (errorData: LiveShowInfoError) => void;
  textMessageToggle: boolean;
  setTextMessageToggle: (data: any) => void;
  appNotificationToggle: boolean;
  setAppNotificationToggle: (data: any) => void;
  messangerToggle: boolean;
  setMessangerToggle: (data: any) => void;
}

export enum liveEventEnum {
  INSTANT = 'INSTANT',
  SCHEDULED = 'SCHEDULED'
}
