import { gql } from '@apollo/client';

export const CREATE_CATALOG = gql`
  mutation CreateCatalog($input: CatalogInput!) @api(name: "productsAPI") {
    createCatalog(input: $input) {
      id
      success
      message
      catalogueURL
    }
  }
`;
