import { gql } from '@apollo/client';

export const GET_TIKTOK_CATEGORIES = gql`
  query GetCategories @api(name: "tiktokAPI") {
    getCategories {
      id
      l1
      l2
      l3
    }
  }
`;
