import React, { FC, useState, useEffect } from 'react';
import { DownArrowIcon } from 'src/assets/icons';
import { FlexBox } from 'src/components/atoms';
import { KTSVG } from 'src/helpers';
import { Checkbox } from 'src/components/molecules';
import { FilterMenuItemProps } from './InventoryHistotryModal.types';

const FilterMenuItem: FC<FilterMenuItemProps> = ({ title, options, type, filterData, setFilters }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<number[]>([]);

  useEffect(() => {
    setFilteredOptions(filterData);
  }, [filterData]);

  const handleOption = (id: number) => {
    const filteredItems = filteredOptions.includes(id) ? filteredOptions.filter((item) => item !== id) : [...filteredOptions, id];
    setFilteredOptions(filteredItems);
    setFilters((filters) => {
      return { ...filters, ...(type === 'Type' ? { adjustmentType: filteredItems } : { adjustmentOwner: filteredItems }) };
    });
  };

  return (
    <div className="filter-menu-item">
      <FlexBox className="filter-menu-item-header align-items-center justify-content-between">
        <h6 className="m-0 poppins-regular">{title}</h6>
        <KTSVG
          path={DownArrowIcon}
          className={`svg-icon-2 cursor-pointer me-2 ${showOptions ? 'rotate-icon' : ''}`}
          onClick={() => setShowOptions(!showOptions)}
        />
      </FlexBox>
      {showOptions && (
        <div className="filter-menu-item-content">
          {options.map((option, index) => (
            <Checkbox
              key={index}
              labelclassname="filter-menu-checkbox"
              name={option.name}
              value={filteredOptions.includes(option.id)}
              onChangeValue={() => handleOption(option.id)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterMenuItem;
