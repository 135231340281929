import React, { FC, useEffect, useState, createRef } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { toast } from 'react-toastify';
import clsx from 'clsx';

// Apollo
import { useMutation } from '@apollo/client';
import { CREATE_LOCATION, DELETE_LOCATION, UPDATE_LOCATION, ASSOCIATE_LOCATION_PRODUCT } from 'src/apollo/mutations';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { FlexBox } from 'src/components/atoms';
import { CustomModal, DeleteConfirmModal } from 'src/components/oraganisms';
import { FloatingInventoryBoxLayout, EditInventoryLocation, Portal, Toast } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers/components/KTSVG';

// Icons
import { HollowPencilIcon } from 'src/assets/icons';

// Types
import { InventoryLocationProps } from '../InventoryList.types';
import { LocationItem } from 'src/components/pages/inventory/InventoryManagementPage.type';
import { UserRoles } from 'src/components/pages/settings/UserPermissions/UserPermissions.type';

const InventoryLocation: FC<InventoryLocationProps> = ({
  type,
  id,
  productId,
  defaultLocationId,
  locationName,
  showEditIcon,
  selectedWarehouseId,
  updateInventoryList,
  wearhouseLocations
}) => {
  const locationMenuRef = createRef<any>();
  const [locations, setLocations] = useState<LocationItem[]>([]);
  const [showDeleteLocationConfirmModal, setShowDeleteLocationConfirmModal] = useState(false);
  const [selectedOptionItem, setSelectedOptionItem] = useState<LocationItem>();
  const [deleteConfirmMessage, setDeleteConfirmMessage] = useState<string>('');
  const [locationId, setLocationId] = useState<number>();
  const [positionLocationMenu, setPositionLocationMenu] = useState({
    top: 0,
    left: 0
  });
  const [showLocationMenu, setShowLocationMenu] = useState(false);

  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  useEffect(() => {
    if (wearhouseLocations) {
      setLocations(wearhouseLocations);
    }
  }, [wearhouseLocations]);

  useEffect(() => {
    setLocationId(defaultLocationId);
  }, [defaultLocationId]);

  useEffect(() => {
    if (selectedOptionItem) {
      const { totalQuantity, totalProducts, totalProductVariants } = selectedOptionItem;
      if (!totalQuantity && !totalProducts && !totalProductVariants) {
        setDeleteConfirmMessage('');
      } else {
        setDeleteConfirmMessage(
          `This location contains ${
            totalQuantity !== undefined && totalQuantity > 0 ? `${totalQuantity} item${totalQuantity > 1 ? 's' : ''}` : ''
          } from ${
            totalProducts !== undefined && totalProducts > 0 ? `${totalProducts} product${totalProducts > 1 ? 's' : ''}` : ''
          } ${
            totalProducts !== undefined && totalProducts > 0 && totalProductVariants !== undefined && totalProductVariants > 0
              ? ' and'
              : ''
          } 
          ${
            totalProductVariants !== undefined && totalProductVariants > 0
              ? `${totalProductVariants} variant${totalProductVariants > 1 ? 's' : ''}`
              : ''
          }, are you sure you want to delete it?`
        );
      }
    }
  }, [selectedOptionItem]);

  const [createLocation] = useMutation(CREATE_LOCATION, {
    onCompleted: (response) => {
      const { addLocation } = response;
      setLocationId(addLocation.id);
      setLocations((locations) => {
        return [{ id: addLocation.id, name: addLocation.name }, ...(locations || [])];
      });

      toast(
        <Toast title="" message="The location has been added sucessfully" successText="Location added successfully" errorText="" />,
        { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, hideProgressBar: true }
      );
    },
    onError: (error) => {
      toast(
        <Toast
          title=""
          message={`Error occured while adding location: ${error.message}`}
          errorText={error.message}
          successText=""
        />,
        { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, hideProgressBar: true }
      );
    }
  });

  const [deleteLocation] = useMutation(DELETE_LOCATION, {
    onCompleted: (response) => {
      toast(
        <Toast
          title=""
          message={`The ${type} location has been deleted sucessfully`}
          successText={`${type} location deleted successfully`}
          errorText=""
        />,
        { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, hideProgressBar: true }
      );
    },
    onError: (error) => {
      toast(
        <Toast
          title=""
          message={`Error occured while deleting ${type} location: ${error.message}`}
          errorText={error.message}
          successText=""
        />,
        { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, hideProgressBar: true }
      );
    }
  });

  const [updateLocation] = useMutation(UPDATE_LOCATION, {
    onCompleted: (response) => {
      if (response) {
        const { updateLocation } = response;
        setLocationId(updateLocation.id);
        const updatedLocations = locations?.map((location) => {
          if (location.id === updateLocation.id) {
            return { ...location, name: updateLocation.name };
          }
          return location;
        });
        setLocations(updatedLocations);
        updateInventoryList && updateInventoryList(updateLocation, 'changeLocations');
        toast(
          <Toast
            title=""
            message={`The ${type} location has been updated sucessfully`}
            successText={`${type} location updated successfully`}
            errorText=""
          />,
          { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, hideProgressBar: true }
        );
      }
    },
    onError: (error) => {
      toast(
        <Toast
          title=""
          message={`Error occured while updating ${type} location: ${error.message}`}
          errorText={error.message}
          successText=""
        />,
        { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, hideProgressBar: true }
      );
    }
  });

  const [associateLocationProduct] = useMutation(ASSOCIATE_LOCATION_PRODUCT, {
    onCompleted: (response) => {
      const updatedInventory = JSON.parse(response.associateLocationProduct.additionalData);
      updateInventoryList &&
        updateInventoryList(updatedInventory, type === 'PRODUCT' ? 'productLocation' : 'variantLocation', productId);
      setShowLocationMenu(false);
      toast(
        <Toast
          title=""
          message={`The ${type} inventory location has been added sucessfully`}
          successText={`${type} Inventory Location added successfully`}
          errorText=""
        />,
        { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, hideProgressBar: true }
      );
    },
    onError: (error) => {
      toast(
        <Toast
          title=""
          message={`Error occured while adding ${type} inventory location: ${error.message}`}
          errorText={error.message}
          successText=""
        />,
        { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, hideProgressBar: true }
      );
    }
  });

  const handleAddLocation = (name: string) => {
    createLocation({
      variables: {
        input: {
          name,
          warehouseId: selectedWarehouseId
        }
      }
    });
  };

  const handleUpdateLocation = (id: number, name: string) => {
    if (selectedWarehouseId) {
      updateLocation({
        variables: {
          input: {
            id,
            name,
            warehouseId: selectedWarehouseId
          }
        }
      });
    }
  };

  const handleDeleteLocation = (id: number) => {
    deleteLocation({
      variables: {
        input: {
          id
        }
      }
    });
    const newLocations = locations.filter((item) => item.id !== id);
    setLocations(newLocations);
    const deletedLocation = { id };
    updateInventoryList && updateInventoryList(deletedLocation, 'deleteLocations');
  };

  const handleSaveLocationInventory = (id: number) => {
    associateLocationProduct({
      variables: {
        input: {
          id,
          locationId,
          type,
          warehouseId: selectedWarehouseId
        }
      }
    });
  };

  const handleShowLocationMenu = () => {
    setShowLocationMenu(true);
    if (locationMenuRef.current) {
      const position = locationMenuRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - position.bottom;
      setPositionLocationMenu({
        top: parseInt(spaceBelow < 230 ? window.scrollY + position.top - 235 : window.scrollY + position.top + 40, 10),
        left: parseInt(position.left, 10) - 190
      });
    }
  };

  const closeConfirmModal = () => {
    document.body.style.overflow = 'unset';
    setShowDeleteLocationConfirmModal(false);
  };

  return (
    <>
      <FlexBox className="align-items-center">
        <div className="inventory-location-cell">{locationName}</div>
        {role !== UserRoles.FULFILMENT_ASSOCIATE && (
          <div className="position-relative p-4 userLocation">
            {showEditIcon && (
              <div
                onClick={handleShowLocationMenu}
                className={`${showLocationMenu ? 'pencilSelected' : 'pencilHover'} p-8 rounded m-l-8`}
                ref={locationMenuRef}
              >
                <KTSVG
                  path={HollowPencilIcon}
                  svgColor={showLocationMenu ? '#00A3FF' : ''}
                  className="cursor-pointer d-flex align-items-center"
                />
              </div>
            )}
          </div>
        )}
        {showLocationMenu && (
          <Portal id="kt_body">
            <OutsideClickHandler
              onOutsideClick={() => {
                if (showDeleteLocationConfirmModal) return;
                if (!selectedOptionItem && showLocationMenu) {
                  setShowLocationMenu(false);
                }
              }}
            >
              <div
                className={clsx('menu menu-sub menu-background menu-sub-dropdown menu-column menu-rounded position-absolute', {
                  show: showLocationMenu
                })}
                style={{ top: positionLocationMenu.top, left: positionLocationMenu.left, width: 220 }}
              >
                <FloatingInventoryBoxLayout
                  disableSaveButton={!id}
                  onCancelClick={() => {
                    if (showLocationMenu) {
                      setShowLocationMenu(false);
                    }
                  }}
                  title="Edit Location"
                  onActionClick={() => {
                    if (id) {
                      handleSaveLocationInventory(id);
                      if (showLocationMenu) {
                        setShowLocationMenu(false);
                      }
                    }
                  }}
                >
                  <EditInventoryLocation
                    locations={locations}
                    selectedLocation={selectedOptionItem}
                    setSelectedLocation={setSelectedOptionItem}
                    handleAddLocation={handleAddLocation}
                    handleUpdateLocation={handleUpdateLocation}
                    handleDeleteLocation={handleDeleteLocation}
                    handleShowDeleteConfirmModal={() => setShowDeleteLocationConfirmModal(true)}
                    locationId={locationId}
                    setLocationId={setLocationId}
                  />
                </FloatingInventoryBoxLayout>
              </div>
            </OutsideClickHandler>
          </Portal>
        )}
      </FlexBox>
      {showDeleteLocationConfirmModal && (
        <CustomModal show={showDeleteLocationConfirmModal} closeModal={closeConfirmModal}>
          <DeleteConfirmModal
            title="Delete Location"
            message={deleteConfirmMessage}
            actionBtnTitle="Yes, Delete"
            cancelBtnTitle="Cancel"
            actionBtnHandler={() => selectedOptionItem && handleDeleteLocation(selectedOptionItem?.id)}
            cancelBtnHandler={closeConfirmModal}
          />
        </CustomModal>
      )}
    </>
  );
};

export default React.memo(InventoryLocation);
