/* eslint-disable no-nested-ternary */
import { FC, useEffect, useState } from 'react';

// Components
import CreateUnplannedLiveShowPresentational from './CreateUnplannedLiveshow.presentational';

// Hooks && Utils && Helpers
// import { useToast } from 'src/utils/hooks/useToast';

// Types
import { CreateUnplannedLiveShowProps, UnplannedLiveShowErrorInfo } from './CreateUnplannedLiveshow.types';
import { liveEventEnum } from '../CreateLiveShow/CreateLiveShow.types';
import { ProductItem } from 'src/components/molecules/LiveProductInfo/LiveProductInfoProps.types';
// import { LiveShowDetailInfo } from 'src/components/oraganisms/CreateLiveShowDetailStep/CreateLiveShowDetailStep.types';
import { UnplannedLiveShowDetailInfo } from './CreateUnplannedLiveshow.types';

const CreateUnplannedLiveshow: FC<CreateUnplannedLiveShowProps> = ({
  toggleLiveShowModal,
  liveShowInfo,
  setLiveShowInfo,
  onGoLive
}) => {
  // state params
  const [liveShowInfoError, setLiveShowInfoError] = useState<UnplannedLiveShowErrorInfo>({
    titleError: '',
    description: '',
    thumbnailUrl: '',
    channelError: '',
    notificationTextMessageError: '',
    notificationAppTitleError: '',
    notificationMessengerError: '',
    facebookPostTitleError: '',
    facebookPostDescriptionError: ''
  });
  const [stepCount, setStepCount] = useState<number>(1);
  const [totalReachedStep, setTotalReachedStep] = useState<number>(1);

  // notification params
  const [textMessageToggle, setTextMessageToggle] = useState<boolean>(liveShowInfo.streamOnApp);
  const [appNotificationToggle, setAppNotificationToggle] = useState<boolean>(liveShowInfo.streamOnApp);
  const [messangerToggle, setMessangerToggle] = useState<boolean>(liveShowInfo.streamOnApp);
  const [isCompletedCreateLiveshow, setIsCompletedCreateLiveshow] = useState(false);
  const [bucketList, setBucketList] = useState<ProductItem[]>([]);

  useEffect(() => {
    resetAll();
  }, []);

  useEffect(() => {
    setTextMessageToggle(liveShowInfo.streamOnApp);
    setAppNotificationToggle(liveShowInfo.streamOnApp);
    setMessangerToggle(liveShowInfo.streamOnApp);
  }, [liveShowInfo.streamOnApp]);
  useEffect(() => {
    setLiveShowInfoError({
      ...liveShowInfoError,
      notificationTextMessageError: '',
      notificationAppTitleError: '',
      notificationMessengerError: ''
    });
  }, [textMessageToggle, appNotificationToggle, messangerToggle]);

  const resetAll = () => {
    setLiveShowInfo({
      title: '',
      description: '',
      thumbnailUrl: '',
      streamOnApp: true,
      streamOnWebsite: true,
      facebookChannels: [],
      liveEventType: liveEventEnum.INSTANT,
      products: [],
      notification: {
        textMessage: liveShowInfo.title,
        app: {
          title: liveShowInfo.title,
          description: ''
        },
        fb: {
          title: '',
          description: ''
        },
        messenger: {
          title: '',
          description: liveShowInfo.title
        }
      }
    });
    setLiveShowInfoError({
      titleError: '',
      description: '',
      thumbnailUrl: '',
      channelError: '',
      notificationTextMessageError: '',
      notificationAppTitleError: '',
      notificationMessengerError: '',
      facebookPostTitleError: '',
      facebookPostDescriptionError: ''
    });
    setTextMessageToggle(liveShowInfo.streamOnApp);
    setAppNotificationToggle(liveShowInfo.streamOnApp);
    setMessangerToggle(liveShowInfo.streamOnApp);
    setStepCount(1);
    setTotalReachedStep(1);
  };

  // Event handlers
  const firstStepValidated = (): boolean => {
    const updateErrorObj: UnplannedLiveShowErrorInfo = Object.assign({}, liveShowInfoError);
    let validStatus = true;
    // check title information
    if (liveShowInfo.title.trim() !== '') {
      updateErrorObj.titleError = '';
    } else {
      updateErrorObj.titleError = 'Liveshow title can’t be left empty.';
      validStatus = false;
    }

    // Check atleast one Streaming channels is checked
    if (liveShowInfo.streamOnApp || liveShowInfo.streamOnWebsite || liveShowInfo.facebookChannels) {
      updateErrorObj.channelError = '';
    } else {
      updateErrorObj.channelError = 'At least one streaming channel needs to be selected.';
      validStatus = false;
    }
    setLiveShowInfoError(updateErrorObj);
    return validStatus;
  };
  const validateNotifications = (): boolean => {
    if (textMessageToggle && liveShowInfo.notification.textMessage.trim() === '') {
      setLiveShowInfoError({ ...liveShowInfoError, notificationTextMessageError: "Text message can't be left empty." });
      return false;
    }
    if (appNotificationToggle && liveShowInfo.notification.app.title.trim() === '') {
      setLiveShowInfoError({
        ...liveShowInfoError,
        notificationTextMessageError: '',
        notificationAppTitleError: "Title can't be left empty."
      });
      return false;
    }
    if (liveShowInfo?.facebookChannels?.length > 0 && liveShowInfo.notification.fb.title.trim() === '') {
      setLiveShowInfoError({
        ...liveShowInfoError,
        facebookPostTitleError: "Title can't be left empty."
      });
      return false;
    }
    if (liveShowInfo?.facebookChannels?.length > 0 && liveShowInfo.notification.fb.description.trim() === '') {
      setLiveShowInfoError({
        ...liveShowInfoError,
        facebookPostDescriptionError: "Post Description can't be left empty."
      });
      return false;
    }
    if (messangerToggle && liveShowInfo.notification.messenger.description.trim() === '') {
      setLiveShowInfoError({
        ...liveShowInfoError,
        notificationTextMessageError: '',
        notificationAppTitleError: '',
        notificationMessengerError: "Messenger notification can't be left empty."
      });
      return false;
    } else {
      setLiveShowInfoError({
        ...liveShowInfoError,
        notificationTextMessageError: '',
        notificationAppTitleError: '',
        notificationMessengerError: ''
      });
      return true;
    }
  };

  const updateStepCount = (updatedCount: number): void => {
    if (updatedCount < stepCount) {
      setStepCount(updatedCount);
      if (updatedCount > totalReachedStep) {
        setTotalReachedStep(updatedCount);
      }
    } else if ((updatedCount <= 3 && firstStepValidated()) || (updatedCount > 3 && validateNotifications())) {
      setLiveShowInfoError({
        titleError: '',
        description: '',
        thumbnailUrl: '',
        channelError: '',
        notificationTextMessageError: '',
        notificationAppTitleError: '',
        notificationMessengerError: '',
        facebookPostTitleError: '',
        facebookPostDescriptionError: ''
      });
      if (updatedCount > 3) {
        setIsCompletedCreateLiveshow(true);
        if (!textMessageToggle) {
          setLiveShowInfo({
            ...liveShowInfo,
            notification: {
              app: liveShowInfo.notification.app,
              fb: liveShowInfo.notification.fb,
              messenger: liveShowInfo.notification.messenger,
              textMessage: ''
            }
          });
        }
        if (!appNotificationToggle) {
          setLiveShowInfo({
            ...liveShowInfo,
            notification: {
              app: {
                description: '',
                title: ''
              },
              fb: liveShowInfo.notification.fb,
              messenger: liveShowInfo.notification.messenger,
              textMessage: liveShowInfo.notification.textMessage
            }
          });
        }
        if (!messangerToggle) {
          setLiveShowInfo({
            ...liveShowInfo,
            notification: {
              app: liveShowInfo.notification.app,
              fb: liveShowInfo.notification.fb,
              messenger: {
                title: '',
                description: ''
              },
              textMessage: liveShowInfo.notification.textMessage
            }
          });
        }

        const count = 100;
        setLiveShowInfo({
          ...liveShowInfo,
          products: liveShowInfo.products.map((product) => {
            return {
              ...product,
              productId: product.productId,
              productReferencedId: count + 1
            };
          })
        });

        setLiveShowInfo({ ...liveShowInfo, liveEventType: liveEventEnum.INSTANT });

        setLiveShowInfo({
          ...liveShowInfo,
          notification: {
            app: liveShowInfo.notification.app,
            fb: liveShowInfo.notification.fb,
            messenger: liveShowInfo.notification.messenger,
            textMessage: liveShowInfo.notification.textMessage
          }
        });
        setTimeout(() => {
          toggleLiveShowModal();
          onGoLive();
          setIsCompletedCreateLiveshow(false);
        }, 1000);
      } else {
        setStepCount(updatedCount);
        if (updatedCount > totalReachedStep) {
          setTotalReachedStep(updatedCount);
        }
      }
    }
  };

  const handleCancel = () => {
    resetAll();
    toggleLiveShowModal();
  };

  const addSelectedProducts = (): void => {
    const updatedData: UnplannedLiveShowDetailInfo = Object.assign({}, liveShowInfo);
    const existingProductIds = updatedData.products.map((product) => product.productId);
    const newList: ProductItem[] = updatedData.products;
    bucketList.map((item) => {
      if (!existingProductIds.includes(item.productId)) {
        newList.push(item);
      }
      return item;
    });
    updatedData.products = newList;
    setLiveShowInfo(updatedData);
    setBucketList([]);
  };

  const manageProductList = (data: ProductItem[]) => {
    const updatedData: UnplannedLiveShowDetailInfo = Object.assign({}, liveShowInfo);
    const existingProductIds = updatedData.products.map((product) => product.productId);
    const toBeRemoveIds = data.map((product) => product.productId);
    const newList: ProductItem[] = updatedData.products;
    existingProductIds.map((newItem) => {
      if (!toBeRemoveIds.includes(newItem)) {
        newList.splice(
          newList.findIndex((item) => item.productId === newItem),
          1
        );
      }
      return newItem;
    });
    data.map((item) => {
      if (!existingProductIds.includes(item.productId)) {
        newList.push(item);
      }
      return item;
    });
    updatedData.products = newList;
    setLiveShowInfo(updatedData);
  };

  const manageBucketList = (data: any): void => {
    setBucketList(data);
  };

  return (
    <CreateUnplannedLiveShowPresentational
      reachedStep={totalReachedStep}
      liveShowInfo={liveShowInfo}
      liveShowInfoError={liveShowInfoError}
      stepCount={stepCount}
      updateStepCount={updateStepCount}
      updateLiveShowInfo={setLiveShowInfo}
      cancelBtnHandler={handleCancel}
      updateLiveShowInfoErrors={setLiveShowInfoError}
      textMessageToggle={textMessageToggle}
      setTextMessageToggle={setTextMessageToggle}
      appNotificationToggle={appNotificationToggle}
      setAppNotificationToggle={setAppNotificationToggle}
      messangerToggle={messangerToggle}
      setMessangerToggle={setMessangerToggle}
      isCompletedCreateLiveshow={isCompletedCreateLiveshow}
      updateProductList={manageProductList}
      bucketList={bucketList}
      updateBucketList={manageBucketList}
      addSelectedProducts={addSelectedProducts}
    />
  );
};

export default CreateUnplannedLiveshow;
