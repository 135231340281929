import { FC, useEffect, useState } from 'react';

// Components
import { FlexBox } from 'src/components/atoms';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { StoreCreditProps } from './StoteCreditCustomer.types';

const StoreCreditCustomerDetails: FC<StoreCreditProps> = ({ storeCreditValue, addDataStoreCredit, subtractStoreCredit }) => {
  const [creditValue, setCreditValue] = useState<number>(0);
  const [addValueEnabled, setAddValueEnabled] = useState(false);
  const [subtractValueEnabled, setSubtractValueEnabled] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [disabledSubtractButton, setDisabledSubtractButton] = useState(false);
  const [disabledSubtractBtn, setDisabledSubtractBtn] = useState(false);

  useEffect(() => {
    if (storeCreditValue !== undefined) {
      setCreditValue(storeCreditValue);
      if (storeCreditValue === 0) {
        setDisabledSubtractButton(true);
      } else {
        setDisabledSubtractButton(false);
      }
    }
  }, [storeCreditValue]);

  useEffect(() => {
    if (storeCreditValue !== undefined && inputValue) {
      if (storeCreditValue < parseFloat(inputValue)) {
        setDisabledSubtractBtn(true);
      } else {
        setDisabledSubtractBtn(false);
      }
    }
  }, [storeCreditValue, inputValue]);

  const enableAdd = () => {
    setAddValueEnabled(true);
    setSubtractValueEnabled(false);
  };
  const disableAddSub = () => {
    setDisabledSubtractBtn(false);
    setAddValueEnabled(false);
    setSubtractValueEnabled(false);
    setInputValue('');
  };
  const enableSubtract = () => {
    setSubtractValueEnabled(true);
    setAddValueEnabled(false);
  };
  const changeInputText = (data) => {
    if (!isNaN(data.target.value)) {
      setInputValue(data.target.value);
    }
  };
  const performAdd = () => {
    if (inputValue) {
      addDataStoreCredit(parseFloat(inputValue));
      setInputValue('');
      disableAddSub();
    }
  };
  const performSub = () => {
    subtractStoreCredit(parseFloat(inputValue));
    setInputValue('');
    disableAddSub();
  };
  const checkEnterAdd = (data) => {
    if (data.key === 'Enter') {
      performAdd();
    }
  };
  const checkEnterSub = (data) => {
    if (data.key === 'Enter') {
      performSub();
    }
  };
  const RenderWhich = () => {
    if (addValueEnabled) {
      return (
        <div className="d-flex align-items-center flex-column">
          <div>+</div>
          <div className="my-4 fs-7 d-flex align-items-center p-2 border rounded w-100">
            <div>$</div>
            <input
              placeholder={constVariables.customers.addPlaceholder}
              value={inputValue}
              onChange={changeInputText}
              className="d-flex flex-fill border-none ms-2"
              onKeyDown={checkEnterAdd}
            />
          </div>
          <div className="d-flex w-100 align-items-center justify-content-end">
            <button onClick={disableAddSub} type="button" className="btn btn-outlined-secondary btn-sm me-1">
              Cancel
            </button>
            <button onClick={performAdd} type="button" className="btn btn-success btn-sm ms-1">
              Add
            </button>
          </div>
        </div>
      );
    } else if (subtractValueEnabled) {
      return (
        <div className="d-flex align-items-center flex-column">
          <div>-</div>
          <div className="my-4 fs-7 d-flex align-items-center p-2 border rounded w-100">
            <div>$</div>
            <input
              placeholder={constVariables.customers.subPlaceholder}
              value={inputValue}
              onChange={changeInputText}
              className="d-flex flex-fill border-none ms-2"
              onKeyDown={checkEnterSub}
            />
          </div>
          <div className="d-flex w-100 align-items-center justify-content-end">
            <button onClick={disableAddSub} type="button" className="btn btn-outlined-secondary btn-sm me-1">
              Cancel
            </button>
            <button onClick={performSub} type="button" className="btn btn-danger btn-sm ms-1" disabled={disabledSubtractBtn}>
              Subtract
            </button>
          </div>
        </div>
      );
    }
    return (
      <div className="d-flex align-items-center  ">
        <button onClick={enableAdd} type="button" className="btn btn-primary btn-sm w-50 me-1 ms-0">
          Add
        </button>
        <button
          onClick={enableSubtract}
          type="button"
          className="btn btn-danger btn-sm w-50 ms-1 me-0"
          disabled={disabledSubtractButton}
        >
          Subtract
        </button>
      </div>
    );
  };

  return (
    <div>
      <FlexBox className="m-b-32 justify-content-between">
        <h4 className="section-title mb-0">{constVariables.customers.storeCredit}</h4>
        {creditValue !== undefined ? (
          <h2 className="poppins-regular m-auto-0  text-nowrap text-center text-success my-0">{`$${creditValue.toFixed(2)}`}</h2>
        ) : null}
      </FlexBox>
      {RenderWhich()}
    </div>
  );
};

export default StoreCreditCustomerDetails;
