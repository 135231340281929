import { FC, useState, useEffect } from 'react';

// Components
import { FlexBox } from 'src/components/atoms';
import { InputBox, Tooltip } from 'src/components/molecules';

// Icons
import { DownArrowIcon, InfoIcon, UpArrowIcon } from 'src/assets/icons';

// Types
import { EditInventoryStockRecivedProps } from './EditInventoryStockRecived.types';

const EditInventoryStockRecived: FC<EditInventoryStockRecivedProps> = ({
  total,
  value,
  setValue,
  levelType,
  handleUpdateInventoryStock
}) => {
  const [error, setError] = useState('');

  useEffect(() => {
    if (parseInt(value, 10) === 0) {
      setError('Enter value greater than 0.');
    } else {
      setError('');
    }
  }, [value]);

  const changeInputText = (data) => {
    if (data === '') {
      setValue(data);
    } else if (!isNaN(data) && data < 1000000) {
      setValue(data);
    }
  };

  const incOne = () => {
    if (parseInt(value, 10) < 999999) setValue((parseInt(value, 10) + 1).toString());
  };
  const decOne = () => {
    if (parseInt(value, 10) > 1) setValue((parseInt(value, 10) - 1).toString());
  };

  const handleInputKeyEvent = (event) => {
    if (event.keyCode === 13) {
      if (event.target.value === '') {
        setError('Enter value greater than 0.');
        return;
      } else {
        setError('');
      }
      handleUpdateInventoryStock(event.target.value, levelType);
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center">
        <div className="helper-text">Input quantity</div>
        <img src={InfoIcon} className="ms-2 cursor-pointer h-10px w-10px" data-tip data-for="stockReceived" />
        <Tooltip
          tooltipId="stockReceived"
          place="top"
          content={() => {
            return <div>Added stock + Old stock = Updated stock</div>;
          }}
        />
      </div>

      <div className="mt-4 d-flex align-items-center justify-content-between">
        <FlexBox
          className={`w-50 ${
            parseInt(value, 10) === 0 ? 'border-danger' : ''
          } form-control form-control-sm  px-5 justify-content-between align-items-center cursor-pointer dropdown-box dropdown-box-active`}
        >
          <InputBox
            value={value}
            onChangeText={changeInputText}
            name="StockRecivedInput"
            noBorders
            inputClass={`${parseInt(value, 10) === 0 ? 'border-danger' : 'text-muted'} stock-level`}
            onKeyDown={handleInputKeyEvent}
            isAutoFocus={true}
          />

          <div className="opacity-50 d-flex flex-column">
            <img onClick={incOne} className="cursor-pointer h-10px w-10px" src={UpArrowIcon} />
            <img onClick={decOne} className="cursor-pointer h-10px w-10px" src={DownArrowIcon} />
          </div>
        </FlexBox>
        <div>+</div>
        <div>{total}</div>
        <div>=</div>
        {value ? <div>{parseInt(value, 10) + total}</div> : <div>{total}</div>}
      </div>
      {error ? <div className="fs-9 text-danger">{error}</div> : null}
    </div>
  );
};

export default EditInventoryStockRecived;
