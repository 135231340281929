import { FC, useEffect, useState } from 'react';

// Components
import { FlexBox } from 'src/components/atoms';
import { InputBox } from 'src/components/molecules';

// Icons
import { DownArrowIcon, UpArrowIcon } from 'src/assets/icons';

// Types
import { LabelNumberInputProps, ProductLabelItem, ProductVariantLabelItem } from './ProductLabel.types';

const LabelNumberInput: FC<LabelNumberInputProps> = ({
  labelProductType,
  labelPrintType,
  data,
  productId,
  handleUpdateLabelCount
}) => {
  const [value, setValue] = useState('0');
  const [error, setError] = useState('');

  const incOne = () => {
    if (parseInt(value, 10) < 999999) {
      setValue((parseInt(value, 10) + 1).toString());
      handleUpdateLabelCount(
        parseInt(value, 10) + 1,
        productId,
        labelProductType,
        labelProductType === 'variant' ? (data as ProductVariantLabelItem)?.productVariantId : undefined
      );
    }
  };

  const decOne = () => {
    if (parseInt(value, 10) > 1) {
      setValue((parseInt(value, 10) - 1).toString());
      handleUpdateLabelCount(
        parseInt(value, 10) - 1,
        productId,
        labelProductType,
        labelProductType === 'variant' ? (data as ProductVariantLabelItem)?.productVariantId : undefined
      );
    }
  };

  const changeInputText = (data) => {
    if (data === '') {
      setValue(data);
    } else if (!isNaN(data) && data < 1000000) {
      setValue(data);
    }
  };

  useEffect(() => {
    setValue(data?.labelNumber?.toString());
  }, [data]);

  useEffect(() => {
    const populateError = () => {
      if (labelProductType === 'product' && (data as ProductLabelItem).variants?.length === 0) {
        if (labelPrintType === 'sku' && !(data as ProductLabelItem)?.productSku && parseInt(value, 10) > 0) {
          return setError('SKU needed for label');
        }
        if (labelPrintType === 'barcode' && !(data as ProductLabelItem)?.productBarcode && parseInt(value, 10) > 0) {
          return setError('Barcode needed for label');
        }
      }

      if (labelProductType === 'variant') {
        if (labelPrintType === 'sku' && !(data as ProductVariantLabelItem)?.productVariantSku && parseInt(value, 10) > 0) {
          return setError('SKU needed for label');
        }
        if (labelPrintType === 'barcode' && !(data as ProductVariantLabelItem)?.productVariantBarcode && parseInt(value, 10) > 0) {
          return setError('Barcode needed for label');
        }
      }

      return setError('');
    };

    populateError();
  }, [value, labelPrintType, labelProductType, data]);

  const handleInputKeyEvent = (event) => {
    if (event.keyCode === 13) {
      if (event.target.value === '') {
        // setError('Enter value greater than 0.');
        return;
      } else {
        // setError('');
      }
      handleUpdateLabelCount(
        parseInt(event.target.value, 10),
        productId,
        labelProductType,
        labelProductType === 'variant' ? (data as ProductVariantLabelItem)?.productVariantId : undefined
      );
    }
  };

  const handleBlurInput = (event) => {
    if (event.target.value === '') {
      // setError('Enter value greater than 0.');
      return;
    } else {
      // setError('');
    }
    handleUpdateLabelCount(
      parseInt(event.target.value, 10),
      productId,
      labelProductType,
      labelProductType === 'variant' ? (data as ProductVariantLabelItem)?.productVariantId : undefined
    );
  };

  return (
    <FlexBox className={`label-number-input align-items-center position-relative`}>
      <span className="m-r-8">
        {labelProductType === 'product' && (data as ProductLabelItem)?.variants?.length ? 'Update all' : 'Number of labels'}
      </span>
      <div className="position-relative">
        <InputBox
          value={value}
          onChangeText={changeInputText}
          name="LabelNumberInput"
          inputClass="text-muted"
          onKeyDown={handleInputKeyEvent}
          onBlur={handleBlurInput}
          noBorders
        />

        <div className="opacity-50 d-flex flex-column position-absolute input-controls">
          <img onClick={incOne} className="cursor-pointer h-15px w-15px" src={UpArrowIcon} />
          <img onClick={decOne} className="cursor-pointer h-15px w-15px" src={DownArrowIcon} />
        </div>
      </div>
      {error && <span className="text-danger position-absolute error-text">{error}</span>}
    </FlexBox>
  );
};

export default LabelNumberInput;
