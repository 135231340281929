import { gql } from '@apollo/client';

export const CONFIGURE_FACEBOOK = gql`
  mutation configureFacebook($input: ConfigureFacebookInput!) @api(name: "liveStreamingAPI") {
    configureFacebook(input: $input) {
      id
      success
      message
    }
  }
`;
