import { gql } from '@apollo/client';

export const PERFORM_RAPID_ACTIONS = gql`
  mutation PerformRapidAction($input: RapidActionInput!) @api(name: "authAPI") {
    performRapidAction(input: $input) {
      __typename
      ... on GenericResponse {
        success
        message
      }
    }
  }
`;
