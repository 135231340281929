import { gql } from '@apollo/client';

export const DELETE_PRODUCTS = gql`
  mutation DeleteProducts($input: AlterProductInput!) @api(name: "productsAPI") {
    deleteProducts(input: $input) {
      __typename
      ... on AlterProductSuccessResponse {
        productIds
        success
        message
      }
      ... on AlterProductErrorResponse {
        success
        productIds
        code
        type
        message
      }
    }
  }
`;
