import { FC, PropsWithChildren } from 'react';

// Types
import { FlexBoxProps } from './FlexBox.types';

const FlexBox: FC<PropsWithChildren<FlexBoxProps>> = ({ className, onClick, id, children, style }) => {
  return (
    <div className={`d-flex ${className ? className : ''}`} onClick={onClick} id={id} style={style}>
      {children}
    </div>
  );
};

export default FlexBox;
