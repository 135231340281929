import { gql } from '@apollo/client';

export const CONFIGURE_SHIPPO = gql`
  mutation ConfigureShippo($input: ShippoConfigInput!) @api(name: "authAPI") {
    configureShippo(input: $input) {
      success
      message
    }
  }
`;
