import { FC } from 'react';
import ReactTooltip from 'react-tooltip';

// Components
import { Portal } from 'src/components/molecules';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { TooltipProps } from './Tooltip.types';

// Styles
import './Tooltip.scss';

const Tooltip: FC<TooltipProps> = ({ tooltipId, className, place, content }) => {
  return (
    <Portal id="kt_body">
      <ReactTooltip
        id={tooltipId}
        className={`custom-tooltip ${className}`}
        place={place}
        type="dark"
        clickable
        effect="solid"
        data-html={true}
        arrowColor={constVariables.toolTip.arrowColor}
        getContent={content}
        multiline={true}
      />
    </Portal>
  );
};

export default Tooltip;
