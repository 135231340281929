import { gql } from '@apollo/client';

export const GET_MANIFESTS = gql`
  query getManifests($input: GetManifestsInput) @api(name: "ordersAPI") {
    getManifests(input: $input) {
      id
      date
      status
      numberOfLables
      manifestUrl
    }
  }
`;
