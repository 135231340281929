import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import React from 'react';
import {
  InventoryManagementListData,
  LocationItem,
  VariantLocationDetailItem
} from 'src/components/pages/inventory/InventoryManagementPage.type';
import { FilterFields } from 'src/components/molecules/RightFilterMenu/RightFilterMenu.types';
import { getInventoryInputType } from 'src/components/pages/inventory/InventoryManagementPage.type';

export type InventoryListProps = {
  setInventoryList: React.Dispatch<React.SetStateAction<InventoryManagementListData[]>>;
  inventoryProductList: InventoryManagementListData[];
  rapidActionOpen: boolean;
  selectedTab?: string;
  setSortBy: any;
  sortBy: {
    catagory: string;
    id: number;
    type: string;
  };
  isLoading: boolean;
  totalCount: number;
  selectedWarehouseId?: number;
  updateInventoryList: (updatedInventory, fieldName, productId?) => void;
  activeColumns: {
    name: string;
    status: boolean;
  }[];
  inventoryFilters: FilterFields;
  setInventoryFilters: React.Dispatch<React.SetStateAction<FilterFields>>;
  getInventoryInput: getInventoryInputType;
  setInventoryInput: React.Dispatch<React.SetStateAction<getInventoryInputType>>;
  handleClearInventoryFilter: () => void;
  showFilterChips: boolean;
  setShowFilterChips: React.Dispatch<React.SetStateAction<boolean>>;
  handleUpdateInventoryFilters: (fieldName, value) => void;
};

export type DropdownOptions = {
  name: string;
  id: number;
};

export type InventoryListExpandedProps = {
  setSelectedOptions?: React.Dispatch<React.SetStateAction<number | string>>;
  selectedOptions?: number;
  refresh?: ((variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>) | undefined;
  productId?: number | string;
  inventoryData: VariantLocationDetailItem[];
  selectedWarehouseId?: number;
  activeColumns: {
    name: string;
    status: boolean;
  }[];
  isLoading: boolean;
  updateInventoryList: (updatedInventory, fieldName, productId?) => void;
  detailInventoryId: number;
  externalProductProvider?: string;
};
export type ListExpandedProps = {
  shipmentDate: string;
  numberOfItems: number;
  totalAmount: number;
  status: string;
  id: number;
};
export interface InventoryLocationProps {
  type: string;
  id: number;
  productId?: number | string;
  defaultLocationId: number;
  locationName: string;
  showEditIcon: boolean;
  // data?: InventoryManagementListData | VariantLocationDetailItem;
  selectedWarehouseId?: number;
  updateInventoryList?: (updatedInventory, fieldName, productId?) => void;
  wearhouseLocations?: LocationItem[];
}

export interface InventoryStockLevelMenuProps {
  title: string;
  type?: string;
  levelType?: string;
  products: InventoryManagementListData | VariantLocationDetailItem;
  showMenu: boolean;
  floatingValue: string;
  positionStockLevelMenus: {
    top: number;
    left: number;
  };
  disableFloatingSaveButton: boolean;
  isBackButton?: boolean;
  blueButtonText?: string;
  redButtons?: boolean;
  noBottomButton?: boolean;
  children: React.ReactNode;
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
  universalBackButton?: () => void;
  updateInventoryStockPath?: (id: number, type: string, locationId: number, quantity: number, levelType: string) => void;
}

export interface InventoryStockLevelProps {
  type: string;
  id: number;
  productId?: number | string;
  locationId: number;
  quantity: number;
  showEditIcon: boolean;
  selectedWarehouseId?: number;
  updateInventoryList?: (updatedInventory, fieldName, productId?) => void;
}

export interface InventoryLowStockAlertProps {
  type: string;
  id: number;
  productId?: number | string;
  quantity: number;
  showEditIcon: boolean;
  updateInventoryList?: (updatedInventory, fieldName, productId?) => void;
}

export enum InventoryStockLevelTypes {
  GENERAL = 'GENERAL',
  STOCK_RECEIVED = 'STOCK_RECEIVED',
  INVENTORY_RECOUNT = 'INVENTORY_RECOUNT',
  DAMAGE = 'DAMAGE',
  THEFT = 'THEFT',
  LOSS = 'LOSS',
  RESTOCK_RETURN = 'RESTOCK_RETURN',
  GIVEAWAY = 'GIVEAWAY'
}
