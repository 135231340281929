import { FC, useState } from 'react';

// Components
import { FlexBox } from 'src/components/atoms';
import { InputBox } from 'src/components/molecules';

// Icons
import { DownArrowIcon, UpArrowIcon } from 'src/assets/icons';

// Types
import { EditInventoryLowStockAlertProps } from './EditInventoryLowStockAlert.types';

const EditInventoryLowStockAlert: FC<EditInventoryLowStockAlertProps> = ({ id, type, value, setValue, updateLowStockPath }) => {
  const [autoCheck, setAutoCheck] = useState<boolean>(true);
  const [error, setError] = useState('');

  const toggleAutoCheck = () => {
    setAutoCheck(!autoCheck);
  };

  const changeInputText = (data) => {
    if (data === '') {
      setValue(data);
    } else if (!isNaN(data) && data < 999999) {
      setValue(data);
    }
  };

  const incOne = () => {
    if (parseInt(value, 10) < 999998) setValue((parseInt(value, 10) + 1).toString());
  };

  const decOne = () => {
    if (parseInt(value, 10) > 1) setValue((parseInt(value, 10) - 1).toString());
  };

  const handleInputKeyEvent = (event) => {
    if (event.keyCode === 13) {
      if (event.target.value === '') {
        setError('Enter value greater than 0.');
        return;
      } else {
        setError('');
      }
      updateLowStockPath(id, parseInt(event.target.value, 10), type);
    }
  };

  return (
    <div>
      <div>Update Alert Level</div>

      <FlexBox
        className={`mt-4 form-control form-control-sm pe-5 ps-0 justify-content-between align-items-center cursor-pointer dropdown-box dropdown-box-active`}
      >
        <InputBox
          value={value}
          onChangeText={changeInputText}
          onKeyDown={handleInputKeyEvent}
          name="inventoryLowStock"
          noBorders
          inputClass="text-muted fw-bold"
          isAutoFocus={true}
        />

        <div className="opacity-50 d-flex flex-column">
          <img onClick={incOne} className="cursor-pointer h-10px w-10px" src={UpArrowIcon} />
          <img onClick={decOne} className="cursor-pointer h-10px w-10px" src={DownArrowIcon} />
        </div>
      </FlexBox>
      {error ? <div className="fs-9 text-danger">{error}</div> : null}
      {/* <div className="mt-4 d-flex align-items-center">
        <div className="form-check form-check-sm form-check-custom">
          <input className="form-check-input widget-9-check" type="checkbox" checked={autoCheck} onChange={toggleAutoCheck} />
        </div>
        <div className="ms-2">Automatic Level</div>
      </div> */}
    </div>
  );
};

export default EditInventoryLowStockAlert;
