/* eslint-disable no-nested-ternary */
import { FC } from 'react';

// Icons
import { DefaultImage } from 'src/assets/icons';

// Types
import { SplashScreenProps } from '../IntroScreens.type';

const SplashScreen: FC<SplashScreenProps> = ({ bgColor, logo }) => {
  return (
    <div className="position-relative d-flex align-items-center justify-content-center">
      <div className="iphone-layout">
        <svg width="215" height="487" viewBox="0 0 215 487" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.445312" width="214.8" height="485.211" rx="34" fill={bgColor} />
          <path
            opacity="0.4"
            d="M194.788 9.65332H186.133C185.512 9.65332 185.008 10.1784 185.008 10.8261V14.0739C185.008 14.7216 185.512 15.2467 186.133 15.2467H194.788C195.41 15.2467 195.914 14.7216 195.914 14.0739V10.8261C195.914 10.1784 195.41 9.65332 194.788 9.65332Z"
            stroke="black"
          />
          <path
            opacity="0.5"
            d="M196.688 11.3672V13.5324C197.107 13.349 197.38 12.9224 197.38 12.4498C197.38 11.9772 197.107 11.5506 196.688 11.3672Z"
            fill="black"
          />
          <path
            d="M194.437 10.4648H186.474C186.091 10.4648 185.781 10.788 185.781 11.1866V13.7126C185.781 14.1112 186.091 14.4343 186.474 14.4343H194.437C194.819 14.4343 195.129 14.1112 195.129 13.7126V11.1866C195.129 10.788 194.819 10.4648 194.437 10.4648Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M178.169 10.5947C179.325 10.5947 180.436 11.0482 181.273 11.8612C181.336 11.924 181.437 11.9232 181.499 11.8594L182.102 11.2382C182.133 11.2058 182.151 11.162 182.15 11.1164C182.15 11.0708 182.132 11.0273 182.1 10.9953C179.903 8.84401 176.436 8.84401 174.238 10.9953C174.206 11.0273 174.188 11.0708 174.188 11.1164C174.188 11.162 174.205 11.2058 174.236 11.2382L174.839 11.8594C174.901 11.9233 175.002 11.9241 175.065 11.8612C175.902 11.0481 177.014 10.5947 178.169 10.5947ZM178.169 12.6113C178.804 12.6113 179.417 12.8584 179.888 13.3047C179.951 13.368 180.052 13.3667 180.114 13.3016L180.717 12.6644C180.749 12.631 180.766 12.5857 180.766 12.5386C180.765 12.4914 180.747 12.4465 180.714 12.4137C179.28 11.0175 177.058 11.0175 175.624 12.4137C175.591 12.4465 175.573 12.4915 175.573 12.5386C175.572 12.5857 175.59 12.6311 175.621 12.6644L176.224 13.3016C176.286 13.3667 176.386 13.368 176.45 13.3047C176.921 12.8587 177.533 12.6115 178.169 12.6113ZM179.381 14.0855C179.382 14.1318 179.364 14.1764 179.331 14.2088L178.285 15.2866C178.254 15.3183 178.213 15.3361 178.169 15.3361C178.126 15.3361 178.083 15.3183 178.053 15.2866L177.006 14.2088C176.974 14.1764 176.957 14.1318 176.957 14.0855C176.958 14.0392 176.978 13.9955 177.012 13.9644C177.68 13.3875 178.658 13.3875 179.327 13.9644C179.36 13.9955 179.38 14.0393 179.381 14.0855Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M171.586 10.1048C171.586 9.80581 171.354 9.56348 171.067 9.56348H170.548C170.261 9.56348 170.028 9.80581 170.028 10.1048V14.796C170.028 15.0949 170.261 15.3373 170.548 15.3373H171.067C171.354 15.3373 171.586 15.0949 171.586 14.796V10.1048ZM168.124 10.8265H168.643C168.93 10.8265 169.163 11.0688 169.163 11.3678V14.796C169.163 15.0949 168.93 15.3373 168.643 15.3373H168.124C167.837 15.3373 167.605 15.0949 167.605 14.796V11.3678C167.605 11.0688 167.837 10.8265 168.124 10.8265ZM166.22 12.0895H165.701C165.414 12.0895 165.181 12.3319 165.181 12.6308V14.796C165.181 15.0949 165.414 15.3373 165.701 15.3373H166.22C166.507 15.3373 166.739 15.0949 166.739 14.796V12.6308C166.739 12.3319 166.507 12.0895 166.22 12.0895ZM164.316 13.7134C164.316 13.4145 164.083 13.1721 163.796 13.1721H163.277C162.99 13.1721 162.758 13.4145 162.758 13.7134V14.796C162.758 15.0949 162.99 15.3373 163.277 15.3373H163.796C164.083 15.3373 164.316 15.0949 164.316 14.796V13.7134Z"
            fill="black"
          />
          <path
            d="M19.7024 9.33594C20.912 9.33594 21.9199 10.22 21.9199 12.2261V12.2341C21.9199 14.1252 21.0793 15.255 19.6796 15.255C18.664 15.255 17.9033 14.6445 17.7321 13.7802L17.7245 13.7366H18.6983L18.7096 13.7723C18.858 14.1648 19.1927 14.4185 19.6834 14.4185C20.5658 14.4185 20.9348 13.5384 20.9728 12.4561C20.9766 12.3966 20.9804 12.3371 20.9804 12.2737H20.9576C20.7408 12.797 20.1931 13.1935 19.4514 13.1935C18.3939 13.1935 17.6484 12.4006 17.6484 11.3262V11.3183C17.6484 10.1646 18.5194 9.33594 19.7024 9.33594ZM19.7024 12.4045C20.33 12.4045 20.8092 11.9447 20.8092 11.3064V11.3024C20.8092 10.6681 20.33 10.1725 19.7138 10.1725C19.1052 10.1725 18.6145 10.6641 18.6145 11.2825V11.2905C18.6145 11.9407 19.0786 12.4045 19.7024 12.4045ZM23.2656 11.4134C22.9575 11.4134 22.7141 11.1597 22.7141 10.8385C22.7141 10.5174 22.9575 10.2676 23.2656 10.2676C23.5737 10.2676 23.8133 10.5174 23.8133 10.8385C23.8133 11.1597 23.5737 11.4134 23.2656 11.4134ZM23.2656 14.3234C22.9575 14.3234 22.7141 14.0697 22.7141 13.7485C22.7141 13.4274 22.9575 13.1776 23.2656 13.1776C23.5737 13.1776 23.8133 13.4274 23.8133 13.7485C23.8133 14.0697 23.5737 14.3234 23.2656 14.3234ZM27.3081 15.1559V14.0578H24.5695V13.1856L26.8478 9.4351H28.2438V13.2173H28.9893V14.0578H28.2438V15.1559H27.3081ZM25.4519 13.2411H27.3233V10.2082H27.3005L25.4519 13.2133V13.2411ZM31.0995 15.1559V10.4104H31.0767L29.6579 11.4253V10.4857L31.0919 9.4351H32.0618V15.1559H31.0995Z"
            fill="black"
          />
          <g clipPath="url(#clip1_34155_394497)">
            <rect x="74" y="8" width="66.4594" height="20.3108" rx="10.1554" fill="black" />
          </g>
          <defs>
            <clipPath id="clip0_34598_87919">
              <rect x="75" y="8" width="66.4594" height="20.3108" rx="10.1554" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      {(logo.fileImage || logo.url) && (
        <img
          src={logo.fileImage ? logo.fileImage : logo.url ? logo.url : DefaultImage}
          alt=""
          className="preview-logo position-absolute"
        />
      )}
    </div>
  );
};

export default SplashScreen;
