import { gql } from '@apollo/client';

export const ADD_PRODUCT_FOR_OVERLAY = gql`
  mutation addProductForOverlay($input: ChangeLiveProductInput!) @api(name: "liveStreamingAPI") {
    changeLiveProduct(input: $input) {
      id
      success
    }
  }
`;
