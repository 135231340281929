import { gql } from '@apollo/client';

export const GET_MEDIA_UPLOAD_URL = gql`
  mutation MyMutation($input: MediaUploadUrlInput!) @api(name: "authAPI") {
    getMediaUploadUrl(input: $input) {
      uploadURL
      accessURL
    }
  }
`;
