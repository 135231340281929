import { gql } from '@apollo/client';

export const UPDATE_CUSTOMER_TAGS = gql`
  mutation UpdateCustomer($input: UpdateCustomerInput!) @api(name: "productsAPI") {
    updateCustomer(input: $input) {
      success
      message
    }
  }
`;
