import { TypedDocumentNode, gql } from '@apollo/client';

export const CHECK_ELEIGIBILITY_FOR_COMBINING_ORDERS: TypedDocumentNode<
  {
    checkForCombiningOrds: {
      isAvailableForCombining: boolean;
      isAutomaticCombiningEnabled: boolean;
    };
  },
  { input?: { orderIds?: number[] | string[] } }
> = gql`
  query checkForCombiningOrds($input: CombineOrdersInput) @api(name: "ordersAPI") {
    checkForCombiningOrds(input: $input) {
      __typename
      isAvailableForCombining
      isAutomaticCombiningEnabled
    }
  }
`;
