import { gql } from '@apollo/client';

export const UPDATE_STORE_DETAILS = gql`
  mutation UpdateStoreDetailsMutation($input: UpdateStoreDetailsInput!) @api(name: "authAPI") {
    updateStoreDetails(input: $input) {
      additionalData
      userId
      message
      success
    }
  }
`;
