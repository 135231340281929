import { FC } from 'react';

// Components
import Tooltip from './Tooltip';

interface InvalidOrderActionButtonTooltipProps {
  tooltipId: string;
}

const InvalidOrderActionButtonTooltip: FC<InvalidOrderActionButtonTooltipProps> = ({ tooltipId }) => {
  return (
    <Tooltip
      tooltipId={tooltipId}
      place="top"
      content={() => {
        return <>Order contains errors</>;
      }}
    />
  );
};

export default InvalidOrderActionButtonTooltip;
