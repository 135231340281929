import { gql } from '@apollo/client';

export const GET_PRODUCT_BY_LABEL = gql`
  query OnGetProductByLabel($input: GetLabelInput!) @api(name: "productsAPI") {
    getProductByLabel(input: $input) {
      id
      name
      salePrice
      imageUrl
    }
  }
`;
