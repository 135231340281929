import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import clsx from 'clsx';
import moment from 'moment';

// Apollo
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_COMBINED_ORDER_DETAIL,
  GET_ORDER_SHIPMENT,
  GET_ORDERS_DETAILS,
  GET_ORDER_TIMELINE,
  GET_SHOPIFY_ADMIN_BASE_URL
} from 'src/apollo/queries';
import { CANCEL_ORDER, REFUND_SHIPPING_LABEL } from 'src/apollo/mutations';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { FlexBox, Img, Loader } from 'src/components/atoms';
import {
  Dropdown,
  RefundShippingLabel,
  ExpeditedShippingTooltip,
  ShopifySyncTooltip,
  InvalidOrderActionButtonTooltip
} from 'src/components/molecules';
import { CustomModal } from 'src/components/oraganisms';
import CancelOrder from 'src/components/pages/orders/OrderDetails/CancelOrder';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';
import { renderOrderIcon } from 'src/utils/renderOrderIcon';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';
import { setStatusColor, setStatusText } from '../OrdersList/helpers/orderStatus';
import { useToast } from 'src/utils/hooks/useToast';
import { mapCombinedOrderToOrder } from '../OrdersList/helpers/mapCombinedOrderToOrder';
import { usePrintPackingSlip } from '../OrdersList/hooks/usePrintPackingSlip';

// Icons
import {
  CloseIcon,
  DownArrowIcon,
  Phone,
  ThreeDotsIcon,
  UpArrowIcon,
  PrinterWhite,
  Mail,
  DefaultImage,
  Truck,
  CheckedOffSquareIcon,
  ShopifyIcon
} from 'src/assets/icons';

// ConstVariables
import { constVariables, ROUTES } from 'src/constants';

// Types
import { OrderShipmentProps } from 'src/components/pages/orders/FulfillmentCenter/FulfillmentCenter.type';
import { OrderPreviewListProps } from './OrderPreviewList.types';
import { OrderStatus, orderDetailsData } from '../OrdersList/OrdersList.types';
import { TimelineData } from 'src/components/pages/orders/OrderDetails/OrderDetails.types';
import { UserRoles } from 'src/components/pages/settings/UserPermissions/UserPermissions.type';

// Styles
import './OrderPreview.scss';

const OrderPreviewList: FC<OrderPreviewListProps> = ({ close, data, refresh, selectedOrderType }) => {
  const { showToast } = useToast();

  const [openMenu, setOpenMenu] = useState(false);
  const [expandOrderDetails, setExpandOrderDetails] = useState(true);
  const [orderData, setOrderData] = useState<orderDetailsData>({
    id: undefined,
    orderCode: '',
    orderStatus: '',
    customer: '',
    thumbnailUrl: '',
    customerEmail: '',
    isPartiallyShipped: false,
    customerId: 0,
    customerOrder: 0,
    customerPhoneNo: '',
    isEmailVerified: false,
    isPhoneNoVerified: false,
    subTotal: 0.0,
    totalWeight: 0.0,
    orderDetails: [
      {
        availableQuantity: -1,
        id: 0,
        name: '',
        sku: '',
        code: '',
        quantity: 0,
        price: [{ price: 0, quantity: 0 }],
        orderItemStatus: '',
        imageUrl: '',
        dimensions: '',
        location: '',
        items: '',
        productId: 0,
        variantId: 0,
        variantTypes: [
          {
            id: 0,
            name: '',
            value: ''
          }
        ]
      }
    ],
    shippingAddress: {
      id: 0,
      isVerified: false
    },
    isExpeditedShipping: false,
    total: 0.0,
    items: 0,
    availableItemsQuantity: 0,
    discountAmount: 0.0,
    shippingAmount: 0.0,
    taxAmount: 0.0,
    customerOrders: 0,
    createdAt: '',
    updatedAt: '',
    isCombinedOrder: false,
    childOrders: [],
    isCombOrdrParent: false,
    noOfCombChildOrds: 0,
    shopifyId: undefined
  });
  const [expandCustomer, setExpandCustomer] = useState(true);
  const [isCancelOrder, setIsCancelOrder] = useState(false);
  const [isOptions, setIsOptions] = useState(false);
  const [expandShippingAddress, setExpandShippingAddress] = useState(true);
  const [disableRefundBtn, setDisableRefundBtn] = useState(false);
  const [expandTimeline, setExpandTimeline] = useState(true);
  const [isActionButtonDisabled, setIsActionButtonDisabled] = useState<boolean>(false);
  const [orderShipment, setOrderShipment] = useState<OrderShipmentProps>({
    id: '',
    rateId: '',
    packageInfo: '',
    rateInfo: '',
    useWeightOfItems: true,
    packageWeight: 0.0,
    packageWeightUnit: '',
    needsSignatureConfirmation: false,
    hasInsurance: false,
    trackingNumber: '',
    labelId: '',
    labelUrl: ''
  });
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [showRefundError, setShowRefundError] = useState(false);
  const [timeLineData, setTimeLineData] = useState<TimelineData[]>([]);

  const history = useHistory();

  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  const refreshData = () => {
    getOrderDetails();
    refresh();
  };
  const { printPackingSlip, getPackingSlipDetails, isFetchingDataFromServer, isPrintingPackingSlip } = usePrintPackingSlip(
    [orderData?.id],
    refreshData
  );

  const { data: { shopifyAdminBaseUrl } = { shopifyAdminBaseUrl: null } } = useQuery(GET_SHOPIFY_ADMIN_BASE_URL);

  const [getIndividualOrderDetails] = useLazyQuery(GET_ORDERS_DETAILS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        ids: [Number(orderData.id)],
        status: []
      }
    },
    onCompleted: (data) => {
      if (data?.getOrderDetailsList.orders[0]) {
        setOrderData(data?.getOrderDetailsList.orders[0]);
      }
    }
  });

  const [getCombinedOrderDetail] = useLazyQuery(GET_COMBINED_ORDER_DETAIL, {
    onCompleted: (res) => {
      setOrderData(mapCombinedOrderToOrder(res));
    }
  });

  const getOrderDetails = orderData?.isCombinedOrder ? getCombinedOrderDetail : getIndividualOrderDetails;

  const [getOrderShipment, { refetch: refetchOrderShipment }] = useLazyQuery(GET_ORDER_SHIPMENT, {
    onCompleted: (data) => {
      if (data?.getOrderShipment?.length > 0) {
        setOrderShipment(data?.getOrderShipment[0]);
      } else {
        setOrderShipment({
          id: '',
          rateId: '',
          packageInfo: '',
          rateInfo: '',
          useWeightOfItems: true,
          packageWeight: 0.0,
          packageWeightUnit: '',
          needsSignatureConfirmation: false,
          hasInsurance: false,
          trackingNumber: '',
          labelId: '',
          labelUrl: ''
        });
      }
    }
  });

  const [getOrderTimeline] = useLazyQuery(GET_ORDER_TIMELINE, {
    onCompleted: (response) => {
      if (response?.getOrderTimeline) {
        setTimeLineData(response?.getOrderTimeline);
      }
    },
    onError: (error) => {
      console.log('error::', error);
    }
  });

  useEffect(() => {
    if (data.id) {
      setOrderData(data);
    }
  }, [data]);

  useEffect(() => {
    if (data.id) {
      getOrderShipment({
        variables: {
          input: {
            orderId: data.id
          }
        }
      });
    }
    data?.orderDetails?.map((detail, i) => {
      if (
        detail?.dimensions === '' ||
        !JSON.parse(detail?.dimensions)?.weight?.value ||
        JSON.parse(detail?.dimensions)?.weight?.value === '0' ||
        !data?.shippingAddress?.isVerified
      ) {
        setIsActionButtonDisabled(true);
      } else {
        setIsActionButtonDisabled(false);
      }
      return null;
    });
  }, [data]);

  useEffect(() => {
    if (data.id) {
      getOrderTimeline({
        variables: {
          orderId: data.id
        }
      });
    }
  }, [data.id]);

  const changeToProcessToFulfillAndPrint = async () => {
    if (!isPrintingPackingSlip && data?.id && !isActionButtonDisabled && data?.orderStatus === 'awaiting_processing') {
      await printPackingSlip([data.id]);
    }
  };

  const changeToProcessToFulfill = async () => {
    if (!isFetchingDataFromServer && data?.orderStatus === 'awaiting_processing' && data.id && !isActionButtonDisabled) {
      await getPackingSlipDetails([data.id]);
      refreshData();
    }
  };

  const [cancelOrderFun, { loading: isRefundLoading }] = useMutation(CANCEL_ORDER, {
    onCompleted: (result) => {
      if (result.cancelOrder.success) {
        setDisableRefundBtn(false);
        showToast({
          successText: `${constVariables.orderDetails.orderCancelToast.titleOne}${orderData.orderCode} ${constVariables.orderDetails.orderCancelToast.titleTwo}`,
          message: constVariables.orderDetails.orderCancelToast.message
        });
        closeCancelOrderModal();
        refresh();
      }
    },
    onError: (err) => {
      setDisableRefundBtn(false);
      console.error('Cancel order Failed', err);
    }
  });

  const handleConfirmRefundOriginal = () => {
    cancelOrderFun({
      variables: {
        input: { orderId: data.id, refundType: 'ORIGINAL_PAYMENT_METHOD' }
      }
    });
  };
  const handleConfirmRefundStoreCredit = () => {
    cancelOrderFun({
      variables: {
        input: { orderId: data.id, refundType: 'STORE_CREDIT' }
      }
    });
  };

  const [onRefundShippingLabel, { loading: refundLoading }] = useMutation(REFUND_SHIPPING_LABEL, {
    onCompleted: (data) => {
      if (data?.refundShippingLabel?.success) {
        localStorage.setItem('refundedOrderId', data?.id?.toString());
        setShowRefundError(false);
        closeRefundModal();
        getOrderDetails();
        refresh();
        refetchOrderShipment();
      } else setShowRefundError(true);
    },
    onError: (error) => {
      setShowRefundError(true);
    }
  });

  const handleOriginalRefund = () => {
    setDisableRefundBtn(true);
    handleConfirmRefundOriginal();
  };

  const handleStoreCreditRefund = () => {
    setDisableRefundBtn(true);
    handleConfirmRefundStoreCredit();
  };

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const closeMenu = () => {
    if (openMenu) {
      setOpenMenu(false);
    }
  };

  const selectingMenuItem = (option) => {
    closeMenu();
    switch (option.name.toUpperCase()) {
      case 'EDIT ORDER':
        history.push(`/orders/orderDetails/${data.id}`);
        break;
      case 'REFUND ORDER':
        setIsCancelOrder(true);
        break;
      case 'CANCEL ORDER':
        setIsCancelOrder(true);
        break;
      case 'FULFILL ORDER':
        handleFulfillOrder();
        break;
      case 'PRINT PACKING SLIPS':
        changeToProcessToFulfillAndPrint();
        break;
      default:
        break;
    }
  };

  const filterOrderMenu = () => {
    if (data?.orderStatus === OrderStatus.SHIPPED) {
      return constVariables.ordersOptionsDropdownData.filter((item) => item.id === 4);
    }
    if (data?.orderStatus === OrderStatus.AWAITING_PROCESSING) {
      return constVariables.ordersOptionsDropdownData.filter((item) =>
        role === UserRoles.ADMIN_ASSOCIATE || role === UserRoles.WAREHOUSE_ASSOCIATE
          ? item.id === 2
          : item.id === 2 || item.id === 3
      );
    }

    return role === UserRoles.ADMIN_ASSOCIATE || role === UserRoles.WAREHOUSE_ASSOCIATE
      ? []
      : constVariables.ordersOptionsDropdownData.filter((item) => item.id === 3);
  };

  const handleFulfillOrder = () => {
    history.push(ROUTES.orders.allOrders.fulfillmentCenter, { openId: data?.id, orderCode: data?.orderCode });
  };

  const rePrintLabel = () => {
    const link = document.createElement('a');
    link.href = orderShipment?.labelUrl;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
  };

  const refundLabel = () => {
    history.push(ROUTES.orders.allOrders.fulfillmentCenter, { id: orderData?.id });
  };

  const renderActionButtons = (orderStatus) => {
    switch (orderStatus) {
      case 'pending':
        return '';
      case 'awaiting_processing':
        return (
          <>
            <div data-tip={isActionButtonDisabled} data-for="invalidOrder">
              <button
                onClick={changeToProcessToFulfillAndPrint}
                className="btn btn-primary btn-sm m-0"
                disabled={isActionButtonDisabled}
              >
                <img className="me-3" src={PrinterWhite} />
                {isPrintingPackingSlip ? (
                  <Loader type="button" className="ms-4 text-light h-15px w-15px" />
                ) : (
                  <span>{constVariables.orders.print}</span>
                )}
              </button>
            </div>
            <div className="m-x-8" data-tip={isActionButtonDisabled} data-for="invalidOrder">
              <button
                className="btn btn-order-secondary btn-sm m-0"
                onClick={changeToProcessToFulfill}
                disabled={isActionButtonDisabled}
              >
                {isFetchingDataFromServer ? (
                  <Loader type="button" className="ms-4 text-light h-15px w-15px" />
                ) : (
                  <span>Mark As Processed</span>
                )}
              </button>
            </div>
            {isActionButtonDisabled && <InvalidOrderActionButtonTooltip tooltipId="invalidOrder" />}
          </>
        );
      case 'awaiting_fulfillment':
        return (
          <button className="btn btn-success btn-sm ms-2" onClick={handleFulfillOrder} disabled={isActionButtonDisabled}>
            <img className="h-15px w-15px" src={CheckedOffSquareIcon} />
            <span className="ms-2">{constVariables.orders.fulfillOrder}</span>
          </button>
        );
      case 'awaiting_shipment':
        return (
          <>
            <button
              className="input-group-text postBtn btn-primary form-control-solid"
              onClick={rePrintLabel}
              disabled={isActionButtonDisabled}
            >
              Re-print label
            </button>
            <button className="btn-order-secondary" disabled={isActionButtonDisabled} onClick={refundLabel}>
              Refund label
            </button>
          </>
        );
      case 'shipped':
        return '';
      default:
        break;
    }
  };

  const closeCancelOrderModal = () => {
    document.body.style.overflow = 'unset';
    setIsCancelOrder(false);
    setIsOptions(false);
  };

  const closeRefundModal = () => {
    document.body.style.overflow = 'unset';
    setShowRefundModal(false);
  };

  const goToShopifyPage = (shopifyOrderId) => {
    if (shopifyAdminBaseUrl) window.open(`${shopifyAdminBaseUrl}orders/${shopifyOrderId}`, '_blank');
  };

  return (
    <>
      <div className="orderPreviewList">
        <div className="">
          <FlexBox className="align-items-center justify-content-between">
            <FlexBox className="align-items-center">
              <h2 className="poppins-regular m-auto-0 text-nowrap dark-color">
                {constVariables.orders.order}
                <span className="poppins-regular text-primary mx-1">#{orderData.orderCode}</span>
              </h2>
              {orderData?.shopifyId && (
                <>
                  <img
                    src={ShopifyIcon}
                    className="cursor-pointer mx-4"
                    data-tip
                    data-for="shopifySyncIcon"
                    onClick={() => goToShopifyPage(orderData?.shopifyId)}
                  />
                  <ShopifySyncTooltip tooltipText="Order from Shopify" />
                </>
              )}
              {orderData.isPartiallyShipped ? (
                <div className="text-center bg-danger text-white poppins-light px-2 py-1 roundButton fs-9">Partial Shipping</div>
              ) : null}
            </FlexBox>
            <img
              src={CloseIcon}
              className="cursor-pointer"
              height={20}
              onClick={() => {
                close();
              }}
            />
          </FlexBox>
          <h6 className="greyText pt-2 orderDate">{moment(orderData.createdAt).format('D MMM YYYY, hh:mm a')}</h6>
          <div className="d-flex justify-content-start align-items-center">
            <div
              className={` ${setStatusColor(
                orderData.orderStatus,
                orderData.isPartiallyShipped
              )} mt-3 order-status-box text-nowrap  poppins-medium  cursor-pointer`}
            >
              <div className="text-capitalize">{setStatusText(orderData.orderStatus, orderData.isPartiallyShipped)}</div>
            </div>
            {orderData.isExpeditedShipping && (
              <>
                <img src={Truck} className="mx-2 h-75 w-75" data-tip data-for="truckIcon" />
                <ExpeditedShippingTooltip />
              </>
            )}
          </div>
          <br />
          <div className="d-flex justify-content-between align-items-center position-relative">
            <div className="d-flex align-items-center">{renderActionButtons(orderData.orderStatus)}</div>
            {orderData.orderStatus !== 'shipped' && filterOrderMenu()?.length > 0 && (
              <div
                className={clsx({
                  'order-status-menus': orderData.orderStatus === 'pending' || orderData.orderStatus === 'shipped',
                  'display-none': orderData.orderStatus === 'cancelled'
                })}
              >
                <OutsideClickHandler onOutsideClick={closeMenu}>
                  <div className="position-relative">
                    <img
                      src={ThreeDotsIcon}
                      className="dropdown-toggle cursor-pointer"
                      id="dropdownMenuButton"
                      onClick={toggleMenu}
                    />
                    <Dropdown
                      data={filterOrderMenu()}
                      onSelect={selectingMenuItem}
                      shiftLeft
                      shiftLeftValue="-170px"
                      selected={openMenu}
                      className="mt-3"
                      closeDropdown={() => setOpenMenu(false)}
                    />
                  </div>
                </OutsideClickHandler>
              </div>
            )}
          </div>
        </div>
        <div className="mt-8 border-1 border-top border-secondary lineStyle mb-5" />
        <div className={orderData?.isCombinedOrder ? '' : 'd-none'}>
          <div>
            <h4 className="poppins-regular m-auto-0 text-nowrap dark-color">{constVariables.orders.combinedOrders}</h4>
            <img
              src={!expandOrderDetails ? DownArrowIcon : UpArrowIcon}
              className=" float-right arrowOrderDown cursor-pointer"
              onClick={() => {
                setExpandOrderDetails(!expandOrderDetails);
              }}
            />
          </div>
          <div className={expandOrderDetails ? 'com-ordr-codes' : 'd-none'}>
            {orderData.childOrders &&
              orderData.childOrders.map(({ id, code }) => (
                <div className="com-ordr-row" key={id}>
                  <span className="com-ordr-code">#{code}</span>
                </div>
              ))}
          </div>
          <div className=" mt-8 border-1 border-top border-secondary lineStyle" />
        </div>

        <div className={orderData?.isCombinedOrder ? 'd-none' : ''}>
          <div>
            <h4 className="poppins-regular m-auto-0 text-nowrap dark-color">{constVariables.orders.orderSummary}</h4>
            <img
              src={!expandOrderDetails ? DownArrowIcon : UpArrowIcon}
              className=" float-right arrowOrderDown cursor-pointer"
              onClick={() => {
                setExpandOrderDetails(!expandOrderDetails);
              }}
            />
          </div>
          <div className={expandOrderDetails ? '' : 'd-none'}>
            {orderData?.orderDetails?.map((orderDetail, index) => {
              return (
                <div className="d-flex orderedItem" key={index}>
                  <div className="col-2">
                    <Img
                      className="h-50px w-50px"
                      src={setImageSrc(
                        orderDetail?.imageUrl ? generateCompressedImageURL(orderDetail?.imageUrl, '50') : DefaultImage
                      )}
                      placeholderImg={DefaultImage}
                      errorImg={DefaultImage}
                    />
                  </div>
                  <div className="col-7">
                    <p className="poppins-medium product">{orderDetail.name}</p>
                    <p className="greyText product">
                      {orderDetail?.variantTypes?.map((varientType, i) => {
                        return (
                          <span key={i}>{`${varientType?.value}${i === orderDetail.variantTypes.length - 1 ? '' : ' '}`}</span>
                        );
                      })}
                      ,{` Qty: ${orderDetail.quantity}`}
                    </p>
                  </div>
                  <div className="col-3">
                    <p className="poppins-medium product">{orderDetail?.price[index]?.price}</p>
                    <p className="poppins-medium greyText product">{orderDetail.sku}</p>
                  </div>
                </div>
              );
            })}
            <div className="row">
              <div className="col-10 paymentDetails">
                <span className="greyText">{constVariables.orders.tax}</span>
              </div>
              <div className="col-1">
                <p className="float-right">${orderData.taxAmount}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-10 paymentDetails">
                <span className="greyText">{constVariables.orders.discount}</span>
              </div>
              <div className="col-1">
                <p className="float-right">-${orderData.discountAmount}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-10 paymentDetails">
                <span className="greyText">{constVariables.orders.shipping}</span>
              </div>
              <div className="col-1">
                <p className="float-right">${orderData.shippingAmount}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-10 paymentDetails">
                <span className="">{constVariables.orders.total}</span>
              </div>
              <div className="col-1">
                <p className="float-right">${orderData.total}</p>
              </div>
            </div>
          </div>
          <div className=" mt-8 border-1 border-top border-secondary lineStyle" />
        </div>

        <div className="">
          <div>
            <h4 className="poppins-regular m-auto-0 mt-5 text-nowrap dark-color">{constVariables.orders.customer}</h4>
            <img
              src={!expandCustomer ? DownArrowIcon : UpArrowIcon}
              className=" float-right arrowOrderDown cursor-pointer"
              onClick={() => {
                setExpandCustomer(!expandCustomer);
              }}
            />
          </div>
          <div className={expandCustomer ? '' : 'd-none'}>
            <div className="mt-5">
              <Img
                className="h-50px w-50px customerLogo"
                src={setImageSrc(
                  orderData?.thumbnailUrl ? generateCompressedImageURL(orderData?.thumbnailUrl, '50') : DefaultImage
                )}
                placeholderImg={DefaultImage}
                errorImg={DefaultImage}
              />
              <span className="customerName">{orderData.customer}</span>
              {/* <img src={Truck} className="px-2" /> */}
            </div>
            {(!!orderData.customerEmail || orderData.customerPhoneNo) && (
              <div>
                <p className="greyText pt-2">{constVariables.orders.constactInfo}</p>
                <div className="pb-2">
                  <img className="" src={Mail} />
                  <span className="text-primary "> {orderData.customerEmail}</span>
                </div>
                <div className="pb-2">
                  <img className="" src={Phone} />
                  <span className=""> {orderData.customerPhoneNo}</span>
                </div>
              </div>
            )}
          </div>
          <div className=" mt-8 border-1 border-top border-secondary lineStyle" />
        </div>
        <div className="">
          <div>
            <h4 className="poppins-regular m-auto-0 mt-5 text-nowrap dark-color">{constVariables.orders.shippingAddress}</h4>
            <img
              src={!expandShippingAddress ? DownArrowIcon : UpArrowIcon}
              className=" float-right arrowOrderDown cursor-pointer"
              onClick={() => {
                setExpandShippingAddress(!expandShippingAddress);
              }}
            />
          </div>
          <div className={expandShippingAddress ? '' : 'd-none'}>
            <p className="mt-8">{orderData.shippingAddress?.addressLine1}</p>
            <p className="greyText addressStyle">
              {orderData.shippingAddress?.addressLine2 ? orderData.shippingAddress?.addressLine2 : orderData.shippingAddress?.city}
            </p>
            <p className="greyText">
              {orderData.shippingAddress?.addressLine2
                ? orderData.shippingAddress?.addressLine2
                : orderData.shippingAddress?.country}
            </p>
          </div>
          <div className=" mt-8 border-1 border-top border-secondary lineStyle" />
        </div>
        <div className="">
          <div>
            <h4 className="poppins-regular m-auto-0 mt-5 text-nowrap dark-color">{constVariables.orders.timeline}</h4>
            <img
              src={!expandTimeline ? DownArrowIcon : UpArrowIcon}
              className=" float-right arrowOrderDown cursor-pointer"
              onClick={() => {
                setExpandTimeline(!expandTimeline);
              }}
            />
          </div>
          <div className={expandTimeline ? 'mt-8' : 'd-none'}>
            {/* <div className="input-group mb-3 mt-5">
                    <form>
                        <input
                            type="text"
                            className="form-control form-control-solid inputBoxStyle"
                            placeholder={constVariables.orders.placeholder}
                            aria-label="Username"
                        />
                        <img src={Smiley} className="mx-2" />
                        <img src={PaperClip} className="mx-2" />
                        <button className="input-group-text postBtn btn-primary form-control-solid">
                            {constVariables.orders.post}
                        </button>
                    </form>
                </div> */}
            {timeLineData?.length > 0 &&
              timeLineData?.map((timeline, index) => (
                <div key={timeline.id}>
                  <div className="row mt-5">
                    <div className="col-7">
                      <img src={renderOrderIcon(timeline.heading.toLowerCase())} className="order-timeline-icon" />
                      <span className="statusText">{timeline.heading}</span>
                    </div>
                    <div className="col-5">
                      <p className="float-right greyText">{moment(timeline.time).format('D MMM YYYY, hh:mm')}</p>
                    </div>
                  </div>
                  <div className={timeLineData?.length > index + 1 ? 'row' : 'd-none'}>
                    <div className="col-1 timeLineVertical v2"></div>
                    <div className="col-10">
                      <span
                        className={`poppins-medium mailStatus ${
                          timeline?.heading.includes('combined') ? 'text-primary' : 'greyText'
                        }`}
                      >
                        {timeline.description}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      {isCancelOrder && (
        <CustomModal show={isCancelOrder} closeModal={closeCancelOrderModal}>
          <CancelOrder
            disableRefundBtn={disableRefundBtn}
            title={constVariables.orderDetails.orderCancelModal.title}
            message={constVariables.orderDetails.orderCancelModal.title}
            cancelBtnTitle={constVariables.orderDetails.orderCancelModal.cancel}
            actionBtnHandlerOriginal={handleOriginalRefund}
            actionBtnHandlerStoreCredit={handleStoreCreditRefund}
            cancelBtnHandler={closeCancelOrderModal}
            cancelOrder={true}
            isOptions={isOptions}
            isRefunding={isRefundLoading}
          />
        </CustomModal>
      )}
      {showRefundModal && (
        <CustomModal show={showRefundModal} closeModal={closeRefundModal}>
          <RefundShippingLabel
            title={constVariables.FulfillmentCenter.refundModal.title}
            actionBtnTitle={constVariables.FulfillmentCenter.refundModal.actionButtonTitle}
            cancelBtnTitle={constVariables.FulfillmentCenter.refundModal.cancelButtonTitle}
            actionBtnHandler={() => {
              onRefundShippingLabel({
                variables: {
                  input: {
                    shipmentId: orderShipment.id.toString()
                  }
                }
              });
            }}
            cancelBtnHandler={closeRefundModal}
            cancelOrder={true}
            loading={refundLoading}
            error={showRefundError}
          />
        </CustomModal>
      )}
    </>
  );
};

export default OrderPreviewList;
