import { FC } from 'react';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import {
  CustomModal,
  DiscardModal,
  OrganizationSection,
  ErrorModal,
  AddproductVariants,
  AddProductShipping,
  AddProductIdentifiers,
  AddProductInventory,
  AddNotes,
  AddProductPricing,
  ProductMedia
} from 'src/components/oraganisms';
import { GeneralInfoSection, StatusSection } from 'src/components/templates';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { AddProductPresentationalProps } from './AddProduct.types';
import { UserRoles } from '../../settings/UserPermissions/UserPermissions.type';

// Styles
import './addProduct.scss';

const AddProductPresentational: FC<AddProductPresentationalProps> = ({
  generalInfo,
  setGeneralInfo,
  statusInfo,
  channels,
  updateChannelValues,
  status,
  setStatus,
  pricing,
  setPricing,
  errorMessages,
  setErrorMessages,
  onDiscard,
  modal,
  setModal,
  productId,
  leavePageHandler,
  addNewNote,
  editOldNote,
  deleteOldNote,
  options,
  setOptions,
  optionChecked,
  setOptionChecked,
  tableData,
  setTableData,
  shipping,
  setShipping,
  productIdentifiers,
  setProductIdentifiers,
  inventory,
  setInventory,
  organization,
  setOrganization,
  sendMedia,
  warehouseList,
  setWarehouseList,
  notesData,
  setNotesData,
  optionDone,
  setOptionDone,
  setSelectedVariantOption,
  selectedVariantOption,
  setCheckedVariantOption,
  checkedVariantOption,
  addOrRemoveCollectionListItem,
  collectionIdList,
  appliedTags,
  setAppliedTags,
  deleteProduct,
  deleteFromCart,
  deleteFromWishList,
  retailPriceToggle,
  setRetailPriceToggle,
  productVendorID,
  setProductVendorID,
  productMedia,
  updateProductMedia,
  setProductMedia,
  productTypeId,
  setProductTypeId,
  getProductData,
  initializeFormChanges,
  productAvailableFrom,
  setProductAvailableFrom,
  recordings,
  isAutoAssignPrimaryVideo,
  setIsAutoAssignPrimaryVideo,
  primaryVideoId,
  setPrimaryVideoId,
  formChanges,
  setLabelPrintType,
  setShowLabelPrintModal,
  setShowProductSavingModal,
  selectedVariants,
  setSelectedVariants,
  setSelectedVariantIds
}) => {
  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  return (
    <>
      {modal.discardModal && (
        <CustomModal
          bodyClassname="w-90 w-md-50"
          show={modal.discardModal}
          closeModal={() =>
            setModal((prev) => ({
              ...prev,
              discardModal: false
            }))
          }
        >
          <DiscardModal
            title={constVariables.addProduct.discardForm.title}
            message={constVariables.addProduct.discardForm.message}
            actionBtnTitle={constVariables.addProduct.discardForm.action}
            cancelBtnTitle={constVariables.addProduct.discardForm.cancel}
            actionBtnHandler={onDiscard.bind(this, 'discard')}
            cancelBtnHandler={onDiscard.bind(this, 'close')}
          />
        </CustomModal>
      )}
      {modal.leaveModal && (
        <CustomModal
          bodyClassname="w-90 w-md-50"
          show={modal.leaveModal}
          closeModal={() =>
            setModal((prev) => ({
              ...prev,
              leaveModal: false
            }))
          }
        >
          <DiscardModal
            deleteProductMedia
            title={constVariables.common.LeaveForm.title}
            message={constVariables.common.LeaveForm.message}
            actionBtnTitle={constVariables.common.LeaveForm.action}
            cancelBtnTitle={constVariables.common.LeaveForm.cancel}
            actionBtnHandler={leavePageHandler}
            cancelBtnHandler={onDiscard.bind(this, 'leave')}
          />
        </CustomModal>
      )}
      {modal.saveModal && (
        <CustomModal
          bodyClassname="w-90 w-md-50"
          show={modal.saveModal}
          closeModal={() =>
            setModal((prev) => ({
              ...prev,
              saveModal: false
            }))
          }
        >
          <ErrorModal
            message={constVariables.common.onSaveError.message}
            actionTitle={constVariables.common.onSaveError.action}
            actionHandler={onDiscard.bind(this, 'saveError')}
            errors={[
              errorMessages?.generalInfoTitleError,
              errorMessages?.retailPrice,
              errorMessages?.salePrice,
              errorMessages?.optionTitle,
              errorMessages?.optionVariant
            ]}
          />
        </CustomModal>
      )}
      {modal.deleteModal && (
        <CustomModal
          bodyClassname="w-90 w-md-150"
          show={modal.deleteModal || true}
          closeModal={() =>
            setModal((prev) => ({
              ...prev,
              deleteModal: false
            }))
          }
        >
          <DiscardModal
            deleteProductMedia
            title={constVariables.common.productDetail.title}
            message={constVariables.common.productDetail.mainMessage}
            actionBtnTitle={constVariables.common.productDetail.action}
            cancelBtnTitle={constVariables.common.productDetail.cancel}
            actionBtnHandler={deleteProduct}
            cancelBtnHandler={onDiscard.bind(this, 'delete')}
          />
        </CustomModal>
      )}
      <div className="main-detail-layout">
        <div className="left-pane">
          <GeneralInfoSection
            type="Product"
            generalInfo={generalInfo}
            setGeneralInfo={setGeneralInfo}
            errorMessages={errorMessages}
            isDisableInput={role === UserRoles.FULFILMENT_ASSOCIATE ? true : false}
          />
          <ProductMedia
            sendMedia={sendMedia}
            productMedia={productMedia}
            setProductMedia={setProductMedia}
            updateProductMedia={updateProductMedia}
            variantsData={tableData}
            recordings={recordings}
            isAutoAssignPrimaryVideo={isAutoAssignPrimaryVideo}
            setIsAutoAssignPrimaryVideo={setIsAutoAssignPrimaryVideo}
            primaryVideoId={primaryVideoId}
            setPrimaryVideoId={setPrimaryVideoId}
          />
          <AddProductPricing
            shipping={shipping}
            pricing={pricing}
            setPricing={setPricing}
            errorMessages={errorMessages}
            setErrorMessages={setErrorMessages}
            retailPriceToggle={retailPriceToggle}
            setRetailPriceToggle={setRetailPriceToggle}
            isDisableInput={role === UserRoles.FULFILMENT_ASSOCIATE ? true : false}
          />
          <AddProductShipping
            shipping={shipping}
            setShipping={setShipping}
            errorMessages={errorMessages}
            setErrorMessages={setErrorMessages}
            isDisableInput={role === UserRoles.FULFILMENT_ASSOCIATE ? true : false}
          />
          <AddProductIdentifiers
            productIdentifiers={productIdentifiers}
            setProductIdentifiers={setProductIdentifiers}
            errorMessages={errorMessages}
            setErrorMessages={setErrorMessages}
            isDisableInput={role === UserRoles.FULFILMENT_ASSOCIATE ? true : false}
            formChanges={formChanges}
            setLabelPrintType={setLabelPrintType}
            setShowLabelPrintModal={setShowLabelPrintModal}
            setShowProductSavingModal={setShowProductSavingModal}
          />
          <AddProductInventory
            warehouseList={warehouseList}
            setWarehouseList={setWarehouseList}
            inventory={inventory}
            setInventory={setInventory}
            tableData={tableData}
            setTableData={setTableData}
            optionChecked={optionChecked}
            optionDone={optionDone}
          />
          <AddproductVariants
            setOptionDone={setOptionDone}
            optionDone={optionDone}
            pricing={pricing}
            options={options}
            setOptions={setOptions}
            optionChecked={optionChecked}
            setOptionChecked={setOptionChecked}
            tableData={tableData}
            setTableData={setTableData}
            errorMessages={errorMessages}
            setErrorMessages={setErrorMessages}
            setSelectedVariantOption={setSelectedVariantOption}
            selectedVariantOption={selectedVariantOption}
            setCheckedVariantOption={setCheckedVariantOption}
            checkedVariantOption={checkedVariantOption}
            productMedia={productMedia}
            setProductMedia={setProductMedia}
            updateProductMedia={updateProductMedia}
            inventory={inventory}
            setInventory={setInventory}
            formChanges={formChanges}
            setLabelPrintType={setLabelPrintType}
            setShowLabelPrintModal={setShowLabelPrintModal}
            setShowProductSavingModal={setShowProductSavingModal}
            selectedVariants={selectedVariants}
            setSelectedVariants={setSelectedVariants}
            setSelectedVariantIds={setSelectedVariantIds}
          />
        </div>

        <div className="right-pane">
          <StatusSection
            statusInfo={statusInfo}
            channels={channels}
            updateChannelValues={updateChannelValues}
            status={status}
            setStatus={setStatus}
            page="product"
            productAvailableFrom={productAvailableFrom}
            setProductAvailableFrom={setProductAvailableFrom}
          />
          <div className="card">
            <OrganizationSection
              addOrRemoveCollectionListItem={addOrRemoveCollectionListItem}
              collectionIdList={collectionIdList}
              organization={organization}
              setOrganization={setOrganization}
              appliedTags={appliedTags}
              setAppliedTags={setAppliedTags}
              productVendorID={productVendorID}
              setProductVendorID={setProductVendorID}
              productTypeId={productTypeId}
              setProductTypeId={setProductTypeId}
            />
          </div>
          <div className="card">
            <AddNotes
              id={Number(productId)}
              editOldNote={editOldNote}
              deleteOldNote={deleteOldNote}
              addNewNote={addNewNote}
              notesData={notesData}
              setNotesData={setNotesData}
            />
          </div>

          {/* <div className="mb-5 d-flex align-items-center justify-content-between">
          <button className="btn btn-white btn-sm" onClick={() => deleteFromCart({ variables: { productId } })}>
            Remove from Cart
          </button>
          <button className="btn btn-white btn-sm" onClick={() => deleteFromWishList({ variables: { productId } })}>
            Remove from Favorites
          </button>
        </div> */}
        </div>
      </div>
    </>
  );
};

export default AddProductPresentational;
