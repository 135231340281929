import IVSBroadcastClient from 'amazon-ivs-web-broadcast';
import React from 'react';
import { CamOffLive } from 'src/assets/images';

export const CAM_LAYER_NAME = 'camera';
export const MIC_LAYER_NAME = 'mic';

export const getAudioDevices = async (): Promise<MediaDeviceInfo[]> => {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const audioDevices = devices.filter((device) => device.kind === 'audioinput');

    if (!audioDevices.length) {
      throw Error('No audio devices found..');
    }
    return audioDevices;
  } catch (er: any) {
    throw Error(er);
  }
};

export const getVideoDevices = async (): Promise<MediaDeviceInfo[]> => {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter((device) => device.kind === 'videoinput');
    if (!videoDevices.length) {
      throw Error('No video devices found.');
    }
    return videoDevices;
  } catch (err: any) {
    throw Error(err);
  }
};

export const handlePermissions = async (
  setDevicePermissions: React.Dispatch<React.SetStateAction<{ video: boolean; audio: boolean }>>,
  handleError: (message) => void
): Promise<void> => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true
    });

    for (const track of stream.getTracks()) {
      track.stop();
    }
    setDevicePermissions({
      video: true,
      audio: true
    });
  } catch (err: any) {
    setDevicePermissions({
      video: false,
      audio: false
    });
    handleError(
      'Failed to access the camera and microphone. To start streaming, you must allow access to both your camera and microphone'
    );
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getVideoLayer = (
  activeVideoDevice: React.MutableRefObject<null>,
  visible: boolean,
  client: React.MutableRefObject<IVSBroadcastClient.AmazonIVSBroadcastClient | undefined>
) => {
  const dimensions = client?.current?.getCanvasDimensions();

  const width = dimensions?.width || 1080;
  const height = dimensions?.height || 1920;

  return {
    device: activeVideoDevice.current,
    name: CAM_LAYER_NAME,
    index: 4,
    visible,
    x: 0,
    y: 0,
    width,
    height,
    type: 'VIDEO'
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getCameraOffLayer = (client: React.MutableRefObject<IVSBroadcastClient.AmazonIVSBroadcastClient | undefined>) => {
  const dimensions = client?.current?.getCanvasDimensions();
  return {
    name: `camOff-${Math.random().toString()}`,
    imageSrc: CamOffLive,
    index: 1,
    x: 0,
    y: (dimensions?.height || 1920) / 4 - (dimensions?.width || 1080) / 16,
    width: dimensions?.width,
    height: dimensions?.width,
    type: 'IMAGE'
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getAudioMixerDevice = (activeAudioDevice: React.MutableRefObject<null>, micMuted: boolean) => {
  return { name: MIC_LAYER_NAME, device: activeAudioDevice.current, muted: micMuted || false };
};
