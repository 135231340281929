import { gql } from '@apollo/client';

export const DELETE_CATALOG = gql`
  mutation DeleteCatalog($input: CatalogListInput!) @api(name: "productsAPI") {
    deleteCatalog(input: $input) {
      id
      success
      message
    }
  }
`;
