import { FC, useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { SearchIcon, CloseIcon } from 'src/assets/icons';

// Styles
import './_searchBar.scss';

interface SearchBarProps {
  placeholder?: string;
  classes?: string;
  onChange?: (key: string) => void;
  onKeyDown?: (data) => void;
  value?: string;
}

const SearchBar: FC<SearchBarProps> = ({ placeholder, classes, onChange, onKeyDown, value }) => {
  const [searchKey, setSearchKey] = useState<string | null>(null);
  const [isShowCloseIcon, setIsShowCloseIcon] = useState(false);
  const debouncedFn = useCallback(
    debounce((key) => onChange && onChange(key), 500),
    []
  );

  useEffect(() => {
    if (searchKey === null) return;
    debouncedFn(searchKey);
    if (searchKey?.length > 0) {
      setIsShowCloseIcon(true);
    } else {
      setIsShowCloseIcon(false);
    }
  }, [searchKey]);

  const handleChange = (event) => {
    setSearchKey(event.target.value);
  };

  return (
    <div className={`position-relative d-flex align-items-center search-bar-box ${classes}`}>
      <KTSVG path={SearchIcon} className="svg-icon-2 svg-icon-gray-500 position-absolute top-50 ms-3 translate-middle-y" />

      <input
        autoComplete="off"
        className="form-control form-control-solid ps-12 pe-0 background-transparent"
        onChange={handleChange}
        placeholder={placeholder !== '' ? placeholder : `Search for products`}
        onKeyDown={onKeyDown}
        value={searchKey || ''}
        name="search"
      />

      {isShowCloseIcon ? (
        <KTSVG svgClassName="h-30px w-30px px-2 py-1 cursor-pointer" path={CloseIcon} onClick={() => setSearchKey('')} />
      ) : (
        <div className="h-30px w-30px" />
      )}
    </div>
  );
};

export default SearchBar;
