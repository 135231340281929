import React, { FC } from 'react';

interface AvailableSaleProps {
  quantity: number;
}

const AvailableSale: FC<AvailableSaleProps> = ({ quantity }) => {
  return <div>{quantity ? quantity : '-'}</div>;
};

export default AvailableSale;
