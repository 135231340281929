/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';

// Types
import { DropdownProps } from './TimeDropdown.types';

// Styles
import './_dropdownStyle.scss';

const Dropdown: FC<DropdownProps> = ({
  data,
  selected,
  onSelect,
  value,
  className,
  shiftLeft,
  lastElement,
  toTop,
  onBlur,
  shiftLeftValue = '-150px',
  listClass,
  noDisableEffect,
  searchDropDown,
  comparedTime,
  closeDropdown
}) => {
  const [dateFormatType] = useState('hh:mm');
  const toRight = () => {
    if (!shiftLeft) {
      return { right: '0px' };
    }
    return null;
  };
  const toLeft = () => {
    if (shiftLeft) {
      return { left: shiftLeftValue };
    }
    return null;
  };
  const lastEle = () => {
    if (lastElement) {
      return { top: toTop };
    }
    return null;
  };
  return (
    <div
      className={clsx(
        `dropdown-menu z-index-dropdown overflow-scroll ${lastElement ? `lastDropdownElement ` : ''} mh-300px`,
        { show: selected },
        className
      )}
      style={{ ...toRight(), ...toLeft(), ...lastEle() }}
      onBlur={onBlur}
      aria-labelledby="dropdownMenuButton"
    >
      {data?.map((item, index) => {
        return (
          <div
            onClick={() => {
              if (item.name > comparedTime) {
                closeDropdown();
                onSelect(item);
              }
            }}
            className={`d-flex justfy-content-center align-items-center dropdown-item ${listClass} ${
              searchDropDown ? 'show-effects' : ''
            } ${item.name <= comparedTime ? 'opacity-50' : 'cursor-pointer'} `}
            key={`dropdown-${index}`}
          >
            <div key={index} aria-labelledby="dropdownMenuButton">
              <span
                className={`menu-link dropdown-item ${
                  !noDisableEffect && value?.toLowerCase() === moment(item.name).format(dateFormatType).toLowerCase()
                    ? 'text-secondary'
                    : ''
                } `}
              >
                {moment(item.name).format(dateFormatType)}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Dropdown;
