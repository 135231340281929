import { FC } from 'react';
import clsx from 'clsx';

// Types
import { MoreActionDropdownProps } from './MoreActionDropdown.types';

// Styles
import './moreActionDropdown.scss';

const MoreActionDropdown: FC<MoreActionDropdownProps> = ({ selected, options }) => {
  return (
    <div
      className={clsx(
        'mt-2 menu menu-sub menu-background menu-sub-dropdown menu-column menu-rounded menu-gray-600 w-300px position-absolute more-actions',
        { show: selected }
      )}
      data-kt-menu="true"
    >
      {options?.map((optionGroupItem, groupIndex) => (
        <div
          className={`menu-item group-item ${options?.length === groupIndex + 1 ? '' : 'border-bottom border-bottom-light'}`}
          key={groupIndex}
        >
          {optionGroupItem?.map((menuItem, subIndex) => (
            <a
              key={subIndex}
              className={`menu-link dropdown-item ${menuItem?.deleteOption ? 'text-danger' : ''}`}
              onClick={() => menuItem.onClick()}
            >
              {menuItem.text}
            </a>
          ))}
        </div>
      ))}
    </div>
  );
};

export default MoreActionDropdown;
