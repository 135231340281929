import { FC, useEffect, useState } from 'react';
import WorldData from 'country-state-city-plus';

// Apollo
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_WAREHOUSE, AUTO_COMPLETE_ADDRESS } from 'src/apollo/queries';
import { ADD_WAREHOUSE } from 'src/apollo/mutations';

// Components
import AddWarehousePresentational from './AddWarehouse.presentational';

// Hooks && Utils && Helpers
import { useToast } from 'src/utils/hooks/useToast';

// Types
import { AddWarehouseProps, WarehouseDataProps, WarehouseDropdownProps } from './AddWarehouse.types';
import { WarehousesProps } from 'src/components/pages/settings/WarehouseManagement/WarehouseManagement.type';

const AddWarehouse: FC<AddWarehouseProps> = ({ show, data, resetState }) => {
  const refreshState = () => {
    setWarehouseData((prev) => ({
      ...prev,
      warehouseName: '',
      addressLineOne: '',
      postalCode: '',
      phoneNumber: '',
      countryDropDown: false,
      selectedCountry: '',
      selectedCountryCode: '',
      cityDropDown: false,
      selectedCity: '',
      selectedStateCode: '',
      selectedState: '',
      phoneDropDown: false,
      selectedPhone: '',
      id: undefined
    }));
    resetState();
  };
  const { showToast } = useToast();
  const [nameError, setNameError] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<boolean>(false);
  const [countryList, setCountryList] = useState<WarehouseDropdownProps[]>();
  const [cityList, setCityList] = useState<WarehouseDropdownProps[]>();
  const [stateList, setStateList] = useState<WarehouseDropdownProps[]>();
  const [phoneList, setPhoneList] = useState<WarehouseDropdownProps[]>();
  const [addressList, setAddressList] = useState([]);
  const [warehouseData, setWarehouseData] = useState<WarehouseDataProps>({
    warehouseName: '',
    addressLineOne: '',
    postalCode: '',
    phoneNumber: '',
    countryDropDown: false,
    selectedCountryCode: '',
    selectedCountryId: '',
    selectedCountry: '',
    cityDropDown: false,
    selectedCity: '',
    stateDropDown: false,
    selectedState: '',
    addressDropDown: false,
    selectedStateCode: '',
    phoneDropDown: false,
    selectedPhone: ''
  });

  const [autocompleteAddress] = useLazyQuery(AUTO_COMPLETE_ADDRESS, {
    variables: { input: { street: warehouseData.addressLineOne } },
    onCompleted: (data) => {
      if (data?.autoComplete) {
        const tempList = data?.autoComplete.map((data, index) => {
          return { id: index, name: `${data.street_line}, ${data.city}, ${data.state}` };
        });
        setAddressList(data?.autoComplete);
        setAddressListFiltered(tempList);
      }
    }
  });

  const getCountryAndPhoneList = () => {
    const getAllCountries = WorldData.getAllCountries();
    if (getAllCountries) {
      const tempCountryList = getAllCountries.map((data, index) => {
        return { id: index, name: data.name, extraData: data.id, countryCode: data.sortname };
      });
      setCountryList(tempCountryList);

      const tempPhoneList = getAllCountries.map((data, index) => {
        return { id: index, name: `+${data.phonecode.toString()} - ${data.name.toString()}` };
      });
      setPhoneList(tempPhoneList);
    }
  };

  const getCityData = () => {
    const tempCityArray = WorldData.getCitiesOfState(warehouseData.selectedStateCode);

    if (tempCityArray) {
      const tempList = tempCityArray.map((data, index) => {
        return { id: index, name: data.name };
      });
      setCityList(tempList);
    }
  };

  const getStateData = () => {
    const tempStateArray = WorldData.getStatesOfCountry(warehouseData.selectedCountryId);
    if (tempStateArray) {
      const tempList = tempStateArray.map((data, index) => {
        return { id: index, name: data.name, extraData: data.id };
      });
      setStateList(tempList);
    }
  };

  const getPhoneCode = () => {
    const tempPhone = WorldData.getCountryById(warehouseData.selectedCountryId);
    if (tempPhone) {
      setWarehouseData((prev) => ({
        ...prev,
        selectedPhone: tempPhone.phonecode[0] !== '+' ? `+${tempPhone.phonecode}` : tempPhone.phonecode
      }));
    }
  };

  useEffect(() => {
    getCountryAndPhoneList();
  }, []);
  useEffect(() => {
    if (stateList) {
      getCityData();
    }
  }, [warehouseData.selectedStateCode]);

  useEffect(() => {
    if (warehouseData.selectedCountryCode) {
      getStateData();
      getPhoneCode();
    }
  }, [warehouseData.selectedCountryCode]);

  const [callAddWarehouse, { loading: addLoading }] = useMutation(ADD_WAREHOUSE, {
    onCompleted: () => {
      refreshState();
    },
    onError: (error) => {
      showToast({ errorText: error.message, message: error.message });
      if (error.message === 'INVALID_PHONE_NUMBER!_Enter_the_number_in_E._164_format') {
        setPhoneError(true);
      }
    }
  });

  const addWarehouse = () => {
    if (warehouseData?.id) {
      callAddWarehouse({
        variables: {
          input: {
            name: warehouseData?.warehouseName,
            addressLine1: warehouseData?.addressLineOne,
            city: warehouseData?.selectedCity,
            state: warehouseData?.selectedState,
            country: warehouseData?.selectedCountry,
            pincode: warehouseData?.postalCode,
            phoneNumber: `${warehouseData?.selectedPhone}${warehouseData?.phoneNumber}`,
            id: warehouseData?.id
          }
        }
      });
    } else {
      callAddWarehouse({
        variables: {
          input: {
            name: warehouseData?.warehouseName,
            addressLine1: warehouseData?.addressLineOne,
            city: warehouseData?.selectedCity,
            state: warehouseData?.selectedState,
            country: warehouseData?.selectedCountryCode,
            pincode: warehouseData?.postalCode,
            phoneNumber: `${warehouseData?.selectedPhone}${warehouseData?.phoneNumber}`,
            id: warehouseData?.id
          }
        }
      });
    }
  };
  const setEditWarehouseData = (data: WarehousesProps) => {
    const tempPhone = WorldData.getCountryByCode(data.country);
    if (tempPhone) {
      setWarehouseData((prev) => ({
        ...prev,
        addressLineOne: data.addressLine1,
        city: data.city,
        selectedCountry: data.country,
        warehouseName: data.name,
        phoneNumber: data.phone,
        selectedPhone: data.phoneCountryCode,
        postalCode: data.pincode.toString(),
        selectedCity: data.city,
        selectedState: data.state,
        id: data.id
      }));
    }
  };
  useEffect(() => {
    if (data) {
      setEditWarehouseData(data);
    }
  }, [data]);

  const [changeCountryFirst, setChangeCountryFirst] = useState(true);
  useEffect(() => {
    if (warehouseData.selectedCountry) {
      if (!changeCountryFirst) {
        setWarehouseData((prev) => ({
          ...prev,
          selectedCity: '',
          selectedState: ''
        }));
      }
      setChangeCountryFirst(false);
    }
  }, [warehouseData.selectedCountry]);
  const [countryListFiltered, setCountryListFiltered] = useState<WarehouseDropdownProps[]>();
  const [stateListFiltered, setStateListFiltered] = useState<WarehouseDropdownProps[]>();
  const [addressListFiltered, setAddressListFiltered] = useState<WarehouseDropdownProps[]>();
  const [cityListFiltered, setCityListFiltered] = useState<WarehouseDropdownProps[]>();
  const addWarehouseName = (data: string) => {
    setWarehouseData((prev) => ({
      ...prev,
      warehouseName: data
    }));
  };
  const changeAddressLineOne = (data: string) => {
    setWarehouseData((prev) => ({
      ...prev,
      addressLineOne: data
    }));
  };
  useEffect(() => {
    autocompleteAddress();
  }, [warehouseData.addressLineOne]);

  const changePostalCode = (data: any) => {
    if (!isNaN(data)) {
      setWarehouseData((prev) => ({
        ...prev,
        postalCode: data
      }));
    }
  };

  const changeCountry = (data: string) => {
    const tempList = countryList?.filter((listData) => listData.name.toUpperCase().indexOf(data.toUpperCase()) !== -1);
    setCountryListFiltered(tempList);
    setWarehouseData((prev) => ({
      ...prev,
      selectedCountry: data
    }));
  };
  const changeCity = (data: string) => {
    const tempList = cityList?.filter((listData) => listData.name.toUpperCase().indexOf(data.toUpperCase()) !== -1);
    setCityListFiltered(tempList);
    setWarehouseData((prev) => ({
      ...prev,
      selectedCity: data
    }));
  };
  const changeState = (data: string) => {
    const tempList = stateList?.filter((listData) => listData.name.toUpperCase().indexOf(data.toUpperCase()) !== -1);
    setStateListFiltered(tempList);
    setWarehouseData((prev) => ({
      ...prev,
      selectedState: data
    }));
  };

  const changePhoneNumber = (data: any) => {
    if (!isNaN(data)) {
      setPhoneError(false);
      setWarehouseData((prev) => ({
        ...prev,
        phoneNumber: data
      }));
    }
  };

  const onSelectCountryCode = (data: any) => {
    setWarehouseData((prev) => ({
      ...prev,
      selectedCountry: data.name,
      selectedCountryId: data.extraData,
      selectedCountryCode: data.countryCode,
      countryDropDown: !prev.countryDropDown
    }));
  };

  const onSelectCity = (data: any) => {
    setWarehouseData((prev) => ({
      ...prev,
      selectedCity: data.name,
      cityDropDown: !prev.cityDropDown
    }));
  };
  const toggleAddressDropDown = () => {
    setWarehouseData((prev) => ({
      ...prev,
      addressDropDown: !prev.addressDropDown
    }));
  };
  const onSelectState = (data: any) => {
    setWarehouseData((prev) => ({
      ...prev,
      selectedState: data.name,
      selectedStateCode: data.extraData,
      stateDropDown: !prev.stateDropDown
    }));
  };
  const onSelectAddress = (data: any) => {
    const result: {
      zipcode: string;
      state: string;
      city: string;
      // eslint-disable-next-line camelcase
      street_line: string;
    } =
      // esline-disable-next-line
      addressList.find((findData: any) => data.name === `${findData?.street_line}, ${findData.city}, ${findData.state}`) || {
        zipcode: '',
        state: '',
        city: '',
        // eslint-disable-next-line camelcase
        street_line: ''
      };
    setWarehouseData((prev) => ({
      ...prev,
      addressLineOne: result.street_line,
      addressDropDown: false,
      postalCode: result?.zipcode,
      selectedCountry: 'US',
      selectedCountryCode: 'US',
      selectedPhone: '+1',
      selectedState: result?.state,
      selectedCity: result?.city
    }));
  };

  const togglePhoneDropDown = () => {
    if (phoneList) {
      setWarehouseData((prev) => ({
        ...prev,
        phoneDropDown: !prev.phoneDropDown
      }));
    }
  };
  const [warehousePageInfo] = useState({
    skipCount: 0,
    limitCount: 1
  });
  const [callCheckWarehouse] = useLazyQuery(GET_WAREHOUSE, {
    variables: {
      input: {
        pageInfo: warehousePageInfo,
        status: 'ALL',
        name: warehouseData.warehouseName,
        forceFullName: true
      }
    },
    onCompleted: (isWareHouseData) => {
      if (isWareHouseData) {
        if (isWareHouseData?.getWarehouse?.totalWarehouses > 0 && warehouseData.warehouseName.length > 0) {
          setNameError(true);
        } else {
          setNameError(false);
        }
      }
    }
  });
  useEffect(() => {
    if (warehouseData.warehouseName.length > 0) {
      callCheckWarehouse();
    }
  }, [warehouseData.warehouseName]);

  const onSelectPhone = (data: any) => {
    let tempNumber = data.name.split('-')[0].trim(' ');
    if (data.name[0] !== '+') {
      tempNumber = `+${tempNumber}`;
    }
    setWarehouseData((prev) => ({
      ...prev,
      selectedPhone: tempNumber,
      phoneDropDown: !prev.phoneDropDown
    }));
  };
  useEffect(() => {
    if (countryList) {
      setCountryListFiltered(countryList);
    }
  }, [countryList]);
  useEffect(() => {
    if (stateList) {
      setStateListFiltered(stateList);
    }
  }, [stateList]);
  useEffect(() => {
    if (cityList) {
      setCityListFiltered(cityList);
    }
  }, [cityList]);
  useEffect(() => {
    setPhoneError(false);
    setChangeCountryFirst(true);
  }, [show]);

  return (
    <AddWarehousePresentational
      loading={addLoading}
      warehouseData={warehouseData}
      setWarehouseData={setWarehouseData}
      show={show}
      resetState={refreshState}
      countryListFiltered={countryListFiltered}
      cityListFiltered={cityListFiltered}
      stateListFiltered={stateListFiltered}
      addressListFiltered={addressListFiltered}
      phoneList={phoneList}
      addWarehouse={addWarehouse}
      nameError={nameError}
      phoneError={phoneError}
      addWarehouseName={addWarehouseName}
      changeAddressLineOne={changeAddressLineOne}
      changePostalCode={changePostalCode}
      changeCountry={changeCountry}
      changeCity={changeCity}
      changeState={changeState}
      changePhoneNumber={changePhoneNumber}
      onSelectCountryCode={onSelectCountryCode}
      onSelectCity={onSelectCity}
      toggleAddressDropDown={toggleAddressDropDown}
      onSelectState={onSelectState}
      onSelectAddress={onSelectAddress}
      onSelectPhone={onSelectPhone}
      togglePhoneDropDown={togglePhoneDropDown}
    />
  );
};

export default AddWarehouse;
