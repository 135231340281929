import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useLazyQuery, useMutation, useQuery, useApolloClient } from '@apollo/client';
import { GET_PRODUCT_NOTE, GET_WAREHOUSE, GET_PRODUCTS_V3, GET_STORE_DETAILS, GET_AVALARA_CONFIGURATION } from 'src/apollo/queries';
import {
  ADD_PRODUCT,
  ADD_PRODUCT_NOTE,
  DELETE_FROM_CART,
  DELETE_FROM_WISHLIST,
  DELETE_PRODUCTS,
  DELETE_PRODUCT_NOTE,
  EDIT_PRODUCT,
  EDIT_PRODUCT_NOTE,
  EDIT_PRODUCTS
} from 'src/apollo/mutations';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { FlexBox, Loader } from 'src/components/atoms';
import { Dropdown } from 'src/components/molecules';
import { SaveHeader, CustomModal, LabelPrintModal, DiscardModal } from 'src/components/oraganisms';
import AddProductPresentational from './AddProduct.presentational';
import TiktokResponseDrawer from './components/TiktokResponseDrawer';

// Hooks && Utils && Helpers
import { useToast } from 'src/utils/hooks/useToast';
import { sendCahceUpdatedBroadcast } from 'src/utils/hooks/useApolloBroadcaster';

// Icons
import { BackRoundedIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables, ROUTES } from 'src/constants';

// Types
import { GeneralInfo } from 'src/components/templates/GeneralInfoSection/GeneralInfoSection.types';
import { Channels, ChannelType, DefaultChannels } from 'src/components/templates/StatusSection/StatusSection.types';
import { Organization } from 'src/components/templates/OrganizationSection/OrganizationSection.types';
import { WarehouseListProp } from 'src/components/oraganisms/AddProductInventory/AddProductInventory.types';
import { TableData } from 'src/components/oraganisms/AddProductVariants/AddProductVariant.types';
import {
  DimensionOptions,
  ErrorsInfo,
  Inventory,
  InventoryFormatted,
  Modals,
  Notes,
  Pricing,
  ProductIdentifiers,
  Shipping,
  VariantOptions,
  RecordingData
} from './AddProduct.types';
import { UserRoles } from '../../settings/UserPermissions/UserPermissions.type';

// Styles
import './addProduct.scss';

const AddProduct: FC = () => {
  const history = useHistory();
  const { showToast } = useToast();
  const { cache } = useApolloClient();

  const { productId } = useParams<{ productId: string }>();
  const [warehouseList, setWarehouseList] = useState<WarehouseListProp[]>();
  const [collectionIdList, setCollectionIdList] = useState<Array<number>>([]);
  const [selectedVariantOption, setSelectedVariantOption] = useState('');
  const [checkedVariantOption, setCheckedVariantOption] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [retailPriceToggle, setRetailPriceToggle] = useState(false);
  const [productVendorID, setProductVendorID] = useState<number | undefined>();
  const [productTypeId, setProductTypeId] = useState<number | undefined>();
  const [productMedia, setProductMedia] = useState<Array<any>>([]);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [recordings, setRecordings] = useState<RecordingData[]>([]);
  const [isAutoAssignPrimaryVideo, setIsAutoAssignPrimaryVideo] = useState<boolean>(false);
  const [primaryVideoId, setPrimaryVideoId] = useState('');
  const [pageHeading, setPageHeading] = useState(constVariables.AllProductsMenu.addProduct);
  const [generalInfo, setGeneralInfo] = useState<GeneralInfo>({
    value: '',
    description: '',
    updatedAt: '',
    title: constVariables.common.general.productName,
    placeHolder: constVariables.common.general.productName,
    helperText: constVariables.common.general.helperTextProductName,
    helperTextDescription: constVariables.common.general.helperTextdescription,
    name: 'productname',
    externalProvider: undefined,
    externalId: undefined
  });
  const [modal, setModal] = useState<Modals>({
    discardModal: false,
    leaveModal: false,
    saveModal: false,
    deleteModal: false
  });
  const [errorMessages, setErrorMessages] = useState<ErrorsInfo>({
    generalInfoTitleError: '',
    salePrice: '',
    retailPrice: '',
    optionTitle: '',
    optionVariant: '',
    costPerItem: ''
  });
  const [productStatus, setProductStatus] = useState<string>('Draft');
  const [channels, setChannels] = useState<Channels>(DefaultChannels);
  const statusInfo = {
    title: constVariables.common.productStatus.key,
    placeholder: constVariables.common.productStatus.key,
    helperText: constVariables.common.productStatus.helperText
  };
  const [pricing, setPricing] = useState<Pricing>({
    cost: '',
    salePrice: '',
    retailPrice: '',
    margin: '',
    profit: '',
    costPerItem: '',
    taxCode: '',
    finalSale: false,
    showRetailPrice: false
  });
  const [storeUnit, setStoreUnit] = useState({
    weight: '',
    measurement: ''
  });
  const [shipping, setShipping] = useState<Shipping>({
    weightMeasurement: '',
    widthMeasurement: '',
    heightMeasurement: '',
    depthMeasurement: '',
    weight: '',
    measurement: '',
    shippingCost: '',
    freeShippingCheck: false,
    signConfirmationCheck: false,
    shipmentInsauranceCheck: false
  });
  const [organization, setOrganization] = useState<Organization>({
    sensitiveProduct: false,
    collectionList: []
  });
  const [productIdentifiers, setProductIdentifiers] = useState<ProductIdentifiers>({
    skuValue: '',
    barCodeValue: '',
    brandValue: '',
    brandStyleValue: ''
  });
  const [inventory, setInventory] = useState<Inventory>({
    continueSellingChecked: true,
    inventoryData: [
      { id: undefined, inventoryQuantityValue: 0, inventoryWarehouseValue: '', isDropDown: false, inventoryWarehouseId: undefined }
    ]
  });
  const [inventoryFormatted, setInventoryFormatted] = useState<InventoryFormatted | undefined>();
  const [options, setOptions] = useState<VariantOptions[]>([{ title: 'Size', value: [], errTitle: false, errVariant: false }]);
  const [optionDone, setOptionDone] = useState(false);
  const [optionChecked, setOptionChecked] = useState<boolean>(false);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [formChanges, setFormChanges] = useState<boolean>(false);
  const [wareHouseAPICalled, setWareHouseAPICalled] = useState(false);
  const [prodNoteAPICalled, setProdNoteAPICalled] = useState(false);
  const [prodDetailAPICalled, setProdDetailAPICalled] = useState(false);
  const [avaLaraAPICalled, setAvaLaraAPICalled] = useState(false);
  const [isDataChanged, setDataChanged] = useState<boolean>(false);
  const [notesData, setNotesData] = useState<Notes[]>([]);
  const [targettedPath, setTargettedPath] = useState<string>(); // taking this to set clicked path
  const [locationState, setLocationState] = useState();
  const [appliedTags, setAppliedTags] = useState<
    Array<{
      id: number;
      name: string;
      textColorCode: string;
      backgroundColorCode: string;
      createdAt: string;
      updatedAt: string;
    }>
  >([]);
  const [productAvailableFrom, setProductAvailableFrom] = useState<string | undefined | null>(null);
  const [warehousePageInfo] = useState({
    skipCount: 0,
    limitCount: 1
  });
  const [showMoreActions, setShowMoreActions] = useState(false);
  const [showLabelPrintModal, setShowLabelPrintModal] = useState(false);
  const [labelPrintType, setLabelPrintType] = useState('');
  const [expandedViewLabelPrintModal, setExpandedViewLabelPrintModal] = useState(false);
  const [showProductSavingModal, setShowProductSavingModal] = useState(false);
  const [selectedVariants, setSelectedVariants] = useState<any[]>([]);
  const [selectedVariantIds, setSelectedVariantIds] = useState<number[]>([]);
  const [tiktokResponse, setTiktokResponse] = useState<any>();
  const [showTiktokResponseDrawer, setShowTiktokResponseDrawer] = useState<boolean>(false);
  const [showResponseMenu, setShowResponseMenu] = useState(false);

  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  const isSameArray = (array1: string[], array2: string[]) => {
    if (array1.length !== array2.length) return false;
    let count = 0;
    for (const el of array1) {
      if (array2.includes(el)) count++;
    }
    return count === array1.length;
  };

  useEffect(() => {
    if (history?.location?.state?.continueLabelPrinting && history?.location?.state?.labelPrintType) {
      localStorage.removeItem('shouldLabelPrinting');
      const continueLabelPrinting = history?.location?.state?.continueLabelPrinting;
      const labelPrintType = history?.location?.state?.labelPrintType;
      const selectedVariants = history?.location?.state?.selectedVariants;
      if (continueLabelPrinting && !selectedVariants) {
        setShowLabelPrintModal(true);
      }
      if (labelPrintType) {
        setLabelPrintType(labelPrintType);
      }
    }
  }, [
    history?.location?.state?.continueLabelPrinting,
    history?.location?.state?.labelPrintType,
    history?.location?.state?.selectedVariants
  ]);

  useEffect(() => {
    if (history?.location?.state?.tiktokError) {
      setTiktokResponse(history?.location?.state?.tiktokError);
      setShowResponseMenu(true);
      // setShowTiktokResponseDrawer(true);
    }
  }, [history?.location?.state?.tiktokError]);

  useQuery(GET_STORE_DETAILS, {
    onCompleted: (res) => {
      if (res?.getStoreDetails) {
        setIsAutoAssignPrimaryVideo(res?.getStoreDetails?.autoAssignPrimaryVideo);

        if (!productId) {
          setShipping((shipping) => {
            return { ...shipping, weight: res?.getStoreDetails?.weightUnit, measurement: res?.getStoreDetails?.unitOfMeasurement };
          });
        } else {
          setStoreUnit((storeUnit) => {
            return { ...storeUnit, weight: res?.getStoreDetails?.weightUnit, measurement: res?.getStoreDetails?.unitOfMeasurement };
          });
        }
      }
    }
  });

  const updateProductMedia = useCallback(
    (media) => {
      setProductMedia((productMedia) => [...productMedia, ...media]);
    },
    [productMedia]
  );

  const [getWarehouses] = useLazyQuery(GET_WAREHOUSE, {
    variables: {
      input: {
        pageInfo: warehousePageInfo,
        status: 'ACTIVE'
      }
    },
    onCompleted: (data) => {
      setTimeout(() => {
        setWareHouseAPICalled(true);
      }, 1000);
      if (data) {
        const tempList = data?.getWarehouse?.warehouses.map((data) => {
          return {
            name: data.name,
            id: data.id
          };
        });
        setWarehouseList(tempList);
        if (!productId) {
          const defaultWarehouse = data?.getWarehouse?.warehouses[0];
          const updatedWarehouse = [
            {
              id: undefined,
              inventoryQuantityValue: 0,
              inventoryWarehouseValue: defaultWarehouse.name,
              isDropDown: false,
              inventoryWarehouseId: defaultWarehouse.id.toString()
            }
          ];
          setInventory((prev) => ({
            ...prev,
            inventoryData: updatedWarehouse
          }));
        }
      }
    },
    onError: (error) => {
      setTimeout(() => {
        setWareHouseAPICalled(true);
      }, 1000);
      console.error('err', error);
    }
  });

  const [getProductNotesData, { refetch: productNoteRefetch }] = useLazyQuery(GET_PRODUCT_NOTE, {
    variables: {
      input: {
        productId: Number(productId)
      }
    },
    onCompleted: (data) => {
      setTimeout(() => {
        setProdNoteAPICalled(true);
      }, 1000);
      setNotesData(data?.getProductNote);
    },
    onError: (err) => {
      setTimeout(() => {
        setProdNoteAPICalled(true);
      }, 1000);
    }
  });

  const [getProductDetails] = useLazyQuery(GET_PRODUCTS_V3, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        productIds: [Number(productId)],
        filters: {
          locationIds: []
        },
        detailed: true
      }
    },
    onCompleted: (res) => {
      setTimeout(() => {
        setProdDetailAPICalled(true);
      }, 1000);
      if (productId) {
        getProductNotesData();
      }
      if (res?.getAllProductsV3?.products[0]) {
        const allData = res?.getAllProductsV3?.products[0];
        document.title = `Products - ${allData.productName ? allData.productName : ''}`;
        setAppliedTags(allData?.productTagsJson ? allData?.productTagsJson : []);
        setProductVendorID(allData?.vendorId);
        setProductTypeId(allData.productTypeId);
        let dimensionsData: DimensionOptions = {
          height: {
            value: '',
            unit: ''
          },
          width: {
            value: '',
            unit: ''
          },
          depth: {
            value: '',
            unit: ''
          },
          weight: {
            value: '',
            unit: ''
          }
        };
        if (allData?.dimensions) {
          dimensionsData = JSON.parse(allData?.dimensions);
        }
        if (allData?.productCollectionJson) {
          const tempColIdList = allData?.productCollectionJson.map((data) => data.id);
          if (tempColIdList) {
            setCollectionIdList(tempColIdList);
          }
        }
        if (allData?.recordings) {
          const updatedRecordings = allData?.recordings.map((recording) => {
            return { ...recording, id: parseInt(recording.id, 10) };
          });
          setRecordings(updatedRecordings);
          // const primaryVideo = allData?.recordings.find((recording) => recording.isPrimary);
          // if (primaryVideo) {
          //   setPrimaryVideoId(primaryVideo.id);
          // }
        }
        if (allData?.primaryVideoId) {
          setPrimaryVideoId(allData?.primaryVideoId);
        }
        setIsAutoAssignPrimaryVideo(allData?.autoAssignPrimaryVideo);
        if (allData?.attachImageTo) {
          setSelectedVariantOption(allData?.attachImageTo);
          setCheckedVariantOption(true);
        }
        if (allData?.inventory) {
          const productInventoryList = JSON.parse(allData.inventory)?.inventory;
          const tempList = productInventoryList.map((inventoryItem) => {
            const selectedInventory = warehouseList?.find((warehouse) => warehouse.id === parseInt(inventoryItem.warehouseId, 10));
            if (selectedInventory) {
              return {
                id: undefined,
                inventoryQuantityValue: inventoryItem?.inventoryQuantity,
                inventoryWarehouseValue: selectedInventory.name,
                isDropDown: false,
                inventoryWarehouseId: inventoryItem?.warehouseId?.toString()
              };
            }
            return null;
          });
          setInventory((prev) => ({
            ...prev,
            inventoryData: tempList
          }));
        } else {
          if (warehouseList) {
            const tempList = [
              {
                id: undefined,
                inventoryQuantityValue: 0,
                inventoryWarehouseValue: warehouseList[0].name,
                isDropDown: false,
                inventoryWarehouseId: warehouseList[0]?.id
              }
            ];
            setInventory((prev) => ({
              ...prev,
              inventoryData: tempList
            }));
          }
        }

        setInventory((prev) => ({
          ...prev,
          continueSellingChecked: allData?.sellOnOutOfStock
        }));

        let tempImagesArr: any = [];
        const isData = allData?.productMediasJson?.filter((x) => x.productMediaUrl !== null);
        if (isData) {
          tempImagesArr = isData
            .map((item) => {
              return {
                ...item,
                id: Number(item.productMediaId)
              };
            })
            .filter((mediaItem) => mediaItem.productMediaType === 'IMAGE');
        }

        setProductMedia(tempImagesArr);

        setGeneralInfo({
          ...generalInfo,
          value: allData.productName ? allData.productName : '',
          description: allData.productDescription ? allData.productDescription : '',
          updatedAt: allData.productUpdatedAt ? allData.productUpdatedAt : '',
          externalProvider: allData?.externalProductProvider ? allData?.externalProductProvider : undefined,
          externalId: allData?.externalProductId ? allData?.externalProductId : undefined
        });
        if (allData.variantLocationDetails) {
          if (history?.location?.state?.selectedVariants || selectedVariants?.length > 0) {
            let selectedVariantTypes: any[] = [];
            if (history?.location?.state?.selectedVariants) {
              selectedVariantTypes = history?.location?.state?.selectedVariants?.map((item) => item.split('/').sort());
            }
            if (selectedVariants) {
              selectedVariantTypes = selectedVariants?.map((item) => item?.variantType?.split('/').sort());
            }
            const tempSelectedVariants = allData.variantLocationDetails?.map((item) => {
              return { id: item.productVariantId, variantTypes: item.variantTypes.map((typeItem) => typeItem.variantTypeValue) };
            });

            const tempSelectedVariantIds = tempSelectedVariants
              .filter((item) => selectedVariantTypes.find((arry) => isSameArray(item.variantTypes, arry)))
              .map((item) => item.id);
            setSelectedVariantIds(tempSelectedVariantIds);
            setShowLabelPrintModal(true);
          }
          const productInventoryList = JSON.parse(allData?.inventory)?.inventory;
          // const totalQuantity = allData.variantLocationDetails
          //   .map((item) => item?.productVariantQty)
          //   .reduce((a, b) => {
          //     return a + b;
          //   });
          // const tempList = allData.variants[0]?.warehouseVariants?.map((inListData) => {
          //   return {
          //     inventoryQuantityValue: totalQuantity,
          //     inventoryWarehouseValue: inListData.name,
          //     isDropDown: false,
          //     inventoryWarehouseId: parseInt(inListData.warehouseId, 10)
          //   };
          // });
          // setInventory((prev) => ({
          //   ...prev,
          //   inventoryData: tempList
          // }));
          setTableData(getVariantTable(allData.variantLocationDetails, productInventoryList));
        }
        setProductStatus(capitalizeFirstLetter(allData.productStatus));

        setChannels({
          // online: {
          //   value: allData.visibility.indexOf('ONLINE_STORE') > -1 ? true : false,
          //   title: constVariables.common.channelList.channel1Title
          // },
          mobile: {
            value: allData.visibility.indexOf('MOBILE_APP') > -1 ? true : false,
            title: constVariables.common.channelList.channel2Title
          }
          // facebook: {
          //   value: allData.visibility.indexOf('FACEBOOK_STORE') > -1 ? true : false,
          //   title: constVariables.common.channelList.channel3Title
          // }
        });

        if (allData.showRetailPrice) {
          setRetailPriceToggle(true);
        }

        setPricing({
          cost: '',
          salePrice: allData.salePrice ? allData.salePrice : '',
          retailPrice: allData.retailPrice ? allData.retailPrice : '',
          margin: allData.margin ? allData.margin : '',
          profit: allData.profit ? allData.profit : '',
          costPerItem: allData.perItemCost ? allData.perItemCost : '',
          taxCode: allData.taxCode ? allData.taxCode : pricing.taxCode,
          finalSale: allData?.isFinalSale,
          showRetailPrice: allData.showRetailPrice
        });
        setShipping({
          weightMeasurement: dimensionsData ? dimensionsData.weight.value.toString() : '',
          widthMeasurement: dimensionsData ? dimensionsData.width.value.toString() : '',
          heightMeasurement: dimensionsData ? dimensionsData.height.value.toString() : '',
          depthMeasurement: dimensionsData ? dimensionsData.depth.value.toString() : '',
          weight:
            dimensionsData && dimensionsData.weight.unit?.toString() !== ''
              ? dimensionsData.weight.unit?.toString()
              : storeUnit?.weight,
          measurement:
            dimensionsData && dimensionsData.width.unit?.toString() !== ''
              ? dimensionsData.width.unit?.toString()
              : storeUnit?.measurement,
          shippingCost: allData.shippingPrice ? allData.shippingPrice : '',
          freeShippingCheck: allData.shippingPrice === 0 ? true : false,
          signConfirmationCheck: allData.addSignatureConfirmation,
          shipmentInsauranceCheck: allData.addShipmentInsurance
        });
        setProductIdentifiers({
          skuValue: allData.productSku ? allData.productSku : '',
          barCodeValue: allData.productBarcode ? allData.productBarcode : '',
          brandValue: allData.brand ? allData.brand : '',
          brandStyleValue: allData.brandStyle ? allData.brandStyle : ''
        });
        setProductAvailableFrom(allData?.productAvailableFrom);
        setOrganization((organization) => {
          return { ...organization, sensitiveProduct: allData?.sensitive };
        });
      }
      setIsPageLoading(false);
    },
    onError: (error) => {
      console.log('error::', error);
    }
  });

  const [getAvalaraSettings] = useLazyQuery(GET_AVALARA_CONFIGURATION, {
    onCompleted: (data) => {
      setTimeout(() => {
        setAvaLaraAPICalled(true);
      }, 1000);
      if (data?.getAvalaraConfiguration?.defaultProductTaxCode && !pricing.taxCode && !productId) {
        setPricing({
          ...pricing,
          taxCode: data?.getAvalaraConfiguration?.defaultProductTaxCode
        });
      }
    },
    onError: (error) => {
      setTimeout(() => {
        setAvaLaraAPICalled(true);
      }, 1000);
      console.error(error);
    }
  });

  const [addProduct] = useMutation(ADD_PRODUCT, {
    onError: (error) => {
      setFormChanges(false);
      setDisableSaveButton(false);
      setShowProductSavingModal(false);
      showToast({
        errorText: error.message,
        message: `Error occured while adding Product: ${error.message}`
      });
    },
    onCompleted: (res) => {
      setFormChanges(false);
      setDisableSaveButton(false);
      showToast({ successText: 'Product added successfully', message: `The Product has been added sucessfully` });
      const shouldLabelPrinting = localStorage.getItem('shouldLabelPrinting');
      if (shouldLabelPrinting) {
        if (res?.addProduct?.additionalData) {
          setShowProductSavingModal(false);
          const createdProduct = JSON.parse(res?.addProduct?.additionalData);
          history.push(`/products/allProducts/edit/${createdProduct?.productId}`, {
            continueLabelPrinting: true,
            labelPrintType,
            selectedVariants: selectedVariants?.length > 0 ? selectedVariants?.map((variant) => variant.variantType) : []
          });
        }
      } else {
        history.push(ROUTES.products.allProducts.main, { tab: productStatus });
      }
    }
  });

  const [deleteFromCart] = useMutation(DELETE_FROM_CART, {
    onError: (error) => {
      showToast({
        errorText: error.message,
        message: `Error occured while deleting product from cart: ${error.message}`
      });
    },
    onCompleted: (result) => {
      showToast({ successText: result.deleteFromCart.message, message: result.deleteFromCart.message });
    }
  });

  const [deleteFromWishList] = useMutation(DELETE_FROM_WISHLIST, {
    onError: (error) => {
      showToast({
        errorText: error.message,
        message: `Error occured while deleting product from favorites: ${error.message}`
      });
    },
    onCompleted: (result) => {
      showToast({ successText: result.deleteFromWishList.message, message: result.deleteFromWishList.message });
    }
  });

  const [editProduct] = useMutation(EDIT_PRODUCT, {
    onError: (error) => {
      setFormChanges(false);
      setDisableSaveButton(false);
      setShowProductSavingModal(false);
      showToast({
        errorText: error.message,
        message: `Error occured while adding Product: ${error.message}`
      });
    },
    onCompleted: async (response) => {
      initializeFormChanges();
      showToast({ successText: 'Product saved successfully', message: `The Product info has been edited sucessfully` });
      setShowProductSavingModal(false);
      const { data: updatedProductData } = await getProductDetails();
      const shouldLabelPrinting = localStorage.getItem('shouldLabelPrinting');
      if (shouldLabelPrinting && selectedVariants?.length === 0) {
        localStorage.removeItem('shouldLabelPrinting');
        setShowLabelPrintModal(true);
      }

      sendCahceUpdatedBroadcast(
        {
          id: cache.identify(updatedProductData?.getAllProductsV3?.products[0]),
          data: updatedProductData?.getAllProductsV3?.products[0]
        },
        'product-cache-updated'
      );
    }
  });

  const [addProductNote] = useMutation(ADD_PRODUCT_NOTE, {
    onCompleted: () => {
      if (productNoteRefetch) {
        productNoteRefetch();
      }
    },
    onError: (error) => {
      console.error('Add product Note error', error);
    }
  });

  const [editProductNote] = useMutation(EDIT_PRODUCT_NOTE, {
    onCompleted: () => {
      if (productNoteRefetch) {
        productNoteRefetch();
      }
    },
    onError: (error) => {
      console.error('Edit product Note error', error);
    }
  });

  const [deleteProductNote] = useMutation(DELETE_PRODUCT_NOTE, {
    onCompleted: () => {
      if (productNoteRefetch) {
        productNoteRefetch();
      }
    },
    onError: (error) => {
      console.error('Delete product Note error', error);
    }
  });

  const [callDeleteProducts] = useMutation(DELETE_PRODUCTS, {
    onCompleted: () => {
      setModal((prev) => ({ ...prev, deleteModal: false }));
      setDataChanged(false);
      history.replace(ROUTES.products.allProducts.main);
    }
  });

  const [editProducts] = useMutation(EDIT_PRODUCTS, {
    onCompleted: (res) => {
      if (res) {
        getProductDetails();
        showToast({ successText: 'Product archieved successfully', message: `The product has been archieved sucessfully` });
        initializeFormChanges();
      }
    }
  });

  const initializeFormChanges = () => {
    setProdDetailAPICalled(false);
    setFormChanges(false);
    setDisableSaveButton(false);
  };

  const addOrRemoveCollectionListItem = (id: number) => {
    if (collectionIdList.includes(id)) {
      const tempList = collectionIdList.filter((data) => data !== id);
      setCollectionIdList(tempList);
    } else {
      setCollectionIdList([...collectionIdList, id]);
    }
  };

  const addNewNote = (newNoteData: string) => {
    addProductNote({
      variables: {
        input: {
          productId,
          note: newNoteData
        }
      }
    });
  };

  const editOldNote = (oldNoteData: string, noteId: number) => {
    editProductNote({
      variables: {
        input: {
          productId,
          note: oldNoteData,
          id: noteId
        }
      }
    });
  };

  const deleteOldNote = (noteId: number) => {
    deleteProductNote({
      variables: {
        input: {
          productId,
          id: noteId
        }
      }
    });
  };

  const getVariantTable = (data, productInventoryList) => {
    let tempOptArray: any = [];
    data.forEach((ele1) => {
      ele1.variantTypes.forEach((ele2) => {
        const isIT = tempOptArray.findIndex((x) => x.title === ele2.variantTypeName);
        if (isIT === -1) {
          tempOptArray = [...tempOptArray, { title: ele2.variantTypeName, value: [ele2.variantTypeValue] }];
        } else {
          const isIT2 = tempOptArray[isIT].value.findIndex((x) => x === ele2.variantTypeValue);

          if (isIT2 === -1) {
            tempOptArray[isIT].value = [...tempOptArray[isIT].value, ele2.variantTypeValue];
          }
        }
      });
    });

    setOptions(tempOptArray);

    const inventoryList = productInventoryList?.map((inventoryItem) => {
      const id = inventoryItem.warehouseId;
      const updatedData = data
        .filter((item) => item.variantLocation[0]?.warehouseId === parseInt(id, 10))
        .map((item) => {
          return { id: undefined, warehouseId: id.toString(), quantity: item.productVariantQty };
        });
      return updatedData;
    });

    let variantInventoryList: any[] = [];
    if (inventoryList) {
      const length = inventoryList[0]?.length;
      const count = inventoryList.length;

      if (length && count) {
        for (var i = 0; i < length; i++) {
          let ret: any[] = [];
          for (var j = 0; j < count; j++) {
            const item = inventoryList[j];
            ret.push(item[i]);
          }
          variantInventoryList.push(ret);
        }
      }
    }

    const tempData = data.map((x, index) => {
      const nameArr = x.variantTypes.map((x) => x.variantTypeValue);
      return {
        id: x.productVariantId,
        img: x.variantMediaUrl,
        price: x.productVariantPrice,
        qty: x.productVariantQty,
        sku: x.productVariantSku,
        variantType: nameArr.join('/'),
        barcode: x.productVariantBarcode ? x.productVariantBarcode : '',
        status: x.variantStatus ? x.variantStatus : 'INACTIVE',
        weight: x.variantWeight ? x.variantWeight : '0',
        warehouseInventory: variantInventoryList[index] ? variantInventoryList[index] : [],
        media: x.variantMediaUrl ? { url: x.variantMediaUrl } : null
      };
    });

    if (tempData?.length > 0) {
      setOptionChecked(true);
      setOptionDone(true);

      return tempData;
    }
  };

  const capitalizeFirstLetter = (data: string) => {
    if (data?.length > 0) {
      return data.charAt(0).toUpperCase() + data.slice(1).toLowerCase();
    }
    return '';
  };

  useEffect(() => {
    if (productId) {
      setPageHeading(constVariables.AllProductsMenu.editProduct);
      setIsPageLoading(true);
      getWarehouses().then(() => {
        getProductDetails();
      });
    } else {
      document.title = 'Products - New';
      getAvalaraSettings();
      getWarehouses();
      return;
    }
  }, []);

  /* Don't remove this code */

  // useEffect(() => {
  //   const inventoryIDs = inventory.inventoryData
  //     .filter((item) => item.inventoryWarehouseId !== undefined)
  //     .map((item) => item.inventoryWarehouseId?.toString());
  //   const warehouses = tableData.map((item) => item.warehouseInventory).flat();
  //   const array: number[] = inventoryIDs.map((id, index) => {
  //     return 0;
  //   });

  //   warehouses.forEach((warehouse) => {
  //     const { warehouseId, quantity } = warehouse;
  //     const index = inventoryIDs.findIndex((id) => id === warehouseId);
  //     if (index > -1 && quantity && warehouseId) {
  //       array[index] += quantity;
  //     }
  //   });

  //   let tempInventory = { ...inventory };
  //   array.forEach((quantity, index) => {
  //     const updatedObj = { ...inventory.inventoryData[index], inventoryQuantityValue: quantity };
  //     tempInventory = {
  //       ...tempInventory,
  //       inventoryData: [...tempInventory.inventoryData.slice(0, index), updatedObj, ...tempInventory.inventoryData.slice(index + 1)]
  //     };
  //   });

  //   setInventory({ ...tempInventory });
  // }, [tableData]);

  useEffect(() => {
    let totalQuantity = 0;
    tableData?.forEach((item) => {
      const { warehouseInventory } = item;
      if (warehouseInventory[0]) {
        totalQuantity += warehouseInventory[0]?.quantity ? warehouseInventory[0]?.quantity : 0;
      }
    });
    const updatedInventory = inventory?.inventoryData.map((item) => {
      return { ...item, inventoryQuantityValue: totalQuantity };
    });
    setInventory((inventory) => {
      return {
        ...inventory,
        inventoryData: updatedInventory
      };
    });
  }, [tableData]);

  const getVariantTypes = (tableInfo: TableData[], types: any) => {
    return tableInfo.map((data) => {
      const typeArr = data.variantType.split('/').map((str) => {
        const name = types.find((elem) => elem?.values?.includes(str) || false)?.type;
        return {
          name,
          value: str
        };
      });

      if (data.id) {
        return {
          id: data?.id,
          variantTypes: typeArr,
          price: data.price,
          sku: data.sku,
          media: data.media,
          barcode: data.barcode,
          weight: data.weight,
          warehouseInventory: data.warehouseInventory.map((item) => {
            return { id: item.id, warehouseId: item.warehouseId, quantity: item.quantity };
          }),
          status: data.status
        };
      } else {
        return {
          variantTypes: typeArr,
          price: data.price,
          sku: data.sku,
          media: data.media,
          barcode: data.barcode,
          weight: data.weight,
          warehouseInventory: data.warehouseInventory,
          status: data.status
        };
      }
    });
  };

  const visibleArray = () => {
    let array: string[] = [];
    if (channels.mobile.value) {
      array = [...array, 'MOBILE_APP'];
    }
    // if (channels.facebook.value) {
    //   array = [...array, 'FACEBOOK_STORE'];
    // }
    // if (channels.online.value) {
    //   array = [...array, 'ONLINE_STORE'];
    // }
    return array;
  };

  const fieldCheck = (field: string, value: string) => {
    let returnField = '';
    if (field === 'generalInfoTitleError') {
      returnField = constVariables.common.errors.errProductName;
    }
    if (field === 'retailPrice') {
      returnField = constVariables.common.errors.errRetailPrice;
    }
    if (field === 'salePrice') {
      returnField = constVariables.common.errors.errSalePrice;
    }
    if (!value) {
      setErrorMessages((prev) => ({
        ...prev,
        [`${field}`]: returnField
      }));
      return false;
    }
    return true;
  };

  const errorHandler = (fieldname: string, value: any) => {
    setErrorMessages((prev) => ({
      ...prev,
      [`${fieldname}`]: value
    }));
  };

  const sendMedia = (productsImage: Array<any>) => {
    setProductMedia(productsImage);
  };

  const addProductHandler = () => {
    setDataChanged(false);
    const prodField = fieldCheck('generalInfoTitleError', generalInfo.value);
    const salefield = fieldCheck('salePrice', pricing.salePrice);
    const retailField = retailPriceToggle ? fieldCheck('retailPrice', pricing.retailPrice) : true;
    const modifiedTable: any[] = [];
    const modifiedOptions: any[] = [];

    tableData?.map((type: TableData) => {
      const xxx: any = {
        id: type.id,
        variantType: type.variantType,
        price: parseFloat(type.price),
        barcode: type.barcode,
        weight: parseFloat(type.weight),
        status: type.status,
        sku: type.sku,
        media: {
          id: parseInt(`${type?.media?.id}`, 10),
          type: 'IMAGE',
          url: type.img
        },
        warehouseInventory: type.warehouseInventory
      };
      if (!type.img || type.img === undefined || type.img === '') {
        delete xxx.media;
      }
      modifiedTable.push(xxx);
      return type;
    });

    const errorsArray: any[] = [];
    options.map((opt: VariantOptions) => {
      if (opt.title === '' && optionChecked) {
        errorHandler('optionTitle', constVariables.common.errors.errVariantTitle);
        opt.errTitle = true;
        errorsArray.push(true);
      } else {
        opt.errTitle = false;
        errorHandler('optionTitle', '');
        errorsArray.pop();
      }
      if (opt.value.length === 0 && optionChecked) {
        opt.errVariant = true;
        errorHandler('optionVariant', constVariables.common.errors.errVariantField);
        errorsArray.push(true);
      } else {
        opt.errVariant = false;
        errorHandler('optionVariant', '');
        errorsArray.pop();
      }
      modifiedOptions.push({
        type: opt.title,
        values: opt.value
      });
      return opt;
    });

    if (prodField && salefield && retailField && !errorMessages.retailPrice && errorsArray.length <= 0) {
      if (productId) {
        // To edit Product
        setDisableSaveButton(true);
        let newMediaList: any = [];
        if (productMedia?.length) {
          newMediaList = productMedia.map((instance) => {
            if (instance?.isPrimary) {
              delete instance.isPrimary;
            }
            return {
              id: parseInt(instance.productMediaId, 10),
              url: instance.productMediaUrl,
              type: instance.productMediaType || 'IMAGE',
              position: instance.productMediaPosition
            };
          });
        }

        const tempInventory = inventory.inventoryData
          ?.filter((item) => item?.inventoryWarehouseId !== undefined)
          .map((item) => {
            return { id: item?.id, warehouseId: item?.inventoryWarehouseId?.toString(), quantity: item?.inventoryQuantityValue };
          });

        let newRecordingList: any[] = [];
        if (recordings?.length) {
          newRecordingList = recordings.map((instance) => {
            return {
              ...instance,
              position: undefined
            };
          });
        }

        editProduct({
          variables: {
            input: {
              id: Number(productId),
              tagIds: appliedTags.map((data) => data?.id),
              inventory: inventoryFormatted && JSON.stringify(inventoryFormatted),
              name: generalInfo.value,
              collectionIds: collectionIdList,
              vendorId: productVendorID,
              categoryId: 6,
              medias: newMediaList ? newMediaList : { url: '', type: '' },
              status: productStatus.toUpperCase(),
              description: generalInfo.description ? generalInfo.description : '',
              salePrice: !pricing.salePrice ? 0 : parseFloat(pricing.salePrice),
              shippingPrice: shipping.freeShippingCheck ? 0 : Number(shipping.shippingCost),
              retailPrice: pricing.retailPrice ? parseFloat(pricing.retailPrice) : 0,
              perItemCost: pricing.costPerItem ? parseFloat(pricing.costPerItem) : 0,
              visibility: visibleArray(),
              showRetailPrice: pricing.showRetailPrice,
              variants: optionChecked ? getVariantTypes(modifiedTable, modifiedOptions) : [],
              variantOrder: JSON.stringify({
                orders: options?.map((item, index) => ({ name: item.title, order: index }))
              }),
              sku: productIdentifiers.skuValue,
              code: productIdentifiers.barCodeValue,
              brand: productIdentifiers.brandValue,
              brandStyle: productIdentifiers.brandStyleValue,
              attachImageTo: checkedVariantOption && selectedVariantOption ? selectedVariantOption : '',
              taxCode: pricing.taxCode,
              addSignatureConfirmation: shipping.signConfirmationCheck,
              addShipmentInsurance: shipping.shipmentInsauranceCheck,
              sellOnOutOfStock: inventory.continueSellingChecked,
              dimensions: JSON.stringify({
                height: {
                  value: shipping.heightMeasurement,
                  unit: shipping.measurement
                },
                width: {
                  value: shipping.widthMeasurement,
                  unit: shipping.measurement
                },
                depth: {
                  value: shipping.depthMeasurement,
                  unit: shipping.measurement
                },
                weight: {
                  value: shipping.weightMeasurement,
                  unit: shipping.weight
                }
              }),
              warehouseInventory: tempInventory,
              productTypeId,
              productAvailableFrom,
              autoAssignPrimaryVideo: isAutoAssignPrimaryVideo,
              primaryVideoId: primaryVideoId !== '' ? parseInt(primaryVideoId, 10) : undefined
              // sensitive: organization.sensitiveProduct
            }
          }
        });
      } else {
        setDisableSaveButton(true);

        // To add new Product
        let tempNotesList;
        if (notesData) {
          tempNotesList = notesData.map((data) => data.note);
        }
        let newMediaList: any = [];
        if (productMedia?.length) {
          newMediaList = productMedia.map((instance) => {
            if (instance?.isPrimary) {
              delete instance.isPrimary;
            }
            if (instance?.fileimage) {
              delete instance.fileimage;
            }
            return {
              url: instance.productMediaUrl,
              type: 'IMAGE',
              position: instance.productMediaPosition
            };
          });
        }

        const tempInventory = inventory.inventoryData
          .filter((item) => item.inventoryWarehouseId !== undefined)
          .map((item) => {
            return { warehouseId: item.inventoryWarehouseId?.toString(), quantity: item.inventoryQuantityValue };
          });

        addProduct({
          variables: {
            input: {
              name: generalInfo.value,
              tagIds: appliedTags.map((data) => data.id),
              collectionIds: collectionIdList,
              inventory: inventoryFormatted && JSON.stringify(inventoryFormatted),
              description: generalInfo.description ? generalInfo.description : ' ',
              status: productStatus.toUpperCase(),
              salePrice: !pricing.salePrice ? 0 : parseFloat(pricing.salePrice),
              shippingPrice: shipping.freeShippingCheck ? 0 : Number(shipping.shippingCost),
              retailPrice: pricing.retailPrice ? parseFloat(pricing.retailPrice) : 0,
              perItemCost: pricing.costPerItem ? parseFloat(pricing.costPerItem) : 0,
              visibility: visibleArray(),
              variants: optionChecked ? getVariantTypes(modifiedTable, modifiedOptions) : [],
              variantOrder: JSON.stringify({
                orders: options?.map((item, index) => ({ name: item.title, order: index }))
              }),
              vendorId: productVendorID,
              categoryId: 6,
              medias: newMediaList ? newMediaList : { url: '', isPrimary: true, type: '' },
              notes: tempNotesList,
              sku: productIdentifiers.skuValue,
              code: productIdentifiers.barCodeValue,
              brand: productIdentifiers.brandValue,
              brandStyle: productIdentifiers.brandStyleValue,
              attachImageTo: checkedVariantOption && selectedVariantOption ? selectedVariantOption : '',
              taxCode: pricing.taxCode,
              addSignatureConfirmation: shipping.signConfirmationCheck,
              addShipmentInsurance: shipping.shipmentInsauranceCheck,
              sellOnOutOfStock: inventory.continueSellingChecked,
              showRetailPrice: pricing.showRetailPrice,
              dimensions: JSON.stringify({
                height: {
                  value: shipping.heightMeasurement,
                  unit: shipping.measurement
                },
                width: {
                  value: shipping.widthMeasurement,
                  unit: shipping.measurement
                },
                depth: {
                  value: shipping.depthMeasurement,
                  unit: shipping.measurement
                },
                weight: {
                  value: shipping.weightMeasurement,
                  unit: shipping.weight
                }
              }),
              warehouseInventory: tempInventory,
              productTypeId,
              productAvailableFrom,
              autoAssignPrimaryVideo: isAutoAssignPrimaryVideo
            }
          }
        });
      }
    } else {
      setShowProductSavingModal(false);
      setModal((prev) => ({ ...prev, saveModal: true }));
    }
    if (errorsArray.length > 0) {
      setShowProductSavingModal(false);
      setModal((prev) => ({ ...prev, saveModal: true }));
    }
  };

  useEffect(() => {
    if (inventory && inventory.inventoryData && inventory.inventoryData[0]?.inventoryWarehouseId) {
      const tempList = inventory.inventoryData?.map((invData) => {
        return { warehouseId: invData.inventoryWarehouseId, inventoryQuantity: invData.inventoryQuantityValue };
      });

      setInventoryFormatted({ inventory: tempList });

      /* Don't remove this code */

      // const updatedTableData = tableData.map((item, index) => {
      //   const newWarehouseArray = inventory.inventoryData.map((item) => {
      //     return { id: item.id, warehouseId: item.inventoryWarehouseId?.toString(), quantity: index === 0 ? item.inventoryQuantityValue : 0 }
      //   });
      //   return { ...item, warehouseInventory: newWarehouseArray }
      // });
      // setTableData(updatedTableData);
    }
  }, [inventory]);

  useEffect(() => {
    if (avaLaraAPICalled || (productId && wareHouseAPICalled && prodNoteAPICalled && prodDetailAPICalled)) {
      setFormChanges(true);
    } else {
      setFormChanges(false);
    }
  }, [
    shipping.weightMeasurement,
    shipping.widthMeasurement,
    shipping.heightMeasurement,
    shipping.depthMeasurement,
    shipping.weight,
    shipping.measurement,
    shipping.shippingCost,
    shipping.freeShippingCheck,
    shipping.signConfirmationCheck,
    shipping.shipmentInsauranceCheck,
    generalInfo.value,
    generalInfo.description,
    pricing.cost,
    pricing.costPerItem,
    pricing.finalSale,
    pricing.margin,
    pricing.profit,
    pricing.retailPrice,
    pricing.showRetailPrice,
    pricing.salePrice,
    pricing.taxCode,
    productIdentifiers.barCodeValue,
    productIdentifiers.brandStyleValue,
    productIdentifiers.brandValue,
    productIdentifiers.skuValue,
    inventory.continueSellingChecked,
    inventory.inventoryData,
    notesData,
    // channels.facebook,
    channels.mobile,
    // channels.online,
    productStatus,
    appliedTags,
    options,
    retailPriceToggle,
    productMedia,
    tableData,
    collectionIdList,
    productVendorID,
    productTypeId,
    optionChecked,
    productAvailableFrom,
    // Video values
    recordings,
    isAutoAssignPrimaryVideo,
    primaryVideoId,
    organization.sensitiveProduct
  ]);

  const onDiscardHandler = (state: string) => {
    if (state === 'discard') {
      setModal((prev) => ({ ...prev, discardModal: !modal.discardModal }));
      history.replace('/products/allProducts');
    }
    if (state === 'saveError') {
      setModal((prev) => ({ ...prev, saveModal: !modal.saveModal }));
    }
    if (state === 'leave') {
      setModal((prev) => ({ ...prev, leaveModal: !modal.leaveModal }));
    }
    if (state === 'delete') {
      setModal((prev) => ({ ...prev, deleteModal: !modal.deleteModal }));
    }
    if (state === 'close') {
      setModal({
        discardModal: false,
        leaveModal: false,
        saveModal: false,
        deleteModal: false
      });
    }
  };

  useEffect(() => {
    if (pricing.salePrice.length > 0) {
      setErrorMessages((prev) => ({
        ...prev,
        salePrice: ''
      }));
    }
    if (generalInfo.value.length > 0) {
      setErrorMessages((prev) => ({
        ...prev,
        generalInfoTitleError: ''
      }));
    }
  }, [pricing.salePrice, generalInfo.value]);

  const backbtnHandler = () => {
    document.body.style.overflow = 'unset';
    if (formChanges) {
      setModal((prev) => ({ ...prev, leaveModal: true }));
    } else {
      if (history.location.state?.from) {
        history.push(history.location.state.from);
      } else {
        history.push(ROUTES.products.allProducts.main);
      }
    }
  };

  const leavePageHandler = () => {
    // setDataChanged(false);
    // NOTE: Push to targetted path or products, handled with timeout for now.
    document.body.style.overflow = 'unset';
    setFormChanges(false);
    setTimeout(() => {
      if (targettedPath) {
        if (locationState) {
          history.push({
            pathname: targettedPath,
            state: locationState
          });
        } else {
          history.push(targettedPath);
        }
      } else {
        history.push(ROUTES.products.allProducts.main);
        // history.goBack();
      }
    }, 1000);
  };

  const updateChannelValues = (channelObject: ChannelType) => {
    let tempObj;
    if (channels.mobile.title === channelObject.title) {
      tempObj = { ...channels, mobile: { ...channelObject, value: !channelObject.value } };
    }

    /* Don't Remove this */
    // if (channels.facebook.title === channelObject.title) {
    //   tempObj = { ...channels, facebook: { ...channelObject, value: !channelObject.value } };
    // } else if (channels.mobile.title === channelObject.title) {
    //   tempObj = { ...channels, mobile: { ...channelObject, value: !channelObject.value } };
    // } else if (channels.online.title === channelObject.title) {
    //   tempObj = { ...channels, online: { ...channelObject, value: !channelObject.value } };
    // }

    setChannels(tempObj);
  };

  useEffect(() => {
    const preventRoute = history.block((e: any) => {
      setTargettedPath(e.pathname);
      setLocationState(e.state);
      if (formChanges) {
        setModal((prev) => ({ ...prev, leaveModal: true }));
        return false;
      } else {
        return true;
      }
    });

    return () => {
      preventRoute();
    };
  }, [history, formChanges]);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setModal((prev) => ({
        ...prev,
        leaveModal: false,
        discardModal: false,
        saveModal: false
      }));
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction);

    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction]);

  const setTheInventory = (data: Inventory) => {
    setInventory(data);
  };

  const deleteProduct = () => {
    callDeleteProducts({
      variables: {
        input: {
          productIds: [productId]
        }
      }
    });
  };

  const handleArchiveProduct = () => {
    if (productId !== undefined) {
      editProducts({
        variables: {
          input: [
            {
              productIds: [productId],
              status: 'ARCHIVE'
            }
          ]
        }
      });
    }
  };

  const isVisibleArchiveButton = useMemo(() => {
    if (productId && role !== UserRoles.FULFILMENT_ASSOCIATE && !isPageLoading) {
      return true;
    }
    return false;
  }, [productId, role, isPageLoading]);

  const closeMoreActionDropdown = () => {
    setShowMoreActions(false);
  };

  const handleMoreActions = (option) => {
    if (option.id === 1) {
      setLabelPrintType('sku');
    } else {
      setLabelPrintType('barcode');
    }
    setShowMoreActions(false);
    setShowLabelPrintModal(true);
  };

  const handleCloseLabelPrintModal = () => {
    document.body.style.overflow = 'unset';
    setShowLabelPrintModal(false);
  };

  const handleCloseProductSavingModal = () => {
    document.body.style.overflow = 'unset';
    setShowProductSavingModal(false);
  };

  const handleSaveProductAndContinuePrintLabel = () => {
    // document.body.style.overflow = 'unset';
    // setShowProductSavingModal(false);
    addProductHandler();
  };

  const handleShowResponseDrawer = () => {
    document.body.style.overflow = 'hidden';
    setShowTiktokResponseDrawer(true);
  };

  const handleCloseResponseDrawer = () => {
    document.body.style.overflow = 'unset';
    setShowTiktokResponseDrawer(false);
  };

  return (
    <>
      {formChanges && (
        <SaveHeader
          onSave={addProductHandler}
          saveDisabled={disableSaveButton}
          onDiscard={() => {
            setDataChanged(false);
            setModal((prev) => ({
              ...prev,
              discardModal: !modal.discardModal
            }));
          }}
        />
      )}
      <div className="details-page">
        {showResponseMenu && (
          <button className="response-menu cursor-pointer btn btn-primary" onClick={handleShowResponseDrawer}>
            Show Suggestions
          </button>
        )}
        <div className={`details-page-container ${formChanges ? 'pt-0' : ''}`}>
          <div className="w-100 hide-scrollbar">
            <FlexBox className={`align-items-center justify-content-between px-0 ${formChanges ? 'p-t-24 p-b-40' : 'p-y-40'}`}>
              <FlexBox className="align-items-center">
                <img src={BackRoundedIcon} alt={BackRoundedIcon} className="cursor-pointer" onClick={backbtnHandler} />
                <h2 className="page-title px-4 m-0">{pageHeading}</h2>
              </FlexBox>
              <FlexBox className="align-items-center">
                {productId && (
                  <div className="position-relative">
                    <button
                      className={`btn btn-sm m-r-16 ${showMoreActions ? 'btn-primary' : 'btn-outlined-primary'}`}
                      onClick={() => {
                        setShowMoreActions(!showMoreActions);
                      }}
                    >
                      {constVariables.ProductsTab.moreActions}
                    </button>
                    <OutsideClickHandler onOutsideClick={closeMoreActionDropdown}>
                      <Dropdown
                        className="dropdown-custom-width mt-1"
                        data={constVariables.productDetailsMoreActionDropDownData}
                        selected={showMoreActions}
                        onSelect={handleMoreActions}
                        closeDropdown={closeMoreActionDropdown}
                      />
                    </OutsideClickHandler>
                  </div>
                )}
                {isVisibleArchiveButton && (
                  <button
                    className="btn btn-sm btn-danger m-0"
                    onClick={handleArchiveProduct}
                    disabled={productStatus === 'Archive' ? true : false}
                  >
                    {constVariables.common.general.archiveProduct}
                  </button>
                )}
                {/* <button className="btn btn-danger btn-sm ms-3 me-0" onClick={deleteProduct}>
                    {constVariables.common.general.deleteProduct}
                  </button> */}
              </FlexBox>
            </FlexBox>
          </div>
          {isPageLoading ? (
            <Loader type="page" className="placeholder-content card m-0" />
          ) : (
            <div className="width-animation w-100 hide-scrollbar">
              <AddProductPresentational
                appliedTags={appliedTags}
                setAppliedTags={setAppliedTags}
                optionDone={optionDone}
                setOptionDone={setOptionDone}
                sendMedia={sendMedia}
                generalInfo={generalInfo}
                setGeneralInfo={setGeneralInfo}
                statusInfo={statusInfo}
                status={productStatus}
                setStatus={setProductStatus}
                channels={channels}
                updateChannelValues={updateChannelValues}
                pricing={pricing}
                setPricing={setPricing}
                shipping={shipping}
                setShipping={setShipping}
                errorMessages={errorMessages}
                setErrorMessages={setErrorMessages}
                onDiscard={onDiscardHandler}
                modal={modal}
                setModal={setModal}
                leavePageHandler={leavePageHandler}
                options={options}
                setOptions={setOptions}
                optionChecked={optionChecked}
                setOptionChecked={setOptionChecked}
                tableData={tableData}
                setTableData={setTableData}
                productIdentifiers={productIdentifiers}
                setProductIdentifiers={setProductIdentifiers}
                inventory={inventory}
                setInventory={setTheInventory}
                organization={organization}
                setOrganization={setOrganization}
                warehouseList={warehouseList}
                setWarehouseList={setWarehouseList}
                notesData={notesData}
                setNotesData={setNotesData}
                addNewNote={addNewNote}
                editOldNote={editOldNote}
                deleteOldNote={deleteOldNote}
                productId={Number(productId)}
                selectedVariantOption={selectedVariantOption}
                setSelectedVariantOption={setSelectedVariantOption}
                checkedVariantOption={checkedVariantOption}
                setCheckedVariantOption={setCheckedVariantOption}
                addOrRemoveCollectionListItem={addOrRemoveCollectionListItem}
                collectionIdList={collectionIdList}
                deleteProduct={deleteProduct}
                deleteFromCart={deleteFromCart}
                deleteFromWishList={deleteFromWishList}
                retailPriceToggle={retailPriceToggle}
                setRetailPriceToggle={setRetailPriceToggle}
                productVendorID={productVendorID}
                setProductVendorID={setProductVendorID}
                productMedia={productMedia}
                updateProductMedia={updateProductMedia}
                setProductMedia={setProductMedia}
                productTypeId={productTypeId}
                setProductTypeId={setProductTypeId}
                getProductData={getProductDetails}
                initializeFormChanges={initializeFormChanges}
                productAvailableFrom={productAvailableFrom}
                setProductAvailableFrom={setProductAvailableFrom}
                recordings={recordings}
                isAutoAssignPrimaryVideo={isAutoAssignPrimaryVideo}
                setIsAutoAssignPrimaryVideo={setIsAutoAssignPrimaryVideo}
                primaryVideoId={primaryVideoId}
                setPrimaryVideoId={setPrimaryVideoId}
                formChanges={formChanges}
                setLabelPrintType={setLabelPrintType}
                setShowLabelPrintModal={setShowLabelPrintModal}
                setShowProductSavingModal={setShowProductSavingModal}
                selectedVariants={selectedVariants}
                setSelectedVariants={setSelectedVariants}
                setSelectedVariantIds={setSelectedVariantIds}
              />
            </div>
          )}
        </div>
      </div>
      {showProductSavingModal && (
        <CustomModal show={showProductSavingModal} closeModal={handleCloseProductSavingModal}>
          <DiscardModal
            title="Save product"
            cancelBtnTitle="Cancel"
            actionBtnTitle={disableSaveButton ? 'Saving' : 'Save'}
            message="Please save the product to proceed with label printing."
            isPrimaryBtn
            cancelBtnHandler={handleCloseProductSavingModal}
            actionBtnHandler={handleSaveProductAndContinuePrintLabel}
            isLoading={disableSaveButton}
          />
        </CustomModal>
      )}
      {showLabelPrintModal && (
        <CustomModal
          show={showLabelPrintModal}
          closeModal={handleCloseLabelPrintModal}
          bodyClassname={`${expandedViewLabelPrintModal ? 'm-0' : ''}`}
        >
          <LabelPrintModal
            closeModal={handleCloseLabelPrintModal}
            labelPrintType={labelPrintType}
            expandedViewLabelPrintModal={expandedViewLabelPrintModal}
            toggleExpand={() => setExpandedViewLabelPrintModal(!expandedViewLabelPrintModal)}
            selectedProducts={[parseInt(productId, 10)]}
            variantIds={selectedVariantIds}
          />
        </CustomModal>
      )}

      <TiktokResponseDrawer
        showDrawer={showTiktokResponseDrawer}
        closeDrawer={handleCloseResponseDrawer}
        tiktokResponse={tiktokResponse}
      />
    </>
  );
};

export default AddProduct;
