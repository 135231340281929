import { gql } from '@apollo/client';

export const GET_CATALOG_PRODUCT_CONDITIONS = gql`
  query GetCatalogProductConditions @api(name: "productsAPI") {
    getCatalogProductConditions {
      id
      name
      operations
    }
  }
`;
