import { facebookAuthConfig } from 'src/apollo/reactiveVariables';
import { envUrls } from 'src/constants';

import { FacebookService } from 'src/services';
import { IFacebookConfig } from 'src/types/FacebookConfig.types';

export const initFacebookSdk = (): Promise<any> => {
  return new Promise((resolve) => {
    // wait for facebook sdk to initialize before starting the react app
    window.fbAsyncInit = function () {
      window.FB?.init({
        appId: envUrls.facebookAppId,
        cookie: true,
        xfbml: true,
        version: 'v11.0'
      });
      // auto authenticate with the api if already logged in with facebook
      window.FB?.getLoginStatus(({ authResponse }) => {
        if (authResponse) {
          facebookAuthConfig(authResponse as IFacebookConfig);
          FacebookService.apiAuthenticate(authResponse.accessToken || '').then(resolve);
          resolve(authResponse);
        } else {
          resolve('');
        }
      });
    };

    window.fbAsyncInit();

    // load facebook sdk script
    (function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      const js = d.createElement(s) as HTMLImageElement;
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs?.parentNode?.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  });
};
