import { gql } from '@apollo/client';

export const DELETE_COUPON = gql`
  mutation DeleteCoupon($input: Int!) @api(name: "productsAPI") {
    deleteCoupon(input: $input) {
      id
      success
      message
    }
  }
`;
