import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import { FlexBox } from 'src/components/atoms';
import { CustomModal, AddingProductsModal } from 'src/components/oraganisms';
import CreateOrderPresentational from './CreateOrder.presentational';

// Icons
import { BackRoundedIcon } from 'src/assets/icons';

// Styles
import './CreateOrder.scss';

const CreateOrder: FC = () => {
  const history = useHistory();
  const [showAddingProductsModal, setShowAddingProductsModal] = useState(false);

  const backbtnHandler = () => {
    history.push('/orders/allOrders');
  };
  const handleShowAddingProductModal = () => {
    setShowAddingProductsModal(true);
  };
  const handleCloseAddingProductsModal = () => {
    document.body.style.overflow = 'unset';
    setShowAddingProductsModal(false);
  };

  const handleAddProductsToOrder = (productIds) => {
    console.log('productIds::', productIds);
    handleCloseAddingProductsModal();
  };

  return (
    <>
      <div className="sticky-assist orderDetails">
        <div className="width-animation w-100   hide-scrollbar px-8">
          <FlexBox className="align-items-center">
            <img src={BackRoundedIcon} alt={BackRoundedIcon} className="cursor-pointer" onClick={backbtnHandler} />
            <h1 className="poppins-semibold m-auto-0 text-nowrap dark-color px-2">{'Create Order'}</h1>
          </FlexBox>
          <CreateOrderPresentational handleAddProduct={handleShowAddingProductModal} />
        </div>
      </div>
      {showAddingProductsModal && (
        <CustomModal show={showAddingProductsModal} closeModal={handleCloseAddingProductsModal}>
          <AddingProductsModal
            handleAddProducts={handleAddProductsToOrder}
            shouldShowVariants={false}
            closeModal={handleCloseAddingProductsModal}
            isShowOnlyActiveProducts={false}
          />
        </CustomModal>
      )}
    </>
  );
};

export default CreateOrder;
