import { gql } from '@apollo/client';

export const CHECK_BACKSTORE_USER = gql`
  query checkBackstoreUser($input: checkBackstoreUserInput!) @api(name: "authAPI") {
    checkBackstoreUser(input: $input) {
      email
      fullName
      profileUrl
      userId
      storeId
      storeName
      medium
      isUserExist
      roleDisplayName
      role
      permissions {
        section
        permissions
      }
    }
  }
`;
