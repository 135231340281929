import { useEffect, useState, FC } from 'react';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_ALL_CUSTOMERS } from 'src/apollo/queries';

// Components
import CustomerPagePresentational from './Customer.presentational';

// Hooks && Utils && Helpers
import useTitle from 'src/utils/hooks/useTitle';

// Types
import { CustomerListProps } from './Customer.type';

const CustomerPage: FC = () => {
  useTitle('Customers');
  const [customerList, setCustomerList] = useState<CustomerListProps[]>([]);
  const [customerPageInfo, setCustomerPageInfo] = useState({
    skipCount: 0,
    limitCount: 50
  }); // STATE FOR LIMIT
  const [sortBy, setSortBy] = useState({
    catagory: 'Default',
    id: 1,
    type: 'ASC'
  }); // State For sorting
  const [searchString, setSearchstring] = useState('');

  const [selectedCustomerIDs, setSelectedCustomerIDs] = useState<Array<number>>([]);
  const [totalCount, setTotalCount] = useState(0);

  const input: {
    pageInfo: {
      skipCount: number;
      limitCount: number;
    };
    sortBy?: string;
    sortOrder?: string;
    searchString: string;
  } = {
    pageInfo: {
      skipCount: customerPageInfo.skipCount,
      limitCount: customerPageInfo.limitCount
    },
    sortBy: sortBy.catagory,
    sortOrder: sortBy.type,
    searchString
  };

  sortBy.catagory === 'Default' && delete input.sortBy && delete input.sortOrder;

  const {
    data: customerListData,
    loading: isLoading,
    refetch
  } = useQuery(GET_ALL_CUSTOMERS, {
    variables: {
      input
    },
    onError: (err) => {
      console.error('Get all customer ', err);
    }
  });

  const onRefetchList = () => {
    refetch();
  };

  useEffect(() => {
    if (customerListData?.getAllCustomers?.customers) {
      setCustomerList(customerListData?.getAllCustomers?.customers);
      setTotalCount(customerListData?.getAllCustomers?.total);
    }
  }, [customerListData]);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <CustomerPagePresentational
      selectedCustomerIDs={selectedCustomerIDs}
      setSelectedCustomerIDs={setSelectedCustomerIDs}
      customerPageInfo={customerPageInfo}
      setCustomerPageInfo={setCustomerPageInfo}
      customerList={customerList}
      sortBy={sortBy}
      setSortBy={setSortBy}
      searchString={searchString}
      setSearchstring={setSearchstring}
      onRefetchList={onRefetchList}
      isLoading={isLoading}
      totalCount={totalCount}
    />
  );
};

export default CustomerPage;
