import { FC, useEffect } from 'react';
import { useHistory } from 'react-router';

// Apollo
import { useMutation } from '@apollo/client';
import { RESEND_VERIFY_USER_EMAIL } from 'src/apollo/mutations';
import { userLoginData } from 'src/apollo/reactiveVariables';

// Components
import EmailVerificationPresentation from './EmailVerification.presentation';

// Hooks && Utils && Helpers
import { getUserData } from 'src/helpers/authHelper';

const EmailVerification: FC = () => {
  const [resendEmailVerification] = useMutation(RESEND_VERIFY_USER_EMAIL);
  const { userData } = getUserData();

  const history = useHistory();

  const callResendEmailVerification = () => {
    resendEmailVerification({
      variables: {
        email: userLoginData().email || userData?.email
      }
    });
  };

  useEffect(() => {
    if (userData?.email_verified) {
      history.push('/signup/storecreation');
    }
  }, [userData]);

  return <EmailVerificationPresentation sendEmailAgain={callResendEmailVerification} />;
};

export default EmailVerification;
