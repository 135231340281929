import { FC, createRef, useEffect, useState } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import axios from 'axios';

// Apollo
import { useMutation } from '@apollo/client';
import { GET_MEDIA_UPLOAD_URL } from 'src/apollo/mutations';

// Components
import { Loader } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { CloseIcon } from 'src/assets/icons';

// Styles
import 'cropperjs/dist/cropper.css';

interface ImageCropModalProps {
  image: any;
  updateImage: (image: any) => void;
  discardCropImage: () => void;
  closeModal: () => void;
  setGeneralError?: React.Dispatch<React.SetStateAction<string>>;
}

const ImageCropModal: FC<ImageCropModalProps> = ({ image, closeModal, updateImage, discardCropImage, setGeneralError }) => {
  const cropperRef = createRef<ReactCropperElement>();
  const [accessLoading, setAccessLoading] = useState(false);

  const [getMediaUploadUrl] = useMutation(GET_MEDIA_UPLOAD_URL, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      console.log('data', data);
    },
    onError: (error) => {
      console.log('error', error);
    }
  });

  const uploadImage = async (item, file) => {
    const { uploadURL } = item;
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': file.type
      },
      body: file
    };
    try {
      await fetch(uploadURL, requestOptions);
    } catch (err) {
      console.log('error', err);
    }
  };

  const handleSaveCroppedImage = async () => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      const tempLocalImage = cropperRef.current?.cropper.getCroppedCanvas({ minWidth: 1024, minHeight: 1024 }).toDataURL();
      updateImage({
        url: '',
        fileImage: tempLocalImage
      });
      closeModal();

      // setAccessLoading(true);
      const res = await getMediaUploadUrl({
        variables: {
          input: {
            urlCount: 1,
            fileType: 'IMAGE',
            uploadType: 'APP_LOGO'
          }
        }
      });
      const data = res.data;

      if (data.getMediaUploadUrl) {
        try {
          cropperRef.current?.cropper.getCroppedCanvas({ minWidth: 1024, minHeight: 1024 }).toBlob(async (blob) => {
            if (blob) {
              const fileSize = blob?.size / 1000000;
              if (fileSize > 5) {
                setGeneralError && setGeneralError('File size should be less than 5Mb.');
                return;
              } else {
                setGeneralError && setGeneralError('');
              }
              await uploadImage(data.getMediaUploadUrl[0], blob);
            }
          });
        } catch (error) {
          // setAccessLoading(false);
          console.log('upload err', error);
        } finally {
          // setAccessLoading(false);
        }
        updateImage({
          url: data.getMediaUploadUrl[0].accessURL,
          fileImage: tempLocalImage
        });
      }
    }
  };

  return (
    <div className="modal-content image-crop-modal">
      <div className="modal-header">
        <h5 className="modal-title">Crop your logo</h5>
        <div className="btn btn-xs btn-active-light-primary p-0 m-0 border-none" onClick={closeModal}>
          <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
        </div>
      </div>
      <div className="modal-body p-32">
        <Cropper
          checkCrossOrigin={false}
          ref={cropperRef}
          style={{ width: 296, height: 296 }}
          src={image}
          minCropBoxHeight={100}
          minCropBoxWidth={100}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          guides={true}
          // zoomTo={0.5}
          // initialAspectRatio={1}
          // aspectRatio={1}
          // viewMode={1}
        />
      </div>
      <div className="modal-footer">
        <button className="btn btn-outlined-secondary btn-md" onClick={discardCropImage} type="button">
          Discard
        </button>
        <button className="btn btn-primary btn-md" disabled={accessLoading} onClick={handleSaveCroppedImage} type="button">
          {accessLoading ? 'Saving' : 'Save'}
          {accessLoading && <Loader type="button" className="h-10px w-10px" />}
        </button>
      </div>
    </div>
  );
};

export default ImageCropModal;
