import { FC, useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { DatesDropdownSelector } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { DownArrowIcon } from 'src/assets/icons';

// Types
import { CustomeDatePickerProps } from './CustomeDatePicker.types';

// Styles
import './_customDatePicker.scss';

const CustomeDatePicker: FC<CustomeDatePickerProps> = ({
  title,
  titleClass,
  otherClasses,
  isMandatory,
  isError,
  defaultDate,
  updateDateValue
}) => {
  const [dateValue, setDateValue] = useState<Date>(defaultDate ? defaultDate : new Date());
  const [openPicker, setOpenPicker] = useState<boolean>(false);
  useEffect(() => {
    if (defaultDate) {
      setDateValue(defaultDate);
    }
  }, [defaultDate]);

  return (
    <div className={`custom-date-picker ${otherClasses}`}>
      <div className="d-flex">
        {title ? (
          <div className={`poppins-semibold fs-6 m-b-12 ${titleClass}`}>
            {title}
            {isMandatory ? <span className="text-danger fs-4 px-1">*</span> : null}
          </div>
        ) : null}
      </div>
      <div className="picker">
        <div className={`d-flex form-control px-3 align-item-center w-100 h-100 ${isError ? 'form-control-error' : ''}`}>
          <input
            autoComplete="off"
            className="w-100 h-100 border-0"
            type="text"
            readOnly
            value={`${dateValue.getMonth() + 1 > 9 ? '' : '0'}${dateValue.getMonth() + 1}-${
              dateValue.getDate() + 1 > 9 ? '' : '0'
            }${dateValue.getDate()}-${dateValue.getFullYear()}`}
            onClick={() => setOpenPicker(!openPicker)}
            maxLength={5}
          />
          <KTSVG
            path={DownArrowIcon}
            className="svg-icon-2 svg-icon-gray-500 cursor-pointer"
            onClick={() => setOpenPicker(!openPicker)}
          />
        </div>
        <OutsideClickHandler
          onOutsideClick={() => {
            if (openPicker) setOpenPicker(false);
          }}
        >
          <DatesDropdownSelector
            onselect={(data) => null}
            isOpen={openPicker}
            noRangePicker={true}
            onselectDate={(date) => {
              setDateValue(date);
              setOpenPicker(false);
              updateDateValue && updateDateValue(date);
            }}
            defaultDate={defaultDate}
            closeDropdown={() => setOpenPicker(false)}
          />
        </OutsideClickHandler>
      </div>
    </div>
  );
};

export default CustomeDatePicker;
