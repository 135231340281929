import { gql } from '@apollo/client';

export const UPDATE_PRODUCT_TYPE = gql`
  mutation updateProductType($input: AlterProductTypeInput!) @api(name: "productsAPI") {
    editProductType(input: $input) {
      success
      message
    }
  }
`;
