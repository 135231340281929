import { FC, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import moment from 'moment';

// Components
import { Img } from 'src/components/atoms';
import { Dropdown, Divider } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import { DefaultImage, ThreeDotsIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { Notes } from 'src/components/pages/products/AddProduct/AddProduct.types';
import { NotesBlockProps } from './NotesBlock.types';

// Styles
import './noteBlock.scss';

const NotesBlock: FC<NotesBlockProps> = ({
  Note,
  id,
  handleDeleteNote,
  handleEditNote,
  NotesDataLength,
  getUsername,
  getUserImage
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };
  const closeMenu = () => {
    setOpenMenu(false);
  };
  const selectingMenuItem = (data, id, noteId) => {
    setOpenMenu(false);

    if (data.name.toUpperCase() === 'EDIT') {
      handleEditNote(id, noteId);
    } else if (data.name.toUpperCase() === 'DELETE') {
      handleDeleteNote(id, noteId);
    }
  };
  const getDate = (Note: Notes) => {
    if (Note.createdAt) {
      return Note.createdAt;
    } else if (Note.updatedAt) {
      return Note.updatedAt;
    }
    return undefined;
  };
  return (
    <div key={id} className="py-2">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center note-header">
          <Img
            src={setImageSrc(getUserImage(Note) ? generateCompressedImageURL(getUserImage(Note), '1024') : DefaultImage)}
            placeholderImg={DefaultImage}
            errorImg={DefaultImage}
            className="profileUserCls h-50px w-50px"
          />
          {/* TODO remove temp name after Global context */}
          <div className="note-header-text">
            <div className="poppins-medium username">{getUsername(Note)}</div>
            <div className="helper-text note-date">
              {getDate(Note) ? `${moment(getDate(Note)).format('MMM DD YYYY')} at ${moment(getDate(Note)).format('h:mm a')}` : null}
            </div>
          </div>
        </div>
        <OutsideClickHandler onOutsideClick={closeMenu}>
          <div className="position-relative">
            <img src={ThreeDotsIcon} className="dropdown-toggle cursor-pointer" id="dropdownMenuButton" onClick={toggleMenu} />
            <Dropdown
              data={constVariables.notesOptionsDropdownData}
              onSelect={(data) => {
                selectingMenuItem(data, id, Note.id);
              }}
              shiftLeft
              shiftLeftValue="-130px"
              selected={openMenu}
              closeDropdown={() => setOpenMenu(false)}
            />
          </div>
        </OutsideClickHandler>
      </div>
      <p className="text-muted fs-6 mt-4">{Note.note}</p>

      {id < NotesDataLength - 1 ? <Divider /> : null}
    </div>
  );
};

export default NotesBlock;
