/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState, useMemo, useCallback, Fragment } from 'react';
import clsx from 'clsx';

// Components
import { Divider, TimePicker } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import useWindowDimensions from 'src/utils/UseWindowDimensions/useWindowDimensions';

// Icons
import { TinyLeftArrow, TinyRightArrow } from 'src/assets/icons';

// Types
import { DatesDropdownProps } from './DatesDropdownSelector.types';

// Styles
import './_datesDropdownSelector.scss';

const DatesDropdownSelector: FC<DatesDropdownProps> = ({
  onselect,
  isOpen,
  onselectDate,
  noRangePicker,
  extraClass,
  defaultDate,
  type,
  selectedTime,
  selectedDate,
  timeDropDownDataAm,
  timeDropDownDataPm,
  updateSelectedTime,
  saveScheduleProduct,
  closeDropdown,
  isCentered
}) => {
  const { width } = useWindowDimensions();
  const shiftLeft = {
    width: '300px',
    top: '45px',
    zIndex: 150
  };
  const renderPicker = {
    left: 'calc(50% - 150px)',
    width: 300,
    top: '45px',
    zIndex: 150
  };
  const [weeks] = useState(['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']);
  const [months] = useState([
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]);

  const [lastMonthArr, setLastMonthArr] = useState<any>([]);
  const [thisMonthArr, setThisMonthArr] = useState<any>([]);
  const [nextMonthArr, setNextMonthArr] = useState<any>([]);
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [selectedYear, setSelectedYear] = useState(0);
  const [selectedFirstDate, setSelectedFirstDate] = useState();
  const [firstDateString, setFirstDateString] = useState<Date>();
  const [secondDateString, setSecondDateString] = useState<Date>();
  const [selectedSecondDate, setSelectedSecondDate] = useState();
  const [singleDate, setSingleDate] = useState<Date>();

  const configure = (date) => {
    setSelectedMonth(date.getMonth());
    setSelectedYear(date.getFullYear());

    const numberOfDaysInPrevMonth = new Date(date.getFullYear(), date.getMonth(), 0).getDate();
    const numberOfDaysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    const lastDay = new Date(date.getFullYear(), date.getMonth(), numberOfDaysInMonth).getDay();

    const lastMonthDates = [...Array(firstDay)].map((_, i) => {
      return { date: numberOfDaysInPrevMonth - firstDay + 1 + i, disabled: new Date(date) < new Date() ? true : false };
    });
    const thisMonthDates = [...Array(numberOfDaysInMonth)].map((_, i) => i + 1);
    const nextMonthDates = [...Array(6 - lastDay)].map((_, i) => {
      return { date: i + 1, disabled: new Date(date) < new Date() ? true : false };
    });
    setLastMonthArr(lastMonthDates);
    setThisMonthArr(thisMonthDates);
    setNextMonthArr(nextMonthDates);
  };

  const incMonth = () => {
    if (selectedMonth < 11) {
      const temp = new Date(selectedYear, selectedMonth + 1);
      configure(temp);
    } else {
      const temp = new Date(selectedYear + 1, 0);
      configure(temp);
    }
  };
  const decMonth = () => {
    if (selectedMonth > 0) {
      const temp = new Date(selectedYear, selectedMonth - 1);
      configure(temp);
    } else {
      const temp = new Date(selectedYear - 1, 11);
      configure(temp);
    }
  };
  useEffect(() => {
    if (isOpen) {
      const date = defaultDate ? new Date(defaultDate) : new Date();
      configure(date);
      setSingleDate(date);
    } else {
      setSelectedSecondDate(undefined);
      setSecondDateString(undefined);
      setFirstDateString(undefined);
      setSelectedFirstDate(undefined);
      setSelectedYear(0);
      setSelectedMonth(0);
    }
  }, [isOpen]);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      closeDropdown();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction);

    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction]);

  const isOldDated = useMemo(() => {
    if (selectedYear !== 0) {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();
      if (selectedYear < currentYear || (selectedYear === currentYear && selectedMonth < currentMonth)) {
        return true;
      }
    }
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    if (firstDateString && secondDateString) {
      const fromDay = new Date(firstDateString.getFullYear(), firstDateString.getMonth(), firstDateString.getDate()).toDateString();
      const toDay = new Date(
        secondDateString.getFullYear(),
        secondDateString.getMonth(),
        secondDateString.getDate()
      ).toDateString();
      onselect({ from: new Date(`${fromDay} 00:00 UTC`).toISOString(), to: new Date(`${toDay} 11:59 UTC`).toISOString() });
    }
  }, [firstDateString, secondDateString]);

  // useEffect(() => {
  //   if (singleDate) {
  //     onselectDate && onselectDate(singleDate);
  //   }
  // }, [singleDate]);

  useEffect(() => {
    if (noRangePicker) {
      setSingleDate(defaultDate ? new Date(defaultDate) : new Date());
    }
  }, [noRangePicker]);

  const selectDate = (date, month?: string) => {
    if (!month && (isOldDated || isOldDate(date)) && noRangePicker) return;
    if (noRangePicker) {
      if (selectedYear > new Date().getFullYear() || selectedMonth >= new Date().getMonth() || date > new Date().getDate()) {
        let selectedDate;
        if (month === 'prevMonth') {
          selectedDate = new Date(selectedYear, selectedMonth - 1, date);
        } else if (month === 'nextMonth') {
          selectedDate = new Date(selectedYear, selectedMonth + 1, date);
        } else {
          selectedDate = new Date(selectedYear, selectedMonth, date);
        }
        setSingleDate(selectedDate);
        onselectDate && onselectDate(selectedDate);
      }
    } else {
      if (selectedFirstDate && firstDateString) {
        if (selectedSecondDate) {
          if (selectedMonth < firstDateString?.getMonth()) {
            setSelectedFirstDate(date);
            setFirstDateString(new Date(selectedYear, selectedMonth, date));
          } else if (selectedMonth > firstDateString?.getMonth()) {
            setSelectedSecondDate(date);
            setSecondDateString(new Date(selectedYear, selectedMonth, date));
          } else if (selectedMonth === firstDateString?.getMonth()) {
            if (date < selectedFirstDate) {
              setSelectedFirstDate(date);
              setFirstDateString(new Date(selectedYear, selectedMonth, date));
            } else if (date > selectedFirstDate) {
              setSelectedSecondDate(date);
              setSecondDateString(new Date(selectedYear, selectedMonth, date));
            }
          }
        } else {
          setSelectedSecondDate(date);
          setSecondDateString(new Date(selectedYear, selectedMonth, date));
        }
      } else {
        setSelectedFirstDate(date);
        setFirstDateString(new Date(selectedYear, selectedMonth, date));
      }
    }
  };
  const isOldDate = (date: number): boolean => {
    if (selectedMonth === new Date().getMonth()) {
      return date < new Date().getDate();
    } else {
      return false;
    }
  };

  const renderColoredBlock = (date: any) => {
    return (
      <div onClick={() => selectDate(date)} className="my-1 squareCalBlock cursor-pointer">
        <div className="squareCalBlockChild  datesBetween ">{date}</div>
      </div>
    );
  };
  const renderNormalBlock = (date: any) => {
    return (
      <div
        className={`my-1 squareCalBlock ${noRangePicker && (isOldDate(date) || isOldDated) ? '' : 'cursor-pointer'}`}
        onClick={() => selectDate(date)}
      >
        <div
          className={`squareCalBlockChild ${
            noRangePicker && (isOldDate(date) || isOldDated) ? 'opacity-50 disabled-date' : 'future-dates'
          }`}
        >
          {date}
        </div>
      </div>
    );
  };
  const RenderCanendarItem = (date: number) => {
    if (singleDate && date === singleDate?.getDate() && singleDate?.getMonth() === selectedMonth) {
      return (
        <div className="my-1 squareCalBlock cursor-pointer" onClick={() => selectDate(date)}>
          <div className="squareCalBlockChild  bg-primary singleDateSelected">{date}</div>
        </div>
      );
    }
    if (date === selectedFirstDate && firstDateString?.getMonth() === selectedMonth) {
      return (
        <div className="my-1 squareCalBlock ">
          <div className="squareCalBlockChild  bg-primary firstSelectedDate">{date}</div>
        </div>
      );
    } else if (date === selectedSecondDate && secondDateString?.getMonth() === selectedMonth) {
      return (
        <div className="my-1 squareCalBlock">
          <div className="squareCalBlockChild  bg-primary lastSelectedDate">{date}</div>
        </div>
      );
    } else if (
      selectedFirstDate &&
      selectedSecondDate &&
      secondDateString &&
      firstDateString &&
      date > selectedFirstDate &&
      date < selectedSecondDate &&
      secondDateString?.getMonth() === selectedMonth &&
      firstDateString?.getMonth() === selectedMonth
    ) {
      return renderColoredBlock(date);
    } else if (secondDateString && firstDateString && secondDateString?.getMonth() > firstDateString?.getMonth()) {
      if (selectedMonth === firstDateString?.getMonth() && selectedFirstDate && date > selectedFirstDate) {
        return renderColoredBlock(date);
      } else if (selectedMonth > firstDateString?.getMonth() && selectedMonth < secondDateString?.getMonth()) {
        return renderColoredBlock(date);
      } else if (selectedMonth === secondDateString?.getMonth() && selectedSecondDate && date < selectedSecondDate) {
        return renderColoredBlock(date);
      } else {
        return renderNormalBlock(date);
      }
    } else {
      return renderNormalBlock(date);
    }
  };

  return (
    <div
      className={clsx(
        'p-2 mt-1 menu menu-sub menu-background menu-sub-dropdown menu-column menu-rounded menu-gray-600 fw-bold position-absolute',
        { show: isOpen },
        { 'scheduled-availability-datepicker': type === 'schedule' },
        { 'mh-400px': type !== 'schedule' },
        { 'centered-date-dropdown': isCentered },
        `${width > 1800 ? 'left-0' : ''}`,
        extraClass
      )}
      style={!isCentered ? renderPicker : shiftLeft}
      data-kt-menu="true"
    >
      {type === 'schedule' && (
        <div className="p-2 pb-4">
          <TimePicker
            value={selectedTime}
            dateValue={selectedDate}
            isMandatory={false}
            setValue={updateSelectedTime}
            timeDropDownDataAm={timeDropDownDataAm}
            timeDropDownDataPm={timeDropDownDataPm}
            isShowTimeSlots={true}
            comparedDate={new Date()}
          />
        </div>
      )}
      <div className="d-flex align-items-center justify-content-between">
        <KTSVG svgClassName="cursor-pointer h-10px w-10px me-1" path={TinyLeftArrow} onClick={decMonth} />
        <div className="w-100 month-year-indicator rounded d-flex align-items-center justify-content-center py-2 mx-1">
          <div>{months[selectedMonth]}</div>
        </div>
        <div className="custom-vertical-divider" />
        <div className="w-100 month-year-indicator rounded d-flex align-items-center justify-content-center py-2 mx-1">
          <div>{selectedYear}</div>
        </div>
        <KTSVG svgClassName="cursor-pointer h-10px w-10px ms-1" path={TinyRightArrow} onClick={incMonth} />
      </div>
      <Divider classname="mt-2 m-b-12" />
      <div className="px-2">
        <div className="DatesSelectorWeekContainer m-b-8">
          {weeks.map((x, index) => (
            <div className="squareCalBlock text-primary" key={index}>
              <div className="squareCalBlockChild">{x}</div>
            </div>
          ))}
        </div>
        <div className="DatesSelectorWeekContainer">
          {lastMonthArr.map((x, i) => (
            <div
              className={`squareCalBlock my-1 ${x.disabled ? 'opacity-50 disabled-date' : 'future-dates cursor-pointer'}`}
              key={i}
              onClick={() => {
                if (!x.disabled) selectDate(x.date, 'prevMonth');
              }}
            >
              <div
                className={`squareCalBlockChild ${
                  singleDate &&
                  x.date === singleDate?.getDate() &&
                  (singleDate?.getMonth() === selectedMonth - 1 || singleDate?.getMonth() === selectedMonth + 11)
                    ? 'bg-primary singleDateSelected'
                    : ''
                }`}
              >
                {x.date}
              </div>
            </div>
          ))}
          {thisMonthArr.map((x, i) => (
            <Fragment key={i}>{RenderCanendarItem(x)}</Fragment>
          ))}
          {nextMonthArr.map((x, i) => (
            <div
              className={`squareCalBlock my-1 ${x.disabled ? 'opacity-50 disabled-date' : 'future-dates cursor-pointer'}`}
              key={i}
              onClick={() => {
                if (!x.disabled) selectDate(x.date, 'nextMonth');
              }}
            >
              <div
                className={`squareCalBlockChild ${
                  singleDate &&
                  x.date === singleDate?.getDate() &&
                  (singleDate?.getMonth() === selectedMonth + 1 || singleDate?.getMonth() === selectedMonth - 11)
                    ? 'bg-primary singleDateSelected'
                    : ''
                }`}
              >
                {x.date}
              </div>
            </div>
          ))}
        </div>
      </div>
      {type === 'schedule' && (
        <>
          <Divider classname="mt-2 m-b-12" />
          <div className="d-flex justify-content-end w-100">
            <button className="btn btn-outlined-secondary btn-sm" onClick={closeDropdown}>
              Cancel
            </button>
            <button className="btn btn-primary btn-sm" onClick={saveScheduleProduct}>
              Schedule Product
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default DatesDropdownSelector;
