export const SortIds = [
  {
    id: 21,
    name: 'Product name'
  },
  {
    id: 26,
    name: 'Last updated'
  },
  {
    id: 27,
    name: 'Creation date'
  },
  {
    id: 28,
    name: 'Received date'
  },
  {
    id: 22,
    name: 'Inventory status'
  },
  {
    id: 23,
    name: 'Product type'
  },
  {
    id: 24,
    name: 'Vendor name'
  }
];
