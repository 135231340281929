/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const AUTO_COMPLETE_ADDRESS = gql`
  query onAutoComplete($input: AutoComplete!) @api(name: "productsAPI") {
    autoComplete(input: $input) {
      street_line
      secondary
      city
      state
      zipcode
      entries
    }
  }
`;
