import { gql } from '@apollo/client';

export const PRINT_PACKING_SLIP = gql`
  mutation PrintPackingSlip($orderIds: [Int]!) @api(name: "ordersAPI") {
    printPackingSlip(orderIds: $orderIds) {
      id
      orderCode
      customer
      items
      orderDetails {
        id
        name
        availableQuantity
        location
        sku
        code
        imageUrl
        variantTypes {
          id
          name
          value
        }
      }
      noOfCombChildOrds
    }
  }
`;
