import { gql } from '@apollo/client';

export const ADD_ADDRESS = gql`
  mutation AddAddress($input: AddressInput!) @api(name: "productsAPI") {
    addAddress(input: $input) {
      success
      message
      newAddressId
    }
  }
`;
