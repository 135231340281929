// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createAppSyncAuthorizedWebSocket = (data) => {
    return class extends WebSocket {
        // SubscriptionClient takes a fixed websocket url so we append query string parameters every time the websocket
        // is created, in case the authorization information has changed.
        constructor(url, protocols = undefined) {
            super(url, protocols);
        }

        // AppSync acknowledges GraphQL subscriptions with "start_ack" messages but SubscriptionClient cannot handle them
        set onmessage(handler) {
            super.onmessage = (event) => {
                if (event.data) {
                    const data = this._tryParseJsonString(event.data);

                    if (data && data.type === 'start_ack') {
                        return;
                    }
                }

                return handler(event);
            };
        }

        _tryParseJsonString(jsonString) {
            try {
                return JSON.parse(jsonString);
            } catch (e) {
                return undefined;
            }
        }
    };
};
