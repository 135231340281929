import { FC, useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import clsx from 'clsx';

// Components
import { FlexBox, Loader } from 'src/components/atoms';
import { Dropdown, InputBox } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import getDeviceData from 'src/utils/UseDevice/useDevice';

// Icons
import { CloseIcon, DownArrowIcon, WarehouseBuilding } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { AddWarehousePresentationalProps } from './AddWarehouse.types';

// Styles
import './_addWarehouse.scss';

const AddWarehousePresentational: FC<AddWarehousePresentationalProps> = ({
  loading,
  show,
  resetState,
  warehouseData,
  countryListFiltered,
  cityListFiltered,
  stateListFiltered,
  phoneList,
  addWarehouse,
  nameError,
  phoneError,
  addWarehouseName,
  changeAddressLineOne,
  changePostalCode,
  changeCountry,
  changeCity,
  changeState,
  changePhoneNumber,
  onSelectCountryCode,
  onSelectCity,
  onSelectState,
  onSelectPhone,
  addressListFiltered,
  onSelectAddress,
  setWarehouseData,
  toggleAddressDropDown,
  togglePhoneDropDown
}) => {
  const deviceData = getDeviceData();
  const [firstTime, setFirstTime] = useState(true);
  const [initialName, setInitialName] = useState('');

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
      setInitialName(warehouseData.warehouseName);
    }
  }, [warehouseData]);
  useEffect(() => {
    setFirstTime(true);
  }, [show]);

  const openCountryDropDown = () => {
    setWarehouseData((prev) => ({
      ...prev,
      countryDropDown: true
    }));
  };

  const closeCountryDropDown = () => {
    setWarehouseData((prev) => ({
      ...prev,
      countryDropDown: false
    }));
  };

  const openStateDropDown = () => {
    setWarehouseData((prev) => ({
      ...prev,
      stateDropDown: true
    }));
  };

  const closeStateDropDown = () => {
    setWarehouseData((prev) => ({
      ...prev,
      stateDropDown: false
    }));
  };

  const openCityDropDown = () => {
    setWarehouseData((prev) => ({
      ...prev,
      cityDropDown: true
    }));
  };

  const closeCityDropDown = () => {
    setWarehouseData((prev) => ({
      ...prev,
      cityDropDown: false
    }));
  };

  const cityDropDown = () => {
    if (warehouseData?.cityDropDown) {
      if (cityListFiltered && cityListFiltered.length > 0) {
        return (
          <Dropdown
            className="dropdown-custom-width"
            data={cityListFiltered}
            selected={warehouseData?.cityDropDown}
            value={warehouseData?.selectedCity}
            onSelect={onSelectCity}
            closeDropdown={closeCityDropDown}
          />
        );
      } else {
        return <div className="text-danger fs-9">No Cities Available</div>;
      }
    }
    return null;
  };

  return (
    <>
      {show && (
        <div
          className={clsx('add-vendor modal fade opacity-100', {
            show
          })}
          aria-modal="true"
          tabIndex={-1}
          id="kt_modal_1"
        >
          <div className={`modal-dialog ${deviceData.isWeb ? 'flex-center' : 'w-90 w-md-75'}`}>
            <div className={`modal-content ${deviceData.isWeb ? 'w-900px' : ''}`}>
              <div className="modal-header">
                <h5 className="modal-title">
                  {warehouseData.id
                    ? constVariables.warehouseManagement.editWarehouse
                    : constVariables.warehouseManagement.addNewWarehouse}
                </h5>
                <div className="btn btn-xs btn-active-light-primary p-0 m-0 border-none" onClick={resetState}>
                  <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
                </div>
              </div>
              <div className="modal-body">
                <div className="container px-0 ">
                  {/* WareHouse Name */}
                  <div className="pe-md-8 border-bottom border-bottom-light pb-7">
                    <InputBox
                      inputClass="form-control-lg"
                      mainclass="w-100 w-md-50"
                      title={constVariables.warehouseManagement.warehouseName}
                      value={warehouseData?.warehouseName}
                      onChangeText={addWarehouseName}
                      name={constVariables.warehouseManagement.warehouseName}
                      placeholder={constVariables.warehouseManagement.warehouseName}
                      warningText={
                        nameError && initialName !== warehouseData?.warehouseName
                          ? constVariables.warehouseManagement.warehouseNameExist
                          : ''
                      }
                      inputIcon={WarehouseBuilding}
                      inputIconColor="#A1A5B7"
                    />
                  </div>
                  {/* Appartment suite etc  */}
                  <div className="position-relative mt-5">
                    <div className="text-nowrap poppins-semibold fs-6 mb-2">{constVariables.warehouseManagement.appSuite}</div>
                    <div onClick={toggleAddressDropDown}>
                      <InputBox
                        inputClass="form-control-lg"
                        value={warehouseData?.addressLineOne}
                        onChangeText={changeAddressLineOne}
                        name={constVariables.warehouseManagement.address}
                        placeholder={constVariables.warehouseManagement.addressPlaceholder}
                      />
                    </div>

                    {addressListFiltered && addressListFiltered.length > 0 && (
                      <Dropdown
                        className="dropdown-custom-width"
                        data={addressListFiltered}
                        selected={warehouseData.addressDropDown}
                        value={warehouseData?.addressLineOne}
                        onSelect={onSelectAddress}
                        closeDropdown={() =>
                          setWarehouseData((prev) => ({
                            ...prev,
                            addressDropDown: false
                          }))
                        }
                      />
                    )}
                  </div>
                  {/* Country and zip code */}
                  <div className="d-block d-md-flex mt-5">
                    <div className="me-md-4 w-100 w-md-50">
                      <div className="text-nowrap poppins-semibold fs-6 mb-2">{constVariables.warehouseManagement.zipCode}</div>
                      <InputBox
                        inputClass="form-control-lg"
                        value={warehouseData?.postalCode}
                        onChangeText={changePostalCode}
                        name={constVariables.warehouseManagement.zipCode}
                        placeholder={constVariables.warehouseManagement.zipCodePlaceholder}
                        charLimit={10}
                      />
                    </div>

                    <div className="ms-md-4 w-100 w-md-50">
                      <div className="text-nowrap poppins-semibold fs-6 mb-2">{constVariables.warehouseManagement.country}</div>
                      <OutsideClickHandler onOutsideClick={closeCountryDropDown}>
                        <div className="position-relative">
                          <FlexBox
                            className={`form-control form-control-lg  px-5 justify-content-between align-items-center cursor-pointer dropdown-box `}
                            onClick={openCountryDropDown}
                          >
                            <InputBox
                              value={warehouseData?.selectedCountry}
                              onChangeText={(e) => changeCountry(e)}
                              name={constVariables.warehouseManagement.country}
                              placeholder={constVariables.warehouseManagement.countryPlaceholder}
                              noBorders
                              inputClass="no-padding"
                            />
                            <KTSVG path={DownArrowIcon} />
                          </FlexBox>
                          {countryListFiltered && countryListFiltered.length > 0 && (
                            <Dropdown
                              className="dropdown-custom-width"
                              data={countryListFiltered}
                              selected={warehouseData?.countryDropDown}
                              value={warehouseData?.selectedCountryCode}
                              onSelect={onSelectCountryCode}
                              closeDropdown={() =>
                                setWarehouseData((prev) => ({
                                  ...prev,
                                  countryDropDown: false
                                }))
                              }
                            />
                          )}
                        </div>
                      </OutsideClickHandler>
                    </div>
                  </div>

                  {/* State city */}
                  <div className="d-block d-md-flex mt-5">
                    <div className={`me-md-4 w-100 w-md-50 ${warehouseData.selectedCountry.length === 0 ? 'disabled' : ''}`}>
                      <div className="text-nowrap poppins-semibold fs-6 mb-2">{constVariables.warehouseManagement.state}</div>
                      <OutsideClickHandler onOutsideClick={closeStateDropDown}>
                        <div className="position-relative">
                          <FlexBox
                            className={`form-control form-control-lg  px-5 justify-content-between align-items-center cursor-pointer dropdown-box `}
                            onClick={openStateDropDown}
                          >
                            <InputBox
                              value={warehouseData?.selectedState}
                              onChangeText={changeState}
                              name={constVariables.warehouseManagement.state}
                              placeholder={constVariables.warehouseManagement.statePlaceholder}
                              noBorders
                              inputClass="no-padding"
                            />
                            <KTSVG path={DownArrowIcon} />
                          </FlexBox>

                          {stateListFiltered && stateListFiltered.length > 0 && (
                            <Dropdown
                              className="dropdown-custom-width"
                              data={stateListFiltered}
                              selected={warehouseData?.stateDropDown}
                              value={warehouseData?.selectedState}
                              onSelect={onSelectState}
                              closeDropdown={() =>
                                setWarehouseData((prev) => ({
                                  ...prev,
                                  stateDropDown: false
                                }))
                              }
                            />
                          )}
                        </div>
                      </OutsideClickHandler>
                    </div>
                    <div className={`ms-md-4 w-100 w-md-50 ${warehouseData.selectedState.length === 0 ? 'disabled' : ''}`}>
                      <div className="text-nowrap poppins-semibold fs-6 mb-2">{constVariables.warehouseManagement.city}</div>
                      <OutsideClickHandler onOutsideClick={closeCityDropDown}>
                        <div className="position-relative">
                          <FlexBox
                            className={`form-control form-control-lg  px-5 justify-content-between align-items-center cursor-pointer dropdown-box `}
                            onClick={openCityDropDown}
                          >
                            <InputBox
                              value={warehouseData?.selectedCity}
                              onChangeText={changeCity}
                              name={constVariables.warehouseManagement.city}
                              placeholder={constVariables.warehouseManagement.cityPlaceholder}
                              noBorders
                              inputClass="no-padding"
                            />
                            <KTSVG path={DownArrowIcon} />
                          </FlexBox>

                          {cityDropDown()}
                        </div>
                      </OutsideClickHandler>
                    </div>
                  </div>
                  {/* Phone number */}
                  <div className=" pe-md-4 w-100 w-md-50 mt-5 position-relative">
                    <div className="d-flex">
                      <div className="text-nowrap poppins-semibold fs-6 mb-2">{constVariables.warehouseManagement.phone}</div>
                    </div>
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        setWarehouseData((prev) => ({
                          ...prev,
                          phoneDropDown: false
                        }));
                      }}
                    >
                      <div className="d-flex">
                        <div>
                          <FlexBox
                            className={`right-border-flat form-control form-control-lg  justify-content-between align-items-center cursor-pointer dropdown-box 
                                                        `}
                            onClick={togglePhoneDropDown}
                          >
                            <div className="helper-text-one">
                              {warehouseData?.selectedPhone ? warehouseData?.selectedPhone : 'Select'}
                            </div>
                            <div>
                              <KTSVG path={DownArrowIcon} />
                            </div>
                          </FlexBox>
                        </div>
                        <InputBox
                          mainclass="col"
                          inputClass="form-control-lg left-border-flat"
                          value={warehouseData.phoneNumber}
                          onChangeText={changePhoneNumber}
                          name={constVariables.warehouseManagement.phone}
                          placeholder={constVariables.warehouseManagement.phonePlaceHolder}
                          charLimit={15}
                          warningText={phoneError ? constVariables.warehouseManagement.invalidPhoneNumber : ''}
                        />
                      </div>
                      {phoneList && phoneList.length > 0 && (
                        <Dropdown
                          className="dropdown-custom-width"
                          data={phoneList}
                          selected={warehouseData?.phoneDropDown}
                          value={warehouseData?.selectedPhone}
                          onSelect={onSelectPhone}
                          closeDropdown={() =>
                            setWarehouseData((prev) => ({
                              ...prev,
                              phoneDropDown: false
                            }))
                          }
                        />
                      )}
                    </OutsideClickHandler>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outlined-secondary btn-md"
                  onClick={() => {
                    resetState();
                  }}
                >
                  {constVariables.warehouseManagement.cancel}
                </button>
                <button
                  disabled={
                    !warehouseData.warehouseName ||
                    !warehouseData.addressLineOne ||
                    !warehouseData.postalCode ||
                    !warehouseData.selectedCountry ||
                    !warehouseData.selectedState ||
                    !warehouseData.selectedCity ||
                    !warehouseData.phoneNumber ||
                    !warehouseData.selectedPhone ||
                    loading
                  }
                  type="button"
                  className="btn btn-primary btn-md"
                  onClick={addWarehouse}
                >
                  {warehouseData.id
                    ? constVariables.warehouseManagement.saveChanges
                    : constVariables.warehouseManagement.addWarehouse}
                  {loading && <Loader type="button" className="ms-2 mt-1 h-10px w-10px" />}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddWarehousePresentational;
