/* eslint-disable array-callback-return */
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import ProgressBar from '@ramonak/react-progress-bar';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { GET_COMBINED_ORDER_DETAIL, GET_SHOPIFY_ADMIN_BASE_URL } from 'src/apollo/queries';
import { MARK_ORDER_SCANNED } from 'src/apollo/mutations';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import {
  Divider,
  ProductBoxFulfillmentCenter,
  Tooltip,
  ExpeditedShippingTooltip,
  ShopifySyncTooltip
} from 'src/components/molecules';
import CombinedChildOrdersDropdown from './CombinedChildOrdersDropdown';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { ScanBarcode } from 'src/utils/ScanBarcode/ScanBarcode';
import { setImageSrc } from 'src/utils/setImageSrc';

// Icons
import { DefaultImage, InfoIcon, LeftArrowIcon, ScanItem, Truck, PreviewMagnifyingGlassIcon, ShopifyIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { OrderDetailsBlockFulfillmentCenterProps } from './OrderDetailsBlockFulfillmentCenter.types';
import { ChildOrderItem } from 'src/components/oraganisms/OrdersList/OrdersList.types';

const OrderDetailsBlockFulfillmentCenter: FC<OrderDetailsBlockFulfillmentCenterProps> = ({
  orderDetails,
  scannedCount,
  weightStr,
  refetchOrderDetails,
  orderShipment,
  setErrorModal,
  setErrorModalObj,
  updateOrderDetails,
  setSelectedOrder
}) => {
  const [childOrders, setChildOrders] = useState<ChildOrderItem[]>([]);
  const [showChildOrdersDropdown, setShowChildOrdersDropdown] = useState<boolean>(false);

  const { data: { shopifyAdminBaseUrl } = { shopifyAdminBaseUrl: null } } = useQuery(GET_SHOPIFY_ADMIN_BASE_URL);

  const [getCombinedOrderDetail, { loading: isChildOrdersLoading }] = useLazyQuery(GET_COMBINED_ORDER_DETAIL, {
    onCompleted: (res) => {
      setChildOrders(res?.getCombineOrderDetail?.childOrders);
    },
    onError: (err) => console.log(err)
  });

  const [markScanned] = useMutation(MARK_ORDER_SCANNED, {
    onCompleted: () => {
      if (refetchOrderDetails) {
        refetchOrderDetails();
      }
    },
    onError: (err) => {
      console.error('Mark order scanned error ', err);
    }
  });

  const handleScannedValue = (scannedValue: string) => {
    if (orderDetails?.orderDetails) {
      if (scannedValue && scannedValue !== '') {
        let alreadyFound = false;
        orderDetails?.orderDetails.map((order) => {
          if (JSON.parse(order.items) && !alreadyFound) {
            const foundItem = JSON.parse(order.items).find((item) => item.id.toString() === scannedValue);
            if (foundItem) {
              alreadyFound = true;
            }
            if (foundItem && foundItem.isScanned) {
              setErrorModal(true);
              setErrorModalObj({
                action: 'Try again',
                title: 'Item already scanned! ',
                message: 'This item was already scanned, please try another item.',
                otherIcon: ScanItem
              });
            } else if (foundItem && !foundItem.isScanned) {
              markScanned({
                variables: {
                  input: {
                    orderDetailId: order.id,
                    itemId: foundItem.id,
                    isScanned: true
                  }
                }
              });
            } else {
              setErrorModal(true);
              setErrorModalObj({
                action: 'Try again',
                title: 'Wrong item scanned!',
                message: 'Scanned item is not in the order’s list of items, please try another item.'
              });
            }
          }
          return order;
        });
      }
    }
  };

  const handleGetCombinedChildOrders = () => {
    setShowChildOrdersDropdown(true);
    getCombinedOrderDetail({
      variables: {
        orderId: orderDetails?.id
      }
    });
  };

  const closeChildOrdersDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowChildOrdersDropdown(false);
  };

  const goToShopifyPage = (shopifyOrderId) => {
    if (shopifyAdminBaseUrl) window.open(`${shopifyAdminBaseUrl}orders/${shopifyOrderId}`, '_blank');
  };

  return (
    <div className="d-flex flex-column  flex-grow-1">
      <div className="p-16">
        <div className="d-flex justify-content-between">
          <div className="w-60">
            <div className="d-flex align-items-center">
              <KTSVG
                className="me-2 cursor-pointer"
                svgColor="black"
                path={LeftArrowIcon}
                onClick={() => {
                  setSelectedOrder(-1);
                }}
              />
              <FlexBox className="align-items-center">
                <div className="poppins-regular fulfill-title me-2">
                  Order
                  <Link to={`/orders/orderDetails/${orderDetails?.id}`} target="_blank" className="order-detail-link">
                    <span className="text-primary"> #{orderDetails?.orderCode}</span>
                  </Link>
                </div>
                {orderDetails?.shopifyId && (
                  <>
                    <img
                      src={ShopifyIcon}
                      className="cursor-pointer mx-4"
                      data-tip
                      data-for="shopifySyncIcon"
                      onClick={() => goToShopifyPage(orderDetails?.shopifyId)}
                    />
                    <ShopifySyncTooltip tooltipText="Order from Shopify" />
                  </>
                )}
              </FlexBox>
              {orderDetails?.isCombOrdrParent ? (
                <div className="d-flex align-items-center me-3">
                  <div className="combined-order-indicator me-3">Combined</div>
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      if (showChildOrdersDropdown) setShowChildOrdersDropdown(false);
                    }}
                  >
                    <div onClick={handleGetCombinedChildOrders} className="position-relative cursor-pointer">
                      <KTSVG path={PreviewMagnifyingGlassIcon} />

                      <CombinedChildOrdersDropdown
                        isOpen={showChildOrdersDropdown}
                        loading={isChildOrdersLoading}
                        closeDropdown={closeChildOrdersDropdown}
                        childOrders={childOrders}
                      />
                    </div>
                  </OutsideClickHandler>
                </div>
              ) : null}
              {orderDetails?.isPartiallyShipped ? (
                <div className="text-center bg-danger text-white poppins-light px-2 py-1 partialShippingButton fs-9">
                  Partial Shipping
                </div>
              ) : null}
              {orderShipment?.id ? (
                <div>
                  <span className="badge process rounded-pill badge-fulfillment ms-2">Fulfilled</span>
                </div>
              ) : null}
            </div>
            <div className="d-flex align-items-center order-customer">
              <Img
                src={setImageSrc(orderDetails?.thumbnailUrl ? orderDetails?.thumbnailUrl : '')}
                placeholderImg={DefaultImage}
                errorImg={DefaultImage}
                className="fulfilmentProfilePicture  h-40px w-40px rounded-circle me-2"
              />
              <Link to={`/customers/customerDetails/${orderDetails?.customerId}`} target="_blank">
                <div className="customer-name">{orderDetails?.customer} </div>
              </Link>
              {orderDetails?.isExpeditedShipping && (
                <>
                  <img src={Truck} className="mx-2 h-75 w-75" data-tip data-for="truckIcon" />
                  <ExpeditedShippingTooltip />
                </>
              )}
              <div className="order-info mx-5">
                <span className="order-info-label"> Total: </span>
                <span>${orderDetails?.total}</span>
              </div>
              <div className="order-info">
                <span className="order-info-label">Weight: </span>
                <span>{weightStr}</span>
              </div>
            </div>
          </div>
          <div className="w-40 ps-4 order-shipping-address">
            <div className="order-shipping-address-title">{constVariables.FulfillmentCenter.shippingAddress}</div>
            <div className="order-shipping-address-description">
              {orderDetails?.shippingAddress?.addressLine1}
              {', '}
              {orderDetails?.shippingAddress?.addressLine2
                ? orderDetails?.shippingAddress?.addressLine2
                : orderDetails?.shippingAddress?.city}
              {', '}
              {orderDetails?.shippingAddress?.addressLine2
                ? orderDetails?.shippingAddress?.addressLine2
                : orderDetails?.shippingAddress?.country}
              {', '}
              {orderDetails?.shippingAddress?.pincode}
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="p-16">
        <div>
          <span>{scannedCount}/</span>
          <span className="helper-text">{orderDetails?.availableItemsQuantity} Scanned</span>
        </div>
        <div className="rounded mt-2">
          <ProgressBar
            completed={(scannedCount / (orderDetails?.availableItemsQuantity || 1)) * 100}
            isLabelVisible={false}
            barContainerClassName={'bar-class'}
            bgColor={'#00A3FF'}
            borderRadius={'50px'}
            height={'10px'}
          />
        </div>
      </div>
      <Divider />
      <div className="p-16">
        <div className="d-flex align-items-center order-items">
          <div className="poppins-regular fulfill-title">Items</div>
          <div className="mx-1 fs-7 poppins-medium opacity-75">
            ({orderDetails?.availableItemsQuantity}/{orderDetails?.items} Available Items)
          </div>
          <>
            <img src={InfoIcon} className="px-3 cursor-pointer" data-tip data-for="orderInfoIcon" />
            <Tooltip
              tooltipId="orderInfoIcon"
              place="top"
              content={() => {
                return <>Available items / Total ordered items</>;
              }}
            />
          </>
        </div>
        <div className="order-items-list">
          <div className="row g-3 m-0">
            {orderDetails?.orderDetails?.map((data) =>
              JSON.parse(data.items)?.length > 0 ? (
                JSON.parse(data.items)?.map((item, i) => {
                  return (
                    <ProductBoxFulfillmentCenter
                      key={i}
                      data={data}
                      refetchOrderDetails={refetchOrderDetails}
                      indexValue={i}
                      updateOrderDetails={updateOrderDetails}
                    />
                  );
                })
              ) : (
                <ProductBoxFulfillmentCenter
                  data={data}
                  refetchOrderDetails={refetchOrderDetails}
                  indexValue={0}
                  updateOrderDetails={updateOrderDetails}
                />
              )
            )}
          </div>
        </div>
      </div>
      <ScanBarcode setValue={handleScannedValue} />
    </div>
  );
};

export default OrderDetailsBlockFulfillmentCenter;
