import { FC, useEffect, useState, useMemo } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Link } from 'react-router-dom';

// Apollo
import { useMutation } from '@apollo/client';
import { MARK_ORDER_SCANNED, MARK_PRODUCT_AS_UNAVAILABLE } from 'src/apollo/mutations';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { Img } from 'src/components/atoms';
import { Divider, Dropdown } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { setImageSrc } from 'src/utils/setImageSrc';

// Icons
import { CurveBackIcon, DefaultImage, SelectIcon, ThreeDotsIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { ProductBoxFulfillmentCenterProps } from './ProductBoxFulfillmentCenter.types';
import { UserRoles } from 'src/components/pages/settings/UserPermissions/UserPermissions.type';

// Styles
import './_productBoxFulfillmentCenter.scss';

const ProductBoxFulfillmentCenter: FC<ProductBoxFulfillmentCenterProps> = ({
  data,
  indexValue,
  refetchOrderDetails,
  updateOrderDetails
}) => {
  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  const { imageUrl, productId, variantId, availableQuantity, name, sku, location, variantTypes, id, items } = data;
  const [openDropDown, setOpenDropDown] = useState(false);
  const [useableLocation, setUseableLocation] = useState<{ warehouseId: number; inventoryPosition: string }[]>();

  const isDisabledButton = useMemo(() => {
    if (role === UserRoles.OWNER || role === UserRoles.ADMIN || role === UserRoles.WAREHOUSE_MANAGER) {
      return false;
    }
    return true;
  }, [role]);

  useEffect(() => {
    if (location) {
      setUseableLocation(JSON.parse(location).inventory);
    }
  }, [location]);

  const [markScanned] = useMutation(MARK_ORDER_SCANNED, {
    onError: (err) => {
      console.error('Mark order scanned error ', err);
    }
  });

  const handleChangeMark = (isScanned) => {
    if (JSON.parse(items)?.length > 0 && id) {
      const updatedItems = JSON.parse(items)?.map((item, index) => {
        if (index === indexValue) {
          return { ...item, isScanned };
        } else {
          return item;
        }
      });

      updateOrderDetails(id, JSON.stringify(updatedItems));

      markScanned({
        variables: {
          input: {
            orderDetailId: id,
            itemId: JSON.parse(items)[indexValue]?.id,
            isScanned
          }
        }
      });
    }
  };

  const [callMarkProductAsUnavailableMut] = useMutation(MARK_PRODUCT_AS_UNAVAILABLE, {
    onCompleted: () => {
      if (refetchOrderDetails) refetchOrderDetails();
    },
    onError: (err) => {
      console.error('MARK_PRODUCT_AS_UNAVAILABLE Error- ', err);
    }
  });

  const callMarkProductAsUnavailable = () => {
    callMarkProductAsUnavailableMut({
      variables: {
        input: {
          productId,
          variantId
        }
      }
    });
  };

  const closeDropDown = () => {
    setOpenDropDown(false);
  };
  const toggleDropDown = () => {
    if (availableQuantity !== 0) {
      setOpenDropDown(!openDropDown);
    }
  };

  const selectingMenuItem = (data) => {
    closeDropDown();
    if (data.name === 'Mark As Unavailable') {
      callMarkProductAsUnavailable();
    }
  };

  const bottomButton = () => {
    if (availableQuantity === 0) {
      return (
        <div className="p-2 d-flex align-items-center justify-content-center">
          <div>{constVariables.FulfillmentCenter.unavailableItem}</div>
        </div>
      );
    } else {
      if (JSON.parse(items)?.length > 0 && JSON.parse(items)[indexValue]?.isScanned && JSON.parse(items)[indexValue]?.shipmentId) {
        return (
          <div className="selectedFulfilmentBoxBottom w-100 d-flex">
            <div className="selectedLeftButton text-white w-100 fs-8 p-2 d-flex align-items-center justify-content-center">
              <img src={SelectIcon} className="h-15px w-15px me-1" />
              <div>{constVariables.FulfillmentCenter.fulfilled}</div>
            </div>
          </div>
        );
      } else if (
        JSON.parse(items)?.length > 0 &&
        JSON.parse(items)[indexValue]?.isScanned &&
        !JSON.parse(items)[indexValue]?.shipmentId
      ) {
        return (
          <div className="selectedFulfilmentBoxBottom d-flex">
            <div className="selectedLeftButton text-white d-flex align-items-center justify-content-center">
              <img src={SelectIcon} className="h-15px w-15px me-2" />
              <div>{constVariables.FulfillmentCenter.scanned}</div>
            </div>
            <div
              onClick={() => handleChangeMark(false)}
              className="cursor-pointer selectedRightButton bottomRightRadius d-flex align-items-center justify-content-center"
            >
              <KTSVG path={CurveBackIcon} className="me-1" />
              <div>{constVariables.FulfillmentCenter.undo}</div>
            </div>
          </div>
        );
      } else {
        return (
          <div
            onClick={() => handleChangeMark(true)}
            className="cursor-pointer d-flex align-items-center justify-content-center markScanned-btn"
          >
            {constVariables.FulfillmentCenter.markScanned}
          </div>
        );
      }
    }
  };

  return (
    <>
      {availableQuantity !== 0 ||
      (JSON.parse(items)?.length <= 0 &&
        !JSON.parse(items)[indexValue]?.isScanned &&
        !JSON.parse(items)[indexValue]?.shipmentId) ? (
        <div className={`position-relative col-lg-4 col-md-6 col-sm-12 ${availableQuantity === 0 ? 'opacity-50' : ''} `}>
          <div
            className={`bg-white unSelectedFulfilmentBox d-flex flex-column rounded w-100 ${
              JSON.parse(items)?.length > 0 && JSON.parse(items)[indexValue]?.isScanned && availableQuantity !== 0
                ? 'selectedFulfilmentBox'
                : null
            }`}
          >
            <div
              className={`d-flex justify-content-between flex-grow-1 noScrollBarFulfilment order-item-box-main ${
                JSON.parse(items)?.length > 0 && JSON.parse(items)[indexValue]?.isScanned && availableQuantity !== 0
                  ? 'scanned-box'
                  : ''
              }`}
            >
              <div className="d-flex ">
                <Img
                  src={setImageSrc(imageUrl)}
                  placeholderImg={DefaultImage}
                  errorImg={DefaultImage}
                  className="fulfilmentProfilePicture product-image rounded"
                />
                <div>
                  {name ? (
                    <Link to={`/products/allProducts/edit/${productId}`} className="order-product-name" target="_blank">
                      {name}
                    </Link>
                  ) : null}
                  <div className="order-product-variants poppins-regular">
                    {variantTypes?.map((data, index) => (
                      <span key={index}>
                        {data?.value}
                        {index < variantTypes.length - 1 && data !== null ? '/' : null}
                      </span>
                    ))}
                  </div>
                  {sku ? (
                    <div className="order-product-info poppins-regular">
                      <span className="order-product-info-label">SKU: </span>
                      <span>{sku}</span>
                    </div>
                  ) : null}
                  {location ? (
                    <div className="order-product-info poppins-regular">
                      <span className="order-product-info-label">Location: </span>
                      <span>
                        {useableLocation?.map((data, index) => {
                          if (index === useableLocation.length - 1) {
                            return `${data.warehouseId} - ${data.inventoryPosition}`;
                          }
                          return `${data.warehouseId}-${data.inventoryPosition}, `;
                        })}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
              {!isDisabledButton && (
                <OutsideClickHandler onOutsideClick={closeDropDown}>
                  <KTSVG
                    onClick={toggleDropDown}
                    className={availableQuantity !== 0 ? 'cursor-pointer' : ''}
                    path={ThreeDotsIcon}
                  />
                  <Dropdown
                    data={constVariables.fulfilmentCenterBoxDropdownData}
                    onSelect={selectingMenuItem}
                    selected={openDropDown}
                    className="moveBoxUp"
                    closeDropdown={() => setOpenDropDown(false)}
                    shiftLeft
                    shiftLeftValue="10px"
                  />
                </OutsideClickHandler>
              )}
            </div>
            <Divider />

            <div className={`${isDisabledButton ? 'disabled-bottom-btn' : ''}`}>{bottomButton()}</div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ProductBoxFulfillmentCenter;
