import { gql } from '@apollo/client';

export const CREATE_STORE = gql`
  mutation CreateStore($input: CreateStoreInput!) @api(name: "authAPI") {
    createStore(input: $input) {
      id
      name
      streamConfig {
        performer {
          ingestEndpoint
          streamKey
        }
        shopperPlaybackURL
      }
      stripeAccountId
      stripeAccountPublishKey
    }
  }
`;
