import { gql } from '@apollo/client';

export const CHANGE_LIVE_PRODUCT = gql`
  mutation ChangeLiveProduct($input: ChangeLiveProductInput!) @api(name: "liveStreamingAPI") {
    changeLiveProduct(input: $input) {
      id
      success
      message
    }
  }
`;
