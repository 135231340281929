import { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router';

// Apollo
import { useQuery, useMutation } from '@apollo/client';
import { GET_APP_THEME } from 'src/apollo/queries';
import { CUSTOMISE_APP_THEME } from 'src/apollo/mutations';

// Components
import { CustomModal, AppPublishConfirmModal, SaveOrDiscardAppThemeSettingsModal } from 'src/components/oraganisms';
import { FlexBox, Loader } from 'src/components/atoms';
import { Tabs, Tooltip } from 'src/components/molecules';
import IntroScreens from './IntroScreens';
import AppColors from './AppColors';

// Hooks && Utils && Helpers
import { useToast } from 'src/utils/hooks/useToast';
import useTitle from 'src/utils/hooks/useTitle';

// Icons
import { BackRoundedIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables, ROUTES } from 'src/constants';

// Types
import { AppThemeData, AppLogo, AppCustomizationStatus } from './AppCustomizer.types';

// Styles
import './_appCustomizer.scss';

const AppCustomizer: FC = () => {
  const history = useHistory();
  useTitle('Settings - App Customizer');
  const { showToast } = useToast();
  const [isPublishable, setIsPublishable] = useState(false);
  const [isDraftable, setIsDraftable] = useState(false);
  const [leavePageConfirmModal, setLeavePageConfirmModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState('Intro Screens');
  const [targettedPath, setTargettedPath] = useState<string>();
  const [locationState, setLocationState] = useState();
  const [generalError, setGeneralError] = useState('');
  const [showPublishConfirmModal, setShowPublishConfirmModal] = useState(false);
  const [isDraftLoading, setIsDraftLoading] = useState(false);
  const [isPublishLoading, setIsPublishLoading] = useState(false);
  const [formChanges, setFormChanges] = useState(false);

  const renderStatusTooltip = (statusText?: string) => {
    switch (statusText) {
      case AppCustomizationStatus.PUBLISHED:
        return 'App changes were published successfully and they are live and visible to your users.';
      case AppCustomizationStatus.DRAFT:
        return 'App changes are currently saved as draft. You can publish them anytime.';
      default:
        return `App changes are currently under review. You won't be able to make further edits until these changes are published.`;
    }
  };

  const [appTheme, setAppTheme] = useState<AppThemeData>({
    appLogo: {
      url: '',
      fileImage: ''
    },
    bgColor: '',
    primaryColor: '',
    secondaryColor: '',
    status: ''
  });

  const { loading: isAppThemeLoading } = useQuery(GET_APP_THEME, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.getAppTheme) {
        const { appLogo, bgColor, primaryColor, secondaryColor, status } = res.getAppTheme;
        setAppTheme((appTheme) => {
          return { ...appTheme, appLogo: { url: appLogo, fileImage: appLogo }, bgColor, primaryColor, secondaryColor, status };
        });

        if (status === AppCustomizationStatus.DRAFT) {
          setIsDraftable(false);
          setIsPublishable(true);
        }
        if (status === AppCustomizationStatus.UNDER_REVIEW) {
          setIsDraftable(false);
          setIsPublishable(false);
        }
        if (status === AppCustomizationStatus.PUBLISHED) {
          setIsDraftable(false);
          setIsPublishable(false);
        }
      }
    },
    onError: (err) => {
      console.error('Get app theme ', err);
    }
  });

  const [customizeAppTheme, { loading: isCustomizeLoading }] = useMutation(CUSTOMISE_APP_THEME, {
    onCompleted: (res) => {
      setFormChanges(false);
      setIsDraftLoading(false);
      setIsPublishLoading(false);
      closePublishConfirmModal();

      if (res) {
        const { appLogo, bgColor, primaryColor, secondaryColor, status } = res?.customiseAppTheme;
        // setAppTheme((appTheme) => {
        //   return { ...appTheme, appLogo: { url: appLogo, fileImage: appLogo }, bgColor, primaryColor, secondaryColor, status };
        // });
        setAppTheme((appTheme) => {
          return { ...appTheme, bgColor, primaryColor, secondaryColor, status };
        });
        if (res?.customiseAppTheme?.status === AppCustomizationStatus.DRAFT) {
          showToast({
            successText: `<span style="color: #1e2749;">Changes <span> <span style="color: #50cd89;">have been saved as draft successfully!</span>`,
            message: '',
            width: 548
          });
          setIsDraftable(false);
          setIsPublishable(true);
        }
        if (res?.customiseAppTheme?.status === AppCustomizationStatus.PUBLISHED) {
          showToast({
            successText: `<span style="color: #1e2749;">Changes <span> <span style="color: #50cd89;">were published successfully!</span>`,
            message: ''
          });
          setIsPublishable(false);
        }
        if (res?.customiseAppTheme?.status === AppCustomizationStatus.UNDER_REVIEW) {
          showToast({
            successText: `<span style="color: #1e2749;">Changes <span> <span style="color: #ffc700;">are under review, we will notify once they are live!</span>`,
            message: '',
            width: 548
          });
          setIsDraftable(false);
          setIsPublishable(false);
        }
        leavePageHandler();
      }
    },
    onError: (err) => {
      console.log('err::', err);
      setFormChanges(false);
      setIsDraftLoading(false);
      setIsPublishLoading(false);
      closePublishConfirmModal();
    }
  });

  useEffect(() => {
    const preventRoute = history.block((e: any) => {
      setTargettedPath(e.pathname);
      setLocationState(e.state);
      if (appTheme.status === AppCustomizationStatus.UNDER_REVIEW) {
        return true;
      } else if (formChanges) {
        setLeavePageConfirmModal(true);
        return false;
      } else {
        return true;
      }
    });

    return () => {
      preventRoute();
    };
  }, [history, formChanges, appTheme.status]);

  const returnBack = () => {
    history.push(ROUTES.settings.main);
  };

  const handleUpdateTheme = (fieldName: string, value: string | AppLogo) => {
    setAppTheme((appTheme) => {
      return { ...appTheme, [fieldName]: value };
    });
    if (appTheme.status === AppCustomizationStatus.DRAFT) {
      setIsDraftable(true);
    }
    if (appTheme.status === AppCustomizationStatus.PUBLISHED) {
      setIsDraftable(true);
      setIsPublishable(true);
    }
    setFormChanges(true);
  };

  const leavePageHandler = () => {
    closeLeavePageConfirmModal();
    setTimeout(() => {
      if (targettedPath) {
        if (locationState) {
          history.push({
            pathname: targettedPath,
            state: locationState
          });
        } else {
          history.push(targettedPath);
        }
      }
    }, 5000);
  };

  const handleSaveAppTheme = (type?: string) => {
    if (type === AppCustomizationStatus.DRAFT) {
      setIsDraftLoading(true);
    } else {
      setIsPublishLoading(true);
    }
    customizeAppTheme({
      variables: {
        input: {
          publish: type === AppCustomizationStatus.DRAFT ? false : true,
          primaryColor: appTheme.primaryColor,
          secondaryColor: appTheme.secondaryColor,
          bgColor: appTheme.bgColor,
          appLogo: appTheme.appLogo.url
        }
      }
    });
  };

  const handleShowPublishConfirmModal = () => {
    if (appTheme?.appLogo?.url === '') {
      setGeneralError('Please upload the App logo.');
      return;
    }
    setShowPublishConfirmModal(true);
  };

  const renderStatus = (statusText: string) => {
    switch (statusText) {
      case AppCustomizationStatus.PUBLISHED:
        return 'Live';
      case AppCustomizationStatus.DRAFT:
        return 'Draft';
      case AppCustomizationStatus.UNDER_REVIEW:
        return 'In-review';
      default:
        return null;
    }
  };

  const renderStatusClass = (statusText: string) => {
    switch (statusText) {
      case AppCustomizationStatus.PUBLISHED:
        return 'published';
      case AppCustomizationStatus.DRAFT:
        return 'draft';
      case AppCustomizationStatus.UNDER_REVIEW:
        return 'in-review';
      default:
        return '';
    }
  };

  const closeLeavePageConfirmModal = () => {
    document.body.style.overflow = 'unset';
    setLeavePageConfirmModal(false);
  };

  const closePublishConfirmModal = () => {
    document.body.style.overflow = 'unset';
    setShowPublishConfirmModal(false);
  };

  const handleDiscard = () => {
    setTargettedPath(undefined);
    closeLeavePageConfirmModal();
  };

  return (
    <>
      {leavePageConfirmModal && (
        <CustomModal bodyClassname="w-90 w-md-50" show={leavePageConfirmModal} closeModal={closeLeavePageConfirmModal}>
          <SaveOrDiscardAppThemeSettingsModal
            handleDiscard={handleDiscard}
            saveTheme={() => handleSaveAppTheme(AppCustomizationStatus.DRAFT)}
            isCustomizeLoading={isCustomizeLoading}
          />
        </CustomModal>
      )}
      {showPublishConfirmModal && (
        <CustomModal bodyClassname="w-90 w-md-50" show={showPublishConfirmModal} closeModal={closePublishConfirmModal}>
          <AppPublishConfirmModal
            closeModal={closePublishConfirmModal}
            handleSaveAppTheme={handleSaveAppTheme}
            isPublishLoading={isPublishLoading}
          />
        </CustomModal>
      )}
      <div className="app-customizer-page p-40">
        <div className="app-customizer-page-container p-t-40">
          {isAppThemeLoading ? (
            <Loader type="page" className="h-600px card mb-0" />
          ) : (
            <>
              <FlexBox className="mb-6 align-items-center justify-content-between m-b-40">
                <FlexBox className="align-items-center">
                  <img src={BackRoundedIcon} alt={BackRoundedIcon} onClick={returnBack} className="cursor-pointer" />
                  <h2 className="page-title p-x-16 mb-0">{constVariables.appCustomizer.pageName}</h2>
                  {(!appTheme.status || appTheme.status !== 'ERROR') && (
                    <>
                      <div
                        className="app-theme-status-box d-flex align-items-center justify-content-center"
                        data-tip
                        data-for="appStatusIndicator"
                      >
                        <div className={`app-status ${renderStatusClass(appTheme.status)}`}></div>
                        <span>{renderStatus(appTheme.status)}</span>
                      </div>
                      <Tooltip
                        tooltipId="appStatusIndicator"
                        place="bottom"
                        className="app-customization-status-tooltip"
                        content={() => {
                          return <>{renderStatusTooltip(appTheme.status)}</>;
                        }}
                      />
                    </>
                  )}
                </FlexBox>
                <FlexBox className="align-items-center">
                  <button
                    className="btn btn-outlined-primary btn-sm m-0 m-r-12"
                    onClick={() => handleSaveAppTheme(AppCustomizationStatus.DRAFT)}
                    disabled={!isDraftable}
                  >
                    {isDraftLoading ? 'Saving' : 'Save '} as a draft
                    {isDraftLoading && <Loader type="button" className="h-10px w-10px" />}
                  </button>
                  <button className="btn btn-primary btn-sm m-0" onClick={handleShowPublishConfirmModal} disabled={!isPublishable}>
                    Publish
                  </button>
                </FlexBox>
              </FlexBox>
              <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} tabs={constVariables.TabForAppCustomizer} />
              <div className="app-customizer-page-content">
                {selectedTab.toLowerCase() === 'intro screens' && (
                  <IntroScreens
                    appTheme={appTheme}
                    updateTheme={handleUpdateTheme}
                    generalError={generalError}
                    setGeneralError={setGeneralError}
                  />
                )}
                {selectedTab.toLowerCase() === 'app colors' && <AppColors appTheme={appTheme} updateTheme={handleUpdateTheme} />}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AppCustomizer;
