import { FC } from 'react';

// Components
import OrderPreviewList from '../OrderPreviewList/OrderPreviewList';

// Hooks && Utils && Helpers
import getDeviceData from 'src/utils/UseDevice/useDevice';

// Icons
import { LeftNavigator, RightNavigtor } from 'src/assets/icons';

// Types
import { OrderPreviewProps } from './OrderPreview.types';

const OrderPreview: FC<OrderPreviewProps> = ({
  refreshData,
  setShowOrderModal,
  orderDetails,
  refresh,
  isVisible,
  toggleSlide,
  closeSlide,
  selectedOrderType
}) => {
  const deviceName = getDeviceData();

  return (
    <div
      id="kt_activities"
      className={`bg-body drawer drawer-end liveshow-event-preview overflow-visible ${isVisible ? 'drawer-on' : ''}`}
      data-kt-drawer="true"
      data-kt-drawer-name="activities"
      data-kt-drawer-activate="true"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'428px', 'sm': '428px'}"
      data-kt-drawer-direction="end"
      data-kt-drawer-toggle="#kt_activities_toggle"
      data-kt-drawer-close="#kt_activities_close"
    >
      <div className="position-relative">
        <div className={`bar-toggle-arrow bg-primary ${!isVisible ? 'd-none' : ''}`} onClick={toggleSlide}>
          <img src={RightNavigtor} alt={isVisible && deviceName.isWeb ? LeftNavigator : RightNavigtor} />
        </div>
        <div className={`${deviceName.isWeb ? 'px-4' : 'ps-3'}`}>
          <div className="h-100">
            <div className="w-400px h-100">
              <div className="vh-100 overflow-auto hide-scrollbar">
                <div className="p-6">
                  <div className="pb-6">
                    {isVisible && (
                      <OrderPreviewList
                        refresh={refresh}
                        setShowModal={setShowOrderModal}
                        data={orderDetails}
                        close={closeSlide}
                        selectedOrderType={selectedOrderType}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderPreview;
