/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const SEZZLE_CONNECTION_STATUS = gql`
  query GetSezzleConnectionStatus @api(name: "productsAPI") {
    getSezzleConnectionStatus {
      connected
      publicKey
      privateKey
    }
  }
`;
