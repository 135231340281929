import { useState, FC } from 'react';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { Img } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import { ArrowsOutIcon, DefaultImage, ProductDragIcon } from 'src/assets/icons';

// Types
import { UserRoles } from 'src/components/pages/settings/UserPermissions/UserPermissions.type';

interface ImageItemProps {
  item: any;
  index: number;
  handleModal(index: number): void;
  toggle(url: string): void;
  selectedImage: Array<any>;
}

const ImageItem: FC<ImageItemProps> = (props) => {
  const { item, index, selectedImage, handleModal, toggle } = props;
  const [showController, setShowController] = useState(false);

  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  return (
    <li
      onClick={() => handleModal(index)}
      className="eachImage"
      onMouseEnter={() => setShowController(true)}
      onMouseLeave={() => setShowController(false)}
    >
      {selectedImage.includes(item.productMediaUrl) && (
        <input
          className="form-check-input checks m-0 position-absolute cursor-pointer"
          id="check"
          type="checkbox"
          name=""
          style={{ outline: 'none', zIndex: 6, left: 20, top: 20 }}
          value={index}
          checked={selectedImage.includes(item.productMediaUrl) ? true : false}
          onChange={() => toggle(item.productMediaUrl)}
        />
      )}
      {showController && (
        <div className="controll-box cursor-pointer">
          {!selectedImage.includes(item.productMediaUrl) && role !== UserRoles.FULFILMENT_ASSOCIATE && (
            <input
              className="form-check-input checks m-0 position-absolute cursor-pointer"
              id="check"
              type="checkbox"
              name=""
              style={{ outline: 'none', zIndex: 5 }}
              value={index}
              checked={selectedImage.includes(item.productMediaUrl) ? true : false}
              onChange={() => toggle(item.productMediaUrl)}
            />
          )}
          <KTSVG path={ProductDragIcon} svgColor="#fff" className="drags cursor-pointer" />
          <img src={ArrowsOutIcon} className="arrows cursor-pointer" />
        </div>
      )}
      <Img
        src={setImageSrc(item?.fileimage ? item?.fileimage : generateCompressedImageURL(item?.productMediaUrl, '200'))}
        placeholderImg={DefaultImage}
        errorImg={DefaultImage}
        className="h-100 w-100 default-product-icon product-image cursor-pointer"
      />
    </li>
  );
};

export default ImageItem;
