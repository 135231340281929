import { FC, useState } from 'react';
import clsx from 'clsx';

// Components
import { RadioButton, Loader } from 'src/components/atoms';
import { Divider } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { CloseIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { CancelOrderProps } from 'src/components/oraganisms/CustomModal/Modal.types';

// Styles
import 'src/components/oraganisms/CustomModal/modalStyles.scss';
import './OrderDetails.scss';

const CancelOrder: FC<CancelOrderProps> = ({
  title,
  isRefunding,
  cancelBtnTitle,
  actionBtnHandlerOriginal,
  actionBtnHandlerStoreCredit,
  cancelBtnHandler,
  cancelOrder,
  disableRefundBtn,
  isOptions
}) => {
  const [originalPaymentCheck, setOriginalPaymentCheck] = useState(false);
  const [storeCreditCheck, setStoreCreditCheck] = useState(false);

  const toggleOriginalPayment = () => {
    if (originalPaymentCheck) {
      setOriginalPaymentCheck(false);
    } else {
      setOriginalPaymentCheck(true);
      setStoreCreditCheck(false);
    }
  };
  const toggleStoreCreditCheck = () => {
    if (storeCreditCheck) {
      setStoreCreditCheck(false);
    } else {
      setStoreCreditCheck(true);
      setOriginalPaymentCheck(false);
    }
  };

  const actionBtnHandler = () => {
    if (originalPaymentCheck && actionBtnHandlerOriginal) {
      actionBtnHandlerOriginal();
    } else if (storeCreditCheck && actionBtnHandlerStoreCredit) {
      actionBtnHandlerStoreCredit();
    }
  };

  return (
    <div
      className={clsx('modal-content cancelOrder', {
        'modal-large-content': cancelOrder === true
      })}
    >
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <div className="btn btn-xs btn-active-light-primary p-0 m-0 border-none" onClick={cancelBtnHandler}>
          <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
        </div>
      </div>
      <div
        className={clsx('modal-body p-x-30 p-y-36', {
          mediaGreyText: cancelOrder === true
        })}
      >
        <div className="d-flex align-items-center w-100 m-b-8">
          <RadioButton
            id="refundOriginalPayment"
            name="refundOriginalPayment"
            isChecked={originalPaymentCheck}
            handleChange={toggleOriginalPayment}
            noMargin
          />
          <label className="mb-0 ms-2">{constVariables.orderDetails.orderCancelModal.option1.title}</label>
        </div>

        <div className="d-flex align-items-center ml-3 w-100">
          <p className="ms-5 opacity-50 mb-0">{constVariables.orderDetails.orderCancelModal.option1.message}</p>
        </div>

        <Divider classname="divider-status m-y-24" />

        <div className="d-flex align-items-center w-100 m-b-8">
          <RadioButton
            id="refundStoreCredit"
            name="refundStoreCredit"
            isChecked={storeCreditCheck}
            handleChange={toggleStoreCreditCheck}
            noMargin
          />
          <label className="mb-0 ms-2">{constVariables.orderDetails.orderCancelModal.option2.title}</label>
        </div>

        <div className="d-flex align-items-center ml-3 w-100">
          <p className="ms-5 opacity-50 mb-0">{constVariables.orderDetails.orderCancelModal.option2.message}</p>
        </div>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-outlined-secondary btn-md" data-bs-dismiss="modal" onClick={cancelBtnHandler}>
          {cancelBtnTitle}
        </button>
        <button
          type="button"
          className={clsx('btn m-0 btn-md', {
            'btn-primary': storeCreditCheck || originalPaymentCheck
          })}
          disabled={(!storeCreditCheck && !originalPaymentCheck) || disableRefundBtn || isRefunding}
          onClick={actionBtnHandler}
        >
          {isRefunding ? 'Refunding' : 'Refund'} Order
          {isRefunding && <Loader type="button" className="h-10px w-10px" />}
        </button>
      </div>
    </div>
  );
};

export default CancelOrder;
