/* eslint-disable no-nested-ternary */
import { FC, useState, useEffect, useMemo } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import moment from 'moment';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { FlexBox, DropdownSelector } from 'src/components/atoms';
import { Checkbox, Divider, Dropdown, DatesDropdownSelector } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { CalenderIcon, ScheduleEditIcon, ScheduleDeleteIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';
import { ProductStatus, CollectionStatus } from './statusTempData';

// Types
import { StatusSectionTypes } from './StatusSection.types';
import { UserRoles } from 'src/components/pages/settings/UserPermissions/UserPermissions.type';

// Styles
import './_statusSection.scss';

const StatusSection: FC<StatusSectionTypes> = ({
  statusInfo,
  channels,
  status,
  updateChannelValues,
  setStatus,
  setColApiCall,
  setFormChanges,
  page,
  productAvailableFrom,
  setProductAvailableFrom
}) => {
  const [option, setOption] = useState(false);
  const [showDateDropdown, setShowDateDropdown] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [timeDropDownDataAm, setTimeDropDownDataAm] = useState<{ id: number; name: Date }[]>();
  const [timeDropDownDataPm, setTimeDropDownDataPm] = useState<{ id: number; name: Date }[]>();
  const [selectedTime, setSelectedTime] = useState('');
  const [helperText, setHelperText] = useState('');

  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  const dropdownData = () => {
    switch (page) {
      case 'product':
        return ProductStatus;
      case 'collection':
        return CollectionStatus;
      default:
        return ProductStatus;
    }
  };

  const isDisableSelector = useMemo(() => {
    if (page === 'product' && role === UserRoles.FULFILMENT_ASSOCIATE) return true;
    return false;
  }, [page, role]);

  const isDisableCheckbox = useMemo(() => {
    if (page === 'product' && role === UserRoles.FULFILMENT_ASSOCIATE) return true;
    return false;
  }, [page, role]);

  useEffect(() => {
    if (status.toUpperCase() !== 'ACTIVE') {
      if (productAvailableFrom) {
        setHelperText(`Scheduling won't apply until product status is set as active.`);
      } else {
        setHelperText(`This ${page} will be hidden from all sales channels.`);
      }
    } else {
      setHelperText('');
    }
  }, [status]);

  // useEffect(() => {
  //   if (productAvailableFrom) {
  //     setSelectedDate(new Date(productAvailableFrom));
  //     setSelectedTime(`${moment(productAvailableFrom).format('hh:mm')}${moment(productAvailableFrom).format('a').toUpperCase()}`);
  //   }
  // }, [productAvailableFrom]);

  useEffect(() => {
    if (productAvailableFrom) {
      setSelectedDate(new Date(productAvailableFrom));
      setSelectedTime(`${moment(productAvailableFrom).format('hh:mm')}${moment(productAvailableFrom).format('a').toUpperCase()}`);
    } else {
      setSelectedDate(new Date());
      setSelectedTime(`${moment(new Date()).format('hh:mm')}${moment(new Date()).format('a').toUpperCase()}`);
    }
  }, [productAvailableFrom]);

  useEffect(() => {
    if (selectedDate) {
      const tempAm: { id: number; name: Date }[] = [];
      const tempPm: { id: number; name: Date }[] = [];

      tempAm.push({
        id: 12,
        name: new Date(new Date(selectedDate).setHours(0, 0))
      });
      tempAm.push({
        id: 124,
        name: new Date(new Date(selectedDate).setHours(0, 30))
      });
      for (let i = 1; i < 12; i++) {
        tempAm.push({
          id: i,
          name: new Date(new Date(selectedDate).setHours(i, 0))
        });
        tempAm.push({
          id: i * 12,
          name: new Date(new Date(selectedDate).setHours(i, 30))
        });
      }
      setTimeDropDownDataAm(tempAm);

      tempPm.push({
        id: 0,
        name: new Date(new Date(selectedDate).setHours(12, 0))
      });
      tempPm.push({
        id: 123,
        name: new Date(new Date(selectedDate).setHours(12, 30))
      });

      for (let i = 13; i < 24; i++) {
        tempPm.push({
          id: i,
          name: new Date(new Date(selectedDate).setHours(i, 0))
        });
        tempPm.push({
          id: i * 12,
          name: new Date(new Date(selectedDate).setHours(i, 30))
        });
      }
      setTimeDropDownDataPm(tempPm);
    }
  }, [selectedDate]);

  const selectStatus = (e: any) => {
    if (setColApiCall && setFormChanges) {
      setColApiCall(true);
      setFormChanges(true);
    }
    setStatus(e.name);
    setOption(!option);
  };

  const closeDateDropdown = () => {
    setShowDateDropdown(false);
  };

  const handlePickerDate = (date) => {
    setSelectedDate(date);
  };

  const handleUpdateSelectedTime = (updatedTime) => {
    const typeUpdatedTime = typeof updatedTime;
    if (typeUpdatedTime === 'string') {
      setSelectedTime(updatedTime);
    } else {
      setSelectedTime(`${moment(new Date(updatedTime)).format('hh:mm')}${moment(new Date(updatedTime)).format('a').toUpperCase()}`);
      setSelectedDate(new Date(updatedTime));
    }
  };

  const handleSaveScheduleProduct = () => {
    let formattedTime: Date | null = null;
    let time: string[];
    if (selectedDate && selectedTime) {
      formattedTime = new Date(selectedDate);
      time = selectedTime.slice(0, -2).split(':');
      formattedTime.setHours(
        // eslint-disable-next-line no-nested-ternary
        selectedTime.includes('PM')
          ? Number(time[0]) !== 12
            ? Number(time[0]) + 12
            : Number(time[0])
          : Number(time[0]) === 12
          ? 0
          : Number(time[0])
      );
      formattedTime.setMinutes(Number(time[1]));
    }
    setProductAvailableFrom && setProductAvailableFrom(formattedTime?.toISOString());
    closeDateDropdown();
  };

  const handleClearSchedule = () => {
    setProductAvailableFrom && setProductAvailableFrom(null);
  };

  return (
    <div className="card">
      <div className="m-b-32">
        <h4 className="section-title m-0">{statusInfo.title}</h4>
      </div>
      <div className="text-nowrap input-title">{statusInfo.placeholder}</div>
      <div className="m-b-8">
        <OutsideClickHandler
          onOutsideClick={() => {
            if (option) {
              setOption(!option);
            }
          }}
        >
          <div className="position-relative">
            <DropdownSelector
              className={`form-control form-control-lg px-5 align-items-center dropdown-box ${
                option ? 'dropdown-box-active' : ''
              } ${isDisableSelector ? 'disable-background' : ''}`}
              onClick={() => setOption(!option)}
              selectedValue={status}
              text="Select"
            />
            <Dropdown
              data={dropdownData()}
              selected={option}
              value={status}
              onSelect={selectStatus}
              closeDropdown={() => setOption(false)}
            />
          </div>
        </OutsideClickHandler>
      </div>
      <span className="helper-text poppins-regular">{helperText}</span>
      <Divider classname="divider-status m-y-24" />
      <div className="text-nowrap input-title m-b-16">{constVariables.common.channels}</div>
      {Object.keys(channels).map((channel, index) => {
        return (
          <Checkbox
            key={index}
            name={channels[channel].title}
            value={channels[channel].value}
            onChangeValue={() => {
              if (setColApiCall && setFormChanges) {
                setColApiCall(true);
                setFormChanges(true);
              }
              updateChannelValues(channels[channel]);
            }}
            noVerticalPadding
            disable={isDisableCheckbox}
          />
        );
      })}
      <div className={`position-relative w-100 ${page === 'product' ? 'm-t-24' : ''}`}>
        {page === 'product' &&
          (productAvailableFrom ? (
            <>
              <FlexBox className="align-items-center availability-status">
                <KTSVG path={CalenderIcon} className="svg-icon-3 me-2" />
                <p className="poppins-regular m-0">
                  Scheduled for {moment(productAvailableFrom).format('MMMM D, YYYY')} at{' '}
                  {moment(productAvailableFrom).format('h:mm A')}
                </p>
              </FlexBox>
              {role !== UserRoles.FULFILMENT_ASSOCIATE && (
                <FlexBox className="align-items-center mt-6">
                  <FlexBox
                    className="align-items-center me-2 cursor-pointer availability-control-item"
                    onClick={() => setShowDateDropdown(true)}
                  >
                    <KTSVG path={ScheduleEditIcon} className="me-1" />
                    <p className="poppins-medium m-0">Edit</p>
                  </FlexBox>
                  <FlexBox className="align-items-center cursor-pointer availability-control-item" onClick={handleClearSchedule}>
                    <KTSVG path={ScheduleDeleteIcon} className="me-1" />
                    <p className="poppins-medium m-0">Clear Schedule</p>
                  </FlexBox>
                </FlexBox>
              )}
            </>
          ) : role !== UserRoles.FULFILMENT_ASSOCIATE ? (
            <button
              className="d-flex align-items-center justify-content-center btn btn-secondary btn-md m-0 w-100"
              onClick={() => setShowDateDropdown(true)}
            >
              <KTSVG path={CalenderIcon} />
              <span className="poppins-medium">{constVariables.common.schedule}</span>
            </button>
          ) : null)}
        {page === 'product' && (
          <OutsideClickHandler onOutsideClick={closeDateDropdown}>
            <DatesDropdownSelector
              onselect={(data) => null}
              isOpen={showDateDropdown}
              noRangePicker={true}
              onselectDate={(date) => handlePickerDate(date)}
              defaultDate={productAvailableFrom ? new Date(productAvailableFrom) : new Date()}
              type="schedule"
              selectedTime={selectedTime}
              selectedDate={selectedDate}
              timeDropDownDataAm={timeDropDownDataAm}
              timeDropDownDataPm={timeDropDownDataPm}
              updateSelectedTime={handleUpdateSelectedTime}
              saveScheduleProduct={handleSaveScheduleProduct}
              closeDropdown={closeDateDropdown}
            />
          </OutsideClickHandler>
        )}
      </div>
    </div>
  );
};

export default StatusSection;
