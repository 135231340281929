/* eslint-disable no-nested-ternary */
import { FC, useEffect, useState } from 'react';

// Apollo
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_RAPID_LIST, PRODUCT_DETAILS } from 'src/apollo/queries';
import { DELETE_FROM_RAPID_ACTION_LIST, MANAGE_RAPID_ACTION_CONFIG } from 'src/apollo/mutations';

// Components
import { Catalogs } from '../Catalogs/catalogs';
import { Collections } from '../Collections/collections';
import { RapidActionList } from '..';

// Hooks && Utils && Helpers
import getDeviceData from 'src/utils/UseDevice/useDevice';

// Icons
import { LeftNavigator, RightNavigtor } from 'src/assets/icons';

// ConstVariables
import { constVariables, envUrls } from 'src/constants';

// Types
import { RapidActionProps } from './RapidAction.types';
import { CatalogTypeType, FeaturedProductsStatusType } from 'src/components/pages/featured-products/FeaturedProducts.types';

const RapidAction: FC<RapidActionProps> = ({ setRapidActionOpened, setShowProductModal, reloadList }) => {
  const deviceName = getDeviceData();
  const [openRapidAction, setOpenRapidAction] = useState('');
  const [toggles, setToggles] = useState({ featured: false, flashSale: false });
  const [featureList, setFeatureList] = useState([]);
  const [flashSaleList, setFlashSaleList] = useState([]);
  const [mysteryList, setMysteryList] = useState([]);
  const [savePreviousState, setSavePreviousState] = useState('');

  const [getFeaturedList, { data: featuredData }] = useLazyQuery(GET_RAPID_LIST, {
    context: {
      headers: {
        Authorization: localStorage.getItem('token') || '',
        tenantid: envUrls.firebaseTenantId,
        'x-api-key': envUrls.authXApiKey
      }
    },
    fetchPolicy: 'cache-and-network'
  });

  const [getFlashSaleList, { data: flashSaleData }] = useLazyQuery(GET_RAPID_LIST, {
    context: {
      headers: {
        Authorization: localStorage.getItem('token') || '',
        tenantid: envUrls.firebaseTenantId,
        'x-api-key': envUrls.authXApiKey
      }
    },
    fetchPolicy: 'cache-and-network'
  });

  const [getMysteryList, { data: mysteryListData }] = useLazyQuery(GET_RAPID_LIST, {
    context: {
      headers: {
        Authorization: localStorage.getItem('token') || '',
        tenantid: envUrls.firebaseTenantId,
        'x-api-key': envUrls.authXApiKey
      }
    },
    fetchPolicy: 'cache-and-network'
  });

  const [getFeaturedProducts] = useLazyQuery(PRODUCT_DETAILS, {
    onCompleted: (data) => {
      if (data?.getProduct?.length > 0) {
        const res: any = [];
        featuredData?.getRapidActionList?.productIds?.map((elem: number) => {
          res.push(data?.getProduct?.find((item: any) => item.id === elem.toString()));
          return null;
        });
        setFeatureList(res);
      }
    },
    fetchPolicy: 'network-only'
  });

  const [getFlashSaleProducts] = useLazyQuery(PRODUCT_DETAILS, {
    onCompleted: (data) => {
      if (data?.getProduct?.length > 0) {
        const res = flashSaleData?.getRapidActionList?.productIds?.map((elem: number) => {
          return data?.getProduct?.find((item: any) => item.id === elem.toString());
        });
        setFlashSaleList(res);
      }
    },
    fetchPolicy: 'network-only'
  });

  const [getMysteryListPorducts] = useLazyQuery(PRODUCT_DETAILS, {
    onCompleted: (data) => {
      if (data?.getProduct?.length > 0) {
        const res = mysteryListData?.getRapidActionList?.productIds.map((elem: number) => {
          return data.getProduct.find((item: any) => item.id === elem.toString());
        });
        setMysteryList(res);
      }
    },
    fetchPolicy: 'network-only'
  });

  const [deleteFeaturedProduct] = useMutation(DELETE_FROM_RAPID_ACTION_LIST);

  const [deleteFlashSaleProduct] = useMutation(DELETE_FROM_RAPID_ACTION_LIST);

  const [deleteMysteryProduct] = useMutation(DELETE_FROM_RAPID_ACTION_LIST);

  const [manageRapidAction, { data: rapidActionConfig }] = useMutation(MANAGE_RAPID_ACTION_CONFIG, {
    onCompleted: (data) => {
      setToggles({
        featured: data.manageRapidActionConfig?.featured?.status === 'ACTIVE',
        flashSale: data.manageRapidActionConfig?.flashSale?.status === 'ACTIVE'
      });
    }
  });

  const saveFeaturedConfig = (status: FeaturedProductsStatusType['value'], productAssignmentMode: CatalogTypeType['value']) => {
    manageRapidAction({
      context: {
        headers: {
          Authorization: localStorage.getItem('token') || '',
          tenantid: envUrls.firebaseTenantId,
          'x-api-key': envUrls.authXApiKey
        }
      },
      variables: {
        input: {
          action: 'SET',
          featured: {
            status: status || 'ACTIVE',
            productAssignmentMode: productAssignmentMode || 'MANUAL'
          }
        }
      }
    });
  };

  const saveFlashSaleConfig = (status: FeaturedProductsStatusType['value'], productAssignmentMode: CatalogTypeType['value']) => {
    manageRapidAction({
      context: {
        headers: {
          Authorization: localStorage.getItem('token') || '',
          tenantid: envUrls.firebaseTenantId,
          'x-api-key': envUrls.authXApiKey
        }
      },
      variables: {
        input: {
          action: 'SET',
          flashSale: {
            status: status || 'ACTIVE',
            productAssignmentMode: productAssignmentMode || 'MANUAL'
          }
        }
      }
    });
  };

  useEffect(() => {
    callList();
  }, [reloadList]);

  const toggleFeatured = (enable: boolean) => {
    saveFeaturedConfig(
      enable ? 'ACTIVE' : 'INACTIVE',
      rapidActionConfig.getRapidActionConfig?.featured?.productAssignmentMode || 'MANUAL'
    );
    setToggles({ ...toggles, featured: enable });
  };

  const toggleFlashSale = (enable: boolean) => {
    saveFlashSaleConfig(
      enable ? 'ACTIVE' : 'INACTIVE',
      rapidActionConfig.getRapidActionConfig?.flashSale?.productAssignmentMode || 'MANUAL'
    );
    setToggles({ ...toggles, flashSale: enable });
  };

  const callList = () => {
    getFeaturedList({
      context: {
        headers: {
          Authorization: localStorage.getItem('token') || '',
          tenantid: envUrls.firebaseTenantId,
          'x-api-key': envUrls.authXApiKey
        }
      },
      variables: {
        input: {
          type: 'FEATURED'
        }
      }
    });
    getFlashSaleList({
      context: {
        headers: {
          Authorization: localStorage.getItem('token') || '',
          tenantid: envUrls.firebaseTenantId,
          'x-api-key': envUrls.authXApiKey
        }
      },
      variables: {
        input: {
          type: 'FLASH_SALE'
        }
      }
    });
    getMysteryList({
      context: {
        headers: {
          Authorization: localStorage.getItem('token') || '',
          tenantid: envUrls.firebaseTenantId,
          'x-api-key': envUrls.authXApiKey
        }
      },
      variables: {
        input: {
          type: 'MYSTERY_LIST'
        }
      }
    });
    manageRapidAction({
      context: {
        headers: {
          Authorization: localStorage.getItem('token') || '',
          tenantid: envUrls.firebaseTenantId,
          'x-api-key': envUrls.authXApiKey
        }
      },
      variables: {
        input: {
          action: 'GET'
        }
      }
    });
  };

  useEffect(() => {
    if (featuredData?.getRapidActionList?.productIds?.length > 0)
      getFeaturedProducts({
        variables: {
          input: {
            productIDs: featuredData?.getRapidActionList?.productIds,
            isStoreOwner: true
          }
        }
      });
    if (flashSaleData?.getRapidActionList?.productIds?.length > 0)
      getFlashSaleProducts({
        variables: {
          input: {
            productIDs: flashSaleData?.getRapidActionList?.productIds,
            isStoreOwner: true
          }
        }
      });
    if (mysteryListData?.getRapidActionList?.productIds?.length > 0)
      getMysteryListPorducts({
        variables: {
          input: {
            productIDs: mysteryListData?.getRapidActionList?.productIds,
            isStoreOwner: true
          }
        }
      });
    // eslint-disable-next-line
  }, [featuredData, flashSaleData, mysteryListData]);

  return (
    <div className={`${deviceName.isWeb ? 'ps-8' : 'ps-3'}`}>
      <div
        className="ps-2 h-100"
        onMouseEnter={() => {
          // Hide rapidActions Menu for now.
          // if (openRapidAction !== 'clicked' && deviceName.isWeb) setOpenRapidAction('');
          if (openRapidAction !== 'clicked' && deviceName.isWeb) setOpenRapidAction('');
        }}
        onMouseLeave={() => {
          if (openRapidAction === 'hover' && deviceName.isWeb) setOpenRapidAction('');
        }}
      >
        <div
          className={`engage-toolbar d-flex position-fixed px-5 fw-bolder zindex-2 top-50 end-0 transform-90 mt-20 gap-2 ${
            deviceName.isWeb ? 'd-none' : ''
          }`}
        >
          <button
            id="kt_engage_demos_toggle"
            className="engage-demos-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0"
            onClick={() => {
              setOpenRapidAction('hover');
              setRapidActionOpened(false);
            }}
          >
            <span id="kt_engage_demos_label">Rapid Action</span>
          </button>
        </div>
        <div
          className={`w-400px h-100 side-bar ${
            openRapidAction === 'hover' ? 'side-bar-hover' : openRapidAction === 'clicked' ? 'no-shadow-sidebar' : 'side-bar-hidden'
          }`}
        >
          <div
            className="bar-toggle-arrow bg-primary"
            onClick={() => {
              if (deviceName.isWeb) {
                if (openRapidAction === 'clicked') {
                  setOpenRapidAction('hover');
                  setRapidActionOpened(false);
                } else {
                  setOpenRapidAction('clicked');
                  setRapidActionOpened(true);
                }
              } else {
                setOpenRapidAction('');
                setRapidActionOpened(false);
              }
            }}
          >
            <img
              src={openRapidAction !== 'clicked' && deviceName.isWeb ? LeftNavigator : RightNavigtor}
              alt={openRapidAction !== 'clicked' && deviceName.isWeb ? LeftNavigator : RightNavigtor}
            />
          </div>
          <div className="vh-100 overflow-auto hide-scrollbar">
            <div className="p-6">
              <div className="pb-6">
                <RapidActionList
                  category={constVariables.RapidList.featured.label}
                  data={featureList}
                  deleteProduct={deleteFeaturedProduct}
                  toggleRapidList={toggleFeatured}
                  toggle={toggles.featured}
                  enabled={true}
                  listEnabled={() => ({})}
                  refreshData={getFeaturedList}
                  setOpenRapidAction={() => {
                    setSavePreviousState(openRapidAction);
                    setOpenRapidAction('clicked');
                    setRapidActionOpened(true);
                  }}
                  setCloseRapidAction={() => {
                    setOpenRapidAction(savePreviousState);
                    savePreviousState === 'clicked' ? setRapidActionOpened(true) : setRapidActionOpened(false);
                  }}
                  setShowModal={setShowProductModal}
                  setStateCache={setFeatureList}
                />
              </div>
              <div className="pb-6">
                <RapidActionList
                  category={constVariables.RapidList.flashSale.label}
                  data={flashSaleList}
                  deleteProduct={deleteFlashSaleProduct}
                  toggleRapidList={toggleFlashSale}
                  toggle={toggles.flashSale}
                  enabled={true}
                  listEnabled={() => ({})}
                  refreshData={getFlashSaleList}
                  setOpenRapidAction={() => {
                    setOpenRapidAction('clicked');
                    setRapidActionOpened(true);
                  }}
                  setShowModal={setShowProductModal}
                  setCloseRapidAction={() => {
                    setOpenRapidAction(savePreviousState);
                    savePreviousState === 'clicked' ? setRapidActionOpened(true) : setRapidActionOpened(false);
                  }}
                  setStateCache={setFlashSaleList}
                />
              </div>
              <div>
                <RapidActionList
                  category={constVariables.RapidList.mysteryList.label}
                  data={mysteryList}
                  deleteProduct={deleteMysteryProduct}
                  refreshData={getMysteryList}
                  setOpenRapidAction={() => {
                    setOpenRapidAction('clicked');
                    setRapidActionOpened(true);
                  }}
                  setShowModal={setShowProductModal}
                  setCloseRapidAction={() => {
                    setOpenRapidAction(savePreviousState);
                    savePreviousState === 'clicked' ? setRapidActionOpened(true) : setRapidActionOpened(false);
                  }}
                  setStateCache={setMysteryList}
                />
              </div>
            </div>
            <div className="p-6 border-1 border-top border-secondary">
              <Collections />
              <Catalogs />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RapidAction;
