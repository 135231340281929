import { FC, ReactElement, useEffect, useCallback } from 'react';
import clsx from 'clsx';

interface CustomModalProps {
  show: boolean;
  children: ReactElement;
  bodyClassname?: string;
  modalClassname?: string;
  closeModal: () => void;
}

const CustomModal: FC<CustomModalProps> = ({ show, children, bodyClassname, modalClassname, closeModal }) => {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [show]);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      document.body.style.overflow = 'unset';
      closeModal();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction);

    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction]);

  return (
    <div
      className={clsx(`modal fade bg-none opacity-100 ${modalClassname}`, {
        show
      })}
      aria-modal="true"
      tabIndex={-1}
      id="kt_modal_1"
    >
      <div className={`modal-dialog modal-dialog-centered ${bodyClassname}`}>{children}</div>
    </div>
  );
};

export default CustomModal;
