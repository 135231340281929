import { FC } from 'react';

interface LoaderProps {
  type: string;
  className?: string;
}

const Loader: FC<LoaderProps> = ({ type, className }) => {
  const renderLoader = () => {
    switch (type) {
      case 'page':
        return (
          <div className={`w-100 d-flex flex-center justify-content-center ${className ? className : ''}`}>
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        );
      default:
        return (
          <div style={{ borderWidth: '1px' }} className={`spinner-border ms-5 ${className ? className : ''}`} role="status">
            <span className="sr-only">Loading...</span>
          </div>
        );
    }
  };

  return <>{renderLoader()}</>;
};

export default Loader;
