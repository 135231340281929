import { FC, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { FlexBox } from 'src/components/atoms';
import { Tooltip } from 'src/components/molecules';
import ColorPickerDropDown from './ColorPickerDropdown';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { DownArrowIcon } from 'src/assets/icons';

// Styles
import './_colorPicker.scss';

interface ColorPickerProps {
  color: string;
  updateColor: (color: string) => void;
  disabled: boolean;
  tooltipId: string;
}

const ColorPicker: FC<ColorPickerProps> = ({ color, updateColor, disabled, tooltipId }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleShowDropdown = () => {
    if (disabled) return;
    setShowDropdown(true);
  };

  return (
    <>
      <div className="color-picker position-relative">
        <div
          className={`color-picker-input d-flex align-items-center justify-content-between ${showDropdown ? 'active' : ''} ${
            !disabled ? 'cursor-pointer' : ''
          }`}
          onClick={handleShowDropdown}
          data-tip
          data-for={tooltipId}
        >
          <div className="selected-color" style={{ backgroundColor: color }}></div>
          <div>
            <KTSVG path={DownArrowIcon} className={showDropdown ? 'active-arrow' : ''} />
          </div>
        </div>
        <OutsideClickHandler
          onOutsideClick={() => {
            if (showDropdown) setShowDropdown(false);
          }}
        >
          <ColorPickerDropDown isOpen={showDropdown} color={color} updateColor={updateColor} />
        </OutsideClickHandler>
      </div>
      {disabled && (
        <Tooltip
          tooltipId={tooltipId}
          place="bottom"
          className="app-customization-status-tooltip"
          content={() => {
            return (
              <>
                App changes are currently under review. You won't be able to make further edits until these changes are published.
              </>
            );
          }}
        />
      )}
    </>
  );
};

export default ColorPicker;
