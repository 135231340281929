import { constVariables } from 'src/constants';
import { CommonLaneStyle } from './helper/allPurchaseOrderHelper';

export const CustomData = {
  lanes: [
    {
      id: 'lane1',
      title: 'Draft',
      style: {
        ...CommonLaneStyle(constVariables.allPurchaseOrders.draftColor, '#F8F8FA'),
        height: '100%'
      },
      cards: [
        {
          id: 'Card1',
          title: 'Vendor XXX',
          logo: 'https://media.istockphoto.com/photos/3d-simple-rainbow-snapshot-camera-with-lens-on-pastel-background-with-picture-id1308083607?b=1&k=20&m=1308083607&s=170667a&w=0&h=5pcEUtjvmq7_PAmneHgxStCP9LgHuZeApznUxmrSDcI=',
          purchaseOrder: '232323',
          items: '50',
          price: '1900',
          ownerImg:
            'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YXZhdGFyfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
          ownerName: 'M. Peterson',
          date: '12/20/21'
        },
        {
          id: 'Card2',
          title: 'Vendor XXX',
          logo: 'https://media.istockphoto.com/photos/3d-simple-rainbow-snapshot-camera-with-lens-on-pastel-background-with-picture-id1308083607?b=1&k=20&m=1308083607&s=170667a&w=0&h=5pcEUtjvmq7_PAmneHgxStCP9LgHuZeApznUxmrSDcI=',
          purchaseOrder: '232323',
          items: '50',
          price: '1900',
          ownerImg:
            'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YXZhdGFyfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
          ownerName: 'M. Peterson',
          date: '12/20/21'
        }
      ]
    },
    {
      id: 'lane2',
      title: constVariables.allPurchaseOrders.ordered,
      disallowAddingCard: true,
      cards: [],
      style: CommonLaneStyle(constVariables.allPurchaseOrders.orderedColor, '#F8F8FA')
    },
    {
      id: 'lane3',
      title: constVariables.allPurchaseOrders.arrived,
      disallowAddingCard: true,
      cards: [],
      style: CommonLaneStyle(constVariables.allPurchaseOrders.arrivedColor, '#F8F8FA')
    },
    {
      id: 'lane4',
      title: constVariables.allPurchaseOrders.dispatched,
      disallowAddingCard: true,
      cards: [],
      style: CommonLaneStyle(constVariables.allPurchaseOrders.dispatchedColor, '#F8F8FA')
    },
    {
      id: 'lane5',
      title: constVariables.allPurchaseOrders.stocked,
      disallowAddingCard: true,
      cards: [],
      style: CommonLaneStyle(constVariables.allPurchaseOrders.stockedColor, '#F8F8FA')
    },
    {
      id: 'lane6',
      title: constVariables.allPurchaseOrders.staged,
      disallowAddingCard: true,
      cards: [],
      style: CommonLaneStyle(constVariables.allPurchaseOrders.stagedColor, '#F8F8FA')
    },
    {
      id: 'lane7',
      title: constVariables.allPurchaseOrders.uploading,
      disallowAddingCard: true,
      cards: [],
      style: CommonLaneStyle(constVariables.allPurchaseOrders.uploadingColor, '#F8F8FA')
    },
    {
      id: 'lane8',
      title: constVariables.allPurchaseOrders.stockTaking,
      disallowAddingCard: true,
      cards: [],
      style: CommonLaneStyle(constVariables.allPurchaseOrders.stockTakingColor, '#F8F8FA')
    }
  ]
};
