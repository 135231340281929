import { gql } from '@apollo/client';

export const UPDATE_LOW_STOCK_ALERT = gql`
  mutation UpdateLowStockAlert($input: UpdateLowStockAlertInput!) @api(name: "productsAPI") {
    updateLowStockAlert(input: $input) {
      id
      success
      message
      additionalData
    }
  }
`;
