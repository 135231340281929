import { gql } from '@apollo/client';

export const GET_ORDER_TIMELINE = gql`
  query getOrderTimeline($orderId: Int!) @api(name: "ordersAPI") {
    getOrderTimeline(orderId: $orderId) {
      id
      heading
      description
      time
      metaData
    }
  }
`;
