import { gql } from '@apollo/client';

export const GET_LIVE_SHOW_PRODUCTS = gql`
  query GetLiveShowProducts($id: String!) @api(name: "liveStreamingAPI") {
    getLiveShowProducts(id: $id) {
      id
      productId
      name
      imageUrl
      productReferenceId
      salePrice
      quantity
      noOfOrders
      noOfOrdersPaid
      revenue
      isLive
      isComplete
      isLowStockTriggred
      alertThreshold
    }
  }
`;
