import React from 'react';
import { CellProps } from 'react-datasheet-grid';

const CheckboxCell = React.memo(({ rowData, setRowData, columnData }: CellProps) => {
  const handleChange = (e) => {
    if (columnData.key === 'isFreeShipping') {
      const updatedRowdata = {
        ...rowData,
        [columnData.key]: e.target.checked,
        shippingPrice: e.target.checked ? 0 : rowData.shippingPrice
      };
      setRowData(updatedRowdata);
    } else {
      const updatedRowdata = { ...rowData, [columnData.key]: e.target.checked };
      setRowData(updatedRowdata);
    }
  };

  return (
    <div className="form-check form-check-sm form-check-custom">
      <input
        name="product-checkbox"
        className="form-check-input widget-9-check"
        type="checkbox"
        checked={rowData[columnData.key]}
        onChange={handleChange}
      />
    </div>
  );
});

export default CheckboxCell;
