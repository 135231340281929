import { FC, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Components
import CustomAddCardButton from 'src/components/oraganisms/AddCardButton/AddCardButton';

// Styles
import '../allPurchaseOrder.scss';

interface CustomHead {
  title?: string;
  cards?: [];
}
interface CommonStyleProps {
  borderTop: string;
  borderRadius: string;
  background: string;
  fontFamily: string;
  marginRight: string;
  height: string;
  minHeight: string;
}
export const CommonLaneStyle = (border: string, background: string): CommonStyleProps => {
  return {
    borderTop: `6px solid ${border}`,
    borderRadius: '5px',
    background: `${background}`,
    fontFamily: 'poppins-semibold',
    marginRight: '1.5rem',
    height: '97vh',
    minHeight: '97vh'
  };
};
interface AddMyCardLinkProps {
  onAdd: (...args: any) => void;
  onCancel: (...args: any) => void;
  landId?: string;
}

export const boardStyles = {
  background: '#fff',
  padding: 0,
  width: '100%',
  paddingBottom: '1rem'
};

// custom lane/column header
export const CustomLaneHeader: FC<CustomHead> = ({ title, cards }) => {
  return (
    <div>
      <header className="mb-2">
        <div className="d-flex align-items-center" style={{ fontSize: 14, fontWeight: 500 }}>
          <span>{title}</span>
          <span className="ms-2">({cards?.length})</span>
        </div>
      </header>
    </div>
  );
};

// temporary : add new card in a column
export const AddMyCard: FC<AddMyCardLinkProps> = ({ onAdd, onCancel, landId }) => {
  const handlerCard = () => {
    onAdd({
      id: uuidv4(),
      title: 'New Vendor XXX',
      logo: 'https://media.istockphoto.com/photos/3d-simple-rainbow-snapshot-camera-with-lens-on-pastel-background-with-picture-id1308083607?b=1&k=20&m=1308083607&s=170667a&w=0&h=5pcEUtjvmq7_PAmneHgxStCP9LgHuZeApznUxmrSDcI=',
      purchaseOrder: '232323',
      items: '50',
      price: '1900',
      ownerImg:
        'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YXZhdGFyfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
      ownerName: 'M. Peterson',
      date: '12/20/21'
    });
  };
  useEffect(() => {
    handlerCard();
  }, []);
  return <div />;
};

// default titles in react-trello
export const TRANSLATION_TABLE = {
  'Add another lane': '+ Add another lane',
  'Click to add card': <CustomAddCardButton />,
  'Delete lane': 'Delete lane',
  'Lane actions': 'Lane actions',
  button: {
    'Add lane': 'Add lane',
    'Add card': 'Add card',
    Cancel: 'Cancel'
  },
  placeholder: {
    title: 'title',
    description: 'description',
    label: 'label'
  }
};
