// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const OneSignal = window.OneSignal;
const setTag = async (key: string, value: string): Promise<boolean> => {
  if (value) {
    await OneSignal?.sendTag(key, value);
    return true;
  }
  return false;
};
const deleteTag = async (key: string): Promise<boolean> => {
  await OneSignal?.deleteTag(key);
  return true;
};

export const setStoreIdToOSPlayer = async (storeId: string): Promise<boolean> => setTag('storeId', storeId);
export const removeStoreIdToOSPlayer = async (): Promise<boolean> => deleteTag('storeId');
