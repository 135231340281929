import { gql } from '@apollo/client/core';

export const PRINT_LABELS = gql`
  query OnPrintLabels($input: PrintLabelsInput) @api(name: "productsAPI") {
    printLabels(input: $input) {
      message
      imageURL
    }
  }
`;
