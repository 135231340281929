import { FC, useCallback, useEffect, useState } from 'react';
import Barcode from 'react-barcode';

// Components
import { Img, Loader } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';
import { usePrintPackingSlip } from '../OrdersList/hooks/usePrintPackingSlip';

// Icons
import { DefaultImage } from 'src/assets/icons';

// Types
import { PageTemplateDataType, PageTemplateType } from './OrdersPrint.type';

// Styles
import './_ordersPrint.scss';

const OrdersPrint: FC = () => {
  const [orderIds, setOrderIds] = useState<number[]>([]);
  const [orderList, setOrderList] = useState<PageTemplateDataType[] | undefined>();
  const [firstTime, setFirstTime] = useState(true);
  const { getPackingSlipDetails, error, isPrintingPackingSlip } = usePrintPackingSlip([], () => {});

  useEffect(() => {
    const savedOrders = window.localStorage.getItem('savedOrders');
    if (savedOrders) {
      setOrderIds(JSON.parse(savedOrders));
    }
  }, []);

  const fetchData = useCallback(
    async (orderIds) => {
      const res = await getPackingSlipDetails(orderIds);
      setOrderList(res);
    },
    [orderIds]
  );

  useEffect(() => {
    if (orderIds?.length > 0) {
      fetchData(orderIds);
    }
  }, [orderIds]);

  useEffect(() => {
    if (orderList && firstTime) {
      setFirstTime(false);
      setTimeout(() => {
        window.print();
      }, 1000);
    }
  }, [orderList]);

  const renderLocation = (data: string) => {
    const myData: { warehouseId: number; warehouseName: string; warehouseLocation: string } = JSON.parse(data);

    return <div>{myData.warehouseLocation}</div>;
  };

  const PageTemplate: FC<PageTemplateType> = ({ order, startingPoint, endPoint }) => {
    return (
      <div className="pagesOrderPrint">
        <div className="headingContainerOrderPrint">
          <div className="orderIdsOrderPrint">Order #{order.orderCode}</div>
          {order.orderCode ? <Barcode width={0.5} height={25} value={order.orderCode} displayValue={false} margin={0} /> : null}
        </div>
        {order.noOfCombChildOrds > 0 && <div className="itemsAmountOrderPrint">Combined orders: {order.noOfCombChildOrds}</div>}
        <div className="itemsAmountOrderPrint">Items: {order.orderDetails.length}</div>
        <div className="nameOrderPrint">{order.customer}</div>
        <table className="theTable">
          <tbody>
            <tr className="theHeadingRow">
              <th className="borderRightOrderPrint theHeadingElements leftTableAlign">Product</th>
              <th className="borderRightOrderPrint theHeadingElements centerTableAlign">SKU</th>
              <th className="borderRightOrderPrint theHeadingElements centerTableAlign">Barcode</th>
              <th className="borderRightOrderPrint theHeadingElements centerTableAlign">Qty</th>
              <th className=" theHeadingElements centerTableAlign">Location</th>
            </tr>
            {order.orderDetails.slice(startingPoint, endPoint).map((orderDetailsData) => {
              return (
                <tr className="theContentRow" key={orderDetailsData.id}>
                  <td className="borderRightOrderPrint theContentElements leftTableAlign">
                    <div className="productNameImageContainer">
                      {orderDetailsData.imageUrl ? (
                        <Img
                          src={setImageSrc(
                            orderDetailsData?.imageUrl ? generateCompressedImageURL(orderDetailsData?.imageUrl, '50') : DefaultImage
                          )}
                          placeholderImg={DefaultImage}
                          errorImg={DefaultImage}
                          className="imageOrderPrint"
                        />
                      ) : (
                        <div className="imageOrderPrint bg-white" />
                      )}
                      <div>
                        {orderDetailsData.name}
                        {orderDetailsData?.variantTypes ? (
                          <>
                            {orderDetailsData?.variantTypes?.map(
                              (variantType) => `${variantType?.value ? ` / ${variantType?.value}` : ''}`
                            )}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </td>
                  <td className="borderRightOrderPrint theContentElements centerTableAlign">
                    {orderDetailsData.sku ? orderDetailsData.sku : '-'}
                  </td>
                  <td className="borderRightOrderPrint theContentElements centerTableAlign">
                    {orderDetailsData.code ? (
                      // <Barcode width={0.5} height={25} value={orderDetailsData.code} displayValue={false} margin={0} />
                      <div>{orderDetailsData.code}</div>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td className="borderRightOrderPrint theContentElements centerTableAlign">
                    {orderDetailsData.quantity ? orderDetailsData.quantity : '-'}
                  </td>
                  <td className=" theContentElements centerTableAlign">
                    {orderDetailsData.location ? renderLocation(orderDetailsData.location) : '-'}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  if (isPrintingPackingSlip) {
    return <Loader type="page" className="h-100" />;
  }
  if (error) {
    return (
      <div className={`d-flex flex-center`} style={{ width: '100%', height: '100%' }}>
        <p>Error in getting packing slip data</p>
      </div>
    );
  }

  // eslint-disable-next-line no-unreachable
  return (
    <div id="section-to-print" className="printPageContainerOrderPrint p-0">
      {orderList && !!orderList?.length
        ? orderList?.map((data) => {
            let howManyTimes: number[] = [];

            if (data.orderDetails.length > 6) {
              const thisMany = Math.ceil(data.orderDetails.length / 6);
              for (let i = 0; i < thisMany; i++) {
                howManyTimes = [...howManyTimes, i];
              }
            } else {
              howManyTimes = [1];
            }

            return howManyTimes.map((hmtData, index) => {
              const startingPoint = 0 + 6 * index;
              const endPoint = 6 + startingPoint;

              return <PageTemplate order={data} key={data.id} startingPoint={startingPoint} endPoint={endPoint} />;
            });
          })
        : null}
      {/* <div className="footerOrderPrint">footerOrderPrint</div> */}
      {/* <div className="headerOrderPrint">fheaderOrderPrint</div> */}
    </div>
  );
};

export default OrdersPrint;
