import { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_VENDORS } from 'src/apollo/queries';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { SearchSelectDropdown } from 'src/components/molecules';
import { AddVendor } from 'src/components/oraganisms';

// Types
import { VendorsProps } from './OrganizationSection.types';
import { UserRoles } from 'src/components/pages/settings/UserPermissions/UserPermissions.type';

const Vendors: FC<VendorsProps> = ({ productVendorID, setProductVendorID }) => {
  const history = useHistory();
  const [allVendors, setAllVendors] = useState([]);
  const [addVendorModal, setAddVendorModal] = useState(false);

  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  const input = {
    ids: [],
    pagination: {
      skipCount: 0,
      limitCount: 1000
    },
    status: 'ACTIVE',
    sort: {
      column: 'NAME',
      order: 'DESC'
    }
  };

  const { data, refetch } = useQuery(GET_VENDORS, {
    variables: {
      input
    },
    fetchPolicy: 'network-only',
    onError: (error) => {
      console.log('error', error);
    }
  });

  useEffect(() => {
    if (data?.getVendorDetails?.vendors) {
      setAllVendors(
        data?.getVendorDetails?.vendors?.map((item) => {
          return { id: item.id, name: item.name };
        })
      );
    }
  }, [data]);

  const handleAddItemLink = () => {
    // history.push({
    //   pathname: '/vendorManagement/vendors',
    //   state: {
    //     isAddModal: true
    //   }
    // });
    setAddVendorModal(true);
  };

  return (
    <>
      <SearchSelectDropdown
        type="vendor"
        options={allVendors}
        optionItemId={productVendorID}
        setOptionItemId={setProductVendorID}
        isControllable={false}
        handleAddItemLink={handleAddItemLink}
        isControllableDirection={true}
        className="vendor-search-dropdown"
        isDisableSelector={role === UserRoles.FULFILMENT_ASSOCIATE}
      />
      <AddVendor show={addVendorModal} resetState={refetch} data={null} />
    </>
  );
};

export default Vendors;
