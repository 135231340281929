export interface InventoryHistoryModalProps {
  show: boolean;
  closeModal: () => void;
}

export interface HistoryTableRowProps {
  rowData: any;
  editColumnData: {
    name: string;
    status: boolean;
  }[];
}

export interface Filters {
  adjustmentType: number[];
  adjustmentOwner: number[];
}

export interface OptionItem {
  id: number;
  name: string;
  value?: string;
}

export interface FilterMenuProps {
  show: boolean;
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  adjustmentTypes: OptionItem[];
  adjustmentUsers: OptionItem[];
  handleFilter: () => void;
  resetFilters: () => void;
}

export interface FilterMenuItemProps {
  title: string;
  type: string;
  options: OptionItem[];
  filterData: number[];
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
}

export interface IDateRange {
  startTime: Date;
  endTime: Date;
}

export interface FilterOptionDateItem {
  id: number;
  name: DateFilter;
}

export enum DateFilter {
  'Today' = 'Today',
  'Yesterday' = 'Yesterday',
  'Last week' = 'Last week',
  'Last month' = 'Last month',
  'Last year' = 'Last year',
  'Custom' = 'Custom'
}

export interface FilterMenuDropdownProps {
  isShowDropdown: boolean;
  data: FilterOptionDateItem[];
  selectedValue: FilterOptionDateItem;
  onSelect: (id: number) => void;
}
