import { FC } from 'react';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { DownArrowIcon } from 'src/assets/icons';

// Types
import { DropdownSelectorProps } from './DropdownSelector.types';

const DropdownSelector: FC<DropdownSelectorProps> = ({ className, onClick, selectedValue, text, type, isShowColor }) => {
  const whatColor = () => {
    if (selectedValue === 'Active') {
      return 'bg-success';
    } else if (selectedValue === 'Inactive') {
      return 'bg-danger';
    } else if (selectedValue === 'Scheduled') {
      return 'bg-warning';
    }
    return null;
  };

  return (
    <div className={`d-flex align-items-center m-auto justify-content-between w-100 cursor-pointer ${className}`} onClick={onClick}>
      <div
        className={`${isShowColor ? 'd-flex' : 'd-block'} align-items-center selected-value ${
          selectedValue && selectedValue !== text ? '' : 'placeholder'
        }`}
      >
        {selectedValue !== 'Select' && isShowColor && <div className={`h-10px w-10px rounded-circle me-2 ${whatColor()} `} />}
        {selectedValue ? selectedValue : text}
      </div>
      <div>
        <KTSVG path={DownArrowIcon} />
      </div>
    </div>
  );
};

export default DropdownSelector;
