import { FC, useEffect } from 'react';
import { useHistory } from 'react-router';

// Apollo
import { shippoConfigCode } from 'src/apollo/reactiveVariables';

// ConstVariables
import { ROUTES } from 'src/constants';

const ShippoRedirection: FC = () => {
  const history = useHistory();

  useEffect(() => {
    if (history.location.search.includes('code')) {
      const search = history.location.search;
      const code = new URLSearchParams(search).get('code') || '';
      shippoConfigCode(code);
      history.push(ROUTES.settings.shipping.main);
    }
  }, []);

  return <div></div>;
};

export default ShippoRedirection;
