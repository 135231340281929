import { FC } from 'react';

// Components
import { Img } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { numberWithCommas } from 'src/utils/numberWithCommas';

// Icons

// Types
import { AnalyticsCardProps } from './HomePage.type';

const AnalyticsCardData: FC<AnalyticsCardProps> = ({ value, comparedPercent, text, hasSign }) => {
  const renderPercentClassName = (percent: number | undefined) => {
    if (percent === undefined || percent === null) return '';
    if (percent > 0) {
      return 'positive-value';
    } else if (percent < 0) {
      return 'negative-value';
    } else {
      return '';
    }
  };

  const renderPercent = (percent: number | undefined) => {
    if (percent === undefined || percent === null) return 'N/A';
    if (percent > 0) {
      return `+${percent}%`;
    } else {
      return `${percent}%`;
    }
  };

  return (
    <div>
      <h2 className="poppins-semibold m-b-8">{numberWithCommas(value, hasSign)}</h2>
      <p className="mb-0">
        {text}{' '}
        <span className={`poppins-semibold ${renderPercentClassName(comparedPercent)}`}>{renderPercent(comparedPercent)}</span>
      </p>
    </div>
  );
};

export default AnalyticsCardData;
