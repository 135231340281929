import { FC } from 'react';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { DownArrowIcon, InfoIcon } from 'src/assets/icons';

// Types
import { CheckboxProps } from './Checkbox.types';

const Checkbox: FC<CheckboxProps> = ({
  tagclassname,
  inputclassname,
  labelclassname,
  defaultValue,
  name,
  value,
  normal,
  info,
  downArrow,
  downArrowClick,
  icon,
  addSvgIconClass,
  onChangeValue,
  smaller,
  disable,
  isRotateDownArrow,
  isReadOnly,
  noVerticalPadding
}) => {
  return (
    <label
      className={`d-flex align-items-center justify-content-center form-check form-check-inline ${
        !normal && `form-check-solid form-check-sm form-check-custom`
      } ${labelclassname} ${noVerticalPadding ? '' : 'py-2'}`}
      style={{ width: 'fit-content' }}
    >
      <input
        className={`form-check-input ${smaller ? 'h-20px w-20px smallerInput' : null}  ${inputclassname}`}
        name={name ? name.trim() : 'default'}
        type="checkbox"
        disabled={disable}
        defaultChecked={defaultValue}
        checked={value}
        onChange={onChangeValue}
        readOnly={isReadOnly}
      />
      {icon && <KTSVG className="ms-4" path={icon} addSvgIconClass={addSvgIconClass} />}
      {name && <span className={`${smaller ? 'fs-8' : null} ps-2 input-title mb-0 cursor-pointer ${tagclassname}`}>{name}</span>}

      {info && (
        <div className="ms-4">
          <KTSVG path={InfoIcon} />
        </div>
      )}

      {downArrow && (
        <div className="ms-2 cursor-pointer">
          <KTSVG path={DownArrowIcon} onClick={downArrowClick} className={isRotateDownArrow ? 'rotate-svg' : ''} />
        </div>
      )}
    </label>
  );
};

export default Checkbox;
