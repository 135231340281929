export const SortIds = [
  {
    id: 881,
    name: 'NAME'
  },
  {
    id: 882,
    name: 'TIMES_USED'
  }
];
