/* eslint-disable no-nested-ternary */
import { FC, useCallback, useEffect, useState } from 'react';
import moment from 'moment';

// Apollo
import { useMutation } from '@apollo/client';
import { ADD_LIVE_STREAM, EDIT_LIVE_STREAM } from 'src/apollo/mutations';

// Components
import CreateLiveShowPresentational from './CreateLiveShow.presentational';

// Hooks && Utils && Helpers
import { useToast } from 'src/utils/hooks/useToast';

// Types
import {
  LiveShowDetailInfo,
  LiveShowInfoError
} from 'src/components/oraganisms/CreateLiveShowDetailStep/CreateLiveShowDetailStep.types';
import { ProductItem } from 'src/components/molecules/LiveProductInfo/LiveProductInfoProps.types';
import { CreateLiveShowProps, liveEventEnum } from './CreateLiveShow.types';

const CreateLiveShow: FC<CreateLiveShowProps> = ({
  isEditing,
  resetValue,
  editLiveInfo,
  hideModal,
  setIsUpdated,
  defaultStartTime
}) => {
  // state params
  const { showToast } = useToast();
  const [liveShowInfo, setLiveShowInfo] = useState<LiveShowDetailInfo>({
    title: '',
    description: '',
    thumbnailUrl: {
      url: ''
    },
    date: new Date(),
    startingAt: undefined,
    endingAt: undefined,
    streamOnApp: true,
    streamOnWebsite: true,
    facebookChannels: [],
    products: [],
    notification: {
      textMessage: '',
      app: {
        title: '',
        description: ''
      },
      fb: {
        title: '',
        description: ''
      },
      messenger: {
        title: '',
        description: ''
      }
    },
    startDateMoment: '',
    endDateMoment: ''
  });
  const [liveShowInfoError, setLiveShowInfoError] = useState<LiveShowInfoError>({
    titleError: '',
    startingAtError: '',
    endingAtError: '',
    description: '',
    thumbnailUrl: '',
    timeDurationError: '',
    channelError: '',
    notificationTextMessageError: '',
    notificationAppTitleError: '',
    notificationMessengerError: '',
    facebookPostTitleError: '',
    facebookPostDescriptionError: ''
  });
  const [stepCount, setStepCount] = useState<number>(1);
  const [totalReachedStep, setTotalReachedStep] = useState<number>(1);
  const [bucketList, setBucketList] = useState<ProductItem[]>([]);
  const [isDataChanged, setDataChanged] = useState<boolean>(false);
  // notification params
  const [textMessageToggle, setTextMessageToggle] = useState<boolean>(liveShowInfo.streamOnApp);
  const [appNotificationToggle, setAppNotificationToggle] = useState<boolean>(liveShowInfo.streamOnApp);
  const [messangerToggle, setMessangerToggle] = useState<boolean>(liveShowInfo.streamOnApp);

  // API calls
  const [createLiveStream, { loading: addLoading }] = useMutation(ADD_LIVE_STREAM);
  const [updateLiveStream, { loading: editLoading }] = useMutation(EDIT_LIVE_STREAM);

  useEffect(() => {
    resetAll();
  }, []);

  useEffect(() => {
    if (editLiveInfo) {
      const updateEventObj = Object.assign({}, editLiveInfo);
      updateEventObj.date = new Date(editLiveInfo.startingAt);
      updateEventObj.startingAt = moment(new Date(editLiveInfo.startingAt)).format('hh:mmA');
      updateEventObj.endingAt = moment(new Date(editLiveInfo.endingAt)).format('hh:mmA');
      updateEventObj.products = editLiveInfo.products.map((product) => {
        return {
          productId: product.productId,
          productName: product.productName,
          productImg: product.productImage,
          productPrice: product.productPrice,
          liveProductId: product.liveProductId
        };
      });
      updateEventObj.thumbnailUrl = {
        url: editLiveInfo.thumbnailUrl
      };
      updateEventObj.facebookChannels = updateEventObj.facebookChannels.map((channel) => {
        return { id: channel.id, type: channel.type };
      });
      setLiveShowInfo(updateEventObj);
      if (editLiveInfo.notification.textMessage !== '') {
        setTextMessageToggle(true);
      }
      if (editLiveInfo.notification.app.title !== '') {
        setAppNotificationToggle(true);
      }
      if (editLiveInfo.notification.messenger.title !== '') {
        setMessangerToggle(true);
      }
      setStepCount(1);
      setTotalReachedStep(3);
      setTimeout(() => {
        setDataChanged(false);
      }, 500);
    }
  }, [editLiveInfo]);

  useEffect(() => {
    setTextMessageToggle(liveShowInfo.streamOnApp);
    setAppNotificationToggle(liveShowInfo.streamOnApp);
    setMessangerToggle(liveShowInfo.streamOnApp);
  }, [liveShowInfo.streamOnApp]);
  useEffect(() => {
    setLiveShowInfoError({
      ...liveShowInfoError,
      notificationTextMessageError: '',
      notificationAppTitleError: '',
      notificationMessengerError: ''
    });
  }, [textMessageToggle, appNotificationToggle, messangerToggle]);
  useEffect(() => {
    setDataChanged(true);
  }, [liveShowInfo]);

  const objCompare = (obj1, obj2): boolean => {
    let same = true;
    for (const [key, value] of Object.entries(obj1)) {
      if (typeof value === 'object') {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        same = objCompare(obj1[key], obj2[key]);
      } else {
        if (obj1[key] !== obj2[key]) same = false;
      }
    }

    return same;
  };
  const resetAll = () => {
    if (resetValue && !isEditing) {
      setLiveShowInfo({
        title: '',
        description: '',
        thumbnailUrl: {
          url: ''
        },
        startingAt: defaultStartTime ? moment(defaultStartTime).format('hh:mmA') : '',
        endingAt: defaultStartTime ? moment(defaultStartTime).add('30', 'minute').format('hh:mmA') : '',
        date: defaultStartTime ? moment(defaultStartTime).startOf('day').toDate() : new Date(),
        streamOnApp: true,
        streamOnWebsite: true,
        facebookChannels: [],
        products: [],
        notification: {
          textMessage: liveShowInfo.title,
          app: {
            title: liveShowInfo.title,
            description: ''
          },
          fb: {
            title: '',
            description: ''
          },
          messenger: {
            title: '',
            description: liveShowInfo.title
          }
        }
      });
      setLiveShowInfoError({
        titleError: '',
        description: '',
        thumbnailUrl: '',
        startingAtError: '',
        endingAtError: '',
        timeDurationError: '',
        channelError: '',
        notificationTextMessageError: '',
        notificationAppTitleError: '',
        notificationMessengerError: '',
        facebookPostTitleError: '',
        facebookPostDescriptionError: ''
      });
      setTextMessageToggle(liveShowInfo.streamOnApp);
      setAppNotificationToggle(liveShowInfo.streamOnApp);
      setMessangerToggle(liveShowInfo.streamOnApp);
      setStepCount(1);
      setTotalReachedStep(1);
    }
  };

  useEffect(() => {
    resetAll();
  }, [resetValue]);
  const getHours = useCallback(
    (startTimePeriod: 'AM' | 'PM', time: number) =>
      startTimePeriod === 'PM' ? (time !== 12 ? time + 12 : time) : time === 12 ? 0 : time,
    []
  );

  // Event handlers
  const firstStepValidated = (): boolean => {
    const updateErrorObj: LiveShowInfoError = Object.assign({}, liveShowInfoError);
    let validStatus = true;
    // check title information
    if (liveShowInfo.title.trim() !== '') {
      updateErrorObj.titleError = '';
    } else {
      updateErrorObj.titleError = 'Liveshow title can’t be left empty.';
      validStatus = false;
    }
    // check starting time
    if (liveShowInfo.startingAt && liveShowInfo.startingAt.slice(0, liveShowInfo.startingAt.length - 2) !== '') {
      updateErrorObj.startingAtError = '';
    } else {
      updateErrorObj.startingAtError = 'Start time is required.';
      validStatus = false;
    }
    // check starting time
    if (liveShowInfo.endingAt && liveShowInfo.endingAt.slice(0, liveShowInfo.endingAt.length - 2) !== '') {
      updateErrorObj.endingAtError = '';
    } else {
      updateErrorObj.endingAtError = 'End time is required.';
      validStatus = false;
    }
    // Check atleast one Streaming channels is checked
    if (liveShowInfo.streamOnApp || liveShowInfo.streamOnWebsite || liveShowInfo.facebookChannels) {
      updateErrorObj.channelError = '';
    } else {
      updateErrorObj.channelError = 'At least one streaming channel needs to be selected.';
      validStatus = false;
    }
    setLiveShowInfoError(updateErrorObj);
    return validStatus;
  };
  const validateNotifications = (): boolean => {
    if (textMessageToggle && liveShowInfo.notification.textMessage.trim() === '') {
      setLiveShowInfoError({ ...liveShowInfoError, notificationTextMessageError: "Text message can't be left empty." });
      return false;
    }
    if (appNotificationToggle && liveShowInfo.notification.app.title.trim() === '') {
      setLiveShowInfoError({
        ...liveShowInfoError,
        notificationTextMessageError: '',
        notificationAppTitleError: "Title can't be left empty."
      });
      return false;
    }
    if (liveShowInfo?.facebookChannels?.length > 0 && liveShowInfo.notification.fb.title.trim() === '') {
      setLiveShowInfoError({
        ...liveShowInfoError,
        facebookPostTitleError: "Title can't be left empty."
      });
      return false;
    }
    if (liveShowInfo?.facebookChannels?.length > 0 && liveShowInfo.notification.fb.description.trim() === '') {
      setLiveShowInfoError({
        ...liveShowInfoError,
        facebookPostDescriptionError: "Post Description can't be left empty."
      });
      return false;
    }
    if (messangerToggle && liveShowInfo.notification.messenger.description.trim() === '') {
      setLiveShowInfoError({
        ...liveShowInfoError,
        notificationTextMessageError: '',
        notificationAppTitleError: '',
        notificationMessengerError: "Messenger notification can't be left empty."
      });
      return false;
    } else {
      setLiveShowInfoError({
        ...liveShowInfoError,
        notificationTextMessageError: '',
        notificationAppTitleError: '',
        notificationMessengerError: ''
      });
      return true;
    }
  };
  const isError = (): boolean => {
    return liveShowInfoError.timeDurationError === '';
  };
  const updateStepCount = (updatedCount: number): void => {
    if (updatedCount < stepCount) {
      setStepCount(updatedCount);
      if (updatedCount > totalReachedStep) {
        setTotalReachedStep(updatedCount);
      }
    } else if ((updatedCount <= 3 && firstStepValidated() && isError()) || (updatedCount > 3 && validateNotifications())) {
      setLiveShowInfoError({
        titleError: '',
        description: '',
        thumbnailUrl: '',
        startingAtError: '',
        endingAtError: '',
        timeDurationError: '',
        channelError: '',
        notificationTextMessageError: '',
        notificationAppTitleError: '',
        notificationMessengerError: '',
        facebookPostTitleError: '',
        facebookPostDescriptionError: ''
      });
      if (updatedCount > 3) {
        const finalObj: any = Object.assign({}, liveShowInfo);

        // start date
        const startTime = liveShowInfo.startingAt.slice(0, liveShowInfo.startingAt.length - 2).split(':');
        const startTimePeriod = liveShowInfo.startingAt.slice(liveShowInfo.startingAt.length - 2, liveShowInfo.startingAt.length);
        const startDate = new Date(liveShowInfo.date);
        startDate.setHours(getHours(startTimePeriod, +startTime[0]));
        startDate.setMinutes(Number(startTime[1]));
        finalObj.startingAt = moment(startDate).local().format();
        // finalObj.startingAt = moment(finalObj.startDateMoment).local().format();

        // end date
        const endTime = liveShowInfo.endingAt.slice(0, liveShowInfo.endingAt.length - 2).split(':');
        const endTimePeriod = liveShowInfo.endingAt.slice(liveShowInfo.endingAt.length - 2, liveShowInfo.endingAt.length);
        const endDate = new Date(liveShowInfo.date);
        endDate.setHours(getHours(endTimePeriod, +endTime[0]));
        endDate.setMinutes(Number(endTime[1]));
        finalObj.endingAt = moment(endDate).local().format();
        // finalObj.endingAt = moment(finalObj.endDateMoment).local().format();

        if (!textMessageToggle) {
          finalObj.notification.textMessage = '';
        }
        if (!appNotificationToggle) {
          finalObj.notification.app = { title: '', description: '' };
        }
        if (!messangerToggle) {
          finalObj.notification.messenger = {
            title: '',
            description: ''
          };
        }

        let count = 100;
        finalObj.products = finalObj.products.map((product) => {
          count++;
          return {
            productId: product.productId,
            productReferencedId: count
          };
        });

        delete finalObj.date;

        finalObj.liveEventType = liveEventEnum.SCHEDULED;
        finalObj.thumbnailUrl = liveShowInfo.thumbnailUrl.url;

        delete finalObj.startDateMoment;
        delete finalObj.endDateMoment;

        createLiveStream({
          variables: {
            input: finalObj
          }
        })
          .then((res) => {
            if (res.data.addLiveStream) {
              showToast({
                successText: `<span style="color:black;">Liveshow<span> <span style="color:#50cd89;">successfully created!</span>`,
                message: `New liveshow text has been created.`
              });
              hideModal();
              setIsUpdated();
            } else {
              showToast({
                errorText: 'Error in create liveshow',
                message: 'Something is wrong while creating liveshow'
              });
            }
          })
          .catch((err) => {
            console.error('Creat LiveStream Error : ', err);
            showToast({
              errorText: 'Error in create liveshow',
              message: 'Something is wrong while creating liveshow'
            });
          });
      } else {
        setStepCount(updatedCount);
        if (updatedCount > totalReachedStep) {
          setTotalReachedStep(updatedCount);
        }
      }
    }
  };
  const editEvent = (): void => {
    if (firstStepValidated() && validateNotifications() && isError()) {
      const finalObj: any = { ...liveShowInfo };
      // start date
      const startTime = liveShowInfo.startingAt.slice(0, liveShowInfo.startingAt.length - 2).split(':');
      const startTimePeriod = liveShowInfo.startingAt.slice(liveShowInfo.startingAt.length - 2, liveShowInfo.startingAt.length);
      const startDate = new Date(liveShowInfo.date);
      startDate.setHours(getHours(startTimePeriod, +startTime[0]));
      startDate.setMinutes(Number(startTime[1]));
      finalObj.startingAt = moment(startDate).local().format();

      // end date
      const endTime = liveShowInfo.endingAt.slice(0, liveShowInfo.endingAt.length - 2).split(':');
      const endTimePeriod = liveShowInfo.endingAt.slice(liveShowInfo.endingAt.length - 2, liveShowInfo.endingAt.length);
      const endDate = new Date(liveShowInfo.date);
      endDate.setHours(getHours(endTimePeriod, +endTime[0]));
      endDate.setMinutes(Number(endTime[1]));

      finalObj.endingAt = moment(endDate).local().format();
      let count = 100;
      finalObj.products = finalObj.products.map((product) => {
        count += 1;
        return {
          productId: product.productId,
          productReferencedId: count
        };
      });

      delete finalObj.isComplete;
      delete finalObj.date;
      delete finalObj.viewersCount;
      delete finalObj.startedAt;
      delete finalObj.endedAt;
      delete finalObj.startDateMoment;
      delete finalObj.endDateMoment;
      delete finalObj.__typename;
      const notification = Object.assign({}, finalObj.notification);
      delete notification.id;
      finalObj.notification = notification;
      finalObj.thumbnailUrl = liveShowInfo.thumbnailUrl.url;

      updateLiveStream({
        variables: {
          input: finalObj
        }
      })
        .then((res) => {
          if (res.data) {
            showToast({
              successText: 'Liveshow successfully updated!',
              message: `New liveshow “${finalObj.title}” has been updated.`
            });
            hideModal();
            setIsUpdated();
          }
        })
        .catch((err) => {
          console.error('Edit LiveStream Error : ', err);
          showToast({
            errorText: 'Error in update liveshow',
            message: 'Something is wrong while updating liveshow'
          });
        });
    }
  };
  const addSelectedProducts = (): void => {
    const updatedData: LiveShowDetailInfo = Object.assign({}, liveShowInfo);
    const existingProductIds = updatedData.products.map((product) => product.productId);
    const newList: ProductItem[] = updatedData.products;
    bucketList.map((item) => {
      if (!existingProductIds.includes(item.productId)) {
        newList.push(item);
      }
      return item;
    });
    updatedData.products = newList;
    setLiveShowInfo(updatedData);
    setBucketList([]);
  };
  const manageBucketList = (data: any): void => {
    setBucketList(data);
  };

  const manageProductList = (data: ProductItem[]) => {
    const updatedData: LiveShowDetailInfo = Object.assign({}, liveShowInfo);
    const existingProductIds = updatedData.products.map((product) => product.productId);
    const toBeRemoveIds = data.map((product) => product.productId);
    const newList: ProductItem[] = updatedData.products;
    existingProductIds.map((newItem) => {
      if (!toBeRemoveIds.includes(newItem)) {
        newList.splice(
          newList.findIndex((item) => item.productId === newItem),
          1
        );
      }
      return newItem;
    });
    data.map((item) => {
      if (!existingProductIds.includes(item.productId)) {
        newList.push(item);
      }
      return item;
    });
    updatedData.products = newList;
    setLiveShowInfo(updatedData);
  };

  const updateLiveShowInfo = (value) => {
    setLiveShowInfo((liveShowInfo) => ({ ...liveShowInfo, ...value }));
  };

  return (
    <CreateLiveShowPresentational
      loading={addLoading || editLoading}
      reachedStep={totalReachedStep}
      isDataChanged={isDataChanged}
      isEditing={isEditing}
      liveShowInfo={liveShowInfo}
      liveShowInfoError={liveShowInfoError}
      stepCount={stepCount}
      updateStepCount={updateStepCount}
      editEvent={editEvent}
      updateLiveShowInfo={updateLiveShowInfo}
      cancelBtnHandler={hideModal}
      addSelectedProducts={addSelectedProducts}
      bucketList={bucketList}
      updateBucketList={manageBucketList}
      updateProductList={manageProductList}
      updateLiveShowInfoErrors={setLiveShowInfoError}
      textMessageToggle={textMessageToggle}
      setTextMessageToggle={setTextMessageToggle}
      appNotificationToggle={appNotificationToggle}
      setAppNotificationToggle={setAppNotificationToggle}
      messangerToggle={messangerToggle}
      setMessangerToggle={setMessangerToggle}
    />
  );
};

export default CreateLiveShow;
