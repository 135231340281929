import axios from 'axios';
import { facebookAuthConfig } from 'src/apollo/reactiveVariables';
import { IFacebookConfig } from 'src/types/FacebookConfig.types';

export const login = async (): Promise<any> => {
  // login with facebook then authenticate with the API to get a JWT auth token
  return await new Promise((resolve, reject) => {
    window.FB?.login(
      (response) => {
        facebookAuthConfig(response.authResponse as IFacebookConfig);
        resolve(response);
      },
      {
        scope:
          'publish_video, pages_show_list, business_management, pages_read_engagement, pages_manage_metadata, pages_read_user_content, pages_manage_posts, pages_manage_engagement, public_profile, pages_messaging'
      }
    );
  });
};

export const apiAuthenticate = async (accessToken: string): Promise<any> => {
  // authenticate with the api using a facebook access token,
  // on success the api returns an account object with a JWT auth token
  return axios.get(`https://graph.facebook.com/v8.0/me?access_token=${accessToken}`).then((response) => {
    const { data } = response;
    return data;
    // if (data.error) return unauthorized(data.error.message);
  });
};

export const getUser = (accessToken?: string): Promise<any> => {
  // get logged in facebook User information to show on Page
  return axios.get(`https://graph.facebook.com/v8.0/me?access_token=${accessToken}`).then((response) => {
    const { data } = response;
    return data;
  });
};

export const logout = async (): Promise<any> => {
  // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
  window.FB.api('/me/permissions', 'delete', {}, () => window.FB.logout());
  facebookAuthConfig(undefined);
};

// export const getAll = (): any => {
//     return axios.get(baseUrl).then((response) => response.data);
// };
// export const getById = (id: string): any => {
//     return axios.get(`${baseUrl}/${id}`).then((response) => response.data);
// };

// // helper methods
// let authenticateTimeout;
// function startAuthenticateTimer() {
//     if (accountSubject?.value) {
//         const value = accountSubject.value;
//         // parse json object from base64 encoded jwt token
//         const jwtToken = JSON.parse(atob(value.token.split('.')[1]));
//         // set a timeout to re-authenticate with the api one minute before the token expires
//         const expires = new Date(jwtToken.exp * 1000);
//         const timeout = expires.getTime() - Date.now() - 60 * 1000;
//         const { accessToken } = window.FB.getAuthResponse();
//         authenticateTimeout = setTimeout(() => apiAuthenticate(accessToken), timeout);
//     }
// }
// function stopAuthenticateTimer() {
//     // cancel timer for re-authenticating with the api
//     clearTimeout(authenticateTimeout);
// }
