import { FC, useState } from 'react';
import { format, parseISO } from 'date-fns';

// Components
import IvsPlayer from 'src/components/pages/liveshow/LiveshowManager/components/IvsPlayer';

// Types
import { RecordingData } from 'src/components/pages/products/AddProduct/AddProduct.types';

interface RecordingSlideItemProps {
  recording: RecordingData;
  currentSlide: number;
}

const RecordingSlideItem: FC<RecordingSlideItemProps> = ({ recording, currentSlide }) => {
  const [isShowOverlay, setIsShowOverlay] = useState(false);
  return (
    <div
      className="w-100 h-100 position-relative"
      onMouseEnter={() => setIsShowOverlay(true)}
      onMouseLeave={() => setIsShowOverlay(false)}
    >
      {isShowOverlay && (
        <div className="position-absolute w-100 text-left recording-overlay">
          <p>{recording.title}</p>
          <p>{format(parseISO(recording.createdAt), 'MM/dd/yy')}</p>
        </div>
      )}
      <IvsPlayer playbackUrl={recording?.url ? recording?.url : ''} showControls currentSlide={currentSlide} />
    </div>
  );
};

export default RecordingSlideItem;
