import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import OutsideClickHandler from 'react-outside-click-handler';
import clsx from 'clsx';

// Components
import { Img, Loader } from 'src/components/atoms';
import { AddVendor, ScreenHeader } from 'src/components/oraganisms';
import VendorAllProducts from '../VendorAllProducts/VendorAllProducts';
import DeleteNotePopup from './DeleteNotePopup';
import EndRelationshipPopup from './EndRelationshipPopup';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';
import getDeviceData from 'src/utils/UseDevice/useDevice';

// Icons
import { TrashIcon, ProfileUserIcon, ReminderIcon, PencilIcon, DefaultImage } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Styles
import './vendorDetails.scss';

export type PageLink = {
  title: string;
  path: string;
  isActive: boolean;
  isSeparator?: boolean;
};

interface VendorDetailsPresentationalProps {
  vendorId: number;
  isStatusModal: boolean;
  isDeleteModal: boolean;
  vendorNotesData: any;
  currentVendor: any;
  addNewNote: string;
  breadCrumbs?: Array<PageLink>;
  showVendorDetails: boolean;
  selectedTab: string;
  refetchList: boolean;
  setSelectedTab?: React.Dispatch<React.SetStateAction<string>>;
  editDetails(): any;
  handleAddNewNote(e: string): void;
  handelSendNotes(e: React.MouseEvent): void;
  handleDeleteNote(vendorId: number, vendorNote: string, e: React.MouseEvent): any;
  handleUpdatedNote(editedNotes: any, noteId: number): any;
  handleStatus(): any;
  handleConfirmEnd(e: React.MouseEvent): any;
  handleConfirmDelete(e: React.MouseEvent): any;
  closeModal(): any;
  updateVendorDetails(): any;
  loading: boolean;
}

const VendorDetailsPresentational: FC<VendorDetailsPresentationalProps> = (props) => {
  const deviceName = getDeviceData();
  const {
    currentVendor,
    isStatusModal,
    isDeleteModal,
    vendorNotesData,
    addNewNote,
    breadCrumbs,
    showVendorDetails,
    selectedTab,
    refetchList,
    handleAddNewNote,
    setSelectedTab,
    editDetails,
    handelSendNotes,
    handleDeleteNote,
    handleUpdatedNote,
    handleStatus,
    handleConfirmEnd,
    handleConfirmDelete,
    closeModal,
    updateVendorDetails,
    loading
  } = props;

  const [getNotesId, setNotesId] = useState(null);
  const [editText, setEditText] = useState('');
  const [textFocus, setTextFocus] = useState(true);
  const [newNote, setNewNote] = useState(addNewNote);
  const [overallNotes, setOverallNotes] = useState(vendorNotesData);

  useEffect(() => {
    setNewNote(addNewNote);
    setTextFocus(true);
  }, [addNewNote]);

  useEffect(() => {
    setOverallNotes(vendorNotesData);
  }, [vendorNotesData]);

  const handleEditNote = (id: any) => {
    setNotesId(id);
  };
  const handleCancelNote = () => setNotesId(null);

  const getText = (e: any) => {
    setEditText(e.target.value);
  };

  const handleSaveNote = (data: any, notesId: any) => {
    const editedNotes = {
      imageUrl: data.imageUrl,
      note: editText,
      updatedAt: data.updatedAt,
      userName: data.userName,
      __typename: data.__typename
    };
    const newState = [...overallNotes?.getVendorNote];
    newState[notesId] = editedNotes;
    setOverallNotes({ getVendorNote: newState });
    handleUpdatedNote(editedNotes?.note, data?.id);
    setEditText('');
    setNotesId(null);
  };
  const onEnterSaveNote = (e: any) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      if (addNewNote !== '') {
        handelSendNotes(e);
      }
    }
  };

  const handleBlur = () => {
    setTextFocus(false);
  };

  const handleFocus = () => {
    setTextFocus(true);
  };

  const marketLocation = currentVendor?.extraInfo?.filter((row: any) => row.type === 'LOCATION');
  const saleInfo = currentVendor?.extraInfo?.filter((row: any) => row.type === 'SALE_INFO');
  const otherDetails = currentVendor?.extraInfo?.filter((row: any) => row.type === 'OTHERS');

  return (
    <div className="vendorManagement">
      <ScreenHeader
        breadCrumbsList={breadCrumbs}
        headerTitle={currentVendor?.name || constVariables.VendorDetails.details.vendorName}
        headerButtonText={constVariables.VendorDetails.details.editDetails}
        headerButtonClick={() => editDetails()}
        selectedTab={selectedTab}
        editColumnText="Edit Column"
        setSelectedTab={setSelectedTab}
        tabList={constVariables.TabForVendorsDetails}
        removeExportImport={true}
        onlyImport={selectedTab === constVariables.Tabs.allProduct || selectedTab === constVariables.Tabs.selectedProduct}
      />
      <AddVendor show={showVendorDetails} resetState={editDetails} data={currentVendor} updateCurrentVendor={updateVendorDetails} />
      {loading ? (
        <Loader type="page" />
      ) : (
        selectedTab.toUpperCase() === constVariables.Tabs.details &&
        currentVendor && (
          <div>
            <div className="d-flex justify-content-between">
              <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                <Img
                  className="vendorPic"
                  src={setImageSrc(currentVendor?.profileUrl ? currentVendor?.profileUrl : DefaultImage)}
                  placeholderImg={DefaultImage}
                  errorImg={DefaultImage}
                />

                <div className="row mt-5">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-5">
                    <h6>{constVariables.VendorDetails.details.generalInformation}</h6>
                    <br />
                    <a className="links" href={`//${currentVendor?.websiteUrl}`} target="_blank" rel="noreferrer">
                      {currentVendor?.websiteUrl}
                    </a>
                    <p className="category">
                      {constVariables.VendorDetails.details.category}
                      <span className="categoryTypes"> {currentVendor?.categoryDetails?.category?.name} </span>
                    </p>
                    <p className="category">
                      {constVariables.VendorDetails.details.subCategory}
                      {currentVendor?.categoryDetails?.subCategory?.map((subCategory: any, index: number) => {
                        return (
                          <span key={index} className="subCategoryTypes">
                            {subCategory?.name}
                          </span>
                        );
                      })}
                    </p>
                  </div>
                  {currentVendor?.vendorRep &&
                    currentVendor?.vendorRep[0]?.name &&
                    (currentVendor?.vendorRep[0]?.phoneNo || currentVendor?.vendorRep[0]?.email) && (
                      <div className="col-12 col-sm-12 col-md-3 col-lg-4">
                        <h6>{constVariables.VendorDetails.details.contactInformation}</h6>
                        <br />
                        <p>{currentVendor?.vendorRep[0]?.name}</p>
                        <p className="links">{currentVendor?.vendorRep[0]?.email}</p>
                        <p>{currentVendor?.vendorRep[0]?.phoneNo}</p>
                      </div>
                    )}
                  {saleInfo?.length !== 0 && (
                    <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                      <h6>{constVariables.VendorDetails.details.saleInformation}</h6>
                      <br />
                      {saleInfo?.map((saleInfo: any) => {
                        return <p>{saleInfo?.name}</p>;
                      })}
                    </div>
                  )}
                </div>
                <div className="row mt-19">
                  {currentVendor?.address && (
                    <div className="col-12 col-sm-12 col-md-6 col-lg-5">
                      <h6>{constVariables.VendorDetails.details.address}</h6>
                      <p>{currentVendor?.address}</p>
                      <h6>{constVariables.VendorDetails.details.market}</h6>
                      {marketLocation.map((marketLocation: any) => {
                        return <p>{marketLocation?.name}</p>;
                      })}
                    </div>
                  )}
                  {otherDetails?.length !== 0 && (
                    <div className="col-12 col-sm-12 col-md-3 col-lg-4">
                      <h6>{constVariables.VendorDetails.details.other}</h6>
                      {otherDetails?.map((otherDetails: any, index: number) => {
                        return otherDetails?.name.includes('www.') ||
                          otherDetails?.name.includes('https://') ||
                          otherDetails?.name.includes('http://') ? (
                          <div key={index}>
                            <a href={`//${otherDetails?.name}`} target="_blank" rel="noreferrer">
                              {otherDetails?.name}
                            </a>
                          </div>
                        ) : (
                          <p>{otherDetails?.name}</p>
                        );
                      })}
                    </div>
                  )}
                  <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                    <h6>{constVariables.VendorDetails.details.startDate}</h6>
                    <p>{constVariables.VendorDetails.details.tempDate}</p>
                    <button
                      type="button"
                      className={
                        currentVendor?.status === 'ARCHIVE'
                          ? 'btn btn-light-success fs-6 font-weight-bold mr-2'
                          : 'btn endBtn btn-outline-danger'
                      }
                      onClick={handleStatus}
                    >
                      {currentVendor?.status === 'ARCHIVE' ? 'Restart Relationship' : 'End Relationship'}
                    </button>
                  </div>
                </div>
              </div>
              <div className={`notesContainerBox ${deviceName.isWeb ? '' : 'w-365px'}`}>
                <div className="m-4 d-flex justify-content-start align-items-start">
                  <img src={ReminderIcon} alt={vendorNotesData?.userName} />
                  <h5 className="mx-3 mt-1 poppins-regular">{constVariables?.VendorDetails?.notes?.noteTitle}</h5>
                </div>
                <div className="w-100">
                  <hr className="lineBetween w-100" />
                </div>
                <div className="scrollNotes" id="scrollNotes" style={{ width: '100%' }}>
                  {overallNotes?.getVendorNote.map((vendor: any, id: any) => {
                    if (getNotesId === id) {
                      return (
                        <div
                          key={id}
                          className="mx-5"
                          style={{
                            position: 'relative'
                          }}
                        >
                          <div className="detailsCls">
                            <Img
                              src={setImageSrc(vendor.imageUrl ? vendor.imageUrl : ProfileUserIcon)}
                              placeholderImg={DefaultImage}
                              errorImg={DefaultImage}
                              className="profileUserCls border border-light"
                            />
                            <label>{vendor.userName}</label>
                          </div>
                          <textarea
                            className="editTextInput noresize"
                            defaultValue={editText !== '' ? editText : vendor.note}
                            onChange={(e) => getText(e)}
                          />
                          <div className="actionEditCls">
                            <button type="button" className="btn btn-outlined-secondary" onClick={() => handleCancelNote()}>
                              {constVariables.VendorDetails.details.cancel}
                            </button>
                            <button type="button" className="btn btn-primary" onClick={() => handleSaveNote(vendor, id)}>
                              {constVariables.VendorDetails.details.save}
                            </button>
                          </div>
                          <hr className="lineBetween text-black-50" />
                        </div>
                      );
                    } else {
                      return (
                        <div key={id} className="mx-5">
                          <div className="m-b-20 d-flex justify-content-between">
                            <div className="inline">
                              <img
                                src={setImageSrc(vendor.imageUrl ? vendor.imageUrl : ProfileUserIcon)}
                                alt={DefaultImage}
                                className="profileUserCls border border-light"
                              />
                              <label>{vendor.userName}</label>
                            </div>
                            <div>
                              {' '}
                              <button
                                type="button"
                                className="btn editBtn btn-outlined-secondary"
                                onClick={() => handleEditNote(id)}
                              >
                                <img src={PencilIcon} alt={vendor.userName} className="editsIcon" />
                                <span className="6 fw-light">{constVariables.VendorDetails.details.edit}</span>
                              </button>
                              <button
                                type="button"
                                className="btn trashBtn btn-outlined-secondary"
                                onClick={(e) => handleDeleteNote(vendor.id, vendor.note, e)}
                              >
                                <img src={TrashIcon} alt={vendor.userName} className="editsIcon" />
                                <span className="6 fw-light">{constVariables.VendorDetails.details.delete}</span>
                              </button>
                            </div>
                          </div>
                          <p className="text-muted fs-5">{vendor.note}</p>
                          <p className="text-gray-800 fw-bold">
                            {constVariables.VendorDetails.notes.updated}
                            <span className="text-gray-500">
                              {moment(vendor.updatedAt).format('ll')} at {moment(vendor.updatedAt).format('LT').toLocaleLowerCase()}
                            </span>
                          </p>
                          {id !== overallNotes?.getVendorNote.length - 1 && <hr className="lineBetween text-black-50" />}
                        </div>
                      );
                    }
                  })}
                </div>
                <hr className="text-black-50" />
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setTextFocus(true);
                  }}
                >
                  <div className="m-5 d-flex align-items-end">
                    <textarea
                      id="kt_autosize_2"
                      placeholder={constVariables.VendorDetails.details.placeHolder}
                      className={clsx('createNotesInput noresize', {
                        vendorNoteText: textFocus === false
                      })}
                      onChange={(e) => {
                        handleAddNewNote(e.target.value.trimStart());
                      }}
                      onKeyDown={onEnterSaveNote}
                      value={newNote}
                      onBlur={handleBlur}
                      onFocus={handleFocus}
                    />
                    <button disabled={!newNote} type="button" className="btn btn-primary" onClick={handelSendNotes}>
                      {constVariables.VendorDetails.details.save}
                    </button>
                  </div>
                </OutsideClickHandler>
              </div>
            </div>
          </div>
        )
      )}
      {(selectedTab.toUpperCase() === constVariables.Tabs.allProduct ||
        selectedTab.toUpperCase() === constVariables.Tabs.selectedProduct) && (
        <VendorAllProducts selectedTab={selectedTab} refetchList={refetchList} />
      )}
      {isStatusModal && (
        <EndRelationshipPopup
          title={constVariables.VendorDetails.endRelationship.title}
          message={constVariables.VendorDetails.endRelationship.message}
          cancelButton={constVariables.VendorDetails.endRelationship.cancel}
          confirmButton={constVariables.VendorDetails.endRelationship.confirm}
          handleConfirmEnd={handleConfirmEnd}
          open={isStatusModal}
          closeModal={closeModal}
        />
      )}
      {isDeleteModal && (
        <DeleteNotePopup
          title={constVariables.VendorDetails.deleteNote.title}
          message={constVariables.VendorDetails.deleteNote.message}
          cancelButton={constVariables.VendorDetails.deleteNote.cancel}
          confirmButton={constVariables.VendorDetails.deleteNote.confirm}
          handleConfirmDelete={handleConfirmDelete}
          open={isDeleteModal}
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default React.memo(VendorDetailsPresentational);
