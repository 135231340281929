import { useApolloClient } from '@apollo/client/react/hooks/useApolloClient';
import { useEffect } from 'react';

const CHANNEL_NAME = 'apollo-cahce';
const PRODUCT_UPDATED_MESSAGE = 'product-cache-updated';
const COUPON_UPDATED_MESSAGE = 'coupon-cache-updated';

const useApolloBroadcaster = () => {
  const client = useApolloClient();
  useEffect(() => {
    const broadcast = new BroadcastChannel(CHANNEL_NAME);

    broadcast.onmessage = (event) => {
      if (event?.data?.type === PRODUCT_UPDATED_MESSAGE) {
        client.cache.modify({
          id: event?.data?.id,
          fields: {
            variantLocationDetails() {
              return event?.data?.data?.variantLocationDetails;
            },
            productSku() {
              return event?.data?.data?.productSku;
            },
            productBarcode() {
              return event?.data?.data?.productBarcode;
            },
            productName() {
              return event?.data?.data?.productName;
            },
            productQty() {
              return event?.data?.data?.productQty;
            },
            salePrice() {
              return event?.data?.data?.salePrice;
            },
            productMediasJson() {
              return event?.data?.data?.productMediasJson;
            }
          },
          broadcast: true
        });
      }

      if (event?.data?.type === COUPON_UPDATED_MESSAGE) {
        client.cache.modify({
          id: event?.data?.data?.id,
          fields: {
            status() {
              return event?.data?.data?.status;
            }
          },
          broadcast: true
        });
      }
    };

    return () => {
      broadcast.close();
    };
  }, []);
};

const sendCahceUpdatedBroadcast = (data, type) => {
  const broadcast = new BroadcastChannel(CHANNEL_NAME);
  broadcast.postMessage({ type, ...data });
  broadcast.close();
};

export { useApolloBroadcaster, sendCahceUpdatedBroadcast };
