import { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_SHOPIFY_ADMIN_BASE_URL } from 'src/apollo/queries';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import { ShopifySyncTooltip } from 'src/components/molecules';
import StatusCell from 'src/components/pages/collections/ProductCategory/components/StatusCell';
import ActionCell from 'src/components/pages/collections/ProductCategory/components/ActionCell';
import TableCell from '../components/TableCell';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import { DefaultImage, RightArrowIcon, ShopifyIcon } from 'src/assets/icons';

interface CollectionDetailsRowProps {
  collection: any;
  columnOptions: any[];
  rowWidth: number;
  handleUpdateTreeData?: (updatedCollection) => void;
  handleCollectionActionMenus?: (optionId: number, collectionName: string, collectionId: string | number) => void;
}

const CollectionDetailsRow: FC<CollectionDetailsRowProps> = ({
  collection,
  columnOptions,
  rowWidth,
  handleUpdateTreeData,
  handleCollectionActionMenus
}) => {
  const { data: { shopifyAdminBaseUrl } = { shopifyAdminBaseUrl: null } } = useQuery(GET_SHOPIFY_ADMIN_BASE_URL);

  const goToShopifyPage = (shopifyCollectionId) => {
    if (shopifyAdminBaseUrl) window.open(`${shopifyAdminBaseUrl}collections/${shopifyCollectionId}`, '_blank');
  };

  const renderColumn = (collection, column) => {
    switch (column.dataKey) {
      case 'collection':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <FlexBox className="align-items-center d-inline-block name-cell" style={{ marginLeft: `${collection.level * 64}px` }}>
              <button
                className={`bg-transparent border border-transparent w-25px ${
                  collection.children && collection.children.length > 0 ? '' : 'opacity-0'
                }`}
              >
                <img src={RightArrowIcon} className={`h-10px w-10px rotatedArrowIcon`} />
              </button>
              <div className="symbol me-5 ms-5">
                <Img
                  src={setImageSrc(
                    collection.thumbnailUrl ? generateCompressedImageURL(collection.thumbnailUrl, '260') : DefaultImage
                  )}
                  placeholderImg={DefaultImage}
                  errorImg={DefaultImage}
                  className="object-fit-scale-down bg-white border border-light border-2"
                />
                {collection.externalCollectionProvider === 'SHOPIFY' && (
                  <>
                    <img
                      src={ShopifyIcon}
                      className="cursor-pointer ms-1 shopify-icon position-absolute"
                      data-tip
                      data-for="shopifySyncIcon"
                      onClick={() => goToShopifyPage(collection?.externalCollectionId)}
                    />
                    <ShopifySyncTooltip tooltipText="Collection from Shopify" />
                  </>
                )}
              </div>
              <FlexBox className="justify-content-start flex-column d-inline-block collectionName">
                <Link to={`/products/collections/edit/${collection.id}`} className="main-cell">
                  <span className="d-block">{collection.text}</span>
                </Link>
              </FlexBox>
            </FlexBox>
          </TableCell>
        );
      case 'products':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <div>{collection.productCount ? collection.productCount : 0}</div>
          </TableCell>
        );
      case 'collectionType':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <div>{collection?.type?.toLowerCase()}</div>
          </TableCell>
        );
      case 'status':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <StatusCell id={collection.id} status={collection.status} updateTreeData={handleUpdateTreeData} />
          </TableCell>
        );
      case 'action':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <ActionCell id={collection.id} name={collection.text} handleMenuOptions={handleCollectionActionMenus} />
          </TableCell>
        );
      default:
        return;
    }
  };

  return (
    <>
      {collection?.children?.map((childItem, index) => (
        <Fragment key={index}>
          <div
            style={{
              height: 70,
              display: 'flex',
              alignItems: 'center',
              width: rowWidth
            }}
            className="ReactVirtualized__Table__row"
          >
            {columnOptions?.map((column, columnIndex) => {
              return <Fragment key={columnIndex}>{renderColumn(childItem, column)}</Fragment>;
            })}
          </div>
          {childItem?.children ? (
            <>
              {childItem?.children?.map((subChildItem, subChildIndex) => (
                <div
                  style={{
                    height: 70,
                    display: 'flex',
                    alignItems: 'center',
                    width: rowWidth
                  }}
                  key={subChildIndex}
                  className="ReactVirtualized__Table__row"
                >
                  {columnOptions?.map((column, subChildColumnIndex) => {
                    return <Fragment key={subChildColumnIndex}>{renderColumn(subChildItem, column)}</Fragment>;
                  })}
                </div>
              ))}
            </>
          ) : null}
        </Fragment>
      ))}
    </>
  );
};

export default CollectionDetailsRow;
