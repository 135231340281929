import { CombinedOrderDetail, orderDetailsData } from '../OrdersList.types';

export const mapCombinedOrderToOrder = (res: { getCombineOrderDetail: CombinedOrderDetail }): orderDetailsData => {
  const cOrdrDetail: CombinedOrderDetail = res?.getCombineOrderDetail;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const orderData: orderDetailsData = {
    id: cOrdrDetail?.id,
    createdAt: cOrdrDetail?.orderCombinedOn,
    orderCode: `${cOrdrDetail?.code}`,
    isCombinedOrder: true,
    orderStatus: cOrdrDetail?.status,
    isExpeditedShipping: cOrdrDetail?.isExpeditedShipping,
    thumbnailUrl: cOrdrDetail?.thumbnailUrl,
    customer: cOrdrDetail?.customer,
    customerEmail: cOrdrDetail?.customerEmail,
    customerPhoneNo: cOrdrDetail?.customerPhoneNo,
    shippingAddress: cOrdrDetail?.shippingAddress,
    childOrders: cOrdrDetail?.childOrders
  };

  return orderData;
};
