import { FC, useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';

// Components
import { Loader } from 'src/components/atoms';
import { OrderToFulfilListBlockFulfillmentCenter, InputBox } from 'src/components/molecules';

// Icons
import { SearchIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { OrderToFulfilListFulfillmentCenterProps } from './OrderToFulfilListFulfillmentCenter.types';

// Styles
import './_orderToFulfilListFulfillmentCenter.scss';

const OrderToFulfilListFulfillmentCenter: FC<OrderToFulfilListFulfillmentCenterProps> = ({
  orders,
  ordersListData,
  changeSearchOrderValue,
  searchOrderValue,
  selectedOrder,
  setSelectedOrder,
  selectedStatus,
  setSelectedStatus,
  isLoading
}) => {
  const [searchKey, setSearchKey] = useState('');
  const handleSearchOrder = (value) => {
    changeSearchOrderValue(value, !!value);
  };

  const debouncedFn = useCallback(
    debounce((value) => handleSearchOrder(value), 1000),
    []
  );

  useEffect(() => {
    debouncedFn(searchKey);
  }, [searchKey]);

  useEffect(() => {
    setSearchKey(searchOrderValue);
  }, []);

  const handleSearchKey = (value) => {
    setSearchKey(value);
  };

  return (
    <div className={`w-100 d-flex flex-column flex-grow-1 ${isLoading ? 'pe-3' : ''}`}>
      <div className="d-flex align-items-center justify-content-between">
        <div
          className="poppins-regular fulfill-title"
          // onClick={() => {
          //   setSelectedStatus(['awaiting_fulfillment']);
          // }}
        >
          {constVariables.FulfillmentCenter.ordersToFulfill} ({orders})
        </div>
        {/* <div
          className="text-primary cursor-pointer"
          onClick={() => {
            setSelectedStatus(['awaiting_shipment']);
          }}
        >
          {constVariables.FulfillmentCenter.history}
        </div> */}
      </div>
      <div className={`m-t-20 search-order-input ${isLoading ? 'me-0' : ''}`}>
        <InputBox
          name="SearchOrdersToFulfil"
          inputIcon={SearchIcon}
          value={searchKey}
          onChangeText={handleSearchKey}
          placeholder={constVariables.FulfillmentCenter.searchForOrder}
          inputClass="form-control-lg"
        />
      </div>
      {isLoading ? (
        <Loader type="page" className="h-100 card mt-5 mb-0" />
      ) : (
        <div className="m-t-12 columnSize  d-flex flex-column  flex-grow-1 w-100 h-100 scroll-y pb-0 fulfill-orders-list">
          {ordersListData?.orders.map((data, index) => (
            <OrderToFulfilListBlockFulfillmentCenter
              key={index}
              data={data}
              selectedOrder={selectedOrder}
              setSelectedOrder={setSelectedOrder}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default OrderToFulfilListFulfillmentCenter;
