import { gql } from '@apollo/client';

export const GET_ROLES = gql`
  query GetRoles @api(name: "authAPI") {
    getRoles {
      roles {
        id
        role
        roleDisplayName
        permissions {
          section
          permissions
        }
        canBeNotifiedWhenOrderPlaced
      }
    }
  }
`;
