import { FC } from 'react';

// Components
import AddProductInventoryPresentational from './AddProductInventory.presentational';

// Types
import { WarehouseListProp } from './AddProductInventory.types';
import { InventorySection } from 'src/components/pages/products/AddProduct/AddProduct.types';

// Styles
import './_addProductInventory.scss';

const AddProductInventory: FC<InventorySection> = ({
  inventory,
  setInventory,
  warehouseList,
  tableData,
  setTableData,
  optionChecked,
  optionDone
}) => {
  let alreadyAvailable;
  let filteredWarehouseList;
  if (warehouseList) {
    filteredWarehouseList = warehouseList;
  }
  if (inventory.inventoryData && filteredWarehouseList) {
    alreadyAvailable = inventory.inventoryData.map((data) => data?.inventoryWarehouseId);
    alreadyAvailable.pop();
    filteredWarehouseList = warehouseList?.filter((data) => alreadyAvailable.indexOf(data.id) === -1);
  }
  const addAddInventories = () => {
    if (
      inventory?.inventoryData[inventory?.inventoryData.length - 1].inventoryQuantityValue &&
      inventory?.inventoryData[inventory?.inventoryData.length - 1].inventoryWarehouseValue.length > 0 &&
      inventory?.inventoryData[inventory?.inventoryData.length - 1].inventoryWarehouseId
    ) {
      if (filteredWarehouseList) {
        const getOldIndex = filteredWarehouseList.findIndex(
          (mapData) => mapData.id === inventory?.inventoryData[inventory?.inventoryData.length - 1].inventoryWarehouseId
        );

        setInventory({
          ...inventory,
          inventoryData: [
            ...inventory.inventoryData,
            {
              inventoryQuantityValue: 0,
              inventoryWarehouseValue:
                getOldIndex === filteredWarehouseList.length - 1
                  ? filteredWarehouseList[getOldIndex].name
                  : filteredWarehouseList[getOldIndex + 1].name,
              inventoryWarehouseId:
                getOldIndex === filteredWarehouseList.length - 1
                  ? filteredWarehouseList[getOldIndex].id
                  : filteredWarehouseList[getOldIndex + 1].id,
              isDropDown: false
            }
          ]
        });
      }
    }
  };
  const OpenCloseWareHouse = (invIndex: number) => {
    const updatedObj = { ...inventory.inventoryData[invIndex], isDropDown: !inventory.inventoryData[invIndex].isDropDown };
    setInventory({
      ...inventory,
      inventoryData: [...inventory.inventoryData.slice(0, invIndex), updatedObj, ...inventory.inventoryData.slice(invIndex + 1)]
    });
  };
  const CloseWareHouse = (invIndex: number) => {
    if (inventory.inventoryData[invIndex]?.isDropDown) {
      const updatedObj = { ...inventory.inventoryData[invIndex], isDropDown: false };
      setInventory({
        ...inventory,
        inventoryData: [...inventory.inventoryData.slice(0, invIndex), updatedObj, ...inventory.inventoryData.slice(invIndex + 1)]
      });
    }
  };

  const WareHouseSelect = (dropdownData: WarehouseListProp, invIndex: number) => {
    const updatedObj = {
      ...inventory.inventoryData[invIndex],
      inventoryWarehouseValue: dropdownData.name,
      isDropDown: false,
      inventoryWarehouseId: dropdownData.id,
      inventoryQuantityValue:
        inventory.inventoryData[invIndex].inventoryWarehouseId === dropdownData.id
          ? inventory.inventoryData[invIndex].inventoryQuantityValue
          : 0
    };
    setInventory({
      ...inventory,
      inventoryData: [...inventory.inventoryData.slice(0, invIndex), updatedObj, ...inventory.inventoryData.slice(invIndex + 1)]
    });
    if (tableData.length > 0) {
      const updatedTableData = tableData.map((item) => {
        const newWarehouseArray = inventory.inventoryData.map((item) => {
          return {
            id: item.id,
            warehouseId:
              inventory.inventoryData[invIndex].inventoryWarehouseId === dropdownData.id
                ? item.inventoryWarehouseId?.toString()
                : dropdownData.id?.toString(),
            quantity: inventory.inventoryData[invIndex].inventoryWarehouseId === dropdownData.id ? item.inventoryQuantityValue : 0
          };
        });
        return { ...item, warehouseInventory: newWarehouseArray };
      });
      setTableData(updatedTableData);
    }
  };
  const InventoryQuantityValueChange = (quantity: string, invIndex: number) => {
    const updatedObj = { ...inventory.inventoryData[invIndex], inventoryQuantityValue: parseInt(quantity, 10) };
    setInventory({
      ...inventory,
      inventoryData: [...inventory.inventoryData.slice(0, invIndex), updatedObj, ...inventory.inventoryData.slice(invIndex + 1)]
    });

    if (tableData?.length > 0) {
      const updatedTableData = tableData.map((item, index) => {
        const newWarehouseArray = inventory.inventoryData.map((item) => {
          return {
            id: item.id,
            warehouseId: item.inventoryWarehouseId?.toString(),
            quantity: index === 0 ? parseInt(quantity, 10) : 0
          };
        });
        return { ...item, warehouseInventory: newWarehouseArray };
      });
      setTableData(updatedTableData);
    }
  };
  const changeCheckBox = () => {
    setInventory({
      ...inventory,
      continueSellingChecked: !inventory.continueSellingChecked
    });
  };

  return (
    <AddProductInventoryPresentational
      inventory={inventory}
      OpenCloseWareHouse={OpenCloseWareHouse}
      CloseWareHouse={CloseWareHouse}
      warehouseList={filteredWarehouseList}
      WareHouseSelect={WareHouseSelect}
      addAddInventories={addAddInventories}
      changeCheckBox={changeCheckBox}
      InventoryQuantityValueChange={InventoryQuantityValueChange}
      tableData={tableData}
      optionChecked={optionChecked}
      optionDone={optionDone}
    />
  );
};

export default AddProductInventory;
