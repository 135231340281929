import { FC, useState } from 'react';

// Components
import { FlexBox, Loader } from 'src/components/atoms';
import { Divider } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { CloseIcon } from 'src/assets/icons';

interface ConfirmPushTiktokModalProps {
  readyStatusCount: number;
  readyInfoStatusCount: number;
  remainingStatusCount: number;
  closeModal: () => void;
  handlePushToTiktok: (includeBothReady: boolean) => void;
  isPushingProduct: boolean;
}

const ConfirmPushTiktokModal: FC<ConfirmPushTiktokModalProps> = ({
  readyStatusCount,
  readyInfoStatusCount,
  remainingStatusCount,
  closeModal,
  handlePushToTiktok,
  isPushingProduct
}) => {
  const [includeBothReady, setIncludeBothReady] = useState(false);

  return (
    <div className="modal-content push-tiktok-modal">
      <div className="modal-header">
        <h5 className="modal-title">Push to Tiktok</h5>
        <div className="btn btn-xs btn-active-light-primary p-0 m-0 border-none" onClick={closeModal}>
          <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
        </div>
      </div>
      <div className="modal-body p-x-30 p-y-20">
        <p className="mb-0">
          Selected items will be sent to TikTok for review. Products with suggestions will also sent to TikTok. You can change this
          with the toggle below.
        </p>
        <Divider classname="m-y-20" />
        <FlexBox className="align-items-center">
          <FlexBox className="align-items-center tiktok-row">
            <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
              <input
                className="form-check-input"
                type="checkbox"
                checked={includeBothReady}
                onChange={(e) => {
                  setIncludeBothReady(e.target.checked);
                }}
              />
            </div>
            <span className="m-l-8">Push products with suggestions</span>
          </FlexBox>
          <span>{readyInfoStatusCount}</span>
        </FlexBox>
        <Divider classname="m-y-20" />
        <div>
          <FlexBox className="align-items-center m-b-16">
            <FlexBox className="align-items-center tiktok-row">
              <span className="status-dot active"></span>
              <span className="m-l-8">Products that will be pushed to TikTok</span>
            </FlexBox>
            <span className="text-success">{includeBothReady ? readyStatusCount + readyInfoStatusCount : readyStatusCount}</span>
          </FlexBox>
          <FlexBox className="align-items-center">
            <FlexBox className="align-items-center tiktok-row">
              <span className="status-dot inactive"></span>
              <span className="m-l-8">Products that will stay in Drafts</span>
            </FlexBox>
            <span>{remainingStatusCount}</span>
          </FlexBox>
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-outlined-secondary btn-sm" onClick={closeModal}>
          Cancel
        </button>
        <button className="btn btn-primary btn-sm" disabled={isPushingProduct} onClick={() => handlePushToTiktok(includeBothReady)}>
          {isPushingProduct ? 'Confirming' : 'Confirm'}
          {isPushingProduct && <Loader type="button" className="h-15px w-15px" />}
        </button>
      </div>
    </div>
  );
};

export default ConfirmPushTiktokModal;
