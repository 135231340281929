// Components
import Tooltip from './Tooltip';

const VipCustomerTooltip = () => {
  return (
    <Tooltip
      tooltipId="vipCustomerIcon"
      place="top"
      content={() => {
        return <>VIP Customer</>;
      }}
    />
  );
};

export default VipCustomerTooltip;
