import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Apollo
import { useQuery } from '@apollo/client';
import { PRODUCT_DETAILS_VARIANTS, GET_SHOPIFY_ADMIN_BASE_URL } from 'src/apollo/queries';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import { Table, Divider, InputBox, ShopifySyncTooltip } from 'src/components/molecules';
import OrderDetailActionCell from './OrderDetailActionCell';
import OrderDetailVariantsCell from './OrderDetailVariantsCell';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';
import { pascalCaseConversion } from 'src/utils/convertText';
import getDeviceData from 'src/utils/UseDevice/useDevice';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import {
  // AddIcon,
  DefaultImage,
  ShopifyIcon,
  SmallThickDownArrowIcon,
  SmallThickUpArrowIcon
} from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { TablesProps, RowObjectProps } from 'src/components/molecules/Table/Table.types';
import { EditOrderDetailsItem, OrderDetailsTableProps } from './OrderDetails.types';

// Styles
import './OrderDetails.scss';

const OrderDetailsTable: FC<OrderDetailsTableProps> = ({
  orderDetails,
  orderSummaryFormData,
  setIsDelete,
  openAddProduct,
  updateOrderSummaryFormChanges,
  updatedFormData
}) => {
  const deviceName = getDeviceData();
  const [dropdownData, setDropdownData] = useState<any[]>([]);
  const [productVariants, setProductVariants] = useState([]); // list of variants based on product

  const { data: { shopifyAdminBaseUrl } = { shopifyAdminBaseUrl: null } } = useQuery(GET_SHOPIFY_ADMIN_BASE_URL);

  const selectVariant = (data: { id: number; name: string; productId: number; itemId: number }) => {
    setDropdownData(
      dropdownData.map((product) => {
        if (product.orderItemId === data.itemId) {
          product.variants = {
            id: data.id,
            name: data.name
          };
        }
        return product;
      })
    );
    const updatedList: EditOrderDetailsItem[] =
      orderSummaryFormData?.items?.map((item) => {
        if (item.orderItemId === data.itemId) {
          item.variantId = data.id;
        }
        return item;
      }) || [];
    updateOrderSummaryFormChanges && updateOrderSummaryFormChanges(true);
    updatedFormData && updatedFormData(updatedList);
  };

  const updateQuantity = (productItemId: number, input: any) => {
    const updatedList: EditOrderDetailsItem[] =
      orderSummaryFormData?.items?.map((item) => {
        if (item.orderItemId === productItemId) {
          item.quantity = Number(input);
          item.total = Number(input) * (item.price || 0);
        }
        return item;
      }) || [];
    updatedFormData && updatedFormData(updatedList);
  };

  const updateDropdownData = (productIemList: any, productVariantList) => {
    const arr2: {
      productId: number;
      variants: {
        name: string;
        id: number;
      };
      selectedVariantId: number;
      orderItemId: number;
    }[] = [];
    productIemList.map((productItem: any) => {
      const obj = {
        productId: -1,
        variants: {
          id: -1,
          name: ''
        },
        selectedVariantId: -1,
        orderItemId: -1
      };
      const productVariant = productVariantList.find((productVariant) => productVariant.id === productItem.productId.toString());
      productVariant?.variants?.map((variants) => {
        obj.productId = productVariant.id;
        let tempString = '';
        obj.variants.id = variants.id;
        obj.selectedVariantId = Number(productItem.productVariantId) || -1;
        obj.orderItemId = productItem.id;
        if (variants.id === productItem.productVariantId) {
          variants.variantTypes.map((type, i) => {
            tempString = `${tempString} ${type.value} ${i === variants.variantTypes.length - 1 ? '' : ' /'} `;
            return null;
          });
          obj.variants.name = tempString;
        }
        return null;
      });
      arr2.push(obj);
      return null;
    });
    setDropdownData([...arr2]);
  };

  useEffect(() => {
    const itemList = orderDetails?.orderDetails?.map((product) => {
      return {
        id: product.id,
        productId: product.productId,
        productVariantId: product.variantId,
        productQuantity: product.quantity
      };
    });
    updateDropdownData(itemList, productVariants);
  }, [orderDetails?.orderDetails]);

  // call getProduct to get details of the product
  useQuery(PRODUCT_DETAILS_VARIANTS, {
    variables: {
      input: {
        productIDs: orderDetails?.orderDetails
          ?.map((product) => {
            return {
              id: product.id,
              productId: product.productId
            };
          })
          .map((item) => item.productId),
        isStoreOwner: true
      }
    },
    onCompleted: (data) => {
      setProductVariants(data?.getProduct);
      const itemList = orderDetails?.orderDetails?.map((product) => {
        return {
          id: product.id,
          productId: product.productId,
          productVariantId: product.variantId,
          productQuantity: product.quantity
        };
      });
      updateDropdownData(itemList, data?.getProduct);
    }
  });

  const getDropDownData = (summaryProduct: any) => {
    const variants: any = productVariants.filter(
      (product: { id: string; variants: any[] }) => product.id === summaryProduct.productId.toString()
    )[0];
    const dropdDownArr: {
      name: string;
      id: number;
      productId: number;
      itemId: number;
    }[] = [];
    variants?.variants?.map((variant) => {
      const obj = {
        id: -1,
        name: '',
        productId: 0,
        itemId: 0
      };
      obj.id = variant.id;
      obj.productId = summaryProduct.productId;
      obj.itemId = summaryProduct.id;
      let tempString = '';
      variant.variantTypes.map((type, i) => {
        tempString = `${tempString} ${type.value} ${i === variant.variantTypes.length - 1 ? '' : ' /'} `;
        return null;
      });
      obj.name = tempString;
      dropdDownArr.push(obj);
      return null;
    });
    return dropdDownArr;
  };

  const deleteProductHandler = (itemId: number) => {
    setIsDelete && setIsDelete({ isDelete: true, orderId: orderDetails?.id, itemId });
  };

  const goToShopifyPage = (shopifyProductId) => {
    if (shopifyAdminBaseUrl) window.open(`${shopifyAdminBaseUrl}products/${shopifyProductId}`, '_blank');
  };

  // TABLE PROPS
  const tableData: TablesProps = {
    isDragDisabled: true,
    headerClassName: 'poppins-normal text-dark-600',
    className: 'overflow-visible'
  };
  tableData.headers = [
    {
      id: 1,
      className: 'w-400px  align-middle my-4 border-bottom-0',
      colName: constVariables.orderDetails.product,
      category: constVariables.orderDetails.product
    },

    {
      id: 2,
      className: 'w-300px  align-middle my-4 border-bottom-0',
      colName: constVariables.orderDetails.variant,
      category: constVariables.orderDetails.variant
    },
    {
      id: 3,
      className: 'w-250px  align-middle my-4 border-bottom-0',
      colName: constVariables.orderDetails.unitPrice,
      category: constVariables.orderDetails.unitPrice
    },
    {
      id: 4,
      className: 'w-250px  align-middle my-4 border-bottom-0',
      colName: constVariables.orderDetails.quantity,
      category: constVariables.orderDetails.quantity
    },
    {
      id: 5,
      className: 'w-100px align-middle my-4 border-bottom-0',
      colName: constVariables.orderDetails.Total,
      category: constVariables.orderDetails.Total
    },
    {
      id: 6,
      className: 'align-middle my-4 border-bottom-0',
      colName: '',
      category: 'BUTTON'
    }
  ];
  const arr: Array<RowObjectProps> = [];

  const showFullfilledLabel = (data: any) => {
    if (data) {
      const isAbleToFulfill = JSON.parse(data).filter((item) => item.shipmentId !== null);
      if (isAbleToFulfill.length > 0) {
        return (
          <div className="d-flex">
            <div className="filfileldItems bg-success mb-2">{constVariables.orderItemStatuses.Fulfilled}</div>
          </div>
        );
      }
    }
  };

  // MAPPING THROUGH DATA
  orderDetails?.orderDetails?.map((summaryProduct) => {
    // PUSHING ROWS IN A TABLE
    let inpValue = 1;

    const isIt = orderSummaryFormData?.items.filter((item) => item.orderItemId === summaryProduct.id)[0];
    if (isIt && isIt.quantity) {
      inpValue = isIt.quantity;
    }
    arr.push({
      className: `position-relative h-fit-content table-row no-hover-row`,
      id: summaryProduct.id,
      cells: [
        {
          className: 'cursor-pointer align-middle table-top-border ps-0 text-muted py-0',
          value: (
            <div className="d-flex align-items-center">
              <div className="symbol m-r-20">
                <Img
                  className={`rounded osImage ${summaryProduct.availableQuantity === 0 ? 'opacity-50' : ''}`}
                  src={setImageSrc(
                    summaryProduct?.imageUrl ? generateCompressedImageURL(summaryProduct?.imageUrl, '50') : DefaultImage
                  )}
                  placeholderImg={DefaultImage}
                  errorImg={DefaultImage}
                />
                {summaryProduct?.shopifyId && (
                  <>
                    <img
                      src={ShopifyIcon}
                      className="cursor-pointer ms-1 shopify-icon position-absolute"
                      data-tip
                      data-for="shopifySyncIcon"
                      onClick={() => goToShopifyPage(summaryProduct?.shopifyId)}
                    />
                    <ShopifySyncTooltip tooltipText="Product from Shopify" />
                  </>
                )}
              </div>
              <div className="ms-4 d-flex flex-column justify-content-start">
                {summaryProduct.availableQuantity === 0 ? (
                  <div className="mb-2 d-flex">
                    <div className="unAvailItems">{constVariables.orderItemStatuses.UnavailableItem}</div>
                  </div>
                ) : null}

                {showFullfilledLabel(summaryProduct.items)}
                <div>
                  <Link
                    to={{
                      pathname: `/products/allProducts/edit/${summaryProduct?.productId}`,
                      state: { from: `/orders/orderDetails/${orderDetails?.id}` }
                    }}
                    className="main-cell"
                  >
                    <span className="d-block mb-2 osHeading">{summaryProduct.name}</span>
                  </Link>
                </div>
                {/* <div>
                  <span className="osLocation">{constVariables.orderDetails.sku} </span>
                  <span className="osLight">
                    {summaryProduct?.location && JSON.parse(summaryProduct?.location).inventory?.length > 0
                      ? `${JSON.parse(summaryProduct?.location).inventory[0].warehouseId}-${
                          JSON.parse(summaryProduct?.location).inventory[0].inventoryPosition
                        }` // TODO: Need to update this later
                      : null}
                  </span>
                </div> */}
              </div>
            </div>
          )
        },
        {
          className: 'align-middle table-top-border ps-0 pe-5 text-muted py-0',
          value: (
            <OrderDetailVariantsCell
              summaryProduct={summaryProduct}
              getDropDownData={getDropDownData}
              selectVariant={selectVariant}
              dropdownData={dropdownData}
            />
          )
        },
        {
          className: 'align-middle table-top-border py-0',
          value: <div>${summaryProduct?.price?.length ? summaryProduct?.price[0]?.price?.toFixed(2) : 0}</div>
        },
        {
          className: 'align-middle table-top-border py-0',
          value: (
            <div>
              {
                (
                  orderSummaryFormData?.items.filter((item) => item.orderItemId === summaryProduct.id)[0] || {
                    quantity: 1
                  }
                ).quantity
              }
            </div>
            // <div className="d-flex align-items-center justify-content-end">
            //   {summaryProduct.items && JSON.parse(summaryProduct.items).filter((item) => item.shipmentId !== null).length > 0 ? (
            //     <FlexBox
            //       className={`osQuantText form-control form-control-lg justify-content-end align-items-center noBorderColor`}
            //     >
            //       {
            //         (
            //           orderSummaryFormData?.items.filter((item) => item.orderItemId === summaryProduct.id)[0] || {
            //             quantity: 1
            //           }
            //         ).quantity
            //       }
            //     </FlexBox>
            //   ) : (
            //     <FlexBox
            //       className={`form-control order_variant dropStyle form-control-lg  px-5 justify-content-between align-items-center cursor-pointer dropdown-box qty-dropdown`}
            //       onClick={() => null}
            //     >
            //       <InputBox
            //         value={inpValue}
            //         onChangeText={(input) => updateQuantity(summaryProduct.id, input)}
            //         name={constVariables.orderDetails.quantity}
            //         placeholder={constVariables.orderDetails.quantity}
            //         noBorders
            //         inputClass="poppins-medium osQuantText"
            //       />
            //       <div className="ms-2 d-flex flex-column justify-content-end">
            //         <div onClick={() => updateQuantity(summaryProduct.id, inpValue + 1)}>
            //           <Img className="h-7px" src={SmallThickUpArrowIcon} />
            //         </div>
            //         <div onClick={() => updateQuantity(summaryProduct.id, inpValue - 1)}>
            //           <Img className="h-7px" src={SmallThickDownArrowIcon} />
            //         </div>
            //       </div>
            //     </FlexBox>
            //   )}
            //   <span className="ms-4 osMulAmount">X</span>
            //   <span className="ms-4 osMulAmount">
            //     ${summaryProduct?.price?.length ? summaryProduct?.price[0]?.price?.toFixed(2) : 0}
            //   </span>
            // </div>
          )
        },
        {
          className: 'align-middle table-top-border ps-0 py-0',
          value: (
            <FlexBox className="align-items-center">
              <div className="osTotal">
                $
                {summaryProduct?.price?.length > 0 &&
                summaryProduct?.price[0]?.price &&
                orderSummaryFormData &&
                orderSummaryFormData.items
                  ? (
                      summaryProduct?.price[0]?.price *
                      ((orderSummaryFormData?.items.filter((item) => item.orderItemId === summaryProduct.id)[0] &&
                        orderSummaryFormData?.items.filter((item) => item.orderItemId === summaryProduct.id)[0].quantity) ||
                        1)
                    ).toFixed(2)
                  : 0}
              </div>
            </FlexBox>
          )
        },
        {
          className: 'align-middle table-top-border ps-0 py-0',
          value: <OrderDetailActionCell deleteProductHandler={() => deleteProductHandler(summaryProduct.id)} />
        }
      ]
    });
    return null;
  });
  tableData.rows = arr; // PUSHING DATA IN TABLE

  return (
    <div className="orderSummary">
      <h4 className="section-title m-b-32">
        {`${pascalCaseConversion(constVariables.orders.orderSummary)} `}
        <span className="order-place-time">({orderDetails?.orderDetails?.length})</span>
      </h4>
      <div className={`${deviceName.isWeb ? '' : 'overflow-scroll'}`}>
        <Table
          isOrder={true}
          rows={tableData.rows}
          headerClassName={tableData.headerClassName}
          headers={tableData.headers}
          sortByType={tableData.sortByType}
          sortByCategory={tableData.sortByCategory}
          isDragDisabled={tableData.isDragDisabled}
          type="Product List"
          // className={`orderSummaryTable ${!deviceName.isWeb && tableData?.rows?.length ? 'vw-87' : ''}`}
          className="orderSummaryTable"
          loadingPosition="center"
          noMarginBottom
        />
      </div>
      <Divider />
      {/* {orderDetails?.orderStatus !== 'shipped' && !orderDetails?.isPartiallyShipped && (
        <div>
          <button
            onClick={openAddProduct}
            className="d-flex align-items-center justify-content-center btn  btn-light-primary btn-color-primary btn-active-light-primary  mt-4"
          >
            <span className=" fs-6">
              <KTSVG path={AddIcon} />
              {constVariables.orderDetails.addProducts}
            </span>
          </button>
        </div>
      )} */}
    </div>
  );
};

export default OrderDetailsTable;
