import { FC } from 'react';

// Components
import { InputBox, RadioSelector, Divider } from 'src/components/molecules';
import { Img } from 'src/components/atoms';

// Icons
import { DownArrowIcon, UpArrowIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants/constVariables';

// Types
import { CustomerBuyGetTypeSection } from 'src/components/pages/coupons/CreateCoupon/CreateCoupon.types';

const CustomerBuyGetCreateCoupon: FC<CustomerBuyGetTypeSection> = ({ customerBuyGet, setCustomerBuyGet }) => {
  const selectMinQuantity = () => {
    setCustomerBuyGet((prev) => ({ ...prev, isMinQuantity: true, isMinPurchase: false }));
  };
  const selectMinPurchase = () => {
    setCustomerBuyGet((prev) => ({ ...prev, isMinQuantity: false, isMinPurchase: true }));
  };

  const changeBuyQuantity = (buyQuantity: any) => {
    if (buyQuantity === '') {
      setCustomerBuyGet((prev) => ({ ...prev, buyQuantity: '' }));
    } else if (!isNaN(buyQuantity)) {
      setCustomerBuyGet((prev) => ({ ...prev, buyQuantity }));
    }
  };
  const changeBuyItem = (buyItem: any) => {
    setCustomerBuyGet((prev) => ({ ...prev, buyItem }));
  };
  const addBuyQuantity = () => {
    if (customerBuyGet.buyQuantity) {
      const temp = parseInt(customerBuyGet.buyQuantity, 10) + 1;
      setCustomerBuyGet((prev) => ({ ...prev, buyQuantity: temp.toString() }));
    }
  };
  const subtractBuyQuantity = () => {
    if (customerBuyGet.buyQuantity) {
      if (parseInt(customerBuyGet.buyQuantity, 10) > 0) {
        const temp = parseInt(customerBuyGet.buyQuantity, 10) - 1;
        setCustomerBuyGet((prev) => ({ ...prev, buyQuantity: temp.toString() }));
      }
    }
  };

  const changeGetQuantity = (getQuantity: any) => {
    if (getQuantity === '') {
      setCustomerBuyGet((prev) => ({ ...prev, getQuantity: '' }));
    } else if (!isNaN(getQuantity)) {
      setCustomerBuyGet((prev) => ({ ...prev, getQuantity }));
    }
  };
  const changeGetItem = (getItem: any) => {
    setCustomerBuyGet((prev) => ({ ...prev, getItem }));
  };
  const addGetQuantity = () => {
    const temp = parseInt(customerBuyGet.getQuantity, 10) + 1;
    setCustomerBuyGet((prev) => ({ ...prev, getQuantity: temp.toString() }));
  };
  const subtractGetQuantity = () => {
    if (parseInt(customerBuyGet.getQuantity, 10) > 0) {
      const temp = parseInt(customerBuyGet.getQuantity, 10) - 1;
      setCustomerBuyGet((prev) => ({ ...prev, getQuantity: temp.toString() }));
    }
  };

  return (
    <div className="card w-100">
      <div className="section-title">{constVariables.Coupons.AddCoupon.customerBuyGet.customerBuys}</div>

      <div className="mt-6">
        <div className="pamTitle m-b-16">{constVariables.Coupons.AddCoupon.value.productAssignmentMethod}</div>

        <RadioSelector
          isCheck={customerBuyGet.isMinQuantity}
          name={constVariables.Coupons.AddCoupon.customerBuyGet.minQuantityItems}
          changeCheck={selectMinQuantity}
        />

        <RadioSelector
          isCheck={customerBuyGet.isMinPurchase}
          name={constVariables.Coupons.AddCoupon.customerBuyGet.minPurchaseAmount}
          changeCheck={selectMinPurchase}
        />
      </div>
      <div className="d-block d-md-flex mt-3">
        <div className="col-12 col-md-4 me-1 p-0">
          <div className="customerBuyGetSamllHeading m-b-12">{constVariables.Coupons.AddCoupon.customerBuyGet.quantity}</div>
          <div className="d-flex align-items-center justify-content-start form-control form-control-lg">
            <InputBox
              noBorders
              inputClass="valueColor valueInput d-flex align-items-center  "
              value={customerBuyGet.buyQuantity}
              onChangeText={changeBuyQuantity}
              name="CouponValue"
              charLimit={10}
            />
            <div className="d-flex flex-column">
              <Img onClick={addBuyQuantity} className="cursor-pointer h-15px w-15px opacity-50" src={UpArrowIcon} />
              <Img onClick={subtractBuyQuantity} className="cursor-pointer h-15px w-15px opacity-50" src={DownArrowIcon} />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-8 ms-1">
          <div className="customerBuyGetSamllHeading m-b-12">{constVariables.Coupons.AddCoupon.customerBuyGet.anyItemsFrom}</div>
          <div className="d-flex align-items-center justify-content-start form-control form-control-lg">
            <InputBox
              noBorders
              inputClass="valueColor valueInput d-flex align-items-center  "
              value={customerBuyGet.buyItem}
              onChangeText={changeBuyItem}
              name="CouponValue"
              charLimit={10}
            />
          </div>
        </div>
      </div>
      <Divider classname="my-7" />

      <div className="section-title">{constVariables.Coupons.AddCoupon.customerBuyGet.customerGets}</div>
      <div className="lightDescription mt-1">{constVariables.Coupons.AddCoupon.customerBuyGet.customerGetsDesc}</div>
      <div className="d-block d-md-flex mt-6">
        <div className="col-12 col-md-4 me-1 p-0">
          <div className="customerBuyGetSamllHeading m-b-12">{constVariables.Coupons.AddCoupon.customerBuyGet.quantity}</div>
          <div className="d-flex align-items-center justify-content-start form-control form-control-lg">
            <InputBox
              noBorders
              inputClass="valueColor valueInput d-flex align-items-center  "
              value={customerBuyGet.getQuantity}
              onChangeText={changeGetQuantity}
              name="CouponValue"
              charLimit={10}
            />
            <div className="d-flex flex-column">
              <Img onClick={addGetQuantity} className="cursor-pointer h-15px w-15px opacity-50" src={UpArrowIcon} />
              <Img onClick={subtractGetQuantity} className="cursor-pointer h-15px w-15px opacity-50" src={DownArrowIcon} />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-8 ms-1">
          <div className="customerBuyGetSamllHeading m-b-12">{constVariables.Coupons.AddCoupon.customerBuyGet.anyItemsFrom}</div>
          <div className="d-flex align-items-center justify-content-start form-control form-control-lg">
            <InputBox
              noBorders
              inputClass="valueColor valueInput d-flex align-items-center  "
              value={customerBuyGet.getItem}
              onChangeText={changeGetItem}
              name="CouponValue"
              charLimit={10}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerBuyGetCreateCoupon;
