import { FC, useEffect, useState } from 'react';

// Apollo
import { useLazyQuery } from '@apollo/client';
import { GET_WAITLIST } from 'src/apollo/queries';

// Components
import WaitlistsPagePresentational from './WaitlistsPage.presentational';

// Hooks && Utils && Helpers
import useTitle from 'src/utils/hooks/useTitle';

// Types
import { WaitlistsDataProductProp } from './WaitlistsPage.type';

const WaitlistsPage: FC = () => {
  useTitle('Waitlists');
  const [waitlists, setWaitlists] = useState<WaitlistsDataProductProp[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [sortBy, setSortBy] = useState({
    catagory: 'NAME',
    id: 1,
    type: 'ASC'
  });
  const [sortByWorking, setSortByWorking] = useState({ column: 'NAME', order: 'ASC' });

  const [waitlistsPageInfo, setWaitlistsPageInfo] = useState({
    skipCount: 0,
    limitCount: 50
  });

  useEffect(() => {
    if (sortBy) {
      if (sortBy.catagory === 'NAME') {
        setSortByWorking({ column: 'NAME', order: sortBy.type.toUpperCase() });
      } else {
        setSortByWorking({ column: sortBy.catagory.toUpperCase(), order: sortBy.type.toUpperCase() });
      }
    }
  }, [sortBy]);

  const [getProductsInWaitlist, { loading: isLoading }] = useLazyQuery(GET_WAITLIST, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        pagination: waitlistsPageInfo,
        sortBy: sortByWorking.column,
        sortType: sortByWorking.order
      }
    },
    onCompleted: (waitlistsListData) => {
      if (waitlistsListData.getProductsInWaitlist) {
        setWaitlists(waitlistsListData.getProductsInWaitlist.products);
        setTotalCount(waitlistsListData.getProductsInWaitlist.total);
      }
    },
    onError: (error) => {
      console.error('err', error);
    }
  });

  useEffect(() => {
    getProductsInWaitlist();
  }, []);

  return (
    <WaitlistsPagePresentational
      waitlists={waitlists}
      setWaitlists={setWaitlists}
      totalCount={totalCount}
      sortBy={sortBy}
      setSortBy={setSortBy}
      waitlistsPageInfo={waitlistsPageInfo}
      setWaitlistsPageInfo={setWaitlistsPageInfo}
      isLoading={isLoading}
    />
  );
};

export default WaitlistsPage;
