import { gql } from '@apollo/client';

export const ADD_OR_EDIT_STORE_VENDOR_COLUMNS = gql`
  mutation AddOrEditStoreVendorColumns($input: StoreVendorColumnsInput!) @api(name: "productsAPI") {
    addOrEditStoreVendorColumns(input: $input) {
      id
      success
      message
    }
  }
`;
