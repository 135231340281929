import jwtDecode from 'jwt-decode';

export const getAccountDetailsFromToken = (token?: string): any => {
  try {
    const decoded: any = jwtDecode(token && token !== '' ? token : (localStorage.getItem('token') as string));
    return {
      success: true,
      ...decoded
    };
  } catch (e) {
    return {
      success: false
    };
  }
};

export const isTokenExpired: (exp: number) => boolean = (exp: number) => {
  if (Date.now() >= exp * 1000) {
    return true;
  } else {
    return false;
  }
};
