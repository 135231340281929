import { FC, useState, useEffect } from 'react';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import { Tooltip } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import { DefaultImage, expandRight } from 'src/assets/icons';

// Types
import { CollectionTreeRowProps } from './BulkEditorModal.types';

const CollectionTreeRow: FC<CollectionTreeRowProps> = ({
  collection,
  showAllDetails,
  selectedCollections,
  setSelectedCollections
}) => {
  const { id, text, thumbnailUrl, type, status, children, productCount, level } = collection;

  const [showDetail, setShowDetail] = useState(false);

  useEffect(() => {
    if (showAllDetails) {
      setShowDetail(true);
    } else {
      setShowDetail(false);
    }
  }, [showAllDetails]);

  const statusText = (title: string) => {
    if (title === 'ACTIVE') {
      return 'Active';
    }
    if (title === 'SCHEDULED') {
      return 'Scheduled';
    }
    return 'Inactive';
  };

  const selectCollectionStatusClass = (collectionStatus: string | undefined) => {
    switch (collectionStatus) {
      case 'active':
        return 'active';
      case 'inactive':
        return 'inactive';
      default:
        return 'scheduled';
    }
  };

  const handleChangeCollection = () => {
    if (selectedCollections && selectedCollections?.length > 0) {
      if (selectedCollections?.includes(id)) {
        setSelectedCollections && setSelectedCollections(selectedCollections.filter((collectionId) => collectionId !== id));
      } else {
        setSelectedCollections && setSelectedCollections([...selectedCollections, id]);
      }
    }
  };

  return (
    <>
      <tr className={`${type === 'AUTOMATIC' ? 'opacity-half' : ''}`}>
        <td className="align-middle w-25px table-top-border">
          <div className="ps-5 pe-2 form-check form-check-custom form-check-sm">
            {type === 'AUTOMATIC' ? (
              <>
                <div data-tip data-for="automaticCollection">
                  <input className="form-check-input widget-9-check parent-checkbox" type="checkbox" disabled />
                </div>
                <Tooltip
                  tooltipId="automaticCollection"
                  place="top"
                  content={() => {
                    return <>Automatic collection type</>;
                  }}
                />
              </>
            ) : (
              <input
                className="form-check-input widget-9-check parent-checkbox"
                type="checkbox"
                checked={selectedCollections?.includes(id)}
                onChange={handleChangeCollection}
              />
            )}
          </div>
        </td>
        <td className="align-middle p-0 w-30px poppins-semibold table-top-border">
          <button
            className={`h-20x w-20x p-2 d-flex align-items-center justify-content-center me-4 bg-transparent border border-transparent w-25px ${
              children && children.length > 0 ? 'cursor-pointer' : 'opacity-0'
            }`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (type !== 'AUTOMATIC') {
                setShowDetail(!showDetail);
              }
            }}
            disabled={type === 'AUTOMATIC'}
          >
            <img src={expandRight} className={`${showDetail ? 'rotatedArrowIcon' : ''}`} />
          </button>
        </td>
        <td className="align-middle table-top-border">
          <FlexBox className="align-items-center d-inline-block name-cell" style={{ marginLeft: `${level * 32}px` }}>
            <div className="symbol me-5">
              <Img
                src={setImageSrc(thumbnailUrl ? generateCompressedImageURL(thumbnailUrl, '260') : DefaultImage)}
                placeholderImg={DefaultImage}
                errorImg={DefaultImage}
                className="object-fit-scale-down border border-light border-2"
              />
            </div>
            <FlexBox className="justify-content-start flex-column d-inline-block collectionName">
              <span className="poppins-regular d-block main-cell no-decoration">{text}</span>
            </FlexBox>
          </FlexBox>
        </td>
        <td className="align-middle table-top-border">{productCount ? productCount : 0}</td>
        <td className="align-middle text-capitalize table-top-border">{type?.toLowerCase()}</td>
        <td className="align-middle ps-0 table-top-border">
          <FlexBox
            className={`p-2 poppins-medium justify-content-center ${selectCollectionStatusClass(status?.toLowerCase())}-status-box`}
          >
            <span>{statusText(status ? status : '')}</span>
          </FlexBox>
        </td>
      </tr>
      {showDetail &&
        collection.children?.map((collectionItem, index) => (
          <CollectionTreeRow
            collection={collectionItem}
            key={index}
            showAllDetails={showAllDetails}
            selectedCollections={selectedCollections}
            setSelectedCollections={setSelectedCollections}
          />
        ))}
    </>
  );
};

export default CollectionTreeRow;
