/* eslint-disable no-nested-ternary */
import { FC, useEffect, useState } from 'react';

// Apollo
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_SHIPPING_RATE, GET_STORE_DETAILS } from 'src/apollo/queries';

// Components
import { Loader } from 'src/components/atoms';
import {
  OrderDetailsBlockFulfillmentCenter,
  PackageAndShippingFulfillmentCenter,
  ShippingRatesFulfillmentCenter,
  Divider
} from 'src/components/molecules';
import { CustomModal } from 'src/components/oraganisms';
import LeaveFulfillmentCenterModal from 'src/components/pages/orders/FulfillmentCenter/LeaveFulfillmentCenterModal';

// Hooks && Utils && Helpers
import { convertWeight } from 'src/utils/weightConverter';

// Icons
import { NoOrderSelected } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { OrderDetailsFulfillmentCenterProps, ShippingRateInputProp } from './OrderDetailsFulfillmentCenter.types';

const OrderDetailsFulfillmentCenter: FC<OrderDetailsFulfillmentCenterProps> = ({
  isLoading,
  orderDetails,
  refetchOrderDetails,
  orderShipment,
  refetchOrderShipment,
  refetchOrderList,
  selectedStatus,
  setSelectedStatus,
  openRefundModalRemotely,
  setOpenRefundModalRemotely,
  setErrorModal,
  setErrorModalObj,
  updateOrderDetails,
  selectedOrder,
  setSelectedOrder
}) => {
  const [unverifiedAddressModal, setUnverifiedAddressModal] = useState(false);
  const [addSignatureConfirmation, setAddSignatureConfirmation] = useState(false);
  const [addShipmentInsurance, setAddShipmentInsurance] = useState(false);
  const [totalOrderValue, setTotalOrderValue] = useState(0.0);
  const [pkgContentDescription, setPkgContentDescription] = useState('');
  const [pkgWeightValue, setPkgWeightValue] = useState(0.0);
  const [showPackageData, setShowPackageData] = useState(false);
  const [pkgUnitValue, setPkgUnitValue] = useState('kg');
  const [useWeightCheck, setUseWeightCheck] = useState(false);
  const [packageData, setPackageData] = useState({
    carrier: '',
    distanceUnit: '',
    height: 0.0,
    length: 0.0,
    massUnit: '',
    name: '',
    token: '',
    type: '',
    width: 0.0,
    weight: 0.0
  });

  const [scannedData, setScannedData] = useState({ weight: '0 Kg', scannedCount: 0 });

  const toggleunVerifiedAddModal = () => {
    setUnverifiedAddressModal(!unverifiedAddressModal);
  };

  const { data: storeDetails } = useQuery(GET_STORE_DETAILS);

  const [getShippingRateData, { data: shippingRateData, loading }] = useLazyQuery(GET_SHIPPING_RATE, {
    onError: (err) => {
      console.error('Shipping rates error -', err.message);
      if (err.message === 'ADDRESS_NOT_VERIFIED') {
        setUnverifiedAddressModal(true);
      } else if (err.message === 'WAREHOUSE_ADDRESS_NOT_VERIFIED') {
        setUnverifiedAddressModal(true);
      }
    }
  });

  useEffect(() => {
    if (orderDetails?.orderDetails?.length) {
      if (orderShipment?.id) {
        setPackageData(JSON.parse(orderShipment.packageInfo));
        setAddShipmentInsurance(orderShipment.hasInsurance);
        if (orderShipment.hasInsurance) {
          setTotalOrderValue(orderShipment.insurance?.amount || 0.0);
          setPkgContentDescription(orderShipment.insurance?.content || '');
        }
        setPkgWeightValue(
          convertWeight(
            Math.round(orderShipment.packageWeight * 100) / 100,
            orderShipment.packageWeightUnit,
            storeDetails?.getStoreDetails?.weightUnit
          )
        );
        setPkgUnitValue(storeDetails?.getStoreDetails?.weightUnit);

        setAddSignatureConfirmation(orderShipment.needsSignatureConfirmation);
        setUseWeightCheck(orderShipment.useWeightOfItems);

        let scannedCount = 0;
        let weight = 0;
        orderDetails?.orderDetails?.map((order) => {
          JSON.parse(order?.items)?.map((item) => {
            const dimension = order?.dimensions ? JSON.parse(order?.dimensions) : {};
            if (+item.shipmentId === +orderShipment?.id) {
              if (item?.isScanned) {
                scannedCount += 1;
              }
              weight += convertWeight(
                +dimension?.weight?.value,
                dimension?.weight?.unit,
                storeDetails?.getStoreDetails?.weightUnit
              );
            }
            return null;
          });
          return null;
        });

        weight = +weight.toFixed(2);
        setScannedData({
          scannedCount,
          weight: `${weight} ${storeDetails?.getStoreDetails?.weightUnit}`
        });
      } else {
        setPackageData({
          carrier: '',
          distanceUnit: '',
          height: 0.0,
          length: 0.0,
          massUnit: '',
          name: '',
          token: '',
          type: '',
          width: 0.0,
          weight: 0.0
        });
        let scannedCount = 0;
        let weight = 0;
        orderDetails?.orderDetails?.map((order) => {
          JSON.parse(order?.items)?.map((item) => {
            const dimension = order?.dimensions ? JSON.parse(order?.dimensions) : {};
            if (!item?.isPresold && !item.shipmentId) {
              if (item?.isScanned) {
                scannedCount += 1;
              }
              weight += convertWeight(
                +dimension?.weight?.value,
                dimension?.weight?.unit,
                storeDetails?.getStoreDetails?.weightUnit
              );
              if (order?.addShipmentInsurance) {
                setAddShipmentInsurance(true);
              }
              if (order?.addSignatureConfirmation) {
                setAddSignatureConfirmation(true);
              }
            }
            return null;
          });
          return null;
        });

        weight = +weight.toFixed(2);

        setPkgWeightValue(weight);
        setPkgUnitValue(storeDetails?.getStoreDetails?.weightUnit);

        setScannedData({
          scannedCount,
          weight: `${weight} ${storeDetails?.getStoreDetails?.weightUnit}`
        });

        if (scannedCount && scannedCount === orderDetails?.availableItemsQuantity) {
          setShowPackageData(true);
        } else {
          setShowPackageData(false);
        }
      }
    }
  }, [orderDetails, orderShipment, storeDetails?.getStoreDetails?.weightUnit]);

  useEffect(() => {
    if (packageData.name && pkgWeightValue > 0 && !orderShipment.id) {
      const input: ShippingRateInputProp = {
        orderId: orderDetails?.id?.toString() || '',
        signatureConfirmation: addSignatureConfirmation,
        parcel: {
          width: packageData.width,
          height: packageData.height,
          length: packageData.length,
          weight: pkgWeightValue,
          distanceUnit: packageData.distanceUnit,
          massUnit: pkgUnitValue
        },
        insurance: {
          amount: totalOrderValue,
          content: pkgContentDescription
        }
      };
      if (!addShipmentInsurance) {
        delete input.insurance;
      }
      getShippingRateData({
        variables: {
          input
        }
      });
    }
  }, [
    packageData,
    pkgUnitValue,
    pkgWeightValue,
    addSignatureConfirmation,
    totalOrderValue,
    pkgContentDescription,
    addShipmentInsurance,
    orderShipment
  ]);

  const closeUnverifiedAddressModal = () => {
    document.body.style.overflow = 'unset';
    setUnverifiedAddressModal(false);
  };

  return (
    <>
      {unverifiedAddressModal && (
        <CustomModal show={unverifiedAddressModal} closeModal={closeUnverifiedAddressModal}>
          <LeaveFulfillmentCenterModal
            title={constVariables.FulfillmentCenter.unverifiedAddModal.title}
            actionBtnTitle={constVariables.FulfillmentCenter.unverifiedAddModal.actionButtonTitle}
            cancelBtnTitle={constVariables.FulfillmentCenter.unverifiedAddModal.cancelButtonTitle}
            message={constVariables.FulfillmentCenter.unverifiedAddModal.message}
            actionBtnHandler={toggleunVerifiedAddModal}
            cancelBtnHandler={closeUnverifiedAddressModal}
          />
        </CustomModal>
      )}
      <div className="d-flex flex-column flex-grow-1">
        <div className=" bg-white rounded flex-column flex-xl-row  w-100 d-flex flex-grow-1">
          {isLoading ? (
            <Loader type="page" className="h-100 card" />
          ) : selectedOrder !== -1 ? (
            <>
              <div className="w-100 w-xl-75 d-flex flex-grow-1">
                <OrderDetailsBlockFulfillmentCenter
                  orderDetails={orderDetails}
                  refetchOrderDetails={refetchOrderDetails}
                  orderShipment={orderShipment}
                  setErrorModal={setErrorModal}
                  setErrorModalObj={setErrorModalObj}
                  updateOrderDetails={updateOrderDetails}
                  setSelectedOrder={setSelectedOrder}
                  scannedCount={scannedData?.scannedCount}
                  weightStr={scannedData?.weight}
                />
              </div>
              <Divider vertical classname="h-100 m-0" />
              <div className=" w-100 w-xl-25 d-flex flex-column flex-grow-1">
                <Divider classname="packageShippingDivider" />
                <div className="d-flex mt-2 mt-xl-0 flex-grow-1 columnSize h-10px">
                  <PackageAndShippingFulfillmentCenter
                    showPackageData={showPackageData}
                    addSignatureConfirmation={addSignatureConfirmation}
                    addShipmentInsurance={addShipmentInsurance}
                    setAddSignatureConfirmation={setAddSignatureConfirmation}
                    setAddShipmentInsurance={setAddShipmentInsurance}
                    totalWeight={orderDetails?.totalWeight || 0}
                    pkgWeightValue={pkgWeightValue}
                    pkgUnitValue={pkgUnitValue}
                    setPkgUnitValue={setPkgUnitValue}
                    setPkgWeightValue={setPkgWeightValue}
                    packageData={packageData}
                    setPackageData={setPackageData}
                    totalOrderValue={totalOrderValue}
                    setTotalOrderValue={setTotalOrderValue}
                    pkgContentDescription={pkgContentDescription}
                    setPkgContentDescription={setPkgContentDescription}
                    useWeightCheck={useWeightCheck}
                    setUseWeightCheck={setUseWeightCheck}
                    orderShipment={orderShipment}
                    orderId={orderDetails?.id}
                  />
                </div>
                <Divider />
                <div className="d-flex flex-grow-1 columnSize h-10px overflow-hidden p-4">
                  <ShippingRatesFulfillmentCenter
                    isLoading={loading}
                    shippingRateList={
                      showPackageData && pkgWeightValue && packageData.carrier ? shippingRateData?.getShippingRates : []
                    }
                    pkgWeightValue={pkgWeightValue}
                    pkgUnitValue={pkgUnitValue}
                    packageData={packageData}
                    insurance={{ amount: totalOrderValue, content: pkgContentDescription }}
                    addShipmentInsurance={addShipmentInsurance}
                    signatureConfirmation={addSignatureConfirmation}
                    useWeightCheck={useWeightCheck}
                    orderId={orderDetails?.id || 0}
                    openRefundModalRemotely={openRefundModalRemotely}
                    setOpenRefundModalRemotely={setOpenRefundModalRemotely}
                    totalProducts={orderDetails?.items || 0}
                    refetchShippingRate={() => {
                      if (packageData.name && pkgWeightValue > 0) {
                        const input: ShippingRateInputProp = {
                          orderId: orderDetails?.id?.toString() || '',
                          signatureConfirmation: addSignatureConfirmation,
                          parcel: {
                            width: packageData.width,
                            height: packageData.height,
                            length: packageData.length,
                            weight: pkgWeightValue,
                            distanceUnit: packageData.distanceUnit,
                            massUnit: pkgUnitValue
                          },
                          insurance: {
                            amount: totalOrderValue,
                            content: pkgContentDescription
                          }
                        };
                        if (!addShipmentInsurance) {
                          delete input.insurance;
                        }
                        getShippingRateData({
                          variables: {
                            input
                          }
                        });
                      }
                    }}
                    orderShipment={orderShipment}
                    refetchOrderShipment={refetchOrderShipment}
                    refetchOrderDetails={refetchOrderDetails}
                    refetchOrderList={refetchOrderList}
                    selectedStatus={selectedStatus}
                    setSelectedStatus={setSelectedStatus}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="w-100 flex-center">
              <img src={NoOrderSelected} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OrderDetailsFulfillmentCenter;
