import React, { FC, useState, createRef, useCallback, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import clsx from 'clsx';

// Apollo
import { useMutation } from '@apollo/client';
import { UPDATE_LOW_STOCK_ALERT } from 'src/apollo/mutations';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { FlexBox } from 'src/components/atoms';
import { FloatingInventoryBoxLayout, EditInventoryLowStockAlert, Portal } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers/components/KTSVG';

// Icons
import { HollowPencilIcon } from 'src/assets/icons';

// Types
import { InventoryLowStockAlertProps } from '../InventoryList.types';
import { UserRoles } from 'src/components/pages/settings/UserPermissions/UserPermissions.type';

const InventoryLowStockAlert: FC<InventoryLowStockAlertProps> = ({
  type,
  id,
  productId,
  quantity,
  showEditIcon,
  updateInventoryList
}) => {
  const lowStockAlertDropdownRef = createRef<any>();
  const [showInput, setShowInput] = useState(false);
  const [disableFloatingSaveButton, setDisableFloatingSaveButton] = useState(false);
  const [floatingValue, setFloatingValue] = useState<string>('1');
  const [positionLowStockMenu, setPositionLowStockMenu] = useState({
    top: 0,
    left: 0
  });

  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setShowInput(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction);

    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction]);

  const openModifyLowStock = (id: number, total: number) => {
    setShowInput(true);
    if (total) {
      setFloatingValue(total.toString());
    } else {
      setFloatingValue('1');
    }

    if (lowStockAlertDropdownRef.current) {
      const position = lowStockAlertDropdownRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - position.bottom;
      setPositionLowStockMenu({
        top: parseInt(spaceBelow < 240 ? window.scrollY + position.top - 245 : window.scrollY + position.top + 40, 10),
        left: parseInt(position.left, 10) - 190
      });
    }
  };

  const [updateLowStockMut] = useMutation(UPDATE_LOW_STOCK_ALERT, {
    onCompleted(response) {
      // refresh && refresh();
      const updatedInventory = JSON.parse(response.updateLowStockAlert.additionalData);
      updateInventoryList &&
        updateInventoryList(updatedInventory, type === 'PRODUCT' ? 'alertThreshold' : 'variantAlertThreshold', productId);
      setDisableFloatingSaveButton(false);
      setShowInput(false);
    },
    onError(err) {
      setDisableFloatingSaveButton(false);
      setShowInput(false);
      console.error('Update low stock error - ', err);
    }
  });

  const updateLowStockPath = (id: number, quantity: number, type: string) => {
    setDisableFloatingSaveButton(true);
    updateLowStockMut({
      variables: {
        input: {
          id,
          quantity,
          type
        }
      }
    });
  };

  return (
    <FlexBox className="align-items-center">
      <div className="d-flex align-items-center justify-content-center ">{quantity ? quantity : '-'}</div>
      {role !== UserRoles.FULFILMENT_ASSOCIATE && (
        <div className="position-relative p-4">
          {showEditIcon && (
            <div
              onClick={() => {
                openModifyLowStock(id, quantity);
              }}
              className={`${showInput ? 'pencilSelected' : 'pencilHover'} p-8 rounded m-l-8`}
              ref={lowStockAlertDropdownRef}
            >
              <KTSVG
                path={HollowPencilIcon}
                svgColor={showInput ? '#00A3FF' : ''}
                className="cursor-pointer d-flex align-items-center"
              />
            </div>
          )}
        </div>
      )}
      {showInput ? (
        <Portal id="kt_body">
          <OutsideClickHandler
            onOutsideClick={() => {
              if (showInput) {
                setShowInput(false);
              }
            }}
          >
            <div
              className={clsx('menu menu-sub menu-background menu-sub-dropdown menu-column menu-rounded position-absolute', {
                show: showInput
              })}
              style={{ top: positionLowStockMenu.top, left: positionLowStockMenu.left, width: 220 }}
            >
              <FloatingInventoryBoxLayout
                disableSaveButton={disableFloatingSaveButton || !floatingValue}
                onCancelClick={() => {
                  setShowInput(false);
                }}
                title="Edit low stock alert"
                onActionClick={() => {
                  updateLowStockPath(id, parseInt(floatingValue, 10), type);
                }}
              >
                <EditInventoryLowStockAlert
                  id={id}
                  type={type}
                  value={floatingValue}
                  setValue={setFloatingValue}
                  updateLowStockPath={updateLowStockPath}
                />
              </FloatingInventoryBoxLayout>
            </div>
          </OutsideClickHandler>
        </Portal>
      ) : null}
    </FlexBox>
  );
};

export default React.memo(InventoryLowStockAlert);
