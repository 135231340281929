import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';

// Appollo
import { useMutation } from '@apollo/client';
import { GET_MEDIA_UPLOAD_URL } from 'src/apollo/mutations';

// Components
import { Img, Loader } from 'src/components/atoms';
import { CustomModal, ErrorModal } from 'src/components/oraganisms';
import { FileInput } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { setImageSrc } from 'src/utils/setImageSrc';

// Icons
import { AddVariantImageSmallIcon, CloseIcon, DefaultImage, VariantImageCheckboxIcon, AcceptDraggedFile } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { VariantImagesModalProps } from './Modal.types';

// Styles
import './modalStyles.scss';

const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.svg|\.webp|\.heif)$/i;

const VariantImagesModal: FC<VariantImagesModalProps> = ({
  closeImagesModalFun,
  changeVariantImage,
  selectedImageURL,
  productMedia,
  updateProductMedia
}) => {
  const [selectedImage, setSelectedImage] = useState(-1);
  const [isDragActive, setIsDragActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showFileTypeErrorModal, setShowTypeErrorModal] = useState(false);
  const [accessLoading, setAccessLoading] = useState(false);
  const [totalProductImages, setTotalProductImages] = useState<any[]>(productMedia);
  const [loadingFilesCount, setLoadingFilesCount] = useState<number>(0);

  useEffect(() => {
    setTotalProductImages(productMedia);
  }, [productMedia]);

  const selectImageFun = (id: number) => {
    setSelectedImage(id);
  };
  const closeandClear = () => {
    closeImagesModalFun();
    setSelectedImage(-1);
  };
  const changeImage = () => {
    if (selectedImage !== -1 && productMedia && productMedia[selectedImage]) {
      changeVariantImage(productMedia[selectedImage]);
      closeandClear();
    }
  };

  useEffect(() => {
    if (selectedImageURL) {
      productMedia?.forEach((data, id) => {
        if (data.url === selectedImageURL) {
          selectImageFun(id);
        }
      });
    }
  }, [selectedImageURL]);

  const [getMediaUploadUrl] = useMutation(GET_MEDIA_UPLOAD_URL, {
    onError: (error) => {
      console.log('error', error);
    }
  });

  const getImageUrl = async (item, file) => {
    const { uploadURL } = item;
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': file.type
      },
      body: file
    };

    try {
      await fetch(uploadURL, requestOptions);
    } catch (err) {
      console.log('error', err);
    }
  };

  const handleSetFiles = async (files) => {
    setAccessLoading(true);
    setLoadingFilesCount(files.length);
    for (let i = 0; i < files.length; i++) {
      if (!allowedExtensions.exec(files[i].name)) {
        setIsDragActive(false);
        setErrorMessage(
          `${files[i].name} is not a supported file type. Upload files ending with: jpg, jpeg, png, svg, webP, heif.`
        );
        setShowTypeErrorModal(true);
        setAccessLoading(false);
        setLoadingFilesCount(0);
        return;
      }
    }

    const res = await getMediaUploadUrl({
      variables: {
        input: {
          urlCount: files.length,
          fileType: 'IMAGE',
          uploadType: 'PRODUCT'
        }
      }
    });

    files = Object.keys(files).map((key) => files[key]);

    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      const file = files[i];
      reader.onloadend = () => {
        files[i] = { ...files[i], fileimage: reader.result, filesize: files[i].size };
      };
      if (files[i]) {
        reader.readAsDataURL(file);
      }
    }

    const data = res.data;
    if (data.getMediaUploadUrl) {
      try {
        await Promise.all(data.getMediaUploadUrl.map(async (item, index) => await getImageUrl(item, files[index])));
      } catch (error) {
        setAccessLoading(false);
        console.log(error);
      } finally {
        setAccessLoading(false);
        setLoadingFilesCount(0);
      }

      updateProductMedia(
        data.getMediaUploadUrl.map((item, index) => {
          return { productMediaUrl: item.accessURL, fileimage: files[index].fileimage };
        })
      );
    }
  };

  const handleProductFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    handleSetFiles(files);
  };

  const handleActionErrorModal = () => {
    setErrorMessage('');
    closeErrorModal();
  };

  const closeErrorModal = () => {
    document.body.style.overflow = 'unset';
    setShowTypeErrorModal(false);
  };

  return (
    <>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Select Variant Image</h5>
          <div className="btn btn-xs btn-active-light-primary p-0 m-0 border-none" onClick={closeandClear}>
            <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
          </div>
        </div>
        <div
          className={clsx('modal-body', {
            mediaGreyText: true
          })}
        >
          <p>You can only select a single image as variant media.</p>
          <div className="variantImagesGridContainer">
            <FileInput
              className="variantAddImagesGridImageContainer p-0"
              handleSetFiles={handleSetFiles}
              isDragActive={isDragActive}
              setIsDragActive={setIsDragActive}
            >
              <div className="variantAddImagesGridImage">
                <input type="file" className="file-upload-input" onChange={handleProductFileChange} multiple />
                <img src={!isDragActive ? AddVariantImageSmallIcon : AcceptDraggedFile} />
                <div className="variantAddUploadImagesText">Drop files here or click to upload</div>
              </div>
            </FileInput>
            {accessLoading &&
              loadingFilesCount > 0 &&
              [...Array(loadingFilesCount)].map((item, index) => (
                <div key={index} className="variantImagesGridImageContainer">
                  <Loader type="page" className="loading-card" />
                </div>
              ))}
            {totalProductImages?.map((data, index) => (
              <div className="variantImagesGridImageContainer" key={index}>
                <div
                  onClick={() => {
                    selectImageFun(index);
                  }}
                  className={
                    selectedImage === index ? 'variantImagesGridImageContainerTwoSelected' : 'variantImagesGridImageContainerTwo'
                  }
                >
                  {selectedImage === index ? <img className="variantImagesCheckbox" src={VariantImageCheckboxIcon} /> : null}
                  <Img
                    className="variantImagesGridImage"
                    src={setImageSrc(data?.fileimage ? data?.fileimage : data?.productMediaUrl)}
                    placeholderImg={DefaultImage}
                    errorImg={DefaultImage}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-outlined-secondary btn-md" data-bs-dismiss="modal" onClick={closeandClear}>
            Cancel
          </button>
          <button
            disabled={selectedImage === -1 ? true : false}
            type="button"
            className="btn btn-primary btn-md"
            onClick={changeImage}
          >
            Done
          </button>
        </div>
      </div>
      {showFileTypeErrorModal && (
        <CustomModal bodyClassname="w-90 w-md-150" show={showFileTypeErrorModal} closeModal={closeErrorModal}>
          <ErrorModal
            message={constVariables.common.onSaveError.message}
            actionTitle={constVariables.common.onSaveError.action}
            actionHandler={handleActionErrorModal}
            errors={[errorMessage]}
          />
        </CustomModal>
      )}
    </>
  );
};

export default VariantImagesModal;
