/* eslint-disable no-nested-ternary */
import { FC, useEffect, useState, createRef } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useMutation, useQuery } from '@apollo/client';
import { GET_TAGS_LIST } from 'src/apollo/queries';
import { DELETE_TAG, UPDATE_TAG } from 'src/apollo/mutations';

// Components
import { CustomModal, DeleteConfirmModal } from 'src/components/oraganisms';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { useToast } from 'src/utils/hooks/useToast';

// Icons
import { VeritalDotsIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { TagMenuItemProps } from './TagAndCollectionDropdown.types';

const TagMenuItem: FC<TagMenuItemProps> = ({
  item,
  type,
  changeAppliedItems,
  data,
  showDropdown,
  handleDeleteCustomerTag,
  isEditAndDeletable
}) => {
  const { showToast } = useToast();
  const menuIconRef = createRef<any>();
  const [isShowActionMenus, setIsShowActionMenus] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [isShowInput, setIsShowInput] = useState(false);
  const [tagName, setTagName] = useState(item.name);
  const [error, setError] = useState('');
  const [isShowMoreIcon, setIsShowMoreIcon] = useState(false);
  const [shouldShowUp, setShouldShowUp] = useState(false);

  const showActionMenus = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsShowActionMenus(!isShowActionMenus);

    if (menuIconRef.current) {
      const menuBottomPosition = menuIconRef.current.getBoundingClientRect().bottom;
      const dropdownElement = document.getElementsByClassName('tags-menu-dropdown');
      if (dropdownElement) {
        const dropdownBottomPosition = dropdownElement[0].getBoundingClientRect().bottom;
        if (dropdownBottomPosition - menuBottomPosition < 90) {
          setShouldShowUp(true);
        }
      }
    }
  };

  useEffect(() => {
    if (!showDropdown) {
      setIsShowActionMenus(false);
      setShouldShowUp(false);
      setIsShowInput(false);
      setIsShowMoreIcon(false);
      setConfirmModal(false);
    }
  }, [showDropdown]);

  useEffect(() => {
    setTagName(item.name);
  }, [item.name]);

  const { refetch: refetchTagData } = useQuery(GET_TAGS_LIST, {
    variables: {
      input: {
        filters: {
          searchText: ''
        }
      }
    }
  });

  const [deleteTag] = useMutation(DELETE_TAG, {
    onError: (error) => {
      showToast({
        errorText: error.message,
        message: `Error occured while deleting Tag: ${error.message}`
      });
    },
    onCompleted: (data) => {
      if (data) {
        refetchTagData();
        showToast({ successText: 'Tag deleted successfully', message: `The Tag has been deleted sucessfully` });
      }
    }
  });

  const [updateTag] = useMutation(UPDATE_TAG, {
    onError: (error) => {
      showToast({
        errorText: error.message,
        message: `Error occured while updating Tag: ${error.message}`
      });
    },
    onCompleted: (data) => {
      if (data) {
        if (data.updateTag) {
          setIsShowInput(false);
          refetchTagData();
          showToast({ successText: 'Tag updated successfully', message: `The Tag has been updated sucessfully` });
        }
      }
    }
  });

  const handleDeleteTag = () => {
    deleteTag({
      variables: {
        input: {
          id: item.id
        }
      }
    });
    closeConfirmModal();
  };

  const handleChangeTag = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isExistedTag = data?.find((item) => item.name.toLowerCase() === e.target.value.toLowerCase());
    if (isExistedTag) {
      setError('Tag already exists');
    } else {
      setError('');
    }
    setTagName(e.target.value);
  };

  const handleUpdateTag = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (error) {
      return;
    }
    if (item.name === tagName) {
      // setError(`Tag already exists`);
      return;
    }
    if (e.key === 'Enter') {
      updateTag({
        variables: {
          input: {
            id: item.id,
            name: tagName,
            type: 'TAG'
          }
        }
      });
    }
  };

  const handleDeleteOption = () => {
    if (type === 'Tag') {
      setIsShowActionMenus(false);
      setConfirmModal(true);
    }
    if (type === 'Customer Tag' && handleDeleteCustomerTag) {
      handleDeleteCustomerTag(item.id);
    }
  };

  const closeConfirmModal = () => {
    document.body.style.overflow = 'unset';
    setConfirmModal(false);
  };

  return (
    <>
      <div className={`menu-item ${item?.disabled ? 'disabled-item' : ''}`}>
        {!isEditAndDeletable ? (
          <a
            className="menu-link dropdown-item"
            onClick={() => {
              changeAppliedItems(item, type, 'add');
            }}
          >
            <span className="tag-name poppins-regular">{item.name}</span>
          </a>
        ) : (
          <>
            {isShowInput ? (
              <div className="mt-2 mb-2">
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setIsShowInput(false);
                    setIsShowMoreIcon(false);
                  }}
                >
                  <input
                    type="text"
                    value={tagName}
                    className={`border-none ${error ? 'error' : ''}`}
                    onChange={handleChangeTag}
                    onKeyDown={handleUpdateTag}
                    autoFocus
                  />
                  {error && <span className="mt-1 error-message poppins-regular">{error}</span>}
                </OutsideClickHandler>
              </div>
            ) : (
              <a
                className={`menu-link dropdown-item ${isShowActionMenus ? 'active' : ''}`}
                onClick={() => {
                  changeAppliedItems(item, type, 'add');
                }}
                onMouseEnter={() => setIsShowMoreIcon(true)}
                onMouseLeave={() => {
                  if (!isShowActionMenus) {
                    setIsShowMoreIcon(false);
                  }
                }}
              >
                <span className="tag-name poppins-regular">{item.name}</span>
                {isShowMoreIcon && (
                  <span onClick={(e) => showActionMenus(e)} className="more-action-icon" ref={menuIconRef}>
                    <KTSVG path={VeritalDotsIcon} className={`action-icon ${isShowActionMenus ? 'active' : ''}`} />
                  </span>
                )}
              </a>
            )}
          </>
        )}
        <OutsideClickHandler
          onOutsideClick={() => {
            setIsShowActionMenus(false);
            setShouldShowUp(false);
          }}
        >
          {isShowActionMenus && (
            <div className={`tags-action-menu ${shouldShowUp ? 'up-direction' : ''}`}>
              <div
                className="cursor-pointer poppins-regular"
                onClick={() => {
                  setIsShowActionMenus(false);
                  setShouldShowUp(false);
                  setIsShowInput(true);
                }}
              >
                {constVariables.common.tags.edit}
              </div>
              <div className="cursor-pointer poppins-regular" onClick={handleDeleteOption}>
                {constVariables.common.tags.delete}
              </div>
            </div>
          )}
        </OutsideClickHandler>
      </div>
      {confirmModal && (
        <CustomModal bodyClassname="w-90 w-md-150" show={confirmModal} closeModal={closeConfirmModal}>
          <DeleteConfirmModal
            title={constVariables.common.tags.title}
            message={`${
              item.productIds !== undefined
                ? item.productIds?.length > 0
                  ? `This tag is assigned to ${item.productIds?.length} product${
                      item.productIds?.length > 1 ? 's' : ''
                    } at the moment. Are you sure you want to delete it?`
                  : `Are you sure you want to delete this ${type}?`
                : `Are you sure you want to delete this ${type}?`
            } This can’t be undone.`}
            actionBtnTitle="Yes, Delete"
            cancelBtnTitle="Cancel"
            actionBtnHandler={handleDeleteTag}
            cancelBtnHandler={closeConfirmModal}
          />
        </CustomModal>
      )}
    </>
  );
};

export default TagMenuItem;
