import { gql } from '@apollo/client';

export const GET_RETURN_CONDITIONS = gql`
  query GetReturnConditions @api(name: "productsAPI") {
    getReturnConditions {
      id
      name
      operations
    }
  }
`;
