import { useRef, useState } from 'react';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { toBlobURL } from '@ffmpeg/util';
import { Parser } from 'm3u8-parser';
import axios, { CancelTokenSource } from 'axios';
import { FileData } from '@ffmpeg/ffmpeg/dist/esm/types';

// Hooks && Utils && Helpers
import { createHash } from '../encrypt/hash';
import { useToast } from 'src/utils/hooks/useToast';

interface IUseFfmpeg {
  downloadHls: (uri: string, title: string, duration: number) => Promise<void>;
  onCancel: () => Promise<void>;
  progress: number;
  isProcessing: boolean;
  processingFileHash: string | null;
}

interface IUseFfmpegInput {
  onComplete: (fileHash: string) => void;
}

const useFfmpeg = ({ onComplete }: IUseFfmpegInput): IUseFfmpeg => {
  const { CancelToken } = axios;

  const { showToast } = useToast();
  const [progress, setProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [processingFileHash, setProcessingFileHash] = useState(null);
  const intervalRef = useRef<NodeJS.Timer>();
  const [cancelTokenSources, setCancelTokenSources] = useState<CancelTokenSource[]>([]);
  const ffmpegRef = useRef(new FFmpeg());

  const FFMPEG_LOADED = 'ffmpeg Loaded';

  const load = async (totalProgress: any, splitProgressBy: number, duration: number) => {
    totalProgress.load = 0;
    const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.4/dist/umd';
    const ffmpeg = ffmpegRef.current;
    ffmpeg.on('progress', ({ time }) => {
      const completedTime = Math.ceil(time / 1000000);
      let completedPercent = (completedTime / duration) * 100;
      completedPercent = completedPercent > 100 ? 100 : completedPercent;
      totalProgress.conversion = completedPercent / splitProgressBy;
    });
    // toBlobURL is used to bypass CORS issue, urls with the same
    // domain can be used directly.
    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
      wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm')
    });

    totalProgress.load = 100 / splitProgressBy;
    return FFMPEG_LOADED;
  };

  const getm3u8Contents = async (url) => {
    const m3u8File = await fetch(url);
    const m3u8Text = await m3u8File.text();
    const parser = new Parser();
    parser.push(m3u8Text);
    parser.end();
    return parser.manifest;
  };

  const getByteRangesFromSegments = (segments) => {
    const startSegemts = segments?.filter(({ uri }) => uri === segments[0]?.uri);

    const byteRanges = {};

    if (segments[0]?.byterange) {
      byteRanges[startSegemts[0]?.uri] = {
        start: startSegemts[0]?.byterange?.offset,
        end: startSegemts[startSegemts.length - 1]?.byterange?.length + startSegemts[startSegemts.length - 1]?.byterange?.offset
      };

      byteRanges[segments[segments.length - 1]?.uri] = {
        start: 0,
        end: segments[segments.length - 1]?.byterange?.length + segments[segments.length - 1]?.byterange?.offset
      };
    }
    return byteRanges;
  };

  const downloadSegementsFromLocation = async (location, i, url, byteRanges, totalProgress, splitProgresssBy) => {
    const headers = {};
    const urlArr = url.split('/');

    const segmentUrl = url.replace(urlArr[urlArr.length - 1], location);

    // eslint-disable-next-line no-extra-boolean-cast
    if (!!byteRanges[location]) {
      // eslint-disable-next-line dot-notation
      headers['Range'] = `bytes=${byteRanges[location].start}-${byteRanges[location].end}`;
    }

    const cancelTokenSource = CancelToken.source();
    setCancelTokenSources((s) => [...s, cancelTokenSource]);

    const segmentResponse = await axios.get(segmentUrl, {
      headers,
      responseType: 'arraybuffer',
      onDownloadProgress: (progressEvent) => {
        const currentProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        totalProgress[i] = currentProgress / splitProgresssBy;
      },
      cancelToken: cancelTokenSource.token
    });

    const segment = new Uint8Array(segmentResponse.data);

    return segment;
  };

  const downloadFileFromData = (data: FileData, title: string = 'Buysocial recording') => {
    // @ts-ignore
    const newObject = URL.createObjectURL(new Blob([data.buffer], { type: 'video/mp4' }));
    const a = document.createElement('a');
    a.href = newObject;
    a.download = `${title}.mp4`;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(newObject);
    document.body.removeChild(a);
  };

  const downloadHls = async (url: string, title: string, duration: number): Promise<void> => {
    if (!url) {
      throw Error('url not found');
    }

    const urlHash = createHash(url);

    try {
      setProgress(0);
      setIsProcessing(true);
      setProcessingFileHash(urlHash);

      let parsedManifest = await getm3u8Contents(url);
      let segmentsLocation = '';
      if (parsedManifest?.mediaGroups?.VIDEO) {
        const maxQual = Object.keys(parsedManifest?.mediaGroups?.VIDEO).sort()[0];
        const location = parsedManifest?.playlists?.filter(({ uri }) => uri.includes(maxQual))[0]?.uri;
        const urlArr = url.split('/');
        segmentsLocation = url.replace(urlArr[urlArr.length - 1], location);
        parsedManifest = await getm3u8Contents(segmentsLocation);
      }

      if (parsedManifest?.segments) {
        const segments = parsedManifest?.segments;
        const byteRanges = getByteRangesFromSegments(parsedManifest?.segments);

        intervalRef.current = setInterval(() => {
          let progress = 0;
          Object.values(totalProgress).forEach((val) => {
            progress += val as number;
          });
          setProgress(progress);
        }, 1000);

        // @ts-ignore
        let segmentLocation: string[] = [...new Set(segments?.map(({ uri }: { uri: string }) => uri))];

        const ffmpeg = ffmpegRef.current;

        const totalProgress = {};

        const splitProgressBy = segmentLocation.length + 2;

        const allPromises: any[] = segmentLocation.map((location, index) =>
          downloadSegementsFromLocation(location, index, segmentsLocation, byteRanges, totalProgress, splitProgressBy)
        );

        if (!ffmpegRef?.current?.loaded) {
          allPromises.push(load(totalProgress, splitProgressBy, duration));
        }

        const segmentResult = await Promise.all(allPromises);

        segmentResult.map(async (result, i) => {
          if (result === FFMPEG_LOADED) {
            return;
          }

          return await ffmpeg.writeFile(`segment_${i}.ts`, result);
        });

        // Write the concat input to a file
        const concatInput = segmentLocation.map((_, i) => `file segment_${i}.ts`).join('\n');
        await ffmpeg.writeFile('input.txt', new TextEncoder().encode(concatInput));

        // Run ffmpeg command to concatenate segments and output as mp4
        await ffmpeg.exec(['-f', 'concat', '-safe', '0', '-i', 'input.txt', '-c', 'copy', 'output.mp4'], 600000);
        const data = await ffmpeg.readFile('output.mp4');

        downloadFileFromData(data, title);

        // Show the success notification for downloading recording
        showToast({
          successText: `<span style="color: #1e2749;">Recording downloaded <span> <span style="color: #50cd89;"> successfully!</span>`,
          message: '',
          width: 470
        });
      } else {
        throw new Error('Media segments not found');
      }
    } catch (err: any) {
      if (err.name === 'AbortError') {
        console.log('Conversion aborted - ', err.message); // "`Message # ID was aborted`"
      } else if (axios.isCancel(err)) {
        console.log('Download canceled:', err.message);
      } else {
        console.log(err);
      }
    } finally {
      await clearInterval(intervalRef.current);
      setIsProcessing(false);
      setProcessingFileHash(null);
      setProgress(0);
      onComplete(urlHash);
      await ffmpegRef?.current?.terminate();
    }
  };

  const onCancel = async () => {
    if (cancelTokenSources.length) {
      await cancelTokenSources.map((tokenSource) => tokenSource.cancel());
    }

    if (ffmpegRef?.current?.loaded) {
      ffmpegRef.current.terminate();
    }
    await clearInterval(intervalRef.current);
  };

  return { downloadHls, onCancel, processingFileHash, progress, isProcessing };
};

export default useFfmpeg;
