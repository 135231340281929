import { gql } from '@apollo/client';

export const DELETE_USER_TAG = gql`
  mutation DeleteUserTag($input: DeleteUserTagInput!) @api(name: "productsAPI") {
    deleteUserTag(input: $input) {
      id
      success
      message
    }
  }
`;
