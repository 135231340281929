import { gql } from '@apollo/client';

export const UPDATE_INVITE_DETAILS = gql`
  mutation UpdateInviteDetails($input: updateInviteDetailsInput!) @api(name: "authAPI") {
    updateInviteDetails(input: $input) {
      success
      message
      userId
      additionalData
    }
  }
`;
