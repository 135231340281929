import { FC } from 'react';

// Components
import { Img } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';

// Icons
import { DefaultImage } from 'src/assets/icons';

// Styles
import './purchaseOrderCard.scss';
interface PurchaseOrderCardProps {
  title?: string;
  logo?: any;
  purchaseOrder?: string;
  items?: string;
  price?: string;
  ownerImg?: string;
  ownerName?: string;
  date?: string;
}

const PurchaseOrderCard: FC<PurchaseOrderCardProps> = ({ title, logo, purchaseOrder, items, price, ownerImg, ownerName, date }) => {
  return (
    <div className="my-2 purchase-order-card">
      <div className="d-flex p-4  flex-column">
        <div className="d-flex align-items-center mb-2">
          <Img
            className="my-auto me-2 card-image-top"
            src={setImageSrc(logo)}
            placeholderImg={DefaultImage}
            errorImg={DefaultImage}
          />
          <span className="text-bold">{title}</span>
        </div>
        <span className="text-medium mb-1">
          <span className="title text">{'P. order: '}</span>
          {purchaseOrder}
        </span>
        <span className="text-medium ">
          <span className="title text">{`${items} items: `}</span>${price}
        </span>
      </div>
      <span className="w-100 breaker" />
      <div className="w-100 p-4 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <Img
            className="my-auto me-2 card-image-bottom"
            src={setImageSrc(ownerImg || '')}
            placeholderImg={DefaultImage}
            errorImg={DefaultImage}
          />
          <span className="text-bold">{ownerName}</span>
        </div>
        <span className="text title pe-0 fs-8">{date}</span>
      </div>
    </div>
  );
};

export default PurchaseOrderCard;
