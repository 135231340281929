import { createPortal } from 'react-dom';
import usePortal from 'src/utils/UsePortal/usePortal';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Portal = ({ id, children }) => {
  const target = usePortal(id);
  return createPortal(children, target);
};

export default Portal;
