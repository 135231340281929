import { gql } from '@apollo/client';

export const GET_PRODUCT_TYPES = gql`
  query GET_PRODUCT_TYPES @api(name: "productsAPI") {
    getAllProductTypes {
      id
      name
      storeId
      totalProducts
      createdAt
      updatedAt
      __typename
    }
  }
`;
