/* eslint-disable camelcase */
/* eslint-disable no-console */
import React, { PropsWithChildren } from 'react';
import { CSVBoxButton } from '@csvbox/react';
import { useHistory } from 'react-router-dom';

// Hooks && Utils && Helpers
import { getAccountDetailsFromToken } from 'src/utils/authentication/decodeToken';
import { CSVboxTemplate, getCSVboxLicenseKey } from 'src/utils/csvbox/csvBoxKey';

// Types
import { CSVboxModalProps } from './CSVboxModal.types';

// Styles
import './CSVboxModal.scss';

const CSVboxModal: React.FC<PropsWithChildren<CSVboxModalProps>> = (props) => {
  const history = useHistory();

  const accountInfo = getAccountDetailsFromToken();
  return (
    <CSVBoxButton
      licenseKey={getCSVboxLicenseKey(CSVboxTemplate.PRODUCTS)}
      user={{
        user_id: accountInfo.userId,
        store_id: accountInfo.storeId
      }}
      onImport={(result) => {
        if (result) {
          history.go(0);
        } else {
          console.error('fail');
          // custom code
        }
      }}
      render={(launch) => {
        return props.child(launch);
      }}
    >
      {props.children}
    </CSVBoxButton>
  );
};

export default CSVboxModal;
