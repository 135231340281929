import { useState } from 'react';

export const useStream: any = () => {
  const [isLive, setIsLive] = useState(false);
  const [streamLoading, setStreamLoading] = useState(false);

  const startStream = async (ingestServer, streamKey, client, handleError) => {
    let timer;
    try {
      client.config.ingestEndpoint = ingestServer;
      timer = setTimeout(() => {
        handleError(
          "It's taking longer than ususal to start the stream. Check the status of your internet connection and disable any active VPNs."
        );
        setIsLive(false);
        setStreamLoading(false);
      }, 10000);

      setStreamLoading(true);

      await client.getAudioContext().resume();
      await client.startBroadcast(streamKey);

      clearTimeout(timer);
      setIsLive(true);
      setStreamLoading(false);
    } catch (err: any) {
      switch (err.code) {
        case 10000:
          handleError(
            `Error starting stream: Your stream settings are misconfigured. This is likely caused by mismatched channel types. The Channel type you selected:  must match the channel type of your Amazon IVS Channel.`
          );
          break;
        default:
          handleError(`Error starting stream: ${err.message}.`);
          break;
      }
      if (timer) clearTimeout(timer);
      setIsLive(false);
      setStreamLoading(false);
    }
  };

  const stopStream = async (client, handleError) => {
    try {
      await client.stopBroadcast();
      setIsLive(false);
    } catch (err: any) {
      handleError(`${err.name}: ${err.message}`);
    }
  };

  const toggleStream = async (ingestServer, streamKey, client, handleError) => {
    if (isLive) {
      stopStream(client, handleError);
    } else {
      startStream(ingestServer, streamKey, client, handleError);
    }
  };

  return {
    isLive,
    streamLoading,
    toggleStream,
    stopStream,
    startStream
  };
};
