/* eslint-disable no-nested-ternary */
import { FC, useMemo, Fragment } from 'react';

// Components
import { Tooltip } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers/components/KTSVG';

// Icons
import { InfoIcon } from 'src/assets/icons';

// Types
import { TIKTOK_PRODUCT_STATUS, TikTokProduct } from '../TiktokIntegration.types';

interface StatusCellProps {
  data: TikTokProduct;
  handleShowResponse: (recommendation) => void;
}

const StatusCell: FC<StatusCellProps> = ({ data, handleShowResponse }) => {
  const { status, error, category } = data;
  const renderStatusText = (status) => {
    switch (status) {
      case TIKTOK_PRODUCT_STATUS.PROCESSING:
        return 'Processing';
      case TIKTOK_PRODUCT_STATUS.UPDATES_REQUIRED:
        return 'Update';
      case TIKTOK_PRODUCT_STATUS.IN_REVIEW:
        return 'In Review';
      case 'DRAFT':
        return 'Ready';
      case TIKTOK_PRODUCT_STATUS.FAILED:
        return 'Failed';
      case TIKTOK_PRODUCT_STATUS.SUSPENDED:
        return 'Suspended';
      case TIKTOK_PRODUCT_STATUS.QUEUED:
        return 'In Queue';
      default:
        return '';
    }
  };

  const selectProductStatusClass = (productStatus: string | undefined) => {
    switch (productStatus) {
      case TIKTOK_PRODUCT_STATUS.PROCESSING:
        return 'processing';
      case TIKTOK_PRODUCT_STATUS.UPDATES_REQUIRED:
        return 'update';
      case TIKTOK_PRODUCT_STATUS.DRAFT:
        return 'ready';
      case TIKTOK_PRODUCT_STATUS.IN_REVIEW:
        return 'in-review';
      case TIKTOK_PRODUCT_STATUS.QUEUED:
        return 'in-queue';
      default:
        return '';
    }
  };

  const handleStatus = () => {
    if (error && (status === TIKTOK_PRODUCT_STATUS.UPDATES_REQUIRED || status === TIKTOK_PRODUCT_STATUS.DRAFT)) {
      handleShowResponse(data);
    }
  };

  const renderStatusTooltip = useMemo(() => {
    switch (status) {
      case TIKTOK_PRODUCT_STATUS.UPDATES_REQUIRED:
        return !category ? (
          'Select a category.'
        ) : (
          <Fragment>
            <span>Click to check errors and</span>
            <br />
            <span className="m-b-8">TikTok suggestions.</span>
          </Fragment>
        );
      case TIKTOK_PRODUCT_STATUS.DRAFT:
        return (
          <Fragment>
            <span>Click to check TikTok</span>
            <br />
            <span className="m-b-8">improvement suggestions.</span>
          </Fragment>
        );
      default:
        return null;
    }
  }, [status, category]);

  const isVisibleRecommendation = useMemo(() => {
    if (error && (status === TIKTOK_PRODUCT_STATUS.UPDATES_REQUIRED || status === TIKTOK_PRODUCT_STATUS.DRAFT)) return true;
    return false;
  }, [error, status]);

  const isDisableTooltip = useMemo(() => {
    if (status === TIKTOK_PRODUCT_STATUS.UPDATES_REQUIRED || (status === TIKTOK_PRODUCT_STATUS.DRAFT && error)) return false;
    return true;
  }, [error, status]);

  return (
    <div className="tiktok-sync-status">
      <div
        className={`${selectProductStatusClass(status)}-status-box sync-status-box ${
          isVisibleRecommendation ? 'recommendation-box cursor-pointer' : ''
        }`}
        onClick={handleStatus}
        data-tip={isDisableTooltip}
        data-for={!isDisableTooltip ? (!category ? 'selectCategoryTooltip' : 'tiktokSyncStatusIndicator') : null}
      >
        {renderStatusText(status)}
        {isVisibleRecommendation && <KTSVG path={InfoIcon} className={`m-l-8 ${selectProductStatusClass(status)}-arrow`} />}
      </div>
      {!isDisableTooltip && (
        <Tooltip
          tooltipId={!category ? 'selectCategoryTooltip' : 'tiktokSyncStatusIndicator'}
          place="top"
          content={() => {
            return <>{renderStatusTooltip}</>;
          }}
        />
      )}
    </div>
  );
};

export default StatusCell;
