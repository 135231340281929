import { FC } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

// Hooks && Utils && Helpers
import { pascalCaseConversion } from 'src/utils/convertText';
import { renderOrderIcon } from 'src/utils/renderOrderIcon';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { orderDetailsData } from 'src/components/oraganisms/OrdersList/OrdersList.types';
import { TimelineData } from './OrderDetails.types';

// Styles
import './OrderDetails.scss';

interface OrderDetailsTimeLineProps {
  tempdata?: number;
  orderDetails: orderDetailsData;
  timeLineData: TimelineData[];
}

const OrderDetailsTimeLine: FC<OrderDetailsTimeLineProps> = ({ orderDetails, timeLineData }) => {
  const renderDescription = (timeline) => {
    if (timeline?.heading?.toLowerCase().includes('printed')) {
      const metaData = JSON.parse(timeline?.metaData);
      return (
        <>
          {timeline?.heading.charAt(0).toUpperCase() + timeline?.heading.slice(1).toLowerCase()} by{' '}
          <Link to={`/customers/customerDetails/${metaData.userId}`} target="_blank" className="text-primary text-capitalize">
            {metaData.userName}
          </Link>
        </>
      );
    }
    return timeline?.description;
  };

  return (
    <>
      <div>
        <h4 className="section-title m-b-32">{pascalCaseConversion(constVariables.orders.timeline)}</h4>
        {timeLineData?.length > 0 &&
          timeLineData?.map((timeline, index) => (
            <div key={timeline.id}>
              <div className="row mt-5">
                <div className="col-7">
                  <img src={renderOrderIcon(timeline.heading.toLowerCase())} className="order-timeline-icon" />
                  <span className="statusText">{timeline.heading}</span>
                </div>
                <div className="col-5">
                  <p className="float-right greyText">{moment(timeline.time).format('D MMM YYYY, hh:mm a')}</p>
                </div>
              </div>
              <div className={timeLineData?.length > index + 1 ? 'row' : 'd-none'}>
                <div className="col-1 timeLineVertical v2"></div>
                <div className="col-10">
                  <span
                    className={`poppins-medium mailStatus ${timeline?.heading.includes('combined') ? 'text-primary' : 'greyText'}`}
                  >
                    {renderDescription(timeline)}
                  </span>
                </div>
              </div>
            </div>
          ))}
        {/* <div className="input-group mb-3 mt-5">
          <form className="w-100">
            <input
              type="text"
              className="form-control form-control-solid timelineForm"
              placeholder={constVariables.orders.placeholder}
              aria-label="Username"
            />
            <img src={Smiley} className="mx-2 formButtons" />
            <img src={PaperClip} className="mx-2" />
            <button className="input-group-text postBtn btn-primary form-control-solid">{constVariables.orders.post}</button>
          </form>
        </div> */}
      </div>
    </>
  );
};

export default OrderDetailsTimeLine;
