import { gql } from '@apollo/client';

export const PUSH_PRODUCT_TO_TIKTOK = gql`
  mutation PushProductToTikTok($input: AddProductToTiktokInput!) @api(name: "tiktokAPI") {
    pushProductToTikTok(input: $input) {
      id
      name
      imageUrl
      inventory
      category {
        id
        l1
        l2
        l3
      }
      status
      error
    }
  }
`;
