import { gql } from '@apollo/client';

export const CONNECT_AVALARA_CONFIGURATION = gql`
  mutation ConnectAvalaraAccount($input: ConnectAvalaraAccountInput!) @api(name: "authAPI") {
    connectAvalaraAccount(input: $input) {
      __typename
      isConnected
      companyCode
      companyName
      companyCountry
      isSandbox
      defaultShippingTaxCode
      defaultProductTaxCode
      allowDocumentRecording
      isAvalaraEnabled
      availableCompanies {
        companyCode
        name
      }
    }
  }
`;
