import { gql } from '@apollo/client';

export const FORGET_PASSWORD = gql`
  mutation MyMutation($input: ForgetPasswordInput!) @api(name: "authAPI") {
    forgetPassword(input: $input) {
      success
      message
      userId
    }
  }
`;
