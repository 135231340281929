import { gql } from '@apollo/client';

export const GET_VIEWERS_DETAILS = gql`
  query GetViewers_Details($input: GetgetViewersDetailsInput!) @api(name: "liveStreamingAPI") {
    getViewersDetails(input: $input) {
      __typename
      id
      firstName
      profileUrl
      amountSpent
      noOfCartItems
      isFirstTimeCustomer
      isVIPCustomer
      cartProgress
      isUserBlocked
      isLiveBlocked
      fetchedAt
    }
  }
`;
