import { FC } from 'react';

// Components
import { FlexBox } from 'src/components/atoms';
import { CommonProductsBlock } from 'src/components/molecules';
import LiveProductsPreview from '../LiveProductsPreview/LiveProductsPreview';

// Types
import { CreateLiveShowProductsStepProps } from './CreateLiveShowProductsStep.types';

// Styles
import './_createLiveShowProductsStep.scss';

const CreateLiveShowProductsStepPresentational: FC<CreateLiveShowProductsStepProps> = ({
  selectedProductList,
  bucketList,
  handleSelectedProducts,
  removeProduct,
  handleSelectedItem,
  onProductDragEnd
}) => {
  const handleUpdateSelectedProductIDs = (updatedProducts) => {
    handleSelectedItem && handleSelectedItem(updatedProducts);
  };
  const handleAddProductsToLivePreview = (updatedProducts) => {
    handleSelectedProducts(updatedProducts);
  };

  return (
    <div className="create-live-show-detail-step-div create-live-show-products-step-presentational">
      <FlexBox className="content">
        <div className="product-list-view">
          <div className="w-100 p-x-32 p-t-32">
            <CommonProductsBlock
              selectedProductIDs={bucketList ? bucketList.map((product) => product?.productId) : []}
              handleUpdateSelectedProductIDs={handleUpdateSelectedProductIDs}
              shouldShowVariants={true}
              isShowingOnModal={true}
              isLiveshow={true}
              disabledIds={selectedProductList.map((product) => product?.productId)}
              isShowOnlyActiveProducts={true}
              handleAddProductsToLivePreview={handleAddProductsToLivePreview}
            />
          </div>
        </div>
        <div className="products-preview-div">
          <LiveProductsPreview
            productList={selectedProductList}
            hidePlaceholderText={true}
            removeProduct={(productId) => removeProduct && removeProduct(productId)}
            onProductDragEnd={onProductDragEnd}
          />
        </div>
      </FlexBox>
    </div>
  );
};
export default CreateLiveShowProductsStepPresentational;
