import React, { useState } from 'react';
import { SignUpBySocial } from 'src/assets/images';
import { CaretLeft } from 'src/assets/icons';
import { constVariables, envUrls } from 'src/constants';
import './_forgotPassword.scss';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';
import { FORGET_PASSWORD } from 'src/apollo/mutations';
import { maskedEmail } from 'src/utils/maskEmail';

const ForgotPassword: React.FC = () => {
  const [genericMsg, setGenericMsg] = useState('');
  const history = useHistory();
  if (!history?.location?.state?.detail) {
    history.goBack();
  }
  const { email } = history?.location?.state?.detail;

  const handleBack = () => {
    history.goBack();
  };

  const [forgetPassword, { loading }] = useMutation(FORGET_PASSWORD, {
    onCompleted: () => {
      setGenericMsg('Reset Link sent Successfully');
    },
    onError: (e) => {
      console.error('resetpassword onError - ', e);
      console.error('e?.graphQLErrors[0] - ', e?.graphQLErrors[0]);
      setGenericMsg('Some Error occured!');
    }
  });

  const handleSendAgain = () => {
    forgetPassword({
      context: {
        headers: {
          tenantid: '',
          'x-api-key': envUrls.authXApiKey
        }
      },
      variables: {
        input: {
          email,
          isBackStoreUser: true
        }
      }
    });
  };

  return (
    <>
      <div className="signup d-flex gradient flex-row ">
        <div className="form-container padding-style">
          {/* left panel */}
          <div className={`p-8rem container-height container-width overflow-hidden bg-white content-container height-container`}>
            <div className="btn-back" onClick={handleBack}>
              <img src={CaretLeft} alt="Back" />
              <p>Back</p>
            </div>
            <img src={SignUpBySocial} alt={SignUpBySocial} className="image-dimension brand-logo" />
            <div className={`text-danger fs-8`}>{genericMsg}</div>
            <p className="poppins-regular title-style m-0 auth-header ">
              <p>{constVariables.signUp.checkYourMail}</p>
            </p>

            <div className="content-section">
              <span>{constVariables.signUp.resetLinkSent}</span>
              <span className="email-sentence">
                {constVariables.signUp.pleaseClickOnTheLinkSentTo}
                <span className="email-text">{maskedEmail(email)}</span>
              </span>
            </div>

            <div className="p-text">{constVariables.signUp.didntGetVerificationLink}</div>

            <div className="vh-5">
              <div className="button-container">
                <div className="button margin-style">
                  <button
                    disabled={loading}
                    onClick={handleSendAgain}
                    className="btn btn-primary social-btn w-100 button-padding flex-center"
                  >
                    {constVariables.signUp.sendAgain}
                    {loading && <div className="spinner-border text-primary auth-flow"></div>}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
