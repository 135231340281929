import React, { FC } from 'react';
import CustomModalPopup from '../CustomModalPopup/CustomModalPopup';

interface DeleteNotePopupProps {
  title: string;
  message: string;
  cancelButton: string;
  confirmButton: string;
  open: boolean;
  handleConfirmDelete(e: React.MouseEvent): void;
  closeModal(): void;
}

const DeleteNotePopup: FC<DeleteNotePopupProps> = (props) => {
  const { title, message, cancelButton, confirmButton, open, handleConfirmDelete, closeModal } = props;
  return (
    <CustomModalPopup
      icon={true}
      title={title}
      open={open}
      message={message}
      cancelButton={cancelButton}
      confirmButton={confirmButton}
      handleConfirm={handleConfirmDelete}
      closeModal={closeModal}
    />
  );
};

export default DeleteNotePopup;
