import React, { FC, useEffect, useState, memo } from 'react';

import { DefaultImage } from 'src/assets/icons';
interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  placeholderImg?: string;
  errorImg?: string;
}
const Img: FC<ImageProps> = ({ src, placeholderImg, errorImg, ...props }) => {
  const [imgSrc, setSrc] = useState(placeholderImg || src);
  // const onLoad = useCallback(() => {
  //   setSrc(src);
  // }, [src]);
  // const onError = useCallback(() => {
  //   setSrc(errorImg || placeholderImg);
  // }, [errorImg, placeholderImg]);
  // useEffect(() => {
  //   const img = new Image();
  //   img.src = src as string;
  //   img.addEventListener('load', onLoad);
  //   img.addEventListener('error', onError);
  //   return () => {
  //     img.removeEventListener('load', onLoad);
  //     img.removeEventListener('error', onError);
  //   };
  // }, [src, onLoad, onError]);

  useEffect(() => {
    if (src !== '') {
      setSrc(src);
    } else {
      setSrc(placeholderImg || DefaultImage);
    }
  }, [src]);

  return <img {...props} alt={imgSrc} src={imgSrc} onError={() => setSrc(errorImg)} loading="lazy" />;
};

export default memo(Img);
