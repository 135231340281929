import { gql } from '@apollo/client';

export const ADD_USER_NOTE = gql`
  mutation AddUserNote($input: AddUserNoteInput!) @api(name: "productsAPI") {
    addUserNote(input: $input) {
      id
      note
      creatorName
      creatorDp
      updatedAt
    }
  }
`;
