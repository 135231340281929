import { FC } from 'react';

// Components
import EditColumnPresentational from './EditColumn.presentational';

// Types
import { EditColumnProps } from './EditColumn.types';

const EditColumn: FC<EditColumnProps> = ({ columns, show, setEditColumnData, allSwitchable }) => {
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const arr = Array.from(columns);
    const [removed] = arr.splice(result.source.index, 1);
    arr.splice(result.destination.index, 0, removed);
    setEditColumnData(arr);
  };

  const changeStatus = (index: number, status: boolean) => {
    const arr = Array.from(columns);
    const obj = arr[index];
    obj.status = status;
    arr[index] = obj;
    setEditColumnData([...arr]);
  };

  return (
    <EditColumnPresentational
      columns={columns}
      show={show}
      onDragEnd={onDragEnd}
      changeStatus={changeStatus}
      allSwitchable={allSwitchable}
    />
  );
};

export default EditColumn;
