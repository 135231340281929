import { gql } from '@apollo/client';

export const CREATE_MANIFEST = gql`
  mutation createManifest @api(name: "ordersAPI") {
    createManifest {
      numberOfManifests
      success
    }
  }
`;
