import { gql } from '@apollo/client';

export const GET_STORE_STREAM_CONFIGURATION = gql`
  query GetStoreStreamConfiguration @api(name: "liveStreamingAPI") {
    getStoreStreamConfiguration {
      medialiveChannelState
      liveVideo {
        isStreaming
        arn
        streamKey
        ingestServer
        playbackUrl
      }
      previewVideo {
        isStreaming
        arn
        streamKey
        ingestServer
        playbackUrl
      }
      runningLiveId
      runningStreamSource
      storeId
      message
      isLiveOnApp
      isLiveOnFB
      liveStartedAt
      nextShowBlockedTill
    }
  }
`;
