import { FC, useState } from 'react';

import { Checkbox, EmojiInput } from 'src/components/molecules';
import { CreateLiveShowNotificationsProps } from './CreateLiveShowNotifications';

// Icons
import { RoundWarningIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { LiveNotifications } from '../CreateLiveShowDetailStep/CreateLiveShowDetailStep.types';

// Styles
import './_createLiveShowNotifications.scss';

const CreateLiveShowNotificationsPresentational: FC<CreateLiveShowNotificationsProps> = ({
  liveShowDetailInfo,
  liveShowInfoError,
  updateLiveShowInfo,
  textMessageToggle,
  setTextMessageToggle,
  appNotificationToggle,
  setAppNotificationToggle,
  messangerToggle,
  setMessangerToggle
}) => {
  // State Params
  const { facebookChannels } = liveShowDetailInfo;

  const [notificationObj, setNotificationObj] = useState<LiveNotifications>(liveShowDetailInfo.notification);
  const updateData = (fieldName, fieldValue) => {
    setNotificationObj({ ...notificationObj, [fieldName]: fieldValue });
    updateGlobalData(fieldName, fieldValue);
  };
  const updateGlobalData = (fieldName, fieldValue) => {
    updateLiveShowInfo({
      ...liveShowDetailInfo,
      notification: { ...liveShowDetailInfo.notification, [fieldName]: fieldValue }
    });
  };

  return (
    <div className="create-liveshow-notifications-step p-10">
      <p className="poppnis-semibold fw-500 fs-4">{constVariables.LiveShow.notificationStep.notificationsInChannels}</p>
      {/* Text Message */}
      <div className="mt-10">
        <Checkbox
          tagclassname="poppins-regular fw-500 checkbox-title"
          labelclassname="pb-3"
          value={textMessageToggle}
          onChangeValue={(value) => {
            if (textMessageToggle) {
              // updateData('textMessage', '');
            } else {
              updateData('textMessage', notificationObj.textMessage);
            }
            setTextMessageToggle(!textMessageToggle);
          }}
          name={constVariables.LiveShow.notificationStep.textMessage}
        />
        <EmojiInput
          isTextArea={true}
          isError={liveShowInfoError.notificationTextMessageError !== ''}
          errorMessage={liveShowInfoError.notificationTextMessageError}
          title={constVariables.LiveShow.notificationStep.description}
          placeholder={constVariables.LiveShow.notificationStep.description}
          isMandatory={true}
          titleClass={`input-label-class poppins-medium fw-500`}
          emojiClass={'notifications-emoji'}
          value={textMessageToggle ? notificationObj.textMessage : ''}
          showCount={true}
          isDisabled={!textMessageToggle}
          showHover={!textMessageToggle}
          messageOnHover={constVariables.LiveShow.notificationStep.textMessageHoverMsg}
          max={constVariables.LiveShow.notificationStep.descriptionCount}
          onChangeText={(text) => updateData('textMessage', text)}
        ></EmojiInput>
      </div>
      {/* App notifications */}
      <div className="mt-10">
        <Checkbox
          tagclassname="poppins-semibold fw-500 checkbox-title"
          labelclassname="pb-3"
          value={appNotificationToggle}
          onChangeValue={(value) => {
            if (appNotificationToggle) {
              // updateData('app', { title: '', description: '' });
            } else {
              updateData('app', {
                title: notificationObj.app.title,
                description: notificationObj.app.description
              });
            }
            setAppNotificationToggle(!appNotificationToggle);
          }}
          name={constVariables.LiveShow.notificationStep.appNotification}
        />
        <div>
          <EmojiInput
            title={constVariables.LiveShow.notificationStep.title}
            titleClass={`input-label-class poppins-medium fw-500`}
            placeholder={constVariables.LiveShow.notificationStep.title}
            isError={liveShowInfoError.notificationAppTitleError !== ''}
            errorMessage={liveShowInfoError.notificationAppTitleError}
            isMandatory={true}
            value={appNotificationToggle ? notificationObj.app.title : ''}
            emojiClass={'notifications-emoji'}
            showCount={true}
            isDisabled={!appNotificationToggle}
            showHover={!appNotificationToggle}
            messageOnHover={constVariables.LiveShow.notificationStep.appNotificationHoverMsg}
            max={constVariables.LiveShow.notificationStep.appNotificationTitleCount}
            onChangeText={(text) => updateData('app', { title: text, description: notificationObj.app.description })}
          ></EmojiInput>
        </div>
        <EmojiInput
          isTextArea={true}
          title={constVariables.LiveShow.notificationStep.description}
          titleClass={`input-label-class poppins-medium fw-500`}
          placeholder={constVariables.LiveShow.notificationStep.description}
          value={appNotificationToggle ? notificationObj.app.description : ''}
          emojiClass={'notifications-emoji'}
          showCount={true}
          isDisabled={!appNotificationToggle}
          showHover={!appNotificationToggle}
          messageOnHover={constVariables.LiveShow.notificationStep.appNotificationHoverMsg}
          max={constVariables.LiveShow.notificationStep.appNotificationDescriptionCount}
          onChangeText={(text) => updateData('app', { title: notificationObj.app.title, description: text })}
        ></EmojiInput>
      </div>
      {/* Facebook Post */}
      <div className="mt-10">
        <p className="poppnis-semibold fw-500 fs-4">{constVariables.LiveShow.notificationStep.facebookPost}</p>
        {facebookChannels?.length === 0 && (
          <div className="facebook-post-warning">
            <img src={RoundWarningIcon} />
            <span className="ps-2 warning-text">{constVariables.LiveShow.notificationStep.facebookPostWarning}</span>
          </div>
        )}
        <EmojiInput
          title={constVariables.LiveShow.notificationStep.title}
          titleClass={`input-label-class poppins-medium fw-500`}
          placeholder={constVariables.LiveShow.notificationStep.title}
          isMandatory={true}
          value={notificationObj.fb.title}
          emojiClass={'notifications-emoji'}
          showCount={true}
          isDisabled={facebookChannels?.length === 0}
          // showHover={true}
          messageOnHover={constVariables.LiveShow.notificationStep.facebookPostHoverMsg}
          max={constVariables.LiveShow.notificationStep.facebookPostTitleCount}
          onChangeText={(text) => updateData('fb', { title: text, description: notificationObj.fb.description })}
          isError={liveShowInfoError.facebookPostTitleError !== ''}
          errorMessage={liveShowInfoError.facebookPostTitleError}
        ></EmojiInput>
        <EmojiInput
          isTextArea={true}
          isMandatory={true}
          title={constVariables.LiveShow.notificationStep.description}
          titleClass={`input-label-class poppins-medium fw-500`}
          placeholder={constVariables.LiveShow.notificationStep.description}
          value={notificationObj.fb.description}
          emojiClass={'notifications-emoji'}
          showCount={true}
          isDisabled={facebookChannels?.length === 0}
          // showHover={true}
          // messageOnHover={constVariables.LiveShow.notificationStep.facebookPostHoverMsg}
          max={constVariables.LiveShow.notificationStep.descriptionCount}
          onChangeText={(text) => updateData('fb', { title: notificationObj.fb.title, description: text })}
          isError={liveShowInfoError.facebookPostDescriptionError !== ''}
          errorMessage={liveShowInfoError.facebookPostDescriptionError}
        ></EmojiInput>
      </div>
      {/* Messanger notifications */}
      <div className="mt-10">
        <Checkbox
          tagclassname="poppins-semibold fw-500 checkbox-title"
          labelclassname="pb-3"
          value={messangerToggle}
          onChangeValue={(value) => {
            if (messangerToggle) {
              // updateData('messenger', { description: '' });
            } else {
              updateData('messenger', { description: notificationObj.messenger.description });
            }
            setMessangerToggle(!messangerToggle);
          }}
          name={constVariables.LiveShow.notificationStep.messangerInNotification}
        />
        <EmojiInput
          title={constVariables.LiveShow.notificationStep.description}
          titleClass={`input-label-class poppins-medium fw-500`}
          placeholder={constVariables.LiveShow.notificationStep.description}
          isMandatory={true}
          isError={liveShowInfoError.notificationMessengerError !== ''}
          errorMessage={liveShowInfoError.notificationMessengerError}
          isTextArea={true}
          emojiClass={'notifications-emoji'}
          value={messangerToggle ? notificationObj.messenger.description : ''}
          showCount={true}
          isDisabled={!messangerToggle}
          showHover={!messangerToggle}
          messageOnHover={constVariables.LiveShow.notificationStep.messangerHoverMsg}
          max={constVariables.LiveShow.notificationStep.descriptionCount}
          onChangeText={(text) => updateData('messenger', { title: '', description: text })}
        ></EmojiInput>
      </div>
    </div>
  );
};

export default CreateLiveShowNotificationsPresentational;
