import { gql } from '@apollo/client';

export const SAVE_SHIPPING_ADDRESS = gql`
  mutation changeAdressOfOrder($input: ChangeOrderAddressInput!) @api(name: "ordersAPI") {
    changeOrderAddress(input: $input) {
      success
      message
    }
  }
`;
