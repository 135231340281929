/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useState } from 'react';
import ReactDragListView from 'react-drag-listview/lib/index.js';

// Components
import { Img } from 'src/components/atoms';
import RecordingItem from './RecordingItem';
import RecordingPreviewSlideModal from './RecordingPreviewSlideModal';
import EmptyMedias from './EmptyMedias';

// Icons
import { FilmIcon, DefaultImage, EmptyRecordingsIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { RecordingData } from 'src/components/pages/products/AddProduct/AddProduct.types';

// Styles
import './RecordingDnd.scss';

interface RecordingDndProps {
  recordings: RecordingData[];
  primaryVideoId: string;
  setPrimaryVideoId: React.Dispatch<React.SetStateAction<string>>;
  setIsAutoAssignPrimaryVideo: React.Dispatch<React.SetStateAction<boolean>>;
}

const RecordingDnd: FC<RecordingDndProps> = ({ recordings, primaryVideoId, setPrimaryVideoId, setIsAutoAssignPrimaryVideo }) => {
  const { DragColumn } = ReactDragListView;
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isPrimaryRecordingEmpty, setIsPrimaryRecordingEmpty] = useState(true);
  const [recordingsList, setRecordingsList] = useState<RecordingData[]>(recordings ? recordings : []);

  useEffect(() => {
    if (recordings) {
      const primaryRecording = recordings.find((recording) => recording.id === primaryVideoId);
      if (primaryRecording) {
        setIsPrimaryRecordingEmpty(false);
      }
    }
  }, [recordings, primaryVideoId]);

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      if (fromIndex !== recordingsList.length) {
        const data = [...recordingsList];
        const item = data?.splice(fromIndex, 1)[0];
        data?.splice(toIndex, 0, item);
        if (fromIndex === 0) {
          setIsPrimaryRecordingEmpty(true);
          setPrimaryVideoId('');
        } else if (toIndex === 0) {
          setIsPrimaryRecordingEmpty(false);
          if (item?.id) {
            setPrimaryVideoId(item.id);
          }
        }
        setRecordingsList(data);
      }
    },
    nodeSelector: 'li',
    handleSelector: 'li'
  };

  const handleShowPreviewModal = (index: number) => {
    setShowPreviewModal(true);
    setCurrentSlide(index);
    document.body.classList.add('modal-open');
  };

  const handleCloseModal = () => {
    document.body.classList.remove('modal-open');
    setShowPreviewModal(false);
  };

  const handleUpdateCurrentSlide = (index: number) => {
    setCurrentSlide(index);
  };

  return (
    <div className="simple simple1 simple3 imgDnd">
      {recordings?.length > 0 ? (
        <div className="simple-inner">
          <ol className={recordings.length > 0 ? '' : 'hidden'}>
            <DragColumn {...dragProps}>
              {recordingsList.length > 0 && (
                <>
                  {isPrimaryRecordingEmpty && (
                    <li className="primary-video-placeholder">
                      <Img src={FilmIcon} placeholderImg={DefaultImage} errorImg={DefaultImage} />
                      <p className="mt-6 mb-0">Choose a Primary Video</p>
                    </li>
                  )}
                  {recordingsList.map((item, index) => (
                    <RecordingItem
                      key={index}
                      recording={item}
                      index={index}
                      handleShowPreviewModal={handleShowPreviewModal}
                      primaryVideoId={primaryVideoId}
                      setPrimaryVideoId={setPrimaryVideoId}
                      setIsPrimaryRecordingEmpty={setIsPrimaryRecordingEmpty}
                      onDragEnd={dragProps.onDragEnd}
                      setIsAutoAssignPrimaryVideo={setIsAutoAssignPrimaryVideo}
                      // toggle={toggle}
                      // selectedImage={selectedImage}
                    />
                  ))}
                </>
              )}
            </DragColumn>
          </ol>
        </div>
      ) : (
        <EmptyMedias
          icon={EmptyRecordingsIcon}
          title={constVariables.common.productmedia.emptyRecordingsTitle}
          description={constVariables.common.productmedia.emptyRecordingsDescription}
        />
      )}
      {recordings.length > 0 && showPreviewModal && (
        <RecordingPreviewSlideModal
          show={showPreviewModal}
          currentSlide={currentSlide}
          recordings={recordings}
          handleCloseModal={handleCloseModal}
          handleUpdateCurrentSlide={handleUpdateCurrentSlide}
        />
      )}
    </div>
  );
};

export default RecordingDnd;
