import { FC, useCallback, useRef, useState } from 'react';
import moment from 'moment';

// Components
import CreateLiveShowDetailStepPresentational from './CreateLiveShowDetailStep.presentational';

// Types
import { CreateLiveShowDetailProps } from './CreateLiveShowDetailStep.types';

const CreateLiveShowDetailStep: FC<CreateLiveShowDetailProps> = ({
  liveShowDetailInfo,
  liveShowInfoError,
  updateLiveShowDetailInfo,
  updateLiveShowInfoErrors
}) => {
  const [dateValue, setDateValue] = useState<Date>(liveShowDetailInfo.date);
  const [startDateMoment, setStartDateMoment] = useState<any>();
  const [endDateMoment, setEndDateMoment] = useState<any>();
  const stateRef: any = useRef();
  stateRef.current = liveShowDetailInfo;

  const handleSendSuccessURL = useCallback(
    (res: any) => {
      updateLiveShowDetailInfo({ ...liveShowDetailInfo, thumbnailUrl: res });
    },
    [liveShowDetailInfo, updateLiveShowDetailInfo]
  );

  const removeThumbnailHandler = (): void => {
    updateLiveShowDetailInfo({ ...liveShowDetailInfo, thumbnailUrl: {} });
  };

  // Validate start-end time to check 8 hours limit
  const validateTimes = (): boolean => {
    try {
      // let errorMessage = '';

      if (liveShowInfoError.startingAtError !== '' || liveShowInfoError.endingAtError !== '') {
        updateLiveShowInfoErrors({ ...liveShowInfoError, startingAtError: '', endingAtError: '', timeDurationError: '' });
        // return false;
      }
      if (stateRef.current.startingAt && stateRef.current.endingAt) {
        // Getting Actual Values of start and end time
        const startTimeValue = stateRef.current.startingAt.slice(0, stateRef.current.startingAt.length - 2).split(':');
        const endTimeValue = stateRef.current.endingAt.slice(0, stateRef.current.endingAt.length - 2).split(':');

        // Getting times period which selected by User
        const startTimePeriod = stateRef.current.startingAt.slice(
          stateRef.current.startingAt.length - 2,
          stateRef.current.startingAt.length
        );
        const endTimePeriod = stateRef.current.endingAt.slice(
          stateRef.current.endingAt.length - 2,
          stateRef.current.endingAt.length
        );

        startTimeValue[0] = startTimeValue[0] === '12' ? Number(0) : Number(startTimeValue[0]);
        startTimeValue[0] = startTimePeriod === 'PM' ? startTimeValue[0] + 12 : startTimeValue[0];
        endTimeValue[0] = endTimeValue[0] === '12' ? Number(0) : Number(endTimeValue[0]);
        endTimeValue[0] = endTimePeriod === 'PM' ? endTimeValue[0] + 12 : endTimeValue[0];

        // Converting start time in date object with values
        const startDate = new Date(dateValue);
        startDate.setHours(startTimeValue[0]);
        startDate.setMinutes(startTimeValue[1]);

        // Converting end time in date object with values
        const endDate = new Date(dateValue);
        endDate.setHours(endTimeValue[0]);
        endDate.setMinutes(endTimeValue[1]);

        if (endDate < startDate) {
          endDate.setDate(endDate.getDate() + 1);
        }

        const startDateMoment = moment(startDate.getTime());
        const endDateMoment = moment(endDate.getTime());
        updateLiveShowDetailInfo({ ...stateRef?.current, startDateMoment, endDateMoment });

        const difference = endDateMoment.diff(startDateMoment, 'hours', true); // 1.75

        let startingAtError = '';
        let endingAtError = '';

        const now = new Date();

        if (now.getTime() > startDate.getTime() && stateRef.current.date.getDate() <= now.getDate()) {
          startingAtError = 'Invalid Time';
        }

        if (now.getTime() > endDate.getTime() && stateRef.current.date.getDate() <= now.getDate()) {
          endingAtError = 'Invalid Time';
        }

        if (!!startingAtError || !!endingAtError) {
          updateLiveShowInfoErrors({
            ...liveShowInfoError,
            startingAtError,
            endingAtError,
            timeDurationError: ''
          });
          return false;
        }

        if (endDate?.toISOString() === startDate?.toISOString() || endDate.getTime() < startDate.getTime()) {
          updateLiveShowInfoErrors({
            ...liveShowInfoError,
            startingAtError,
            endingAtError,
            timeDurationError: 'End time should be higher than start time'
          });
          return false;
        }

        if (
          difference > 8 ||
          endDate.getHours() - startDate.getHours() > 8 ||
          (endDate.getHours() - startDate.getHours() === 8 && endDate.getMinutes() - startDate.getMinutes() > 0)
        ) {
          updateLiveShowInfoErrors({
            ...liveShowInfoError,
            startingAtError,
            endingAtError,
            timeDurationError: `Liveshow duration can't exceed 8 hours`
          });
          return false;
        }

        setStartDateMoment(startDateMoment);
        setEndDateMoment(endDateMoment);
      }
      const data = { ...liveShowInfoError, startingAtError: '', endingAtError: '', timeDurationError: '' };
      updateLiveShowInfoErrors(data);
      return true;
    } catch (err) {
      console.log({ err });
      return false;
    }
  };
  const updateStartTime = (timeValue: string) => {
    updateLiveShowDetailInfo({ ...liveShowDetailInfo, startingAt: timeValue });
  };
  const updateEndTime = (timeValue: string) => {
    updateLiveShowDetailInfo({ ...liveShowDetailInfo, endingAt: timeValue });
  };
  const updateDateValue = (dateValue: Date) => {
    updateLiveShowDetailInfo({ ...liveShowDetailInfo, date: dateValue });
    setDateValue(dateValue);
  };

  return (
    <CreateLiveShowDetailStepPresentational
      handleSendSuccessURL={handleSendSuccessURL}
      liveShowDetailInfo={liveShowDetailInfo}
      liveShowInfoError={liveShowInfoError}
      updateLiveShowDetailInfo={updateLiveShowDetailInfo}
      updateLiveShowInfoErrors={updateLiveShowInfoErrors}
      removeThumbnail={removeThumbnailHandler}
      updateDate={updateDateValue}
      updateStartTime={updateStartTime}
      updateEndTime={updateEndTime}
      validateTimes={validateTimes}
      startDateMoment={startDateMoment}
      endDateMoment={endDateMoment}
    />
  );
};

export default CreateLiveShowDetailStep;
