import { FC, useEffect, useState } from 'react';

// Apollo
import { useLazyQuery } from '@apollo/client';
import { GET_PRODUCT_TYPES, GET_VENDORS, GET_TAGS_LIST, GET_COLLECTIONS } from 'src/apollo/queries';

// Components
import RulesEngineConditionsPresentational from './RulesEngineConditions.presentational';

// Types
import { CollectionConditionProps } from './RulesEngineConditions.types';

// Styles
import './_rulesEngineCondition.scss';

const RulesEngineConditions: FC<CollectionConditionProps> = ({
  rulesEngineConditions,
  fieldDropdown,
  conditionDropdownArray,
  allConditionMatch,
  setRulesEngineConditions,
  setConditionDropdownArray,
  setAllConditionMatch,
  setFormChanges,
  setRulesChanges,
  rulesChanges,
  saveModuleRules
}) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const [getTagsList, { data: tagsDropdownData }] = useLazyQuery(GET_TAGS_LIST, {});

  useEffect(() => {
    if (tagsDropdownData?.getTagList) {
      const arr = Array.from(conditionDropdownArray);
      const obj = Object.assign({}, arr[activeIndex]);
      obj.categoryDropdown = tagsDropdownData?.getTagList;
      arr[activeIndex] = obj;
      setConditionDropdownArray([...arr]);
    }
  }, [tagsDropdownData]);

  const [getCollections, { data: collectionsDropdownData }] = useLazyQuery(GET_COLLECTIONS, {});

  useEffect(() => {
    if (collectionsDropdownData?.getCollections?.collections) {
      const arr = Array.from(conditionDropdownArray);
      const obj = Object.assign({}, arr[activeIndex]);
      obj.categoryDropdown = collectionsDropdownData?.getCollections?.collections;
      arr[activeIndex] = obj;
      setConditionDropdownArray([...arr]);
    }
  }, [collectionsDropdownData]);

  const [getProductTypeData, { data: typeDropdownData }] = useLazyQuery(GET_PRODUCT_TYPES, {});

  useEffect(() => {
    if (typeDropdownData?.getAllProductTypes) {
      const arr = Array.from(conditionDropdownArray);
      const obj = Object.assign({}, arr[activeIndex]);
      obj.categoryDropdown = typeDropdownData?.getAllProductTypes;
      arr[activeIndex] = obj;
      setConditionDropdownArray([...arr]);
    }
  }, [typeDropdownData]);

  const [getVendorData, { data: vendorDropdownData }] = useLazyQuery(GET_VENDORS, {});

  useEffect(() => {
    if (vendorDropdownData?.getVendorDetails?.vendors) {
      const arr = Array.from(conditionDropdownArray);
      const obj = Object.assign({}, arr[activeIndex]);
      obj.categoryDropdown = vendorDropdownData?.getVendorDetails?.vendors;
      arr[activeIndex] = obj;
      setConditionDropdownArray([...arr]);
    }
  }, [vendorDropdownData]);

  const changeAppliedItems = (item: { id: number; name: string }, type: string, action: string) => {
    const conditionArr = Array.from(rulesEngineConditions);
    const obj: {
      field: { id: number };
      condition: string;
      category: string | { name: string; id: number }[];
      openFieldDropdown?: boolean;
      openCategoryDropdown?: boolean;
      openConditionDropdown?: boolean;
    } = Object.assign({}, conditionArr[activeIndex]);
    const categoryArr =
      obj?.category?.length > 0 ? Array.from(typeof obj.category !== 'string' ? obj.category : []) : Array.from([]);
    if (action === 'remove') {
      const i = categoryArr.indexOf(item);
      categoryArr.splice(i, 1);
    } else {
      categoryArr.push(item);
    }
    obj.category = categoryArr;
    conditionArr[activeIndex] = obj;
    setRulesEngineConditions([...conditionArr]);
  };

  const openDropdown = (index: number, key: string, value?: boolean) => {
    const arr = Array.from(rulesEngineConditions);
    const obj = arr[index];
    obj[key] = typeof value === 'boolean' ? value : !obj[key];
    arr[index] = obj;
    setRulesEngineConditions([...arr]);
  };

  const openTagCollectionTypeDropdown = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <RulesEngineConditionsPresentational
      rulesEngineConditions={rulesEngineConditions}
      setRulesEngineConditions={setRulesEngineConditions}
      fieldDropdown={fieldDropdown}
      conditionDropdownArray={conditionDropdownArray}
      setConditionDropdownArray={setConditionDropdownArray}
      allConditionMatch={allConditionMatch}
      setAllConditionMatch={setAllConditionMatch}
      setActiveIndex={setActiveIndex}
      getTagsList={getTagsList}
      getCollections={getCollections}
      changeAppliedItems={changeAppliedItems}
      getProductTypeData={getProductTypeData}
      getVendorData={getVendorData}
      openDropdown={openDropdown}
      openTagCollectionTypeDropdown={openTagCollectionTypeDropdown}
      setFormChanges={setFormChanges}
      setRulesChanges={setRulesChanges}
      rulesChanges={rulesChanges}
      saveModuleRules={saveModuleRules}
    />
  );
};

export default RulesEngineConditions;
