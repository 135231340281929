import { FC } from 'react';
import moment from 'moment';

// Components
import { FlexBox } from 'src/components/atoms';
import { Checkbox, InputBox, ShopifySyncTooltip } from 'src/components/molecules';

// Icons
import { ShopifyIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { GeneralCreateCouponSection } from './GeneralInfoCreateCoupon.types';

const GeneralInfoCreateCoupon: FC<GeneralCreateCouponSection> = ({
  maxLength,
  generalInfo,
  errorMessages,
  setGeneralInfo,
  validateInput
}) => {
  const changeName = (e: string) => {
    setGeneralInfo((prev) => ({ ...prev, value: e.trimStart() }));
  };
  const changeAutomaticDiscount = () => {
    setGeneralInfo((prev) => ({ ...prev, automaticDiscount: !prev.automaticDiscount }));
  };

  const handleKeyCoupontInput = (event) => {
    if (event.keyCode === 13) {
      validateInput(event.target.value, 'generalInfoTitleError');
    }
  };

  const handleBlurCouponInput = (event) => {
    validateInput(event.target.value, 'generalInfoTitleError');
  };

  return (
    <div className="card w-100">
      <div className="m-b-32">
        <FlexBox className={`align-items-center ${generalInfo.updatedAt ? 'm-b-8' : ''}`}>
          <h4 className="section-title mb-0">{constVariables.common.general.key}</h4>
          {generalInfo?.externalProvider === 'SHOPIFY' && (
            <>
              <img src={ShopifyIcon} className="cursor-pointer m-l-8" data-tip data-for="shopifySyncIcon" />
              <ShopifySyncTooltip tooltipText="Coupon from Shopify" />
            </>
          )}
        </FlexBox>
        {generalInfo.updatedAt ? (
          <p className="helper-text-one fs-8">
            {`Last update ${moment(generalInfo.updatedAt).format('D MMMM YYYY')} at ${moment(generalInfo.updatedAt).format(
              'h:mm a'
            )} by`}{' '}
            <span className="text-primary cursor-pointer ">you</span>
          </p>
        ) : null}
      </div>
      <InputBox
        inputClass="form-control-lg"
        mainclass="m-b-24"
        title={generalInfo.title}
        imageRight={<span className="text-danger fs-4 px-1">*</span>}
        value={generalInfo.value}
        onChangeText={changeName}
        name={generalInfo.name}
        placeholder={generalInfo.placeHolder}
        helperText={generalInfo.helperText}
        warningText={errorMessages?.generalInfoTitleError}
        maxLength={maxLength}
        onKeyDown={handleKeyCoupontInput}
        onBlur={handleBlurCouponInput}
      />

      <span className="helper-text-bottom">{generalInfo.helperTextDescription}</span>
      <Checkbox
        tagclassname="createCouponCheckBox ps-4"
        name={constVariables.Coupons.AddCoupon.general.automaticDiscount}
        value={generalInfo.automaticDiscount}
        onChangeValue={changeAutomaticDiscount}
        noVerticalPadding
      />
    </div>
  );
};

export default GeneralInfoCreateCoupon;
