/* eslint-disable no-nested-ternary */
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { format } from 'date-fns';

// Apollo
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_USER_BASIC_INFO, GET_CONNECTION_STATUS, SHOPIFY_TO_BS_SYNC, GET_TIKTOK_STORE } from 'src/apollo/queries';

// Components
import { FlexBox, Loader } from 'src/components/atoms';

// Hooks && Utils && Helpers
import useTitle from 'src/utils/hooks/useTitle';
import { useToast } from 'src/utils/hooks/useToast';

// Icons
import { BackRoundedIcon, ShopifyLogoIcon, GreenCheckedIcon, TikTokIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables, ROUTES, envStatuses, ENV } from 'src/constants';

// Types
import { SYNC_STATUS, SyncField, CONNECTION_STATUS } from './Integrations.type';

// Styles
import './_integrations.scss';

const ShopifyIntegrations = () => {
  useTitle('Settings - Shopfiy Integrations');
  const history = useHistory();
  const { showToast } = useToast();

  const [isShopifyConnected, setIsShopifyConnected] = useState(false);
  const [isShopifySyncProgress, setIsShopifySyncProgress] = useState(false);
  const [isSyncCompleted, setIsSyncCompleted] = useState(false);
  const [shopifySyncFieldList, setShopifySyncFieldList] = useState<SyncField[]>([]);
  const [lastSyncDate, setLastSyncDate] = useState(new Date());

  const [isTikTokConnected, setIsTikTokConnected] = useState(false);
  const [tiktokStoreName, setTiktokStoreName] = useState('');

  const storeId = localStorage.getItem('storeId');

  if (envStatuses === 'DEV') {
    console.log('envStatuses::', envStatuses);
  }

  const { data: usrData } = useQuery(GET_USER_BASIC_INFO, {
    variables: {
      input: {
        isBackStoreUser: true
      }
    }
  });
  const userName = usrData?.getUserBasicInfo?.userInfo?.firstName ? `${usrData?.getUserBasicInfo?.userInfo?.firstName}'s` : 'The';

  const [getSyncStatus, { loading: isShopifyConnectStatusLoading }] = useLazyQuery(GET_CONNECTION_STATUS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.getConnectionStatus) {
        if (res?.getConnectionStatus?.status === CONNECTION_STATUS.CONNECTED) {
          setIsShopifyConnected(true);
          if (res?.getConnectionStatus?.initialSync === SYNC_STATUS.RUNNING) {
            localStorage.setItem('isAlreadySynced', 'false');
            setIsShopifySyncProgress(true);
            const syncStatus = res?.getConnectionStatus?.initialSyncStatus;
            const updatedSyncFieldList = Object.keys(syncStatus).map((syncFieldKey, index) => {
              return {
                id: index + 1,
                name: syncFieldKey,
                status: syncStatus[syncFieldKey].status,
                completed: syncStatus[syncFieldKey].completed
              };
            });
            setShopifySyncFieldList(updatedSyncFieldList);
          }
          if (res?.getConnectionStatus?.initialSync === SYNC_STATUS.COMPLETED) {
            const count = res?.getConnectionStatus?.count;
            const isAlreadySynced = localStorage.getItem('isAlreadySynced');
            if (isAlreadySynced !== 'true') {
              localStorage.setItem('isAlreadySynced', 'true');
              showToast({
                successText: `<span style="color:black;">Shopify store<span> <span style="color:#50cd89;">synced successfully!</span>`,
                message: ``
              });
            }
            const updatedSyncFieldList = [
              { id: 1, name: 'Product', status: SYNC_STATUS.COMPLETED, completed: count?.products },
              { id: 2, name: 'Coupon', status: SYNC_STATUS.COMPLETED, completed: count?.coupons },
              { id: 3, name: 'Customer', status: SYNC_STATUS.COMPLETED, completed: count?.customers },
              { id: 4, name: 'Order', status: SYNC_STATUS.COMPLETED, completed: count?.orders }
            ];
            setShopifySyncFieldList(updatedSyncFieldList);
            setLastSyncDate(res?.getConnectionStatus?.lastSyncedAt);
            setIsShopifySyncProgress(false);
            setIsSyncCompleted(true);
          }
        } else {
          setIsShopifyConnected(false);
        }
      }
    },
    onError: (err) => {
      console.log('err::', err);
    }
  });

  useEffect(() => {
    getSyncStatus();

    if (!isSyncCompleted) {
      const intervalCall = setInterval(() => {
        getSyncStatus();
      }, 60000);

      return () => {
        clearInterval(intervalCall);
      };
    }
  }, [isSyncCompleted]);

  const [startShopifySync] = useLazyQuery(SHOPIFY_TO_BS_SYNC, {
    onCompleted: (res) => {
      if (res?.shopifyToBSSynch?.message === 'SHOPIFY_SYNCH_SUCCESS') {
        setShopifySyncFieldList([
          { id: 1, name: 'Products', status: SYNC_STATUS.NOT_STARTED, completed: undefined, total: undefined },
          { id: 2, name: 'Coupons', status: SYNC_STATUS.NOT_STARTED, completed: undefined, total: undefined },
          { id: 3, name: 'Customers', status: SYNC_STATUS.NOT_STARTED, completed: undefined, total: undefined },
          { id: 4, name: 'Orders', status: SYNC_STATUS.NOT_STARTED, completed: undefined, total: undefined }
        ]);
      }
    },
    onError: (err) => {
      console.log('err::', err);
    }
  });

  const { loading: isTikTokConnectionStatusLoading } = useQuery(GET_TIKTOK_STORE, {
    onCompleted: (res) => {
      if (res?.getTikTokStore?.status) {
        setIsTikTokConnected(res?.getTikTokStore?.status === CONNECTION_STATUS.CONNECTED);
        if (res?.getTikTokStore?.storeName) {
          setTiktokStoreName(res?.getTikTokStore?.storeName);
        }
      }
    },
    onError: (err) => {
      console.log('err::', err);
    }
  });

  const returnBack = () => {
    history.push(ROUTES.settings.main);
  };

  const handleStartSync = async () => {
    setIsShopifySyncProgress(true);
    startShopifySync({
      variables: {
        input: {
          updatedAtSince: new Date().toISOString()
        }
      }
    });
  };

  const shopifyConnectLink =
    'https://admin.shopify.com/oauth/install_custom_app?client_id=a408cd1e13185d1b242d16d4650c21ec&no_redirect=true&signature=eyJfcmFpbHMiOnsibWVzc2FnZSI6ImV5SmxlSEJwY21WelgyRjBJam94TnpFeE5UWXlNVEEzTENKd1pYSnRZVzVsYm5SZlpHOXRZV2x1SWpvaVluVjVjMjlqYVdGc0xXUmxkbVZzYjNCdFpXNTBMbTE1YzJodmNHbG1lUzVqYjIwaUxDSmpiR2xsYm5SZmFXUWlPaUpoTkRBNFkyUXhaVEV6TVRnMVpERmlNalF5WkRFMlpEUTJOVEJqTWpGbFl5SXNJbkIxY25CdmMyVWlPaUpqZFhOMGIyMWZZWEJ3SWl3aWJXVnlZMmhoYm5SZmIzSm5ZVzVwZW1GMGFXOXVYMmxrSWpvMk1qUTBNVFkzTkgwPSIsImV4cCI6IjIwMjQtMDQtMDNUMTc6NTU6MDcuNTA3WiIsInB1ciI6bnVsbH19--a3190bcd94e34c8ca3abe717135d6307230e1312';

  const tiktokConnectionLink = `https://services.us.tiktokshop.com/open/authorize?service_id=7355495696320005930&state=${storeId}`;

  return (
    <div className="shopify-integrations">
      <div className="width-animation w-100 p-40">
        <div className="shopify-integrations-container">
          <FlexBox className="align-items-center m-y-40">
            <img src={BackRoundedIcon} alt={BackRoundedIcon} onClick={returnBack} className="cursor-pointer" />
            <h2 className="page-title px-4 mb-0">{constVariables.shopifyIntegrations.pageName}</h2>
          </FlexBox>
          <div className="card w-100">
            {isShopifyConnectStatusLoading ? (
              <Loader type="page" className="h-300px card mb-0" />
            ) : (
              <>
                <div className="m-b-32">
                  <img src={ShopifyLogoIcon} width={105} />
                </div>
                {!isShopifyConnected ? (
                  <>
                    <p className="m-b-32">{constVariables.shopifyIntegrations.description}</p>
                    <div>
                      <a className="btn btn-md btn-primary m-0" href={shopifyConnectLink} target="_blank" rel="noreferrer">
                        Connect
                      </a>
                    </div>
                  </>
                ) : !isShopifySyncProgress ? (
                  <>
                    {!isSyncCompleted ? (
                      <>
                        <p className="m-b-32">
                          {userName} store is now connected to your BuySocial account. Click on 'Start Sync' to begin synchronizing
                          your stores.
                        </p>
                        <FlexBox className="align-items-center">
                          <button className="btn btn-md btn-primary m-0" onClick={handleStartSync}>
                            Start Sync
                          </button>
                        </FlexBox>
                      </>
                    ) : (
                      <>
                        <FlexBox className="align-items-center">
                          <img src={GreenCheckedIcon} alt="" />
                          <span className="m-l-12 sync-completed">{userName} store is Synced.</span>
                        </FlexBox>
                        <p className="m-0 sync-last-time">
                          Last sync {format(new Date(lastSyncDate), 'd MMM yyyy')} at {format(new Date(lastSyncDate), 'h:mm aaa')}
                        </p>
                        <div className="m-t-32 sync-counts">
                          {shopifySyncFieldList?.map((syncItem, index) => (
                            <div key={index}>
                              {<span>{syncItem.name}</span>}s synced: {syncItem.completed ? syncItem.completed : 0}
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <p className="m-b-32">
                      Syncing may take a while, depending on your store's size. You'll receive a notification once everything is
                      synced up.
                    </p>
                    <div>
                      <FlexBox className="align-items-center m-b-12">
                        <Loader type="button" className="sync-border m-r-8" />
                        <span className="sync-progress">Sync in progress</span>
                      </FlexBox>
                      <div className="sync-steps">
                        {shopifySyncFieldList.map((field, index) => (
                          <div
                            key={field.id}
                            className={`sync-steps-item ${field.status === SYNC_STATUS.COMPLETED ? 'sync-completed' : ''} ${
                              field.status === SYNC_STATUS.ERROR ? 'sync-error' : ''
                            } ${index < 3 ? 'm-b-12' : ''}`}
                          >
                            {field.name} {field.status === SYNC_STATUS.COMPLETED ? '✓' : ''}{' '}
                            {field.status === SYNC_STATUS.ERROR ? 'X' : ''}
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          {envStatuses !== ENV.STAGING && (
            <div className="card w-100 mb-0">
              {isTikTokConnectionStatusLoading ? (
                <Loader type="page" className="h-300px card mb-0" />
              ) : (
                <>
                  <div className="m-b-32">
                    <img src={TikTokIcon} width={45} />
                  </div>
                  {!isTikTokConnected ? (
                    <>
                      <p className="m-b-32">{constVariables.tiktokIntegrations.description}</p>
                      <div>
                        <a className="btn btn-md btn-primary m-0" href={tiktokConnectionLink}>
                          Connect
                        </a>
                      </div>
                    </>
                  ) : (
                    <p className="mb-0">{tiktokStoreName} store is now connected to your BuySocial account.</p>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShopifyIntegrations;
