import { gql } from '@apollo/client';
// Created to get just basic details
export const GET_PRODUCT_BASIC_DETAILS = gql`
  query GetProduct($input: ProductDetailInput!) @api(name: "productsAPI") {
    getProduct(input: $input) {
      __typename
      ... on ProductOutputStoreOwner {
        addShipmentInsurance
        addSignatureConfirmation
        sensitive
        id
        name
        salePrice
        medias {
          id
          url
          position
          __typename
        }
      }
    }
  }
`;

export const PRODUCT_DETAILS = gql`
  query GetProduct($input: ProductDetailInput!) @api(name: "productsAPI") {
    getProduct(input: $input) {
      __typename
      ... on ProductOutputStoreOwner {
        productAvailableFrom
        id
        name
        code
        brand
        brandStyle
        categoryID
        vendorID
        dimensions
        addSignatureConfirmation
        addShipmentInsurance
        sellOnOutOfStock
        sensitive
        vendor
        quantityInStock
        description
        salePrice
        sku
        taxCode
        status
        isFinalSale
        isReceivedProduct
        retailPrice
        showRetailPrice
        perItemCost
        shippingPrice
        attachImageTo
        collections {
          id
          name
        }
        inventory
        tags {
          id
          name
          textColorCode
          backgroundColorCode
        }
        productTypeInfo {
          id
          name
        }
        channels
        availableFrom
        medias {
          id
          url
          position
          __typename
        }
        recordings {
          id
          url
          isPrimary
          thumbnailUrl
          duration
          title
          createdAt
          position
        }
        autoAssignPrimaryVideo
        visibility
        variants {
          id
          price
          status
          barcode
          weight
          sku
          imageURL
          status
          media {
            id
            url
          }
          variantTypes {
            id
            name
            value
          }
          warehouseVariants {
            id
            name
            quantity
            warehouseId
          }
        }
        warehouseProducts {
          id
          warehouseId
          name
          quantity
          __typename
        }
        createdAt
        updatedAt
      }
      ... on ProductOutput {
        id
        name
        brand
        collections {
          id
          name
        }
        tags {
          id
          name
          textColorCode
          backgroundColorCode
        }
      }
    }
  }
`;

export const GET_PRODUCT_MINIMAL_INFO = gql`
  query GetProduct($input: ProductDetailInput!) @api(name: "productsAPI") {
    getProduct(input: $input) {
      __typename
      ... on ProductOutputStoreOwner {
        id
        name
        salePrice
        status
        retailPrice
        perItemCost
        shippingPrice
        medias {
          id
          url
          position
        }
      }
    }
  }
`;

export const PRODUCT_DETAILS_VARIANTS = gql`
  query GetProduct($input: ProductDetailInput!) @api(name: "productsAPI") {
    getProduct(input: $input) {
      __typename
      ... on ProductOutputStoreOwner {
        id
        variants {
          id
          price
          quantity
          sku
          media {
            id
            url
          }
          variantTypes {
            id
            name
            value
            __typename
          }
          status
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
    }
  }
`;

export const PRODUCT_DESCRIPTON_AND_VARIANTS = gql`
  query GetProduct($input: ProductDetailInput!) @api(name: "productsAPI") {
    getProduct(input: $input) {
      __typename
      ... on ProductOutputStoreOwner {
        id
        description
        variants {
          id
          price
          quantity
          sku
          media {
            id
            url
          }
          status
          variantTypes {
            id
            name
            value
            __typename
          }
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
    }
  }
`;
