import { FC } from 'react';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { orderDetailsData } from 'src/components/oraganisms/OrdersList/OrdersList.types';
import { EditOrderDetailsFormType } from './OrderDetails.types';

// Styles
import './OrderDetails.scss';

interface OrderPaymentDetailsProps {
  tempdata?: number;
  orderDetails: orderDetailsData;
  orderSummaryFormData?: EditOrderDetailsFormType;
}

const OrderPaymentDetails: FC<OrderPaymentDetailsProps> = (props) => {
  const calculateSubTotal = () => {
    let subTotal = 0;
    props.orderSummaryFormData?.items.forEach((element) => {
      subTotal += element.total || 0;
    });
    return subTotal;
  };
  const calculateTotal = () => {
    let subTotal = 0;
    props.orderSummaryFormData?.items.forEach((element) => {
      subTotal += element.total || 0;
    });
    return subTotal + props?.orderDetails?.taxAmount - props?.orderDetails?.discountAmount + props?.orderDetails?.shippingAmount;
  };
  return (
    <>
      <div className="row">
        <h4 className="section-title m-b-32">{constVariables.orderDetails.paymentDetails}</h4>
        <div className="col-10 paymentDetails">
          <span className="poppins-regular">
            {constVariables.orderDetails.subTotal}
            <span className="greyText px-5">({`${props.orderSummaryFormData?.items?.length} items`})</span>
          </span>
        </div>
        <div className="col-1">
          <p className="float-right m-b-12">${calculateSubTotal().toFixed(2)}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-10 paymentDetails">
          <span className="poppins-regular">{constVariables.orders.tax}</span>
        </div>
        <div className="col-1">
          <p className="float-right m-b-12">${props?.orderDetails?.taxAmount}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-10 paymentDetails">
          <span className="poppins-regular">{constVariables.orders.discount}</span>
        </div>
        <div className="col-1">
          <p className="float-right m-b-12">
            {props?.orderDetails?.discountAmount > 0
              ? `- $${props?.orderDetails?.discountAmount}`
              : `$${props?.orderDetails?.discountAmount}`}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-10 paymentDetails">
          <span className="poppins-regular">{constVariables.orders.shipping}</span>
        </div>
        <div className="col-1">
          <p className="float-right m-b-12">${props?.orderDetails?.shippingAmount}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-10 paymentDetails">
          <span className="poppins-regular">{constVariables.orders.total}</span>
        </div>
        <div className="col-1">
          <p className="float-right m-b-12">${calculateTotal().toFixed(2)}</p>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-10 paymentDetails">
          <span className="">{constVariables.orderDetails.paidBy}</span>
        </div>
        <div className="col-1">
          <p className="float-right mb-0">${calculateTotal().toFixed(2)}</p>
        </div>
      </div>
    </>
  );
};

export default OrderPaymentDetails;
