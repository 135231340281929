import { FC, useEffect, useState } from 'react';

// Components
import { FlexBox } from 'src/components/atoms';
import { InputBox, Tooltip } from 'src/components/molecules';

// Icons
import { DownArrowIcon, InfoIcon, UpArrowIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { EditInventoryRecountProps } from './EditInventoryRecount.types';

const EditInventoryRecount: FC<EditInventoryRecountProps> = ({ value, setValue, levelType, handleUpdateInventoryStock }) => {
  const [error, setError] = useState('');
  useEffect(() => {
    if (value) {
      setValue(value);
    }
  }, [value]);

  const changeInputText = (data) => {
    if (data === '') {
      setValue(data);
    } else if (!isNaN(data) && data < 1000000) {
      setValue(data);
    }
  };

  const incOne = () => {
    if (parseInt(value, 10) < 999999) setValue((parseInt(value, 10) + 1).toString());
  };
  const decOne = () => {
    if (parseInt(value, 10) > 1) setValue((parseInt(value, 10) - 1).toString());
  };

  const handleInputKeyEvent = (event) => {
    if (event.keyCode === 13) {
      if (event.target.value === '') {
        setError('Enter value greater than 0.');
        return;
      } else {
        setError('');
      }
      handleUpdateInventoryStock(event.target.value, levelType);
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center ">
        <div className="helper-text">Input quantity</div>
        <img src={InfoIcon} className="ms-2 cursor-pointer h-10px w-10px" data-tip data-for="inventoryRecount" />
        <Tooltip
          tooltipId="inventoryRecount"
          place="top"
          content={() => {
            return <div>Added stock + Old stock = Updated stock</div>;
          }}
        />
      </div>

      <FlexBox
        className={`mt-4 form-control form-control-sm pe-5 ps-0 justify-content-between align-items-center cursor-pointer dropdown-box dropdown-box-active`}
      >
        <InputBox
          value={value}
          onChangeText={changeInputText}
          name="InventoryRecountInput"
          noBorders
          inputClass="text-muted"
          onKeyDown={handleInputKeyEvent}
          isAutoFocus={true}
        />

        <div className="opacity-50 d-flex flex-column">
          <img onClick={incOne} className="cursor-pointer h-10px w-10px" src={UpArrowIcon} />
          <img onClick={decOne} className="cursor-pointer h-10px w-10px" src={DownArrowIcon} />
        </div>
      </FlexBox>
      {error ? <div className="fs-9 text-danger">{error}</div> : null}
    </div>
  );
};

export default EditInventoryRecount;
