export const isTimeExpired: (startAt: string, endingAt: string) => string = (startAt: string, endingAt: string) => {
  if (new Date() > new Date(startAt) && new Date() < new Date(endingAt)) {
    return 'live';
  } else if (new Date(endingAt) > new Date()) {
    return 'upcoming';
  } else if (new Date(startAt) < new Date()) {
    return 'past';
  }

  return '';
};
