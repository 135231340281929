import React from 'react';

import CreateUnplannedLiveShowNotificationsPresentational from './CreateUnplannedLiveShowNotifications.presentational';
import {
  UnplannedLiveShowDetailInfo,
  UnplannedLiveShowErrorInfo
} from 'src/components/templates/CreateUnplannedLiveshow/CreateUnplannedLiveshow.types';

export interface CreateUnplannedLiveShowNotificationsProps {
  liveShowDetailInfo: UnplannedLiveShowDetailInfo;
  liveShowInfoError: UnplannedLiveShowErrorInfo;
  updateLiveShowInfo(liveShowInfo: UnplannedLiveShowDetailInfo): void;
  textMessageToggle: boolean;
  setTextMessageToggle: (data: any) => void;
  appNotificationToggle: boolean;
  setAppNotificationToggle: (data: any) => void;
  messangerToggle: boolean;
  setMessangerToggle: (data: any) => void;
}

const CreateUnplannedLiveShowNotifications: React.FC<CreateUnplannedLiveShowNotificationsProps> = ({
  liveShowDetailInfo,
  liveShowInfoError,
  updateLiveShowInfo,
  textMessageToggle,
  setTextMessageToggle,
  appNotificationToggle,
  setAppNotificationToggle,
  messangerToggle,
  setMessangerToggle
}) => {
  return (
    <CreateUnplannedLiveShowNotificationsPresentational
      liveShowDetailInfo={liveShowDetailInfo}
      liveShowInfoError={liveShowInfoError}
      updateLiveShowInfo={updateLiveShowInfo}
      textMessageToggle={textMessageToggle}
      setTextMessageToggle={setTextMessageToggle}
      appNotificationToggle={appNotificationToggle}
      setAppNotificationToggle={setAppNotificationToggle}
      messangerToggle={messangerToggle}
      setMessangerToggle={setMessangerToggle}
    />
  );
};

export default CreateUnplannedLiveShowNotifications;
