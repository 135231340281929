import { FC } from 'react';

// Components
import { FlexBox, Img } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import { DefaultImage } from 'src/assets/icons';

// Types
import { CartProductItem } from './liveshowCartDetails.types';

interface CartProductsTableCellProps {
  product: CartProductItem;
}

const CartProductsTableCell: FC<CartProductsTableCellProps> = ({ product }) => {
  return (
    <tr className="table-top-border">
      <td className="align-middle" style={{ width: '65%' }}>
        <FlexBox className="align-items-center">
          <div className="symbol me-5">
            <Img
              src={setImageSrc(product?.imageUrl ? generateCompressedImageURL(product?.imageUrl, '50') : DefaultImage)}
              placeholderImg={DefaultImage}
              errorImg={DefaultImage}
              className="object-fit-scale-down bg-white border border-light border-2"
            />
          </div>
          <FlexBox className="justify-content-start flex-column">
            <span className="d-block">{product.productName}</span>
          </FlexBox>
        </FlexBox>
      </td>
      <td className="align-middle">{product.quantity}</td>
      <td className="align-middle">${product.price}</td>
    </tr>
  );
};

export default CartProductsTableCell;
