export const SortIds = [
  {
    id: 5781,
    name: 'Order'
  },
  {
    id: 5786,
    name: 'Date'
  },
  {
    id: 5787,
    name: 'Customer'
  },
  {
    id: 5788,
    name: 'Total'
  },
  {
    id: 578578,
    name: 'Items'
  }
];
export const DateListData = [
  {
    id: 1231,
    name: 'Today'
  },
  {
    id: 1236,
    name: 'Yesterday'
  },
  {
    id: 1237,
    name: 'Last week'
  },
  {
    id: 123844,
    name: 'Last month'
  },
  {
    id: 1238,
    name: 'Last 3 months'
  },
  {
    id: 123123,
    name: 'Last year'
  },
  {
    id: 1233,
    name: 'All Time'
  },
  {
    id: 1234,
    name: 'Date range'
  }
];
