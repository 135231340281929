import { gql } from '@apollo/client';

export const GET_COUPON_CONDITIONS = gql`
  query GetCouponConditions @api(name: "productsAPI") {
    getCouponConditions {
      id
      name
      operations
    }
  }
`;
