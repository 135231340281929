import { FC } from 'react';
import clsx from 'clsx';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { DragIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { EditColumnPresentationalProps } from './EditColumn.types';

// Styles
import './_editColumn.scss';

const EditColumnPresentational: FC<EditColumnPresentationalProps> = ({ columns, show, onDragEnd, changeStatus, allSwitchable }) => {
  return (
    <div
      className={clsx(
        'mt-1 menu menu-sub menu-background menu-sub-dropdown menu-column menu-rounded menu-gray-600 w-300px position-absolute overflow-scroll hide-scrollbar end-0 edit-columns',
        { show }
      )}
      data-kt-menu="true"
    >
      <div className="edit-columns-header">Edit Columns</div>
      <div className={`edit-columns-content ${columns?.length > 7 ? 'scroll-y' : ''}`}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {(provided, snapshot) => (
              <div id="list" {...provided.droppableProps} ref={provided.innerRef}>
                {columns?.map((item, index) => {
                  return (
                    <Draggable key={index} draggableId={index.toString()} index={index}>
                      {(provided, snapshot) => (
                        <div
                          key={index}
                          className="menu-item"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className={`menu-link px-3 dropdown-item d-flex justify-content-between`}>
                            <KTSVG path={DragIcon} svgColor="#c2c4cc" svgClassName="drag-icon" />
                            <div className="w-100 field-name">{item.name}</div>
                            <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={item.status}
                                disabled={
                                  allSwitchable === true
                                    ? false
                                    : ![
                                        constVariables.vendorEditColumns.vendorCategory,
                                        constVariables.vendorEditColumns.totalSpending,
                                        constVariables.productEditColumns.inventory,
                                        constVariables.productEditColumns.type,
                                        constVariables.productEditColumns.vendor,
                                        constVariables.productEditColumns.salePrice,
                                        constVariables.productEditColumns.status,
                                        constVariables.productEditColumns.margin,
                                        constVariables.productEditColumns.profit,
                                        constVariables.productEditColumns.itemCost,
                                        constVariables.productEditColumns.potentialRevenue,
                                        constVariables.productEditColumns.shippingCost,
                                        constVariables.productEditColumns.sku,
                                        constVariables.productEditColumns.barCode,
                                        constVariables.productEditColumns.tags,
                                        constVariables.productVariantEditColumns.price,
                                        constVariables.productVariantEditColumns.barcode,
                                        constVariables.productVariantEditColumns.weight
                                      ].includes(item.name)
                                }
                                onChange={(e) => {
                                  changeStatus(index, e.target.checked);
                                }}
                                name="switchColumns"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default EditColumnPresentational;
