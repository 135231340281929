import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import moment from 'moment';
import momentz from 'moment-timezone';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_USER_BASIC_INFO } from 'src/apollo/queries';
import { GET_STORE_ANALYTICS } from 'src/apollo/queries/getAnalytics';

// Components
import { FlexBox, Img, Loader } from 'src/components/atoms';
import FilterMenuDropdown from './FilterMenuDropdown';
import AnalyticsCard from './AnalyticsCard';
import AnalyticsChart from './AnalyticsChart';
import AnalyticsCardData from './AnalyticsCardData';
import { DatesDropdownSelector } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { numberWithCommas } from 'src/utils/numberWithCommas';
import { useToast } from 'src/utils/hooks/useToast';
import useTitle from 'src/utils/hooks/useTitle';

// Icons
import { CalendarIcon, MegaPhoneIcon, IncreaingGraphIcon, MegaPhoneChannelIcon } from 'src/assets/icons';

// Types
import {
  ChannelFilter,
  DateFilter,
  FilterOptionDateItem,
  FilterOptionChannelItem,
  IDateRange,
  ANALYTICS_CHANNEL
} from './HomePage.type';

// Styles
import './_homePage.scss';

const dateFilterList: FilterOptionDateItem[] = [
  { id: 1, name: DateFilter.Today },
  { id: 2, name: DateFilter.Yesterday },
  { id: 3, name: DateFilter['Last week'] },
  { id: 4, name: DateFilter['Last month'] },
  { id: 5, name: DateFilter['Last year'] },
  { id: 6, name: DateFilter.Custom }
];

const channelFilterList: FilterOptionChannelItem[] = [
  { id: 1, name: ChannelFilter['All channels'] },
  { id: 2, name: ChannelFilter['Shopper app'] },
  { id: 3, name: ChannelFilter.Webstore },
  { id: 4, name: ChannelFilter.Facebook }
];

const HomePage: FC = () => {
  useTitle('Home');
  const getDateRange = useCallback((daySelector: DateFilter): IDateRange => {
    switch (daySelector) {
      case DateFilter.Today:
      default: {
        return {
          startTime: moment().startOf('day').toDate(),
          endTime: moment().endOf('day').toDate()
        };
      }
      case DateFilter.Yesterday: {
        return {
          startTime: moment().subtract(1, 'days').startOf('day').toDate(),
          endTime: moment().subtract(1, 'days').endOf('day').toDate()
        };
      }
      case DateFilter['Last week']: {
        return {
          startTime: moment().subtract(7, 'days').startOf('day').toDate(),
          endTime: moment().toDate()
        };
      }
      case DateFilter['Last month']: {
        return {
          startTime: moment().subtract(30, 'days').startOf('day').toDate(),
          endTime: moment().toDate()
        };
      }
      case DateFilter['Last year']: {
        return {
          startTime: moment().subtract(365, 'days').startOf('month').toDate(),
          endTime: moment().toDate()
        };
      }
    }
  }, []);

  const { showToast } = useToast();

  const [dateRange, setDateRange] = useState<IDateRange>(getDateRange(DateFilter.Today));
  const [showDateDropdown, setShowDateDropdown] = useState(false);
  const [showChannelDropdown, setShowChannelDropdown] = useState(false);
  const [selectedDateFilter, setSelectedDateFilter] = useState(dateFilterList[0]);
  const [selectedChannelFilter, setSelectedChannelFilter] = useState(channelFilterList[0]);
  const [greet, setGreet] = useState('');
  const [showCalendar, setShowCalendar] = useState(false);

  const handleSelectDateFilter = (selectedId: number) => {
    const selected = dateFilterList.filter((filterItem) => filterItem.id === selectedId)[0];
    setDateRange(getDateRange(selected.name));
    setSelectedDateFilter(selected);
    setShowDateDropdown(false);
    if (selectedId === 6) {
      setShowCalendar(true);
    }
  };

  const handleSelectChannelFilter = (selectedId: number) => {
    const selected = channelFilterList.filter((filterItem) => filterItem.id === selectedId)[0];
    setSelectedChannelFilter(selected);
    setShowChannelDropdown(false);
  };

  const analyticsChannel = useMemo(() => {
    switch (selectedChannelFilter?.name) {
      case ChannelFilter['All channels']:
      default:
        return null;
      case ChannelFilter.Facebook:
        return ANALYTICS_CHANNEL.FACEBOOK;
      case ChannelFilter.Webstore:
        return ANALYTICS_CHANNEL.WEB_STORE;
      case ChannelFilter['Shopper app']:
        return ANALYTICS_CHANNEL.MOBILE_STORE;
    }
  }, [selectedChannelFilter]);

  const { data: anaData, loading } = useQuery(GET_STORE_ANALYTICS, {
    variables: {
      input: {
        startTime: dateRange?.startTime,
        endTime: dateRange?.endTime,
        channel: analyticsChannel,
        timeZone: momentz.tz.guess()
      }
    },
    skip: !dateRange?.startTime,
    onError: () => {
      showToast({
        errorText: `Error in getting ${selectedDateFilter?.name}'s data`,
        message: ``
      });
    }
  });
  const analyticsData = anaData?.getStoreAnalytics;

  const { data: usrData } = useQuery(GET_USER_BASIC_INFO, {
    variables: {
      input: {
        isBackStoreUser: true
      }
    }
  });
  const userName = usrData?.getUserBasicInfo?.userInfo?.firstName ? `, ${usrData?.getUserBasicInfo?.userInfo?.firstName}` : '';

  useEffect(() => {
    const hours = new Date().getHours();
    if (hours >= 4 && hours < 12) {
      setGreet('Morning');
    } else if (hours >= 12 && hours < 18) {
      setGreet('Afternoon');
    } else {
      setGreet('Evening');
    }
  }, []);

  const handleCustomPicker = (data) => {
    setDateRange(() => {
      return { startTime: data.from, endTime: data.to };
    });
    setShowCalendar(false);
  };

  const selectedDateName = useMemo(() => {
    if (selectedDateFilter.name === DateFilter.Custom) {
      return showCalendar
        ? DateFilter.Custom
        : `${moment(dateRange.startTime).format('DD/MM/YYYY')} - ${moment(dateRange.endTime).format('DD/MM/YYYY')}`;
    } else {
      return selectedDateFilter.name;
    }
  }, [dateRange, selectedDateFilter.name]);

  return (
    <div className="details-page">
      <div className="details-page-container">
        <div className="m-y-40">
          <h2 className="page-title m-b-8">
            Good {greet}
            {userName}
          </h2>
          <p className="mb-0 home-description">Here’s what is happening to your store</p>
        </div>
        <div className="p-t-40">
          <div>
            <h5 className="home-subtitle poppins-semibold mb-0">Overview</h5>
            <FlexBox className="m-y-32 align-items-center justify-content-between">
              <FlexBox className="align-items-center">
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setShowDateDropdown(false);
                    if (showCalendar) setShowCalendar(false);
                  }}
                >
                  <button
                    className={`btn btn-sm btn-flex btn-icon-text align-items-center ms-0 m-r-16 ${
                      showDateDropdown ? 'btn-primary' : 'btn-secondary'
                    }`}
                    onClick={() => setShowDateDropdown(true)}
                  >
                    <KTSVG path={CalendarIcon} className="me-2" />
                    <span className="poppins-regular">{selectedDateName}</span>
                  </button>
                  <FilterMenuDropdown
                    isShowDropdown={showDateDropdown}
                    data={dateFilterList}
                    selectedValue={selectedDateFilter}
                    onSelect={handleSelectDateFilter}
                  />
                  <div className="position-relative">
                    <DatesDropdownSelector
                      onselect={(data) => handleCustomPicker(data)}
                      isOpen={showCalendar}
                      noRangePicker={false}
                      closeDropdown={() => setShowCalendar(false)}
                      extraClass="top-0 left-0"
                      isCentered
                    />
                  </div>
                </OutsideClickHandler>
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setShowChannelDropdown(false);
                  }}
                >
                  <button
                    className={`btn btn-sm btn-flex btn-icon-text align-items-center m-0 ${
                      showChannelDropdown ? 'btn-primary' : 'btn-secondary'
                    }`}
                    onClick={() => setShowChannelDropdown(true)}
                  >
                    <KTSVG path={MegaPhoneIcon} className="me-2" />
                    <span className="poppins-regular">{selectedChannelFilter.name}</span>
                  </button>
                  <FilterMenuDropdown
                    isShowDropdown={showChannelDropdown}
                    data={channelFilterList}
                    selectedValue={selectedChannelFilter}
                    onSelect={handleSelectChannelFilter}
                  />
                </OutsideClickHandler>
              </FlexBox>
              {/* <a
                className="d-flex align-items-center text-primary cursor-pointer"
                href="#"
                download
                target="_blank"
                rel="noreferrer"
              >
                <span className="text-btn">View Detailed Analytics</span>
              </a> */}
            </FlexBox>
          </div>
          {loading ? (
            <Loader type="page" className="h-400px" />
          ) : (
            <div>
              <div className="row gx-6">
                <div className="col-12 col-sm-6 col-md-3">
                  <AnalyticsCard
                    type="visitor"
                    value={analyticsData?.visitors?.count}
                    comparedPercent={analyticsData?.visitors?.percent}
                    text="Visitors"
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-3">
                  <AnalyticsCard
                    type="order"
                    value={analyticsData?.orders?.count}
                    comparedPercent={analyticsData?.orders?.percent}
                    text="Orders"
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-3">
                  <AnalyticsCard
                    type="revenue"
                    value={analyticsData?.revenue?.count}
                    comparedPercent={analyticsData?.revenue?.percent}
                    text="Revenue"
                    hasSign
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-3">
                  <AnalyticsCard
                    type="profit"
                    value={analyticsData?.profit?.count}
                    comparedPercent={analyticsData?.profit?.percent}
                    text="Profit"
                    hasSign
                  />
                </div>
              </div>
              <div className="main-detail-layout">
                <div className="left-pane">
                  <div className="card home-analytics-card mb-0 h-380px">
                    <Img src={IncreaingGraphIcon} width={32} height={32} className="m-b-8" />
                    <AnalyticsCardData
                      type=""
                      comparedPercent={analyticsData?.totalSales?.percent}
                      text="Total Sales "
                      value={analyticsData?.totalSales?.count}
                      hasSign
                      key="total-sale"
                    />
                    <AnalyticsChart keys={analyticsData?.chart?.keys || []} values={analyticsData?.chart?.values || []} />
                  </div>
                </div>
                <div className="right-pane home-analytics-page">
                  <div className="card home-analytics-card mb-0 h-380px">
                    <Img src={MegaPhoneChannelIcon} width={32} height={32} className="m-b-8" />
                    <h2 className="poppins-semibold m-b-8">{numberWithCommas(analyticsData?.visitors?.count)}</h2>
                    <p className="m-b-16">Channels</p>
                    {(selectedChannelFilter?.name === ChannelFilter['All channels'] ||
                      selectedChannelFilter?.name === ChannelFilter['Shopper app']) && (
                      <div>
                        <FlexBox className="channel-profit-item">
                          <div className="poppins-medium">Shopping App</div>
                          <div className="poppins-medium">{numberWithCommas(analyticsData?.channels?.shopperApp?.total, true)}</div>
                        </FlexBox>
                        <FlexBox className="channel-profit-item subitem">
                          <div className="poppins-medium">Liveshow</div>
                          <div className="poppins-medium">{numberWithCommas(analyticsData?.channels?.shopperApp?.live, true)}</div>
                        </FlexBox>
                        <FlexBox className="channel-profit-item subitem">
                          <div className="poppins-medium">Mobile App</div>
                          <div className="poppins-medium">{numberWithCommas(analyticsData?.channels?.shopperApp?.app, true)}</div>
                        </FlexBox>
                      </div>
                    )}
                    {(selectedChannelFilter?.name === ChannelFilter['All channels'] ||
                      selectedChannelFilter?.name === ChannelFilter.Webstore) && (
                      <FlexBox className="channel-profit-item">
                        <div className="poppins-medium">Webstore</div>
                        <div className="poppins-medium">{numberWithCommas(analyticsData?.channels?.webStore?.total, true)}</div>
                      </FlexBox>
                    )}
                    {(selectedChannelFilter?.name === ChannelFilter['All channels'] ||
                      selectedChannelFilter?.name === ChannelFilter.Facebook) && (
                      <FlexBox className="channel-profit-item no-border">
                        <div className="poppins-medium">Facebook</div>
                        <div className="poppins-medium">{numberWithCommas(analyticsData?.channels?.facebook, true)}</div>
                      </FlexBox>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
