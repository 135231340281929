import { FC, useState } from 'react';
import clsx from 'clsx';
import { HexColorPicker, HexColorInput } from 'react-colorful';

// Components
import { Tabs } from 'src/components/molecules';

// ConstVariables
import { constVariables } from 'src/constants';
import { colors } from './colorsData';

interface ColorPickerDropDownProps {
  isOpen: boolean;
  color: string;
  updateColor: (color: string) => void;
}

const ColorPickerDropDown: FC<ColorPickerDropDownProps> = ({ isOpen, color, updateColor }) => {
  const [selectedTab, setSelectedTab] = useState('Solid');

  const handleChangeColor = (value) => {
    updateColor(value);
  };

  return (
    <div
      className={clsx('mt-1 menu menu-sub menu-background menu-sub-dropdown position-absolute color-picker-dropdown', {
        show: isOpen
      })}
      data-kt-menu="true"
    >
      <div className="color-picker-dropdown-content">
        <Tabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabs={constVariables.TabForColorPicker}
          className="color-picker-tab"
        />
        {selectedTab.toLowerCase() === 'solid' && (
          <div className="solid-section">
            {colors.map((colorGroup, index) => (
              <div
                className={`d-flex align-items-center justify-content-between colors-group-row ${index === 0 ? 'm-0' : ''}`}
                key={index}
              >
                {colorGroup.map((colorItem) => (
                  <div
                    key={colorItem}
                    className="color-item cursor-pointer"
                    style={{ backgroundColor: colorItem }}
                    onClick={() => handleChangeColor(colorItem)}
                  ></div>
                ))}
              </div>
            ))}
          </div>
        )}
        {selectedTab.toLowerCase() === 'picker' && (
          <div className="picker-section">
            <HexColorPicker color={color} onChange={handleChangeColor} />
            <HexColorInput color={color} onChange={handleChangeColor} placeholder="Type a color" prefixed />
          </div>
        )}
      </div>
    </div>
  );
};

export default ColorPickerDropDown;
