import { gql } from '@apollo/client';

export const CREATE_COLLECTION_OR_TAG_OR_CATALOGUE = gql`
  mutation onCreateCollectionOrTagOrCatalogue($input: CreateCollectionOrCatalogueInput!) @api(name: "authAPI") {
    createCollectionOrCatalogueOrTag(input: $input) {
      success
      message
      id
      name
      textColorCode
      backgroundColorCode
    }
  }
`;
