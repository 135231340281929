import { FC } from 'react';

// Types
import { SelectionCircleProps } from './SelectionCircle.types';

// Styles
import './_selectionCircle.scss';

const SelectionCircle: FC<SelectionCircleProps> = ({ className, onClick, id, isSelected }) => {
  return (
    <div
      onClick={onClick}
      className={` border ${
        isSelected ? 'border-primary' : ''
      } d-flex cursor-pointer align-items-center justify-content-center outerSelectionCircle   ${className}`}
    >
      {isSelected ? <div className="innerSelectionCircle bg-primary" /> : null}
    </div>
  );
};

export default SelectionCircle;
