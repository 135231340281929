import { FC } from 'react';

// Components
import { FlexBox } from 'src/components/atoms';

// ConstVariables
import { constVariables } from 'src/constants';
import { errorTextGroups } from '../ShopDetails';

// Types
import { DetailsProps } from '../ShopDetails.types';

const CustomerDetails: FC<DetailsProps> = ({ storeDetailsData, errorMessages, handleUpdateStoreDetails, validateInputValues }) => {
  return (
    <div className="card">
      <h4 className="section-title m-b-32">{constVariables.shopDetails.customers.key}</h4>
      <div className="row gx-3">
        <div className="col-md-6">
          <div className="input-title">{constVariables.shopDetails.customers.vip}</div>
          <FlexBox className="form-control form-control-lg align-items-center">
            <span className="me-1 text-muted">$</span>
            <input
              autoComplete="off"
              className="border-0"
              type="text"
              pattern="(\d*\.\d{0,2}|\d+)"
              name="customerSpendingThreshold"
              value={storeDetailsData.customerSpendingThreshold}
              onChange={(e) => {
                e.target.validity.valid &&
                  handleUpdateStoreDetails('customerSpendingThreshold', e.target.value, errorTextGroups.customerSpendingThreshold);
              }}
              onBlur={(event) =>
                validateInputValues('customerSpendingThreshold', event.target.value, errorTextGroups.customerSpendingThreshold)
              }
            />
          </FlexBox>
          {errorMessages.customerSpendingThreshold && (
            <div className="warning-text p-0 fs-7">{errorMessages.customerSpendingThreshold}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;
