import { FC, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';

// Components
import { FlexBox } from 'src/components/atoms';
import { ColorPicker } from 'src/components/molecules';
import { HomeScreen, IllustrationScreen, ProductScreen, LiveshowScreen } from './pages';
import SigninScreen from '../IntroScreens/pages/SigninScreen';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { AppArrowLeftIcon, AppArrowRightIcon } from 'src/assets/icons';

// Types
import { ScreensProps, fadeAnimationHandler, AppCustomizationStatus } from '../AppCustomizer.types';

// Styles
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const pages = [
  { id: 1, name: 'Home Screen' },
  { id: 2, name: 'Product Screen' },
  { id: 3, name: 'Illustrations Screen' },
  { id: 4, name: 'Liveshow Screen' },
  { id: 5, name: 'Sign In Screen' }
];

const AppColors: FC<ScreensProps> = ({ appTheme, updateTheme }) => {
  const [currentPage, setCurrentPage] = useState(pages[0]);

  const handleUpdatePrimaryColor = (color) => {
    updateTheme('primaryColor', color);
  };

  const handleUpdateSecondaryColor = (color) => {
    updateTheme('secondaryColor', color);
  };

  const handleChangeSlide = (index) => {
    const currentItem = pages.find((page) => page.id === index + 1);
    if (currentItem) {
      setCurrentPage(currentItem);
    }
  };

  const renderScreen = (pageName: string) => {
    switch (pageName) {
      case 'Home Screen':
        return <HomeScreen primaryColor={appTheme.primaryColor} secondaryColor={appTheme.secondaryColor} />;
      case 'Product Screen':
        return <ProductScreen primaryColor={appTheme.primaryColor} secondaryColor={appTheme.secondaryColor} />;
      case 'Illustrations Screen':
        return <IllustrationScreen primaryColor={appTheme.primaryColor} secondaryColor={appTheme.secondaryColor} />;
      case 'Liveshow Screen':
        return <LiveshowScreen primaryColor={appTheme.primaryColor} secondaryColor={appTheme.secondaryColor} />;
      default:
        return (
          <SigninScreen logo={appTheme.appLogo} primaryColor={appTheme.primaryColor} secondaryColor={appTheme.secondaryColor} />
        );
    }
  };

  return (
    <FlexBox className="app-customizer-layout align-items-center justify-content-between">
      <div className="left-pane">
        <div className="card intro-screens m-0">
          <h3>App Colors</h3>
          <p className="m-0 section-description">Once you save changes, your customers will see the changes immediately.</p>
          <div className="intro-screens-section">
            <h5 className="poppins-medium">Select primary color</h5>
            <p className="section-description">This color will be applied to titles and text.</p>
            <ColorPicker
              color={appTheme.primaryColor}
              updateColor={handleUpdatePrimaryColor}
              disabled={appTheme.status === AppCustomizationStatus.UNDER_REVIEW}
              tooltipId="primaryColorTooltip"
            />
          </div>
          <div className="intro-screens-section">
            <h5 className="poppins-medium">Select secondary color</h5>
            <p className="section-description">This color will be applied to titles and text.</p>
            <ColorPicker
              color={appTheme.secondaryColor}
              updateColor={handleUpdateSecondaryColor}
              disabled={appTheme.status === AppCustomizationStatus.UNDER_REVIEW}
              tooltipId="secondaryColorTooltip"
            />
          </div>
        </div>
      </div>
      <div className="right-pane">
        <div className="preview-mode-page">
          <div className="d-flex align-items-center justify-content-center position-relative">
            <Carousel
              infiniteLoop={false}
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              // emulateTouch
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <button
                    type="button"
                    className="carousel-arrow-icon prev-icon d-flex align-items-center justify-content-center cursor-pointer"
                    onClick={onClickHandler}
                  >
                    <KTSVG path={AppArrowLeftIcon} className="cursor-pointer" />
                  </button>
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <button
                    type="button"
                    className="carousel-arrow-icon next-icon d-flex align-items-center justify-content-center cursor-pointer"
                    onClick={onClickHandler}
                  >
                    <KTSVG path={AppArrowRightIcon} className="cursor-pointer" />
                  </button>
                )
              }
              onChange={handleChangeSlide}
              animationHandler={fadeAnimationHandler}
            >
              {pages.map((page, index) => (
                <div className="screen-item" key={index}>
                  {renderScreen(page.name)}
                </div>
              ))}
            </Carousel>
          </div>
        </div>
        <p className="m-0 mt-4 text-center">{currentPage?.name}</p>
      </div>
    </FlexBox>
  );
};

export default AppColors;
