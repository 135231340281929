import { gql } from '@apollo/client';

export const DELETE_TAG = gql`
  mutation onDeleteTag($input: DeleteTagInput!) @api(name: "productsAPI") {
    deleteTag(input: $input) {
      id
      success
      message
    }
  }
`;
