import { FC } from 'react';
import { format } from 'date-fns';

// Components
import { RenderImageList, Portal } from 'src/components/molecules';

// Types
import { FullEventContentCardProps } from './FullEventContentCard.types';

const FullEventContentCard: FC<FullEventContentCardProps> = ({
  event,
  eventList,
  cardPositions,
  isHovered,
  setIsHovered,
  showEventDetail,
  eventStatus
}) => {
  const handleFullEventCard = () => {
    const currentEvent = eventList.find((eventItem) => eventItem.id.toString() === event.id);
    if (currentEvent) {
      showEventDetail(currentEvent);
    }
  };

  return (
    <Portal id="kt_body">
      <div
        style={{
          backgroundColor: event.backgroundColor,
          border: `1px solid ${event.borderColor}`,
          top: cardPositions.top,
          left: cardPositions.left,
          width: cardPositions.width,
          minHeight: cardPositions.minHeight
        }}
        className={`fixedBlock cursor-pointer extendedWidth poppins-medium p-2 ${isHovered ? 'evenContentDB' : 'evenContentDN'} ${
          eventStatus ? eventStatus : ''
        }`}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        onClick={handleFullEventCard}
      >
        <RenderImageList nextLiveStream={event.extendedProps} />
        <div className="titleTextCal text-capitalize">{event.title}</div>

        <p className="m-0 descTextCal" style={{ color: '#4A516C' }}>
          {format(event.start, 'h:mm aaa')} - {format(event.end, 'h:mm aaa')}
        </p>
        <p className="m-0 descTextCal" style={{ color: '#4A516C' }}>
          {event.extendedProps?.products?.length > 0 && `${event.extendedProps?.products?.length} Products Live`}
        </p>
      </div>
    </Portal>
  );
};

export default FullEventContentCard;
