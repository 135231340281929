import { FC, createRef, useMemo, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Redux
import { useAppDispatch } from 'src/redux/hooks';
import { updateNotification, deleteNotification } from 'src/redux/reducer/notificationReducer';

// Components
import { Dropdown, Portal } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { renderNotificationIcon } from 'src/utils/renderNotificationIcon';
import { KTSVG } from 'src/helpers';

// Icons
import { HorizontalThreeDotIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { NotificationItemData } from 'src/redux/reducer/notificationReducer';

interface NotificationItemProps {
  notification: NotificationItemData;
}

const NotificationItem: FC<NotificationItemProps> = ({ notification }) => {
  const moreActionRef = createRef<any>();
  const dispatch = useAppDispatch();
  const [showMoreActionButton, setShowMoreActionButton] = useState(false);
  const [showActionDropdown, setShowActionDropdown] = useState(false);
  const [dropdownPositions, setDropdownPositions] = useState({
    top: 0,
    left: 0
  });

  const handleOpenDropDown = (e) => {
    setShowActionDropdown(true);

    e.stopPropagation();
    setShowActionDropdown(true);

    if (moreActionRef.current) {
      const position = moreActionRef.current.getBoundingClientRect();
      setDropdownPositions({
        top: window.scrollY + position.top + position.height + 10,
        left: position.left
      });
    }
  };

  const notificationActionDropdownData = useMemo(() => {
    return notification.isRead
      ? constVariables.notificationDropdownData.filter((item) => item.id !== 1)
      : constVariables.notificationDropdownData.filter((item) => item.id !== 2);
  }, [notification.isRead]);

  const handleNotification = (option) => {
    setShowActionDropdown(false);
    if (option.id === 1) {
      const data = { id: notification.id, value: true, fieldName: 'isRead' };
      dispatch(updateNotification({ data }));
    } else if (option.id === 2) {
      const data = { id: notification.id, value: false, fieldName: 'isRead' };
      dispatch(updateNotification({ data }));
    } else {
      dispatch(deleteNotification(notification.id));
    }
  };

  return (
    <div
      className="notification-item p-x-8 p-y-24 cursor-pointer d-flex position-relative justify-content-between"
      onMouseEnter={() => setShowMoreActionButton(true)}
      onMouseLeave={() => setShowMoreActionButton(false)}
    >
      <div className="d-flex">
        <div className={`notification-read-status ${notification.isRead ? 'opacity-0' : ''} m-t-16`}></div>
        <img src={renderNotificationIcon(notification.title.toLowerCase())} className="m-x-8" />
        <div>
          <div
            className="notification-item-title m-b-8"
            dangerouslySetInnerHTML={{ __html: notification.title ? notification.title : '' }}
          ></div>
          <p className="notification-item-description mb-0">{notification.time}</p>
        </div>
      </div>
      <div className="position-relative">
        <div
          className={`notification-more-action position-absolute d-flex align-items-center justify-content-center rounded-circle ${
            showMoreActionButton || showActionDropdown ? '' : 'opacity-0'
          }`}
          ref={moreActionRef}
        >
          <KTSVG onClick={handleOpenDropDown} className="cursor-pointer active-icon" path={HorizontalThreeDotIcon} />
        </div>
        {showActionDropdown && (
          <Portal id="kt_body">
            <OutsideClickHandler
              onOutsideClick={() => {
                if (showActionDropdown) {
                  setShowActionDropdown(false);
                }
              }}
            >
              <div className="position-absolute" style={{ top: dropdownPositions.top, left: dropdownPositions.left }}>
                <Dropdown
                  className="mt-1 notification-action-dropdown"
                  data={notificationActionDropdownData}
                  selected={showActionDropdown}
                  onSelect={handleNotification}
                  closeDropdown={() => setShowActionDropdown(false)}
                />
              </div>
            </OutsideClickHandler>
          </Portal>
        )}
        {/* <Dropdown
          className="mt-1 notification-action-dropdown"
          data={notificationActionDropdownData}
          selected={showActionDropdown}
          onSelect={handleNotification}
          closeDropdown={() => setShowActionDropdown(false)}
        /> */}
      </div>
    </div>
  );
};

export default NotificationItem;
