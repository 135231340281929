/* eslint-disable no-nested-ternary */
import { FC } from 'react';
import ProgressBar from '@ramonak/react-progress-bar';

// Components
import { FlexBox } from 'src/components/atoms';

// Types
import { LiveShowStepperProps } from './LiveShowStepper.types';

// Styles
import './_liveShowStepper.scss';

const LiveShowStepper: FC<LiveShowStepperProps> = ({ completed, stepCount, reachedStep, updateStepCount, isUnplanned }) => {
  const updateCount = (count: number) => {
    if (reachedStep >= count) {
      updateStepCount(count);
    }
  };

  return (
    <div className="live-show-stepper">
      <div className="ps-20 pe-20 pt-5 pb-5">
        <div>
          <ProgressBar
            completed={
              stepCount === 1
                ? 4
                : stepCount === 2 && isUnplanned && !completed
                ? 50
                : stepCount === 2 && isUnplanned && completed
                ? 100
                : stepCount === 2
                ? 33.3
                : stepCount === 3 && !completed
                ? 66.6
                : 100
            }
            isLabelVisible={false}
            barContainerClassName={'bar-class'}
            bgColor={'#00A3FF'}
            borderRadius={'50px'}
            height={'10px'}
          />
        </div>
        <FlexBox className="pt-5 justify-content-between">
          <div
            className={`step-label cursor-pointer ${reachedStep >= 1 ? 'opacity-100' : 'opacity-50'}`}
            onClick={() => updateCount(1)}
          >
            DETAILS
          </div>

          {!isUnplanned && (
            <div
              className={`step-label cursor-pointer ${reachedStep >= (isUnplanned ? 3 : 2) ? 'opacity-100' : 'opacity-50'}`}
              onClick={() => updateCount(2)}
            >
              PRODUCTS
            </div>
          )}

          <div
            className={`step-label cursor-pointer ${reachedStep >= (isUnplanned ? 2 : 3) ? 'opacity-100' : 'opacity-50'}`}
            onClick={() => updateCount(3)}
          >
            NOTIFICATIONS
          </div>
        </FlexBox>
      </div>
    </div>
  );
};

export default LiveShowStepper;
