import { gql } from '@apollo/client';

export const MANAGE_RAPID_ACTION_CONFIG = gql`
  mutation ManageRapidActionConfig($input: RapidActionConfigInput!) @api(name: "authAPI") {
    manageRapidActionConfig(input: $input) {
      featured {
        status
        productAssignmentMode
      }
      flashSale {
        status
        productAssignmentMode
      }
    }
  }
`;
