import { useEffect, useState, FC } from 'react';
import ReactDragListView from 'react-drag-listview/lib/index.js';

// Appollo
import { useMutation } from '@apollo/client';
import { DELETE_MEDIA } from 'src/apollo/mutations';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { FlexBox, Loader } from 'src/components/atoms';
import { FileInput } from 'src/components/molecules';
import { CustomModal, DiscardModal, ErrorModal } from 'src/components/oraganisms';
import ImageItem from './ImageItem';
import ImagePreviewSlideModal from './ImagePreviewSlideModal';

// Icons
import { AddVariantImageSmallIcon, AcceptDraggedFile } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { TableData } from 'src/components/pages/products/AddProduct/AddProduct.types';
import { UserRoles } from 'src/components/pages/settings/UserPermissions/UserPermissions.type';

// Styles
import './ImageDnd.scss';

interface ImageDndProps {
  sendUpdatedResult(updatedImages: Array<any>): void;
  sendMedia(updatedImages: Array<any>): void;
  handleProductFileChange(event: React.ChangeEvent<HTMLInputElement> | any): void;
  isLoading: boolean;
  handleSetFiles(files: Array<any>): void;
  productMedia: any[];
  variantsData: TableData[];
  loadingFilesCount: number;
}

const { DragColumn } = ReactDragListView;
const ImageDnd: FC<ImageDndProps> = (props) => {
  const {
    sendUpdatedResult,
    handleProductFileChange,
    isLoading,
    handleSetFiles,
    productMedia,
    variantsData,
    loadingFilesCount,
    sendMedia
  } = props;
  const [totalProductImage, setTotalProductImage] = useState<Array<any>>(productMedia);
  const [show, setShow] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState<Array<any>>([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isDragActive, setIsDragActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showDeleteVariantImageError, setShowDeleteVariantImageError] = useState(false);

  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  useEffect(() => {
    setTotalProductImage(productMedia);
  }, [productMedia]);

  const [deleteMedia] = useMutation(DELETE_MEDIA, {
    onError: (error) => {
      console.log('error', error);
    }
  });

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      if (role === UserRoles.FULFILMENT_ASSOCIATE) return;
      if (fromIndex !== totalProductImage.length) {
        const data = [...totalProductImage];
        const item = data?.splice(fromIndex, 1)[0];
        data?.splice(toIndex, 0, item);
        const newProductImages = data.map((image, index) => {
          // if (image?.id) {
          //   delete image.id;
          // }
          return { ...image, productMediaPosition: index + 1 };
        });
        setTotalProductImage(data);
        sendMedia(newProductImages);
      }
    },
    nodeSelector: 'li',
    handleSelector: 'li'
  };

  const handleModal = (index: number) => {
    setShow(true);
    setCurrentSlide(index);
    document.body.classList.add('modal-open');
  };
  const handleCloseModal = () => {
    document.body.classList.remove('modal-open');
    setShow(false);
  };
  const closeModal = () => {
    document.body.style.overflow = 'unset';
    setIsDeleteModal(false);
  };
  const handleConfirmDelete = () => {
    const variantImages = variantsData ? variantsData?.map((item) => item.img) : [];
    const selectedVariantImage = selectedImage.find((item) => variantImages.includes(item));
    if (selectedVariantImage) {
      setIsDeleteModal(false);
      setShowDeleteVariantImageError(true);
      setErrorMessage('You can not delete the image which is used for the variants.');
      return;
    }

    const urls = totalProductImage
      ?.filter((item) => selectedImage.includes(item.productMediaUrl))
      .map((item) => item.productMediaUrl);

    deleteMedia({
      variables: {
        input: {
          urls
        }
      }
    });

    const updatedMediaList = totalProductImage?.filter((item) => !selectedImage.includes(item.productMediaUrl));
    setTotalProductImage(updatedMediaList);
    sendUpdatedResult(updatedMediaList);
    setSelectedImage([]);
    setIsDeleteModal(false);
  };
  const handleDeleteAll = () => {
    setIsDeleteModal(true);
  };
  const handleDeselectAll = () => {
    setSelectedImage([]);
  };
  const toggle = (index: string) => {
    document.body.classList.remove('modal-open');
    setShow(false);
    if (selectedImage.includes(index) === false) {
      setSelectedImage((selectedImage) => [...selectedImage, index]);
    } else if (selectedImage.includes(index) === true) {
      setSelectedImage(selectedImage.filter((item, i) => item !== index));
    }
  };

  const handleActionErrorModal = () => {
    document.body.style.overflow = 'unset';
    setShowDeleteVariantImageError(false);
    setErrorMessage('');
  };

  return (
    <>
      {selectedImage.length > 0 && (
        <>
          <FlexBox className="align-items-center justify-content-end m-b-24">
            <button className="btn btn-danger btn-xs me-2" onClick={handleDeleteAll}>
              {constVariables.common.productmedia.deleteFrom}({selectedImage.length})
            </button>
            <button className="btn btn-outlined-secondary btn-xs ms-2" onClick={handleDeselectAll}>
              {constVariables.ProductsTab.deselectAll}
            </button>
          </FlexBox>
        </>
      )}
      <div className="simple simple1 simple2 imgDnd">
        <div className="simple-inner">
          <ol
            // style={{ minWidth: 120 * totalProductImage?.length }}
            className={totalProductImage.length > 0 || isLoading ? '' : 'hidden'}
          >
            <DragColumn {...dragProps}>
              {totalProductImage.length > 0 &&
                totalProductImage.map((item, index) => (
                  <ImageItem
                    key={index}
                    item={item}
                    index={index}
                    handleModal={handleModal}
                    toggle={toggle}
                    selectedImage={selectedImage}
                  />
                ))}
              {isLoading &&
                loadingFilesCount > 0 &&
                [...Array(loadingFilesCount)].map((item, index) => (
                  <li key={index} className="eachImage loading">
                    <Loader type="button" />
                    <div className="h-100 w-100 default-product-icon border-none" />
                  </li>
                ))}
            </DragColumn>
            {(totalProductImage.length > 0 || (isLoading && loadingFilesCount > 0)) && role !== UserRoles.FULFILMENT_ASSOCIATE && (
              <li>
                <FileInput
                  className="imageUploadArea"
                  handleSetFiles={handleSetFiles}
                  isDragActive={isDragActive}
                  setIsDragActive={setIsDragActive}
                >
                  <input type="file" id="fileupload" className="file-upload-input" onChange={handleProductFileChange} multiple />
                  <img
                    src={!isDragActive ? AddVariantImageSmallIcon : AcceptDraggedFile}
                    className={`imageUploadIcon ${isDragActive ? 'accepted' : ''}`}
                  />
                  <p className="text-center mt-3 mb-0">
                    Drop files here or <br /> click to upload
                  </p>
                </FileInput>
              </li>
            )}
          </ol>
        </div>
        {totalProductImage.length > 0 && (
          <ImagePreviewSlideModal
            show={show}
            currentSlide={currentSlide}
            totalProductImage={totalProductImage}
            handleCloseModal={handleCloseModal}
          />
        )}
      </div>
      {isDeleteModal && (
        <CustomModal show={isDeleteModal} closeModal={closeModal}>
          <DiscardModal
            title={constVariables.common.productmedia.deleteNote.headerText(selectedImage?.length, totalProductImage?.length)}
            message={constVariables.common.productmedia.deleteNote.message}
            actionBtnTitle={constVariables.common.productmedia.deleteNote.confirm}
            cancelBtnTitle={constVariables.common.productmedia.deleteNote.cancel}
            actionBtnHandler={handleConfirmDelete}
            cancelBtnHandler={closeModal}
            deleteProductMedia={true}
          />
        </CustomModal>
      )}
      {showDeleteVariantImageError && (
        <CustomModal bodyClassname="w-90 w-md-150" show={showDeleteVariantImageError} closeModal={handleActionErrorModal}>
          <ErrorModal
            message={constVariables.common.onSaveError.message}
            actionTitle={constVariables.common.onSaveError.action}
            actionHandler={handleActionErrorModal}
            errors={[errorMessage]}
          />
        </CustomModal>
      )}
    </>
  );
};

export default ImageDnd;
