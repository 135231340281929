// Components
import Tooltip from './Tooltip';

const PreviewTooltip = () => {
  return (
    <Tooltip
      tooltipId="previewIcon"
      place="top"
      content={() => {
        return <>Preview</>;
      }}
    />
  );
};

export default PreviewTooltip;
