import { FC, useEffect, useState } from 'react';

// Apollo
import { useMutation } from '@apollo/client';
import { GET_MEDIA_UPLOAD_URL, DELETE_MEDIA } from 'src/apollo/mutations';

// Components
import { Img, Loader } from 'src/components/atoms';
import { CustomModal, ErrorModal } from 'src/components/oraganisms';
import { FileInput } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import { DefaultImage, CrossIcon, AddVariantImageSmallIcon, AcceptDraggedFile } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { ThumbnailTypes } from './Thumbnail.types';

// Styles
import './_thumbnail.scss';

const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.svg|\.webp|\.heif)$/i;

const Thumbnail: FC<ThumbnailTypes> = (props) => {
  const { thumbnailUrlData, title, showDeleteIcon, setThumbnailUrlData, type, uploadeType, setFormChanges } = props;

  const [isDragActive, setIsDragActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [accessLoading, setAccessLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const [getMediaUploadUrl, { loading: isLoading }] = useMutation(GET_MEDIA_UPLOAD_URL, {
    onError: (error) => {
      console.log('error', error);
    }
  });

  const [deleteMedia] = useMutation(DELETE_MEDIA, {
    onError: (error) => {
      console.log('error', error);
    }
  });

  const getImageUrl = async (item, file) => {
    const { uploadURL } = item;
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': file.type
      },
      body: file
    };

    try {
      await fetch(uploadURL, requestOptions);
    } catch (err) {
      console.log('error', err);
    }
  };

  const handleSetFiles = async (files) => {
    if (files.length > 1) {
      setErrorMessage('You can only drop one image.');
      setShowErrorModal(true);
      return;
    }

    setAccessLoading(true);

    for (let i = 0; i < files.length; i++) {
      if (!allowedExtensions.exec(files[i].name)) {
        setIsDragActive(false);
        setErrorMessage(
          `${files[i].name} is not a supported file type. Upload files ending with: jpg, jpeg, png, svg, webP, heif.`
        );
        setShowErrorModal(true);
        setAccessLoading(false);
        return;
      }
    }

    const res = await getMediaUploadUrl({
      variables: {
        input: {
          urlCount: files.length,
          fileType: 'IMAGE',
          uploadType: uploadeType
        }
      }
    });

    files = Object.keys(files).map((key) => files[key]);

    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      const file = files[i];
      reader.onloadend = () => {
        files[i] = { ...files[i], fileimage: reader.result, filesize: files[i].size };
      };
      if (files[i]) {
        reader.readAsDataURL(file);
      }
    }

    const data = res.data;
    if (data.getMediaUploadUrl) {
      try {
        await Promise.all(data.getMediaUploadUrl.map(async (item, index) => await getImageUrl(item, files[index])));
      } catch (error) {
        setAccessLoading(false);
        console.log(error);
      } finally {
        setAccessLoading(false);
      }

      const tempThumbnailURL = { url: data.getMediaUploadUrl[0].accessURL, fileimage: files[0].fileimage };
      setThumbnailUrlData(tempThumbnailURL);
      setFormChanges && setFormChanges(true);
    }
  };

  const handleThumbnailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    handleSetFiles(files);
  };

  const handleActionErrorModal = () => {
    setErrorMessage('');
    closeErrorModal();
  };

  useEffect(() => {
    if (isLoading) {
      setAccessLoading(true);
    }
  }, [isLoading]);

  const handleDeleteThumbnail = () => {
    deleteMedia({
      variables: {
        input: {
          urls: [thumbnailUrlData.url]
        }
      }
    });
    setThumbnailUrlData({});
    setFormChanges && setFormChanges(true);
  };

  const imageSize = (type: string) => {
    switch (type) {
      case 'LIVE_STREAM_THUMBNAIL':
        return '226';
      default:
        return '260';
    }
  };

  const closeErrorModal = () => {
    document.body.style.overflow = 'unset';
    setShowErrorModal(false);
  };

  return (
    <>
      <div className="card w-100 thumbnail-div">
        <div className="mb-6">
          <h4 className={`section-title m-0 ${props.classes ? props.classes : ''}`}>
            {constVariables.ProductCategoryMenu.collections.thumbnail}
          </h4>
        </div>
        <div className="col-sm">
          {!thumbnailUrlData?.url && !thumbnailUrlData?.fileimage && !accessLoading && (
            <>
              <FileInput
                className={`thumbnail-uploader-container ${type ? type : ''} ${isDragActive ? 'active' : ''}`}
                handleSetFiles={handleSetFiles}
                isDragActive={isDragActive}
                setIsDragActive={setIsDragActive}
              >
                <input type="file" id="fileupload" className="file-upload-input custor-pointer" onChange={handleThumbnailChange} />
                <img
                  src={!isDragActive ? AddVariantImageSmallIcon : AcceptDraggedFile}
                  className={`uploadIcon ${isDragActive ? 'accepted' : ''}`}
                />
                <p className="mt-4">{constVariables.ProductCategoryMenu.collections.mediaUpload}</p>
              </FileInput>
            </>
          )}
          {accessLoading && (
            <div className={`loading-container ${type ? type : ''}`}>
              <Loader type="button" />
            </div>
          )}
          {(thumbnailUrlData?.fileimage || thumbnailUrlData?.url) && (
            <button className={`thumbnail-icon position-relative p-0 ${type ? type : ''}`}>
              <Img
                // src={setImageSrc(thumbnailUrlData?.fileimage ? thumbnailUrlData.fileimage : thumbnailUrlData.url)}
                src={setImageSrc(
                  thumbnailUrlData?.fileimage
                    ? thumbnailUrlData.fileimage
                    : generateCompressedImageURL(thumbnailUrlData?.url, imageSize(uploadeType))
                )}
                placeholderImg={DefaultImage}
                errorImg={DefaultImage}
                className="cursor-pointer thumbnail-img"
              />
              {showDeleteIcon && (
                <KTSVG
                  path={CrossIcon}
                  className="cursor-pointer svg-icon-8 svg-icon-gray-500 me-1 thumbanil-remove-icon"
                  onClick={() => handleDeleteThumbnail()}
                />
              )}
              {thumbnailUrlData && title && title !== '' && (
                <span className="thumbnail-img-title fs-5 poppins-semibold">{title}</span>
              )}
              {type === 'liveshow' && <div className="gradient-section"></div>}
            </button>
          )}
          {props.hidePlaceholderText ? null : (
            <div className="col-sm text-center greyText m-t-24 ">{constVariables.ProductCategoryMenu.collections.thumbnailMsg}</div>
          )}
        </div>
      </div>
      {showErrorModal && (
        <CustomModal bodyClassname="w-90 w-md-150" show={showErrorModal} closeModal={closeErrorModal}>
          <ErrorModal
            message={constVariables.common.onSaveError.message}
            actionTitle={constVariables.common.onSaveError.action}
            actionHandler={handleActionErrorModal}
            errors={[errorMessage]}
          />
        </CustomModal>
      )}
    </>
  );
};

export default Thumbnail;
