import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import moment from 'moment';

// Apollo
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GET_ORDERS_DETAILS,
  GET_ORDER_NOTE,
  GET_ORDER_SHIPMENT,
  GET_CUSTOMER_DETAILS,
  GET_ORDER_TIMELINE,
  GET_SHOPIFY_ADMIN_BASE_URL
} from 'src/apollo/queries';
import {
  ADD_ORDER_NOTE,
  DELETE_ORDER_NOTE,
  CANCEL_ORDER,
  MARK_USER_ADDRESS_VERIFIED,
  SAVE_SHIPPING_ADDRESS,
  DELETE_USER_ADDRESS,
  EDIT_ORDER_ITEM,
  DELETE_ITEM_FROM_ORDER,
  ADD_ITEMS_TO_ORDER
} from 'src/apollo/mutations';
import { orderWithErrors } from 'src/apollo/reactiveVariables';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import {
  AddCustomerAddress,
  CustomModal,
  DeleteProductFromOrderModal,
  SaveHeader,
  AddingProductsModal
} from 'src/components/oraganisms';
import {
  Dropdown,
  ExpeditedShippingTooltip,
  FirstTimeCustomerTooltip,
  ShopifySyncTooltip,
  InvalidOrderActionButtonTooltip
} from 'src/components/molecules';
import { FlexBox, Loader } from 'src/components/atoms';
import OrderDetailsPresentational from './OrderDetails.presentational';
import CancelOrder from './CancelOrder';

// Hooks && Utils && Helpers
import { useToast } from 'src/utils/hooks/useToast';
import { usePrintPackingSlip } from 'src/components/oraganisms/OrdersList/hooks/usePrintPackingSlip';

// Icons
import {
  BackRoundedIcon,
  CheckedOffSquareIcon,
  PrinterWhite,
  ThreeDotsIcon,
  FirstTime,
  Truck,
  ShopifyIcon
} from 'src/assets/icons';

// ConstVariables
import { ROUTES, constVariables } from 'src/constants';

// Types
import { OrderShipmentProps } from '../FulfillmentCenter/FulfillmentCenter.type';
import { Notes } from '../../products/AddProduct/AddProduct.types';
import { OrdersListData, OrderStatus } from 'src/components/oraganisms/OrdersList/OrdersList.types';
import {
  AddressCDType,
  DeleteItemType,
  EditOrderDetailsFormType,
  EditOrderDetailsItem,
  RefundType,
  TimelineData
} from './OrderDetails.types';
import { UserRoles } from '../../settings/UserPermissions/UserPermissions.type';

// Styles
import './OrderDetails.scss';

const OrderDetails: FC = () => {
  const history = useHistory();
  const { showToast } = useToast();

  const [openMenu, setOpenMenu] = useState(false);
  const { orderId } = useParams<{ orderId: string }>();
  const [isCancelOrder, setIsCancelOrder] = useState(false);
  const [isOptions, setIsOptions] = useState(false);
  const [showAddingProductsModal, setShowAddingProductsModal] = useState(false); // GOING TO USE IT
  const [isError, setIsError] = useState(false);
  const [isDelete, setIsDelete] = useState<DeleteItemType | null>(null);
  const [isSave, setIsSave] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [showAddAdressModal, setShowAddAdressModal] = useState<boolean>(false);
  const [selectedAdressId, setSelectedAdressId] = useState(-1);
  const [disableRefundBtn, setDisableRefundBtn] = useState(false);
  const [isEditCustomerAddress, setIsEditCustomerAddress] = useState(false);
  const [isActionButtonDisabled, setIsActionButtonDisabled] = useState<boolean>(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  const [orderShipment, setOrderShipment] = useState<OrderShipmentProps>({
    id: '',
    rateId: '',
    packageInfo: '',
    rateInfo: '',
    useWeightOfItems: true,
    packageWeight: 0.0,
    packageWeightUnit: '',
    needsSignatureConfirmation: false,
    hasInsurance: false,
    trackingNumber: '',
    labelId: '',
    labelUrl: ''
  });
  const [orderData, setOrderData] = useState({
    id: undefined,
    orderCode: '',
    orderStatus: '',
    customer: '',
    thumbnailUrl: '',
    customerEmail: '',
    isPartiallyShipped: false,
    customerId: 0,
    customerOrder: 0,
    customerPhoneNo: '',
    isEmailVerified: false,
    isPhoneNoVerified: false,
    subTotal: 0.0,
    totalWeight: 0.0,
    orderDetails: [
      {
        availableQuantity: -1,
        id: 0,
        name: '',
        sku: '',
        code: '',
        quantity: 0,
        price: [{ price: 0, quantity: 0 }],
        orderItemStatus: '',
        imageUrl: '',
        dimensions: '',
        location: '',
        items: '',
        productId: 0,
        variantId: 0,
        variantTypes: [
          {
            id: 0,
            name: '',
            value: ''
          }
        ],
        shopifyId: undefined
      }
    ],
    shippingAddress: {
      id: 0,
      isVerified: false
    },
    isExpeditedShipping: false,
    total: 0.0,
    items: 0,
    availableItemsQuantity: 0,
    discountAmount: 0.0,
    shippingAmount: 0.0,
    taxAmount: 0.0,
    customerOrders: 0,
    createdAt: '',
    updatedAt: '',
    isCombOrdrParent: false,
    noOfCombChildOrds: 0,
    shopifyId: undefined
  });
  const [notesData, setNotesData] = useState<Notes[]>([]);
  const [verifiedManually, setVerifiedManually] = useState(false);
  const [addressesCustomerDetails, setAddressesCustomerDetails] = useState<AddressCDType>({
    availableStoreCredit: 0,
    userAddress: []
  });
  const [timeLineData, setTimeLineData] = useState<TimelineData[]>([]);

  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  // Page Header for save changes
  const [formChanges, setFormChanges] = useState<boolean>(false);
  const [formData, setFormData] = useState<EditOrderDetailsFormType>();

  const { data: { shopifyAdminBaseUrl } = { shopifyAdminBaseUrl: null } } = useQuery(GET_SHOPIFY_ADMIN_BASE_URL);

  // Graphql Mutation/Query calls
  const [cancelOrderFun, { loading: isRefundLoading }] = useMutation(CANCEL_ORDER, {
    onCompleted: (result) => {
      if (result.cancelOrder.success) {
        setDisableRefundBtn(false);
        showToast({
          successText: `${constVariables.orderDetails.orderCancelToast.titleOne}${orderData.orderCode} ${constVariables.orderDetails.orderCancelToast.titleTwo}`,
          message: constVariables.orderDetails.orderCancelToast.message
        });
        setIsCancelOrder(false);
        getOrderDetailsagain();
      }
    },
    onError: (err) => {
      getOrderDetailsagain();
      setDisableRefundBtn(false);
      console.error('Cancel order Failed', err);
    }
  });
  const [deleteItemFromOrder] = useMutation(DELETE_ITEM_FROM_ORDER, {
    onCompleted: () => {
      setFormChanges(false);
      getOrderDetailsagain();
      showToast({
        successText: 'Item successfully deleted!',
        message: 'Product has been successfully deleted and refund to selected method has been initiated.'
      });
      handleCloseAddingProductsModal();
      setIsDelete(null);
    },
    onError: () => {
      setFormChanges(false);
      getOrderDetailsagain();
      showToast({
        errorText: 'Error while deleting item!',
        message: 'Product has not been successfully deleted.'
      });
      handleCloseAddingProductsModal();
      setIsDelete(null);
    }
  });
  const [addItemsToOrder] = useMutation(ADD_ITEMS_TO_ORDER, {
    onCompleted: () => {
      setFormChanges(false);
      getOrderDetailsagain();
      handleCloseAddingProductsModal();
      setIsDelete(null);
      showToast({
        title: 'Product',
        successText: 'Successfully Added!',
        message: 'Product had been successfully added in the order.'
      });
    },
    onError: (err) => {
      setFormChanges(false);
      getOrderDetailsagain();
      handleCloseAddingProductsModal();
      setIsDelete(null);
      if (err.message === 'PRODUCT_QUANTITY_NOT_AVAILABLE') {
        showToast({
          title: 'Product',
          errorText: 'Not Available!',
          message: 'Product is not available to add to this order.'
        });
      } else {
        showToast({
          title: 'Product',
          errorText: 'Not Successfully Added!',
          message: 'Product had not been successfully added in the order.'
        });
      }
    }
  });
  const [editOrderItems] = useMutation(EDIT_ORDER_ITEM, {
    onCompleted: () => {
      setFormChanges(false);
      getOrderDetailsagain();
      handleCloseAddingProductsModal();
      setIsDelete(null);
      setDisableSaveButton(false);
      showToast({
        successText: 'Order successfully modified!',
        message: 'You order has been successfully modified.'
      });
    },
    onError: () => {
      setFormChanges(false);
      getOrderDetailsagain();
      setDisableSaveButton(false);
      handleCloseAddingProductsModal();
      setIsDelete(null);
      showToast({
        errorText: 'Error while modifing order!',
        message: 'You order has not been successfully modified.'
      });
    }
  });

  const handleAddOrderItem = async (selectedProducts) => {
    addItemsToOrder({
      variables: {
        input: {
          orderId: orderData.id,
          products: selectedProducts.map((prod) => ({
            productId: prod.productId,
            variantId: prod.variantId,
            quantity: 1
          }))
        }
      }
    });
  };

  const handleDeleteOrderItem = async (refund: RefundType) => {
    if (orderData.orderDetails.length === 1) {
      cancelOrderFun({
        variables: {
          input: { orderId: orderData.id, refundType: refund }
        }
      }).then(() => {
        history.replace('/orders/allOrders');
        showToast({
          successText: 'Order cancelled successfully!',
          message: `Order cancelled successfully. Refund will be initiated shortly.`
        });
      });
    } else {
      deleteItemFromOrder({
        variables: {
          input: {
            orderId: isDelete?.orderId,
            itemId: isDelete?.itemId,
            refundType: refund
          }
        }
      });
    }
  };

  const handleEditOrderItem = async (selectedProducts) => {
    editOrderItems({
      variables: {
        input: {
          orderId: isDelete?.orderId,
          items: selectedProducts.map((prod) => ({
            orderItemId: isDelete?.itemId,
            productId: prod.productId,
            variantId: prod.variantId,
            quantity: 1 // Default one required
          }))
        }
      }
    });
  };

  const handleAddProductToOrderModal = (selectedProducts) => {
    const selectedItems = selectedProducts.slice(0);
    if (isDelete) {
      handleEditOrderItem([selectedItems[0]]).then(() => {
        selectedItems.shift();
        handleAddOrderItem(selectedItems);
      });
    } else {
      handleAddOrderItem(selectedItems);
    }
  };

  const openProductSelectorModalSafely = () => {
    setIsDelete((prev) => ({ ...prev, isDelete: false } as DeleteItemType));
    setShowAddingProductsModal(true);
  };

  const [getOrderShipment] = useLazyQuery(GET_ORDER_SHIPMENT, {
    onCompleted: (data) => {
      if (data?.getOrderShipment?.length > 0) {
        setOrderShipment(data?.getOrderShipment[0]);
      } else {
        setOrderShipment({
          id: '',
          rateId: '',
          packageInfo: '',
          rateInfo: '',
          useWeightOfItems: true,
          packageWeight: 0.0,
          packageWeightUnit: '',
          needsSignatureConfirmation: false,
          hasInsurance: false,
          trackingNumber: '',
          labelId: '',
          labelUrl: ''
        });
      }
    }
  });

  const [getOrderTimeline] = useLazyQuery(GET_ORDER_TIMELINE, {
    onCompleted: (response) => {
      if (response?.getOrderTimeline) {
        setTimeLineData(response?.getOrderTimeline);
      }
    },
    onError: (error) => {
      console.log('error::', error);
    }
  });

  useEffect(() => {
    if (orderId) {
      getOrderShipment({
        variables: {
          input: {
            orderId
          }
        }
      });
      getOrderTimeline({
        variables: {
          orderId
        }
      });
    }
  }, [orderId]);

  const [getOrderNotesData, { refetch: orderNoteRefetch }] = useLazyQuery(GET_ORDER_NOTE, {
    variables: {
      orderId: parseInt(orderId, 10)
    },
    onCompleted: (data) => {
      setNotesData(data?.getOrderNotes);
    }
  });

  const [markUserAddressVerified] = useMutation(MARK_USER_ADDRESS_VERIFIED, {
    onCompleted: (data) => {
      setVerifiedManually(true);
      setIsError(false);
    },
    onError: (err) => {
      console.error('Mark user address unverified', err);
    }
  });

  const [addOrderNote] = useMutation(ADD_ORDER_NOTE, {
    onCompleted: () => {
      if (orderNoteRefetch) {
        orderNoteRefetch();
      }
    },
    onError: (error) => {
      console.error('Add product Note error', error);
    }
  });

  const [changeOrderShippingAddress] = useMutation(SAVE_SHIPPING_ADDRESS, {
    onCompleted: (data) => {
      if (data?.changeOrderAddress?.success) {
        setIsSave(false);
        setIsEdit(false);
        getOrderDetailsagain();

        showToast({
          title: constVariables.orderDetails.sucessMessageOne,
          successText: constVariables.orderDetails.successMessageTwo,
          message: ''
        });
      }
    },
    onError: (error) => {
      console.error('Error in saving shipping adrress', error);
    }
  });

  const saveAdressFromList = (addressId: number) => {
    changeOrderShippingAddress({
      variables: {
        input: {
          orderId: parseInt(orderId, 10),
          addressId
        }
      }
    });
  };

  const [deleteOrderNote] = useMutation(DELETE_ORDER_NOTE, {
    onCompleted: () => {
      if (orderNoteRefetch) {
        orderNoteRefetch();
      }
    },
    onError: (error) => {
      console.error('Delete product Note error', error);
    }
  });

  const addNewNote = (newNoteData: string) => {
    addOrderNote({
      variables: {
        input: {
          orderId,
          note: newNoteData
        }
      }
    });
  };

  const editOldNote = (oldNoteData: string, noteId: number) => {
    addOrderNote({
      variables: {
        input: {
          orderId,
          note: oldNoteData,
          id: noteId
        }
      }
    });
  };

  const deleteOldNote = (noteId: number) => {
    deleteOrderNote({
      variables: {
        id: noteId,
        orderId
      }
    });
  };

  const handleConfirmRefundOriginal = () => {
    cancelOrderFun({
      variables: {
        input: { orderId: orderData.id, refundType: 'ORIGINAL_PAYMENT_METHOD' }
      }
    });
  };

  const handleConfirmRefundStoreCredit = () => {
    cancelOrderFun({
      variables: {
        input: { orderId: orderData.id, refundType: 'STORE_CREDIT' }
      }
    });
  };

  const handleOriginalRefund = () => {
    setDisableRefundBtn(true);
    handleConfirmRefundOriginal();
  };

  const handleStoreCreditRefund = () => {
    setDisableRefundBtn(true);
    handleConfirmRefundStoreCredit();
  };

  const { data: orderDetails, refetch: getOrderDetailsagain } = useQuery(GET_ORDERS_DETAILS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        ids: [Number(orderId)],
        status: []
      }
    },
    onCompleted: () => {
      getOrderTimeline({
        variables: {
          orderId
        }
      });
    }
  });

  const [gettingCustomerDetails, { refetch: gettingCustomerDetailsAgain }] = useLazyQuery(GET_CUSTOMER_DETAILS, {
    onCompleted: (data) => {
      if (data?.getCustomerDetails) {
        setAddressesCustomerDetails({
          availableStoreCredit: data?.getCustomerDetails?.availableStoreCredit,
          userAddress: data?.getCustomerDetails?.addresses
        });
      }
    },
    onError: (error) => {
      console.error('get customer details error', error);
    }
  });

  const getAddressListHandler = () => {
    if (orderData?.customerId) {
      gettingCustomerDetails({
        variables: {
          input: {
            userId: orderData?.customerId
          }
        }
      });
    }
  };

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };
  const closeMenu = () => {
    if (openMenu) {
      setOpenMenu(false);
    }
  };
  const selectingMenuItem = (data) => {
    closeMenu();
    if (data.name.toUpperCase() === 'CANCEL ORDER') {
      setIsCancelOrder(true);
    } else if (data.name.toUpperCase() === 'PRINT PACKING SLIP') {
      changeToProcessToFulfillAndPrint();
    } else if (data.name.toUpperCase() === 'FULFILL ORDER') {
      goToFulfilmentCenter();
    }
  };

  const openAddressModalHandler = () => {
    setShowAddAdressModal(true);
  };

  const openEditAddressModalHandler = (add) => {
    setShowAddAdressModal(true);
  };

  useEffect(() => {
    getOrderNotesData();
  }, []);

  useEffect(() => {
    if (orderDetails?.getOrderDetailsList?.orders?.length > 0) {
      const orderDataObject = orderDetails?.getOrderDetailsList?.orders[0];
      if (orderDataObject.shippingAddress.isVerified !== null) {
        // TODO: Currently it's kind of patch to fix issue in QA env, we need to see it later
        setOrderData(orderDataObject);
        setSelectedAdressId(orderDataObject?.shippingAddress?.userAddressId);
        document.title = `Orders - ${orderDataObject.orderCode ? `#${orderDataObject.orderCode}` : ''} - ${
          orderDataObject.customer ? orderDataObject.customer : ''
        }`;
      }
      const arr: OrdersListData[] = Array.from(orderWithErrors());
      if (arr.length > 0) {
        if (arr.find(({ id }) => id === orderDetails?.getOrderDetailsList?.orders[0]?.id)) {
          setIsError(true);
        }
      }
    }
  }, [orderDetails]);

  const breadCrumbData = [
    {
      title: constVariables.AllOrdersMenu.orders,
      path: '/orders/allOrders',
      isActive: false
    },
    {
      title: constVariables.AllOrdersMenu.allOrders,
      path: '/orders/allOrders',
      isActive: true
    }
  ];

  const returnOrderStatus = (status: string) => {
    if (status === 'pending') {
      return 'Pending';
    } else if (status === 'awaiting_fulfillment') {
      return 'To Fulfill';
    } else if (status === 'awaiting_payment' || status === 'awaiting_processing') {
      return 'To Process';
    } else if (status === 'awaiting_shipment') {
      return 'To Ship';
    } else if (status === 'cancelled') {
      return 'Cancelled';
    } else {
      return 'Shipped';
    }
  };

  const { printPackingSlip, getPackingSlipDetails, isFetchingDataFromServer, isPrintingPackingSlip } = usePrintPackingSlip(
    [orderData?.id],
    getOrderDetailsagain
  );

  const goToPrintPage = () => {
    const newWindow = window.open(ROUTES.orders.allOrders.printOrder, '_blank');
    if (newWindow && orderData?.id) {
      newWindow.localStorage.setItem('state', JSON.stringify([orderData.id]));
    }
  };

  const changeToProcessToFulfillAndPrint = async () => {
    if (!isPrintingPackingSlip && orderData?.id && !isActionButtonDisabled && orderData?.orderStatus === 'awaiting_processing') {
      await printPackingSlip([orderData.id]);
    }
  };

  const changeToProcessToFulfill = async () => {
    if (!isFetchingDataFromServer && orderData?.orderStatus === 'awaiting_processing' && orderData.id && !isActionButtonDisabled) {
      await getPackingSlipDetails([orderData.id]);
      await getOrderDetailsagain();
    }
  };

  const onMarkUserVerified = () => {
    markUserAddressVerified({
      variables: {
        input: {
          ids: [Number(orderData?.shippingAddress?.id)]
        }
      }
    });
  };
  const goToFulfilmentCenter = () => {
    history.push(ROUTES.orders.allOrders.fulfillmentCenter, { openId: orderData?.id, orderCode: orderData?.orderCode });
  };

  const rePrintFun = () => {
    const link = document.createElement('a');
    link.href = orderShipment?.labelUrl;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
  };

  const refundLabelFun = () => {
    history.push(ROUTES.orders.allOrders.fulfillmentCenter, { id: orderData?.id });
  };

  const renderWhichStatusButton = () => {
    if (returnOrderStatus(orderData?.orderStatus) === 'To Process') {
      return (
        <>
          <div className="m-l-16" data-tip={isActionButtonDisabled} data-for="invalidOrder">
            <button
              onClick={changeToProcessToFulfillAndPrint}
              className="btn btn-primary btn-sm me-0 d-flex align-items-center justify-content-center"
              disabled={isActionButtonDisabled}
            >
              <img className="h-15px w-15px" src={PrinterWhite} />
              {isPrintingPackingSlip ? (
                <Loader type="button" className="text-light h-15px w-15px" />
              ) : (
                <span className="ms-2">{constVariables.orders.print}</span>
              )}
            </button>
          </div>
          <div className="m-l-16" data-tip={isActionButtonDisabled} data-for="invalidOrder">
            <button
              onClick={changeToProcessToFulfill}
              className="lightBlueButtonBack btn-color-primary btn btn-sm me-0"
              disabled={isActionButtonDisabled}
            >
              {isFetchingDataFromServer ? (
                <Loader type="button" className="text-light h-15px w-15px" />
              ) : (
                <span className="ms-2"> {constVariables.orders.markAsProcessed}</span>
              )}
            </button>
          </div>
          {isActionButtonDisabled && <InvalidOrderActionButtonTooltip tooltipId="invalidOrder" />}
        </>
      );
    } else if (returnOrderStatus(orderData?.orderStatus) === 'To Fulfill') {
      return (
        <button onClick={goToFulfilmentCenter} className="btn btn-success btn-sm m-l-16 me-0" disabled={isActionButtonDisabled}>
          <img className="h-15px w-15px" src={CheckedOffSquareIcon} />
          <span className="ms-2">{constVariables.orders.fulfillOrder}</span>
        </button>
      );
    } else if (returnOrderStatus(orderData?.orderStatus) === 'To Ship') {
      return (
        <>
          {orderShipment?.labelUrl ? (
            <button onClick={rePrintFun} className="btn btn-primary btn-sm m-l-16 me-0" disabled={isActionButtonDisabled}>
              <span>{constVariables.orders.rePrintLabel}</span>
            </button>
          ) : null}
          <button
            onClick={refundLabelFun}
            className="lightBlueButtonBack btn-color-primary btn btn-sm m-l-16"
            disabled={isActionButtonDisabled}
          >
            {constVariables.orders.refundLabel}
          </button>
        </>
      );
    }
    return null;
  };

  useEffect(() => {
    if (orderData.id && orderData.id !== 0) {
      const itemList = orderData.orderDetails.map((orderSummaryProduct, i) => {
        return {
          productId: orderSummaryProduct?.productId ? orderSummaryProduct.productId : undefined,
          variantId: orderSummaryProduct?.variantId ? orderSummaryProduct.variantId : undefined,
          quantity: orderSummaryProduct.quantity,
          orderItemId: orderSummaryProduct.id,
          total:
            orderSummaryProduct?.quantity && orderSummaryProduct?.price
              ? orderSummaryProduct.quantity * orderSummaryProduct.price[0].price
              : undefined,
          price: orderSummaryProduct?.price ? orderSummaryProduct.price[0].price : undefined
        };
      });
      orderData.orderDetails.map((detail, i) => {
        console.log('detail::', detail);
        if (
          detail?.dimensions === '' ||
          !JSON.parse(detail?.dimensions)?.weight?.value ||
          JSON.parse(detail?.dimensions)?.weight?.value === '0'
          // || !orderData?.shippingAddress?.isVerified
        ) {
          setIsActionButtonDisabled(true);
        } else {
          setIsActionButtonDisabled(false);
        }
        return null;
      });
      setFormData({
        orderId: orderData.id,
        items: itemList
      });
    }
  }, [orderData]);

  const openAddProductHandler = () => {
    setShowAddingProductsModal(true);
  };

  const closeAddNewAddresHandler = () => {
    // setSelectedAdressId();
    setShowAddAdressModal(false);
  };

  const resetHandler = (addressId?: number) => {
    getOrderDetailsagain();
    gettingCustomerDetailsAgain();
    setShowAddAdressModal(false);
    if (addressId) {
      setSelectedAdressId(addressId);
      setIsSave(true);
    }
    // setIsEdit(false);
    // setIsSave(false);
    // setSelectedAdressId();
  };

  const [callDeleteAddress] = useMutation(DELETE_USER_ADDRESS, {
    onCompleted: () => {
      getOrderDetailsagain();
      gettingCustomerDetailsAgain();
      // setIsEdit(false);

      showToast({
        title: constVariables.orderDetails.sucessMessageOne,
        errorText: constVariables.orderDetails.deleteMessage,
        message: ''
      });

      // setSelectedAdressId();
    },
    onError: (error) => {
      // setIsEdit(false);
      console.error('Remove Address error', error);
    }
  });

  const deleteAddressHandler = (addressId) => {
    callDeleteAddress({
      variables: {
        input: {
          userId: orderData?.customerId,
          addressId
        }
      }
    });
  };

  const saveOrderDetails = () => {
    setDisableSaveButton(true);
    const newFormData = {
      orderId: formData?.orderId,
      items: formData?.items.map((data) => {
        return {
          orderItemId: data.orderItemId,
          productId: data.productId,
          quantity: data.quantity,
          variantId: data.variantId
        };
      })
    };

    editOrderItems({
      variables: {
        input: newFormData
      }
    });
  };
  const updatedFormData = (itemList: EditOrderDetailsItem[]) => {
    setFormChanges(true);
    setFormData({
      orderId: Number(orderId),
      items: itemList
    });
  };
  const setStatusColor = (status: string, isPartial: boolean) => {
    if (status === 'awaiting_fulfillment') {
      return 'to-fulfil-order-status-box';
    } else if (status === 'pending') {
      return 'pending-order-status-box';
    } else if (status === 'awaiting_payment' || status === 'awaiting_processing') {
      return 'to-process-order-status-box';
    } else if (status === 'awaiting_shipment' || status === 'awiting_pickup' || status === 'partially_shipped') {
      return 'to-ship-order-status-box';
    } else if ((status === 'completed' || status === 'shipped') && isPartial) {
      return 'pending-order-status-box';
    } else if (status === 'completed' || status === 'shipped') {
      return 'shipped-order-status-box';
    } else if (status === 'partially_refund' || status === 'cancelled' || status === 'declined' || status === 'refunded') {
      return 'error-order-status-box';
    } else if (status === 'disputed' || status === 'manual_verification_required') {
      return 'disputed-order-status-box';
    }
    return '';
  };
  const setStatusText = (status: string, isPartial: boolean) => {
    if (status === 'pending') {
      return 'Pending';
    } else if (status === 'awaiting_payment' || status === 'awaiting_processing') {
      return 'To process';
    } else if (status === 'awaiting_fulfillment') {
      return 'To Fulfill';
    } else if (status === 'awaiting_shipment' || status === 'awiting_pickup' || status === 'partially_shipped') {
      return 'To ship';
    } else if ((status === 'completed' || status === 'shipped') && isPartial) {
      return 'Pending';
    } else if (status === 'completed' || status === 'shipped') {
      return 'Shipped';
    } else if (status === 'manual_verification_required') {
      return 'Manual verification required';
    } else if (status === 'partially_refund') {
      return 'Partially refund';
    }
    return status;
  };

  const filterOrderMenu = useMemo(() => {
    if (orderData?.orderStatus === OrderStatus.SHIPPED) {
      return constVariables.ordersOptionsDropdownData.filter((item) => item.id === 4);
    }
    if (orderData?.orderStatus === OrderStatus.AWAITING_PROCESSING) {
      const options = isActionButtonDisabled
        ? constVariables.ordersOptionsDropdownData.filter((item) => item.id !== 2)
        : constVariables.ordersOptionsDropdownData;
      return options.filter((item) =>
        role === UserRoles.ADMIN_ASSOCIATE || role === UserRoles.WAREHOUSE_ASSOCIATE
          ? item.id === 2
          : item.id === 2 || item.id === 3
      );
    }
    return role === UserRoles.ADMIN_ASSOCIATE || role === UserRoles.WAREHOUSE_ASSOCIATE
      ? []
      : constVariables.ordersOptionsDropdownData.filter((item) => item.id === 3);
  }, [orderData?.orderStatus, isActionButtonDisabled]);

  const backbtnHandler = () => {
    if (formChanges) {
      // setModal((prev) => ({ ...prev, leaveModal: true }));
    } else {
      history.goBack();
    }
  };

  const handleCloseAddingProductsModal = () => {
    document.body.style.overflow = 'unset';
    setShowAddingProductsModal(false);
  };

  const closeCancelOrderModal = () => {
    document.body.style.overflow = 'unset';
    setIsCancelOrder(false);
    setIsOptions(false);
  };

  const goToShopifyPage = (shopifyOrderId) => {
    if (shopifyAdminBaseUrl) window.open(`${shopifyAdminBaseUrl}orders/${shopifyOrderId}`, '_blank');
  };

  return (
    <>
      {formChanges && (
        <SaveHeader
          onSave={saveOrderDetails}
          saveDisabled={disableSaveButton}
          onDiscard={() => {
            history.push('/orders/allOrders');
          }}
        />
      )}
      {showAddingProductsModal && (
        <CustomModal
          bodyClassname="modal-dialog-scrollable"
          show={showAddingProductsModal}
          closeModal={handleCloseAddingProductsModal}
        >
          <AddingProductsModal
            closeModal={handleCloseAddingProductsModal}
            shouldShowVariants={false}
            handleAddProducts={handleAddProductToOrderModal}
            isShowOnlyActiveProducts={false}
          />
        </CustomModal>
      )}
      {isCancelOrder && (
        <CustomModal show={isCancelOrder} closeModal={closeCancelOrderModal}>
          <CancelOrder
            title={constVariables.orderDetails.orderCancelModal.title}
            message={constVariables.orderDetails.orderCancelModal.title}
            cancelBtnTitle={constVariables.orderDetails.orderCancelModal.cancel}
            actionBtnHandlerOriginal={handleOriginalRefund}
            actionBtnHandlerStoreCredit={handleStoreCreditRefund}
            cancelBtnHandler={closeCancelOrderModal}
            cancelOrder={true}
            disableRefundBtn={disableRefundBtn}
            isOptions={isOptions}
            isRefunding={isRefundLoading}
          />
        </CustomModal>
      )}
      {isDelete?.isDelete && (
        <CustomModal
          show={!!isDelete?.isDelete}
          closeModal={() => {
            document.body.style.overflow = 'unset';
            setIsDelete((prev) => ({ ...prev, isDelete: false } as DeleteItemType));
          }}
        >
          <DeleteProductFromOrderModal
            show={!!isDelete?.isDelete}
            title={constVariables.orderDetails.orderProductModal.title}
            message={constVariables.orderDetails.orderCancelModal.title}
            actionBtnTitle={constVariables.orderDetails.orderProductModal.deleteButtonTitle}
            cancelBtnTitle={constVariables.orderDetails.orderCancelModal.cancel}
            cancelBtnHandler={() => setIsDelete(null)}
            deleteHandler={handleDeleteOrderItem}
            replaceHandler={openProductSelectorModalSafely}
            cancelOrder={true}
            onlyOneItem={orderData.orderDetails.length === 1}
            actionBtnTitleDelete={constVariables.orderDetails.orderProductModal.deleteButtonTitle}
            actionBtnTitleReplacement={constVariables.orderDetails.orderProductModal.replaceButtonTitle}
            disableRefundBtn={disableRefundBtn}
            isOptions={isOptions}
          />
        </CustomModal>
      )}
      <div className="orderDetailsPage details-page">
        <AddCustomerAddress
          isEditing={isEditCustomerAddress}
          customerAddress={addressesCustomerDetails?.userAddress?.find((address) => address.id === selectedAdressId)}
          show={showAddAdressModal}
          resetState={resetHandler}
          closeAddressModal={closeAddNewAddresHandler}
          customerId={orderData.customerId.toString()}
        />

        <div className="sticky-assist orderDetails details-page-container m-t-40">
          <div className="width-animation w-100 hide-scrollbar">
            <FlexBox className="align-items-center m-b-40">
              <img src={BackRoundedIcon} alt={BackRoundedIcon} className="cursor-pointer" onClick={backbtnHandler} />

              <h1 className="page-title ps-2 mb-0">
                {constVariables.orders.order}
                <span className="poppins-regular text-primary mx-4 me-2">#{orderData?.orderCode}</span>
              </h1>

              {orderData?.shopifyId && (
                <>
                  <img
                    src={ShopifyIcon}
                    className="cursor-pointer mx-4"
                    data-tip
                    data-for="shopifySyncIcon"
                    onClick={() => goToShopifyPage(orderData?.shopifyId)}
                  />
                  <ShopifySyncTooltip tooltipText="Order from Shopify" />
                </>
              )}

              {orderData?.isPartiallyShipped ? (
                <div className="text-center bg-danger text-white poppins-light px-2 py-1 partialShippingButton fs-9">
                  {constVariables.orderItemStatuses.PartialShipping}
                </div>
              ) : null}
            </FlexBox>
            <FlexBox className="align-items-center m-b-40">
              <div
                className={`${setStatusColor(
                  orderData.orderStatus,
                  orderData.isPartiallyShipped
                )} order-status-box text-nowrap poppins-medium cursor-pointer`}
              >
                <div className="text-capitalize">{setStatusText(orderData.orderStatus, orderData.isPartiallyShipped)}</div>
              </div>
              {orderData.customerOrders === 1 && (
                <>
                  <img src={FirstTime} className="m-x-8" data-tip data-for="firstTimeCustomerIcon" />
                  <FirstTimeCustomerTooltip />
                </>
              )}
              {orderData.isExpeditedShipping && (
                <>
                  <img src={Truck} className="m-x-8" data-tip data-for="truckIcon" />
                  <ExpeditedShippingTooltip />
                </>
              )}
              <div className="m-l-16 order-place-time">{moment(orderData.createdAt).format('D MMM YYYY, hh:mm a')}</div>
              {renderWhichStatusButton()}
              {orderData.orderStatus !== 'shipped' && filterOrderMenu?.length > 0 && (
                <OutsideClickHandler onOutsideClick={closeMenu}>
                  <span className="position-relative ms-4">
                    <img
                      src={ThreeDotsIcon}
                      className="dropdown-toggle cursor-pointer"
                      id="dropdownMenuButton"
                      onClick={toggleMenu}
                    />
                    <Dropdown
                      data={filterOrderMenu}
                      onSelect={selectingMenuItem}
                      shiftLeft
                      shiftLeftValue="-130px"
                      selected={openMenu}
                      className="mt-6"
                      closeDropdown={() => setOpenMenu(false)}
                    />
                  </span>
                </OutsideClickHandler>
              )}
            </FlexBox>
            <OrderDetailsPresentational
              isDelete={isDelete}
              setIsDelete={setIsDelete}
              orderDetails={orderData}
              notesData={notesData}
              setNotesData={setNotesData}
              addNewNote={addNewNote}
              editOldNote={editOldNote}
              deleteOldNote={deleteOldNote}
              orderId={orderId}
              isError={isError}
              markUserAddressVerified={onMarkUserVerified}
              verifiedManually={verifiedManually}
              openAddProduct={openAddProductHandler}
              addressesCustomerDetails={addressesCustomerDetails}
              setAddressesCustomerDetails={setAddressesCustomerDetails}
              saveAdressFromList={saveAdressFromList}
              isSave={isSave}
              setIsSave={setIsSave}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              selectedAdressId={selectedAdressId}
              setSelectedAdressId={setSelectedAdressId}
              openAddressModal={openAddressModalHandler}
              deleteAddress={deleteAddressHandler}
              editAddress={openEditAddressModalHandler}
              getAdrressList={getAddressListHandler}
              isEditCustomerAddress={isEditCustomerAddress}
              setIsEditCustomerAddress={setIsEditCustomerAddress}
              updatedFormData={updatedFormData}
              orderSummaryFormData={formData}
              timeLineData={timeLineData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetails;
