import { forwardRef, useEffect, useImperativeHandle, useState, createRef, useCallback, useRef, useMemo } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { ChatRoom, SendMessageRequest } from 'amazon-ivs-chat-messaging';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';

// Apollo
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_VIEWERS_DETAILS, GET_COMMENTS, GET_CHAT_TOKEN } from 'src/apollo/queries';
import { DELETE_COMMENT, PIN_COMMENT } from 'src/apollo/mutations';

// Components
import { EmojiInput, Dropdown, Portal } from 'src/components/molecules';
import LiveShowComment from './LiveShowComment';

// Hooks && Utils && Helpers
import { useToast } from 'src/utils/hooks/useToast';
import { KTSVG } from 'src/helpers';
import useWindowDimensions from 'src/utils/UseWindowDimensions/useWindowDimensions';
import { getPosition } from 'src/utils/getPosition';

// Icons
import { ThreeDotsIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables, envUrls } from 'src/constants';

// Types
import { LivsDashboardCustomModalsRefMethods } from './LivsDashboardCustomModals';
import { ICommentBE, ILiveUser } from './LiveshowManager.types';

// Styles
import './_liveshowCommentsList.scss';

interface IUserComment {
  id: string;
  userId: string;
  facebookId?: string;
  bsId: string | undefined;
  userImage: string | undefined;
  userName: string | undefined;
  comment: string;
  sendTime: any;
  isPinned?: boolean;
}

let myRoom: ChatRoom | undefined = undefined;

interface LiveShowCommentsListProps {
  liveShowId: string;
  onAllUserCartDumped?: () => void;
  onBlockUser: LivsDashboardCustomModalsRefMethods['onBlockUser'];
  onDeleteComment: LivsDashboardCustomModalsRefMethods['onDeleteComment'];
  onAddStoreCredit: LivsDashboardCustomModalsRefMethods['onAddStoreCredit'];
  onShowCart: LivsDashboardCustomModalsRefMethods['onShowCart'];
  onDumpCart: LivsDashboardCustomModalsRefMethods['onDumpCart'];
}

export interface LiveShowCommentsListRefMethods {
  onUserDetailChange: (user: ILiveUser) => void;
  handleDeleteComment: (commentId: string) => void;
}

const LiveShowCommentsList = forwardRef<LiveShowCommentsListRefMethods, LiveShowCommentsListProps>(
  ({ liveShowId, onAllUserCartDumped, onBlockUser, onDeleteComment, onAddStoreCredit, onShowCart, onDumpCart }, ref) => {
    const { showToast } = useToast();
    const { width } = useWindowDimensions();

    const menuIcon = createRef<any>();
    const listRef = useRef<List>(null);
    const rowHeights = useRef({});

    const [chatRoom, setChatRoom] = useState<ChatRoom | undefined>(undefined);
    const [allComments, setAllComments] = useState<IUserComment[]>([]);
    const [userIdToDetails, setUserIdToDetails] = useState<Map<number, ILiveUser>>(new Map());
    const [menuPositions, setMenuPositions] = useState({
      top: 0,
      left: 0
    });
    const [isChatRoomConnected, setIsChatRoomConnected] = useState(false);
    const [inputCommentError, setInputCommentError] = useState('');
    const [inputComment, setInputComment] = useState('');
    const [commentsGroupType, setCommentsGroupType] = useState('all');

    const getCommentsInput = {
      showId: liveShowId,
      isPinned: false,
      pageInfo: {
        skipCount: 0,
        limitCount: 20
      },
      sortType: 'DESC'
    };

    const [pinComment] = useMutation(PIN_COMMENT);

    const [getChatToken] = useLazyQuery(GET_CHAT_TOKEN);
    const [getViewersDetails] = useLazyQuery(GET_VIEWERS_DETAILS, {
      onCompleted: (data) => {
        data?.getViewersDetails.forEach((detail) => {
          userIdToDetails.set(detail.id, detail);
        });
        setUserIdToDetails(new Map(userIdToDetails));
      },
      onError: (error: any) => {
        showToast({
          errorText: error.message,
          message: `Error occured while get viewers details: ${error.message}`
        });
      }
    });
    const [fetchComments] = useLazyQuery(GET_COMMENTS, {
      onCompleted: (data) => {
        if (data) {
          onMessage(data.getComments, true);
        }
      },
      onError: (error: any) => {
        console.log({ error });
        showToast({
          errorText: error.message,
          message: `Error occured while get live show comments: ${error.message}`
        });
      }
    });

    useImperativeHandle(ref, () => ({
      onUserDetailChange: (user) => {
        handleUserDetailChange(user?.id, user);
      },
      handleDeleteComment: (commentId) => handleDeleteComment(commentId)
    }));

    const tokenProvider = async () => {
      const { data } = await getChatToken({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fetchPolicy: 'no-cache',
        onError: (err) => {
          console.log({ triggeredBy: 'ChatToken', err });
          showToast({
            errorText: 'Chat Error',
            message: 'Eroor in connecting to live chat'
          });
        }
      });
      return {
        token: data?.getChatToken?.token,
        sessionExpirationTime: new Date(data?.getChatToken?.sessionExpirationTime),
        tokenExpirationTime: new Date(data?.getChatToken?.tokenExpirationTime)
      };
    };

    useEffect(() => {
      if (liveShowId) {
        fetchComments({
          variables: {
            input: getCommentsInput
          }
        });
      }

      if (!myRoom && liveShowId) {
        myRoom = new ChatRoom({ regionOrUrl: envUrls.awsRegion, tokenProvider });
        if (myRoom.state === 'disconnected') {
          myRoom?.connect();
        }
        setChatRoom(myRoom);
      }
    }, [liveShowId]);

    useEffect(() => {
      // If chat room listeners are not available, do not continue
      if (!chatRoom?.addListener) {
        return;
      }
      // TODO Add event listners for CUSTOM_EVENTS, DELETE_MESSAGE adn BLOCK_USERS ref: https://docs.aws.amazon.com/ivs/latest/userguide/chat-sdk-js.html

      const unsubscribeOnConnected = chatRoom.addListener('connect', () => {
        setIsChatRoomConnected(true);
      });

      const unsubscribeOnDisconnected = chatRoom.addListener('disconnect', (reason) => {
        setIsChatRoomConnected(false);
        if (reason === 'serverDisconnect') {
          const newRoom = new ChatRoom({ regionOrUrl: envUrls?.awsRegion, tokenProvider });
          newRoom.connect();
          setChatRoom(newRoom);
        }
      });

      const unsubscribeOnMessageReceived = chatRoom.addListener('message', (m: any) => onMessage([m]));

      const unsubscribeOnEventReceived = chatRoom.addListener('event', (event) => {
        switch (event?.eventName) {
          case 'ALL_USER_CARTS_DUMPED':
            if (onAllUserCartDumped) {
              onAllUserCartDumped();
            }

            dumpAllCarts();
            break;

          case 'FACEBOOK_COMMENT':
            if (event?.attributes) {
              onMessage([JSON.parse(event.attributes.comment)]);
            }
            break;

          default:
            break;
        }
      });

      return () => {
        unsubscribeOnConnected();
        unsubscribeOnDisconnected();
        unsubscribeOnMessageReceived();
        unsubscribeOnEventReceived();
        myRoom?.disconnect();
        myRoom = undefined;
      };
    }, [chatRoom]);

    const comments = useMemo(() => {
      return commentsGroupType === 'pinned' ? allComments.filter((comment) => comment.isPinned) : allComments;
    }, [allComments, commentsGroupType]);

    const onMessage = useCallback(
      (messages: ICommentBE[], isOld = false) => {
        const userIds: number[] = [];
        const userIdMap = {};

        messages.forEach((message) => {
          const newComment: IUserComment = {
            comment: message?.content,
            id: message?.id,
            userId: message?.sender?.userId,
            bsId: message?.attributes?.BSCommentId,
            facebookId: message?.facebookId,
            userName: message?.sender?.attributes?.userName,
            userImage: message?.sender?.attributes?.userImage,
            sendTime: message?.sendTime
          };

          const setInOrder = (d) => (isOld ? [...d, newComment] : [newComment, ...d]);

          setAllComments(setInOrder);
          // if (listRef?.current?.state.scrollOffset <= 100) {
          //   setAllComments(setInOrder);
          // }

          const id = +message?.sender?.userId;

          if (id && !userIdToDetails.get(id) && !userIdMap[id]) {
            userIds.push(id);
            userIdMap[id] = true;
          }
        });

        if (userIds.length) {
          getViewersDetails({
            variables: {
              input: {
                customerIds: userIds,
                pageInfo: {
                  skipCount: 0,
                  limitCount: userIds?.length || 1
                },
                liveShowId
              }
            }
          });
        }
      },
      [userIdToDetails, liveShowId]
    );

    const dumpAllCarts = useCallback(() => {
      setUserIdToDetails((detail) => {
        const newDetail = new Map();
        for (const [key, value] of detail) {
          newDetail.set(key, { ...value, noOfCartItems: 0 });
        }
        return newDetail;
      });
    }, [userIdToDetails]);

    const handlePost = async () => {
      if (!inputComment) {
        return;
      }
      const sendMessagerequest = new SendMessageRequest(inputComment);
      try {
        setInputComment('');
        await chatRoom?.sendMessage(sendMessagerequest);
        listRef?.current?.scrollToItem(0);
      } catch (error: any) {
        const message = error && error.message ? error.message : 'Error occured while sending message';
        showToast({
          errorText: message,
          message
        });
        setInputCommentError(message);
      }
    };
    const [showDropDown, setShowDropDown] = useState(false);
    const closeDropDown = () => setShowDropDown(false);
    const openDropDown = (e) => {
      e.stopPropagation();
      setShowDropDown(true);

      if (menuIcon.current) {
        const position = menuIcon.current.getBoundingClientRect();
        console.log('position::', position);
        const { top, left } = position;
        // const tempTopPosition = window.scrollY + position.top + 30;
        // const { topPosition, leftPosition } = getPosition(width, tempTopPosition, position.left, 14.64);

        // setMenuPositions({
        //   top: topPosition,
        //   left: leftPosition
        // });

        setMenuPositions({
          top: top + 36,
          left: left - 170
        });
      }
    };

    const [visible, setVisible] = useState(false);

    const [deleteComment] = useMutation(DELETE_COMMENT, {
      onCompleted: (response) => {
        if (response) {
          if (response.deleteComment.id) {
            const index = allComments.findIndex((c) => c.id === response.deleteComment.id);
            if (index > -1) {
              allComments.splice(index, 1);
              setAllComments([...allComments]);
              showToast({
                successText: `<span style="color: #1e2749;">Comment is <span> <span style="color:#f1416c;">deleted!</span>`,
                message: ``
              });
            }
          }
        }
      },
      onError: (error) => {
        showToast({
          errorText: error.message,
          message: `Error occured while delete Comment: ${error.message}`
        });
      }
    });

    const handleDeleteComment = useCallback(
      (id) => {
        deleteComment({
          variables: {
            input: {
              showId: liveShowId,
              commentId: id
            }
          }
        });
      },
      [liveShowId]
    );

    const handleCommentChange = useCallback(
      (commentId, itemAttributes) => {
        const findIndexAll = allComments.findIndex((comment) => comment.id === commentId);
        setAllComments([
          ...allComments.slice(0, findIndexAll),
          { ...allComments[findIndexAll], ...itemAttributes },
          ...allComments.slice(findIndexAll + 1)
        ]);
      },
      [allComments]
    );

    const handleUserDetailChange = useCallback(
      (userId, itemAttributes) => {
        const detail = userIdToDetails.get(Number(userId));
        userIdToDetails.set(+userId, {
          ...detail,
          ...itemAttributes
        });
        setUserIdToDetails(new Map(userIdToDetails));
      },
      [userIdToDetails]
    );

    const isItemLoaded = (index) => index < comments.length;

    const selectingMenuItem = (data) => {
      if (data.id === 1) {
        setCommentsGroupType('all');
      } else if (data.id === 2) {
        setCommentsGroupType('pinned');
      }
      closeDropDown();
    };

    function loadMoreRows(startIndex, stopIndex) {
      if (commentsGroupType === 'pinned') return;
      fetchComments({
        variables: {
          input: {
            ...getCommentsInput,
            pageInfo: {
              skipCount: comments.length,
              limitCount: 20
            }
          }
        }
      });
    }

    const onPinComment = (comment) => {
      pinComment({
        variables: {
          input: {
            showId: liveShowId,
            commentId: comment.id,
            action: comment.isPinned ? 'UNPIN' : 'PIN'
          }
        },
        onCompleted: () => {
          handleCommentChange(comment.id, { isPinned: !comment.isPinned });
        },
        onError: (error) => {
          showToast({
            errorText: error.message,
            message: `Error occured while pin Comment: ${error.message}`
          });
        }
      });
    };

    function setRowHeight(index, size) {
      listRef.current.resetAfterIndex(0);
      rowHeights.current = { ...rowHeights.current, [index]: size };
    }

    function getRowHeight(index) {
      return rowHeights.current[index] || 58;
    }

    return (
      <div className="live-show-comments-section position-relative card m-0 p-0">
        <div
          className="d-flex live-show-comments-header position-relative p-x-24 p-t-24 p-b-12"
          onMouseEnter={() => setVisible(true)}
          onMouseLeave={() => setVisible(false)}
        >
          <h4 className="liveshow-section-title">Live Comments</h4>
          <div className={`live-show-comments-header-more position-absolute ${!visible ? 'hidden' : ''}`} ref={menuIcon}>
            <KTSVG onClick={openDropDown} className="cursor-pointer" path={ThreeDotsIcon} />
          </div>
          {showDropDown && (
            <Portal id="kt_body">
              <OutsideClickHandler onOutsideClick={closeDropDown}>
                <div className="position-absolute" style={{ top: menuPositions.top, left: menuPositions.left, zIndex: 200 }}>
                  <Dropdown
                    data={constVariables.liveShowCommentHeaderDropdownData}
                    onSelect={selectingMenuItem}
                    selected={showDropDown}
                    className="comment-action-drop-down"
                    closeDropdown={() => setShowDropDown(false)}
                  />
                </div>
              </OutsideClickHandler>
            </Portal>
          )}
        </div>
        <div className={`live-show-comments-list`}>
          <InfiniteLoader isItemLoaded={isItemLoaded} loadMoreItems={loadMoreRows} itemCount={comments.length + 1} threshold={1}>
            {({ onItemsRendered, ref }) => (
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    height={height}
                    onItemsRendered={onItemsRendered}
                    ref={(e) => {
                      ref.current = e;
                      listRef.current = e;
                    }}
                    itemCount={comments.length}
                    itemSize={getRowHeight}
                    // onScroll={onListScroll}
                    width={width}
                    itemData={{
                      comments,
                      userIdToDetails,
                      liveShowId,
                      onPinComment,
                      onBlockUser,
                      onDeleteComment,
                      onAddStoreCredit,
                      onShowCart,
                      onDumpCart,
                      setRowHeight
                    }}
                  >
                    {LiveShowComment}
                  </List>
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        </div>
        {isChatRoomConnected && (
          <div className="live-show-comment-input-container w-100">
            <EmojiInput
              isError={inputCommentError !== ''}
              errorMessage={inputCommentError}
              placeholder={'Leave the comments.'}
              value={inputComment}
              onChangeText={setInputComment}
              emojiClass={'show-post-btn'}
              showPostButton
              handlePost={handlePost}
              inputClass="comment-input"
            />
          </div>
        )}
      </div>
    );
  }
);
export default LiveShowCommentsList;
