import { gql } from '@apollo/client';

export const GET_COUPON_MATCHING_PPRODUCT_LIST = gql`
  query GetAllProductList($input: GetProductListInput!) @api(name: "productsAPI") {
    getAllProductList(input: $input) {
      productIds {
        id
      }
    }
  }
`;
