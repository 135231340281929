import { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

// Apollo
import { useMutation } from '@apollo/client';
import { CONNECT_TIKTOK_STORE } from 'src/apollo/mutations';

// Components
import { Loader } from 'src/components/atoms';

// ConstVariables
import { ROUTES } from 'src/constants';

const TikTokCallback: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get('code');
  const state = urlParams.get('state');

  const [connectTikTokStore] = useMutation(CONNECT_TIKTOK_STORE, {
    onCompleted: (res) => {
      if (res?.connectTiktokStore?.status === 'CONNECTED') {
        history.push(ROUTES.settings.integrations.main);
      }
    },
    onError: (error) => {
      console.log('error::', error);
    }
  });

  useEffect(() => {
    if (code && state) {
      connectTikTokStore({
        variables: {
          input: { code, state }
        }
      });
    }
  }, [code, state]);

  return (
    <div className="w-100 full-height d-flex align-items-center justify-content-center">
      <div>
        <Loader type="page" className="m-b-24" />
        <h1>TikTok Connecting ...</h1>
      </div>
    </div>
  );
};

export default TikTokCallback;
