import { useState, useEffect, FC, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import { DataTable, Pagination } from 'src/components/molecules';
import { MoreActionDropdown, EmptyList } from 'src/components/oraganisms';
import StatusCell from './components/StatusCell';
import ActionMenu from './components/ActionMenu';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import { LinkIcon, DefaultImage, EmptyDataIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables, ROUTES } from 'src/constants';

// Types
import { CatalogyListProps } from './CatalogList.type';
import { ColumnType } from 'src/components/molecules/Table/Table.types';
import { moreActionMenuItem } from '../MoreActionDropdown/MoreActionDropdown.types';

// Styles
import './_catalogList.scss';

const CatalogList: FC<CatalogyListProps> = ({
  setSelectedCatalogIDs,
  selectedCatalogIDs,
  catalogList,
  onDeleteCatalog,
  onToggleCatalog,
  catalogsToDelete,
  setCatalogsToDelete,
  isLoading,
  editColumnData,
  pageInfo,
  setPageInfo,
  totalCount
}) => {
  const history = useHistory();
  const [showMoreAction, setShowMoreAction] = useState(false);
  const [copied, setCopied] = useState(-1);
  const [activeColumns, setActiveColumns] = useState<ColumnType[]>([]);

  useEffect(() => {
    if (editColumnData?.length > 0) {
      const arr = Array.from(editColumnData);
      editColumnData.map((column) => {
        if (!column.status) {
          arr.splice(arr.indexOf(column), 1);
        }
        return null;
      });
      setActiveColumns([...arr]);
      localStorage.setItem('catalogTable', JSON.stringify(editColumnData));
    }
  }, [editColumnData]);

  const selectCatalog = useCallback(
    (e: any, catalogId?: any) => {
      if (e === 'All') {
        setSelectedCatalogIDs(catalogList.map((catalog) => catalog.id));
      } else if (e.target.checked) {
        setSelectedCatalogIDs([...selectedCatalogIDs, catalogId]);
      } else {
        setSelectedCatalogIDs(selectedCatalogIDs.filter((catalogItemId) => catalogItemId !== catalogId));
      }
    },
    [catalogList, selectedCatalogIDs]
  );

  useEffect(() => {
    setSelectedCatalogIDs([]);
  }, [catalogList]);

  const onDeleteClick = () => {
    onDeleteCatalog();
  };

  const copyLink = (text: string, id: number) => {
    navigator?.clipboard?.writeText(text);
    setCopied(id);
  };

  const catalogOptions = (id: number, name: string, catalogId: number) => {
    if (name.toUpperCase() === 'DELETE CATALOG') {
      setCatalogsToDelete([catalogId]);
      onDeleteCatalog();
    }
    if (name.toUpperCase() === 'EDIT CATALOG') {
      history.push(`${ROUTES.marketing.catalogs.editCatalog}/${catalogId}`);
    }
  };

  const selectActiveInactive = (id: number, name: string) => {
    onToggleCatalog(id, name);
  };

  const initialColumnOptions = [
    {
      dataKey: 'checkbox',
      name: 'Checkbox',
      width: 50,
      headerRenderer: () => {
        return (
          <div className="form-check form-check-sm form-check-custom">
            <input
              className="me-5 form-check-input widget-9-check"
              type="checkbox"
              checked={catalogList?.length === selectedCatalogIDs?.length}
              onChange={() => {
                if (catalogList?.length === selectedCatalogIDs?.length) {
                  setSelectedCatalogIDs([]);
                } else {
                  selectCatalog('All');
                }
              }}
            />
          </div>
        );
      },
      cellRenderer: ({ rowData }) => {
        return (
          <div className="form-check form-check-sm form-check-custom">
            <input
              className="me-5 form-check-input widget-9-check"
              type="checkbox"
              checked={selectedCatalogIDs.includes(rowData.id)}
              onChange={(e) => {
                selectCatalog(e, rowData.id);
              }}
              onClick={(e) => {
                selectCatalog(e, rowData.id);
              }}
            />
          </div>
        );
      },
      staticColumn: true
    },
    {
      dataKey: 'name',
      name: 'Catalogs',
      width: 300,
      cellRenderer: ({ rowData }) => {
        return (
          <div className="d-flex align-items-center">
            <div className="symbol me-5">
              <Img
                src={setImageSrc(rowData?.thumbnailUrl ? generateCompressedImageURL(rowData?.thumbnailUrl, '260') : DefaultImage)}
                placeholderImg={DefaultImage}
                errorImg={DefaultImage}
                className="object-fit-scale-down bg-white border border-light border-2"
              />
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <Link to={`/marketing/catalogs/edit/${rowData.id}`} className="main-cell">
                <span className="d-block">{rowData.name}</span>
              </Link>
            </div>
          </div>
        );
      },
      staticColumn: true
    },
    {
      dataKey: 'name',
      name: 'Products',
      width: 120,
      cellRenderer: ({ rowData }) => {
        return <div>{rowData.productIds.length}</div>;
      }
    },
    {
      dataKey: 'catalogType',
      name: 'Catalog Type',
      width: 150,
      cellRenderer: ({ rowData }) => {
        return <FlexBox className="align-items-center">{rowData.type}</FlexBox>;
      }
    },
    {
      dataKey: 'status',
      name: 'Status',
      width: 150,
      cellRenderer: ({ rowData }) => {
        return <StatusCell id={rowData.id} status={rowData.status} updateStatus={selectActiveInactive} />;
      }
    },
    {
      dataKey: 'link',
      name: 'Link',
      width: 150,
      cellRenderer: ({ rowData }) => {
        return (
          <FlexBox className="align-items-center">
            <div
              className="d-flex align-items-center justify-content-start cursor-pointer"
              onClick={() => copyLink(rowData.link, rowData.id)}
            >
              <KTSVG path={LinkIcon} />
              <span className="ms-2 text-primary">{copied === rowData.id ? 'Copied!' : 'Copy Link'}</span>
            </div>
          </FlexBox>
        );
      }
    },
    {
      dataKey: 'action',
      name: '',
      width: 50,
      cellRenderer: ({ rowData }) => {
        return <ActionMenu catalog={rowData} catalogOptions={catalogOptions} />;
      },
      staticColumn: true
    }
  ];

  const columnOptions = useMemo(() => {
    const activeColumnNames = activeColumns?.map((column) => column.name);
    // eslint-disable-next-line array-callback-return
    const filteredColumns = initialColumnOptions
      ?.filter((column) => {
        if (column.staticColumn || activeColumnNames?.includes(column?.name)) return true;
        return false;
      })
      .sort(function (a, b) {
        if (a.dataKey === 'action') return 1;
        return activeColumnNames.indexOf(a.name) - activeColumnNames.indexOf(b.name);
      });
    return filteredColumns;
  }, [activeColumns, selectedCatalogIDs, catalogList, copied]);

  const moreActionMenus: Array<moreActionMenuItem[]> = [
    [
      {
        id: 1,
        text: 'Export selected',
        onClick: () => {
          setShowMoreAction(false);
          alert('export selected');
        }
      },
      {
        id: 2,
        text: 'Delete selected',
        onClick: () => {
          setCatalogsToDelete(selectedCatalogIDs);
          onDeleteClick();
          setShowMoreAction(false);
        },
        deleteOption: true
      }
    ]
  ];

  const closeMoreActionDropdown = () => {
    setShowMoreAction(false);
  };

  return (
    <div className="w-100">
      <div className="p-x-32 p-t-32">
        {!isLoading && totalCount === 0 && (
          <EmptyList
            title={constVariables.emptyDataList.catalog}
            description={constVariables.emptyDataList.catalogDescription}
            datatype="catalog"
            icon={EmptyDataIcon}
          />
        )}
        {selectedCatalogIDs.length > 0 && (
          <FlexBox className="justify-content-start align-items-center">
            <button className="btn btn-outlined-primary btn-sm me-2">
              <div className="d-flex align-items-center" onClick={() => setSelectedCatalogIDs([])}>
                <input
                  className={'form-check-input small-checkbox-size shadow-none opacity-100 bg-primary rounded-sm m-0'}
                  type="checkbox"
                  disabled={true}
                  ref={(input) => {
                    if (input) {
                      input.indeterminate = true;
                    }
                  }}
                />
                <span className="ms-2">{selectedCatalogIDs.length} Selected</span>
              </div>
            </button>
            <div className="position-relative">
              <button
                className={`btn btn-sm ${showMoreAction ? 'btn-primary' : 'btn-outlined-primary'}`}
                onClick={() => setShowMoreAction(true)}
              >
                {constVariables.Catalogs.moreActions}
              </button>
              <OutsideClickHandler onOutsideClick={closeMoreActionDropdown}>
                <MoreActionDropdown selected={showMoreAction} options={moreActionMenus} closeDropdown={closeMoreActionDropdown} />
              </OutsideClickHandler>
            </div>
          </FlexBox>
        )}

        <div className="p-t-12">
          <DataTable
            page="catalog"
            rowData={catalogList}
            columnOptions={columnOptions}
            isLoading={isLoading}
            searchText=""
            selectedIndex={-1}
            selectedIDs={selectedCatalogIDs}
          />
        </div>
      </div>

      <div className={clsx('w-100', { 'display-none': totalCount === 0 })}>
        <Pagination
          skipCount={pageInfo?.skipCount}
          limitCount={pageInfo?.limitCount}
          setSkipCount={(num: number) =>
            setPageInfo((prev) => ({
              ...prev,
              skipCount: num
            }))
          }
          total={totalCount}
        />
      </div>
    </div>
  );
};

export default CatalogList;
