import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';

// Apollo
import { useLazyQuery } from '@apollo/client';
import { GET_MANIFEST_DETAILS } from 'src/apollo/queries';

// Components
import { FixedHeaderTable, Divider } from 'src/components/molecules';
import { FlexBox, Img } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { setImageSrc } from 'src/utils/setImageSrc';

// Icons
import { DefaultImage, CloseIcon, ShippoLogoImage } from 'src/assets/icons';

// Types
import { ManifestPreviewProps, ManifestDetailItem } from './ManifestDropdown.types';
import { TablesProps } from '../Table/Table.types';

const ManifestPreview: FC<ManifestPreviewProps> = ({ selectedManifest, closeModal }) => {
  const [manifestDetails, setManifestDetails] = useState<ManifestDetailItem[]>([]);

  const [getManifestDetails, { loading }] = useLazyQuery(GET_MANIFEST_DETAILS, {
    onCompleted: (res) => {
      if (res) {
        setManifestDetails(res.getManifestDetails);
      }
    },
    onError: (err) => {
      console.log('err::', err);
    }
  });

  useEffect(() => {
    if (selectedManifest && selectedManifest?.id !== '') {
      getManifestDetails({
        variables: {
          input: {
            manifestId: selectedManifest?.id
          }
        }
      });
    }
  }, [selectedManifest]);

  let tableData: TablesProps = {};
  tableData = {
    headerClassName: 'position-relative ',
    isDragDisabled: true,
    bodyClass: 'w-100 no-top-border mh-300px',
    className: 'rounded-top w-100 scrollable-table-wrapper',
    headers: [
      {
        id: 1,
        className: 'py-0 ps-4 pe-0 align-middle table-header-background',
        colName: 'Customer'
      },
      {
        id: 2,
        className: 'py-0 align-middle table-header-background',
        colName: 'Order'
      },
      {
        id: 3,
        className: 'ps-3 py-0 align-middle table-header-background',
        colName: 'Shipping Label '
      }
    ],
    rows: manifestDetails?.map((item, index) => ({
      id: item.labelId,
      isDragDisabled: true,
      className: 'position-relative border-top-0 dontScrollBulkEditorTop',
      cells: [
        {
          className: `align-middle ps-4 pe-0 py-0 ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: (
            <div className="d-flex align-items-center">
              <div className="me-5">
                <Img
                  src={setImageSrc(item?.profileUrl ? item?.profileUrl : '')}
                  placeholderImg={DefaultImage}
                  errorImg={DefaultImage}
                  className="profile-avatar"
                />
              </div>
              <span>{item.firstName}</span>
            </div>
          )
        },
        {
          className: `align-middle text-muted py-0 align-middle ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: `#${item.orderId}`
        },
        {
          className: `align-middle text-muted w-120px ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: item.labelId
        }
      ]
    }))
  };

  return (
    <div className={clsx('modal-content manifest-preview-modal')}>
      <FlexBox className="manifest-preview-modal-header align-items-center justify-content-between position-relative">
        <FlexBox className="align-items-center">
          <Img src={setImageSrc(ShippoLogoImage)} />
          <div className="ms-4">
            <h3>Shippo_USPS_Account</h3>
            <p className="m-0">{selectedManifest?.id}</p>
          </div>
        </FlexBox>
        <KTSVG path={CloseIcon} className="cursor-pointer" onClick={closeModal} />
        <p className="creation-date position-absolute m-0">
          <span>Creation Date:</span> {moment(selectedManifest?.date).format('d MMM YYYY, hh:mm')}
        </p>
      </FlexBox>
      <Divider />
      <div className="manifest-preview-modal-content">
        <FixedHeaderTable
          headers={tableData.headers}
          rows={tableData.rows}
          loadingPosition="center"
          isLoading={loading}
          containerHeight={300}
          type="manifest"
        />
      </div>
      {manifestDetails?.length > 0 && (
        <>
          <Divider />
          <div className="manifest-preview-modal-footer">
            <span>{manifestDetails?.length} Labels are included</span>
          </div>
        </>
      )}
    </div>
  );
};

export default ManifestPreview;
