import firebase from 'firebase/app';
import 'firebase/auth';
import { envUrls } from 'src/constants';

export const firebaseData = firebase.initializeApp({
  apiKey: envUrls.firebaseApiKey,
  authDomain: envUrls.firebaseAuthDomain
});

export const createFirebaseUser = async (email: string, password: string): Promise<any> => {
  const firebaseAuth = firebaseData.auth();
  return await firebaseAuth
    .createUserWithEmailAndPassword(email, password)
    .then((userCredential) => {
      return { success: true, userCredential };
    })
    .catch((error) => {
      return { success: false, error, message: error.message };
    });
};

export const changePassword = async (newPassword: string): Promise<any> => {
  const firebaseAuth = firebaseData.auth();
  return await firebaseAuth.currentUser
    ?.updatePassword(newPassword)
    .then((userCredential) => {
      return { success: true, userCredential };
    })
    .catch((error) => {
      return { success: false, error, message: error.message };
    });
};

export const signInWithEmail = async (email: string, password: string): Promise<any> => {
  const firebaseAuth = firebaseData.auth();
  return await firebaseAuth
    .signInWithEmailAndPassword(email, password)
    .then(async (userCredential) => {
      const authUser: any = userCredential.user;
      const idToken = await authUser.getIdToken(true).then();
      return { success: true, userCredential, idToken };
    })
    .catch((error) => {
      return { success: false, error, message: error.message };
    });
};

export const sigupWithGoogle = async (): Promise<any> => {
  const googleProvider = new firebase.auth.GoogleAuthProvider();
  const firebaseAuth = firebaseData.auth();
  return firebaseAuth
    .signInWithPopup(googleProvider)
    .then(async (result) => {
      const authUser: any = result.user;
      const idToken = await authUser.getIdToken(true).then();
      return { success: true, userCredential: result, idToken };
    })
    .catch((error) => {
      // Handle Errors here.
      let errorMessage = error.message;
      if (error.code === 'auth/internal-error') {
        errorMessage = 'Something went wrong. Please try again later.';
      }
      return { success: false, error, message: errorMessage };
    });
};

export const sigupWithFacebook = async (): Promise<any> => {
  const firebaseAuth = firebaseData.auth();

  const facebookProvider = new firebase.auth.FacebookAuthProvider();
  return firebaseAuth
    .signInWithPopup(facebookProvider)
    .then(async (result) => {
      const authUser: any = result.user;
      const idToken = await authUser.getIdToken(true).then();
      return { success: true, userCredential: result, idToken };
    })
    .catch((error: any) => {
      let errorMessage = error.message;
      if (error.code === 'auth/internal-error') {
        errorMessage = 'Something went wrong. Please try again later.';
        error.message = 'Something went wrong. Please try again later.';
      }
      return { success: false, error, message: errorMessage };
    });
};

export const sigupWithApple = async (): Promise<any> => {
  const firebaseAuth = firebaseData.auth();
  const provider = new firebase.auth.OAuthProvider('apple.com');
  provider.addScope('email');
  provider.addScope('name');
  provider.setCustomParameters({
    display: 'popup'
  });
  return await firebaseAuth
    .signInWithPopup(provider)
    .then(async (result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const authUser: any = result.user;
      const idToken = await authUser.getIdToken(true).then();
      return { success: true, userCredential: result, idToken };
    })
    .catch((error) => {
      // Handle Errors here.

      let errorMessage = error.message;
      if (error.code === 'auth/internal-error') {
        errorMessage = 'Something went wrong. Please try again later.';
      }

      return { success: false, error, message: errorMessage };
    });
};

export const refreshToken = async (): Promise<string> => {
  try {
    const token = (await firebaseData.auth().currentUser?.getIdToken(true)) || '';
    return token;
  } catch (e) {
    return ``;
  }
};

export const getFirebaseToken = async (): Promise<string> => {
  try {
    const user = await firebaseData.auth().currentUser;
    console.log('getFirebaseToken user - ', user);

    // const lastSignInTime = new Date(user.metadata.lastSignInTime);
    // const lastSignInTimeTimeStamp = Math.round(lastSignInTime.getTime() / 1000);

    // const token = (await firebaseData.auth().currentUser?.getIdToken(true)) || '';
    // return token;
    return '';
  } catch (e) {
    return ``;
  }
};

export const signOut = async (): Promise<boolean> => {
  return await firebase
    .auth()
    .signOut()
    .then(() => {
      return true;
    })
    .catch((e) => {
      return false;
    });
};
