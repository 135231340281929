import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';

// Apollo
import { useLazyQuery } from '@apollo/client';
import { GET_LIVE_STREAMS } from 'src/apollo/queries/getLiveStreams';

// Components
import LiveShowDropdown from './LiveShowDropdown';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { CloseIcon, SearchIcon } from 'src/assets/icons';

// ConstVariables
import { ROUTES } from 'src/constants';

// Types
import { LiveshowSearch } from './LiveshowSearch.types';

const LiveshowSearchDropdown: FC<LiveshowSearch> = ({ handleSelectLiveshow }) => {
  const [search, setSearch] = useState<string>('');
  const [searchResponse, setSearchResponse] = useState([]);
  const history = useHistory();

  const [getLiveStreams, { loading }] = useLazyQuery(GET_LIVE_STREAMS, {
    variables: {
      input: {
        search
      }
    },
    onCompleted(data) {
      setShowSearchDropDown(true);
      if (search) {
        setSearchResponse(
          data.getLiveStreams?.map((item) => {
            const date = new Date(item.startingAt).toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              weekday: 'long'
            });
            const startTime = new Date(item.startingAt).toLocaleTimeString('en-US', { timeStyle: 'short' });
            const endTime = new Date(item.endingAt).toLocaleTimeString('en-US', { timeStyle: 'short' });
            const productLength = item.products ? item.products.length : 0;
            return {
              id: item.id,
              name: item.title,
              thumbnailUrl: item.thumbnailUrl || '',
              extraData: `${date} | ${startTime} - ${endTime} | ${productLength} Product${productLength > 1 ? 's' : ''} Live`,
              data: item
            };
          })
        );
      } else {
        setSearchResponse([]);
      }
    }
  });

  // Search for Livestreams
  const handleSearch = (searchString: string) => {
    setShowSearchDropDown(false);
    if (searchString.length > 0) {
      setSearch(searchString);
      onSearch(searchString);
    } else {
      setSearch(searchString);
      setSearchResponse([]);
    }
  };

  const onSearch = useMemo(
    () =>
      debounce(
        (search: string) =>
          getLiveStreams({
            variables: {
              input: {
                search
              }
            }
          }),
        500
      ),
    []
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    history.push(ROUTES.liveShow.calendar.search, { search });
  };

  const handleShowPreview = (data) => {
    handleSelectLiveshow(data);
    handleSearch('');
  };
  const [showSearchDropDown, setShowSearchDropDown] = useState(false);
  useEffect(() => {
    if (loading) {
      setShowSearchDropDown(false);
    }
  }, [loading]);

  return (
    <form className="position-relative d-flex flex-row flex-nowrap align-items-center rounded-2" onSubmit={handleSubmit}>
      <KTSVG svgClassName="h-35px w-35px px-2 py-1" path={SearchIcon} />
      <input
        className="livestream-search-bar bg-light border-0 px-2 py-1"
        type="text"
        aria-haspopup="true"
        data-toggle="dropdown"
        placeholder="Search for liveshows"
        value={search}
        onChange={(e) => handleSearch(e.target.value)}
      />
      {loading && <div className="spinner-border live-show-search-spinner text-primary"></div>}
      {!loading && <KTSVG svgClassName="h-30px w-30px px-2 py-1" path={CloseIcon} onClick={() => handleSearch('')} />}
      {showSearchDropDown && search && (
        <LiveShowDropdown
          data={searchResponse}
          showImage={true}
          selected={search.length > 0}
          onBlur={() => handleSearch('')}
          onSelect={(item) => handleShowPreview(item.data)}
          searchDropDown={true}
          closeDropdown={() => setSearch('')}
        />
      )}
    </form>
  );
};

export default LiveshowSearchDropdown;
