import { FC } from 'react';

// Components
import { Checkbox } from 'src/components/molecules';

// ConstVariables
import { constVariables } from 'src/constants/constVariables';

// Types
import { CouponChannelsTypeSection, CouponChannels } from 'src/components/pages/coupons/CreateCoupon/CreateCoupon.types';

const CouponChannelsCreateCoupon: FC<CouponChannelsTypeSection> = ({ couponChannel, setCouponChannel }) => {
  return (
    <div className="card w-100">
      <div className="section-title m-b-32">{constVariables.Coupons.AddCoupon.couponChannel.couponChannels}</div>

      {Object.keys(CouponChannels).map((channel, index) => {
        return (
          <Checkbox
            key={index}
            labelclassname={index === Object.keys(CouponChannels)?.length - 1 ? 'mb-0' : 'm-b-24'}
            name={CouponChannels[channel].title}
            value={couponChannel.value.includes(CouponChannels[channel].key)}
            onChangeValue={() => {
              if (couponChannel.value.includes(CouponChannels[channel].key)) {
                setCouponChannel({ value: couponChannel.value.filter((e: string) => e !== CouponChannels[channel].key) });
              } else {
                setCouponChannel({ value: [...couponChannel.value, CouponChannels[channel].key] });
              }
            }}
            noVerticalPadding
          />
        );
      })}
    </div>
  );
};

export default CouponChannelsCreateCoupon;
