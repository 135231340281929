/* eslint-disable id-blacklist */
import { CellComponent, createTextColumn } from 'react-datasheet-grid';
import CheckboxCell from './components/CheckboxCell';

export const FALSY = ['', 'false', 'no', 'off', 'disabled', '0', 'n', 'f', 'unchecked', 'undefined', 'null', 'wrong', 'negative'];

export const floatColumn = createTextColumn<number | null>({
  // formatBlurredInput: (value) => (typeof value === 'number' ? `$${new Intl.NumberFormat().format(value)}` : ''),
  parseUserInput: (value) => {
    const number = parseFloat(value);
    return !isNaN(number) ? number : null;
  },
  parsePastedValue: (value) => {
    const number = parseFloat(value);
    return !isNaN(number) ? number : null;
  }
});

export const percentColumn = createTextColumn<number | null>({
  // formatBlurredInput: (value) => (typeof value === 'number' ? `${new Intl.NumberFormat().format(value)}%` : ''),
  parseUserInput: (value) => {
    const number = parseFloat(value);
    return !isNaN(number) ? number : null;
  },
  parsePastedValue: (value) => {
    const number = parseFloat(value);
    return !isNaN(number) ? number : null;
  }
});

export const dimensionColumn = createTextColumn<number | null>({
  parseUserInput: (value) => {
    const number = parseFloat(value);
    return !isNaN(number) ? number : null;
  },
  parsePastedValue: (value) => {
    const number = parseFloat(value);
    return !isNaN(number) ? number : null;
  }
});
