import { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import WorldData from 'country-state-city-plus';

// Apollo
import { useMutation, useLazyQuery } from '@apollo/client';
import { GET_STORE_DETAILS } from 'src/apollo/queries';
import { UPDATE_STORE_DETAILS } from 'src/apollo/mutations';

// Components
import { SaveHeader, DiscardModal, CustomModal } from 'src/components/oraganisms';
import { FlexBox, Loader } from 'src/components/atoms';
import { GeneralDetails, BusinessDetails, StandardFormatDetails, CustomerDetails, ProductPrimaryMediaDetails } from './sections';

// Hooks && Utils && Helpers
import { isEmailValid } from 'src/utils/validate';
import useTitle from 'src/utils/hooks/useTitle';

// Icons
import { BackRoundedIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables, ROUTES } from 'src/constants';

// Types
import { StoreDetails, PhoneItem, CountryItem } from './ShopDetails.types';

// Styles
import './_shopDetails.scss';

export const errorTextGroups = {
  name: 'store name',
  customerSupportPhoneCountryCode: 'phone number (10-15 digits)',
  customerSupportPhone: 'phone number (10-15 digits)',
  customerSupportEmail: 'email address',
  legalBusinessName: 'business name',
  address: 'address',
  city: 'city',
  postalCode: 'postal code',
  state: 'state',
  country: 'country',
  unitOfMeasurement: 'unit system',
  weightUnit: 'weight unit',
  customerSpendingThreshold: 'amount'
};

const ShopDetails: FC = () => {
  useTitle('Settings - Shop details');
  const history = useHistory();

  const [formChanges, setFormChanges] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [phoneList, setPhoneList] = useState<PhoneItem[]>([]);
  const [countryList, setCountryList] = useState<CountryItem[]>([]);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [leavePageConfirmModal, setLeavePageConfirmModal] = useState(false);
  const [targettedPath, setTargettedPath] = useState<string>();
  const [locationState, setLocationState] = useState();
  const [storeDetailsData, setStoreDetailsData] = useState<StoreDetails>({
    name: '',
    customerSupportPhoneCountryCode: '+1',
    customerSupportPhone: '',
    customerSupportEmail: '',
    legalBusinessName: '',
    address: '',
    apartmentSuite: '',
    city: '',
    postalCode: '',
    state: '',
    country: 'United States',
    storeCurrency: 'USD ($)',
    unitOfMeasurement: 'in',
    weightUnit: 'lb',
    customerSpendingThreshold: ''
  });
  const [isAutoAsignPrimaryVideo, setIsAutoAsignPrimaryVideo] = useState(false);

  const [errorMessages, setErrorMessages] = useState<StoreDetails>({
    name: '',
    customerSupportPhoneCountryCode: '',
    customerSupportPhone: '',
    customerSupportEmail: '',
    legalBusinessName: '',
    address: '',
    city: '',
    postalCode: '',
    state: '',
    country: '',
    storeCurrency: '',
    unitOfMeasurement: '',
    weightUnit: '',
    customerSpendingThreshold: ''
  });

  const [getStoreDetails, { loading: isLoading }] = useLazyQuery(GET_STORE_DETAILS, {
    onCompleted: (response) => {
      if (response?.getStoreDetails) {
        const tempStoreDetails = response?.getStoreDetails;
        setStoreDetailsData((storeDetailsData) => ({
          ...storeDetailsData,
          name: tempStoreDetails.name ? tempStoreDetails.name : '',
          customerSupportPhoneCountryCode: tempStoreDetails.customerSupportPhoneCountryCode
            ? tempStoreDetails.customerSupportPhoneCountryCode
            : '+1',
          customerSupportPhone: tempStoreDetails.customerSupportPhone ? tempStoreDetails.customerSupportPhone : '',
          customerSupportEmail: tempStoreDetails.customerSupportEmail ? tempStoreDetails.customerSupportEmail : '',
          legalBusinessName: tempStoreDetails.legalBusinessName ? tempStoreDetails.legalBusinessName : '',
          address: tempStoreDetails.address ? tempStoreDetails.address : '',
          apartmentSuite: tempStoreDetails.apartmentSuite ? tempStoreDetails.apartmentSuite : '',
          city: tempStoreDetails.city ? tempStoreDetails.city : '',
          postalCode: tempStoreDetails.postalCode ? tempStoreDetails.postalCode : '',
          state: tempStoreDetails.state ? tempStoreDetails.state : '',
          country: tempStoreDetails.country ? tempStoreDetails.country : 'United States',
          storeCurrency: tempStoreDetails.storeCurrency ? tempStoreDetails.storeCurrency : 'USD ($)',
          unitOfMeasurement: tempStoreDetails.unitOfMeasurement ? tempStoreDetails.unitOfMeasurement : 'in',
          weightUnit: tempStoreDetails.weightUnit ? tempStoreDetails.weightUnit : 'lb',
          customerSpendingThreshold: tempStoreDetails.customerSpendingThreshold ? tempStoreDetails.customerSpendingThreshold : ''
        }));

        setIsAutoAsignPrimaryVideo(tempStoreDetails.autoAssignPrimaryVideo);
      }
    },
    onError: (error) => {
      console.log('error', error);
    }
  });

  const [updateStoreDetails] = useMutation(UPDATE_STORE_DETAILS, {
    onCompleted: (response) => {
      if (response?.updateStoreDetails?.success) {
        setFormChanges(false);
        setDisableSaveButton(false);
      }
    },
    onError: (error) => {
      console.log('error', error);
      setFormChanges(false);
      setDisableSaveButton(false);
    }
  });

  useEffect(() => {
    getStoreDetails();
    getCountryAndPhoneList();
  }, []);

  const returnBack = () => {
    if (formChanges) {
      setLeavePageConfirmModal(true);
    } else {
      history.push(ROUTES.settings.main);
    }
  };

  const getCountryAndPhoneList = () => {
    const getAllCountries = WorldData.getAllCountries();
    if (getAllCountries) {
      const tempCountryList = getAllCountries.map((data, index) => {
        return { id: index, name: data.name, extraData: data.id, countryCode: data.sortname };
      });
      setCountryList(tempCountryList);

      const tempPhoneList = getAllCountries.map((data, index) => {
        return {
          id: index,
          name: `${data.name.toString()} (+${data.phonecode.toString()})`,
          extraData: `+${data.phonecode.toString()}`
        };
      });
      setPhoneList(tempPhoneList);
    }
  };

  const removeErrorMessages = (fieldName: string) => {
    setErrorMessages((errorMessages) => ({
      ...errorMessages,
      [fieldName]: ''
    }));
  };

  const validateInputValues = (fieldName: string, value: string, errorText?: string) => {
    if (fieldName === 'apartmentSuite') return;
    if (
      !value ||
      ((fieldName === 'name' || fieldName === 'legalBusinessName' || fieldName === 'city' || fieldName === 'state') &&
        value.length > 50) ||
      (fieldName === 'customerSupportPhone' && (value.length < 10 || value.length > 15)) ||
      (fieldName === 'customerSupportEmail' && !isEmailValid(value)) ||
      (fieldName === 'address' && value.length > 100)
    ) {
      setErrorMessages((errorMessages) => ({
        ...errorMessages,
        [fieldName]: `Please enter a valid ${errorText}.`
      }));
      return true;
    } else {
      removeErrorMessages(fieldName);
      return false;
    }
  };

  const handleUpdateStoreDetails = (fieldName: string, value: string, errorText?: string) => {
    setStoreDetailsData((storeDetails) => ({
      ...storeDetails,
      [fieldName]: value
    }));
    setFormChanges(true);
    setErrorMessages((errorMessages) => ({
      ...errorMessages,
      [fieldName]: ''
    }));
  };

  const handleSaveStoreDetails = () => {
    const storeDetailsKeys = Object.keys(storeDetailsData);
    for (let i = 0; i < storeDetailsKeys.length; i++) {
      validateInputValues(storeDetailsKeys[i], storeDetailsData[storeDetailsKeys[i]], errorTextGroups[storeDetailsKeys[i]]);
    }

    const isError =
      storeDetailsKeys.map((key) => validateInputValues(key, storeDetailsData[key], errorTextGroups[key])).filter((v) => !v)
        .length < 15
        ? true
        : false;

    if (isError) return;

    setDisableSaveButton(true);
    updateStoreDetails({
      variables: {
        input: {
          name: storeDetailsData.name,
          customerSupportPhoneCountryCode: storeDetailsData.customerSupportPhoneCountryCode,
          customerSupportPhone: storeDetailsData.customerSupportPhone,
          customerSupportEmail: storeDetailsData.customerSupportEmail,
          legalBusinessName: storeDetailsData.legalBusinessName,
          address: storeDetailsData.address,
          apartmentSuite: storeDetailsData.apartmentSuite,
          city: storeDetailsData.city,
          postalCode: storeDetailsData.postalCode,
          state: storeDetailsData.state,
          country: storeDetailsData.country,
          storeCurrency: storeDetailsData.storeCurrency,
          unitOfMeasurement: storeDetailsData.unitOfMeasurement,
          weightUnit: storeDetailsData.weightUnit,
          customerSpendingThreshold: parseFloat(storeDetailsData.customerSpendingThreshold),
          autoAssignPrimaryVideo: isAutoAsignPrimaryVideo
        }
      }
    });
  };

  const handleDiscardModal = () => {
    getStoreDetails();
    closeDiscardModal();
    setFormChanges(false);
  };

  useEffect(() => {
    const preventRoute = history.block((e: any) => {
      setTargettedPath(e.pathname);
      setLocationState(e.state);
      if (formChanges) {
        setLeavePageConfirmModal(true);
        return false;
      } else {
        return true;
      }
    });

    return () => {
      preventRoute();
    };
  }, [history, formChanges]);

  const leavePageHandler = () => {
    closeConfirmModal();
    setFormChanges(false);
    setTimeout(() => {
      if (targettedPath) {
        if (locationState) {
          history.push({
            pathname: targettedPath,
            state: locationState
          });
        } else {
          history.push(targettedPath);
        }
      } else {
        history.push(ROUTES.settings.main);
      }
    }, 1000);
  };

  const handleChangeAutoAssign = (value: boolean) => {
    setFormChanges(true);
    setIsAutoAsignPrimaryVideo(value);
  };

  const closeDiscardModal = () => {
    document.body.style.overflow = 'unset';
    setShowDiscardModal(false);
  };

  const closeConfirmModal = () => {
    document.body.style.overflow = 'unset';
    setLeavePageConfirmModal(false);
  };

  return (
    <>
      {showDiscardModal && (
        <CustomModal bodyClassname="w-90 w-md-50" show={showDiscardModal} closeModal={closeDiscardModal}>
          <DiscardModal
            title={constVariables.shopDetails.discardForm.title}
            message={constVariables.shopDetails.discardForm.message}
            actionBtnTitle={constVariables.shopDetails.discardForm.action}
            cancelBtnTitle={constVariables.shopDetails.discardForm.cancel}
            actionBtnHandler={handleDiscardModal}
            cancelBtnHandler={closeDiscardModal}
          />
        </CustomModal>
      )}
      {leavePageConfirmModal && (
        <CustomModal bodyClassname="w-90 w-md-50" show={leavePageConfirmModal} closeModal={closeConfirmModal}>
          <DiscardModal
            deleteProductMedia
            title={constVariables.common.LeaveForm.title}
            message={constVariables.common.LeaveForm.message}
            actionBtnTitle={constVariables.common.LeaveForm.action}
            cancelBtnTitle={constVariables.common.LeaveForm.cancel}
            actionBtnHandler={leavePageHandler}
            cancelBtnHandler={closeConfirmModal}
          />
        </CustomModal>
      )}
      {formChanges && (
        <SaveHeader onSave={handleSaveStoreDetails} saveDisabled={disableSaveButton} onDiscard={() => setShowDiscardModal(true)} />
      )}
      <div className="shop-details-page p-x-40">
        <div className={`shop-details-page-container ${formChanges ? 'pt-0' : 'p-t-40'} p-b-40`}>
          <FlexBox className={`align-items-center ${formChanges ? 'p-t-24 p-b-40' : 'p-y-40'}`}>
            <img src={BackRoundedIcon} alt={BackRoundedIcon} onClick={returnBack} className="cursor-pointer" />
            <h2 className="page-title px-4 mb-0">{constVariables.shopDetails.pageName}</h2>
          </FlexBox>
          <div>
            {isLoading ? (
              <Loader type="page" className="h-400px card mb-0" />
            ) : (
              <>
                <GeneralDetails
                  storeDetailsData={storeDetailsData}
                  handleUpdateStoreDetails={handleUpdateStoreDetails}
                  errorMessages={errorMessages}
                  phoneList={phoneList}
                  validateInputValues={validateInputValues}
                />
                <BusinessDetails
                  storeDetailsData={storeDetailsData}
                  handleUpdateStoreDetails={handleUpdateStoreDetails}
                  errorMessages={errorMessages}
                  countryList={countryList}
                  validateInputValues={validateInputValues}
                />
                <StandardFormatDetails
                  storeDetailsData={storeDetailsData}
                  handleUpdateStoreDetails={handleUpdateStoreDetails}
                  errorMessages={errorMessages}
                  validateInputValues={validateInputValues}
                />
                <CustomerDetails
                  storeDetailsData={storeDetailsData}
                  handleUpdateStoreDetails={handleUpdateStoreDetails}
                  errorMessages={errorMessages}
                  validateInputValues={validateInputValues}
                />
                <ProductPrimaryMediaDetails
                  isAutoAsignPrimaryVideo={isAutoAsignPrimaryVideo}
                  handleChangeAutoAssign={handleChangeAutoAssign}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopDetails;
