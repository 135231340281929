import { FC } from 'react';

// Components
import { Loader } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { CloseIcon } from 'src/assets/icons';

interface AppPublishConfirmModalProps {
  closeModal: () => void;
  handleSaveAppTheme: (type: string) => void;
  isPublishLoading: boolean;
}

const AppPublishConfirmModal: FC<AppPublishConfirmModalProps> = ({ closeModal, handleSaveAppTheme, isPublishLoading }) => {
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Save or publish unsaved changes</h5>
        <div className="btn btn-xs btn-active-light-primary p-0 m-0 border-none" onClick={closeModal}>
          <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
        </div>
      </div>
      <div className="modal-body">
        <p>Do you wish to save unsaved changes as draft for later, or publish all changes for reviewing?</p>
        <p>
          <span className="fw-bolder">Note:</span> If publish, the app updates may take approximately 1 hour. We will notify you
          once the changes are live.
        </p>
      </div>
      <div className="modal-footer">
        <button className="btn btn-secondary btn-md" data-bs-dismiss="modal" type="button" onClick={closeModal}>
          Cancel
        </button>
        <button
          className="btn btn-primary btn-md"
          type="button"
          disabled={isPublishLoading}
          onClick={() => handleSaveAppTheme('publish')}
        >
          {isPublishLoading ? 'Publishing' : 'Publish'}
          {isPublishLoading && <Loader type="button" className="h-10px w-10px" />}
        </button>
      </div>
    </div>
  );
};

export default AppPublishConfirmModal;
