import { gql } from '@apollo/client';

export const UPDATE_TAG = gql`
  mutation onUpdateTag($input: UpdateCollectionOrCatalogueInput!) @api(name: "productsAPI") {
    updateTag(input: $input) {
      success
      message
      id
      name
      textColorCode
      backgroundColorCode
    }
  }
`;
