/* eslint-disable import/prefer-default-export */
import { makeVar, ReactiveVar } from '@apollo/client';
import { IFacebookConfig } from 'src/types/FacebookConfig.types';

export const refreshInactiveList = makeVar(false);
export const refreshActiveList = makeVar(false);
export const refreshArchivedList = makeVar(false);
export const refreshAllProductList = makeVar(false);
export const successToast = makeVar(false);
export const refreshDraftList = makeVar(false);
export const showOrderError = makeVar(true);
export const orderWithErrors: any = makeVar([]);
export const addErrorFilter = makeVar(false);
export const userLoginData: ReactiveVar<{
  email?: string;
  // eslint-disable-next-line
  email_verified?: boolean;
  sub?: string;
  isAuthenticated?: boolean;
}> = makeVar({});
export const shippoConfigCode = makeVar('');
export const facebookAuthConfig = makeVar<IFacebookConfig | undefined>(undefined);
