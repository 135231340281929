/* eslint-disable import/prefer-default-export */
import { InMemoryCache, defaultDataIdFromObject } from '@apollo/client';

export const cache: InMemoryCache = new InMemoryCache({
  addTypename: false,
  typePolicies: {
    Query: {
      fields: {}
    }
  },
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      case 'CheckForCombiningOrds':
        return 'checkForCombiningOrds';
      case 'StoreDetails':
        return 'StoreDetails';
      case 'AvalaraAccount':
        return 'AvalaraAccount';
      case 'StripeConnection':
        return 'StripeConnection';
      case 'BasicUserInfo':
        return 'LoggedinUserInfo';
      case 'AllProductsStoreOwnerOutputV3':
        return `ProductV3_${responseObject?.productId}`;
      case 'LiveStreamDetails':
        return `LiveStreamDetails_${responseObject?.id}`;
      case 'CouponsList':
        return `${responseObject?.id}`;

      default:
        return defaultDataIdFromObject(responseObject);
    }
  }
});
