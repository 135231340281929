import { createSlice } from '@reduxjs/toolkit';

interface UserInfoState {
  userInfo: {
    email: string;
    firstName: string;
    lastName: string;
    isEmailVerified: boolean;
    medium: string;
    permission: {
      section: string;
      permissions: string[];
    }[];
    profileURL: string;
    role: string;
    roleDisplayName: string;
    storeId: string;
    storeName: string;
    userId: string;
    userPhoneNumber: string;
  };
}

const initialState: UserInfoState = {
  userInfo: {
    email: '',
    firstName: '',
    lastName: '',
    isEmailVerified: false,
    medium: '',
    permission: [
      {
        section: '',
        permissions: []
      }
    ],
    profileURL: '',
    role: '',
    roleDisplayName: '',
    storeId: '',
    storeName: '',
    userId: '',
    userPhoneNumber: ''
  }
};

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    getUserInfoSuccess: (state, action) => {
      const { payload } = action;
      state.userInfo = payload;
    },
    getUserInfoFailure: (state, action) => {},
    updateUserInfoSuccess: (state, action) => {
      const { payload } = action;
      state.userInfo = {
        ...state.userInfo,
        profileURL: payload
      };
    }
  }
});

export const { getUserInfoSuccess, getUserInfoFailure, updateUserInfoSuccess } = userInfoSlice.actions;

export default userInfoSlice.reducer;
