import { FC, useEffect, useState } from 'react';

// Components
import { FlexBox, Loader } from 'src/components/atoms';
import { Thumbnail } from 'src/components/oraganisms';
import { CatalogProductCategory, CatalogStatus, RulesEngineProducts } from 'src/components/molecules';
import { GeneralInfoSection, TypeSection } from 'src/components/templates';

// Icons
import { BackRoundedIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants/constVariables';

// Types
import { AddCatalogPresentationalProps } from './AddCatalog.types';
import { productAssignmentMethod } from '../../featured-products/FeaturedProducts.types';

// Styles
import './_addCatalog.scss';

const AddCatalogPresentational: FC<AddCatalogPresentationalProps> = ({
  formChanges,
  isPageLoading,
  generalInfo,
  setGeneralInfo,
  errorMessages,
  productListData,
  setProductListData,
  productCategory,
  setProductCategory,
  catalogStatus,
  setCatalogStatus,
  thumbnailUrlData,
  setThumbnailUrlData,
  catalogId,
  goBackToCatalogScreen,
  productCategoryInput,
  setProductCategoryInput,
  deleteCatalog,
  disabledDelBtn,
  // Rules engine
  selectedProductAssignmentMethod,
  selectProductAssignmentMethod,
  rulesEngineConditions,
  setRulesEngineConditions,
  fieldDropdown,
  setAllConditionMatch,
  allConditionMatch,
  conditionDropdownArray,
  setConditionDropdownArray,
  setFormChanges,
  rulesChanges,
  setRulesChanges,
  saveModuleRules
  // Rules engine
}) => {
  const [hasProductFilterConditions, setHasProductFilterConditions] = useState(false);

  const deleteCatalogPassage = () => {
    if (catalogId) deleteCatalog(catalogId);
  };

  useEffect(() => {
    if (rulesEngineConditions.length > 0) {
      setHasProductFilterConditions(true);
    } else {
      setHasProductFilterConditions(false);
    }
  }, [rulesEngineConditions]);

  const handleRemoveProductsFromCatalog = (ids) => {
    setFormChanges(true);
  };

  return (
    <div className={`details-page-container ${formChanges ? 'pt-0' : ''}`}>
      <div className="width-animation w-100 hide-scrollbar">
        <FlexBox className="align-items-center justify-content-between">
          <FlexBox className={`align-items-center px-0 ${formChanges ? 'p-t-24 p-b-40' : 'p-y-40'}`}>
            <img src={BackRoundedIcon} alt="Back" className=" me-5 h-30px w-30px cursor-pointer" onClick={goBackToCatalogScreen} />
            <h2 className="page-title mb-0">
              {catalogId ? constVariables.Catalogs.addCatalog.editPageHeading : constVariables.Catalogs.addCatalog.pageHeading}
            </h2>
          </FlexBox>
          {catalogId ? (
            <button disabled={disabledDelBtn} onClick={deleteCatalogPassage} type="button" className="btn btn-danger btn-md m-0">
              {constVariables.Catalogs.addCatalog.deleteButtonTitle}
            </button>
          ) : null}
        </FlexBox>
        <div>
          {isPageLoading ? (
            <Loader type="page" className="placeholder-content card m-8" />
          ) : (
            <div className="main-detail-layout">
              <div className="left-pane">
                {/* LEFT */}
                <GeneralInfoSection
                  type="Catalog"
                  generalInfo={generalInfo}
                  setGeneralInfo={setGeneralInfo}
                  errorMessages={errorMessages}
                />

                <TypeSection
                  title="Catalog type"
                  typeDescription="this catalog"
                  selectedProductAssignmentMethod={selectedProductAssignmentMethod}
                  selectProductAssignmentMethod={selectProductAssignmentMethod}
                  rulesEngineConditions={rulesEngineConditions}
                  setRulesEngineConditions={setRulesEngineConditions}
                  fieldDropdown={fieldDropdown}
                  conditionDropdownArray={conditionDropdownArray}
                  setConditionDropdownArray={setConditionDropdownArray}
                  allConditionMatch={allConditionMatch}
                  setAllConditionMatch={setAllConditionMatch}
                  setFormChanges={setFormChanges}
                  rulesChanges={rulesChanges}
                  setRulesChanges={setRulesChanges}
                  saveModuleRules={saveModuleRules}
                />
                <RulesEngineProducts
                  type="catalog"
                  productListIds={
                    selectedProductAssignmentMethod === productAssignmentMethod.AUTOMATIC
                      ? productListData.autoMaticProductListIds
                      : productListData.manualProductListIds
                  }
                  selectedProductsIds={productListData.selectedProductsIds}
                  setProductListData={setProductListData}
                  isAutoType={selectedProductAssignmentMethod === productAssignmentMethod.AUTOMATIC ? true : false}
                  hasConditions={hasProductFilterConditions}
                  removeProducts={handleRemoveProductsFromCatalog}
                  setFormChanges={setFormChanges}
                  detailId={catalogId}
                />
              </div>

              <div className="right-pane">
                <CatalogStatus
                  catalogStatus={catalogStatus}
                  setCatalogStatus={setCatalogStatus}
                  setFormChanges={setFormChanges}
                  errorMessages={errorMessages}
                />
                <CatalogProductCategory
                  productCategory={productCategory}
                  setProductCategory={setProductCategory}
                  productCategoryInput={productCategoryInput}
                  setProductCategoryInput={setProductCategoryInput}
                  setFormChanges={setFormChanges}
                />
                <Thumbnail
                  type=""
                  thumbnailUrlData={thumbnailUrlData}
                  showDeleteIcon={true}
                  setThumbnailUrlData={setThumbnailUrlData}
                  uploadeType="CATALOG"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddCatalogPresentational;
