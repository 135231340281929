import { FC } from 'react';

// Components
import Tooltip from './Tooltip';

interface ShopifySyncTooltipProps {
  tooltipText: string;
  tooltipId?: string;
}

const ShopifySyncTooltip: FC<ShopifySyncTooltipProps> = ({ tooltipText, tooltipId }) => {
  return (
    <Tooltip
      tooltipId={tooltipId ? tooltipId : 'shopifySyncIcon'}
      place="top"
      content={() => {
        return <>{tooltipText}</>;
      }}
    />
  );
};

export default ShopifySyncTooltip;
