import { gql } from '@apollo/client';

export const GET_PRODUCT_NOTE = gql`
  query GetProductNote($input: ProductNotes!) @api(name: "productsAPI") {
    getProductNote(input: $input) {
      id
      userName
      imageUrl
      note
      updatedAt
    }
  }
`;
