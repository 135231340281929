import React, { PropsWithChildren, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useLocation, useHistory } from 'react-router';

// Components
import { Tooltip } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { checkIsActive, KTSVG } from '../../../helpers';
import { useLayout } from '../../core';

// Styles
import './AsideMenuItem.scss';

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  defaultRedirectRoute?: string;
  autoSelectSubItem: boolean;
};

const AsideMenuItemWithSub: React.FC<PropsWithChildren<Props>> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  defaultRedirectRoute,
  autoSelectSubItem
}) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const subMenusRef = useRef<HTMLDivElement>(null);
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { aside } = config;
  const handleClick = (e) => {
    if (!autoSelectSubItem) return;

    const newOpen = !pathname.includes(to);

    if (newOpen) {
      if (subMenusRef.current) {
        e.preventDefault();
        e.stopPropagation();
        subMenusRef.current.style.removeProperty('display');
        subMenusRef.current.style.removeProperty('overflow');
        history.push(defaultRedirectRoute);
      }
    }
  };

  return (
    <div className={clsx('menu-item', { 'here show': isActive }, 'menu-accordion')} data-kt-menu-trigger="click">
      <span className={clsx('menu-link without-sub', { active: isActive })} onClick={handleClick}>
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && (
          <>
            <div data-tip={title} data-for={`menuItem-${title}`}>
              <span className="menu-icon">
                <KTSVG path={icon} className="svg-icon-2" />
              </span>
            </div>
            {/* {aside.minimize && <Tooltip tooltipId={`menuItem-${title}`} className="titleNames" place="right" />} */}
          </>
        )}
        {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className="menu-title">{title}</span>
      </span>
      <div
        className={clsx('menu-sub menu-sub-accordion', {
          'menu-active-bg': isActive
        })}
        ref={subMenusRef}
      >
        {children}
      </div>
    </div>
  );
};

export { AsideMenuItemWithSub };
