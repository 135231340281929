import { gql } from '@apollo/client';

export const DELETE_BACKSTORE_USER = gql`
  mutation DeleteBackstoreUser($input: deleteBackstoreUserInput!) @api(name: "authAPI") {
    deleteBackstoreUser(input: $input) {
      success
      message
      userId
      additionalData
    }
  }
`;
