import { FC, useState } from 'react';

// Components
import { InputBox, Tooltip } from 'src/components/molecules';
import { FlexBox } from 'src/components/atoms';

// Icons
import { DownArrowIcon, InfoIcon, UpArrowIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { EditInventoryCommonSubtractProps } from './EditInventoryCommonSubtract.types';

const EditInventoryCommonSubtract: FC<EditInventoryCommonSubtractProps> = ({
  value,
  setValue,
  total,
  levelType,
  handleUpdateInventoryStock
}) => {
  const [error, setError] = useState('');
  const changeInputText = (data) => {
    if (data === '') {
      setValue(data);
    } else if (!isNaN(data) && data < total + 1) {
      setValue(data);
    }
  };

  const incOne = () => {
    if (parseInt(value, 10) < total) setValue((parseInt(value, 10) + 1).toString());
  };
  const decOne = () => {
    if (parseInt(value, 10) > 1) setValue((parseInt(value, 10) - 1).toString());
  };

  const handleInputKeyEvent = (event) => {
    if (event.keyCode === 13) {
      if (event.target.value === '') {
        setError('Enter value greater than 0.');
        return;
      } else {
        setError('');
      }
      handleUpdateInventoryStock(event.target.value, levelType);
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center">
        <div className="helper-text">Input quantity</div>
        <img src={InfoIcon} className="ms-2 cursor-pointer h-10px w-10px" data-tip data-for="inventorySubstractInfo" />
        <Tooltip
          tooltipId="inventorySubstractInfo"
          place="top"
          content={() => {
            return <>Old stock - Subtract stock = Updated stock</>;
          }}
        />
      </div>

      <div className="mt-4 d-flex align-items-center justify-content-between">
        <div>{total}</div>
        <div>-</div>
        <FlexBox
          className={`w-50 form-control form-control-sm  px-5 justify-content-between align-items-center cursor-pointer dropdown-box dropdown-box-active`}
        >
          <InputBox
            inputClass="text-muted stock-level"
            onChangeText={changeInputText}
            name="StockRecivedInput"
            onKeyDown={handleInputKeyEvent}
            value={value}
            noBorders
            isAutoFocus={true}
          />

          <div className="opacity-50 d-flex flex-column">
            <img onClick={incOne} className="cursor-pointer h-10px w-10px" src={UpArrowIcon} />
            <img onClick={decOne} className="cursor-pointer h-10px w-10px" src={DownArrowIcon} />
          </div>
        </FlexBox>
        <div>=</div>
        {value ? <div>{total - parseInt(value, 10)}</div> : <div>{total}</div>}
      </div>
      {error ? <div className="fs-9 text-danger">{error}</div> : null}
    </div>
  );
};

export default EditInventoryCommonSubtract;
