import { gql } from '@apollo/client';

export const DELETE_FROM_COLLECTION = gql`
  mutation DeleteFromCollection($input: CollectionMutationInput!) @api(name: "AuthAPI") {
    deleteFromCollection(input: $input) {
      success
      message
    }
  }
`;
