import { ApolloCache } from '@apollo/client';

export const modifyCacheForAutoCombineOrders = (cache: ApolloCache<any>, isEnabled: boolean) => {
  try {
    cache.modify({
      id: 'checkForCombiningOrds',
      fields: {
        isAutomaticCombiningEnabled() {
          return isEnabled;
        }
      }
    });
    cache.modify({
      id: 'StoreDetails',
      fields: {
        combineOrders() {
          return isEnabled;
        }
      }
    });
  } catch (err) {
    console.log(err);
  }
};
