export const WeightCodes = [
  {
    id: 1,
    name: 'lb'
  },
  {
    id: 2,
    name: 'oz'
  }
  // {
  //   id: 3,
  //   name: 'kg'
  // },
  // {
  //   id: 4,
  //   name: 'g'
  // }
];
export const SizeCodes = [
  {
    id: 1,
    name: 'in'
  },
  {
    id: 2,
    name: 'ft'
  }
  // {
  //   id: 3,
  //   name: 'm'
  // },
  // {
  //   id: 4,
  //   name: 'cm'
  // },
  // {
  //   id: 5,
  //   name: 'mm'
  // },
  // {
  //   id: 6,
  //   name: 'yd'
  // }
];
