export interface tiktokInputType {
  status?: string[] | null;
  searchString: string;
  pageInfo: {
    skipCount: number;
    limitCount: number;
  };
}

export interface TiktokCategoryItem {
  id: string;
  l1: string;
  l2: string;
  l3: string;
}

export interface TiktokCategoryOptionItem {
  id: string;
  name: string;
}

export interface TikTokProduct {
  id: number;
  name: string;
  imageUrl: string;
  inventory: number;
  category: TiktokCategoryItem;
  status: string;
  error: any;
  price: number;
}

export enum TIKTOK_PRODUCT_STATUS {
  PROCESSING = 'PROCESSING',
  QUEUED = 'QUEUED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DRAFT = 'DRAFT',
  IN_REVIEW = 'IN_REVIEW',
  FAILED = 'FAILED',
  SUSPENDED = 'SUSPENDED',
  UPDATES_REQUIRED = 'UPDATES_REQUIRED'
}
