import { FC, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { FlexBox } from 'src/components/atoms';
import { Dropdown } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { CloseIcon, RemoveButtonIcon, DragIcon, DownArrowIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { DraggableTagInputProps } from './DraggableTagInput.types';

// Styles
import './draggableTagInput.scss';

const DraggableTagInput: FC<DraggableTagInputProps> = ({
  dragHandler,
  dropdownData,
  index,
  onChangeText,
  onDelete,
  onHandleChange,
  title,
  value,
  errTitle,
  errVariant,
  checkTitleError,
  checkTagError,
  setDropdownData,
  options
}) => {
  const [tagvalue, setagvalue] = useState<string[]>([]);
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [input, setInput] = useState<string>('');
  const [doubleclickTitle, setDoubleclickTitle] = useState<boolean>(true);
  const [focusVariantName, setFocusVariantName] = useState<boolean>(false);
  const [previousTitle] = useState<string>(title);

  const textHandler = (e: any) => {
    if (e.target.value !== '' && (e.code === 'Comma' || e.key === 'Enter')) {
      if (e.code === 'Comma') {
        const modifiedInput = e.target.value.split(',');
        setagvalue((prev) => [...prev, modifiedInput.find((item: string) => item !== '')]);
        onHandleChange([...options[index].value, modifiedInput.find((item: string) => item !== '')], index);
      } else {
        setagvalue((prev) => [...prev, e.target.value]);
        onHandleChange([...options[index].value, e.target.value], index);
      }
      setInput('');
    }

    if (input === '') {
      if (e.key === 'Backspace') {
        const arr = [...value];
        arr.pop();
        setagvalue(arr);
        onHandleChange(arr, index);
      }
    }
  };
  const closeDropdown = () => {
    setOpenDropdown(false);
  };
  const remove = (id: any) => {
    const arr = [...value];
    arr.splice(id, 1);
    setagvalue(arr);
    onHandleChange(arr, index);
  };
  const selectStatus = (e: any) => {
    onChangeText(e.name, index);
    setOpenDropdown(!openDropdown);
  };
  return (
    <div className="w-100 variant-input-item">
      <div className="text-nowrap poppins-semibold fs-6 mb-3">{`Option ${+index + 1}`}</div>
      <div className="d-flex  w-100">
        <span {...dragHandler.dragHandleProps} className="me-2 mt-4">
          <KTSVG path={DragIcon} />
        </span>
        <div className="position-relative ">
          <OutsideClickHandler onOutsideClick={closeDropdown}>
            <FlexBox
              className={`form-control form-control-lg  px-5 justify-content-between align-items-center cursor-pointer dropdown-box ${
                openDropdown ? 'dropdown-box-active' : ''
              }`}
              onClick={() => {
                setOpenDropdown(!openDropdown);
              }}
            >
              <input
                autoComplete="off"
                onDoubleClick={() => setDoubleclickTitle(false)}
                className="form-control form-control-lg me-3 title-input"
                type="text"
                onKeyDown={(e) => {
                  if (e.key === 'Escape') {
                    onChangeText(previousTitle, index);
                  }
                }}
                autoCapitalize="words"
                onBlur={() => {
                  setDoubleclickTitle(true);
                  checkTitleError(index);
                }}
                readOnly={doubleclickTitle}
                value={title}
                onChange={(e: any) => onChangeText(e.target.value, index)}
              />
              <div>
                <KTSVG path={DownArrowIcon} />
              </div>
            </FlexBox>

            <Dropdown
              className="dropdown-custom-width"
              data={dropdownData}
              selected={openDropdown}
              value={title}
              onSelect={selectStatus}
              closeDropdown={() => setOpenDropdown(false)}
            />
          </OutsideClickHandler>
          {errTitle && title === '' && <span className="warning-text fs-7">{constVariables.common.errors.errVariantTitle}</span>}
        </div>
        <div className="d-flex align-items-start w-100">
          <div className="w-100">
            <div className="row align-items-center tag-input m-0">
              {value.map((item, index) => (
                <div style={{ width: 'fit-content' }} className="p-0" key={item}>
                  <div className="d-flex align-items-center fs-7 p-1 py-1 rounded w-20  mb-1 small-tags">
                    <span className="me-1">{item}</span>

                    <KTSVG path={CloseIcon} className="cursor-pointer flex-1" onClick={() => remove(index)} />
                  </div>
                </div>
              ))}
              <div className="col w-100 p-0" style={{}}>
                <input
                  autoComplete="off"
                  className="border-0 tag-input-class w-100"
                  type="text"
                  value={input.replace(/,/g, '')}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyDown={textHandler}
                  onBlur={() => {
                    setFocusVariantName(false);
                    checkTagError(index);
                  }}
                  onFocus={() => {
                    setFocusVariantName(true);
                    setOpenDropdown(false);
                  }}
                  autoFocus
                />
              </div>
            </div>

            <div className="d-flex flex-column">
              {errVariant && tagvalue.length === 0 && (
                <span className="warning-text fs-7 ms-4">{constVariables.common.errors.errVariantField}</span>
              )}
              {value.length <= 0 && focusVariantName && (
                <span className="helper-text fs-6 mt-1 ms-4">{constVariables.common.variantions.tagInputHelperText}</span>
              )}
            </div>
          </div>
          <button
            className="btn delete-icon-btn ms-4 mt-2"
            onClick={() => {
              onDelete(index);
            }}
          >
            <KTSVG path={RemoveButtonIcon} className="m-0" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default DraggableTagInput;
