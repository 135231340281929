import React, { useCallback, useState } from 'react';
import { OrderStatus, OrdersListData } from '../OrdersList.types';
import { useToast } from 'src/utils/hooks/useToast';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { CHECK_ELEIGIBILITY_FOR_COMBINING_ORDERS, GET_ORDER_STATUS_COUNT } from 'src/apollo/queries';
import { GET_ORDER_DETAIL_WITH_ERROR_DATA } from 'src/apollo/queries/getOrderDetailWithErrorData';
import { ROUTES } from 'src/constants';
import { COMBINE_ORDERS } from 'src/apollo/mutations';
import { PRINT_PACKING_SLIP } from 'src/apollo/mutations/printPackingSlip';

interface IUsePrintPackingSlip {
  checkForOrdrErrors: () => void;
  checkOrdersForCombining: () => void;
  combineAndPrintPackingSlip: () => void;
  printPackingSlip: (orderIds?: number[]) => void;
  getPackingSlipDetails: (orderIds: number[]) => Promise<any>;
  setIsShowConfirmCombinedOrderModal: React.Dispatch<React.SetStateAction<boolean>>;
  error: ApolloError | undefined;
  isPrintingPackingSlip: boolean;
  isFetchingDataFromServer: boolean;
  isAutomaticCombiningEnabled: boolean | undefined;
  isShowConfirmCombinedOrderModal: boolean;
}

export const usePrintPackingSlip = (
  selectedOrderIds: Array<number | undefined>,
  refresh: () => void,
  orderList?: Array<OrdersListData>,
  setErrorOrderIds?: React.Dispatch<React.SetStateAction<{}>>,
  setShowErrorModal?: React.Dispatch<React.SetStateAction<boolean>>
): IUsePrintPackingSlip => {
  const { showToast } = useToast();
  const [isPrintingPackingSlip, setIsPrintingPackingSlip] = useState(false);
  const [idsWithoutError, setIdsWithoutError] = useState<number[]>([]);
  const [isShowConfirmCombinedOrderModal, setIsShowConfirmCombinedOrderModal] = useState(false);

  const [checkCmbEligibility, { data: cmbData }] = useLazyQuery(CHECK_ELEIGIBILITY_FOR_COMBINING_ORDERS);

  const [getErrorData] = useLazyQuery(GET_ORDER_DETAIL_WITH_ERROR_DATA);
  const [getPackingSlip, { loading: isFetchingDataFromServer, error }] = useMutation(PRINT_PACKING_SLIP);

  const [combineOrders] = useMutation(COMBINE_ORDERS);

  const checkForOrdrErrors = async () => {
    setIsPrintingPackingSlip(true);
    !!setErrorOrderIds && setErrorOrderIds({});
    !!setShowErrorModal && setShowErrorModal(false);

    try {
      const idsWithErr: OrdersListData['id'][] = [];
      const idsWithOutErr: OrdersListData['id'][] = [];
      const erroridsMap = {};
      if (selectedOrderIds?.length) {
        orderList?.forEach((d) => {
          if (selectedOrderIds.includes(d?.id)) {
            if (!d?.isOrderAddressVerified) {
              // d?.hasWeightErrors ||
              // Just ignore weight errors
              idsWithErr.push(+d?.id);
              erroridsMap[+d?.id] = true;
            } else {
              idsWithOutErr.push(+d?.id);
            }
          }
        });
      } else {
        const noOfDocs = 100;
        let i = 0;
        let con = true;
        while (con) {
          const { data } = await getErrorData({
            variables: {
              input: {
                pagination: { skipCount: i * noOfDocs, limitCount: noOfDocs },
                status: [OrderStatus.AWAITING_PROCESSING],
                combined: true
              }
            }
          });
          con = data?.getOrderDetailsList?.orders.length === noOfDocs;

          data?.getOrderDetailsList?.orders?.forEach((d) => {
            if (!d?.isOrderAddressVerified) {
              // d?.hasWeightErrors ||
              // Just ignore weight errors
              idsWithErr.push(+d?.id);
              erroridsMap[+d?.id] = true;
            } else {
              idsWithOutErr.push(+d?.id);
            }
          });
          i++;
        }
      }

      setIdsWithoutError(idsWithOutErr);
      if (idsWithErr?.length) {
        !!setErrorOrderIds && setErrorOrderIds(erroridsMap);
        !!setShowErrorModal && setShowErrorModal(true);
      } else {
        await checkOrdersForCombining(idsWithOutErr);
      }
    } catch (err) {
      showToast({ errorText: 'Error in printing packing slip', message: '' });
    } finally {
      setIsPrintingPackingSlip(false);
    }
  };

  const checkOrdersForCombining = async (ids?: number[]) => {
    setIsPrintingPackingSlip(true);
    !!setShowErrorModal && setShowErrorModal(false);
    const ordrIds = ids || idsWithoutError;
    try {
      if (ordrIds.length < 1) return;
      const { data } = await checkCmbEligibility({
        variables: {
          input: {
            orderIds: ordrIds
          }
        }
      });
      const isAvailableForCombining = data?.checkForCombiningOrds?.isAvailableForCombining;
      const isAutomaticCombiningEnabled = data?.checkForCombiningOrds?.isAutomaticCombiningEnabled;
      if (!isAvailableForCombining) {
        await printPackingSlip(ordrIds);
      } else {
        if (isAutomaticCombiningEnabled) {
          await combineAndPrintPackingSlip(ordrIds);
        } else {
          setIsShowConfirmCombinedOrderModal(true);
        }
      }
    } catch (err: any) {
      throw new Error(err);
    } finally {
      setIsPrintingPackingSlip(false);
    }
  };

  const combineAndPrintPackingSlip = async (ids?: number[]) => {
    setIsPrintingPackingSlip(true);
    setIsShowConfirmCombinedOrderModal(false);
    const ordrIds = ids || idsWithoutError;
    try {
      if (ordrIds.length < 1) return;
      const response = await combineOrders({
        variables: {
          input: { orderIds: ordrIds }
        }
      });
      await refresh();
      const combinedOrds = JSON.parse(response?.data?.combineOrders);
      let ordsToPrint = ordrIds;
      if (combinedOrds) {
        const newids: number[] = [];
        Object.keys(combinedOrds).forEach((k) => {
          newids.push(+k);
        });
        const idsTOremove = {};
        Object.values(combinedOrds).forEach((myarr) => {
          if (Array.isArray(myarr)) {
            myarr?.forEach((key) => {
              idsTOremove[+key] = true;
            });
          }
        });

        const filtered = ordrIds.filter((id) => !idsTOremove[+id]);
        ordsToPrint = [...filtered, ...newids];
        setIdsWithoutError(ordsToPrint);
      }
      await printPackingSlip(ordsToPrint);
    } catch (err: any) {
      throw new Error(err);
    } finally {
      setIsPrintingPackingSlip(false);
    }
  };

  const printPackingSlip = useCallback(
    async (idsToProcess?: number[]) => {
      setIsPrintingPackingSlip(true);
      setIsShowConfirmCombinedOrderModal(false);
      const ordIds = idsToProcess || idsWithoutError;

      try {
        if (ordIds.length < 1) return;
        const newWindow = window.open(ROUTES.orders.allOrders.printOrder, '_blank');
        if (newWindow) {
          newWindow.localStorage.setItem('savedOrders', JSON.stringify(ordIds));
        }
      } catch (err: any) {
        throw new Error(err);
      } finally {
        setIsPrintingPackingSlip(false);
        setTimeout(() => {
          refresh();
        }, 5000);
      }
    },
    [idsWithoutError]
  );

  const getPackingSlipDetails = async (orderIds: number[]) => {
    const { data } = await getPackingSlip({
      variables: {
        orderIds
      },
      onError: () => {
        showToast({ errorText: 'Error in printing packing slip', message: '' });
      }
    });

    return data?.printPackingSlip;
  };

  return {
    checkForOrdrErrors,
    checkOrdersForCombining,
    setIsShowConfirmCombinedOrderModal,
    combineAndPrintPackingSlip,
    printPackingSlip,
    getPackingSlipDetails,
    error,
    isPrintingPackingSlip,
    isFetchingDataFromServer,
    isAutomaticCombiningEnabled: cmbData?.checkForCombiningOrds?.isAutomaticCombiningEnabled,
    isShowConfirmCombinedOrderModal
  };
};
