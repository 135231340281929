import { gql } from '@apollo/client';

export const GET_UESR_TAGS = gql`
  query GetUserTags($input: UserIdInput) @api(name: "productsAPI") {
    getUserTags(input: $input) {
      id
      tag
      color
      bgColor
    }
  }
`;
