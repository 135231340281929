import { gql } from '@apollo/client';

export const UPDATE_INVENTORY_STOCK_LEVEL = gql`
  mutation UpdateInventoryStockLevel($input: StockAdjustmentInput!) @api(name: "productsAPI") {
    updtInventStockLvl(input: $input) {
      id
      success
      message
      additionalData
    }
  }
`;
