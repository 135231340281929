import { gql } from '@apollo/client';

export const PIN_COMMENT = gql`
  mutation pinComment($input: PinCommentInput!) @api(name: "liveStreamingAPI") {
    pinComment(input: $input) {
      id
      content
      sendTime
      isPinned
      attributes {
        BSCommentId
      }
      sender {
        userId
        attributes {
          storeId
          showId
          userImage
          userName
        }
      }
    }
  }
`;
