import { gql } from '@apollo/client';

export const SIGN_UP_BACK_STORE_USERS = gql`
  mutation SignupBackstoreUser($input: SignupBackstoreUserInput!) @api(name: "authAPI") {
    signupBackstoreUser(input: $input) {
      email
      firstName
      lastName
      profileUrl
      medium
      token {
        accessToken
        idToken
        refreshToken
      }
      userId
    }
  }
`;
