import { FC, useState, createRef } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { Dropdown, Portal } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers/components/KTSVG';

// Icons
import { ThreeDotsIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants/constVariables';

// Types
import { WarehouseList, DropdownOptions } from '../WarehouseList.types';

interface ActionMenuProps {
  warehouse: WarehouseList;
  warehouseOptions: (data: DropdownOptions, warehouse: WarehouseList) => void;
}

const ActionMenu: FC<ActionMenuProps> = ({ warehouse, warehouseOptions }) => {
  const menuBtnRef = createRef<any>();
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownPositions, setDropdownPositions] = useState({
    top: 0,
    left: 0
  });

  const handleShowDropdown = () => {
    setShowDropdown(true);

    if (menuBtnRef.current) {
      const position = menuBtnRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - position.bottom;
      setDropdownPositions({
        top: spaceBelow < 150 ? window.scrollY + position.top - 150 : window.scrollY + position.top + 30,
        left: position.left + 90
      });
    }
  };

  return (
    <div className="position-relative">
      <button onClick={handleShowDropdown} className="bg-transparent border border-transparent" ref={menuBtnRef}>
        <KTSVG path={ThreeDotsIcon} svgClassName="cursor-pointer collapseFilter collapseIcon" />
      </button>
      {showDropdown && (
        <Portal id="kt_body">
          <OutsideClickHandler
            onOutsideClick={() => {
              if (showDropdown) setShowDropdown(false);
            }}
          >
            <div className="position-absolute" style={{ top: dropdownPositions.top, left: dropdownPositions.left }}>
              <Dropdown
                data={
                  // eslint-disable-next-line no-nested-ternary
                  warehouse.isDefault
                    ? constVariables.warehouseDefaultOptionsDropdownData
                    : warehouse.isActive
                    ? constVariables.warehouseOptionsDropdownData
                    : constVariables.warehouseInactiveOptionsDropdownData
                }
                selected={showDropdown}
                value={warehouse.isActive ? 'Active' : 'Inactive'}
                onSelect={(data) => {
                  warehouseOptions(data, warehouse);
                }}
                className="mt-1"
                shiftLeft
                shiftLeftValue="-250px"
                closeDropdown={() => setShowDropdown(false)}
              />
            </div>
          </OutsideClickHandler>
        </Portal>
      )}
    </div>
  );
};

export default ActionMenu;
