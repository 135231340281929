import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

// Apollo
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  ADD_VENDOR_NOTES,
  DELETE_VENDOR_NOTES,
  EDIT_VENDOR_NOTES,
  EDIT_VENDOR_STATUS,
  GET_VENDOR_NOTE,
  GET_VENDORS
} from 'src/apollo/queries';

// Components
import VendorDetailsPresentational from './VendorDetails.presentational';

// ConstVariables
import { constVariables } from 'src/constants';

const VendorDetails: FC = () => {
  const history = useHistory();
  const { vendorId } = useParams<{ vendorId: string }>();

  const [addNewNote, setAddNewNote] = useState('');
  const [currentNoteId, setCurrentNoteId] = useState(0);
  const [currentNote, setCurrentNote] = useState('');
  const [isStatusModal, setStatusModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [currentVendor, setCurrentVendor] = useState<any>({}); // TODO: need model here
  const [selectedTab, setSelectedTab] = useState('Details');
  const [showVendorDetails, setShowVendorDetails] = useState(false);
  const [refetchList, setRefetchList] = useState(false);

  const [getCurrentVendor, { data: vendorDetails, loading, error }] = useLazyQuery(GET_VENDORS, {
    fetchPolicy: 'network-only'
  });

  const [getVendorNotes, { data: vendorNotesData }] = useLazyQuery(GET_VENDOR_NOTE);
  const [addVendorNote, { data: notesAdded }] = useMutation(ADD_VENDOR_NOTES);
  const [deleteVendorNotes, { data: notesDeleted }] = useMutation(DELETE_VENDOR_NOTES);
  const [editVendorNotes, { data: notesEdited }] = useMutation(EDIT_VENDOR_NOTES);
  const [changeVendorStatus] = useMutation(EDIT_VENDOR_STATUS);
  const handleAddNewNote = (notes: string) => {
    setAddNewNote(notes);
  };

  const breadCrumbList = [
    {
      title: constVariables.VendorManagementMenu.vendorManagement,
      path: '/vendorManagement/vendors',
      isActive: false
    },
    {
      title: constVariables.VendorManagementMenu.vendors,
      path: '/vendorManagement/vendors',
      isActive: true
    }
  ];

  const getCurrentVendorObj = () => {
    getCurrentVendor({
      variables: {
        input: {
          ids: [parseInt(vendorId, 10)],
          pagination: {
            skipCount: 0,
            limitCount: 10
          },
          status: 'ALL'
        }
      }
    });
  };

  useEffect(() => {
    getCurrentVendorObj();
  }, []);

  useEffect(() => {
    if (vendorDetails?.getVendorDetails?.vendors.length > 0) {
      const tempCategoryInput: string[] = [];
      const categoryDetails: any = {};
      vendorDetails?.getVendorDetails?.vendors[0]?.categoriesSet?.map((set: any, index: number) => {
        categoryDetails.category = set?.category;
        tempCategoryInput.push(set?.category?.name);
        categoryDetails.openCategoryDropdown = false;
        const arr = Array.from(set?.subCategory);
        arr.map((subCategory: any, index: number) => {
          const obj = Object.assign({}, subCategory);
          delete obj.categoryId;
          arr[index] = obj;
          return null;
        });
        categoryDetails.subCategory = arr;
        return null;
      });
      const vendorObj = { ...vendorDetails?.getVendorDetails?.vendors[0], categoryDetails };
      setCurrentVendor(vendorObj);
    }
  }, [vendorDetails]);

  useEffect(() => {
    setTimeout(() => {
      const myDiv = document.getElementById('scrollNotes');
      myDiv?.scrollTo(0, myDiv?.scrollHeight);
    }, 300);
  }, [vendorNotesData]);

  useEffect(() => {
    if (vendorId)
      getVendorNotes({
        variables: {
          input: {
            vendorId: Number(vendorId)
          }
        }
      });
  }, [getVendorNotes, notesAdded?.addVendorNote, notesDeleted?.deleteVendorNote, notesEdited?.editVendorNote, vendorId]);

  const handelSendNotes = (event: React.MouseEvent) => {
    event.preventDefault();
    setAddNewNote('');
    addVendorNote({
      variables: {
        input: {
          vendorId: Number(vendorId),
          note: addNewNote
        }
      }
    });
    const myDiv = document.getElementById('scrollNotes');
    myDiv?.scrollTo(0, myDiv?.scrollHeight);
  };

  const handleDeleteNote = (noteId: number, vendorNote: string, event: React.MouseEvent) => {
    setCurrentNoteId(noteId);
    setCurrentNote(vendorNote);
    setIsDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    setIsDeleteModal(false);
    deleteVendorNotes({
      variables: {
        input: {
          vendorId: Number(vendorId),
          id: currentNoteId,
          note: currentNote
        }
      }
    });
  };

  const handleUpdatedNote = (editedNotes: string, noteId: number) => {
    editVendorNotes({
      variables: {
        input: {
          vendorId: Number(vendorId),
          id: noteId,
          note: editedNotes
        }
      }
    });
  };

  const updateVendorStatus = () => {
    setStatusModal(false);
    changeVendorStatus({
      variables: {
        input: {
          id: Number(currentVendor?.id),
          status:
            currentVendor?.status === 'ARCHIVE'
              ? constVariables.VendorDetails.endRelationship.active
              : constVariables.VendorDetails.endRelationship.archive
        }
      }
    }).then(() => history.go(0));
  };

  const openEndRelationShipModal = () => {
    currentVendor?.status !== 'ARCHIVE' ? setStatusModal(true) : updateVendorStatus();
  };

  const closeModal = () => {
    setStatusModal(false);
    setIsDeleteModal(false);
  };

  return (
    <VendorDetailsPresentational
      vendorId={Number(vendorId)}
      currentVendor={currentVendor}
      vendorNotesData={vendorNotesData}
      isStatusModal={isStatusModal}
      isDeleteModal={isDeleteModal}
      addNewNote={addNewNote}
      breadCrumbs={breadCrumbList}
      showVendorDetails={showVendorDetails}
      selectedTab={selectedTab}
      refetchList={refetchList}
      editDetails={() => {
        setShowVendorDetails(!showVendorDetails);
        setRefetchList(!refetchList);
      }}
      setSelectedTab={setSelectedTab}
      handleDeleteNote={handleDeleteNote}
      handleAddNewNote={handleAddNewNote}
      handelSendNotes={handelSendNotes}
      handleUpdatedNote={handleUpdatedNote}
      handleStatus={openEndRelationShipModal}
      handleConfirmEnd={updateVendorStatus}
      handleConfirmDelete={handleConfirmDelete}
      closeModal={closeModal}
      updateVendorDetails={getCurrentVendorObj}
      loading={loading || error ? true : false}
    />
  );
};

export default VendorDetails;
