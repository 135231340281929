import React, { createContext } from 'react';
import { IRecordingsDownloader } from './RecordingsDownloader.types';

const RecordingsDownloaderContext = createContext<IRecordingsDownloader>({
  uri: null,
  progress: 0,
  downloadRecordings: null,
  onCancelDownload: null
});

export { RecordingsDownloaderContext };
