import React, { ReactNode, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// Components
import DownloadRecordingToast from 'src/components/molecules/Toast/DownloadRecordingToast';

// Hooks && Utils && Helpers
import { createHash } from 'src/utils/encrypt/hash';
import useFfmpeg from 'src/utils/hooks/useFfmpeg';
import { RecordingsDownloaderContext } from './RecordingsDownloader.context';

// Types
import {
  IDownloaderQue,
  IRecordingsDownloader,
  TDownloadRecrdingsHandler,
  TOnCancelDownloadHandler
} from './RecordingsDownloader.types';

const RecordingsDownloaderProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [queue, setQueue] = useState<IDownloaderQue[]>([]);
  const [uri, setUri] = useState<string | null>(null);
  const onComplete = (hash: string) => {
    if (queue[0].urlHash === hash) {
      toast.dismiss(queue[0].toastId);
      setQueue((q) => q.slice(1));
    }
  };

  const { isProcessing, downloadHls, processingFileHash, progress, onCancel } = useFfmpeg({ onComplete });

  useEffect(() => {
    if (!isProcessing && queue.length) {
      const { uri, duration, title } = queue[0];
      setUri(uri);
      downloadHls(uri, title, duration);
    }
  }, [queue]);

  // Methods to be shared
  const downloadRecordings: TDownloadRecrdingsHandler = async (uri: string, title: string, duration: number) => {
    const urlHash = createHash(uri);
    if (queue.find((q) => q.uri === uri)) return;

    const toastId = toast(<DownloadRecordingToast uri={uri} />, {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: true,
      progress,
      closeOnClick: false
    });

    setQueue((q) => [...q, { uri, title, duration, urlHash, toastId }]);
  };

  const onCancelDownload: TOnCancelDownloadHandler = async (uri: string) => {
    const index = queue.findIndex((d) => d.uri === uri);

    if (index >= 0) {
      toast.dismiss(queue[index].toastId);
      if (queue[index].urlHash === processingFileHash) {
        onCancel();
      } else {
        setQueue((q) => {
          q.splice(index, 1);
          return q;
        });
      }
    }
  };

  const eventHandlers: IRecordingsDownloader = {
    uri,
    progress,
    downloadRecordings,
    onCancelDownload
  };

  return <RecordingsDownloaderContext.Provider value={eventHandlers}>{children}</RecordingsDownloaderContext.Provider>;
};

export { RecordingsDownloaderProvider };
