import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import OutsideClickHandler from 'react-outside-click-handler';
import validator from 'validator';

// Components
import { Img } from 'src/components/atoms';
import { Dropdown, TagAndCollectionDropdown } from 'src/components/molecules';
import FieldTitles from './AddVendor.title';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { setImageSrc } from 'src/utils/setImageSrc';
import getDeviceData from 'src/utils/UseDevice/useDevice';

// Icons
import { CloseIcon, InfoIcon, BinIcon, PlusIcon, DragLogo, AddIcon, DownArrowIcon, DefaultImage } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { AddVendorPresentationalProps } from './AddVendor.types';

// Styles
import './_addVendor.scss';

const AddVendorPresentational: FC<AddVendorPresentationalProps> = ({
  show,
  resetState,
  contacts,
  addContact,
  deleteContact,
  salesInfos,
  addSalesInfo,
  deleteSaleInfo,
  locations,
  addLocation,
  deleteLocation,
  others,
  addOther,
  deleteOther,
  changeAppliedItems,
  handleDrop,
  imageDetails,
  uploadImageError,
  removeUploadedImage,
  showValidation,
  setShowValidation,
  changeExtraContactFields,
  changeExtraSalesInfoFields,
  changeExtraLocationsFields,
  changeExtraOthersFields,
  generalInfo,
  editGeneralInfo,
  contactInfo,
  editContactInfo,
  salesInformation,
  editSalesInformation,
  locationInfo,
  editLocationInfo,
  hoverAction,
  setHoverAction,
  allCategory,
  hideContactInfo,
  toggleContactInfo,
  onSaveVendor,
  handleEnter,
  categoriesAndSubCategories,
  addAndRemoveCategories,
  categoryInput,
  setCategoryInput,
  subCategoryInput,
  setSubCategoryInput,
  getSubCategories,
  callCreateSubCategory,
  setCategoryIndex,
  editVendor,
  openSubCategory,
  setOpenSubCategory,
  allSubCategories,
  contactTitleInput,
  setContactTitleInput,
  profileUrlData,
  isLoading
}) => {
  const deviceData = getDeviceData();
  const [openLoader, setOpenLoader] = useState(false);
  const handleOpenFileStack = () => {
    setOpenLoader(!openLoader);
  };

  const [isValidWebsite, setIsValidWebsite] = useState(false);
  useEffect(() => {
    if (generalInfo.website) {
      const isIT = validator.isURL(generalInfo.website);
      setIsValidWebsite(isIT);
    }
  }, [generalInfo.website]);

  return (
    <>
      {show && (
        <div
          className={clsx('add-vendor modal fade opacity-100', {
            show
          })}
          aria-modal="true"
          tabIndex={-1}
          id="kt_modal_1"
        >
          <div className={`modal-dialog ${deviceData.isWeb ? ' flex-center' : 'w-90 w-md-75 h-94vh'}`}>
            <div className={`modal-content ${deviceData.isWeb ? 'w-900px' : ''} h-90`}>
              <div className="modal-header">
                <h5 className="modal-title">
                  {editVendor ? constVariables.VendorManagementMenu.editVendor : constVariables.VendorManagementMenu.addNewVendor}
                </h5>
                <div className="btn btn-xs btn-active-light-primary p-0 m-0 border-none" onClick={resetState}>
                  <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
                </div>
              </div>
              <div className="modal-body overflow-scroll border-bottom border-bottom-light p-0">
                <div className="p-7">
                  <h5 className="poppins-semibold pb-5 mb-0">{constVariables.addVendor.generalInfo}</h5>
                  <div className="container px-0 mb-6">
                    <div className="row">
                      <div className="d-flex flex-column col-sm">
                        <div className="col-sm opacity-80 d-flex align-items-center mb-5">
                          <div className="text-nowrap poppins-semibold" style={{ width: '30%' }}>
                            {constVariables.addVendor.vendorName}
                          </div>
                          <div className="w-100">
                            <input
                              autoComplete="nope"
                              className={`form-control form-control-solid w-100 w-100 px-5 input-text-color poppins-regular ${
                                showValidation && !generalInfo.vendorName ? 'red-border' : ''
                              }`}
                              value={generalInfo.vendorName}
                              onChange={(e) => {
                                editGeneralInfo('vendorName', e.target.value);
                              }}
                              placeholder="Enter vendor name (Required)"
                            />
                            {showValidation && !generalInfo.vendorName && (
                              <div className={`text-danger fs-8 position-absolute`}>This field is required</div>
                            )}
                          </div>
                        </div>
                        <div className="col-sm opacity-80 d-flex align-items-center">
                          <div className="text-nowrap poppins-semibold" style={{ width: '30%' }}>
                            {constVariables.addVendor.website}
                          </div>
                          <div className="w-100">
                            <input
                              className="form-control form-control-solid w-100 w-100 px-5 input-text-color poppins-regular"
                              autoComplete="nope"
                              placeholder="https://"
                              value={generalInfo.website}
                              onChange={(e) => {
                                editGeneralInfo('website', e.target.value);
                              }}
                            />
                            {!isValidWebsite && generalInfo.website ? (
                              <div className={`text-danger fs-8 position-absolute`}>Enter a valid URL</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex col-sm">
                        <div className="profile-pic-margin"></div>
                        <Img
                          src={setImageSrc(profileUrlData ? profileUrlData : DragLogo)}
                          placeholderImg={DefaultImage}
                          errorImg={DefaultImage}
                          onClick={handleOpenFileStack}
                          className="vendorLogo cursor-pointer"
                        />
                        <div className="upload-logo">
                          <h4>Upload a logo</h4>
                          <p>Upload JPG, PNG or GIF Files here</p>
                        </div>
                        {/* NOTE: Disabled DropZone for FileStack POC */}
                        {/* <Dropzone onDrop={handleDrop} accept="image/*">
                                            {({
                                                getRootProps,
                                                getInputProps,
                                                isDragAccept,
                                                open
                                            }) => (
                                                <div
                                                    className={`col-sm d-flex justify-content-end align-items-center w-400px`}
                                                >
                                                    {isDragAccept ? (
                                                        <div
                                                            className="flex-center draggable-box p-5 px-9 h-100px"
                                                            {...getRootProps()}
                                                        >
                                                            <input {...getInputProps()} />
                                                            <img
                                                                src={AcceptDraggedFile}
                                                                alt={AcceptDraggedFile}
                                                            />
                                                            <div className="fs-6 poppins-semibold ms-5">
                                                                Drop the photo to upload it{' '}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div className="position-relative">
                                                                <img
                                                                    {...getRootProps()}
                                                                    src={
                                                                        imageDetails?.imgSrc
                                                                            ? imageDetails.imgSrc
                                                                            : uploadImageError
                                                                            ? DragErrorLogo
                                                                            : DragLogo
                                                                    }
                                                                    alt={DragLogo}
                                                                    className="h-100px w-100px cursor-pointer"
                                                                    style={{
                                                                        borderRadius: '10px'
                                                                    }}
                                                                />
                                                                <div
                                                                    className={
                                                                        imageDetails?.imgSrc
                                                                            ? 'delete-product-icon'
                                                                            : 'd-none'
                                                                    }
                                                                >
                                                                    <input {...getInputProps()} />
                                                                    <KTSVG
                                                                        path={CloseIcon}
                                                                        onClick={
                                                                            removeUploadedImage
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-column ms-8">
                                                                <div
                                                                    className={`poppins-semibold cursor-pointer ${
                                                                        uploadImageError
                                                                            ? 'text-danger fs-6'
                                                                            : 'fs-5'
                                                                    }`}
                                                                >
                                                                    {uploadImageError ? (
                                                                        <span>
                                                                            <KTSVG
                                                                                path={InfoIcon}
                                                                                className="cross-icon"
                                                                            />{' '}
                                                                            Media couldn’t be added
                                                                        </span>
                                                                    ) : (
                                                                        'Drop or upload a logo'
                                                                    )}
                                                                </div>
                                                                <div className="fs-8">
                                                                    {imageDetails?.fileName
                                                                        ? imageDetails?.fileName
                                                                        : uploadImageError
                                                                        ? uploadImageError
                                                                        : 'Upload JPG, PNG or GIF Files here'}
                                                                </div>
                                                                <div>
                                                                    {imageDetails?.fileName ? (
                                                                        <button
                                                                            className="btn change-logo py-2 px-3 fs-8"
                                                                            onClick={open}
                                                                        >
                                                                            {
                                                                                constVariables
                                                                                    .addVendor
                                                                                    .changeLogo
                                                                            }
                                                                        </button>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </Dropzone> */}
                      </div>
                    </div>
                  </div>
                  <div className="container px-0">
                    <div className="column">
                      <div className="d-flex flex-row row-sm mb-5">
                        <div className="col-sm opacity-80 d-flex align-items-center w-50">
                          <div className="text-nowrap me-5 poppins-semibold">
                            <span className="me-2">{constVariables.addVendor.accountNo}</span>
                            <KTSVG path={InfoIcon} />
                          </div>
                          <input
                            className="form-control form-control-solid w-100 w-100 px-5 input-text-color poppins-regular"
                            autoComplete="nope"
                            placeholder="Account number"
                            value={generalInfo.accountNo}
                            onChange={(e) => {
                              editGeneralInfo('accountNo', e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-sm opacity-80 d-flex align-items-center ps-5">
                          <div className="text-nowrap me-5 poppins-semibold">{constVariables.addVendor.vendorManager}</div>
                          <input
                            className="form-control form-control-solid w-100 w-100 px-5 input-text-color poppins-regular opacity-90"
                            placeholder="Name"
                            autoComplete="nope"
                            value={generalInfo.vendorManager}
                            onChange={(e) => {
                              editGeneralInfo('vendorManager', e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      {categoriesAndSubCategories.map((categoryAndSubCategory, index) => {
                        return (
                          <div key={index} className="d-flex flex-row col-sm mb-5">
                            <div className="row-sm d-flex align-items-center w-50">
                              <div className="text-nowrap me-5 poppins-semibold opacity-80">
                                {constVariables.addVendor.category}
                              </div>
                              <div className="w-100">
                                <div
                                  className={`outside-click-parent-div form-control form-control-solid h-40px d-flex p-0 position-relative flex-center input-text-color poppins-regular ${
                                    showValidation && index === 0 && !categoryAndSubCategory.category.name ? 'red-border' : ''
                                  }`}
                                  style={{
                                    borderRadius: '7px'
                                  }}
                                >
                                  <OutsideClickHandler
                                    onOutsideClick={() => {
                                      if (categoryAndSubCategory.openCategoryDropdown) {
                                        const obj = categoryAndSubCategory;
                                        obj.openCategoryDropdown = false;
                                        addAndRemoveCategories(obj, 'edit', index);
                                      }
                                    }}
                                  >
                                    <div
                                      className="pe-3 d-flex justify-content-between align-items-center w-100"
                                      onClick={() => {
                                        const obj = categoryAndSubCategory;
                                        obj.openCategoryDropdown = !obj.openCategoryDropdown;
                                        addAndRemoveCategories(
                                          {
                                            ...obj
                                          },
                                          'edit',
                                          index
                                        );
                                      }}
                                    >
                                      {categoryInput && (
                                        <div className="d-flex text-nowrap overflow-scroll hide-scrollbar">
                                          <input
                                            className={`category-input bg-transparent ps-2 w-100`}
                                            autoComplete="nope"
                                            value={categoryInput[index]}
                                            autoFocus={true}
                                            size={categoryInput[index]?.length ? categoryInput[index]?.length : 38}
                                            onChange={(e) => {
                                              const arr = categoryInput;
                                              arr[index] = e.target.value;
                                              setCategoryInput([...arr]);
                                              if (!e.target.value) {
                                                const obj = categoryAndSubCategory;
                                                obj.category = {
                                                  id: 0,
                                                  name: ''
                                                };
                                                addAndRemoveCategories(obj, 'edit', index);
                                              }
                                            }}
                                            onKeyDown={(e) => {
                                              if (e.key === 'Enter') {
                                                const obj = categoryAndSubCategory;
                                                obj.category = {
                                                  id: 0,
                                                  name: categoryInput[index]
                                                };
                                                addAndRemoveCategories(obj, 'newCategory', index);
                                              }
                                            }}
                                          />
                                          <div
                                            className={`ps-2 ${
                                              categoryInput[index]?.length > 0 &&
                                              categoryInput[index] !== categoryAndSubCategory?.category?.name
                                                ? ''
                                                : 'd-none'
                                            }`}
                                          >
                                            <div
                                              className="badge-0 p-1 pe-3 cursor-pointer"
                                              onClick={() => {
                                                const obj = categoryAndSubCategory;
                                                obj.category = {
                                                  id: 0,
                                                  name: categoryInput[index]
                                                };
                                                addAndRemoveCategories(obj, 'newCategory', index);
                                              }}
                                            >
                                              <KTSVG path={AddIcon} /> Add {categoryInput[index]?.trim()}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      <KTSVG path={DownArrowIcon} />
                                    </div>
                                    <Dropdown
                                      data={allCategory}
                                      selected={categoryAndSubCategory.openCategoryDropdown}
                                      className="h-300px w-250px overflow-scroll hide-scrollbar mt-5"
                                      onSelect={(data: any) => {
                                        const obj = categoryAndSubCategory;
                                        const arr = categoryInput;
                                        obj.category = data;
                                        obj.openCategoryDropdown = !obj.openCategoryDropdown;
                                        obj.subCategory = [];
                                        arr[index] = data.name;
                                        setCategoryInput([...arr]);
                                        addAndRemoveCategories(
                                          {
                                            ...obj
                                          },
                                          'edit',
                                          index
                                        );
                                      }}
                                      closeDropdown={() => {
                                        const obj = categoryAndSubCategory;
                                        obj.openCategoryDropdown = false;
                                        addAndRemoveCategories(obj, 'edit', index);
                                      }}
                                    />
                                  </OutsideClickHandler>
                                </div>
                                {showValidation && index === 0 && !categoryAndSubCategory.category.name && (
                                  <div className={`text-danger fs-8 position-absolute`}>This field is required</div>
                                )}
                              </div>
                            </div>
                            <div className="row-sm d-flex align-items-center position-relative ps-5 w-50">
                              <div className="text-nowrap me-5 poppins-semibold opacity-80">
                                {constVariables.addVendor.subCategory}
                              </div>
                              <div className="w-100 outside-click-parent-div overflow-scroll">
                                <OutsideClickHandler
                                  onOutsideClick={() => {
                                    if (openSubCategory[index]) {
                                      const arr = Array.from(openSubCategory);
                                      arr[index] = false;
                                      setOpenSubCategory(arr);
                                    }
                                  }}
                                >
                                  <div
                                    className="position-relative form-control form-control-solid h-40px p-0 overflow-scroll text-nowrap hide-scrollbar pe-3"
                                    style={{
                                      borderRadius: '7px'
                                    }}
                                    onClick={(event) => {
                                      if (event.target === event.currentTarget) {
                                        getSubCategories({
                                          variables: {
                                            input: {
                                              parentCategoryId: categoryAndSubCategory?.category?.id
                                            }
                                          }
                                        });
                                        const arr = Array.from(openSubCategory);
                                        arr[index] = !arr[index];
                                        setCategoryIndex(index);
                                        setOpenSubCategory(arr);
                                      }
                                    }}
                                  >
                                    {categoryAndSubCategory?.subCategory?.map((item, i) => {
                                      return (
                                        <div key={i} className={`badge ms-3 mt-badge category-badge ${item ? '' : 'd-none'}`}>
                                          <span className="d-flex justify-content-center align-items-center">
                                            {item.name}{' '}
                                            <KTSVG
                                              path={CloseIcon}
                                              className="cursor-pointer"
                                              svgClassName={`badge-${i % 7} bg-transparent`}
                                              onClick={() => {
                                                changeAppliedItems(item, index);
                                              }}
                                            />
                                          </span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <TagAndCollectionDropdown
                                    className="end-0 mt-15"
                                    type="SubCategories"
                                    allItems={allSubCategories[index]?.subCategories}
                                    appliedItems={categoryAndSubCategory?.subCategory}
                                    changeAppliedItems={(item: any, type: string, event: string) => {
                                      changeAppliedItems(item, index, event);
                                    }}
                                    selected={openSubCategory[index]}
                                    addToSubCategory={(text) => {
                                      setCategoryIndex(index);
                                      const input = text?.trim();
                                      callCreateSubCategory({
                                        variables: {
                                          input: {
                                            name: input,
                                            parentCategoryId: categoryAndSubCategory.category.id
                                          }
                                        }
                                      });
                                      const subCategoryInputArr = subCategoryInput;
                                      subCategoryInputArr[index] = '';
                                      setSubCategoryInput(subCategoryInputArr);
                                    }}
                                    isCreatable
                                    isEditAndDeletable={false}
                                    closeDropdown={() => {
                                      console.log('closeDropdown');
                                    }}
                                  />
                                </OutsideClickHandler>
                              </div>
                              <img
                                src={BinIcon}
                                alt={BinIcon}
                                className={`cursor-pointer ms-5`}
                                onClick={() => {
                                  if (index === 0) {
                                    const arr = categoryInput;
                                    arr[index] = '';
                                    setCategoryInput([...arr]);
                                    const obj = categoryAndSubCategory;
                                    obj.category = {
                                      id: 0,
                                      name: ''
                                    };
                                    addAndRemoveCategories(obj, 'edit', index);
                                  } else {
                                    addAndRemoveCategories(categoryAndSubCategory, 'remove', index);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div
                      className="d-flex align-items-center w-fit-content cursor-pointer"
                      onClick={() => {
                        const arr = categoryInput.concat('');
                        setCategoryInput(arr);
                        addAndRemoveCategories(
                          {
                            category: {
                              id: 0,
                              name: ''
                            },
                            subCategory: [],
                            openCategoryDropdown: false
                          },
                          'add'
                        );
                      }}
                    >
                      <KTSVG path={PlusIcon} />
                      <span className="text-secondary ms-3">{constVariables.addVendor.addCategory}</span>
                    </div>
                  </div>
                </div>
                <div className="p-7 table-top-border">
                  <h5 className="poppins-semibold pb-5 mb-0">{constVariables.addVendor.contactInfo}</h5>
                  <div className="d-flex w-100">
                    <div className={`col-sm d-flex align-items-center mb-5 w-100 ${hideContactInfo ? 'd-none' : ''}`}>
                      {hoverAction === 'clicked' ? (
                        <OutsideClickHandler
                          onOutsideClick={() => {
                            if (hoverAction === 'clicked' && contactTitleInput) {
                              editContactInfo('title', contactTitleInput);
                              setHoverAction('');
                            }
                          }}
                        >
                          <input
                            className={`form-control form-control-solid px-1 me-5 mw-150px bg-transparent border-primary input-text-color`}
                            style={{
                              width: `${
                                contactTitleInput?.length ? `${contactTitleInput?.length}ch` : `${'Contact title'.length}ch`
                              }`
                            }}
                            placeholder="Contact title"
                            autoComplete="nope"
                            value={contactTitleInput}
                            onChange={(e) => {
                              setContactTitleInput(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' && contactInfo.title) {
                                editContactInfo('title', contactTitleInput);
                                setHoverAction('');
                              }
                              if (e.key === 'Escape') {
                                setContactTitleInput(contactInfo.title);
                                setHoverAction('');
                              }
                            }}
                          />
                        </OutsideClickHandler>
                      ) : (
                        <div
                          className={`text-nowrap poppins-semibold me-5 opacity-80 px-1 py-2 h-100 ${
                            hoverAction === 'hover' ? '' : 'border border-transparent'
                          } ${contactTitleInput?.length > 50 ? 'label-text' : ''}`}
                          style={
                            hoverAction === 'hover'
                              ? {
                                  border: '1px solid #00A3FF',
                                  borderRadius: '7px'
                                }
                              : {}
                          }
                          onMouseEnter={() => {
                            if (!hoverAction) setHoverAction('hover');
                          }}
                          onMouseLeave={() => {
                            if (hoverAction === 'hover') {
                              setHoverAction('');
                            }
                          }}
                          onClick={() => {
                            setHoverAction('clicked');
                          }}
                        >
                          {contactInfo.title}
                        </div>
                      )}
                      <input
                        className="form-control form-control-solid w-100 w-100 px-5 me-5 input-text-color poppins-regular"
                        placeholder="Full Name"
                        autoComplete="nope"
                        value={contactInfo.fullName}
                        onChange={(e) => {
                          editContactInfo('fullName', e.target.value);
                        }}
                      />
                      <input
                        className="form-control form-control-solid w-100 w-100 px-5 me-5 input-text-color poppins-regular"
                        placeholder="Email"
                        autoComplete="nope"
                        value={contactInfo.email}
                        onChange={(e) => {
                          editContactInfo('email', e.target.value);
                        }}
                      />
                      <input
                        className="form-control form-control-solid w-100 w-100 px-5 me-5 input-text-color poppins-regular"
                        type="number"
                        autoComplete="nope"
                        maxLength={10}
                        placeholder="Phone number"
                        value={contactInfo.phoneNumber}
                        onChange={(e) => {
                          if (e.target.value.length <= 10) editContactInfo('phoneNumber', e.target.value);
                        }}
                      />
                      <img src={BinIcon} alt={BinIcon} className="cursor-pointer" onClick={toggleContactInfo} />
                    </div>
                  </div>
                  {contacts?.map((contact, index) => {
                    return (
                      <div key={index} className="d-flex w-100">
                        <form className="col-sm d-flex align-items-center mb-5 w-100 bg-white border border-transparent">
                          <FieldTitles
                            obj={contact}
                            changeAction={changeExtraContactFields}
                            index={index}
                            handleEnter={handleEnter}
                            isContactTitle={true}
                          />
                          <input
                            className="form-control form-control-solid w-100 w-100 px-5 mx-5 input-text-color poppins-regular"
                            placeholder="Full Name"
                            autoComplete="nope"
                            value={contact.fullName}
                            onChange={(e) => {
                              changeExtraContactFields(e.target.value, index, 'fullName');
                            }}
                            onKeyDown={handleEnter}
                          />
                          <input
                            className="form-control form-control-solid w-100 w-100 px-5 me-5 input-text-color poppins-regular"
                            name="email"
                            autoComplete="nope"
                            value={contact.email}
                            onChange={(e) => {
                              changeExtraContactFields(e.target.value, index, 'email');
                            }}
                            placeholder="Email"
                            onKeyDown={handleEnter}
                          />
                          <input
                            className="form-control form-control-solid w-100 w-100 px-5 me-5 input-text-color poppins-regular"
                            placeholder="Phone number"
                            autoComplete="nope"
                            type="number"
                            maxLength={10}
                            value={contact.phoneNumber}
                            onChange={(e) => {
                              if (e.target.value.length <= 10) changeExtraContactFields(e.target.value, index, 'phoneNumber');
                            }}
                          />
                          <img src={BinIcon} alt={BinIcon} className="cursor-pointer" onClick={() => deleteContact(index)} />
                        </form>
                      </div>
                    );
                  })}
                  <div
                    className="d-flex align-items-center w-fit-content cursor-pointer"
                    onClick={() => {
                      const arr = contacts.concat({
                        title: '',
                        fullName: '',
                        email: '',
                        phoneNumber: '',
                        mouseAction: 'clicked'
                      });
                      addContact(arr);
                    }}
                  >
                    <KTSVG path={PlusIcon} />
                    <span className="text-secondary ms-3">{constVariables.addVendor.addContact}</span>
                  </div>
                </div>
                <div className="p-7 table-top-border">
                  <h5 className="poppins-semibold pb-5 mb-0">{constVariables.addVendor.saleInfo}</h5>
                  <div className="d-flex align-items-center mb-5 w-100">
                    <div className="text-nowrap me-2 poppins-semibold">{constVariables.addVendor.shippingCosts}</div>
                    <input
                      type="radio"
                      id="onTheVendor"
                      name="shipping_cost"
                      value="onTheVendor"
                      className="mt-0 ms-3"
                      checked={salesInformation.shippingCosts === constVariables.addVendor.onTheVendor}
                      onChange={(e) => {
                        if (e.target.checked) {
                          editSalesInformation('shippingCosts', constVariables.addVendor.onTheVendor);
                        }
                      }}
                    />
                    <label htmlFor="onTheVendor" className="mb-0 mx-2 opacity-80 fw-normal">
                      {constVariables.addVendor.onTheVendor}
                    </label>
                    <input
                      type="radio"
                      id="onTheBoutique"
                      name="shipping_cost"
                      value="onTheBoutique"
                      className="mt-0 ms-3"
                      checked={salesInformation.shippingCosts === constVariables.addVendor.onTheBoutique}
                      onChange={(e) => {
                        if (e.target.checked) {
                          editSalesInformation('shippingCosts', constVariables.addVendor.onTheBoutique);
                        }
                      }}
                    />
                    <label htmlFor="onTheBoutique" className="mb-0 mx-2 opacity-80 fw-normal">
                      {constVariables.addVendor.onTheBoutique}
                    </label>
                  </div>
                  {salesInfos?.map((salesInfo, index) => {
                    return (
                      <div key={index} className="d-flex w-100">
                        <form className="col-sm d-flex align-items-center mb-5 w-100 bg-white border border-transparent">
                          <FieldTitles
                            obj={salesInfo}
                            changeAction={changeExtraSalesInfoFields}
                            index={index}
                            handleEnter={handleEnter}
                          />
                          <input
                            className="form-control form-control-solid w-100 w-100 px-5 mx-5 input-text-color poppins-regular"
                            autoComplete="nope"
                            placeholder="Value"
                            value={salesInfo.value}
                            onChange={(e) => {
                              changeExtraSalesInfoFields(e.target.value, index, 'value');
                            }}
                          />
                          <img
                            src={BinIcon}
                            alt={BinIcon}
                            className="cursor-pointer"
                            onClick={() => {
                              deleteSaleInfo(index);
                            }}
                          />
                        </form>
                      </div>
                    );
                  })}
                  <div
                    className="d-flex align-items-center w-fit-content cursor-pointer"
                    onClick={() => {
                      const arr = salesInfos.concat({
                        title: '',
                        value: '',
                        mouseAction: 'clicked'
                      });
                      addSalesInfo(arr);
                    }}
                  >
                    <KTSVG path={PlusIcon} />
                    <span className="text-secondary ms-3">{constVariables.addVendor.addAdditionalInfo}</span>
                  </div>
                </div>
                <div className="p-7 table-top-border">
                  <h5 className="poppins-semibold pb-5 mb-0">{constVariables.addVendor.location}</h5>
                  <div className="d-flex w-100">
                    <div className="col-sm d-flex align-items-center mb-5 w-100">
                      <div className="text-nowrap poppins-semibold me-5 opacity-80">{constVariables.addVendor.location}</div>
                      <input
                        className="form-control form-control-solid w-100 w-100 px-5 me-5 input-text-color poppins-regular"
                        autoComplete="nope"
                        placeholder="Address"
                        value={locationInfo.address}
                        onChange={(e) => {
                          editLocationInfo('address', e.target.value);
                        }}
                      />
                      <input
                        className="form-control form-control-solid w-100 w-100 px-5 me-5 input-text-color poppins-regular"
                        autoComplete="nope"
                        placeholder="State"
                        value={locationInfo.state}
                        onChange={(e) => {
                          editLocationInfo('state', e.target.value);
                        }}
                      />
                      <input
                        className="form-control form-control-solid w-100 w-100 px-5 input-text-color poppins-regular"
                        autoComplete="nope"
                        placeholder="Country"
                        value={locationInfo.country}
                        onChange={(e) => {
                          editLocationInfo('country', e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  {locations?.map((location, index) => {
                    return (
                      <div key={index} className="d-flex w-100">
                        <form className="col-sm opacity-80 d-flex align-items-center mb-5 w-100 bg-white border border-transparent">
                          <FieldTitles
                            obj={location}
                            changeAction={changeExtraLocationsFields}
                            index={index}
                            handleEnter={handleEnter}
                          />
                          <input
                            className="form-control form-control-solid w-100 w-100 px-5 mx-5 input-text-color poppins-regular"
                            autoComplete="nope"
                            placeholder="Text"
                            value={location.value}
                            onChange={(e) => {
                              changeExtraLocationsFields(e.target.value, index, 'value');
                            }}
                          />
                          <img
                            src={BinIcon}
                            alt={BinIcon}
                            className="cursor-pointer"
                            onClick={() => {
                              deleteLocation(index);
                            }}
                          />
                        </form>
                      </div>
                    );
                  })}
                  <div
                    className="d-flex align-items-center w-fit-content cursor-pointer"
                    onClick={() => {
                      const arr = locations.concat({
                        title: '',
                        value: '',
                        mouseAction: 'clicked'
                      });
                      addLocation(arr);
                    }}
                  >
                    <KTSVG path={PlusIcon} />
                    <span className="text-secondary ms-3">{constVariables.addVendor.addAdditionalInfo}</span>
                  </div>
                </div>
                <div className="p-7 table-top-border">
                  <h5 className="poppins-semibold pb-5 mb-0">{constVariables.addVendor.others}</h5>
                  {others?.map((other, index) => {
                    return (
                      <div key={index} className="d-flex w-100">
                        <form className="col-sm opacity-80 d-flex align-items-center mb-5 w-100 bg-white border border-transparent">
                          <FieldTitles obj={other} changeAction={changeExtraOthersFields} index={index} handleEnter={handleEnter} />
                          <input
                            className="form-control form-control-solid w-100 px-5 mx-5 input-text-color poppins-regular"
                            autoComplete="nope"
                            placeholder="https://"
                            value={other.website}
                            onChange={(e) => {
                              changeExtraOthersFields(e.target.value, index, 'website');
                            }}
                          />
                          <img
                            src={BinIcon}
                            alt={BinIcon}
                            className="cursor-pointer"
                            onClick={() => {
                              deleteOther(index);
                            }}
                          />
                        </form>
                      </div>
                    );
                  })}
                  <div
                    className="d-flex align-items-center w-fit-content cursor-pointer"
                    onClick={() => {
                      const arr = others.concat({
                        title: '',
                        website: '',
                        mouseAction: 'clicked'
                      });
                      addOther(arr);
                    }}
                  >
                    <KTSVG path={PlusIcon} />
                    <span className="text-secondary ms-3">{constVariables.addVendor.addAdditionalInfo}</span>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outlined-secondary btn-md"
                  onClick={() => {
                    resetState();
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-md"
                  onClick={() => {
                    setShowValidation(true);
                    if (
                      generalInfo.vendorName &&
                      categoriesAndSubCategories[0]?.category?.name &&
                      ((generalInfo.website && isValidWebsite) || !generalInfo.website) &&
                      !isLoading
                    )
                      onSaveVendor();
                  }}
                >
                  Save Vendor
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddVendorPresentational;
