import { FC, useState, useEffect } from 'react';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import { FixedHeaderTable, Tooltip } from 'src/components/molecules';

// Hooks && Utils && Helpers
import toTree from 'src/utils/makeTreeData';
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import { expandRight, DefaultImage } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { TablesProps } from 'src/components/molecules/Table/Table.types';
import { CollectionDetail, RequiredTreeData } from './BulkEditorModal.types';

interface CollectionTreeViewProps {
  data: CollectionDetail[] | any;
  selectedCollections: number[];
  setSelectedCollections: React.Dispatch<React.SetStateAction<number[]>>;
  isLoading?: boolean;
  searchText?: string;
}

const CollectionTreeView: FC<CollectionTreeViewProps> = ({
  data,
  selectedCollections,
  setSelectedCollections,
  isLoading,
  searchText
}) => {
  const [showAllDetails, setShowAllDetails] = useState(true);
  const [treeData, setTreeData] = useState<RequiredTreeData[]>([]);
  const [showCollectionDetailsState, setShowCollectionDetailsState] = useState<number[]>([]);

  useEffect(() => {
    if (data) {
      const treeCollections = toTree(
        data.map((collection) => {
          return {
            id: collection.id,
            parent: collection.parent,
            droppable: collection.droppable,
            text: collection.name,
            thumbnailUrl: collection.thumbnailUrl ? collection.thumbnailUrl : '',
            status: collection.status ? collection.status : '',
            type: collection.type,
            productCount: collection.productCount ? collection.productCount : 0
          };
        })
      );

      setTreeData(treeCollections);
    }
  }, [data]);

  const handleChangeCollection = (id) => {
    if (selectedCollections.includes(id)) {
      setSelectedCollections(selectedCollections.filter((collectionId) => collectionId !== id));
    } else {
      setSelectedCollections([...selectedCollections, id]);
    }
  };

  const statusText = (title: string) => {
    if (title === 'ACTIVE') {
      return 'Active';
    }
    if (title === 'SCHEDULED') {
      return 'Scheduled';
    }
    return 'Inactive';
  };

  const selectCollectionStatusClass = (collectionStatus: string | undefined) => {
    switch (collectionStatus) {
      case 'active':
        return 'active';
      case 'inactive':
        return 'inactive';
      default:
        return 'scheduled';
    }
  };

  const expandCollection = (collectionId) => {
    const tempArr = [...showCollectionDetailsState];
    if (showCollectionDetailsState.findIndex((data) => data === collectionId) > -1) {
      const index = tempArr.indexOf(collectionId);

      if (index > -1) {
        tempArr.splice(index, 1);
      }

      setShowCollectionDetailsState(tempArr);
      setShowAllDetails(false);
    } else {
      setShowCollectionDetailsState([...showCollectionDetailsState, collectionId]);
      setShowAllDetails(false);
    }
  };

  const tableData: TablesProps = {
    headerClassName: 'bg-light p-4',
    isDragDisabled: true,
    headers: [
      {
        id: 1,
        className: 'align-middle border-bottom-0 bg-light w-50px',
        colName: (
          <div className="ps-5 pe-2 form-check form-check-custom form-check-sm">
            <input
              className="form-check-input widget-9-check parent-checkbox"
              type="checkbox"
              checked={selectedCollections.length > 0 && selectedCollections.length === data.length}
              onChange={() => {
                if (selectedCollections.length === data.length) {
                  setSelectedCollections([]);
                } else {
                  setSelectedCollections(data?.map((item) => item.id));
                }
              }}
            />
          </div>
        )
      },
      {
        id: 2,
        className: 'align-middle p-0 border-bottom-0 bg-light poppins-semibold w-50px',
        colName: (
          <button
            className="h-20x w-20x p-2 d-flex align-items-center justify-content-center me-4 bg-transparent border border-transparent cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowAllDetails(!showAllDetails);
            }}
          >
            <img src={expandRight} className={`${showAllDetails ? 'rotatedArrowIcon' : ''}`} />
          </button>
        )
      },
      {
        id: 3,
        className: 'align-middle border-bottom-0 bg-light w-300px',
        colName: (
          <div className="d-flex align-items-center">
            <div className="align-middle p-0 border-bottom-0">{constVariables.ProductCategoryMenu.collections.collection}</div>
          </div>
        )
      },
      {
        id: 4,
        className: 'align-middle border-bottom-0 bg-light w-150px',
        colName: constVariables.ProductCategoryMenu.collections.products
      },
      {
        id: 5,
        className: 'align-middle border-bottom-0 bg-light w-175px',
        colName: constVariables.ProductCategoryMenu.collections.collectionType
      },
      {
        id: 6,
        className: 'align-middle border-bottom-0 bg-light ps-0',
        colName: constVariables.ProductCategoryMenu.collections.status
      }
    ],
    rows: treeData?.map((collection, index) => ({
      id: collection.id,
      isDragDisabled: true,
      className: `${collection?.type === 'AUTOMATIC' ? 'opacity-half' : ''}`,
      cells: [
        {
          className: `align-middle w-50px ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: (
            <div className="ps-5 pe-2 form-check form-check-custom form-check-sm">
              {collection?.type === 'AUTOMATIC' ? (
                <>
                  <div data-tip data-for="automaticCollection">
                    <input className="form-check-input widget-9-check parent-checkbox" type="checkbox" disabled />
                  </div>
                  <Tooltip
                    tooltipId="automaticCollection"
                    place="top"
                    content={() => {
                      return <>Automatic collection type</>;
                    }}
                  />
                </>
              ) : (
                <input
                  className="form-check-input widget-9-check parent-checkbox"
                  type="checkbox"
                  checked={selectedCollections.includes(collection?.id)}
                  onChange={() => handleChangeCollection(collection?.id)}
                />
              )}
            </div>
          )
        },
        {
          className: `align-middle p-0 poppins-semibold w-50px ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: (
            <>
              {collection?.children && collection?.children?.length > 0 ? (
                <div
                  onClick={() => {
                    if (collection?.type === 'MANUAL') expandCollection(collection.id);
                  }}
                  className={`h-20x w-20x p-2 d-flex align-items-center justify-content-center me-4 ${
                    collection?.type === 'MANUAL' ? 'cursor-pointer' : ''
                  }`}
                >
                  {showCollectionDetailsState.findIndex((data) => data === collection.id) > -1 || showAllDetails ? (
                    <img src={expandRight} className="rotatedArrowIcon" />
                  ) : (
                    <img src={expandRight} />
                  )}
                </div>
              ) : (
                <div className="opacity-0 h-20x w-20x p-2 d-flex align-items-center justify-content-center me-4">
                  <img src={expandRight} />
                </div>
              )}
            </>
          )
        },
        {
          className: `align-middle w-300px ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: (
            <FlexBox className="align-items-center d-inline-block name-cell" style={{ marginLeft: `${collection?.level * 32}px` }}>
              <div className="symbol me-5">
                <Img
                  src={setImageSrc(
                    collection?.thumbnailUrl ? generateCompressedImageURL(collection?.thumbnailUrl, '260') : DefaultImage
                  )}
                  placeholderImg={DefaultImage}
                  errorImg={DefaultImage}
                  className="object-fit-scale-down border border-light border-2"
                />
              </div>
              <FlexBox className="justify-content-start flex-column d-inline-block collectionName">
                <span className="poppins-regular d-block main-cell no-decoration">{collection?.text}</span>
              </FlexBox>
            </FlexBox>
          )
        },
        {
          className: `align-middle w-150px ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: collection?.productCount ? collection?.productCount : 0
        },
        {
          className: `align-middle text-capitalize w-175px ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: collection?.type?.toLowerCase()
        },
        {
          className: `align-middle ps-0 ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: (
            <FlexBox
              className={`p-2 poppins-medium justify-content-center ${selectCollectionStatusClass(
                collection?.status?.toLowerCase()
              )}-status-box`}
            >
              <span>{statusText(collection?.status ? collection?.status : '')}</span>
            </FlexBox>
          )
        }
      ],
      showCollectionDetails: showCollectionDetailsState,
      showAllDetails,
      collectionDetail: {
        collection,
        selectedCollections,
        setSelectedCollections
      }
    }))
  };

  return (
    <FixedHeaderTable
      headers={tableData.headers}
      rows={tableData.rows}
      loadingPosition="center"
      searchText={searchText}
      isLoading={isLoading}
      containerHeight={300}
    />
  );
};

export default CollectionTreeView;
