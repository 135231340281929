import { gql } from '@apollo/client';

export const TOGGLE_COLLECTION_OR_CATALOG = gql`
  mutation ToggleCollectionOrCatalog($input: ToggleCollectionOrCatalogInput!) @api(name: "authAPI") {
    toggleCollectionOrCatalog(input: $input) {
      success
      message
    }
  }
`;
