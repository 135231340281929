import { FC, useState, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { FlexBox } from 'src/components/atoms';
import { InputBox, Dropdown } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// ConstVariables
import { constVariables } from 'src/constants';
import { errorTextGroups } from '../ShopDetails';

// Icons
import { DownArrowIcon } from 'src/assets/icons';

// Types
import { GeneralDetailsProps, PhoneItem } from '../ShopDetails.types';

const GeneralDetails: FC<GeneralDetailsProps> = ({
  storeDetailsData,
  errorMessages,
  handleUpdateStoreDetails,
  phoneList,
  validateInputValues
}) => {
  const [showPhoneDropdown, setShowPhoneDropdown] = useState(false);
  const [showPhoneCodeSearchInput, setShowPhoneCodeSearchInput] = useState(false);
  const [searchPhonecodeText, setSearchPhonecodeText] = useState('');
  const [filteredPhonelist, setFilteredPhonelist] = useState<PhoneItem[]>([]);

  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  const handleShowPhonecodeSearchDropdown = () => {
    setShowPhoneDropdown(true);
    setShowPhoneCodeSearchInput(true);
  };

  useEffect(() => {
    if (searchPhonecodeText === '') {
      setFilteredPhonelist(phoneList);
    } else {
      const filteredPhoneCodes = phoneList.filter((phone) => phone.name.toLowerCase().includes(searchPhonecodeText.toLowerCase()));
      setFilteredPhonelist(filteredPhoneCodes);
    }
  }, [searchPhonecodeText, phoneList]);

  const handlePhoneCodeUpdate = (value) => {
    handleUpdateStoreDetails('customerSupportPhoneCountryCode', value.extraData, errorTextGroups.customerSupportPhoneCountryCode);
    setShowPhoneDropdown(false);
    setShowPhoneCodeSearchInput(false);
    setSearchPhonecodeText('');
  };

  return (
    <div className="card">
      <h4 className="section-title m-b-32">{constVariables.shopDetails.general.key}</h4>
      <div className="row gx-3 mb-6">
        <div className="col-md-6">
          <InputBox
            title={constVariables.shopDetails.general.storeName}
            value={storeDetailsData.name}
            onChangeText={(value) => handleUpdateStoreDetails('name', value, errorTextGroups.name)}
            name="name"
            charLimit={50}
            inputClass="form-control-lg"
            warningText={errorMessages?.name}
            onBlur={(event) => validateInputValues('name', event.target.value, errorTextGroups.name)}
            placeholder="Store name"
            disable={role !== 'ROLE_OWNER' ? true : false}
          />
        </div>
      </div>
      <div className="row gx-3">
        <div className="col-md-6">
          <div className="w-100 position-relative input-title-section">
            <div className="input-title">{constVariables.shopDetails.general.supportPhone}</div>
            <OutsideClickHandler
              onOutsideClick={() => {
                setShowPhoneDropdown(false);
                setShowPhoneCodeSearchInput(false);
              }}
            >
              <div className="d-flex">
                <div>
                  {!showPhoneDropdown && !showPhoneCodeSearchInput && (
                    <FlexBox
                      className={`right-border-flat form-control form-control-lg  justify-content-between align-items-center cursor-pointer dropdown-box phone-code-input`}
                      onClick={handleShowPhonecodeSearchDropdown}
                    >
                      <div className="helper-text-one">
                        {storeDetailsData?.customerSupportPhoneCountryCode ? storeDetailsData?.customerSupportPhoneCountryCode : ''}
                      </div>
                      <div>
                        <KTSVG path={DownArrowIcon} />
                      </div>
                    </FlexBox>
                  )}
                  {showPhoneCodeSearchInput && (
                    <div className="right-border-flat form-control form-control-lg p-0 justify-content-between align-items-center cursor-pointer dropdown-box phone-code-input active">
                      <input
                        autoComplete="off"
                        autoFocus
                        className="border-0 w-100 h-100 px-5"
                        type="text"
                        pattern="[0-9]+"
                        name="phonecode"
                        value={searchPhonecodeText}
                        onChange={(e) => {
                          e.target.validity.valid && setSearchPhonecodeText(e.target.value);
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="form-control form-control-lg p-0 left-border-flat">
                  <input
                    autoComplete="off"
                    className="border-0 w-100 h-100 px-5"
                    type="text"
                    pattern="[0-9]+"
                    name="customerSupportPhone"
                    value={storeDetailsData.customerSupportPhone}
                    onChange={(e) => {
                      e.target.validity.valid &&
                        handleUpdateStoreDetails('customerSupportPhone', e.target.value, errorTextGroups.customerSupportPhone);
                    }}
                    onBlur={(event) =>
                      validateInputValues('customerSupportPhone', event.target.value, errorTextGroups.customerSupportPhone)
                    }
                    maxLength={15}
                  />
                </div>
              </div>
              {phoneList && phoneList.length > 0 && (
                <Dropdown
                  className="dropdown-custom-width"
                  data={filteredPhonelist}
                  selected={showPhoneDropdown}
                  value={storeDetailsData?.customerSupportPhoneCountryCode}
                  onSelect={(value) => handlePhoneCodeUpdate(value)}
                  closeDropdown={() => setShowPhoneDropdown(false)}
                />
              )}
            </OutsideClickHandler>
            {/* {(errorMessages.customerSupportPhoneCountryCode || errorMessages.customerSupportPhone) && (
              <div className="warning-text p-0 fs-7">{errorMessages.customerSupportPhone}</div>
            )} */}
            {errorMessages.customerSupportPhone && (
              <div className="warning-text p-0 fs-7">{errorMessages.customerSupportPhone}</div>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <InputBox
            title={constVariables.shopDetails.general.supportEmail}
            value={storeDetailsData.customerSupportEmail}
            onChangeText={(value) => handleUpdateStoreDetails('customerSupportEmail', value, errorTextGroups.customerSupportEmail)}
            name="customerSupportEmail"
            inputClass="form-control-lg"
            warningText={errorMessages?.customerSupportEmail}
            type="email"
            onBlur={(event) =>
              validateInputValues('customerSupportEmail', event.target.value, errorTextGroups.customerSupportEmail)
            }
            placeholder="Email address"
          />
        </div>
      </div>
    </div>
  );
};

export default GeneralDetails;
