/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';

// Apollo
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { GET_LIVE_STATS, GET_WS_TOKEN, GET_LIVE_STREAM_DETAILS, GET_PRODUCTS_V3 } from 'src/apollo/queries';
import { ADD_PRODUCT_FOR_OVERLAY } from 'src/apollo/mutations';

// Components
import { OrderMetric, LiveViewers } from 'src/components/molecules';
import { LiveshowProductList, CurrentProductDetail } from 'src/components/oraganisms';
import LiveshowFeedDesktop from './LiveshowFeedDesktop';
import LiveShowCommentsList from './LiveShowCommentsList';
import LivsDashboardCustomModals, { LivsDashboardCustomModalsRefMethods } from './LivsDashboardCustomModals';

// Hooks && Utils && Helpers
import { useToast } from 'src/utils/hooks/useToast';

// ConstVariables
import { envUrls } from 'src/constants';

// Types
import { ILiveProduct, StoreStreamConfiguratorType } from './LiveshowManager.types';
import { OrderMetricDataType } from 'src/components/molecules/OrderMetric/OrderMetric';
import { LiveViewersRefMethods } from 'src/components/molecules/LiveViewers/LiveViewers';
import { LiveShowCommentsListRefMethods } from './LiveShowCommentsList';

// Styles
import './_liveshowFeed.scss';

interface LiveshowFeedProps {
  liveVideo?: any;
  liveshowRef: any;
  handleCameraMute: () => void;
  handleMicMute: () => void;
  micMuted: boolean;
  camMuted: boolean;
  handleStopStream: () => void;
  storeStreamConfigurator: StoreStreamConfiguratorType;
  endLoading: boolean;
  isStreamingFromCurrentDevice: boolean;
  setIsStaredFromCurrentDevice: React.Dispatch<React.SetStateAction<boolean>>;
}

export type wsDataType = {
  data: any;
  type: WS_EVENT_TYPE;
  sentAt: string;
};

enum WS_EVENT_TYPE {
  'USER_JOINED' = 'USER_JOINED',
  'USER_EXCITED' = 'USER_EXCITED',
  'USER_UPDATE' = 'USER_UPDATE',
  'PRODUCT_STATS_UPDATED' = 'PRODUCT_STATS_UPDATED',
  'PRODUCT_REMOVED_FROM_LIVE' = 'PRODUCT_REMOVED_FROM_LIVE',
  'LIVE_METRICS_UPDATED' = 'LIVE_METRICS_UPDATED',
  'LIVE_SHOW_LIKED' = 'LIVE_SHOW_LIKED',
  'DISCONNECT_USER' = 'DISCONNECT_USER'
}

const LiveshowFeed: React.FC<LiveshowFeedProps> = ({
  liveshowRef,
  handleCameraMute,
  handleMicMute,
  micMuted,
  camMuted,
  liveVideo,
  handleStopStream,
  storeStreamConfigurator,
  endLoading,
  isStreamingFromCurrentDevice,
  setIsStaredFromCurrentDevice
}) => {
  const { showToast } = useToast();
  const [selectedProduct, setSelectedProduct] = useState<ILiveProduct>();
  const [preSelectedProduct, setPreSelectedProduct] = useState<ILiveProduct>();
  const [nextSelectedProduct, setNextSelectedProduct] = useState<ILiveProduct>();

  const [productsList, setProductsList] = useState<ILiveProduct[]>([]);
  const [visitedProductIds, setVisitedProductIds] = useState<ILiveProduct['productId'][]>([]);
  const [lowStockProductsList, setLowStockProductsList] = useState<ILiveProduct[]>([]);

  const [disabledIds, setDisabledIds] = useState<ILiveProduct['productId'][]>([]);
  const [orderMetricData, setOrderMetricData] = useState<OrderMetricDataType>({
    likes: 0,
    paidOrders: 0,
    pendingRevenue: 0,
    profit: 0,
    targetGoal: 0,
    totalSales: 0,
    unpaidOrders: 0
  });

  const [wsRetryCount, setWsRetryCount] = useState(0);

  const LiveViewersRef = useRef<LiveViewersRefMethods>(null);
  const LiveShowCommentsListRef = useRef<LiveShowCommentsListRefMethods>(null);
  const liveShowModalsRef = useRef<LivsDashboardCustomModalsRefMethods>(null);

  const ws = useRef<WebSocket>();

  useEffect(() => {
    setIsStaredFromCurrentDevice(false);
  }, [storeStreamConfigurator.liveStartedAt]);

  useQuery(GET_LIVE_STATS, {
    variables: { id: storeStreamConfigurator?.runningLiveId },
    skip: !storeStreamConfigurator?.runningLiveId,
    onCompleted: (d) => {
      console.log({ d });
      onLiveStatData(d?.getLiveShowStats);
    }
  });

  useQuery(GET_LIVE_STREAM_DETAILS, {
    variables: { id: storeStreamConfigurator?.runningLiveId },
    skip: !storeStreamConfigurator?.runningLiveId,
    onCompleted: (response) => {
      if (response?.getLiveStreamDetails?.title) {
        document.title = `Liveshow dashboard - ${response?.getLiveStreamDetails?.title}`;
      }
    }
  });

  const [getProductByv3] = useLazyQuery(GET_PRODUCTS_V3);

  const { refetch } = useQuery(GET_WS_TOKEN, {
    onCompleted: (data) => {
      if (data?.getWsToken) {
        ws.current = new WebSocket(`${envUrls.liveWebSocketUrl}?authorization=${data?.getWsToken}`, []);
        ws.current.onopen = () => console.log('ws opened');
        ws.current.onerror = (e) => {
          console.log('ws error', wsRetryCount, { e });
          if (wsRetryCount < 20) {
            setWsRetryCount((c) => c + 1);
            setTimeout(refetch, 2000);
          } else {
            showToast({
              errorText: 'Realtime updates Unavailable',
              message: `Error in connection`
            });
          }
        };
        ws.current.onclose = (e) => console.log('ws closed', { e });
      }
    },
    onError: (er) => {
      console.error(er);
    },
    skip: !storeStreamConfigurator?.runningLiveId
  });

  useEffect(() => {
    return () => {
      ws?.current?.close();
    };
  }, []);

  const onLiveStatData = (d) => {
    LiveViewersRef?.current?.setLiveViwersCount(d.views);
    const metricData = Object.assign({}, d);
    delete metricData.views;
    setOrderMetricData(metricData);
  };

  useEffect(() => {
    if (!ws?.current) return;

    ws.current.onmessage = (e) => {
      const wsData: wsDataType[] = JSON.parse(e.data);

      if (wsData && wsData?.length) {
        wsData.forEach((el) => {
          switch (el.type) {
            case WS_EVENT_TYPE.USER_JOINED:
              LiveViewersRef?.current?.onUserJoined(el.data[0]);
              break;
            case WS_EVENT_TYPE.USER_UPDATE:
              LiveViewersRef?.current?.onUserUpdated(el.data[0]);
              LiveShowCommentsListRef?.current?.onUserDetailChange(el.data[0]);
              liveShowModalsRef?.current?.onUserUpdated(el.data[0]);
              break;
            case WS_EVENT_TYPE.USER_EXCITED:
              LiveViewersRef?.current?.onUserExited(el?.data?.userId);
              break;
            case WS_EVENT_TYPE.PRODUCT_REMOVED_FROM_LIVE:
              setDisabledIds(disabledIds?.filter((id) => id !== el.data.productId));
              setProductsList(productsList.filter((product) => product?.id !== el.data.id));
              break;
            case WS_EVENT_TYPE.PRODUCT_STATS_UPDATED:
              onIncommingProductData(el.data);
              break;
            case WS_EVENT_TYPE.LIVE_METRICS_UPDATED:
              onLiveStatData(el.data);
              break;
            case WS_EVENT_TYPE.LIVE_SHOW_LIKED:
              setOrderMetricData((d) => {
                return { ...d, likes: el.data?.likes };
              });
          }
        });
      }
    };
  }, [ws.current, productsList, disabledIds]);

  const onIncommingProductData = (products: ILiveProduct[]) => {
    products.forEach((product: ILiveProduct) => {
      const currentIndex = productsList?.findIndex((item) => {
        return `${item?.id}` === `${product.id}`;
      });
      if (currentIndex === -1) {
        setProductsList((productsList) => {
          return [...productsList, product];
        });
      } else {
        setProductsList([...productsList.slice(0, currentIndex), product, ...productsList.slice(currentIndex + 1)]);
      }

      if (!disabledIds.includes(product?.productId)) {
        setDisabledIds((ids) => [...ids, product?.productId]);
      }

      if (product?.isLive) {
        setSelectedProduct(product);
      }

      if ((product?.isComplete || product?.isLive) && !visitedProductIds.includes(product?.productId)) {
        setVisitedProductIds((ids) => [...ids, product?.productId]);
      }

      const lowstockIndex = lowStockProductsList?.findIndex((item) => {
        return `${item?.id}` === `${product.id}`;
      });
      if (product?.isLowStockTriggred) {
        if (lowstockIndex === -1) {
          setLowStockProductsList((prds) => [...prds, product]);
        }
      } else {
        setLowStockProductsList((p) => [...p.slice(0, lowstockIndex), ...p.slice(lowstockIndex + 1)]);
      }

      getProductByv3({
        variables: {
          input: {
            productIds: [product?.productId],
            detailed: true,
            filters: {
              locationIds: []
            }
          }
        }
      });
    });
  };

  useEffect(() => {
    document.body.setAttribute('data-kt-aside-minimize', 'on');

    return () => {
      document.body.removeAttribute('data-kt-aside-minimize');
    };
  }, []);

  const [addProductForOverlay] = useMutation(ADD_PRODUCT_FOR_OVERLAY, {
    onCompleted: (response) => {
      if (response) {
        showToast({ successText: 'Product added For Overlay', message: `The Product has been added sucessfully` });
      }
    },
    onError: (error) => {
      showToast({
        errorText: error.message,
        message: `Error occured while adding Product For Overlay: ${error.message}`
      });
    }
  });

  const selectAproductForLiveShow = (prd) => {
    const currentIndex = productsList.findIndex((product) => product.productId === prd.productId);
    const prev = productsList[currentIndex - 1];
    const next = productsList[currentIndex + 1];
    setPreSelectedProduct(prev);
    setNextSelectedProduct(next);
    addProductForOverlay({
      variables: {
        input: {
          liveShowId: storeStreamConfigurator?.runningLiveId,
          productId: prd?.productId
        }
      }
    });
    setSelectedProduct(prd);
  };

  return (
    <>
      <div className="row m-0 liveshow-dashboard">
        <div className="col-lg-12 col-xl-7">
          <div className="row">
            <div className="col-lg-6 col-xxl-7 ps-0 liveshow-products-section">
              <div className="card p-24 m-0">
                <CurrentProductDetail
                  productDetails={selectedProduct}
                  setSelectedProduct={selectAproductForLiveShow}
                  preSelectedProduct={preSelectedProduct}
                  nextSelectedProduct={nextSelectedProduct}
                />
                <LiveshowProductList
                  selectedProduct={selectedProduct}
                  liveshowId={storeStreamConfigurator?.runningLiveId}
                  setSelectedProduct={selectAproductForLiveShow}
                  onIncommingProductData={onIncommingProductData}
                  productsList={productsList}
                  visitedProductIds={visitedProductIds}
                  disabledIds={disabledIds}
                  lowStockProductsList={lowStockProductsList}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xxl-5 live-show-comments">
              <LiveShowCommentsList
                liveShowId={storeStreamConfigurator?.runningLiveId}
                ref={LiveShowCommentsListRef}
                onAllUserCartDumped={LiveViewersRef?.current?.dumpAllUserCarts}
                onBlockUser={liveShowModalsRef?.current?.onBlockUser!}
                onDeleteComment={liveShowModalsRef?.current?.onDeleteComment!}
                onAddStoreCredit={liveShowModalsRef?.current?.onAddStoreCredit!}
                onShowCart={liveShowModalsRef?.current?.onShowCart!}
                onDumpCart={liveShowModalsRef?.current?.onDumpCart!}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-xl-5 liveshow-metric-sections h-100">
          <div className="row">
            <div className="col-lg-6 col-xl-12 m-b-24">
              <div className="row upper-container">
                <div className="col-lg-6 h-100">
                  <LiveshowFeedDesktop
                    liveshowRef={liveshowRef}
                    micMuted={micMuted}
                    camMuted={camMuted}
                    isStreamingFromCurrentDevice={isStreamingFromCurrentDevice}
                    handleCameraMute={handleCameraMute}
                    handleMicMute={handleMicMute}
                    showConfirmationModal={liveShowModalsRef?.current?.onEndShow!}
                    liveVideo={storeStreamConfigurator?.liveVideo}
                    storeStreamConfigurator={storeStreamConfigurator}
                    liveStartedAt={storeStreamConfigurator.liveStartedAt}
                  />
                </div>
                <div className="col-lg-6 live-viewers-highlights">
                  <LiveViewers
                    ref={LiveViewersRef}
                    liveShowId={storeStreamConfigurator?.runningLiveId}
                    onBlockUser={liveShowModalsRef?.current?.onBlockUser!}
                    onAddStoreCredit={liveShowModalsRef?.current?.onAddStoreCredit!}
                    onShowCart={liveShowModalsRef?.current?.onShowCart!}
                    onDumpCart={liveShowModalsRef?.current?.onDumpCart!}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-12">
              <div className="lower-container">
                <OrderMetric
                  likes={orderMetricData.likes}
                  paidOrders={orderMetricData.paidOrders}
                  pendingRevenue={orderMetricData.pendingRevenue}
                  profit={orderMetricData.profit}
                  targetGoal={orderMetricData.targetGoal}
                  totalSales={orderMetricData.totalSales}
                  unpaidOrders={orderMetricData.unpaidOrders}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <LivsDashboardCustomModals
        ref={liveShowModalsRef}
        liveShowId={storeStreamConfigurator?.runningLiveId}
        endLoading={endLoading}
        handleStopStream={handleStopStream}
        onUserDetailsUpdated={LiveShowCommentsListRef?.current?.onUserDetailChange!}
        handleDeleteComment={LiveShowCommentsListRef?.current?.handleDeleteComment!}
      />
    </>
  );
};

export default LiveshowFeed;
