import { FC, useState, useEffect } from 'react';

// Apollo
import { useQuery, useMutation } from '@apollo/client';
import { GET_PRODUCT_TYPES } from 'src/apollo/queries';
import { ADD_PRODUCT_TYPE, DELETE_PRODUCT_TYPE, UPDATE_PRODUCT_TYPE } from 'src/apollo/mutations';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { SearchSelectDropdown } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { useToast } from 'src/utils/hooks/useToast';

// Types
import { ProductTypesProps } from './OrganizationSection.types';
import { UserRoles } from 'src/components/pages/settings/UserPermissions/UserPermissions.type';

const ProductTypes: FC<ProductTypesProps> = ({ productTypeId, setProductTypeId }) => {
  const { showToast } = useToast();
  const [allProductTypes, setAllProductTypes] = useState<{ id: number; name: string; relatedCount?: number }[]>([]);

  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  const { data, refetch: refetchProductTypes } = useQuery(GET_PRODUCT_TYPES, {
    fetchPolicy: 'network-only',
    onError: (error) => {
      console.log('error', error);
    }
  });

  const [addProductType, { data: addedProductType }] = useMutation(ADD_PRODUCT_TYPE, {
    onCompleted: (response) => {
      if (response?.addProductType?.id) {
        // refetchProductTypes();
        setProductTypeId && setProductTypeId(response?.addProductType?.id);
        showToast({ successText: 'Product Type added successfully', message: `The Product Type has been added sucessfully` });
      }
    },
    onError: (error) => {
      showToast({
        errorText: error.message,
        message: `Error occured while adding Product Type: ${error.message}`
      });
    }
  });

  useEffect(() => {
    if (addedProductType?.addProductType?.success) {
      const newProductType = { id: addedProductType.addProductType.id, name: addedProductType.addProductType.name };
      const updatedAllProductTypes = [newProductType, ...allProductTypes];
      setAllProductTypes(updatedAllProductTypes);
    }
  }, [addedProductType]);

  const [deleteProductType] = useMutation(DELETE_PRODUCT_TYPE, {
    onCompleted: (response) => {
      if (response) {
        showToast({ successText: 'Product Type deleted successfully', message: `The Product Type has been deleted sucessfully` });
        refetchProductTypes();
      }
    },
    onError: (error) => {
      showToast({
        errorText: error.message,
        message: `Error occured while deleting Product Type: ${error.message}`
      });
    }
  });

  const [updateProductType] = useMutation(UPDATE_PRODUCT_TYPE, {
    onCompleted: (response) => {
      if (response) {
        showToast({ successText: 'Product Type updated successfully', message: `The Product Type has been updated sucessfully` });
        // refetchProductTypes();
      }
    },
    onError: (error) => {
      showToast({
        errorText: error.message,
        message: `Error occured while updating Product Type: ${error.message}`
      });
    }
  });

  const handleAddOption = (name: string) => {
    addProductType({
      variables: {
        input: {
          name
        }
      }
    });
  };

  const handleDeleteOption = (id: number) => {
    deleteProductType({
      variables: {
        input: {
          id
        }
      }
    });
    const filteredProductTypes = allProductTypes.filter((item) => item.id !== id);
    setAllProductTypes(filteredProductTypes);
  };

  const handleUpdateOption = (id: number, name: string) => {
    updateProductType({
      variables: {
        input: {
          id,
          name
        }
      }
    });
    const updatedAllProductTypes = allProductTypes.map((item) => {
      if (item.id === id) {
        return { ...item, name };
      }
      return item;
    });
    setAllProductTypes(updatedAllProductTypes);
  };

  useEffect(() => {
    if (data?.getAllProductTypes) {
      setAllProductTypes(
        data?.getAllProductTypes?.map((item) => {
          return { id: item.id, name: item.name, relatedCount: item.totalProducts };
        })
      );
    }
  }, [data]);

  return (
    <SearchSelectDropdown
      type="product type"
      options={allProductTypes}
      handleAddOption={handleAddOption}
      handleUpdateOption={handleUpdateOption}
      handleDeleteOption={handleDeleteOption}
      optionItemId={productTypeId}
      setOptionItemId={setProductTypeId}
      isControllable={true}
      isControllableDirection={true}
      className="product-type-search-dropdown"
      isDisableSelector={role === UserRoles.FULFILMENT_ASSOCIATE}
    />
  );
};

export default ProductTypes;
