import { FC, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { Checkbox, Divider } from 'src/components/molecules';
import DraggableTagInput from '../DraggableTagInput/DraggableTagInput';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import ProductVariationTable from './helper/ProductVariationTable';

// Icons
import { PlusBlueIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { VariantOptionsSectionPresentationalProps } from './AddProductVariant.types';
import { UserRoles } from 'src/components/pages/settings/UserPermissions/UserPermissions.type';

const AddProductVariantPresentational: FC<VariantOptionsSectionPresentationalProps> = ({
  optionChecked,
  optionDone,
  options,
  setOptions,
  tableData,
  variantOption,
  addOptionHandler,
  addTagHandler,
  changeFieldValue,
  changeValue,
  checkTagError,
  checkTitleError,
  onChangeTitle,
  onDragEnd,
  onSubmitOptionsHandler,
  optionCheckHandler,
  removeOptionHandler,
  setOptionDone,
  setTableData,
  setVariationOption,
  setSelectedVariantOption,
  selectedVariantOption,
  setCheckedVariantOption,
  checkedVariantOption,
  filteredOptions,
  setFilteredOptions,
  setOptionChecked,
  pricing,
  productMedia,
  updateProductMedia,
  inventory,
  formChanges,
  setLabelPrintType,
  setShowLabelPrintModal,
  setShowProductSavingModal,
  selectedVariants,
  setSelectedVariants,
  setSelectedVariantIds
}) => {
  const [existingVariantOptions, setExistingVariantOptions] = useState<string[]>([]);
  const [letEscapeWork, setLetEscapeWork] = useState(false);

  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  useEffect(() => {
    if (options) {
      let tempVal: string[] = [];
      options.forEach((item) => {
        tempVal = [...tempVal, item.title];
      });
      setExistingVariantOptions(tempVal);
    }
  }, [options]);

  useEffect(() => {
    if (existingVariantOptions) {
      const tempVal = variantOption.filter((data) => existingVariantOptions.indexOf(data.name) === -1);
      setFilteredOptions(tempVal);
    }
  }, [existingVariantOptions]);

  return (
    <div className="card w-100 m-0">
      <h4 className="section-title m-b-32">{constVariables.common.variantions.key}</h4>
      <Checkbox
        labelclassname=""
        name={constVariables.common.variantions.title}
        onChangeValue={optionCheckHandler}
        value={optionChecked}
        noVerticalPadding
        disable={role === UserRoles.FULFILMENT_ASSOCIATE}
      />

      {optionChecked && options.length !== 0 && <Divider classname="m-y-24" />}
      {optionChecked && options.length !== 0 && (
        <div
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Escape' && letEscapeWork) {
              onSubmitOptionsHandler();
              setLetEscapeWork(false);
            }
          }}
          className="w-100 noFocusOutline"
        >
          <div className="d-flex align-items-center justify-content-between m-b-24">
            <h3 className="poppins-medium m-auto-0 text-nowrap variant-subtitle">{constVariables.common.variantions.options}</h3>
            {optionDone && role !== UserRoles.FULFILMENT_ASSOCIATE && (
              <div
                onClick={() => {
                  setLetEscapeWork(true);
                  setOptionDone(false);
                }}
                className="text-primary cursor-pointer text-btn poppins-regular fw-normal"
              >
                {constVariables.common.variantions.editOption}
              </div>
            )}
          </div>

          {optionDone && (
            <div className="w-100">
              {options.map((item, index) => (
                <div key={item.title} className={`d-flex align-items-center ${index !== options?.length - 1 ? 'm-b-16' : ''}`}>
                  <p className="m-0 option-title">{`${item.title}`}</p>
                  <div className="d-flex align-items-center flex flex-wrap">
                    {item.value.map((tag) => (
                      <span key={tag} className="ms-2 option-item mb-2">
                        {tag}
                      </span>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}

          {!optionDone && (
            <>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list">
                  {(provided) => (
                    <div id="list" {...provided.droppableProps} ref={provided.innerRef}>
                      {options?.map((item, index) => {
                        return (
                          <Draggable key={index} draggableId={index.toString()} index={index}>
                            {(provided) => {
                              return (
                                <div key={index} ref={provided.innerRef} {...provided.draggableProps}>
                                  <div className={`d-flex mt-4 w-100`}>
                                    <DraggableTagInput
                                      dropdownData={filteredOptions}
                                      setDropdownData={setVariationOption}
                                      dragHandler={provided}
                                      index={index}
                                      onChangeText={onChangeTitle}
                                      onDelete={removeOptionHandler}
                                      onHandleChange={addTagHandler}
                                      title={item.title}
                                      value={item.value}
                                      errTitle={item.errTitle}
                                      errVariant={item.errVariant}
                                      checkTitleError={checkTitleError}
                                      checkTagError={checkTagError}
                                      options={options}
                                    />
                                  </div>
                                </div>
                              );
                            }}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <div className="d-flex align-items-center ps-6 mt-8">
                {options.length < 3 && (
                  <button
                    onClick={addOptionHandler}
                    className="d-flex align-items-center justify-content-center btn btn-secondary btn-md me-2"
                  >
                    <KTSVG path={PlusBlueIcon} />
                    <span className="fs-6">{constVariables.common.variantions.addAnotherOption}</span>
                  </button>
                )}
                <button onClick={onSubmitOptionsHandler} className="btn btn-primary btn-md">
                  <span className="fs-6">{constVariables.common.variantions.done}</span>
                </button>
              </div>
            </>
          )}
          <Divider classname="m-y-24" />
        </div>
      )}
      {optionChecked && optionDone && (
        <ProductVariationTable
          pricing={pricing}
          tableData={tableData}
          changeFieldValue={changeFieldValue}
          changeValue={changeValue}
          options={options}
          setOptions={setOptions}
          setTableData={setTableData}
          setSelectedVariantOption={setSelectedVariantOption}
          selectedVariantOption={selectedVariantOption}
          setCheckedVariantOption={setCheckedVariantOption}
          setOptionDone={setOptionDone}
          checkedVariantOption={checkedVariantOption}
          setOptionChecked={setOptionChecked}
          productMedia={productMedia}
          updateProductMedia={updateProductMedia}
          inventory={inventory}
          formChanges={formChanges}
          setLabelPrintType={setLabelPrintType}
          setShowLabelPrintModal={setShowLabelPrintModal}
          setShowProductSavingModal={setShowProductSavingModal}
          selectedVariants={selectedVariants}
          setSelectedVariants={setSelectedVariants}
          setSelectedVariantIds={setSelectedVariantIds}
        />
      )}
    </div>
  );
};

export default AddProductVariantPresentational;
