import { gql } from '@apollo/client';

export const CHANGE_COLLECTION_STATUS = gql`
  mutation ChangeCollectionStatus($input: CollectionStatusChange!) @api(name: "productsAPI") {
    changeCollectionStatus(input: $input) {
      id
      success
      message
      additionalData
    }
  }
`;
