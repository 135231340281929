import { TypedDocumentNode, gql } from '@apollo/client';
import { IAvalaraAccount } from 'src/components/pages/settings/Taxes/Taxes.types';

export const GET_AVALARA_CONFIGURATION: TypedDocumentNode<{
  getAvalaraConfiguration: IAvalaraAccount;
}> = gql`
  query GetAvalaraConfiguration @api(name: "authAPI") {
    getAvalaraConfiguration {
      __typename
      isConnected
      companyCode
      companyName
      companyCountry
      isSandbox
      defaultShippingTaxCode
      defaultProductTaxCode
      allowDocumentRecording
      isAvalaraEnabled
      availableCompanies {
        companyCode
        name
      }
    }
  }
`;
