import IVSBroadcastClient from 'amazon-ivs-web-broadcast';
import { MIC_LAYER_NAME } from 'src/components/pages/liveshow/LiveshowManager/helpers/mediaDevices';

interface mixerDevice {
  name: string;
  device: null;
  muted: boolean;
}

interface IUseMixer {
  addMixerDevice: (mixer: mixerDevice) => void;
  removeMixerDevice: () => void;
}

const useMixer = (
  client: React.MutableRefObject<IVSBroadcastClient.AmazonIVSBroadcastClient | undefined>['current']
): IUseMixer => {
  const addMixerDevice = async (mixerDevice) => {
    if (!client) {
      return;
    }
    try {
      if (mixerDevice.device.deviceId) {
        const { device, name, muted } = mixerDevice;

        if (client.getAudioInputDevice(name)) {
          await removeMixerDevice();
        }

        if (muted) {
          return;
        }

        const audioStream = await navigator.mediaDevices.getUserMedia({
          audio: { deviceId: device.deviceId }
        });

        await client.addAudioInputDevice(audioStream, name);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const removeMixerDevice = async () => {
    if (!client) {
      return;
    }

    try {
      const name = MIC_LAYER_NAME;
      if (!name) return;

      const audioStreamStream: any = client.getAudioInputDevice(name);
      if (audioStreamStream) {
        for (const track of audioStreamStream.getAudioTracks()) {
          track.stop();
        }
      }

      await client.removeAudioInputDevice(name);
    } catch (err) {
      console.error(err);
    }
  };

  return {
    addMixerDevice,
    removeMixerDevice
  };
};

export default useMixer;
