import { FC } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import clsx from 'clsx';

// Components
import CreateOrderDetailsTable from './CreateOrderDetailsTable';
import CreateOrderPaymentDetails from './CreateOrderPaymentDetails';

// Hooks && Utils && Helpers
import getDeviceData from 'src/utils/UseDevice/useDevice';

// Icons
import { CloseIcon, EmptyNotes, PlusNoBorder } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants/constVariables';

// Types
import { CreateOrderPresentationProps } from './CreateOrder.types';

// Styles
import './CreateOrder.scss';

const CreateOrderPresentational: FC<CreateOrderPresentationProps> = ({ handleAddProduct }) => {
  const deviceName = getDeviceData();
  return (
    <div className="row ">
      <div className={`col-sm-12 col-md-8 ${deviceName.isWeb ? 'col-lg-9' : 'col-lg-8'} pe-6`}>
        <div className="card my-7 p-7">
          <div className="w-100 ">
            <CreateOrderDetailsTable />
            <div className="text-center">
              <button
                className="btn btn-light-primary btn-color-primary btn-active-light-primary text-center"
                onClick={handleAddProduct}
              >
                <img src={PlusNoBorder} className="px-2" />
                {constVariables.createOrder.addProduct}
              </button>
            </div>
          </div>
        </div>

        <div className="card w-100">
          <CreateOrderPaymentDetails />
        </div>
      </div>

      {/* Side Column  */}
      <div className={`col-sm-12 col-md-4 ${deviceName.isWeb ? 'col-lg-3' : 'col-lg-4'}   mt-7`}>
        <div className="card p-3">
          <div>
            <h4 className="poppins-regular m-auto-0 mt-5 text-nowrap dark-color">{constVariables.orders.customer}</h4>
            <button className="btn dropdown-toggle dropCreate greyText btn-sm mt-5" type="button" id="dropdownMenuButton">
              {constVariables.createOrder.searchPlaceHolder}
            </button>
          </div>
        </div>

        <div className="card p-3">
          <>
            <div className="productNotesBox">
              <div className="m-4 d-flex justify-content-start align-items-start">
                <h3 className="mx-3 mt-1">{constVariables.common.productNote.noteTitle}</h3>
              </div>
              <div className="noProductText">
                <img src={EmptyNotes} className=" p-4" />
                <p className="">{constVariables.createOrder.emptyNotesTitle}</p>
                <p className=" greyText">{constVariables.createOrder.emptyNotesMessage}</p>
              </div>
            </div>
            <OutsideClickHandler
              onOutsideClick={() => {
                // setTextFocus(true);
              }}
            >
              <div className="m-5 d-flex align-items-end">
                <textarea
                  id="kt_autosize_2"
                  placeholder={constVariables.common.productNote.placeHolder}
                  className={clsx('createNotesInput noresize', {})}
                />
                <button type="button" className="btn btn-primary">
                  {constVariables.common.productNote.save}
                </button>
              </div>
            </OutsideClickHandler>
          </>
        </div>

        <div className="card p-3">
          <div>
            <h4 className="poppins-regular m-auto-0 mt-5 text-nowrap dark-color">{constVariables.orderDetails.tag}</h4>
          </div>
          <div className="tagInput">
            <span className="badge tagBadge ">
              <span className="tagText">{constVariables.orderDetails.tag1}</span>
              <img src={CloseIcon} className="closeIconBadge" />
            </span>
          </div>
        </div>
        <button className="btn btn-light-danger btn-color-danger btn-active-light-danger deleteDraft border-danger mb-5">
          {constVariables.createOrder.deleteDraft}
        </button>
      </div>
    </div>
  );
};

export default CreateOrderPresentational;
