import { FC } from 'react';
import clsx from 'clsx';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { CloseIcon, Reciept, WarningIcon } from 'src/assets/icons';

// Styles
import 'src/components/oraganisms/CustomModal/modalStyles.scss';
import './_shippingRateFulfillmentCenter.scss';

export interface RefundOrderProps {
  title: string;
  message?: string;
  actionBtnTitle?: string;
  cancelBtnTitle?: string;
  cancelOrder?: boolean;
  isOptions?: boolean;
  actionBtnHandler?: (...args: any) => void;
  cancelBtnHandler?: (...args: any) => void;
  loading?: boolean;
  error?: boolean;
}

const RefundShippingLabel: FC<RefundOrderProps> = ({
  title,
  actionBtnTitle,
  cancelBtnTitle,
  actionBtnHandler,
  cancelBtnHandler,
  cancelOrder,
  loading,
  error
}) => {
  const Loading = () => {
    return (
      <div className="flex-center w-100">
        <div className="loader"></div>
      </div>
    );
  };

  return (
    <div
      className={clsx('modal-content cancelOrder pt-4', {
        'modal-large-content': cancelOrder === true
      })}
    >
      <div className={`w-100 text-center mt-3`}>
        <img src={Reciept} className={`h-60px ${loading || error ? 'd-none' : ''}`} />
        <span className="btn btn-icon ms-2 mt-3 me-3 position-absolute end-0 top-0" data-bs-dismiss="modal" aria-label="Close">
          <KTSVG path={CloseIcon} className="svg-icon svg-icon-2x" onClick={cancelBtnHandler} />
        </span>
      </div>
      <div className="modal-header pt-0 border-bottom-none pb-2">
        <h2 className={`modal-title mx-auto fs-1 ${loading || error ? 'd-none' : ''}`}>{title}</h2>
      </div>
      <div
        className={clsx('modal-body text-center py-2', {
          mediaGreyText: cancelOrder === true
        })}
      >
        {loading ? (
          <div>
            <Loading />
            <h2 className="text-center my-5">Loading...</h2>
          </div>
        ) : (
          <div>
            {error ? (
              <div className="text-center">
                <img src={WarningIcon} />
                <h3 className="text-danger mt-5">We are unable to refund shipping label!</h3>
              </div>
            ) : (
              <div>Are you sure you want to refund shipping label?</div>
            )}
          </div>
        )}
      </div>
      {!loading ? (
        <div className="modal-footer">
          {error ? (
            <button type="button" className="btn btn-primary btn-md" onClick={actionBtnHandler}>
              Try Again
            </button>
          ) : (
            <>
              <button type="button" className="btn btn-outlined-secondary btn-md" onClick={cancelBtnHandler}>
                {cancelBtnTitle}
              </button>
              <button type="button" className="btn btn-primary btn-md" onClick={actionBtnHandler}>
                {actionBtnTitle}
              </button>
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default RefundShippingLabel;
