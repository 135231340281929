/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// Icons
import { NotificationUserIcon, NotificationOrderIcon, NotificationAppIcon } from 'src/assets/icons';

export const renderNotificationIcon = (title: string) => {
  if (title.includes('new product')) {
    return NotificationUserIcon;
  } else if (title.includes('order')) {
    return NotificationOrderIcon;
  } else if (title.includes('app')) {
    return NotificationAppIcon;
  }
};
