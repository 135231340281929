import { gql } from '@apollo/client';

export const CREATE_LOCATION = gql`
  mutation onAddLocation($input: CreateLocationInput!) @api(name: "productsAPI") {
    addLocation(input: $input) {
      id
      name
      warehouseId
    }
  }
`;
