import { gql } from '@apollo/client';

export const DELETE_USER = gql`
  mutation DeleteUser($input: UserIdInput!) @api(name: "productsAPI") {
    deleteUser(input: $input) {
      id
      success
      message
    }
  }
`;
