import { FC, memo } from 'react';
import clsx from 'clsx';

// Components
import { Pagination } from 'src/components/molecules';
import { AddWarehouse, WarehouseList, EmptyList, ScreenHeader } from 'src/components/oraganisms';

// Icons
import { NoWarehouseIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { WarehouseManagementPresentationalProps } from './WarehouseManagement.type';

const WarehouseManagementPresentational: FC<WarehouseManagementPresentationalProps> = (props) => {
  const {
    addWarehouseModal,
    setSelectedTab,
    selectedTab,
    addWarehouse,
    warehouseListData,
    sortBy,
    setSortBy,
    warehousePageInfo,
    setWarehousePageInfo,
    refresh,
    setWarehouseData,
    editWarehouseData,
    isLoading,
    totalCount
  } = props;

  const breadCrumbs = [
    {
      title: constVariables.warehouseManagement.settings,
      path: '/settings',
      isActive: false
    },
    {
      title: constVariables.warehouseManagement.title,
      path: '/settings/warehouses',
      isActive: true
    }
  ];

  return (
    <>
      <div className="w-100 p-40" id="tablePage">
        <div className={`width-animation w-100 hide-scrollbar min-h-100`}>
          <ScreenHeader
            breadCrumbsList={breadCrumbs}
            headerTitle={constVariables.warehouseManagement.title}
            headerButtonText={constVariables.warehouseManagement.addNewWarehouse}
            headerButtonClick={addWarehouse}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            tabList={constVariables.TabForWarehouse}
            removeExportImport={true}
          />
          <AddWarehouse show={addWarehouseModal} resetState={addWarehouse} data={editWarehouseData} />
          <div className="card w-100 mb-0 p-0">
            <div className="p-x-32 p-t-32">
              {!isLoading && totalCount === 0 && (
                <>
                  {selectedTab === constVariables.WareHousesTab.allWarehouses.toUpperCase() ? (
                    <EmptyList
                      title={constVariables.emptyDataList.warehouse}
                      description={constVariables.emptyDataList.warehouseDescription}
                      addButtonText={constVariables.emptyDataList.warehouseAction}
                      onAddButton={addWarehouse}
                      icon={NoWarehouseIcon}
                    />
                  ) : (
                    <EmptyList datatype="warehouse" status={selectedTab} icon={NoWarehouseIcon} />
                  )}
                </>
              )}
              <WarehouseList
                warehouseList={warehouseListData}
                setSortBy={setSortBy}
                sortBy={sortBy}
                rapidActionOpen={false}
                refresh={refresh}
                setWarehouseData={setWarehouseData}
                isLoading={isLoading}
              />
            </div>
            <div className={clsx({ 'display-none': totalCount === 0 })}>
              <Pagination
                skipCount={warehousePageInfo?.skipCount}
                limitCount={warehousePageInfo?.limitCount}
                setSkipCount={(num: number) =>
                  setWarehousePageInfo((prev) => ({
                    ...prev,
                    skipCount: num
                  }))
                }
                total={totalCount}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(WarehouseManagementPresentational);
