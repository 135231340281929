import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import clsx from 'clsx';

// Components
import { FlexBox } from 'src/components/atoms';
import { InputBox, Tooltip } from 'src/components/molecules';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { ProductIdentifiersSection } from 'src/components/pages/products/AddProduct/AddProduct.types';

// Styles
import './_addProductIdentifiers.scss';

const AddProductIdentifiers: FC<ProductIdentifiersSection> = ({
  productIdentifiers,
  setProductIdentifiers,
  errorMessages,
  setErrorMessages,
  isDisableInput,
  formChanges,
  setLabelPrintType,
  setShowLabelPrintModal,
  setShowProductSavingModal
}) => {
  const { productId } = useParams<{ productId: string }>();
  const [showMoreActions, setShowMoreActions] = useState(false);

  const isDisablePrintLabelBtn = useMemo(() => {
    if (!productIdentifiers.skuValue && !productIdentifiers.barCodeValue) {
      return true;
    }
    return false;
  }, [productIdentifiers.skuValue, productIdentifiers.barCodeValue]);

  const SkuValueChange = (e: any) => {
    const regex = /^[a-zA-Z0-9_]+$/;
    if (!e || regex.test(e)) {
      setProductIdentifiers((prev) => ({
        ...prev,
        skuValue: e
      }));
    }
  };
  const BarCodeValueChange = (e: any) => {
    if (!isNaN(e)) {
      setProductIdentifiers((prev) => ({
        ...prev,
        barCodeValue: e
      }));
    }
  };
  const BrandValueChange = (e: any) => {
    setProductIdentifiers((prev) => ({
      ...prev,
      brandValue: e
    }));
  };
  const BrandStyleValueChange = (e: any) => {
    setProductIdentifiers((prev) => ({
      ...prev,
      brandStyleValue: e
    }));
  };

  const handleMoreActions = (option) => {
    setShowMoreActions(false);
    setLabelPrintType(option);
    if (!productId || formChanges) {
      localStorage.setItem('shouldLabelPrinting', 'true');
      setShowProductSavingModal(true);
    } else {
      setShowLabelPrintModal(true);
    }
  };

  const closeMoreActionDropdown = () => {
    setShowMoreActions(false);
  };

  const MenuOptionItem = ({ value, optionText, printType }) => {
    const isDisablePrint = useMemo(() => {
      if (!value) {
        return true;
      }
      return false;
    }, [value]);

    return (
      <div
        onClick={() => {
          if (!isDisablePrint) handleMoreActions(printType);
        }}
        className={`d-flex justfy-content-center align-items-center dropdown-item dropdown-height`}
      >
        <>
          {isDisablePrint ? (
            <>
              <div
                aria-labelledby="dropdownMenuButton"
                className="dropdown-item-option opacity-50"
                data-tip
                data-for="printLabelAlert"
              >
                <span className="option-text poppins-medium">{optionText}</span>
              </div>
              <Tooltip
                tooltipId="printLabelAlert"
                place="top"
                content={() => {
                  return <>Add {printType === 'sku' ? 'SKU' : 'Barcode'} to print</>;
                }}
              />
            </>
          ) : (
            <div aria-labelledby="dropdownMenuButton" className="dropdown-item-option cursor-pointer">
              <span className="option-text poppins-medium">{optionText}</span>
            </div>
          )}
        </>
      </div>
    );
  };

  return (
    <div className="card w-100">
      <FlexBox className="align-items-center justify-content-between m-b-32">
        <h4 className="section-title m-0">{constVariables.common.productIdentifiers.key}</h4>
        <div className="position-relative">
          <div
            className={`poppins-regular fw-normal ${
              isDisablePrintLabelBtn ? 'disabled-text-btn' : 'cursor-pointer text-primary text-btn'
            }`}
            onClick={() => {
              if (!isDisablePrintLabelBtn) setShowMoreActions(true);
            }}
          >
            {constVariables.common.productIdentifiers.printLabels}
          </div>
          <OutsideClickHandler onOutsideClick={closeMoreActionDropdown}>
            <div className={clsx(`dropdown-menu z-index-dropdown custom-dropdown`, { show: showMoreActions })}>
              <MenuOptionItem value={productIdentifiers.skuValue} optionText="Print SKU" printType="sku" />
              <MenuOptionItem value={productIdentifiers.barCodeValue} optionText="Print barcode" printType="barcode" />
            </div>
          </OutsideClickHandler>
        </div>
      </FlexBox>

      <div className="row gx-3">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <InputBox
            inputClass="form-control-lg"
            mainclass="m-b-24"
            title={constVariables.common.productIdentifiers.sku}
            value={productIdentifiers.skuValue}
            onChangeText={SkuValueChange}
            name={constVariables.common.productIdentifiers.sku}
            placeholder={constVariables.common.productIdentifiers.numberPlaceholder}
            disable={isDisableInput}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <InputBox
            inputClass="form-control-lg"
            mainclass="m-b-24"
            title={constVariables.common.productIdentifiers.barCode}
            value={productIdentifiers.barCodeValue}
            onChangeText={BarCodeValueChange}
            name={constVariables.common.productIdentifiers.barCode}
            placeholder={constVariables.common.productIdentifiers.numberPlaceholder}
            disable={isDisableInput}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <InputBox
            inputClass="form-control-lg"
            title={constVariables.common.productIdentifiers.brand}
            value={productIdentifiers.brandValue}
            onChangeText={BrandValueChange}
            name={constVariables.common.productIdentifiers.brand}
            placeholder={constVariables.common.productIdentifiers.brandPlaceholder}
            disable={isDisableInput}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <InputBox
            inputClass="form-control-lg"
            title={constVariables.common.productIdentifiers.brandStyle}
            value={productIdentifiers.brandStyleValue}
            onChangeText={BrandStyleValueChange}
            name={constVariables.common.productIdentifiers.brandStyle}
            placeholder={constVariables.common.productIdentifiers.brandStylePlaceholder}
            disable={isDisableInput}
          />
        </div>
      </div>
    </div>
  );
};

export default AddProductIdentifiers;
