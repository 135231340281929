import { gql } from '@apollo/client';

export const CUSTOMISE_APP_THEME = gql`
  mutation customiseAppTheme($input: CustomiseAppThemeInput) @api(name: "authAPI") {
    customiseAppTheme(input: $input) {
      primaryColor
      secondaryColor
      bgColor
      appLogo
      status
    }
  }
`;
