import { gql } from '@apollo/client';

export const EDIT_CATALOGUE_RULES = gql`
  mutation EditCatalogueRules($input: UpdateCatalogProductAssignmentRuleInput!) @api(name: "productsAPI") {
    updtCatalogPrdAssignRules(input: $input) {
      id
      success
      message
      additionalData
    }
  }
`;
