import moment from 'moment';

export const getTimediff = (startAt: string): string => {
  const timeNow = moment(new Date());
  const min = moment(startAt).diff(timeNow, 'minutes') % 60;
  let hours = moment(startAt).diff(timeNow, 'hours');
  let day = 0;
  day = parseInt((hours / 24).toString(), 10);
  if (hours > 23) {
    hours = hours % 24;
  }

  if (day) {
    return ` Starts in ${day.toString()}d ${hours.toString()}hr ${min.toString()}min`;
  } else if (hours) {
    return ` Starts in ${hours.toString()}hr ${min.toString()}min`;
  } else if (min) {
    return ` Starts in ${min.toString()}min`;
  }

  return ` Starting in few seconds`;
};
