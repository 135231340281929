import { FC, useMemo, useState } from 'react';

// Apollo
import { useMutation } from '@apollo/client';
import { FORGET_PASSWORD } from 'src/apollo/mutations';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import { InputBox } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { validateEmail } from 'src/utils/validate';

// Icons
import { CloseIcon, SendSuccessIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables, envUrls } from 'src/constants';

interface ForgotPasswordModalProps {
  closeModal: () => void;
}

const ForgotPasswordModal: FC<ForgotPasswordModalProps> = ({ closeModal }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [currenStep, setCurrentStep] = useState('email');

  const handleChangeEmail = (value: string) => {
    setEmail(value);
  };

  const [forgetPassword] = useMutation(FORGET_PASSWORD, {
    fetchPolicy: 'no-cache',
    onCompleted: (responseData) => {
      setError('');
      setCurrentStep('completed');
    },
    onError: (e) => {
      console.error('resetpassword onError - ', e);
      console.error('e?.graphQLErrors[0] - ', e?.graphQLErrors[0]);
      setError('Some Error occured!');
    }
  });

  const handleContinue = async () => {
    switch (currenStep) {
      case 'email': {
        let emailError = validateEmail(email);

        if (email !== localStorage.getItem('email')) {
          emailError = 'Invalid email address';
        }

        if (typeof emailError === 'string') {
          setError(emailError);
        } else {
          forgetPassword({
            context: {
              headers: {
                tenantid: '',
                'x-api-key': envUrls.authXApiKey
              }
            },
            variables: {
              input: {
                email,
                isBackStoreUser: true
              }
            }
          });
        }
        break;
      }

      default:
        break;
    }
  };

  const handleValidateEmail = (value: string) => {
    // Validate the email;
    let emailError = validateEmail(value);
    if (email !== localStorage.getItem('email')) {
      emailError = 'Invalid email address';
    }

    if (typeof emailError === 'string') {
      setError(emailError);
    } else {
      setError('');
    }
  };

  const isButtonDisabled = useMemo(() => {
    if (error.trim().length > 0) {
      return true;
    }
    return false;
  }, [currenStep, email, error]);

  return (
    <div className="change-account-info-modal bg-white modal-content">
      <div className="modal-header">
        <h5 className="modal-title">{constVariables.accountSettings.forgotPassword}</h5>
        <div
          className="btn btn-xs btn-active-light-primary p-0 m-0 border-none"
          onClick={() => {
            closeModal();
            setCurrentStep('email');
            setError('');
          }}
        >
          <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
        </div>
      </div>
      <div className="modal-body">
        {currenStep === 'email' && (
          <div>
            <InputBox
              title={constVariables.accountSettings.enterExistingEmail}
              value={email}
              onChangeText={(value) => handleChangeEmail(value)}
              name="email"
              inputClass="form-control-lg"
              warningText={error}
              onBlur={(event) => handleValidateEmail(event.target.value)}
              placeholder="e.g.yourname@mail.com"
              type="email"
              helperText={constVariables.accountSettings.sendVerification}
            />
          </div>
        )}
        {currenStep === 'completed' && (
          <div>
            <FlexBox className="align-items-center flex-column m-b-24">
              <Img src={SendSuccessIcon} />
              <p className="mb-0 sent-success m-t-8">{constVariables.accountSettings.sentSuccess}</p>
            </FlexBox>
            <p className="m-b-24">
              We just sent an email to sarahdoe@gmail.com with a link to reset your password for your BuySocial account.
            </p>
            <p className="mb-0">
              Didn’t get the verification link?{' '}
              <span
                className="text-btn text-primary cursor-pointer"
                onClick={() => {
                  forgetPassword({
                    context: {
                      headers: {
                        tenantid: '',
                        'x-api-key': envUrls.authXApiKey
                      }
                    },
                    variables: {
                      input: {
                        email,
                        isBackStoreUser: true
                      }
                    }
                  });
                }}
              >
                Send again
              </span>
            </p>
          </div>
        )}
      </div>
      {currenStep === 'email' && (
        <div className="modal-footer">
          <button className="btn btn-primary btn-md" type="button" disabled={isButtonDisabled} onClick={handleContinue}>
            Send password reset link
          </button>
        </div>
      )}
    </div>
  );
};

export default ForgotPasswordModal;
