import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

// Apollo
import { useMutation, useQuery } from '@apollo/client';
import { CONFIGURE_SHIPPO } from 'src/apollo/mutations';
import { CHECK_SHIPPO_INTEGRATION } from 'src/apollo/queries';
import { shippoConfigCode } from 'src/apollo/reactiveVariables';

// Components
import { FlexBox, Loader } from 'src/components/atoms';
import { Tooltip } from 'src/components/molecules';

// Hooks && Utils && Helpers
import useTitle from 'src/utils/hooks/useTitle';
import { useToast } from 'src/utils/hooks/useToast';

// Icons
import { BackRoundedIcon, FilledCheckCircle, InfoIcon, ShippoLogo } from 'src/assets/icons';

// ConstVariables
import { constVariables, envUrls, ROUTES } from 'src/constants';

// Styles
import './Shipping.scss';

const Shipping: FC = () => {
  useTitle('Settings - Shipping');
  const history = useHistory();
  const { showToast } = useToast();
  const [accountConnected, setAccountConnected] = useState(false);
  const [configureShippo] = useMutation(CONFIGURE_SHIPPO, {
    onCompleted: () => {
      shippoConfigCode('');
      setAccountConnected(true);
      showToast({
        successText: constVariables.shipping.modal.title,
        message: constVariables.shipping.modal.message
      });
    }
  });

  const { loading: isLoading } = useQuery(CHECK_SHIPPO_INTEGRATION, {
    onCompleted: (data) => {
      if (data?.checkShippoIntegration?.isIntegrated) {
        setAccountConnected(true);
      } else {
        setAccountConnected(false);
      }
    }
  });

  useEffect(() => {
    if (shippoConfigCode()) {
      configureShippo({
        variables: {
          input: {
            code: shippoConfigCode()
          }
        }
      });
    }
  }, []);

  const returnBack = () => {
    history.push(ROUTES.settings.main);
  };

  return (
    <FlexBox className="shipping">
      <div className="width-animation w-100">
        <div className="details-page">
          <div className="details-page-container">
            <FlexBox className="align-items-center m-y-40">
              <img src={BackRoundedIcon} alt={BackRoundedIcon} onClick={returnBack} className="cursor-pointer" />
              <h2 className="page-title px-4 mb-0">{constVariables.shipping.key}</h2>
            </FlexBox>
            <div className="main-detail-layout">
              <div className="left-pane">
                <div className="card w-100">
                  <div>
                    <img src={ShippoLogo} />
                    <img src={InfoIcon} className="px-3" data-tip data-for="shippingInfoIcon" />
                    <Tooltip
                      tooltipId="shippingInfoIcon"
                      className="toolTipShip"
                      place="right"
                      content={() => {
                        return (
                          <div>
                            {constVariables.shipping.hoverMessage}
                            <a className="text-primary">{constVariables.shipping.clickHere}</a>
                            {constVariables.shipping.moreInfo}
                          </div>
                        );
                      }}
                    />
                    {accountConnected ? (
                      <div className="mt-10 d-flex align-items-center">
                        {isLoading ? (
                          <Loader type="page" />
                        ) : (
                          <>
                            <img src={FilledCheckCircle} />
                            <span className="ms-2 text-success">{constVariables.shipping.accountConnectedMessage}</span>
                          </>
                        )}
                      </div>
                    ) : (
                      <>
                        {isLoading ? (
                          <Loader type="page" />
                        ) : (
                          <>
                            <p className="poppins-regular mt-5">{constVariables.shipping.shippoMessage}</p>
                            <a
                              className="btn btn-primary mt-5"
                              href={`https://goshippo.com/oauth/authorize?response_type=code&client_id=${envUrls.shippoClientId}&scope=*&state=YOUR_RANDOM_STRING`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {constVariables.shipping.connectBtn}
                            </a>
                          </>
                        )}
                      </>
                    )}
                    {/* NOTE: commented Success message for Future */}
                    {/* <>
                      <img src={Checked} />
                      <span className="connected">{constVariables.shipping.connectedMessage}</span>
                    </> */}
                  </div>
                </div>
              </div>
              <div className="right-pane">
                <div className="card w-100">
                  <h2 className="poppins-regular fs-3 m-auto-0 text-nowrap dark-color">{constVariables.shipping.steps.title}</h2>
                  <p className="mt-5 mediaGreyText">{constVariables.shipping.steps.stepTitle}</p>
                  <p className="mt-5 mediaGreyText">
                    {constVariables.shipping.steps.step1}
                    <span className="unLine cursor-pointer">
                      <a className="text-primary" href="https://apps.goshippo.com/join" rel="noreferrer" target="_blank">
                        {constVariables.shipping.steps.signUp}
                      </a>
                    </span>
                  </p>
                  <p className="mediaGreyText">
                    {constVariables.shipping.steps.step2}
                    <span className="unLine cursor-pointer">
                      <a
                        className="text-primary"
                        href="https://dashboard.sezzle.com/merchant/login/"
                        rel="noreferrer"
                        target="_blank"
                      >
                        {constVariables.shipping.sezzeleDashboard}
                      </a>
                    </span>
                  </p>
                  <p className="mediaGreyText mb-0">{constVariables.shipping.steps.step3}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FlexBox>
  );
};

export default Shipping;
