import { FC, PropsWithChildren, createRef, useEffect } from 'react';
import { FlexBox } from './atoms';
import { AsideDefault } from './oraganisms/aside/AsideDefault';
import { Content } from './Content';
import { MasterInit } from './MasterInit';
import useWindowDimensions from 'src/utils/UseWindowDimensions/useWindowDimensions';
import { useLayout } from './core';

const MasterLayout: FC<PropsWithChildren<any>> = ({ children }) => {
  const { width } = useWindowDimensions();
  const { config } = useLayout();
  const { aside } = config;

  return (
    <div>
      <FlexBox className="page flex-row flex-column-fluid">
        <FlexBox className="wrapper flex-column flex-row-fluid" id="kt_wrapper">
          <div id="kt_content" className="content flex-column flex-column-fluid d-flex">
            <FlexBox className="post flex-column-fluid" id="kt_post">
              <Content>{children}</Content>
            </FlexBox>
          </div>
        </FlexBox>
        <AsideDefault />
      </FlexBox>
      <MasterInit />
    </div>
  );
};

export { MasterLayout };
