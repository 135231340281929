/* eslint-disable no-nested-ternary */
import { FC, useEffect, useState, createRef } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { CustomModal, DeleteConfirmModal } from 'src/components/oraganisms';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { VeritalDotsIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { OptionItemProps } from './SearchSelectDropdown.types';

const OptionItem: FC<OptionItemProps> = ({
  type,
  item,
  handleSelectOption,
  data,
  isVisibleDropdown,
  handleDeleteOption,
  handleUpdateOption,
  isControllable,
  closeDropdown,
  isUseOutsideConfirmModal,
  handleShowDeleteConfirmModal,
  setSelectedOptionItem,
  setOptionItemId,
  containerClassName
}) => {
  const menuIconRef = createRef<any>();
  const [isShowActionMenus, setIsShowActionMenus] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [isShowInput, setIsShowInput] = useState(false);
  const [optionName, setOptionName] = useState(item.name);
  const [error, setError] = useState('');
  const [isShowMoreIcon, setIsShowMoreIcon] = useState(false);
  const [shouldShowUp, setShouldShowUp] = useState(false);

  const showActionMenus = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsShowActionMenus(!isShowActionMenus);

    if (menuIconRef.current) {
      const menuBottomPosition = menuIconRef.current.getBoundingClientRect().bottom;
      const dropdownElement = document.getElementsByClassName(containerClassName);
      if (dropdownElement) {
        const dropdownBottomPosition = dropdownElement[0].getBoundingClientRect().bottom;
        if (dropdownBottomPosition - menuBottomPosition < 90) {
          setShouldShowUp(true);
        }
      }
    }
  };

  useEffect(() => {
    if (!isVisibleDropdown) {
      setIsShowActionMenus(false);
      setIsShowInput(false);
      setIsShowMoreIcon(false);
      setConfirmModal(false);
      setShouldShowUp(false);
    }
  }, [isVisibleDropdown]);

  useEffect(() => {
    setOptionName(item.name);
  }, [item.name]);

  const handleChangeOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isExistedOption = data?.find((item) => item.name.toLowerCase() === e.target.value.toLowerCase());
    if (isExistedOption) {
      setError(`This ${type} already exists`);
    } else {
      setError('');
    }
    setOptionName(e.target.value);
  };

  const handleUpdateOptionItem = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (error) {
      return;
    }
    if (item.name === optionName) {
      // setError(`This ${type} already exists`);
      return;
    }
    if (e.key === 'Enter') {
      handleUpdateOption && handleUpdateOption(item.id, optionName);
      setOptionItemId && setOptionItemId(item.id);
      setIsShowInput(false);
      setIsShowMoreIcon(false);
      closeDropdown();
    }
  };

  const closeConfirmModal = () => {
    document.body.style.overflow = 'unset';
    setConfirmModal(false);
  };

  return (
    <>
      <div className="menu-item">
        {isShowInput ? (
          <div className="mt-2 mb-2 input-box">
            <OutsideClickHandler
              onOutsideClick={() => {
                if (isShowInput) {
                  handleUpdateOption && handleUpdateOption(item.id, optionName);
                  setOptionItemId && setOptionItemId(item.id);
                  setIsShowInput(false);
                  setIsShowMoreIcon(false);
                }
              }}
            >
              <input
                type="text"
                value={optionName}
                className={`border-none ${error ? 'error' : ''}`}
                onChange={handleChangeOption}
                onKeyDown={handleUpdateOptionItem}
                autoFocus
              />
              {error && <span className="mt-1 error-message poppins-regular">{error}</span>}
            </OutsideClickHandler>
          </div>
        ) : (
          <a
            title={item.name}
            className={`menu-link dropdown-item ${isShowActionMenus ? 'active' : ''}`}
            onClick={() => {
              handleSelectOption(item);
            }}
            onMouseEnter={() => setIsShowMoreIcon(true)}
            onMouseLeave={() => {
              if (!isShowActionMenus) {
                setIsShowMoreIcon(false);
              }
            }}
          >
            <span className="tag-name poppins-regular">{item.name}</span>
            {isShowMoreIcon && isControllable && (
              <span onClick={(e) => showActionMenus(e)} className="more-action-icon" ref={menuIconRef}>
                <KTSVG path={VeritalDotsIcon} className={`action-icon ${isShowActionMenus ? 'active' : ''}`} />
              </span>
            )}
          </a>
        )}

        <OutsideClickHandler
          onOutsideClick={() => {
            setIsShowActionMenus(false);
            setShouldShowUp(false);
          }}
        >
          {isShowActionMenus && (
            <div className={`tags-action-menu ${shouldShowUp ? 'up-direction' : ''}`}>
              <div
                className="cursor-pointer poppins-regular"
                onClick={() => {
                  setIsShowActionMenus(false);
                  setShouldShowUp(false);
                  setIsShowInput(true);
                }}
              >
                {constVariables.common.tags.edit}
              </div>
              <div
                className="cursor-pointer poppins-regular"
                onClick={() => {
                  if (isUseOutsideConfirmModal) {
                    setSelectedOptionItem && setSelectedOptionItem(item);
                    handleShowDeleteConfirmModal && handleShowDeleteConfirmModal();
                  } else {
                    setConfirmModal(true);
                  }
                }}
              >
                {constVariables.common.tags.delete}
              </div>
            </div>
          )}
        </OutsideClickHandler>
      </div>
      {confirmModal && (
        <CustomModal bodyClassname="w-90 w-md-150" show={confirmModal} closeModal={closeConfirmModal}>
          <DeleteConfirmModal
            title={`Delete ${type}`}
            message={`${
              item.relatedCount !== undefined
                ? item.relatedCount > 0
                  ? `This ${type} is assigned to ${item.relatedCount} product${
                      item.relatedCount > 1 ? 's' : ''
                    } at the moment. Are you sure you want to delete it?`
                  : `Are you sure you want to delete this ${type}?`
                : `Are you sure you want to delete this ${type}?`
            } This can’t be undone.`}
            actionBtnTitle="Yes, Delete"
            cancelBtnTitle="Cancel"
            actionBtnHandler={() => {
              handleDeleteOption && handleDeleteOption(item.id);
              closeConfirmModal();
            }}
            cancelBtnHandler={closeConfirmModal}
          />
        </CustomModal>
      )}
    </>
  );
};

export default OptionItem;
