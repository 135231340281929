import React, { FC } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { FlexBox } from 'src/components/atoms';
import { SearchBar, Pagination, Table, SortDropdown } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import getDeviceData from 'src/utils/UseDevice/useDevice';

// Icons
import { FilterIcon, SortIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { TablesProps } from 'src/components/molecules/Table/Table.types';

// Styles
import './VendorAllProducts.scss';

interface VendorAllProductsPresentationalProps {
  tableData: TablesProps;
  skipCount: number;
  limitCount: number;
  onlyUnSelected: Array<any>;
  totalVendors: number;
  selectedVendorProducts: Array<number>;
  selectedTab: string;
  allSortCategories: {
    name: string;
    id: number;
    label: string;
  }[];
  sortBy: any;
  openSortDropdown: boolean;
  setSkipCount: React.Dispatch<React.SetStateAction<number>>;
  handleDeselectAll(e: React.MouseEvent): void;
  handleChangeProductStatus(status: boolean): void;
  ChangeSortBy: (column: string, id: number) => void;
  setOpenSortDropdown: React.Dispatch<React.SetStateAction<boolean>>;
}
const VendorAllProductsPresentational: FC<VendorAllProductsPresentationalProps> = ({
  tableData,
  skipCount,
  limitCount,
  onlyUnSelected,
  totalVendors,
  selectedVendorProducts,
  selectedTab,
  allSortCategories,
  sortBy,
  openSortDropdown,
  setSkipCount,
  handleDeselectAll,
  handleChangeProductStatus,
  ChangeSortBy,
  setOpenSortDropdown
}) => {
  const deviceName = getDeviceData();
  return (
    <div className="card w-100 m-b-28">
      <div className={`card-header w-100 px-0`} id="kt_chat_contacts_header">
        <div className="w-100 vendor-list">
          <div className="mx-9">
            <FlexBox className="justify-content-between w-100 searchBar">
              <SearchBar />
              <FlexBox className="pb-1">
                <div className="position-relative updateTime">
                  <p>
                    <span className="lastUpdated">{constVariables.VendorDetails.allProduct.lastUpdated}</span>
                    {constVariables.VendorDetails.allProduct.tempTime}
                  </p>
                </div>
                <div className="position-relative">
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      if (openSortDropdown) {
                        setOpenSortDropdown(false);
                      }
                    }}
                  >
                    <button
                      className={`btn btn-sm btn-flex btn-icon-text align-items-center ms-3`}
                      onClick={() => {
                        setOpenSortDropdown(!openSortDropdown);
                      }}
                    >
                      <KTSVG path={SortIcon} className="me-1" />
                      <span className="my-auto ms-2 me-0">{constVariables.VendorDetails.allProduct.sort}</span>
                    </button>
                    <SortDropdown
                      data={allSortCategories}
                      value={sortBy}
                      onSelect={ChangeSortBy}
                      selected={openSortDropdown}
                      closeDropdown={() => setOpenSortDropdown(false)}
                    />
                  </OutsideClickHandler>
                </div>
                <button className="btn btn-sm btn-flex btn-icon-text align-items-center ms-3">
                  <KTSVG path={FilterIcon} className="me-1" />
                  <span className="my-auto ms-2 me-0">{constVariables.VendorDetails.allProduct.filter}</span>
                </button>
              </FlexBox>
            </FlexBox>
            {selectedVendorProducts?.length > 0 && (
              <>
                <FlexBox className="mt-3 justify-content-start align-items-center">
                  <button className="btn btn-outlined-primary btn-xs me-2" onClick={handleDeselectAll}>
                    <div className="d-flex align-items-center">
                      <input
                        className={'form-check-input small-checkbox-size shadow-none opacity-100 bg-primary rounded-sm'}
                        type="checkbox"
                        disabled={true}
                        ref={(input) => {
                          if (input) {
                            input.indeterminate = true;
                          }
                        }}
                      />
                      <span className="ms-2">
                        {selectedVendorProducts?.length}
                        {` ${constVariables.AllProductsMenu.selected}`}
                      </span>
                    </div>
                  </button>
                  <button className="btn btn-outlined-primary btn-xs me-2">{constVariables.ProductsTab.editProducts}</button>
                  <div>
                    <button className="btn btn-outlined-primary btn-xs me-2">{constVariables.ProductsTab.moreActions}</button>
                  </div>
                  <button className="btn btn-outlined-secondary btn-xs me-2" onClick={handleDeselectAll}>
                    {constVariables.ProductsTab.deselectAll}
                  </button>
                  {selectedTab === constVariables.Tabs.allProduct && (
                    <button className="btn btn-xs btn-primary me-2" onClick={(e) => handleChangeProductStatus(true)}>
                      {constVariables.ProductsTab.addToProducts}({onlyUnSelected?.length})
                    </button>
                  )}
                  {selectedTab === constVariables.Tabs.selectedProduct && (
                    <button className="btn btn-xs btn-danger me-2" onClick={(e) => handleChangeProductStatus(false)}>
                      {constVariables.ProductsTab.deleteFromProducts}({selectedVendorProducts.length})
                    </button>
                  )}
                </FlexBox>
              </>
            )}
            <div className={`pt-3 ${deviceName.isWeb ? '' : 'overflow-scroll'}`}>
              <Table
                headerClassName={tableData.headerClassName}
                isDragDisabled={tableData.isDragDisabled}
                headers={tableData.headers}
                rows={tableData.rows}
                sortByCategory={tableData.sortByCategory}
                sortByType={tableData.sortByType}
                // className={`${!deviceName.isWeb && tableData?.rows?.length ? 'vw-87' : ''}`}
              />
            </div>
          </div>
          <Pagination skipCount={skipCount} limitCount={limitCount} setSkipCount={setSkipCount} total={totalVendors} />
        </div>
      </div>
    </div>
  );
};

export default VendorAllProductsPresentational;
