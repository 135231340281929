import React, { useEffect, useMemo, useState } from 'react';
import ProgressBar from '@ramonak/react-progress-bar';
import Lottie from 'react-lottie';

// Components
import { FlexBox } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { priceFormat } from 'src/utils/priceFormat';
import useWindowDimensions from 'src/utils/UseWindowDimensions/useWindowDimensions';

// Icons && Assets
import { HeartFill, EditGoalIcon } from 'src/assets/icons';
import { HeartAnimation } from 'src/assets/lottie';

// Styles
import './_orderMetric.scss';
export interface OrderMetricDataType {
  totalSales: number;
  targetGoal: number;
  paidOrders: number;
  profit: number;
  unpaidOrders: number;
  pendingRevenue: number;
  likes: number;
}

const OrderMetric: React.FC<OrderMetricDataType> = ({
  likes,
  paidOrders,
  pendingRevenue,
  profit,
  targetGoal,
  totalSales,
  unpaidOrders
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: HeartAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const [panelHeight, setPanelHeight] = useState(0);

  const { width, height } = useWindowDimensions();

  useEffect(() => {
    if (width >= 1280 && width <= 1920) {
      setPanelHeight((height / 14 - 3.42) / 2);
    }
    if (width > 1920 && width <= 2150) {
      setPanelHeight((height / 18 - 3.42) / 2);
    }
    if (width > 2150 && width <= 2560) {
      setPanelHeight((height / 20 - 3.42) / 2);
    }
    if (width > 2560 && width <= 3050) {
      setPanelHeight((height / 25 - 3.42) / 2);
    }
  }, [width, height]);

  const isShowHeartAnimation = useMemo(() => {
    if (likes) return true;
    return false;
  }, [likes]);

  return (
    <div className="background-panel h-100">
      <div className="analytics-board">
        <FlexBox className="pattern-image flex-column justify-content-between">
          <FlexBox className="total-sales flex-column">
            <span className="sales-title">Total Sales</span>
            <span className="sales-value">{priceFormat(totalSales)}</span>
          </FlexBox>
          <FlexBox className="target-goal justify-content-between align-items-end">
            <FlexBox className="title-wrapper flex-column">
              {/* <span className="goal-title">Target Goal</span>
              <FlexBox>
                <span className="goal-value me-2">{priceFormat(targetGoal)}</span>
                <img src={EditGoalIcon} alt="Edit Goal" />
              </FlexBox>
              <ProgressBar
                completed={70}
                isLabelVisible={false}
                barContainerClassName="bar-class"
                bgColor="#00A3FF"
                borderRadius="50px"
                height="8px"
                width="277px"
              /> */}
            </FlexBox>
            <FlexBox className="like-score position-relative flex-column align-items-center">
              <FlexBox className="align-items-center position-relative">
                <img className="heart-fill-icon position-absolute" src={HeartFill} alt="Like" height={40} width={40} />
                <div className={`heart-animation position-relative ${isShowHeartAnimation ? '' : 'opacity-0'}`}>
                  <Lottie options={defaultOptions} height={100} width={100} />
                </div>
              </FlexBox>
              <span className="score-count">{likes}</span>
            </FlexBox>
          </FlexBox>
          <FlexBox className="align-items-center justify-content-between">
            <FlexBox className="flex-column">
              <span className="order-title">PAID ORDERS</span>
              <span className="order-value">{paidOrders}</span>
            </FlexBox>
            <FlexBox className="flex-column">
              <span className="order-title">PROFIT</span>
              <span className="order-value">{priceFormat(profit)}</span>
            </FlexBox>
            <FlexBox className="flex-column">
              <span className="order-title">UNPAID ORDERS</span>
              <span className="order-value">{unpaidOrders}</span>
            </FlexBox>
            <FlexBox className="flex-column">
              <span className="order-title">PENDING REVENUE</span>
              <span className="order-value">{pendingRevenue}</span>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </div>
    </div>
  );
};

export default OrderMetric;
