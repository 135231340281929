import { gql } from '@apollo/client';

export const GET_UESR_ORGANIZATION = gql`
  query GetUserOrganisations @api(name: "productsAPI") {
    getUserOrganisations {
      id
      name
      streamConfig {
        performer {
          ingestEndpoint
          streamKey
        }
        shopperPlaybackURL
      }
      stripeAccountId
      stripeAccountPublishKey
      email
      logoUrl
    }
  }
`;
