import { FC, useState } from 'react';
import Board, { createTranslate } from 'react-trello';

// Components
import { SearchBar, Tabs } from 'src/components/molecules';
import PurchaseOrderCard from 'src/components/oraganisms/PurchaseOrderCard/PurchaseOrderCard';

// Hooks && Utils && Helpers
import { AddMyCard, boardStyles, CustomLaneHeader, TRANSLATION_TABLE } from './helper/allPurchaseOrderHelper';

// ConstVariables
import { constVariables } from 'src/constants';
import { CustomData } from './CustomData';

// Types
import { AllPurchaseOrderPresentationalProps } from './AllPurchaseOrder.types';

// Styles
import './allPurchaseOrder.scss';

const components = {
  LaneHeader: CustomLaneHeader,
  Card: PurchaseOrderCard,
  NewCardForm: AddMyCard
};

const customTranslation = createTranslate(TRANSLATION_TABLE);
const AllPurchaseOrderPresentational: FC<AllPurchaseOrderPresentationalProps> = ({ selectedTab, setSelectedTab }) => {
  const [boardData, setBoardData] = useState(CustomData);

  const updateBoard = (newData: any) => {
    setBoardData(newData);
  };

  return (
    <div className="mt-8">
      <Tabs
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        tabs={constVariables.allPurchaseOrders.TabForAllPruchaseOrder}
      />
      <div className="pb-6">
        <div className="p-8 mb-6 bg-white rounded columnContainer">
          <div className="ps-0 col-lg-4 col-sm-6 col-md-6">
            <SearchBar placeholder={'Search purchase orders'} />
          </div>
          <div className="d-flex pt-6 ps-0 w-100 columnSubContainer">
            <Board
              components={components}
              data={boardData}
              style={boardStyles}
              draggable
              laneDraggable={false}
              hideCardDeleteIcon={true}
              onDataChange={updateBoard}
              editable
              newCardTemplate={(props: any) => <button onClick={props.addCard}>Add Card</button>}
              t={customTranslation}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllPurchaseOrderPresentational;
