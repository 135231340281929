import { gql } from '@apollo/client';

export const ADD_AVALARA_TAX_CODE = gql`
  mutation SaveAvalaraSettings($input: AvalaraSettings!) @api(name: "productsAPI") {
    saveAvalaraSettings(input: $input) {
      id
      success
      message
    }
  }
`;
