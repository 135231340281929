import { gql } from '@apollo/client';

export const GET_PRODUCTS_V2 = gql`
  query GetAllProductV2($input: AllProductsInputV2) @api(name: "productsAPI") {
    getAllProductsV2(input: $input) {
      totalProducts
      products {
        __typename
        ... on AllProductsStoreOwnerOutputV2 {
          id
          name
          medias {
            productMediaId
            productMediaUrl
            productMediaPosition
            productMediaType
          }
          quantityInStock
          productCatagory
          productCategoryId
          productLocation {
            id
            name
          }
          vendor
          vendorId
          salePrice
          perItemCost
          status
          productTypeInfo {
            id
            name
          }
          tags {
            id
            name
            textColorCode
            backgroundColorCode
            createdAt
            updatedAt
          }
          sku
          barcode
          shippingPrice
          profit
          margin
          collections {
            id
            name
            product_assignment_rules
            rules_match
            type
          }
          productAvailableFrom
          variantCount
          variantLocationDetails {
            variantId
            variantSku
            variantBarcode
            variantTypes {
              variantTypeName
              variantTypeValue
            }
            variantMediaId
            variantMediaUrl
            variantMediaType
            variantLocation {
              id
              name
            }
            variantInventoryPositionQty
            variantAlertThreshold
            variantQuantity
            variantCommitedQuantity
            variantUsersQuantity
          }
          committedQuantity
          alertThreshold
          usersQuantity
          sensitive
          sellOnOutOfStock
          addSignatureConfirmation
          addShipmentInsurance
          isFinalSale
          dimensions
          taxCode
          retailPrice
          showRetailPrice
          autoAssignPrimaryVideo
        }
      }
    }
  }
`;
