import { FC } from 'react';

// Components
import { useLayout } from 'src/components/core';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { HamburgerIcon } from 'src/assets/icons';

const Hamburger: FC = () => {
  const { config } = useLayout();
  const { aside } = config;
  return (
    <>
      {aside.display && (
        <div className="align-items-center d-lg-none ms-n3 me-1" title="Show aside menu">
          <div className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px" id="kt_aside_mobile_toggle">
            <KTSVG path={HamburgerIcon} className="svg-icon-2x mt-1" />
          </div>
        </div>
      )}
    </>
  );
};

export default Hamburger;
