import { FC, useEffect, useState } from 'react';

// Apollo
import { useMutation, useQuery } from '@apollo/client';
import { GET_CATALOG_LIST } from 'src/apollo/queries';
import { DELETE_CATALOG, UPDATE_CATALOG } from 'src/apollo/mutations';

// Components
import { CustomModal, DeleteConfirmModal } from 'src/components/oraganisms';
import CatalogPagePresentational from './CatalogPage.presentational';

// Hooks && Utils && Helpers
import useTitle from 'src/utils/hooks/useTitle';
import { useToast } from 'src/utils/hooks/useToast';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { CatalogListDataType } from './CatalogPage.type';

const CatalogPage: FC = () => {
  useTitle('Catalogs');
  const { showToast } = useToast();
  const [selectedCatalogIDs, setSelectedCatalogIDs] = useState<Array<number>>([]);
  const [catalogsToDelete, setCataLogsToDelete] = useState<Array<number>>([]);
  const [catalogListState, setCatalogListState] = useState<CatalogListDataType[]>([]);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [pageInfo, setPageInfo] = useState({
    skipCount: 0,
    limitCount: 50
  });
  const [totalCount, setTotalCount] = useState<number>(0);

  const { refetch: catalogListRefetch, loading: isLoading } = useQuery(GET_CATALOG_LIST, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-and-network',
    variables: {
      input: {
        catalogIds: [],
        filters: { searchText: '' },
        pageInfo
      }
    },
    onCompleted: (catalogListData) => {
      if (catalogListData?.getCatalogList) {
        setCatalogListState(catalogListData?.getCatalogList?.catalogues);
        setTotalCount(catalogListData?.getCatalogList?.totalRecords);
      }
    }
  });

  const [deleteCatalog] = useMutation(DELETE_CATALOG, {
    onCompleted: (data) => {
      if (data?.deleteCatalog?.success) {
        catalogListRefetch();
        showToast({
          errorText:
            catalogsToDelete.length > 1
              ? `${catalogsToDelete.length} ${constVariables.Catalogs.tooltip.mainTitle}`
              : `${catalogsToDelete.length} ${constVariables.Catalogs.tooltip.secondTitle}`,
          message:
            catalogsToDelete.length > 1
              ? `${constVariables.Catalogs.tooltip.firstMessage} ${catalogsToDelete.length} ${constVariables.Catalogs.tooltip.secondMessage}`
              : `${constVariables.Catalogs.tooltip.firstMessage} ${catalogsToDelete.length} ${constVariables.Catalogs.tooltip.mainMessageTwo}`
        });
      }
    }
  });

  const [updateCatalogMutation] = useMutation(UPDATE_CATALOG, {
    onCompleted: (response) => {
      if (response) {
        const updatedCatalog = JSON.parse(response?.updateCatalog?.additionalData);
        const updatedCatalogs = catalogListState.map((catalog) => {
          if (catalog.id === updatedCatalog.id) {
            return { ...catalog, status: updatedCatalog.status };
          } else {
            return catalog;
          }
        });
        setCatalogListState(updatedCatalogs);
      }
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const onToggleCatalog = (catalogId: number, changeItTo: string) => {
    updateCatalogMutation({
      variables: {
        input: {
          id: catalogId,
          status: changeItTo.toUpperCase()
        }
      }
    });
  };
  const onDeleteCatalog = () => {
    deleteCatalog({
      variables: {
        input: {
          catalogIds: catalogsToDelete
        }
      }
    });
  };

  const openDelModal = () => {
    setShowDeleteConfirmModal(true);
  };

  useEffect(() => {
    catalogListRefetch();
  }, []);

  const closeDelModal = () => {
    document.body.style.overflow = 'unset';
    setShowDeleteConfirmModal(false);
  };

  return (
    <>
      <CatalogPagePresentational
        selectedCatalogIDs={selectedCatalogIDs}
        setSelectedCatalogIDs={setSelectedCatalogIDs}
        catalogsToDelete={catalogsToDelete}
        setCatalogsToDelete={setCataLogsToDelete}
        catalogListData={catalogListState}
        onDeleteCatalog={openDelModal}
        onToggleCatalog={onToggleCatalog}
        isLoading={isLoading}
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        totalCount={totalCount}
      />
      {showDeleteConfirmModal && (
        <CustomModal bodyClassname="w-90 w-md-150" show={showDeleteConfirmModal} closeModal={closeDelModal}>
          <DeleteConfirmModal
            title={
              catalogsToDelete.length > 1
                ? constVariables.common.deleteCatalog.title
                : constVariables.common.deleteCatalog.titleSingle
            }
            message={`${
              catalogsToDelete.length > 1
                ? `${constVariables.common.deleteCatalog.message} selected ${constVariables.common.deleteCatalog.secondMessage}`
                : `${constVariables.common.deleteCatalog.mainMessage}`
            } This can't be undone.`}
            actionBtnTitle={constVariables.common.deleteCatalog.action}
            cancelBtnTitle={constVariables.common.deleteCatalog.cancel}
            actionBtnHandler={onDeleteCatalog}
            cancelBtnHandler={closeDelModal}
          />
        </CustomModal>
      )}
    </>
  );
};

export default CatalogPage;
