import { FC } from 'react';

// Types
import { DividerProps } from './Divider.types';

// Styles
import './_divider.scss';

const Divider: FC<DividerProps> = ({ classname, style, vertical }) => {
  return <span className={`${vertical ? 'custom-vertical-divider' : 'custom-divider'} ${classname}`} style={style} />;
};

export default Divider;
