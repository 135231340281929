/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/prefer-default-export */
import { TypedDocumentNode, gql } from '@apollo/client';
import { IGetStoreAnalyticsInput, IStoreAnalytics } from 'src/components/pages/home/HomePage.type';

export const GET_STORE_ANALYTICS: TypedDocumentNode<
  { getStoreAnalytics: IStoreAnalytics },
  { input: IGetStoreAnalyticsInput }
> = gql`
  query GetStoreAnalytics($input: GetStoreAnalyticsInput!) @api(name: "authAPI") {
    getStoreAnalytics(input: $input) {
      id
      visitors {
        count
        percent
      }
      orders {
        count
        percent
      }
      revenue {
        count
        percent
      }
      profit {
        count
        percent
      }
      totalSales {
        count
        percent
      }
      channels {
        facebook
        shopperApp {
          total
          app
          live
        }
        webStore {
          total
          app
          live
        }
      }
      chart {
        keys
        values
      }
    }
  }
`;
