import React, { FC } from 'react';
import clsx from 'clsx';

// Components
import { Pagination, TableTree, SearchBar } from 'src/components/molecules';
import CollectionTable from './CollectionTable';

// Types
import { NodeModel } from 'src/components/molecules/TableTree/types';
import { collectionInputType } from './Collections.types';

// Styles
import './ProductCategory.scss';

interface ProductCategoryPresentationalProps {
  collectionData: NodeModel[];
  deleteNode(id: number): void;
  updateDndCollection(data: NodeModel[]): void;
  addCollection(droppable: boolean, text: string): void;
  editCollection(id: number, newName: string): void;
  sendParentId(id: number | string): void;
  currentParentId: number;
  totalCount: number;
  isLoading: boolean;
  showAllDetails: boolean;
  collectionInput: collectionInputType;
  setCollectionInput: React.Dispatch<React.SetStateAction<collectionInputType>>;
}

const ProductCategoryPresentational: FC<ProductCategoryPresentationalProps> = (props) => {
  const {
    collectionData,
    deleteNode,
    editCollection,
    updateDndCollection,
    addCollection,
    sendParentId,
    currentParentId,
    isLoading,
    totalCount,
    showAllDetails,
    collectionInput,
    setCollectionInput
  } = props;

  const handleSearch = (value) => {
    setCollectionInput((collectionInput) => {
      return {
        ...collectionInput,
        filters: {
          searchText: value
        }
      };
    });
  };

  return (
    <div>
      <div className="card h-100 mb-0 p-0">
        <div className="p-t-32 p-x-32">
          <div className="m-b-24 w-33">
            <SearchBar placeholder="Search for collections" onChange={handleSearch} />
          </div>
          <CollectionTable
            collectionData={collectionData}
            isLoading={isLoading}
            showAllDetails={showAllDetails}
            searchText={collectionInput.filters.searchText}
          />
        </div>
        <div className={clsx({ 'display-none': !isLoading && totalCount === 0 && !collectionInput.filters.searchText })}>
          <Pagination
            skipCount={collectionInput.pageInfo.skipCount}
            limitCount={collectionInput.pageInfo.limitCount}
            setSkipCount={(num: number) => {
              setCollectionInput((prev) => ({
                ...prev,
                pageInfo: {
                  ...prev.pageInfo,
                  skipCount: num
                }
              }));
            }}
            total={totalCount}
          />
        </div>
      </div>
      {/* <div className={` col-sm-12 col-md-12 col-lg-12 col-xl-8 pe-6`}>
        
      </div> */}
      {/* <div className={`col-sm-12 col-md-12 col-lg-12 col-xl-4 mt-7 mt-md-0`}>
        <div className="card px-7 pb-5">
          <TableTree
            collectionData={collectionData}
            deleteNode={deleteNode}
            editCollection={editCollection}
            updateDndCollection={updateDndCollection}
            addCollection={addCollection}
            sendParentId={sendParentId}
            currentParentId={currentParentId}
            collectionsDataRefetch={collectionsDataRefetch}
            skipCount={skipCount + 1}
            isLoading={isLoading}
          />
        </div>
      </div> */}
    </div>
  );
};

export default ProductCategoryPresentational;
