import { FC } from 'react';
import clsx from 'clsx';

// Components
import { FlexBox } from 'src/components/atoms';

interface AccountDropdownMenuProps {
  isShowDropdown: boolean;
  data: {
    id: number;
    name: string;
  }[];
  onSelect: (id: number) => void;
}

const AccountDropdownMenu: FC<AccountDropdownMenuProps> = ({ isShowDropdown, data, onSelect }) => {
  return (
    <div
      className={clsx(`menu menu-sub menu-column menu-rounded position-absolute p-0 account-menu-dropdown`, {
        show: isShowDropdown
      })}
      data-kt-menu="true"
    >
      <div className="dropdown-content">
        {data?.map((item, index) => {
          return (
            <div className="menu-item" key={index}>
              <a
                className={`dropdown-item menu-link mb-0`}
                onClick={() => {
                  onSelect(item.id);
                }}
              >
                <FlexBox className="w-100 justify-content-between align-items-center">
                  <div>{item.name}</div>
                </FlexBox>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AccountDropdownMenu;
