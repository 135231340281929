import { gql } from '@apollo/client';

export const INVITE_BACKSTORE_USER = gql`
  mutation InviteBackstoreUser($input: inviteBackstoreUser!) @api(name: "authAPI") {
    inviteBackstoreUser(input: $input) {
      success
      message
      userId
      additionalData
    }
  }
`;
