import { FC, useEffect, useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import clsx from 'clsx';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useQuery, useMutation } from '@apollo/client';
import { GET_SHOPIFY_ADMIN_BASE_URL } from 'src/apollo/queries';
import { BLOCK_CUSTOMER, DELETE_CUSTOMER, UPDATE_STORE_CREDIT } from 'src/apollo/mutations';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import {
  Dropdown,
  SearchBar,
  DataTable,
  CustomerTags,
  VipCustomerTooltip,
  FirstTimeCustomerTooltip,
  ShopifySyncTooltip
} from 'src/components/molecules';
import { AddOrSubtractModal, CustomModal, MoreActionDropdown } from 'src/components/oraganisms';
import ActionMenu from './components/ActionMenu';
import SortableTableHeader from 'src/components/molecules/DataTable/components/SortableTableHeader';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';
import { useToast } from 'src/utils/hooks/useToast';

// Icons
import { FirstTime, VipCustomerIcon, DefaultImage, ShopifyIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { ColumnType } from 'src/components/molecules/Table/Table.types';
import { CustomerListProps } from './CustomerList.types';
import { moreActionMenuItem } from '../MoreActionDropdown/MoreActionDropdown.types';

// Styles
import './_customerList.scss';

const CustomerList: FC<CustomerListProps> = ({
  customerList,
  selectedCustomerIDs,
  sortBy,
  searchString,
  setSortBy,
  setSelectedCustomerIDs,
  setSearchstring,
  onRefetchList,
  limitCount,
  isLoading,
  editColumnData,
  totalCount
}) => {
  const { showToast } = useToast();
  const [openSortDropdown, setOpenSortDropdown] = useState(false);
  const [storeCreditValue, setStoreCreditValue] = useState('');
  const [activeColumns, setActiveColumns] = useState<ColumnType[]>([]);
  const [showMoreAction, setShowMoreAction] = useState(false);
  const [showAddStoreCredit, setShowAddStoreCredit] = useState(false);
  const [showSubtractStoreCredit, setShowSubtractStoreCredit] = useState(false);

  const { data: { shopifyAdminBaseUrl } = { shopifyAdminBaseUrl: null } } = useQuery(GET_SHOPIFY_ADMIN_BASE_URL);

  const [deleteCustomer] = useMutation(DELETE_CUSTOMER, {
    onCompleted: (data) => {
      onRefetchList();
    }
  });
  const [blockCustomer] = useMutation(BLOCK_CUSTOMER, {
    onCompleted: () => {
      onRefetchList();
    }
  });

  const [addStoreCreditData] = useMutation(UPDATE_STORE_CREDIT, {
    onCompleted: () => {
      setSelectedCustomerIDs([]);
      onRefetchList();
    },
    onError: (error) => {
      console.error('error in adding store credit', error);
    }
  });

  const [subtractStoreCreditData] = useMutation(UPDATE_STORE_CREDIT, {
    onCompleted: () => {
      setSelectedCustomerIDs([]);
      onRefetchList();
    },
    onError: (error) => {
      console.error('error in subtracting store credit', error);
    }
  });

  const onAddStoreCredit = () => {
    addStoreCreditData({
      variables: {
        input: {
          type: 'CREDITED',
          amount: storeCreditValue,
          customerIds: selectedCustomerIDs
        }
      }
    }).then((res) => {
      if (res?.data?.manageStoreCredits?.success) {
        showToast({
          successText: `<span style="color: #1e2749;">$${storeCreditValue}<span> <span style="color:#50cd89;">added successfully</span> to customer’s store credit!`,
          message: ``
        });
      }
    });
  };

  const onSubtractStoreCredit = () => {
    subtractStoreCreditData({
      variables: {
        input: {
          type: 'DEBITED',
          amount: storeCreditValue,
          customerIds: selectedCustomerIDs
        }
      }
    }).then((res) => {
      if (res?.data?.manageStoreCredits?.success) {
        showToast({
          successText: `<span style="color: #1e2749;">$${storeCreditValue}<span> <span style="color:#f1416c;">subtracted </span> from customer’s store credit.`,
          message: ``
        });
      }
    });
  };

  const onDeleteCustomer = (id: number) => {
    deleteCustomer({
      variables: {
        input: {
          userId: id
        }
      }
    });
  };

  const onBlockCustomer = (action: string, id: number) => {
    blockCustomer({
      variables: {
        input: {
          userId: id,
          userBlockAction: action
        }
      }
    });
  };

  // TO SELECT THE CUSTOMER FROM LIST
  const selectCustomer = useCallback(
    (e: any, customerId?: any) => {
      if (e === 'All') {
        setSelectedCustomerIDs(customerList.map((customer) => customer.id));
      } else if (e.target.checked) {
        setSelectedCustomerIDs([...selectedCustomerIDs, customerId]);
      } else {
        setSelectedCustomerIDs(selectedCustomerIDs.filter((customerItemId) => customerItemId !== customerId));
      }
    },
    [customerList, selectedCustomerIDs]
  );

  const onEnter = (data) => {
    if (data.key === 'Enter') {
      onRefetchList();
    }
  };

  const ChangeSortBy = (field: string, id: number) => {
    if (sortBy.catagory === field) {
      if (sortBy.type === 'DESC') {
        setSortBy({
          ...sortBy,
          type: 'ASC'
        });
      } else {
        setSortBy({
          ...sortBy,
          type: 'DESC'
        });
      }
    } else {
      setSortBy({
        catagory: field,
        id,
        type: 'DESC'
      });
    }
  };
  useEffect(() => {
    setSelectedCustomerIDs([]);
  }, [limitCount]);

  useEffect(() => {
    if (editColumnData?.length > 0) {
      const arr = Array.from(editColumnData);
      editColumnData.map((column) => {
        if (!column.status) {
          arr.splice(arr.indexOf(column), 1);
        }
        return null;
      });
      setActiveColumns([...arr]);
      localStorage.setItem('customerTable', JSON.stringify(editColumnData));
    }
  }, [editColumnData]);

  const goToShopifyPage = (shopifyCustomerId) => {
    if (shopifyAdminBaseUrl) window.open(`${shopifyAdminBaseUrl}customers/${shopifyCustomerId}`, '_blank');
  };

  const initialColumnOptions = [
    {
      dataKey: 'checkbox',
      name: 'Checkbox',
      width: 50,
      headerRenderer: () => {
        return (
          <div className="form-check form-check-sm form-check-custom">
            <input
              className="ms-2 me-2 form-check-input widget-9-check"
              type="checkbox"
              checked={customerList.length === selectedCustomerIDs.length}
              onChange={() => {
                if (customerList.length === selectedCustomerIDs.length && customerList.length > 0) {
                  setSelectedCustomerIDs([]);
                } else {
                  selectCustomer('All');
                }
              }}
            />
          </div>
        );
      },
      cellRenderer: ({ rowData }) => {
        return (
          <div className="form-check form-check-sm form-check-custom">
            <input
              className="ms-2 me-2 form-check-input widget-9-check"
              type="checkbox"
              checked={selectedCustomerIDs?.includes(rowData.id)}
              onChange={(e) => {
                selectCustomer(e, rowData.id);
              }}
              onClick={(e) => {
                selectCustomer(e, rowData.id);
              }}
            />
          </div>
        );
      },
      staticColumn: true
    },
    {
      dataKey: 'name',
      name: 'Customer',
      width: 450,
      headerRenderer: () => {
        return <SortableTableHeader label="Customer" sortHeader={() => ChangeSortBy('NAME', 1)} type={sortBy.type} />;
      },
      cellRenderer: ({ rowData }) => {
        return (
          <FlexBox className="align-items-center customer-email-name">
            <div className="d-flex align-items-center justify-content-center symbol me-5">
              <Img
                className="rounded-circle objectFit"
                src={setImageSrc(
                  rowData?.profilePicture ? generateCompressedImageURL(rowData?.profilePicture, '50') : DefaultImage
                )}
                errorImg={DefaultImage}
                placeholderImg={DefaultImage}
                height={40}
                width={40}
              />
              {rowData?.shopifyId && (
                <>
                  <img
                    src={ShopifyIcon}
                    className="cursor-pointer position-absolute shopify-icon"
                    data-tip
                    data-for="shopifySyncIcon"
                    onClick={() => goToShopifyPage(rowData?.shopifyId)}
                  />
                  <ShopifySyncTooltip tooltipText="Customer from Shopify" />
                </>
              )}
            </div>
            <div>
              <FlexBox className="align-items-center">
                <Link to={`/customers/customerDetails/${rowData.id}`} className="customer-name-cell">
                  <span className="d-block">{rowData.name}</span>
                </Link>
              </FlexBox>
              <div>{rowData.email}</div>
            </div>
            {rowData.isFirstTimeCustomer && (
              <>
                <img src={FirstTime} className="cursor-pointer mx-4 first-time-icon" data-tip data-for="firstTimeCustomerIcon" />
                <FirstTimeCustomerTooltip />
              </>
            )}
            {rowData.isVIPCustomer && (
              <>
                <img src={VipCustomerIcon} className="cursor-pointer mx-4 vip-icon" data-tip data-for="vipCustomerIcon" />
                <VipCustomerTooltip />
              </>
            )}
          </FlexBox>
        );
      },
      staticColumn: true
    },
    {
      dataKey: 'status',
      name: 'Status',
      width: 120,
      cellRenderer: ({ rowData }) => {
        return (
          <div
            className={clsx('px-5 d-flex flex-center poppins-medium', {
              'active-status-box': !rowData.isBlockedForLive,
              'inactive-status-box': rowData.isBlockedForLive
            })}
          >
            {rowData.isBlockedForLive ? 'Blocked' : 'Active'}
          </div>
        );
      }
    },
    {
      dataKey: 'phoneNumber',
      name: 'Phone number',
      width: 150,
      cellRenderer: ({ rowData }) => {
        return <FlexBox className="align-items-center">{rowData.phoneNumber}</FlexBox>;
      }
    },
    {
      dataKey: 'numberOfOrders',
      name: 'Orders',
      width: 120,
      headerRenderer: () => {
        return <SortableTableHeader label="Orders" sortHeader={() => ChangeSortBy('ORDERS_COUNT', 2)} type={sortBy.type} />;
      },
      cellRenderer: ({ rowData }) => {
        return <FlexBox className="align-items-center">{rowData.numberOfOrders}</FlexBox>;
      }
    },
    {
      dataKey: 'totalAmountSpent',
      name: 'Orders',
      width: 120,
      headerRenderer: () => {
        return <SortableTableHeader label="Total" sortHeader={() => ChangeSortBy('TOTAL_AMOUNT_SPENT', 3)} type={sortBy.type} />;
      },
      cellRenderer: ({ rowData }) => {
        return <FlexBox className="align-items-center">${rowData.totalAmountSpent}</FlexBox>;
      }
    },
    {
      dataKey: 'joinedOn',
      name: 'Joined',
      width: 150,
      headerRenderer: () => {
        return <SortableTableHeader label="Joined" sortHeader={() => ChangeSortBy('JOINED_DATE', 4)} type={sortBy.type} />;
      },
      cellRenderer: ({ rowData }) => {
        return <FlexBox className="align-items-center">{moment(rowData.joinedOn).format('DD/MM/YYYY')}</FlexBox>;
      }
    },
    {
      dataKey: 'locations',
      name: 'Location',
      width: 200,
      headerRenderer: () => {
        return <SortableTableHeader label="Location" sortHeader={() => ChangeSortBy('LOCATION', 5)} type={sortBy.type} />;
      },
      cellRenderer: ({ rowData }) => {
        return (
          <FlexBox className="align-items-center">
            <div className="customer-location-cell me-3">{rowData.locations}</div>
          </FlexBox>
        );
      }
    },
    {
      dataKey: 'tags',
      name: 'Tags',
      width: 220,
      headerRenderer: () => {
        return <SortableTableHeader label="Tags" sortHeader={() => ChangeSortBy('TAGS', 6)} type={sortBy.type} />;
      },
      cellRenderer: ({ rowData }) => {
        return <div className="p-r-12">{rowData?.userTags ? <CustomerTags userTags={rowData?.userTags} /> : null}</div>;
      }
    },
    {
      dataKey: 'action',
      name: '',
      width: 50,
      cellRenderer: ({ rowData }) => {
        return (
          <ActionMenu
            id={rowData.id}
            isBlocked={rowData.isBlockedForLive}
            onDeleteCustomer={onDeleteCustomer}
            onBlockCustomer={onBlockCustomer}
          />
        );
      },
      staticColumn: true
    }
  ];

  const columnOptions = useMemo(() => {
    const activeColumnNames = activeColumns?.map((column) => column.name);
    // eslint-disable-next-line array-callback-return
    const filteredColumns = initialColumnOptions
      ?.filter((column) => {
        if (column.staticColumn || activeColumnNames?.includes(column?.name)) return true;
        return false;
      })
      .sort(function (a, b) {
        if (a.dataKey === 'action') return 1;
        return activeColumnNames.indexOf(a.name) - activeColumnNames.indexOf(b.name);
      });
    return filteredColumns;
  }, [activeColumns, selectedCustomerIDs, customerList, shopifyAdminBaseUrl]);

  const cancelButtonHandler = (e) => {
    setShowAddStoreCredit(false);
    setShowSubtractStoreCredit(false);
  };

  const addStoreCreditHandler = (e) => {
    onAddStoreCredit && onAddStoreCredit();
    setShowAddStoreCredit(false);
  };

  const subtractStoreCreditHandler = (e) => {
    onSubtractStoreCredit && onSubtractStoreCredit();
    setShowSubtractStoreCredit(false);
  };

  const moreActionMenus: Array<moreActionMenuItem[]> = [
    [
      {
        id: 1,
        text: 'Ignore buy comments',
        onClick: () => {}
      },
      {
        id: 2,
        text: 'Block selected',
        onClick: () => {}
      },
      {
        id: 3,
        text: 'Add store credit',
        onClick: () => setShowAddStoreCredit(true)
      },
      {
        id: 4,
        text: 'Subtract store credit',
        onClick: () => setShowSubtractStoreCredit(true)
      },
      {
        id: 5,
        text: 'Delete selected',
        onClick: () => {},
        deleteOption: true
      }
    ]
  ];

  const closeModal = () => {
    document.body.style.overflow = 'unset';
    setShowAddStoreCredit(false);
    setShowSubtractStoreCredit(false);
  };

  const closeMoreActionDropdown = () => {
    setShowMoreAction(false);
  };

  return (
    <>
      {(showAddStoreCredit || showSubtractStoreCredit) && (
        <CustomModal bodyClassname="w-90 w-md-150" show={showAddStoreCredit || showSubtractStoreCredit} closeModal={closeModal}>
          <AddOrSubtractModal
            isSubtract={showSubtractStoreCredit}
            selectedTitle={`${selectedCustomerIDs.length} customers selected`}
            title={showAddStoreCredit ? `Add Store Credit` : `Subtract store credit`}
            message={
              showAddStoreCredit
                ? `Enter store credit value to be added to selected customers`
                : `Enter store credit value to be Subtract to selected customers`
            }
            value={storeCreditValue}
            setValue={setStoreCreditValue}
            actionBtnTitle={showAddStoreCredit ? `Add` : `Subtract`}
            cancelBtnTitle="Cancel"
            actionBtnHandler={showAddStoreCredit ? addStoreCreditHandler : subtractStoreCreditHandler}
            cancelBtnHandler={showAddStoreCredit ? cancelButtonHandler : cancelButtonHandler}
          />
        </CustomModal>
      )}
      <div>
        <div className={clsx({ 'display-none': totalCount === 0 && !searchString })}>
          <FlexBox className="justify-content-between w-100">
            <SearchBar
              placeholder="Search for Customers"
              onChange={(data) => {
                setSearchstring(data.trim());
              }}
              onKeyDown={onEnter}
            />
            {/* <FlexBox className="pb-1">
            <div className="position-relative">
              <OutsideClickHandler
                onOutsideClick={() => {
                  if (openSortDropdown) setOpenSortDropdown(false);
                }}
              >
                <button
                  className={`btn optBtn btn-sm btn-flex btn-light fw-bolder ${
                    openSortDropdown ? 'btn-primary' : 'btn-active-primary'
                  }`}
                  onClick={() => {
                    setOpenSortDropdown(!openSortDropdown);
                  }}
                >
                  <KTSVG path={SortIcon} className="svg-icon-8 svg-icon-gray-500 me-1" />
                  <span className="my-auto me-0 poppins-semibold f-14px">Sort</span>
                </button>
                <SortDropdown data={SortIds} value={sortBy} onSelect={changeSortBy} selected={openSortDropdown} closeDropdown={() => setOpenSortDropdown(false)} />
              </OutsideClickHandler>
            </div>
            <button className="btn btn-sm btn-flex btn-light btn-active-primary fw-bolder ms-3">
              <KTSVG path={FilterIcon} className="svg-icon-6 svg-icon-gray-500 me-1" />
              <span className="my-auto me-0 poppins-semibold f-14px">Filter</span>
            </button>
          </FlexBox> */}
          </FlexBox>
        </div>

        {selectedCustomerIDs.length > 0 && (
          <FlexBox className="m-t-24 justify-content-start align-items-center">
            <button className="btn btn-outlined-primary btn-sm me-2" onClick={() => setSelectedCustomerIDs([])}>
              <div className="d-flex align-items-center">
                <input
                  className={'form-check-input small-checkbox-size shadow-none opacity-100 bg-primary rounded-sm m-0'}
                  type="checkbox"
                  disabled={true}
                  ref={(input) => {
                    if (input) {
                      input.indeterminate = true;
                    }
                  }}
                />
                <span className="ms-2">{selectedCustomerIDs.length} Selected</span>
              </div>
            </button>
            <div className="position-relative">
              <button className="btn btn-outlined-primary btn-sm me-2" onClick={() => setShowMoreAction(true)}>
                {constVariables.Catalogs.moreActions}
              </button>
              <OutsideClickHandler onOutsideClick={closeMoreActionDropdown}>
                <MoreActionDropdown selected={showMoreAction} options={moreActionMenus} closeDropdown={closeMoreActionDropdown} />
              </OutsideClickHandler>
            </div>
          </FlexBox>
        )}
        <div className="p-t-12">
          <DataTable
            page="customer"
            rowData={customerList}
            columnOptions={columnOptions}
            isLoading={isLoading}
            searchText={searchString}
            selectedIDs={selectedCustomerIDs}
            selectedIndex={-1}
          />
        </div>
      </div>
    </>
  );
};

export default CustomerList;
