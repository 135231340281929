import { gql } from '@apollo/client';

export const CLEAR_USER_CART = gql`
  mutation ClearUserCart($userId: Int!) @api(name: "productsAPI") {
    clearUserCart(userId: $userId) {
      success
      message
    }
  }
`;
