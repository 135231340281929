import React, { useState } from 'react';
import { FlexBox } from 'src/components/atoms';
import { VideoControllerDropDown, VideoControllerDropDownActive } from 'src/assets/icons';
import './_videoControllerButton.scss';
import OutsideClickHandler from 'react-outside-click-handler';

interface VideoControllerButtonProps {
  sources?: Array<any>;
  image: string;
  onClick?: () => void;
  isMuted?: boolean;
  activeDevice?: any;
  renderActiveDevice?: (isVisible: boolean) => void;
  small?: boolean;
}

const VideoControllerButton: React.FC<VideoControllerButtonProps> = ({
  sources,
  image,
  onClick,
  isMuted,
  activeDevice,
  renderActiveDevice,
  small
}) => {
  const [isDropDownVisible, setIsDropDownVisible] = useState<boolean>(false);

  const toggleDropDown = () => {
    if (!sources?.length) {
      return;
    }
    setIsDropDownVisible((prev) => !prev);
  };

  const closeDropdown = () => {
    setIsDropDownVisible(false);
  };

  const handleDeviceSelect = (deviceId: any) => {
    if (!sources?.length) {
      return;
    }
    const device = sources?.find((device) => device.deviceId === deviceId);

    activeDevice.current = device;

    if (renderActiveDevice) {
      renderActiveDevice(!isMuted);
    }

    closeDropdown();
  };

  return (
    <FlexBox className={small ? 'small-video-controller-wrapper' : 'video-controller-wrapper'}>
      <div
        className={
          isMuted
            ? `${small ? 'small-controller-wrapper-muted' : 'controller-wrapper-muted'}`
            : `${small ? 'small-controller-wrapper' : 'controller-wrapper'}`
        }
        onClick={onClick}
      >
        <img src={image} alt="controller" />
      </div>
      {sources?.length && (
        <div className="drop-down-container">
          <img
            onClick={toggleDropDown}
            src={isDropDownVisible ? VideoControllerDropDownActive : VideoControllerDropDown}
            alt="dropdown"
            height={7}
            width={16}
          />

          <OutsideClickHandler onOutsideClick={closeDropdown}>
            <div className="drop-down-controller">
              {isDropDownVisible &&
                sources?.map((device) => (
                  <div key={device.deviceId} className="drop-down-option" onClick={() => handleDeviceSelect(device.deviceId)}>
                    {device.deviceId === activeDevice.current.deviceId ? (
                      <span className="active-device">{device.label}</span>
                    ) : (
                      <span>{device.label}</span>
                    )}
                  </div>
                ))}
            </div>
          </OutsideClickHandler>
        </div>
      )}
    </FlexBox>
  );
};

export default VideoControllerButton;
