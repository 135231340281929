/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { useMutation } from '@apollo/client';

// Apollo
import { ADD_LIVE_STORE_CREDIT, BLOCK_USER_FROM_COMMENTS, DUMP_CART } from 'src/apollo/mutations';

// Components
import { AddOrSubtractModal, CustomModal, DeleteConfirmModal, LeaveLiveShowConfirmModal } from 'src/components/oraganisms';
import { LiveshowCartDetails } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { useToast } from 'src/utils/hooks/useToast';

// Icons
import { RemoveCartIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { ILiveUser } from './LiveshowManager.types';

export interface LivsDashboardCustomModalsRefMethods {
  onUserUpdated: (user: ILiveUser) => void;
  onEndShow: () => void;
  onBlockUser: (user: ILiveUser) => void;
  onAddStoreCredit: (user: ILiveUser) => void;
  onShowCart: (user: ILiveUser) => void;
  onDumpCart: (user: ILiveUser) => void;
  onDeleteComment: (commentId: string) => void;
}

interface LivsDashboardCustomModalsProps {
  endLoading: boolean;
  handleStopStream: () => void;
  onUserDetailsUpdated: (user: ILiveUser) => void;
  handleDeleteComment: (commentId: string) => void;
  liveShowId: string;
}

enum ModalType {
  END_STREAM_WARNING = 'END_STREAM_WARNING',
  BLOCK_USER_FROM_LIVE_STREAM_WARNING = 'BLOCK_USER_FROM_LIVE_STREAM_WARNING',
  DELETE_COMMENT_WARNING = 'DELETE_COMMENT_WARNING',
  ADD_STORE_CREDIT_WARNING = 'ADD_STORE_CREDIT_WARNING',
  USER_CART = 'USER_CART',
  DUMP_CART_WARNING = 'DUMP_CART_WARNING'
}

const LivsDashboardCustomModals = forwardRef<LivsDashboardCustomModalsRefMethods, LivsDashboardCustomModalsProps>((props, ref) => {
  const { liveShowId, endLoading, handleStopStream, onUserDetailsUpdated, handleDeleteComment } = props;

  const { showToast } = useToast();

  const [modalType, setModalType] = useState<ModalType | null>(null);
  const [userDetails, setUserDetails] = useState<ILiveUser | null>(null);
  const [commentId, setCommentId] = useState<string | null>(null);
  const [storeCreditValue, setStoreCreditValue] = useState('');
  const [isShowUserCart, setIsShowUSerCart] = useState(false);

  useImperativeHandle(ref, () => ({
    onEndShow: () => {
      setModalType(ModalType.END_STREAM_WARNING);
    },
    onBlockUser: (user) => {
      setUserDetails(user);
      setModalType(ModalType.BLOCK_USER_FROM_LIVE_STREAM_WARNING);
    },
    onDeleteComment: (commentId) => {
      setCommentId(commentId);
      setModalType(ModalType.DELETE_COMMENT_WARNING);
    },
    onAddStoreCredit: (user) => {
      setUserDetails(user);
      setModalType(ModalType.ADD_STORE_CREDIT_WARNING);
    },
    onShowCart: (user) => {
      setUserDetails(user);
      setIsShowUSerCart(true);
      setModalType(ModalType.USER_CART);
    },
    onDumpCart: (user) => {
      setUserDetails(user);
      setModalType(ModalType.DUMP_CART_WARNING);
    },
    onUserUpdated: (user) => {
      if (userDetails && +user?.id === +userDetails?.id) {
        setUserDetails(user);
      }
    }
  }));

  const [blockViewer] = useMutation(BLOCK_USER_FROM_COMMENTS, {
    onCompleted: (response) => {
      if (response.blockUserFromComments.success) {
        onUserDetailsUpdated({ ...userDetails!, isLiveBlocked: !userDetails?.isLiveBlocked });
      }

      if (response.blockUserFromComments.success) {
        const msg = response.blockUserFromComments.message;
        if ('User unblocked' === response.blockUserFromComments.message) {
          showToast({ successText: msg, message: msg });
        } else {
          showToast({ errorText: msg, message: msg });
        }
      }
    },
    onError: (error) => {
      console.log('error', error);
      showToast({ errorText: 'Error', message: `Error in blocking user` });
    }
  });

  const [addLiveStoreCredit] = useMutation(ADD_LIVE_STORE_CREDIT, {
    onCompleted: (response) => {
      if (response.addLiveStoreCredit.success) {
        showToast({
          successText: `<span style="color: #1e2749;">$${storeCreditValue} has been <span> <span style="color: #50cd89;">added successfully</span> to ${userDetails?.firstName}!`,
          message: '',
          width: 500
        });
      }
    },
    onError: (error) => {
      showToast({ errorText: 'Error', message: `Error in Adding storecredit` });
      console.log('error', error);
    }
  });

  const [dumpCart] = useMutation(DUMP_CART, {
    onCompleted: (response) => {
      if (response.dumpCart.success) {
        showToast({
          successText: `<span style="color: #1e2749;">${constVariables.LiveShow.dumpViewer.cartHasBeen} <span> <span style="color:#f1416c;">dumped!</span>`,
          message: ``
        });
      }
    },
    onError: (error) => {
      console.log({ error });
      showToast({ errorText: 'Error', message: `Error in dumping cart` });
    }
  });

  const onCloseModal = () => {
    document.body.style.overflow = 'unset';
    if (isShowUserCart) {
      setModalType((d) => ModalType.USER_CART);
    } else {
      setModalType(null);
      setUserDetails(null);
      setIsShowUSerCart(false);
    }
  };

  const handleBlock = () => {
    setModalType(null);
    setUserDetails(null);
    setIsShowUSerCart(false);
    blockViewer({
      variables: {
        input: {
          userId: userDetails?.id,
          action: userDetails?.isLiveBlocked ? 'UNBLOCK' : 'BLOCK'
        }
      }
    });
  };

  const onHandleDeleteComment = () => {
    handleDeleteComment(commentId!);
    onCloseModal();
    setCommentId(null);
  };

  const handleAddStoreCreditHandler = () => {
    addLiveStoreCredit({
      variables: {
        input: {
          liveShowId,
          amountToAdd: storeCreditValue,
          customerId: parseInt(`${userDetails?.id}`, 10)
        }
      }
    });
    onStoreCreditComplete();
  };

  const handleDumpCart = () => {
    onCloseModal();
    dumpCart({
      variables: {
        input: {
          liveShowId,
          customerId: parseInt(`${userDetails?.id}`, 10)
        }
      }
    });
  };

  const onStoreCreditComplete = () => {
    setStoreCreditValue('');
    onCloseModal();
  };

  const onBlockUser = useCallback(() => {
    setModalType((d) => ModalType.BLOCK_USER_FROM_LIVE_STREAM_WARNING);
  }, []);
  const onDumpUserCart = useCallback(() => setModalType(ModalType.DUMP_CART_WARNING), []);
  const onAddStoreCredit = useCallback(() => setModalType(ModalType.ADD_STORE_CREDIT_WARNING), []);

  const closeUserCart = () => {
    document.body.style.overflow = 'unset';
    setIsShowUSerCart(false);
    setModalType(null);
  };

  return (
    <CustomModal bodyClassname="d-flex justify-content-center w-md-150" show={!!modalType} closeModal={onCloseModal}>
      <div>
        {modalType === ModalType.END_STREAM_WARNING && (
          <LeaveLiveShowConfirmModal isEndLoading={endLoading} handleConfirm={handleStopStream} closeModal={onCloseModal} />
        )}
        {modalType === ModalType.BLOCK_USER_FROM_LIVE_STREAM_WARNING && (
          <DeleteConfirmModal
            title={`Are you sure you want to ${userDetails?.isLiveBlocked ? 'unblock' : 'block'} viewer?`}
            actionBtnTitle="Yes"
            cancelBtnTitle="No"
            cancelBtnHandler={onCloseModal}
            actionBtnHandler={handleBlock}
            noDeleteIcon={true}
          ></DeleteConfirmModal>
        )}
        {modalType === ModalType.DELETE_COMMENT_WARNING && (
          <DeleteConfirmModal
            title={`Are you sure you want to delete this comment?`}
            actionBtnTitle="Yes"
            cancelBtnTitle="No"
            cancelBtnHandler={onCloseModal}
            actionBtnHandler={onHandleDeleteComment}
            noDeleteIcon={true}
          ></DeleteConfirmModal>
        )}
        {modalType === ModalType.ADD_STORE_CREDIT_WARNING && (
          <AddOrSubtractModal
            title="Add store credit"
            message="Enter store credit value to be added to Selected viewers"
            value={storeCreditValue}
            setValue={setStoreCreditValue}
            actionBtnTitle="Add"
            cancelBtnTitle="Cancel"
            actionBtnHandler={handleAddStoreCreditHandler}
            cancelBtnHandler={onStoreCreditComplete}
          />
        )}
        {modalType === ModalType.DUMP_CART_WARNING && (
          <DeleteConfirmModal
            title={constVariables.LiveShow.dumpViewer.title}
            actionBtnTitle="Yes"
            cancelBtnTitle="No"
            cancelBtnHandler={onCloseModal}
            actionBtnHandler={handleDumpCart}
            deleteIcon={RemoveCartIcon}
          />
        )}
        {modalType === ModalType.USER_CART && (
          <LiveshowCartDetails
            closeModal={closeUserCart}
            liveShowId={liveShowId}
            userId={`${userDetails?.id}`}
            customer={userDetails!}
            setIsBlockConfirm={onBlockUser}
            setIsCreditStore={onAddStoreCredit}
            setShowDumpConfirm={onDumpUserCart}
          />
        )}
      </div>
    </CustomModal>
  );
});

export default LivsDashboardCustomModals;
