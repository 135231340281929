import { FC, useState } from 'react';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_BS_USER_PROFILE } from 'src/apollo/queries';

// Components
import AddNotesPresentational from './AddNotes.presentational';

// Types
import { NotesSection } from 'src/components/pages/products/AddProduct/AddProduct.types';

export interface AddNotesProps extends NotesSection {
  addNewNote?: (newNoteData: string) => void;
  id?: number;
  editOldNote?: (oldNoteData: string, noteId: number) => void;
  deleteOldNote?: (noteId: number) => void;
}
export interface UserInfo {
  name: string;
  imageUrl: string;
}

const AddNotes: FC<AddNotesProps> = ({ deleteOldNote, editOldNote, id, notesData, setNotesData, addNewNote }) => {
  const [user, setUser] = useState<UserInfo>();
  const [newNote, setNewNote] = useState<string>('');
  const [editText, setEditText] = useState<string>('');
  const [getNotesId, setNotesId] = useState<number>(-1);
  const [editNotesId, setEditNotesId] = useState<number>();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [deleteNotesId, setDeleteNotesId] = useState<number>();
  const [deleteNotes, setDeleteNotes] = useState<number | null>();

  useQuery(GET_BS_USER_PROFILE, {
    onCompleted: (data) => {
      if (data.getBackstoreUserProfile) {
        setUser({
          name: data.getBackstoreUserProfile.firstName,
          imageUrl: data.getBackstoreUserProfile.profileURL
        });
      }
    }
  });
  const handleAddNewNote = (notes: string) => {
    setNewNote(notes);
  };
  const handleCancelNote = () => {
    setEditText('');
    setNotesId(-1);
  };

  const getText = (e: string) => {
    setEditText(e);
  };
  const handleEditNote = (id: number, noteId: number | undefined) => {
    setNotesId(id);
    setEditNotesId(noteId);
    setEditText(notesData[id].note);
  };
  const handelSendNotes = () => {
    if (newNote) {
      if (id) {
        if (addNewNote) {
          addNewNote(newNote);
        }
      } else {
        setNotesData([
          ...notesData,
          {
            id: undefined,
            imageUrl: user?.imageUrl,
            note: newNote,
            updatedAt: new Date().toISOString(),
            userName: user?.name
          }
        ]);
      }
      setNewNote('');
    }
  };

  const handleSaveEditedNote = () => {
    if (editText) {
      if (id) {
        if (editNotesId && editOldNote) {
          editOldNote(editText, editNotesId);
        }
      } else {
        setNotesData([
          ...notesData.slice(0, getNotesId),
          { ...notesData[getNotesId], note: editText },
          ...notesData.slice(getNotesId + 1)
        ]);
      }
      handleCancelNote();
    }
  };
  const onEnterSaveNote = (e: any) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      if (newNote !== '') {
        handelSendNotes();
      }
    }
  };
  const handleDeleteNote = (id: number, noteId: number | undefined) => {
    setIsDeleteModal(true);
    setDeleteNotes(id);
    setDeleteNotesId(noteId);
  };
  const handleConfirmDelete = () => {
    if (id) {
      if (deleteNotesId && deleteOldNote) {
        deleteOldNote(deleteNotesId);
      }
    } else {
      if (deleteNotes !== null) {
        setNotesData(notesData.filter((data, index) => index !== deleteNotes));
        setDeleteNotes(null);
      }
    }
    setIsDeleteModal(false);
  };
  const closeModal = () => {
    document.body.style.overflow = 'unset';
    setIsDeleteModal(false);
  };
  // const sendUpdatedNotes = (updatedNotes: Array<string>) => {
  //     setAllNotes(updatedNotes);
  // };

  return (
    <AddNotesPresentational
      NotesData={notesData}
      newNote={newNote}
      isDeleteModal={isDeleteModal}
      editText={editText}
      handelSendNotes={handelSendNotes}
      getNotesId={getNotesId}
      handleAddNewNote={handleAddNewNote}
      onEnterSaveNote={onEnterSaveNote}
      handleDeleteNote={handleDeleteNote}
      closeModal={closeModal}
      handleConfirmDelete={handleConfirmDelete}
      handleEditNote={handleEditNote}
      handleCancelNote={handleCancelNote}
      getText={getText}
      handleSaveEditedNote={handleSaveEditedNote}
    />
  );
};

export default AddNotes;
