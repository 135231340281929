import { gql } from '@apollo/client';

export const MARK_ORDER_SCANNED = gql`
  mutation markOrderScanned($input: OrderScanInput!) @api(name: "ordersAPI") {
    markOrderScanned(input: $input) {
      orderDetailId
      itemId
      isScanned
    }
  }
`;
