import React from 'react';
import { Modal } from 'react-responsive-modal';

// Icons
import { TrashIcon } from 'src/assets/icons';

// Styles
import 'react-responsive-modal/styles.css';
import './CustomModalPopup.scss';

interface CustomModalPopupPresentationalProps {
  title?: string;
  icon?: boolean;
  message?: string;
  cancelButton?: string;
  confirmButton?: string;
  open: boolean;
  handleConfirm(e: React.MouseEvent): void;
  closeModal(): void;
  type?: string;
}

const CustomModalPopupPresentational: React.FC<CustomModalPopupPresentationalProps> = (props) => {
  const { title, message, cancelButton, confirmButton, open, icon, handleConfirm, closeModal, type } = props;

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        center
        closeOnOverlayClick={false}
        classNames={{
          modal: 'modalCls'
        }}
        showCloseIcon={false}
        styles={{
          modalContainer: {
            padding: 0
          },
          modal: {
            padding: 0
          }
        }}
      >
        <div style={{ padding: '20px 0px' }}>
          {icon && <img src={TrashIcon} alt="delete" className="center icon mb-4" />}
          <div className="border-bottom py-5 ">
            <h5 className="text-center text-danger  titleText">{title}</h5>
          </div>

          <div className="border-bottom py-5 px-20">
            <p className="text-center  messageText">{message}</p>
          </div>
          <div className="d-flex align-items-center justify-content-end  text-center mt-10">
            <button
              className={`me-4 btn btn-secondary btn-md ${type === 'End relationship' ? '' : 'w-150px'}`}
              onClick={closeModal}
            >
              {cancelButton}
            </button>
            <button
              className={`me-4 btn btn-danger btn-md ${type === 'End relationship' ? '' : 'w-150px'}`}
              onClick={handleConfirm}
            >
              {confirmButton}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CustomModalPopupPresentational;
