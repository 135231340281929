import { useState, createRef, FC } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { Portal, Dropdown } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { ThreeDotsIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { MoreActionsProps } from './UserPermissions.type';

const MoreActions: FC<MoreActionsProps> = ({ user, userAction }) => {
  const moreActionRef = createRef<any>();
  const [showDropdown, setShowDropdown] = useState(false);

  const [dropDownPositions, setDropDownPositions] = useState({
    top: 0,
    left: 0
  });

  const handleOpenMoreActionDropdown = () => {
    setShowDropdown(true);
    if (moreActionRef.current) {
      const position = moreActionRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - position.bottom;
      setDropDownPositions({
        top: spaceBelow < 130 ? window.scrollY + position.top - 100 : window.scrollY + position.top + 30,
        left: position.left - 135
      });
    }
  };

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  const handleSelectOption = (option) => {
    userAction(option.id, option.name, user);
  };

  return (
    <>
      <button onClick={handleOpenMoreActionDropdown} className="bg-transparent border border-transparent" ref={moreActionRef}>
        <KTSVG path={ThreeDotsIcon} svgClassName="cursor-pointer collapseFilter collapseIcon" />
      </button>
      {showDropdown && (
        <Portal id="kt_body">
          <OutsideClickHandler
            onOutsideClick={() => {
              if (showDropdown) setShowDropdown(false);
            }}
          >
            <div className="position-absolute" style={{ top: dropDownPositions.top, left: dropDownPositions.left }}>
              <Dropdown
                data={constVariables.usersPermissionsDropdownData}
                selected={showDropdown}
                onSelect={handleSelectOption}
                closeDropdown={closeDropdown}
              />
            </div>
          </OutsideClickHandler>
        </Portal>
      )}
    </>
  );
};

export default MoreActions;
