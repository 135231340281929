import { FC } from 'react';
import clsx from 'clsx';

interface RecordingActionMenuProps {
  show: boolean;
  data: { id: number; name: string }[];
  onSelectOption: (e, id: number) => void;
}

const RecordingActionMenu: FC<RecordingActionMenuProps> = ({ show, data, onSelectOption }) => {
  return (
    <div className={clsx('dropdown-menu recording-action-options', { show })} aria-labelledby="dropdownMenuButton">
      {data.map((option, index) => (
        <div
          aria-labelledby="dropdownMenuButton"
          className="option-item cursor-pointer"
          key={index}
          onClick={(e) => onSelectOption(e, option.id)}
        >
          <span className="option-text poppins-medium">{option.name}</span>
        </div>
      ))}
    </div>
  );
};

export default RecordingActionMenu;
