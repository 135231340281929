import { gql } from '@apollo/client';

export const GET_CREATED_ORDER_SHIPMENTS = gql`
  query GetCreatedOrderShipments($input: getOrderShipmentsInput) @api(name: "ordersAPI") {
    getCreatedOrderShipments(input: $input) {
      id
      shipmentDate
      status
      totalAmount
      numberOfItems
    }
  }
`;
