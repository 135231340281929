import { Default, Delete, Duplicate, Pencil } from 'src/assets/icons';

export const constVariables = {
  MainMenuBar: {
    home: 'Home',
    liveShow: 'Liveshow',
    vendorsManagement: 'Vendors Management',
    products: 'Products',
    orders: 'Orders',
    analytics: 'Analytics',
    settings: 'Settings',
    marketing: 'Marketing',
    customer: 'Customers',
    coupons: 'Coupons'
  },
  SettingsScreen: {
    settings: 'Settings'
  },
  SideMenuBar: {
    allProducts: 'All Products',
    fulfillmentCenter: 'Fulfillment center',
    waitlists: 'Waitlists',
    allOrders: 'All Orders',
    allOrdersBadge: '52',
    catalogs: 'Catalogs',
    featuredProducts: 'Featured Products',
    inventory: 'Inventory',
    couponManagement: 'Coupons',
    productCategory: 'Product Category',
    returns: 'Returns',
    collections: 'Collections',
    importProduct: 'Import Product',
    warehouse: 'Warehouse',
    return: 'Return',
    sezzleConnect: 'Sezzle Connect',
    shippo: 'Shippo',
    allCustomer: 'All Customers',
    tiktokIntegration: 'Tiktok Integration'
  },
  liveShowBar: {
    calendar: 'Calendar',
    management: 'Management'
  },
  VendorManagementBar: {
    allVendors: 'All Vendors',
    allPurchaseOrders: 'All Purchase Orders'
  },
  BulkEditor: {
    selectCollection: 'selectCollection',
    selectStatus: 'selectStatus',
    selectType: 'selectType',
    selectTag: 'selectTag',
    showMoreTag: 'showMoreTag',
    changeIndividualStatus: 'changeIndividualStatus',
    salePrice: 'salePrice',
    retailPrice: 'retailPrice',
    isFinalSale: 'isFinalSale',
    freeShipping: 'freeShipping',
    addSignatureConfirmation: 'addSignatureConfirmation',
    addShipmentInsurance: 'addShipmentInsurance',
    sensitiveProduct: 'sensitive',
    margin: 'margin',
    perItemCost: 'perItemCost',
    taxCode: 'taxCode',
    shippingPrice: 'shippingPrice',
    status: 'status',
    profitPrice: 'profitPrice',
    productTypeInfo: 'productTypeInfo',
    primaryMedia: 'autoAssignPrimaryVideo'
  },
  Coupons: {
    coupons: 'Coupons',
    couponName: 'Coupon Code',
    helperText: 'Coupon code is required.',
    isAutoDiscount: 'Is Automatic Discount.',
    AllCouponMenu: {
      createCoupon: 'Create Coupon',
      searchForCoupons: 'Search for coupons',
      couponsCode: 'Coupons code',
      noTitle: 'Manage coupons and promotions',
      noDescription: 'Create coupon codes that apply at checkout.'
    },
    AddCoupon: {
      general: {
        createCouponCode: 'Create Coupon code',
        editCouponCode: 'Edit Coupons Code',
        generalPlaceHolder: 'Coupon Code',
        generalWarning: 'Coupon code can’t be left empty, please enter a name',
        automaticDiscount: 'Is this coupon an automatic discount?'
      },
      type: {
        couponType: 'Coupon Type',
        amountOffOrder: 'Amount off order',
        amountOffOrderKey: 'AMOUNT_OF_ORDER',
        amountOffProducts: 'Amount off products',
        amountOffProductsKey: 'AMOUNT_OF_PRODUCT',
        freeShipping: 'Free Shipping',
        freeShippingKey: 'FREE_SHIPPING',
        buyXGetY: 'Buy X Get Y',
        buyXGetYKey: 'BUY_X_GET_Y',
        buyXGetX: 'Buy X Get X',
        buyXGetXKey: 'BUY_X_GET_X'
      },
      minReq: {
        minReq: 'Minimum requirements',
        none: 'None',
        minAmount: 'Minimum purchase amount ($ - USD)',
        minQuant: 'Minimum quantity of items'
      },
      customerEligibility: {
        customerEligibility: 'Customer eligibility',
        everyone: 'Everyone',
        specificCustomerSegments: 'Specific customer segments',
        specificCustomers: 'Specific customers',
        searchForCustmoers: 'Search for customers',
        customers: 'Customers',
        searchForSegments: 'Search for tagments'
      },
      customerBuyGet: {
        customerBuys: 'Customer buys',
        customerGets: 'Customer gets',
        customerGetsDesc: 'Customers must add the quantity of items specified below to their cart.',
        minQuantityItems: 'Minimum quantity of items',
        minPurchaseAmount: 'Minimum purchase amount',
        quantity: 'Quantity',
        anyItemsFrom: 'Any items from'
      },

      countries: {
        countries: 'Countries',
        allCountries: 'All countries',
        selectedCountries: 'Selected countries',
        shippingRates: 'Shipping Rates',
        searchForCountries: 'Search for countries',
        shippingRatesDesc: 'Exclude shipping rates over a certain amount'
      },
      value: {
        value: 'Value',
        valuePlaceHolder: '$0.00',
        percentage: 'Percentage',
        fixedAmount: 'Fixed amount',
        productAssignmentMethod: 'Product Assignment method',
        manual: 'Manual',
        manualText:
          'Add products to this category one by one by manually selecting this category during product creation or update.',
        automatic: 'Automatic',
        automaticText: 'Products matched with the following conditions will be automatically assigned to this category.'
      },
      useageLimits: {
        usageLimits: 'Usage limits',
        usedOnce: 'Only used once per customer',
        usedForFirst: 'Only used for first purchase via any channel',
        usedForNew: 'Coupon only used for new users',
        limitNumber: 'Limit number of times this discount can be used in total'
      },
      couponChannel: {
        couponChannels: 'Coupon Channels',
        app: 'App',
        webstore: 'Webstore',
        facebook: 'Facebook'
      },
      summary: {
        summary: 'Summary',
        app: 'App',
        webstore: 'Webstore',
        facebook: 'Facebook',
        summaryInfo: 'Summary information',
        summaryInfoDesc: 'Please enter coupon details to view summary information'
      },
      collections: {
        collections: 'Collections'
      },
      status: {
        couponStatus: 'Coupon Status',
        activeDates: 'Active Dates',
        startDate: 'Start Date',
        startTime: 'Start Time',
        endDate: 'End Date',
        endTime: 'End Time',
        setEndDate: 'Set end date'
      }
    },
    channels: {
      channel1Title: 'App',
      channel1Key: 'MOBILE_STORE',
      channel2Title: 'Webstore',
      channel2Key: 'WEB_STORE',
      channel3Title: 'Facebook',
      channel3Key: 'FACEBOOK'
    },
    usageLimits: {
      option1Title: 'Only used once per customer',
      option1Key: 'ONCE_PER_CUSTOMER',
      option2Title: 'Only used for first purchase via any channel',
      option2Key: 'FIRST_PURCHASE_VIA_ANY_CHANNEL',
      option3Title: 'Coupon only used for new users',
      option3Key: 'NEW_CUSTOMER',
      option4Title: 'Limit number of times this discount can be used in total',
      option4Key: 'MAX_TIMES_USED'
    },
    couponTypeList: [
      // NOTE: adding as list, so we can add multiple as per our need
      {
        title: 'Manual',
        description:
          'Add products to this category one by one by manually selecting this category during product creation or update.'
      },

      {
        title: 'Automatic',
        description: 'Products matched with the following conditions will be automatically assigned to this collection.'
      }
    ]
  },
  AllProductsMenu: {
    products: 'Products',
    allProducts: 'All Products',
    export: 'Export',
    import: 'Import',
    addProduct: 'Add Product',
    editProduct: 'Edit Product',
    editColumns: 'Edit Columns',
    bulkEditor: 'Bulk Editor',
    selected: 'Selected',
    unselected: 'Unselected',
    selectCatalogs: 'Select catalogs',
    selectCollection: 'Select collection'
  },
  FulfillmentCenter: {
    fulfilled: 'Fulfilled',
    products: 'Products',
    insuranceMessage:
      'Insurance is through Shipsurance. They are a professional shipping insurer that is worldwide, covers parcels and freight, and can have a coverage limit over $1000. To learn about carrier limits, ',
    goHere: 'Go Here',
    orders: 'Orders',
    dimensions: 'Dimensions:',
    item: 'item:',
    items: 'items:',
    pOrders: 'Order:',
    history: 'History',
    searchForOrder: 'Search for order',
    ordersToFulfill: 'Orders to fulfill',
    FulfillmentCenter: 'Fulfillment center',
    fulfillOrder: 'Fulfill order',
    processedTodayBy: 'Processed today by',
    shippingAddress: 'Shipping Address',
    shippingAddressValue: 'White Sulphur Springs near by Houston TX, Montana (MT). 59645.',
    shippingName: 'Marley marley',
    markScanned: 'Mark Scanned',
    unavailableItem: 'Unavailable Item',
    scanned: 'Scanned',
    undo: 'Undo',
    package: 'Package',
    totalValueOfOrder: 'Total Value of order',
    totalValuePlaceholder: 'e.g. 1000',
    packageContentForInsurance: 'Package contents for insurance',
    packageContentPlaceholder: 'Clothes, electronics, etc.',
    packageDesc: 'Rates are calculated based on package dimensions and weight',
    packageWeight: 'Package weight',
    shipmentOptions: 'Shipment Options',
    signatureConfirmation: 'Add signature confirmation',
    shipmentInsurance: 'Add shipment insurance',
    useWeightOfItems: 'Use weight of items',
    pkgWeightPlaceholder: '0.00',
    shippingRates: 'Shipping Rates',
    businessDays: 'business days',
    businessDay: 'business day',
    buyShippingLabel: 'Buy shipping label',
    refundModal: {
      title: 'Refund shipping label',
      cancelButtonTitle: 'Cancel',
      actionButtonTitle: 'Yes, Refund'
    },
    unverifiedAddModal: {
      title: 'Address not verified!',
      cancelButtonTitle: 'Close',
      actionButtonTitle: 'Ok',
      message: 'Please verify your address'
    },
    leavePageModal: {
      title: 'Exit order fulfillement!',
      cancelButtonTitle: 'Cancel',
      actionButtonTitle: 'Yes, Exit',
      message: 'Are you sure you want to stop fulfilling the actual order? '
    },
    modal: {
      title: 'Shipping label',
      success: 'refunded successfully!',
      message: 'Your Shipping label have been refunded successfully. You can choose another shipping label to continue.'
    }
  },
  // CONSTANTS FOR CATALOG
  Catalogs: {
    marketing: 'Marketing',
    products: 'Products',
    catalogs: 'Catalogs',
    createCatalogs: 'Create Catalog',
    serchForProducts: 'Search for products',
    information: {
      title: 'How it works',
      steps:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ornare lectus sit amet est placerat in egestas erat. Auctor eu augue ut lectus arcu bibendum at varius vel. Dictumst quisque sagittis purus sit.'
    },
    addCatalog: {
      genralHeading: 'General',
      poductListHeading: 'Products',
      pageHeading: 'Create catalog',
      editPageHeading: 'Edit catalog',
      updatedAt: 'Last update 28 april 2021 at 12:10 pm by',
      catalogName: 'Catalog name',
      placeHolder: 'Catalog name',
      catalogUrlTitle: 'Catalog Url',
      catalogDummyTitleUrl: 'Catalog URL will be generated after creating catalog',
      catalogMainUrl: 'https://www.',
      catalogDynamicUrl: 'storename.com/summer',
      catalogStatusTitle: 'Catalog Status',
      catalogProductsTitle: 'Products',
      catalogStatusInputTitle: 'Catalog status',
      catalogTypeTitle: 'Catalog type',
      productAssignmentMethodTitle: 'Products Category',
      catalogTypeSubTitle: 'Product Assignment method',
      manualTitle:
        'Add products to this category one by one by manually selecting this category during product creation or update.',
      automaticTitle: 'Products matched with the following conditions will be automatically assigned to this category.',
      productCategoryAutomaticInfo: 'Categories will be attributed automatically by Facebook & google.',
      productCategoryManualInfoClick: 'Click here',
      productCategoryManualInfoClickMessage: 'to find the correct category to use.',
      productAssignmentMethod: {
        isManual: 'Manual',
        automatic: 'Automatic'
      },
      productCategoryInputPlaceholder: 'Product Category',
      deleteButtonTitle: 'Delete Catalog',
      productActive: 'Active',
      productArchive: 'Archive',
      productDraft: 'Draft',
      removeFromCatalogTitle: 'Remove from catalog'
    },
    active: 'Active',
    inactive: 'Inactive',
    delete: 'Delete',
    moreActions: 'More Actions',
    tooltip: {
      mainTitle: ' Catalogs has been deleted!',
      secondTitle: ' Catalog has been deleted!',
      mainMessage: 'You have deleted',
      mainMessageTwo: 'catalog from the list successfully.',
      firstMessage: 'You have deleted',
      secondMessage: 'catalogs from the list successfully.'
    },
    catalogProductTooltip: {
      mainTitle: ' Products has been removed!',
      secondtitle: ' Product has been removed!',
      mainMessage: 'You have removed',
      mainMessageTwo: 'products from this catalog list successfully.',
      mainMessageThree: 'product from this catalog list successfully.',
      firstMessage: 'You have removed',
      secondMessage: 'catalog from the list successfully.',
      addedProductsToCatalog: 'successfully to catalogs!'
    }
  },
  FeaturedProducts: {
    featuredProducts: 'Featured products',
    featuredList: 'Featured list',
    type: {
      productAssignmentMethod: 'Product Assignment method',
      manual: 'Manual',
      manualText:
        'Add products to this collection one by one by manually selecting this collection during product creation or update.',
      automatic: 'Automatic',
      automaticText: 'Products matched with the following conditions will be automatically assigned to this collection.'
    },
    status: {
      featuredListStatus: 'Featured list Status',
      featuredListStatusTwo: 'Featured list status',
      statusDesc: 'Featured products will appear on the app.'
    },
    productList: {
      removeFromFeaturedProducts: 'Remove from featured products'
    },
    removedModal: {
      mainTitle: ' Products has been removed!',
      secondtitle: ' Product has been removed!',
      mainMessage: 'You have removed',
      mainMessageTwo: 'products from this featured list successfully.',
      mainMessageThree: 'product from this featured list successfully.',
      firstMessage: 'You have removed',
      secondMessage: 'products from featured list successfully.'
    },
    addingToFeatProducts: {
      featuredList: 'Featured List!',
      messageOne: 'You have successfully added',
      messageTwo: 'to Featured List!',
      errorMessage: 'already exist on featured list.'
    }
  },
  TikTokIntegration: {
    title: 'TikTok Integration',
    addProducts: 'Add Products',
    overview: 'Overview',
    inProcess: 'in Process',
    readyToPush: 'Ready to Push',
    inQueue: 'in Queue',
    inReview: 'in Review',
    dailyLimit: 'Daily Limit',
    products: 'Products',
    addProductsToTiktok: 'Add New Products to TikTok'
  },
  // LiveShow Constants
  LiveShow: {
    liveShowTitle: 'Liveshow',
    save: 'Save',
    calendar: 'Calendar',
    management: 'Management',
    liveShowCalendar: 'Liveshow Calendar',
    noUpcomingLiveShow: 'No Upcoming Liveshow Scheduled',
    searchLiveshow: 'Search results',
    goLiveNow: 'Go Live Now!',
    createLiveShow: 'Create Liveshow',
    createLiveShowLowerCase: 'Create liveshow',
    saveDetails: 'Save Details',
    createNewLiveShow: 'Create New Liveshow',
    editLiveShow: 'Edit Liveshow',
    cancel: 'Cancel',
    next: 'Next',
    previous: 'Previous',
    saveChanges: 'Save Changes',
    add: 'Add',
    adding: 'Adding',
    skipThisStep: 'Skip this step',
    inputFields: {
      liveShowTitle: 'Liveshow title',
      liveShowTitlePlaceholder: 'Enter liveshow title',
      liveShowDate: 'Liveshow date',
      liveShowDatePlaceholder: 'MM-DD-YYYY',
      liveShowTimePlaceholder: 'HH:MM',
      startTime: 'Start time',
      endTime: 'End time',
      streamChannel: 'Stream channels',
      streamChannels: {
        streamOnApp: 'App',
        streamOnWebsite: 'WebStore'
        // streamOnFbChannels: 'Facebook Channels'
      },
      facebookChannels: 'Facebook Channels'
    },
    enterLiveshowDetails: 'Enter Liveshow Details',
    editLiveshowDetails: 'Edit Liveshow Details',
    manager: 'Manager',
    newLiveShow: 'New Liveshow',
    liveShowManager: 'Liveshow Manager',
    setLiveshowGoals: 'Set Liveshow Goals',
    liveSettings: 'Live Settings',
    nextLive: 'Next Live',
    liveshowManagerDropDown: [
      {
        id: 1,
        name: 'Seller app'
      },
      {
        id: 2,
        name: 'Desktop'
      }
    ],
    productForLiveTitle: 'Products For Live',
    productForLivePlaceholderTextTitle: 'No products selected yet',
    productForLivePlaceholderText: 'You haven’t selected any products yet. They will be shown here once you do.',
    liveShowTimeFormat: {
      AM: 'AM',
      PM: 'PM'
    },
    notificationStep: {
      title: 'Title',
      description: 'Description',
      notificationsInChannels: 'Notification in channels',
      textMessage: 'Text message',
      textMessageHoverMsg: 'Text messages are disabled',
      descriptionCount: 160,
      appNotification: 'App notification',
      appNotificationHoverMsg: 'App notifications are disabled',
      appNotificationTitleCount: 65,
      appNotificationDescriptionCount: 113,
      facebookPost: 'Facebook post',
      facebookPostHoverMsg: 'Facebook channels are disabled',
      facebookPostTitleCount: 40,
      facebookPostWarning:
        'You need to enable at least one Facebook channel in streaming channels to be able to send notifications.',
      messangerInNotification: 'Messenger notification',
      messangerHoverMsg: 'Messengers notifications are disabled'
    },
    liveShowEditOptions: [
      {
        id: 1,
        name: 'Edit Liveshow'
      },
      {
        id: 2,
        name: 'Delete Liveshow'
      }
    ],
    deleteModal: {
      title: 'Delete Event',
      mainMessage: 'Are you sure want to delete the event?',
      cancel: 'No, don’t want to',
      action: 'Yes, Delete it'
    },
    addProductByScanning: 'Add Product by Scanning',
    lowResolution: 'Warning',
    lowResolutionDescription:
      'Camera resolution is low, please use a higher resolution camera (4k or higher) to have a good quality streaming.',
    dumpAllViewers: {
      title: 'Are you sure you want to dump all viewers’ carts?',
      allCartsWere: 'All carts were'
    },
    dumpViewer: {
      title: 'Are you sure you want to dump viewer’s cart?',
      cartHasBeen: 'Cart has been',
      theViewer: 'The viewer has been'
    },
    blockViewer: {
      title: 'Are you sure you want to block viewer?',
      description: 'The viewer has been blocked!'
    },
    unSupportedResolution: 'Low resolution detected',
    unSupportedResolutionDescription: 'Camera resolution is low, we donot recommend streaming with this resolution',
    cameraCannotBeCaptured: 'Camera cannot be captured',
    cameraCannotBeCapturedDescription: 'Error in accessing your camera.',
    deleteProduct: {
      title: 'Remove Product from Liveshow',
      message: 'Are you sure you want to remove product from liveshow products selection?'
    }
  },
  emojiInputCountLimit: 40,
  WaitlistsMenu: {
    orders: 'Orders',
    waitlists: 'Waitlists',
    searchForProducts: 'Search for products'
  },
  InventoryMenu: {
    products: 'Products',
    inventory: 'Inventory',
    inventoryManagement: 'Inventory Management',
    viewProducts: 'View Products',
    searchForInventory: 'Search for Inventory'
  },
  AllOrdersMenu: {
    orders: 'Orders',
    allOrders: 'All Orders',
    createOrder: 'Create Order',
    searchForOrders: 'Search for order',
    searchForFavoriteOrders: 'Search for Favorites orders'
  },

  AllCustomersMenu: {
    customers: 'Customers',
    allCustomers: 'All Customers'
  },
  ProductCategoryMenu: {
    collections: {
      allCollections: 'All Collections',
      collection: 'Collection',
      products: 'Products',
      createCollection: 'Create Collection',
      editCollection: 'Edit Collection',
      collectionName: 'Collection name',
      helperText: 'A collection name is required and recommended to be unique.',
      helperTextdescription: 'Set a description to the collection for better visibility.',
      status: 'Status',
      statusKey: 'Collection Status',
      statusHelperText: 'This collection will be hidden from all sales channels. ',
      collectionType: 'Collection type',
      collectionTypeMsg: 'Product Assignment Method',
      collectionTypeList: [
        // NOTE: adding as list, so we can add multiple as per our need
        {
          title: 'Manual',
          description:
            'Add products to this collection one by one by manually selecting this collection during product creation or update.'
        },

        {
          title: 'Automatic',
          description: 'Products matched with the following conditions will be automatically assigned to this collection.'
        }
      ],
      thumbnail: 'Thumbnail',
      thumbnailMsg: 'Set the category thumbnail image only *.png,*.jpg & *.jpeg, *svg, *webp, *heif image files are accepted',
      mediaUpload: 'Drop or upload a Thumbnail'
    }
  },
  ShippingStatusTerms: {
    preTransit: 'PRE_TRANSIT',
    transit: 'TRANSIT',
    unknown: 'UNKNOWN',
    delivered: 'DELIVERED',
    faliure: 'FAILURE',
    returned: 'RETURNED'
  },
  ShippingStatusName: {
    preTransit: 'Pre Transit',
    transit: 'Transit',
    unknown: 'Unknown',
    delivered: 'Delivered',
    faliure: 'Failure',
    returned: 'Returned'
  },
  VendorManagementMenu: {
    vendorManagement: 'Vendor Management',
    vendors: 'Vendors',
    addVendor: 'Add Vendor',
    addNewVendor: 'Add New Vendor',
    editVendor: 'Edit vendor details'
  },
  VendorDetails: {
    details: {
      vendorName: 'Vendor Name',
      editDetails: 'Edit Details',
      generalInformation: 'General Information',
      webaddress: 'www.company.com',
      category: 'Category :',
      subCategory: 'Sub Category :',
      categoryType: 'Tops, denim',
      address: 'Address',
      market: 'At market location',
      other: 'Other',
      delete: 'Delete',
      edit: 'Edit',
      save: 'Save',
      cancel: 'Cancel',
      saleInformation: 'Sale Information',
      shippingOnTheVendor: 'Shipping on the Vendor',
      discount: 'Discount',
      packsConditions: 'Packs Conditions',
      contactInformation: 'Contact Information',
      startDate: 'Relationship start date',
      tempDate: 'Sept 20, 2020',
      endButton: 'End Relationship',
      placeHolder: 'Type a note . . .  ',
      stock: ' in stock'
    },
    endRelationship: {
      cancel: 'No, Keep Relationship',
      confirm: 'Yes, End Relationship',
      title: 'Are you sure, end relationship?',
      message: 'End relationship will permanently remove it from Buy Social',
      archive: 'ARCHIVE',
      active: 'ACTIVE'
    },
    deleteNote: {
      cancel: 'No, Keep Note',
      confirm: 'Yes, Delete Note',
      title: 'Are you sure, delete note?',
      message: 'Deleting a note will permanently remove it from your notes'
    },
    profile: {
      first: 'Fashiongo profile',
      second: 'Faire profile',
      mobile: '(404)-238-2382',
      mail: 'johnDoe@company.com',
      name: 'John Doe'
    },
    subCategoryType: {
      pants: 'Pants',
      denim: 'Denim'
    },
    address: {
      street: '1963 Post Farm Road, Nebraska, USA',
      floor: '3rd Floor, pavilion E'
    },
    notes: {
      noteTitle: 'Notes',
      updated: 'Last Updated: '
    },
    allProduct: {
      lastUpdated: 'Last update',
      tempTime: 'Apr 20 at 10:30 AM',
      addProduct: 'Add Product',
      editColumn: 'Edit Columns',
      tableHeader: {
        status: 'Status',
        price: 'Price',
        brand: 'Brand',
        type: 'Type',
        availability: 'Availability',
        product: 'Product'
      },
      sort: 'Sort',
      filter: 'Filter'
    }
  },
  ProductsTab: {
    allProducts: 'All Products',
    active: 'Active',
    inactive: 'Inactive',
    draft: 'Draft',
    archive: 'Archive',
    archived: 'Archived',
    editProducts: 'Edit Products',
    moreActions: 'More Actions',
    sortBy: 'Sort By:',
    default: 'Default',
    deselectAll: 'Deselect All',
    deleteFromProducts: 'Delete from selected products',
    addToProducts: 'Add to selected products ',
    scheduled: 'Scheduled'
  },
  VendorsTab: {
    allVendors: 'All Vendors',
    active: 'Active',
    inactive: 'Inactive',
    archived: 'Archived'
  },
  WareHousesTab: {
    allWarehouses: 'All Warehouses',
    active: 'Active',
    inactive: 'Inactive'
  },
  OrdersTab: {
    all: 'All',
    pending: 'Pending',
    process: 'To Process',
    fulfill: 'To Fulfill',
    ship: 'To Ship',
    shipped: 'Shipped'
  },
  CouponsTab: {
    allCoupons: 'All Coupons',
    active: 'Active',
    scheduled: 'Scheduled',
    expired: 'Expired',
    inactive: 'Inactive'
  },
  TabForTikTokIntegration: ['All products', 'Active', 'Inactive', 'In Review', 'Draft', 'Failed', 'Suspended'],
  TabForProduct: ['All products', 'Active', 'Inactive', 'Draft', 'Archived'],
  TabForOrders: ['All', 'Pending', 'To Process', 'To Fulfill', 'To Ship', 'Shipped'],
  TabForFavorites: ['Wishlist', 'Waitlist', 'Waitlist authorized'],
  TabForCoupons: ['All Coupons', 'Active', 'Scheduled', 'Expired', 'Inactive'],
  TabForVendors: ['All Vendors', 'Active', 'Inactive', 'Archived'],
  TabForWarehouse: ['All Warehouses', 'Active', 'Inactive'],
  TabForVendorsDetails: ['Details', 'All Products', 'Selected Products', 'Purchase Orders'],
  TabForAppCustomizer: ['Intro Screens', 'App Colors'],
  TabForColorPicker: ['Solid', 'Picker'],
  TabForProductMeida: ['Images', 'Recordings'],
  Tabs: {
    details: 'DETAILS',
    allVendors: 'All Vendors',
    allWarehouses: 'ALL WAREHOUSES',
    allProduct: 'ALL PRODUCTS',
    selectedProduct: 'SELECTED PRODUCTS'
  },
  RapidList: {
    featured: { label: 'Featured', value: 'FEATURED' },
    flashSale: { label: 'Flash Sale', value: 'FLASH_SALE' },
    mysteryList: { label: 'Mystery list', value: 'MYSTERY_LIST' }
  },
  recordingOptionsDropdownData: [
    { id: 1, name: 'Set as Primary' },
    { id: 2, name: 'Deselect as Primary' },
    { id: 3, name: 'Download' }
  ],
  warehouseInactiveOptionsDropdownData: [
    {
      id: 1,
      name: 'Edit',
      icon: Pencil
    },
    {
      id: 2,
      name: 'Move Products',
      icon: Duplicate
    },
    {
      id: 3,
      name: 'Delete',
      icon: Delete
    }
  ],
  warehouseOptionsDropdownData: [
    {
      id: 1,
      name: 'Make Default',
      icon: Default
    },
    {
      id: 2,
      name: 'Edit',
      icon: Pencil
    },
    {
      id: 3,
      name: 'Move Products',
      icon: Duplicate
    },
    {
      id: 4,
      name: 'Delete',
      icon: Delete
    }
  ],
  notesOptionsDropdownData: [
    {
      id: 1,
      name: 'Edit'
    },
    {
      id: 2,
      name: 'Delete'
    }
  ],
  warehouseDefaultOptionsDropdownData: [
    {
      id: 1,
      name: 'Edit',
      icon: Pencil
    },
    {
      id: 2,
      name: 'Move Products',
      icon: Duplicate
    },
    {
      id: 4,
      name: 'Delete',
      icon: Delete,
      disabled: true
    }
  ],
  catalogStatusDropDownData: [
    {
      id: 1,
      name: 'Active'
    },
    {
      id: 2,
      name: 'Inactive'
    }
    // {
    //   id: 3,
    //   name: 'Scheduled'
    // }
  ],
  productDetailsMoreActionDropDownData: [
    {
      id: 1,
      name: 'Print SKU'
    },
    {
      id: 2,
      name: 'Print barcode'
    }
  ],
  couponMoreActionDropDownData: [
    {
      id: 1,
      name: 'Active'
    },
    {
      id: 2,
      name: 'Inactive'
    },
    {
      id: 3,
      name: 'Scheduled'
    }
  ],
  featuredProductsStatusDropDownData: [
    {
      id: 1,
      name: 'Active'
    },
    {
      id: 2,
      name: 'Inactive'
    }
    // {
    //   id: 3,
    //   name: 'Scheduled'
    // }
  ],
  createCouponStatusDropDownData: [
    {
      id: 1,
      name: 'Active'
    },
    {
      id: 2,
      name: 'Inactive'
    }
    // {
    //   id: 3,
    //   name: 'Scheduled',
    //   className: 'text-secondary',
    //   disabled: true
    // },
    // {
    //   id: 4,
    //   name: 'Expired',
    //   className: 'text-secondary',
    //   disabled: true
    // }
  ],
  amPmDropDownData: [
    {
      id: 1,
      name: 'AM'
    },
    {
      id: 2,
      name: 'PM'
    }
  ],

  catalogOptionsDropdownData: [
    {
      id: 1,
      name: 'Edit Catalog',
      className: 'text-muted'
    },
    // {
    //   id: 2,
    //   name: 'Export Catalog',
    //   className: 'text-muted'
    // },
    {
      id: 3,
      name: 'Delete Catalog',
      className: 'text-danger'
    }
  ],
  collectionOptionsDropdownData: [
    // {
    //   id: 1,
    //   name: 'Archive Collection'
    // },
    {
      id: 2,
      name: 'Delete Collection',
      className: 'text-danger'
    }
  ],
  usersPermissionsDropdownData: [
    {
      id: 1,
      name: 'Re-send invite',
      className: 'text-muted'
    },
    {
      id: 2,
      name: 'Delete',
      className: 'text-muted'
    }
  ],
  notificationDropdownData: [
    {
      id: 1,
      name: 'Mark as read'
    },
    {
      id: 2,
      name: 'Mark as unread'
    },
    {
      id: 3,
      name: 'Delete'
    }
  ],
  fulfilmentCenterBoxDropdownData: [
    {
      id: 0,
      name: 'Mark As Unavailable'
    }
  ],
  ordersEditOptionsDropdownData: [
    {
      id: 0,
      name: 'Edit order'
    },
    {
      id: 1,
      name: 'Refund order'
    },
    {
      id: 2,
      name: 'Cancel order'
    }
  ],
  customerAddressOptionsPrimaryDropdownData: [
    {
      id: 1,
      name: 'Edit address'
    }
  ],
  customerAddressOptionsDropdownData: [
    {
      id: 1,
      name: 'Edit address'
    },
    {
      id: 2,
      name: 'Make as primary address'
    },
    {
      id: 3,
      name: 'Delete address'
    }
  ],
  inventoryReasonsList: [
    {
      id: 1,
      name: 'Stock received',
      key: 'STOCK_RECEIVED'
    },
    {
      id: 2,
      name: 'Inventory re-count',
      key: 'INVENTORY_RECOUNT'
    },
    {
      id: 3,
      name: 'Damage',
      key: 'DAMAGE'
    },
    {
      id: 4,
      name: 'Theft',
      key: 'THEFT'
    },
    {
      id: 5,
      name: 'Loss',
      key: 'LOSS'
    },
    {
      id: 6,
      name: 'Restock return',
      key: 'RESTOCK_RETURN'
    },
    {
      id: 7,
      name: 'Giveaway',
      key: 'GIVEAWAY'
    }
  ],
  inventoryDummyList: [
    {
      id: 1,
      name: 'D - 975',
      className: 'text-muted fw-bold'
    },
    {
      id: 2,
      name: 'D - 976',
      className: 'text-muted fw-bold'
    },
    {
      id: 3,
      name: 'D - 977',
      className: 'text-muted fw-bold'
    },
    {
      id: 4,
      name: 'D - 978',
      className: 'text-muted fw-bold'
    }
  ],
  shippingCustomerAddressOptionsPrimaryDropdownData: [
    {
      id: 1,
      name: 'Edit address'
    }
  ],
  shippingCustomerAddressOptionsDropdownData: [
    {
      id: 1,
      name: 'Edit address'
    },
    {
      id: 2,
      name: 'Delete address'
    }
  ],
  customrDetailsOptionsBlockedDropdownData: [
    // {
    //   id: 1,
    //   name: 'Combine account'
    // },
    // {
    //   id: 2,
    //   name: 'Reset password'
    // },
    // {
    //   id: 3,
    //   name: 'Ignore buy comments'
    // },
    {
      id: 4,
      name: 'Unblock customer'
    },
    {
      id: 5,
      name: 'Delete customer'
    }
  ],
  customrDetailsOptionsUnblockedDropdownData: [
    // {
    //   id: 1,
    //   name: 'Combine account'
    // },
    // {
    //   id: 2,
    //   name: 'Reset password'
    // },
    // {
    //   id: 3,
    //   name: 'Ignore buy comments'
    // },
    {
      id: 4,
      name: 'Block customer'
    },
    {
      id: 5,
      name: 'Delete customer'
    }
  ],
  ordersOptionsDropdownData: [
    {
      id: 1,
      name: 'Print Invoice',
      className: 'thinDropdownTextOrderDetails',
      disabled: true
    },
    {
      id: 2,
      name: 'Print Packing Slips',
      className: 'thinDropdownTextOrderDetails'
    },
    {
      id: 3,
      name: 'Cancel Order',
      className: 'thinDropdownTextOrderDetails'
    },
    {
      id: 4,
      name: 'Return Order',
      className: 'thinDropdownTextOrderDetails'
    }
  ],
  // ordersOptionsDropdownData: [
  //   {
  //     id: 1,
  //     name: 'Edit order',
  //     className: 'thinDropdownTextOrderDetails'
  //   },
  //   {
  //     id: 2,
  //     name: 'Print Packing Slip',
  //     className: 'thinDropdownTextOrderDetails'
  //   },
  //   {
  //     id: 3,
  //     name: 'Print Shipping Label',
  //     className: 'thinDropdownTextOrderDetails'
  //   },
  //   {
  //     id: 4,
  //     name: 'Fulfill Order',
  //     className: 'thinDropdownTextOrderDetails'
  //   },
  //   {
  //     id: 5,
  //     name: 'Refund Order',
  //     className: 'thinDropdownTextOrderDetails'
  //   },
  //   {
  //     id: 6,
  //     name: 'Print Order',
  //     className: 'thinDropdownTextOrderDetails'
  //   },
  //   {
  //     id: 7,
  //     name: 'Delete',
  //     className: 'thinDropdownTextOrderDetails'
  //   },
  //   {
  //     id: 8,
  //     name: 'Return Order',
  //     className: 'thinDropdownTextOrderDetails'
  //   },
  //   {
  //     id: 9,
  //     name: 'Print Invoice',
  //     className: 'thinDropdownTextOrderDetails'
  //   }
  // ],
  activeInactivestatusDropdownData: [
    {
      id: 1,
      name: 'Active'
    },
    {
      id: 2,
      name: 'Inactive'
    }
  ],
  activeInactiveScheduledstatusDropdownData: [
    {
      id: 1,
      name: 'Active'
    },
    {
      id: 2,
      name: 'Inactive'
    },
    {
      id: 3,
      name: 'Scheduled'
    }
  ],
  statusDropdownData: [
    {
      id: 1,
      name: 'Active'
    },
    {
      id: 2,
      name: 'Inactive'
    },
    {
      id: 3,
      name: 'Draft'
    },
    {
      id: 4,
      name: 'Archive'
    }
    // {
    //   id: 5,
    //   name: 'Scheduled'
    // }
  ],
  userRoles: [
    { id: 1, name: 'Owner' },
    { id: 2, name: 'Admin' },
    { id: 3, name: 'Admin associate' },
    { id: 4, name: 'Warehouse manager' },
    { id: 5, name: 'Fulfilment associate' },
    { id: 6, name: 'Warehouse associate' }
  ],
  productStatus: [
    {
      id: 1,
      name: 'Selected'
    },
    {
      id: 2,
      name: 'Unselected'
    }
  ],
  liveShowDashboardProductListDropdownData: [
    // {
    //   id: 1,
    //   name: 'Edit'
    // },
    {
      id: 2,
      name: 'Remove product'
    }
  ],
  liveShowCommentHeaderDropdownData: [
    {
      id: 1,
      name: 'All comments'
    },
    {
      id: 2,
      name: 'Pinned comments'
    }
    // {
    //   id: 3,
    //   name: 'Hide Buy comments'
    // }
  ],
  liveShowViewerHeaderDropdownData: [
    {
      id: 1,
      name: 'Add store credit to all viewers'
    },
    {
      id: 2,
      name: 'Dump all carts'
    }
  ],
  liveShowViewerItemDropdownData: [
    {
      id: 1,
      name: 'Block viewer'
    },
    {
      id: 2,
      name: 'Add store credit'
    },
    {
      id: 3,
      name: 'Dump cart'
    }
  ],
  addVendor: {
    generalInfo: 'General information',
    vendorName: 'Vendor name',
    website: 'Website',
    changeLogo: 'Change Logo',
    category: 'Category',
    accountNo: 'Account Number',
    subCategory: 'Sub Category',
    vendorManager: 'Vendor manager',
    contactInfo: 'Contact information',
    salesRep: 'Sales rep',
    addContact: 'Add contact',
    addCategory: 'Add category',
    saleInfo: 'Sale information',
    shippingCosts: 'Shipping costs',
    onTheVendor: 'On the vendor',
    onTheBoutique: 'On the boutique',
    discount: 'Discount',
    packsCondition: 'Packs condition',
    addAdditionalInfo: 'Add additional information',
    location: 'Location',
    atMarketLocation: 'At market location',
    others: 'Others',
    fashiongoProfile: 'Fashiongo profile',
    faireProfile: 'Faire profile'
  },
  vendorListColumns: {
    vendor: 'Vendor',
    category: 'Category',
    subCategory: 'Sub Category',
    totalSpending: 'Total Spending'
  },
  sortCategories: {
    name: 'NAME',
    category: 'CATEGORY',
    subCategory: 'SUB_CATEGORY',
    totalSpending: 'TOTAL_SPENDING',
    availability: 'AVAILIBILITY',
    type: 'TYPE',
    brand: 'BRAND',
    price: 'PRICE',
    status: 'STATUS',
    createdAt: 'CREATED_AT',
    updatedAt: 'UPDATED_AT'
  },
  sortLabels: {
    name: 'Name',
    category: 'Category',
    subCategory: 'Sub Category',
    totalSpending: 'Total Spending',
    availability: 'Availability',
    type: 'Type',
    brand: 'Brand',
    price: 'Price',
    status: 'Status',
    createdAt: 'Created At',
    updatedAt: 'Updated At'
  },
  toolTip: {
    // Added for module
    arrowColor: '#424758'
  },
  vendorEditColumns: {
    vendorCategory: 'Vendor category',
    performance: 'Performance',
    budget: 'Budget',
    totalSpending: 'Total spending',
    rankTier: 'Rank tier',
    relationshipStartDate: 'Relationship start date',
    relationshipEndDate: 'Relationship end date',
    vendorManager: 'Vendor manager',
    location: 'Location',
    totalBenefit: 'Total benefit',
    accountNumber: 'Account number'
  },
  productEditColumns: {
    inventory: 'Inventory',
    type: 'Type',
    vendor: 'Vendor',
    status: 'Status',
    tags: 'Tags',
    salePrice: 'Sale price',
    margin: 'Margin',
    profit: 'Profit',
    itemCost: 'Item cost',
    potentialRevenue: 'Potential revenue',
    shippingCost: 'Shipping cost',
    freeShipping: 'Free shipping',
    sku: 'SKU',
    barCode: 'Barcode'
  },
  productBulkEditColumns: {
    collection: 'Collection',
    primaryMedia: 'Set recent recording as primary',
    type: 'Type',
    tag: 'Tag',
    status: 'Status',
    salePrice: 'Sale price',
    retailPrice: 'Retail price',
    margin: 'Margin',
    profit: 'Profit',
    itemCost: 'Item cost',
    taxCode: 'Tax code',
    finalSale: 'Final sale',
    shippingCost: 'Shipping cost',
    weight: 'Weight',
    width: 'Width',
    height: 'Height',
    depth: 'Depth',
    freeShipping: 'Free shipping',
    addSignatureConfirmation: 'Add signature confirmation',
    addShipmentInsurance: 'Add shipment insurance',
    sensitiveProduct: 'Sensitive product'
  },
  inventoryEditColumns: {
    title: 'Edit Columns',
    sku: 'SKU',
    location: 'Location',
    incoming: 'Incoming',
    committed: 'Committed',
    lowStockAlert: 'Low stock alert',
    stockLevel: 'Stock level',
    availableSale: 'Available for sale'
  },
  inventoryHistoryEditColumns: {
    title: 'Edit Columns',
    date: 'Date',
    product: 'Product',
    adjustment: 'Adjustment',
    location: 'Location',
    owner: 'Owner',
    oldQuantity: 'Old Quantity',
    newQuantity: 'New Quantity'
  },
  orderEditColumns: {
    title: 'Edit Columns',
    customer: 'Customer',
    date: 'Date',
    status: 'Status',
    total: 'Total',
    items: 'Items',
    tags: 'Tags'
  },
  customerEditColumns: {
    status: 'Status',
    phoneNumber: 'Phone number',
    orders: 'Orders',
    total: 'Total',
    joined: 'Joined',
    location: 'Location',
    tags: 'Tags'
  },
  waitlistEditColumns: {
    commited: 'Commited',
    waitlist: 'Waitlist',
    waitlistAuth: 'Waitlist Authorized',
    type: 'Type',
    vendor: 'Vendor',
    price: 'PRICE',
    restockStatus: 'Restock status',
    estimatedReorderProfit: 'Estimated reorder profit'
  },
  catalogEditColumns: {
    products: 'Products',
    catalogType: 'Catalog Type',
    status: 'Status',
    link: 'Link'
  },
  couponEditColumns: {
    title: 'Edit Columns',
    status: 'Status',
    method: 'Method',
    type: 'Type',
    used: 'Used'
  },
  productVariantEditColumns: {
    price: 'Price',
    barcode: 'Barcode',
    weight: 'Weight'
  },
  extraInfoTypes: {
    others: 'OTHERS',
    location: 'LOCATION',
    saleInfo: 'SALE_INFO'
  },
  common: {
    channels: 'Channels',
    schedule: 'Schedule availability',
    allConditions: 'All conditions',
    anyConditions: 'Any conditions',
    collectionConditions: 'Conditions',

    general: {
      key: 'General',
      productName: 'Product Name',
      helperTextProductName: 'A product name is required and recommended to be unique.',
      description: 'Description',
      helperTextdescription: 'Set a description to the product for better visibility.',
      archiveProduct: 'Archive Product',
      deleteProduct: 'Delete Product'
    },
    productList: {
      title: 'Delete Products',
      mainMessage: 'Are you sure want to delete these products from the list?',
      cancel: 'No, don’t want to',
      action: 'Yes, Delete it'
    },
    productDetail: {
      title: 'Delete Product',
      mainMessage: 'Are you sure want to delete the product?',
      cancel: 'No, don’t want to',
      action: 'Yes, Delete it'
    },
    channelList: {
      channel1Title: 'Online store',
      channel1Key: 'online',
      channel2Title: 'Mobile app',
      channel2Key: 'mobile',
      channel3Title: 'Facebook store',
      channel3Key: 'facebook',
      channel4Title: 'Google',
      channel4Key: 'google'
    },
    productStatus: {
      key: 'Product Status',
      helperText: 'This product will be hidden from all sales channels. '
    },
    addOrganization: {
      key: 'Organization',
      collections: 'Collections',
      tags: ' Tags',
      sensitiveProduct: ' Sensitive product'
    },
    tags: {
      title: 'Delete Tag',
      edit: 'Edit',
      delete: 'Delete'
    },
    pricing: {
      key: 'Pricing',
      pricePlaceholder: '$0.00',
      marginPlaceholder: '0%',
      salePrice: 'Sale price',
      retailPrice: 'Retail price',
      margin: 'Margin',
      profit: 'Profit',
      costPerItem: 'Cost per item',
      taxCode: 'Tax code',
      finalScale: 'Final Sale',
      finalScaleText: 'If checked, customer cannot return or exchange product.'
    },
    productmedia: {
      key: 'Media',
      titleMessage: 'File should be JPEG, PNG, SVG, WebP or HEIF.',
      dndTitleText: 'Drop files here or click to upload',
      videoCheckTitle: 'Exclude this product from automatic products video',
      deleteFrom: 'Delete from Media',
      videoCheckMessage:
        'If checked, even if the Automatic Product Videos from Live Sales feature is enabled for your branded mobile app, they will not be used for this product',
      deleteNote: {
        cancel: `No, don't want to`,
        confirm: 'Yes, Delete it',
        title: 'Delete',
        headerText: (selectedImage: number, result: number): string => `Delete ${selectedImage} of ${result} files`,
        message: `This can't be undone`
      },
      emptyImagesTitle: 'No Images Yet',
      emptyImagesDescription: 'Currently, there are no images available.',
      emptyRecordingsTitle: 'No Recordings Yet',
      emptyRecordingsDescription:
        'Currently, there are no recordings available. They’ll appear here as soon as live shows are recorded.'
    },
    shipping: {
      key: 'Shipping',
      costPlaceholder: '$0.00',
      shippingCost: '',
      shippingCostText: 'Shipping cost',
      weight: 'Weight',
      width: 'Width',
      height: 'Height',
      depth: 'Depth',
      measurementsPlaceHolder: '0',
      enterDetailText: 'Enter product details to generate shipping cost estimation',
      freeShipping: 'Free shipping',
      addSignConfirmation: 'Add signature confirmation',
      addShipmentInsurance: 'Add shipment insurance',
      shippingCostError: 'Entered value is lower then the estimated value, are you sure to continue'
    },
    productIdentifiers: {
      key: 'Product identifiers',
      sku: 'Product SKU (Stock Keeping Unit)',
      barCode: 'Barcode (ISBN, UPC, GTIN, etc)',
      brand: 'Brand',
      brandStyle: 'Brand style #',
      numberPlaceholder: '203019',
      brandPlaceholder: 'Lucky Brand',
      brandStylePlaceholder: 'Optional',
      printLabels: 'Print Labels'
    },
    inventory: {
      key: 'Inventory',
      inventoryPosition: 'Inventory position',
      Warehouse: 'Warehouse',
      addWarehouse: 'Add Warehouse',
      continueSelling: 'Continue selling when out of stock',
      noWarehouse: 'Store has no warehouse',
      inventoryQuantity: 'Quantity'
    },
    variantions: {
      key: 'Variants',
      title: 'This product contains variants like color, size or style.',
      options: 'Options',
      addAnotherOption: 'Add another option',
      done: 'Done',
      subKey: 'Variants',
      subVariantTitle: 'Attach variant images to',
      image: 'Image',
      price: 'Price',
      quality: 'Quality',
      sku: 'SKU',
      updateRows: 'Update all rows at once',
      editColumns: 'Edit Columns',
      editOption: 'Edit options',
      tagInputHelperText: 'Press Enter or comma to create a new variant.'
    },
    errors: {
      errProductName: 'Product name is required.',
      errSalePrice: 'The sale price is required',
      errRetailPrice: 'Retail price can’t be lower then Sale price.',
      errVariantTitle: 'Variant title is required',
      errVariantField: 'Please add variants',
      errCostPerItem: 'The cost per item is required'
    },
    deleteCatalog: {
      titleSingle: 'Delete Catalog',
      title: 'Delete Catalogs',
      mainMessage: 'Are you sure want to delete this catalog from the list?',
      message: 'Are you sure want to delete',
      secondMessage: 'catalogs from the list?',
      cancel: 'Cancel',
      action: 'Yes, Delete'
    },
    deleteProductFromCatalog: {
      title: 'Remove products from catalog',
      mainMessage: 'Are you sure want to delete product from the list?',
      message: 'Are you sure want to delete',
      secondMessage: 'products from the list?',
      cancel: 'No, don’t want to',
      action: 'Yes, Delete it'
    },
    deleteProductFromFeaturedProducts: {
      title: 'Remove products',
      mainMessage: 'Are you sure you want to remove 1 product from featured list?',
      message: 'Are you sure you want to remove',
      secondMessage: 'products from featured list?',
      cancel: 'No, don’t want to',
      action: 'Yes, Remove products',
      actionSingle: 'Yes, Remove product'
    },
    discardForm: {
      title: 'Discard all unsaved changes',
      message: 'If you discard changes, you’ll delete any edits you made since you last saved.',
      cancel: 'Continue editing',
      action: 'Discard changes'
    },
    MoveWarehouse: {
      title: 'Move all products to another warehouse before delete it permanently',
      allowMe: 'Yes, Please allow me',
      allowMeText:
        'You’ve need to choose another warehouse where you want to move from this warehouse. After move all products, this warehouse will be deleted permanently.',
      deleteThem: 'No, I Want to delete them',
      deleteThemText: 'You’ll lose all the product and warehouse by choosing this option. We can’t recover them once you delete it.'
    },
    LeaveForm: {
      title: 'Unsaved changes',
      message: 'If you leave this page, any unsaved changes will be lost.',
      cancel: 'Close',
      action: 'Leave Page'
    },
    onSaveError: {
      message: 'It looks like there are some errors, please try again.',
      action: 'Okay, I’ll try again'
    },
    errorHandlingModal: {
      action: 'Print only ready orders',
      cancelMessage: 'Correct errors',
      title: 'There are some errors'
    },
    productNote: {
      deleteNote: {
        cancel: 'No, Keep Note',
        confirm: 'Yes, Delete Note',
        title: 'Are you sure, delete note?',
        message: 'Deleting a note will permanently remove it from your notes'
      },
      save: 'Save',
      placeHolder: 'Type a note . . .  ',
      updated: 'Last Updated: ',
      delete: 'Delete',
      edit: 'Edit',
      cancel: 'Cancel',
      noteTitle: 'Notes'
    }
  },
  signUp: {
    google: 'Google',
    facebook: 'Facebook',
    apple: 'Apple',
    register: 'Register account',
    createStore: 'Create Store',
    or: 'Or',
    OopsLinkExpired: 'Oops link expired!',
    LinkExpiredContent: 'Enter the email address associated with your account and we’ll send you a link to verify your email.',
    email: 'Email',
    LinkSent: 'Link sent',
    sentVerificationEmail: 'Send Verification Email',
    pleaseClickOnTheLinkSentTo: 'Please click on the link sent to ',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    fullName: 'Name',
    changeLater: '(You can change it later)',
    forgetPassword: 'Forgot Password?',
    forgetPasswordTitle: 'Forgot Password',
    resetPassword: 'Reset Password',
    sendAgain: 'Send Again',
    signIn: 'Sign in',
    sendEmail: 'Send Email',
    didntGetVerificationLink: "Didn't get the verification link?",
    checkYourMail: 'Check your email',
    newPassword: 'New Password',
    resetLinkSent: 'Reset link sent'
  },
  verifyEmail: {
    didNotVerify: 'Didn’t get the verification link ?',
    checkEmail: 'Check your email',
    sendAgain: 'Send Again',
    linkSent: 'Link sent',
    linkMessage: 'Please click on the link sent to ',
    sendVerify: 'Send Verification Email',
    expireLink: 'Oops link expired!',
    expireMessage: 'Enter the email address associated with your account and we’ll send you a link to verify your email.'
  },
  storeCreation: {
    storeName: 'Store name',
    firstName: 'First Name',
    lastName: 'Last Name'
  },
  allPurchaseOrders: {
    key: 'All Purchase Orders',
    addpurchaseButton: 'New Purchase Order',
    draft: 'Draft',
    draftColor: '#C4C4C4',
    ordered: 'Ordered',
    orderedColor: '#26B1FF',
    arrived: 'Arrived',
    arrivedColor: '#61BD4F',
    dispatched: 'Dispatched',
    dispatchedColor: '#FF9DC0',
    stocked: 'Stocked',
    stockedColor: '#079992',
    staged: 'Staged',
    stagedColor: '#FFC700',
    uploading: 'Uploading',
    uploadingColor: '#C377E0',
    stockTaking: 'Stock taking',
    stockTakingColor: '#F1416C',
    TabForAllPruchaseOrder: ['Board', 'List']
  },
  returnManagement: {
    settings: 'Settings',
    returns: 'Returns',
    addAnotherReasons: 'Add another reason',
    done: 'Done',
    resolutionOptions: {
      heading: 'Resolution options',
      exchangeHeading: 'Exchange',
      exchange: 'EXCHANGE',
      storeCreditHeading: 'Refund by store credit',
      storeCredit: 'STORE_CREDIT',
      originalPaymentMethodHeading: 'Refund by original payment method',
      originalPaymentMethod: 'ORIGINAL_PAYMENT_METHOD',
      error: 'Resolution options: At least one resolution option should be selected'
    },
    returnPolicy: 'Return policy',
    returnRules: 'Return rules',
    returnReasons: 'Return reasons',
    editReasons: 'Edit Reasons',
    returnConditions: 'Return conditions',
    allConditions: 'All conditions',
    anyConditions: 'Any conditions'
  },
  warehouseManagement: {
    title: 'Warehouses',
    addWarehouse: 'Add Warehouse',
    saveChanges: 'Save changes',
    cancel: 'Cancel',
    addNewWarehouse: 'Add New Warehouse',
    editWarehouse: 'Edit warehouse details',
    searchForWarehouse: 'Search for warehouses',
    settings: 'Settings',
    warehouseName: 'Warehouse name',
    warehouseNameExist: 'This name already exist. Please try another name.',
    invalidPhoneNumber: 'This number is invalid.',
    address: 'Address',
    addressPlaceholder: 'Add warehose address here',
    appSuite: 'Apartment, suite, etc...',
    addressLineTwo: 'Add Address line 2',
    zipCode: 'Zip code',
    zipCodePlaceholder: 'Ex. 22345',
    phone: 'Phone',
    phonePlaceHolder: 'Phone Number here',
    country: 'Country',
    countryPlaceholder: 'Select Country',
    city: 'City',
    cityPlaceholder: 'Select City',
    state: 'State',
    statePlaceholder: 'Select State',
    warehouseDeleted: ' warehouse deleted',
    warehouseDeletedText:
      'Your warehouse have been successfully deleted from the warehouse listing. If you want it back you can undo.'
  },
  headerID: 3,
  profileAltText: 'Logo',
  fileStackKey: 'A2iZHx7BWSEW7JPsUCwFIz',
  flatFile: {
    customer: {
      companyId: 'BS',
      companyName: 'BuySocial LLC.',
      email: 'trybuysocial.com',
      name: '',
      userId: ''
    }
  },
  collections: {
    archiveCollection: 'Archive Collection',
    deleteCollection: 'Delete Collection',
    deleteConfirmTitle: 'Delete Collection',
    deleteConfirmMessage: 'Are you sure you want to delete this collection? This can’t be undone.',
    collections: 'Collections',
    title: 'Collections',
    createButton: 'Create Collection',
    addNew: 'Add New',
    organization: 'Organization',
    cancel: 'Cancel',
    save: 'Save',
    collection: 'Collection',
    product: 'Products',
    collectionType: 'Collection Type',
    status: 'Status',
    expandAll: 'Expand all',
    collapseAll: 'Collapse all',
    manual: 'Manual',
    deleteNote: {
      titleOne: 'Are you sure, deleting “',
      titleTwo: '” ?',
      message: 'Deleting this collection will permanently remove it from your organization',
      cancel: 'No, Keep it',
      confirm: 'Yes, Delete'
    },
    inactive: 'inactive',
    active: 'Active',
    scheduled: 'Scheduled',
    edit: 'Edit',
    add: 'Add',
    remove: 'Remove'
  },
  taxes: {
    key: 'Taxes',
    settings: 'Settings',
    allowRecording: 'Document Recording',
    Enabled: 'Enable Avatax',
    accountConnectedMessage: 'Avalara Account Is Connected',
    accountErrorSettings:
      'We are unable to connect your Avalara account, please check your credentials and try to connect your account again!',
    company: 'Company',
    steps: {
      signUp: 'Sign up here',
      title: 'How its Work',
      stepTitle: 'To get Started, follow these steps:',
      step1: `1. Don't have an Avalara account yet? `,
      step2: '2. Login to your account and navigate to Settings -> License and API keys.',
      step3: '3. Under License key section, Click generate new Key.',
      step4: '4. Use the generated Account Id and License key to connect your store to your Avalara account.',
      step5: '5. Configure this page and save your settings.'
    },
    discardModal: {
      cancel: 'Continue editing',
      action: 'Discard changes'
    },
    hoverMessage: 'Avalara is an automated sales tax solution for calculations, registrations, collecting tax, and returns.',
    clickHere: ' Click Here ',
    moreInfo: ' for more information',
    sandbox: 'Sandbox',
    setApiKeyMessage: 'Set Avalara API Keys',
    setCompanyMessage: 'Set Avalara Company',
    setShippingCodeTitle: 'Set Avalara Shipping Tax Code',
    setProductCodeTitle: 'Set Avalara Default Product Tax Code',
    taxCode: 'Tax Code',
    searchTaxCode: 'Search Avalara Tax Codes',
    accountId: 'Account ID',
    licenseKey: 'License Key',
    connectBtn: 'Connect',
    applyBtn: 'Apply',
    modal: {
      title: 'Avalara Account',
      success: 'Connected Sucessfully!',
      message: 'Your Avalara account was connected to your store successfully'
    },
    httpLogs: 'HTTP Logs'
  },
  shipping: {
    settings: 'Settings',
    key: 'Shipping',
    connectBtn: 'Connect',
    LoginTo: 'Login to the',
    sezzeleDashboard: 'Sezzle merchant Dashboard',
    connectedMessage: 'Shippo Account Is Connected',
    clickHere: ' Click Here ',
    moreInfo: ' for more information',
    hoverMessage: 'Shippo allows you to quickly and seamlessly get real-time shipping rates and create labels for your packages. ',
    shippoMessage:
      'Please click the connect button below to connect or create your Shippo account and link it to your Buy Social store.',
    accountConnectedMessage: 'Shippo Account Is Connected',
    steps: {
      signUp: 'Sign up here',
      title: 'How its Work',
      stepTitle: 'To get Started, follow these steps:',
      step1: `1. Don't have a sezzle account yet ? `,
      step2: '2. Login to the ',
      step3:
        '3. Copy your public Key and private key from your sezzle Merchant Dashboard, Configure this page and save your settings. '
    },
    modal: {
      title: 'Shippo Account',
      success: 'Connected Sucessfully!',
      message: 'Your shippo account was connected to your store successfully'
    }
  },
  payments: {
    stripe: {
      connected: 'Stripe account is connected',
      connect: 'Connect',
      steps: {
        stepTitle: 'To get Started with Stripe, follow these steps:',
        step1: `1. Click on connect button which will redirect to stripe.`,
        step2: '2. Enter your account details and click on connect'
      }
    },
    key: 'Payments',
    settings: 'Settings',
    updateBtn: 'Update Keys',
    accountConnectedMessage: 'Sezzle Account Is Connected',
    publicKey: 'Public Key',
    privateKey: 'Private Key',
    enterPublicKey: 'Enter public key here',
    enterPrivateKey: 'Enter private key ',
    LoginTo: 'Login to the',
    sezzeleDashboard: 'Sezzle merchant Dashboard',
    connectedMessage: 'Sezzle Account Is Connected',
    sezzleIs: 'Sezzle Is',
    enabled: 'Enabled!',
    connectedSuccessfully: 'Connected successfully!',
    disabled: 'Disabled!',
    sezzleMessage: 'In Order to create a sezzle account you have to',
    disConnectedMessage: 'Sezzle Is Disabled !',
    clickHere: ' Click Here ',
    unableToConnectMessage:
      'We are unable to connect your sezzle acount, please check your keys and try to connect your account again!',
    moreInfo: ' for more information',
    hoverMessage:
      'Sezzle is a Buy Now Pay Later option that you can offer your customers. Sezzle will pay you right away for the entire order after taking a small fee.',
    sezzle: {
      steps: {
        signUp: 'Sign up here',
        title: 'How it Works',
        stepTitle: 'To get Started with sezzle, follow these steps:',
        step1: `1. Don't have a sezzle account yet ? `,
        step2: '2. Login to the ',
        step3:
          '3. Copy your public Key and private key from your sezzle Merchant Dashboard, Configure this page and save your settings. '
      }
    },
    modal: {
      title: 'Sezzle Account',
      success: 'Connected Sucessfully!',
      messageSuccess: 'Your customers will now have the option to use Sezzle at checkout.',
      messageFailure: 'Your customers will not have the option to use Sezzle at checkout.'
    }
  },
  salesChannels: {
    key: 'Sales channels',
    settings: 'Settings',
    pageName: 'Sales channels'
  },
  shopifyIntegrations: {
    key: 'Integrations',
    settings: 'Settings',
    pageName: 'Integrations',
    description: `Press 'Connect' to seamlessly integrate your Shopify store with Buy Social.`
  },
  tiktokIntegrations: {
    description: `Press 'Connect' to seamlessly integrate your Tiktok store with Buy Social.`
  },
  accountSettings: {
    pageTitle: 'Account',
    accountDetails: 'Account Details',
    email: 'Email',
    name: 'Name',
    phoneNumber: 'Phone Number',
    password: 'Password',
    changeEmail: 'Change Email',
    changePassword: 'Change Password',
    enterPassword: 'Please enter your password',
    forgotPassword: 'Forgot Password?',
    enterEmail: 'Please enter a new email',
    enterExistingEmail: 'Please enter your email',
    sendVerification: `We'll send a verification link to your new email.`,
    sentSuccess: 'Link sent successfully',
    enterOldPassword: 'Please enter your old password',
    enterNewPassword: 'Enter new password',
    confirmNewPassword: 'Confirm new password',
    passwordChangeSuccess: 'Password changed successfully',
    passwordChanged: 'Your password has been changed.'
  },
  shopDetails: {
    key: 'Shop Details',
    settings: 'Settings',
    pageName: 'Shop Details',
    discardForm: {
      title: 'Discard all unsaved changes',
      message: 'If you discard changes, you’ll delete any edits you made since you last saved.',
      cancel: 'Continue editing',
      action: 'Discard changes'
    },
    general: {
      key: 'General Details',
      storeName: 'Store Name',
      supportPhone: 'Customer Support Phone',
      supportEmail: 'Customer Support Email'
    },
    business: {
      key: 'Business details',
      businessName: 'Legal Business Name',
      address: 'Address',
      apartment: 'Apartment, Suite, Etc.',
      city: 'City',
      postalCode: 'Postal Code',
      state: 'State',
      country: 'Country'
    },
    standard: {
      key: 'Standard and formats',
      currency: 'Store Currency',
      measurement: 'Unit Of Measurement',
      weight: 'Weight Unit'
    },
    customers: {
      key: 'Customers details',
      vip: 'VIP Customer Spending Threshold'
    },
    primaryMedia: {
      key: 'Primary Recording in Media',
      description: 'When enabled, the recent recording of your product will be set as the primary video throughout the store.'
    }
  },
  orderSettings: {
    key: 'Orders',
    settings: 'Settings',
    pageName: 'Orders'
  },
  userPermissions: {
    key: 'Users & Permissions',
    settings: 'Settings',
    pageName: 'Users & Permissions'
  },
  appCustomizer: {
    key: 'App Customizer',
    settings: 'Settings',
    pageName: 'App Customizer'
  },
  customers: {
    overview: 'Overview',
    orderValue: 'Average order value',
    amountSpend: 'Total amount spent',
    productsReturned: 'Number of returns',
    storeCredit: 'Store Credit',
    addPlaceholder: 'Enter credit amount to be added here',
    subPlaceholder: 'Enter credit amount to be subtracted here',
    contactInformation: 'Contact Information',
    shippingAddress: 'Shipping Address',
    freeShipping: 'Free Shipping',
    blockedMessage: 'Customer can’t access his account.',
    unBlockedMessage: 'Customer can access his account.',
    customerIs: 'Customer is',
    addStoreCreditListPlaceholder: 'Enter value',
    entityUserCode: { heading: 'Tax Exemption Code', placeHolder: 'Type Entity/Use code . . .  ' }
  },
  orders: {
    key: 'All Orders',
    orders: 'Orders',
    order: 'Order',
    allOrders: 'All Orders',
    tempOrderId: '#1234',
    tempOrderDate: '9th May 2021, 22:46',
    tempStatus: 'To Process',
    shippingWay: 'Expedite Shopping',
    print: 'Print Packing Slips',
    rePrintLabel: 'Re-print label',
    refundLabel: 'Refund label',
    markAsProcessed: 'Mark As Processed',
    fulfillOrder: 'Fulfill order',
    fulfillOrders: 'Fulfill orders',
    printScan: 'Print scan form',
    createManifest: 'Create Manifest',
    reciept: 'Reciept',
    refund: 'Refund',
    post: 'Post',
    orderSummary: 'ORDER SUMMARY',
    combinedOrders: 'COMBINED ORDERS',
    tax: 'Tax',
    discount: 'Discount',
    shipping: 'Shipping',
    total: 'Total',
    customer: 'CUSTOMER',
    tempName: 'Jack Finnigan',
    constactInfo: 'Contact Information',
    tempMail: 'jackDoe@gmail.com',
    tempContact: '+1(521)6515151',
    shippingAddress: 'SHIPPING ADDRESS',
    tempAddress: {
      street: '701 E Larime St Houston',
      area: 'White Sulphur near by Huston TX',
      state: 'Montana(MT), 59645'
    },
    timeline: 'TIMELINE',
    placeholder: 'Leave a comments....',
    comment: '* Only you and your future staff can see comments',
    Status: {
      emailStatus: 'Email sent to John Doe',
      emailMessage: 'order confirmation message was sent to John Doe',
      mailButton: 'Resend Email',
      orderPaid: 'Order as Paid',
      orderPaidMessage: 'You manually marked this order as paid',
      itemsOversold: 'Items Oversold',
      itemsOversoldMessage: '2 itmes overold on this Order',
      orderStatusId: 'Order #1473 Created',
      orderStatusMessage: 'You createad this order for John Doe from draft order #D2',
      justNow: 'Just Now',
      tempDate: '1 Oct 2021, 20:20'
    },
    tempData: {
      productName: 'Textured Slim Trouser',
      productQty: 'White 32, Qty: 1',
      price: '$120.00',
      size: 'BA-23'
    }
  },
  orderItemStatuses: {
    Fulfilled: 'Fulfilled',
    PartialShipping: 'Partial Shipping',
    UnavailableItem: 'Unavailable item'
  },
  addProduct: {
    images: {
      type: {
        IMAGE: 'IMAGE',
        VIDEO: 'VIDEO'
      }
    },
    status: {
      DRAFT: 'DRAFT',
      ARCHIVE: 'ARCHIVE',
      ACTIVE: 'ACTIVE',
      INACTIVE: 'INACTIVE'
    },
    discardForm: {
      title: 'Discard all unsaved changes',
      message: 'If you discard changes, you’ll delete any edits you made since you last saved.',
      cancel: 'Continue editing',
      action: 'Discard changes'
    }
  },
  customerDetails: {
    addCustomerAddress: {
      title: 'Add New Address',
      titileTwo: 'Edit Address',
      receiverName: "Receiver's Name",
      receiverNamePH: 'Enter receiver name',
      emailAddress: 'Email Address',
      emailAddressPH: 'Enter email address',
      phone: 'Phone (Optional)',
      phonePH: 'Phone Number here',
      addressType: 'Address Type',
      shippingAddress: 'Shipping Address',
      country: 'Country',
      countryPH: 'Select Country',
      address: 'Address',
      addressNotVerified:
        'This address couldn’t be verified. You may be unable to buy shipping labels or set up certain delivery methods. Review the format or try re-entering your address.',
      addressPH: 'Add Address line 1',
      apartment: 'Apartment, suite, etc.',
      apartmentPH: 'Add Address line 2',
      postalCode: 'Postal code',
      postalCodePH: 'Ex. 22345',
      state: 'State',
      statePH: 'Select State',
      city: 'City',
      cityPH: 'Select city',
      defaultShippingAddress: 'Set this as my default shipping address',
      saveAddress: 'Save Address',
      cancel: 'Cancel'
    }
  },
  orderDetails: {
    product: 'Products',
    productTitle: 'Product',
    variant: 'Variants',
    unitPrice: 'Unit Price',
    quantity: 'Quantity',
    addProducts: 'Add Products',
    Total: 'Total',
    sku: 'SKU:-',
    subTotal: 'Sub Total',
    tempVariant: 'White / 32 / Cotton',
    paidBy: 'Total Paid By Customer',
    edit: 'Edit',
    save: 'Save',
    tag1: 'Tag 1',
    order: 'Order',
    paymentDetails: 'Payment Details',
    tempItems: '3 Items',
    userName: 'User Name',
    tag: 'Tags',
    toProcess: 'To Process',
    sucessMessageOne: 'Shipping address ',
    successMessageTwo: 'updated successfully!',
    deleteMessage: 'deleted successfully!',
    location: 'Location',

    orderCancelModal: {
      title: 'Refund Order',
      option1: {
        title: 'Refund by original payment method',
        message: `Refund will be cerdited to customer's original payment method`
      },
      option2: {
        title: 'Refund by Store Credit',
        message: `Refund will be cerdited to customer's store account`
      },
      cancel: 'Cancel',
      refundOrder: 'Refund order'
    },
    orderProductModal: {
      title: 'Delete or replace item from order',
      option1: {
        title: 'Replace item',
        message: `Select replacement item and add it to order.`
      },
      option2: {
        title: 'Delete item and refund customer',
        message: `Delete item from order and initiate refund.`,
        messageTwo: 'Refund by original payment method ',
        messageThree: 'Refund by store credit '
      },
      cancel: 'Cancel',
      deleteButtonTitle: 'Delete item',
      refundOrder: 'Refund order',
      replaceButtonTitle: 'Replace item'
    },
    orderDeleteToast: {
      titleOne: 'Item successfully deleted !',
      titleTwo: 'successfully cancelled and refund has been initiated',
      messageProductName: '“Leather Sneaker Men Shoes”',
      message: ` has been successfully deleted and refund to \n  selected method has been initiated.`
    },
    orderCancelToast: {
      titleOne: 'Order #',
      titleTwo: 'successfully cancelled and refund has been initiated',
      message: `Customer's account will be refunded within 5-7 business days.`
    },
    cancelOrder: 'Cancel Order',
    refundOrder: 'Refund Order',
    shippingAddress: {
      statues: {
        UnverifiedAddress: 'Unverified Address',
        ConfirmedManually: 'Confirmed manually'
      }
    }
  },
  createOrder: {
    product: 'Products',
    price: 'Price',
    items: 'Items',
    sort: 'Sort',
    sortBy: 'Sort By:',
    default: 'Default',
    date: 'Date',
    status: 'Status',
    collections: 'Collections',
    popularProducts: 'Popular Products',
    customerName: 'Customer Name',
    filter: 'Filter',
    productVendor: 'Product Vendor',
    taggedWith: 'Tagged With',
    availablity: 'Availablity',
    productType: 'Product Type',
    collection: 'Collection',
    apply: 'Apply',
    reset: 'Reset',
    itemsAdded: 'Items Added',
    cancel: 'Cancel',
    add: 'Add',
    itemsInStock: 'Items in Stock',
    inStock: 'in Stock',
    selectProduct: 'Select Product',
    addProduct: 'Add Product',
    searchPlaceHolder: 'Search or Create a customer',
    emptyNotesTitle: `You don't have any notes yet.`,
    emptyNotesMessage: `The note is empty, you can start typing the notes below.`,
    deleteDraft: 'Delete Draft Order',
    orderEmptyTitle: `Order Summary Empty`,
    orderEmptyMessage: `Please add a products to Calculate total and view payments option.`
  },
  customer: {
    customers: 'Customers',
    allCustomers: 'All customers',
    addNewCustomer: 'Add new customer'
  },
  customerOptionActiveDropdownData: [
    // {
    //   id: 1,
    //   name: 'Combine account'
    // },
    // {
    //   id: 2,
    //   name: 'Enable free shipping'
    // },
    // {
    //   id: 3,
    //   name: 'Ignore buy comments'
    // },
    {
      id: 4,
      name: 'Block customer'
    },
    {
      id: 5,
      name: 'Delete customer',
      className: 'text-danger'
    }
  ],
  customerOptionBlockDropdownData: [
    // {
    //   id: 1,
    //   name: 'Combine account'
    // },
    // {
    //   id: 2,
    //   name: 'Enable free shipping'
    // },
    // {
    //   id: 3,
    //   name: 'Ignore buy comments'
    // },
    {
      id: 4,
      name: 'Unblock customer'
    },
    {
      id: 5,
      name: 'Delete customer',
      className: 'text-danger'
    }
  ],
  confirmLabelPurchaseModal: {
    title: 'Confirm Label Purchase & Print',
    cancelTitle: 'Cancel',
    actionTitle: 'Confirm Purchase'
  },
  emptyDataList: {
    addProducts: 'Add Products',
    addProductsDescription: 'Get started by adding your first products',
    inventory: 'Inventory',
    inventoryDescription: 'Get started by adding your first products',
    inventoryAction: 'Go to products',
    collection: 'Collection',
    collectionDescription: 'Get started by creating your first collection',
    collectionAction: 'Create Collection',
    customer: 'Customer',
    customerDescription: 'Get started by adding your first products',
    customerAction: 'Add Customer',
    vendor: 'Vendors',
    vendorDescription: 'Get  started by adding your first vendor',
    vendorAction: 'Add Vendor',
    warehouse: 'Warehouse',
    warehouseDescription: 'Get started by adding your first warehouse',
    warehouseAction: 'Add Warehouse',
    order: 'No Order Found',
    catalog: 'Add Catalog',
    catalogDescription: 'Get started by creating your first catalog'
  }
};
