import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useLazyQuery, useMutation } from '@apollo/client';
import { CHECK_BACKSTORE_USER } from 'src/apollo/queries';
import { INVITE_BACKSTORE_USER, REINVITE_BACKSTORE_USER } from 'src/apollo/mutations';

// Components
import { InputBox, Dropdown, Checkbox } from 'src/components/molecules';
import { DropdownSelector, Loader } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { isEmailValid } from 'src/utils/validate';

// Icons
import { CloseIcon } from 'src/assets/icons';

// Types
import { InviteModalProps, RoleItem, InviteData } from './UserPermissions.type';

const InviteModal: FC<InviteModalProps> = ({ roles, closeModal, refetchUsers, user, inviteMode }) => {
  const [showRoleDropdown, setShowRoleDropdown] = useState(false);
  const [inviteData, setInviteData] = useState<InviteData>({
    email: '',
    role: undefined
  });
  const [selectedRole, setSelectedRole] = useState<RoleItem>();
  const [errorMessages, setErrorMessages] = useState({
    email: '',
    role: ''
  });

  useEffect(() => {
    if (user && roles) {
      const userRole = roles.find((roleItem) => roleItem.role === user.role);
      if (userRole) {
        setInviteData({
          email: user.email,
          role: userRole.id
        });
        setSelectedRole(userRole);
      }
    }
  }, [user]);

  const [checkUserData] = useLazyQuery(CHECK_BACKSTORE_USER, {
    fetchPolicy: 'network-only',
    onError: (err) => {
      setErrorMessages((errorMessages) => {
        return { ...errorMessages, email: err.message };
      });
    }
  });

  const resetData = () => {
    setInviteData({
      email: '',
      role: undefined
    });
    setSelectedRole(undefined);
  };

  const [inviteBackstoreUser, { loading: isInviteLoading }] = useMutation(INVITE_BACKSTORE_USER, {
    onCompleted: () => {
      resetData();
      refetchUsers();
      closeModal();
    },
    onError: (e) => {
      console.error(e);
      resetData();
      closeModal();
    }
  });

  const [reInviteBackstoreUser, { loading: isReInviteLoading }] = useMutation(REINVITE_BACKSTORE_USER, {
    onCompleted: (data) => {
      resetData();
      refetchUsers();
      closeModal();
    },
    onError: (e) => {
      console.error(e);
    }
  });

  const handleChangeInviteEmail = (value, fieldName) => {
    setInviteData((inviteData) => {
      return { ...inviteData, [fieldName]: value };
    });
  };

  const handleSelectRole = (value, fieldName) => {
    validateInputValues(fieldName, value);
    const currentRole = roles.find((role) => role.id === value.id);
    setInviteData((inviteData) => {
      return { ...inviteData, [fieldName]: value.id };
    });
    setSelectedRole(currentRole);
    setShowRoleDropdown(false);
  };

  const validateInputValues = (fieldName: string, value: any) => {
    if (fieldName === 'email' && !isEmailValid(value)) {
      setErrorMessages((errorMessages) => ({
        ...errorMessages,
        [fieldName]: `Please enter a valid ${fieldName}.`
      }));
      return true;
    } else if (fieldName === 'role' && !value) {
      setErrorMessages((errorMessages) => ({
        ...errorMessages,
        [fieldName]: `Please select a role.`
      }));
      return true;
    } else {
      setErrorMessages((errorMessages) => ({
        ...errorMessages,
        [fieldName]: ''
      }));
      return false;
    }
  };

  const handleInviteUser = () => {
    const errorKeys = Object.keys(errorMessages);
    for (let i = 0; i < errorKeys.length; i++) {
      validateInputValues(errorKeys[i], inviteData[errorKeys[i]]);
    }

    const isError = errorKeys.map((key) => validateInputValues(key, inviteData[key])).filter((v) => !v).length === 2 ? false : true;

    if (isError) return;

    if (inviteMode === 'invite') {
      inviteBackstoreUser({
        variables: {
          input: {
            email: inviteData.email,
            role: inviteData.role,
            notifyWhenOrderPlaced: selectedRole?.canBeNotifiedWhenOrderPlaced
          }
        }
      });
    } else {
      reInviteBackstoreUser({
        variables: {
          input: {
            email: inviteData.email,
            existingEmail: user?.email,
            role: inviteData.role,
            notifyWhenOrderPlaced: selectedRole?.canBeNotifiedWhenOrderPlaced
          }
        }
      });
    }
  };

  const handleCheckEmail = (fieldName: string, value: string) => {
    const isInValid = validateInputValues(fieldName, value);
    if (isInValid) return;
    checkUserData({
      variables: {
        input: {
          email: value,
          medium: 'EMAIL'
        }
      }
    });
  };

  const renderPermissions = (permissions) => {
    const renderSection = (section: string) => {
      switch (section) {
        case 'HOME_DASHBOARD':
          return 'Home Dashboard';
        case 'LIVESHOW':
          return 'Live show';
        case 'VENDORS_MANAGEMENT':
          return 'Vendor management';
        case 'PRODUCTS':
          return 'Products';
        case 'ORDERS':
          return 'Orders';
        case 'CUSTOMERS':
          return 'Customers';
        case 'COUPONS':
          return 'Coupons';
        case 'MARKETING':
          return 'Marketing';
        default:
          return '';
      }
    };

    const renderPermission = (permission: string, index: number) => {
      switch (permission) {
        case 'READ_ONLY':
          return index === 0 ? 'View' : ' View';
        case 'ALL_PRODUCTS':
          return index === 0 ? 'All Products' : ' All Products';
        case 'INVENTORY':
          return index === 0 ? 'Inventory' : ' Inventory';
        case 'MANAGE':
          return index === 0 ? 'Manage' : ' Manage';
        default:
          return '';
      }
    };

    return (
      <div className="role-permissions mt-4 p-4">
        <h5>Permissions</h5>
        <div>
          {permissions.map((permissionItem, index) => (
            <span className="permission-item" key={index}>
              {renderSection(permissionItem.section)}
              {!permissionItem.permissions.includes('FULL') &&
                ` (${permissionItem.permissions.map((permission, pIndex) => renderPermission(permission, pIndex))})`}
            </span>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="modal-content modal-large-content">
      <div className="modal-header">
        <h5 className="modal-title">{inviteMode === 'invite' ? 'Invite User' : 'Invite Re-send'}</h5>
        <div className="btn btn-xs btn-active-light-primary p-0 m-0 border-none" onClick={closeModal}>
          <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
        </div>
      </div>
      <div className="modal-body">
        <InputBox
          title="Email"
          value={inviteData?.email}
          onChangeText={(value) => handleChangeInviteEmail(value, 'email')}
          name="customerSupportEmail"
          inputClass="form-control-lg"
          mainclass="my-2"
          type="email"
          placeholder="Enter email"
          warningText={errorMessages?.email}
          onBlur={(event) => handleCheckEmail('email', event.target.value)}
        />
        <div className="my-4">
          <div className="input-title">Role</div>
          <div className="position-relative">
            <DropdownSelector
              className={`form-control form-control-lg  px-5 justify-content-between align-items-center cursor-pointer dropdown-box ${
                showRoleDropdown ? 'dropdown-box-active' : ''
              } ${errorMessages?.role ? 'input-error' : ''}`}
              onClick={() => setShowRoleDropdown(!showRoleDropdown)}
              selectedValue={selectedRole?.roleDisplayName}
              text="Select role"
            />
            <OutsideClickHandler onOutsideClick={() => setShowRoleDropdown(false)}>
              <Dropdown
                data={roles?.map((role) => {
                  return { id: role?.id, name: role?.roleDisplayName };
                })}
                selected={showRoleDropdown}
                value={selectedRole?.roleDisplayName}
                onSelect={(value) => handleSelectRole(value, 'role')}
                closeDropdown={() => setShowRoleDropdown(false)}
              />
            </OutsideClickHandler>
          </div>
          {errorMessages.role && <div className="warning-text p-0 fs-7">{errorMessages.role}</div>}
        </div>
        <Checkbox
          labelclassname="py-3"
          value={selectedRole?.canBeNotifiedWhenOrderPlaced}
          name="Notify when an order placed"
          isReadOnly
        />
        {selectedRole && renderPermissions(selectedRole?.permissions)}
      </div>
      <div className="modal-footer">
        <button className="btn btn-outlined-secondary btn-md" onClick={closeModal} data-bs-dismiss="modal" type="button">
          Cancel
        </button>
        <button
          className={clsx('btn btn-primary btn-md')}
          disabled={isInviteLoading || isReInviteLoading}
          onClick={handleInviteUser}
          type="button"
        >
          {isInviteLoading || isReInviteLoading ? 'Inviting' : 'Invite'}
          {(isInviteLoading || isReInviteLoading) && <Loader type="button" className="h-15px w-15px" />}
        </button>
      </div>
    </div>
  );
};

export default InviteModal;
