import { DefaultTheme } from 'styled-components';

const light: DefaultTheme = {
  name: 'light',
  colors: {
    body: '#F8F8FA',
    text: '#1E2749',
    link: '#1877f2',
    button: {
      text: 'black',
      backgroundColor: 'white',
      borderRadius: '10px',
      active: {
        text: 'white',
        backgroundColor: 'black'
      }
    }
  }
};

export default light;
