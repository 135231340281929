import { FC, useEffect, useState } from 'react';

// Components
import { InputBox } from 'src/components/molecules';

// ConstVariables
import { constVariables } from 'src/constants/constVariables';

// Types
import { ValueCCTypeSection } from 'src/components/pages/coupons/CreateCoupon/CreateCoupon.types';

const ValueCreateCoupon: FC<ValueCCTypeSection> = ({ value, setValue, errorMessages, validateInput }) => {
  const [percentageOpt, setPercentageOpt] = useState(false);
  const [valueOpt, setValueOpt] = useState(true);

  useEffect(() => {
    if (value.optionSelected === 'FLAT') {
      setValueOpt(true);
      setPercentageOpt(false);
    } else if (value.optionSelected === 'PERCENTAGE') {
      setValueOpt(false);
      setPercentageOpt(true);
    }
  }, [value.optionSelected]);

  const selectedPercentageOpt = () => {
    setValue((prev) => ({ ...prev, optionSelected: 'PERCENTAGE' }));
    if (parseInt(value.amountPercentage, 10) > 100) {
      setValue((prev) => ({ ...prev, amountPercentage: '100' }));
    }
  };
  const selectedValueOpt = () => {
    setValue((prev) => ({ ...prev, optionSelected: 'FLAT' }));
  };

  const changeValue = (value: any) => {
    if (!isNaN(value)) {
      if (percentageOpt) {
        if (value < 101) {
          setValue((prev) => ({ ...prev, amountPercentage: value }));
        }
      } else {
        setValue((prev) => ({ ...prev, amountFixed: value?.trim() }));
      }
    }
  };

  const handleKeyAmountInput = (event) => {
    if (event.keyCode === 13) {
      validateInput(event.target.value, !percentageOpt ? 'fixedAmountError' : 'percentageAmountError');
    }
  };

  const handleBlurAmountInput = (event) => {
    validateInput(event.target.value, !percentageOpt ? 'fixedAmountError' : 'percentageAmountError');
  };

  return (
    <>
      <div className="card w-100">
        <div className="section-title m-b-32">{constVariables.Coupons.AddCoupon.value.value}</div>

        <div className="d-flex align-items-center justify-content-start m-b-24">
          <div
            onClick={selectedPercentageOpt}
            className={`valueBoxes valueBoxesLeft ${percentageOpt ? 'valueBoxSelected' : 'valueBoxNotSelected'}`}
          >
            {constVariables.Coupons.AddCoupon.value.percentage}
          </div>
          <div
            onClick={selectedValueOpt}
            className={`valueBoxes valueBoxesRight  ${valueOpt ? 'valueBoxSelected' : 'valueBoxNotSelected'} `}
          >
            {constVariables.Coupons.AddCoupon.value.fixedAmount}
          </div>
        </div>
        <div className="col-4">
          <div className="d-flex align-items-center justify-content-start">
            <div className="d-flex align-items-center justify-content-between valueInput position-relative">
              <InputBox
                dollarSign={!percentageOpt && value.amountFixed.toString().length > 0}
                inputClass="valueColor d-flex align-items-center form-control-lg"
                value={percentageOpt ? value.amountPercentage : value.amountFixed}
                onChangeText={changeValue}
                name="CouponValue"
                placeholder={!percentageOpt && valueOpt ? constVariables.Coupons.AddCoupon.value.valuePlaceHolder : ''}
                charLimit={10}
                onKeyDown={handleKeyAmountInput}
                onBlur={handleBlurAmountInput}
                warningText={!percentageOpt ? errorMessages?.fixedAmountError : errorMessages?.percentageAmountError}
              />
              {percentageOpt ? (
                <div
                  className={`valuePlaceHodler percentage-value position-absolute ${value.amountPercentage ? 'valueColor' : null}`}
                >
                  %
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ValueCreateCoupon;
