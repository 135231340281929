import { gql } from '@apollo/client';

export const DELETE_FROM_RAPID_ACTION_LIST = gql`
  mutation DeleteFromRapidActionList($input: RapidActionInput!) @api(name: "authAPI") {
    deleteFromRapidActionList(input: $input) {
      __typename
      ... on GenericResponse {
        success
        message
      }
    }
  }
`;
