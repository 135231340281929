import { useState, useEffect, FC, useRef } from 'react';
import { useHistory } from 'react-router';

// Apollo
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CREATE_COLLECTION_BY_NAME,
  DELETE_COLLECTION,
  DND_UPDATE_COLLECTION,
  GET_COLLECTIONS,
  UPDATE_COLLECTION_NAME
} from 'src/apollo/queries/getCollections';

// Components
import { PageHeader, EmptyList } from 'src/components/oraganisms';
import ProductCategoryPresentational from './ProductCategory.presentational';

// Hooks && Utils && Helpers
import useTitle from 'src/utils/hooks/useTitle';

// Icons
import { NoCollectionIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables, ROUTES } from 'src/constants';

// Types
import { NodeModel } from 'src/components/molecules/TableTree/types';
import { collectionInputType } from './Collections.types';

// Styles
import './ProductCategory.scss';

const _ = require('lodash');

interface ProductCategoryProps {
  collectionData: NodeModel[];
}

const ProductCategory: FC<ProductCategoryProps> = (props) => {
  useTitle('Collections');
  const history = useHistory();
  const collectionInputRef = useRef({});

  const createCollectionBtnClick = () => {
    history.push(ROUTES.products.productCategory.collections.createCollection);
  };

  const [currentParentId, setCurrentParentId] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [showAllDetails, setShowAllDetails] = useState(false);
  const [collectionInput, setCollectionInput] = useState<collectionInputType>({
    filters: {
      searchText: ''
    },
    pageInfo: {
      limitCount: 50,
      skipCount: 0
    },
    setDroppableByStatus: false
  });

  useEffect(() => {
    if (!_.isEqual(collectionInput, collectionInputRef.current)) {
      collectionInputRef.current = collectionInput;
      getCollections({
        variables: {
          input: collectionInput
        }
      });
    } else {
      console.log('collectionInput.current is equal - ');
    }
  }, [collectionInput]);

  useEffect(() => {
    setCollectionInput({
      ...collectionInput,
      pageInfo: {
        ...collectionInput.pageInfo,
        skipCount: 0
      }
    });
  }, [collectionInput.filters.searchText]);

  const [getCollections, { data: collectionsData, loading: isLoading }] = useLazyQuery(GET_COLLECTIONS, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: (res) => {
      if (res?.getCollections) {
        setTotalCount(res?.getCollections?.totalRecords);
      }
    },
    onError: (err) => {
      console.log('err::', err);
    }
  });

  const [deleteCollection, { data: dataDeleted }] = useMutation(DELETE_COLLECTION, {
    onError: (err) => {
      console.error('deleteCollection', err);
    }
  });
  const [updateCollectionName, { data: updatedData }] = useMutation(UPDATE_COLLECTION_NAME, {
    onError: (err) => {
      console.error('updateCollectionName', err);
    }
  });
  const [dndCollectionData, { data: dndUpdatedData }] = useMutation(DND_UPDATE_COLLECTION, {
    onError: (err) => {
      console.error('dndCollectionData', err);
    }
  });
  const [createCollection, { data: createCollectionData }] = useMutation(CREATE_COLLECTION_BY_NAME, {
    onError: (err) => {
      console.error('createCollection', err);
    }
  });

  const deleteNode = (deleteId: number) => {
    deleteCollection({
      variables: {
        id: deleteId
      }
    });
  };

  const editCollection = (updateId: number, newName: string) => {
    updateCollectionName({
      variables: {
        input: {
          id: updateId,
          name: newName
        }
      }
    });
  };

  const updateDndCollection = (newdata: NodeModel[]) => {
    dndCollectionData({
      variables: {
        input: newdata
      }
    });
  };

  const addCollection = (droppableStatus: boolean, newCollectionName: string) => {
    createCollection({
      variables: {
        input: {
          parent: currentParentId === 0 ? null : Number(currentParentId),
          droppable: droppableStatus,
          text: newCollectionName,
          setDroppableByStatus: true
        }
      }
    });
    setCurrentParentId(0);
  };

  const sendParentId = (id: number) => {
    setCurrentParentId(id);
  };

  return (
    <div className="width-animation w-100 hide-scrollbar p-40">
      <PageHeader
        pageTitle={constVariables.collections.collections}
        addButton={constVariables.collections.createButton}
        onAddButton={createCollectionBtnClick}
        removeExportImport
      />
      {!isLoading && totalCount === 0 && !collectionInput.filters.searchText && (
        <EmptyList
          title={constVariables.emptyDataList.collection}
          description={constVariables.emptyDataList.collectionDescription}
          addButtonText={constVariables.emptyDataList.collectionAction}
          onAddButton={() => history.push('/products/collections/create')}
          icon={NoCollectionIcon}
        />
      )}
      <ProductCategoryPresentational
        collectionData={collectionsData?.getCollections?.collections}
        deleteNode={deleteNode}
        editCollection={editCollection}
        updateDndCollection={updateDndCollection}
        addCollection={addCollection}
        sendParentId={sendParentId}
        currentParentId={currentParentId}
        isLoading={isLoading}
        totalCount={totalCount}
        showAllDetails={showAllDetails}
        collectionInput={collectionInput}
        setCollectionInput={setCollectionInput}
      />
    </div>
  );
};

export default ProductCategory;
