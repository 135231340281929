import { gql } from '@apollo/client';

export const REINVITE_BACKSTORE_USER = gql`
  mutation ReInviteBackstoreUser($input: inviteBackstoreUser!) @api(name: "authAPI") {
    reInviteBackstoreUser(input: $input) {
      success
      message
      userId
      additionalData
    }
  }
`;
