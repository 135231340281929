export const getFormattedDate: (startTime: string, endTime: string) => string = (startTime: string, endTime: string) => {
  const startAt = new Date(startTime);
  const endAt = new Date(endTime);

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  return `${startAt.getDate()} ${monthNames[startAt.getMonth()]} ${startAt.getFullYear()} | ${startAt.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  })} - ${endAt.toLocaleString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' })}`.toString();
};
