import { FC, useEffect, useState } from 'react';

// Apollo
import { useMutation } from '@apollo/client';
import { PRINT_SHIPPING_LABEL, REFUND_SHIPPING_LABEL } from 'src/apollo/mutations';

// Components
import { FlexBox, Img, Loader } from 'src/components/atoms';
import { CustomModal, LabelPurchaseAndPrintModal } from 'src/components/oraganisms';
import RefundShippingLabel from './refundShippingLabel';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { setImageSrc } from 'src/utils/setImageSrc';
import { useToast } from 'src/utils/hooks/useToast';

// Icons
import { DefaultImage, NoShippingRateImage, RefreshIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { LableBoxProps, ShippingRateObj, ShippingRateProps } from './ShippingRatesFulfillmentCenter.types';

const ShippingRatesFulfillmentCenter: FC<ShippingRateProps> = ({
  shippingRateList,
  refetchShippingRate,
  isLoading,
  totalProducts,
  packageData,
  pkgUnitValue,
  pkgWeightValue,
  orderId,
  useWeightCheck,
  insurance,
  addShipmentInsurance,
  signatureConfirmation,
  orderShipment,
  refetchOrderDetails,
  refetchOrderShipment,
  refetchOrderList,
  selectedStatus,
  setSelectedStatus,
  openRefundModalRemotely,
  setOpenRefundModalRemotely
}) => {
  const { showToast } = useToast();

  const [selectedBlock, setSelectedBlock] = useState({
    objectId: '',
    amount: 0,
    amountLocal: 0,
    attributes: '',
    currency: '',
    currencyLocal: '',
    provider: '',
    providerImage75: '',
    providerImage200: '',
    estimatedDays: 0,
    arrivesBy: '',
    durationTerms: '',
    zone: ''
  });
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [listRefreshed, setListRefreshed] = useState(false);
  const [showRefundError, setShowRefundError] = useState(false);
  const selectBlock = (data: ShippingRateObj) => {
    setSelectedBlock(data);
  };

  useEffect(() => {
    if (openRefundModalRemotely) {
      setShowRefundModal(true);
      setOpenRefundModalRemotely(false);
    }
  }, [openRefundModalRemotely]);

  useEffect(() => {
    if (listRefreshed) {
      setTimeout(() => {
        setListRefreshed(false);
      }, 1000);
    }
  }, [listRefreshed]);

  const [printShippingLabel, { loading }] = useMutation(PRINT_SHIPPING_LABEL, {
    onCompleted: (data) => {
      if (data?.printShippingLabel?.labelURL) {
        const refundedOrderId = localStorage.getItem('refundedOrderId');
        if (refundedOrderId && parseInt(refundedOrderId, 10) === orderId) {
          localStorage.removeItem('refundedOrderId');
        }
        closePurchaseModal();
        if (refetchOrderDetails) refetchOrderDetails();
        refetchOrderShipment();
        refetchOrderList();
        const link = document.createElement('a');
        link.href = data?.printShippingLabel?.labelURL;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
      }
    },
    onError: (err) => {
      console.log({ err });
      showToast({ errorText: 'Error in printing shipping label', message: '' });
      closePurchaseModal();
    }
  });

  const [onRefundShippingLabel, { loading: refundLoading }] = useMutation(REFUND_SHIPPING_LABEL, {
    onCompleted: (data) => {
      if (data?.refundShippingLabel?.success) {
        localStorage.setItem('refundedOrderId', orderId.toString());
        showToast({
          successText: `${constVariables.FulfillmentCenter.modal.title} is ${constVariables.FulfillmentCenter.modal.success}`,
          message: constVariables.shipping.modal.message
        });
        setShowRefundError(false);
        closeRefundModal();
        setSelectedStatus(['awaiting_fulfillment']);
        if (refetchOrderDetails) refetchOrderDetails();
        refetchOrderShipment();
        refetchOrderList();
      } else setShowRefundError(true);
    },
    onError: (error) => {
      setShowRefundError(true);
    }
  });

  const onPrintShippingLabel = () => {
    const input: {
      orderId: string;
      rateId: string;
      packageInfo: string;
      rateInfo: string;
      useWeightOfItems: boolean;
      packageWeight: number;
      packageWeightUnit: string;
      needsSignatureConfirmation: boolean;
      hasInsurance: boolean;
      insurance?: {
        amount: number;
        content: string;
      };
    } = {
      orderId: orderId.toString(),
      rateId: selectedBlock.objectId,
      useWeightOfItems: useWeightCheck,
      packageInfo: JSON.stringify(packageData),
      rateInfo: JSON.stringify(selectedBlock),
      packageWeight: pkgWeightValue,
      packageWeightUnit: pkgUnitValue,
      needsSignatureConfirmation: signatureConfirmation,
      hasInsurance: addShipmentInsurance
    };

    if (addShipmentInsurance) {
      input.insurance = insurance;
    }
    printShippingLabel({
      variables: {
        input
      }
    });
  };

  useEffect(() => {
    if (orderShipment.id) {
      setSelectedBlock(JSON.parse(orderShipment.rateInfo));
    }
  }, [orderShipment]);

  const LableBox = ({ data, key }: LableBoxProps) => {
    const { provider, estimatedDays, amount, providerImage200 } = data;
    return (
      <div
        onClick={() => {
          if (!orderShipment.id) selectBlock(data);
        }}
        className={`cursor-pointer bg-white border rounded shipping-rate-item ${
          selectedBlock.objectId === data.objectId && !orderShipment.id ? 'border-primary' : null
        } `}
        key={key}
      >
        <div className="d-flex align-items-center ">
          <Img
            src={setImageSrc(providerImage200)}
            placeholderImg={DefaultImage}
            errorImg={DefaultImage}
            className="fulfilmentProfilePicture h-50px w-50px rounded shipping-rate-icon"
          />
          <div className="w-100">
            <div className={`shipping-rate-item-provider ${selectedBlock.objectId === data.objectId ? 'text-primary' : null}`}>
              {provider}
            </div>
            <div className="d-flex align-items-center justify-content-between mt-1 w-100">
              <div className="shipping-rate-item-amount">$ {amount}</div>
              <div className="shipping-rate-item-dates poppins-regular">
                <span>{estimatedDays}</span>
                <span>
                  {' '}
                  {estimatedDays === 1
                    ? constVariables.FulfillmentCenter.businessDay
                    : constVariables.FulfillmentCenter.businessDays}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const closePurchaseModal = () => {
    document.body.style.overflow = 'unset';
    setShowPurchaseModal(false);
  };

  const closeRefundModal = () => {
    document.body.style.overflow = 'unset';
    setShowRefundModal(false);
  };

  return (
    <>
      {showPurchaseModal && (
        <CustomModal bodyClassname="w-90 w-md-150" show={showPurchaseModal} closeModal={closePurchaseModal}>
          <LabelPurchaseAndPrintModal
            message={'mic check'}
            actionBtnTitle={`${constVariables.confirmLabelPurchaseModal.actionTitle} ($${selectedBlock.amount})`}
            cancelBtnTitle={constVariables.confirmLabelPurchaseModal.cancelTitle}
            actionBtnHandler={() => {
              onPrintShippingLabel();
            }}
            cancelBtnHandler={() => {
              setSelectedBlock({
                objectId: '',
                amount: 0,
                amountLocal: 0,
                attributes: '',
                currency: '',
                currencyLocal: '',
                provider: '',
                providerImage75: '',
                providerImage200: '',
                estimatedDays: 0,
                arrivesBy: '',
                durationTerms: '',
                zone: ''
              });
              closePurchaseModal();
            }}
            totalProducts={totalProducts}
            packageData={packageData}
            selectedBlock={selectedBlock}
            pkgUnitValue={pkgUnitValue}
            pkgWeightValue={pkgWeightValue}
            isLoading={loading}
          />
        </CustomModal>
      )}
      {showRefundModal && (
        <CustomModal show={showRefundModal} closeModal={closeRefundModal}>
          <RefundShippingLabel
            title={constVariables.FulfillmentCenter.refundModal.title}
            actionBtnTitle={constVariables.FulfillmentCenter.refundModal.actionButtonTitle}
            cancelBtnTitle={constVariables.FulfillmentCenter.refundModal.cancelButtonTitle}
            actionBtnHandler={() => {
              onRefundShippingLabel({
                variables: {
                  input: {
                    shipmentId: orderShipment.id.toString()
                  }
                }
              });
            }}
            cancelBtnHandler={() => {
              setShowRefundError(false);
              closeRefundModal();
            }}
            cancelOrder={true}
            loading={refundLoading}
            error={showRefundError}
          />
        </CustomModal>
      )}
      <div className="h-100 w-100 d-flex">
        <div className="d-flex flex-column flex-grow-1 w-100">
          <FlexBox className="align-items-center justify-content-between shipping-rates-header">
            <div className="poppins-regular fulfill-title">{constVariables.FulfillmentCenter.shippingRates}</div>
            {shippingRateList?.length > 0 && (
              <KTSVG
                path={RefreshIcon}
                className={`cursor-pointer ${orderShipment.id ? 'd-none' : ''}`}
                onClick={() => {
                  setListRefreshed(true);
                  refetchShippingRate();
                  setSelectedBlock({
                    objectId: '',
                    amount: 0,
                    amountLocal: 0,
                    attributes: '',
                    currency: '',
                    currencyLocal: '',
                    provider: '',
                    providerImage75: '',
                    providerImage200: '',
                    estimatedDays: 0,
                    arrivesBy: '',
                    durationTerms: '',
                    zone: ''
                  });
                }}
              />
            )}
          </FlexBox>
          <div className="d-flex flex-column flex-grow-1 h-10px overflow-scroll position-relative">
            {shippingRateList?.length > 0 || orderShipment.id ? (
              <>
                {isLoading || listRefreshed ? (
                  <Loader type="page" className="mt-20" />
                ) : (
                  <>
                    {orderShipment.id ? (
                      <LableBox data={JSON.parse(orderShipment.rateInfo)} key="label-box-0" />
                    ) : (
                      shippingRateList?.map((data, index) => (
                        <LableBox
                          data={orderShipment.id ? JSON.parse(orderShipment.rateInfo) : data}
                          key={`label-box-${index + 1}`}
                        />
                      ))
                    )}
                    {orderShipment.id ? (
                      <div>
                        <button
                          className={`btn btn-primary btn-sm w-100 mb-3 m-0`}
                          onClick={() => {
                            const link = document.createElement('a');
                            link.href = orderShipment?.labelUrl;
                            link.target = '_blank';
                            document.body.appendChild(link);
                            link.click();
                          }}
                        >
                          Re-print label
                        </button>
                        <button
                          className={`btn btn-secondary btn-sm w-100 m-0`}
                          onClick={() => {
                            setShowRefundModal(true);
                          }}
                        >
                          Refund label
                        </button>
                      </div>
                    ) : (
                      <div className="w-100 mt-2 d-flex align-items-center justify-content-center buy-shipping-label">
                        <button
                          className={`w-100 btn btn-sm ${selectedBlock.objectId !== '' ? 'btn-primary' : 'btn-secondary'} m-0`}
                          disabled={selectedBlock.objectId === ''}
                          onClick={() => {
                            setShowPurchaseModal(true);
                          }}
                        >
                          {constVariables.FulfillmentCenter.buyShippingLabel}
                        </button>
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {isLoading || listRefreshed ? (
                  <Loader type="page" className="mt-20" />
                ) : (
                  <img className="p-15" src={NoShippingRateImage} />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ShippingRatesFulfillmentCenter;
