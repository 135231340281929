import React, { FC, useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { DropdownSelector } from 'src/components/atoms';
import { InputBox, Dropdown } from 'src/components/molecules';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { InventoryItemProps } from './AddProductInventory.types';
import { UserRoles } from 'src/components/pages/settings/UserPermissions/UserPermissions.type';

const InventoryItem: FC<InventoryItemProps> = ({
  invData,
  invIndex,
  CloseWareHouse,
  OpenCloseWareHouse,
  warehouseList,
  WareHouseSelect,
  InventoryQuantityValueChange,
  tableData,
  optionChecked,
  optionDone
}) => {
  const [inputDisable, setInputDisable] = useState(false);
  const [quantity, setQuantity] = useState<number>(0);

  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  useEffect(() => {
    setQuantity(invData?.inventoryQuantityValue);
  }, [invData?.inventoryQuantityValue]);

  useEffect(() => {
    if (tableData?.length > 0 && invData?.inventoryQuantityValue && invData?.inventoryWarehouseId) {
      const filteredInventoryList = tableData
        .map((item) => item.warehouseInventory)
        .flat()
        .filter((item) => item?.warehouseId === invData?.inventoryWarehouseId?.toString())
        .filter((item) => item.quantity !== undefined && item.quantity >= 0);
      if (filteredInventoryList?.length > 0) {
        setInputDisable(true);
      } else {
        setInputDisable(false);
      }
    }
  }, [tableData]);

  useEffect(() => {
    if (optionChecked && optionDone) {
      setInputDisable(true);
    } else {
      setInputDisable(false);
    }
  }, [optionChecked, optionDone]);

  const handleQuantityKeyPress = (e) => {
    if (e.key === 'Enter') {
      InventoryQuantityValueChange(quantity.toString(), invIndex);
    }
  };

  const handleBlurQuantityInput = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.type === 'blur') {
      InventoryQuantityValueChange(quantity.toString(), invIndex);
    }
  };

  return (
    <>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <div className="text-nowrap input-title">{constVariables.common.inventory.Warehouse}</div>
        <div className="position-relative">
          <OutsideClickHandler
            onOutsideClick={() => {
              CloseWareHouse(invIndex);
            }}
          >
            <DropdownSelector
              className={`form-control form-control-lg  px-5 justify-content-between align-items-center cursor-pointer dropdown-box disable-background ${
                invData?.isDropDown ? 'dropdown-box-active' : ''
              } ${role === UserRoles.FULFILMENT_ASSOCIATE ? 'disable-background' : ''}`}
              onClick={() => {
                OpenCloseWareHouse(invIndex);
              }}
              selectedValue={invData?.inventoryWarehouseValue}
              text="Select"
            />
          </OutsideClickHandler>
          {warehouseList && (
            <Dropdown
              className="dropdown-custom-width"
              data={warehouseList}
              selected={invData?.isDropDown}
              value={invData?.inventoryWarehouseValue}
              onSelect={(dropdownData) => {
                if (invData?.inventoryWarehouseValue) return;
                WareHouseSelect(dropdownData, invIndex);
                setInputDisable(false);
              }}
              closeDropdown={() => CloseWareHouse(invIndex)}
            />
          )}
        </div>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <div className="text-nowrap input-title">{constVariables.common.inventory.inventoryQuantity}</div>
        <InputBox
          inputClass="form-control-lg"
          value={quantity}
          onChangeText={(quantity) => {
            setQuantity(quantity);
          }}
          type="number"
          min="0"
          name={constVariables.common.inventory.inventoryQuantity}
          placeholder={constVariables.common.inventory.inventoryQuantity}
          disable={inputDisable || role === UserRoles.FULFILMENT_ASSOCIATE}
          onKeyDown={handleQuantityKeyPress}
          onBlur={handleBlurQuantityInput}
        />
      </div>
    </>
  );
};

export default InventoryItem;
