import { GeneralInfo } from 'src/components/templates/GeneralInfoSection/GeneralInfoSection.types';
import { OrganizationSectionTypes } from 'src/components/templates/OrganizationSection/OrganizationSection.types';
import { StatusSectionTypes } from 'src/components/templates/StatusSection/StatusSection.types';
import { Modals } from '../../products/AddProduct/AddProduct.types';
import { RulesEngineProductListType } from 'src/components/molecules/RulesEngineProducts/RulesEngineProducts.types';

export interface CreateCollectionFormErrors {
  generalInfoTitleError: string;
}

export interface CreateCollectionPresentationalProps extends StatusSectionTypes, OrganizationSectionTypes {
  generalInfo: GeneralInfo;
  errorMessages: CreateCollectionFormErrors;
  modal: Modals;
  formChanges: boolean;
  rulesChanges: boolean;
  thumbnailUrlData: string;
  setThumbnailUrlData: React.Dispatch<React.SetStateAction<any>>;
  setGeneralInfo: React.Dispatch<React.SetStateAction<GeneralInfo>>;
  formBackBtnHandler: (...args: any) => void;
  onDiscard: (...args: any) => void;
  leavePageHandler: () => void;
  saveCollection: () => void;
  // NOTE: Used Type any given from FILESTACK
  handleSendSuccessURL?(res: any): void;
  setModal: (...args: any) => void;
  selectedProductAssignmentMethod?: productAssignmentMethod;
  allConditionMatch: boolean;
  setAllConditionMatch: React.Dispatch<React.SetStateAction<boolean>>;
  selectProductAssignmentMethod: (productAssignmentType: productAssignmentMethod) => void;
  // Rules engine
  rulesEngineConditions: {
    field: { id: number; name?: string };
    condition: string;
    category: string | { name: string; id: number }[];
    errorMessage?: string;
    openFieldDropdown?: boolean;
    openCategoryDropdown?: boolean;
    openConditionDropdown?: boolean;
  }[];
  setRulesEngineConditions: React.Dispatch<
    React.SetStateAction<
      {
        field: { id: number; name?: string };
        condition: string;
        category: string | { name: string; id: number }[];
        errorMessage?: string;
        openFieldDropdown?: boolean;
        openCategoryDropdown?: boolean;
        openConditionDropdown?: boolean;
      }[]
    >
  >;
  fieldDropdown: {
    id: number;
    name: string;
    operations: { name: string }[];
  }[];
  conditionDropdownArray: {
    categoryDropdown: { id: number; name: string }[];
  }[];
  setConditionDropdownArray: React.Dispatch<
    React.SetStateAction<
      {
        categoryDropdown: {
          name: string;
          id: number;
        }[];
      }[]
    >
  >;
  // Collection product
  setFormChanges: React.Dispatch<React.SetStateAction<boolean>>;
  setRulesChanges: React.Dispatch<React.SetStateAction<boolean>>;
  saveModuleRules: () => void;
  setColApiCall: any;
  productListData: RulesEngineProductListType;
  setProductListData: React.Dispatch<React.SetStateAction<RulesEngineProductListType>>;
}

export enum CollectionStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SCHEDULED = 'SCHEDULED'
}

export enum productAssignmentMethod {
  MANUAL = 'MANUAL',
  AUTOMATIC = 'AUTOMATIC'
}
