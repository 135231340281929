import { FC } from 'react';
import clsx from 'clsx';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import NotificationItem from './NotificationItem';

interface NotificationsDropdownProps {
  className?: string;
  showDropdown: boolean;
  topPosition: number;
  leftPosition: number;
}

const NotificationsDropdown: FC<NotificationsDropdownProps> = ({ className, showDropdown, topPosition, leftPosition }) => {
  const notifications = useAppSelector((state) => state.notifications.notifications);

  return (
    <div
      className={clsx(
        `menu menu-sub menu-background menu-sub-dropdown menu-rounded menu-gray-600 p-32 sort-dropdown position-fixed notifications-dropdown ${className}`,
        { show: showDropdown }
      )}
      style={{ top: topPosition, left: leftPosition }}
      data-kt-menu="true"
    >
      <h4 className="section-title m-b-24">All Notifications</h4>
      <div className="notifications-content">
        {notifications?.map((notificationItem, index) => (
          <NotificationItem key={index} notification={notificationItem} />
        ))}
      </div>
    </div>
  );
};

export default NotificationsDropdown;
