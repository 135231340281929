export const ROUTES = {
  authentication: {
    signup: {
      main: '/signup',
      storeCreation: '/createstore',
      forgotPassword: '/forgot-password',
      resetPassword: '/reset-password/:token',
      verifyEmail: '/verify-email/:token',
      verifyScreen: '/signup/verify',
      storeSelection: '/signup/selectstore',
      inviteSignup: '/invitee-signup/:token',
      changeEmail: '/verify-change-email/:token'
    }
  },

  Account: {
    main: '/account'
  },

  Shopify: {
    auth: '/shopify/callback'
  },

  TikTok: {
    auth: '/tiktok/callback'
  },

  products: {
    main: '/products',
    allProducts: {
      main: '/products/allProducts',
      addProduct: '/products/allProducts/add',
      editProduct: '/products/allProducts/edit/'
    },
    productCategory: {
      collections: {
        allCollections: '/products/collections',
        createCollection: '/products/collections/create',
        editCollections: '/products/collections/edit/'
      }
    },
    inventory: {
      main: '/products/inventory'
    },
    returns: {
      main: '/products/returns'
    }
  },
  customers: {
    main: '/customers',
    allCustomers: {
      main: '/customers/allCustomers',
      customerDetails: '/customers/customerDetails'
    }
  },

  orders: {
    main: '/orders',
    allOrders: {
      main: '/orders/allOrders',
      orderDetails: '/orders/orderDetails',
      createOrder: '/orders/createOrder',
      printOrder: '/orders/printOrder',
      fulfillmentCenter: '/orders/fulfillmentCenter',
      waitlists: '/orders/waitlists'
    }
  },
  liveShow: {
    main: '/liveShow',
    calendar: {
      main: '/liveShow/calendar',
      search: '/liveShow/calendar/search/'
    },
    management: {
      main: '/liveShow/management'
    }
  },
  coupons: {
    main: '/coupons',
    allCoupons: {
      main: '/coupons/allCoupons'
    },
    createCoupon: {
      main: '/coupons/createCoupon'
    }
  },
  vendorManagement: {
    main: '/vendorManagement',
    vendors: {
      main: '/vendorManagement/vendors',
      vendorDetail: '/vendorManagement/vendors/details/'
    },
    purchaseOrders: {
      main: '/vendorManagement/purchaseOrders'
    }
  },
  settings: {
    main: '/settings',
    shopDetails: {
      main: '/settings/shop-details'
    },
    shippoOnboard: {
      main: '/shippo-onboarding'
    },
    return: {
      main: '/settings/return'
    },
    warehouse: {
      main: '/settings/warehouses'
    },
    sezzle: {
      connect: '/settings/sezzle/connect'
    },
    shipping: {
      main: '/settings/shipping'
    },
    taxes: {
      main: '/settings/taxes'
    },
    payments: {
      main: '/settings/payments'
    },
    salesChannels: {
      main: '/settings/sales-channels'
    },
    orders: {
      main: '/settings/orders'
    },
    userPermissions: {
      main: '/settings/user-permissions'
    },
    appCustomizer: {
      main: '/settings/app-customizer'
    },
    integrations: {
      main: '/settings/integrations'
    }
  },
  // ADDED ROUTE FOR CATALOG IN MARKETING TAB
  marketing: {
    main: '/marketing',
    catalogs: {
      allCatalogs: '/marketing/catalogs',
      addCatalog: '/marketing/catalogs/add',
      editCatalog: '/marketing/catalogs/edit'
    },
    featuredProducts: {
      main: '/marketing/featuredProducts'
    },
    tiktokIntegration: {
      main: '/marketing/tiktok-integration'
    }
  },
  liveStreaming: {
    main: '/liveStreaming',
    stream: {
      main: '/liveStreaming/stream'
    },
    config: {
      main: '/liveStreaming/config'
    }
  },
  home: {
    main: '/home'
  },
  analytics: {
    main: '/analytics'
  }
};
