import { gql } from '@apollo/client';

export const GET_ALL_CUSTOMERS = gql`
  query GetAllCustomers($input: GetAllCustomersInput) @api(name: "productsAPI") {
    getAllCustomers(input: $input) {
      total
      customers {
        id
        name
        phoneNumber
        email
        profilePicture
        isBlocked
        isBlockedForLive
        numberOfOrders
        totalAmountSpent
        joinedOn
        locations
        userTags {
          id
          tag
          color
          bgColor
        }
        isVIPCustomer
        isFirstTimeCustomer
        shopifyId
        shopifyUrl
      }
    }
  }
`;
