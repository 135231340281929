import { createRef, FC, useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { Img, FlexBox } from 'src/components/atoms';
import { Dropdown, Checkbox } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers/components/KTSVG';
import { setImageSrc } from 'src/utils/setImageSrc';
import { tableCellClick } from 'src/utils/tableCellClick';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import { AddVariantImage, DefaultImage, DownArrowIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { CustomTableRowProps, ArrayItemsWithIndex, TableData } from '../AddProductVariant.types';
import { UserRoles } from 'src/components/pages/settings/UserPermissions/UserPermissions.type';

const commonStyle = 'table-border-right align-middle px-4 py-0';
interface CheckboCellProps {
  checkboxValue?: boolean;
  item: TableData;
  onChangeCheckBox: (arg: any) => void;
  index: number;
  isDisable: boolean;
}
interface ImageCellProps {
  img:
    | {
        id?: number | undefined;
        type: string;
        url: string;
        fileimage?: string;
      }
    | undefined;
  index: number;
  openImagesModalFun: (id: number) => void;
  isDisable: boolean;
}
interface TypeCellProps {
  variantType: string;
  index: number;
}
interface PriceCellProps {
  index: number;
  price: string;
  changeFieldValue: ({ e, fieldname, value, index }: ArrayItemsWithIndex) => void;
  isDisable: boolean;
}
interface TextCellProps {
  name: string;
  index: number;
  value: string;
  changeFieldValue: ({ e, fieldname, value, index }: ArrayItemsWithIndex) => void;
  isDisable?: boolean;
}

interface QuantityCellProps {
  index: number;
  subIndex: number;
  inventoryId?: number | undefined;
  value:
    | {
        id?: string;
        warehouseId: string | undefined;
        quantity: number | undefined;
      }[]
    | undefined;
  changeFieldValue: ({ e, fieldname, value, index }: ArrayItemsWithIndex) => void;
  isDisable: boolean;
}

interface WeightCellProps {
  index: number;
  name: string;
  value: string;
  changeFieldValue: ({ e, fieldname, value, index }: ArrayItemsWithIndex) => void;
}

interface StatusCellProps {
  index: number;
  name: string;
  value: string;
  changeFieldValue: ({ e, fieldname, value, index }: ArrayItemsWithIndex) => void;
}

const CheckboxCell: FC<CheckboCellProps> = ({ item, checkboxValue, onChangeCheckBox, index, isDisable }) => {
  return (
    <td className={`align-middle px-2 py-0 ${index !== 0 ? 'table-top-border' : 'border-top-0'}`}>
      <Checkbox
        normal
        labelclassname="form-check form-check-sm form-check-custom"
        onChangeValue={() => onChangeCheckBox(item)}
        value={checkboxValue}
        disable={isDisable}
      />
    </td>
  );
};

const ImageCell: FC<ImageCellProps> = ({ img, index, openImagesModalFun, isDisable }) => {
  const [showAdd, setShowAdd] = useState(false);

  const showAddIcon = () => {
    if (isDisable) return;
    setShowAdd(true);
  };

  const removeAddIcon = () => {
    setShowAdd(false);
  };

  const handleOpenImageModal = () => {
    if (isDisable) return;
    openImagesModalFun(index);
  };

  return (
    <td className={`align-middle px-0 py-0 fw-bolder image-cell ${index !== 0 ? 'table-top-border' : 'border-top-0'}`}>
      <div className="cursor-pointer symbol" onMouseEnter={showAddIcon} onMouseLeave={removeAddIcon} onClick={handleOpenImageModal}>
        {showAdd ? (
          <Img src={setImageSrc(AddVariantImage)} placeholderImg={AddVariantImage} errorImg={AddVariantImage} />
        ) : (
          <Img
            src={setImageSrc(
              img !== undefined && img?.fileimage ? img?.fileimage : generateCompressedImageURL(img ? img.url : '', '50')
            )}
            placeholderImg={DefaultImage}
            errorImg={DefaultImage}
            className="object-fit-scale-down bg-white border border-light border-2"
          />
        )}
      </div>
    </td>
  );
};

const TypeCell: FC<TypeCellProps> = ({ variantType, index }) => {
  return (
    <td
      className={`table-border-right align-middle px-2 py-0 main-table-text fw-bolder variant-type-cell ${
        index !== 0 ? 'table-top-border' : 'border-top-0'
      }`}
      style={{ minWidth: '150px' }}
    >
      <span className="type-text">{variantType}</span>
    </td>
  );
};

const PriceCell: FC<PriceCellProps> = ({ index, price, changeFieldValue, isDisable }) => {
  const productPriceInputRef = createRef<HTMLInputElement>();
  const cellRef = createRef<HTMLTableCellElement>();
  const [productPrice, setproductPrice] = useState('');
  const [showPriceInput, setShowPriceInput] = useState(false);

  useEffect(() => {
    setproductPrice(price);
  }, [price]);

  return (
    <td
      ref={cellRef}
      className={`${commonStyle} attribute-cell cursor-pointer focus-field ${index !== 0 ? 'table-top-border' : 'border-top-0'}`}
      onClick={() => {
        setShowPriceInput(false);
        tableCellClick(cellRef);
      }}
      onDoubleClick={() => {
        if (isDisable) return;
        setShowPriceInput(true);
      }}
    >
      <div className="price-cell">
        {showPriceInput ? (
          <>
            <span>$</span>
            <input
              autoComplete="off"
              autoFocus
              className="border-0 text-muted"
              type="text"
              ref={productPriceInputRef}
              pattern="(\d*\.\d{0,2}|\d+)"
              name="price"
              value={productPrice}
              onChange={(e) => {
                e.target.validity.valid && setproductPrice(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  productPriceInputRef?.current?.blur();
                  changeFieldValue({ e, fieldname: 'price', value: productPrice, index });
                }
              }}
              onBlur={(e) => {
                if (e.target.value === '') {
                  setproductPrice('0');
                } else {
                  changeFieldValue({ e, fieldname: 'price', value: productPrice, index });
                }
                setShowPriceInput(false);
              }}
              onFocus={(e) => {
                if (e.target.value === '0') {
                  setproductPrice('');
                }
              }}
            />
          </>
        ) : (
          <span>${productPrice}</span>
        )}
      </div>
    </td>
  );
};

const StatusCell: FC<StatusCellProps> = ({ index, value, changeFieldValue, name }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [status, setStatus] = useState('');

  useEffect(() => {
    setStatus(value);
  }, [value]);

  const selectVariantStatusClass = (productStatus: string | undefined) => {
    switch (productStatus) {
      case constVariables.ProductsTab.active.toLowerCase():
        return 'active';
      default:
        return 'inactive';
    }
  };

  const handleChangeStatus = (item, e) => {
    changeFieldValue({ e, fieldname: name, value: item.name.toUpperCase(), index });
    setStatus(item.name);
    setShowDropdown(false);
  };

  return (
    <td className={`${commonStyle} status-cell ${index !== 0 ? 'table-top-border' : 'border-top-0'}`}>
      <div className="position-relative">
        <OutsideClickHandler
          onOutsideClick={() => {
            setShowDropdown(false);
          }}
        >
          <FlexBox
            className={`p-2 poppins-medium cursor-pointer justify-content-between ${selectVariantStatusClass(
              status?.toLowerCase()
            )}-status-box`}
            onClick={() => {
              setShowDropdown(true);
            }}
          >
            <span className="variant-status">{status?.toLocaleLowerCase()}</span>
            <KTSVG path={DownArrowIcon} className={`m-auto-0 ${selectVariantStatusClass(status?.toLocaleLowerCase())}-arrow`} />
          </FlexBox>
          <Dropdown
            data={constVariables.activeInactivestatusDropdownData}
            selected={showDropdown}
            value={status}
            onSelect={handleChangeStatus}
            closeDropdown={() => setShowDropdown(false)}
          />
        </OutsideClickHandler>
      </div>
    </td>
  );
};

const TextCell: FC<TextCellProps> = ({ value, index, changeFieldValue, name, isDisable }) => {
  const inputRef = createRef<HTMLInputElement>();
  const cellRef = createRef<HTMLTableCellElement>();
  const [isShowInput, setIsShowInput] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <td
      className={`table-border-right align-middle px-4 py-2 attribute-cell cursor-pointer focus-field ${
        index !== 0 ? 'table-top-border' : 'border-top-0'
      }`}
      onDoubleClick={() => {
        if (isDisable) return;
        setIsShowInput(true);
      }}
      ref={cellRef}
      onClick={() => tableCellClick(cellRef)}
    >
      <div>
        {isShowInput ? (
          <input
            autoComplete="off"
            autoFocus
            className="border-0 text-muted"
            name={name}
            type="text"
            ref={inputRef}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Escape') {
                inputRef?.current?.blur();
                changeFieldValue({ e, fieldname: name, value: inputValue, index });
              }
            }}
            onBlur={(e) => {
              changeFieldValue({ e, fieldname: name, value: inputValue, index });
              setIsShowInput(false);
            }}
          />
        ) : (
          <span>{inputValue}</span>
        )}
      </div>
    </td>
  );
};

const QuantityCell: FC<QuantityCellProps> = ({ changeFieldValue, value, index, inventoryId, subIndex, isDisable }) => {
  // const { productId } = useParams<{ productId: string }>();
  const quantityInputRef = createRef<HTMLInputElement>();
  const cellRef = createRef<HTMLTableCellElement>();
  const [quantity, setQuantity] = useState('');
  const [showQuantityInput, setShowQuantityInput] = useState(false);

  useEffect(() => {
    // if (value && value[subIndex]) {
    //   const warehouseQuantity = value[subIndex]?.quantity;
    //   if (warehouseQuantity !== undefined) {
    //     setQuantity(warehouseQuantity.toString());
    //   }
    // }
    if (value && value[0]) {
      const warehouseQuantity = value[0]?.quantity;
      if (warehouseQuantity !== undefined) {
        setQuantity(warehouseQuantity.toString());
      }
    }
  }, [value]);

  const handleCellDoubleClick = () => {
    // if (productId) return;
    if (isDisable) return;
    setShowQuantityInput(true);
  };

  return (
    <td
      className={`${commonStyle} attribute-cell cursor-pointer focus-field ${index !== 0 ? 'table-top-border' : 'border-top-0'}`}
      onClick={() => tableCellClick(cellRef)}
      onDoubleClick={handleCellDoubleClick}
      ref={cellRef}
    >
      <div>
        {showQuantityInput ? (
          <input
            autoComplete="off"
            autoFocus
            className="border-0 text-muted"
            name="qty"
            ref={quantityInputRef}
            type="text"
            pattern="[0-9]*"
            value={quantity}
            onChange={(e) => e.target.validity.valid && setQuantity(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Escape') {
                quantityInputRef?.current?.blur();
                changeFieldValue({
                  e,
                  fieldname: 'warehouseInventory',
                  value: { warehouseId: inventoryId?.toString(), quantity: parseInt(quantity, 10) },
                  index,
                  subIndex
                });
              }
            }}
            onBlur={(e) => {
              if (e.target.value === '') {
                setQuantity('0');
              } else {
                changeFieldValue({
                  e,
                  fieldname: 'warehouseInventory',
                  value: { warehouseId: inventoryId?.toString(), quantity: parseInt(quantity, 10) },
                  index,
                  subIndex
                });
              }
              setShowQuantityInput(false);
            }}
            onFocus={(e) => {
              if (e.target.value === '0') {
                setQuantity('');
              }
            }}
          />
        ) : (
          <span>{quantity}</span>
        )}
      </div>
    </td>
  );
};

const WeightCell: FC<WeightCellProps> = ({ index, value, changeFieldValue, name }) => {
  const weightInputRef = createRef<HTMLInputElement>();
  const cellRef = createRef<HTMLTableCellElement>();
  const [weight, setWeight] = useState('');
  const [showWeightInput, setShowWeightInput] = useState(false);

  useEffect(() => {
    setWeight(value);
  }, [value]);

  return (
    <td
      className={`${commonStyle} attribute-cell cursor-pointer focus-field ${index !== 0 ? 'table-top-border' : 'border-top-0'}`}
      onClick={() => {
        setShowWeightInput(false);
        tableCellClick(cellRef);
      }}
      onDoubleClick={() => {
        setShowWeightInput(true);
      }}
      ref={cellRef}
    >
      <div className="weight-cell cursor-pointer">
        {showWeightInput ? (
          <>
            <input
              autoComplete="off"
              autoFocus
              className="border-0 text-muted"
              type="text"
              ref={weightInputRef}
              pattern="([0-9]|[1-9][0-9]{1,3}|10000)"
              name={name}
              value={weight}
              onChange={(e) => {
                e.target.validity.valid && setWeight(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  weightInputRef?.current?.blur();
                  changeFieldValue({ e, fieldname: name, value: weight, index });
                }
              }}
              onBlur={(e) => {
                if (e.target.value === '') {
                  setWeight('0');
                } else {
                  changeFieldValue({ e, fieldname: name, value: weight, index });
                }
                setShowWeightInput(false);
              }}
              onFocus={(e) => {
                if (e.target.value === '0') {
                  setWeight('');
                }
              }}
            />
            <span className="weight-measure"> kg</span>
          </>
        ) : (
          <p className="m-0 w-100">
            {weight} <span className="weight-measure"> kg</span>
          </p>
        )}
      </div>
    </td>
  );
};

const CustomTableRow: FC<CustomTableRowProps> = ({
  item,
  price,
  variantType,
  sku,
  img,
  barcode,
  status,
  weight,
  index,
  changeFieldValue,
  onChangeCheckBox,
  checkboxValue,
  openImagesModalFun,
  editColumnData,
  warehouseInventory
}) => {
  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  const defaultColumns = ['Price', 'SKU', 'Barcode', 'Status', 'Weight'];
  const renderCell = (column, key) => {
    switch (column.name) {
      case 'Price':
        return (
          <PriceCell
            key={key}
            index={index}
            price={price}
            changeFieldValue={changeFieldValue}
            isDisable={role === UserRoles.FULFILMENT_ASSOCIATE}
          />
        );
      case 'SKU':
        return (
          <TextCell
            key={key}
            value={sku}
            name="sku"
            index={index}
            changeFieldValue={changeFieldValue}
            isDisable={role === UserRoles.FULFILMENT_ASSOCIATE}
          />
        );
      case 'Barcode':
        return <TextCell key={key} value={barcode} name="barcode" index={index} changeFieldValue={changeFieldValue} />;
      case 'Status':
        return <StatusCell key={key} value={status} name="status" index={index} changeFieldValue={changeFieldValue} />;
      case 'Weight':
        return <WeightCell key={key} value={weight} name="weight" index={index} changeFieldValue={changeFieldValue} />;
      default:
        return (
          <QuantityCell
            key={key}
            index={index}
            subIndex={key}
            value={warehouseInventory}
            inventoryId={column.warehouseId}
            changeFieldValue={changeFieldValue}
            isDisable={role === UserRoles.FULFILMENT_ASSOCIATE}
          />
        );
    }
  };
  return (
    <tr className={`${checkboxValue ? 'selected-row' : ''}`}>
      <CheckboxCell
        onChangeCheckBox={onChangeCheckBox}
        item={item}
        checkboxValue={checkboxValue}
        index={index}
        isDisable={role === UserRoles.FULFILMENT_ASSOCIATE}
      />
      <ImageCell
        openImagesModalFun={openImagesModalFun}
        img={img}
        index={index}
        isDisable={role === UserRoles.FULFILMENT_ASSOCIATE}
      />
      <TypeCell variantType={variantType} index={index} />
      {editColumnData
        .filter((column) => column.status)
        // .filter((item) => defaultColumns.includes(item.name))
        .map((column, wIndex) => renderCell(column, wIndex))}
      {/* {editColumnData
        .filter((column) => column.status)
        .filter((item) => !defaultColumns.includes(item.name))
        .map((column, wIndex) => (
          <QuantityCell
            key={wIndex}
            index={index}
            subIndex={wIndex}
            value={warehouseInventory}
            inventoryId={column.warehouseId}
            changeFieldValue={changeFieldValue}
            isDisable={role === UserRoles.FULFILMENT_ASSOCIATE}
          />
        ))} */}
    </tr>
  );
};

export default CustomTableRow;
