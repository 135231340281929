import { FC, useEffect, useMemo, useState } from 'react';
import ProgressBar from '@ramonak/react-progress-bar';

import { FlexBox, Img } from 'src/components/atoms';

import { useRecordingsDownloader } from 'src/context/RecordingsDownloader/useRecordingsDownloader';

import { RecordingDownloadIcon, CancelDownloadIcon } from 'src/assets/icons';

interface Props {
  uri: string;
}

const DownloadRecordingToast: FC<Props> = ({ uri }) => {
  const { progress, uri: currentUri, onCancelDownload } = useRecordingsDownloader();
  const [showCancelIcon, setShowCancelIcon] = useState(false);

  const currentProgress = useMemo(() => {
    if (currentUri === uri) {
      return progress;
    }
    return 0;
  }, [progress, currentUri]);

  const handleClickIcon = () => {
    onCancelDownload?.(uri);
  };

  return (
    <div style={{ width: 365 }} className="download-recording-toast">
      <h5>Downloading recording</h5>
      <FlexBox className="align-items-center">
        <div className="me-3" onMouseEnter={() => setShowCancelIcon(true)} onMouseLeave={() => setShowCancelIcon(false)}>
          <div onClick={handleClickIcon}>
            <Img src={showCancelIcon ? CancelDownloadIcon : RecordingDownloadIcon} />
          </div>
        </div>
        <div className="progressbar-section">
          <ProgressBar
            completed={currentProgress.toFixed(2)}
            isLabelVisible={false}
            barContainerClassName={'download-progressbar'}
            bgColor={'#00A3FF'}
            borderRadius={'50px'}
            height={'12px'}
          />
          {currentProgress > 0 && <p className="mb-0">{Math.floor(currentProgress)}% completed</p>}
        </div>
      </FlexBox>
    </div>
  );
};

export default DownloadRecordingToast;
