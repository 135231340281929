import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../AssetHelpers';
type Props = {
  className?: string;
  path: string;
  svgClassName?: string;
  onClick?: any;
  style?: any;
  onBlur?: any;
  addSvgIconClass?: boolean;
  svgColor?: string;
  hoverSvgPath?: string;
  clickedSvgPath?: string;
  isDisabled?: boolean;
  updateClickEvent?: boolean;
  hoverClassName?: string;
};

const KTSVG: React.FC<Props> = ({
  className = '',
  path,
  svgClassName = 'mh-50px',
  onClick,
  style,
  onBlur,
  addSvgIconClass = true,
  svgColor,
  hoverSvgPath,
  clickedSvgPath,
  isDisabled,
  updateClickEvent,
  hoverClassName
}) => {
  // state parameters
  const [isHovered, setHovered] = useState<boolean>(false);
  const [isClicked, setClicked] = useState<boolean>(false);

  // Event Handlers
  const clickEventHandler = (e: any) => {
    if (!isDisabled) {
      if (onClick) {
        onClick(e);
      }
      setClicked(!isClicked);
    }
  };
  useEffect(() => {
    if (!updateClickEvent) {
      setHovered(false);
      setClicked(false);
    }
  }, [updateClickEvent]);

  const rendrWhat = () => {
    if (clickedSvgPath && isClicked) {
      return <SVG onBlur={onBlur} color={svgColor} src={toAbsoluteUrl(clickedSvgPath)} className={svgClassName} />;
    }
    if (hoverSvgPath && isHovered && !isClicked) {
      return <SVG onBlur={onBlur} color={svgColor} src={toAbsoluteUrl(hoverSvgPath)} className={svgClassName} />;
    }
    return <SVG color={svgColor} src={toAbsoluteUrl(path)} className={svgClassName} />;
  };
  return (
    <span
      className={` ${!isDisabled && addSvgIconClass ? 'svg-icon' : ''} ${className} ${
        hoverSvgPath && isHovered && !isClicked ? hoverClassName : ''
      }`}
      onClick={clickEventHandler}
      style={style}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {isDisabled ? <SVG src={toAbsoluteUrl(path)} /> : rendrWhat()}
    </span>
  );
};

export { KTSVG };
