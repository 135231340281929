import { useCallback, useEffect, useRef, FC } from 'react';

interface FileInputProps {
  handleSetFiles(files: Array<any>): void;
  children?: React.ReactNode;
  className?: string;
  isDragActive: boolean;
  setIsDragActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const FileInput: FC<FileInputProps> = (props) => {
  const { children, handleSetFiles, className, isDragActive, setIsDragActive } = props;
  const dropBoxRef = useRef<null | HTMLButtonElement>(null);

  const handleDragOver = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (!isDragActive) {
        setIsDragActive(true);
      }
    },
    [isDragActive, setIsDragActive]
  );

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      const { files } = e.dataTransfer;
      handleSetFiles(files);
    },
    [handleSetFiles]
  );

  const handleDragEnter = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      const { items } = e.dataTransfer;

      if (items && items.length > 0) {
        setIsDragActive(true);
      }
    },
    [setIsDragActive]
  );

  const handleDragLeave = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragActive(false);
    },
    [setIsDragActive]
  );

  useEffect(() => {
    const dragElement = dropBoxRef?.current;
    if (dragElement) {
      dragElement.addEventListener('dragenter', handleDragEnter);
      dragElement.addEventListener('dragleave', handleDragLeave);
      dragElement.addEventListener('dragover', handleDragOver);
      dragElement.addEventListener('drop', handleDrop);
    }

    return () => {
      dragElement?.removeEventListener('dragover', handleDragOver);
      dragElement?.removeEventListener('drop', handleDrop);
      dragElement?.removeEventListener('dragenter', handleDragEnter);
      dragElement?.removeEventListener('dragleave', handleDragLeave);
    };
  }, [handleDragEnter, handleDragLeave, handleDragOver, handleDrop]);

  return (
    <button className={className ? className : ''} ref={dropBoxRef}>
      {children}
    </button>
  );
};

export default FileInput;
