import { FC, useEffect, useState } from 'react';

interface ScanBarcodeProps {
  setValue: (value: string) => void;
}
export const ScanBarcode: FC<ScanBarcodeProps> = ({ setValue }) => {
  const [reading, setReading] = useState(false);
  useEffect(() => {
    let codeValue = '';
    document.addEventListener('keydown', function (e: any) {
      if (!reading) {
        setReading(true);
        const textInput = e.key || String.fromCharCode(e.keyCode);
        const targetName = e.target.localName;
        if (textInput !== 'Enter') {
          codeValue = codeValue + textInput;
        }
        if (textInput && textInput === 'Enter' && targetName !== 'input') {
          setValue(codeValue);
        }
        setTimeout(() => {
          codeValue = '';
          setReading(false);
        }, 1000);
      }
    });
  }, [reading]);

  return <></>;
};
