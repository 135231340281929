import { gql } from '@apollo/client';

export const DELETE_ITEM_FROM_ORDER = gql`
  mutation DeleteItemFromOrder($input: DeleteItemFromOrderInput!) @api(name: "ordersAPI") {
    deleteItemFromOrder(input: $input) {
      id
      orderCode
      customerId
      orderStatus
      customer
      customerEmail
      customerPhoneNo
      isEmailVerified
      isPhoneNoVerified
      thumbnailUrl
      orderDetails {
        id
        availableQuantity
        name
        sku
        quantity
        price {
          price
          quantity
        }
        orderItemStatus
        productId
        variantId
        imageUrl
        location
        dimensions
        items
        variantTypes {
          id
          name
          value
        }
      }
      total
      totalWeight
      items
      shippingAddress {
        id
        receiverName
        addressLine1
        city
        state
        country
        pincode
        phoneNumber
        isVerified
        type
      }
      discountAmount
      shippingAmount
      taxAmount
      subTotal
      createdAt
      updatedAt
    }
  }
`;
