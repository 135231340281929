import { gql } from '@apollo/client';

export const MARK_PRODUCT_AS_UNAVAILABLE = gql`
  mutation MarkProductAsUnavailable($input: MarkProductAsUnavailableInput!) @api(name: "productsAPI") {
    markProductAsUnavailable(input: $input) {
      id
      success
      message
    }
  }
`;
