import { useState } from 'react';
import { useHistory } from 'react-router';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useQuery } from '@apollo/client';
import { client } from 'src/apollo/client';
import { userLoginData } from 'src/apollo/reactiveVariables';
import { GET_USER_BASIC_INFO } from 'src/apollo/queries';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { Img } from 'src/components/atoms';
import { Tooltip } from 'src/components/molecules';
import AccountDropdownMenu from './AccountDropdownMenu';

// Hooks && Utils && Helpers
import { signOut } from 'src/helpers/InitFirebase';
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import { DefaultImage } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Styles
import './AsideMenuItem.scss';

const menuData = [
  { id: 1, name: 'Account Management' },
  { id: 2, name: 'Help' },
  { id: 3, name: 'Log Out' }
];

const AsideBottomMenu = () => {
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);

  // const userAvatarUrl = useAppSelector((state) => state.userInfo.userInfo.profileURL);

  const { data } = useQuery(GET_USER_BASIC_INFO, {
    variables: {
      input: {
        isBackStoreUser: true
      }
    }
  });
  const userAvatarUrl = data?.getUserBasicInfo?.userInfo?.profileURL;

  const handleSelectOption = (optionId: number) => {
    if (optionId === 1) {
      history.push('/account');
    } else if (optionId === 3) {
      client.clearStore();
      localStorage.clear();
      userLoginData({});
      signOut();
      history.replace('/login');
    }
  };

  return (
    <div className={`position-relative w-100 account-menu ${showMenu ? 'active' : ''}`}>
      <OutsideClickHandler
        onOutsideClick={() => {
          if (showMenu) setShowMenu(false);
        }}
      >
        <div className="d-flex align-items-center cursor-pointer" onClick={() => setShowMenu(true)}>
          <>
            <div data-tip data-for="myAccountMenu">
              <Img
                src={setImageSrc(userAvatarUrl ? generateCompressedImageURL(userAvatarUrl, '1024') : DefaultImage)}
                placeholderImg={DefaultImage}
                errorImg={DefaultImage}
                className="account-menu-avatar rounded-circle"
                width={30}
                height={30}
              />
            </div>
            {/* <Tooltip
              tooltipId="myAccountMenu"
              className="titleNames"
              place="right"
              content={() => {
                return <>My Account</>;
              }}
            /> */}
          </>
          <span className={`menu-title ${showMenu ? 'text-primary' : ''}`}>My Account</span>
        </div>
      </OutsideClickHandler>
      <AccountDropdownMenu isShowDropdown={showMenu} data={menuData} onSelect={handleSelectOption} />
    </div>
  );
};

export default AsideBottomMenu;
