import { gql } from '@apollo/client';

export const ON_START_LIVESTREAM = gql`
  mutation ON_START_LIVESHOW($id: String!, $runningStreamSource: RunningStreamSource!) @api(name: "liveStreamingAPI") {
    onStartingLiveStream(id: $id, runningStreamSource: $runningStreamSource) {
      medialiveChannelState
      liveVideo {
        isStreaming
        arn
        streamKey
        ingestServer
        playbackUrl
      }
      previewVideo {
        isStreaming
        arn
        streamKey
        ingestServer
        playbackUrl
      }
      runningLiveId
      runningStreamSource
      storeId
      message
      isLiveOnApp
      isLiveOnFB
      liveStartedAt
      nextShowBlockedTill
    }
  }
`;
