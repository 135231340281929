import { gql } from '@apollo/client';

export const GET_COUPONS_LIST = gql`
  query GetAllCouponsList($input: GetCouponsListInput!) @api(name: "productsAPI") {
    getAllCouponsList(input: $input) {
      total
      couponsList {
        __typename
        id
        name
        description
        status
        storeId
        couponType
        couponStatus
        couponValue
        couponAmount
        couponChannels
        buyQuantity
        getQuantity
        minAmount
        minQuantity
        uptoAmount
        isAutomaticApplied
        timesUsed
        couponStartTime
        couponExpiryTime
        freeShippingCountries
        collectionIds
        customerEligibility
        minimumRequirement
        usageLimitType
        couponUsers {
          id
          firstName
          lastName
          email
          profileUrl
        }
        customerSegment {
          id
          tag
          backgroundColorCode
          textColorCode
        }
        type
        rulesMatch
        productAssignmentRules
        productAssignmentRulesLayout
        freeShippingAmountThreshold
        productIds
        maxCouponUsage
        externalDiscountId
        externalEntitledProductIds
        externalEntitledVariantIds
        externalEntitledCollectionIds
        externalEntitledCountryIds
        externalPrerequisiteProductIds
        externalPrerequisiteVariantIds
        externalPrerequisiteCollectionIds
        externalCustomerSegmentPrerequisiteIds
        externalPrerequisiteCustomerIds
        externalPrerequisiteSubtotalRange
        externalPrerequisiteQuantityRange
        externalPrerequisiteShippingPriceRange
        externalPrerequisiteToEntitlementQuantityRatio
        externalPrerequisiteToEntitlementPurchase
        externalCustomerSelection
        externalTargetType
        externalTargetSelection
        externalAllocationMethod
        externalAllocationLimit
        externalOncePerCustomer
        externalUsageLimit
        externalDiscountProvider
      }
    }
  }
`;
