import { gql } from '@apollo/client';

export const GET_PRODUCTS_FROM_CATALOGUE = gql`
  query GetProductsFromCatalogue($input: CollectionGetInput!) @api(name: "authAPI") {
    getProductsFromCatalogue(input: $input) {
      enableCatalogue
      productIds
    }
  }
`;
