import React, { FC } from 'react';

// Components
import { Loader } from 'src/components/atoms';

// ConstVariables
import { constVariables } from 'src/constants';

// Styles
import './CustomerTaxcode.scss';

interface CustomertaxCodePresentationalProps {
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
  onSave: () => void;
  loading: boolean;
  isEditMode: boolean;
  toggleEditMode: () => void;
}
const CustomertaxCodePresentational: FC<CustomertaxCodePresentationalProps> = ({
  code,
  onSave,
  setCode,
  loading,
  isEditMode,
  toggleEditMode
}) => {
  const handleInputKeyEvent = (event) => {
    if (event.keyCode === 13) {
      if (event.target.value) {
        onSave();
      }
    }
  };

  const onKeyChange = (event) => {
    setCode(event?.target?.value);
  };

  return (
    <>
      <div className="customerTaxcodeBox">
        <div className="tax-code-heading">
          <h4 className="section-title ">{constVariables.customers.entityUserCode.heading}</h4>
          {!isEditMode && (
            <div onClick={toggleEditMode} className="fs-8 text-primary cursor-pointer tax-edit-button">
              Edit
            </div>
          )}
        </div>
        {isEditMode && (
          <div className=" d-flex align-items-center justify-content-between">
            <input
              id="kt_autosize_2"
              placeholder={constVariables.customers.entityUserCode.placeHolder}
              className="d-flex flex-grow-1 border rounded p-2  newNotesInput w-100"
              onChange={onKeyChange}
              onKeyDown={handleInputKeyEvent}
              value={code}
              onSubmit={onSave}
            />
            {loading ? (
              <Loader type="button" className="tax-spinner" />
            ) : (
              <button type="button" className="ms-2 btn btn-primary btn-sm" onClick={onSave}>
                {constVariables.common.productNote.save}
              </button>
            )}
          </div>
        )}
        {!isEditMode && <h6 className="mb-2">{code || 'TAXABLE'}</h6>}
      </div>
    </>
  );
};

export default CustomertaxCodePresentational;
