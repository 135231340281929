/* eslint-disable no-nested-ternary */
import { FC, useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';

// Components
import TagMenuItem from './TagMenuItem';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { CloseIcon } from 'src/assets/icons';

// Types
import { TagDropdownProps } from './TagAndCollectionDropdown.types';

// Styles
import './_tagAndCollectionDropdown.scss';

const TagDropdown: FC<TagDropdownProps> = ({
  allItems,
  appliedItems,
  selected,
  onlyAppliedTags,
  changeAppliedItems,
  type,
  className,
  addToTag,
  addToCollection,
  addToSubCategory,
  moveToRight,
  endItem,
  isBulkEditorModal,
  isCreatable,
  closeDropdown,
  handleDeleteCustomerTag,
  isEditAndDeletable
}) => {
  const [filter, setFilter] = useState('');

  const data: Array<{ id: number; name: string; productIds?: string[]; disabled?: boolean }> = allItems || [];

  const lowercasedFilter = filter.toLowerCase();
  function isFiltered(
    element: { id: number; name: string; productIds?: string[]; disalbed?: boolean },
    index: number,
    array: Array<{ id: number; name: string; productIds?: string[]; disabled?: boolean }>
  ) {
    return element.name?.toLowerCase().includes(lowercasedFilter);
  }

  const filteredData = (data.length > 0 && data.filter(isFiltered)) || data;

  const filtered: Array<{ id: number; name: string; productIds?: string[]; disabled?: boolean }> = [];

  for (const a in filteredData) {
    let flag = 0;
    for (const b in appliedItems) {
      if (filteredData[a]?.id !== appliedItems[b]?.id) {
        flag = flag + 1;
      }
    }
    if (flag === appliedItems?.length) {
      filtered.push(filteredData[a]);
    }
  }

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      closeDropdown();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction);

    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction]);

  const handleChange = (event) => {
    if (event.key === ',') {
      event.preventDefault();
    }
    setFilter(event.target.value);
    if (event.target.value.includes(',')) {
      const name = event.target.value.substring(0, event.target.value.indexOf(','));
      if (type === 'Collection') {
        addToCollection({
          variable: {
            input: {
              parentId: null,
              name,
              description: '',
              status: 'ACTIVE',
              type: 'MANUAL',
              thumbnailUrl: '',
              productIds: null
            }
          }
        });
      } else if (type === 'Tag') {
        addToTag({
          variable: {
            input: {
              name,
              type: 'TAG'
            }
          }
        });
      } else if (addToSubCategory) {
        addToSubCategory(name);
      }
      setFilter('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === ',') {
      event.preventDefault();
    }
    if (event.key === 'Enter') {
      const name = event.target.value.substring(0, event.target.value.indexOf(','));
      if (type === 'Collection') {
        addToCollection({
          variables: {
            input: {
              parentId: null,
              name: event.target.value.includes(',') ? name : filter,
              description: '',
              status: 'ACTIVE',
              type: 'MANUAL',
              thumbnailUrl: '',
              productIds: null
            }
          }
        });
      } else if (type === 'Tag') {
        addToTag({
          variables: {
            input: {
              name: event.target.value.includes(',') ? name : filter,
              type: 'TAG'
            }
          }
        });
      } else if (addToSubCategory) {
        addToSubCategory(filter);
      }
      setFilter('');
    }
  };

  return (
    <div
      className={clsx(
        className,
        'overflow-hidden mt-1 menu menu-sub menu-background menu-sub-dropdown menu-column menu-rounded menu-gray-600 w-100 px-0 dropdown-height',
        { show: selected },
        { 'end-0': !endItem },
        { 'position-absolute': !endItem },
        { 'position-fixed': endItem },
        { 'd-flex flex-column': selected },
        { 'dropdown-width': onlyAppliedTags },
        { 'tags-menu-dropdown': !onlyAppliedTags },
        { 'w-250px': isBulkEditorModal },
        { moveToRight }
      )}
      data-kt-menu="true"
    >
      <div
        className={clsx(
          'px-3',
          { 'border-bottom': !onlyAppliedTags },
          { 'border-bottom-secondary': !onlyAppliedTags },
          { 'py-3': appliedItems?.length > 0 }
        )}
      >
        {[
          appliedItems?.map((item: any, index: number) => {
            return (
              <span
                className={`tag-item ${type === 'Tag' ? null : 'collection-badge'} ${item?.disabled ? 'disabled-tag' : ''}`}
                key={index}
                style={
                  type === 'Tag' || type === 'Segment' || type === 'Customer Tag'
                    ? {
                        color: item?.textColorCode,
                        backgroundColor: item?.backgroundColorCode,
                        borderRadius: '4px'
                      }
                    : {}
                }
              >
                <span>{item?.name ? item?.name : ''}</span>
                {!onlyAppliedTags ? (
                  <KTSVG
                    path={CloseIcon}
                    className={`cross-color-class ${item?.disabled ? '' : 'cursor-pointer'}`}
                    onClick={() => {
                      if (!item?.disabled) {
                        changeAppliedItems(item, type, 'remove');
                      }
                    }}
                    style={{
                      '--textColorCode': item?.textColorCode ? item?.textColorCode : item?.disabled ? '#a1a5b7' : '#66728b'
                    }}
                    svgClassName={`badge-${index % 7} bg-transparent`}
                  />
                ) : null}
              </span>
            );
          })
        ]}
      </div>
      {onlyAppliedTags ? null : (
        <>
          <div className="border-bottom border-bottom-secondary search-box">
            <input
              autoComplete="off"
              type="text"
              className="form-control form-control-solid px-2 w-100"
              value={filter}
              onChange={(e) => handleChange(e)}
              onKeyDown={(e) => handleKeyDown(e)}
              name="search"
              placeholder={isCreatable ? 'Search or Create new' : 'Search'}
            />
          </div>
          <div className="overflow-scroll tags-list h-100">
            {filtered?.map((item, index) => {
              return (
                <TagMenuItem
                  key={index}
                  item={item}
                  type={type}
                  changeAppliedItems={changeAppliedItems}
                  data={data}
                  showDropdown={selected}
                  handleDeleteCustomerTag={handleDeleteCustomerTag}
                  isEditAndDeletable={isEditAndDeletable}
                />
              );
            })}
            {filteredData.filter((item) => !appliedItems?.includes(item.name)).length === 0 &&
              (isCreatable ? (
                <div className="px-2 text-center">
                  <span className="f-14px text-muted opacity-50">Press Enter or comma to create a new {type.toLowerCase()}</span>
                </div>
              ) : type === 'Segment' ? (
                <div className="px-2 text-center">
                  <span className="f-14px text-muted opacity-50">Not Found {type}</span>
                </div>
              ) : null)}
          </div>
        </>
      )}
    </div>
  );
};

export default TagDropdown;
