import { FC } from 'react';

// Components
import { Img } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import { DefaultImage } from 'src/assets/icons';

interface RenderImageListProps {
  nextLiveStream: any;
}

const RenderImageList: FC<RenderImageListProps> = ({ nextLiveStream }) => {
  return (
    <>
      {nextLiveStream.products?.length > 0 && (
        <div className="d-flex align-items-center liveshow-image-list">
          {nextLiveStream.products.map((product, index) => {
            const { productId, productReferencedId, productImage } = product;
            return index < 5 ? (
              <div key={`${productId}-${index}-${productReferencedId}-${productImage}`}>
                <Img
                  src={setImageSrc(productImage ? generateCompressedImageURL(productImage, '50') : DefaultImage)}
                  placeholderImg={DefaultImage}
                  errorImg={DefaultImage}
                  className="rounded-circle overlappingImage"
                />
              </div>
            ) : null;
          })}
          {nextLiveStream?.products?.length > 5 && (
            <div className="background-next-product-image rounded-circle overlappingImage">
              +{nextLiveStream.products.length - 5}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default RenderImageList;
