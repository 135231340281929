import { ModifiedArrayArgs, ModifiedArrayArgsWithIndex } from '../AddProductVariant.types';

export const modifyArray = ({ data, fieldname, value }: ModifiedArrayArgs): any[] => {
  let arr = [...data];
  const updatedWareHouse = (oldValue, newValue) => {
    if (oldValue?.length > 0) {
      return oldValue.map((item) => {
        return { ...item, warehouseId: newValue.warehouseId, quantity: newValue.quantity };
      });
    } else {
      return oldValue.push({ id: undefined, warehouseId: newValue.warehouseId, quantity: newValue.quantity });
    }
  };

  arr = arr.map((item, i) => {
    return {
      ...item,
      [`${fieldname}`]: fieldname === 'warehouseInventory' ? updatedWareHouse(item[fieldname], value) : value
    };
  });
  return arr;
};

export const modifyArrayItem = ({ data, fieldname, value, index }: ModifiedArrayArgsWithIndex): any[] => {
  let arr = [...data];
  arr = arr.map((item, i) => {
    if (i === index) {
      return {
        ...item,
        [`${fieldname}`]: value
      };
    }
    return item;
  });
  return arr;
};
