import { gql } from '@apollo/client';

export const GET_CATALOG_LIST = gql`
  query GetCatalogList($input: CatalogListInput!) @api(name: "productsAPI") {
    getCatalogList(input: $input) {
      totalRecords
      catalogues {
        id
        name
        link
        status
        type
        enable
        thumbnailUrl
        productIds
        createdAt
        updatedAt
        productCategory
        categoryText
        rulesMatch
        productAssignmentRules
        productAssignmentRulesLayout
      }
    }
  }
`;
