import { FC, useState } from 'react';

// Components
import { FlexBox } from 'src/components/atoms';
import Checkbox from '../Checkbox/Checkbox';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { DownArrowIcon } from 'src/assets/icons';

// Types
import { FilterRadioGroupProps } from './RightFilterMenu.types';

const FilterRadioGroup: FC<FilterRadioGroupProps> = ({ title, options, fieldName, selectedValue, updateFilterData }) => {
  const [showOptions, setShowOptions] = useState(false);

  const handleRadioSelector = (option) => {
    updateFilterData && updateFilterData(fieldName, option.textValue);
  };

  const handleClearOptions = () => {
    updateFilterData && updateFilterData(fieldName, undefined);
  };

  return (
    <div className="filter-menu-item">
      <FlexBox className="filter-menu-item-header align-items-center justify-content-between">
        <FlexBox>
          <KTSVG
            path={DownArrowIcon}
            className={`svg-icon-2 cursor-pointer me-2 ${showOptions ? '' : 'rotate-icon'}`}
            onClick={() => setShowOptions(!showOptions)}
          />
          <h6 className="m-0 poppins-regular">{title}</h6>
        </FlexBox>
        {selectedValue !== undefined && (
          <div className="cursor-pointer clear-btn poppins-regular" onClick={handleClearOptions}>
            Clear
          </div>
        )}
      </FlexBox>
      {showOptions && (
        <div className="filter-menu-item-content">
          {options?.map((option, index) => (
            <Checkbox
              key={index}
              labelclassname="filter-menu-checkbox"
              name={option.name}
              value={selectedValue === option.textValue}
              onChangeValue={() => handleRadioSelector(option)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterRadioGroup;
