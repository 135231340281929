/* eslint-disable no-nested-ternary */
import { FC, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';

// Apollo
import { useMutation } from '@apollo/client';
import { CHANGE_EMAIL } from 'src/apollo/mutations/changeEmail';

// Hooks && Utils && Helpers
import { getAccountDetailsFromToken, isTokenExpired } from 'src/utils/authentication/decodeToken';

// Icons
import { Ring } from 'src/assets/images';

// ConstVariables
import { envUrls } from 'src/constants';

// Styles
import './_verifyEmailToken.scss';
import 'src/components/pages/auth/Signup/_signUp.scss';
import 'src/components/oraganisms/CustomModal/modalStyles.scss';

const VerifyChangeEmail: FC = (props) => {
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const accountInfo = getAccountDetailsFromToken(token);
  useEffect(() => {
    changeEmail({
      context: {
        headers: {
          token,
          tenantid: accountInfo.tenantId,
          'x-api-key': envUrls.authXApiKey
        }
      },
      variables: {
        input: {
          email: accountInfo.email,
          isBackStoreUser: true
        },
        token,
        tenantid: accountInfo.tenantId
      }
    });
  }, []);

  const [changeEmail, { loading: changeMailLoading, error: changeMailError }] = useMutation(CHANGE_EMAIL, {
    fetchPolicy: 'no-cache',
    onCompleted: (responseData) => {
      setInterval(() => {
        // history.push('/login');
      }, 3000);
    },
    onError: (e) => {
      console.error(e);
    }
  });

  return (
    <>
      {isTokenExpired(accountInfo.exp) ? (
        <div className="signup d-flex gradient flex-row ">
          <div className="form-container padding-style">
            <div>Token is expired</div>
          </div>
        </div>
      ) : changeMailLoading ? (
        <div className="vw-100 vh-100 d-flex flex-column justify-content-center align-items-center">
          <div className="spinner">
            <img src={Ring} role="status"></img>
          </div>
          <p className="store-text">Processing</p>
        </div>
      ) : changeMailError ? (
        <div className="vw-100 vh-100 d-flex flex-column justify-content-center align-items-center">
          <p className="store-text">Some Error Occurred, please try again later!</p>
        </div>
      ) : (
        <div className="vw-100 vh-100 d-flex flex-column justify-content-center align-items-center">
          <p className="store-text">Email changed Successfully</p>
        </div>
      )}
    </>
  );
};

export default VerifyChangeEmail;
