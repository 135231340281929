import { gql } from '@apollo/client';

export const DELETE_MEDIA = gql`
  mutation DeleteMedia($input: DeleteMediaInput!) @api(name: "authAPI") {
    deleteMedia(input: $input) {
      success
      message
    }
  }
`;
