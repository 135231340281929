import { FC, useState, useEffect } from 'react';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import {
  CountriesCreateCoupon,
  CouponChannelsCreateCoupon,
  CouponTypeCreateCoupon,
  CustomerBuyGetCreateCoupon,
  CustomerEligibilityCreateCoupon,
  MinimumRequirementCreateCoupon,
  StatusCreateCoupon,
  SummaryCreateCoupon,
  UsageLimitsCreateCoupon,
  ValueCreateCoupon,
  RulesEngineProducts
} from 'src/components/molecules';
import { GeneralInfoCreateCoupon, TypeSection } from 'src/components/templates';

// Icons
import { BackRoundedIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { CreateCouponPresentationalProps } from './CreateCoupon.types';
import { productAssignmentMethod } from '../../collections/CreateCollection/CreateCollection.types';

const CreateCouponPresentational: FC<CreateCouponPresentationalProps> = ({
  couponId,
  generalInfo,
  setGeneralInfo,
  productListData,
  setProductListData,
  couponType,
  setCouponType,
  goBackToCouponListScreen,
  minRequirement,
  setMinRequirement,
  setCustomerEligibility,
  customerEligibility,
  setUsageLimits,
  usageLimits,
  setCouponChannel,
  couponChannel,
  summary,
  status,
  setStatus,
  setValue,
  value,
  valueType,
  setCustomerBuyGet,
  customerBuyGet,
  countries,
  setCountries,
  setCouponCollections,
  couponCollections,
  errorMessages,
  setErrorMessages,
  countryList,
  validateInput,
  selectedProductAssignmentMethod,
  selectProductAssignmentMethod,
  rulesEngineConditions,
  setRulesEngineConditions,
  fieldDropdown,
  setAllConditionMatch,
  allConditionMatch,
  conditionDropdownArray,
  setConditionDropdownArray,
  rulesChanges,
  formChanges,
  setFormChanges,
  setRulesChanges,
  saveModuleRules,
  setColApiCall,
  couponTimesError,
  setCouponTimesError
}) => {
  const [hasProductFilterConditions, setHasProductFilterConditions] = useState(false);

  useEffect(() => {
    if (rulesEngineConditions.length > 0) {
      setHasProductFilterConditions(true);
    } else {
      setHasProductFilterConditions(false);
    }
  }, [rulesEngineConditions]);

  const handleRemoveProductsFromCoupon = (ids) => {
    setFormChanges(true);
  };

  return (
    <div className={`createCouponPage width-animation w-100 d-flex flex-column hide-scrollbar  `}>
      <FlexBox className={`align-items-center ${formChanges ? 'p-t-24 p-b-40' : 'p-y-40'}`}>
        <Img src={BackRoundedIcon} alt="Back" className="me-5 h-30px w-30px cursor-pointer" onClick={goBackToCouponListScreen} />
        <div className="page-title">
          {couponId
            ? constVariables.Coupons.AddCoupon.general.editCouponCode
            : constVariables.Coupons.AddCoupon.general.createCouponCode}
        </div>
      </FlexBox>
      <div className="main-detail-layout">
        <div className="left-pane">
          <GeneralInfoCreateCoupon
            generalInfo={generalInfo}
            setGeneralInfo={setGeneralInfo}
            errorMessages={errorMessages}
            validateInput={validateInput}
          />
          <CouponTypeCreateCoupon couponType={couponType} setCouponType={setCouponType} />
          {valueType.value === 'AMOUNT_OF_PRODUCT' || valueType.value === 'AMOUNT_OF_ORDER' ? (
            <ValueCreateCoupon
              valueType={valueType}
              value={value}
              setValue={setValue}
              errorMessages={errorMessages}
              validateInput={validateInput}
            />
          ) : null}

          {/* Rules of Engine */}
          {valueType.value === 'AMOUNT_OF_PRODUCT' ? (
            <>
              <TypeSection
                title="Product Assignment method"
                showSubtitle={false}
                typeDescription="this coupon"
                selectedProductAssignmentMethod={selectedProductAssignmentMethod}
                selectProductAssignmentMethod={selectProductAssignmentMethod}
                rulesEngineConditions={rulesEngineConditions}
                setRulesEngineConditions={setRulesEngineConditions}
                fieldDropdown={fieldDropdown}
                conditionDropdownArray={conditionDropdownArray}
                setConditionDropdownArray={setConditionDropdownArray}
                allConditionMatch={allConditionMatch}
                setAllConditionMatch={setAllConditionMatch}
                setFormChanges={setFormChanges}
                setRulesChanges={setRulesChanges}
                rulesChanges={rulesChanges}
                saveModuleRules={saveModuleRules}
              />
              <RulesEngineProducts
                type="coupon"
                productListIds={
                  selectedProductAssignmentMethod === productAssignmentMethod.AUTOMATIC
                    ? productListData.autoMaticProductListIds
                    : productListData.manualProductListIds
                }
                selectedProductsIds={productListData.selectedProductsIds}
                setProductListData={setProductListData}
                hasConditions={hasProductFilterConditions}
                isAutoType={selectedProductAssignmentMethod === productAssignmentMethod.AUTOMATIC ? true : false}
                removeProducts={handleRemoveProductsFromCoupon}
                setFormChanges={setFormChanges}
                detailId={couponId}
              />
            </>
          ) : null}

          {valueType.value === 'FREE_SHIPPING' ? (
            <CountriesCreateCoupon
              countries={countries}
              setCountries={setCountries}
              countryList={countryList}
              errorMessages={errorMessages}
              validateInput={validateInput}
            />
          ) : null}
          {valueType.value === 'BUY_X_GET_Y' ? (
            <CustomerBuyGetCreateCoupon customerBuyGet={customerBuyGet} setCustomerBuyGet={setCustomerBuyGet} />
          ) : null}

          <MinimumRequirementCreateCoupon
            minRequirement={minRequirement}
            setMinRequirement={setMinRequirement}
            errorMessages={errorMessages}
            setErrorMessages={setErrorMessages}
            validateInput={validateInput}
          />
          <CustomerEligibilityCreateCoupon
            customerEligibility={customerEligibility}
            setCustomerEligibility={setCustomerEligibility}
            errorMessages={errorMessages}
          />
          <UsageLimitsCreateCoupon
            usageLimits={usageLimits}
            setUsageLimits={setUsageLimits}
            errorMessages={errorMessages}
            validateInput={validateInput}
          />
        </div>

        <div className="right-pane">
          {/* RIGHT */}
          <StatusCreateCoupon
            setStatus={setStatus}
            status={status}
            setFormChanges={setFormChanges}
            couponTimesError={couponTimesError}
            setCouponTimesError={setCouponTimesError}
          />
          <CouponChannelsCreateCoupon couponChannel={couponChannel} setCouponChannel={setCouponChannel} />
          {/* <CouponsCollection setCouponCollections={setCouponCollections} couponCollections={couponCollections} /> */}
          {/* <SummaryCreateCoupon summary={summary} /> */}
        </div>
      </div>
    </div>
  );
};

export default CreateCouponPresentational;
