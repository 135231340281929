import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

// Apollo
import { useMutation, useQuery } from '@apollo/client';
import { GET_VENDOR_COLUMN } from 'src/apollo/queries';
import { ADD_OR_EDIT_STORE_VENDOR_COLUMNS } from 'src/apollo/mutations';

// Components
import VendorManagementPresentational from './VendorManagement.presentational';

const VendorManagement: FC = () => {
  const history = useHistory();
  const [vendorDetailsOpen, setVendorDetailsOpen] = useState(false);
  const [refetchList, setRefetchList] = useState(false);
  const [selectedTab] = useState('ALL VENDORS');
  const [addVendorModal, setAddVendorModal] = useState(false);
  const [openEditColumnDropdown, setOpenEditColumnDropdown] = useState(false);
  const [editColumnData, setEditColumnData] = useState<
    {
      name: string;
      status: boolean;
    }[]
  >([
    { name: 'Vendor category', status: true },
    { name: 'Relationship start date', status: false },
    { name: 'Budget', status: false },
    { name: 'Rank tier', status: false },
    { name: 'Total spending', status: true },
    { name: 'Performance', status: false },
    { name: 'Relationship end date', status: false },
    { name: 'Vendor manager', status: false },
    { name: 'Location', status: false },
    { name: 'Total benefit', status: false },
    { name: 'Account number', status: false }
  ]);

  const handleVendorDetails = (e: React.MouseEvent, vendor: any) => {
    setVendorDetailsOpen(true);
    e.preventDefault();
    history.push(`/vendorManagement/vendors/details/${vendor?.id}`);
  };

  const { data: cols, refetch } = useQuery(GET_VENDOR_COLUMN);
  const [callEditColumns, { data: editData }] = useMutation(ADD_OR_EDIT_STORE_VENDOR_COLUMNS);

  useEffect(() => {
    if (history?.location?.state?.isAddModal) {
      setAddVendorModal(true);
    }
  }, [history]);

  useEffect(() => {
    if (cols?.getStoreVendorColumns?.column) {
      const arr = Array.from(editColumnData);
      JSON.parse(cols?.getStoreVendorColumns?.column).map((item, index) => {
        const obj = arr.find(({ name }) => name === item.name);
        if (obj) {
          const i = arr.indexOf(obj);
          arr[i] = item;
        }
        return null;
      });
      setEditColumnData([...arr]);
    }
  }, [cols]);

  useEffect(() => {
    if (editColumnData.length > 0)
      callEditColumns({
        variables: {
          input: {
            column: JSON.stringify(editColumnData)
          }
        },
        errorPolicy: 'ignore'
      });
    // eslint-disable-next-line
  }, [editColumnData]);

  useEffect(() => {
    if (editData?.addOrEditStoreVendorColumns?.success) {
      refetch();
    }
  }, [editData]);

  return (
    <VendorManagementPresentational
      vendorDetailsOpen={vendorDetailsOpen}
      handleVendorDetails={handleVendorDetails}
      addVendor={() => {
        setAddVendorModal(!addVendorModal);
        setRefetchList(!refetchList);
      }}
      addVendorModal={addVendorModal}
      selectedIntTab={selectedTab}
      refetchList={refetchList}
      openEditColumnDropdown={openEditColumnDropdown}
      setOpenEditColumnDropdown={setOpenEditColumnDropdown}
      editColumnData={editColumnData}
      setEditColumnData={setEditColumnData}
    />
  );
};

export default VendorManagement;
