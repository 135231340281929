import { FC, useState, createRef } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { Dropdown, Portal } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers/components/KTSVG';

// Icons
import { ThreeDotsIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants/constVariables';

interface ActionMenuProps {
  level: string;
  productId: number;
  variantId?: number;
  handleActionMenus?: (level: string, productId: number, printType: string, variantId?: number) => void;
}

const ActionMenu: FC<ActionMenuProps> = ({ level, productId, variantId, handleActionMenus }) => {
  const menuBtnRef = createRef<any>();
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownPositions, setDropdownPositions] = useState({
    top: 0,
    left: 0
  });

  const handleShowDropdown = () => {
    setShowDropdown(true);

    if (menuBtnRef.current) {
      const position = menuBtnRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - position.bottom;
      setDropdownPositions({
        top: spaceBelow < 150 ? window.scrollY + position.top - 110 : window.scrollY + position.top + 30,
        left: position.left - 120
      });
    }
  };

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  const handleActions = (option) => {
    setShowDropdown(false);
    handleActionMenus && handleActionMenus(level, productId, option.id === 1 ? 'sku' : 'barcode', variantId);
  };

  return (
    <>
      <button onClick={handleShowDropdown} className="bg-transparent border border-transparent" ref={menuBtnRef}>
        <KTSVG path={ThreeDotsIcon} svgClassName="cursor-pointer collapseFilter collapseIcon" />
      </button>
      {showDropdown && (
        <Portal id="kt_body">
          <OutsideClickHandler onOutsideClick={closeDropdown}>
            <div className="position-absolute" style={{ top: dropdownPositions.top, left: dropdownPositions.left }}>
              <Dropdown
                data={constVariables.productDetailsMoreActionDropDownData}
                selected={showDropdown}
                onSelect={handleActions}
                closeDropdown={closeDropdown}
              />
            </div>
          </OutsideClickHandler>
        </Portal>
      )}
    </>
  );
};

export default ActionMenu;
