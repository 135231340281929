import { gql } from '@apollo/client';

export const ASSOCIATE_LOCATION_PRODUCT = gql`
  mutation onAssociateLocationProduct($input: AssociateLocationProductInput!) @api(name: "productsAPI") {
    associateLocationProduct(input: $input) {
      success
      message
      additionalData
    }
  }
`;
