import { gql } from '@apollo/client';

export const UPDATE_AVALARA_ACCOUNT_SETTINGS = gql`
  mutation UpdateAvalaraConfiguration($input: UpdateAvalaraConfigurationInput!) @api(name: "authAPI") {
    updateAvalaraConfig(input: $input) {
      __typename
      isConnected
      companyCode
      companyName
      companyCountry
      isSandbox
      defaultShippingTaxCode
      defaultProductTaxCode
      allowDocumentRecording
      isAvalaraEnabled
      availableCompanies {
        companyCode
        name
      }
    }
  }
`;
