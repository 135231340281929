import { FC, useState, useEffect, createRef } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useMutation, useQuery } from '@apollo/client';
import { GET_TAGS_LIST } from 'src/apollo/queries';
import { CREATE_COLLECTION_OR_TAG_OR_CATALOGUE } from 'src/apollo/mutations';

// Components
import { TagAndCollectionDropdown, CustomerTags, Portal } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { useToast } from 'src/utils/hooks/useToast';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { TagProps } from './OrganizationSection.types';

const Tags: FC<TagProps> = ({ appliedTags, setAppliedTags, isDisable }) => {
  const containerRef = createRef<any>();
  const [openTags, setOpenTags] = useState<boolean>(false);
  const [dropdownPositions, setDropdownPositions] = useState({
    top: 0,
    left: 0,
    width: 0
  });
  const [tags, setTags] = useState([]);

  const [addToTag, { data: addToTagData }] = useMutation(CREATE_COLLECTION_OR_TAG_OR_CATALOGUE, {
    onError: (error) => {
      showToast({
        errorText: error.message,
        message: `Error occured while adding Tag: ${error.message}`
      });
    },
    onCompleted: (response) => {
      if (response) {
        showToast({ successText: 'Tag added successfully', message: `The Tag has been added sucessfully` });
      }
    }
  });

  const { showToast } = useToast();

  const { refetch: refetchTagData } = useQuery(GET_TAGS_LIST, {
    variables: {
      input: {
        filters: {
          searchText: ''
        }
      }
    },
    onCompleted: (res) => {
      if (res?.getTagList) {
        const updatesTags = res?.getTagList?.map((tagItem) => {
          return { ...tagItem, productIds: tagItem?.products?.map((product) => product.id) };
        });
        setTags(updatesTags);
      }
    }
  });

  const changeAppliedTagsAndCollection = (item: any, type: string, action: string) => {
    const arr: any = appliedTags && Array.from(appliedTags);

    if (action === 'remove') {
      const i = arr.indexOf(item);
      arr.splice(i, 1);
    } else {
      arr.push(item);
    }

    refetchTagData();
    setAppliedTags && setAppliedTags([...arr]);
  };
  useEffect(() => {
    if (addToTagData?.createCollectionOrCatalogueOrTag?.success) {
      const item = {
        id: addToTagData?.createCollectionOrCatalogueOrTag?.id,
        name: addToTagData?.createCollectionOrCatalogueOrTag?.name,
        textColorCode: addToTagData?.createCollectionOrCatalogueOrTag?.textColorCode,
        backgroundColorCode: addToTagData?.createCollectionOrCatalogueOrTag?.backgroundColorCode
      };
      if (!appliedTags?.find((x) => x.id === item.id)) {
        changeAppliedTagsAndCollection(item, 'Tag', 'add');
        refetchTagData();
      }
    }
  }, [addToTagData]);

  const handleOpenDropdown = (e) => {
    e.stopPropagation();
    setOpenTags(true);

    if (containerRef.current) {
      const position = containerRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - position.bottom;
      setDropdownPositions({
        top: spaceBelow < 400 ? window.scrollY + position.top - 363 : window.scrollY + position.top + position.height,
        left: position.left,
        width: position.width
      });
    }
  };

  return (
    <div>
      <div className="text-nowrap input-title m-t-24">{constVariables.common.addOrganization.tags}</div>
      <div className={`position-relative ${isDisable ? 'disable-background' : ''}`} ref={containerRef}>
        <div
          onClick={handleOpenDropdown}
          className="flex-nowrap d-flex align-items-center cursor-pointer h-40px w-100 rounded tags-list-container"
        >
          {appliedTags && appliedTags?.length > 0 ? <CustomerTags isProduct={true} userTags={appliedTags} /> : ''}
        </div>
      </div>
      {openTags && (
        <Portal id="kt_body">
          <OutsideClickHandler
            onOutsideClick={() => {
              setOpenTags(false);
            }}
          >
            <div
              className="position-absolute"
              style={{ top: dropdownPositions.top, left: dropdownPositions.left, width: dropdownPositions.width }}
            >
              <TagAndCollectionDropdown
                type="Tag"
                allItems={tags}
                appliedItems={appliedTags}
                changeAppliedItems={changeAppliedTagsAndCollection}
                selected={openTags}
                addToTag={addToTag}
                isCreatable
                isEditAndDeletable={true}
                closeDropdown={() => setOpenTags(false)}
              />
            </div>
          </OutsideClickHandler>
        </Portal>
      )}
    </div>
  );
};

export default Tags;
