import { gql } from '@apollo/client';
export const GET_USER_BASIC_INFO = gql`
  query getUserBasicInfoQuery($input: GetUserBasicInfoInput!) @api(name: "authAPI") {
    getUserBasicInfo(input: $input) {
      userInfo {
        __typename
        userId
        email
        isEmailVerified
        firstName
        lastName
        profileURL
        storeId
        storeName
        userPhoneNumber
        roleDisplayName
        medium
        role
        permission {
          section
          permissions
        }
      }
    }
  }
`;
