import { gql } from '@apollo/client';

export const CREATE_PRODUCT_CATEGORIES = gql`
  mutation onCreateProductCategories($input: CreateProductCategoryInput!) @api(name: "productsAPI") {
    createProductCategory(input: $input) {
      id
      name
    }
  }
`;
