export const cacheWithAsyncRefresh: any = async (asyncSupplier: any) => {
  let value;

  const asyncRefresh = async () => (value = await asyncSupplier());

  // Warm cache
  await asyncRefresh();

  return () => {
    // eslint-disable-next-line dot-notation
    asyncRefresh().catch(console.error);
    return value;
  };
};
