import { TypedDocumentNode, gql } from '@apollo/client';
import { IAvalaraLog } from 'src/components/pages/settings/Taxes/Taxes.types';

export const GET_AVALARA_LOGS: TypedDocumentNode<{
  getAvalaraLogs: IAvalaraLog[];
}> = gql`
  query GetAvalaraLogs @api(name: "authAPI") {
    getAvalaraLogs {
      __typename
      id
      url
      httpMethod
      requestHeaders
      request
      response
      responseTime
      createdAt
    }
  }
`;
