import { gql } from '@apollo/client';

export const DELETE_FROM_CART = gql`
  mutation DeleteFromCart($productId: Int!) @api(name: "productsAPI") {
    deleteFromCart(productId: $productId) {
      success
      message
    }
  }
`;
