import moment from 'moment';

export const pad = (n: number | string): string => {
  if (parseInt(n.toString(), 10) > 9) {
    return `${n}`;
  } else {
    return `0${n}`;
  }
};

export const countDown: (startAt: string) => string = (startAt: string) => {
  const timeNow = moment(new Date());

  const seconds = moment(startAt).diff(timeNow, 'seconds');

  const duration = moment.duration(seconds, 'seconds');

  if (duration.days() || duration.hours() || duration.minutes() || duration.seconds()) {
    if (duration.days() > 0) {
      return `Go Live in ${duration.days()}d:${pad(duration.hours())}h:${pad(duration.minutes())}:${pad(duration.seconds())}!`;
    } else {
      if (duration.hours() > 0) {
        return `Go Live in ${pad(duration.hours())}h:${pad(duration.minutes())}:${pad(duration.seconds())}!`;
      } else {
        return `Go Live in ${pad(duration.minutes())}:${pad(duration.seconds())}!`;
      }
    }
  }

  return '';
};

export const getTimeElapse: (startAt: string) => moment.Duration = (startAt: string) => {
  const timeNow = moment(new Date());

  const seconds = moment(timeNow).diff(startAt, 'seconds');

  const duration = moment.duration(seconds, 'seconds');

  return duration;
};
