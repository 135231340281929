import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html, body, ol, ul {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }
  html {
    font-size: 16px;
  }
  body {
    background: ${({ theme }) => theme.colors.body};
    color: ${({ theme }) => theme.colors.text};
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'poppins','poppins-black', 'poppins-bold',
    'poppins-extrabold', 'poppins-medium', 'poppins-light',
    'poppins-regular', 'poppins-semibold', 'sans-serif';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-display: block;
  }
  #root {
    height: 100%;
    width: 100%;
  }
`;
export default GlobalStyle;
