import { FC } from 'react';
import clsx from 'clsx';

// Components
import { FlexBox, Img, Loader } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { setImageSrc } from 'src/utils/setImageSrc';

// Icons
import { CloseIcon, DefaultImage, PrinterWhite } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { LabelPurchaseAndPrintModalProps } from './Modal.types';

// Styles
import './modalStyles.scss';

const LabelPurchaseAndPrintModal: FC<LabelPurchaseAndPrintModalProps> = ({
  message,
  actionBtnTitle,
  cancelBtnTitle,
  actionBtnHandler,
  cancelBtnHandler,
  totalProducts,
  packageData,
  selectedBlock,
  pkgUnitValue,
  pkgWeightValue,
  isLoading
}) => {
  return (
    <div className={clsx('modal-content w-740px')}>
      {isLoading ? (
        <div className=" position-relative">
          <div
            className="btn btn-xs btn-active-light-primary p-0 m-0 position-absolute"
            style={{ top: 20, right: 20 }}
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <KTSVG path={CloseIcon} className="svg-icon svg-icon-2x m-0" onClick={cancelBtnHandler} />
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: 244 }}>
            <Loader type="page" />
            <h2 className="text-center m-t-20">Shipping label is being purchased...</h2>
          </div>
        </div>
      ) : (
        <>
          <div className="modal-header">
            <h5 className="modal-title">{constVariables.confirmLabelPurchaseModal.title}</h5>
            <div className="btn btn-xs btn-active-light-primary p-0 m-0 border-none" onClick={cancelBtnHandler}>
              <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
            </div>
          </div>
          <div className={clsx('modal-body p-x-30 p-y-36')}>
            <FlexBox className="align-items-center">
              <Img
                src={setImageSrc(selectedBlock.providerImage200)}
                placeholderImg={DefaultImage}
                errorImg={DefaultImage}
                height={80}
                width={80}
              />
              <div className="ms-10">
                <p className="mb-3 fs-5 poppins-semibold">{selectedBlock.provider}</p>
                <FlexBox>
                  <div>
                    <p className="mb-2 gray-value-color">
                      <span className="label-text-color">Dimensions:</span> {packageData.length} x {packageData.width} x{' '}
                      {packageData.height} {packageData.distanceUnit}
                    </p>
                    <p className="mb-0 gray-value-color">
                      <span className="label-text-color">Price of label:</span> ${selectedBlock.amount}
                    </p>
                  </div>
                  <div className="ms-10">
                    <p className="mb-2 gray-value-color">
                      <span className="label-text-color">No of products:</span> {totalProducts}
                    </p>
                    <p className="mb-0 gray-value-color">
                      <span className="label-text-color">Total weight:</span> {pkgWeightValue} {pkgUnitValue}
                    </p>
                  </div>
                </FlexBox>
              </div>
            </FlexBox>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light btn-md" data-bs-dismiss="modal" onClick={cancelBtnHandler}>
              {cancelBtnTitle}
            </button>
            <button type="button" className="btn btn-primary btn-md" onClick={actionBtnHandler}>
              <KTSVG path={PrinterWhite} className="me-3" />
              {actionBtnTitle}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default LabelPurchaseAndPrintModal;
