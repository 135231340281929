import { gql } from '@apollo/client';

export const DELETE_USER_ADDRESS = gql`
  mutation deleteUserAddress($input: DeleteUserAddrerssInput!) @api(name: "productsAPI") {
    deleteUserAddress(input: $input) {
      success
      message
    }
  }
`;
