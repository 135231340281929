import { gql } from '@apollo/client';
export const GET_CUSTOMER_DETAILS = gql`
  query GetCustomerDetails($input: UserIdInput!) @api(name: "productsAPI") {
    getCustomerDetails(input: $input) {
      __typename
      id
      name
      email
      isBlocked
      taxEntityUseCode
      isEmailVerified
      joinedOn
      signupMedium
      phone
      phoneCountryCode
      profileUrl
      isPhoneNoVerified
      isEligibleForFreeShipping
      totalNumberOfOrders
      totalAmountSpent
      numberOfProductsRefunded
      lastOrderId
      lastOrderDate
      availableStoreCredit
      isBlockedForLive
      isVIPCustomer
      addresses {
        id
        receiverName
        addressLine1
        city
        state
        country
        pincode
        type
        isPrimary
        phoneNumber
        isVerified
      }
      userTags {
        id
        tag
        color
        bgColor
      }
      userNotes {
        id
        note
        creatorName
        creatorDp
        updatedAt
      }
      shopifyId
      shopifyUrl
    }
  }
`;
