import { gql } from '@apollo/client';

export const START_STOP_SELLER_APPSTREAM = gql`
  mutation START_STOP_SELLER_APP($input: SELLER_STREAM_ACTION!) @api(name: "liveStreamingAPI") {
    startStopSellerAppStream(action: $input) {
      action
      storeId
    }
  }
`;
