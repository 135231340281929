import { FC } from 'react';

// Components
import { Img } from 'src/components/atoms';

// Icons
import { VisitorAnalyticsIcon, OrderAnalyticsIcon, RevenueAnalyticsIcon, ProfitAnalyticsIcon } from 'src/assets/icons';

// Types
import { AnalyticsCardProps } from './HomePage.type';
import AnalyticsCardData from './AnalyticsCardData';

const AnalyticsCard: FC<AnalyticsCardProps> = (props) => {
  const { type } = props;
  const renderIcon = (type: string) => {
    switch (type) {
      case 'order':
        return OrderAnalyticsIcon;
      case 'revenue':
        return RevenueAnalyticsIcon;
      case 'profit':
        return ProfitAnalyticsIcon;
      default:
        return VisitorAnalyticsIcon;
    }
  };

  return (
    <div className="card home-analytics-card">
      <Img src={renderIcon(type)} width={32} height={32} className="m-b-8" />
      <AnalyticsCardData {...props} />
    </div>
  );
};

export default AnalyticsCard;
