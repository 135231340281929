import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_VENDORS } from 'src/apollo/queries';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import { EmptyList } from 'src/components/oraganisms';
import VendorListPresentational from './VendorList.presentational';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { setImageSrc } from 'src/utils/setImageSrc';

// Icons
import { DefaultImage, DownArrowIcon, UpArrowIcon, NoVendorIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { RowObjectProps, TablesProps } from 'src/components/molecules/Table/Table.types';

// Styles
import './vendorList.scss';

interface VendorListProps {
  handleVendorDetails(e: React.MouseEvent, vendor: any): void;
  selectedTab: string;
  refetchList: boolean;
  editColumnData: {
    name: string;
    status: boolean;
  }[];
  addVendor: () => void;
}
const VendorList: FC<VendorListProps> = (props) => {
  const { handleVendorDetails, selectedTab, refetchList, editColumnData, addVendor } = props;

  const [showVendorDetails, setShowVendorDetails] = useState(-1);
  const [totalVendors, setTotalVendors] = useState(0);
  const [skipCount, setSkipCount] = useState(0);
  const [limitCount, setLimitCount] = useState(50);
  const [searchText, setSearchText] = useState<string>('');
  const [selectedVendors, setSelectedVendors] = useState<Array<number>>([]);
  const [sortBy, setSortBy] = useState<{
    id: number;
    catagory: string;
    type: string;
  }>({
    id: 0,
    catagory: constVariables.sortCategories.name,
    type: 'DESC'
  });
  const [vendorList, setVendorList] = useState([]);
  const [openSortDropdown, setOpenSortDropdown] = useState(false);
  const [activeColumns, setActiveColumns] = useState<
    {
      name: string;
      status: boolean;
    }[]
  >([]);

  useEffect(() => {
    if (editColumnData?.length > 0) {
      const arr = Array.from(editColumnData);
      editColumnData.map((column, index) => {
        if (!column.status) {
          arr.splice(arr.indexOf(column), 1);
        }
        return null;
      });
      setActiveColumns([...arr]);
    }
  }, [editColumnData]);

  const input: {
    ids: Array<number>;
    pagination: {
      skipCount: number;
      limitCount: number;
    };
    status: string;
    sort?: {
      column: string;
      order: string;
    };
  } = {
    ids: [],
    pagination: {
      skipCount,
      limitCount
    },
    status: selectedTab === constVariables.Tabs.allVendors.toUpperCase() ? 'ALL' : selectedTab.toUpperCase()
  };

  if (sortBy.catagory) {
    input.sort = {
      column: sortBy.catagory,
      order: sortBy.type
    };
  }

  const {
    data: vendors,
    refetch: refetchVendors,
    loading: isLoading
  } = useQuery(GET_VENDORS, {
    variables: {
      input
    },
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    if (vendors?.getVendorDetails?.vendors?.length > 0) {
      const arr: any = Object.assign([], vendors?.getVendorDetails?.vendors);
      arr.map((vendor: any, index: number) => {
        const obj: any = Object.assign({}, vendor);
        const categories: any = [];
        const subCategories: any = [];
        vendor?.categoriesSet?.map((set: any, i: number) => {
          categories.push(set?.category?.name);
          set?.subCategory?.map((subCategory: any, j: number) => {
            subCategories.push(subCategory?.name);
            return null;
          });
          return null;
        });
        obj.category = categories;
        obj.subCategory = subCategories;
        arr[index] = obj;
        return null;
      });
      setVendorList(arr);
    }
    if (vendors?.getVendorDetails?.totalVendors) {
      setTotalVendors(vendors?.getVendorDetails?.totalVendors);
    }
  }, [vendors]);

  useEffect(() => {
    refetchVendors();
  }, [refetchList, refetchVendors]);

  const ChangeSortBy = (column: string, id: number) => {
    if (sortBy?.catagory === column) {
      if (sortBy.type === 'DESC') {
        setSortBy({
          ...sortBy,
          type: 'ASC'
        });
      } else {
        setSortBy({
          ...sortBy,
          type: 'DESC'
        });
      }
    } else {
      setSortBy({
        catagory: column,
        id,
        type: 'DESC'
      });
    }
  };

  const selectVendor = (e: any, vendor?: any) => {
    if (e === 'All') {
      if (selectedVendors.length === 0) {
        const tempArr: Array<number> = [];
        vendorList?.map((vendor: any, index: any) => {
          tempArr.push(vendor.id);
          return null;
        });
        setSelectedVendors(tempArr);
      }
    } else if (e.target.checked) {
      const arr = selectedVendors.concat(vendor.id);
      setSelectedVendors(arr);
    } else {
      const arr = selectedVendors;
      arr.forEach((item, index) => {
        if (item === vendor.id) arr.splice(index, 1);
      });
      setSelectedVendors([...arr]);
    }
  };

  const allHeaders: any = {
    [constVariables.vendorEditColumns.vendorCategory]: {
      className: 'align-middle p-0 border-bottom-0 ps-2',
      colName: constVariables.vendorListColumns.category,
      sorting: true,
      applySort: () => {
        ChangeSortBy(constVariables.sortCategories.category, 1);
      },
      category: constVariables.sortCategories.category
    },
    [constVariables.sortCategories.subCategory]: {
      className: 'align-middle p-0 border-bottom-0',
      colName: constVariables.vendorListColumns.subCategory,
      sorting: true,
      applySort: () => {
        ChangeSortBy(constVariables.sortCategories.subCategory, 2);
      },
      category: constVariables.sortCategories.subCategory
    },
    [constVariables.vendorEditColumns.totalSpending]: {
      className: 'align-middle p-0 border-bottom-0',
      colName: constVariables.vendorListColumns.totalSpending,
      sorting: true,
      applySort: () => {
        ChangeSortBy(constVariables.sortCategories.totalSpending, 3);
      },
      category: constVariables.sortCategories.totalSpending
    }
  };

  const tableData: TablesProps = {
    isDragDisabled: true,
    sortByType: sortBy?.type,
    sortByCategory: sortBy?.catagory
  };

  tableData.headers = [
    {
      id: 1,
      className: 'w-25px align-middle border-bottom-0 ps-2',
      colName: (
        <div className="form-check form-check-sm form-check-custom">
          <input
            autoComplete="off"
            className={clsx('me-5 form-check-input widget-9-check', {
              'bg-primary': selectedVendors.length > 0
            })}
            value={selectedVendors.length}
            type="checkbox"
            onClick={() => {
              if (selectedVendors.length > 0) {
                setSelectedVendors([]);
              } else {
                selectVendor('All');
              }
            }}
            checked={false}
            ref={(input) => {
              if (input && selectedVendors.length > 0) {
                input.indeterminate = true;
              } else if (input) {
                input.indeterminate = false;
              }
            }}
          />
        </div>
      )
    },
    {
      id: 2,
      className: 'align-middle p-0 border-bottom-0',
      colName: constVariables.vendorListColumns.vendor,
      sorting: true,
      applySort: () => {
        ChangeSortBy(constVariables.sortCategories.name, 0);
      },
      category: constVariables.sortCategories.name
    }
  ];

  let headerId = constVariables.headerID;

  activeColumns.map((column) => {
    if (allHeaders[column.name]) {
      if (column.name === constVariables.vendorEditColumns.vendorCategory) {
        tableData.headers?.push({
          ...allHeaders[column.name],
          id: headerId
        });
        tableData.headers?.push({
          ...allHeaders[constVariables.sortCategories.subCategory],
          id: headerId + 1
        });
        headerId = headerId + 2;
      } else {
        tableData.headers?.push({
          ...allHeaders[column.name],
          id: headerId
        });
        headerId += 1;
      }
    }
    return null;
  });

  tableData.headers.push({
    id: 6,
    className: 'border-bottom-0',
    colName: ''
  });

  const tableCells: any = {
    [constVariables.vendorEditColumns.vendorCategory](vendor: any, index: number) {
      return {
        className: 'align-middle ps-2 table-top-border text-muted',
        value: vendor?.category?.map((item: any, index: number) => {
          return (
            <span key={index}>
              {item}
              {index === vendor?.category?.length - 1 ? '' : ', '}
            </span>
          );
        })
      };
    },
    [constVariables.sortCategories.subCategory](vendor: any, index: number) {
      return {
        className: 'align-middle ps-0 table-top-border text-muted',
        value: vendor?.subCategory?.map((subCategory: any, index: number) => {
          return (
            <span className={`badge me-3 my-1 ${`badge-${index % 7}`}`} key={index}>
              {subCategory}
            </span>
          );
        })
      };
    },
    [constVariables.vendorEditColumns.totalSpending](vendor: any, index: number) {
      return {
        className: 'align-middle ps-0 table-top-border text-muted',
        value: `$${Number(vendor.totalSpending).toFixed(2)}`
      };
    }
  };

  const arr: Array<RowObjectProps> = [];

  vendorList.map((vendor: any, index: number) => {
    const cells = [
      {
        className: 'align-middle table-top-border ps-2 py-0',
        value: (
          <div className="form-check form-check-sm form-check-custom">
            <input
              autoComplete="off"
              className="me-5 form-check-input widget-9-check"
              type="checkbox"
              checked={selectedVendors.includes(vendor.id)}
              onChange={(e) => {
                selectVendor(e, vendor);
              }}
              onClick={(e) => {
                selectVendor(e, vendor);
              }}
            />
          </div>
        )
      },
      {
        className: 'align-middle ps-0 py-0 table-top-border',
        value: (
          <FlexBox className="align-items-center">
            <div className="symbol me-5">
              <Img
                className="object-fit-scale-down bg-white border border-light border-2"
                src={setImageSrc(vendor.profileUrl)}
                placeholderImg={DefaultImage}
                errorImg={DefaultImage}
              />
            </div>
            <FlexBox className="justify-content-start flex-column">
              <span onClick={(e) => handleVendorDetails(e, vendor)} className="d-block cursor-pointer main-cell">
                {vendor.name}
              </span>
            </FlexBox>
          </FlexBox>
        )
      }
    ];

    activeColumns.map((column) => {
      tableCells[column.name] && cells.push(tableCells[column.name](vendor, index));
      if (column.name === constVariables.vendorEditColumns.vendorCategory) {
        cells.push(tableCells[constVariables.sortCategories.subCategory](vendor, index));
      }
      return null;
    });

    cells.push({
      className: 'text-end align-middle ps-0 py-0 table-top-border',
      value: (
        <button
          className="bg-transparent border border-transparent"
          onClick={() => {
            if (showVendorDetails === vendor.id) setShowVendorDetails(-1);
            else setShowVendorDetails(vendor.id);
          }}
        >
          <KTSVG
            path={showVendorDetails === vendor.id ? UpArrowIcon : DownArrowIcon}
            svgClassName="cursor-pointer collapseFilter collapseIcon"
          />
        </button>
      )
    });

    arr.push({
      id: vendor.id,
      className: `position-relative h-fit-content table-row cursor-pointer ${showVendorDetails === vendor.id ? 'top-shadow' : ''}`,
      showVendorDetails,
      vendorDetails: { details: vendor },
      cells
    });
    return null;
  });

  tableData.rows = arr;

  return (
    <>
      {!isLoading && totalVendors === 0 && !searchText && (
        <>
          {selectedTab === constVariables.VendorsTab.allVendors.toUpperCase() ? (
            <EmptyList
              title={constVariables.emptyDataList.vendor}
              description={constVariables.emptyDataList.vendorDescription}
              addButtonText={constVariables.emptyDataList.vendorAction}
              onAddButton={addVendor}
              icon={NoVendorIcon}
            />
          ) : (
            <EmptyList datatype="vendor" status={selectedTab} icon={NoVendorIcon} />
          )}
        </>
      )}
      <VendorListPresentational
        tableData={tableData}
        skipCount={skipCount}
        setSkipCount={setSkipCount}
        limitCount={limitCount}
        totalVendors={totalVendors}
        openSortDropdown={openSortDropdown}
        setOpenSortDropdown={setOpenSortDropdown}
        sortBy={sortBy}
        ChangeSortBy={ChangeSortBy}
        isLoading={isLoading}
        selectedVendors={selectedVendors}
        searchText={searchText}
        setSearchText={setSearchText}
      />
    </>
  );
};

export default VendorList;
