import { FC } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// Components
import { LiveProductInfo } from 'src/components/molecules';

// Icons
import { LiveProductsPreviewIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { LiveProductsPreviewProps } from './LiveProductsPreview.types';

// Styles
import './_liveProductsPreview.scss';

const LiveProductsPreviewPresentational: FC<LiveProductsPreviewProps> = ({
  productList,
  hidePlaceholderText,
  placeholderImg,
  classes,
  removeProduct,
  onProductDragEnd
}) => {
  return (
    <div className="live-products-preview">
      <div className="preview w-100 p-7">
        <div className="d-flex justify-content-between mb-6 mt-4">
          <h6 className={`fs-4 m-auto-0 text-nowrap dark-color preview-title ${classes ? classes : ''}`}>
            {constVariables.LiveShow.productForLiveTitle}
          </h6>
          {productList.length > 0 && <span className="poppins-regular">Total {productList.length} items</span>}
        </div>
        <div className="col-sm text-center fixed-height-scroll">
          <DragDropContext onDragEnd={onProductDragEnd}>
            <Droppable droppableId="list">
              {(provided, snapshot) => (
                <div id="list" {...provided.droppableProps} ref={provided.innerRef}>
                  {productList?.map((product, index) => {
                    return (
                      <Draggable key={index} draggableId={index.toString()} index={index}>
                        {(provided, snapshot) => (
                          <div
                            key={index}
                            className="menu-item"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <LiveProductInfo productInfo={product} indexNumber={101 + index} deleteProduct={removeProduct} />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {hidePlaceholderText && productList.length > 0 ? (
            <></>
          ) : (
            <>
              <div className="profile-pic-margin"></div>

              <img
                src={placeholderImg ? placeholderImg : LiveProductsPreviewIcon}
                alt={placeholderImg ? placeholderImg : LiveProductsPreviewIcon}
                className="cursor-pointer mt-15 mb-5"
              />
              <div className="">
                <h5 className="mt-4 align-items-center justify-content-center text-center opacity-50 poppins-semibold fs-6">
                  {constVariables.LiveShow.productForLivePlaceholderTextTitle}
                </h5>
                <p className="mt-4 align-items-center justify-content-center text-center greyText fs-8">
                  {constVariables.LiveShow.productForLivePlaceholderText}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LiveProductsPreviewPresentational;
