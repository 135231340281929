export enum MEASUREMENT_UNITS {
  M = 'M',
  CM = 'CM',
  MM = 'MM',
  IN = 'IN',
  FT = 'FT',
  YD = 'YD'
}

export const convertMeasurement = (currentValue: number = 0, currentUnit: string = '', unitToChange: MEASUREMENT_UNITS) => {
  if (!currentValue || !currentUnit) return 0;

  if (unitToChange.toUpperCase() === currentUnit.toUpperCase()) {
    return currentValue;
  }

  if (currentUnit.toUpperCase() === 'M') {
    if (unitToChange.toUpperCase() === 'CM') {
      return Math.round((currentValue * 100 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange.toUpperCase() === 'MM') {
      return Math.round((currentValue * 1000 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange.toUpperCase() === 'IN') {
      return Math.round((currentValue * 39.3701 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange.toUpperCase() === 'FT') {
      return Math.round((currentValue * 3.28084 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange.toUpperCase() === 'YD') {
      return Math.round((currentValue * 1.09361 + Number.EPSILON) * 100) / 100;
    }
  }

  if (currentUnit.toUpperCase() === 'CM') {
    if (unitToChange.toUpperCase() === 'M') {
      return Math.round((currentValue / 100 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange.toUpperCase() === 'MM') {
      return Math.round((currentValue * 10 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange.toUpperCase() === 'IN') {
      return Math.round((currentValue * 0.393701 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange.toUpperCase() === 'FT') {
      return Math.round((currentValue * 0.0328084 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange.toUpperCase() === 'YD') {
      return Math.round((currentValue * 0.0109361 + Number.EPSILON) * 100) / 100;
    }
  }

  if (currentUnit.toUpperCase() === 'MM') {
    if (unitToChange.toUpperCase() === 'M') {
      return Math.round((currentValue / 1000 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange.toUpperCase() === 'CM') {
      return Math.round((currentValue / 10 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange.toUpperCase() === 'IN') {
      return Math.round((currentValue * 0.0393701 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange.toUpperCase() === 'FT') {
      return Math.round((currentValue * 0.00328084 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange.toUpperCase() === 'YD') {
      return Math.round((currentValue * 0.00109361 + Number.EPSILON) * 100) / 100;
    }
  }

  if (currentUnit.toUpperCase() === 'IN') {
    if (unitToChange.toUpperCase() === 'M') {
      return Math.round((currentValue * 0.0254 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange.toUpperCase() === 'CM') {
      return Math.round((currentValue * 2.54 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange.toUpperCase() === 'MM') {
      return Math.round((currentValue * 25.4 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange.toUpperCase() === 'FT') {
      return Math.round((currentValue * 0.0833333 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange.toUpperCase() === 'YD') {
      return Math.round((currentValue * 0.0277778 + Number.EPSILON) * 100) / 100;
    }
  }

  if (currentUnit.toUpperCase() === 'FT') {
    if (unitToChange.toUpperCase() === 'M') {
      return Math.round((currentValue * 0.3048 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange.toUpperCase() === 'CM') {
      return Math.round((currentValue * 30.48 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange.toUpperCase() === 'MM') {
      return Math.round((currentValue * 304.8 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange.toUpperCase() === 'IN') {
      return Math.round((currentValue * 12 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange.toUpperCase() === 'YD') {
      return Math.round((currentValue * 0.333333 + Number.EPSILON) * 100) / 100;
    }
  }

  if (currentUnit.toUpperCase() === 'YD') {
    if (unitToChange.toUpperCase() === 'M') {
      return Math.round((currentValue * 0.9144 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange.toUpperCase() === 'CM') {
      return Math.round((currentValue * 91.44 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange.toUpperCase() === 'MM') {
      return Math.round((currentValue * 914.4 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange.toUpperCase() === 'IN') {
      return Math.round((currentValue * 36 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange.toUpperCase() === 'FT') {
      return Math.round((currentValue * 3 + Number.EPSILON) * 100) / 100;
    }
  }

  return 0;
};
