import { FC, ReactNode } from 'react';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { UpArrowIcon, DownArrowIcon } from 'src/assets/icons';

interface SortableTableHeaderProps {
  label: string | ReactNode;
  type: string;
  sortHeader: () => void;
}

const SortableTableHeader: FC<SortableTableHeaderProps> = ({ label, type, sortHeader }) => {
  return (
    <div className="d-flex align-items-center">
      <div>{label}</div>
      <button className="bg-transparent border border-transparent" onClick={sortHeader}>
        <KTSVG
          path={type === 'ASC' ? DownArrowIcon : UpArrowIcon}
          className="expand-arrow-icon"
          svgClassName="cursor-pointer collapseFilter collapseIcon"
        />
      </button>
    </div>
  );
};

export default SortableTableHeader;
