import { FC, useMemo, useState } from 'react';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import { InputBox } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { validatePassword } from 'src/utils/validate';
import { changePassword, signInWithEmail } from 'src/helpers/InitFirebase';

// Icons
import { CloseIcon, SendSuccessIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

interface PasswordChangeModalProps {
  closeModal: () => void;
  showForgotPasswordModal: () => void;
  medium?: string;
}

const PasswordChangeModal: FC<PasswordChangeModalProps> = ({ closeModal, showForgotPasswordModal, medium }) => {
  const currentStepInitialValue = medium && medium.toLowerCase() === 'email' ? 'old' : 'confirm';
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [currenStep, setCurrentStep] = useState(currentStepInitialValue);

  const handleChangePassword = (value: string, type: string) => {
    if (type === 'old') setOldPassword(value);
    if (type === 'new') setNewPassword(value);
    if (type === 'confirmed') setConfirmedPassword(value);
  };

  const handleContinue = async () => {
    switch (currenStep) {
      case 'old': {
        const signInWithEmailResult = await signInWithEmail(localStorage.getItem('email') || '', oldPassword).then();
        if (signInWithEmailResult.success) {
          setCurrentStep('confirm');
        } else {
          setError('Password is incorrect');
        }

        break;
      }
      case 'confirm': {
        const newPasswordValidation = validatePassword(newPassword);

        if (newPasswordValidation !== false) {
          setError(`${newPasswordValidation}`);
          return;
        } else if (newPassword !== confirmedPassword) {
          setError('New password and confirmed password are not matched');
          return;
        } else {
          const changePasswordResult = await changePassword(newPassword);
          if (changePasswordResult.success) {
            setCurrentStep('completed');
            setError('');
            setConfirmedPassword('');
            setNewPassword('');
            setShowPassword(false);
            setOldPassword('');
          } else {
            setError('Password change failed');
          }
        }

        // setCurrentStep('completed');
        break;
      }
      default:
        break;
    }
  };

  const isButtonDisabled = useMemo(() => {
    if (error.trim().length > 0) {
      return true;
    }
    return false;
  }, [currenStep, oldPassword, newPassword, confirmedPassword, error]);

  const handleShowForgotPasswordModal = () => {
    closeModal();
    showForgotPasswordModal();
  };

  const handleValidatePassword = (value) => {
    // Validate the password;
    if (value.trim().length === 0) {
      setError('Password is required');
    } else {
      setError('');
    }
  };

  const handleValidateNewAndConfirmPassword = (value: string, type: string) => {
    // Validate the password;

    switch (type) {
      case 'new': {
        const newPasswordValidation = validatePassword(value);
        if (newPasswordValidation !== false) {
          setError(`${newPasswordValidation}`);
        } else {
          setError('');
        }
        break;
      }
      case 'confirmed': {
        if (value !== newPassword) {
          setError('New password and confirmed password are not matched');
        } else {
          setError('');
        }
        break;
      }
      default:
        break;
    }
  };

  return (
    <div className="change-account-info-modal bg-white modal-content">
      <div className="modal-header">
        <h5 className="modal-title">{constVariables.accountSettings.changePassword}</h5>
        <div
          className="btn btn-xs btn-active-light-primary p-0 m-0 border-none"
          onClick={() => {
            setCurrentStep('old');
            setError('');
            setConfirmedPassword('');
            setNewPassword('');
            setShowPassword(false);
            setOldPassword('');
            closeModal();
          }}
        >
          <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
        </div>
      </div>
      <div className="modal-body">
        {currenStep === 'old' && (
          <div>
            <InputBox
              title={constVariables.accountSettings.enterOldPassword}
              value={oldPassword}
              onChangeText={(value) => handleChangePassword(value, 'old')}
              name="password"
              inputClass="form-control-lg"
              warningText={error}
              placeholder="Password"
              onBlur={(event) => handleValidatePassword(event.target.value)}
              type={showPassword ? 'text' : 'password'}
              isPasswordInput
              toggleShowPassword={() => setShowPassword(!showPassword)}
              imageRight={
                <div
                  className="text-primary cursor-pointer text-btn position-absolute change-input-text"
                  onClick={handleShowForgotPasswordModal}
                >
                  {constVariables.accountSettings.forgotPassword}
                </div>
              }
            />
          </div>
        )}
        {currenStep === 'confirm' && (
          <div>
            <div className="m-b-24">
              <InputBox
                title={constVariables.accountSettings.enterNewPassword}
                value={newPassword}
                onChangeText={(value) => handleChangePassword(value, 'new')}
                name="password"
                inputClass="form-control-lg"
                warningText={error}
                onBlur={(event) => handleValidateNewAndConfirmPassword(event.target.value, 'new')}
                placeholder="Password"
                type={showPassword ? 'text' : 'password'}
                isPasswordInput
                toggleShowPassword={() => setShowPassword(!showPassword)}
                imageRight={
                  <div
                    className="text-primary cursor-pointer text-btn position-absolute change-input-text"
                    onClick={handleShowForgotPasswordModal}
                  >
                    {constVariables.accountSettings.forgotPassword}
                  </div>
                }
              />
            </div>
            <div>
              <InputBox
                title={constVariables.accountSettings.confirmNewPassword}
                value={confirmedPassword}
                onChangeText={(value) => handleChangePassword(value, 'confirmed')}
                name="password"
                inputClass="form-control-lg"
                onBlur={(event) => handleValidateNewAndConfirmPassword(event.target.value, 'confirmed')}
                placeholder="Password"
                type={showPassword ? 'text' : 'password'}
                isPasswordInput
                toggleShowPassword={() => setShowPassword(!showPassword)}
                imageRight={
                  <div className="text-primary cursor-pointer text-btn position-absolute change-input-text">
                    {constVariables.accountSettings.forgotPassword}
                  </div>
                }
              />
            </div>
          </div>
        )}
        {currenStep === 'completed' && (
          <div>
            <FlexBox className="align-items-center flex-column m-b-24">
              <Img src={SendSuccessIcon} />
              <p className="mb-0 sent-success m-t-8">{constVariables.accountSettings.passwordChangeSuccess}</p>
            </FlexBox>
            <p className="mb-0">{constVariables.accountSettings.passwordChanged}</p>
          </div>
        )}
      </div>
      {(currenStep === 'old' || currenStep === 'confirm') && (
        <div className="modal-footer">
          <button className="btn btn-primary btn-md" type="button" disabled={isButtonDisabled} onClick={handleContinue}>
            {currenStep === 'old' ? 'Continue' : 'Confirm Password'}
          </button>
        </div>
      )}
    </div>
  );
};

export default PasswordChangeModal;
