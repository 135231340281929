// Components
import Tooltip from './Tooltip';

const ExpeditedShippingTooltip = () => {
  return (
    <Tooltip
      tooltipId="truckIcon"
      place="top"
      content={() => {
        return <>Expedite Shipping</>;
      }}
    />
  );
};

export default ExpeditedShippingTooltip;
