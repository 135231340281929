/* eslint-disable dot-notation */
import { FC, useEffect, useState } from 'react';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_COUPONS_LIST } from 'src/apollo/queries';

// Components
import AllCouponsPagePresentational from './AllCouponsPage.presentational';

// Hooks && Utils && Helpers
import useTitle from 'src/utils/hooks/useTitle';

// Types
import { CouponsListData, getCouponInputType } from './AllCouponsPage.type';

// Styles
import './_allCouponsPage.scss';

const AllCouponsPage: FC = () => {
  useTitle('Coupons');

  const [couponsList, setCouponsList] = useState<CouponsListData[]>([]);
  const [sortBy, setSortBy] = useState({
    catagory: 'NAME',
    id: 1,
    type: 'ASC'
  });
  const [selectedTab, setSelectedTab] = useState('ACTIVE');
  const [totalCount, setTotalCount] = useState(0);

  const [getCouponInput, setCouponInput] = useState<getCouponInputType>({
    pagination: {
      skipCount: 0,
      limitCount: 50
    },
    status: selectedTab === 'ALL COUPONS' ? '' : selectedTab,
    sortBy: sortBy.catagory,
    sortType: sortBy.type,
    filters: {
      searchText: ''
    }
  });

  useEffect(() => {
    setCouponInput((couponInput) => {
      return { ...couponInput, sortBy: sortBy.catagory, sortType: sortBy.type };
    });
  }, [sortBy]);

  useEffect(() => {
    setCouponInput((couponInput) => {
      return {
        ...couponInput,
        status: selectedTab === 'ALL COUPONS' ? '' : selectedTab,
        pagination: { ...couponInput.pagination, skipCount: 0 }
      };
    });
  }, [selectedTab]);

  useEffect(() => {
    setCouponInput((couponInput) => {
      return { ...couponInput, pagination: { ...couponInput.pagination, skipCount: 0 } };
    });
  }, [getCouponInput?.filters?.searchText]);

  const { loading: isLoading } = useQuery(GET_COUPONS_LIST, {
    variables: {
      input: getCouponInput
    },
    onCompleted: (couponsListData) => {
      if (couponsListData?.getAllCouponsList) {
        setCouponsList(couponsListData.getAllCouponsList?.couponsList);
        setTotalCount(couponsListData?.getAllCouponsList?.total);
      }
    },
    onError: (error) => {
      console.error('Coupon List err-> ', error);
    }
  });

  return (
    <AllCouponsPagePresentational
      searchedValue={getCouponInput.filters.searchText}
      couponsList={couponsList}
      setCouponsList={setCouponsList}
      sortBy={sortBy}
      setSortBy={setSortBy}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      isLoading={isLoading}
      totalCount={totalCount}
      getCouponInput={getCouponInput}
      setCouponInput={setCouponInput}
    />
  );
};

export default AllCouponsPage;
