import CryptoJS from 'crypto-js';
import { envUrls } from 'src/constants/appUrls';
const encryptionKey = envUrls.passwordEncryptionKey;

export const encrypt = (pwd: string): string => {
  const encryptedBytes = CryptoJS.AES.encrypt(pwd, encryptionKey);
  const encryptedPass = encryptedBytes.toString();
  return encryptedPass;
};

export const decrypt = (pwd: string): string => {
  const bytes = CryptoJS.AES.decrypt(pwd, encryptionKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};
