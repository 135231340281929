import { gql } from '@apollo/client';

export const CHANGE_PRODUCT_STAUS = gql`
  mutation onChangeProductStatus($input: ChangeProductStatusInput!) @api(name: "productsAPI") {
    changeProductStatus(input: $input) {
      id
      productStatus
      success
      message
    }
  }
`;
