import { useRef, useState } from 'react';
import IVSBroadcastClient from 'amazon-ivs-web-broadcast';

// Hooks && Utils && Helpers
import { defaultDevicePermissions } from '../helpers/defaultData';
import {
  getAudioDevices,
  getAudioMixerDevice,
  getVideoDevices,
  getVideoLayer,
  handlePermissions,
  CAM_LAYER_NAME,
  MIC_LAYER_NAME
} from '../helpers/mediaDevices';
import useLayers from 'src/utils/hooks/useLayers';
import useMixer from 'src/utils/hooks/useMixer';

interface IuseMediaDevices {
  stopMediaDevices: (client: React.MutableRefObject<IVSBroadcastClient.AmazonIVSBroadcastClient | undefined>['current']) => void;
  devicePermissions: {
    video: boolean;
    audio: boolean;
  };
  initLayers: () => void;
  handleMicMute: () => void;
  handleCameraMute: () => void;
  renderActiveVideoDevice: (isVisible?: boolean) => void;
  renderActiveAudioDevice: () => void;
  handleError: (message: any) => void;
  videoDevices: any[];
  audioDevices: any[];
  camMuted: boolean;
  micMuted: boolean;
  activeVideoDevice: React.MutableRefObject<null>;
  activeAudioDevice: React.MutableRefObject<null>;
  canvasRef: React.MutableRefObject<any>;
  CAM_LAYER_NAME: string;
  MIC_LAYER_NAME: string;
}

const useMediaDevices = (
  client: React.MutableRefObject<IVSBroadcastClient.AmazonIVSBroadcastClient | undefined>
): IuseMediaDevices => {
  const [devicePermissions, setDevicePermissions] = useState(defaultDevicePermissions);
  const [videoDevices, setVideoDevices] = useState<any[]>([]);
  const [audioDevices, setAudioDevices] = useState<any[]>([]);
  const [micMuted, setMicMuted] = useState(false);

  const { addVideoLayer, removeLayer, camMuted } = useLayers(client.current);
  const { addMixerDevice, removeMixerDevice } = useMixer(client.current);

  const activeVideoDevice = useRef(null);
  const activeAudioDevice = useRef(null);
  const canvasRef = useRef<any>();

  const handleError = (message) => {
    console.log('error', message);
  };

  const handleMicMute = async () => {
    if (micMuted) {
      renderActiveAudioDevice();
    } else {
      removeMixerDevice();
    }

    setMicMuted((b) => !b);
  };

  const renderActiveVideoDevice = (isVisible = true) => {
    if (!isVisible) {
      return;
    }
    const layer = getVideoLayer(activeVideoDevice, true, client);
    addVideoLayer(layer);
  };

  const removeActiveVideoDevice = () => {
    const layer = getVideoLayer(activeVideoDevice, !camMuted, client);
    removeLayer(layer);
  };

  const handleCameraMute = async () => {
    camMuted ? renderActiveVideoDevice(true) : removeActiveVideoDevice();
  };

  const renderActiveAudioDevice = () => {
    const mixerDevice = getAudioMixerDevice(activeAudioDevice, false);
    addMixerDevice(mixerDevice);
  };

  const initLayers = async () => {
    if (!devicePermissions.video) {
      await handlePermissions(setDevicePermissions, handleError);
    }
    client?.current?.attachPreview(canvasRef.current);
    let vd;
    let ad;
    try {
      vd = await getVideoDevices();
      setVideoDevices(vd);
      ad = await getAudioDevices();
      setAudioDevices(ad);
    } catch (err) {
      console.error(err);
      handleError(
        'Error: Could not find any available video or audio devices. Please ensure that a camera or microphone is attached to your device, and your privacy settings allow this app access them.'
      );
    }
    try {
      if (!activeVideoDevice.current) {
        activeVideoDevice.current = vd[0];
      }
      if (!camMuted) {
        renderActiveVideoDevice(!camMuted);
      }
      if (!activeAudioDevice.current) {
        activeAudioDevice.current = ad[0];
      }
      if (!micMuted) {
        renderActiveAudioDevice();
      }
    } catch (err) {
      console.error(err);
      handleError(
        'Error: Could not add the selected audio and video devices to the canvas. Please check the app settings to ensure that the correct webcam and microphone are selected.'
      );
    }
  };

  const stopMediaDevices = (client: React.MutableRefObject<IVSBroadcastClient.AmazonIVSBroadcastClient | undefined>['current']) => {
    try {
      const videoStream = client?.getVideoInputDevice(CAM_LAYER_NAME);
      if (videoStream) {
        for (const track of videoStream.source.getVideoTracks()) {
          track.stop();
        }
      }

      const audioStreamStream: any = client?.getAudioInputDevice(MIC_LAYER_NAME);
      if (audioStreamStream) {
        for (const track of audioStreamStream.getAudioTracks()) {
          track.stop();
        }
      }

      if (activeAudioDevice?.current) {
        removeMixerDevice();
      }
      if (activeVideoDevice?.current) {
        removeActiveVideoDevice();
      }
      setDevicePermissions({
        video: false,
        audio: false
      });
    } catch (error) {
      console.error(error);
    }
  };

  return {
    stopMediaDevices,
    devicePermissions,
    handleError,
    videoDevices,
    audioDevices,
    initLayers,
    camMuted,
    micMuted,
    activeAudioDevice,
    activeVideoDevice,
    renderActiveAudioDevice,
    renderActiveVideoDevice,
    handleCameraMute,
    handleMicMute,
    canvasRef,
    CAM_LAYER_NAME,
    MIC_LAYER_NAME
  };
};

export default useMediaDevices;
