import { FC } from 'react';

// Components
import { Divider } from 'src/components/molecules';

// Icons
import { LeftArrowIcon } from 'src/assets/icons';

// Types
import { FloatingInventoryBoxLayoutProps } from './FloatingInventoryBoxLayout.types';

// Styles
import './_floatingInventoryBoxLayout.scss';

const FloatingInventoryBoxLayout: FC<FloatingInventoryBoxLayoutProps> = ({
  title,
  isBackButton,
  noBottomButton,
  redButtons,
  blueButtonText,
  redButtonText,
  onBackClick,
  onActionClick,
  onCancelClick,
  children,
  disableSaveButton,
  isMorePaddingTitle
}) => {
  const bottomButtons = () => {
    const returnActionText = () => {
      if (redButtons) {
        if (redButtonText) {
          return redButtonText;
        } else {
          return 'Subtract';
        }
      } else {
        if (blueButtonText) {
          return blueButtonText;
        } else {
          return 'Save';
        }
      }
    };

    if (!noBottomButton) {
      return (
        <>
          <Divider />
          <div className="p-x-12 p-y-8 d-flex align-items-center justify-content-end">
            <button onClick={onCancelClick} type="button" className="btn btn-outlined-secondary btn-sm">
              Cancel
            </button>
            <button
              onClick={onActionClick}
              type="button"
              className={`btn btn-sm ms-2 me-0 ${redButtons ? 'btn-danger' : 'btn-primary'}`}
              disabled={disableSaveButton}
            >
              {returnActionText()}
            </button>
          </div>
        </>
      );
    }
    return null;
  };
  return (
    <>
      <div className={`d-flex align-items-center ${isMorePaddingTitle ? 'p-x-24 p-y-12' : 'p-12'}`}>
        {isBackButton ? <img onClick={onBackClick} className="h-10px w-10px me-2 cursor-pointer" src={LeftArrowIcon} /> : null}
        <div className="dropdown-title">{title}</div>
      </div>
      <Divider />
      <div className="p-12">{children ? children : null}</div>
      {bottomButtons()}
    </>
  );
};

export default FloatingInventoryBoxLayout;
