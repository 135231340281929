import { gql } from '@apollo/client';

export const EDIT_PRODUCT_NOTE = gql`
  mutation EditProductNote($input: EditOrDeleteProductNotes!) @api(name: "productsAPI") {
    editProductNote(input: $input) {
      id
      success
      message
    }
  }
`;
