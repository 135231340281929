export enum SYNC_STATUS {
  'NOT_STARTED' = 'NOT_STARTED',
  'RUNNING' = 'RUNNING',
  'COMPLETED' = 'COMPLETED',
  'ERROR' = 'ERROR'
}

export enum CONNECTION_STATUS {
  'CONNECTED' = 'CONNECTED',
  'NOT_CONNECTED' = 'NOT_CONNECTED'
}

export interface SyncField {
  id: number;
  name: string;
  status: string;
  completed?: number;
  total?: number;
}
