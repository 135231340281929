import React from 'react';

// Types
import { ToastProp } from './Toast.types';

// Styles
import './Toast.scss';

const Toast: React.FC<ToastProp> = ({
  show,
  showHandler,
  undoButton,
  undoBtnHandler,
  editBtnText,
  editBtnHandler,
  title,
  successText,
  errorText,
  message,
  width
}) => {
  return (
    <div style={{ minWidth: width }}>
      <div className={`d-flex align-items-center justify-content-between w-100 poppins-regular ${message ? 'mb-3' : ''}`}>
        {successText ? (
          <h5 className="modal-title">
            {title}
            <span className="text-success">
              <span dangerouslySetInnerHTML={{ __html: successText ? successText : '' }}></span>
            </span>
            &nbsp;
          </h5>
        ) : null}
        {errorText ? (
          <h5 className="modal-title">
            {title ? <span className="pe-2">{title}</span> : null}
            <span className="text-danger">{errorText}</span>
          </h5>
        ) : null}
      </div>
      <p className="m-0 poppins-regular toast-description" dangerouslySetInnerHTML={{ __html: message ? message : '' }}></p>
    </div>
  );
};

export default Toast;
