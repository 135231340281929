export interface FilterOptionDateItem {
  id: number;
  name: DateFilter;
}
export interface FilterOptionChannelItem {
  id: number;
  name: ChannelFilter;
}

export interface FilterMenuDropdownProps {
  isShowDropdown: boolean;
  data: FilterOptionDateItem[] | FilterOptionChannelItem[];
  selectedValue: FilterOptionDateItem | FilterOptionChannelItem;
  onSelect: (id: number) => void;
}

export interface AnalyticsCardProps {
  type: string;
  value: number | undefined;
  comparedPercent: number | undefined;
  text: string;
  hasSign?: boolean;
}

export enum ANALYTICS_CHANNEL {
  MOBILE_STORE = 'MOBILE_STORE',
  WEB_STORE = 'WEB_STORE',
  FACEBOOK = 'FACEBOOK'
}

export interface IDateRange {
  startTime: Date;
  endTime: Date;
}

export interface IGetStoreAnalyticsInput {
  startTime: Date;
  endTime: Date;
  channel?: ANALYTICS_CHANNEL | null;
}

interface AnalyticStat {
  count: number;
  percent: number;
}

interface ChanelStat {
  total: number;
  app: number;
  live: number;
}

export interface IStoreAnalytics {
  id: string;
  visitors: AnalyticStat;
  orders: AnalyticStat;
  revenue: AnalyticStat;
  profit: AnalyticStat;
  totalSales: AnalyticStat;
  channels: {
    shopperApp?: ChanelStat;
    webStore?: ChanelStat;
    facebook?: number;
  };
  chart: {
    keys: string[];
    values: number[];
  };
}

export enum DateFilter {
  'Today' = 'Today',
  'Yesterday' = 'Yesterday',
  'Last week' = 'Last week',
  'Last month' = 'Last month',
  'Last year' = 'Last year',
  'Custom' = 'Custom'
}

export enum ChannelFilter {
  'All channels' = 'All channels',
  'Shopper app' = 'Shopper app',
  'Webstore' = 'Webstore',
  'Facebook' = 'Facebook'
}
