import { gql } from '@apollo/client';

export const GET_PRODUCTS = gql`
  query GetAllProduct($input: AllProductsInput) @api(name: "productsAPI") {
    getAllProducts(input: $input) {
      totalProducts
      products {
        __typename
        ... on AllProductsStoreOwnerOutput {
          id
          name
          medias {
            id
            url
            reviewId
            type
          }
          quantityInStock
          productCatagory
          vendor
          salePrice
          perItemCost
          status
          productTypeInfo {
            id
            name
          }
          tags {
            id
            name
            textColorCode
            backgroundColorCode
            createdAt
            updatedAt
          }
          variantCount
          sku
          barcode
          shippingPrice
          profit
          margin
          productAvailableFrom
        }
      }
    }
  }
`;
