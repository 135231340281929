import { constVariables } from 'src/constants';
export const ProductStatus = [
  {
    id: 1,
    name: constVariables.ProductsTab.active
  },
  {
    id: 2,
    name: constVariables.ProductsTab.inactive
  },
  {
    id: 3,
    name: constVariables.ProductsTab.draft
  }
  // {
  //   id: 4,
  //   name: constVariables.ProductsTab.archive
  // }
  // {
  //   id: 3,
  //   name: constVariables.ProductsTab.scheduled
  // }
];

export const CollectionStatus = [
  {
    id: 1,
    name: 'Active'
  },
  {
    id: 2,
    name: 'Inactive'
  }
];
