import { FC, useState, createRef, memo, useRef, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import { Dropdown, Portal, VipCustomerTooltip, FirstTimeCustomerTooltip } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { setImageSrc } from 'src/utils/setImageSrc';
import { getPosition } from 'src/utils/getPosition';
import useWindowDimensions from 'src/utils/UseWindowDimensions/useWindowDimensions';

// Icons
import { DefaultImage, LiveViewerFirst, LiveViewerVIP, LiveViewerCart, ThreeDotsIcon } from 'src/assets/icons';

// interface LiveViewerItemProps {
//   liveShowId: string | null;
//   viewer: ILiveUser;
//   newlyJoinedUser: string;
//   handleUpdateViewer: (viewer: ILiveUser) => void;
// }

const getLiveShowViewerDropdownData = (isBlocked) => {
  return [
    {
      id: 1,
      name: isBlocked ? 'Unblock user' : 'Block user'
    },
    {
      id: 2,
      name: 'Add store credit'
    },
    {
      id: 3,
      name: 'Dump cart'
    }
  ];
};

const LiveViewerItem: FC<any> = memo(({ data, index, style }) => {
  const { viewersList, newlyJoinedUser, onBlockUser, onAddStoreCredit, onShowCart, onDumpCart, setRowHeight } = data;
  const viewer = viewersList[index];

  const rowRef = useRef<HTMLDivElement>(null);
  const menuIcon = createRef<any>();

  const { width } = useWindowDimensions();
  const [visible, setVisible] = useState(false);
  const [showDropDown, setShowDropdown] = useState(false);
  const [menuPositions, setMenuPositions] = useState({
    top: 0,
    left: 0
  });

  useEffect(() => {
    if (rowRef?.current) {
      setRowHeight(rowRef.current.clientHeight);
    }
    // eslint-disable-next-line
  }, [rowRef]);

  const openDropDown = (e) => {
    e.stopPropagation();
    setShowDropdown(true);

    if (menuIcon.current) {
      const position = menuIcon.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - position.bottom;

      const tempTopPosition = parseInt(
        spaceBelow < 150 ? window.scrollY + position.top - 150 : window.scrollY + position.top + 30,
        10
      );
      const { topPosition, leftPosition } = getPosition(width, tempTopPosition, position.left, 10.71);

      setMenuPositions({
        top: topPosition,
        left: leftPosition
      });
    }
  };

  const closeDropDown = () => {
    setShowDropdown(false);
  };

  const selectingMenuItem = (option) => {
    if (option.id === 1) {
      onBlockUser(viewer);
    }
    if (option.id === 2) {
      onAddStoreCredit(viewer);
    }
    if (option.id === 3) {
      onDumpCart(viewer);
    }
    setShowDropdown(false);
  };

  const handleShowCartDetails = () => {
    onShowCart(viewer);
  };

  return (
    <div style={style}>
      <div
        ref={rowRef}
        className={`viewer-detail d-flex align-items-center position-relative ${
          newlyJoinedUser === `${viewer?.id}` ? `active` : ``
        }`}
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
      >
        <div className={`position-absolute ${viewer.isLiveBlocked ? 'viewer-detail-blocked' : 'hidden'}`} />
        <div className="circularProgres-with-image">
          {/* <CircularProgressbarWithChildren
            value={viewer?.cartProgress}
            strokeWidth={10}
            styles={buildStyles({
              pathColor: `linear-gradient(180deg, #8ED6FF 0%, #00A3FF 100%)`,
              trailColor: 'transparent'
            })}
          > */}
          <Img
            src={setImageSrc(viewer?.profileUrl ? viewer?.profileUrl : '')}
            placeholderImg={DefaultImage}
            errorImg={DefaultImage}
            className="circle-image object-fit-cover"
          />
          {/* </CircularProgressbarWithChildren> */}
        </div>
        <span className="viewer-name">{viewer?.firstName}</span>
        <FlexBox className="d-flex align-items-center justify-content-around">
          {viewer?.isFirstTimeCustomer && (
            <>
              <img
                className="ms-1 viewer-icon"
                src={LiveViewerFirst}
                alt="First time user"
                data-tip
                data-for="firstTimeCustomerIcon"
              />
              <FirstTimeCustomerTooltip />
            </>
          )}
          {viewer?.noOfCartItems > 0 && (
            <div
              onClick={handleShowCartDetails}
              className="cursor-pointer ms-1 position-relative d-flex align-items-center justify-content-center viewer-icon"
            >
              <span className="cart-number" style={{ color: '#ffffff', position: 'absolute', fontSize: `0.6rem` }}>
                {viewer?.noOfCartItems}
              </span>
              <img src={LiveViewerCart} alt="Cart" className="viewer-icon" />
            </div>
          )}
          {viewer?.isVIPCustomer && (
            <>
              <img className="ms-1 viewer-icon" src={LiveViewerVIP} alt="VIP Customer" data-tip data-for="vipCustomerIcon" />
              <VipCustomerTooltip />
            </>
          )}
        </FlexBox>
        <span ref={menuIcon} className="viewer-action-menu position-absolute">
          <KTSVG onClick={openDropDown} className={`cursor-pointer ${visible ? '' : 'hidden'}`} path={ThreeDotsIcon} />
        </span>
        {showDropDown && (
          <Portal id="kt_body">
            <OutsideClickHandler onOutsideClick={closeDropDown}>
              <div
                style={{ top: `${menuPositions.top}rem`, left: `${menuPositions.left}rem` }}
                className="position-absolute viewer-action-drop-down"
              >
                <Dropdown
                  data={getLiveShowViewerDropdownData(Boolean(viewer && viewer.isLiveBlocked))}
                  onSelect={selectingMenuItem}
                  selected={showDropDown}
                  closeDropdown={() => setShowDropdown(false)}
                />
              </div>
            </OutsideClickHandler>
          </Portal>
        )}
      </div>
    </div>
  );
});

export default LiveViewerItem;
