import { FC, useState, createRef } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { Portal, Dropdown } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { ThreeDotsIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { ActionMenuProps } from '../CatalogList.type';

const ActionMenu: FC<ActionMenuProps> = ({ catalog, catalogOptions }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const moreActionRef = createRef<any>();
  const [dropDownPositions, setDropDownPositions] = useState({
    top: 0,
    left: 0
  });

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  const handleOpenMoreActionDropdown = () => {
    setShowDropdown(true);
    if (moreActionRef.current) {
      const position = moreActionRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - position.bottom;
      setDropDownPositions({
        top: spaceBelow < 180 ? window.scrollY + position.top - 146 : window.scrollY + position.top + 30,
        left: position.left - 135
      });
    }
  };

  return (
    <>
      <div className="position-relative">
        <button onClick={handleOpenMoreActionDropdown} className="bg-transparent border border-transparent" ref={moreActionRef}>
          <KTSVG path={ThreeDotsIcon} svgClassName="cursor-pointer collapseFilter collapseIcon" />
        </button>
      </div>
      {showDropdown && (
        <Portal id="kt_body">
          <OutsideClickHandler
            onOutsideClick={() => {
              if (showDropdown) setShowDropdown(false);
            }}
          >
            <div className="position-absolute" style={{ top: dropDownPositions.top, left: dropDownPositions.left }}>
              <Dropdown
                data={constVariables.catalogOptionsDropdownData}
                selected={showDropdown}
                onSelect={(data) => {
                  catalogOptions(data.id, data.name, catalog.id);
                  closeDropdown();
                }}
                closeDropdown={closeDropdown}
              />
            </div>
          </OutsideClickHandler>
        </Portal>
      )}
    </>
  );
};

export default ActionMenu;
