import { FC, useEffect, useState } from 'react';
import moment from 'moment';

// Apollo
import { useLazyQuery } from '@apollo/client';
import { CREATED_SHIPMENT_DETAILS } from 'src/apollo/queries';

// Components
import { FlexBox, Img, Loader } from 'src/components/atoms';
import { Divider } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';
import { setStatusColor, setStatusText } from '../OrdersList/helpers/orderStatus';

// Icons
import { CloseIcon, CopyIcon, LeftNavigator, RightNavigtor, DefaultImage } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { OrderPreviewShippingDetialProps, OrderPreviewShippingProps } from './OrderShippingPreview.types';

const OrderShipmentPreview: FC<OrderPreviewShippingProps> = ({
  isVisible,
  closeSlide,
  shipmentId,
  shippingAddress,
  shippingIndex
}) => {
  const [orderShippingDetails, setOrderShippingDetails] = useState<OrderPreviewShippingDetialProps>();

  const [callShipmentDetailsQuery] = useLazyQuery(CREATED_SHIPMENT_DETAILS, {
    variables: {
      input: {
        shipmentId
      }
    },
    onCompleted: (data) => {
      if (data.getCreatedShipmentDetails) {
        setOrderShippingDetails(data.getCreatedShipmentDetails);
      }
    },
    onError: (err) => {
      console.error('Order Shipment Details - ', err);
    }
  });

  useEffect(() => {
    if (shipmentId) {
      callShipmentDetailsQuery();
    }
  }, [shipmentId]);

  const dateSuffix = (date: number) => {
    if (date === 1) {
      return 'st';
    } else if (date === 2) {
      return 'nd';
    } else if (date === 3) {
      return 'rd';
    }
    return 'th';
  };

  return (
    <div
      id="kt_activities"
      className={`bg-body drawer drawer-end liveshow-event-preview overflow-visible ${isVisible ? 'drawer-on' : ''}`}
      data-kt-drawer="true"
      data-kt-drawer-name="activities"
      data-kt-drawer-activate="true"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'400px', 'sm': '400px'}"
      data-kt-drawer-direction="end"
      data-kt-drawer-toggle="#kt_activities_toggle"
      data-kt-drawer-close="#kt_activities_close"
    >
      <div className="position-relative w-400px vh-100">
        {!orderShippingDetails ? (
          <Loader type="page" className="h-100" />
        ) : (
          <>
            <div className={`bar-toggle-arrow bg-primary ${!isVisible ? 'd-none' : ''}`} onClick={closeSlide}>
              <img src={RightNavigtor} alt={isVisible ? LeftNavigator : RightNavigtor} />
            </div>
            <div className="w-100 h-100">
              <div className="py-6">
                <div className="px-6">
                  <FlexBox className=" align-items-center justify-content-between">
                    {orderShippingDetails?.id ? (
                      <h2 className="poppins-regular m-auto-0 text-nowrap dark-color">
                        {constVariables.orders.order}
                        <span className="poppins-regular text-primary mx-1">#{orderShippingDetails.orderCode}</span>
                      </h2>
                    ) : null}

                    <img src={CloseIcon} className="cursor-pointer" height={20} onClick={closeSlide} />
                  </FlexBox>
                  {orderShippingDetails?.orderDate ? (
                    <div className="fs-6 greyText mt-2 orderDate">
                      {moment(orderShippingDetails?.orderDate).format('D MMM YYYY, hh:mm a')}
                    </div>
                  ) : null}
                  {orderShippingDetails?.trackingNumber ? (
                    <div className="mt-4 d-flex align-items-center">
                      <div className=" fs-8">
                        <span className="greyText">Tracking Number : </span>
                        <span>{orderShippingDetails.trackingNumber} </span>
                      </div>

                      <img
                        onClick={() => {
                          navigator.clipboard.writeText(orderShippingDetails.trackingNumber);
                        }}
                        className="ms-2 h-15px h-15px cursor-pointer"
                        src={CopyIcon}
                      />
                    </div>
                  ) : null}

                  <div className="mt-4 d-flex align-items-center">
                    <div
                      className={`${setStatusColor('shipped')} order-status-box fs-8 text-nowrap  poppins-medium  cursor-pointer`}
                    >
                      <div className="text-capitalize">{setStatusText('shipped')}</div>
                    </div>
                    {shippingAddress && shippingIndex ? (
                      <div className="ms-2 border rounded px-4 py-2 fs-7 ">
                        <span className="fw-bold text">
                          {shippingIndex}
                          {dateSuffix(shippingIndex)} shipping
                        </span>
                        <span className="helper-text"> - on {moment(shippingAddress).format('DD MMMM YYYY')}</span>
                      </div>
                    ) : null}
                  </div>
                </div>
                <Divider classname="my-7" />
                <div className="px-6">
                  <div className="fs-4 poppins-semibold text-gray-800 opacity-80">CUSTOMER</div>

                  <div className="mt-4 d-flex align-items-center">
                    <Img
                      src={setImageSrc(
                        orderShippingDetails.customerImage
                          ? generateCompressedImageURL(orderShippingDetails.customerImage, '50')
                          : DefaultImage
                      )}
                      placeholderImg={DefaultImage}
                      errorImg={DefaultImage}
                      height={40}
                      width={40}
                      className="rounded-circle objectFit me-4"
                    />
                    {orderShippingDetails?.customerName ? (
                      <div className="ps-4 text-gray-800 opacity-80">{orderShippingDetails.customerName}</div>
                    ) : null}
                  </div>
                </div>
                <Divider classname="my-7" />

                {orderShippingDetails && orderShippingDetails?.items.length > 0 ? (
                  <div className="px-6">
                    <div className="fs-4 poppins-semibold text-gray-800 opacity-80">ITEMS</div>
                    {orderShippingDetails.items.map((data, index) => (
                      <div className="mt-6" key={index}>
                        <div className="d-flex align-items-stretch">
                          {data.image ? (
                            <img className="h-50px w-50px rounded objectFit me-3" src={data.image} />
                          ) : (
                            <div className="h-50px w-50px rounded objectFit me-3" />
                          )}
                          <div className="flex-grow-1  d-flex flex-column justify-content-between">
                            <div className="fs-7 poppins-medium d-flex  justify-content-between">
                              <div>{data.name}</div>
                              <div>${data.price}</div>
                            </div>
                            <div className="helper-text fs-8 d-flex  justify-content-between">
                              <div>
                                <span>{data.variant}</span>
                                <span>, Qty: {data.quantity}</span>
                              </div>
                              {data.warehouseLocation
                                ? data.warehouseLocation.map((data, locationIndex) => (
                                    <div key={locationIndex}>
                                      <span>{data.warehouseId}-</span>
                                      <span>{data.inventoryPosition}</span>
                                    </div>
                                  ))
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
                <Divider classname="mt-7" />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OrderShipmentPreview;
