import { FC, useState } from 'react';

// Components
import { Img } from 'src/components/atoms';

// Icons
import { RightArrowIcon } from 'src/assets/icons';

// Types
import { PLInventoryProps } from './PLInventory.types';

const PLInventory: FC<PLInventoryProps> = ({ quantityInStock, goToInventoryPage }) => {
  const [showArrow, setShowArrow] = useState(false);
  return (
    <div
      onMouseOver={() => {
        setShowArrow(true);
      }}
      onMouseLeave={() => {
        setShowArrow(false);
      }}
      onClick={goToInventoryPage}
      className="d-flex align-items-center"
    >
      {quantityInStock ? quantityInStock : 0} in stock
      {showArrow ? <Img src={RightArrowIcon} className="ms-2 h-10px w-10px" /> : null}
    </div>
  );
};

export default PLInventory;
