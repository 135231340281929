import { FC, useEffect, useMemo, useState } from 'react';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import { InputBox } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { CloseIcon, SendSuccessIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';
import { validateEmail } from 'src/utils/validate';
import { signInWithEmail } from 'src/helpers/InitFirebase';
import { SEND_CHANGE_EMAIL_VERIFICATION } from 'src/apollo/mutations/sendChangeEmailVerificationEmail';
import { useMutation } from '@apollo/client';

interface EmailChangeModalProps {
  closeModal: () => void;
  showForgotPasswordModal: () => void;
}

const EmailChangeModal: FC<EmailChangeModalProps> = ({ closeModal, showForgotPasswordModal }) => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [currenStep, setCurrentStep] = useState('password');
  const [email, setEmail] = useState('');

  const handleChangePassword = (value) => {
    setPassword(value);
  };

  const handleValidatePassword = (value) => {
    // Validate the password;
    if (value.trim().length === 0) {
      setError('Password is required');
    } else {
      setError('');
    }
  };

  useEffect(() => {
    setError('');
  }, []);

  const [sendChangeEmailVerification] = useMutation(SEND_CHANGE_EMAIL_VERIFICATION, {
    fetchPolicy: 'no-cache',
    onCompleted: (resetData) => {
      setCurrentStep('completed');
      setError('');
    },
    onError: (e) => {
      console.error('resetpassword onError - ', e);
      console.error('e?.graphQLErrors[0] - ', e?.graphQLErrors[0]);
      const error: any = e?.graphQLErrors[0];
      if (error?.errorType === 'USER_ALREADY_EXIST') {
        setError('This email is already used in a registered account.');
      } else if (error.errorType === 'USER_NOT_FOUND') {
        setError(error.message);
      } else {
        setError('Somthing went wrong. Please try again later.');
      }
    }
  });

  const handleContinue = async () => {
    switch (currenStep) {
      case 'password': {
        const signInWithEmailResult = await signInWithEmail(localStorage.getItem('email') || '', password).then();
        if (signInWithEmailResult.success) {
          setCurrentStep('email');
        } else {
          setError('Password is incorrect');
        }
        break;
      }
      case 'email': {
        const emailError = validateEmail(email);
        if (typeof emailError === 'string') {
          setError(emailError);
        } else {
          setError('');

          sendChangeEmailVerification({
            variables: {
              input: {
                email
              }
            }
          });
          // setCurrentStep('completed');
        }
        break;
      }

      default:
        break;
    }
  };

  const handleChangeEmail = (value) => {
    setEmail(value);
  };

  const handleValidateEmail = (value) => {
    // Validate the email;
    const emailError = validateEmail(value);
    if (typeof emailError === 'string') {
      setError(emailError);
    } else {
      setError('');
    }
  };

  const isButtonDisabled = useMemo(() => {
    if (currenStep === 'password' && !password) {
      return true;
    } else if (currenStep === 'email' && !email) {
      return true;
    }
    return false;
  }, [currenStep, password, email]);

  const handleShowForgotPasswordModal = () => {
    closeModal();
    showForgotPasswordModal();
  };

  return (
    <div className="change-account-info-modal bg-white modal-content">
      <div className="modal-header">
        <h5 className="modal-title">{constVariables.accountSettings.changeEmail}</h5>
        <div
          className="btn btn-xs btn-active-light-primary p-0 m-0 border-none"
          onClick={() => {
            setError('');
            setPassword('');
            setEmail('');
            closeModal();
          }}
        >
          <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
        </div>
      </div>
      <div className="modal-body">
        {currenStep !== 'completed' && <p>Your current email is {localStorage.getItem('email')}</p>}
        {currenStep === 'password' && (
          <div>
            <InputBox
              title={constVariables.accountSettings.enterPassword}
              value={password}
              onChangeText={(value) => handleChangePassword(value)}
              name="password"
              inputClass="form-control-lg"
              warningText={error}
              placeholder="Password"
              onBlur={(event) => handleValidatePassword(event.target.value)}
              type={showPassword ? 'text' : 'password'}
              isPasswordInput
              toggleShowPassword={() => setShowPassword(!showPassword)}
              imageRight={
                <div
                  className="text-primary cursor-pointer text-btn position-absolute change-input-text"
                  onClick={handleShowForgotPasswordModal}
                >
                  {/* {constVariables.accountSettings.forgotPassword} */}
                </div>
              }
            />
          </div>
        )}
        {currenStep === 'email' && (
          <div>
            <InputBox
              title={constVariables.accountSettings.enterEmail}
              value={email}
              onChangeText={(value) => handleChangeEmail(value)}
              name="email"
              inputClass="form-control-lg"
              warningText={error}
              onBlur={(event) => handleValidateEmail(event.target.value)}
              placeholder="Enter new email"
              type="email"
              helperText={constVariables.accountSettings.sendVerification}
            />
          </div>
        )}
        {currenStep === 'completed' && (
          <div>
            <FlexBox className="align-items-center flex-column m-b-24">
              <Img src={SendSuccessIcon} />
              <p className="mb-0 sent-success m-t-8">{constVariables.accountSettings.sentSuccess}</p>
            </FlexBox>
            <p className="m-b-24">
              We sent a verification link to {email} please follow the instructions to verify your new email.
            </p>
            <p className="mb-0">
              Didn’t get the verification link?{' '}
              <span
                className="text-btn text-primary cursor-pointer"
                onClick={() => {
                  sendChangeEmailVerification({
                    variables: {
                      input: {
                        email
                      }
                    }
                  });
                }}
              >
                Send again
              </span>
            </p>
          </div>
        )}
      </div>
      {(currenStep === 'password' || currenStep === 'email') && (
        <div className="modal-footer">
          <button className="btn btn-primary btn-md" type="button" disabled={isButtonDisabled} onClick={handleContinue}>
            {currenStep === 'password' ? 'Continue' : 'Send verification link'}
          </button>
        </div>
      )}
    </div>
  );
};

export default EmailChangeModal;
