import { FC } from 'react';

// Components
import LiveProductsPreviewPresentational from './LiveProductsPreview.presentational';

// Types
import { LiveProductsPreviewProps } from './LiveProductsPreview.types';

const LiveProductsPreview: FC<LiveProductsPreviewProps> = ({
  productList,
  hidePlaceholderText,
  removeProduct,
  onProductDragEnd
}) => {
  return (
    <LiveProductsPreviewPresentational
      productList={productList}
      hidePlaceholderText={hidePlaceholderText}
      removeProduct={removeProduct}
      onProductDragEnd={onProductDragEnd}
    />
  );
};

export default LiveProductsPreview;
