import { gql } from '@apollo/client';

export const GET_PRODUCTS_V3_BY_SCAN_SKU = gql`
  query GetAllProductV3($input: AllProductsInputV3) @api(name: "productsAPI") {
    getAllProductsV3(input: $input) {
      totalProducts
      products {
        __typename
        ... on AllProductsStoreOwnerOutputV3 {
          productId
          productName
          salePrice
          perItemCost
          profit
          shippingPrice
          productSku
          productBarcode
          productStoreId
          productQty
          committedQuantity
          alertThreshold
          usersQuantity
          sensitive
          productCreatedAt
          productUpdatedAt
          sellOnOutOfStock
          inventory
          productHasVariants
          margin
          productLocation {
            warehouseId
            productLocationId
            productLocationName
            productLocationQuantity
          }
          productMediasJson {
            productMediaId
            productMediaUrl
            productMediaPosition
            productMediaType
          }
          variantLocationDetails {
            productVariantId
            productVariantSku
            productVariantBarcode
            productVariantQty
            productVariantPrice
            variantUsersQuantity
            variantAlertThreshold
            variantStatus
            variantWeight
            variantCommittedQty
            variantMediaId
            variantMediaUrl
            variantMediaType
            variantTypes {
              variantTypeId
              variantTypeName
              variantTypeValue
            }
            variantLocation {
              warehouseId
              productVariantLocationId
              productVariantLocationName
              productVariantLocationQuantity
            }
          }
        }
      }
    }
  }
`;
