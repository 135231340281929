import { gql } from '@apollo/client';

export const GET_ORDER_SHIPMENT = gql`
  query GetOrderShipment($input: GetShippmentDetailsInput!) @api(name: "ordersAPI") {
    getOrderShipment(input: $input) {
      id
      rateId
      packageInfo
      rateInfo
      useWeightOfItems
      packageWeight
      packageWeightUnit
      needsSignatureConfirmation
      hasInsurance
      insurance {
        amount
        content
      }
      trackingNumber
      labelId
      labelUrl
    }
  }
`;
