import { FC, useState, memo, useEffect } from 'react';

// Components
import { FlexBox } from 'src/components/atoms';
import { Pagination } from 'src/components/molecules';
import { WaitlistsList, PageHeader } from 'src/components/oraganisms';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { WaitlistsPagePresentationalProps } from './WaitlistsPage.type';
import { ColumnType } from 'src/components/molecules/Table/Table.types';

const initialWaitListTableColumns: ColumnType[] = [
  { name: 'Commited', status: true },
  { name: 'Waitlist', status: true },
  { name: 'Waitlist Authorized', status: true },
  { name: 'Type', status: true },
  { name: 'Vendor', status: true },
  { name: 'PRICE', status: true },
  { name: 'Restock status', status: true },
  { name: 'Estimated reorder profit', status: true }
];

const WaitlistsPagePresentational: FC<WaitlistsPagePresentationalProps> = (props) => {
  const { waitlists, setWaitlists, sortBy, setSortBy, waitlistsPageInfo, setWaitlistsPageInfo, isLoading, totalCount } = props;

  const [openEditColumnDropdown, setOpenEditColumnDropdown] = useState(false);
  const [editColumnData, setEditColumnData] = useState<ColumnType[]>([]);

  useEffect(() => {
    const tableColumns = localStorage.getItem('waitlistTable');
    if (tableColumns) {
      setEditColumnData(JSON.parse(tableColumns));
    } else {
      setEditColumnData(initialWaitListTableColumns);
    }
  }, []);

  return (
    <FlexBox className="p-40">
      <div className={`width-animation w-100 d-flex flex-column hide-scrollbar `}>
        <PageHeader
          pageTitle={constVariables.WaitlistsMenu.waitlists}
          removeExportImport
          isEditFields={true}
          openEditColumnDropdown={openEditColumnDropdown}
          setOpenEditColumnDropdown={setOpenEditColumnDropdown}
          editColumnData={editColumnData}
          setEditColumnData={setEditColumnData}
        />

        <div className="card w-100 p-0 mb-0">
          <div className="p-x-32 p-t-32">
            <WaitlistsList
              waitlists={waitlists}
              setWaitlists={setWaitlists}
              setSortBy={setSortBy}
              sortBy={sortBy}
              rapidActionOpen={false}
              isLoading={isLoading}
              editColumnData={editColumnData}
            />
          </div>

          <div className="w-100">
            <Pagination
              skipCount={waitlistsPageInfo?.skipCount}
              limitCount={waitlistsPageInfo?.limitCount}
              setSkipCount={(num: number) =>
                setWaitlistsPageInfo((prev) => ({
                  ...prev,
                  skipCount: num
                }))
              }
              total={totalCount}
            />
          </div>
        </div>
      </div>
    </FlexBox>
  );
};

export default memo(WaitlistsPagePresentational);
