import { gql } from '@apollo/client';

export const GET_APP_LOGO_UPLOAD_URL = gql`
  query getApplogoUploadUrl @api(name: "authAPI") {
    getApplogoUploadUrl {
      uploadURL
      accessURL
    }
  }
`;
