import React, { FC, useState } from 'react';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Apollo
import { useMutation } from '@apollo/client';
import { GET_MEDIA_UPLOAD_URL } from 'src/apollo/mutations/getMediaUploadUrl';

// Components
import { ImageDnd, FileInput, Tabs, RecordingDnd } from 'src/components/molecules';
import { CustomModal, ErrorModal } from 'src/components/oraganisms';
import EmptyMedias from 'src/components/molecules/RecordingDnd/EmptyMedias';

// Icons
import { AddVariantImageSmallIcon, AcceptDraggedFile } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { TableData, RecordingData } from 'src/components/pages/products/AddProduct/AddProduct.types';
import { UserRoles } from 'src/components/pages/settings/UserPermissions/UserPermissions.type';

// Styles
import './ProductMedia.scss';

interface ProductMediaProps {
  sendMedia: (updatedImages: any[]) => void;
  productMedia: any[];
  updateProductMedia: (media: any[]) => void;
  setProductMedia: React.Dispatch<React.SetStateAction<any[]>>;
  variantsData: TableData[];
  recordings: RecordingData[];
  isAutoAssignPrimaryVideo: boolean;
  setIsAutoAssignPrimaryVideo: React.Dispatch<React.SetStateAction<boolean>>;
  primaryVideoId: string;
  setPrimaryVideoId: React.Dispatch<React.SetStateAction<string>>;
}

const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.tif|\.svg|\.webp|\.heif)$/i;

const ProductMedia: FC<ProductMediaProps> = (props) => {
  const [selectedTab, setSelectedTab] = useState('IMAGES');
  const {
    sendMedia,
    productMedia,
    updateProductMedia,
    setProductMedia,
    variantsData,
    recordings,
    isAutoAssignPrimaryVideo,
    setIsAutoAssignPrimaryVideo,
    primaryVideoId,
    setPrimaryVideoId
  } = props;
  const [isDragActive, setIsDragActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showFileTypeError, setShowTypeError] = useState(false);
  // const [isExcludeVideo, setIsExcludeVideo] = useState(false);
  const [loadingFilesCount, setLoadingFilesCount] = useState<number>(0);
  const [accessLoading, setAccessLoading] = useState(false);

  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  const sendUpdatedResult = (updatedMedia: Array<any>) => {
    setProductMedia(updatedMedia);
  };

  const getImageUrl = async (item, file) => {
    const { uploadURL } = item;
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': file.type
      },
      body: file
    };

    try {
      await fetch(uploadURL, requestOptions);
    } catch (err) {
      console.log('error', err);
    }
  };

  const [getMediaUploadUrl] = useMutation(GET_MEDIA_UPLOAD_URL, {
    onError: (error) => {
      console.log('error', error);
    }
  });

  const handleSetFiles = async (files) => {
    setAccessLoading(true);
    setLoadingFilesCount(files.length);
    for (let i = 0; i < files.length; i++) {
      if (!allowedExtensions.exec(files[i].name)) {
        setIsDragActive(false);
        setErrorMessage(
          `${files[i].name} is not a supported file type. Upload files ending with: jpg, jpeg, png, svg, webP, heif.`
        );
        setShowTypeError(true);
        setAccessLoading(false);
        setLoadingFilesCount(0);
        return;
      }
    }

    const res = await getMediaUploadUrl({
      variables: {
        input: {
          urlCount: files.length,
          fileType: 'IMAGE',
          uploadType: 'PRODUCT'
        }
      }
    });

    files = Object.keys(files).map((key) => files[key]);

    for (let i = 0; i < files.length; i++) {
      // eslint-disable-next-line no-loop-func
      const reader = new FileReader();
      const file = files[i];
      reader.onloadend = () => {
        files[i] = { ...files[i], fileimage: reader.result, filesize: files[i].size };
      };
      if (files[i]) {
        reader.readAsDataURL(file);
      }
    }

    const data = res.data;
    if (data.getMediaUploadUrl) {
      try {
        await Promise.all(data.getMediaUploadUrl.map(async (item, index) => await getImageUrl(item, files[index])));
      } catch (error) {
        setAccessLoading(false);
        console.log(error);
      } finally {
        // const maxFileSize = Math.abs(
        //   [files.map((file) => file.filesize)].reduce((partialSum, item) => partialSum + item, 0) / (1024 * 1024)
        // );
        // await new Promise((resolve) =>
        //   setTimeout(() => {
        //     resolve(1);
        //   }, 15000)
        // );
        setLoadingFilesCount(0);
      }

      updateProductMedia(
        data.getMediaUploadUrl.map((item, index) => {
          return {
            productMediaUrl: item.accessURL,
            fileimage: files[index].fileimage,
            productMediaPosition: index + 1 + productMedia.length
          };
        })
      );

      setAccessLoading(false);
    }
  };

  const handleProductFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    handleSetFiles(files);
  };

  const handleActionErrorModal = () => {
    setErrorMessage('');
    setIsDragActive(false);
    closeFileTypeErrorModal();
  };

  const handleChangeAutoAssign = (e) => {
    setIsAutoAssignPrimaryVideo(e.target.checked);
  };

  const closeFileTypeErrorModal = () => {
    document.body.style.overflow = 'unset';
    setShowTypeError(false);
  };

  return (
    <div className="card w-100 p-b-20">
      <h4 className="section-title media-title mb-6">{constVariables.common.productmedia.key}</h4>
      <div className="d-flex align-items-center justify-content-between">
        <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} tabs={constVariables.TabForProductMeida} />

        {selectedTab === 'RECORDINGS' && (
          <div className="d-flex align-items-center justify-content-between">
            <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
              <input
                className="form-check-input"
                type="checkbox"
                checked={isAutoAssignPrimaryVideo}
                name="switchColumns"
                onChange={handleChangeAutoAssign}
                disabled={role === UserRoles.FULFILMENT_ASSOCIATE}
              />
            </div>
            <div className="ms-3 mt-0 helper-text">Set Recent Recording as Primary</div>
          </div>
        )}
      </div>

      <div className="p-t-24 position-relative">
        {selectedTab === 'IMAGES' && (
          <>
            <div>
              {productMedia?.length > 0 && (
                <FileInput
                  className={`whole-drag-section ${isDragActive ? 'drag-active' : ''}`}
                  handleSetFiles={handleSetFiles}
                  isDragActive={isDragActive}
                  setIsDragActive={setIsDragActive}
                ></FileInput>
              )}
              <div>
                {productMedia.length === 0 &&
                  loadingFilesCount === 0 &&
                  !accessLoading &&
                  role !== UserRoles.FULFILMENT_ASSOCIATE && (
                    <>
                      <p className="mediaGreyText">{constVariables.common.productmedia.titleMessage}</p>
                      <div className="product-file-upload">
                        <FileInput
                          className={`product-file-upload-box ${isDragActive ? 'dragged-status' : ''}`}
                          handleSetFiles={handleSetFiles}
                          isDragActive={isDragActive}
                          setIsDragActive={setIsDragActive}
                        >
                          <input
                            type="file"
                            id="fileupload"
                            className="file-upload-input"
                            onChange={handleProductFileChange}
                            multiple
                          />
                          <img
                            src={!isDragActive ? AddVariantImageSmallIcon : AcceptDraggedFile}
                            className={`uploadIcon ${isDragActive ? 'accepted' : ''}`}
                          />
                          <div className="dndText">
                            <p className="poppins-semibold m-0">{constVariables.common.productmedia.dndTitleText}</p>
                            {!isDragActive && (
                              <p className="mediaGreyText m-0">{constVariables.common.productmedia.titleMessage}</p>
                            )}
                          </div>
                        </FileInput>
                      </div>
                      {/* <FlexBox>
                <div className="videoCheck me-3">
                  <input
                    className="form-check-input"
                    id="check"
                    type="checkbox"
                    name=""
                    style={{ outline: 'none' }}
                    checked={isExcludeVideo}
                    onClick={() => setIsExcludeVideo(!isExcludeVideo)}
                    onChange={() => setIsExcludeVideo(!isExcludeVideo)}
                  />
                </div>
                <div className="videoCheckArea">
                  <p className="mt-2 mb-3 input-title mb-0 cursor-pointer poppins-medium final-scale-checkbox">
                    {constVariables.common.productmedia.videoCheckTitle}
                  </p>
                  <p className="videoMessage mediaGreyText">{constVariables.common.productmedia.videoCheckMessage}</p>
                </div>
              </FlexBox> */}
                    </>
                  )}
                {productMedia.length === 0 && role === UserRoles.FULFILMENT_ASSOCIATE && (
                  <EmptyMedias
                    icon={AcceptDraggedFile}
                    title={constVariables.common.productmedia.emptyImagesTitle}
                    description={constVariables.common.productmedia.emptyImagesDescription}
                  />
                )}
                <ImageDnd
                  sendUpdatedResult={sendUpdatedResult}
                  handleProductFileChange={handleProductFileChange}
                  sendMedia={sendMedia}
                  isLoading={accessLoading}
                  handleSetFiles={handleSetFiles}
                  productMedia={productMedia}
                  variantsData={variantsData}
                  loadingFilesCount={loadingFilesCount}
                />
              </div>
            </div>
            {showFileTypeError && (
              <CustomModal bodyClassname="w-90 w-md-150" show={showFileTypeError} closeModal={closeFileTypeErrorModal}>
                <ErrorModal
                  message={constVariables.common.onSaveError.message}
                  actionTitle={constVariables.common.onSaveError.action}
                  actionHandler={handleActionErrorModal}
                  errors={[errorMessage]}
                />
              </CustomModal>
            )}
          </>
        )}
        {selectedTab === 'RECORDINGS' && (
          <RecordingDnd
            recordings={recordings}
            primaryVideoId={primaryVideoId}
            setPrimaryVideoId={setPrimaryVideoId}
            setIsAutoAssignPrimaryVideo={setIsAutoAssignPrimaryVideo}
          />
        )}
      </div>
    </div>
  );
};

export default ProductMedia;
