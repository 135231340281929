import { FC, useCallback, useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import clsx from 'clsx';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import { debounce } from 'lodash';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_ALL_CUSTOMERS } from 'src/apollo/queries';

// Components
import { InputBox, CustomerBlock, RadioSelector } from 'src/components/molecules';
import { FlexBox, Img } from 'src/components/atoms';
import CustomerTagsSection from './CustomerTagsSection';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { DownArrowIcon, NotFoundCustomerIcon, DefaultImage, RemoveButtonIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants/constVariables';

// Types
import { CustomerEligibilityTypeSection, CustomerItem } from 'src/components/pages/coupons/CreateCoupon/CreateCoupon.types';

// Styles
import './_customerEligibility.scss';

interface queryResult {
  getAllCustomers: {
    total: number;
    customers: any;
  };
}

const CustomerEligibilityCreateCoupon: FC<CustomerEligibilityTypeSection> = ({
  customerEligibility,
  setCustomerEligibility,
  errorMessages
}) => {
  const [isEveryone, setIsEveryone] = useState(false);
  const [isSegment, setIsSegment] = useState(false);
  const [isCustomer, setIsCustomer] = useState(false);
  const [isCustomerSearchList, setIsCustomerSearchList] = useState(false);
  const [searchCustomer, setSearchCustomer] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('');
  const [selectedCustomers, setSelectedCustomers] = useState<CustomerItem[]>([]);

  const { data, fetchMore } = useQuery(GET_ALL_CUSTOMERS, {
    variables: {
      input: {
        pageInfo: {
          skipCount: 0,
          limitCount: 10
        },
        searchString: searchKey
      }
    },
    onError: (err) => {
      console.error('Get all customer ', err);
    }
  });

  const debouncedFn = useCallback(
    debounce((key) => setSearchKey(key), 1000),
    []
  );

  useEffect(() => {
    debouncedFn(searchCustomer);
  }, [searchCustomer]);

  useEffect(() => {
    setSelectedCustomers(customerEligibility.customers?.length > 0 ? customerEligibility.customers : []);

    if (customerEligibility.value === 'EVERYONE') {
      setIsEveryone(true);

      setIsSegment(false);
      setIsCustomer(false);
    } else if (customerEligibility.value === 'SPECIFIC_CUSTOMER_SEGMENTS') {
      setIsSegment(true);

      setIsEveryone(false);
      setIsCustomer(false);
    } else if (customerEligibility.value === 'SPECIFIC_CUSTOMERS') {
      setIsCustomer(true);

      setIsEveryone(false);
      setIsSegment(false);
    }
  }, [customerEligibility]);

  const handleNone = () => {
    setCustomerEligibility((prev) => ({
      ...prev,
      value: 'EVERYONE',
      customers: [],
      segments: []
    }));
  };
  const handleSegmentOption = () => {
    setCustomerEligibility((prev) => ({
      ...prev,
      value: 'SPECIFIC_CUSTOMER_SEGMENTS',
      customers: []
    }));
  };
  const handleCustomerOption = () => {
    setCustomerEligibility((prev) => ({
      ...prev,
      value: 'SPECIFIC_CUSTOMERS',
      segments: []
    }));
  };
  const closeCustomerSearchList = () => {
    setIsCustomerSearchList(false);
  };

  const changeSearchCustomer = (data: string) => {
    setSearchCustomer(data);
  };

  const NotFoundCustomer = () => {
    return (
      <div className="customer-not-found d-flex flex-column align-items-center">
        <Img src={NotFoundCustomerIcon} />
        <h5>Customers not found</h5>
        <p>Please try another name or email</p>
      </div>
    );
  };

  const handleToggleCustomer = (selectedcustomer: CustomerItem) => {
    const customerIndex = selectedCustomers?.findIndex((customer) => customer.id === selectedcustomer.id);
    if (customerIndex > -1) {
      setSelectedCustomers(selectedCustomers?.filter((customer) => customer.id !== selectedcustomer.id));
    } else {
      setSelectedCustomers([...selectedCustomers, selectedcustomer]);
    }
  };

  const handleRemoveCustomer = (customerId) => {
    setSelectedCustomers(selectedCustomers?.filter((customer) => customer.id !== customerId));
    setCustomerEligibility((prev) => ({
      ...prev,
      customers: prev.customers?.filter((customer) => customer.id !== customerId)
    }));
  };

  const isItemLoaded = (index) => index < data?.getAllCustomers?.customers?.length;

  const loadMoreRows = () => {
    fetchMore({
      variables: {
        input: {
          pageInfo: {
            skipCount: data?.getAllCustomers?.customers?.length,
            limitCount: 10
          },
          searchString: searchCustomer
        }
      },
      updateQuery: (previousQueryResult: queryResult, { fetchMoreResult }) => {
        if (fetchMoreResult?.getAllCustomers?.customers.length === 0) {
          return previousQueryResult;
        }

        return {
          getAllCustomers: {
            total: fetchMoreResult?.getAllCustomers?.total,
            customers: [...previousQueryResult.getAllCustomers?.customers, ...fetchMoreResult?.getAllCustomers?.customers]
          }
        };
      }
    });
  };

  const handleAddCustomersToCoupon = () => {
    setCustomerEligibility((prev) => ({ ...prev, customers: selectedCustomers }));
    setIsCustomerSearchList(false);
  };

  return (
    <div className="card w-100">
      <div className="section-title m-b-32">{constVariables.Coupons.AddCoupon.customerEligibility.customerEligibility}</div>
      <RadioSelector
        name={constVariables.Coupons.AddCoupon.customerEligibility.everyone}
        isCheck={isEveryone}
        changeCheck={handleNone}
      />
      <div className="m-b-24">
        <RadioSelector
          name={constVariables.Coupons.AddCoupon.customerEligibility.specificCustomerSegments}
          isCheck={isSegment}
          changeCheck={handleSegmentOption}
          noMarginBottom={isSegment ? false : true}
        />
        {isSegment && (
          <CustomerTagsSection
            customerEligibility={customerEligibility}
            setCustomerEligibility={setCustomerEligibility}
            errorMessages={errorMessages}
          />
        )}
      </div>
      <div className={isCustomer ? 'mb-0' : 'm-b-24'}>
        <RadioSelector
          name={constVariables.Coupons.AddCoupon.customerEligibility.specificCustomers}
          isCheck={isCustomer}
          changeCheck={handleCustomerOption}
          noMarginBottom={isCustomer ? false : true}
        />
        {isCustomer ? (
          <div className="section-content">
            <OutsideClickHandler onOutsideClick={closeCustomerSearchList}>
              <div className="col-6 mt-3 m-b-12 position-relative">
                <FlexBox
                  className={`form-control form-control-lg  px-5 justify-content-between align-items-center cursor-pointer dropdown-box `}
                  onClick={() => setIsCustomerSearchList(true)}
                >
                  <InputBox
                    value={searchCustomer}
                    onChangeText={changeSearchCustomer}
                    name={constVariables.Coupons.AddCoupon.customerEligibility.searchForCustmoers}
                    placeholder={constVariables.Coupons.AddCoupon.customerEligibility.searchForCustmoers}
                    noBorders
                  />
                  <KTSVG path={DownArrowIcon} />
                </FlexBox>

                {errorMessages?.specifiCustomersError && (
                  <div className="warning-text p-0 fs-7 mt-1">{errorMessages?.specifiCustomersError}</div>
                )}

                <div
                  className={clsx(`p-5 dropdown-menu z-index-dropdown w-100 mt-2 overflow-scroll mh-300px customer-dropdown-menu`, {
                    show: isCustomerSearchList
                  })}
                  aria-labelledby="dropdownMenuButton"
                >
                  <InfiniteLoader
                    isItemLoaded={isItemLoaded}
                    loadMoreItems={loadMoreRows}
                    itemCount={data?.getAllCustomers?.customers?.length + 10}
                    threshold={1}
                  >
                    {({ onItemsRendered, ref }) => (
                      <AutoSizer>
                        {({ height, width }) => (
                          <List
                            height={height}
                            onItemsRendered={onItemsRendered}
                            ref={ref}
                            itemCount={data?.getAllCustomers?.customers?.length}
                            itemSize={60}
                            width={width}
                            itemData={{
                              customers: data?.getAllCustomers?.customers,
                              handleToggleCustomer,
                              selectedCustomers
                            }}
                          >
                            {CustomerBlock}
                          </List>
                        )}
                      </AutoSizer>
                    )}
                  </InfiniteLoader>
                  {searchCustomer && data?.getAllCustomers?.customers?.length === 0 && <NotFoundCustomer />}
                  {data?.getAllCustomers?.customers?.length > 0 && (
                    <FlexBox className="justify-content-end customer-dropdown-menu-actions position-absolute w-100">
                      <button onClick={closeCustomerSearchList} type="button" className="btn btn-outlined-secondary me-3 btn-sm">
                        Cancel
                      </button>
                      <button onClick={handleAddCustomersToCoupon} type="button" className="btn btn-primary btn-sm">
                        Add
                      </button>
                    </FlexBox>
                  )}
                </div>
              </div>
            </OutsideClickHandler>
            {customerEligibility?.customers?.length > 0 && (
              <div className="mt-6 coupon-customers-table overflow-scroll">
                <table className="table table-row-black-300 align-middle gs-0 gy-4">
                  <thead>
                    <tr>
                      <th className="ps-4">Customers</th>
                      <th>Email</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom">
                    {customerEligibility?.customers?.map((customer) => (
                      <tr key={customer.id}>
                        <td className="align-middle ps-2">
                          <div className="d-flex align-items-center">
                            <Img
                              src={customer.profilePicture}
                              placeholderImg={DefaultImage}
                              errorImg={DefaultImage}
                              className="coverFit h-40px w-40px ms-2 rounded-circle"
                            />
                            <div className="ms-3">
                              <div className="customerBlockName">{customer.name}</div>
                            </div>
                          </div>
                        </td>
                        <td className="align-middle">{customer.email}</td>
                        <td className="align-middle">
                          <button className="btn delete-icon-btn" onClick={() => handleRemoveCustomer(customer.id)}>
                            <KTSVG path={RemoveButtonIcon} className="m-0" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CustomerEligibilityCreateCoupon;
