import { orderDetailsData } from 'src/components/oraganisms/OrdersList/OrdersList.types';
import { Notes } from '../../products/AddProduct/AddProduct.types';

export interface DeleteItemType {
  isDelete: boolean;
  orderId: number;
  itemId: number;
}

export enum RefundType {
  STORE_CREDIT = 'STORE_CREDIT',
  ORIGINAL_PAYMENT_METHOD = 'ORIGINAL_PAYMENT_METHOD'
}

export interface EditOrderDetailsItem {
  orderItemId?: number;
  productId?: number;
  variantId?: number;
  quantity?: number;
  total?: number;
  price?: number;
}

export interface EditOrderDetailsFormType {
  orderId: number;
  items: EditOrderDetailsItem[];
}

export type OrderDetailsPresentationProps = {
  orderDetails: orderDetailsData;
  notesData: Notes[];
  setNotesData: React.Dispatch<React.SetStateAction<Notes[]>>;
  addNewNote: (newNoteData: string) => void;
  editOldNote: (oldNoteData: string, noteId: number) => void;
  deleteOldNote: (noteId: number) => void;
  orderId: string;
  isError: boolean;
  verifiedManually: boolean;
  markUserAddressVerified: () => void;
  isDelete?: DeleteItemType | null;
  setIsDelete?: React.Dispatch<React.SetStateAction<DeleteItemType | null>>;
  variantDetailsData?: {
    id: number;
    price: number;
    quantity: number;
    sku: string;
    media: {
      id: string;
      url: string;
      type: string;
      reviewId: number;
    };
    imageURL: string;
    variantTypes: { id: string; name: string; value: string }[];
  }[];
  setVariantDetailsData?: React.Dispatch<
    React.SetStateAction<
      {
        id: number;
        price: number;
        quantity: number;
        sku: string;
        media: {
          id: string;
          url: string;
          reviewId: number;
        };
        imageURL: string;
        variantTypes: { id: string; name: string; value: string }[];
      }[]
    >
  >;
  openAddProduct?: () => void;
  editShippingAddress?: () => void;
  addressesCustomerDetails: AddressCDType;
  setAddressesCustomerDetails: React.Dispatch<React.SetStateAction<AddressCDType>>;
  saveAdressFromList?: (addressId: number) => void;
  isSave: boolean;
  setIsSave: React.Dispatch<React.SetStateAction<boolean>>;
  isEdit?: boolean;
  setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedAdressId: number;
  setSelectedAdressId: React.Dispatch<React.SetStateAction<number>>;
  openAddressModal?: () => void;
  deleteAddress?: (add: any) => void;
  editAddress?: (add: any) => void;
  getAdrressList?: () => void;
  isEditCustomerAddress?: boolean;
  setIsEditCustomerAddress?: React.Dispatch<React.SetStateAction<boolean>>;
  updateOrderSummaryFormChanges?: React.Dispatch<React.SetStateAction<boolean>>;
  updatedFormData?: (itemList: EditOrderDetailsItem[]) => void;
  orderSummaryFormData?: EditOrderDetailsFormType;
  timeLineData: TimelineData[];
};

export type AddressCDType = {
  availableStoreCredit?: number;
  userAddress: {
    id: number;
    receiverName: string;
    addressLine1: string;
    city: string;
    state: string;
    country: string;
    pincode: string;
    type: 'HOME' | 'WORK' | 'OTHERS';
    isPrimary: boolean;
    phoneNumber: string;
    isVerified: boolean;
  }[];
};

export interface OrderDetailsTableProps {
  tempdata?: number;
  orderDetails?: orderDetailsData;
  isError: boolean;
  isDelete?: any;
  setIsDelete?: React.Dispatch<React.SetStateAction<any>>;
  variantDetailsData?: {
    id: number;
    price: number;
    quantity: number;
    sku: string;
    media: {
      id: string;
      url: string;
      reviewId: number;
    };
    imageURL: string;
    variantTypes: { id: string; name: string; value: string }[];
  }[];
  setVariantDetailsData?: React.Dispatch<
    React.SetStateAction<
      {
        id: number;
        price: number;
        quantity: number;
        sku: string;
        media: {
          id: string;
          url: string;
          reviewId: number;
        };
        imageURL: string;
        variantTypes: { id: string; name: string; value: string }[];
      }[]
    >
  >;
  openAddProduct?: () => void;
  updateOrderSummaryFormChanges?: React.Dispatch<React.SetStateAction<boolean>>;
  updatedFormData?: (itemList: EditOrderDetailsItem[]) => void;
  orderSummaryFormData?: EditOrderDetailsFormType;
}

export interface TimelineData {
  id: number;
  heading: string;
  description: string;
  metaData: string;
  time: string;
}
