import { gql } from '@apollo/client';

export const BLOCK_USER_FROM_COMMENTS = gql`
  mutation blockUserFromComments($input: BlockLiveUserInput!) @api(name: "liveStreamingAPI") {
    blockUserFromComments(input: $input) {
      id
      success
      message
    }
  }
`;
