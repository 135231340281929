import { gql } from '@apollo/client';

export const DELETE_PRODUCT_TYPE = gql`
  mutation deleteProductType($input: AlterProductTypeInput!) @api(name: "productsAPI") {
    deleteProductType(input: $input) {
      success
      message
    }
  }
`;
