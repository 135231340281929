import { FC, useState } from 'react';
import { useHistory } from 'react-router';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { LongArrowIcon } from 'src/assets/icons';

// Types
import { SettingsBlockProps } from './SettingsBlock.types';

// Styles
import './_settingsBlock.scss';

const SettingsBlock: FC<SettingsBlockProps> = ({ blockData }) => {
  const [coloredIcons, setColoredIcons] = useState(false);
  const [coloredText, setColoredText] = useState(false);
  const [isArrow, setisArrow] = useState(false);

  const history = useHistory();

  const hoverAdded = () => {
    setColoredIcons(true);
    setisArrow(true);
  };
  const hoverRemoved = () => {
    setColoredIcons(false);
    setisArrow(false);
  };
  const mouseDown = () => {
    setColoredText(true);
    setColoredIcons(true);
    setisArrow(false);
  };
  const mouseUp = () => {
    setColoredText(false);
    setColoredIcons(true);
    setisArrow(true);
  };
  const goToPage = () => {
    if (blockData.isExternalLink && blockData.pageLink) {
      return Object.assign(document.createElement('a'), {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: blockData.pageLink,
      }).click();
    }
    if (blockData.pageLink) {
      history.push(blockData.pageLink);
    }
  };
  return (
    <div className="col-xs-12 col-sm-6 col-md-4 m-b-24">
      <div
        onMouseOver={hoverAdded}
        onMouseLeave={hoverRemoved}
        onMouseDown={mouseDown}
        onMouseUp={mouseUp}
        className="cursor-pointer bg-white p-5 rounded h-100 w-100"
        onClick={goToPage}
      >
        <div className="my-2 d-flex justify-content-between ">
          <KTSVG svgColor="red" svgClassName={`h-30px w-30px  ${coloredIcons ? 'settingBlockIcons' : ''}`} path={blockData.icon} />
          {isArrow ? <KTSVG svgClassName={`h-30px w-30px settingBlockIcons`} path={LongArrowIcon} /> : null}
        </div>
        <div className={` poppins-semibold fs-4 my-2 ${coloredText ? 'settingBlockIcons' : ''}`}>{blockData.heading}</div>
        <div className={` poppins-semibold fs-7 my-2 opacity-80`}>{blockData.description}</div>
      </div>
    </div>
  );
};

export default SettingsBlock;
