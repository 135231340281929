import { gql } from '@apollo/client';

export const GET_TIKTOK_PRODUCTS = gql`
  query GetTikTokProducts($input: GetTikTokProductsInput!) @api(name: "tiktokAPI") {
    getTikTokProducts(input: $input) {
      totalProducts
      products {
        id
        name
        imageUrl
        inventory
        category {
          id
          l1
          l2
          l3
        }
        status
        error
        price
      }
    }
  }
`;
