export const SortIds = [
  {
    id: 619,
    label: 'Product',
    name: 'NAME'
  },
  {
    id: 620,
    label: 'Inventory',
    name: 'QUANTITY'
  },
  // {
  //   id: 621,
  //   label: 'Vendor',
  //   name: 'VENDOR'
  // },
  {
    id: 622,
    label: 'Price',
    name: 'PRICE'
  },
  {
    id: 623,
    label: 'Status',
    name: 'STATUS'
  }
];
