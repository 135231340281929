import { gql } from '@apollo/client';

export const GET_STORE_DETAILS = gql`
  query onGetStoreDetails @api(name: "authAPI") {
    getStoreDetails {
      __typename
      name
      customerSupportPhoneCountryCode
      customerSupportPhone
      customerSupportEmail
      legalBusinessName
      address
      apartmentSuite
      city
      postalCode
      state
      country
      storeCurrency
      unitOfMeasurement
      weightUnit
      customerSpendingThreshold
      combineOrders
      autoAssignPrimaryVideo
    }
  }
`;
