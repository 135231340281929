import { FC } from 'react';
import clsx from 'clsx';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

// Components
import { Img } from 'src/components/atoms';
import RecordingSlideItem from './RecordingSlideItem';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { SlideCloseIcon, CarouselPrevIcon, CarouselNextIcon } from 'src/assets/icons';

// Types
import { RecordingData } from 'src/components/pages/products/AddProduct/AddProduct.types';

interface RecordingPreviewSlideModalProps {
  show: boolean;
  currentSlide: number;
  recordings: RecordingData[];
  handleCloseModal: () => void;
  handleUpdateCurrentSlide: (index: number) => void;
}

const RecordingPreviewSlideModal: FC<RecordingPreviewSlideModalProps> = ({
  show,
  currentSlide,
  recordings,
  handleCloseModal,
  handleUpdateCurrentSlide
}) => {
  const renderCustomThumbs = () => {
    const thumbList = recordings.map((recording, index) => (
      <Img key={index} src={recording.thumbnailUrl} alt={recording.thumbnailUrl} />
    ));
    return thumbList;
  };

  const handleChangeSlide = (selectedIndex) => {
    handleUpdateCurrentSlide(selectedIndex);
  };

  return (
    <div
      className={clsx('modal fade opacity-100', {
        show
      })}
      aria-modal="true"
      tabIndex={-1}
      id="kt_modal_1"
    >
      <button className="closeIcon cursor-pointer" onClick={handleCloseModal}>
        <KTSVG path={SlideCloseIcon} />
      </button>
      <div className="video-preview-modal-content">
        <Carousel
          selectedItem={currentSlide}
          infiniteLoop={false}
          showIndicators={false}
          showStatus={false}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <button type="button" className="carousel-arrow-icon prev-icon" onClick={onClickHandler}>
                <KTSVG path={CarouselPrevIcon} className="cursor-pointer" />
              </button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button type="button" className="carousel-arrow-icon next-icon" onClick={onClickHandler}>
                <KTSVG path={CarouselNextIcon} className="cursor-pointer" />
              </button>
            )
          }
          renderThumbs={renderCustomThumbs}
          onChange={handleChangeSlide}
        >
          {recordings.map((recording, index) => (
            <RecordingSlideItem key={index} recording={recording} currentSlide={currentSlide} />
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default RecordingPreviewSlideModal;
