import { liveEventEnum } from 'src/components/templates/CreateLiveShow/CreateLiveShow.types';

export const defaultDevicePermissions = {
  video: false,
  audio: false
};

export const defaultLiveShowData = {
  title: '',
  description: '',
  thumbnailUrl: '',
  liveEventType: liveEventEnum.INSTANT,
  streamOnApp: true,
  streamOnWebsite: true,
  facebookChannels: [],
  products: [],
  notification: {
    textMessage: '',
    app: {
      title: '',
      description: ''
    },
    fb: {
      title: '',
      description: ''
    },
    messenger: {
      title: '',
      description: ''
    }
  }
};

export const defaultliveShowPlannedInfo = {
  id: '',
  title: '',
  description: '',
  thumbnailUrl: {
    url: ''
  },
  date: new Date(),
  startingAt: undefined,
  endingAt: undefined,
  streamOnApp: true,
  streamOnWebsite: true,
  facebookChannels: [],
  products: [],
  notification: {
    textMessage: '',
    app: {
      title: '',
      description: ''
    },
    fb: {
      title: '',
      description: ''
    },
    messenger: {
      title: '',
      description: ''
    }
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getLiveShowPlanedInfoFromEventDetails = (eventDetails) => ({
  id: eventDetails.id,
  title: eventDetails.title,
  description: '',
  thumbnailUrl: eventDetails.thumbnailUrl,
  date: new Date(eventDetails.startingAt),
  startingAt: eventDetails.startingAt,
  endingAt: eventDetails.endingAt,
  streamOnApp: true,
  streamOnWebsite: true,
  facebookChannels: eventDetails.facebookChannels,
  products: eventDetails.products,
  notification: {
    textMessage: '',
    app: {
      title: '',
      description: ''
    },
    fb: {
      title: '',
      description: ''
    },
    messenger: {
      title: '',
      description: ''
    }
  }
});
