import { gql } from '@apollo/client';

export const DELETE_COMMENT = gql`
  mutation deleteComment($input: DeleteCommentInput!) @api(name: "liveStreamingAPI") {
    deleteComment(input: $input) {
      id
      success
      message
    }
  }
`;
