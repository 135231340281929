import { gql } from '@apollo/client';

export const GET_COLLECTION_CONDITIONS = gql`
  query GetCollectionConditions @api(name: "productsAPI") {
    getCollectionConditions {
      id
      name
      operations
    }
  }
`;
