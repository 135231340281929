import { FC } from 'react';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { PlusBlueIcon } from 'src/assets/icons';

const CustomAddCardButton: FC = () => {
  return (
    <button className="d-flex align-items-center justify-content-center btn btn-icon-text w-100">
      <KTSVG path={PlusBlueIcon} />
      <span>{'Add a new PO'}</span>
    </button>
  );
};

export default CustomAddCardButton;
