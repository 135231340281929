import { FC } from 'react';
import { Link } from 'react-router-dom';

// Components
import { FlexBox } from 'src/components/atoms';
import LabelNumberInput from './LabelNumberInput';

// Types
import { ProductVariantLabelProps } from './ProductLabel.types';

const ProductVariantLabel: FC<ProductVariantLabelProps> = ({
  productId,
  productVariant,
  labelPrintType,
  handleUpdateLabelCount
}) => {
  return (
    <FlexBox className="align-items-center justify-content-between variant-label-item">
      <div>
        <Link to={`/products/allProducts/edit/${productId}`} className="main-cell" target="_blank">
          ${productVariant.productVariantPrice} /{' '}
          {productVariant.variantTypes?.map((varType) => varType.variantTypeValue).join(' / ')}
        </Link>
        <p className="mt-1 mb-0">{productVariant?.productVariantQty} in stock</p>
      </div>
      <LabelNumberInput
        labelProductType="variant"
        labelPrintType={labelPrintType}
        data={productVariant}
        productId={productId}
        handleUpdateLabelCount={handleUpdateLabelCount}
      />
    </FlexBox>
  );
};

export default ProductVariantLabel;
