import { FC, useState, createRef } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { FlexBox } from 'src/components/atoms';
import { TagAndCollectionDropdown, Portal } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers/components/KTSVG';

// Icons
import { DownArrowIcon } from 'src/assets/icons';

// Types
import { TagColorsExtra } from 'src/components/oraganisms/OrdersList/tagColors';

interface ProductTagsProps {
  product: any;
}

const ProductTags: FC<ProductTagsProps> = ({ product }) => {
  const moreTagsRef = createRef<any>();
  const [showMoreTags, setShowMoreTags] = useState(false);
  const [dropdownPositions, setDropdownPositions] = useState({
    top: 0,
    left: 0
  });

  const handleOpenMoreTagsDropdown = () => {
    setShowMoreTags(true);

    if (moreTagsRef.current) {
      const position = moreTagsRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - position.bottom;
      setDropdownPositions({
        top: spaceBelow < 180 ? window.scrollY + position.top - 65 : window.scrollY + position.top + position.height + 5,
        left: position.left + 40
      });
    }
  };

  return (
    <div className="position-relative">
      <FlexBox className="text-muted d-block flex-wrap">
        <div className="display-flex flex-wrap">
          {product?.productTagsJson?.slice(0, 1).map((item, index) => {
            return (
              <div
                className={`text-nowrap tag-item m-r-12`}
                style={{
                  color: item?.textColorCode,
                  backgroundColor: item?.backgroundColorCode,
                  borderRadius: '8px'
                }}
                key={index}
              >
                {item?.name}
              </div>
            );
          })}
          {product?.productTagsJson && product.productTagsJson.length > 1 && (
            <div
              style={{ backgroundColor: TagColorsExtra.background, color: TagColorsExtra.color }}
              className="d-flex align-items-center rounded tag-item limited-tag"
              onClick={handleOpenMoreTagsDropdown}
              ref={moreTagsRef}
            >
              <div>+{product?.productTagsJson?.length - 1}</div>
              <KTSVG path={DownArrowIcon} />
            </div>
          )}
        </div>
      </FlexBox>

      {showMoreTags && (
        <Portal id="kt_body">
          <OutsideClickHandler onOutsideClick={() => setShowMoreTags(false)}>
            <div className="position-absolute" style={{ top: dropdownPositions.top, left: dropdownPositions.left }}>
              <TagAndCollectionDropdown
                type="Tag"
                onlyAppliedTags={true}
                appliedItems={product?.productTagsJson?.slice(1, product.productTagsJson.length) || []}
                selected={showMoreTags}
                isBulkEditorModal={true}
                isCreatable
                isEditAndDeletable={true}
                closeDropdown={() => setShowMoreTags(false)}
              />
            </div>
          </OutsideClickHandler>
        </Portal>
      )}
    </div>
  );
};

export default ProductTags;
