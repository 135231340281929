// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const toTree = (data) => {
  const childrenById = {}; // of the form id: [child-ids]
  const nodes = {}; // of the form id: {name: children: }
  let i;
  let row;
  const root: number[] = [];
  // first pass: build child arrays and initial node array
  for (i = 0; i < data.length; i++) {
    row = data[i];
    nodes[row.id] = { ...row, children: [] };
    if (!row.parent) {
      root.push(row.id);
    } else if (childrenById[row.parent] === undefined) {
      childrenById[row.parent] = [row.id];
    } else {
      childrenById[row.parent].push(row.id);
    }
  }
  // second pass: build tree, using the awesome power of recursion!
  function expand(id, level) {
    if (childrenById[id] !== undefined) {
      for (let i = 0; i < childrenById[id].length; i++) {
        const childId = childrenById[id][i];
        nodes[id].children.push(expand(childId, level + 1));
      }
    }
    nodes[id].level = level;
    return nodes[id];
  }

  return root.map((rootId) => expand(rootId, 0));
};

export default toTree;
