import { FC } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import clsx from 'clsx';

// Components
import { Loader } from 'src/components/atoms';
import { NotFound } from 'src/components/molecules';
import { VendorRowDetail } from 'src/components/oraganisms';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { DownArrowIcon, UpArrowIcon } from 'src/assets/icons';

// Types
import { TablesProps } from './Table.types';

// Styles
import './_table.scss';

const Table: FC<TablesProps> = ({
  headers,
  rows,
  headerClassName,
  className,
  onDragEnd,
  sortByCategory,
  sortByType,
  rapidActionOpen,
  isDragDisabled,
  bodyClass,
  refresh,
  type,
  isCatalog,
  onScroll,
  isLoading,
  openOrderShippingPreview,
  openShipmentDetails,
  sendDate,
  isOrder,
  selectedOptions,
  setSelectedOptions,
  setStatusColor,
  setStatusText,
  filterOrderMenu,
  handleOrderMenu,
  openOrderPreview,
  selectedIDs,
  loadingPosition,
  searchText,
  hideHeader,
  categoryInfo,
  tableContainerId,
  isFixedHeader,
  activeColumnsLength,
  noMarginBottom
}) => {
  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    // styles we need to apply on draggables
    display: isDragging && 'table',
    top: 'auto !important',
    left: 'auto !important',
    ...draggableStyle
  });

  const NotFoundRow = () => {
    return (
      <tr className="no-hover-row">
        <td colSpan={headers?.length} className="w-100 text-center">
          <NotFound
            type={type ? type : 'product'}
            isCatalog={isCatalog}
            isOrder={isOrder}
            categoryInfo={categoryInfo}
            searchText={searchText}
          />
        </td>
      </tr>
    );
  };

  const LoadingRow = () => {
    return (
      <tr className="no-hover-row">
        <td colSpan={headers?.length} className="w-100 text-center border-none align-middle">
          <Loader type="page" className={loadingPosition === 'center' ? 'h-400px' : ''} />
        </td>
      </tr>
    );
  };

  const TableContent = () => {
    return (
      <>
        {rows && rows?.length > 0
          ? rows?.map((row, index) => {
              return (
                <Draggable key={`${index}${row.id}`} draggableId={index.toString()} index={index} isDragDisabled={isDragDisabled}>
                  {(provided, snapshot) => (
                    <>
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={clsx(
                          !isDragDisabled
                            ? `h-fit-content table-row cursor-pointer ${snapshot.isDragging ? 'dragging-row' : ''}`
                            : row.className,
                          {
                            selected: selectedIDs && row.id && selectedIDs?.includes(row.id)
                          }
                        )}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        {row?.cells?.map((cell, i) => {
                          return (
                            <td className={`${cell.className}`} key={i}>
                              {cell.value}
                            </td>
                          );
                        })}
                      </tr>
                      {row.vendorDetails && row.showVendorDetails === row.id ? (
                        <VendorRowDetail details={row.vendorDetails.details} />
                      ) : null}
                    </>
                  )}
                </Draggable>
              );
            })
          : (searchText || isCatalog || isOrder) && <NotFoundRow />}
      </>
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div onScroll={onScroll} className={`table-responsive h-100  ${className}`} id={tableContainerId}>
        {/* begin::Table */}
        <table
          className={`table table-row-black-300 align-middle gs-0 gy-4 m-0 ${isFixedHeader ? 'fixed_header' : ''} ${
            noMarginBottom ? 'mb-0' : ''
          }`}
        >
          {!hideHeader && (
            <thead>
              <tr className={headerClassName}>
                {headers?.map((header, index) => {
                  return (
                    <th key={index} className={header.className}>
                      <div className="d-flex align-items-center">
                        <div key={index} className={header.textClass}>
                          {header.colName}
                        </div>
                        {header.sorting && (
                          <button
                            className="bg-transparent border border-transparent"
                            onClick={() => {
                              header.applySort();
                            }}
                          >
                            <KTSVG
                              path={sortByCategory === header.category && sortByType === 'ASC' ? UpArrowIcon : DownArrowIcon}
                              className="expand-arrow-icon"
                              svgClassName="cursor-pointer collapseFilter collapseIcon"
                            />
                          </button>
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
          )}
          <Droppable droppableId="table">
            {(provided, snapshot) => (
              <tbody id="table" {...provided.droppableProps} ref={provided.innerRef} className={bodyClass}>
                {loadingPosition === 'center' && (isLoading ? <LoadingRow /> : <TableContent />)}
                {loadingPosition === 'bottom' && (
                  <>
                    <TableContent />
                    {isLoading && <LoadingRow />}
                  </>
                )}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </table>
      </div>
    </DragDropContext>
  );
};

export default Table;
