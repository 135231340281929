import { TypedDocumentNode, gql } from '@apollo/client';

export const GET_ORDER_STATUS_COUNT: TypedDocumentNode<
  {
    getOrderStatusCounts: {
      all: number;
      pending: number;
      awaitingProcessing: number;
      awaitingFulfillment: number;
      awaitingShipment: number;
      shipped: number;
    };
  },
  { input: { combined?: boolean; userId?: string } }
> = gql`
  query getOrderStatusCounts($input: GetOrderStatusCounts) @api(name: "ordersAPI") {
    getOrderStatusCounts(input: $input) {
      all
      pending
      awaitingProcessing
      awaitingFulfillment
      awaitingShipment
      shipped
    }
  }
`;
