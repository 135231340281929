/* eslint-disable */
import { FC, useState, useReducer, useEffect } from 'react';
import { useHistory } from 'react-router';
import OutsideClickHandler from 'react-outside-click-handler';
import WorldData from 'country-state-city-plus';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { set } from 'date-fns';
import { ro } from 'date-fns/locale';

// Apollo client
import { useMutation, useLazyQuery } from '@apollo/client';
import { CHECK_BACKSTORE_USER, GET_USER_BASIC_INFO } from 'src/apollo/queries';
import { UPDATE_USER } from 'src/apollo/mutations';

// Redux
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { getUserInfoSuccess, updateUserInfoSuccess } from 'src/redux/reducer/userInfoReducer';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import { InputBox, Dropdown } from 'src/components/molecules';
import { CustomModal, EmailChangeModal, PasswordChangeModal, ForgotPasswordModal, ImageCropModal } from 'src/components/oraganisms';

// Hooks && Utils && Helpers
import { createFirebaseUser, sigupWithGoogle, sigupWithFacebook, sigupWithApple, refreshToken } from 'src/helpers/InitFirebase';
import { getAccountDetailsFromToken } from 'src/utils/authentication/decodeToken';
import { validateFullName, validateEmail, validatePassword, validateStoreName } from 'src/utils/validate';
import { setStoreIdToOSPlayer } from 'src/services/pushnotification/DataTags';
import { KTSVG } from 'src/helpers';
import { setImageSrc } from 'src/utils/setImageSrc';

// Icons
import { SignUpBySocial } from 'src/assets/images';
import { HideIcon, ShowIcon, GoogleIcon1, AppleIcon1, Facebook1, DownArrowIcon, DefaultImage } from 'src/assets/icons';

// ConstVariables
import { ROUTES, envUrls, constVariables } from 'src/constants';

// Types
import { PhoneItem } from '../settings/ShopDetails/ShopDetails.types';

// Styles
import './_account.scss';
import 'src/components/oraganisms/CustomModal/modalStyles.scss';

const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;

const Account: FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const userAvatarUrl = useAppSelector((state) => state.userInfo.userInfo.profileURL);
  const [showPassword, setShowPassword] = useState(false);
  const [phoneList, setPhoneList] = useState<PhoneItem[]>([]);
  const [showPhoneDropdown, setShowPhoneDropdown] = useState(false);
  const [showPhoneCodeSearchInput, setShowPhoneCodeSearchInput] = useState(false);
  const [searchPhonecodeText, setSearchPhonecodeText] = useState('');
  const [filteredPhonelist, setFilteredPhonelist] = useState<PhoneItem[]>([]);
  const [errorMessages, setErrorMessages] = useState({
    email: '',
    name: '',
    phoneNumber: '',
    password: ''
  });

  const [showChangeEmailModal, setShowChangeEmailModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [generalError, setGeneralError] = useState('');
  const [tempUserImage, setTempUserImage] = useState<string | ArrayBuffer>('');
  const [userProfileImage, setUserProfileImage] = useState({
    url: '',
    fileImage: ''
  });
  const [showCropImageModal, setShowCropImageModal] = useState(false);

  const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), {
    email: {
      value: '',
      error: ''
    },
    fullName: {
      value: '',
      error: ''
    },
    phoneNo: {
      value: '',
      error: ''
    },
    countryCode: {
      value: '',
      error: ''
    },
    password: {
      value: '',
      error: ''
    },
    nationalNumber: {
      value: '',
      error: ''
    },
    roleDisplayName: '',
    medium: '',
    genericError: '',
    profileUrl: userAvatarUrl ? userAvatarUrl : '',
    token: ''
  });

  const getCountryAndPhoneList = () => {
    const getAllCountries = WorldData.getAllCountries();
    if (getAllCountries) {
      const tempPhoneList = getAllCountries.map((data, index) => {
        return {
          id: index,
          name: `${data.name.toString()} (+${data.phonecode.toString()})`,
          extraData: `+${data.phonecode.toString()}`
        };
      });
      setPhoneList(tempPhoneList);
    }
  };

  const [updateUser] = useMutation(UPDATE_USER, {
    fetchPolicy: 'no-cache',
    onCompleted: (resetData) => {
      console.log('updateUser onCompleted - ', resetData);
    },
    onError: (e) => {
      console.error('resetpassword onError - ', e);
      console.error('e?.graphQLErrors[0] - ', e?.graphQLErrors[0]);
      const error: any = e?.graphQLErrors[0];
      if (error.errorType === 'USER_NOT_FOUND') {
      } else {
      }
    }
  });

  useEffect(() => {
    // checkUserData();
    getUserBasicInfo();
    getCountryAndPhoneList();
  }, []);

  useEffect(() => {
    if (searchPhonecodeText === '') {
      setFilteredPhonelist(phoneList);
    } else {
      const filteredPhoneCodes = phoneList.filter((phone) => phone.name.toLowerCase().includes(searchPhonecodeText.toLowerCase()));
      setFilteredPhonelist(filteredPhoneCodes);
    }
  }, [searchPhonecodeText, phoneList]);

  const [checkUserData, { data, error }] = useLazyQuery(CHECK_BACKSTORE_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      console.log('data - ', data);
      console.log('response - ', response);
    },
    onError: (e) => {
      localStorage.clear();
      const responseError: any = e?.graphQLErrors[0];
      console.error('checkUserData responseError - ', responseError);
    }
  });

  const [getUserBasicInfo, { error: userBasicInfoError }] = useLazyQuery(GET_USER_BASIC_INFO, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',

    variables: {
      input: {
        isBackStoreUser: true
      }
    },
    context: {
      headers: {
        authorization: localStorage.getItem('token') ? localStorage.getItem('token') : ''
      }
    },

    onCompleted: (res) => {
      const accountInfo = getAccountDetailsFromToken(state.token);
      localStorage.setItem('storeId', accountInfo.storeId);
      localStorage.setItem('userId', accountInfo.userId);
      localStorage.setItem('firebaseUserId', accountInfo.user_id);
      localStorage.setItem('firstName', res?.getUserBasicInfo?.userInfo?.firstName);
      localStorage.setItem('lastName', res?.getUserBasicInfo?.userInfo?.lastName);
      localStorage.setItem('picture', res?.getUserBasicInfo?.userInfo?.profileURL);
      localStorage.setItem('phoneNo', res?.getUserBasicInfo?.userInfo?.phoneNo);
      localStorage.setItem('email', accountInfo.email);

      dispatch(getUserInfoSuccess(res?.getUserBasicInfo?.userInfo));

      setState({
        email: {
          ...state.email,
          value: res.getUserBasicInfo.userInfo.email
        },

        fullName: {
          ...state.fullName,
          value: `${res.getUserBasicInfo.userInfo.firstName} ${res.getUserBasicInfo.userInfo.lastName}`
        },
        phoneNo: {
          ...state.phoneNo,
          value: res.getUserBasicInfo.userInfo.userPhoneNumber
        },
        profileUrl: res.getUserBasicInfo.userInfo.profileURL,
        roleDisplayName: res.getUserBasicInfo.userInfo.roleDisplayName,
        medium: res.getUserBasicInfo?.userInfo.medium
      });

      getPhoneCode(res.getUserBasicInfo.userInfo.userPhoneNumber);
    },
    onError: (err) => {
      console.error('err we get - userBasicInfoError', err);
      console.error('eerror we get - userBasicInfoError', userBasicInfoError);
      const errorMsg = userBasicInfoError?.message;

      setState({
        isLoading: false,
        genericError:
          errorMsg === 'User not verified'
            ? 'Your account has not been verified yet. Please check your email inbox for a verification link.'
            : 'Somthing went wrong. Please try again later.'
      });
    }
  });

  const validateInputValues = (fieldName: string, value: string) => {
    switch (fieldName) {
      case 'fullName': {
        const nameError = validateFullName(value);
        setState({
          fullName: {
            ...state.fullName,
            error: nameError
          }
        });

        if (nameError === false) {
          updateUser({
            variables: {
              input: {
                name: value
              }
            }
          });
        }

        break;
      }
      case 'email': {
        const emailError = validateEmail(value);
        setState({
          email: {
            ...state.email,
            error: emailError
          }
        });
        break;
      }

      case 'password': {
        const passwordError = validatePassword(value);
        setState({
          email: {
            ...state.email,
            error: passwordError
          }
        });
        break;
      }
      case 'phoneNumber': {
        const phoneNumberWithCountryCode = `${state.countryCode.value}${value}`;
        const phoneNumberError = isValidPhoneNumber(phoneNumberWithCountryCode) ? '' : 'Please enter a valid phone number';

        setState({
          phoneNo: {
            ...state.phoneNumber,
            error: phoneNumberError
          }
        });

        if (phoneNumberError === '') {
          updateUser({
            variables: {
              input: {
                phoneNo: phoneNumberWithCountryCode
              }
            }
          });
        }

        break;
      }
      default:
        break;
    }
  };

  const handleUpdateAccountInfo = (fieldName: string, value: string) => {
    setState({
      [fieldName]: {
        ...state[fieldName],
        value: value
      }
    });
  };

  const handleShowPhonecodeSearchDropdown = () => {
    setShowPhoneDropdown(true);
    setShowPhoneCodeSearchInput(true);
  };

  const handlePhoneCodeUpdate = (value) => {
    handleUpdateAccountInfo('countryCode', value.extraData);
    setShowPhoneDropdown(false);
    setShowPhoneCodeSearchInput(false);
    setSearchPhonecodeText('');
  };

  const handleForgotPasswordModal = () => {
    setShowForgotPasswordModal(true);
  };

  const handleProfileImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    handleSetFiles(files);
  };

  const handleSetFiles = async (files) => {
    if (files.length > 1) {
      setGeneralError && setGeneralError('You can only drop one image.');
      return;
    }

    for (let i = 0; i < files.length; i++) {
      if (!allowedExtensions.exec(files[i].name)) {
        setGeneralError &&
          setGeneralError(`${files[i].name} is not a supported file type. Upload files ending with: jpg, jpeg, png, svg.`);
        return;
      }
    }

    setGeneralError && setGeneralError('');

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      if (reader.result) {
        setTempUserImage(reader.result);
      }
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
    setShowCropImageModal(true);
  };

  const closeCropImageModal = () => {
    document.body.style.overflow = 'unset';
    setShowCropImageModal(false);
  };

  const handleDiscardCropImage = () => {
    closeCropImageModal();
  };

  const handleUpdateUserImage = (imageData) => {
    setUserProfileImage(imageData);

    updateUser({
      variables: {
        input: {
          profileUrl: imageData.url
        }
      },
      update(cache, {}) {
        cache.modify({
          id: 'LoggedinUserInfo',
          fields: {
            profileURL() {
              return imageData.fileImage ? imageData.fileImage : imageData.url;
            }
          }
        });
      }
    });

    dispatch(updateUserInfoSuccess(imageData.fileImage ? imageData.fileImage : imageData.url));

    setState({
      profileUrl: imageData.url
    });
  };

  const getPhoneCode = (phoneNumber) => {
    const { countryCallingCode, nationalNumber } = phoneNumber
      ? parsePhoneNumber(phoneNumber)
      : { countryCallingCode: null, nationalNumber: null };

    setState({
      countryCode: {
        ...state.countryCode,
        value: countryCallingCode
      },
      nationalNumber: {
        ...state.nationalNumber,
        value: nationalNumber
      }
    });
  };

  const closeChangeEmailModal = () => {
    document.body.style.overflow = 'unset';
    setShowChangeEmailModal(false);
  };

  const closeChangePasswordModal = () => {
    document.body.style.overflow = 'unset';
    setShowChangePasswordModal(false);
  };

  const closeForgotPasswordModal = () => {
    document.body.style.overflow = 'unset';
    setShowForgotPasswordModal(false);
  };

  return (
    <>
      <div className="account-settings-page p-40">
        <div className="account-settings-page-container">
          <h2 className="page-title m-y-40">{constVariables.accountSettings.pageTitle}</h2>
          <div className="card mb-0">
            <h4 className="section-title mb-0">{constVariables.accountSettings.accountDetails}</h4>
            <FlexBox className="profile-data m-y-40 align-items-center justify-content-center flex-column">
              <div className="m-b-4">
                <Img
                  src={setImageSrc(
                    userProfileImage.fileImage ? userProfileImage.fileImage : state.profileUrl ? state.profileUrl : DefaultImage
                  )}
                  placeholderImg={DefaultImage}
                  errorImg={DefaultImage}
                  width={80}
                  height={80}
                  className="rounded-circle border"
                />
              </div>
              <h3 className="poppins-medium">{state.fullName.value}</h3>
              <p className="m-b-8">{state.roleDisplayName}</p>
              <div className="file-upload">
                <label htmlFor="input-file" className="mb-0 cursor-pointer poppins-medium">
                  Upload Photo
                </label>
                <input id="input-file" type="file" onChange={handleProfileImageChange} />
              </div>
            </FlexBox>
            <div className="p-t-40 profile-info">
              <div className="m-b-24">
                <InputBox
                  title={constVariables.accountSettings.email}
                  value={state.email.value}
                  onChangeText={(value) => handleUpdateAccountInfo('email', value)}
                  name="email"
                  disable={true}
                  inputClass="form-control-lg"
                  warningText={state.email.error ? state.email.error : ''}
                  type="email"
                  onBlur={(event) => validateInputValues('email', event.target.value)}
                  placeholder="Email address"
                  readOnly
                  imageRight={
                    state.medium === 'EMAIL' ? (
                      <div
                        className="text-primary cursor-pointer text-btn position-absolute change-input-text"
                        onClick={() => setShowChangeEmailModal(true)}
                      >
                        {constVariables.accountSettings.changeEmail}
                      </div>
                    ) : (
                      <></>
                    )
                  }
                />
              </div>
              <div className="m-b-24">
                <InputBox
                  title={constVariables.accountSettings.name}
                  value={state.fullName.value}
                  onChangeText={(value) => handleUpdateAccountInfo('fullName', value)}
                  name="name"
                  inputClass="form-control-lg"
                  warningText={state.fullName?.error}
                  onBlur={(event) => validateInputValues('fullName', event.target.value)}
                  placeholder="Name"
                />
              </div>
              <div className="m-b-24">
                <div className="w-100 position-relative input-title-section">
                  <div className="input-title">{constVariables.shopDetails.general.supportPhone}</div>
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setShowPhoneDropdown(false);
                      setShowPhoneCodeSearchInput(false);
                    }}
                  >
                    <div className="d-flex">
                      <div>
                        {!showPhoneDropdown && !showPhoneCodeSearchInput && (
                          <FlexBox
                            className={`right-border-flat form-control form-control-lg  justify-content-between align-items-center cursor-pointer dropdown-box phone-code-input`}
                            onClick={handleShowPhonecodeSearchDropdown}
                          >
                            <div className="helper-text-one">{state?.countryCode?.value ? state.countryCode?.value : ''}</div>
                            <div>
                              <KTSVG path={DownArrowIcon} />
                            </div>
                          </FlexBox>
                        )}
                        {showPhoneCodeSearchInput && (
                          <div className="right-border-flat form-control form-control-lg p-0 justify-content-between align-items-center cursor-pointer dropdown-box phone-code-input active">
                            <input
                              autoComplete="off"
                              autoFocus
                              className="border-0 w-100 h-100 px-5"
                              type="text"
                              pattern="[0-9]+"
                              name="phonecode"
                              value={searchPhonecodeText}
                              onChange={(e) => {
                                e.target.validity.valid && setSearchPhonecodeText(e.target.value);
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="form-control form-control-lg p-0 left-border-flat">
                        <input
                          autoComplete="off"
                          className="border-0 w-100 h-100 px-5"
                          type="text"
                          pattern="[0-9]+"
                          name="customerSupportPhone"
                          value={state.nationalNumber.value}
                          onChange={(e) => {
                            e.target.validity.valid && handleUpdateAccountInfo('phoneNumber', e.target.value);
                          }}
                          onBlur={(event) => validateInputValues('phoneNumber', event.target.value)}
                          maxLength={15}
                        />
                      </div>
                    </div>
                    {phoneList && phoneList.length > 0 && (
                      <Dropdown
                        className="dropdown-custom-width"
                        data={filteredPhonelist}
                        selected={showPhoneDropdown}
                        value={state?.countryCode?.value ? state?.countryCode?.value : ''}
                        onSelect={(value) => {
                          handlePhoneCodeUpdate(value);
                        }}
                        closeDropdown={() => setShowPhoneDropdown(false)}
                      />
                    )}
                  </OutsideClickHandler>
                  {state.phoneNo.error && <div className="warning-text p-0 fs-7">{state.phoneNo.error}</div>}
                </div>
              </div>
              <div>
                <InputBox
                  title={constVariables.accountSettings.password}
                  value={state.password.value}
                  onChangeText={(value) => handleUpdateAccountInfo('password', value)}
                  name="password"
                  disable={true}
                  inputClass="form-control-lg"
                  warningText={errorMessages?.name}
                  onBlur={(event) => validateInputValues('password', event.target.value)}
                  placeholder="Password"
                  type={showPassword ? 'text' : 'password'}
                  isPasswordInput
                  toggleShowPassword={() => setShowPassword(!showPassword)}
                  readOnly
                  imageRight={
                    <div
                      className="text-primary cursor-pointer text-btn position-absolute change-input-text"
                      onClick={() => setShowChangePasswordModal(true)}
                    >
                      {constVariables.accountSettings.changePassword}
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showCropImageModal && (
        <CustomModal bodyClassname="w-90 w-md-150" show={showCropImageModal} closeModal={closeCropImageModal}>
          <ImageCropModal
            closeModal={closeCropImageModal}
            image={tempUserImage}
            updateImage={handleUpdateUserImage}
            setGeneralError={setGeneralError}
            discardCropImage={handleDiscardCropImage}
          />
        </CustomModal>
      )}
      {showChangeEmailModal && (
        <CustomModal bodyClassname="w-90 w-md-50" show={showChangeEmailModal} closeModal={closeChangeEmailModal}>
          <EmailChangeModal closeModal={closeChangeEmailModal} showForgotPasswordModal={() => handleForgotPasswordModal()} />
        </CustomModal>
      )}
      {showChangePasswordModal && (
        <CustomModal bodyClassname="w-90 w-md-50" show={showChangePasswordModal} closeModal={closeChangePasswordModal}>
          <PasswordChangeModal
            closeModal={closeChangePasswordModal}
            showForgotPasswordModal={() => handleForgotPasswordModal()}
            medium={state.medium}
          />
        </CustomModal>
      )}
      {showForgotPasswordModal && (
        <CustomModal bodyClassname="w-90 w-md-50" show={showForgotPasswordModal} closeModal={closeForgotPasswordModal}>
          <ForgotPasswordModal closeModal={closeForgotPasswordModal} />
        </CustomModal>
      )}
    </>
  );
};

export default Account;
