/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';

// Apollo
import { useMutation } from '@apollo/client';
import { SIGN_UP_BACK_STORE_INVITEE } from 'src/apollo/mutations';

// Components
import { Loader } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { createFirebaseUser, refreshToken } from 'src/helpers/InitFirebase';
import { checkPassword } from 'src/utils/validate';
import { getAccountDetailsFromToken, isTokenExpired } from 'src/utils/authentication/decodeToken';
import { setStoreIdToOSPlayer } from 'src/services/pushnotification/DataTags';

// Icons
import { HideIcon, ShowIcon, HorizontalLogoIcon } from 'src/assets/icons';

// ConstVariables
import { envUrls, ROUTES } from 'src/constants';

// Types
import { UserRoles } from '../../settings/UserPermissions/UserPermissions.type';

// Styles
import './_inviteSignup.scss';

const InviteSignup: FC = () => {
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const accountInfo = getAccountDetailsFromToken(token);
  const [tenantId, setTenantId] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    password: '',
    confirmPassword: ''
  });

  const [errors, setErrors] = useState({
    name: '',
    password: '',
    confirmPassword: ''
  });

  const [generalError, setGeneralError] = useState<string>('');

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isInviteLoading, setIsInviteLoading] = useState(false);

  useEffect(() => {
    if (accountInfo) {
      setTenantId(accountInfo?.tenantId);
    }
  }, [accountInfo]);

  const [signupBackstoreInvitee] = useMutation(SIGN_UP_BACK_STORE_INVITEE, {
    onCompleted: async (data) => {
      const idToken = await refreshToken();
      localStorage.setItem('token', idToken);
      const accountInfo = getAccountDetailsFromToken(idToken);
      localStorage.setItem('storeId', accountInfo.storeId);
      localStorage.setItem('userId', accountInfo.userId);
      localStorage.setItem('firebaseUserId', accountInfo.user_id);
      localStorage.setItem('name', accountInfo.name);
      localStorage.setItem('picture', accountInfo.picture);
      localStorage.setItem('email', accountInfo.email);
      localStorage.setItem('userRole', accountInfo.role);
      setStoreIdToOSPlayer(accountInfo.storeId);
      setIsInviteLoading(false);

      const userRole = accountInfo.role;
      if (userRole === UserRoles.OWNER || userRole === UserRoles.ADMIN) {
        history.replace(ROUTES.home.main);
      } else if (userRole === UserRoles.ADMIN_ASSOCIATE) {
        history.replace(ROUTES.products.allProducts.main);
      } else if (userRole === UserRoles.WAREHOUSE_MANAGER) {
        history.replace(ROUTES.products.inventory.main);
      } else if (userRole === UserRoles.FULFILMENT_ASSOCIATE) {
        history.replace(ROUTES.orders.allOrders.fulfillmentCenter);
      } else if (userRole === UserRoles.WAREHOUSE_ASSOCIATE) {
        history.replace(ROUTES.products.inventory.main);
      }
    },
    onError: (e) => {
      console.error('signupBackstoreInvitee onError - ', e);
      console.error('e?.graphQLErrors[0] - ', e?.graphQLErrors[0]);
      setIsInviteLoading(false);
      localStorage.clear();
      const error: any = e?.graphQLErrors[0];
      if (error?.errorType === 'STORE_ALREADY_EXIST') {
        setGeneralError(error?.message);
      } else {
        setGeneralError('Something went wrong. Please try again later.');
      }
    }
  });

  const handleChange = (fieldName: string, value: string) => {
    setFormData((formData) => {
      return { ...formData, [fieldName]: value };
    });
  };

  const resetErrors = (fieldName: string) => {
    setErrors((errors) => ({
      ...errors,
      [fieldName]: ''
    }));
    return false;
  };

  const validateInputValues = (fieldName: string, value: string) => {
    if (!value) {
      setErrors((errors) => ({
        ...errors,
        [fieldName]: 'Required Field'
      }));
      return true;
    }
    if (fieldName === 'password' || fieldName === 'confirmPassword') {
      if (!checkPassword(value)) {
        setErrors((errors) => ({
          ...errors,
          [fieldName]: 'Please enter at least 8 letter password, with a symbol, upper and lower case letters and a number'
        }));
        return true;
      }
      if (fieldName === 'password') {
        if (value !== formData.confirmPassword && formData.confirmPassword) {
          setErrors((errors) => ({
            ...errors,
            password: `Password doesn't match`,
            confirmPassword: `Password doesn't match`
          }));
        } else {
          resetErrors(fieldName);
        }
      }
      if (fieldName === 'confirmPassword') {
        if (formData.password !== value) {
          setErrors((errors) => ({
            ...errors,
            password: `Password doesn't match`,
            confirmPassword: `Password doesn't match`
          }));
        } else {
          resetErrors(fieldName);
        }
      } else {
        resetErrors(fieldName);
      }
    } else {
      resetErrors(fieldName);
    }
  };

  const handleJoinStore = async () => {
    const formDataKeys = Object.keys(formData);
    for (let i = 0; i < formDataKeys.length; i++) {
      validateInputValues(formDataKeys[i], formData[formDataKeys[i]]);
    }

    const isError =
      formDataKeys.map((key) => validateInputValues(key, formData[key])).filter((v) => !v).length === 3 ? false : true;

    if (isError) return;

    setIsInviteLoading(true);

    const {
      success,
      userCredential = {},
      error = ''
    }: { success: boolean; userCredential?: any; error?: any } = await createFirebaseUser(accountInfo.email, formData.password);

    if (success) {
      const user = userCredential.user;
      const idToken = await user.getIdToken(true).then();
      localStorage.setItem('token', idToken);
      localStorage.setItem('storeId', tenantId);
      signupBackstoreInvitee({
        context: {
          headers: {
            Authorization: idToken,
            tenantid: tenantId,
            'x-api-key': envUrls.authXApiKey
          }
        },
        variables: {
          input: {
            email: accountInfo.email,
            fullName: formData.name,
            role: accountInfo.role
          }
        }
      });
    } else if (!success && error) {
      console.log('error::', error);
      setIsInviteLoading(false);
      setGeneralError(error.message);
    }
  };

  return (
    <div className="invite-signup-page d-flex align-items-center justify-content-center">
      <img src={HorizontalLogoIcon} alt={HorizontalLogoIcon} />
      <div className="card">
        <h3 className="text-center">Join {accountInfo.storeName}</h3>
        <div className="d-flex inner-gap flex-column">
          <div className="input-gap">
            <div className="input-title-section">
              <span className="input-title">Name</span>
            </div>
            <input
              autoComplete="off"
              className={`form-control form-control-solid hp-50px ${errors.name ? 'red-border' : 'grey-border'}`}
              id="fullName"
              name="fullName"
              value={formData.name}
              onChange={(e) => handleChange('name', e.target.value)}
              type="text"
              placeholder="Enter name"
              onBlur={(e) => validateInputValues('name', e.target.value)}
            />
            <div className={`text-danger fs-8`}>{errors.name}</div>
          </div>
          <div className="input-gap">
            <div className="input-title-section">
              <span className="input-title">Password</span>
            </div>
            <div className="password-input">
              <input
                autoComplete="off"
                className={`form-control form-control-solid hp-50px ${errors.password ? 'red-border' : 'grey-border'}`}
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={formData.password}
                onChange={(e) => handleChange('password', e.target.value)}
                placeholder="Set Password"
                onBlur={(e) => validateInputValues('password', e.target.value)}
              />
              <div className="password-input-addon h-100 d-flex align-items-center justify-content-center">
                <img
                  src={!showPassword ? HideIcon : ShowIcon}
                  className="cursor-pointer"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                />
              </div>
            </div>
            <div className={`text-danger fs-8`}>{errors.password}</div>
          </div>
          <div className="input-gap">
            <div className="input-title-section">
              <span className="input-title">Confirm Password</span>
            </div>
            <div className="password-input">
              <input
                autoComplete="off"
                className={`form-control form-control-solid hp-50px w-100 ${errors.confirmPassword ? 'red-border' : 'grey-border'}`}
                id="confirmPassword"
                name="confirmPassword"
                type={showConfirmPassword ? 'text' : 'password'}
                value={formData.confirmPassword}
                onChange={(e) => handleChange('confirmPassword', e.target.value)}
                placeholder="Confirm Password"
                onBlur={(e) => validateInputValues('confirmPassword', e.target.value)}
              />
              <div className="password-input-addon h-100 d-flex align-items-center justify-content-center">
                <img
                  src={!showConfirmPassword ? HideIcon : ShowIcon}
                  className="cursor-pointer"
                  onClick={() => {
                    setShowConfirmPassword(!showConfirmPassword);
                  }}
                />
              </div>
            </div>
            <div className={`text-danger fs-8`}>{errors.confirmPassword}</div>
          </div>
          <div className={`text-danger fs-8`}>{generalError}</div>
          <div className="mt-3">
            <button className="btn btn-md btn-primary w-100" disabled={isInviteLoading} onClick={handleJoinStore}>
              {isInviteLoading ? 'Joining' : 'Join'} Store
              {isInviteLoading && <Loader type="button" className="h-15px w-15px" />}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteSignup;
