/* eslint-disable no-nested-ternary */
import { FC, useEffect, useMemo, useState, createRef, useRef } from 'react';
import { Column, Table, AutoSizer, defaultTableRowRenderer, WindowScroller } from 'react-virtualized';

// Components
import { Loader } from 'src/components/atoms';
import NotFound from '../NotFound/NotFound';
import { ProductDetailsRow, InventoryDetailsRow, CollectionDetailsRow, WaitListDetailsRow, OrderDetailsRow } from './Details';

// Types
import { LocationItem } from 'src/components/pages/inventory/InventoryManagementPage.type';
import { OrdersListData } from 'src/components/oraganisms/OrdersList/OrdersList.types';

// Styles
import 'react-virtualized/styles.css';
import './_dataTable.scss';

interface DataTableProps {
  columnOptions: any[];
  rowData: any[];
  tableOption?: any;
  headerHeight?: number;
  width?: number;
  rowHeight?: number;
  isLoading: boolean;
  searchText: string;
  page: string;
  selectedIndex: number;
  setSelectedIndex?: any;
  selectedWarehouseId?: number;
  updateInventoryList?: (updatedInventory, fieldName, productId?) => void;
  selectedIDs: number[];
  isDetailsLoading?: boolean;
  wearhouseLocations?: LocationItem[];
  handleUpdateTreeData?: (updatedCollection) => void;
  openOrderPreview?: (id: number, isCombOrdrParent: boolean, order?: OrdersListData) => void;
  openOrderShippingPreview?: () => void;
  openShipmentDetails?: (id: number) => void;
  sendDate?: (date: string, index: number) => void;
  handleInventoryActionMenus?: (level: string, productId: number, printType: string, variantId?: number) => void;
  handleCollectionActionMenus?: (optionId: number, collectionName: string, collectionId: string | number) => void;
}

const DataTable: FC<DataTableProps> = ({
  columnOptions,
  rowData,
  headerHeight = 70,
  rowHeight = 70,
  tableOption = null,
  isLoading,
  searchText,
  page,
  selectedIndex,
  setSelectedIndex,
  selectedWarehouseId,
  updateInventoryList,
  selectedIDs,
  isDetailsLoading,
  wearhouseLocations,
  handleUpdateTreeData,
  openOrderPreview,
  openOrderShippingPreview,
  openShipmentDetails,
  sendDate,
  handleInventoryActionMenus,
  handleCollectionActionMenus
}) => {
  const tableWrapperRef = createRef<any>();
  const tableRef = createRef<any>();
  const stickyHeaderRef = useRef<any>();
  const dataTableBodyRef = useRef<any>();
  const stickyScrollBarRef = useRef<any>();
  const [defaultTableWidth, setDefaultTableWidth] = useState(1000);
  const isDragging = useRef<boolean>(false);
  const [startPosition, setStartPosition] = useState<number>(0);
  const [scrollbarPosition, setScrollbarPosition] = useState<number>(0);
  const [scrollWidth, setScrollWidth] = useState<number>(200);
  const [showStickyScrollBar, setShowStickyScrollBar] = useState(true);
  const [isStickyHeader, setIsStickyHeader] = useState(false);

  useEffect(() => {
    if (tableWrapperRef?.current) {
      const { width } = tableWrapperRef.current.getBoundingClientRect();
      setDefaultTableWidth(width - 27);
    }
  }, []);

  useEffect(() => {
    tableRef?.current?.recomputeRowHeights();
  }, [selectedIndex, rowData, isDetailsLoading]);

  const tableWidth = useMemo(() => {
    return columnOptions
      .map((column) => column.width)
      .reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0);
  }, [columnOptions]);

  useEffect(() => {
    if (!isLoading) {
      const stickyHeader = document.getElementById('stickyHeader');
      if (stickyHeader) {
        const containerWidth = stickyHeader.clientWidth;
        if (tableWidth > containerWidth) {
          const width = (containerWidth * containerWidth) / tableWidth;
          const tableStickyScrollBar = document.getElementById('tableStickyScrollBar');
          if (tableStickyScrollBar) tableStickyScrollBar.style.width = `${width}px`;
          setScrollWidth(width);
        } else {
          setShowStickyScrollBar(false);
        }
      }
    }
  }, [tableWidth, isLoading]);

  const isHiddenTable = useMemo(() => {
    if (rowData?.length === 0 && !searchText && !isLoading) return false;
    else return true;
  }, [rowData?.length, searchText, isLoading]);

  const noRowsRenderer = () => {
    return (
      <div className="d-flex align-items-center justify-content-center" style={{ width: defaultTableWidth }}>
        <NotFound type={page ? page : 'product'} searchText={searchText} />
      </div>
    );
  };

  const renderDetails = (rowData) => {
    switch (page) {
      case 'product':
        return (
          <ProductDetailsRow
            productDetails={rowData}
            isLoading={isDetailsLoading}
            rowWidth={tableWidth > defaultTableWidth ? tableWidth : defaultTableWidth}
          />
        );
      case 'inventory':
        return (
          <InventoryDetailsRow
            inventory={rowData}
            columnOptions={columnOptions}
            isLoading={isDetailsLoading}
            selectedWarehouseId={selectedWarehouseId}
            updateInventoryList={updateInventoryList}
            wearhouseLocations={wearhouseLocations}
            rowWidth={tableWidth > defaultTableWidth ? tableWidth : defaultTableWidth}
            handleInventoryActionMenus={handleInventoryActionMenus}
          />
        );
      case 'waitlist':
        return (
          <WaitListDetailsRow
            waitListItem={rowData}
            columnOptions={columnOptions}
            isLoading={isDetailsLoading}
            rowWidth={tableWidth > defaultTableWidth ? tableWidth : defaultTableWidth}
          />
        );
      case 'collection':
        return (
          <CollectionDetailsRow
            collection={rowData}
            columnOptions={columnOptions}
            rowWidth={tableWidth > defaultTableWidth ? tableWidth : defaultTableWidth}
            handleUpdateTreeData={handleUpdateTreeData}
            handleCollectionActionMenus={handleCollectionActionMenus}
          />
        );
      case 'order':
        return (
          <OrderDetailsRow
            order={rowData}
            columnOptions={columnOptions}
            isLoading={isDetailsLoading}
            rowWidth={tableWidth > defaultTableWidth ? tableWidth : defaultTableWidth}
            openOrderPreview={openOrderPreview}
            openOrderShippingPreview={openOrderShippingPreview}
            openShipmentDetails={openShipmentDetails}
            sendDate={sendDate}
          />
        );
      default:
        return null;
    }
  };

  const totalCollectionNumber = (data) => {
    let count = 0;
    if (data?.children?.length) {
      for (const d of data.children) {
        count += totalCollectionNumber(d);
      }
    }

    return (count += data.children.length);
  };

  const orderRowHeight = (data) => {
    if (data?.isCombOrdrParent && data?.childOrders) {
      return (data?.childOrders.length + 1) * 70;
    }
    if (data?.orderStatus === 'completed' || data?.orderStatus === 'shipped') {
      return data?.orderShipments?.length ? (data?.orderShipments?.length + 1) * 70 : 70;
    }
    return 70;
  };

  const getUpdatedRowHeight = useMemo(() => {
    if (selectedIndex === -1) return 70;
    switch (page) {
      case 'product':
        return 300;
      case 'inventory':
        return isDetailsLoading
          ? 140
          : rowData[selectedIndex]?.variantLocationDetails?.length > 0
          ? (rowData[selectedIndex]?.variantLocationDetails?.length + 1) * 70
          : 70;
      case 'waitlist':
        return isDetailsLoading
          ? 140
          : rowData[selectedIndex]?.variantLocationDetails?.length > 0
          ? (rowData[selectedIndex]?.variantLocationDetails?.length + 1) * 70
          : 70;
      case 'collection':
        return rowData[selectedIndex]?.children?.length > 0 ? (totalCollectionNumber(rowData[selectedIndex]) + 1) * 70 : 70;
      case 'order':
        return isDetailsLoading ? 140 : orderRowHeight(rowData[selectedIndex]);
      default:
        return 70;
    }
  }, [rowData, selectedIndex, isDetailsLoading]);

  const rowRenderer = (props) => {
    const { index, style, className, key, rowData, isScrolling } = props;
    if (index === selectedIndex) {
      return (
        <div style={{ ...style, display: 'flex', flexDirection: 'column', alignItems: 'start' }} key={key}>
          {defaultTableRowRenderer({
            ...props,
            style: { width: style.width, height: 70 }
          })}
          {renderDetails(rowData)}
        </div>
      );
    }
    return defaultTableRowRenderer(props);
  };

  const getRowHeight = ({ index }) => (index === selectedIndex ? getUpdatedRowHeight : 70);

  const fieldData = (data) => {
    if (!data) return;
    switch (page) {
      case 'product':
        return data?.productId;
      case 'inventory':
        return data?.productId;
      default:
        return data?.id;
    }
  };

  const handleWindowScroll = (params) => {
    const { scrollTop } = params;
    if (dataTableBodyRef?.current) {
      const { top } = dataTableBodyRef.current.getBoundingClientRect();
      if (top < 0) {
        setIsStickyHeader(true);
      } else {
        setIsStickyHeader(false);
      }
    }
    const yPosition = window.scrollY;
    const tablePage = document.getElementById('tablePage');
    const windowHeight = window.innerHeight;
    if (tablePage) {
      const pageHeight = tablePage?.offsetHeight;
      if (pageHeight - yPosition > windowHeight + 102) {
        setShowStickyScrollBar(true);
      } else {
        setShowStickyScrollBar(false);
      }
    }
  };

  const handleTableBodyScroll = (e) => {
    const stickyHeader = document.getElementById('stickyHeader');
    if (stickyHeader) {
      stickyHeader.scrollTo({
        left: e.target.scrollLeft
      });
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    setStartPosition(e.clientX);
    const tableStickyScrollBar = document.getElementById('tableStickyScrollBar');
    setScrollbarPosition(tableStickyScrollBar?.offsetLeft || 0);
    isDragging.current = true;
  };

  document.addEventListener('mousemove', (e) => {
    if (isDragging.current) {
      e.preventDefault();
      const position = stickyScrollBarRef?.current?.getBoundingClientRect();
      const { left, width } = position || {};
      if (left <= startPosition) {
        const tableStickyScrollBar = document.getElementById('tableStickyScrollBar');
        let left = scrollbarPosition + e.clientX - startPosition;
        if (left < 0) left = 0;
        if (left > width - scrollWidth) left = width - scrollWidth;

        if (tableStickyScrollBar) tableStickyScrollBar.style.left = `${left}px`;

        const min = 0;
        const max = width - scrollWidth;
        const percent = left / (max - min);

        const dataTable = document.getElementById('dataTable');

        if (dataTable) {
          dataTable.scrollTo({
            left: tableWidth * percent
          });
        }
      }
    }
  });

  document.addEventListener('mouseup', (event) => {
    event.preventDefault();
    isDragging.current = false;
  });

  return (
    <div ref={tableWrapperRef} className="tableWrapperRef">
      <div>
        {isLoading ? (
          <Loader type="page" className="justify-content-center h-600px" />
        ) : (
          <div>
            <WindowScroller onScroll={handleWindowScroll}>
              {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
                <>
                  <div
                    className={`data-table-header ${isStickyHeader ? 'sticky' : 'border-none'} ${
                      !isHiddenTable ? 'display-none' : ''
                    }`}
                    style={{ overflow: 'hidden' }}
                    id="stickyHeader"
                    ref={stickyHeaderRef}
                  >
                    <div
                      className="d-flex align-items-center h-100"
                      style={{ width: tableWidth > defaultTableWidth ? tableWidth : defaultTableWidth }}
                    >
                      {columnOptions.map((options, i) => (
                        <div
                          key={i}
                          style={{
                            overflow: 'hidden',
                            flex: `0 1 ${options.width}px`,
                            minWidth: `${options.width ? options.width : 150}px`
                          }}
                          className={`d-felx align-items-center ReactVirtualized__Table__headerColumn ${
                            options.staticColumn ? 'fixed-width-column' : ''
                          }`}
                        >
                          {options?.headerRenderer ? <>{options?.headerRenderer()}</> : options?.name}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div style={{ overflow: 'auto hidden' }} onScroll={handleTableBodyScroll} id="dataTable" ref={dataTableBodyRef}>
                    <AutoSizer disableHeight={isHiddenTable}>
                      {({ width }) => (
                        <Table
                          id="dataTableContent"
                          autoHeight
                          height={height}
                          headerHeight={0}
                          rowHeight={getRowHeight}
                          width={tableWidth > defaultTableWidth ? tableWidth : defaultTableWidth}
                          rowGetter={({ index }) => {
                            if (isScrolling) {
                              if (page === 'product') {
                                return {
                                  productName: rowData[index].productName,
                                  productId: rowData[index].productId,
                                  variantLocationDetails: rowData[index].variantLocationDetails,
                                  productBarcode: rowData[index].productBarcode,
                                  productSku: rowData[index].productSku,
                                  productMediasJson: rowData[index].productMediasJson,
                                  productQty: rowData[index].productQty,
                                  salePrice: rowData[index].salePrice,
                                  vendorName: rowData[index].vendorName,
                                  productTypeName: rowData[index].productTypeName,
                                  productStatus: rowData[index].productStatus,
                                  externalProductProvider: rowData[index].externalProductProvider,
                                  productTagsJson: rowData[index].productTagsJson,
                                  margin: rowData[index].margin,
                                  profit: rowData[index].profit,
                                  perItemCost: rowData[index].perItemCost,
                                  shippingPrice: rowData[index].shippingPrice,
                                  freeShipping: rowData[index].freeShipping
                                };
                              }
                              if (page === 'inventory') {
                                return {
                                  productName: rowData[index].productName,
                                  productId: rowData[index].productId,
                                  variantLocationDetails: rowData[index].variantLocationDetails,
                                  productSku: rowData[index].productSku,
                                  productMediasJson: rowData[index].productMediasJson,
                                  productQty: rowData[index].productQty,
                                  usersQuantity: rowData[index].usersQuantity,
                                  committedQuantity: rowData[index].committedQuantity,
                                  productHasVariants: rowData[index].productHasVariants,
                                  externalProductProvider: rowData[index].externalProductProvider,
                                  productLocation: rowData[index].productLocation,
                                  alertThreshold: rowData[index].alertThreshold
                                };
                              }
                              if (page === 'order') {
                                return {
                                  isScrolling: true,
                                  id: rowData[index].id,
                                  orderCode: rowData[index].orderCode,
                                  orderStatus: rowData[index].orderStatus,
                                  isOrderAddressVerified: rowData[index].isOrderAddressVerified,
                                  isCombOrdrParent: rowData[index].isCombOrdrParent,
                                  createdAt: rowData[index].createdAt,
                                  shopifyId: rowData[index].shopifyId,
                                  isPartiallyShipped: rowData[index].isPartiallyShipped,
                                  thumbnailUrl: rowData[index].thumbnailUrl,
                                  customer: rowData[index].customer,
                                  isFirstTimeCustomer: rowData[index].isFirstTimeCustomer,
                                  isExpeditedShipping: rowData[index].isExpeditedShipping,
                                  isVIPCustomer: rowData[index].isVIPCustomer,
                                  total: rowData[index].total,
                                  availableItemsQuantity: rowData[index].availableItemsQuantity,
                                  items: rowData[index].items,
                                  tags: rowData[index].tags,
                                  childOrders: rowData[index].childOrders,
                                  orderShipments: rowData[index]?.orderShipments ? rowData[index]?.orderShipments : []
                                };
                              }
                              if (page === 'customer') {
                                return {
                                  isScrolling: true,
                                  id: rowData[index].id,
                                  profilePicture: rowData[index].profilePicture,
                                  shopifyId: rowData[index].shopifyId,
                                  name: rowData[index].name,
                                  email: rowData[index].email,
                                  isFirstTimeCustomer: rowData[index].isFirstTimeCustomer,
                                  isVIPCustomer: rowData[index].isVIPCustomer,
                                  isBlockedForLive: rowData[index].isBlockedForLive,
                                  phoneNumber: rowData[index].phoneNumber,
                                  numberOfOrders: rowData[index].numberOfOrders,
                                  totalAmountSpent: rowData[index].totalAmountSpent,
                                  joinedOn: rowData[index].joinedOn,
                                  locations: rowData[index].locations,
                                  userTags: rowData[index].userTags
                                };
                              }
                              if (page === 'coupon') {
                                return {
                                  isScrolling: true,
                                  id: rowData[index].id,
                                  name: rowData[index].name,
                                  externalDiscountProvider: rowData[index].externalDiscountProvider,
                                  status: rowData[index].status,
                                  couponValue: rowData[index].couponValue,
                                  couponType: rowData[index].couponType,
                                  timesUsed: rowData[index].timesUsed
                                };
                              }
                              if (page === 'catalog') {
                                return {
                                  isScrolling: true,
                                  id: rowData[index].id,
                                  thumbnailUrl: rowData[index].thumbnailUrl,
                                  name: rowData[index].name,
                                  productIds: rowData[index].productIds,
                                  type: rowData[index].type,
                                  status: rowData[index].status,
                                  timesUsed: rowData[index].timesUsed
                                };
                              }
                              if (page === 'waitlist') {
                                return {
                                  isScrolling: true,
                                  mediaUrl: rowData[index].mediaUrl,
                                  name: rowData[index].name,
                                  committedQuantity: rowData[index].committedQuantity,
                                  waitlistQuantity: rowData[index].waitlistQuantity,
                                  waitlistAuthQuantity: rowData[index].waitlistAuthQuantity,
                                  price: rowData[index].price,
                                  variantLocationDetails: rowData[index].variantLocationDetails
                                };
                              }
                              if (page === 'collection') {
                                return {
                                  isScrolling: true,
                                  level: rowData[index].level,
                                  children: rowData[index].children,
                                  thumbnailUrl: rowData[index].thumbnailUrl,
                                  externalCollectionProvider: rowData[index].externalCollectionProvider,
                                  text: rowData[index].text,
                                  productCount: rowData[index].productCount,
                                  type: rowData[index].type,
                                  status: rowData[index].status
                                };
                              }
                              if (page === 'warehouse') {
                                return {
                                  isScrolling: true,
                                  name: rowData[index].name,
                                  addressLine1: rowData[index].addressLine1,
                                  city: rowData[index].city,
                                  state: rowData[index].state,
                                  pincode: rowData[index].pincode,
                                  isDefault: rowData[index].isDefault,
                                  isActive: rowData[index].isActive,
                                  status: rowData[index].status
                                };
                              }
                              if (page === 'tiktok') {
                                return {
                                  isScrolling: true,
                                  id: rowData[index].id,
                                  name: rowData[index].name,
                                  imageUrl: rowData[index].imageUrl,
                                  inventory: rowData[index].inventory,
                                  category: rowData[index].category,
                                  categoryId: rowData[index].categoryId,
                                  status: rowData[index].status,
                                  error: rowData[index].error
                                };
                              }
                            } else {
                              return rowData ? { ...rowData[index], isScrolling: false } : { productName: 'Loading...' };
                            }
                          }}
                          rowCount={rowData && rowData.length > 0 ? rowData.length : 0}
                          scrollTop={scrollTop}
                          noRowsRenderer={noRowsRenderer}
                          rowRenderer={rowRenderer}
                          rowClassName={({ index }) =>
                            `${selectedIDs.includes(fieldData(rowData ? rowData[index] : null)) ? 'selected' : ''} ${
                              page === 'order' && !rowData[index]?.isOrderAddressVerified ? 'error-list-background' : ''
                            }`
                          }
                          overscanRowCount={6}
                          ref={tableRef}
                          overscanIndicesGetter={(props) => {
                            const { overscanCellsCount, startIndex, stopIndex, cellCount } = props;
                            return {
                              overscanStartIndex: startIndex <= props.overscanCellsCount ? 0 : startIndex - overscanCellsCount,
                              overscanStopIndex:
                                stopIndex + overscanCellsCount < cellCount ? stopIndex + overscanCellsCount : props.stopIndex
                            };
                          }}
                          {...tableOption}
                        >
                          {columnOptions.map((options, i) => (
                            <Column
                              key={i}
                              width={options?.width}
                              minWidth={options?.width ? options?.width : 120}
                              style={options?.style || null}
                              headerRenderer={options.headerRenderer}
                              headerClassName={`d-felx align-items-center ${options.staticColumn ? 'fixed-width-column' : ''}`}
                              className={options.staticColumn ? 'fixed-width-column' : ''}
                              {...options}
                            />
                          ))}
                        </Table>
                      )}
                    </AutoSizer>
                  </div>
                  <div
                    className={`table-sticky-scroll ${!showStickyScrollBar ? 'opacity-0 hidden-scrollbar' : ''} ${
                      !isHiddenTable ? 'display-none' : ''
                    }`}
                    ref={stickyScrollBarRef}
                  >
                    <div
                      id="tableStickyScrollBar"
                      className={`table-sticky-scroll-bar ${isDragging ? 'active-scrollbar' : ''}`}
                      onMouseDown={handleMouseDown}
                    />
                  </div>
                </>
              )}
            </WindowScroller>
          </div>
        )}
      </div>
    </div>
  );
};
export default DataTable;
