import React, { FC } from 'react';

// Components
import { Img } from 'src/components/atoms';

interface EmptyMediasProps {
  icon: string;
  title: string;
  description: string;
}

const EmptyMedias: FC<EmptyMediasProps> = ({ icon, title, description }) => {
  return (
    <div className="empty-recordings d-flex align-items-center justify-content-center flex-column">
      <Img src={icon} />
      <h3 className="poppins-medium">{title}</h3>
      <p className="text-center">{description}</p>
    </div>
  );
};

export default EmptyMedias;
