import { createSlice } from '@reduxjs/toolkit';

export interface NotificationItemData {
  id: number;
  title: string;
  time: string;
  isRead: boolean;
}

interface NotificationState {
  notifications: NotificationItemData[];
}

const initialState: NotificationState = {
  notifications: [
    {
      id: 1,
      title: `<p><span>User Name</span> added a new product to <span>Denim</span></p>`,
      time: '2 minutes',
      isRead: false
    },
    {
      id: 2,
      title: `<p><span>Order #2345</span> has been created.</p>`,
      time: '2 hours ago',
      isRead: false
    },
    {
      id: 3,
      title: `<p>Update <span>V32.4</span> is available in your app.</p>`,
      time: '6 hours ago',
      isRead: false
    },
    {
      id: 4,
      title: `<p><span>User Name</span> added a new product to <span>Jackets</span></p>`,
      time: '6 hours ago',
      isRead: false
    },
    {
      id: 5,
      title: `<p><span>User Name</span> added a new product to <span>Pants</span></p>`,
      time: '6 hours ago',
      isRead: false
    },
    {
      id: 6,
      title: `<p><span>User Name</span> added a new product to <span>Pants</span></p>`,
      time: '18 hours ago',
      isRead: false
    }
  ]
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    getNotificationsSuccess: (state, action) => {
      const { payload } = action;
      // state.userInfo = payload;
    },
    updateNotification: (state, action) => {
      const { payload } = action;
      const index = state.notifications.findIndex((notification) => notification.id === payload.data.id);
      state.notifications = state.notifications.map((notification) => {
        if (notification.id === payload.data.id) {
          return { ...notification, [payload.data.fieldName]: payload.data.value };
        }
        return notification;
      });
    },
    deleteNotification: (state, action) => {
      const { payload } = action;
      state.notifications = state.notifications.filter((notification) => notification.id !== payload);
    }
  }
});

export const { getNotificationsSuccess, updateNotification, deleteNotification } = notificationsSlice.actions;

export default notificationsSlice.reducer;
