import { envUrls } from 'src/constants';

export const initOnesignal = async (): Promise<boolean> => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const OneSignal = window.OneSignal;
    await OneSignal.init({
      appId: envUrls.onesignalAppId,
      allowLocalhostAsSecureOrigin: true,
      welcomeNotification: {
        title: 'Buy Social',
        message: 'Thanks for subscribing!'
      }
    });
    await OneSignal.showSlidedownPrompt();
    return true;
  } catch (err) {
    console.error('Onesignal Error');
    console.log({ err });
    return false;
  }
};
