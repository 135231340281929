import { FC } from 'react';
import clsx from 'clsx';

// Components
import { Loader } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { CloseIcon } from 'src/assets/icons';

interface Props {
  isEndLoading: boolean;
  handleConfirm: () => void;
  closeModal: () => void;
}

const LeaveLiveShowConfirmModal: FC<Props> = (props) => {
  const { isEndLoading, handleConfirm, closeModal } = props;

  return (
    <div className="modal-content default-modal">
      <div className="modal-header">
        <h5 className="modal-title">End Liveshow</h5>
        <div className="btn btn-xs btn-active-light-primary p-0 m-0 border-none" onClick={closeModal}>
          <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
        </div>
      </div>
      <div className="modal-body">
        <div>Do you want to End Liveshow?</div>
      </div>
      <div className="modal-footer">
        {!isEndLoading && (
          <button className="btn btn-outlined-secondary btn-md" onClick={closeModal} data-bs-dismiss="modal" type="button">
            No
          </button>
        )}
        <button className={clsx('btn btn-danger btn-md')} onClick={handleConfirm} type="button" disabled={isEndLoading}>
          {isEndLoading ? 'Ending Liveshow' : 'Yes'}
          {isEndLoading && <Loader type="button" className="h-15px w-15px" />}
        </button>
      </div>
    </div>
  );
};

export default LeaveLiveShowConfirmModal;
