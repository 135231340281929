import React, { FC } from 'react';

// Components
import { FlexBox, Img } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import { DefaultImage } from 'src/assets/icons';

interface LiveShowAddProductVariantRowProps {
  variant: any;
}

const LiveShowAddProductVariantRow: FC<LiveShowAddProductVariantRowProps> = (props) => {
  const { variant } = props;

  return (
    <tr>
      <td className="align-middle w-60px ps-5 pe-2 py-0 table-top-border">
        <div className="form-check form-check-sm form-check-custom" />
      </td>
      <td className="align-middle w-60px px-2 py-0 table-top-border"></td>
      <td className="align-middle w-300px text-muted px-2 py-0 table-top-border">
        <FlexBox className="align-items-center">
          <div className="symbol me-5">
            <Img
              src={setImageSrc(
                variant?.variantMediaUrl ? generateCompressedImageURL(variant?.variantMediaUrl, '50') : DefaultImage
              )}
              placeholderImg={DefaultImage}
              errorImg={DefaultImage}
              className="object-fit-scale-down bg-white border border-light border-2"
            />
          </div>
          <FlexBox className="justify-content-start flex-column">
            <span className="d-block main-cell no-decoration">
              {variant.variantTypes?.map((varType) => varType.variantTypeValue).join('/')}
            </span>
          </FlexBox>
        </FlexBox>
      </td>
      <td className="align-middle minMidSize text-muted px-2 py-0 table-top-border">
        <div
          className="d-flex"
          style={{
            flexDirection: 'column'
          }}
        >
          <div>{variant?.productVariantQty ? variant?.productVariantQty : 0} in stock</div>
        </div>
      </td>
      <td className="align-middle minBoxSize text-muted px-2 py-0 table-top-border">{`$ ${Number(
        variant?.productVariantPrice
      ).toFixed(2)}`}</td>
      <td className="align-middle minBoxSize text-muted px-2 py-0 table-top-border" />
    </tr>
  );
};

export default React.memo(LiveShowAddProductVariantRow);
