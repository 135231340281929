import { FC } from 'react';

// Components
import { Img } from 'src/components/atoms';
import { NotesBlock } from 'src/components/molecules';
import { DeleteNotePopup } from 'src/components/pages';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';

// Icons
import { DefaultImage, NoNotesIllustration } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { Notes } from 'src/components/pages/products/AddProduct/AddProduct.types';

// Styles
import './AddNotes.scss';

interface AddNotesPresentationalProps {
  NotesData: Notes[];
  newNote: string;
  editText: string;
  isDeleteModal: boolean;
  getNotesId: number;
  handleAddNewNote: (notes: string) => void;
  onEnterSaveNote: (e: any) => void;
  handelSendNotes: () => void;
  handleDeleteNote: (id: number, noteId: number | undefined) => void;
  handleEditNote: (id: number, noteId: number | undefined) => void;
  handleSaveEditedNote: () => void;
  getText: (e: string) => void;
  closeModal: () => void;
  handleCancelNote: () => void;
  handleConfirmDelete: () => void;
}
const AddNotesPresentational: FC<AddNotesPresentationalProps> = ({
  NotesData,
  newNote,
  editText,
  handleAddNewNote,
  handelSendNotes,
  handleEditNote,
  getText,
  isDeleteModal,
  onEnterSaveNote,
  handleDeleteNote,
  closeModal,
  handleConfirmDelete,
  handleCancelNote,
  handleSaveEditedNote,
  getNotesId
}) => {
  const getUsername = (Note: Notes) => {
    if (Note.username) {
      return Note.username;
    } else if (Note.userName) {
      return Note.userName;
    } else if (Note.creatorName) {
      return Note.creatorName;
    }
    return 'User';
  };
  const getUserImage = (Note: Notes) => {
    if (Note.imageUrl) {
      return Note.imageUrl;
    } else if (Note.creatorDp) {
      return Note.creatorDp;
    }
    return DefaultImage;
  };

  return (
    <>
      <div className="productNotesBox">
        <div className="d-flex justify-content-start align-items-center">
          <h4 className="section-title m-b-32">{constVariables.common.productNote.noteTitle}</h4>
        </div>
        <div className="scrollProductNotes" id="scrollProductNotes" style={{ width: '100%' }}>
          {NotesData?.length > 0 ? (
            NotesData?.map((Note: Notes, id: number) => {
              if (getNotesId === id) {
                return (
                  <div
                    key={`${Note.id}-${id}`}
                    style={{
                      position: 'relative'
                    }}
                  >
                    <div className="detailsCls">
                      <Img
                        src={setImageSrc(getUserImage(Note))}
                        placeholderImg={DefaultImage}
                        errorImg={DefaultImage}
                        className="profileUserCls h-40px w-40px"
                      />
                      {/* TODO remove temp name after Global context */}
                      <div className="poppins-semibold">{getUsername(Note)}</div>
                    </div>
                    <textarea className="editTextInput noresize" value={editText} onChange={(e) => getText(e.target.value)} />
                    <div>
                      <button type="button" className="btn btn-outlined-secondary" onClick={handleCancelNote}>
                        {constVariables.common.productNote.cancel}
                      </button>
                      <button type="button" className="btn btn-outlined-primary" onClick={handleSaveEditedNote}>
                        {constVariables.common.productNote.save}
                      </button>
                    </div>
                  </div>
                );
              } else {
                return (
                  <NotesBlock
                    key={`${Note.id}-${id}`}
                    handleEditNote={handleEditNote}
                    handleDeleteNote={handleDeleteNote}
                    NotesDataLength={NotesData.length}
                    getUsername={getUsername}
                    getUserImage={getUserImage}
                    id={id}
                    Note={Note}
                  />
                );
              }
            })
          ) : (
            <>
              <div className="h-100 d-flex align-items-center justify-content-center flex-wrap">
                <img src={NoNotesIllustration} />
                <div className="note-placeholder-text">
                  <h6 className="mb-2">You don't have any notes yet.</h6>
                  <p className="m-0">The note is empty, you can start typing your notes below</p>
                </div>
              </div>
            </>
          )}
        </div>

        <div className=" d-flex align-items-center justify-content-between">
          <input
            id="kt_autosize_2"
            placeholder={constVariables.common.productNote.placeHolder}
            className="d-flex flex-grow-1 border rounded p-2  newNotesInput w-100"
            onChange={(e) => {
              handleAddNewNote(e.target.value);
            }}
            onKeyDown={onEnterSaveNote}
            value={newNote}
            onSubmit={handelSendNotes}
          />
          <button type="button" className="ms-2 btn btn-primary btn-sm" onClick={handelSendNotes}>
            {constVariables.common.productNote.save}
          </button>
        </div>
      </div>
      {isDeleteModal && (
        <DeleteNotePopup
          title={constVariables.common.productNote.deleteNote.title}
          message={constVariables.common.productNote.deleteNote.message}
          cancelButton={constVariables.common.productNote.deleteNote.cancel}
          confirmButton={constVariables.common.productNote.deleteNote.confirm}
          handleConfirmDelete={handleConfirmDelete}
          open={isDeleteModal}
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export default AddNotesPresentational;
