import { gql } from '@apollo/client';

export const SAVE_COLLECTION_PRODUCT_IDS = gql`
  mutation SaveCollectionProductIds($input: SaveCollectionProductIdsInput!) @api(name: "productsAPI") {
    saveCollectionProductIds(input: $input) {
      success
      message
    }
  }
`;
