export enum WEIGHT_UNITS {
  KG = 'KG',
  G = 'G',
  LB = 'LB',
  OZ = 'OZ'
}

export const convertWeight = (currentValue: number = 0, currentUnit: string = '', unitToChange: WEIGHT_UNITS) => {
  if (!currentValue || !currentUnit) return 0;

  if (unitToChange?.toUpperCase() === currentUnit.toUpperCase()) {
    return currentValue;
  }

  if (currentUnit.toUpperCase() === 'KG') {
    if (unitToChange?.toUpperCase() === 'G') {
      return Math.round((currentValue * 1000 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange?.toUpperCase() === 'LB') {
      return Math.round((currentValue * 2.2 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange?.toUpperCase() === 'OZ') {
      return Math.round((currentValue * 35.27 + Number.EPSILON) * 100) / 100;
    }
  }
  if (currentUnit.toUpperCase() === 'G') {
    if (unitToChange?.toUpperCase() === 'KG') {
      return Math.round((currentValue / 1000 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange?.toUpperCase() === 'LB') {
      return Math.round((currentValue * 0.0022 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange?.toUpperCase() === 'OZ') {
      return Math.round((currentValue * 0.03527 + Number.EPSILON) * 100) / 100;
    }
  }
  if (currentUnit.toUpperCase() === 'OZ') {
    if (unitToChange?.toUpperCase() === 'KG') {
      return Math.round((currentValue / 35.27 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange?.toUpperCase() === 'G') {
      return Math.round((currentValue / 0.03527 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange?.toUpperCase() === 'LB') {
      return Math.round((currentValue * 0.0625 + Number.EPSILON) * 100) / 100;
    }
  }
  if (currentUnit.toUpperCase() === 'LB') {
    if (unitToChange?.toUpperCase() === 'KG') {
      return Math.round((currentValue / 2.2 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange?.toUpperCase() === 'G') {
      return Math.round(((currentValue / 2.2) * 1000 + Number.EPSILON) * 100) / 100;
    }
    if (unitToChange?.toUpperCase() === 'OZ') {
      return Math.round((currentValue / 0.0625 + Number.EPSILON) * 100) / 100;
    }
  }
  return 0;
};
