import { gql } from '@apollo/client';

export const CREATE_COLLECTION = gql`
  mutation CreateCollection($input: CreateCollectionInput!) @api(name: "productsAPI") {
    createCollection(input: $input) {
      success
      message
      additionalData
    }
  }
`;
