import { gql } from '@apollo/client';

export const SAVE_SORT_ORDER_IN_RAPID_LIST = gql`
  mutation saveSortOrderInRapidList($input: SortOrderInput!) @api(name: "authAPI") {
    saveSortOrderInRapidList(input: $input) {
      success
      message
    }
  }
`;
