import { FC, useEffect, useState, createRef } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Picker } from 'emoji-mart';

// Components
import { ConditionalWrapper } from 'src/components/atoms/ConditionalWrapper/ConditionalWrapper';
import { Portal, Tooltip } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import {
  EmojiClickedIcon,
  EmojiHoverIcon,
  EmojiIcon,
  FrequentIcon,
  SmileIcon,
  AnimalIcon,
  FoodIcon,
  TravelIcon,
  ActivityIcon,
  ObjectIcon,
  SymbolIcon,
  FlagIcon
} from 'src/assets/icons';

// Types
import { EmojiInputProps } from './EmojiInput.types';

// Styles
import 'emoji-mart/css/emoji-mart.css';
import './_emojiInput.scss';

const categoryIcons = {
  categories: {
    recent: () => <KTSVG path={FrequentIcon} />,
    people: () => <KTSVG path={SmileIcon} />,
    nature: () => <KTSVG path={AnimalIcon} />,
    foods: () => <KTSVG path={FoodIcon} />,
    places: () => <KTSVG path={TravelIcon} />,
    activity: () => <KTSVG path={ActivityIcon} />,
    objects: () => <KTSVG path={ObjectIcon} />,
    symbols: () => <KTSVG path={SymbolIcon} />,
    flags: () => <KTSVG path={FlagIcon} />
  }
};

const EmojiInput: FC<EmojiInputProps> = ({
  title,
  titleClass,
  emojiClass,
  max,
  value,
  placeholder,
  isDisabled,
  isMandatory,
  showCount,
  isError,
  isTextArea,
  showHover,
  messageOnHover,
  errorMessage,
  onChangeText,
  onKeyDown,
  showPostButton,
  handlePost,
  inputClass
}) => {
  // state params
  const pickerIcon = createRef<any>();
  const [showEmoji, setShowEmoji] = useState<boolean>(false);
  const [hoverCondition, setHoverCondition] = useState<boolean>(false);
  const [pickerPositions, setPickerPositions] = useState({
    top: 0,
    left: 0
  });

  useEffect(() => {
    if (showHover !== undefined) {
      setHoverCondition(showHover);
    }
  }, [showHover]);

  // Event handlers
  const openPicker = (): void => {
    setShowEmoji(!showEmoji);
    if (pickerIcon.current) {
      const position = pickerIcon.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - position.bottom;
      setPickerPositions({
        top: parseInt(spaceBelow < 455 ? window.scrollY + position.top - 445 : window.scrollY + position.top + 40, 10),
        left: parseInt(position.left, 10) - 425
      });
    }
  };
  const enterKeyDownHandler = (e: any) => {
    if (e.key === 'Enter') {
      if (!showPostButton) {
        openPicker();
      }
    }
  };
  const keyDownHandler = (e: any) => {
    if (e.key === 'Enter') {
      if (showPostButton) {
        handlePost && handlePost();
      } else {
        openPicker();
      }
    }
    if (e.key === 'Tab') {
      pickerIcon.current.focus();
      e.preventDefault();
    }
  };
  const addEmoji = (e) => {
    const emoji = e.native;
    if (max) {
      if ((value + emoji).length <= max) {
        onChangeText && onChangeText(value + emoji);
      }
    } else {
      onChangeText && onChangeText(value + emoji);
    }
  };

  return (
    <div>
      <div className="d-flex">
        {title ? (
          <div className={`poppins-semibold fs-6 m-b-12 ${titleClass} ${isDisabled ? 'opacity-50' : ''}`}>
            {title}
            {isMandatory ? <span className="text-danger fs-4 px-1">*</span> : null}
          </div>
        ) : null}
      </div>
      <div
        className={`position-relative d-flex form-control px-5 align-item-center w-100 ${isError ? 'form-control-error' : ''} ${
          isTextArea ? 'h-100px' : 'h-100'
        } ${isDisabled ? 'opacity-50' : ''}`}
      >
        <ConditionalWrapper
          condition={hoverCondition}
          wrapper={(children) => (
            <>
              <div className="w-100" data-tip data-for="disableNotification">
                {children}
              </div>
              <Tooltip
                tooltipId="disableNotification"
                place="top"
                content={() => {
                  return <>{messageOnHover}</>;
                }}
              />
            </>
          )}
        >
          {isTextArea ? (
            <textarea
              id="commnet-input-box"
              autoComplete="off"
              className="w-100 h-100 border-0 emoji-textarea noresize"
              placeholder={placeholder}
              value={value}
              disabled={isDisabled}
              onKeyDown={keyDownHandler}
              onChange={(e) => onChangeText && onChangeText(e.target.value)}
              maxLength={max}
            />
          ) : (
            <input
              id="emojiInpuBox"
              autoFocus
              autoComplete="off"
              className={`w-100 h-100 border-0 ${inputClass ? inputClass : ''}`}
              type="text"
              placeholder={placeholder}
              value={value}
              disabled={isDisabled}
              onKeyDown={keyDownHandler}
              onChange={(e) => onChangeText && onChangeText(e.target.value)}
              maxLength={max}
            />
          )}
        </ConditionalWrapper>
        {showPostButton && (
          <button className="btn btn-primary btn-xs live-show-comment-post-btn position-absolute" onClick={handlePost}>
            Post
          </button>
        )}
        <span
          ref={pickerIcon}
          id="picker"
          onKeyDown={enterKeyDownHandler}
          className={`position-absolute emoji-icon
             ${showPostButton ? 'show-post-btn' : ''}
             ${isTextArea ? 'emoji-top-70' : ''}
             ${!isDisabled ? 'cursor-pointer' : ''}`}
        >
          <KTSVG
            path={EmojiIcon}
            hoverSvgPath={EmojiHoverIcon}
            clickedSvgPath={EmojiClickedIcon}
            // EmojiIcon
            className={`svg-icon-2 svg-icon-gray-500 translate-middle-y`}
            // svgClassName={'emoji-icon-margin'}
            addSvgIconClass={false}
            onClick={openPicker}
            isDisabled={isDisabled}
            updateClickEvent={showEmoji}
            hoverClassName="svg-hover"
          />
        </span>
      </div>
      <Portal id="kt_body">
        <OutsideClickHandler
          onOutsideClick={() => {
            if (showEmoji) setShowEmoji(false);
          }}
        >
          {/* Emoji Picker */}
          {showEmoji && (
            <div className="position-absolute emoji-picker-div" style={{ top: pickerPositions.top, left: pickerPositions.left }}>
              <Picker
                onSelect={addEmoji}
                emojiTooltip={true}
                className={emojiClass}
                title={''}
                showPreview={false}
                showSkinTones={false}
                icons={categoryIcons}
                color="#00a3ff"
              />
            </div>
          )}
        </OutsideClickHandler>
      </Portal>
      <div className="d-flex pt-2 greyText w-100 h-100 justify-content-between">
        {errorMessage && <div className={`text-danger poppins-regular fs-7 m-b-12`}>{errorMessage}</div>}
        {showCount && (
          <span>
            {(value || '').length} / {max}
          </span>
        )}
      </div>
    </div>
  );
};
export default EmojiInput;
