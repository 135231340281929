import { gql } from '@apollo/client';

export const CANCEL_ORDER = gql`
  mutation CancelOrder($input: CancelOrderInput!) @api(name: "ordersAPI") {
    cancelOrder(input: $input) {
      id
      success
      message
    }
  }
`;
