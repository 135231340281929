import { FC, useEffect, useState } from 'react';

// Components
import { Img } from 'src/components/atoms';
import { RadioSelector, InputBox } from 'src/components/molecules';

// Icons
import { DownArrowIcon, UpArrowIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants/constVariables';

// Types
import { MinRequirementTypeSection } from 'src/components/pages/coupons/CreateCoupon/CreateCoupon.types';

const MinimumRequirementCreateCoupon: FC<MinRequirementTypeSection> = ({
  minRequirement,
  setMinRequirement,
  errorMessages,
  setErrorMessages,
  validateInput
}) => {
  const [isNone, setIsNone] = useState(false);
  const [isMinPurchase, setIsMinPurchase] = useState(false);
  const [isMinQuant, setIsMinQuant] = useState(false);

  useEffect(() => {
    if (minRequirement.value === 'NONE') {
      setIsNone(true);

      setIsMinPurchase(false);
      setIsMinQuant(false);
    } else if (minRequirement.value === 'PURCHASE_AMOUNT') {
      setIsMinPurchase(true);

      setIsNone(false);
      setIsMinQuant(false);
    } else if (minRequirement.value === 'QAUANTITY_OF_ITEMS') {
      setIsMinQuant(true);

      setIsNone(false);
      setIsMinPurchase(false);
    }
  }, [minRequirement]);

  const handleNone = () => {
    setMinRequirement((prev) => ({
      ...prev,
      value: 'NONE',
      minPurchaseAmount: '',
      minQuantOfItems: ''
    }));
    setErrorMessages((errorMessages) => ({
      ...errorMessages,
      minPurchaseAmountError: '',
      minQuantityAmountError: ''
    }));
  };
  const handleMinPurchase = () => {
    setMinRequirement((prev) => ({
      ...prev,
      value: 'PURCHASE_AMOUNT',
      minQuantOfItems: ''
    }));
    setErrorMessages((errorMessages) => ({
      ...errorMessages,
      minQuantityAmountError: ''
    }));
  };
  const handleMinQuant = () => {
    setMinRequirement((prev) => ({
      ...prev,
      value: 'QAUANTITY_OF_ITEMS',
      minPurchaseAmount: ''
    }));
    setErrorMessages((errorMessages) => ({
      ...errorMessages,
      minPurchaseAmountError: ''
    }));
  };

  const changeMinPurAmt = (data: any) => {
    if (!isNaN(data)) {
      setMinRequirement((prev) => ({
        ...prev,
        minPurchaseAmount: data.trim()
      }));
    }
  };
  const changeMinQnt = (data: any) => {
    if (!isNaN(data)) {
      setMinRequirement((prev) => ({
        ...prev,
        minQuantOfItems: data.trim()
      }));
    }
  };
  const addMinQnt = () => {
    if (minRequirement.minQuantOfItems) {
      setMinRequirement((prev) => ({
        ...prev,
        minQuantOfItems: (parseInt(prev.minQuantOfItems, 10) + 1).toString()
      }));
    }
  };
  const subMinQnt = () => {
    if (minRequirement.minQuantOfItems && parseInt(minRequirement.minQuantOfItems, 10) > 0) {
      setMinRequirement((prev) => ({
        ...prev,
        minQuantOfItems: (parseInt(prev.minQuantOfItems, 10) - 1).toString()
      }));
    }
  };

  const handleKeyInput = (event, fieldName) => {
    if (event.keyCode === 13) {
      validateInput(event.target.value, fieldName);
    }
  };

  const handleBlurInput = (event, fieldName) => {
    validateInput(event.target.value, fieldName);
  };

  return (
    <div className="card w-100">
      <div className="section-title m-b-32">{constVariables.Coupons.AddCoupon.minReq.minReq}</div>
      <RadioSelector name={constVariables.Coupons.AddCoupon.minReq.none} isCheck={isNone} changeCheck={handleNone} />
      <div className="m-b-24">
        <RadioSelector
          name={constVariables.Coupons.AddCoupon.minReq.minAmount}
          isCheck={isMinPurchase}
          changeCheck={handleMinPurchase}
          noMarginBottom={isMinPurchase ? false : true}
        />
        {isMinPurchase ? (
          <div className="d-flex align-items-center justify-content-between valueInput section-content">
            <InputBox
              dollarSign={minRequirement.minPurchaseAmount.toString().length > 0}
              inputClass="valueColor d-flex align-items-center form-control-lg"
              value={minRequirement.minPurchaseAmount}
              onChangeText={changeMinPurAmt}
              name="CouponValue"
              placeholder="$0.00"
              charLimit={10}
              onKeyDown={(event) => handleKeyInput(event, 'minPurchaseAmountError')}
              onBlur={(event) => handleBlurInput(event, 'minPurchaseAmountError')}
              warningText={errorMessages?.minPurchaseAmountError}
            />
          </div>
        ) : null}
      </div>
      <div>
        <RadioSelector name={constVariables.Coupons.AddCoupon.minReq.minQuant} isCheck={isMinQuant} changeCheck={handleMinQuant} />
        {isMinQuant ? (
          <div className="section-content">
            <div className="d-flex align-items-center justify-content-start">
              <div className="d-flex align-items-center justify-content-between position-relative">
                <InputBox
                  inputClass="valueColor valueInput d-flex align-items-center form-control-lg"
                  value={minRequirement.minQuantOfItems}
                  onChangeText={changeMinQnt}
                  name="CouponValue"
                  charLimit={10}
                  onKeyDown={(event) => handleKeyInput(event, 'minQuantityAmountError')}
                  onBlur={(event) => handleBlurInput(event, 'minQuantityAmountError')}
                  warningText={errorMessages?.minQuantityAmountError}
                />
                <div className="d-flex flex-column position-absolute arrow-icons">
                  <Img onClick={addMinQnt} className="cursor-pointer h-15px w-15px opacity-50" src={UpArrowIcon} />
                  <Img onClick={subMinQnt} className="cursor-pointer h-15px w-15px opacity-50" src={DownArrowIcon} />
                </div>
              </div>
            </div>
            <div className="text-muted mt-2 fs-7">Applies to all products.</div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MinimumRequirementCreateCoupon;
