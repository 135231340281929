import React, { useState, createRef } from 'react';
import { CellProps } from 'react-datasheet-grid';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { Portal, Dropdown } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { DownArrowIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

const StatusCell = React.memo(({ rowData, setRowData, active, focus }: CellProps) => {
  const statusRef = createRef<any>();
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropDownPositions, setDropDownPositions] = useState({
    top: 0,
    left: 0
  });

  const selectProductStatusClass = (productStatus: string) => {
    switch (productStatus) {
      case constVariables.ProductsTab.active.toLowerCase():
        return 'active';
      case constVariables.ProductsTab.draft.toLowerCase():
        return 'draft';
      default:
        return 'archive';
    }
  };

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  const handleOpenDropdown = () => {
    if (focus) {
      setShowDropdown(true);
      if (statusRef.current) {
        const position = statusRef.current.getBoundingClientRect();
        const spaceBelow = window.innerHeight - position.bottom;
        setDropDownPositions({
          top: spaceBelow < 180 ? window.scrollY + position.top - position.height : window.scrollY + position.top + 72,
          left: position.left
        });
      }
    }
  };

  const renderProductStatus = (productStatus) => {
    return constVariables.statusDropdownData.find((status) => {
      if (productStatus === 'ARCHIVED') {
        return status.name.toLowerCase() === 'archive';
      }
      return status.name.toLowerCase() === productStatus?.toLowerCase();
    })?.name;
  };

  return (
    <>
      <div className="position-relative w-100 h-100 d-flex align-items-center px-3" ref={statusRef} onClick={handleOpenDropdown}>
        <div
          className={`text-center p-2 poppins-medium ${selectProductStatusClass(
            rowData?.productStatus?.toLocaleLowerCase()
          )}-status-box`}
        >
          <span>{renderProductStatus(rowData?.productStatus)}</span>
        </div>
        {active && !showDropdown && <KTSVG path={DownArrowIcon} className="select-downarrow-icon position-absolute" />}
      </div>
      {showDropdown && (
        <Portal id="kt_body">
          <OutsideClickHandler onOutsideClick={closeDropdown}>
            <div className="position-absolute" style={{ top: dropDownPositions.top, left: dropDownPositions.left }}>
              <Dropdown
                data={constVariables.statusDropdownData}
                selected={showDropdown}
                value={rowData?.productStatus}
                onSelect={(data: any) => {
                  const productStatus = data.name.toUpperCase();
                  const updatedRowdata = { ...rowData, productStatus };
                  setRowData(updatedRowdata);
                  setShowDropdown(false);
                }}
                closeDropdown={closeDropdown}
                className="bulk-editor-dropdown"
              />
            </div>
          </OutsideClickHandler>
        </Portal>
      )}
    </>
  );
});

export default StatusCell;
