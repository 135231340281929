import { gql } from '@apollo/client';

export const GET_RAPID_LIST = gql`
  query GetRapidActionList($input: RapidListInput!) @api(name: "authAPI") {
    getRapidActionList(input: $input) {
      __typename
      ... on GetCollection {
        productIds
        enabledFeatured
        enabledFlashSale
      }
    }
  }
`;
