import { FC } from 'react';

// Components
import { KTSVG } from 'src/helpers';

// Icons
import { ThreeDotsIcon } from 'src/assets/icons';

const ActionMenu: FC = () => {
  return (
    <div>
      <button className="bg-transparent border border-transparent">
        <KTSVG path={ThreeDotsIcon} svgClassName="cursor-pointer collapseFilter collapseIcon" />
      </button>
    </div>
  );
};

export default ActionMenu;
