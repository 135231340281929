/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react';
import clsx from 'clsx';

// Components
import { FlexBox } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { SortDownArrow, SortUpArrow } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { CatalogSortDropdownProps } from './CatalogSortDropdown.types';

const CatalogSortDropdown: FC<CatalogSortDropdownProps> = ({ data, clearner, textClass, selected, onSelect, value }) => {
  const [hoverEffect, setHoverEffect] = useState(true);

  const shiftLeft: {
    width: string;
    top: string;
    zIndex: number;
    left?: string;
  } = {
    width: '180px',
    top: '45px',
    zIndex: 150
  };

  return (
    <div
      className={clsx(
        `mt-1 menu menu-sub menu-background menu-sub-dropdown menu-column menu-rounded menu-gray-600 fw-bold pt-1 pb-1 position-absolute px-0 mh-400px overflow-scroll ${
          clearner ? 'w-250px border-rounded moveleftFeatProd' : ''
        }`,
        { show: selected }
      )}
      style={shiftLeft}
      data-kt-menu="true"
    >
      {!clearner ? (
        <FlexBox className="border-bottom border-bottom-secondary p-3 w-100 justify-content-between">
          <div className="poppins-medium">{constVariables.ProductsTab.sortBy}</div>
          <div
            className="text-primary cursor-pointer"
            onClick={() => {
              onSelect('NAME', 619, 'Product');
            }}
          >
            {constVariables.ProductsTab.default}
          </div>
        </FlexBox>
      ) : null}
      {data?.map((item, index) => {
        return (
          <div className="menu-item" key={index}>
            <a
              className={`dropdown-item menu-link px-4 py-3 mb-0 ${value.column === item.name ? 'text-primary' : ''} ${
                hoverEffect ? '' : 'no-hover'
              }`}
              onClick={() => {
                if (value.column !== item.name) onSelect(item.name, item.id, item.label);
              }}
            >
              <FlexBox className="w-100 justify-content-between align-items-center">
                <div className={textClass}>{item.label ? item.label : item.name}</div>
                <div
                  onClick={() => {
                    onSelect(item.name, item.id, item.label);
                  }}
                  className={`d-flex sort-arrow-div align-items-center justify-content-center ${
                    value.column !== item.name ? 'invisible' : ''
                  }`}
                  onMouseEnter={() => {
                    if (value.id === item.id) setHoverEffect(false);
                  }}
                  onMouseLeave={() => {
                    setHoverEffect(true);
                  }}
                >
                  <KTSVG path={value.order === 'ASC' ? SortUpArrow : SortDownArrow} className="sort-icon" />
                </div>
              </FlexBox>
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default CatalogSortDropdown;
