import React, { createRef, useState } from 'react';
import { CellProps } from 'react-datasheet-grid';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { Portal, Dropdown } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { DownArrowIcon } from 'src/assets/icons';

const ProductTypeCell = React.memo(({ rowData, setRowData, active, focus, columnData }: CellProps) => {
  const typeCellRef = createRef<any>();
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropDownPositions, setDropDownPositions] = useState({
    top: 0,
    left: 0
  });

  const handleOpenDropdown = () => {
    if (focus) {
      setShowDropdown(true);
      if (typeCellRef.current) {
        const position = typeCellRef.current.getBoundingClientRect();
        const spaceBelow = window.innerHeight - position.bottom;
        setDropDownPositions({
          top: spaceBelow < 180 ? window.scrollY + position.top - position.height : window.scrollY + position.top + 72,
          left: position.left
        });
      }
    }
  };

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  return (
    <>
      <div className="w-100 h-100 px-3 d-flex align-items-center position-relative" ref={typeCellRef} onClick={handleOpenDropdown}>
        <span>{rowData?.productTypeName}</span>
        {active && !showDropdown && <KTSVG path={DownArrowIcon} className="select-downarrow-icon position-absolute" />}
      </div>
      {showDropdown && (
        <Portal id="kt_body">
          <OutsideClickHandler
            onOutsideClick={() => {
              if (showDropdown) closeDropdown();
            }}
          >
            <div className="position-absolute" style={{ top: dropDownPositions.top, left: dropDownPositions.left }}>
              <Dropdown
                data={columnData?.getAllProductTypes}
                selected={showDropdown}
                value={rowData?.productTypeName}
                onSelect={(data: any) => {
                  const updatedRowdata = { ...rowData, productTypeId: data.id, productTypeName: data.name };
                  setRowData(updatedRowdata);
                  setShowDropdown(false);
                }}
                closeDropdown={closeDropdown}
                className="bulk-editor-dropdown"
              />
            </div>
          </OutsideClickHandler>
        </Portal>
      )}
    </>
  );
});

export default ProductTypeCell;
