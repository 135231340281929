import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuid4 } from 'uuid';

// Apollo
import { useMutation, useQuery } from '@apollo/client';
import { STRIPE_CONNECTION_STATUS } from 'src/apollo/queries/getStripeConnectionStatus';
import { CONFIGURE_STRIPE } from 'src/apollo/mutations/connectStripe';

// Components
import { FlexBox, Loader } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { useToast } from 'src/utils/hooks/useToast';

// Icons
import { FilledCheckCircle, StripeIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables, envUrls } from 'src/constants';

const StripeConnection: FC = () => {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const { showToast } = useToast();

  const { data } = useQuery(STRIPE_CONNECTION_STATUS);
  const [connectStripe, { loading, error }] = useMutation(CONFIGURE_STRIPE, {
    onError: (err) => {
      showToast({
        message: err?.message,
        errorText: 'Error in connecting stripe'
      });
    },
    onCompleted: () => {
      localStorage.removeItem('stripeStateTOken');
    }
  });
  useEffect(() => {
    if (data?.getStripeStatus && !data?.getStripeStatus?.isConnected) {
      const code = searchParams?.get('code');
      const state = searchParams?.get('state');
      const loacalState = localStorage.getItem('stripeStateTOken');
      if (code && state && loacalState === state) {
        connectStripe({
          variables: {
            secureCode: code
          }
        });
      }
    }
  }, [data]);

  const openConnectUri = () => {
    const state = uuid4();
    const redirecturl = `${window.location.host.includes('localhost') ? 'http://' : 'https://'}${
      window.location.host
    }/settings/payments`;
    const stripeUrl = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${envUrls.stripeClientId}&scope=read_write&state=${state}&redirect_uri=${redirecturl}`;
    localStorage.setItem('stripeStateTOken', state);
    window.open(stripeUrl, '_blank');
  };

  const showError = false;
  return (
    <div className="card w-100 mb-0">
      <div>
        <FlexBox className="align-items-center">
          <img src={StripeIcon} width={100} height={48} className="stripe-logo" />
        </FlexBox>

        <div>
          {data?.getStripeStatus?.isConnected ? (
            <div className="my-5">
              <img src={FilledCheckCircle} />
              <span className="ms-2 text-success">{constVariables.payments.stripe.connected}</span>
            </div>
          ) : (
            <button className="btn mt-5 btn-primary" onClick={openConnectUri}>
              {loading ? (
                <div className={`w-70px h-22px d-flex flex-center`}>
                  <Loader type="button" />
                </div>
              ) : (
                constVariables.payments.stripe.connect
              )}
            </button>
          )}
          {showError && (
            <p className="poppins-regular mt-5 text-danger errorMsg">{constVariables.payments.unableToConnectMessage}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default StripeConnection;
