import { gql } from '@apollo/client';

export const GET_TAGS_LIST = gql`
  query onGetTagList($input: TagListInput!) @api(name: "productsAPI") {
    getTagList(input: $input) {
      id
      name
      textColorCode
      backgroundColorCode
      products {
        productId
        productName
      }
    }
  }
`;
