interface ObjectLiteral {
  [key: string]: any;
}

export const validateForm = (value: string | boolean | ObjectLiteral): boolean => {
  if (typeof value === 'string' && value.length > 0) {
    return true;
  }
  if (typeof value === 'boolean' && value) {
    return true;
  }
  if (typeof value === 'object') {
    return Object.keys(value).every((key) => {
      return validateForm(value[key]);
    });
  }
  return false;
};

export const checkInvalidCharacter = (event: ObjectLiteral): void => {
  if (event.key === '-' || event.key === '+') {
    event.preventDefault();
  }
};

export const isEmailValid = (email: string): boolean => {
  // const regex = /^(?!.*\.{2})[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/gim;
  // const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/gim;
  const regex = /^(?!\d+@)\w+([-+.']\w+)*@(?!\d+\.)\w+([-.]\w+)*\.\w+([-.]\w+)*$/gim;
  return regex.test(email);
  // return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const isFullNameValid = (fullname: string): boolean => {
  // const regex = /^[^\s\d]+\s[^\s\d]+$/gim;
  // const regex = /^[A-Za-z]+\s[A-Za-z]+$/gim;
  const regex = /^[A-Za-z_ ]+([A-Za-z_ ])+$/gim;
  return regex.test(fullname);
};

export const isStoreNameValid = (storeName: string): boolean => {
  // const regex = /^[A-Z]{1}[A-Za-z0-9-\s]{2,18}[A-Za-z0-9]$/gim;
  // return regex.test(storeName);
  return storeName?.length <= 50;
};

export const validateRequired = (text: string, fieldName: string): boolean | string => {
  if (text.trim() === '') {
    return `${fieldName ? fieldName : 'This field'} is required`;
  }
  return false;
};

export const validateEmail = (text: string): boolean | string => {
  if (text !== undefined) {
    if (text.trim() === '') {
      return 'Email address is required';
    } else {
      if (!isEmailValid(text)) {
        return 'Wrong email format';
      }
    }
  }
  return false;
};

export const validateStoreName = (text: string): boolean | string => {
  if (text !== undefined) {
    if (text.trim() === '') {
      return 'Store name is required';
    } else {
      if (!isStoreNameValid(text)) {
        return 'Please enter a valid store name.';
      }
    }
  }
  return false;
};

export const validateFullName = (text: string): boolean | string => {
  if (text !== undefined) {
    if (text.trim() === '') {
      return 'Full name is required';
    } else {
      if (!isFullNameValid(text)) {
        return 'Full name is not in correct format';
      }
    }
  }
  return false;
};

export const checkPassword = (password: string): boolean => {
  const regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return regex.test(password);
};

export const validatePassword = (text: string): boolean | string => {
  if (text !== undefined) {
    if (text.trim() === '') {
      return 'Password is required';
    } else {
      if (!checkPassword(text)) {
        return 'Please enter at least 8 letter password, with a symbol, upper and lower case letters and a number';
      }
    }
  }
  return false;
};
