import { useContext } from 'react';
import { IRecordingsDownloader } from './RecordingsDownloader.types';
import { RecordingsDownloaderContext } from './RecordingsDownloader.context';

const useRecordingsDownloader = (): IRecordingsDownloader => {
  const handlers = useContext(RecordingsDownloaderContext);
  if (!handlers) {
    throw new Error('useGlobalEventHandlers must be used within a GlobalEventHandlerProvider');
  }
  return handlers;
};

export { useRecordingsDownloader };
