import { FC, useEffect, useState, useReducer } from 'react';
import { useHistory, useParams } from 'react-router';
import moment from 'moment';
import WorldData from 'country-state-city-plus';

// Apollo
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_COUPONS_LIST, GET_COUPON_CONDITIONS, GET_COUPON_MATCHING_PPRODUCT_LIST } from 'src/apollo/queries';
import { ADD_COUPON, EDIT_COUPON } from 'src/apollo/mutations';

// Components
import { CustomModal, DiscardModal, SaveHeader } from 'src/components/oraganisms';
import CreateCouponPresentational from './CreateCoupon.presentational';

// Hooks && Utils && Helpers
import { useToast } from 'src/utils/hooks/useToast';

// ConstVariables
import { ROUTES, constVariables } from 'src/constants';

// Types
import { CouponsListData } from 'src/components/pages/coupons/AllCouponsPage/AllCouponsPage.type';
import {
  CatalogProductListType,
  CouponTypeType,
  GeneralInfoCreateCouponType,
  MinRequirementType,
  CustomerEligibilityType,
  UsageLimitsType,
  CouponChannelsType,
  SummaryType,
  StatusType,
  ValueCCType,
  CustomerBuyGetType,
  CountriesCCType,
  CouponCollectionsType,
  ErrorsInfo,
  CountryItem,
  productAssignmentMethod,
  CouponTimeError
} from './CreateCoupon.types';
import { ConditionType, FieldConditionType } from './CreateCoupon.types';

// Styles
import './_createCoupon.scss';

const CreateCoupon: FC = () => {
  const history = useHistory();
  const { showToast } = useToast();
  let productAssignmentRules = [] as any;
  const { couponId } = useParams<{ couponId: string }>();
  const [isCouponDetailAPIcalled, setIsCouponDetailAPIcalled] = useState(false);
  const [generalInfo, setGeneralInfo] = useState<GeneralInfoCreateCouponType>({
    placeHolder: constVariables.Coupons.AddCoupon.general.generalPlaceHolder,
    title: constVariables.Coupons.AddCoupon.general.generalPlaceHolder,
    name: constVariables.Coupons.AddCoupon.general.generalPlaceHolder,
    description: '',
    updatedAt: '',
    value: '',
    automaticDiscount: false,
    externalProvider: undefined
  });

  const [errorMessages, setErrorMessages] = useState<ErrorsInfo>({
    generalInfoTitleError: '',
    fixedAmountError: '',
    percentageAmountError: '',
    minPurchaseAmountError: '',
    minQuantityAmountError: '',
    specifiCustomersError: '',
    specificSegmentsError: '',
    usageLimitsError: '',
    freeShippingAmountThresholdError: ''
  });

  const [couponTimesError, setCouponTimesError] = useState<CouponTimeError>({
    isStartTimeError: false,
    startTimeErrorMessage: '',
    isEndTimeError: false,
    endTimeErrorMessage: ''
  });

  const [couponCollections, setCouponCollections] = useState<CouponCollectionsType>({ value: [] });
  const [couponType, setCouponType] = useState<CouponTypeType>({ value: 'AMOUNT_OF_ORDER' });
  const [minRequirement, setMinRequirement] = useState<MinRequirementType>({
    value: 'NONE',
    minPurchaseAmount: '',
    minQuantOfItems: ''
  });
  const [customerEligibility, setCustomerEligibility] = useState<CustomerEligibilityType>({
    value: 'EVERYONE',
    customers: [],
    segments: []
  });
  const [usageLimits, setUsageLimits] = useState<UsageLimitsType>({ value: '', selectedItems: [] });
  const [couponChannel, setCouponChannel] = useState<CouponChannelsType>({ value: ['MOBILE_STORE', 'WEB_STORE', 'FACEBOOK'] });
  const [status, setStatus] = useState<StatusType>({
    statusValue: 'ACTIVE',
    startDate: new Date(),
    endDate: undefined,
    startTime: undefined,
    endTime: undefined,
    isSetEndDate: false
  });
  const [countryList, setCountryList] = useState<CountryItem[]>([]);

  const [summary] = useState<SummaryType>({
    value: []
    // value: ['Available on online sales channels', '10% off all products', 'For everyone', 'Active 15 Jun']
  });
  const [value, setValue] = useState<ValueCCType>({
    amountPercentage: '',
    amountFixed: '',
    isManual: false,
    isAutomatic: false,
    optionSelected: 'FLAT'
  });
  const [countries, setCountries] = useState<CountriesCCType>({
    isAllCountries: false,
    isSelectedCountries: false,
    isShippingRates: true,
    shippingRateValue: ''
  });
  const [customerBuyGet, setCustomerBuyGet] = useState<CustomerBuyGetType>({
    isMinPurchase: false,
    isMinQuantity: false,
    buyQuantity: '',
    buyItem: '',
    getQuantity: '',
    getItem: ''
  });
  const [isDiscardModal, setIsDiscardModal] = useState<boolean>(false);
  const [isLeaveModal, setIsLeaveModal] = useState<boolean>(false);
  const [isDataChanged, setDataChanged] = useState<boolean>(false);
  const [targettedPath, setTargettedPath] = useState<string>();
  const [openLoader, setOpenLoader] = useState<boolean>(false);
  const [formChanges, setFormChanges] = useState(false);

  const [productListData, setProductListData] = useState<CatalogProductListType>({
    autoMaticProductListIds: [],
    manualProductListIds: [],
    selectedProductsIds: []
  });

  const [disableSaveButton, setDisableSaveButton] = useState(false);

  const [callCouponConditions, { data: couponConditionData }] = useLazyQuery(GET_COUPON_CONDITIONS, {
    onCompleted: () => {
      setTimeout(() => {
        // console.log('GET_COUPON_CONDITIONS called');
      }, 150);
    }
  });

  useEffect(() => {
    if (couponConditionData?.getCouponConditions) {
      const arr: { id: number; name: string; operations: string[] }[] = Array.from(couponConditionData?.getCouponConditions);
      const fieldDropdownArr: FieldConditionType[] = [];
      arr?.map((condition: { id: number; name: string; operations: string[] }, index: number) => {
        const operationArr: { name: string }[] = [];
        const obj: FieldConditionType = {
          id: 0,
          name: '',
          operations: [
            {
              name: ''
            }
          ]
        };
        condition?.operations?.map((operation, i) => {
          operationArr.push({ name: operation?.replace(/_/g, ' ').toLowerCase() });
          return null;
        });
        obj.id = condition.id;
        obj.name = condition.name;
        obj.operations = operationArr;
        fieldDropdownArr.push(obj);
        return null;
      });
      setFieldDropdown(fieldDropdownArr);
    }
  }, [couponConditionData]);

  const [rulesChanges, setRulesChanges] = useState<boolean>(false);
  const [fieldDropdown, setFieldDropdown] = useState<FieldConditionType[]>([]);
  const [state, setState] = useReducer((state: any, newState: any) => ({ ...state, ...newState }), {
    selectedProductAssignmentMethod: productAssignmentMethod.MANUAL
  });
  const [allConditionMatch, setAllConditionMatch] = useState<boolean>(false);
  const [rulesEngineConditions, setRulesEngineConditions] = useState<ConditionType[]>([
    {
      field: {
        id: 0,
        name: ''
      },
      condition: '',
      category: '',
      errorMessage: '',
      openFieldDropdown: false,
      openConditionDropdown: false,
      openCategoryDropdown: false
    }
  ]);

  const [conditionDropdownArray, setConditionDropdownArray] = useState<
    {
      categoryDropdown: {
        name: string;
        id: number;
      }[];
    }[]
  >([
    {
      categoryDropdown: []
    }
  ]);

  // save rules

  const saveModuleRules = () => {
    let isError = false;
    productAssignmentRules = [];

    if (state.selectedProductAssignmentMethod === productAssignmentMethod.AUTOMATIC) {
      rulesEngineConditions.forEach((element) => {
        const ruleObject = {} as any;
        switch (element.field.name) {
          case 'Product name':
            ruleObject.fact = 'productName';
            break;
          case 'Product type':
            ruleObject.fact = 'productType';
            break;
          case 'Vendor Name':
            ruleObject.fact = 'vendorName';
            break;
          case 'Product tag':
            ruleObject.fact = 'productTag';
            break;
          case 'Product price':
            ruleObject.fact = 'productPrice';
            break;
          case 'Product cost':
            ruleObject.fact = 'productCost';
            break;
          case 'Product stock':
            ruleObject.fact = 'inventoryStock';
            break;
          case 'Collection Name':
            ruleObject.fact = 'collection';
            break;
        }
        switch (element.condition) {
          case 'is equal to':
            ruleObject.operator = 'equal';
            break;
          case 'is not equal to':
            ruleObject.operator = 'notEqual';
            break;
          case 'is greater than':
            ruleObject.operator = 'greaterThan';
            break;
          case 'is less then':
            ruleObject.operator = 'lessThan';
            break;
          case 'contains':
            ruleObject.operator = 'stringContains';
            break;
          case 'does not contains':
            ruleObject.operator = 'stringNotContains';
            break;
        }
        ruleObject.value = element.category;

        if (typeof element.category == 'string' && element.category.trim().length === 0) {
          isError = true;
          element.errorMessage = `Field can't be empty`;
        } else {
          element.errorMessage = ``;
        }
        productAssignmentRules.push(ruleObject);
      });

      if (!rulesEngineConditions.length) {
        isError = true;
        showToast({
          errorText: 'Error',
          message: `Product condition not specified`
        });
      }
    }

    if (isError) {
      setFormChanges(false);
      setRulesChanges(false);
      return;
    }

    if (!isError) {
      // setDisableSaveButton(true);

      const input = {
        id: null,
        type: state.selectedProductAssignmentMethod,
        rulesMatch: allConditionMatch ? 'ALL' : 'ANY',
        productAssignmentRules: JSON.stringify(productAssignmentRules),
        productAssignmentRulesLayout: JSON.stringify(rulesEngineConditions)
        // id: couponId ? parseInt(couponId, 10) : null
      };

      getAllProductList({
        variables: {
          input
        }
      });
    }
  };

  const selectProductAssignmentMethod = (productAssignmentMethod) => {
    setState({
      selectedProductAssignmentMethod: productAssignmentMethod
    });
  };

  const [colApiCall, setColApiCall] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    callCouponConditions();
  }, []);

  // Rules engine specific

  useEffect(() => {
    setStatus({
      ...status,
      startTime: `${moment(new Date()).format('hh:mm')} ${moment(new Date()).format('a').toUpperCase()}`
    });
  }, []);

  const [getCouponData] = useLazyQuery(GET_COUPONS_LIST, {
    variables: {
      input: {
        pagination: { skipCount: 0, limitCount: 10 },
        id: parseInt(couponId, 10),
        sortBy: 'NAME',
        sortType: 'ASC'
      }
    },
    onCompleted: (couponsListData) => {
      if (couponsListData?.getAllCouponsList) {
        const allData: CouponsListData = couponsListData.getAllCouponsList.couponsList[0];
        document.title = `Coupons - ${allData.name ? allData.name : ''}`;

        if (allData?.productAssignmentRulesLayout) {
          const productAssignmentRulesLayout = JSON.parse(allData?.productAssignmentRulesLayout);
          const arr = [] as any;
          if (productAssignmentRulesLayout.length > 0) {
            setRulesEngineConditions(productAssignmentRulesLayout);
            productAssignmentRulesLayout.forEach((element, index) => {
              const obj = Object.assign({}, arr[index]) as any;
              obj.categoryDropdown = element.categoryOptions;
              arr[index] = obj as any;
              setConditionDropdownArray([...arr]);
            });
          }

          setState({
            selectedProductAssignmentMethod: allData?.type,
            formChanges: false
          });
        }

        if (allData?.productIds) {
          if (allData?.type === productAssignmentMethod.AUTOMATIC) {
            setProductListData((productlistData) => {
              return {
                ...productlistData,
                autoMaticProductListIds: allData?.productIds?.length > 0 ? allData?.productIds : []
              };
            });
          } else {
            setProductListData((productlistData) => {
              return {
                ...productlistData,
                manualProductListIds: allData?.productIds?.length > 0 ? allData?.productIds : []
              };
            });
          }
        }

        setGeneralInfo({
          ...generalInfo,
          value: allData.name ? allData.name : '',
          description: allData.description ? allData.description : 'allData.description',
          automaticDiscount: allData.isAutomaticApplied,
          externalProvider: allData?.externalDiscountProvider ? allData?.externalDiscountProvider : undefined
        });
        setCouponType({
          ...couponType,
          value: allData.couponType ? allData.couponType : undefined
        });
        setValue({
          ...value,
          optionSelected: allData.couponValue ? allData.couponValue : 'FLAT',
          amountFixed: allData.couponValue === 'FLAT' && allData.couponAmount ? allData.couponAmount : '',
          amountPercentage: allData.couponValue === 'PERCENTAGE' && allData.couponAmount ? allData.couponAmount : ''
        });
        setCustomerBuyGet({
          ...customerBuyGet,
          buyQuantity: allData.buyQuantity ? allData.buyQuantity : '',
          getQuantity: allData.getQuantity ? allData.getQuantity : ''
        });
        setUsageLimits({
          ...usageLimits,
          value: allData.maxCouponUsage ? allData.maxCouponUsage.toString() : '',
          selectedItems: allData.usageLimitType ? allData.usageLimitType : []
        });
        setCouponCollections({
          ...couponCollections,
          value: allData.collectionIds
        });

        setStatus({
          ...status,
          statusValue: allData.status,
          startDate: allData.couponStartTime ? new Date(allData.couponStartTime) : undefined,
          startTime: allData.couponStartTime
            ? `${moment(new Date(allData.couponStartTime)).format('hh:mm')} ${moment(new Date(allData.couponStartTime))
                .format('a')
                .toUpperCase()}`
            : undefined,
          endDate: allData.couponExpiryTime ? allData.couponExpiryTime : undefined,
          endTime: allData.couponExpiryTime
            ? `${moment(new Date(allData.couponExpiryTime)).format('hh:mm')} ${moment(new Date(allData.couponExpiryTime))
                .format('a')
                .toUpperCase()}`
            : undefined,
          isSetEndDate: allData.couponExpiryTime ? true : false
        });

        setCouponChannel({
          ...couponChannel,
          value: allData.couponChannels ? allData.couponChannels : []
        });

        if (allData.customerEligibility === 'SPECIFIC_CUSTOMERS' && allData?.couponUsers?.length > 0) {
          setCustomerEligibility({
            ...customerEligibility,
            value: allData.customerEligibility,
            customers: allData.couponUsers.map((customer) => {
              return {
                id: customer.id,
                name: customer.firstName + customer.lastName,
                email: customer.email,
                profilePicture: customer.profileUrl
              };
            })
          });
        }

        if (allData.couponType === 'FREE_SHIPPING' && allData.freeShippingAmountThreshold) {
          setCountries({
            ...countries,
            isShippingRates: true,
            shippingRateValue: allData.freeShippingAmountThreshold.toString()
          });
        }

        if (allData.customerEligibility === 'SPECIFIC_CUSTOMER_SEGMENTS' && allData?.customerSegment?.length > 0) {
          setCustomerEligibility({
            ...customerEligibility,
            value: allData.customerEligibility,
            segments: allData.customerSegment.map((segment) => {
              return {
                id: segment.id,
                name: segment.tag,
                color: segment.textColorCode,
                bgColor: segment.backgroundColorCode
              };
            })
          });
        }

        setMinRequirement({
          ...minRequirement,
          value: allData.minimumRequirement,
          minPurchaseAmount: allData.minimumRequirement === 'PURCHASE_AMOUNT' ? allData.minAmount.toString() : '',
          minQuantOfItems: allData.minimumRequirement === 'QAUANTITY_OF_ITEMS' ? allData.minQuantity.toString() : ''
        });
      }
      setIsCouponDetailAPIcalled(true);
    },
    fetchPolicy: 'cache-and-network',
    onError: (error) => {
      console.error('Coupon Details err-> ', error);
      setIsCouponDetailAPIcalled(true);
    }
  });
  useEffect(() => {
    if (couponId) {
      getCouponData();
    } else {
      setIsCouponDetailAPIcalled(true);
      document.title = 'Coupons - New';
    }

    getCountryList();
  }, []);

  const getCountryList = () => {
    const getAllCountries = WorldData.getAllCountries();
    if (getAllCountries) {
      const tempCountryList = getAllCountries.map((data) => {
        return { id: data.id, name: data.name };
      });
      setCountryList(tempCountryList);
    }
  };

  const [addCoupon] = useMutation(ADD_COUPON, {
    onCompleted: () => {
      setFormChanges(false);
      showToast({ successText: 'Coupon added successfully', message: `The Coupon has been added sucessfully` });
      setDataChanged(false);
      setDisableSaveButton(false);
    },
    onError: (error) => {
      setFormChanges(false);
      setDisableSaveButton(false);
      showToast({ errorText: 'Error', message: `The Coupon was not added sucessfully` });
    }
  });

  const [editCoupon] = useMutation(EDIT_COUPON, {
    onCompleted: () => {
      setFormChanges(false);
      showToast({ successText: 'Coupon updated successfully', message: `The Coupon has been updated sucessfully` });
      setDataChanged(false);
      setDisableSaveButton(false);
    },
    onError: (error) => {
      setFormChanges(false);
      setDisableSaveButton(false);
      showToast({ errorText: 'Error', message: `The Coupon was not added sucessfully` });
    }
  });

  const [getAllProductList] = useLazyQuery(GET_COUPON_MATCHING_PPRODUCT_LIST, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      if (response) {
        const productIds: any = [];
        if (response?.getAllProductList?.productIds && response?.getAllProductList?.productIds.length > 0) {
          response?.getAllProductList?.productIds.forEach(async (productId) => {
            productIds.push(productId.id);
          });
        }
        setProductListData((productListData) => {
          return { ...productListData, autoMaticProductListIds: productIds?.length > 0 ? productIds : [] };
        });

        setFormChanges(true);
        setRulesChanges(false);
      }
    }
  });

  const closeDiscardModal = () => {
    document.body.style.overflow = 'unset';
    setIsDiscardModal(false);
  };
  const closeLeaveModal = () => {
    document.body.style.overflow = 'unset';
    setIsLeaveModal(false);
  };
  const openDiscardModal = () => {
    setIsDiscardModal(true);
  };
  const discardAllChanges = () => {
    document.body.style.overflow = 'unset';
    setTimeout(() => {
      history.push(ROUTES.coupons.allCoupons.main);
    }, 100);

    setIsDiscardModal(false);
    setIsLeaveModal(false);
    setDataChanged(false);
  };

  const toggleFileStack = () => {
    setOpenLoader(!openLoader);
  };
  const handleSendSuccessURL = (res: any) => null;
  const goBackToCouponListScreen = () => {
    setTargettedPath(ROUTES.coupons.allCoupons.main);
    if (formChanges) {
      setIsLeaveModal(true);
    } else {
      history.push(ROUTES.coupons.allCoupons.main);
    }
  };

  const validateInput = (value: string, fieldName: string) => {
    const regex = /^[A-Za-z0-9]*$/;
    const inputValue = value[0] === '$' ? value.substring(1)?.trim() : value;
    if (inputValue === '') {
      setErrorMessages((error) => ({
        ...error,
        [fieldName]: 'Required field'
      }));
      return false;
    } else if (fieldName === 'generalInfoTitleError' && !regex.test(inputValue)) {
      setErrorMessages((error) => ({
        ...error,
        [fieldName]: 'Please enter valid coupon code.'
      }));
      return false;
    } else {
      setErrorMessages((error) => ({
        ...error,
        [fieldName]: ''
      }));
      return true;
    }
  };

  const createCouponCode = () => {
    // code for creating coupon code
    let formattedStartTime: Date | null = null;
    let startTime: string[];
    if (status.startDate && status.startTime) {
      formattedStartTime = new Date(status.startDate);
      startTime = status.startTime.slice(0, -2).split(':');
      formattedStartTime.setHours(
        // eslint-disable-next-line no-nested-ternary
        status.startTime.includes('PM')
          ? Number(startTime[0]) !== 12
            ? Number(startTime[0]) + 12
            : Number(startTime[0])
          : Number(startTime[0]) === 12
          ? 0
          : Number(startTime[0])
      );
      formattedStartTime.setMinutes(Number(startTime[1]));
    }
    let formattedEndTime: Date | null = null;
    let endTime: string[];
    if (status.endDate && status.endTime) {
      formattedEndTime = new Date(status.endDate);
      endTime = status.endTime.slice(0, -2).split(':');
      formattedEndTime.setHours(
        // eslint-disable-next-line no-nested-ternary
        status.endTime.includes('PM')
          ? Number(endTime[0]) !== 12
            ? Number(endTime[0]) + 12
            : Number(endTime[0])
          : Number(endTime[0]) === 12
          ? 0
          : Number(endTime[0])
      );
      formattedEndTime.setMinutes(Number(endTime[1]));
    }

    // Validation for the coupon
    const requestData = [
      { value: generalInfo.value, key: 'generalInfoTitleError' },
      { value: value.amountFixed, key: 'fixedAmountError' },
      { value: value.amountPercentage, key: 'percentageAmountError' },
      { value: minRequirement.minPurchaseAmount, key: 'minPurchaseAmountError' },
      { value: minRequirement.minQuantOfItems, key: 'minQuantityAmountError' },
      { value: usageLimits.value, key: 'usageLimitsError' },
      { value: countries.shippingRateValue, key: 'freeShippingAmountThresholdError' }
    ];

    let filteredRequestData = requestData;
    if (value.optionSelected === 'FLAT') {
      filteredRequestData = filteredRequestData.filter((data) => data.key !== 'percentageAmountError');
    }
    if (value.optionSelected === 'PERCENTAGE') {
      filteredRequestData = filteredRequestData.filter((data) => data.key !== 'fixedAmountError');
    }
    if (minRequirement.value === 'NONE') {
      filteredRequestData = filteredRequestData.filter(
        (data) => data.key !== 'minPurchaseAmountError' && data.key !== 'minQuantityAmountError'
      );
    }
    if (minRequirement.value === 'PURCHASE_AMOUNT') {
      filteredRequestData = filteredRequestData.filter((data) => data.key !== 'minQuantityAmountError');
    }
    if (minRequirement.value === 'QAUANTITY_OF_ITEMS') {
      filteredRequestData = filteredRequestData.filter((data) => data.key !== 'minPurchaseAmountError');
    }
    if (!usageLimits.selectedItems.includes('MAX_TIMES_USED')) {
      filteredRequestData = filteredRequestData.filter((data) => data.key !== 'usageLimitsError');
    }
    if (couponType.value !== 'FREE_SHIPPING') {
      filteredRequestData = filteredRequestData.filter((data) => data.key !== 'freeShippingAmountThresholdError');
    }
    if (couponType.value === 'FREE_SHIPPING' && countries.shippingRateValue === '') {
      setErrorMessages((errorMessages) => ({
        ...errorMessages,
        freeShippingAmountThresholdError: 'This field can’t be left empty.'
      }));
      return;
    }
    if (couponType.value === 'FREE_SHIPPING') {
      filteredRequestData = filteredRequestData.filter(
        (data) => data.key !== 'percentageAmountError' && data.key !== 'fixedAmountError'
      );
    }
    if (customerEligibility.value === 'SPECIFIC_CUSTOMERS') {
      if (customerEligibility?.customers?.length === 0) {
        setErrorMessages((errorMessages) => ({
          ...errorMessages,
          specifiCustomersError: 'Customers list can’t be left empty.'
        }));
        return;
      }
    }
    if (customerEligibility.value === 'SPECIFIC_CUSTOMER_SEGMENTS') {
      if (customerEligibility?.segments?.length === 0) {
        setErrorMessages((errorMessages) => ({
          ...errorMessages,
          specifiCustomersError: 'This field can’t be left empty.'
        }));
        return;
      }
    }
    const isError =
      filteredRequestData.map((item) => validateInput(item.value, item.key)).filter((v) => v === false).length >= 1 ? true : false;

    if (isError) {
      return;
    }

    if (
      couponTimesError?.isStartTimeError ||
      couponTimesError?.startTimeErrorMessage !== '' ||
      couponTimesError?.isEndTimeError ||
      couponTimesError?.endTimeErrorMessage !== ''
    ) {
      return;
    }

    if (couponId) {
      setDisableSaveButton(true);
      editCoupon({
        variables: {
          input: {
            id: parseInt(couponId, 10),
            name: generalInfo.value,
            description: generalInfo.description,
            couponType: couponType.value,
            couponValue: value.optionSelected,
            couponChannels: couponChannel.value,
            couponAmount:
              value.optionSelected === 'PERCENTAGE' ? parseFloat(value.amountPercentage) : parseFloat(value.amountFixed),
            buyQuantity: customerBuyGet.buyQuantity ? parseInt(customerBuyGet.buyQuantity, 10) : undefined,
            getQuantity: customerBuyGet.getQuantity ? parseInt(customerBuyGet.getQuantity, 10) : undefined,
            collectionIds: couponCollections.value,
            usageLimitType: usageLimits.selectedItems?.length > 0 ? usageLimits.selectedItems : undefined,
            maxCouponUsage:
              usageLimits.selectedItems.includes('MAX_TIMES_USED') && usageLimits.value
                ? parseInt(usageLimits.value, 10)
                : undefined,
            minimumRequirement: minRequirement.value,
            minAmount: minRequirement.value === 'PURCHASE_AMOUNT' ? parseFloat(minRequirement.minPurchaseAmount) : undefined,
            minQuantity: minRequirement.value === 'QAUANTITY_OF_ITEMS' ? parseInt(minRequirement.minQuantOfItems, 10) : undefined,
            isAutomaticApplied: generalInfo.automaticDiscount,
            customerEligibility: customerEligibility.value,
            customerIds:
              customerEligibility.value === 'SPECIFIC_CUSTOMERS'
                ? customerEligibility.customers.map((customer) => customer.id)
                : [],
            customerSegmentIds:
              customerEligibility.value === 'SPECIFIC_CUSTOMER_SEGMENTS'
                ? customerEligibility.segments.map((segment) => segment.id)
                : [],
            couponStartTime: formattedStartTime && status.statusValue === 'ACTIVE' ? formattedStartTime.toISOString() : undefined,
            couponExpiryTime:
              formattedEndTime && status.isSetEndDate && status.statusValue === 'ACTIVE'
                ? formattedEndTime.toISOString()
                : undefined,
            type: state.selectedProductAssignmentMethod,
            rulesMatch: allConditionMatch ? 'ALL' : 'ANY',
            productAssignmentRules:
              state.selectedProductAssignmentMethod !== 'MANUAL' ? JSON.stringify(productAssignmentRules) : JSON.stringify([]),
            productAssignmentRulesLayout:
              state.selectedProductAssignmentMethod !== 'MANUAL' ? JSON.stringify(rulesEngineConditions) : JSON.stringify([]),
            productIds:
              state.selectedProductAssignmentMethod !== 'MANUAL'
                ? productListData.autoMaticProductListIds
                : productListData.manualProductListIds,
            freeshippingAmountThreshold: countries?.shippingRateValue ? parseInt(countries?.shippingRateValue, 10) : undefined,
            couponStatus: status.statusValue
          }
        }
      });
    } else {
      setDisableSaveButton(true);
      addCoupon({
        variables: {
          input: {
            name: generalInfo.value,
            description: generalInfo.description,
            couponType: couponType.value,
            couponValue: value.optionSelected,
            couponChannels: couponChannel.value,
            couponAmount:
              value.optionSelected === 'PERCENTAGE' ? parseFloat(value.amountPercentage) : parseFloat(value.amountFixed),
            buyQuantity: customerBuyGet.buyQuantity ? parseInt(customerBuyGet.buyQuantity, 10) : undefined,
            getQuantity: customerBuyGet.getQuantity ? parseInt(customerBuyGet.getQuantity, 10) : undefined,
            collectionIds: couponCollections.value,
            usageLimitType: usageLimits.selectedItems?.length > 0 ? usageLimits.selectedItems : undefined,
            maxCouponUsage:
              usageLimits.selectedItems.includes('MAX_TIMES_USED') && usageLimits.value
                ? parseInt(usageLimits.value, 10)
                : undefined,
            isAutomaticApplied: generalInfo.automaticDiscount,
            customerEligibility: customerEligibility.value,
            customerIds:
              customerEligibility.value === 'SPECIFIC_CUSTOMERS'
                ? customerEligibility.customers.map((customer) => customer.id)
                : undefined,
            customerSegmentIds:
              customerEligibility.value === 'SPECIFIC_CUSTOMER_SEGMENTS'
                ? customerEligibility.segments.map((segment) => segment.id)
                : undefined,
            minimumRequirement: minRequirement.value,
            minAmount: minRequirement.value === 'PURCHASE_AMOUNT' ? minRequirement.minPurchaseAmount : undefined,
            minQuantity: minRequirement.value === 'QAUANTITY_OF_ITEMS' ? minRequirement.minQuantOfItems : undefined,
            couponStartTime: formattedStartTime && status.statusValue === 'ACTIVE' ? formattedStartTime.toISOString() : undefined,
            couponExpiryTime:
              formattedEndTime && status.isSetEndDate && status.statusValue === 'ACTIVE'
                ? formattedEndTime.toISOString()
                : undefined,
            type: state.selectedProductAssignmentMethod,
            rulesMatch: allConditionMatch ? 'ALL' : 'ANY',
            productAssignmentRules:
              state.selectedProductAssignmentMethod !== 'MANUAL' ? JSON.stringify(productAssignmentRules) : JSON.stringify([]),
            productAssignmentRulesLayout:
              state.selectedProductAssignmentMethod !== 'MANUAL' ? JSON.stringify(rulesEngineConditions) : JSON.stringify([]),
            productIds:
              state.selectedProductAssignmentMethod !== 'MANUAL'
                ? productListData.autoMaticProductListIds
                : productListData.manualProductListIds,
            freeshippingAmountThreshold: countries?.shippingRateValue ? parseInt(countries?.shippingRateValue, 10) : undefined,
            couponStatus: status.statusValue
          }
        }
      });
    }
  };
  const leavePageHandler = () => {
    document.body.style.overflow = 'unset';
    setDataChanged(false);
    // NOTE: Push to targetted path or products, handled with timeout for now.
    setTimeout(() => {
      history.push(targettedPath ? targettedPath : '/products/allProducts');
    }, 100);
  };

  useEffect(() => {
    if (isCouponDetailAPIcalled) {
      setFormChanges(true);
      setDataChanged(true);
    } else {
      setFormChanges(false);
      setDataChanged(false);
    }
  }, [
    generalInfo.value,
    generalInfo.automaticDiscount,
    couponType.value,
    value.amountPercentage,
    value.isManual,
    value.isAutomatic,
    value.optionSelected,
    value.amountFixed,
    couponChannel.value,
    customerBuyGet.isMinPurchase,
    customerBuyGet.isMinQuantity,
    customerBuyGet.buyQuantity,
    customerBuyGet.buyItem,
    customerBuyGet.getQuantity,
    customerBuyGet.getItem,
    usageLimits.value,
    usageLimits.selectedItems,
    status.statusValue,
    // status.startDate,
    // status.startTime,
    // status.endDate,
    // status.endTime,
    status.isSetEndDate,
    couponCollections.value,
    minRequirement.value,
    minRequirement.minPurchaseAmount,
    minRequirement.minQuantOfItems,
    customerEligibility.value,
    customerEligibility.customers,
    customerEligibility.segments,
    countries.shippingRateValue
  ]);

  useEffect(() => {
    const preventRoute = history.block((e: any) => {
      setTargettedPath(e.pathname);
      if (isDataChanged) {
        setIsLeaveModal(true);
        return false;
      } else {
        return true;
      }
    });
    return () => {
      preventRoute();
    };
  }, [history, isDataChanged]);

  return (
    <>
      {formChanges ? (
        <SaveHeader
          onSave={createCouponCode}
          // saveDisabled={generalInfo.value && couponType.value ? false : true}
          saveDisabled={disableSaveButton}
          onDiscard={openDiscardModal}
        />
      ) : null}
      {isDiscardModal && (
        <CustomModal bodyClassname="w-90 w-md-50" show={isDiscardModal} closeModal={closeDiscardModal}>
          <DiscardModal
            actionBtnTitle={constVariables.common.discardForm.action}
            cancelBtnTitle={constVariables.common.discardForm.cancel}
            message={constVariables.common.discardForm.message}
            title={constVariables.common.discardForm.title}
            actionBtnHandler={discardAllChanges}
            cancelBtnHandler={closeDiscardModal}
          />
        </CustomModal>
      )}
      {isLeaveModal && (
        <CustomModal show={isLeaveModal} bodyClassname="w-90 w-md-50" closeModal={closeLeaveModal}>
          <DiscardModal
            actionBtnTitle={constVariables.common.LeaveForm.action}
            cancelBtnTitle={constVariables.common.LeaveForm.cancel}
            message={constVariables.common.LeaveForm.message}
            title={constVariables.common.LeaveForm.title}
            actionBtnHandler={leavePageHandler}
            cancelBtnHandler={closeLeaveModal}
            deleteProductMedia
          />
        </CustomModal>
      )}
      <div className="details-page">
        <div className={`details-page-container ${formChanges ? 'pt-0' : ''}`}>
          <div className="width-animation w-100 hide-scrollbar">
            <CreateCouponPresentational
              couponId={couponId}
              formChanges={formChanges}
              customerEligibility={customerEligibility}
              setCustomerEligibility={setCustomerEligibility}
              minRequirement={minRequirement}
              setMinRequirement={setMinRequirement}
              goBackToCouponListScreen={goBackToCouponListScreen}
              setProductListData={setProductListData}
              productListData={productListData}
              setGeneralInfo={setGeneralInfo}
              setCouponType={setCouponType}
              couponType={couponType}
              generalInfo={generalInfo}
              setUsageLimits={setUsageLimits}
              usageLimits={usageLimits}
              setCouponChannel={setCouponChannel}
              couponChannel={couponChannel}
              summary={summary}
              status={status}
              setStatus={setStatus}
              setValue={setValue}
              value={value}
              valueType={couponType}
              setCustomerBuyGet={setCustomerBuyGet}
              customerBuyGet={customerBuyGet}
              setCountries={setCountries}
              countries={countries}
              couponCollections={couponCollections}
              setCouponCollections={setCouponCollections}
              errorMessages={errorMessages}
              setErrorMessages={setErrorMessages}
              countryList={countryList}
              validateInput={validateInput}
              couponTimesError={couponTimesError}
              setCouponTimesError={setCouponTimesError}
              // Rules engine
              selectedProductAssignmentMethod={state.selectedProductAssignmentMethod}
              selectProductAssignmentMethod={selectProductAssignmentMethod}
              rulesEngineConditions={rulesEngineConditions}
              setRulesEngineConditions={setRulesEngineConditions}
              fieldDropdown={fieldDropdown}
              setAllConditionMatch={setAllConditionMatch}
              allConditionMatch={allConditionMatch}
              conditionDropdownArray={conditionDropdownArray}
              setConditionDropdownArray={setConditionDropdownArray}
              setFormChanges={setFormChanges}
              setRulesChanges={setRulesChanges}
              rulesChanges={rulesChanges}
              saveModuleRules={saveModuleRules}
              setColApiCall={setColApiCall}
              // Rules engine
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCoupon;
