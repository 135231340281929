import { FC, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { InputBox, Dropdown } from 'src/components/molecules';
import { DropdownSelector } from 'src/components/atoms';

// ConstVariables
import { constVariables } from 'src/constants';
import { errorTextGroups } from '../ShopDetails';

// Types
import { DetailsProps } from '../ShopDetails.types';

const measurementList = [
  { id: 1, name: 'in' },
  { id: 2, name: 'ft' }
  // { id: 3, name: 'm' },
  // { id: 4, name: 'cm' },
  // { id: 5, name: 'mm' },
];

const weightUnits = [
  { id: 1, name: 'oz' },
  { id: 2, name: 'lb' }
  // { id: 3, name: 'kg' },
  // { id: 4, name: 'g' }
];

const StandardFormatDetails: FC<DetailsProps> = ({ storeDetailsData, errorMessages, handleUpdateStoreDetails }) => {
  const [showMeasureDropdown, setShowMeasureDropdown] = useState(false);
  const [showWeightDropdown, setShowWeightDropdown] = useState(false);

  return (
    <div className="card">
      <h4 className="section-title m-b-32">{constVariables.shopDetails.standard.key}</h4>
      <div className="row mb-6 gx-3">
        <div className="col-md-6">
          <InputBox
            title={constVariables.shopDetails.standard.currency}
            value={storeDetailsData.storeCurrency}
            name="storeCurrency"
            inputClass="form-control-lg"
            disable
            readOnly
          />
        </div>
      </div>
      <div className="row gx-3">
        <div className="col-md-6">
          <div className="input-title">{constVariables.shopDetails.standard.measurement}</div>
          <div className="position-relative">
            <DropdownSelector
              className={`form-control form-control-lg  px-5 justify-content-between align-items-center cursor-pointer dropdown-box ${
                showMeasureDropdown ? 'dropdown-box-active' : ''
              }`}
              onClick={() => setShowMeasureDropdown(!showMeasureDropdown)}
              selectedValue={storeDetailsData?.unitOfMeasurement}
              text="Select"
            />
            <OutsideClickHandler onOutsideClick={() => setShowMeasureDropdown(false)}>
              <Dropdown
                data={measurementList}
                selected={showMeasureDropdown}
                value={storeDetailsData.unitOfMeasurement}
                onSelect={(value) => {
                  handleUpdateStoreDetails('unitOfMeasurement', value.name, errorTextGroups.unitOfMeasurement);
                  setShowMeasureDropdown(false);
                }}
                closeDropdown={() => setShowMeasureDropdown(false)}
              />
            </OutsideClickHandler>
          </div>
          {errorMessages.unitOfMeasurement && <div className="warning-text p-0 fs-7">{errorMessages.unitOfMeasurement}</div>}
        </div>
        <div className="col-md-6">
          <div className="input-title">{constVariables.shopDetails.standard.weight}</div>
          <div className="position-relative">
            <DropdownSelector
              className={`form-control form-control-lg  px-5 justify-content-between align-items-center cursor-pointer dropdown-box ${
                showWeightDropdown ? 'dropdown-box-active' : ''
              }`}
              onClick={() => setShowWeightDropdown(!showWeightDropdown)}
              selectedValue={storeDetailsData?.weightUnit}
              text="Select"
            />
            <OutsideClickHandler onOutsideClick={() => setShowWeightDropdown(false)}>
              <Dropdown
                data={weightUnits}
                selected={showWeightDropdown}
                value={storeDetailsData.weightUnit}
                onSelect={(value) => {
                  handleUpdateStoreDetails('weightUnit', value.name, errorTextGroups.weightUnit);
                  setShowWeightDropdown(false);
                }}
                closeDropdown={() => setShowWeightDropdown(false)}
              />
            </OutsideClickHandler>
          </div>
          {errorMessages.weightUnit && <div className="warning-text p-0 fs-7">{errorMessages.weightUnit}</div>}
        </div>
      </div>
    </div>
  );
};

export default StandardFormatDetails;
