import React, { FC, useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { FlexBox } from 'src/components/atoms';
import { Dropdown, Portal } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers/components/KTSVG';

// Icons
import { DownArrowIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { RoleItem, UserRoleProps } from './UserPermissions.type';

const UserRole: FC<UserRoleProps> = ({ role, roles, userId, runChangeUserRole }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const statusRef = React.createRef<any>();
  const [dropdownPositions, setDropdownPositions] = useState({
    top: 0,
    left: 0
  });
  const [selectedRole, setSelectedRole] = useState<RoleItem>();

  useEffect(() => {
    if (roles && role) {
      if (role === 'ROLE_OWNER') {
        setSelectedRole({
          id: 1,
          canBeNotifiedWhenOrderPlaced: true,
          role: 'ROLE_OWNER',
          roleDisplayName: 'Owner',
          permissions: [
            '{section=HOME_DASHBOARD, permissions=[FULL]}',
            '{section=LIVESHOW, permissions=[FULL]}',
            '{section=VENDORS_MANAGEMENT, permissions=[FULL]}',
            '{section=PRODUCTS, permissions=[FULL]}',
            '{section=ORDERS, permissions=[FULL]}',
            '{section=COUPONS, permissions=[FULL]}',
            '{section=CUSTOMERS, permissions=[FULL]}',
            '{section=MARKETING, permissions=[FULL]}'
          ]
        });
      } else {
        setSelectedRole(roles.find((roleOption) => roleOption.role === role));
      }
    }
  }, [roles, role]);

  const selectUserRoleClass = (userRole: string | undefined) => {
    switch (userRole) {
      case constVariables.userRoles[0].name.toLowerCase():
        return 'owner';
      case constVariables.userRoles[1].name.toLowerCase():
        return 'admin';
      case constVariables.userRoles[2].name.toLowerCase():
        return 'admin-associate';
      case constVariables.userRoles[3].name.toLowerCase():
        return 'warehouse-manager';
      case constVariables.userRoles[4].name.toLowerCase():
        return 'fulfilment-associate';
      default:
        return 'warehouse-associate';
    }
  };

  const handleStatus = () => {
    setShowDropdown(true);

    if (statusRef.current) {
      const position = statusRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - position.bottom;
      setDropdownPositions({
        top: spaceBelow < 180 ? window.scrollY + position.top - 220 : window.scrollY + position.top - 20,
        left: position.left
      });
    }
  };

  const handleUserRole = (value) => {
    runChangeUserRole(value.id, value.name, userId);
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (showDropdown) setShowDropdown(false);
      }}
    >
      <FlexBox
        className={`poppins-medium justify-content-between ${role !== 'ROLE_OWNER' ? 'cursor-pointer' : ''}`}
        onClick={handleStatus}
      >
        <div
          className={`d-flex justify-content-between user-role-status ${
            role === 'ROLE_OWNER' ? 'no-hover-effect' : ''
          } ${selectUserRoleClass(selectedRole?.roleDisplayName?.toLowerCase())}`}
          ref={statusRef}
        >
          <span className="me-2">{selectedRole?.roleDisplayName}</span>
          <KTSVG
            path={DownArrowIcon}
            className={`m-auto-0 status-arrow ${selectUserRoleClass(selectedRole?.roleDisplayName?.toLocaleLowerCase())}-arrow`}
          />
        </div>
      </FlexBox>
      {role !== 'ROLE_OWNER' && showDropdown && (
        <Portal id="kt_body">
          <div className="position-absolute" style={{ top: dropdownPositions.top, left: dropdownPositions.left }}>
            <Dropdown
              data={roles?.map((role) => {
                return { id: role?.id, name: role?.roleDisplayName };
              })}
              selected={showDropdown}
              value={selectedRole?.roleDisplayName}
              className="user-roles-dropdown"
              onSelect={(value) => handleUserRole(value)}
              closeDropdown={() => setShowDropdown(false)}
            />
          </div>
        </Portal>
      )}
    </OutsideClickHandler>
  );
};

export default UserRole;
