import { gql } from '@apollo/client';

export const ADD_PRODUCT_TYPE = gql`
  mutation AddProductType($input: AddProductTypeInput!) @api(name: "productsAPI") {
    addProductType(input: $input) {
      id
      name
      success
      message
    }
  }
`;
