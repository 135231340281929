import { gql } from '@apollo/client';

export const GET_VENDORS = gql`
  query GetAllVendors($input: GetVendorInput!) @api(name: "productsAPI") {
    getVendorDetails(input: $input) {
      __typename
      ... on VendorResponse {
        vendors {
          id
          name
          address
          state
          status
          isShopPayForShipping
          accountNumber
          country
          websiteUrl
          profileUrl
          categoriesSet {
            id
            category {
              id
              name
            }
            subCategory {
              id
              name
              categoryId
            }
          }
          vendorRep {
            id
            title
            name
            email
            phoneNo
          }
          extraInfo {
            id
            type
            title
            name
          }
          totalSpending
        }
        totalVendors
      }
    }
  }
`;
