import { gql } from '@apollo/client';

export const CREATE_PRODUCT_SUB_CATEGORIES = gql`
  mutation onCreateProductSubCategories($input: CreateProductSubCategoryInput!) @api(name: "productsAPI") {
    createProductSubCategory(input: $input) {
      id
      name
    }
  }
`;
