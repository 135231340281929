import { FC } from 'react';

// Components
import CreateLiveShowNotificationsPresentational from './CreateLiveShowNotifications.presentational';

// Types
import { LiveShowDetailInfo, LiveShowInfoError } from '../CreateLiveShowDetailStep/CreateLiveShowDetailStep.types';

export interface CreateLiveShowNotificationsProps {
  liveShowDetailInfo: LiveShowDetailInfo;
  liveShowInfoError: LiveShowInfoError;
  updateLiveShowInfo(liveShowInfo: LiveShowDetailInfo): void;
  textMessageToggle: boolean;
  setTextMessageToggle: (data: any) => void;
  appNotificationToggle: boolean;
  setAppNotificationToggle: (data: any) => void;
  messangerToggle: boolean;
  setMessangerToggle: (data: any) => void;
}

const CreateLiveShowNotifications: FC<CreateLiveShowNotificationsProps> = ({
  liveShowDetailInfo,
  liveShowInfoError,
  updateLiveShowInfo,
  textMessageToggle,
  setTextMessageToggle,
  appNotificationToggle,
  setAppNotificationToggle,
  messangerToggle,
  setMessangerToggle
}) => {
  return (
    <CreateLiveShowNotificationsPresentational
      liveShowDetailInfo={liveShowDetailInfo}
      liveShowInfoError={liveShowInfoError}
      updateLiveShowInfo={updateLiveShowInfo}
      textMessageToggle={textMessageToggle}
      setTextMessageToggle={setTextMessageToggle}
      appNotificationToggle={appNotificationToggle}
      setAppNotificationToggle={setAppNotificationToggle}
      messangerToggle={messangerToggle}
      setMessangerToggle={setMessangerToggle}
    />
  );
};

export default CreateLiveShowNotifications;
