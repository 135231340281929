import { FC } from 'react';

// Components
import { RadioSelector } from 'src/components/molecules';

// ConstVariables
import { constVariables } from 'src/constants/constVariables';

// Types
import { CouponTypeSection, CouponTypes } from 'src/components/pages/coupons/CreateCoupon/CreateCoupon.types';

const CouponTypeCreateCoupon: FC<CouponTypeSection> = ({ couponType, setCouponType }) => {
  return (
    <div className="card w-100">
      <div className="section-title m-b-32">{constVariables.Coupons.AddCoupon.type.couponType}</div>
      {Object.keys(CouponTypes).map((typeItem, index) => {
        return (
          <RadioSelector
            key={index}
            name={CouponTypes[typeItem].title}
            isCheck={couponType.value === CouponTypes[typeItem].key}
            changeCheck={() => {
              setCouponType({ value: CouponTypes[typeItem].key });
            }}
            noMarginBottom={index === Object.keys(CouponTypes).length - 1}
          />
        );
      })}
    </div>
  );
};

export default CouponTypeCreateCoupon;
