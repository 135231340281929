export const SortIds = [
  {
    id: 0,
    label: 'Vendor name',
    name: 'NAME'
  },
  {
    id: 1,
    label: 'Category',
    name: 'CATEGORY'
  },
  {
    id: 2,
    label: 'Sub category',
    name: 'SUB_CATEGORY'
  },
  {
    id: 3,
    label: 'Total spending',
    name: 'TOTAL_SPENDING'
  }
];
