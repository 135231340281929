import { gql } from '@apollo/client';

export const TOGGLE_RAPID_LIST = gql`
  mutation onSetStoreConfig($input: StoreConfig!) @api(name: "authAPI") {
    setStoreConfig(input: $input) {
      success
      message
    }
  }
`;
