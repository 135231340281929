import { FC, useState, useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import clsx from 'clsx';

// Apollo
import { useQuery, useMutation } from '@apollo/client';
import { GET_TIKTOK_PRODUCTS, GET_TIKTOK_PRODUCTS_STATUS_COUNTS } from 'src/apollo/queries';
import { ADD_PRODUCT_TO_TIKTOK, CHANGE_PRODUCT_TIKTOK_CATEGORY, PUSH_PRODUCT_TO_TIKTOK } from 'src/apollo/mutations';

// Components
import { FlexBox, Img } from 'src/components/atoms';
import {
  CustomModal,
  AddingProductsModal,
  EmptyList,
  TiktokResponseModal,
  ConfirmPushTiktokModal
} from 'src/components/oraganisms';
import { Tabs, SearchBar, PLInventory, DataTable, Pagination, Tooltip, Portal } from 'src/components/molecules';
import CategoryCell from './components/CategoryCell';
import StatusCell from './components/StatusCell';
import ActionMenu from './components/ActionMenu';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';
import { useToast } from 'src/utils/hooks/useToast';

// Icons
import { InfoIcon, DefaultImage, EmptyDataIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables, ROUTES } from 'src/constants';

// Types
import { TikTokProduct, tiktokInputType, TIKTOK_PRODUCT_STATUS } from './TiktokIntegration.types';

// Styles
import './_tiktokIntegration.scss';

const TiktokIntegration: FC = () => {
  const history = useHistory();
  const { showToast } = useToast();
  const [selectedTab, setSelectedTab] = useState('ACTIVE');
  const [statusCountList, setStatusCountList] = useState<any>();
  const [tiktokProducts, setTiktokProducts] = useState<TikTokProduct[]>([]);
  const [selectedProductIDs, setSelectedProductIDs] = useState<number[]>([]);
  const [totalCounts, setTotalCounts] = useState(0);
  const [isShowAddingProductsModal, setIsShowAddingProductsModal] = useState(false);
  const [showTiktokResponseModal, setShowTiktokResponseModal] = useState(false);
  const [responsedProduct, setResponsedProduct] = useState<TikTokProduct>();
  const [pushTiktokConfirmModal, setPushTiktokConfirmModal] = useState(false);

  const [tiktokInput, setTiktokInput] = useState<tiktokInputType>({
    status: ['ACTIVE'],
    searchString: '',
    pageInfo: {
      skipCount: 0,
      limitCount: 50
    }
  });

  useEffect(() => {
    let syncStatus;
    if (selectedTab === 'IN REVIEW') {
      syncStatus = ['IN_REVIEW', 'QUEUED'];
    } else if (selectedTab === 'DRAFT') {
      syncStatus = ['PROCESSING', 'DRAFT', 'UPDATES_REQUIRED'];
    } else if (selectedTab === 'ALL PRODUCTS') {
      syncStatus = [];
    } else {
      syncStatus = [selectedTab];
    }

    setTiktokInput({
      ...tiktokInput,
      status: syncStatus,
      pageInfo: {
        ...tiktokInput.pageInfo,
        skipCount: 0
      }
    });
  }, [selectedTab]);

  useEffect(() => {
    setTiktokInput({
      ...tiktokInput,
      pageInfo: {
        ...tiktokInput.pageInfo,
        skipCount: 0
      }
    });
  }, [tiktokInput.searchString]);

  const { refetch: refecthStatusCounts } = useQuery(GET_TIKTOK_PRODUCTS_STATUS_COUNTS, {
    onCompleted: (res) => {
      if (res?.getTiktokProductStatusCounts) {
        const statusCounts = res.getTiktokProductStatusCounts;
        setStatusCountList({
          [constVariables.TabForTikTokIntegration[0]]: statusCounts.totalCount ? statusCounts.totalCount : 0,
          [constVariables.TabForTikTokIntegration[1]]: statusCounts.activeCount ? statusCounts.activeCount : 0,
          [constVariables.TabForTikTokIntegration[2]]: statusCounts.inactiveCount ? statusCounts.inactiveCount : 0,
          [constVariables.TabForTikTokIntegration[3]]: statusCounts.inReviewCount ? statusCounts.inReviewCount : 0,
          [constVariables.TabForTikTokIntegration[4]]: statusCounts.draftCount ? statusCounts.draftCount : 0,
          [constVariables.TabForTikTokIntegration[5]]: statusCounts.failedCount ? statusCounts.failedCount : 0,
          [constVariables.TabForTikTokIntegration[6]]: statusCounts.suspendedCount ? statusCounts.suspendedCount : 0
        });
      }
    },
    onError: (error) => {
      console.log('error::', error);
    }
  });

  const { loading: isProductsLoading, refetch: refetchTiktokProducts } = useQuery(GET_TIKTOK_PRODUCTS, {
    variables: {
      input: tiktokInput
    },
    onCompleted: (res) => {
      if (res?.getTikTokProducts) {
        setTotalCounts(res?.getTikTokProducts?.totalProducts);
        setTiktokProducts(res?.getTikTokProducts?.products);
      }
    },
    onError: (error) => {
      console.log('error::', error);
    }
  });

  const [addProductToTiktok, { loading: isAddingProduct }] = useMutation(ADD_PRODUCT_TO_TIKTOK, {
    onError: (error) => {
      console.log('error::', error);
      handleCloseAddingProductsModal();
    }
  });

  const [changeTiktokProductCategory] = useMutation(CHANGE_PRODUCT_TIKTOK_CATEGORY, {
    onError: (error) => {
      console.log('error::', error);
    }
  });

  const [pushProductToTiktok, { loading: isPushingProduct }] = useMutation(PUSH_PRODUCT_TO_TIKTOK, {
    onError: (err) => {
      console.log('err::', err);
      closeConfirmPushTiktokModal();
    }
  });

  const showAddingProductModal = () => {
    setIsShowAddingProductsModal(true);
  };

  const handleCloseAddingProductsModal = () => {
    document.body.style.overflow = 'unset';
    setIsShowAddingProductsModal(false);
  };

  const handleAddProducts = (productIds: number[]) => {
    addProductToTiktok({
      variables: {
        input: {
          productIds
        }
      }
    }).then((res) => {
      if (res?.data?.addProductToTiktok?.success) {
        handleCloseAddingProductsModal();
        setSelectedTab('DRAFT');
        showToast({
          successText: `<span style="color: #1e2749;">${productIds?.length} products<span> <span style="color:#50cd89;">are being processed.</span>`,
          message: 'Initial review in progress to ensure all product information is accurate.'
        });
        refecthStatusCounts();
        refetchTiktokProducts();
      }
    });
  };

  const handleUpdateProductTiktokCategory = (productId, categoryId) => {
    changeTiktokProductCategory({
      variables: {
        input: {
          productId,
          categoryId
        }
      }
    }).then((res) => {
      if (res?.data?.changeProductCategory) {
        const { status } = res?.data?.changeProductCategory;
        const updateadProducts = tiktokProducts.map((product) => {
          if (product.id === productId) {
            return { ...product, status };
          }
          return product;
        });
        setTiktokProducts(updateadProducts);
      }
    });
  };

  const handleSearch = (value) => {
    setTiktokInput({
      ...tiktokInput,
      searchString: value
    });
  };

  const selectTiktokProduct = useCallback(
    (e: any, productId?: any) => {
      if (e === 'All') {
        setSelectedProductIDs(tiktokProducts.map((product) => product.id));
      } else if (e.target.checked) {
        setSelectedProductIDs([...selectedProductIDs, productId]);
      } else {
        setSelectedProductIDs(selectedProductIDs.filter((productItemId) => productItemId !== productId));
      }
    },
    [tiktokProducts, selectedProductIDs]
  );

  const goToInventoryPage = useCallback(() => history.push(`${ROUTES.products.inventory.main}`), [history]);

  const handleShowResponse = (product) => {
    setShowTiktokResponseModal(true);
    setResponsedProduct(product);
  };

  const initialColumnOptions = [
    {
      dataKey: 'checkbox',
      name: '',
      width: 50,
      headerRenderer: () => {
        return (
          <div className="form-check form-check-sm form-check-custom">
            <input
              className="form-check-input widget-9-check"
              type="checkbox"
              checked={tiktokProducts?.length === selectedProductIDs?.length}
              onChange={() => {
                if (tiktokProducts?.length === selectedProductIDs?.length) {
                  setSelectedProductIDs([]);
                } else {
                  selectTiktokProduct('All');
                }
              }}
            />
          </div>
        );
      },
      cellRenderer: ({ rowData }) => {
        return (
          <div
            className="form-check form-check-sm form-check-custom"
            data-tip={rowData?.status === TIKTOK_PRODUCT_STATUS.UPDATES_REQUIRED}
            data-for={rowData?.status === TIKTOK_PRODUCT_STATUS.UPDATES_REQUIRED ? 'checkboxTooltip' : null}
          >
            <input
              className="form-check-input widget-9-check tiktok-checkbox"
              type="checkbox"
              checked={selectedProductIDs.includes(rowData.id)}
              onChange={(e) => {
                selectTiktokProduct(e, rowData.id);
              }}
              onClick={(e) => {
                selectTiktokProduct(e, rowData.id);
              }}
              disabled={rowData?.status !== TIKTOK_PRODUCT_STATUS.DRAFT}
            />
            {rowData?.status === TIKTOK_PRODUCT_STATUS.UPDATES_REQUIRED && (
              <Tooltip
                tooltipId="checkboxTooltip"
                place="top"
                content={() => {
                  return <>Product is not ready</>;
                }}
              />
            )}
          </div>
        );
      }
    },
    {
      dataKey: 'productName',
      name: 'Products',
      width: 330,
      cellRenderer: ({ rowData }) => {
        return (
          <FlexBox className="align-items-center">
            <div className="symbol m-r-20">
              <Img
                src={setImageSrc(rowData?.imageUrl ? generateCompressedImageURL(rowData?.imageUrl, '50') : DefaultImage)}
                placeholderImg={DefaultImage}
                errorImg={DefaultImage}
                className="object-fit-scale-down bg-white border border-light border-2 productList"
              />
            </div>
            <Link
              to={{
                pathname: `/products/allProducts/edit/${rowData?.id}`,
                state: {
                  tiktokError:
                    rowData?.status === TIKTOK_PRODUCT_STATUS?.UPDATES_REQUIRED && JSON.parse(rowData?.error)
                      ? JSON.parse(rowData?.error)
                      : undefined,
                  from: ROUTES.marketing.tiktokIntegration.main
                }
              }}
              className="main-cell tiktok-product"
            >
              <span className="d-block">{rowData?.name}</span>
            </Link>
          </FlexBox>
        );
      }
    },
    {
      dataKey: 'productQty',
      name: 'Inventory',
      width: 120,
      cellRenderer: ({ rowData }) => {
        return <PLInventory goToInventoryPage={goToInventoryPage} quantityInStock={rowData?.inventory} />;
      }
    },
    {
      dataKey: 'category',
      name: 'Category',
      width: 300,
      cellRenderer: ({ rowData }) => {
        return <CategoryCell data={rowData} updateCategory={handleUpdateProductTiktokCategory} />;
      }
    },
    {
      dataKey: 'status',
      name: 'Sync Status',
      headerRenderer: () => {
        return (
          <div className="d-flex align-items-center">
            <span className="me-2">Sync Status</span>
            <img src={InfoIcon} />
          </div>
        );
      },
      width: 140,
      cellRenderer: ({ rowData }) => {
        return <StatusCell data={rowData} handleShowResponse={handleShowResponse} />;
      }
    },
    {
      dataKey: 'action',
      name: '',
      width: 50,
      cellRenderer: ({ rowData }) => {
        return <ActionMenu />;
      }
    }
  ];

  const closeTiktokResponseModal = () => {
    document.body.style.overflow = 'unset';
    setShowTiktokResponseModal(false);
  };

  const handlePushTiktokModal = () => {
    setPushTiktokConfirmModal(true);
  };

  const closeConfirmPushTiktokModal = () => {
    document.body.style.overflow = 'unset';
    setPushTiktokConfirmModal(false);
  };

  const handlePushToTiktok = (includeBothReady: boolean) => {
    let productIds;
    if (includeBothReady) {
      productIds = tiktokProducts
        ?.filter((product) => product.status === TIKTOK_PRODUCT_STATUS.DRAFT && product.error)
        ?.map((product) => product.id);
    } else {
      productIds = tiktokProducts
        ?.filter((product) => product.status === TIKTOK_PRODUCT_STATUS.DRAFT && !product.error)
        ?.map((product) => product.id);
    }

    if (productIds?.length === 0) return;

    pushProductToTiktok({
      variables: {
        input: {
          productIds
        }
      }
    }).then((res) => {
      if (res?.data?.pushProductToTikTok) {
        closeConfirmPushTiktokModal();
        setSelectedTab('IN REVIEW');
        showToast({
          successText: `<span style="color: #1e2749;">${productIds?.length} products<span> <span style="color:#50cd89;">were sent to review!</span>`,
          message: 'Your products will become Active as TikTok review them.'
        });
        refecthStatusCounts();
        refetchTiktokProducts();
      }
    });
  };

  const readyStatusCount = useMemo(() => {
    return tiktokProducts?.filter((product) => product.status === TIKTOK_PRODUCT_STATUS.DRAFT && !product.error)?.length;
  }, [tiktokProducts]);

  const readyInfoStatusCount = useMemo(() => {
    return tiktokProducts?.filter((product) => product.status === TIKTOK_PRODUCT_STATUS.DRAFT && product.error)?.length;
  }, [tiktokProducts]);

  const remainingStatusCount = useMemo(() => {
    return tiktokProducts?.filter(
      (product) => product.status === TIKTOK_PRODUCT_STATUS.PROCESSING || product.status === TIKTOK_PRODUCT_STATUS.UPDATES_REQUIRED
    )?.length;
  }, [tiktokProducts]);

  return (
    <>
      <div className="w-100 details-page p-t-40" id="tablePage">
        <div className="details-page-container">
          <FlexBox className="w-100 mx-0 justify-content-between custom-page-header">
            <FlexBox className="align-items-center">
              <h2 className="page-title m-0">{constVariables.TikTokIntegration.title}</h2>
              <img src={InfoIcon} className="cursor-pointer m-l-12" data-tip data-for="infoItemIcon" />
            </FlexBox>
            <button className="btn btn-primary btn-md me-0" onClick={showAddingProductModal}>
              {constVariables.TikTokIntegration.addProducts}
            </button>
          </FlexBox>
          <div className="card m-b-40">
            <h4 className="section-title m-b-30">{constVariables.TikTokIntegration.overview}</h4>
            <FlexBox className="align-items-center tiktok-overview">
              <div className="tiktok-overview-item">
                <h5>{constVariables.TikTokIntegration.inProcess}</h5>
                <p>0</p>
              </div>
              <div className="tiktok-overview-item">
                <h5>{constVariables.TikTokIntegration.readyToPush}</h5>
                <p>0</p>
              </div>
              <div className="tiktok-overview-item">
                <h5>{constVariables.TikTokIntegration.inQueue}</h5>
                <p>0</p>
              </div>
              <div className="tiktok-overview-item">
                <h5>{constVariables.TikTokIntegration.inReview}</h5>
                <p>0</p>
              </div>
              <div className="tiktok-overview-item">
                <h5>{constVariables.TikTokIntegration.dailyLimit}</h5>
                <p>1000 / 1000</p>
              </div>
            </FlexBox>
          </div>
          <div className="card w-100 mb-0 p-0">
            <div className="p-x-32 p-t-32">
              <h4 className="section-title m-b-30">{constVariables.TikTokIntegration.products}</h4>
              <Tabs
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                tabs={constVariables.TabForTikTokIntegration}
                showCount
                countValue={statusCountList}
              />
              {!isProductsLoading && totalCounts === 0 && !tiktokInput.searchString && (
                <EmptyList datatype="product" status={selectedTab} icon={EmptyDataIcon} />
              )}
              <div
                className={`${!isProductsLoading && totalCounts === 0 && !tiktokInput.searchString ? 'display-none' : 'm-t-24'}`}
              >
                <div className="m-b-24">
                  <SearchBar placeholder="Search for products" onChange={handleSearch} />
                </div>
                {(selectedTab === 'ALL PRODUCTS' || selectedTab === 'DRAFT') && (
                  <div className="m-b-8">
                    <button className="btn btn-primary btn-sm m-0" onClick={handlePushTiktokModal}>
                      Push to TikTok
                    </button>
                  </div>
                )}
                <DataTable
                  page="tiktok"
                  rowData={tiktokProducts}
                  columnOptions={initialColumnOptions}
                  isLoading={isProductsLoading}
                  searchText={tiktokInput.searchString}
                  selectedIndex={-1}
                  selectedIDs={selectedProductIDs}
                />
              </div>
            </div>
            <div className={clsx({ 'display-none': totalCounts === 0 && !tiktokInput.searchString })}>
              <Pagination
                skipCount={tiktokInput?.pageInfo?.skipCount}
                limitCount={tiktokInput?.pageInfo?.limitCount}
                setSkipCount={(num: number) => {
                  setTiktokInput((prev) => ({
                    ...prev,
                    pageInfo: {
                      ...prev.pageInfo,
                      skipCount: num
                    }
                  }));
                }}
                total={totalCounts}
              />
            </div>
          </div>
        </div>
      </div>
      {isShowAddingProductsModal && (
        <CustomModal bodyClassname="w-90 w-md-150" show={isShowAddingProductsModal} closeModal={handleCloseAddingProductsModal}>
          <AddingProductsModal
            title={constVariables.TikTokIntegration.addProductsToTiktok}
            closeModal={handleCloseAddingProductsModal}
            shouldShowVariants={false}
            handleAddProducts={handleAddProducts}
            isShowOnlyActiveProducts={true}
            isAddingProduct={isAddingProduct}
            isAddingToTiktok={true}
          />
        </CustomModal>
      )}
      {showTiktokResponseModal && (
        <CustomModal bodyClassname="w-90 w-md-150" show={showTiktokResponseModal} closeModal={closeTiktokResponseModal}>
          <TiktokResponseModal product={responsedProduct} closeModal={closeTiktokResponseModal} />
        </CustomModal>
      )}
      {pushTiktokConfirmModal && (
        <CustomModal bodyClassname="w-90 w-md-150" show={pushTiktokConfirmModal} closeModal={closeConfirmPushTiktokModal}>
          <ConfirmPushTiktokModal
            readyStatusCount={readyStatusCount}
            readyInfoStatusCount={readyInfoStatusCount}
            remainingStatusCount={remainingStatusCount}
            closeModal={closeConfirmPushTiktokModal}
            handlePushToTiktok={handlePushToTiktok}
            isPushingProduct={isPushingProduct}
          />
        </CustomModal>
      )}
    </>
  );
};

export default TiktokIntegration;
