import { gql } from '@apollo/client';

export const CONNECT_TIKTOK_STORE = gql`
  mutation ConnectTiktokStore($input: ConnectTiktokStoreInput!) @api(name: "tiktokAPI") {
    connectTiktokStore(input: $input) {
      status
      storeName
    }
  }
`;
