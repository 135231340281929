import { FC, useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import firebase from 'firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Apollo
import { ApolloProvider } from '@apollo/client';
import { client } from './apollo/client';

// Components
import { Navigation } from './navigation';
import { Toast } from './components/molecules';

// Hooks && Utils && Helpers
import { defaultToast, ToastHandler } from './helpers/context/toastContext';
import { initOnesignal } from './services/pushnotification/initOnesignal';
import { removeStoreIdToOSPlayer } from './services/pushnotification/DataTags';
import GlobalStyle from './styles/GlobalStyles';
import themes from '../src/styles/themes/themes';
import { RecordingsDownloaderProvider } from './context/RecordingsDownloader/RecordingsDownloader';

// Types
import { ToastProp } from './components/molecules/Toast/Toast.types';

// Styles
import './App.css';

const App: FC = () => {
  const [toastValue, setToast] = useState<ToastProp>(defaultToast.toastValue);

  const setToastValues = (obj: ToastProp) => {
    setToast({
      message: obj.message,
      errorText: obj.errorText,
      undoButton: obj.undoButton,
      successText: obj.successText,
      undoBtnHandler: obj.undoBtnHandler,
      editBtnText: obj.editBtnText,
      editBtnHandler: obj.editBtnHandler,
      title: obj.title,
      show: true,
      width: obj.width
    });
    setTimeout(() => {
      setToast((prev) => ({ ...prev, show: false }));
    }, 5000);
  };

  useEffect(() => {
    initOnesignal();

    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (!user) {
        removeStoreIdToOSPlayer();
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (toastValue.show) {
      toast(
        <Toast
          message={toastValue.message}
          successText={toastValue.successText}
          errorText={toastValue.errorText}
          title={toastValue.title}
          show={toastValue.show}
          undoButton={toastValue.undoButton}
          undoBtnHandler={toastValue.undoBtnHandler}
          showHandler={() => setToast((prev) => ({ ...prev, show: false }))}
          editBtnText={toastValue.editBtnText}
          editBtnHandler={toastValue.editBtnHandler}
          width={toastValue.width}
        />,
        { autoClose: 3000, position: toast.POSITION.TOP_RIGHT, hideProgressBar: true }
      );
    }
  }, [toastValue.show]);

  return (
    <ToastHandler.Provider value={{ toastValue, setToastValues }}>
      <ThemeProvider theme={themes.light}>
        <ApolloProvider client={client}>
          <RecordingsDownloaderProvider>
            <GlobalStyle />
            <ToastContainer autoClose={false} style={{ width: 'unset' }} />
            <Switch>
              <Navigation />
            </Switch>
          </RecordingsDownloaderProvider>
        </ApolloProvider>
      </ThemeProvider>
    </ToastHandler.Provider>
  );
};

export default App;
