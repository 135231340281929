import { FC } from 'react';
import moment from 'moment';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { OverviewDataCDType } from 'src/components/pages/customers/CustomerDetails/CustomerDetails.types';

const OverviewCustomerDetails: FC<OverviewDataCDType> = ({
  averageOrderValue,
  totalAmountSpentValue,
  numberOfProducts,
  lastOrderDate,
  productsRefunded
}) => {
  return (
    <div>
      <h4 className="section-title m-b-32">{constVariables.customers.overview}</h4>
      <div className="d-flex">
        {averageOrderValue ? (
          <div className="oneThirdCustomerDetails rightBorderCustomerDetails d-flex align-items-center justify-content-start pe-6">
            <div>
              <div className="helper-text">{constVariables.customers.orderValue}</div>
              <div className="mt-1">
                <span className="fs-4">${averageOrderValue.toFixed(2)} </span>

                {lastOrderDate ? (
                  <span className="fs-8 helper-text">(Last order on {moment(lastOrderDate).format('l')})</span>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
        {totalAmountSpentValue ? (
          <div className="oneThirdCustomerDetails rightBorderCustomerDetails d-flex justify-content-start ps-6">
            <div>
              <div className="helper-text">{constVariables.customers.amountSpend}</div>
              <div className="mt-1">
                <span className="fs-4">${totalAmountSpentValue} </span>
                {numberOfProducts ? <span className="fs-8 helper-text">({numberOfProducts} orders)</span> : null}
              </div>
            </div>
          </div>
        ) : null}
        {productsRefunded ? (
          <div className="oneThirdCustomerDetails d-flex justify-content-start ps-6 pe-6">
            <div>
              <div className="helper-text">{constVariables.customers.productsReturned}</div>
              <div className="mt-1">
                <span className="fs-4">{productsRefunded} Items </span>

                {productsRefunded && numberOfProducts ? (
                  <span className="helper-text fs-8">({Math.round((productsRefunded / numberOfProducts) * 100 * 100) / 100}%)</span>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default OverviewCustomerDetails;
