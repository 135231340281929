import { FC } from 'react';

// Components
import { Checkbox } from 'src/components/molecules';

// ConstVariables
import { constVariables } from 'src/constants/constVariables';

// Types
import { UsageLimitsTypeSection, UsageLimits } from 'src/components/pages/coupons/CreateCoupon/CreateCoupon.types';

const UsageLimitsCreateCoupon: FC<UsageLimitsTypeSection> = ({ usageLimits, setUsageLimits, errorMessages, validateInput }) => {
  const limitNumberValueHandler = (value: any) => {
    if (!isNaN(value)) {
      setUsageLimits((prev) => ({ ...prev, value }));
    }
  };

  const handleKeyInput = (event, fieldName) => {
    if (event.keyCode === 13) {
      validateInput(event.target.value, fieldName);
    }
  };

  const handleBlurInput = (event, fieldName) => {
    validateInput(event.target.value, fieldName);
  };

  return (
    <div className="card w-100 mb-0">
      <div className="section-title m-b-32">{constVariables.Coupons.AddCoupon.useageLimits.usageLimits}</div>

      {Object.keys(UsageLimits).map((limit, index) => {
        return (
          <Checkbox
            key={index}
            labelclassname={index === Object.keys(UsageLimits).length - 1 ? 'mb-0' : 'm-b-24'}
            name={UsageLimits[limit].title}
            value={usageLimits.selectedItems.includes(UsageLimits[limit].key)}
            onChangeValue={() => {
              if (usageLimits.selectedItems.includes(UsageLimits[limit].key)) {
                setUsageLimits((usageLimits) => ({
                  ...usageLimits,
                  selectedItems: usageLimits.selectedItems.filter((e: string) => e !== UsageLimits[limit].key)
                }));
              } else {
                setUsageLimits((usageLimits) => ({
                  ...usageLimits,
                  selectedItems: [...usageLimits.selectedItems, UsageLimits[limit].key]
                }));
              }
            }}
            noVerticalPadding
          />
        );
      })}
      {usageLimits?.selectedItems?.includes('MAX_TIMES_USED') ? (
        <div className="col-4 mt-3 ms-8">
          <div className="d-flex align-items-center justify-content-center">
            <div className="d-flex form-control p-0 align-item-center form-control-lg input-box position-relative">
              <input
                autoComplete="off"
                className={`w-100 h-100 px-5 ${errorMessages?.usageLimitsError ? 'input-error' : ''}`}
                type="text"
                pattern="[0-9]+"
                name="LimitNumber"
                value={usageLimits.value}
                onChange={(e) => {
                  e.target.validity.valid && limitNumberValueHandler(e.target.value);
                }}
                onKeyDown={(event) => handleKeyInput(event, 'usageLimitsError')}
                onBlur={(event) => handleBlurInput(event, 'usageLimitsError')}
              />
            </div>
          </div>
          {errorMessages?.usageLimitsError && <div className="warning-text p-0 fs-7">{errorMessages?.usageLimitsError}</div>}
        </div>
      ) : null}
    </div>
  );
};

export default UsageLimitsCreateCoupon;
