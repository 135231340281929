// Components
import Tooltip from './Tooltip';

const FirstTimeCustomerTooltip = () => {
  return (
    <Tooltip
      tooltipId="firstTimeCustomerIcon"
      place="top"
      content={() => {
        return <>First Time Customer</>;
      }}
    />
  );
};

export default FirstTimeCustomerTooltip;
