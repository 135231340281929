import { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_SHOPIFY_ADMIN_BASE_URL } from 'src/apollo/queries';

// Components
import { FlexBox, Img, Loader } from 'src/components/atoms';
import {
  OrderTagsBlock,
  ShopifySyncTooltip,
  PreviewTooltip,
  FirstTimeCustomerTooltip,
  ExpeditedShippingTooltip,
  VipCustomerTooltip
} from 'src/components/molecules';
import TableCell from '../components/TableCell';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';
import { setStatusColor, setStatusText } from 'src/components/oraganisms/OrdersList/helpers/orderStatus';

// Icons
import {
  RightArrowIcon,
  ShopifyIcon,
  PreviewMagnifyingGlassIcon,
  DefaultImage,
  FirstTime,
  Truck,
  VipCustomerIcon
} from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { OrdersListData } from 'src/components/oraganisms/OrdersList/OrdersList.types';

interface OrderDetailsRowProps {
  order: any;
  columnOptions: any[];
  isLoading?: boolean;
  rowWidth: number;
  openOrderPreview?: (id: number, isCombOrdrParent: boolean, order?: OrdersListData) => void;
  openOrderShippingPreview?: () => void;
  openShipmentDetails?: (id: number) => void;
  sendDate?: (date: string, index: number) => void;
}

const OrderDetailsRow: FC<OrderDetailsRowProps> = ({
  order,
  columnOptions,
  isLoading,
  rowWidth,
  openOrderPreview,
  openOrderShippingPreview,
  openShipmentDetails,
  sendDate
}) => {
  const { data: { shopifyAdminBaseUrl } = { shopifyAdminBaseUrl: null } } = useQuery(GET_SHOPIFY_ADMIN_BASE_URL);

  const goToShopifyPage = (shopifyOrderId) => {
    if (shopifyAdminBaseUrl) window.open(`${shopifyAdminBaseUrl}orders/${shopifyOrderId}`, '_blank');
  };

  const renderCombinedColumn = (childOrder, column, order) => {
    switch (column.dataKey) {
      case 'index':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <div>
              <div className="d-flex align-items-center m-b-8">
                {childOrder.isPartiallyShipped ? (
                  <div className="d-flex me-1">
                    <div className="bg-danger text-white p-1 px-2 roundButton order-status-error-text">
                      {constVariables.orderItemStatuses.PartialShipping}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="d-flex align-items-center">
                <div className="form-check form-check-sm form-check-custom">
                  <input className="me-5 form-check-input widget-9-check opacity-0" type="checkbox" disabled />
                </div>

                <div className="me-4 opacity-0">
                  <img src={RightArrowIcon} className={`h-10px w-10px  opacity-0`} />
                </div>

                {childOrder?.isCombOrdrParent ? (
                  <div className="cursor-pointer me-4 opacity-0">
                    <img src={RightArrowIcon} className={`h-10px w-10px opacity-0`} />
                  </div>
                ) : null}
                <FlexBox className="align-items-center">
                  <FlexBox className="align-items-center justify-content-center m-r-16">
                    <Link to={`/orders/orderDetails/${childOrder.id}`} className="main-cell">
                      <span className="d-block">#{childOrder.code}</span>
                    </Link>
                  </FlexBox>
                  {childOrder?.shopifyId && (
                    <>
                      <img
                        src={ShopifyIcon}
                        className="cursor-pointer m-r-16"
                        data-tip
                        data-for="shopifySyncIcon"
                        onClick={() => goToShopifyPage(childOrder?.shopifyId)}
                      />
                      <ShopifySyncTooltip tooltipText="Order from Shopify" />
                    </>
                  )}
                  <>
                    <img
                      src={PreviewMagnifyingGlassIcon}
                      className="cursor-pointer"
                      data-tip
                      onClick={() => {
                        openOrderPreview && openOrderPreview(childOrder?.id, childOrder?.isCombOrdrParent, childOrder);
                      }}
                      data-for="previewIcon"
                    />
                    <PreviewTooltip />
                  </>
                </FlexBox>
              </div>
            </div>
          </TableCell>
        );
      case 'date':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <FlexBox className="align-items-center">
              {childOrder?.paidAt ? moment(childOrder.paidAt).format('D MMM YYYY, hh:mm a') : '-'}
            </FlexBox>
          </TableCell>
        );
      case 'status':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <div
              className={` ${setStatusColor(
                childOrder.status,
                childOrder.isPartiallyShipped
              )} order-status-box text-nowrap poppins-medium`}
            >
              <div className="text-capitalize">{setStatusText(childOrder.status, childOrder.isPartiallyShipped)}</div>
            </div>
          </TableCell>
        );
      case 'customer':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <div className="align-items-center d-flex">
              <Img
                src={setImageSrc(order?.thumbnailUrl ? generateCompressedImageURL(order?.thumbnailUrl, '50') : DefaultImage)}
                placeholderImg={DefaultImage}
                errorImg={DefaultImage}
                height={40}
                width={40}
                className="rounded-circle objectFit me-4"
              />

              <div className="inline-row align-items-center">
                <Link to={`/customers/customerDetails/${order.customerId}`} target="_blank" className="order-customer-link">
                  <span className="d-block">{order.customer}</span>
                </Link>
                {order.isFirstTimeCustomer && (
                  <>
                    <img src={FirstTime} className="cursor-pointer mx-4" data-tip data-for="firstTimeCustomerIcon" />
                    <FirstTimeCustomerTooltip />
                  </>
                )}

                {order.isExpeditedShipping && (
                  <>
                    <img src={Truck} className="cursor-pointer mx-4" data-tip data-for="truckIcon" />
                    <ExpeditedShippingTooltip />
                  </>
                )}
                {order.isVIPCustomer && (
                  <>
                    <img src={VipCustomerIcon} className="cursor-pointer mx-4" data-tip data-for="vipCustomerIcon" />
                    <VipCustomerTooltip />
                  </>
                )}
              </div>
            </div>
          </TableCell>
        );
      case 'total':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <FlexBox className="align-items-center">
              {childOrder.totalAmount ? `${childOrder.totalAmount.toFixed(2)}` : '-'}
            </FlexBox>
          </TableCell>
        );
      case 'items':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <FlexBox className="align-items-center">
              {childOrder.quantity ? (
                <>
                  <span className="available-item-quantity">{childOrder.availableQuantity}</span>/{childOrder.quantity} Available
                  Items
                </>
              ) : (
                '-'
              )}
            </FlexBox>
          </TableCell>
        );
      case 'tags':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            {childOrder.tags ? (
              <div>
                <OrderTagsBlock tags={childOrder.tags} />
              </div>
            ) : null}
          </TableCell>
        );
      case 'action':
        return <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}></TableCell>;
      default:
        return;
    }
  };

  const dateSuffix = (date: number) => {
    if (date === 1) {
      return 'st';
    } else if (date === 2) {
      return 'nd';
    } else if (date === 3) {
      return 'rd';
    }
    return 'th';
  };

  const renderShippedColumn = (orderShipment, column, index) => {
    switch (column.dataKey) {
      case 'index':
        return <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}></TableCell>;
      case 'date':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <div className="bg-white border rounded px-4 py-2 fs-7 ">
              <span className="fw-bold text">
                {index + 1}
                {dateSuffix(index + 1)} shipping
              </span>
              <span className="helper-text"> - on {moment(orderShipment.shipmentDate).format('DD MMMM YYYY')}</span>
            </div>
          </TableCell>
        );
      case 'status':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <div className={`${setStatusColor('completed')} order-status-box text-nowrap  poppins-medium`}>
              <div className="text-capitalize">{setStatusText(orderShipment.status)}</div>
            </div>
          </TableCell>
        );
      case 'customer':
        return <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}></TableCell>;
      case 'total':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            {orderShipment.totalAmount ? `${orderShipment.totalAmount.toFixed(2)}` : '-'}
          </TableCell>
        );
      case 'items':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <div className="d-flex align-items-center">
              <div className="fw-bold text-muted fs-7 ">
                {orderShipment.numberOfItems} {orderShipment.numberOfItems === 1 ? 'Item' : 'Items'}
              </div>
              <img
                onClick={() => {
                  if (openOrderShippingPreview) {
                    openOrderShippingPreview();
                    if (openShipmentDetails) {
                      openShipmentDetails(orderShipment.id);
                    }
                    if (sendDate) {
                      sendDate(orderShipment.shipmentDate, index + 1);
                    }
                  }
                }}
                src={PreviewMagnifyingGlassIcon}
                className="h-20px w-20px ms-2 cursor-pointer"
                data-tip
                data-for="previewIcon"
              />
              <PreviewTooltip />
            </div>
          </TableCell>
        );
      case 'tags':
        return <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}></TableCell>;
      case 'action':
        return <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}></TableCell>;
      default:
        return;
    }
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            height: 70,
            display: 'flex',
            alignItems: 'center',
            width: rowWidth
          }}
          className="ReactVirtualized__Table__row"
        >
          <Loader type="page" />
        </div>
      ) : (
        <>
          {order?.isCombOrdrParent ? (
            <>
              {order?.childOrders?.map((childOrder, index) => (
                <div
                  style={{
                    height: 70,
                    display: 'flex',
                    alignItems: 'center',
                    width: rowWidth
                  }}
                  key={index}
                  className="ReactVirtualized__Table__row"
                >
                  {columnOptions?.map((column, columnIndex) => {
                    return <Fragment key={columnIndex}>{renderCombinedColumn(childOrder, column, order)}</Fragment>;
                  })}
                </div>
              ))}
            </>
          ) : null}
          {order.orderStatus === 'completed' || order.orderStatus === 'shipped' ? (
            <>
              {order?.orderShipments?.map((orderShipment, index) => (
                <div
                  style={{
                    height: 70,
                    display: 'flex',
                    alignItems: 'center',
                    width: rowWidth
                  }}
                  className="ReactVirtualized__Table__row"
                  key={index}
                >
                  {columnOptions?.map((column, columnIndex) => {
                    return <Fragment key={columnIndex}>{renderShippedColumn(orderShipment, column, index)}</Fragment>;
                  })}
                </div>
              ))}
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default OrderDetailsRow;
