import { gql } from '@apollo/client';

export const SIGN_UP_BACK_STORE_INVITEE = gql`
  mutation SignupBackstoreInvitee($input: SignupBackstoreUserInput!) @api(name: "authAPI") {
    signupBackstoreInvitee(input: $input) {
      email
      fullName
      profileUrl
      userId
      storeId
      medium
    }
  }
`;
