import { FC, useState, useCallback, useEffect } from 'react';

// Apollo
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_PRODUCT_CATEGORIES, GET_PRODUCT_SUB_CATEGORIES } from 'src/apollo/queries';
import { CREATE_PRODUCT_CATEGORIES, CREATE_PRODUCT_SUB_CATEGORIES, SAVE_VENDOR_INFO } from 'src/apollo/mutations';

// Components
import AddVendorPresentational from './AddVendor.presentational';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { AddVendorProps, CategoriesAndSubCategoriesType, contactsType, extraInfoType, otherInfoType } from './AddVendor.types';

const AddVendor: FC<AddVendorProps> = ({ show, data, resetState, updateCurrentVendor }) => {
  const [imageDetails, setImageDetails] = useState<{
    fileName?: any;
    imgSrc?: any;
  }>({});
  const [uploadImageError, setUploadImageError] = useState('');
  const [showValidation, setShowValidation] = useState(false);
  const [profileUrlData, setProfileUrlData] = useState('');
  const [openSubcategoryDropdown, setOpenSubcategoryDropdown] = useState<any>([]);
  const [hideContactInfo, setHideContactInfo] = useState(false);
  const [contactTitleInput, setContactTitleInput] = useState(constVariables.addVendor.salesRep);
  const [hoverAction, setHoverAction] = useState('');
  const [contacts, addContact] = useState<contactsType>([]);
  const [salesInfos, addSalesInfo] = useState<extraInfoType>([
    {
      title: constVariables.addVendor.discount,
      value: '',
      mouseAction: ''
    },
    {
      title: constVariables.addVendor.packsCondition,
      value: '',
      mouseAction: ''
    }
  ]);
  const [locations, addLocation] = useState<extraInfoType>([
    {
      title: constVariables.addVendor.atMarketLocation,
      value: '',
      mouseAction: ''
    }
  ]);
  const [others, addOther] = useState<otherInfoType>([
    {
      title: constVariables.addVendor.fashiongoProfile,
      website: '',
      mouseAction: ''
    },
    {
      title: constVariables.addVendor.faireProfile,
      website: '',
      mouseAction: ''
    }
  ]);
  const [generalInfo, setGeneralInfo] = useState({
    vendorName: '',
    website: '',
    accountNo: '',
    vendorManager: ''
  });

  const [categoriesAndSubCategories, setCategoriesAndSubCategories] = useState<CategoriesAndSubCategoriesType[]>([
    {
      category: {
        id: 0,
        name: ''
      },
      subCategory: [],
      openCategoryDropdown: false
    }
  ]);
  const [allSubCategories, setAllSubCategories] = useState<
    {
      subCategories: { name: string; id: number }[];
    }[]
  >([]);
  const [contactInfo, setContactInfo] = useState({
    title: constVariables.addVendor.salesRep,
    fullName: '',
    email: '',
    phoneNumber: ''
  });

  const [salesInformation, setSalesInformation] = useState({
    shippingCosts: constVariables.addVendor.onTheVendor
  });

  const [locationInfo, setLocationInfo] = useState({
    address: '',
    state: '',
    country: ''
  });

  const [categoryInput, setCategoryInput] = useState<string[]>([]);
  const [subCategoryInput, setsubCategoryInput] = useState<string[]>([]);
  const [categoryIndex, setCategoryIndex] = useState(0);

  const { data: categoryData } = useQuery(GET_PRODUCT_CATEGORIES, {
    variables: {
      input: { getStoreSpecificCategories: true }
    },
    context: {
      headers: {
        authorization: localStorage.getItem('token') ? localStorage.getItem('token') : ''
      }
    }
  });
  console.error('categoryData-', categoryData);

  const [getSubCategories, { data: subCategories }] = useLazyQuery(GET_PRODUCT_SUB_CATEGORIES, {
    fetchPolicy: 'network-only'
  });

  const [callSaveVendorInfo, { data: saveVendorInfo, loading }] = useMutation(SAVE_VENDOR_INFO);

  const [callCreateCategory, { data: categoryInfo }] = useMutation(CREATE_PRODUCT_CATEGORIES);

  const [callCreateSubCategory, { data: subCategoryInfo }] = useMutation(CREATE_PRODUCT_SUB_CATEGORIES);

  useEffect(() => {
    if (data) {
      const tempGeneralInfo = Object.assign({}, generalInfo);
      tempGeneralInfo.vendorName = data?.name;
      tempGeneralInfo.accountNo = data?.accountNumber || '';
      tempGeneralInfo.website = data?.websiteUrl;
      setGeneralInfo(tempGeneralInfo);
      setProfileUrlData(data?.profileUrl);
      const tempCategoriesAndSubCategories: CategoriesAndSubCategoriesType[] = [];
      const tempCategoryInput: string[] = [];
      data?.categoriesSet?.map((set: any, index: number) => {
        const obj: any = {};
        obj.category = set?.category;
        tempCategoryInput.push(set?.category?.name);
        obj.openCategoryDropdown = false;
        const arr = Array.from(set?.subCategory);
        arr.map((subCategory: any, index: number) => {
          const obj = Object.assign({}, subCategory);
          delete obj.categoryId;
          arr[index] = obj;
          return null;
        });
        obj.subCategory = arr;
        tempCategoriesAndSubCategories.push(obj);
        return null;
      });
      if (tempCategoriesAndSubCategories.length > 0) setCategoriesAndSubCategories([...tempCategoriesAndSubCategories]);
      setCategoryInput([...tempCategoryInput]);
      if (data?.vendorRep) {
        const tempContactInfo = Object.assign({}, contactInfo);
        tempContactInfo.title = data?.vendorRep[0]?.title;
        tempContactInfo.fullName = data?.vendorRep[0]?.name;
        tempContactInfo.email = data?.vendorRep[0]?.email;
        tempContactInfo.phoneNumber = data?.vendorRep[0]?.phoneNo;
        setContactInfo(tempContactInfo);
        setContactTitleInput(tempContactInfo.title);
      }

      const tempContact: contactsType = [];
      data?.vendorRep?.map((contact: any, index: number) => {
        if (index > 0) {
          const obj: any = {};
          obj.title = contact?.title;
          obj.fullName = contact?.name;
          obj.email = contact?.email;
          obj.phoneNumber = contact?.phoneNo;
          obj.mouseAction = '';
          tempContact.push(obj);
        }
        return null;
      });
      addContact([...tempContact]);

      const tempLocationInfo = Object.assign({}, locationInfo);
      tempLocationInfo.address = data?.address;
      tempLocationInfo.country = data?.country;
      tempLocationInfo.state = data?.state;

      setLocationInfo(tempLocationInfo);

      const tempSalesInfo = Object.assign({}, salesInformation);
      tempSalesInfo.shippingCosts = data?.isShopPayForShipping
        ? constVariables.addVendor.onTheBoutique
        : constVariables.addVendor.onTheVendor;
      setSalesInformation(tempSalesInfo);

      const tempSales: extraInfoType = [];
      const tempLocations: extraInfoType = [];
      const tempOthers: otherInfoType = [];

      data?.extraInfo?.map((info: any, index: number) => {
        const obj: any = {};
        obj.title = info.title;
        if (info.type === constVariables.extraInfoTypes.others) obj.website = info.name;
        else obj.value = info.name;
        obj.mouseAction = '';
        if (info.type === constVariables.extraInfoTypes.others) tempOthers.push(obj);
        else if (info.type === constVariables.extraInfoTypes.location) tempLocations.push(obj);
        else if (info.type === constVariables.extraInfoTypes.saleInfo) tempSales.push(obj);
        return null;
      });
      addSalesInfo([...tempSales]);
      addLocation([...tempLocations]);
      addOther([...tempOthers]);
    }
  }, [show]);

  useEffect(() => {
    if (categoryInfo?.createProductCategory?.id) {
      getSubCategories({
        variables: {
          input: {
            parentCategoryId: categoryInfo?.createProductCategory?.id
          }
        }
      });
      const arr: any = Object.assign([], categoriesAndSubCategories);
      const obj = arr[categoryIndex];
      obj.category = {
        id: categoryInfo?.createProductCategory?.id,
        name: categoryInfo?.createProductCategory?.name
      };
      arr[categoryIndex] = obj;
      setCategoriesAndSubCategories(arr);
    }
  }, [categoryInfo]);

  useEffect(() => {
    if (subCategoryInfo?.createProductSubCategory?.id) {
      const arr: any = Object.assign([], categoriesAndSubCategories);
      const obj = arr[categoryIndex];
      const tempSubCategories = obj.subCategory;
      if (
        tempSubCategories.indexOf({
          id: subCategoryInfo?.createProductSubCategory?.id,
          name: subCategoryInfo?.createProductSubCategory?.name
        }) === -1
      ) {
        tempSubCategories.push({
          id: subCategoryInfo?.createProductSubCategory?.id,
          name: subCategoryInfo?.createProductSubCategory?.name
        });
        obj.subCategory = tempSubCategories;
        arr[categoryIndex] = obj;
        setCategoriesAndSubCategories(arr);
      }
    }
  }, [subCategoryInfo]);

  useEffect(() => {
    if (subCategories?.getProductSubCategories) {
      const arr: any = Object.assign([], allSubCategories);
      const obj: any = {};
      obj.subCategories = [...subCategories?.getProductSubCategories];
      arr[categoryIndex] = obj;
      setAllSubCategories([...arr]);
    }
  }, [subCategories]);

  useEffect(() => {
    if (saveVendorInfo?.saveVendorInfo?.id) {
      refreshState();
      if (data && updateCurrentVendor) {
        updateCurrentVendor();
      }
    }
  }, [saveVendorInfo]);

  const editGeneralInfo = (field: string, value: string) => {
    const obj: any = generalInfo;
    obj[field] = value;
    setGeneralInfo({ ...obj });
  };

  const editContactInfo = (field: string, value: string) => {
    const obj: any = contactInfo;
    obj[field] = value;
    setContactInfo({ ...obj });
  };

  const editSaleInformation = (field: string, value: string) => {
    const obj: any = salesInformation;
    obj[field] = value;
    setSalesInformation({ ...obj });
  };

  const addAndRemoveCategories = (obj: any, action: string, index?: number) => {
    if (action === 'add') {
      const arr = categoriesAndSubCategories.concat(obj);
      setCategoriesAndSubCategories(arr);
    }
    if (action === 'remove') {
      const arr = categoriesAndSubCategories;
      if (typeof index === 'number') arr.splice(index, 1);
      setCategoriesAndSubCategories([...arr]);
    }
    if (action === 'edit') {
      const array: {
        category: {
          id: number;
          name: string;
        };
        subCategory: [];
        openCategoryDropdown: false;
      }[] = Object.assign([], categoriesAndSubCategories);
      if (typeof index === 'number') {
        setCategoryIndex(index);
        array[index] = obj;
        setCategoriesAndSubCategories(array);
      }
    }
    if (action === 'newCategory' && typeof index === 'number') {
      setCategoryIndex(index);
      callCreateCategory({
        variables: {
          input: {
            name: obj.category.name
          }
        }
      });
    }
  };

  const editLocationInfo = (field: string, value: string) => {
    const obj: any = locationInfo;
    obj[field] = value;
    setLocationInfo({ ...obj });
  };

  const deleteContact = (index: number) => {
    const arr = contacts;
    arr.splice(index, 1);
    addContact([...arr]);
  };

  const deleteSaleInfo = (index: number) => {
    const arr = salesInfos;
    arr.splice(index, 1);
    addSalesInfo([...arr]);
  };

  const deleteLocation = (index: number) => {
    const arr = locations;
    arr.splice(index, 1);
    addLocation([...arr]);
  };

  const deleteOther = (index: number) => {
    const arr = others;
    arr.splice(index, 1);
    addOther([...arr]);
  };

  const handleDrop = useCallback((acceptedFiles, fileRejections) => {
    const reader = new FileReader();
    reader.onabort = () => console.info('file reading was aborted');
    reader.onerror = () => console.info('file reading has failed');
    reader.onload = async () => {
      setImageDetails({
        fileName: acceptedFiles[0].name,
        imgSrc: reader.result
      });
      setUploadImageError('');
    };
    if (acceptedFiles[0]) {
      reader.readAsDataURL(acceptedFiles[0]);
    } else {
      setUploadImageError(
        `"${fileRejections[0].file.name}" is not a supported file type. Upload files ending .jpg, .gif, .mp4, .mov, .glb, .usdz.`
      );
      setImageDetails({});
    }
  }, []);

  const changeAppliedItems = (item: any, index: number, type?: string) => {
    if (type === 'add') {
      const arr: any = Object.assign([], categoriesAndSubCategories);
      const obj = categoriesAndSubCategories[index];
      const subCategoryArr: any = obj.subCategory;
      subCategoryArr.push(item);
      obj.subCategory = subCategoryArr;
      arr[index] = obj;
      setCategoriesAndSubCategories([...arr]);
    } else {
      const arr: any = Object.assign([], categoriesAndSubCategories);
      const obj = categoriesAndSubCategories[index];
      const subCategoryArr: any = obj.subCategory;
      subCategoryArr.splice(obj.subCategory.indexOf(item), 1);
      obj.subCategory = [...subCategoryArr];
      arr[index] = obj;
      setCategoriesAndSubCategories(arr);
    }
  };

  const removeUploadedImage = () => {
    setImageDetails({});
  };

  const toggleContactInfo = () => {
    setContactInfo({
      title: constVariables.addVendor.salesRep,
      fullName: '',
      email: '',
      phoneNumber: ''
    });
    setHideContactInfo(!hideContactInfo);
  };

  const changeExtraContactFields = (value: string, index: number, field: string) => {
    const arr: any = Object.assign([], contacts);
    const obj: any = contacts[index];
    obj[field] = value;
    arr[index] = obj;
    addContact(arr);
  };

  const changeExtraSalesInfoFields = (value: string, index: number, field: string) => {
    const arr: any = Object.assign([], salesInfos);
    const obj: any = salesInfos[index];
    obj[field] = value;
    arr[index] = obj;
    addSalesInfo(arr);
  };

  const changeExtraLocationFields = (value: string, index: number, field: string) => {
    const arr: any = Object.assign([], locations);
    const obj: any = locations[index];
    obj[field] = value;
    arr[index] = obj;
    addLocation(arr);
  };

  const changeExtraOthersFields = (value: string, index: number, field: string) => {
    const arr: any = Object.assign([], others);
    const obj: any = others[index];
    obj[field] = value;
    arr[index] = obj;
    addOther(arr);
  };

  const refreshState = () => {
    setImageDetails({
      fileName: '',
      imgSrc: ''
    });
    setUploadImageError('');
    setShowValidation(false);
    addContact([]);
    addSalesInfo([
      {
        title: constVariables.addVendor.discount,
        value: '',
        mouseAction: ''
      },
      {
        title: constVariables.addVendor.packsCondition,
        value: '',
        mouseAction: ''
      }
    ]);
    addLocation([
      {
        title: constVariables.addVendor.atMarketLocation,
        value: '',
        mouseAction: ''
      }
    ]);
    addOther([
      {
        title: constVariables.addVendor.fashiongoProfile,
        website: '',
        mouseAction: ''
      },
      {
        title: constVariables.addVendor.faireProfile,
        website: '',
        mouseAction: ''
      }
    ]);
    setGeneralInfo({
      vendorName: '',
      website: '',
      accountNo: '',
      vendorManager: ''
    });
    setContactInfo({
      title: constVariables.addVendor.salesRep,
      fullName: '',
      email: '',
      phoneNumber: ''
    });
    setSalesInformation({
      shippingCosts: constVariables.addVendor.onTheVendor
    });
    setLocationInfo({
      address: '',
      state: '',
      country: ''
    });
    setHoverAction('');
    setHideContactInfo(false);
    setCategoriesAndSubCategories([
      {
        category: {
          id: 0,
          name: ''
        },
        subCategory: [],
        openCategoryDropdown: false
      }
    ]);
    setCategoryInput(['']);
    setCategoryIndex(0);
    setsubCategoryInput([]);
    resetState();
    setProfileUrlData('');
  };

  const handleEnter = (event: any) => {
    if (event.key.toLowerCase() === 'enter') {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };
  const handleSendSuccessURL = (res: any) => {
    setProfileUrlData(res.filesUploaded[0].url);
  };
  const onSaveVendorInfo = () => {
    const obj: any = {};
    const extraInfo: any = [];
    const contactInformation: any = [];
    const categoriesSet: any = [];
    categoriesAndSubCategories.map((categoryAndSubCategory, index) => {
      const obj: any = Object.assign({}, categoryAndSubCategory);
      obj.categoryId = obj.category.id;
      const arr = Object.assign([], obj.subCategory);
      arr.map((subCategory: any, i: number) => {
        const tempObj: any = Object.assign({}, subCategory);
        delete tempObj.name;
        arr[i] = tempObj.id;
        return null;
      });
      obj.subCategoryIds = arr;
      delete obj.category;
      delete obj.openCategoryDropdown;
      delete obj.subCategory;
      categoriesSet.push(obj);
      return null;
    });
    salesInfos.map((sale, index) => {
      if (sale.value && sale.title) {
        extraInfo.push({
          type: constVariables.extraInfoTypes.saleInfo,
          title: sale.title,
          name: sale.value
        });
      }
      return null;
    });
    locations.map((location, index) => {
      if (location.value && location.title) {
        extraInfo.push({
          type: constVariables.extraInfoTypes.location,
          title: location.title,
          name: location.value
        });
      }
      return null;
    });
    others.map((other, index) => {
      if (other.title && other.website) {
        extraInfo.push({
          type: constVariables.extraInfoTypes.others,
          title: other.title,
          name: other.website
        });
      }
      return null;
    });
    if (contactInfo.title) {
      contactInformation.push({
        title: contactInfo.title,
        name: contactInfo.fullName,
        email: contactInfo.email,
        phoneNo: contactInfo.phoneNumber
      });
    }
    contacts.map((contact, index) => {
      if (contact.title) {
        contactInformation.push({
          title: contact.title,
          name: contact.fullName,
          email: contact.email,
          phoneNo: contact.phoneNumber
        });
      }
      return null;
    });
    if (data?.id) obj.id = data?.id;
    obj.name = generalInfo.vendorName;
    obj.websiteURL = generalInfo.website;
    obj.profileURL = profileUrlData;
    obj.categoriesSet = categoriesSet;
    obj.accountNumber = generalInfo.accountNo;
    obj.address = locationInfo.address;
    obj.state = locationInfo.state;
    obj.country = locationInfo.country;
    obj.contactInfo = contactInformation;
    obj.extraInfo = extraInfo;
    obj.isShopPayForShipping = salesInformation.shippingCosts === constVariables.addVendor.onTheBoutique ? true : false;
    callSaveVendorInfo({
      variables: {
        input: obj
      }
    });
  };

  return (
    <AddVendorPresentational
      show={show}
      resetState={refreshState}
      contacts={contacts}
      addContact={addContact}
      deleteContact={deleteContact}
      salesInfos={salesInfos}
      addSalesInfo={addSalesInfo}
      deleteSaleInfo={deleteSaleInfo}
      locations={locations}
      addLocation={addLocation}
      deleteLocation={deleteLocation}
      others={others}
      addOther={addOther}
      deleteOther={deleteOther}
      allCategory={categoryData?.getProductCategories?.categories}
      changeAppliedItems={changeAppliedItems}
      imageDetails={imageDetails}
      handleDrop={handleDrop}
      uploadImageError={uploadImageError}
      removeUploadedImage={removeUploadedImage}
      showValidation={showValidation}
      setShowValidation={setShowValidation}
      changeExtraContactFields={changeExtraContactFields}
      changeExtraSalesInfoFields={changeExtraSalesInfoFields}
      changeExtraLocationsFields={changeExtraLocationFields}
      changeExtraOthersFields={changeExtraOthersFields}
      generalInfo={generalInfo}
      contactInfo={contactInfo}
      salesInformation={salesInformation}
      locationInfo={locationInfo}
      editGeneralInfo={editGeneralInfo}
      editContactInfo={editContactInfo}
      editSalesInformation={editSaleInformation}
      editLocationInfo={editLocationInfo}
      hoverAction={hoverAction}
      setHoverAction={setHoverAction}
      hideContactInfo={hideContactInfo}
      toggleContactInfo={toggleContactInfo}
      onSaveVendor={onSaveVendorInfo}
      handleEnter={handleEnter}
      categoriesAndSubCategories={categoriesAndSubCategories}
      addAndRemoveCategories={addAndRemoveCategories}
      categoryInput={categoryInput}
      setCategoryInput={setCategoryInput}
      subCategoryInput={subCategoryInput}
      setSubCategoryInput={setsubCategoryInput}
      getSubCategories={getSubCategories}
      callCreateSubCategory={callCreateSubCategory}
      setCategoryIndex={setCategoryIndex}
      editVendor={data ? true : false}
      openSubCategory={openSubcategoryDropdown}
      setOpenSubCategory={setOpenSubcategoryDropdown}
      allSubCategories={allSubCategories}
      contactTitleInput={contactTitleInput}
      setContactTitleInput={setContactTitleInput}
      handleSendSuccessURL={handleSendSuccessURL}
      profileUrlData={profileUrlData}
      isLoading={loading}
    />
  );
};

export default AddVendor;
