import { FC } from 'react';

// Components
import GeneralInfoSectionPresentational from './GeneralInfoSection.presentational';

// Types
import { GeneralSection } from './GeneralInfoSection.types';

const GeneralInfoSection: FC<GeneralSection> = ({
  type,
  maxLength,
  generalInfo,
  errorMessages,
  setGeneralInfo,
  setFormChanges,
  setColApiCall,
  isDisableInput
}) => {
  return (
    <GeneralInfoSectionPresentational
      type={type}
      generalInfo={generalInfo}
      errorMessages={errorMessages}
      setGeneralInfo={setGeneralInfo}
      maxLength={maxLength}
      setFormChanges={setFormChanges}
      setColApiCall={setColApiCall}
      isDisableInput={isDisableInput}
    />
  );
};

export default GeneralInfoSection;
