// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const tableCellClick = (ref) => {
  const focusedCell = document.getElementsByClassName('focused-cell');
  if (focusedCell[0]) {
    focusedCell[0].classList.remove('focused-cell');
  }
  if (ref.current) {
    if (ref.current.classList.contains('focused-cell')) {
      ref.current.classList.remove('focused-cell');
    } else {
      ref.current.classList.add('focused-cell');
    }
  }
};
