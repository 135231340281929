import { FC, memo, useEffect, useState } from 'react';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Types
import { UserRoles } from 'src/components/pages/settings/UserPermissions/UserPermissions.type';

interface ProductCheckboxProps {
  productId: number;
  selectedProducts: number[];
  selectProduct: (e: any, productId: number) => void;
}

const ProductCheckbox: FC<ProductCheckboxProps> = ({ productId, selectProduct, selectedProducts }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(selectedProducts.includes(productId));
  }, [selectedProducts]);

  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  return (
    <div className="form-check form-check-sm form-check-custom">
      <input
        id={`product-checkbox-${productId}`}
        name="product-checkbox"
        className="me-5 form-check-input widget-9-check"
        type="checkbox"
        checked={checked}
        onChange={(e) => {
          setChecked(e.target.checked);
          selectProduct(e, productId);
        }}
        disabled={role === UserRoles.FULFILMENT_ASSOCIATE}
      />
    </div>
  );
};

export default memo(ProductCheckbox);
