import React, { FC } from 'react';

import { constVariables } from 'src/constants';

interface ProductPrimaryMediaDetailsProps {
  isAutoAsignPrimaryVideo: boolean;
  handleChangeAutoAssign: (value: boolean) => void;
}

const ProductPrimaryMediaDetails: FC<ProductPrimaryMediaDetailsProps> = ({ isAutoAsignPrimaryVideo, handleChangeAutoAssign }) => {
  return (
    <div className="card mb-0">
      <h4 className="section-title m-b-32">{constVariables.shopDetails.primaryMedia.key}</h4>
      <div className="row">
        <p className="mb-6">{constVariables.shopDetails.primaryMedia.description}</p>
        <div className="d-flex align-items-center">
          <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              checked={isAutoAsignPrimaryVideo}
              name="switchColumns"
              onChange={(e) => handleChangeAutoAssign(e.target.checked)}
            />
          </div>
          <div className="ms-3 mt-0 helper-text">Set Recent Recording as Primary</div>
        </div>
      </div>
    </div>
  );
};

export default ProductPrimaryMediaDetails;
