import { FC, useState, createRef, useRef, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { format, parseISO } from 'date-fns';

// Components
import { Img } from 'src/components/atoms';
import { Portal } from 'src/components/molecules';
import RecordingActionMenu from './RecordingActionMenu';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { setImageSrc } from 'src/utils/setImageSrc';
import { convertSecondstoTime } from 'src/utils/convertSecondstoTime';
import { useRecordingsDownloader } from 'src/context/RecordingsDownloader/useRecordingsDownloader';

// Icons
import { DefaultImage, ProductDragIcon, PrimaryVideoIcon, VideoPlayerIcon, ThreeDotsVerticalIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { RecordingData } from 'src/components/pages/products/AddProduct/AddProduct.types';

interface RecordingItemProps {
  recording: RecordingData;
  index: number;
  handleShowPreviewModal: (index: number) => void;
  primaryVideoId: string;
  setPrimaryVideoId: React.Dispatch<React.SetStateAction<string>>;
  setIsPrimaryRecordingEmpty: React.Dispatch<React.SetStateAction<boolean>>;
  onDragEnd: (fromIndex: number, toIndex: number) => void;
  setIsAutoAssignPrimaryVideo: React.Dispatch<React.SetStateAction<boolean>>;
}

const RecordingItem: FC<RecordingItemProps> = ({
  recording,
  index,
  handleShowPreviewModal,
  primaryVideoId,
  setPrimaryVideoId,
  setIsPrimaryRecordingEmpty,
  onDragEnd,
  setIsAutoAssignPrimaryVideo
}) => {
  const menuRef = createRef<any>();
  const [showDragsIcon, setShowDragsIcon] = useState(false);
  const [showMenuDropdown, setShowMenuDropdown] = useState(false);
  const [dropdownPositions, setDropdownPositions] = useState({
    top: 0,
    left: 0
  });
  const { downloadRecordings } = useRecordingsDownloader();

  const handleShowMenuDropdown = (e) => {
    e.stopPropagation();
    setShowMenuDropdown(true);

    if (menuRef.current) {
      const position = menuRef.current.getBoundingClientRect();
      setDropdownPositions({
        top: window.scrollY + position.top + position.height + 4,
        left: position.left
      });
    }
  };

  const handleSelectOption = async (e, optionId) => {
    e.stopPropagation();
    if (optionId === 1) {
      onDragEnd(index, 0);
    } else if (optionId === 2) {
      setPrimaryVideoId('');
      setIsPrimaryRecordingEmpty(true);
    } else if (optionId === 3) {
      downloadRecordings?.(recording?.url, recording?.title, recording?.duration);
    }
    setShowMenuDropdown(false);
  };

  return (
    <li
      onClick={() => handleShowPreviewModal(index)}
      className="recording-item cursor-pointer"
      onMouseEnter={() => setShowDragsIcon(true)}
      onMouseLeave={() => setShowDragsIcon(false)}
    >
      {showDragsIcon && (
        <KTSVG
          path={ProductDragIcon}
          svgColor="#fff"
          className="drags-video position-absolute cursor-pointer d-flex align-items-center justify-content-center"
        />
      )}
      <button
        className={`cursor-pointer actions-menu position-absolute d-flex align-items-center justify-content-center ${
          showMenuDropdown ? 'active-btn' : ''
        }`}
        onClick={(e) => handleShowMenuDropdown(e)}
        ref={menuRef}
      >
        <KTSVG path={ThreeDotsVerticalIcon} svgColor="#fff" />
      </button>
      {showMenuDropdown && (
        <Portal id="kt_body">
          <OutsideClickHandler
            onOutsideClick={() => {
              if (showMenuDropdown) setShowMenuDropdown(false);
            }}
          >
            <div className="position-absolute" style={{ top: dropdownPositions.top, left: dropdownPositions.left }}>
              <RecordingActionMenu
                show={showMenuDropdown}
                data={
                  recording.id === primaryVideoId
                    ? constVariables.recordingOptionsDropdownData.filter((option) => option.id !== 1)
                    : constVariables.recordingOptionsDropdownData.filter((option) => option.id !== 2)
                }
                onSelectOption={handleSelectOption}
              />
            </div>
          </OutsideClickHandler>
        </Portal>
      )}
      <Img
        src={setImageSrc(recording?.thumbnailUrl ? recording?.thumbnailUrl : '')}
        placeholderImg={DefaultImage}
        errorImg={DefaultImage}
        className="h-100 w-100 product-recording-image"
      />
      <div className="position-absolute d-flex align-items-center recording-date">
        <Img src={VideoPlayerIcon} />
        <span className="ms-1">{convertSecondstoTime(recording.duration)}&nbsp;-&nbsp;</span>
        <span>{format(parseISO(recording.createdAt), 'MM/dd/yy')}</span>
      </div>
      {recording.id === primaryVideoId && <Img src={PrimaryVideoIcon} className="position-absolute primary-video-icon" />}
    </li>
  );
};

export default RecordingItem;
