import { gql } from '@apollo/client';

export const DELETE_LOCATION = gql`
  mutation onDeleteLocation($input: DeleteLocationInput!) @api(name: "productsAPI") {
    deleteLocation(input: $input) {
      success
      message
    }
  }
`;
