import { FC } from 'react';
import clsx from 'clsx';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { CloseIcon, RedWarningIcon } from 'src/assets/icons';

// Styles
import 'src/components/oraganisms/CustomModal/modalStyles.scss';

export interface LeaveFulfillmentCenterModalProps {
  title: string;
  message?: string;
  actionBtnTitle?: string;
  cancelBtnTitle?: string;
  isOptions?: boolean;
  actionBtnHandler?: (...args: any) => void;
  cancelBtnHandler?: (...args: any) => void;
}

const LeaveFulfillmentCenterModal: FC<LeaveFulfillmentCenterModalProps> = ({
  title,
  actionBtnTitle,
  cancelBtnTitle,
  actionBtnHandler,
  cancelBtnHandler,
  message
}) => {
  return (
    <div className={clsx('modal-content cancelOrder modal-large-content')}>
      <div className="modal-header p-x-30 p-t-20 pb-0 border-none justify-content-end">
        <div className="btn btn-xs btn-active-light-primary p-0 m-0" data-bs-dismiss="modal" aria-label="Close">
          <KTSVG path={CloseIcon} className="svg-icon svg-icon-2x m-0" onClick={cancelBtnHandler} />
        </div>
      </div>
      <div className={clsx('modal-body p-x-30 p-t-24 p-b-36')}>
        <div className="d-flex flex-column align-items-center justify-content-center m-b-24">
          <img src={RedWarningIcon} className="h-40px m-b-8" />
          <h2 className={`error-modal-title text-danger poppins-medium mb-0`}>{title}</h2>
        </div>
        <div>{message}</div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-light btn-md" data-bs-dismiss="modal" onClick={cancelBtnHandler}>
          {cancelBtnTitle}
        </button>
        <button type="button" className="btn btn-danger btn-md" onClick={actionBtnHandler}>
          {actionBtnTitle}
        </button>
      </div>
    </div>
  );
};

export default LeaveFulfillmentCenterModal;
