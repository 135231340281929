import { gql } from '@apollo/client';

export const GET_LIVE_STREAM_DETAILS = gql`
  query GetLiveStreamDetails($id: String!) @api(name: "liveStreamingAPI") {
    getLiveStreamDetails(id: $id) {
      __typename
      id
      title
      description
      thumbnailUrl
      startingAt
      endingAt
      startedAt
      endedAt
      viewersCount
      products {
        productId
        liveProductId
        productReferencedId
        productName
        productPrice
        productImage
      }
      streamOnApp
      streamOnWebsite
      facebookChannels {
        id
        name
        type
      }
      notification {
        id
        textMessage
        app {
          title
          description
        }
        fb {
          title
          description
        }
        messenger {
          title
          description
        }
      }
    }
  }
`;
