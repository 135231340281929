import { FC, useEffect, useState } from 'react';
import WorldData from 'country-state-city-plus';

// Apollo
import { useLazyQuery, useMutation } from '@apollo/client';
import { AUTO_COMPLETE_ADDRESS } from 'src/apollo/queries';
import { ADD_ADDRESS } from 'src/apollo/mutations';

// Components
import AddCustomerAddressPresentational from './AddCustomerAddress.presentational';

// Types
import { AddCustomerAddressProps, CustomerAddressDataProps, CustomerAddressDropdownProps } from './AddCustomerAddress.types';

const AddCustomerAddress: FC<AddCustomerAddressProps> = ({
  show,
  resetState,
  customerId,
  closeAddressModal,
  customerAddress,
  isEditing
}) => {
  const refreshState = (addressId?: number) => {
    setCustomerAddressData({
      reciverName: '',
      emailAddress: '',
      addressLineOne: '',
      addressError: false,
      addressLineTwo: '',
      postalCode: '',
      phoneNumber: '',
      countryDropDown: false,
      selectedCountryCode: '',
      selectedCountryId: '',
      selectedCountry: '',
      cityDropDown: false,
      selectedCity: '',
      stateDropDown: false,
      selectedState: '',
      addressDropDownOne: false,
      addressDropDownTwo: false,
      selectedStateCode: '',
      phoneDropDown: false,
      selectedPhone: '',
      addressType: '',
      defaultShippingAddress: false
    });
    resetState(addressId);
  };

  const closeAddressModalPassage = () => {
    closeAddressModal();
    refreshState();
  };
  const [countryList, setCountryList] = useState<CustomerAddressDropdownProps[]>();
  const [cityList, setCityList] = useState<CustomerAddressDropdownProps[]>();
  const [stateList, setStateList] = useState<CustomerAddressDropdownProps[]>();
  const [phoneList, setPhoneList] = useState<CustomerAddressDropdownProps[]>();
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);
  const [addressList, setAddressList] = useState([]);
  const [customerAddressData, setCustomerAddressData] = useState<CustomerAddressDataProps>({
    reciverName: '',
    emailAddress: '',
    addressLineOne: '',
    addressError: false,
    addressLineTwo: '',
    postalCode: '',
    phoneNumber: '',
    countryDropDown: false,
    selectedCountryCode: '',
    selectedCountryId: '',
    selectedCountry: '',
    cityDropDown: false,
    selectedCity: '',
    stateDropDown: false,
    selectedState: '',
    addressDropDownOne: false,
    addressDropDownTwo: false,
    selectedStateCode: '',
    phoneDropDown: false,
    selectedPhone: '',
    addressType: '',
    defaultShippingAddress: false
  });
  const [autocompleteAddress] = useLazyQuery(AUTO_COMPLETE_ADDRESS, {
    variables: { input: { street: customerAddressData.addressLineOne } },
    onCompleted: (data) => {
      if (data?.autoComplete) {
        const tempList = data?.autoComplete.map((data, index) => {
          return { id: index, name: `${data.street_line}, ${data.city}, ${data.state}` };
        });
        setAddressList(data?.autoComplete);
        setAddressListFiltered(tempList);
      }
    }
  });
  const [autocompleteAddressTwo] = useLazyQuery(AUTO_COMPLETE_ADDRESS, {
    variables: { input: { street: customerAddressData.addressLineTwo } },
    onCompleted: (data) => {
      if (data?.autoComplete) {
        const tempList = data?.autoComplete.map((data, index) => {
          return { id: index, name: `${data.street_line}, ${data.city}, ${data.state}` };
        });
        setAddressList(data?.autoComplete);
        setAddressListFiltered(tempList);
      }
    }
  });

  const getCountryAndPhoneList = () => {
    const getAllCountries = WorldData.getAllCountries();
    if (getAllCountries) {
      const tempCountryList = getAllCountries.map((data, index) => {
        return { id: index, name: data.name, extraData: data.id, countryCode: data.sortname };
      });
      setCountryList(tempCountryList);

      const tempPhoneList = getAllCountries.map((data, index) => {
        return { id: index, name: data.phonecode.toString() };
      });
      setPhoneList(tempPhoneList);
    }
  };

  const getCityData = () => {
    const tempCityArray = WorldData.getCitiesOfState(customerAddressData.selectedStateCode);

    if (tempCityArray) {
      const tempList = tempCityArray.map((data, index) => {
        return { id: index, name: data.name };
      });
      setCityList(tempList);
    }
  };

  const getStateData = () => {
    const tempStateArray = WorldData.getStatesOfCountry(customerAddressData.selectedCountryId);
    if (tempStateArray) {
      const tempList = tempStateArray.map((data, index) => {
        return { id: index, name: data.name, extraData: data.id };
      });
      setStateList(tempList);
    }
  };

  const getPhoneCode = () => {
    const tempPhone = WorldData.getCountryById(customerAddressData.selectedCountryId);
    if (tempPhone) {
      setCustomerAddressData((prev) => ({
        ...prev,
        selectedPhone: tempPhone.phonecode[0] !== '+' ? `+${tempPhone.phonecode}` : tempPhone.phonecode
      }));
    }
  };

  useEffect(() => {
    getCountryAndPhoneList();
  }, []);

  useEffect(() => {
    if (stateList) {
      getCityData();
    }
  }, [customerAddressData.selectedStateCode]);

  useEffect(() => {
    if (customerAddressData.selectedCountryCode) {
      getStateData();
      getPhoneCode();
    }
  }, [customerAddressData.selectedCountryCode]);

  useEffect(() => {
    if (customerAddress && isEditing) {
      setCustomerAddressData({
        reciverName: customerAddress?.receiverName ? customerAddress?.receiverName : '',
        emailAddress: '',
        addressLineOne: customerAddress?.addressLine1 ? customerAddress?.addressLine1 : '',
        addressError: false,
        addressLineTwo: customerAddress?.addressLine2 ? customerAddress?.addressLine2 : '',
        postalCode: customerAddress?.pincode ? customerAddress?.pincode : '',
        phoneNumber: customerAddress?.phoneNumber ? customerAddress?.phoneNumber : '',
        countryDropDown: false,
        selectedCountryCode: '',
        selectedCountryId: '',
        selectedCountry: customerAddress?.country ? customerAddress?.country : '',
        cityDropDown: false,
        selectedCity: customerAddress?.city ? customerAddress?.city : '',
        stateDropDown: false,
        selectedState: customerAddress?.state ? customerAddress?.state : '',
        addressDropDownOne: false,
        addressDropDownTwo: false,
        selectedStateCode: '',
        phoneDropDown: false,
        selectedPhone: '',
        addressType: customerAddress?.type ? customerAddress?.type : '',
        defaultShippingAddress: customerAddress?.isPrimary
      });
    }
  }, [customerAddress, isEditing]);

  const [callAddAddress] = useMutation(ADD_ADDRESS, {
    onCompleted: (res) => {
      refreshState(res?.addAddress?.newAddressId);
      setDisableSaveButton(false);
    },
    onError: (error) => {
      setDisableSaveButton(false);
      console.error('Add address  error', error);
    }
  });

  const [callEditAddress] = useMutation(ADD_ADDRESS, {
    onCompleted: () => {
      refreshState();
      setDisableSaveButton(false);
    },
    onError: (error) => {
      setDisableSaveButton(false);
      console.error('edit address  error', error);
    }
  });

  const editCustomerAddress = () => {
    setDisableSaveButton(true);
    callEditAddress({
      variables: {
        input: {
          receiverName: customerAddressData.reciverName,
          receiverMobileCountryCode: customerAddressData.selectedPhone,
          receiverMobileNumber: customerAddressData.phoneNumber,
          addressLine1: customerAddressData.addressLineOne,
          city: customerAddressData.selectedCity,
          state: customerAddressData.selectedState,
          country: customerAddressData.selectedCountry,
          pincode: customerAddressData.postalCode,
          addressType: customerAddressData.addressType,
          isPrimary: customerAddressData.defaultShippingAddress,
          phoneNumber: customerAddressData.phoneNumber,
          userId: customerId,
          id: customerAddress?.id
        }
      }
    });
  };

  const addCustomerAddress = () => {
    setDisableSaveButton(true);

    callAddAddress({
      variables: {
        input: {
          receiverName: customerAddressData.reciverName,
          receiverMobileCountryCode: customerAddressData.selectedPhone,
          receiverMobileNumber: customerAddressData.phoneNumber,
          addressLine1: customerAddressData.addressLineOne,
          city: customerAddressData.selectedCity,
          state: customerAddressData.selectedState,
          country: customerAddressData.selectedCountry,
          pincode: customerAddressData.postalCode,
          addressType: customerAddressData.addressType,
          isPrimary: customerAddressData.defaultShippingAddress,
          phoneNumber: customerAddressData.phoneNumber,
          userId: customerId
        }
      }
    });
  };

  const [countryListFiltered, setCountryListFiltered] = useState<CustomerAddressDropdownProps[]>();
  const [stateListFiltered, setStateListFiltered] = useState<CustomerAddressDropdownProps[]>();
  const [addressListFiltered, setAddressListFiltered] = useState<CustomerAddressDropdownProps[]>();
  const [cityListFiltered, setCityListFiltered] = useState<CustomerAddressDropdownProps[]>();
  const addReciverName = (data: string) => {
    setCustomerAddressData((prev) => ({
      ...prev,
      reciverName: data
    }));
  };
  const addEmailAddress = (data: string) => {
    setCustomerAddressData((prev) => ({
      ...prev,
      emailAddress: data
    }));
  };
  const changeAddressLineOne = (data: string) => {
    setCustomerAddressData((prev) => ({
      ...prev,
      addressLineOne: data,
      addressError: true
    }));
  };
  const changeAddressLineTwo = (data: string) => {
    setCustomerAddressData((prev) => ({
      ...prev,
      addressLineTwo: data
    }));
  };
  useEffect(() => {
    autocompleteAddress();
  }, [customerAddressData.addressLineOne]);
  useEffect(() => {
    autocompleteAddressTwo();
  }, [customerAddressData.addressLineTwo]);

  const changePostalCode = (data: any) => {
    if (!isNaN(data)) {
      setCustomerAddressData((prev) => ({
        ...prev,
        postalCode: data
      }));
    }
  };

  const changeAddressType = (data) => {
    setCustomerAddressData((prev) => ({
      ...prev,
      addressType: data
    }));
  };

  const changeCountry = (data: string) => {
    const tempList = countryList?.filter((listData) => listData.name.toUpperCase().indexOf(data.toUpperCase()) !== -1);
    setCountryListFiltered(tempList);
    setCustomerAddressData((prev) => ({
      ...prev,
      selectedCountry: data
    }));
  };
  const changeCity = (data: string) => {
    const tempList = cityList?.filter((listData) => listData.name.toUpperCase().indexOf(data.toUpperCase()) !== -1);
    setCityListFiltered(tempList);
    setCustomerAddressData((prev) => ({
      ...prev,
      selectedCity: data
    }));
  };
  const changeState = (data: string) => {
    const tempList = stateList?.filter((listData) => listData.name.toUpperCase().indexOf(data.toUpperCase()) !== -1);
    setStateListFiltered(tempList);
    setCustomerAddressData((prev) => ({
      ...prev,
      selectedState: data
    }));
  };

  const changePhoneNumber = (data: any) => {
    if (!isNaN(data)) {
      setCustomerAddressData((prev) => ({
        ...prev,
        phoneNumber: data
      }));
    }
  };

  const toggleCountryCodeDropDown = () => {
    if (countryList) {
      setCustomerAddressData((prev) => ({
        ...prev,
        countryDropDown: !prev.countryDropDown
      }));
    }
  };
  const onSelectCountryCode = (data: any) => {
    setCustomerAddressData((prev) => ({
      ...prev,
      selectedCountry: data.countryCode,
      selectedCountryId: data.extraData,
      selectedCountryCode: data.countryCode,
      countryDropDown: !prev.countryDropDown
    }));
  };
  const toggleCityDropDown = () => {
    if (cityList) {
      setCustomerAddressData((prev) => ({
        ...prev,
        cityDropDown: !prev.cityDropDown
      }));
    }
  };
  const onSelectCity = (data: any) => {
    setCustomerAddressData((prev) => ({
      ...prev,
      selectedCity: data.name,
      cityDropDown: !prev.cityDropDown
    }));
  };
  const toggleStateDropDown = () => {
    if (stateList) {
      setCustomerAddressData((prev) => ({
        ...prev,
        stateDropDown: !prev.stateDropDown
      }));
    }
  };
  const toggleAddressDropDownOne = () => {
    setCustomerAddressData((prev) => ({
      ...prev,
      addressDropDownOne: !prev.addressDropDownOne
    }));
  };
  const toggleAddressDropDownTwo = () => {
    setCustomerAddressData((prev) => ({
      ...prev,
      addressDropDownTwo: !prev.addressDropDownTwo
    }));
  };
  const onSelectState = (data: any) => {
    setCustomerAddressData((prev) => ({
      ...prev,
      selectedState: data.name,
      selectedStateCode: data.extraData,
      stateDropDown: !prev.stateDropDown
    }));
  };
  const onSelectAddress = (data: any) => {
    const result: {
      zipcode: string;
      state: string;
      city: string;
      // eslint-disable-next-line camelcase
      street_line: string;
    } =
      // esline-disable-next-line
      addressList.find((findData: any) => data.name === `${findData?.street_line}, ${findData.city}, ${findData.state}`) || {
        zipcode: '',
        state: '',
        city: '',
        // eslint-disable-next-line camelcase
        street_line: ''
      };
    setCustomerAddressData((prev) => ({
      ...prev,
      addressLineOne: result.street_line,
      addressError: false,
      addressDropDownOne: false,
      addressDropDownTwo: false,
      postalCode: result?.zipcode,
      selectedCountry: 'US',
      selectedCountryCode: 'US',
      selectedPhone: '+1',
      selectedState: result?.state,
      selectedCity: result?.city
    }));
  };

  const togglePhoneDropDown = () => {
    if (phoneList) {
      setCustomerAddressData((prev) => ({
        ...prev,
        phoneDropDown: !prev.phoneDropDown
      }));
    }
  };
  const toggleDefaultShippingAddress = () => {
    if (phoneList) {
      setCustomerAddressData((prev) => ({
        ...prev,
        defaultShippingAddress: !prev.defaultShippingAddress
      }));
    }
  };

  const onSelectPhone = (data: any) => {
    let tempNumber = data.name;
    if (data.name[0] !== '+') {
      tempNumber = `+${tempNumber}`;
    }
    setCustomerAddressData((prev) => ({
      ...prev,
      selectedPhone: tempNumber,
      phoneDropDown: false
    }));
  };
  useEffect(() => {
    if (countryList) {
      setCountryListFiltered(countryList);
    }
  }, [countryList]);
  useEffect(() => {
    if (stateList) {
      setStateListFiltered(stateList);
    }
  }, [stateList]);
  useEffect(() => {
    if (cityList) {
      setCityListFiltered(cityList);
    }
  }, [cityList]);
  return (
    <AddCustomerAddressPresentational
      isEdit={isEditing}
      customerAddressData={customerAddressData}
      closeAddressModal={closeAddressModalPassage}
      setCustomerAddressData={setCustomerAddressData}
      show={show}
      countryListFiltered={countryListFiltered}
      cityListFiltered={cityListFiltered}
      stateListFiltered={stateListFiltered}
      addressListFiltered={addressListFiltered}
      phoneList={phoneList}
      addCustomerAddress={isEditing ? editCustomerAddress : addCustomerAddress}
      addReciverName={addReciverName}
      addEmailAddress={addEmailAddress}
      changeAddressLineOne={changeAddressLineOne}
      changeAddressLineTwo={changeAddressLineTwo}
      changePostalCode={changePostalCode}
      changeCountry={changeCountry}
      changeCity={changeCity}
      changeState={changeState}
      changePhoneNumber={changePhoneNumber}
      toggleCountryCodeDropDown={toggleCountryCodeDropDown}
      onSelectCountryCode={onSelectCountryCode}
      toggleCityDropDown={toggleCityDropDown}
      onSelectCity={onSelectCity}
      toggleStateDropDown={toggleStateDropDown}
      toggleAddressDropDownOne={toggleAddressDropDownOne}
      toggleAddressDropDownTwo={toggleAddressDropDownTwo}
      onSelectState={onSelectState}
      changeAddressType={changeAddressType}
      onSelectAddress={onSelectAddress}
      toggleDefaultShippingAddress={toggleDefaultShippingAddress}
      togglePhoneDropDown={togglePhoneDropDown}
      onSelectPhone={onSelectPhone}
      disableSaveButton={disableSaveButton}
    />
  );
};

export default AddCustomerAddress;
