import { gql } from '@apollo/client';

export const DELETE_LIVE_STREAM = gql`
  mutation DeleteLiveStream($id: String!) @api(name: "liveStreamingAPI") {
    deleteLiveStream(id: $id) {
      id
      success
      message
    }
  }
`;
