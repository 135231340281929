import { gql } from '@apollo/client';

export const ADD_PRODUCT_TO_TIKTOK = gql`
  mutation AddProductToTiktok($input: AddProductToTiktokInput!) @api(name: "tiktokAPI") {
    addProductToTiktok(input: $input) {
      id
      success
    }
  }
`;
