import React, { FC } from 'react';
import CustomModalPopupPresentational from './CustomModalPopup.presentational';

interface ModalPopupProps {
  title: string;
  message: string;
  cancelButton: string;
  confirmButton: string;
  open: boolean;
  handleConfirm(e: React.MouseEvent): void;
  closeModal(): void;
  icon?: boolean;
  type?: string;
}

const CustomModalPopup: FC<ModalPopupProps> = (props) => {
  const { title, message, cancelButton, confirmButton, open, icon, handleConfirm, closeModal, type } = props;
  return (
    <CustomModalPopupPresentational
      title={title}
      message={message}
      icon={icon}
      open={open}
      cancelButton={cancelButton}
      confirmButton={confirmButton}
      handleConfirm={handleConfirm}
      closeModal={closeModal}
      type={type}
    />
  );
};

export default CustomModalPopup;
