import { FC } from 'react';

// Components
import { FlexBox } from 'src/components/atoms';
import {
  CustomModal,
  ErrorModal,
  OrderDetailsFulfillmentCenter,
  OrderToFulfilListFulfillmentCenter
} from 'src/components/oraganisms';
import LeaveFulfillmentCenterModal from './LeaveFulfillmentCenterModal';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { FulfillmentCenterProps } from './FulfillmentCenter.type';

// Styles
import './_fulfillmentCenter.scss';

const FulfillmentCenterPresentational: FC<FulfillmentCenterProps> = ({
  isPageloading,
  isLoadingOrderDetails,
  ordersListData,
  ordersToFulfillListData,
  searchOrderValue,
  changeSearchOrderValue,
  selectedOrder,
  setSelectedOrder,
  orderDetails,
  refetchOrderDetails,
  totalProducts,
  orderShipment,
  refetchOrderShipment,
  selectedStatus,
  setSelectedStatus,
  refetchOrderList,
  leaveModal,
  leavePageHandler,
  setLeaveModal,
  errorModal,
  setErrorModal,
  errorModalObj,
  setErrorModalObj,
  openRefundModalRemotely,
  setOpenRefundModalRemotely,
  updateOrderDetails
}) => {
  const closeLeaveModal = () => {
    document.body.style.overflow = 'unset';
    setLeaveModal(false);
  };

  const closeErroModal = () => {
    document.body.style.overflow = 'unset';
    setErrorModal(false);
  };

  return (
    <>
      {leaveModal && (
        <CustomModal show={leaveModal} closeModal={closeLeaveModal}>
          <LeaveFulfillmentCenterModal
            title={constVariables.FulfillmentCenter.leavePageModal.title}
            actionBtnTitle={constVariables.FulfillmentCenter.leavePageModal.actionButtonTitle}
            cancelBtnTitle={constVariables.FulfillmentCenter.leavePageModal.cancelButtonTitle}
            message={constVariables.FulfillmentCenter.leavePageModal.message}
            actionBtnHandler={() => {
              document.body.style.overflow = 'unset';
              leavePageHandler();
            }}
            cancelBtnHandler={closeLeaveModal}
          />
        </CustomModal>
      )}
      {errorModal && (
        <CustomModal bodyClassname="w-90 w-md-50" show={errorModal} closeModal={closeErroModal}>
          <ErrorModal
            type="showTitle"
            actionTitle={errorModalObj.action}
            title={errorModalObj.title}
            message={errorModalObj.message}
            actionHandler={closeErroModal}
            cancelHandler={closeErroModal}
            errors={[]}
            otherIcon={errorModalObj.otherIcon}
          />
        </CustomModal>
      )}
      <FlexBox>
        <div className={`width-animation w-100 d-flex flex-column hide-scrollbar  `}>
          <div className="d-flex flex-column flex-xl-row flex-grow-1">
            <div className=" d-flex leftFulfilmentBlock flex-column">
              {/* <ProfileBlockFulfillmentCenter
              imageUrl="https://dragonball.guru/wp-content/uploads/2021/01/goku-dragon-ball-guru.jpg"
              name="Sarah"
              orders={ordersToFulfillListData?.total || 0}
              products={totalProducts}
            /> */}

              <div className="d-flex flex-column flex-grow-1">
                <OrderToFulfilListFulfillmentCenter
                  searchOrderValue={searchOrderValue}
                  changeSearchOrderValue={changeSearchOrderValue}
                  ordersListData={selectedStatus.includes('awaiting_fulfillment') ? ordersToFulfillListData : ordersListData}
                  orders={ordersToFulfillListData?.total || 0}
                  selectedOrder={selectedOrder}
                  setSelectedOrder={setSelectedOrder}
                  selectedStatus={selectedStatus}
                  setSelectedStatus={setSelectedStatus}
                  isLoading={isPageloading}
                />
              </div>
            </div>
            <div className="ms-2  rightFulfilmentBlock d-flex flex-column">
              <OrderDetailsFulfillmentCenter
                orderDetails={orderDetails}
                refetchOrderDetails={refetchOrderDetails}
                orderShipment={orderShipment}
                refetchOrderShipment={refetchOrderShipment}
                refetchOrderList={refetchOrderList}
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
                openRefundModalRemotely={openRefundModalRemotely}
                setOpenRefundModalRemotely={setOpenRefundModalRemotely}
                setErrorModal={setErrorModal}
                setErrorModalObj={setErrorModalObj}
                isLoading={isLoadingOrderDetails}
                updateOrderDetails={updateOrderDetails}
                selectedOrder={selectedOrder}
                setSelectedOrder={setSelectedOrder}
              />
            </div>
          </div>
        </div>
      </FlexBox>
    </>
  );
};

export default FulfillmentCenterPresentational;
