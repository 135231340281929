import { FC, useState } from 'react';
import { Link } from 'react-router-dom';

// Components
import { Img, FlexBox } from 'src/components/atoms';
import LabelNumberInput from './LabelNumberInput';
import ProductVariantLabel from './ProductVariantLabel';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import { DownArrowIcon, DefaultImage } from 'src/assets/icons';

// Types
import { ProductLabelProps } from './ProductLabel.types';

// Styles
import './_productLabel.scss';

const ProductLabel: FC<ProductLabelProps> = ({ productLabel, labelPrintType, handleUpdateLabelCount }) => {
  const [showVariants, setShowVariants] = useState(true);

  return (
    <div>
      <FlexBox className="align-items-center justify-content-between">
        <FlexBox className="align-items-center">
          <KTSVG
            path={DownArrowIcon}
            className={`${showVariants ? '' : 'rotated-icon'} ${
              productLabel?.variants?.length > 0 ? 'cursor-pointer' : 'opacity-0'
            }`}
            onClick={() => setShowVariants(!showVariants)}
          />
          <FlexBox className="p-x-16 p-y-8 align-items-center">
            <div className="symbol m-r-20">
              <Img
                src={setImageSrc(
                  productLabel?.productMediaUrl ? generateCompressedImageURL(productLabel?.productMediaUrl, '50') : DefaultImage
                )}
                placeholderImg={DefaultImage}
                errorImg={DefaultImage}
                className="object-fit-scale-down bg-white border border-light border-2"
              />
            </div>
            <div style={{ maxWidth: 300 }}>
              <Link to={`/products/allProducts/edit/${productLabel.productId}`} className="main-cell" target="_blank">
                <span className="d-block">{productLabel.productName}</span>
              </Link>
              <p className="mt-1 mb-0">
                {productLabel?.productQty} in stock{' '}
                {productLabel?.variants?.length > 0 ? `for ${productLabel?.variants?.length} variants` : ''}
              </p>
            </div>
          </FlexBox>
        </FlexBox>
        <LabelNumberInput
          labelProductType="product"
          labelPrintType={labelPrintType}
          data={productLabel}
          productId={productLabel.productId}
          handleUpdateLabelCount={handleUpdateLabelCount}
        />
      </FlexBox>
      {showVariants && (
        <div>
          {productLabel?.variants?.length > 0 &&
            productLabel?.variants?.map((productVariant, index) => (
              <ProductVariantLabel
                key={index}
                productVariant={productVariant}
                labelPrintType={labelPrintType}
                productId={productLabel?.productId}
                handleUpdateLabelCount={handleUpdateLabelCount}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default ProductLabel;
