import { FC } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { CSVBoxButton } from '@csvbox/react';

// Components
import { FlexBox } from 'src/components/atoms';
import { EditColumn, CSVboxModal } from 'src/components/molecules';

// Icons
import { BackRoundedIcon, ExportIcon, ImportIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables, envUrls } from 'src/constants';

// Types
import { PageHeaderProps } from './PageHeader.types';

// Styles
import './PageHeader.scss';

const PageHeader: FC<PageHeaderProps> = ({
  pageTitle,
  addButton,
  removeExportImport,
  onlyImport,
  onlyExport,
  csvTemplate,
  onAddButton,
  HeadingExtenstion,
  HeaderRowElement,
  backButton,
  backbtnHandler,
  isEditFields,
  openEditColumnDropdown,
  setOpenEditColumnDropdown,
  editColumnData,
  setEditColumnData
}) => {
  return (
    <FlexBox className="w-100 mx-0 justify-content-between custom-page-header">
      <FlexBox className="align-items-center">
        {backButton && backbtnHandler ? (
          <img src={BackRoundedIcon} alt={BackRoundedIcon} className="cursor-pointer me-4" onClick={backbtnHandler} />
        ) : null}
        <h2 className="page-title m-0">{pageTitle}</h2>
        {HeadingExtenstion ? <HeadingExtenstion /> : null}
      </FlexBox>
      <FlexBox className="align-items-center">
        {!removeExportImport && (
          <>
            <button className="d-flex bg-transparent m-auto-0 p-2 border border-transparent">
              <img className="my-auto mx-2" src={ExportIcon} alt={ExportIcon} />
              <span className="dark-color textImportExport">{constVariables.AllProductsMenu.export}</span>
            </button>
            <CSVboxModal
              template={csvTemplate}
              child={(launch) => (
                <div className="csv-upload-div">
                  <img className="my-auto mx-2" src={ImportIcon} alt={ImportIcon} />
                  <button className="csv-upload-button" data-csvbox onClick={launch}>
                    Import
                  </button>
                </div>
              )}
            />
          </>
        )}
        {onlyImport && (
          <div className="cursor-pointer csv-import-btn me-3">
            <CSVBoxButton
              licenseKey={envUrls.csvBoxLicenseKey}
              user={{
                userId: localStorage.getItem('userId') ? localStorage.getItem('userId') : null,
                storeId: localStorage.getItem('storeId') ? localStorage.getItem('storeId') : null
              }}
              onImport={async (result, data) => {
                if (result) {
                  console.log('success');
                  console.log(` ${data.row_success} rows uploaded`);
                  console.log('data we had -', data);
                  // await axios.post(envUrls.csvBoxImportURL, data).then();
                } else {
                  console.log('fail');
                  // custom code
                }
              }}
            >
              <img className="my-auto mx-2" src={ImportIcon} alt={ImportIcon} />
              <span className="dark-color textImportExport">{constVariables.AllProductsMenu.import}</span>
            </CSVBoxButton>
          </div>
        )}
        {onlyExport ? (
          <button className="d-flex bg-transparent m-auto-0 p-2 border border-transparent">
            <img className="my-auto mx-2" src={ExportIcon} alt={ExportIcon} />
            <span className="dark-color textImportExport">{constVariables.AllProductsMenu.export}</span>
          </button>
        ) : null}
        {HeaderRowElement ? <HeaderRowElement /> : null}
        {isEditFields ? (
          <OutsideClickHandler
            onOutsideClick={() => {
              if (setOpenEditColumnDropdown) setOpenEditColumnDropdown(false);
            }}
          >
            <div className="position-relative ms-3">
              <div
                className="text-primary text-btn cursor-pointer"
                onClick={() => {
                  if (setOpenEditColumnDropdown) setOpenEditColumnDropdown(!openEditColumnDropdown);
                }}
              >
                {constVariables.AllProductsMenu.editColumns}
              </div>
              <EditColumn
                show={openEditColumnDropdown ? openEditColumnDropdown : false}
                columns={editColumnData || []}
                setEditColumnData={setEditColumnData}
                allSwitchable={true}
              />
            </div>
          </OutsideClickHandler>
        ) : null}
        {addButton ? (
          <div className="my-auto ms-3 me-0">
            <button
              className="btn btn-primary btn-md me-0"
              onClick={() => {
                if (onAddButton) onAddButton();
              }}
            >
              {addButton}
            </button>
          </div>
        ) : null}
      </FlexBox>
    </FlexBox>
  );
};

export default PageHeader;
