import { FC, useState } from 'react';

// Apollo
import { useMutation, useQuery } from '@apollo/client';
import { GET_CUSTOMER_DETAILS } from 'src/apollo/queries';

// Components
import CustomerTaxcodePresentational from './CustomerTaxcode.presentational';

// Types
import { UPDATE_PROFILE_INFO } from 'src/apollo/mutations';
import { useToast } from 'src/utils/hooks/useToast';

export interface ICustomerTaxcode {
  customerId: string;
}

const CustomerTaxcode: FC<ICustomerTaxcode> = ({ customerId }) => {
  const { showToast } = useToast();

  const { data } = useQuery(GET_CUSTOMER_DETAILS, {
    variables: {
      input: {
        userId: parseInt(customerId, 10)
      }
    }
  });
  const [code, setCode] = useState(data?.getCustomerDetails?.taxEntityUseCode);
  const [isEditMode, setIsEditMode] = useState(false);
  const [updatetaxCode, { loading }] = useMutation(UPDATE_PROFILE_INFO, {});
  const toggeleEditing = () => setIsEditMode((b) => !b);

  const onSave = () => {
    updatetaxCode({
      variables: {
        input: {
          userId: customerId,
          updateProfileInfoType: 'TAX_ENTITY_USE_CODE',
          newValue: code
        }
      },
      onCompleted() {
        toggeleEditing();
      },
      onError(err) {
        let error = 'Unknown Error';

        if (err?.message === 'EXCEPTION_CODE_NOT_FOUND') {
          error = `Tax Excemption code ${code} doesnot exists`;
        }

        if (err?.message === 'AVALARA_DISABLED') {
          error = 'Please enable Avalara Tax calculations in Settings';
        }
        if (err?.message === 'AVALARA_NOT_CONFIGURED_FOR_STORE') {
          error = 'Please configure Avalara Tax calculations in Settings';
        }

        showToast({ message: error, errorText: 'Error in saving excemtion code' });
      },
      update(cache) {
        const id = `CustomerDetails:${customerId}`;
        cache.modify({
          id,
          fields: {
            taxEntityUseCode() {
              return code;
            }
          }
        });
      }
    });
  };
  return (
    <CustomerTaxcodePresentational
      code={code}
      onSave={onSave}
      setCode={setCode}
      loading={loading}
      isEditMode={isEditMode}
      toggleEditMode={toggeleEditing}
    />
  );
};

export default CustomerTaxcode;
