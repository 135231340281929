import { FC, useRef } from 'react';

// Components
import CreateUnplannedLiveShowDetailStepPresentational from './CreateUnplannedLiveShowDetailStep.presentational';

// Types
import { CreateUnplannedLiveShowDetailProps } from './CreateUnplannedLiveShowDetailStep.types';

const CreateUnplannedLiveShowDetailStep: FC<CreateUnplannedLiveShowDetailProps> = ({
  liveShowDetailInfo,
  liveShowInfoError,
  updateLiveShowDetailInfo,
  updateLiveShowInfoErrors
}) => {
  // State params
  const stateRef: any = useRef();
  stateRef.current = liveShowDetailInfo;

  // Event Handlers
  const handleSendSuccessURL = (res: any) => {
    updateLiveShowDetailInfo({ ...liveShowDetailInfo, thumbnailUrl: res });
  };
  const removeThumbnailHandler = (): void => {
    updateLiveShowDetailInfo({ ...liveShowDetailInfo, thumbnailUrl: {} });
  };

  return (
    <CreateUnplannedLiveShowDetailStepPresentational
      handleSendSuccessURL={handleSendSuccessURL}
      liveShowDetailInfo={liveShowDetailInfo}
      liveShowInfoError={liveShowInfoError}
      updateLiveShowDetailInfo={updateLiveShowDetailInfo}
      updateLiveShowInfoErrors={updateLiveShowInfoErrors}
      removeThumbnail={removeThumbnailHandler}
    />
  );
};

export default CreateUnplannedLiveShowDetailStep;
