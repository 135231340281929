import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

// Apollo
import { useMutation } from '@apollo/client';
import { RESEND_VERIFY_USER_EMAIL } from 'src/apollo/mutations';

// Hooks && Utils && Helpers
import { maskedEmail } from 'src/utils/maskEmail';

// Icons
import { SignUpBySocial } from 'src/assets/images';
import { CaretLeft } from 'src/assets/icons';

// ConstVariables
import { constVariables, envUrls } from 'src/constants';

// Styles
import './_verifyEmail.scss';
import 'src/components/oraganisms/CustomModal/modalStyles.scss';
import 'src/components/pages/auth/Signup/_signUp.scss';

const VerifyEmail: FC = (props) => {
  const history = useHistory();
  const [genericMsg, setGenericMsg] = useState('');
  const [counter, setCounter] = useState(60);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    if (isClicked) {
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
      if (counter === 0) {
        setIsClicked(false);
        setCounter(60);
      }
    }
  }, [counter, isClicked]);

  if (!history?.location?.state?.detail) {
    history.goBack();
  }
  const { email, store, token } = history?.location?.state?.detail;
  localStorage.clear();

  const handleBack = () => {
    history.push('/signup');
  };

  const [resendVerifyEmail, { loading }] = useMutation(RESEND_VERIFY_USER_EMAIL, {
    onCompleted: () => {
      setGenericMsg('Link has been sent successfully!');
      setIsClicked(true);
    },
    onError: () => {
      setGenericMsg('Something went wrong, please try again!');
    }
  });

  const handleResentVerifyEmailButton = () => {
    resendVerifyEmail({
      context: {
        headers: {
          authorization: token,
          tenantid: store,
          'x-api-key': envUrls.authXApiKey
        }
      },
      variables: {
        input: {
          email,
          isBackStoreUser: true
        }
      }
    });
  };

  return (
    <>
      <div className="signup d-flex gradient flex-row ">
        <div className="form-container padding-style">
          {/* left panel */}
          <div className={`overflow-hidden bg-white verify-email-container height-container`}>
            <div className="btn-back" onClick={handleBack}>
              <img src={CaretLeft} alt="Back" />
              <p>Back</p>
            </div>
            <img src={SignUpBySocial} alt={SignUpBySocial} className="image-dimension brand-logo" />

            <div className={`text-danger fs-8`}>{genericMsg}</div>

            <p className="poppins-regular title-style m-0 auth-header ">
              <p>{constVariables.signUp.checkYourMail}</p>
            </p>

            <div className="content-section">
              <span>{constVariables.signUp.LinkSent}</span>
              <span className="email-sentence">
                {constVariables.signUp.pleaseClickOnTheLinkSentTo}
                <span className="email-text">{maskedEmail(email)}</span>
              </span>
            </div>

            <div className="p-text m-0">{constVariables.signUp.didntGetVerificationLink}</div>

            <div className="vh-5">
              <div className="button-container">
                <div className="button margin-style">
                  <button
                    disabled={loading || isClicked}
                    onClick={handleResentVerifyEmailButton}
                    className="btn btn-primary social-btn w-100 button-padding flex-center"
                  >
                    {constVariables.signUp.sendAgain}
                    {loading && <div className="spinner-border text-primary auth-flow"></div>}
                  </button>
                </div>
              </div>
            </div>

            {isClicked && <div className={`text-danger fs-8 counter-msg`}>Please wait {counter}s to sent link again!</div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
