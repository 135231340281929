import { gql } from '@apollo/client';

export const CREATE_FEATURED_PRODUCT_RULES = gql`
  mutation createFeaturedProductRules($input: CreateFeaturedProductRulesInput!) @api(name: "productsAPI") {
    createFeaturedProductRules(input: $input) {
      id
      success
      message
      productIds
      productAssignmentMethod
    }
  }
`;
