import { FC, useState } from 'react';

// Components
import { Loader } from 'src/components/atoms';
import { CommonProductsBlock } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { CloseIcon } from 'src/assets/icons';

// Types
import { AddingProducstModalProps } from './Modal.types';

// Styles
import './modalStyles.scss';

const AddingProductsModal: FC<AddingProducstModalProps> = ({
  title,
  closeModal,
  shouldShowVariants,
  handleAddProducts,
  disabledIds,
  isAddingProduct,
  isShowOnlyActiveProducts,
  isAddingToTiktok
}) => {
  const [selectedProductIDs, setSelectedProductIDs] = useState<Array<number>>([]);
  const handleClose = () => {
    closeModal();
  };

  const handleAddProduct = () => {
    handleAddProducts(selectedProductIDs);
    setSelectedProductIDs([]);
  };

  const handleUpdateSelectedProductIDs = (updatedIDs) => {
    setSelectedProductIDs(updatedIDs);
  };

  return (
    <div className="modal-content adding-product-modal">
      <div className="modal-header">
        <h5 className="modal-title">{title ? title : 'Add Product'}</h5>
        <div className="btn btn-xs btn-active-light-primary p-0 m-0 border-none" onClick={handleClose}>
          <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
        </div>
      </div>
      <div className="modal-body">
        <CommonProductsBlock
          selectedProductIDs={selectedProductIDs}
          handleUpdateSelectedProductIDs={handleUpdateSelectedProductIDs}
          shouldShowVariants={shouldShowVariants}
          isShowingOnModal={true}
          disabledIds={disabledIds}
          isShowOnlyActiveProducts={isShowOnlyActiveProducts}
          isAddingToTiktok={isAddingToTiktok}
        />
      </div>
      <div className="modal-footer justify-content-between">
        <span className={`selected-products m-0`}>Selected products: {selectedProductIDs.length}</span>
        <div className="m-0">
          <button className="btn btn-light btn-md" data-bs-dismiss="modal" type="button" onClick={handleClose}>
            Cancel
          </button>
          <button className="btn btn-primary btn-md" onClick={handleAddProduct} type="button" disabled={isAddingProduct}>
            {isAddingProduct ? 'Adding' : 'Add'}
            {isAddingProduct && <Loader type="button" className="h-10px w-10px" />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddingProductsModal;
