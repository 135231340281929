/* eslint-disable dot-notation */
export const ENV = {
  DEV: 'DEV',
  QA: 'QA',
  LOCAL: 'LOCAL',
  STAGING: 'STAGING'
};

export const localEnvironmentUrls = {
  productsApiHost: 'rxevqtamkbbehcdbexy3ymmxyu.appsync-api.ap-south-1.amazonaws.com',
  ordersAPIHost: 'suus5kwauna3hitzmdkza5obx4.appsync-api.ap-south-1.amazonaws.com',
  LiveStreamingAPIHost: 'plu6qoouhbddvokb23fnsbmzqa.appsync-api.ap-south-1.amazonaws.com',
  productsApiUrl: 'http://localhost:3007/graphql',
  authApiUrl: 'http://localhost:3001/graphql',
  ordersApiUrl: 'https://suus5kwauna3hitzmdkza5obx4.appsync-api.ap-south-1.amazonaws.com/graphql',
  liveStreamingUrl: 'https://plu6qoouhbddvokb23fnsbmzqa.appsync-api.ap-south-1.amazonaws.com/graphql',
  productxApiKey: 'da2-yo5ypk7sqvavxo7654xacnbuue',
  orderXApiKey: 'da2-cd4z62tqrrgh5f5orzsxyxltze',
  livestreamApiKey: 'da2-onouuebtqfd2binq67x2x3qrqa',
  authXApiKey: 'da2-t4dzh6e36zccbfszjf7uedeyza',
  realTimeLiveStreamingApiHost: 'wss://plu6qoouhbddvokb23fnsbmzqa.appsync-realtime-api.ap-south-1.amazonaws.com/graphql',
  passwordEncryptionKey: 'WeNeedSomeSecureKeyToEncryptPass',
  shippoClientId: '8f3883d75c264920a072d79125bcab02',

  /* dev */
  firebaseApiKey: 'AIzaSyD7Ni96kIGlWtHz3ATTWFoYiNctn3_CFHs',
  firebaseAuthDomain: 'stellar-operand-368804.firebaseapp.com',
  // firebaseTenantId: 'Tenant1-y0xwu'
  firebaseTenantId: 'Tenant1-y0xwux',
  awsRegion: 'ap-south-1',


  /* staging */

  /*
  firebaseApiKey: 'AIzaSyB0hDy2kkB5IyGsrxReveBjrxRxe6ivH-w',
  firebaseAuthDomain: 'buysocial-staging.firebaseapp.com',
  firebaseTenantId: 'Tenant', // No validation on BE
  awsRegion: 'us-east-1',
  */

  liveWebSocketUrl: 'wss://40ycp9zuva.execute-api.ap-south-1.amazonaws.com/dev',
  facebookAppId: 525169602445808,
  csvBoxImportURL: 'http://localhost:4000/dev/csvbox/webhook/products',
  onesignalAppId: 'f4a4d524-aad9-4666-ae28-a3cb902d6ed1',
  csvBoxLicenseKey: 'hyB1sGeHKaGqo8jsDA5jm4meT8tgVf',
  stripeClientId: 'ca_KZH9OePpyuv00W2iqI5EueNbFQoOvIA0',




  shopifyApiKey: 'da2-3e3hiw3qqnaaxgcy2nabdqp7iq'
};

export const devEnvironmentUrls = {
  productsApiHost: 'rxevqtamkbbehcdbexy3ymmxyu.appsync-api.ap-south-1.amazonaws.com',
  ordersAPIHost: 'suus5kwauna3hitzmdkza5obx4.appsync-api.ap-south-1.amazonaws.com',
  LiveStreamingAPIHost: 'plu6qoouhbddvokb23fnsbmzqa.appsync-api.ap-south-1.amazonaws.com',
  productsApiUrl: 'https://g7u4klpub5gwlclja2piphljnu.appsync-api.ap-south-1.amazonaws.com/graphql',
  authApiUrl: 'https://yfrf2vwcfbb6zpf27fnqdegcay.appsync-api.ap-south-1.amazonaws.com/graphql',
  ordersApiUrl: 'https://suus5kwauna3hitzmdkza5obx4.appsync-api.ap-south-1.amazonaws.com/graphql',
  liveStreamingUrl: 'https://plu6qoouhbddvokb23fnsbmzqa.appsync-api.ap-south-1.amazonaws.com/graphql',
  productxApiKey: 'da2-yo5ypk7sqvavxo7654xacnbuue',
  orderXApiKey: 'da2-cd4z62tqrrgh5f5orzsxyxltze',
  livestreamApiKey: 'da2-onouuebtqfd2binq67x2x3qrqa',
  authXApiKey: 'da2-t4dzh6e36zccbfszjf7uedeyza',
  realTimeLiveStreamingApiHost: 'wss://plu6qoouhbddvokb23fnsbmzqa.appsync-realtime-api.ap-south-1.amazonaws.com/graphql',
  passwordEncryptionKey: 'WeNeedSomeSecureKeyToEncryptPass',
  shippoClientId: '8f3883d75c264920a072d79125bcab02',
  firebaseApiKey: 'AIzaSyD7Ni96kIGlWtHz3ATTWFoYiNctn3_CFHs',
  firebaseAuthDomain: 'stellar-operand-368804.firebaseapp.com',
  firebaseTenantId: 'Tenant1-y0xwux',
  liveWebSocketUrl: 'wss://40ycp9zuva.execute-api.ap-south-1.amazonaws.com/dev',
  facebookAppId: 525169602445808,
  csvBoxImportURL: 'https://nygs93jdod.execute-api.ap-south-1.amazonaws.com/dev/csvbox/webhook/products',
  onesignalAppId: 'f4a4d524-aad9-4666-ae28-a3cb902d6ed1',
  csvBoxLicenseKey: 'tM1qADHoTOCqpaUO90xG8mIHmc3eAz',
  stripeClientId: 'ca_KZH9OePpyuv00W2iqI5EueNbFQoOvIA0',
  awsRegion: 'ap-south-1',
  shopifyApiUrl: 'https://vsonvnllqvd4lbf2odx4z7y2gq.appsync-api.ap-south-1.amazonaws.com/graphql',
  shopifyApiKey: 'da2-3e3hiw3qqnaaxgcy2nabdqp7iq',
  shopifyCallbackUrl: 'https://b987sj0rnk.execute-api.ap-south-1.amazonaws.com/dev/shopify',
  tiktokApiUrl: 'https://pmhnue2bqrfaboif4fhpbhqyku.appsync-api.ap-south-1.amazonaws.com/graphql',
  tiktokApiKey: 'da2-bjgywhqjzjg3lk6nhit3ulvs6e'
};

export const qaEnvironmentUrls = {
  productsApiHost: 'j5rro2lpmvfrjlvepg2kipwbxa.appsync-api.ap-south-1.amazonaws.com',
  ordersAPIHost: 'c24gxrt7u5gh3n6xjhkwtrte7e.appsync-api.ap-south-1.amazonaws.com',
  LiveStreamingAPIHost: 'zad5sevi7zhkrdfzenhu4opbge.appsync-api.ap-south-1.amazonaws.com',
  productsApiUrl: 'https://hcawu5p6fzazljyf32diqmtoym.appsync-api.ap-south-1.amazonaws.com/graphql',
  authApiUrl: 'https://p4jhhx4al5f3dg5zvlysxbounq.appsync-api.ap-south-1.amazonaws.com/graphql',
  ordersApiUrl: 'https://c24gxrt7u5gh3n6xjhkwtrte7e.appsync-api.ap-south-1.amazonaws.com/graphql',
  liveStreamingUrl: 'https://zad5sevi7zhkrdfzenhu4opbge.appsync-api.ap-south-1.amazonaws.com/graphql',
  productxApiKey: 'da2-f3gkcevyzjastg43gurz2l3vea',
  orderXApiKey: 'da2-6s3unb53sbegbp7px5w22shlcq',
  livestreamApiKey: 'da2-6aqi24deebgt5pjawehwq3hnoe',
  authXApiKey: 'da2-4ousioaiird7vd2binbbi3ojci',
  realTimeLiveStreamingApiHost: 'wss://zad5sevi7zhkrdfzenhu4opbge.appsync-realtime-api.ap-south-1.amazonaws.com/graphql',
  passwordEncryptionKey: 'WeNeedSomeSecureKeyToEncryptPass',
  shippoClientId: 'bd15fd76036e40aba0b8a714f3df52bf',
  firebaseApiKey: 'AIzaSyBQFk3cxqCl_4g-bHi2hGvHsJvPd8s2QM8',
  firebaseAuthDomain: 'evident-post-377014.firebaseapp.com',
  firebaseTenantId: 'Tenant1-y0xwu',
  liveWebSocketUrl: 'wss://dr949juzdk.execute-api.ap-south-1.amazonaws.com/qa',
  facebookAppId: '3285298398400956',
  csvBoxImportURL: 'https://m0z3avdot8.execute-api.ap-south-1.amazonaws.com/qa/csvbox/webhook/products',
  onesignalAppId: 'f9c81444-96bf-4349-a20f-0debf6541887',
  csvBoxLicenseKey: 'rpesnOqFjXHHBgGpd7uoZgis7plRVD',
  stripeClientId: 'ca_KZH9OePpyuv00W2iqI5EueNbFQoOvIA0',
  awsRegion: 'ap-south-1',
  shopifyApiUrl: 'https://vsonvnllqvd4lbf2odx4z7y2gq.appsync-api.ap-south-1.amazonaws.com/graphql',
  shopifyApiKey: 'da2-3e3hiw3qqnaaxgcy2nabdqp7iq',
  shopifyCallbackUrl: 'https://b987sj0rnk.execute-api.ap-south-1.amazonaws.com/dev/shopify'
};
export const stagingEnvironmentUrls = {
  authApiUrl: 'https://auth.staging.buysocial.app/graphql',
  authXApiKey: 'da2-ihpsekrcmbbyrdr2bq7vep2y34',

  ordersAPIHost: 'orders.staging.buysocial.app',
  ordersApiUrl: 'https://orders.staging.buysocial.app/graphql',
  orderXApiKey: 'da2-ef6ns4z6rvf4xm6sqi3zbydibi',

  LiveStreamingAPIHost: 'live.staging.buysocial.app',
  liveStreamingUrl: 'https://live.staging.buysocial.app/graphql',
  realTimeLiveStreamingApiHost: 'wss://live.staging.buysocial.app/graphql/realtime',
  livestreamApiKey: 'da2-7m63bf4v5ffovanyjmvkvm2h4a',

  productsApiHost: 'products.staging.buysocial.app',
  productsApiUrl: 'https://products.staging.buysocial.app/graphql',
  productxApiKey: 'da2-uqbrltlt65grhhpsuostnppj7m',

  passwordEncryptionKey: 'Fe(w}Xg2,EdkQ]Vcaf-CqYHKN2Fd',
  shippoClientId: 'bd15fd76036e40aba0b8a714f3df52bf',

  firebaseApiKey: 'AIzaSyB0hDy2kkB5IyGsrxReveBjrxRxe6ivH-w',
  firebaseAuthDomain: 'buysocial-staging.firebaseapp.com',
  firebaseTenantId: 'Tenant', // No validation on BE

  liveWebSocketUrl: 'wss://ws.staging.buysocial.app/live',
  facebookAppId: '3730770460484928',
  onesignalAppId: '0bf376f6-ee1e-41a5-8489-233a4fcb0c2d',
  csvBoxLicenseKey: 'rpesnOqFjXHHBgGpd7uoZgis7plRVD',
  csvBoxImportURL: 'https://http://api.staging.buysocial.app/products/csvbox/webhook/products',
  stripeClientId: 'ca_PQrCbU3fXgphPc3PbhsP9eR9RrWbERjF',
  awsRegion: 'us-east-1',

  shopifyApiUrl: 'https://vsonvnllqvd4lbf2odx4z7y2gq.appsync-api.ap-south-1.amazonaws.com/graphql',
  shopifyApiKey: 'da2-3e3hiw3qqnaaxgcy2nabdqp7iq',
  shopifyCallbackUrl: 'https://b987sj0rnk.execute-api.ap-south-1.amazonaws.com/dev/shopify'
};

const getEnvUrls = () => {
  // const env = devEnvironmentUrls;
  let env;
  switch (window['app'].env.REACT_APP_ENV) {
    case ENV.QA:
      env = qaEnvironmentUrls;
      break;
    case ENV.LOCAL:
      env = localEnvironmentUrls;
      break;
    case ENV.STAGING:
      env = stagingEnvironmentUrls;
      break;
    default:
      env = devEnvironmentUrls;
      break;
  }
  return env;
};

const getEnvStatus = () => {
  console.log('env::', window['app'].env.REACT_APP_ENV);
  switch (window['app'].env.REACT_APP_ENV) {
    case ENV.QA:
      return ENV.QA;
    case ENV.LOCAL:
      return ENV.LOCAL;
    case ENV.STAGING:
      return ENV.QA;
    default:
      return ENV.DEV;
  }
};

const envUrls = getEnvUrls();
const envStatuses = getEnvStatus();

export { envUrls, envStatuses };
