import React, { useCallback } from 'react';
import { CellProps } from 'react-datasheet-grid';

// Components
import { FlexBox, Img } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import { DefaultImage } from 'src/assets/icons';

const ProductName = React.memo(({ rowData, setRowData, active, focus }: CellProps) => {
  const callbackRef = useCallback(
    (inputElement) => {
      if (inputElement && focus) {
        setTimeout(() => {
          inputElement.focus();
          inputElement.select();
        }, 100);
      }
    },
    [focus]
  );

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (focus) {
      const updatedRowdata = { ...rowData, productName: value };
      setRowData(updatedRowdata);
    }
  };

  return (
    <FlexBox className="align-items-center w-100 h-100">
      <div className="symbol ms-2">
        <Img
          src={setImageSrc(
            rowData?.productMediasJson
              ? generateCompressedImageURL(rowData?.productMediasJson[0]?.productMediaUrl, '50')
              : DefaultImage
          )}
          placeholderImg={DefaultImage}
          errorImg={DefaultImage}
          className="object-fit-scale-down border border-light border-2"
        />
      </div>
      <input
        style={{ pointerEvents: focus ? 'auto' : 'none' }}
        className="dsg-input product-name-input"
        type="text"
        value={rowData?.productName}
        onChange={handleChange}
        ref={callbackRef}
        onKeyDown={handleKeyPress}
      />
    </FlexBox>
  );
});

export default ProductName;
