import { gql } from '@apollo/client';

export const ADD_OR_EDIT_STORE_PRODUCT_COLUMNS = gql`
  mutation AddOrEditStoreProductColumns($input: StoreVendorColumnsInput!) @api(name: "productsAPI") {
    addOrEditStoreProductColumns(input: $input) {
      id
      success
      message
    }
  }
`;
