import { gql } from '@apollo/client';

export const GET_MANIFEST_DETAILS = gql`
  query getManifestDetails($input: GetManifestDetailsInput!) @api(name: "ordersAPI") {
    getManifestDetails(input: $input) {
      labelId
      orderId
      firstName
      profileUrl
    }
  }
`;
