import { gql } from '@apollo/client';

export const ADD_PRODUCT_TO_LIVE_SHOW = gql`
  mutation AddProductToShow($input: AddProductsToLiveShowInput!) @api(name: "liveStreamingAPI") {
    addProductsToLiveShow(input: $input) {
      id
      success
      message
    }
  }
`;
