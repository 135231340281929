export const generateCompressedImageURL = (
  url: string,
  resolution: '50' | '100' | '200' | '226' | '260' | '400' | '500' | '1024'
): string | undefined => {
  if (!url) {
    return undefined;
  }
  const resolutions = {
    '50': { width: 50, height: 50 },
    '100': { width: 100, height: 100 },
    '200': { width: 200, height: 200 },
    '226': { width: 226, height: 378 },
    '260': { width: 260, height: 260 },
    '400': { width: 400, height: 400 },
    '500': { width: 500, height: 500 },
    '1024': { width: 1024, height: 1024 }
  };

  const splitted = url.split('/image/');
  const imgRes = resolutions[resolution];
  if (url.includes('/PRODUCT/image/')) {
    return `${splitted[0]}/image/${imgRes.width}_${imgRes.height}/${splitted[1]}`;
  } else if (url.includes('/CATALOG/image/') || url.includes('/COLLECTION/image/')) {
    const splitted = url.split('/image/');
    return `${splitted[0]}/image/260_260/${splitted[1]}`;
  } else if (url.includes('/USER/image/')) {
    const splitted = url.split('/image/');
    return `${splitted[0]}/image/${imgRes.width}_${imgRes.height}/${splitted[1]}`;
  } else if (url.includes('/APP_LOGO/image/')) {
    const splitted = url.split('/image/');
    return `${splitted[0]}/image/${imgRes.width}_${imgRes.height}/${splitted[1]}`;
  } else if (url.includes('/LIVE_STREAM_THUMBNAIL/image/')) {
    const splitted = url.split('/image/');
    return `${splitted[0]}/image/${imgRes.width}_${imgRes.height}/${splitted[1]}`;
  } else {
    return url;
  }
};
