import { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_SHOPIFY_ADMIN_BASE_URL } from 'src/apollo/queries';

// Components
import { FlexBox, Img, Loader } from 'src/components/atoms';
import { ShopifySyncTooltip } from 'src/components/molecules';
import {
  AvailableSale,
  InventoryLocation,
  InventoryLowStockAlert,
  InventoryStockLevel,
  ActionMenu
} from 'src/components/oraganisms/InventoryList/components';
import TableCell from '../components/TableCell';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';

// Icons
import { RightArrowIcon, DefaultImage, ShopifyIcon } from 'src/assets/icons';

// ConstVariables
import { ROUTES } from 'src/constants';

// Types
import { LocationItem } from 'src/components/pages/inventory/InventoryManagementPage.type';

interface InventoryDetailsRowProps {
  inventory: any;
  columnOptions: any[];
  isLoading?: boolean;
  selectedWarehouseId?: number;
  updateInventoryList?: (updatedInventory, fieldName, productId?) => void;
  wearhouseLocations?: LocationItem[];
  rowWidth: number;
  handleInventoryActionMenus?: (level: string, id: number, printType: string) => void;
}

const InventoryDetailsRow: FC<InventoryDetailsRowProps> = ({
  inventory,
  columnOptions,
  isLoading,
  selectedWarehouseId,
  updateInventoryList,
  wearhouseLocations,
  rowWidth,
  handleInventoryActionMenus
}) => {
  const { data: { shopifyAdminBaseUrl } = { shopifyAdminBaseUrl: null } } = useQuery(GET_SHOPIFY_ADMIN_BASE_URL);

  const goToShopifyPage = (shopifyProductId) => {
    if (shopifyAdminBaseUrl) window.open(`${shopifyAdminBaseUrl}products/${shopifyProductId}`, '_blank');
  };

  const renderColumn = (variant, column) => {
    switch (column.dataKey) {
      case 'productName':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <div className="d-flex align-items-center">
              <div className="form-check form-check-sm form-check-custom">
                <input className="m-r-20 opacity-0 form-check-input widget-9-check m-0" type="checkbox" disabled />
              </div>
              <div className="opacity-0 h-20x w-20x p-8 d-flex align-items-center justify-content-center m-r-16">
                <img src={RightArrowIcon} className="h-10px w-10px" />
              </div>
              <div className="align-items-center d-flex">
                <div className="symbol me-5">
                  <Img
                    src={setImageSrc(
                      variant?.variantMediaUrl ? generateCompressedImageURL(variant?.variantMediaUrl, '50') : DefaultImage
                    )}
                    height={50}
                    width={50}
                    className="object-fit-scale-down bg-white border border-light border-2"
                  />
                  {inventory?.externalProductProvider === 'SHOPIFY' && (
                    <>
                      <img
                        src={ShopifyIcon}
                        className="cursor-pointer ms-1 shopify-icon position-absolute"
                        data-tip
                        data-for="shopifySyncIcon"
                        title="Product from Shopify"
                        onClick={() => goToShopifyPage(inventory?.externalProductId)}
                      />
                      {/* <ShopifySyncTooltip tooltipText="Product from Shopify" /> */}
                    </>
                  )}
                </div>
                <div className="text-left">
                  <Link
                    to={{
                      pathname: `/products/allProducts/edit/${inventory?.productId}`,
                      state: { from: ROUTES.products.inventory.main }
                    }}
                    className="main-cell"
                  >
                    <span className="d-block">
                      {variant.variantTypes?.map((dataTwo, index) => (
                        <Fragment key={index}>
                          {dataTwo?.variantTypeValue}
                          {index < variant.variantTypes.length - 1 && variant !== null ? ' / ' : null}
                        </Fragment>
                      ))}
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </TableCell>
        );
      case 'sku':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <FlexBox className="align-items-center">{variant?.productVariantSku ? variant.productVariantSku : '-'}</FlexBox>
          </TableCell>
        );
      case 'location':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <InventoryLocation
              type="VARIANT"
              id={variant.productVariantId}
              productId={inventory?.productId}
              defaultLocationId={variant?.variantLocation ? variant?.variantLocation[0]?.productVariantLocationId : undefined}
              locationName={variant?.variantLocation ? variant?.variantLocation[0]?.productVariantLocationName : '-'}
              showEditIcon={true}
              selectedWarehouseId={selectedWarehouseId}
              updateInventoryList={updateInventoryList}
              wearhouseLocations={wearhouseLocations}
            />
          </TableCell>
        );
      case 'availableSale':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <AvailableSale quantity={variant.variantUsersQuantity} />
          </TableCell>
        );
      case 'committed':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <FlexBox className="align-items-center">{variant.variantCommittedQty ? variant.variantCommittedQty : '-'}</FlexBox>
          </TableCell>
        );
      case 'lowStockAlert':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <InventoryLowStockAlert
              type="PRODUCT_VARIANT"
              id={variant?.productVariantId}
              productId={inventory?.productId}
              quantity={variant?.variantAlertThreshold}
              showEditIcon={true}
              updateInventoryList={updateInventoryList}
            />
          </TableCell>
        );
      case 'stockLevel':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <InventoryStockLevel
              type="PRODUCT_VARIANT"
              id={variant?.productVariantId}
              productId={inventory?.productId}
              locationId={variant?.variantLocation ? variant?.variantLocation[0]?.productVariantLocationId : undefined}
              quantity={variant?.productVariantQty}
              showEditIcon={true}
              selectedWarehouseId={selectedWarehouseId}
              updateInventoryList={updateInventoryList}
            />
          </TableCell>
        );
      case 'action':
        return (
          <TableCell columnWidth={column.width} isStaticColumn={column.staticColumn}>
            <ActionMenu
              level="variant"
              productId={inventory?.productId}
              variantId={variant?.productVariantId}
              handleActionMenus={handleInventoryActionMenus}
            />
          </TableCell>
        );
      default:
        return;
    }
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            height: 70,
            display: 'flex',
            alignItems: 'center',
            width: rowWidth
          }}
          className="ReactVirtualized__Table__row"
        >
          <Loader type="page" />
        </div>
      ) : (
        <>
          {inventory?.variantLocationDetails?.map((variant, index) => (
            <div
              style={{
                height: 70,
                display: 'flex',
                alignItems: 'center',
                width: rowWidth
              }}
              key={index}
              className="ReactVirtualized__Table__row"
            >
              {columnOptions?.map((column, columnIndex) => {
                return <Fragment key={columnIndex}>{renderColumn(variant, column)}</Fragment>;
              })}
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default InventoryDetailsRow;
