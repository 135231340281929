import { gql } from '@apollo/client/core';

export const ON_ENDING_LIVESTREAM = gql`
  mutation END_LIVESTEAM($id: String!) @api(name: "liveStreamingAPI") {
    onEndLiveStream(id: $id) {
      action
      storeId
    }
  }
`;
