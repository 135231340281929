import { FC } from 'react';

// Icons
import { SearchGroupIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Styles
import './CreateOrder.scss';

interface CreateOrderDetailsTableProps {
  tempdata?: number;
}

const CreateOrderDetailsTable: FC<CreateOrderDetailsTableProps> = (props) => {
  return (
    <>
      <div>
        <h4 className="poppins-regular m-auto-0 text-nowrap dark-color">{`${constVariables.orders.orderSummary}`}</h4>
      </div>
      <div className="noProductText">
        <img src={SearchGroupIcon} className="pb-4 pt-4" />
        <p className="poppin-regular">{constVariables.createOrder.orderEmptyTitle}</p>
        <p className="poppin-regular greyText">{constVariables.createOrder.orderEmptyMessage}</p>
      </div>
    </>
  );
};

export default CreateOrderDetailsTable;
