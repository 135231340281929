import { gql } from '@apollo/client';

export const UPDATE_LOCATION = gql`
  mutation onUpdateLocation($input: UpdateLocationInput!) @api(name: "productsAPI") {
    updateLocation(input: $input) {
      id
      name
      warehouseId
    }
  }
`;
