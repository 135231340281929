/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

// Components
import { FlexBox, Img, Loader } from 'src/components/atoms';
import { SearchBar, FixedHeaderTable } from 'src/components/molecules';
import CollectionTreeView from './CollectionTreeView';

// Hooks && Utils && Helpers
import getDeviceData from 'src/utils/UseDevice/useDevice';
import { setImageSrc } from 'src/utils/setImageSrc';
import { generateCompressedImageURL } from 'src/utils/generateCompressedImageUrl';
import { KTSVG } from 'src/helpers';

// Icons
import { CloseIcon, DefaultImage } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { BulkEditorModalProps } from './BulkEditorModal.types';
import { TablesProps } from 'src/components/molecules/Table/Table.types';

// Styles
import './_bulkEditorModal.scss';

const BulkEditorModal: FC<BulkEditorModalProps> = ({
  show,
  refreshList,
  data,
  dataType,
  handleAddProductsToCatalog,
  cancelOperation,
  searchText,
  setSearchText,
  isLoading,
  closeModal,
  updateProductList,
  isAdding
}) => {
  const deviceData = getDeviceData();
  const [selectedCatalogs, setSelectedCatalogs] = useState<number[]>([]);

  useEffect(() => {
    if (show) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [show]);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      cancelOperation && cancelOperation();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction);

    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction]);

  const submitCatalogs = () => {
    if (selectedCatalogs.length > 0) {
      setSelectedCatalogs([]);
      handleAddProductsToCatalog(selectedCatalogs);
    }
  };

  const handleSearchData = (value) => {
    setSearchText && setSearchText(value);
  };

  const handleSelectedCatalog = (id: number) => {
    if (selectedCatalogs.find((item) => item === id)) {
      setSelectedCatalogs((prev) => prev.filter((a) => a !== id));
    } else {
      setSelectedCatalogs((prev) => [...prev, id]);
    }
  };

  // Catalog table data
  const statusText = (title: string) => {
    if (title === 'ACTIVE') {
      return 'Active';
    }
    if (title === 'SCHEDULED') {
      return 'Scheduled';
    }
    return 'Inactive';
  };

  const selectCollectionStatusClass = (collectionStatus: string | undefined) => {
    switch (collectionStatus) {
      case 'active':
        return 'active';
      case 'inactive':
        return 'inactive';
      default:
        return 'scheduled';
    }
  };

  let catalogsTableData: TablesProps = {};
  if (dataType === 'CATALOGS') {
    catalogsTableData = {
      headerClassName: 'position-relative ',
      isDragDisabled: true,
      bodyClass: 'w-100 no-top-border mh-300px',
      className: 'rounded-top w-100 scrollable-table-wrapper',
      headers: [
        {
          id: 1,
          className: 'w-300px ps-4 py-0 align-middle table-header-background',
          colName: (
            <div className="d-flex align-items-center form-check form-check-custom form-check-sm">
              <input
                className={clsx('form-check-input removeInputHighlight me-5 shadow-none opacity-100')}
                type="checkbox"
                checked={selectedCatalogs.length > 0 ? true : false}
                onChange={() => {
                  if (selectedCatalogs.length > 0) {
                    setSelectedCatalogs([]);
                  } else {
                    setSelectedCatalogs(data?.map((item) => item.id));
                  }
                }}
                id="select-all-catalogs-checkbox"
              />
              <div>Catalog</div>
            </div>
          )
        },
        ...['Products', 'Catalog Type', 'Status'].map((item) => ({
          id: 3,
          className: 'py-0 align-middle table-header-background',
          colName: item
        }))
      ],
      rows: data?.map((item, index) => ({
        id: item.id,
        isDragDisabled: true,
        className: `position-relative border-top-0 dontScrollBulkEditorTop ${
          item.type === 'AUTOMATIC' ? 'opacity-half disabled-row' : ''
        }`,
        cells: [
          {
            className: `align-middle w-300px ps-4 ${index !== 0 ? 'table-top-border' : 'border-0'}`,
            value: (
              <div className="d-flex align-items-center">
                <div className="form-check form-check-custom form-check-sm">
                  <input
                    className="form-check-input removeInputHighlight widget-9-check me-5"
                    type="checkbox"
                    checked={selectedCatalogs.find((selectedCatalog) => selectedCatalog === item.id) ? true : false}
                    onChange={() => handleSelectedCatalog(item.id)}
                    // onClick={() => handleSelectedCatalog(item.id)}
                    id={`select-catalog-${item.id}`}
                  />
                </div>
                <div className="symbol me-5">
                  <Img
                    src={setImageSrc(item?.thumbnailUrl ? generateCompressedImageURL(item?.thumbnailUrl, '260') : DefaultImage)}
                    placeholderImg={DefaultImage}
                    errorImg={DefaultImage}
                    className="object-fit-scale-down border border-light border-2"
                  />
                </div>
                <Link to={`/marketing/catalogs/edit/${item.id}`} className="main-cell">
                  <span className="d-block">{item.name}</span>
                </Link>
              </div>
            )
          },
          {
            className: `align-middle text-muted position-relative ${index !== 0 ? 'table-top-border' : 'border-0'}`,
            value: item.productIds.length
          },
          {
            className: `align-middle text-muted position-relative ${index !== 0 ? 'table-top-border' : 'border-0'}`,
            value: item.type[0].toUpperCase() + item.type.substr(1).toLowerCase()
          },
          {
            className: `align-middle text-muted position-relative ${index !== 0 ? 'table-top-border' : 'border-0'}`,
            value: (
              <FlexBox
                className={`p-2 poppins-medium justify-content-center ${selectCollectionStatusClass(
                  item?.status?.toLowerCase()
                )}-status-box`}
              >
                <span>{statusText(item?.status ? item?.status : '')}</span>
              </FlexBox>
            )
          }
        ]
      }))
    };
  }

  useEffect(() => {
    setSelectedCatalogs([]);
  }, [show]);

  const renderHeading = () => {
    if (dataType === 'CATALOGS') {
      return constVariables.AllProductsMenu.selectCatalogs;
    }
    if (dataType === 'COLLECTION') {
      return constVariables.AllProductsMenu.selectCollection;
    }
    return constVariables.AllProductsMenu.bulkEditor;
  };

  return (
    <div
      className={clsx('modal fade opacity-100 bulk-editor-modal', {
        show
      })}
      aria-modal="true"
      tabIndex={-1}
      id="kt_modal_1"
    >
      <div className={`${deviceData.isWeb ? 'modal-dialog modal-dialog-centered flex-center' : 'w-90 w-md-75'}`}>
        <div className={`modal-content ${deviceData.isWeb ? 'w-1068px' : ''}`}>
          <div className="modal-header">
            <h5 className="modal-title">{renderHeading()}</h5>
            <div
              className="btn btn-xs btn-active-light-primary p-0 m-0 border-none"
              onClick={() => {
                document.body.classList.remove('modal-open');
                closeModal();
              }}
            >
              <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
            </div>
          </div>
          <div className="modal-body">
            {['CATALOGS', 'COLLECTION'].includes(String(dataType)) && (
              <div className="m-b-24 w-50">
                <SearchBar
                  placeholder={`${dataType === 'COLLECTION' ? 'Search for collection' : 'Search for catalogs'}`}
                  onChange={handleSearchData}
                  value={searchText}
                />
              </div>
            )}

            <div className="d-flex flex-grow-1 table-body h-100">
              {dataType === 'CATALOGS' ? (
                <FixedHeaderTable
                  headers={catalogsTableData.headers}
                  rows={catalogsTableData.rows}
                  type="catalog"
                  loadingPosition="center"
                  searchText={searchText}
                  isLoading={isLoading}
                  containerHeight={300}
                  noMargin
                />
              ) : (
                <CollectionTreeView
                  data={data}
                  selectedCollections={selectedCatalogs}
                  setSelectedCollections={setSelectedCatalogs}
                  isLoading={isLoading}
                  searchText={searchText}
                />
              )}
            </div>
          </div>
          <div className="modal-footer justify-content-between">
            <span className="p-0">
              {selectedCatalogs.length} {dataType === 'COLLECTION' ? 'Collections' : selectedCatalogs.length > 1 ? 'Items' : 'Item'}{' '}
              selected
            </span>
            <div className="d-flex align-items-center m-0">
              <button type="button" className="btn btn-outlined-secondary btn-md" onClick={closeModal}>
                Cancel
              </button>
              <button
                type="button"
                className={`btn ${selectedCatalogs.length > 0 ? 'btn-primary' : 'btn-secondary'} btn-md`}
                disabled={isAdding}
                onClick={submitCatalogs}
              >
                {isAdding ? (dataType === 'COLLECTION' ? 'Adding' : 'Saving') : dataType === 'COLLECTION' ? 'Add' : 'Save'}
                {isAdding && <Loader type="button" className="h-10px w-10px" />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkEditorModal;
