import React, { FC, useState, createRef } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Dropdown, Portal } from 'src/components/molecules';
import { KTSVG } from 'src/helpers/components/KTSVG';
import { ThreeDotsIcon } from 'src/assets/icons';
import { OrdersListData, DropdownOptions } from '../OrdersList.types';

interface OrderMenuProps {
  orders: OrdersListData;
  filterOrderMenu: (orderStatus: string) => { id: number; name: string }[];
  handleOrderMenu: (selectedOption: DropdownOptions, order: OrdersListData) => void;
}

const OrderMenu: FC<OrderMenuProps> = ({ orders, filterOrderMenu, handleOrderMenu }) => {
  const menuBtnRef = createRef<any>();
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownPositions, setDropdownPositions] = useState({
    top: 0,
    left: 0
  });

  const handleOrderOption = (option) => {
    handleOrderMenu(option, orders);
    setShowDropdown(false);
  };

  const handleShowDropdown = () => {
    setShowDropdown(true);

    if (menuBtnRef.current) {
      const position = menuBtnRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - position.bottom;
      setDropdownPositions({
        top: spaceBelow < 104 ? window.scrollY + position.top - 104 : window.scrollY + position.top + 20,
        left: position.left - 120
      });
    }
  };

  return (
    <div className="position-relative">
      {filterOrderMenu(orders.orderStatus)?.length > 0 && (
        <>
          <button onClick={handleShowDropdown} className="bg-transparent border border-transparent" ref={menuBtnRef}>
            <KTSVG path={ThreeDotsIcon} svgClassName="cursor-pointer collapseFilter collapseIcon" />
          </button>
          {showDropdown && (
            <Portal id="kt_body">
              <OutsideClickHandler
                onOutsideClick={() => {
                  if (showDropdown) setShowDropdown(false);
                }}
              >
                <div className="position-absolute" style={{ top: dropdownPositions.top, left: dropdownPositions.left }}>
                  <Dropdown
                    data={filterOrderMenu(orders.orderStatus)}
                    selected={showDropdown}
                    onSelect={(selectedOption) => handleOrderOption(selectedOption)}
                    toTop="-5px"
                    closeDropdown={() => setShowDropdown(false)}
                  />
                </div>
              </OutsideClickHandler>
            </Portal>
          )}
        </>
      )}
    </div>
  );
};

export default OrderMenu;
