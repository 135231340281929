import { gql } from '@apollo/client';

export const GET_WAITLIST_VARIANTS = gql`
  query GetProductVariantsInWaitlist($input: GetProductsVariantsInWaitlistInput!) @api(name: "ordersAPI") {
    getProductVariantsInWaitlist(input: $input) {
      id
      variant
      comittedQuantity
      waitlistQuantity
      waitlistAuthQuantity
      mediaUrl
      price
    }
  }
`;
