/* eslint-disable no-nested-ternary */
import { FC, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

export type Props = {
  obj: any;
  changeAction: any;
  index: number;
  handleEnter: any;
  isContactTitle?: boolean;
};

const FieldTitles: FC<Props> = ({ obj, changeAction, index, handleEnter, isContactTitle }) => {
  const [value, setValue] = useState(obj?.title);
  return obj.mouseAction === 'clicked' ? (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (obj.mouseAction === 'clicked' && value) {
          changeAction(value, index, 'title');
          changeAction('', index, 'mouseAction');
        }
      }}
    >
      <input
        autoComplete="off"
        className={`form-control form-control-solid mw-150px px-1 bg-transparent border-primary min-width-20`}
        style={{
          width: `${value?.length ? `${value?.length}ch` : isContactTitle ? `${'Contact title'.length}ch` : `${'Title'.length}ch`}`
        }}
        name="title"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        placeholder={isContactTitle ? 'Contact title' : 'Title'}
        autoFocus
        onKeyDown={(e) => {
          handleEnter(e);
          if (e.key === 'Enter' && value) {
            changeAction(value, index, 'title');
            changeAction('', index, 'mouseAction');
          }
          if (e.key === 'Escape') {
            changeAction('', index, 'mouseAction');
          }
        }}
      />
    </OutsideClickHandler>
  ) : (
    <div
      className={`text-nowrap poppins-semibold opacity-80 px-1 py-2 h-100 ${
        obj.mouseAction === 'hover' ? '' : 'border border-transparent'
      } ${value?.length > 50 ? 'label-text' : ''}`}
      style={
        obj.mouseAction === 'hover'
          ? {
              border: '1px solid #00A3FF',
              borderRadius: '7px'
            }
          : {}
      }
      onMouseEnter={() => {
        if (!obj.mouseAction) changeAction('hover', index, 'mouseAction');
      }}
      onMouseLeave={() => {
        if (obj.mouseAction === 'hover') {
          changeAction('', index, 'mouseAction');
        }
      }}
      onClick={() => {
        setValue(obj.title);
        changeAction('clicked', index, 'mouseAction');
      }}
    >
      {obj.title}
    </div>
  );
};

export default FieldTitles;
