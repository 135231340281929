import { gql } from '@apollo/client';

export const GET_ORDER_DETAIL_WITH_ERROR_DATA = gql`
  query GetOrderDetailsList($input: getOrderListInput!) @api(name: "ordersAPI") {
    getOrderDetailsList(input: $input) {
      orders {
        id
        hasWeightErrors
        isOrderAddressVerified
      }
    }
  }
`;
