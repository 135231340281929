import { FC } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { FlexBox } from 'src/components/atoms';
import { SearchBar, Pagination, Table, SortDropdown } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { FilterIcon, SortIcon } from 'src/assets/icons';

// ConstVariables
import { SortIds } from './data';

// Types
import { TablesProps } from 'src/components/molecules/Table/Table.types';

// Styles
import './vendorList.scss';

type VendorPresentationProps = {
  tableData: TablesProps;
  skipCount: number;
  setSkipCount: any;
  limitCount: number;
  totalVendors: number;
  openSortDropdown: boolean;
  setOpenSortDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  sortBy: {
    id: number;
    catagory: string;
    type: string;
  };
  ChangeSortBy: (column: string, id: number) => void;
  isLoading: boolean;
  selectedVendors?: number[];
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
};

const VendorListPresentational: FC<VendorPresentationProps> = ({
  tableData,
  skipCount,
  setSkipCount,
  limitCount,
  totalVendors,
  openSortDropdown,
  setOpenSortDropdown,
  sortBy,
  ChangeSortBy,
  isLoading,
  selectedVendors,
  searchText,
  setSearchText
}) => {
  const handleSearchVendor = (value) => {
    setSearchText(value);
  };

  return (
    <>
      <div className="p-x-32 p-t-32">
        <div className={`${totalVendors === 0 && !searchText ? 'display-none' : ''}`}>
          <FlexBox className="justify-content-between w-100">
            <SearchBar placeholder="Search for Vendors" onChange={handleSearchVendor} />
            <FlexBox className="align-items-center">
              <div className="position-relative">
                <OutsideClickHandler
                  onOutsideClick={() => {
                    if (openSortDropdown) setOpenSortDropdown(false);
                  }}
                >
                  <button
                    className={`btn btn-sm btn-flex btn-icon-text align-items-center ${
                      openSortDropdown ? 'btn-primary' : 'btn-active-primary'
                    }`}
                    onClick={() => {
                      setOpenSortDropdown(!openSortDropdown);
                    }}
                  >
                    <KTSVG path={SortIcon} className="me-1" />
                    <span className="my-auto me-0">Sort</span>
                  </button>
                  <SortDropdown
                    data={SortIds}
                    value={sortBy}
                    onSelect={ChangeSortBy}
                    selected={openSortDropdown}
                    closeDropdown={() => setOpenSortDropdown(false)}
                  />
                </OutsideClickHandler>
              </div>
              <button className="btn btn-sm btn-flex btn-icon-text align-items-center ms-3">
                <KTSVG path={FilterIcon} className="me-1" />
                <span className="my-auto me-0">Filter</span>
              </button>
            </FlexBox>
          </FlexBox>
        </div>
        <div className="pt-3 overflow-scroll">
          <Table
            headerClassName={tableData.headerClassName}
            isDragDisabled={tableData.isDragDisabled}
            headers={tableData.headers}
            rows={tableData.rows}
            sortByCategory={tableData.sortByCategory}
            sortByType={tableData.sortByType}
            type={'vendor'}
            isLoading={isLoading}
            selectedIDs={selectedVendors}
            searchText={searchText}
            hideHeader={totalVendors === 0 && !searchText}
          />
        </div>
      </div>
      <div className={`${totalVendors === 0 && !searchText ? 'display-none' : ''}`}>
        <Pagination skipCount={skipCount} limitCount={limitCount} setSkipCount={setSkipCount} total={totalVendors} />
      </div>
    </>
  );
};

export default VendorListPresentational;
