import { gql } from '@apollo/client';

export const ADD_ORDER_NOTE = gql`
  mutation AddOrderNote($input: AddOrderNoteInput!) @api(name: "ordersAPI") {
    addOrderNote(input: $input) {
      id
      isCreatedByYou
      note
      username
      createdAt
      updatedAt
    }
  }
`;
