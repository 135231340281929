/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// Icons
import {
  CheckCircle,
  OrderFullfilIcon,
  OrderCancelIcon,
  OrderDeliverIcon,
  OrderCombinedIcon,
  OrderShippedIcon,
  OrderPrinterIcon
} from 'src/assets/icons';

export const renderOrderIcon = (title: string) => {
  if (title.includes('create')) {
    return CheckCircle;
  } else if (title.includes('fulfill')) {
    return OrderFullfilIcon;
  } else if (title.includes('cancel')) {
    return OrderCancelIcon;
  } else if (title.includes('deliver')) {
    return OrderDeliverIcon;
  } else if (title.includes('combined')) {
    return OrderCombinedIcon;
  } else if (title.includes('shipped')) {
    return OrderShippedIcon;
  } else if (title.includes('printed')) {
    return OrderPrinterIcon;
  }
};
