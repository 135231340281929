import { gql } from '@apollo/client';

export const REFUND_SHIPPING_LABEL = gql`
  mutation onRefundShippingLabel($input: RefundShippingLabelInput!) @api(name: "ordersAPI") {
    refundShippingLabel(input: $input) {
      id
      success
      message
    }
  }
`;
