import { gql } from '@apollo/client';

export const GET_FEATURED_PRODUCT_RULE = gql`
  query getFeaturedProductsRulesQuery @api(name: "productsAPI") {
    getFeaturedProductsRules {
      id
      createdAt
      updatedAt
      storeId
      type
      createdBy
      updatedBy
      status
      rulesMatch
      productAssignmentRules
      productAssignmentRulesLayout
      productIds
    }
  }
`;
