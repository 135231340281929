import { gql } from '@apollo/client';

export const DELETE_PRODUCT_NOTE = gql`
  mutation DeleteProductNote($input: EditOrDeleteProductNotes!) @api(name: "productsAPI") {
    deleteProductNote(input: $input) {
      id
      success
      message
    }
  }
`;
