import { FC, useState } from 'react';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_AVALARA_LOGS } from 'src/apollo/queries';

// Components
import { FlexBox, Loader } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers/components/KTSVG';

// Icons
import { DownArrowIcon, UpArrowIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Styles
import './_avalaraLogs.scss';

const AvalaraLogs: FC = () => {
  const [isOpen, setIsOpoen] = useState(false);

  const { data, loading } = useQuery(GET_AVALARA_LOGS, { skip: !isOpen });

  return (
    <div>
      <div className="card w-100">
        <div>
          <div className="taxLogheaderContainer">
            <p className="poppins-semibold fs-16px">{constVariables.taxes.httpLogs}</p>

            <button
              className="bg-transparent border border-transparent"
              onClick={() => {
                setIsOpoen((b) => !b);
              }}
            >
              <KTSVG
                path={isOpen ? DownArrowIcon : UpArrowIcon}
                className="expand-arrow-icon"
                svgClassName="cursor-pointer collapseFilter collapseIcon"
              />
            </button>
          </div>
          {isOpen && (
            <FlexBox className="align-items-end w-100">
              {loading ? (
                <Loader type="page" className="h-500px" />
              ) : (
                <div className="w-100 log-container">
                  {data?.getAvalaraLogs?.map(
                    ({ id, httpMethod, responseTime, url, requestHeaders, request, response, createdAt }) => (
                      <div key={id}>
                        <div className="urlcontainer">
                          <p>{new Date(createdAt).toLocaleString()}</p>
                          <div className="methodContainer ">
                            <h4>{httpMethod}</h4>
                            <p className="log-url">{url}</p>
                            <p>
                              <i>{(responseTime / 1000).toFixed(2)} s</i>
                            </p>
                          </div>
                          <div></div>
                        </div>
                        <div>
                          <h4>Request Headers</h4>
                          <div>
                            <pre>{JSON.stringify(JSON.parse(requestHeaders), null, 2)}</pre>
                          </div>
                        </div>
                        <div>
                          <h4>Request Body</h4>
                          <div>
                            <pre>{JSON.stringify(JSON.parse(request), null, 2)}</pre>
                          </div>
                        </div>
                        <div>
                          <h4>Response</h4>
                          <div>
                            <pre>{JSON.stringify(JSON.parse(response), null, 2)}</pre>
                          </div>
                        </div>
                        <div className="logs-breaker" />
                      </div>
                    )
                  )}
                </div>
              )}
            </FlexBox>
          )}
        </div>
      </div>
    </div>
  );
};

export default AvalaraLogs;
