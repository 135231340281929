/* eslint-disable no-nested-ternary */
import { FC } from 'react';
import clsx from 'clsx';

// Components
import { FlexBox, Loader } from 'src/components/atoms';
import { LiveShowStepper } from 'src/components/molecules';
import { CreateLiveShowProductsStep, CreateLiveShowDetailStep, CreateLiveShowNotifications } from 'src/components/oraganisms';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { CloseIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { CreateLiveShowPresentationalProps } from './CreateLiveShow.types';

// Styles
import './_createLiveShow.scss';

const CreateLiveShowPresentational: FC<CreateLiveShowPresentationalProps> = ({
  isEditing,
  reachedStep,
  isDataChanged,
  liveShowInfo,
  liveShowInfoError,
  stepCount,
  loading,
  updateStepCount,
  editEvent,
  updateLiveShowInfo,
  cancelBtnHandler,
  addSelectedProducts,
  bucketList,
  updateBucketList,
  updateProductList,
  updateLiveShowInfoErrors,
  textMessageToggle,
  setTextMessageToggle,
  appNotificationToggle,
  setAppNotificationToggle,
  messangerToggle,
  setMessangerToggle
}) => {
  const { isComplete } = liveShowInfo;

  const handleNextStep = () => {
    updateBucketList && updateBucketList([]);
    updateStepCount(stepCount + 1);
  };

  return (
    <div className={`create-live-show-div extended-content`}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">
            {isEditing ? constVariables.LiveShow.editLiveShow : constVariables.LiveShow.createNewLiveShow}
          </h5>
          <div className="btn btn-xs btn-active-light-primary p-0 m-0 border-none" onClick={cancelBtnHandler}>
            <KTSVG path={CloseIcon} className="m-0" svgClassName="close-icon" />
          </div>
        </div>
        {!isComplete && (
          <LiveShowStepper
            isUnplanned={false}
            stepCount={stepCount}
            reachedStep={reachedStep}
            updateStepCount={updateStepCount}
            completed={loading}
          />
        )}
        <div>
          {stepCount === 1 && (
            <CreateLiveShowDetailStep
              liveShowDetailInfo={liveShowInfo}
              liveShowInfoError={liveShowInfoError}
              updateLiveShowDetailInfo={updateLiveShowInfo}
              updateLiveShowInfoErrors={updateLiveShowInfoErrors}
            />
          )}
          {stepCount === 2 && (
            <CreateLiveShowProductsStep
              selectedProductList={liveShowInfo.products}
              liveShowDetailInfo={liveShowInfo}
              handleSelectedProducts={(data) => updateProductList && updateProductList(data)}
              bucketList={bucketList}
              handleProductBucketList={updateBucketList}
              updateLiveShowDetailInfo={updateLiveShowInfo}
              updateProductList={updateProductList}
              onProductDragEnd={() => null}
            />
          )}
          {stepCount === 3 && (
            <CreateLiveShowNotifications
              liveShowDetailInfo={liveShowInfo}
              liveShowInfoError={liveShowInfoError}
              updateLiveShowInfo={updateLiveShowInfo}
              textMessageToggle={textMessageToggle}
              setTextMessageToggle={setTextMessageToggle}
              appNotificationToggle={appNotificationToggle}
              setAppNotificationToggle={setAppNotificationToggle}
              messangerToggle={messangerToggle}
              setMessangerToggle={setMessangerToggle}
            />
          )}
        </div>
        <div className="modal-footer d-block">
          <FlexBox className="justify-content-between m-0">
            <div className="left-side-buttons">
              <div className="float-left">
                {stepCount !== 1 && (
                  <button
                    className="btn btn-outlined-secondary btn-md"
                    onClick={cancelBtnHandler}
                    data-bs-dismiss="modal"
                    type="button"
                  >
                    {constVariables.LiveShow.cancel}
                  </button>
                )}
                {stepCount !== 1 && (
                  <button
                    className={clsx('btn btn-outlined-secondary btn-md ms-5')}
                    onClick={() => updateStepCount(stepCount - 1)}
                    type="button"
                  >
                    {constVariables.LiveShow.previous}
                  </button>
                )}
              </div>
              {stepCount === 2 && bucketList && bucketList?.length > 0 && (
                <div className="float-right">
                  <button
                    className={clsx('btn btn-primary btn-md')}
                    onClick={addSelectedProducts}
                    disabled={bucketList && bucketList?.length <= 0}
                    type="button"
                  >
                    {constVariables.LiveShow.add}
                  </button>
                </div>
              )}
            </div>
            <div className="right-side-buttons">
              {!isEditing && (
                <>
                  {stepCount !== 3 && (
                    <button
                      className="btn btn-outlined-secondary btn-md"
                      onClick={stepCount === 1 ? cancelBtnHandler : () => updateStepCount(3)}
                      data-bs-dismiss="modal"
                      type="button"
                    >
                      {stepCount === 1 ? constVariables.LiveShow.cancel : constVariables.LiveShow.skipThisStep}
                    </button>
                  )}
                  <button className={clsx('btn btn-primary btn-md ms-5')} onClick={handleNextStep} disabled={loading} type="button">
                    {stepCount === 3 ? constVariables.LiveShow.createLiveShowLowerCase : constVariables.LiveShow.next}
                    {loading && <Loader type="button" className="h-10px w-10px" />}
                  </button>
                </>
              )}
              {isEditing && (
                <>
                  {stepCount !== 3 && !isComplete && (
                    <button
                      className="btn btn-outlined-secondary btn-md"
                      onClick={() => updateStepCount(stepCount + 1)}
                      data-bs-dismiss="modal"
                      type="button"
                    >
                      {constVariables.LiveShow.next}
                    </button>
                  )}
                  {isComplete && (
                    <button
                      className="btn btn-outlined-secondary btn-md"
                      onClick={cancelBtnHandler}
                      data-bs-dismiss="modal"
                      type="button"
                    >
                      {constVariables.LiveShow.cancel}
                    </button>
                  )}
                  <button
                    className={clsx('btn btn-primary btn-md ms-5')}
                    onClick={() => editEvent()}
                    disabled={loading || !isDataChanged}
                    type="button"
                  >
                    {constVariables.LiveShow.saveChanges}
                    {loading && <Loader type="button" className="h-10px w-10px" />}
                  </button>
                </>
              )}
            </div>
          </FlexBox>
        </div>
      </div>
    </div>
  );
};

export default CreateLiveShowPresentational;
