import { gql } from '@apollo/client';

export const SIGN_UP_SOCIAL_USER = gql`
  mutation MyMutation($input: SignupInput!) @api(name: "authAPI") {
    signup(input: $input) {
      data {
        email
        isEmailVerified
        thirdPartySignup
      }
    }
  }
`;
