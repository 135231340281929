import { gql } from '@apollo/client';

export const GET_COMMENTS = gql`
  query GetComments($input: GetCommentsInput!) @api(name: "liveStreamingAPI") {
    getComments(input: $input) {
      id
      facebookId
      content
      sendTime
      isPinned
      attributes {
        BSCommentId
      }
      sender {
        userId
        facebookUserId
        attributes {
          storeId
          showId
          userImage
          userName
        }
      }
    }
  }
`;
