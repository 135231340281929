import { useState, useEffect, createRef, FC } from 'react';
import { useParams } from 'react-router';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useMutation } from '@apollo/client';
// import { GET_UESR_TAGS } from 'src/apollo/queries';
import { ADD_USER_TAG, DELETE_USER_TAG } from 'src/apollo/mutations';

// Components
import { TagAndCollectionDropdown, CustomerTags, Portal } from 'src/components/molecules';

// Types
import { CustomerTagsBlockProps } from './CustomerDetails.types';

const CustomerTagsBlock: FC<CustomerTagsBlockProps> = ({ tags, setTags, setFormChanges, selectedTags, setSelectedTags }) => {
  const { customerId } = useParams<{ customerId: string }>();
  const containerRef = createRef<any>();
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownPositions, setDropdownPositions] = useState({
    top: 0,
    left: 0,
    width: 0
  });

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  const [addUserTag] = useMutation(ADD_USER_TAG, {
    onCompleted: (response) => {
      if (response) {
        const item = {
          id: response?.addUserTag?.id,
          name: response?.addUserTag?.tag,
          textColorCode: response?.addUserTag?.color,
          backgroundColorCode: response?.addUserTag?.bgColor
        };
        if (!selectedTags?.find((x) => x.id === item.id)) {
          const arr1: any = Array.from(tags);
          const arr2: any = Array.from(selectedTags);
          arr1.push(item);
          arr2.push(item);
          setFormChanges(true);
          setTags(arr1);
          setSelectedTags(arr2);
        }
      }
    },
    onError: (error) => {
      console.error('Add User Tag error', error);
    }
  });

  const [deleteUserTag] = useMutation(DELETE_USER_TAG, {
    onCompleted: () => {
      // refetchCustomerDetails();
    },
    onError: (error) => {
      console.error('Delete User Tag error', error);
    }
  });

  const handleOpenDropdown = (e) => {
    e.stopPropagation();
    setShowDropdown(true);

    if (containerRef.current) {
      const position = containerRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - position.bottom;
      setDropdownPositions({
        top: spaceBelow < 400 ? window.scrollY + position.top - 363 : window.scrollY + position.top + position.height,
        left: position.left,
        width: position.width
      });
    }
  };

  const changeAppliedTags = (item: any, type: string, action: string) => {
    const arr: any = Array.from(selectedTags);

    if (action === 'remove') {
      const i = arr.indexOf(item);
      arr.splice(i, 1);
    } else {
      arr.push(item);
    }

    setFormChanges(true);
    setSelectedTags(arr);
  };

  const handleAddCustomerTag = (addedTag) => {
    addUserTag({
      variables: {
        input: {
          tag: addedTag
          // userId: parseInt(customerId, 10)
        }
      }
    });
  };

  const handleRemoveCustomerTag = (customerTagId: number) => {
    deleteUserTag({
      variables: {
        input: {
          userId: customerId,
          tagId: customerTagId
        }
      }
    });
  };

  return (
    <div>
      <h2 className="">Tags</h2>
      <div>
        <div className="position-relative" ref={containerRef}>
          <div
            onClick={handleOpenDropdown}
            className="flex-nowrap d-flex align-items-center cursor-pointer h-40px w-100 rounded tags-list-container"
          >
            {selectedTags?.length > 0 && <CustomerTags isProduct={true} userTags={selectedTags} />}
          </div>
        </div>
        {showDropdown && (
          <Portal id="kt_body">
            <OutsideClickHandler onOutsideClick={closeDropdown}>
              <div
                className="position-absolute"
                style={{ top: dropdownPositions.top, left: dropdownPositions.left, width: dropdownPositions.width }}
              >
                <TagAndCollectionDropdown
                  type="Customer Tag"
                  allItems={tags}
                  appliedItems={selectedTags}
                  changeAppliedItems={changeAppliedTags}
                  selected={showDropdown}
                  isCreatable={true}
                  isEditAndDeletable={true}
                  closeDropdown={closeDropdown}
                  addToSubCategory={handleAddCustomerTag}
                  handleDeleteCustomerTag={handleRemoveCustomerTag}
                />
              </div>
            </OutsideClickHandler>
          </Portal>
        )}
      </div>
    </div>
  );
};

export default CustomerTagsBlock;
