import { gql } from '@apollo/client';

export const GET_TIKTOK_PRODUCTS_STATUS_COUNTS = gql`
  query GetTiktokProductStatusCounts @api(name: "tiktokAPI") {
    getTiktokProductStatusCounts {
      totalCount
      activeCount
      inactiveCount
      inReviewCount
      draftCount
      failedCount
      suspendedCount
    }
  }
`;
