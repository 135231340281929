import { gql } from '@apollo/client';

export const BLOCK_CUSTOMER = gql`
  mutation BlockUser($input: BlockUserInput!) @api(name: "productsAPI") {
    blockUser(input: $input) {
      id
      success
      message
    }
  }
`;
