import { gql } from '@apollo/client';

export const SET_GET_SEZZLE_CONFIG = gql`
  mutation SetGetSezzleConfig($input: StoreSezzleConfigInput!) @api(name: "productsAPI") {
    setGetSezzleConfig(input: $input) {
      ... on GenericResponse {
        success
        message
        __typename
      }
      ... on StoreSezzleConfigOutput {
        publicKey
        privateKey
        __typename
      }
    }
  }
`;
