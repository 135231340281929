import { gql } from '@apollo/client';

export const DELETE_FROM_WISHLIST = gql`
  mutation DeleteFromWishList($productId: Int!) @api(name: "productsAPI") {
    deleteFromWishList(productId: $productId) {
      success
      message
    }
  }
`;
