import { gql } from '@apollo/client';

export const ADD_USER_TAG = gql`
  mutation AddUserTag($input: AddUserTagInput!) @api(name: "productsAPI") {
    addUserTag(input: $input) {
      id
      tag
      color
      bgColor
    }
  }
`;
