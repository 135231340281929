import React, { FC } from 'react';
import clsx from 'clsx';
import { FlexBox } from 'src/components/atoms';
import { FilterMenuProps } from './InventoryHistotryModal.types';
import FilterMenuItem from './FilterMenuItem';

const FilterMenu: FC<FilterMenuProps> = ({
  show,
  filters,
  setFilters,
  adjustmentTypes,
  adjustmentUsers,
  handleFilter,
  resetFilters
}) => {
  return (
    <div
      className={clsx(
        'mt-1 menu menu-sub menu-background menu-sub-dropdown menu-column menu-rounded menu-gray-600 w-300px position-absolute overflow-scroll hide-scrollbar end-0 filter-menu',
        { show }
      )}
      data-kt-menu="true"
    >
      <div className="position-relative h-100">
        <FlexBox className="filter-menu-header align-items-center justify-content-between">
          <h6 className="m-0 poppins-regular">Filter</h6>
          {(filters.adjustmentType.length > 0 || filters.adjustmentOwner.length > 0) && (
            <button className="btn clear-btn p-0" onClick={() => resetFilters()}>
              Clear
            </button>
          )}
        </FlexBox>
        <FilterMenuItem
          title="Adjustment type"
          options={adjustmentTypes}
          type="Type"
          filterData={filters.adjustmentType}
          setFilters={setFilters}
        />
        <FilterMenuItem
          title="Adjustment owner"
          options={adjustmentUsers}
          type="Owner"
          filterData={filters.adjustmentOwner}
          setFilters={setFilters}
        />
        <FlexBox className="filter-menu-footer align-items-center justify-content-end">
          {(filters.adjustmentType.length > 0 || filters.adjustmentOwner.length > 0) && (
            <button className="btn btn-sm btn-secondary me-4" onClick={() => resetFilters()}>
              Reset
            </button>
          )}
          <button className="btn btn-sm btn-primary" onClick={handleFilter}>
            Apply
          </button>
        </FlexBox>
      </div>
    </div>
  );
};

export default FilterMenu;
