import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
import moment from 'moment';

// Apollo
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_ORDERS_LIST, GET_SHOPIFY_ADMIN_BASE_URL } from 'src/apollo/queries';
import { GET_ORDER_STATUS_COUNT } from 'src/apollo/queries/getOrderStatusCounts';

// Components
import {
  Tabs,
  SearchBar,
  FixedHeaderTable,
  ExpeditedShippingTooltip,
  FirstTimeCustomerTooltip,
  ShopifySyncTooltip
} from 'src/components/molecules';
import { FlexBox } from 'src/components/atoms';
import { EmptyList } from 'src/components/oraganisms';

// Icons
import { NoProductIcon, FirstTime, Truck, ShopifyIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { TablesProps, RowObjectProps } from 'src/components/molecules/Table/Table.types';

interface queryResult {
  getOrderDetailsList: {
    total: number | undefined;
    orders: any;
  };
}

const CustomerOrders: FC = () => {
  const { customerId } = useParams<{ customerId: string }>();

  const tableData: TablesProps = {
    isDragDisabled: true,
    className: 'overflow-visible',
    headerClassName: 'table-dark-strip'
  };

  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedTab, setSelectedTab] = useState('ALL');
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [orderStatusCountList, setOrderStatusCountList] = useState<any>();
  const [searchText, setSearchText] = useState<string>('');
  const [ordersList, setOrdersList] = useState<any[]>([]);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  const { data: { shopifyAdminBaseUrl } = { shopifyAdminBaseUrl: null } } = useQuery(GET_SHOPIFY_ADMIN_BASE_URL);

  const [getCustomerOrderStatusCount] = useLazyQuery(GET_ORDER_STATUS_COUNT, {
    variables: {
      input: {
        userId: customerId
      }
    },
    onCompleted: (orderStatusCounts) => {
      if (orderStatusCounts && orderStatusCounts.getOrderStatusCounts) {
        const statusCounts = orderStatusCounts.getOrderStatusCounts;
        setOrderStatusCountList({
          [constVariables.TabForOrders[0]]: statusCounts.all ? statusCounts.all : 0,
          [constVariables.TabForOrders[1]]: statusCounts.pending ? statusCounts.pending : 0,
          [constVariables.TabForOrders[2]]: statusCounts.awaitingProcessing ? statusCounts.awaitingProcessing : 0,
          [constVariables.TabForOrders[3]]: statusCounts.awaitingFulfillment ? statusCounts.awaitingFulfillment : 0,
          [constVariables.TabForOrders[4]]: statusCounts.awaitingShipment ? statusCounts.awaitingShipment : 0,
          [constVariables.TabForOrders[5]]: statusCounts.shipped ? statusCounts.shipped : 0
        });
      }
    },
    fetchPolicy: 'cache-and-network',
    onError: (error) => {
      console.error('orderStatusCounts err', error);
    }
  });

  const [getCustomerOrders, { loading: isLoading, data, fetchMore }] = useLazyQuery(GET_ORDERS_LIST, {
    variables: {
      input: {
        customerId,
        search: searchText,
        status: selectedStatus === 'all' ? [] : [selectedStatus],
        pagination: {
          skipCount: 0,
          limitCount: 10
        }
      }
    },
    onCompleted: (response) => {
      if (response?.getOrderDetailsList) {
        setOrdersList(response?.getOrderDetailsList.orders);
        setTotalCount(response?.getOrderDetailsList.total);
      }
    }
  });

  useEffect(() => {
    if (data?.getOrderDetailsList) {
      setOrdersList(data?.getOrderDetailsList.orders);
      setTotalCount(data?.getOrderDetailsList.total);
    }

    if (totalCount === data?.getInventoryHistory?.logs?.length) {
      setIsLoadingMore(false);
    }
  }, [data]);

  useEffect(() => {
    getCustomerOrders();
    getCustomerOrderStatusCount();
  }, [customerId]);

  useEffect(() => {
    if (selectedTab) {
      if (selectedTab.toUpperCase() === 'PENDING') {
        setSelectedStatus('pending');
      } else if (selectedTab.toUpperCase() === 'TO PROCESS') {
        setSelectedStatus('awaiting_processing');
      } else if (selectedTab.toUpperCase() === 'TO FULFILL') {
        setSelectedStatus('awaiting_fulfillment');
      } else if (selectedTab.toUpperCase() === 'TO SHIP') {
        setSelectedStatus('awaiting_shipment');
      } else if (selectedTab.toUpperCase() === 'SHIPPED') {
        setSelectedStatus('shipped');
      } else {
        setSelectedStatus(selectedTab.toLowerCase());
      }
    }
  }, [selectedTab]);

  tableData.headers = [
    {
      id: 1,
      colName: 'Order',
      sorting: true,
      category: 'Order',
      className: 'minMidSize align-middle ps-3 table-header-background'
    },
    {
      id: 2,
      className: 'minMidSize align-middle p-0 border-bottom-0 ps-3 table-header-background',
      colName: 'Date',
      sorting: true,
      category: 'Date'
    },
    {
      id: 3,
      className: 'minBoxSize align-middle p-0 border-bottom-0 ps-3 table-header-background',
      colName: 'Status',
      sorting: true,
      category: 'Status'
    },
    {
      id: 4,
      className: 'minBoxSize align-middle p-0 border-bottom-0 ps-3 table-header-background',
      colName: 'Total',
      sorting: true,
      category: 'Total'
    },
    {
      id: 5,
      className: 'minBoxSize align-middle p-0 border-bottom-0 ps-3 table-header-background',
      colName: 'Items',
      sorting: true,
      category: 'Items'
    },
    {
      id: 6,
      className: 'minBoxSize align-middle p-0 border-bottom-0 ps-3 table-header-background',
      colName: 'Shipping cost',
      category: 'shipping_cost'
    },
    {
      id: 7,
      className: 'minBoxSize align-middle p-0 border-bottom-0 ps-3 table-header-background',
      colName: 'Discount',
      category: 'Discount'
    }
  ];

  const handleSearchOrders = (value) => {
    setSearchText(value);
  };

  const setStatusColor = (status: string, isPartial: boolean) => {
    if (status === 'awaiting_fulfillment') {
      return 'to-fulfil-order-status-box';
    } else if (status === 'pending') {
      return 'pending-order-status-box';
    } else if (status === 'awaiting_payment' || status === 'awaiting_processing') {
      return 'to-process-order-status-box';
    } else if (status === 'awaiting_shipment' || status === 'awiting_pickup' || status === 'partially_shipped') {
      return 'to-ship-order-status-box';
    } else if ((status === 'completed' || status === 'shipped') && isPartial) {
      return 'pending-order-status-box';
    } else if (status === 'completed' || status === 'shipped') {
      return 'shipped-order-status-box';
    } else if (status === 'partially_refund' || status === 'cancelled' || status === 'declined' || status === 'refunded') {
      return 'error-order-status-box';
    } else if (status === 'disputed' || status === 'manual_verification_required') {
      return 'disputed-order-status-box';
    }
    return '';
  };

  const setStatusText = (status: string, isPartial: boolean) => {
    if (status === 'pending') {
      return 'Pending';
    } else if (status === 'awaiting_payment' || status === 'awaiting_processing') {
      return 'To process';
    } else if (status === 'awaiting_fulfillment') {
      return 'To Fulfill';
    } else if (status === 'awaiting_shipment' || status === 'awiting_pickup' || status === 'partially_shipped') {
      return 'To ship';
    } else if ((status === 'completed' || status === 'shipped') && isPartial) {
      return 'Pending';
    } else if (status === 'completed' || status === 'shipped') {
      return 'Shipped';
    } else if (status === 'manual_verification_required') {
      return 'Manual verification required';
    } else if (status === 'partially_refund') {
      return 'Partially refund';
    }
    return status;
  };

  const goToShopifyPage = (shopifyOrderId) => {
    if (shopifyAdminBaseUrl) window.open(`${shopifyAdminBaseUrl}orders/${shopifyOrderId}`, '_blank');
  };

  const arr: Array<RowObjectProps> = [];
  ordersList?.map((orders, index) => {
    arr.push({
      className: `position-relative h-fit-content table-row cursor-pointer`,
      orderDetails: orders,
      id: orders.id,
      cells: [
        {
          className: `minMidSize align-middle ps-2 text-muted ps-3 ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: (
            <div>
              <FlexBox className="align-items-center">
                <div className=" d-flex align-items-center justify-content-center">
                  <Link to={`/orders/orderDetails/${orders.id}`} className="main-cell me-3">
                    <span className="d-block">#{orders.orderCode}</span>
                  </Link>
                  {orders.customerOrders === 1 && (
                    <>
                      <img src={FirstTime} className="mx-1 h-20 w-20" data-tip data-for="firstTimeCustomerIcon" />
                      <FirstTimeCustomerTooltip />
                    </>
                  )}
                  {orders.isExpeditedShipping && (
                    <>
                      <img src={Truck} className="mx-1 h-20 w-20" data-tip data-for="truckIcon" />
                      <ExpeditedShippingTooltip />
                    </>
                  )}
                  {orders?.shopifyId && (
                    <>
                      <img
                        src={ShopifyIcon}
                        className="cursor-pointer mx-1"
                        data-tip
                        data-for="shopifySyncIcon"
                        onClick={() => goToShopifyPage(orders?.shopifyId)}
                      />
                      <ShopifySyncTooltip tooltipText="Order from Shopify" />
                    </>
                  )}
                </div>
              </FlexBox>
            </div>
          )
        },
        {
          className: `minMidSize align-middle ps-0 text-muted ps-3 ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: (
            <FlexBox className="align-items-center">
              {orders.createdAt ? (
                <div>
                  <div className="d-flex align-items-center justify-content-center me-5 ">
                    {moment(orders.createdAt).format('D MMM YYYY, HH:MM')}
                  </div>
                </div>
              ) : null}
            </FlexBox>
          )
        },
        {
          className: `minBoxSize align-middle ps-0 position-relative ps-3 ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: (
            <div>
              {orders.id ? (
                <div
                  className={` ${setStatusColor(
                    orders.orderStatus,
                    orders.isPartiallyShipped
                  )} order-status-box text-nowrap  poppins-medium  cursor-pointer`}
                >
                  <div className="text-capitalize">{setStatusText(orders.orderStatus, orders.isPartiallyShipped)}</div>
                </div>
              ) : null}
            </div>
          )
        },
        {
          className: `minBoxSize align-middle ps-0 text-muted ps-3 ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: (
            <FlexBox className="align-items-center">
              {orders.total ? (
                <div>
                  <div className="d-flex align-items-center me-5 rounded">${orders.total.toFixed(2)}</div>
                </div>
              ) : null}
            </FlexBox>
          )
        },
        {
          className: `minBoxSize align-middle ps-0 text-muted ps-3 ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: (
            <FlexBox className="align-items-center">
              {orders.items ? (
                <div>
                  <div className="d-flex align-items-center me-5 rounded">
                    {orders.availableItemsQuantity} Item{orders.availableItemsQuantity > 1 ? 's' : ''}
                  </div>
                </div>
              ) : null}
            </FlexBox>
          )
        },
        {
          className: `minBoxSize align-middle ps-0 text-muted ps-3 ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: (
            <div>
              {orders.shippingAmount > 0 ? (
                <div>
                  <div className="d-flex align-items-center me-5 rounded">${orders.shippingAmount}</div>
                </div>
              ) : null}
              {orders.shippingAmount === 0 && 'Free Shipping'}
            </div>
          )
        },
        {
          className: `minBoxSize align-middle ps-0 text-muted ps-3 ${index !== 0 ? 'table-top-border' : 'border-0'}`,
          value: (
            <div>
              {orders.discountAmount > 0 ? (
                <div>
                  <div className="d-flex align-items-center me-5 rounded">${orders.discountAmount}</div>
                </div>
              ) : (
                '-'
              )}
            </div>
          )
        }
      ]
    });
    return null;
  });
  tableData.rows = arr;

  const currentStatus = (selectedTab: string | undefined) => {
    switch (selectedTab) {
      case 'PENDING':
        return 'pending';
      case 'TO PROCESS':
        return 'process';
      case 'TO SHIP':
        return 'ship';
      case 'TO FULFILL':
        return 'fulfill';
      case 'SHIPPED':
        return 'shipped';
      default:
        return '';
    }
  };

  const handleTableScroll = async (event: React.UIEvent<HTMLTableElement>) => {
    debouncedScroll(event);
  };

  const debouncedScroll = debounce(async (event) => {
    const scrollPosition = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const skipCount = data?.getOrderDetailsList?.orders?.length;

    if (scrollHeight - scrollPosition === 500) {
      if (totalCount <= data?.getOrderDetailsList?.orders?.length) {
        setIsLoadingMore(false);
        return;
      }
      setIsLoadingMore(true);
      await fetchMore({
        variables: {
          input: {
            customerId,
            search: searchText,
            status: selectedStatus === 'all' ? [] : [selectedStatus],
            pagination: {
              limitCount: 10,
              skipCount
            }
          }
        },
        updateQuery: (previousQueryResult: queryResult, { fetchMoreResult }) => {
          if (fetchMoreResult?.getOrderDetailsList.orders.length === 0) {
            return previousQueryResult;
          }

          return {
            getOrderDetailsList: {
              total: fetchMoreResult?.getOrderDetailsList.total,
              orders: [...previousQueryResult.getOrderDetailsList.orders, ...fetchMoreResult?.getOrderDetailsList.orders]
            }
          };
        }
      });
    }
  }, 1000);

  return (
    <div className="card w-100">
      <h4 className="section-title mb-0">Orders</h4>
      <div className="d-flex align-items-top w-100 justify-content-between m-t-24">
        <Tabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabs={constVariables.TabForOrders}
          showCount={true}
          countValue={orderStatusCountList}
        />
      </div>
      <div className="w-400px m-t-24">
        <SearchBar placeholder={constVariables.AllOrdersMenu.searchForOrders} onChange={handleSearchOrders} />
      </div>
      {!isLoading && totalCount === 0 && !searchText && (
        <>
          {selectedTab === constVariables.OrdersTab.all.toUpperCase() ? (
            <EmptyList
              description={constVariables.emptyDataList.order}
              icon={NoProductIcon}
              isPage={false}
              // addButtonText={constVariables.AllOrdersMenu.createOrder}
              // onAddButton={() => history.push('/orders/createOrder')}
            />
          ) : (
            <EmptyList datatype="order" status={currentStatus(selectedTab)} icon={NoProductIcon} isPage={false} />
          )}
        </>
      )}
      <div className={`m-t-24 table-wrapper ${totalCount === 0 && !searchText ? 'display-none' : ''}`}>
        <FixedHeaderTable
          headers={tableData.headers}
          sortByType={tableData.sortByType}
          sortByCategory={tableData.sortByCategory}
          rows={tableData.rows}
          type="Order"
          isLoading={isLoading || isLoadingMore}
          loadingPosition={isLoadingMore ? 'bottom' : 'center'}
          searchText={searchText}
          onScroll={handleTableScroll}
          containerHeight={500}
        />
      </div>
    </div>
  );
};

export default CustomerOrders;
