import { gql } from '@apollo/client';

export const ADD_LIVE_STREAM = gql`
  mutation AddLiveStream($input: AddLiveStreamProductInput!) @api(name: "liveStreamingAPI") {
    addLiveStream(input: $input) {
      success
      message
      addedLiveStreamId
    }
  }
`;
