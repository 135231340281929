import { FC, memo } from 'react';

// Components
import { Img } from 'src/components/atoms';

// Icons
import { DefaultImage } from 'src/assets/icons';

const CustomerBlock: FC<any> = memo(({ data, index, style }) => {
  const { customers, handleToggleCustomer, selectedCustomers } = data;
  const customer = customers[index];

  const handlChangeCheckbox = () => {
    handleToggleCustomer(customer);
  };

  return (
    <div style={style} className="customer-block-item d-flex align-items-center">
      <div className="d-flex align-items-center">
        <div className="form-check form-check-sm form-check-custom">
          <input
            className={`form-check-input`}
            name="CustomerBlock"
            type="checkbox"
            checked={selectedCustomers?.map((item) => item.id).includes(customer.id)}
            onChange={handlChangeCheckbox}
          />
        </div>
        <div className="d-flex align-items-start">
          <Img
            src={customer?.profilePicture}
            placeholderImg={DefaultImage}
            errorImg={DefaultImage}
            className="coverFit h-40px w-40px ms-2 rounded-circle"
          />
          <div className="ms-3">
            <div className="customerBlockName">{customer?.name}</div>
            <div className="customerBlockEmail mt-1">{customer?.email}</div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default CustomerBlock;
