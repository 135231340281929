import { gql } from '@apollo/client';

export const GET_ORDERS_DETAILS = gql`
  query GetOrderDetails($input: getOrderListInput!) @api(name: "ordersAPI") {
    getOrderDetailsList(input: $input) {
      total
      orders {
        id
        orderCode
        customerId
        orderStatus
        customer
        customerEmail
        customerPhoneNo
        customerOrders
        isEmailVerified
        isPhoneNoVerified
        thumbnailUrl
        orderDetails {
          id
          name
          sku
          code
          quantity
          availableQuantity
          addSignatureConfirmation
          addShipmentInsurance
          price {
            price
            quantity
          }
          orderItemStatus
          productId
          variantId
          imageUrl
          location
          dimensions
          items
          variantTypes {
            id
            name
            value
          }
          shopifyId
        }
        total
        totalWeight
        items
        availableItemsQuantity
        isOrderAddressVerified
        hasWeightErrors
        mediaUrls
        shippingAddress {
          id
          receiverName
          addressLine1
          city
          state
          country
          pincode
          phoneNumber
          isVerified
          type
          userAddressId
        }
        isExpeditedShipping
        isPartiallyShipped
        discountAmount
        shippingAmount
        taxAmount
        subTotal
        createdAt
        updatedAt
        isCombOrdrParent
        isCombOrdrChild
        noOfCombChildOrds
        shopifyId
      }
    }
  }
`;
