import { FC, Fragment } from 'react';
import clsx from 'clsx';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { TabsProps } from './Tabs.types';

// Styles
import './_tabs.scss';

const Tabs: FC<TabsProps> = ({ selectedTab, setSelectedTab, tabs, showCount, countValue, onChange, className }) => {
  return (
    <div className={className}>
      <ul className="nav nav-tabs nav-line-tabs fs-5 border border-transparent">
        {tabs.map((tab, index) => {
          return (
            <Fragment key={index}>
              {tab === 'Failed' && <li className="tab-divider"></li>}
              <li className="nav-item">
                <button
                  className={clsx('nav-link bg-transparent poppins-medium', {
                    active: selectedTab.toLowerCase() === tab.toLowerCase() || `${selectedTab.toLowerCase()}d` === tab.toLowerCase()
                  })}
                  onClick={() => {
                    if (onChange) {
                      onChange();
                    }
                    setSelectedTab(
                      tab === constVariables.ProductsTab.archived
                        ? constVariables.ProductsTab.archive.toUpperCase()
                        : tab.toUpperCase()
                    );
                  }}
                  data-bs-toggle="tab"
                >
                  {tab}
                  {showCount && countValue && <span> ({countValue[tab]})</span>}
                </button>
              </li>
            </Fragment>
          );
        })}
      </ul>
    </div>
  );
};

export default Tabs;
