import { FC, useState } from 'react';

// Components
import { PageHeader } from 'src/components/oraganisms';
import AllPurchaseOrderPresentational from './AllPurchaseOrder.presentational';

// ConstVariables
import { constVariables } from 'src/constants';

const AllPurchaseOrder: FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>('Board');
  return (
    <div className="width-animation w-100 vh-100  hide-scrollbar pe-10">
      <PageHeader
        pageTitle={constVariables.allPurchaseOrders.key}
        addButton={constVariables.allPurchaseOrders.addpurchaseButton}
        removeExportImport
      />
      <AllPurchaseOrderPresentational selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
    </div>
  );
};

export default AllPurchaseOrder;
