import { gql } from '@apollo/client';

export const GET_FB_COMMENTS = gql`
  query GET_FB_COMMENTS($pageId: String!) @api(name: "liveStreamingAPI") {
    getFacebookComments(pageId: $pageId) {
      message
      success
    }
  }
`;
