import { gql } from '@apollo/client';

export const EDIT_PRODUCTS = gql`
  mutation EditProducts($input: [AlterProductInput]!) @api(name: "productsAPI") {
    editProducts(input: $input) {
      __typename
      ... on AlterProductSuccessResponse {
        productIds
        success
        message
        additionalData
      }
      ... on AlterProductErrorResponse {
        success
        productIds
        code
        type
        message
      }
    }
  }
`;
