/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import clsx from 'clsx';

// Components
import { Img } from 'src/components/atoms';

// Hooks && Utils && Helpers
import { setImageSrc } from 'src/utils/setImageSrc';

// Types
import { DropdownProps } from '../../molecules/Dropdown/Dropdown.types';

// Styles
import '../../molecules/Dropdown/_dropdownStyle.scss';

const LiveShowDropdown: FC<DropdownProps> = ({
  data,
  showImage,
  selected,
  onSelect,
  value,
  className,
  shiftLeft,
  lastElement,
  toTop,
  onBlur,
  shiftLeftValue = '-150px',
  listClass,
  noDisableEffect,
  searchDropDown
}) => {
  const toRight = () => {
    if (!shiftLeft) {
      return { right: '0px' };
    }
    return null;
  };
  const toLeft = () => {
    if (shiftLeft) {
      return { left: shiftLeftValue };
    }
    return null;
  };
  const lastEle = () => {
    if (lastElement) {
      return { top: toTop };
    }
    return null;
  };
  return (
    <div
      className={clsx(`dropdown-menu z-index-dropdown ${lastElement ? `lastDropdownElement ` : ''}`, { show: selected }, className)}
      style={{
        ...toRight(),
        ...toLeft(),
        ...lastEle(),
        background: '#FFFFFF',
        borderRadius: '10px',
        padding: '10px 10px 0px 10px',
        width: '475px'
      }}
      onBlur={onBlur}
      aria-labelledby="dropdownMenuButton"
    >
      <div className="dropdown-menu-container overflow-scroll">
        {data && data.length === 0 ? (
          <div className={`d-flex justfy-content-center align-items-center dropdown-item dropdown-height ${listClass} `}>
            No items found
          </div>
        ) : (
          data &&
          data?.map((item, index) => {
            return (
              <div
                onClick={(e) => {
                  if (!item.disabled) {
                    onSelect(item, e);
                  }
                }}
                style={{
                  width: 'unset',
                  padding: '10px 2px 10px 10px'
                }}
                className={`d-flex justfy-content-center align-items-center dropdown-item dropdown-height ${listClass} ${
                  searchDropDown ? 'show-effects' : ''
                } ${item.disabled ? 'opacity-50' : 'cursor-pointer'} `}
                key={`dropdown-${index}`}
              >
                {showImage && (
                  <Img
                    src={setImageSrc(item?.thumbnailUrl ? item?.thumbnailUrl : '')}
                    alt={item.name}
                    className="h-40px w-40px rounded-2"
                  />
                )}
                <div
                  key={index}
                  aria-labelledby="dropdownMenuButton"
                  className="d-flex"
                  style={{
                    flexDirection: 'column',
                    marginLeft: '10px'
                  }}
                >
                  <span
                    className={`option-text poppins-medium ${
                      !noDisableEffect && value?.toLowerCase() === item?.name?.toLowerCase() ? 'text-secondary' : ''
                    }${
                      item.name.toUpperCase() === 'DELETE' ||
                      item.name.toUpperCase() === 'CANCEL ORDER' ||
                      item.name.toUpperCase() === 'DELETE CUSTOMER' ||
                      item.name.toUpperCase() === 'DELETE ADDRESS'
                        ? 'dropdown-item-red '
                        : ''
                    } ${item.className}`}
                  >
                    {item.name}
                  </span>
                  {item?.extraData && <span className="text-black-50">{item?.extraData}</span>}
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default LiveShowDropdown;
