import { FC, useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useLazyQuery } from '@apollo/client';
import { CALCULATE_SHIPPING_CHARGES } from 'src/apollo/queries';

// Redux
import { useAppSelector } from 'src/redux/hooks';

// Components
import { DropdownSelector } from 'src/components/atoms';
import { Dropdown, Checkbox, InputBox } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';
import { checkInvalidCharacter } from 'src/utils/validate';

// Icons
import { InfoIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';
import { SizeCodes, WeightCodes } from './tempData';

// Types
import { ShippingCostObject, ShippingSection } from 'src/components/pages/products/AddProduct/AddProduct.types';
import { UserRoles } from 'src/components/pages/settings/UserPermissions/UserPermissions.type';

// Styles
import './_addProductShipping.scss';

const AddProductShipping: FC<ShippingSection> = ({ shipping, setShipping, errorMessages, setErrorMessages, isDisableInput }) => {
  const [weightOption, setWeightOption] = useState(false);
  const [widthOption, setWidthOption] = useState(false);
  const [heightOption, setHeightOption] = useState(false);
  const [depthOption, setDepthOption] = useState(false);
  const [shippoCost, setShippoCost] = useState<string>('');
  const [shippingCostData, setShippingCostData] = useState<ShippingCostObject>({
    __typename: '',
    amount: '',
    currency: '',
    provider: '',
    // eslint-disable-next-line camelcase
    providerImage75: '',
    // eslint-disable-next-line camelcase
    providerImage200: ''
  });

  const { role } = useAppSelector((state) => state.userInfo.userInfo);

  const updateShippingData = (key: string, value: string | boolean) => {
    setShipping((prev) => ({
      ...prev,
      [key]: value
    }));
    calculateCharges();
  };

  const changeWeightOptions = () => {
    setWeightOption(!weightOption);
  };
  const changeWidthOptions = () => {
    setWidthOption(!widthOption);
  };
  const changeHeightOptions = () => {
    setHeightOption(!heightOption);
  };
  const changeDepthOptions = () => {
    setDepthOption(!depthOption);
  };

  const WeightCodeSelect = (e: any) => {
    updateShippingData('weight', e.name);
    setWeightOption(false);
  };

  const WidthCodeSelect = (e: any) => {
    updateShippingData('measurement', e.name);
    setWidthOption(false);
  };

  const HeightCodeSelect = (e: any) => {
    updateShippingData('measurement', e.name);
    setHeightOption(false);
  };

  const DepthCodeSelect = (e: any) => {
    updateShippingData('measurement', e.name);
    setDepthOption(false);
  };

  const ChangeShippingCost = (e: any) => {
    if (!isNaN(e)) {
      updateShippingData('shippingCost', e);
    }
  };

  const ChangeWeightValue = (e: any) => {
    if (!isNaN(e)) {
      updateShippingData('weightMeasurement', e);
    }
  };

  const ChangeWidthValue = (e: any) => {
    if (!isNaN(e)) {
      updateShippingData('widthMeasurement', e);
    }
  };

  const ChangeHeightValue = (e: any) => {
    if (!isNaN(e)) {
      updateShippingData('heightMeasurement', e);
    }
  };

  const ChangeDepthValue = (e: any) => {
    if (!isNaN(e)) {
      updateShippingData('depthMeasurement', e);
    }
  };

  const changeFreeShippingCheckBox = () => {
    updateShippingData('freeShippingCheck', !shipping.freeShippingCheck);
  };

  const changeSignConfirmationCheckBox = () => {
    updateShippingData('signConfirmationCheck', !shipping.signConfirmationCheck);
  };

  const changeShipmentInsauranceCheckBox = () => {
    updateShippingData('shipmentInsauranceCheck', !shipping.shipmentInsauranceCheck);
  };

  const [calculateCharges, { data: chargesData }] = useLazyQuery(CALCULATE_SHIPPING_CHARGES, {
    variables: {
      input: {
        width: shipping.widthMeasurement ? parseFloat(shipping.widthMeasurement) : 0,
        height: shipping.heightMeasurement ? parseFloat(shipping.heightMeasurement) : 0,
        length: shipping.depthMeasurement ? parseFloat(shipping.depthMeasurement) : 0,
        weight: shipping.weightMeasurement ? parseFloat(shipping.weightMeasurement) : 0,
        distanceUnit: shipping.measurement,
        massUnit: shipping.weight
      }
    },
    onCompleted: () => {
      if (!shipping?.freeShippingCheck) {
        setShippingCostData(chargesData?.calculateShippingCharges);
      }
    },
    onError: (error) => {
      console.error(error);
    }
  });

  useEffect(() => {
    if (shippingCostData && shippingCostData?.amount) {
      setShippoCost(shippingCostData?.amount?.toString());
      setShipping((prev) => ({
        ...prev,
        shippingCost: shippingCostData?.amount?.toString()
      }));
    }
  }, [shippingCostData]);

  return (
    <div className="card w-100">
      <h4 className="section-title m-b-32">{constVariables.common.shipping.key}</h4>
      <div className="row">
        {/* <div className="col-12 m-b-24">
          <div className="row gx-3">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="d-flex align-items-center input-title-section m-b-8">
                <div className="text-nowrap input-title mb-0">{constVariables.common.shipping.shippingCostText}</div>
                <div className="ms-4">
                  <KTSVG path={InfoIcon} />
                </div>
              </div>
              <InputBox
                dollarSign={shipping?.shippingCost.toString().length > 0}
                inputClass="form-control-lg "
                mainclass="mb-2 w-100"
                value={shipping?.freeShippingCheck ? 0 : shipping.shippingCost}
                onChangeText={ChangeShippingCost}
                name="shippingcost"
                placeholder={constVariables.common.shipping.costPlaceholder}
                disable={shipping?.freeShippingCheck || isDisableInput}
              />
            </div>
            {parseFloat(shipping?.shippingCost) < parseFloat(shippoCost) && (
              <span className="warning-text fs-7">{constVariables.common.shipping.shippingCostError}</span>
            )}
            {!shipping.shippingCost && <span className="helper-text">{constVariables.common.shipping.enterDetailText}</span>}
          </div>
        </div> */}
        <div className={`col-12 position-relative`}>
          <div className="row m-b-24 gx-3">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="text-nowrap input-title">{constVariables.common.shipping.weight}</div>
              <div className="d-flex">
                <div className="position-relative">
                  <DropdownSelector
                    className={`right-border-flat form-control form-control-lg  justify-content-between align-items-center cursor-pointer dropdown-box ${
                      weightOption ? 'dropdown-box-active' : ''
                    } ${role === UserRoles.FULFILMENT_ASSOCIATE ? 'disable-background' : ''}`}
                    onClick={changeWeightOptions}
                    selectedValue={shipping.weight}
                    text="Select"
                  />
                  <OutsideClickHandler onOutsideClick={() => setWeightOption(false)}>
                    <Dropdown
                      className="full-width-dropdown"
                      data={WeightCodes}
                      selected={weightOption}
                      value={shipping.weight}
                      onSelect={WeightCodeSelect}
                      closeDropdown={() => setWeightOption(false)}
                    />
                  </OutsideClickHandler>
                </div>
                <InputBox
                  onKeyDown={checkInvalidCharacter}
                  mainclass="col "
                  inputClass="form-control-lg left-border-flat"
                  value={shipping.weightMeasurement}
                  onChangeText={ChangeWeightValue}
                  name={constVariables.common.shipping.weight.trim()}
                  placeholder={constVariables.common.shipping.measurementsPlaceHolder}
                  charLimit={99999999}
                  disable={isDisableInput}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="text-nowrap input-title">{constVariables.common.shipping.width}</div>
              <div className="d-flex">
                <div className="position-relative">
                  <DropdownSelector
                    className={`right-border-flat form-control form-control-lg  justify-content-between align-items-center cursor-pointer dropdown-box ${
                      widthOption ? 'dropdown-box-active' : ''
                    } ${role === UserRoles.FULFILMENT_ASSOCIATE ? 'disable-background' : ''}`}
                    onClick={changeWidthOptions}
                    selectedValue={shipping.measurement}
                    text="Select"
                  />
                  <OutsideClickHandler onOutsideClick={() => setWidthOption(false)}>
                    <Dropdown
                      className="full-width-dropdown"
                      data={SizeCodes}
                      selected={widthOption}
                      value={shipping.measurement}
                      onSelect={WidthCodeSelect}
                      closeDropdown={() => setWidthOption(false)}
                    />
                  </OutsideClickHandler>
                </div>
                <InputBox
                  onKeyDown={checkInvalidCharacter}
                  min="0"
                  mainclass="col"
                  inputClass="form-control-lg left-border-flat"
                  value={shipping.widthMeasurement}
                  onChangeText={ChangeWidthValue}
                  name={constVariables.common.shipping.width.trim()}
                  placeholder={constVariables.common.shipping.measurementsPlaceHolder}
                  charLimit={99999999}
                  disable={isDisableInput}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={`col-12`}>
          <div className="row gx-3">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="text-nowrap input-title">{constVariables.common.shipping.height}</div>
              <div className="d-flex">
                <div className="position-relative">
                  <DropdownSelector
                    className={`right-border-flat form-control form-control-lg  justify-content-between align-items-center cursor-pointer dropdown-box ${
                      heightOption ? 'dropdown-box-active' : ''
                    } ${role === UserRoles.FULFILMENT_ASSOCIATE ? 'disable-background' : ''}`}
                    onClick={changeHeightOptions}
                    selectedValue={shipping.measurement}
                    text="Select"
                  />
                  <OutsideClickHandler onOutsideClick={() => setHeightOption(false)}>
                    <Dropdown
                      className="full-width-dropdown"
                      data={SizeCodes}
                      selected={heightOption}
                      value={shipping.measurement}
                      onSelect={HeightCodeSelect}
                      closeDropdown={() => setHeightOption(false)}
                    />
                  </OutsideClickHandler>
                </div>
                <InputBox
                  onKeyDown={checkInvalidCharacter}
                  min="0"
                  inputClass="form-control-lg left-border-flat"
                  mainclass="col"
                  value={shipping.heightMeasurement}
                  onChangeText={ChangeHeightValue}
                  name={constVariables.common.shipping.height.trim()}
                  placeholder={constVariables.common.shipping.measurementsPlaceHolder}
                  charLimit={99999999}
                  disable={isDisableInput}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="text-nowrap input-title">{constVariables.common.shipping.depth}</div>
              <div className="d-flex">
                <div className="position-relative">
                  <DropdownSelector
                    className={`right-border-flat form-control form-control-lg  justify-content-between align-items-center cursor-pointer dropdown-box ${
                      depthOption ? 'dropdown-box-active' : ''
                    } ${role === UserRoles.FULFILMENT_ASSOCIATE ? 'disable-background' : ''}`}
                    onClick={changeDepthOptions}
                    selectedValue={shipping.measurement}
                    text="Select"
                  />
                  <OutsideClickHandler onOutsideClick={() => setDepthOption(false)}>
                    <Dropdown
                      className="full-width-dropdown"
                      data={SizeCodes}
                      selected={depthOption}
                      value={shipping.measurement}
                      onSelect={DepthCodeSelect}
                      closeDropdown={() => setDepthOption(false)}
                    />
                  </OutsideClickHandler>
                </div>
                <InputBox
                  onKeyDown={checkInvalidCharacter}
                  min="0"
                  mainclass="col"
                  inputClass="form-control-lg left-border-flat"
                  value={shipping.depthMeasurement}
                  onChangeText={ChangeDepthValue}
                  name={constVariables.common.shipping.depth.trim()}
                  placeholder={constVariables.common.shipping.measurementsPlaceHolder}
                  charLimit={99999999}
                  disable={isDisableInput}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="m-t-24">
            {/* <Checkbox
              tagclassname="poppins-medium ps-4 final-scale-checkbox input-title"
              labelclassname="mb-6"
              name={constVariables.common.shipping.freeShipping}
              value={shipping.freeShippingCheck}
              onChangeValue={changeFreeShippingCheckBox}
              // info
              noVerticalPadding
              disable={role === UserRoles.FULFILMENT_ASSOCIATE}
            /> */}
            <Checkbox
              tagclassname="poppins-medium ps-4 final-scale-checkbox"
              labelclassname="mb-6"
              name={constVariables.common.shipping.addSignConfirmation}
              value={shipping.signConfirmationCheck}
              onChangeValue={changeSignConfirmationCheckBox}
              noVerticalPadding
              disable={role === UserRoles.FULFILMENT_ASSOCIATE}
            />
            <Checkbox
              tagclassname="poppins-medium ps-4 final-scale-checkbox"
              name={constVariables.common.shipping.addShipmentInsurance}
              value={shipping.shipmentInsauranceCheck}
              onChangeValue={changeShipmentInsauranceCheckBox}
              noVerticalPadding
              disable={role === UserRoles.FULFILMENT_ASSOCIATE}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProductShipping;
