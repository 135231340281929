import { gql } from '@apollo/client';

export const DELETE_FROM_CATALOGUE = gql`
  mutation DeleteFromCatalogue($input: CatalogueMutationInput!) @api(name: "authAPI") {
    deleteFromCatalogue(input: $input) {
      success
      message
    }
  }
`;
