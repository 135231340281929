/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import clsx from 'clsx';

// Components
import { Divider } from 'src/components/molecules';

// Types
import { DatesDropdownProps } from './DatesDropdown.types';

const DatesDropdown: FC<DatesDropdownProps> = ({ data, selected, onselect, isOpen }) => {
  const shiftLeft = {
    left: 0,
    width: '250px',
    top: '40px',
    zIndex: 150
  };

  return (
    <div
      className={clsx('mt-3 menu menu-sub menu-background menu-sub-dropdown menu-column menu-rounded position-absolute', {
        show: isOpen
      })}
      style={shiftLeft}
      data-kt-menu="true"
    >
      <div className="p-x-24 p-y-12 dropdown-title">Date</div>
      <Divider />
      <div className="p-12">
        {data?.map((item, index) => {
          return (
            <div
              className={`menu-link dropdown-item cursor-pointer p-x-12 p-y-8 ${
                selected === item.name || selected === item.label ? 'text-primary' : ''
              }`}
              key={index}
              onClick={() => onselect(item)}
            >
              {item.label ? item.label : item.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DatesDropdown;
