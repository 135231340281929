import { gql } from '@apollo/client';

export const GET_RETURN_SETTINGS = gql`
  query GetReturnSettings @api(name: "productsAPI") {
    getReturnSetting {
      daysAfterDeliver
      discountRate
      restockingFee
      isFinalSaleEligible
      policyDescription
      resolutionOptions
      allConditionMatch
      returnReasons {
        id
        name
        reasonPosition
      }
      rulesMatch
      productAssignmentRules
      productAssignmentRulesLayout
      productIds
    }
  }
`;
