import { gql } from '@apollo/client';

export const GET_ORDERS_LIST = gql`
  query GetOrderDetailsList($input: getOrderListInput!) @api(name: "ordersAPI") {
    getOrderDetailsList(input: $input) {
      total
      orders {
        id
        orderCode
        orderStatus
        customer
        total
        items
        availableItemsQuantity
        isOrderAddressVerified
        hasWeightErrors
        customerId
        thumbnailUrl
        customerOrders
        isPartiallyShipped
        createdAt
        tags
        shippingAddress {
          isVerified
        }
        isExpeditedShipping
        shippingAmount
        discountAmount
        isVIPCustomer
        isFirstTimeCustomer
        mediaUrls
        customerId
        isCombOrdrParent
        isCombOrdrChild
        noOfCombChildOrds
        shopifyId
      }
    }
  }
`;
