import { FC, useState, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Apollo
import { useLazyQuery } from '@apollo/client';
import { AUTO_COMPLETE_ADDRESS } from 'src/apollo/queries';

// Components
import { InputBox, Dropdown } from 'src/components/molecules';

// ConstVariables
import { constVariables } from 'src/constants';
import { errorTextGroups } from '../ShopDetails';

// Types
import { BusinessDetailsProps, AddressItem } from '../ShopDetails.types';

const BusinessDetails: FC<BusinessDetailsProps> = ({
  storeDetailsData,
  handleUpdateStoreDetails,
  errorMessages,
  countryList,
  validateInputValues
}) => {
  const [addressList, setAddressList] = useState<AddressItem[]>([]);
  const [addressListFiltered, setAddressListFiltered] = useState([]);
  const [showAddressDropdown, setShowAddressDropdown] = useState(false);
  const [showCountryDropdown, setshowCountryDropdown] = useState(false);
  const [countryListFiltered, setCountryListFiltered] = useState(countryList);
  const [searchCountryText, setSearchCountryText] = useState('');
  const [showCountrySearchInput, setShowCountrySearchInput] = useState(false);

  const [autocompleteAddress] = useLazyQuery(AUTO_COMPLETE_ADDRESS, {
    variables: { input: { street: storeDetailsData.address } },
    onCompleted: (data) => {
      if (data?.autoComplete) {
        const tempList = data?.autoComplete.map((data, index) => {
          return { id: index, name: `${data.street_line}, ${data.city}, ${data.state}` };
        });
        setAddressList(data?.autoComplete);
        setAddressListFiltered(tempList);
      }
    }
  });

  useEffect(() => {
    autocompleteAddress();
  }, [storeDetailsData.address]);

  useEffect(() => {
    if (searchCountryText === '') {
      setCountryListFiltered(countryList);
    } else {
      const filteredCountries = countryList.filter((country) =>
        country.name.toLowerCase().includes(searchCountryText.toLowerCase())
      );
      setCountryListFiltered(filteredCountries);
    }
  }, [searchCountryText, countryList]);

  const handleSelectAddress = (address) => {
    const selectedAddress = addressList.find(
      (findData: any) => address.name === `${findData?.street_line}, ${findData.city}, ${findData.state}`
    );
    const addressListKeys = ['city', 'state', 'zipcode'];
    if (selectedAddress) {
      const splitedStreert = selectedAddress?.street_line?.split(' ');
      handleUpdateStoreDetails('address', splitedStreert[0], errorTextGroups.address);
      handleUpdateStoreDetails('apartmentSuite', splitedStreert.slice(1).join(' '), errorTextGroups.address);
      for (let i = 0; i < addressListKeys.length; i++) {
        const fieldName = addressListKeys[i] === 'zipcode' ? 'postalCode' : addressListKeys[i];
        handleUpdateStoreDetails(fieldName, selectedAddress[addressListKeys[i]], errorTextGroups[fieldName]);
      }
    }
    setShowAddressDropdown(false);
  };

  const toggleAddressDropDown = () => {
    setShowAddressDropdown(!showAddressDropdown);
  };

  const handlShowCountrySearchDropdown = () => {
    setshowCountryDropdown(true);
    setShowCountrySearchInput(true);
  };

  const handleSelectCountry = (value) => {
    handleUpdateStoreDetails('country', value, errorTextGroups.country);
    setshowCountryDropdown(false);
    setShowCountrySearchInput(false);
  };

  return (
    <div className="card">
      <h4 className="section-title m-b-32">{constVariables.shopDetails.business.key}</h4>
      <div className="row mb-6 gx-3">
        <div className="col-md-6">
          <InputBox
            title={constVariables.shopDetails.business.businessName}
            value={storeDetailsData.legalBusinessName}
            onChangeText={(value) => handleUpdateStoreDetails('legalBusinessName', value, errorTextGroups.legalBusinessName)}
            name="legalBusinessName"
            charLimit={50}
            inputClass="form-control-lg"
            warningText={errorMessages?.legalBusinessName}
            onBlur={(event) => validateInputValues('legalBusinessName', event.target.value, errorTextGroups.legalBusinessName)}
            placeholder="Business name"
          />
        </div>
      </div>
      <div className="row mb-6 gx-3">
        <div className="col-12">
          <div className="position-relative w-100">
            <div className="input-title-section">
              <div className="input-title">{constVariables.shopDetails.business.address}</div>
            </div>
            <div onClick={toggleAddressDropDown}>
              <InputBox
                inputClass="form-control-lg"
                value={storeDetailsData?.address}
                onChangeText={(value) => handleUpdateStoreDetails('address', value, errorTextGroups.address)}
                name="address"
                warningText={errorMessages?.address}
                onBlur={(event) => validateInputValues('address', event.target.value, errorTextGroups.address)}
                maxLength={100}
                placeholder="Address"
              />
            </div>

            {addressListFiltered && storeDetailsData?.address?.length > 0 && addressListFiltered.length > 0 && (
              <OutsideClickHandler onOutsideClick={() => setShowAddressDropdown(false)}>
                <Dropdown
                  className="dropdown-custom-width"
                  data={addressListFiltered}
                  selected={showAddressDropdown}
                  value={storeDetailsData?.address}
                  onSelect={handleSelectAddress}
                  closeDropdown={() => setShowAddressDropdown(false)}
                />
              </OutsideClickHandler>
            )}
          </div>
        </div>
      </div>
      <div className="row gx-3">
        <div className="col-12">
          <InputBox
            title={constVariables.shopDetails.business.apartment}
            value={storeDetailsData.apartmentSuite}
            onChangeText={(value) => handleUpdateStoreDetails('apartmentSuite', value)}
            name="apartmentSuite"
            charLimit={50}
            inputClass="form-control-lg"
            warningText={errorMessages?.apartmentSuite}
            placeholder="Apartment, suite..."
          />
        </div>
      </div>
      <div className="row my-6 gx-3">
        <div className="col-md-6">
          <InputBox
            title={constVariables.shopDetails.business.city}
            value={storeDetailsData.city}
            onChangeText={(value) => handleUpdateStoreDetails('city', value, errorTextGroups.city)}
            name="city"
            charLimit={50}
            inputClass="form-control-lg"
            warningText={errorMessages?.city}
            onBlur={(event) => validateInputValues('city', event.target.value, errorTextGroups.city)}
            placeholder="City"
          />
        </div>
        <div className="col-md-6">
          <div className="input-title-section">
            <div className="input-title">{constVariables.shopDetails.business.postalCode}</div>
          </div>
          <div className="d-flex form-control p-0 align-item-center form-control-lg input-box">
            <input
              autoComplete="off"
              className={`border-0 w-100 h-100 px-5 ${errorMessages.postalCode ? 'input-error' : ''}`}
              type="text"
              pattern="[0-9]+"
              name="postalCode"
              value={storeDetailsData.postalCode}
              onChange={(e) => {
                e.target.validity.valid && handleUpdateStoreDetails('postalCode', e.target.value, errorTextGroups.postalCode);
              }}
              onBlur={(event) => validateInputValues('postalCode', event.target.value, errorTextGroups.postalCode)}
              placeholder="Postal code"
            />
          </div>
          {errorMessages.postalCode && <div className="warning-text p-0 fs-7">{errorMessages.postalCode}</div>}
        </div>
      </div>
      <div className="row gx-3">
        <div className="col-md-6">
          <InputBox
            title={constVariables.shopDetails.business.state}
            value={storeDetailsData.state}
            onChangeText={(value) => handleUpdateStoreDetails('state', value, errorTextGroups.state)}
            name="state"
            charLimit={50}
            inputClass="form-control-lg"
            warningText={errorMessages?.state}
            onBlur={(event) => validateInputValues('state', event.target.value, errorTextGroups.state)}
            placeholder="State"
          />
        </div>
        <div className="col-md-6">
          <div className="w-100 position-relative cursor-pointer">
            <div className="input-title-section">
              <div className="input-title">{constVariables.shopDetails.business.country}</div>
            </div>
            {!showCountrySearchInput && !showCountryDropdown && (
              <>
                <div className="form-control form-control-lg" onClick={handlShowCountrySearchDropdown}>
                  <div className="helper-text-one">{storeDetailsData.country ? storeDetailsData.country : ''}</div>
                </div>
                {errorMessages.country && <div className="warning-text p-0 fs-7">{errorMessages.country}</div>}
              </>
            )}
            <OutsideClickHandler
              onOutsideClick={() => {
                setshowCountryDropdown(false);
                setShowCountrySearchInput(false);
              }}
            >
              <div className="position-relative">
                {showCountrySearchInput && (
                  // <InputBox
                  //   inputClass="form-control-lg"
                  //   value={searchCountryText}
                  //   onChangeText={(value) => setSearchCountryText(value)}
                  //   name="countrySearch"
                  // />
                  <input
                    type="text"
                    className="form-control form-control-lg w-100 country-search-input input-box"
                    onChange={(event) => setSearchCountryText(event.target.value)}
                    name="countrySearch"
                    autoFocus
                    placeholder="Country"
                  />
                )}
                <Dropdown
                  data={countryListFiltered}
                  selected={showCountryDropdown}
                  value={storeDetailsData.country}
                  onSelect={(value) => handleSelectCountry(value.name)}
                  closeDropdown={() => setshowCountryDropdown(false)}
                />
              </div>
            </OutsideClickHandler>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessDetails;
