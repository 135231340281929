import React, { FC } from 'react';

// Components
import CustomModalPopup from '../CustomModalPopup/CustomModalPopup';

interface EndRelationShipPopupProps {
  title: string;
  message: string;
  cancelButton: string;
  confirmButton: string;
  open: boolean;
  handleConfirmEnd(e: React.MouseEvent): void;
  closeModal(): void;
}

const EndRelationshipPopup: FC<EndRelationShipPopupProps> = (props) => {
  const { title, message, cancelButton, confirmButton, open, handleConfirmEnd, closeModal } = props;
  return (
    <CustomModalPopup
      title={title}
      message={message}
      cancelButton={cancelButton}
      confirmButton={confirmButton}
      open={open}
      handleConfirm={handleConfirmEnd}
      closeModal={closeModal}
      type="End relationship"
    />
  );
};

export default EndRelationshipPopup;
