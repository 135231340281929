import { FC, useEffect, useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import clsx from 'clsx';

// Apollo
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { GET_SHOPIFY_ADMIN_BASE_URL } from 'src/apollo/queries';
import { DELETE_COUPON, EDIT_COUPON } from 'src/apollo/mutations';
import { addErrorFilter, orderWithErrors, showOrderError } from 'src/apollo/reactiveVariables';

// Components
import { FlexBox } from 'src/components/atoms';
import { SearchBar, SortDropdown, DataTable, ShopifySyncTooltip } from 'src/components/molecules';
import { MoreActionDropdown } from 'src/components/oraganisms';
import SortableTableHeader from 'src/components/molecules/DataTable/components/SortableTableHeader';
import StatusCell from './components/StatusCell';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers/components/KTSVG';

// Icons
import { SortIcon, ShopifyIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables, ROUTES } from 'src/constants';
import { SortIds } from './data';

// Types
import { ColumnType } from 'src/components/molecules/Table/Table.types';
import { CouponsListProps } from './CouponsList.types';
import { CouponsListData } from 'src/components/pages/coupons/AllCouponsPage/AllCouponsPage.type';
import { moreActionMenuItem } from '../MoreActionDropdown/MoreActionDropdown.types';

// Styles
import './_couponsList.scss';

const CouponsList: FC<CouponsListProps> = ({
  couponsList,
  setCouponsList,
  setSortBy,
  sortBy,
  selectedTab,
  setCouponInput,
  isLoading,
  searchText,
  editColumnData
}) => {
  const { cache } = useApolloClient();
  const [ordersWithError, setOrdersWithError] = useState<Array<CouponsListData>>(orderWithErrors() || []);
  const [activeCouponsList, setActiveCouponsList] = useState<Array<CouponsListData>>(couponsList);
  const [selectedCouponIDs, setSelectedCouponIDs] = useState<Array<number>>([]);
  const [showErrorFilter, setShowErrorFilter] = useState(addErrorFilter());
  const [openSortDropdown, setOpenSortDropdown] = useState(false);
  const [showMoreAction, setShowMoreAction] = useState(false);
  const [activeColumns, setActiveColumns] = useState<ColumnType[]>([]);

  const { data: { shopifyAdminBaseUrl } = { shopifyAdminBaseUrl: null } } = useQuery(GET_SHOPIFY_ADMIN_BASE_URL);

  const [editCoupon] = useMutation(EDIT_COUPON);

  useEffect(() => {
    if (editColumnData?.length > 0) {
      const arr = Array.from(editColumnData);
      editColumnData.map((column) => {
        if (!column.status) {
          arr.splice(arr.indexOf(column), 1);
        }
        return null;
      });
      setActiveColumns([...arr]);
      localStorage.setItem('couponTable', JSON.stringify(editColumnData));
    }
  }, [editColumnData]);

  useEffect(() => {
    setOrdersWithError([...orderWithErrors()]);
  }, [orderWithErrors()]);

  useEffect(() => {
    setShowErrorFilter(false);
    showOrderError(false);
    addErrorFilter(false);
    orderWithErrors([]);
  }, []);

  const ChangeSortBy = (field: string, id: number) => {
    if (sortBy.catagory === field) {
      if (sortBy.type === 'DESC') {
        setSortBy({
          ...sortBy,
          type: 'ASC'
        });
      } else {
        setSortBy({
          ...sortBy,
          type: 'DESC'
        });
      }
    } else {
      setSortBy({
        catagory: field,
        id,
        type: 'DESC'
      });
    }
    setOpenSortDropdown(false);
  };

  useEffect(() => {
    if (showErrorFilter && selectedTab && selectedTab?.toUpperCase() === 'TO PROCESS') {
      setActiveCouponsList(
        couponsList.filter(function (obj) {
          return ordersWithError.findIndex((x) => x.id === obj.id) !== -1;
        })
      );
    } else {
      setActiveCouponsList(couponsList);
    }
  }, [showErrorFilter, couponsList]);

  const selectCoupon = useCallback(
    (e: any, couponId?: any) => {
      if (e === 'All') {
        setSelectedCouponIDs(couponsList.map((coupon) => coupon.id));
      } else if (e.target.checked) {
        setSelectedCouponIDs([...selectedCouponIDs, couponId]);
      } else {
        setSelectedCouponIDs(selectedCouponIDs.filter((couponItemId) => couponItemId !== couponId));
      }
    },
    [couponsList, selectedCouponIDs]
  );

  const handleUpdateCouponStatus = (id: number, status: string) => {
    editCoupon({
      variables: {
        input: {
          id,
          couponStatus: status.toUpperCase()
        }
      }
    })
      .then((res) => {
        if (res.data.editCoupon.success) {
          const updatedCouponsList = couponsList.map((couponItem) => {
            if (couponItem.id === id) {
              return { ...couponItem, status: status.toUpperCase() };
            }
            return couponItem;
          });
          setCouponsList(updatedCouponsList);
        }
      })
      .catch((error) => {
        console.log('error::', error);
      });
  };

  const goToShopifyPage = (shopifyCouponId) => {
    if (shopifyAdminBaseUrl) window.open(`${shopifyAdminBaseUrl}discounts/${shopifyCouponId}`, '_blank');
  };

  const initialColumnOptions = [
    {
      dataKey: 'couponName',
      name: 'Coupon code',
      width: 300,
      headerRenderer: () => {
        return (
          <div className="form-check form-check-sm form-check-custom">
            <input
              className="me-5 form-check-input widget-9-check"
              type="checkbox"
              checked={activeCouponsList?.length > 0 && activeCouponsList.length === selectedCouponIDs.length}
              onChange={() => {
                if (activeCouponsList.length === selectedCouponIDs.length) {
                  setSelectedCouponIDs([]);
                } else {
                  selectCoupon('All');
                }
              }}
            />
            <SortableTableHeader label="Coupon code" sortHeader={() => ChangeSortBy('NAME', 881)} type={sortBy.type} />
          </div>
        );
      },
      cellRenderer: ({ rowData }) => {
        return (
          <div className="d-flex align-items-center">
            <div className="form-check form-check-sm form-check-custom">
              <input
                className="me-5 form-check-input widget-9-check"
                type="checkbox"
                checked={selectedCouponIDs.includes(rowData.id)}
                onChange={(e) => {
                  selectCoupon(e, rowData.id);
                }}
              />
            </div>

            <FlexBox className="align-items-center justify-content-center me-5">
              <Link to={`${ROUTES.coupons.createCoupon.main}/${rowData.id}`} className="main-cell">
                <span className="d-block">{rowData.name}</span>
              </Link>
              {rowData?.externalDiscountProvider === 'SHOPIFY' && (
                <>
                  <img
                    src={ShopifyIcon}
                    className="cursor-pointer m-l-12"
                    data-tip
                    data-for="shopifySyncIcon"
                    onClick={() => goToShopifyPage(rowData?.externalDiscountId)}
                  />
                  <ShopifySyncTooltip tooltipText="Coupon from Shopify" />
                </>
              )}
            </FlexBox>
          </div>
        );
      },
      staticColumn: true
    },
    {
      dataKey: 'status',
      name: 'Status',
      width: 150,
      cellRenderer: ({ rowData }) => {
        return <StatusCell id={rowData.id} status={rowData.status} updateStatus={handleUpdateCouponStatus} />;
      }
    },
    {
      dataKey: 'method',
      name: 'Method',
      width: 150,
      cellRenderer: ({ rowData }) => {
        return (
          <FlexBox className="align-items-center">{rowData.couponValue ? couponMethodText(rowData.couponValue) : '-'}</FlexBox>
        );
      }
    },
    {
      dataKey: 'type',
      name: 'Type',
      width: 200,
      cellRenderer: ({ rowData }) => {
        return <FlexBox className="align-items-center">{rowData.couponType ? couponTypeText(rowData.couponType) : '-'}</FlexBox>;
      }
    },
    {
      dataKey: 'timesUsed',
      name: 'Used',
      width: 150,
      headerRenderer: () => {
        return <SortableTableHeader label="Used" sortHeader={() => ChangeSortBy('TIMES_USED', 882)} type={sortBy.type} />;
      },
      cellRenderer: ({ rowData }) => {
        return <FlexBox className="align-items-center">{rowData.timesUsed ? rowData.timesUsed : '-'}</FlexBox>;
      }
    }
  ];

  const columnOptions = useMemo(() => {
    const activeColumnNames = activeColumns?.map((column) => column.name);
    // eslint-disable-next-line array-callback-return
    const filteredColumns = initialColumnOptions
      ?.filter((column) => {
        if (column.staticColumn || activeColumnNames?.includes(column?.name)) return true;
        return false;
      })
      .sort(function (a, b) {
        return activeColumnNames.indexOf(a.name) - activeColumnNames.indexOf(b.name);
      });
    return filteredColumns;
  }, [activeColumns, selectedCouponIDs, couponsList]);

  const couponMethodText = (status: string | null) => {
    if (status === 'PERCENTAGE') {
      return 'Percentage';
    } else if (status === 'FLAT') {
      return 'Flat';
    }
  };

  const couponTypeText = (status: string | null) => {
    if (status === 'AMOUNT_OF_ORDER') {
      return 'Amount off order';
    } else if (status === 'AMOUNT_OF_PRODUCT') {
      return 'Amount off products';
    } else if (status === 'FREE_SHIPPING') {
      return 'Free Shipping';
    } else if (status === 'BUY_X_GET_Y') {
      return 'Buy X Get Y';
    }
  };

  const [deleteSelectedCoupons] = useMutation(DELETE_COUPON, {
    onCompleted: (data) => {
      console.error('Dele Coupon Successful', data);
    },
    onError: (err) => {
      console.error('Dele Coupon Error', err);
    }
  });

  const delThisCoupon = () => {
    setShowMoreAction(false);
    deleteSelectedCoupons({
      variables: {
        input: {
          id: selectedCouponIDs
        }
      }
    });
  };
  useEffect(() => {
    showOrderError(false);
    addErrorFilter(false);
    setShowErrorFilter(false);
    orderWithErrors([]);
  }, [selectedTab]);

  const searchOrders = (value) => {
    setCouponInput((couponInput) => {
      return { ...couponInput, filters: { searchText: value } };
    });
  };

  const moreActionMenus: Array<moreActionMenuItem[]> = [
    [
      {
        id: 1,
        text: 'Activate coupons',
        onClick: () => null
      },
      {
        id: 2,
        text: 'Deactivate coupons',
        onClick: () => null
      },
      {
        id: 3,
        text: 'Delete coupons',
        onClick: () => delThisCoupon(),
        deleteOption: true
      }
    ]
  ];

  const closeMoreActionDropdown = () => {
    setShowMoreAction(false);
  };

  return (
    <div className="couponList w-100">
      <div className={clsx({ 'display-none': couponsList?.length === 0 && !searchText })}>
        <FlexBox className="justify-content-between w-100">
          <span className="searchBar">
            <SearchBar placeholder={constVariables.Coupons.AllCouponMenu.searchForCoupons} onChange={searchOrders} />
          </span>
          <FlexBox className="pb-1">
            <div className="position-relative ms-3">
              <OutsideClickHandler
                onOutsideClick={() => {
                  if (openSortDropdown) setOpenSortDropdown(false);
                }}
              >
                <button
                  className={`btn optBtn btn-sm btn-flex btn-icon-text align-items-center ${
                    openSortDropdown ? 'btn-primary' : 'btn-active-primary'
                  }`}
                  onClick={() => {
                    setOpenSortDropdown(!openSortDropdown);
                  }}
                >
                  <KTSVG path={SortIcon} className="me-1" />
                  <span className="my-auto me-0">Sort</span>
                </button>
                <SortDropdown
                  data={SortIds}
                  value={sortBy}
                  onSelect={ChangeSortBy}
                  selected={openSortDropdown}
                  closeDropdown={() => setOpenSortDropdown(false)}
                />
              </OutsideClickHandler>
            </div>
          </FlexBox>
        </FlexBox>
      </div>

      <FlexBox className="m-t-24 justify-content-start align-items-center">
        {selectedCouponIDs.length > 0 && (
          <>
            <button className="btn btn-outlined-primary btn-sm me-2" onClick={() => setSelectedCouponIDs([])}>
              <div className="d-flex align-items-center">
                <input
                  className={'form-check-input small-checkbox-size shadow-none opacity-100 bg-primary rounded-sm m-0'}
                  type="checkbox"
                  disabled={true}
                  ref={(input) => {
                    if (input) {
                      input.indeterminate = true;
                    }
                  }}
                />
                <span className="ms-2">{selectedCouponIDs.length} Selected</span>
              </div>
            </button>
            <div className="position-relative">
              <button
                className={`btn btn-sm m-r-16 ${showMoreAction ? 'btn-primary' : 'btn-outlined-primary'}`}
                onClick={() => setShowMoreAction(true)}
              >
                {constVariables.ProductsTab.moreActions}
              </button>
              <OutsideClickHandler onOutsideClick={closeMoreActionDropdown}>
                <MoreActionDropdown selected={showMoreAction} options={moreActionMenus} closeDropdown={closeMoreActionDropdown} />
              </OutsideClickHandler>
            </div>
          </>
        )}
      </FlexBox>
      <div className="p-t-12">
        <DataTable
          page="coupon"
          rowData={couponsList}
          columnOptions={columnOptions}
          isLoading={isLoading}
          searchText={searchText}
          selectedIDs={selectedCouponIDs}
          selectedIndex={-1}
        />
      </div>
    </div>
  );
};

export default CouponsList;
