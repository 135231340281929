import { useState, createRef, FC } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

// Components
import { Portal, Dropdown } from 'src/components/molecules';

// Hooks && Utils && Helpers
import { KTSVG } from 'src/helpers';

// Icons
import { ThreeDotsIcon } from 'src/assets/icons';

// ConstVariables
import { constVariables } from 'src/constants';

interface ActionCellProps {
  id: string | number;
  name: string;
  handleMenuOptions?: (optionId: number, collectionName: string, collectionId: string | number) => void;
}

const ActionCell: FC<ActionCellProps> = ({ id, name, handleMenuOptions }) => {
  const actionRef = createRef<any>();
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropDownPositions, setDropDownPositions] = useState({
    top: 0,
    left: 0
  });

  const handleOpenDropdown = (e) => {
    e.preventDefault();
    setShowDropdown(true);
    if (actionRef.current) {
      const position = actionRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - position.bottom;
      setDropDownPositions({
        // top: spaceBelow < 150 ? window.scrollY + position.top - 110 : window.scrollY + position.top + 40,
        top: window.scrollY + position.top + 40,
        left: position.left - 150
      });
    }
  };

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  const handleCollectionOption = (option) => {
    handleMenuOptions && handleMenuOptions(option.id, name, id);
    setShowDropdown(false);
  };

  return (
    <>
      <div className="position-relative">
        <button className="bg-transparent border border-transparent" onClick={handleOpenDropdown} ref={actionRef}>
          <KTSVG path={ThreeDotsIcon} svgClassName="cursor-pointer collapseFilter collapseIcon" />
        </button>
      </div>
      {showDropdown && (
        <Portal id="kt_body">
          <OutsideClickHandler
            onOutsideClick={() => {
              if (showDropdown) closeDropdown();
            }}
          >
            <div className="position-absolute" style={{ top: dropDownPositions.top, left: dropDownPositions.left, width: 175 }}>
              <Dropdown
                className="full-width-dropdown"
                data={constVariables.collectionOptionsDropdownData}
                selected={showDropdown}
                onSelect={handleCollectionOption}
                closeDropdown={closeDropdown}
                noScroll
              />
            </div>
          </OutsideClickHandler>
        </Portal>
      )}
    </>
  );
};

export default ActionCell;
