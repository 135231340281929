import { FC } from 'react';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { orderDetailsData } from 'src/components/oraganisms/OrdersList/OrdersList.types';

// Styles
import './CreateOrder.scss';

interface CreateOrderPaymentDetailsProps {
  tempdata?: number;
  orderDetails?: orderDetailsData;
}

const CreateOrderPaymentDetails: FC<CreateOrderPaymentDetailsProps> = (props) => {
  return (
    <>
      <div className="row">
        <h3 className="poppins-regular m-auto-0 text-nowrap dark-color px-2 pb-5">{constVariables.orderDetails.paymentDetails}</h3>
        <div className="col-10 paymentDetails">
          <span className="poppins-regular">{constVariables.orderDetails.subTotal}</span>
        </div>
        <div className="col-1">
          <p className="float-right">$0.00</p>
        </div>
      </div>
      <div className="row greyText">
        <div className="col-10 paymentDetails">
          <span className="poppins-regular">{constVariables.orders.tax}</span>
        </div>
        <div className="col-1">
          <p className="float-right">$0.00</p>
        </div>
      </div>
      <div className="row greyText">
        <div className="col-10 paymentDetails">
          <span className="poppins-regular">{constVariables.orders.discount}</span>
        </div>
        <div className="col-1">
          <p className="float-right">-$0.00</p>
        </div>
      </div>
      <div className="row greyText">
        <div className="col-10 paymentDetails">
          <span className="poppins-regular">{constVariables.orders.shipping}</span>
        </div>
        <div className="col-1">
          <p className="float-right">$0.00</p>
        </div>
      </div>
      <div className="row">
        <div className="col-10 paymentDetails">
          <span className="poppins-regular">{constVariables.orders.total}</span>
        </div>
        <div className="col-1">
          <p className="float-right">$0.00</p>
        </div>
      </div>
    </>
  );
};

export default CreateOrderPaymentDetails;
