import { gql } from '@apollo/client';

export const CALCULATE_SHIPPING_CHARGES = gql`
  query CalculateShippingCharges($input: CalculateShippingInput) @api(name: "ordersAPI") {
    calculateShippingCharges(input: $input) {
      amount
      currency
      provider
      providerImage75
      providerImage200
    }
  }
`;
