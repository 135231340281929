import { ApolloQueryResult, OperationVariables } from '@apollo/client';

export interface InviteModalProps {
  roles: RoleItem[];
  closeModal: () => void;
  refetchUsers: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>;
  inviteMode: string;
  user?: StoreUser;
}

export interface MoreActionsProps {
  user: StoreUser;
  userAction: (id: number, name: string, user: StoreUser) => void;
}

export interface UserRoleProps {
  role: string;
  userId: number;
  roles: RoleItem[];
  runChangeUserRole: (id: number, name: string, userId: number) => void;
}

export interface InviteData {
  email: string;
  role: number | undefined;
}

export interface RoleItem {
  id?: number;
  canBeNotifiedWhenOrderPlaced: boolean;
  role: string;
  roleDisplayName: string;
  permissions: string[];
}

export interface StoreUser {
  storeUserId: number;
  firstName: string;
  lastName: string;
  role: string;
  roleDisplayName: string;
  email: string;
  lastLoggedinDate: string;
  invitationStatus: string;
  canBeNotifiedWhenOrderPlaced: boolean;
  profileUrl: string;
}

export enum UserRoles {
  OWNER = 'ROLE_OWNER',
  ADMIN = 'ROLE_ADMIN',
  ADMIN_ASSOCIATE = 'ROLE_ADMIN_ASSOCIATE',
  WAREHOUSE_MANAGER = 'ROLE_WAREHOUSE_MANAGER',
  FULFILMENT_ASSOCIATE = 'ROLE_FULFILMENT_ASSOCIATE',
  WAREHOUSE_ASSOCIATE = 'ROLE_WAREHOUSE_ASSOCIATE'
}
