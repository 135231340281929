import React, { useCallback } from 'react';
import { CellProps } from 'react-datasheet-grid';

// Hooks && Utils && Helpers
import { convertWeight } from 'src/utils/weightConverter';
import { convertMeasurement } from 'src/utils/measurementConverter';

const DimensionInputCell = React.memo(({ rowData, setRowData, active, focus, columnData }: CellProps) => {
  const dimensions = JSON.parse(rowData?.dimensions);

  const callbackRef = useCallback(
    (inputElement) => {
      if (inputElement && focus) {
        setTimeout(() => {
          inputElement.focus();
          inputElement.select();
        }, 100);
      }
    },
    [focus]
  );

  const handleChange = (e) => {
    const value = e.target.value;
    const regex = /^\d*(\.\d{0,2})?$/;
    if (focus && (value === '' || regex.test(value))) {
      const updatedDimensions = { ...dimensions, [columnData.key]: { value, unit: columnData.unit } };
      const updatedRowdata = { ...rowData, dimensions: JSON.stringify(updatedDimensions) };
      setRowData(updatedRowdata);
    }
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  };

  return (
    <input
      style={{ pointerEvents: focus ? 'auto' : 'none' }}
      autoFocus={focus ? true : false}
      tabIndex={-1}
      className="dsg-input"
      value={
        !focus
          ? `${
              columnData.key === 'weight'
                ? convertWeight(
                    dimensions ? dimensions[columnData.key]?.value : undefined,
                    dimensions ? dimensions[columnData.key]?.unit : undefined,
                    columnData.unit
                  )
                : convertMeasurement(
                    dimensions ? dimensions[columnData.key]?.value : undefined,
                    dimensions ? dimensions[columnData.key]?.unit : undefined,
                    columnData.unit
                  )
            } ${columnData.unit}`
          : `${
              columnData.key === 'weight'
                ? convertWeight(
                    dimensions ? dimensions[columnData.key]?.value : undefined,
                    dimensions ? dimensions[columnData.key]?.unit : undefined,
                    columnData.unit
                  )
                : convertMeasurement(
                    dimensions ? dimensions[columnData.key]?.value : undefined,
                    dimensions ? dimensions[columnData.key]?.unit : undefined,
                    columnData.unit
                  )
            }`
      }
      ref={callbackRef}
      onKeyDown={handleKeyPress}
      onChange={(e) => {
        e.target.validity.valid && handleChange(e);
      }}
      pattern="^\d*(\.\d{0,2})?$"
      type="text"
    />
  );
});

export default DimensionInputCell;
