import React, { FC } from 'react';

// Components
import { SearchSelectDropdown } from 'src/components/molecules';

// Types
import { EditInventoryLocationProps } from './EditInventoryLocation.types';

const EditInventoryLocation: FC<EditInventoryLocationProps> = ({
  locationId,
  setLocationId,
  locations,
  selectedLocation,
  setSelectedLocation,
  handleAddLocation,
  handleUpdateLocation,
  handleDeleteLocation,
  handleShowDeleteConfirmModal
}) => {
  return (
    <div>
      <div>Select Location</div>

      <SearchSelectDropdown
        type="location"
        hideTitle={true}
        inputPlaceholderText="Enter location"
        options={locations}
        handleAddOption={handleAddLocation}
        handleUpdateOption={handleUpdateLocation}
        handleDeleteOption={handleDeleteLocation}
        isUseOutsideConfirmModal={true}
        isControllable={true}
        handleShowDeleteConfirmModal={handleShowDeleteConfirmModal}
        setSelectedOptionItem={setSelectedLocation}
        optionItemId={locationId}
        setOptionItemId={setLocationId}
        isControllableDirection={false}
        className="location-search-dropdown"
      />
    </div>
  );
};

export default React.memo(EditInventoryLocation);
