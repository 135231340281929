import { constVariables } from 'src/constants';

export const DefaultChannels = {
  // online: {
  //   title: constVariables.common.channelList.channel1Title,
  //   key: constVariables.common.channelList.channel1Key,
  //   value: false
  // },
  mobile: {
    title: constVariables.common.channelList.channel2Title,
    key: constVariables.common.channelList.channel2Key,
    value: false
  }
  // facebook: {
  //   title: constVariables.common.channelList.channel3Title,
  //   key: constVariables.common.channelList.channel3Key,
  //   value: false
  // }
};

export interface ChannelType {
  title?: string;
  key?: string;
  value: boolean;
}
export interface Channels {
  // online: ChannelType;
  mobile: ChannelType;
  // facebook: ChannelType;
}
export interface StatusInfo {
  title: string;
  placeholder: string;
  helperText: string;
}
export interface StatusSectionTypes {
  statusInfo: StatusInfo;
  status: string;
  channels: Channels;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
  updateChannelValues: (channelObject: ChannelType) => void;
  setFormChanges?: React.Dispatch<React.SetStateAction<boolean>>;
  setColApiCall?: React.Dispatch<React.SetStateAction<boolean>>;
  page?: string;
  productAvailableFrom?: string | null;
  setProductAvailableFrom?: React.Dispatch<React.SetStateAction<string | undefined | null>>;
}
