import { gql } from '@apollo/client';

export const GET_PRODUCT_CATEGORIES = gql`
  query getProductCategories($input: GetProductCategoriesInput!) @api(name: "productsAPI") {
    getProductCategories(input: $input) {
      categories {
        id
        name
      }
    }
  }
`;
