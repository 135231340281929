import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Components
import { StatusSection, GeneralInfoSection, TypeSection } from 'src/components/templates';
import { OrganizationSection, Thumbnail, DiscardModal, CustomModal, ErrorModal } from 'src/components/oraganisms';
import { RulesEngineProducts } from 'src/components/molecules';

// ConstVariables
import { constVariables } from 'src/constants';

// Types
import { CreateCollectionPresentationalProps } from './CreateCollection.types';
import { productAssignmentMethod } from './CreateCollection.types';

const CreateCollectionPresentational: FC<CreateCollectionPresentationalProps> = ({
  selectedOrganization,
  updateChannelValues,
  formBackBtnHandler,
  thumbnailUrlData,
  setThumbnailUrlData,
  leavePageHandler,
  setOrganization,
  setGeneralInfo,
  errorMessages,
  organization,
  generalInfo,
  statusInfo,
  onDiscard,
  setStatus,
  channels,
  status,
  modal,
  selectedProductAssignmentMethod,
  selectProductAssignmentMethod,
  rulesEngineConditions,
  setRulesEngineConditions,
  fieldDropdown,
  setAllConditionMatch,
  allConditionMatch,
  conditionDropdownArray,
  setConditionDropdownArray,
  rulesChanges,
  setFormChanges,
  setRulesChanges,
  saveModuleRules,
  setColApiCall,
  setModal,
  productListData,
  setProductListData
}) => {
  const { collectionId } = useParams<{ collectionId: string }>();
  const [hasProductFilterConditions, setHasProductFilterConditions] = useState(false);

  useEffect(() => {
    if (rulesEngineConditions.length > 0) {
      setHasProductFilterConditions(true);
    } else {
      setHasProductFilterConditions(false);
    }
  }, [rulesEngineConditions]);

  const handleRemoveProductsFromCollection = (ids) => {
    setFormChanges(true);
  };

  return (
    <>
      <div className="width-animation w-100">
        <div className="main-detail-layout">
          <div className="left-pane">
            <GeneralInfoSection
              type="Collection"
              setGeneralInfo={setGeneralInfo}
              errorMessages={errorMessages}
              generalInfo={generalInfo}
              maxLength={50}
              setFormChanges={setFormChanges}
              setColApiCall={setColApiCall}
            />
            <TypeSection
              title="Collection type"
              typeDescription="this collection"
              selectedProductAssignmentMethod={selectedProductAssignmentMethod}
              selectProductAssignmentMethod={selectProductAssignmentMethod}
              rulesEngineConditions={rulesEngineConditions}
              setRulesEngineConditions={setRulesEngineConditions}
              fieldDropdown={fieldDropdown}
              conditionDropdownArray={conditionDropdownArray}
              setConditionDropdownArray={setConditionDropdownArray}
              allConditionMatch={allConditionMatch}
              setAllConditionMatch={setAllConditionMatch}
              setFormChanges={setFormChanges}
              setRulesChanges={setRulesChanges}
              rulesChanges={rulesChanges}
              saveModuleRules={saveModuleRules}
            />
            <RulesEngineProducts
              type="collection"
              productListIds={
                selectedProductAssignmentMethod === productAssignmentMethod.AUTOMATIC
                  ? productListData.autoMaticProductListIds
                  : productListData.manualProductListIds
              }
              selectedProductsIds={productListData.selectedProductsIds}
              setProductListData={setProductListData}
              hasConditions={hasProductFilterConditions}
              isAutoType={selectedProductAssignmentMethod === productAssignmentMethod.AUTOMATIC ? true : false}
              removeProducts={handleRemoveProductsFromCollection}
              setFormChanges={setFormChanges}
              detailId={collectionId}
            />
          </div>
          <div className="right-pane">
            <StatusSection
              updateChannelValues={updateChannelValues}
              statusInfo={statusInfo}
              setStatus={setStatus}
              channels={channels}
              status={status}
              setColApiCall={setColApiCall}
              setFormChanges={setFormChanges}
              page="collection"
            />
            <Thumbnail
              type=""
              thumbnailUrlData={thumbnailUrlData}
              setThumbnailUrlData={setThumbnailUrlData}
              showDeleteIcon={true}
              uploadeType="COLLECTION"
              setFormChanges={setFormChanges}
            />
            <div className="card mb-0">
              <OrganizationSection
                selectedOrganization={selectedOrganization}
                setOrganization={setOrganization}
                organization={organization}
                isCreateCollection={true}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        bodyClassname="w-90 w-md-50"
        show={modal.discardModal}
        closeModal={() =>
          setModal((prev) => ({
            ...prev,
            discardModal: false
          }))
        }
      >
        <DiscardModal
          actionBtnTitle={constVariables.common.discardForm.action}
          cancelBtnTitle={constVariables.common.discardForm.cancel}
          message={constVariables.common.discardForm.message}
          actionBtnHandler={onDiscard.bind(this, 'discard')}
          cancelBtnHandler={onDiscard.bind(this, 'close')}
          title={constVariables.common.discardForm.title}
        />
      </CustomModal>
      <CustomModal
        bodyClassname="w-90 w-md-50"
        show={modal.leaveModal}
        closeModal={() =>
          setModal((prev) => ({
            ...prev,
            leaveModal: false
          }))
        }
      >
        <DiscardModal
          actionBtnTitle={constVariables.common.LeaveForm.action}
          cancelBtnTitle={constVariables.common.LeaveForm.cancel}
          message={constVariables.common.LeaveForm.message}
          cancelBtnHandler={onDiscard.bind(this, 'leave')}
          title={constVariables.common.LeaveForm.title}
          actionBtnHandler={leavePageHandler}
        />
      </CustomModal>
      <CustomModal
        bodyClassname="w-90 w-md-50"
        show={modal.saveModal}
        closeModal={() =>
          setModal((prev) => ({
            ...prev,
            saveModal: false
          }))
        }
      >
        <ErrorModal
          actionTitle={constVariables.common.onSaveError.action}
          message={constVariables.common.onSaveError.message}
          actionHandler={onDiscard.bind(this, 'saveError')}
          errors={[errorMessages?.generalInfoTitleError]}
        />
      </CustomModal>
    </>
  );
};
export default CreateCollectionPresentational;
