import { gql } from '@apollo/client';

export const GET_NEXT_SCHEDULED_LIVE_SHOW = gql`
  query GetNextScheduledLiveShow @api(name: "liveStreamingAPI") {
    getNextScheduledLiveShow {
      id
      startingAt
      thumbnailUrl
      title
      endingAt
      products {
        productId
        productImage
        productName
        productPrice
        productReferencedId
      }
    }
  }
`;
